import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_welcome_video.rt";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { practiceWelcomeVideoGetDetails, practiceWelcomeVideoUpload, practiceWelcomeVideoPublish, practiceWelcomeVideoDelete } from '../../redux/practice/network/practice_welcome_video';
import Swal from 'sweetalert2';

class WelcomeVideo extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.onUploadCompleted = this.onUploadCompleted.bind(this);
        this.onUploadFailed = this.onUploadFailed.bind(this);
        this.publishWelcomeVideo = this.publishWelcomeVideo.bind(this);
        this.unpublishWelcomeVideo = this.unpublishWelcomeVideo.bind(this);
        this.deleteWelcomeVideo = this.deleteWelcomeVideo.bind(this);
    };
    
    componentWillMount() {
        this.props.practiceWelcomeVideoGetDetails();
    }

    componentWillUnmount() {
    }

    onUploadCompleted(uploadResult) {
        this.setState({
            uploadResult: uploadResult,
        });
        this.props.practiceWelcomeVideoUpload({
            ...uploadResult,
        }).then(()=>{
            //Utils.showSuccessAlert("Uploaded Successfully");
        });
    }

    onUploadFailed(error){
        Utils.showErrorAlert("Uploaded Failed. Please try again.");
    }
    
    publishWelcomeVideo() {
        this.props.practiceWelcomeVideoPublish({
            'publish': true
        });
    }

    unpublishWelcomeVideo() {
        this.props.practiceWelcomeVideoPublish({
            'publish': false
        });
    }

    deleteWelcomeVideo() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete the welcome video?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.practiceWelcomeVideoDelete().then(() => {
                Utils.showSuccessAlert("Welcome video has been deleted");
            }).catch((exception) => { });            
        }, function (dismiss) {
                        
        });
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        welcome_video: state.practice.practiceWelcomeVideo.welcome_video
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            practiceWelcomeVideoGetDetails,
            practiceWelcomeVideoUpload,     
            practiceWelcomeVideoPublish,
            practiceWelcomeVideoDelete,       
        }, 
        dispatch
    );
}

const WelcomeVideoConnected = connect(mapStateToProps,mapDispatchToProps)(WelcomeVideo);
export default WelcomeVideoConnected;