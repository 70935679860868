import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/guest_container";
import store,{history} from '../../redux/store';

import { verifyToken } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import Cookies from 'js-cookie';
import Axios from '../../utils/network/axios';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import {Table} from "../../vendor/reactable/reactable/table";
import {Thead} from "../../vendor/reactable/reactable/thead";
import {Th} from "../../vendor/reactable/reactable/th";
import {Tr} from "../../vendor/reactable/reactable/tr";
import {Td} from "../../vendor/reactable/reactable/td";


class GuestContainer extends BaseComponent {
    constructor(props, context) {
        super(props, context);
    };

    componentWillReceiveProps(nextProps) {
        if(nextProps.roles.length > 0){
            history.push("/");
        }
    }
    

    render() {
        if(this.props.isAuthenticated  && this.props.roles.length === 0){
            return Template.apply(this);
        }else{
            return (<div className="row center-xs m-t-25">
            <ProgressBar  type='circular' mode='indeterminate' multicolor />    
        </div>);
        }
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({verifyToken,updateMenuOpen:MenuAction.updateMenuOpen}, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen:state.core.appMenu.menu_open,
        pinned:state.core.appMenu.pinned
    };
}
const GuestContainerConnected = connect(mapStateToProps, mapDispatchToProps)(GuestContainer);
export default GuestContainerConnected;
