let initialState = {
    practiceLocation: {},
    practiceLocationList: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_LOCATION_LIST_FILL":
            if (!action.locations){
                return state;
            }        
            return Object.assign({}, state, {
                practiceLocationList: action.locations,
            });
        case "PRACTICE_LOCATION_FILL":
            return Object.assign({}, state, {
                practiceLocation: action.data,
            });
        case "PRACTICE_LOCATION_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
