import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_intakes.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getPartnerIntakeList, addPartnerLibraryIntake, removePartnerLibraryIntake,
    addAllIntakesPartnerLibrary, removeAllIntakesPartnerLibrary
} from '../../redux/partner/network/partner_network';
import {getIntakeList} from '../../redux/intake/network/intake_network';
import partnerActions from '../../redux/partner/actions/partner_action';


class PartnerLibraryIntakes extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addIntake = this.addIntake.bind(this);
        this.removeIntake = this.removeIntake.bind(this);
        this.addAllIntakes = this.addAllIntakes.bind(this);
        this.removeAllIntakes = this.removeAllIntakes.bind(this);
    };

    componentWillMount() {
        if(!this.props.match.params.library_id){
            history.goBack();
        }
        this.props.getPartnerIntakeList({
            id: this.props.match.params.library_id
        });
        this.props.getIntakeList({
            form_type: 1,
            fetch_type: "partner_library",

        });
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    addIntake(intake){
        this.props.addPartnerLibraryIntake({
            id:this.props.match.params.library_id,
            intake_id:intake.id
        });
    }

    removeIntake(intake){
        this.props.removePartnerLibraryIntake({
            id:this.props.match.params.library_id,
            intake_id:intake.id
        });
    }

    addAllIntakes() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.addAllIntakesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.getPartnerIntakeList({
                id: this.props.match.params.library_id
            });
            this.props.getIntakeList({
                form_type: 1,
                fetch_type: "partner_library",
    
            });
            }).catch((error) => {            
        });  
    }

    removeAllIntakes() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.removeAllIntakesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.getPartnerIntakeList({
                id: this.props.match.params.library_id
            });
            this.props.getIntakeList({
                form_type: 1,
                fetch_type: "partner_library",
    
            });
            }).catch((error) => {            
        });  
    }


    render() {
        if(_.isEmpty(this.props.partner_library)){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerIntakeList,
        addPartnerLibraryIntake,
        removePartnerLibraryIntake,
        getIntakeList,
        cleanUp: partnerActions.cleanUp,
        addAllIntakesPartnerLibrary,
        removeAllIntakesPartnerLibrary,
    }, dispatch);
}

function mapStateToProps(state,ownProps) {
    let partner_intake_ids = _.keyBy(state.partner.partnerReducer.partner_library.intakes,"id");
    return {
        partner_library: state.partner.partnerReducer.partner_library,
        intakeList: state.intake.intakeReducer.intake_list,
        partner_intake_ids:partner_intake_ids
    };
}

const PartnerLibraryIntakesConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryIntakes);
export default PartnerLibraryIntakesConnected;
