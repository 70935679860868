import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Dialog } from 'react-toolbox/lib/dialog';
export default function () {
    return React.createElement(Dialog, {
        'active': true,
        'title': 'Set Primary Practice'
    }, React.createElement('div', { 'className': 'row' }, React.createElement(Select, {
        'className': 'col-xs-12',
        'options': this.props.practice_options,
        'value': this.state.primary_practice,
        'simpleValue': true,
        'onChange': val => {
            this.updatePractice(val);
        },
        'valueKey': 'id',
        'labelKey': 'name'
    })), React.createElement('div', { 'className': 'row end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Update',
        'className': 'bg-success m-r-10',
        'onClick': () => {
            this.doSave();
        },
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Close',
        'className': 'bg-gray',
        'onClick': () => {
            this.cancel();
        },
        'primary': true,
        'raised': true
    })));
}