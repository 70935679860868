import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Tab } from 'react-toolbox';
import { Tabs } from 'react-toolbox';
import GroupClassEdit from '../group_class_edit';
import GroupClassEditCalendar from '../group_class_edit_calendar';
import { IconButton } from 'react-toolbox';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs m-t-15 group-classes' }, React.createElement(Card, { 'className': 'w-100' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement(Tabs, {
        'onChange': this.handleTabChange,
        'index': this.state.tabIndex
    }, React.createElement(Tab, {
        'label': 'Group Class Setup',
        'className': 'text-capital c-grey'
    }, React.createElement(GroupClassEdit, _.assign({}, { 'moveToReviewAndPublish': this.moveToReviewAndPublish }, this.props))), React.createElement(Tab, {
        'label': 'View Calendar',
        'className': 'text-capital c-grey'
    }, React.createElement(GroupClassEditCalendar, this.props))))));
}