import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import Switch from 'react-toolbox/lib/switch';
import { Dialog } from 'react-toolbox/lib/dialog';
import { SearchInput as TextField } from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import MailComposer from '../../../utils/common_components/mail_composer';
import { Panel } from 'react-toolbox';
import Select from 'react-select';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { PARTNER } from '../../../core/constants/constants';
import { Provider } from 'react-redux';
import store from '../../../redux/store';
export default function () {
    function repeatClient1(client, index) {
        return React.createElement('div', {
            'className': 'client-card box-shadow bg-white m-10 width-300 ribbon-container',
            'key': 'client' + index
        }, React.createElement('div', {
            'className': 'row pointer',
            'onClick': e => {
                this.gotoClientHome(e, client, index);
            }
        }, client.client_payment_plan ? React.createElement('div', {
            'className': 'ribbon f-10 c-white',
            'style': { backgroundColor: client.client_payment_plan_color },
            'key': '3770'
        }, '\n                    ', client.client_payment_plan.name, '\n                ') : null, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(ProfilePicture, {
            'profile_url': 'r',
            'user': client
        })), React.createElement('div', {
            'className': 'col-xs-6 p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', {
            'className': 'end-xs',
            'style': {
                wordBreak: 'break-word',
                wordSpace: 'pre-line'
            }
        }, client.user_state === 3 ? React.createElement(TooltipWrapper, {
            'tooltip': client.name,
            'key': '4674'
        }, React.createElement('h6', { 'className': 'two-lines text-capital text-left' }, '\n                                        ', client.name, '\n                                ')) : null, client.user_state === 2 ? React.createElement('div', {
            'className': 'f-16 c-red text-left',
            'key': '4996'
        }, '\n                            User has not registered\n                        ') : null, React.createElement('div', { 'className': 'f-12 f-300 p-t-5 text-left word-wrap' }, '\n                            ', client.email, '\n                        ')), this.state.selectedClientList !== 'hidden' ? React.createElement('div', {
            'className': 'p-absolute',
            'style': {
                top: '83px',
                left: '10px',
                right: '10px'
            },
            'key': '5582'
        }, client.user_state === 3 && this.props.selectedRole.is_workfit_practice === true ? React.createElement(IconButton, {
            'tooltip': 'Notify Client',
            'icon': 'notify',
            'onClick': () => {
                this.showNotifyComposer(client);
            },
            'className': 'small-icon-button c-grey',
            'key': '5726'
        }) : null, this.props.user.id !== client.id ? React.createElement(IconButton, {
            'tooltip': 'Reset Password',
            'icon': 'resetpassword',
            'onClick': () => {
                this.resetClientPassword(client);
            },
            'className': 'small-icon-button c-grey',
            'key': '6503'
        }) : null, !client.active_engagement_id ? React.createElement(IconButton, {
            'tooltip': 'No game plan set',
            'icon': 'gameplan',
            'onClick': e => {
                this.gotoClientHome(e, client, index);
            },
            'className': 'small-icon-button c-grey',
            'key': '6876'
        }) : null, React.createElement(IconButton, {
            'tooltip': 'Edit email address and other client details',
            'icon': 'user',
            'onClick': () => {
                this.editDetails(client);
            },
            'className': 'small-icon-button c-grey'
        }), React.createElement(IconButton, {
            'tooltip': 'Deactivate and hide this client',
            'icon': 'deactivate',
            'onClick': () => {
                this.toggleVisibility(client, 'hide');
            },
            'className': 'small-icon-button c-grey'
        })) : null, this.state.selectedClientList === 'hidden' ? React.createElement('div', {
            'className': 'p-absolute',
            'style': {
                top: '83px',
                right: '85px'
            },
            'key': '7897'
        }, React.createElement(IconButton, {
            'tooltip': 'Re-activate and show this client',
            'icon': 'reactivate',
            'onClick': () => {
                this.toggleVisibility(client, 'unhide');
            },
            'className': 'c-red small-icon-button'
        }), React.createElement(IconButton, {
            'tooltip': 'Delete this client from your practice',
            'icon': 'delete',
            'onClick': () => {
                this.deleteClientFromPractice(client);
            },
            'className': 'c-red small-icon-button'
        })) : null)));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, {
        'className': 'w-100 full-panel',
        'style': { width: '100%' }
    }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.goToDashboard
    }, '\n        Back\n    '), React.createElement('div', { 'className': 'w-100 m-t-20 m-r-10' }, React.createElement('div', { 'className': 'd-flex flex-sb' }, React.createElement('div', { 'className': 'page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Trainer'), React.createElement('h2', { 'className': 'page-title with-subtitle' }, 'Clients'))), React.createElement('div', {
        'className': 'col-xs-12 col-sm-10 col-md-8 p-relative search-box w-45  no-box-shadow m-b-20 p-5 vertical-align',
        'style': { justifyContent: 'end' }
    }, React.createElement(TextField, {
        'style': {
            width: '40%',
            marginRight: '25px',
            height: '40px'
        },
        'name': 'referring_physician',
        'type': 'text',
        'label': 'Search Clients',
        'onChange': this.updateSearchText,
        'onKeyPress': this.searchClients
    }), React.createElement(Button, {
        'className': 'width-fit bg-success m-r-10',
        'onClick': this.inviteClientScreen,
        'tooltip': 'Invite new client',
        'floating': true,
        'ripple': true,
        'mini': true
    }, 'Add New'), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    }))), this.props.clientList.length === 0 ? React.createElement('div', {
        'className': 'row m-t-20 center-xs',
        'key': '3307'
    }, '\n        There are no clients for your current selection\n    ') : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row d-flex flex-wrap' },
        _.map(this.props.clientList, repeatClient1.bind(this)),
        this.props.paginateInfo.page < this.props.paginateInfo.total_pages - 1 ? React.createElement('div', {
            'className': 'row center-xs m-30 w-100',
            'key': '8759'
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
            'label': 'Load more clients...',
            'className': 'height-fit bg-success-border text-capital c-black',
            'onClick': () => {
                this.loadMoreClients(true);
            }
        }))) : null
    ]), this.state.openmodal ? React.createElement(Card, {
        'style': { padding: '20px 40px' },
        'className': 'bg-white modal-container box-shadow',
        'key': '9214'
    }, React.createElement('a', {
        'className': 'modal-close',
        'onClick': () => {
            this.onClosePopup();
        }
    }), React.createElement('p', {
        'style': { textAlign: 'center' },
        'className': 'paywall-msg m-b-20'
    }, 'You have already invited 3 out of 3 free patients/clients. To invite more clients enable your subscription from the store.'), React.createElement('div', { 'className': 'modal-info' }, React.createElement('div', { 'className': 'width-fit' }, React.createElement('a', {
        'href': 'https://apps.apple.com/us/app/dhf-coachfit/id1130877074',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to App Store',
        'className': 'paywall-appstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    }))), React.createElement('div', { 'className': 'width-fit' }, React.createElement('a', {
        'href': 'https://play.google.com/store/apps/details?id=com.gomotive.dhf',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to Play Store',
        'className': 'paywall-playstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    }))))) : null, this.state.openmodal ? React.createElement('div', {
        'className': 'modal-overlay',
        'onClick': () => {
            this.onClosePopup();
        },
        'key': '10597'
    }) : null, React.createElement(Dialog, {
        'active': this.state.mailComposerVisible,
        'className': 'dialog-title-center',
        'title': this.state.currentlyMailingClient ? 'Message ' + this.state.currentlyMailingClient.name : '',
        'onEscKeyDown': this.hideMailComposer,
        'onOverlayClick': this.hideMailComposer
    }, React.createElement(Provider, { 'store': store }, React.createElement(MailComposer, {
        'hideEmail': true,
        'onSubmit': this.onMailComposerSubmit
    }))))), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow',
        'width': 50
    }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'p-b-30 col-xs-12' }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n                        Filters\n                    '), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), !this.props.selectedRole.is_consultant ? React.createElement('div', {
        'className': 'filter-section p-5 text-left',
        'key': '11857'
    }, React.createElement(Switch, {
        'checked': this.state.selectedClientList === 'all' ? true : false,
        'label': this.props.all_practice_client_filter_text,
        'onChange': val => {
            val ? this.onChangeClientSelectionList('all') : this.onChangeClientSelectionList('my_all');
        }
    }), React.createElement('hr', { 'className': 'dashed' })) : null, !this.props.selectedRole.is_consultant ? React.createElement('div', {
        'className': 'filter-section p-5 text-left',
        'key': '12393'
    }, React.createElement(Switch, {
        'checked': this.state.selectedClientList === 'hidden' ? true : false,
        'label': this.props.all_hidden_client_filter_text,
        'onChange': val => {
            val ? this.onChangeClientSelectionList('hidden') : this.onChangeClientSelectionList('my_all');
        }
    }), React.createElement('hr', { 'className': 'dashed' })) : null, this.state.selectedClientList !== 'all' && this.state.selectedClientList !== 'hidden' ? React.createElement('div', { 'key': '12949' }, React.createElement('div', { 'className': 'filter-section p-10 text-left' }, React.createElement('label', { 'className': 'medium-label' }, 'My Clients'), React.createElement(Select, {
        'className': 'col-xs-11',
        'value': this.state.selectedClientList,
        'options': this.props.clientSelectionList,
        'simpleValue': true,
        'clearable': false,
        'onChange': val => {
            this.onChangeClientSelectionList(val);
        }
    })), !this.props.selectedRole.is_consultant ? React.createElement('div', {
        'className': 'filter-section p-10 text-left',
        'key': '13653'
    }, React.createElement('label', { 'className': 'medium-label' }, 'Practice Practitioner Clients'), React.createElement(Select, {
        'className': 'col-xs-11',
        'value': this.state.selectedClientListOption,
        'options': this.props.practitionerList,
        'simpleValue': true,
        'clearable': false,
        'onChange': val => {
            this.onChangeClientSelectionList(val);
        }
    })) : null, this.props.selectedRole.practice.is_business_partner && !this.props.selectedRole.is_consultant ? React.createElement('div', {
        'className': 'filter-section p-10 text-left',
        'key': '14332'
    }, React.createElement('label', { 'className': 'medium-label' }, 'Dependent Practitioner Clients'), React.createElement(Select, {
        'className': 'col-xs-11',
        'value': this.state.selectedClientList,
        'options': this.props.consultantList,
        'simpleValue': true,
        'clearable': false,
        'onChange': val => {
            this.onChangeClientSelectionList(val);
        }
    })) : null) : null))));
}