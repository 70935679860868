import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
export default function () {
    function repeatLibrary1(library, libraryIndex) {
        return React.createElement(Tr, { 'key': 'library-' + library.id }, React.createElement(Td, { 'column': 'name' }, React.createElement('div', {}, '\n                    ', library.name, '             \n                ')), React.createElement(Td, {
            'className': 'text-center',
            'column': 'goals',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryGoals(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.goal_count, '\n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'intakes',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryIntakes(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.intake_count, '\n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'exercises',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryExercises(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.exercise_count, '             \n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryTemplates(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.template_count, '             \n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'habit_templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryHabitTemplates(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.habit_templates_count, '             \n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'group_templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryGroupTemplates(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.group_templates_count, '             \n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'gameplan_templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryGameplanTemplates(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.gameplan_templates_count, '             \n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'nutrition',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryNutrition(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.nutrition_count, '             \n                    '))), React.createElement(Td, {
            'className': 'text-center',
            'column': 'guidance',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('div', {
            'className': 'activity-icon bg-info pointer',
            'onClick': () => {
                this.showLibraryGuidance(library);
            }
        }, React.createElement('p', {
            'className': 'f-20',
            'style': { lineHeight: '60px' }
        }, '\n                        ', library.guidance_count, '             \n                    '))), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'icon': 'create',
            'className': 'm-r-5 micro',
            'onClick': () => {
                this.updateLibraryName(library);
            },
            'floating': true,
            'primary': true,
            'mini': true
        }), library.can_be_deleted ? React.createElement(Button, {
            'icon': 'delete_forever',
            'className': 'bg-danger micro',
            'onClick': () => {
                this.deleteLibrary(library);
            },
            'floating': true,
            'mini': true,
            'key': '7615'
        }) : null)));
    }
    return React.createElement('div', {
        'className': 'm-l-20 m-r-20',
        'style': { marginTop: '60px' }
    }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Shared Content Library name')), React.createElement(Th, {
            'column': 'goals',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Goals')), React.createElement(Th, {
            'column': 'intakes',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Intakes')), React.createElement(Th, {
            'column': 'exercises',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Exercises')), React.createElement(Th, {
            'column': 'templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Workout Templates')), React.createElement(Th, {
            'column': 'habit_templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Habit Templates')), React.createElement(Th, {
            'column': 'group_templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Group Templates')), React.createElement(Th, {
            'column': 'gameplan_templates',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Game Plan Templates')), React.createElement(Th, {
            'column': 'nutrition',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Nutrition')), React.createElement(Th, {
            'column': 'guidance',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Guidance')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.partner.libraries, repeatLibrary1.bind(this))
    ]), React.createElement(Button, {
        'style': { top: '100px' },
        'className': 'floating-add-button',
        'onClick': this.addLibrary,
        'icon': 'add',
        'floating': true,
        'mini': true,
        'ripple': true
    }));
}