import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import Sortable from 'react-anything-sortable';
import SortableItem from '../../../utils/common_components/sortable_item';
import { WORKOUT_TARGET_GROUP } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import GoalEdit from '../goal_edit';
import ProgramEdit from '../../../program/components/program_edit';
import ProgramList from '../../../program/components/program_list';
import HealthDocumentList from '../../../documents/components/document_list';
import Chip from 'react-toolbox/lib/chip';
import HabitList from '../treatment_template_habit_list';
import HabitEdit from '../treatment_template_habit_edit';
export default function () {
    function repeatGoal_question1(goal_question, index) {
        return React.createElement('div', {
            'className': 'm-b-2 col-xs-12',
            'key': 'goal_question' + index
        }, React.createElement('div', { 'className': 'row box-shadow bg-white  f-16 p-10' }, React.createElement('div', { 'className': 'col-xs-10' }, '\n                                    ', index + 1, ': ', goal_question.question, ' \n                                '), React.createElement('div', { 'className': 'col-xs-2' }, '\n                                    Metric Unit: ', goal_question.metric_unit.unit_name, '\n                                ')));
    }
    function repeatGoal2(goal, goalIndex) {
        return React.createElement('tr', { 'key': goal.id }, React.createElement('td', {}, goal.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.assignGoal(goal);
            },
            'tooltip': 'Assign goal for this  template',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    function repeatWorkout3(workout, workoutIndex) {
        return React.createElement(SortableItem, {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'workout' + workout.id,
            'sortData': workout
        }, !workout.published ? React.createElement('div', {
            'className': 'bg-warning ribbon f-10',
            'key': '13291'
        }, '\n                                    DRAFT\n                                ') : null, workout.published ? React.createElement('div', {
            'className': 'bg-success ribbon f-10',
            'key': '13468'
        }, '\n                                    PUBLISHED\n                                ') : null, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(workout.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white start-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '80px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (workout.description ? '9' : '12') + ' f-16 truncate',
            'tooltip': workout.name
        }, '\n                                        ', workout.name, '\n                                    '), workout.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': workout.description,
            'icon': 'info',
            'key': '14151'
        }) : null, workout.practitioner ? React.createElement('div', {
            'className': 'col-xs-12 f-12 f-300 m-b-2',
            'key': '14309'
        }, '\n                                        Practitioner: ', workout.owner.name, '\n                                    ') : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-2 m-0' }, React.createElement(IconButton, {
            'icon': 'create',
            'tooltip': 'Edit Workout',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.editWorkout(workout);
            }
        }), this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
            'icon': 'delete_forever',
            'tooltip': 'Delete Workout',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.deleteWorkout(workout);
            },
            'key': '14951'
        }) : null, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
            'icon': 'reorder',
            'style': { cursor: 'move' },
            'tooltip': 'Re-Order Workout',
            'className': 'm-r-5 c-black small-icon-button round-border handle',
            'key': '15324'
        }) : null)));
    }
    function repeatAttachment4(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument5(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment4.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document',
            'className': 'm-l-5 bg-danger micro',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'accent': true,
            'mini': true,
            'key': '18531'
        }) : null)));
    }
    function repeatAttachment6(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument7(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment6.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document to this engagement',
            'className': 'm-l-5 bg-danger micro',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'accent': true,
            'mini': true,
            'key': '21566'
        }) : null)));
    }
    function repeatHabit_template8(habit_template, habit_templateIndex) {
        return React.createElement('tr', { 'key': habit_template.id }, React.createElement('td', {}, habit_template.name), React.createElement('td', {}, habit_template.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.createHabitFromHabitTemplate(habit_template);
            },
            'tooltip': 'Add habit for this Game Plan',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    return React.createElement('div', { 'className': 'row p-15 m-b-25' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'panel m-b-0' }, React.createElement('div', { 'className': 'panel-body p-t-10' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement(Avatar, {
        'className': 'pointer',
        'size': 65,
        'user': {
            name: this.props.treatment_template.name,
            avatar_url: this.props.treatment_template.logo_url
        },
        'profile_type': 'circle'
    }), React.createElement('div', { 'className': 'flex-auto m-l-5' }, React.createElement('h5', { 'className': 'm-b-2' }, '\n                                ', this.props.treatment_template.name, '\n                                ', this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(FontIcon, {
        'onClick': this.editTreatmentTemplate,
        'value': 'create',
        'className': 'di-block c-primary f-15 m-l-5 pointer',
        'key': '3236'
    }) : null), this.props.match.params.treatment_type === 'group' ? React.createElement('div', {
        'className': 'f-14',
        'key': '3591'
    }, ' Type: ', this.props.treatment_template.__treatment_template_cycle) : null), React.createElement('div', { 'className': 'vertical-align' }, !this.props.treatment_template.published && this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.showWorkoutEditForm,
        'onClick': () => {
            this.publishTreatmentTemplate(true);
        },
        'label': 'Publish Template',
        'tooltip': 'Publish template',
        'className': 'bg-success small-button',
        'raised': true,
        'key': '4022'
    }) : null, this.props.treatment_template.published && this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.showWorkoutEditForm,
        'onClick': () => {
            this.publishTreatmentTemplate(false);
        },
        'label': 'Unpublish Template',
        'tooltip': 'Unpublish  template',
        'className': 'bg-danger small-button',
        'raised': true,
        'key': '4391'
    }) : null))))), React.createElement(Tabs, {
        'hideMode': 'display',
        'inverse': true,
        'fixed': true,
        'className': 'custom-tab accent col-xs-12',
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'hidden': this.props.match.params.treatment_type === 'gameplan',
        'label': 'Goal',
        'icon': 'gm-goal',
        'gomotive': true
    }, this.state.topPanelVisible ? [
        !this.props.treatment_template.goal ? React.createElement('div', {
            'className': 'row middle-xs',
            'key': '51211'
        }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'col-xs-10 alert-warning p-l-10',
            'key': '5277'
        }, '\n                        Click on + icon to define a goal and activate this group template.                    \n                    ') : null, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'col-xs-2',
            'key': '5570'
        }, React.createElement(Button, {
            'tooltip': 'Add goal text & metrics',
            'onClick': this.onClickEditGoal,
            'className': 'bg-success pull-right',
            'icon': 'add',
            'floating': true,
            'ripple': true,
            'mini': true
        })) : null) : null,
        this.props.treatment_template.goal ? [
            React.createElement('div', {
                'className': 'row middle-xs p-5 bg-primary',
                'key': '60571'
            }, React.createElement('h4', { 'className': 'col-xs-13' }, '\n                            ', this.props.treatment_template.goal.text, '\n                            ', this.props.treatment_template.goal.goal_questions.length > 0 && this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
                'onClick': this.onClickEditGoal,
                'tooltip': 'Edit goal text & metrics',
                'className': 'c-white',
                'icon': 'create',
                'key': '6321'
            }) : null, this.props.treatment_template.goal.goal_questions.length === 0 && this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
                'onClick': this.onClickEditGoal,
                'tooltip': 'Edit goal text & metrics',
                'label': 'Create Metrics',
                'className': 'bg-warning',
                'raised': true,
                'key': '6757'
            }) : null)),
            React.createElement.apply(this, [
                'div',
                {
                    'className': 'row',
                    'key': '60573'
                },
                _.map(this.props.treatment_template.goal.goal_questions, repeatGoal_question1.bind(this))
            ])
        ] : null,
        ,
        React.createElement(Dialog, {
            'active': this.state.goalChooserVisible,
            'title': 'Please select a goal from your library or create a custom goal',
            'onEscKeyDown': this.hideGoalChooser,
            'onOverlayClick': this.hideGoalChooser,
            'key': '51217'
        }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable no-box-shadow' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
            'tbody',
            {},
            _.map(this.props.goal_list, repeatGoal2.bind(this))
        ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
            'label': 'Add Custom Goal',
            'onClick': this.editGoal,
            'tooltip': 'Click this button if you want to add a custom goal for this  template',
            'className': 'bg-success m-r-10',
            'raised': true
        }), React.createElement(Button, {
            'label': 'Close!',
            'tooltip': 'Close dialog box',
            'accent': true,
            'raised': true,
            'onClick': this.hideGoalChooser
        }))))
    ] : null, !this.state.topPanelVisible ? [this.state.showGoalEditForm ? React.createElement(GoalEdit, {
            'treatment_template_id': this.props.match.params.treatment_template_id,
            'goal': this.props.treatment_template.goal,
            'finishEditing': this.finishEditingGoal,
            'deleteGoal': this.deleteGoal,
            'key': '101411'
        }) : null] : null), React.createElement(Tab, {
        'label': 'Program',
        'icon': 'gm-treatment',
        'gomotive': true,
        'disabled': this.props.match.params.treatment_type === 'group' && !this.props.treatment_template.goal
    }, this.state.topPanelVisible ? React.createElement('div', {
        'style': { marginTop: '-17px' },
        'key': '10797'
    }, React.createElement('ul', { 'className': 'tab-bar grey-tab  clear-row' }, React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 0 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(0);
        }
    }, '\n                            Workouts\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 1 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(1);
        }
    }, '\n                            Habits\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 2 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(2);
        }
    }, '\n                            Nutrition\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 3 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(3);
        }
    }, '\n                            Guidance\n                        '))), this.state.treatmentTabIndex === 0 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '12102'
    }, this.props.workouts.length === 0 ? React.createElement('div', {
        'className': 'col-xs-9 alert-warning m-t-10 m-b-10',
        'key': '12198'
    }, '\n                        Click on + icon to create a workout.\n                    ') : null, this.props.workouts.length !== 0 ? React.createElement('div', {
        'className': 'col-xs-9',
        'key': '12398'
    }) : null, React.createElement('div', { 'className': 'col-xs-3 m-t-10 pull-right' }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'onClick': this.addWorkout,
        'tooltip': 'Add new workout',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true,
        'key': '12553'
    }) : null), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement.apply(this, [
        Sortable,
        {
            'className': 'vertical-container',
            'sortHandle': 'handle',
            'onSort': this.handleWorkoutSort,
            'containment': true,
            'dynamic': true
        },
        _.map(this.props.workouts, repeatWorkout3.bind(this))
    ]))) : null, this.state.treatmentTabIndex === 1 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '15820'
    }, React.createElement(HabitList, { 'editHabit': this.editHabit })) : null, this.state.treatmentTabIndex === 2 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '15999'
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('nutrition'),
        'tooltip': 'Add nutrition document',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true,
        'key': '16150'
    }) : null), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a nutrition plan.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('strong', {}, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.nutritionDocuments, repeatDocument5.bind(this))
    ])) : null, this.state.treatmentTabIndex === 3 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '19033'
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('guidance'),
        'tooltip': 'Add guidance document',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true,
        'key': '19184'
    }) : null), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a guidance document .',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('strong', {}, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.guidanceDocuments, repeatDocument7.bind(this))
    ])) : null) : null, !this.state.topPanelVisible ? React.createElement('div', { 'key': '22102' }, React.createElement('div', { 'className': 'col-xs-12' }, this.state.showWorkoutEditForm ? React.createElement(ProgramEdit, _.assign({}, {
        'finishEditing': this.finishEditingWorkout,
        'usedInside': true,
        'treatment_template_id': this.props.match.params.treatment_template_id,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'program_id': this.state.editingWorkoutId,
        'disableEditing': false,
        'isNonSyncGroup': this.props.treatment_template.treatment_template_cycle == 2,
        'key': '22204'
    }, this.state.workoutProgramTypeParams)) : null, this.state.showTemplateSelection ? React.createElement(ProgramList, _.assign({}, {
        'usedInside': true,
        'cancelTemplateSelection': this.unAllocateSpace,
        'treatment_template_id': this.props.match.params.treatment_template_id,
        'mode': TEMPLATE_SELECTION_MODE,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'afterCreateWorkoutFromProgram': this.afterCreateWorkoutFromTemplate,
        'key': '22723'
    }, this.state.templateProgramTypeParams)) : null, this.state.habitSpaceAllocated ? React.createElement(HabitEdit, {
        'finishEditing': this.finishEditingHabit,
        'treatment_template': this.props.treatment_template,
        'habitId': this.state.editingHabitId,
        'key': '23203'
    }) : null)) : null)), React.createElement(Dialog, {
        'active': this.state.documentSelectionDialogVisible,
        'className': 'full-dialog',
        'title': 'Click \'+\' to assign',
        'onEscKeyDown': () => this.toggleDocumentSelectionDialog(),
        'onOverlayClick': () => this.toggleDocumentSelectionDialog()
    }, this.state.documentSelectionDialogVisible ? React.createElement(HealthDocumentList, {
        'selectionMode': true,
        'match': { params: { document_type: this.state.healthDocumentType } },
        'onSelected': this.assignHealthDocument,
        'key': '23789'
    }) : null), React.createElement(Dialog, {
        'active': this.state.openHabitTemplateModal,
        'title': 'Please select a habit from your library',
        'onEscKeyDown': this.hideHabitTemplateModal,
        'onOverlayClick': this.hideHabitTemplateModal
    }, this.state.openHabitTemplateModal ? React.createElement('div', {
        'className': 'row',
        'key': '24313'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', {}, 'Description'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.habit_template_list, repeatHabit_template8.bind(this))
    ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Close',
        'tooltip': 'Close dialog box',
        'accent': true,
        'raised': true,
        'onClick': this.hideHabitTemplateModal
    }))) : null));
}