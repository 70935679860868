import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'View Appointment',
        'className': 'm-b-10'
    }), React.createElement(CardText, {}, React.createElement('p', {}, 'Event Name: ', this.props.event.client_display_name), React.createElement('p', {}, 'Practice Name: ', this.props.event.practice.name), React.createElement('p', {}, 'Start time: ', moment(this.props.event.start_time).format('hh:mm a')), React.createElement('p', {}, 'End time: ', moment(this.props.event.end_time).format('hh:mm a')))));
}