import Axios from '../../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../../../practice/constants/constants';

import Utils from '../../../../utils/utils';
import {showProgress, hideProgress} from '../../../core/actions/progress_creator';
import ConditionsTreatedCreator from '../actions/conditions_treated_creator';

const getConditionsTreatedList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/conditions_treated_list";
        return Axios.post(url, params).then((response) => {
            dispatch(ConditionsTreatedCreator.conditionsTreatedListFill(response.conditions_treated_list));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveConditionsTreated = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/conditions_treated_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Conditions/Goals treated has been created successfully for your practice");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getConditionsTreated = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/conditions_treated_get";
        return Axios.post(url, params).then((response) => {
            dispatch(ConditionsTreatedCreator.conditionsTreatedGetFill(response.condition_treated));
            dispatch(hideProgress());
            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getConditionsTreatedList,
    getConditionsTreated,
    saveConditionsTreated
};