import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Chip from 'react-toolbox/lib/chip';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { creatableFunction as Creatable } from '../../../utils/common_components/select_form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import Dropzone from '../../../utils/common_components/dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
export default function () {
    function repeatAttachment1(attachment, attachmentIndex) {
        return React.createElement('div', {
            'key': attachmentIndex,
            'className': 'm-t-5'
        }, !this.props.document.practice || this.props.document.practice && this.props.document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Chip, {
            'deletable': true,
            'onDeleteClick': () => {
                this.deleteAttachment(attachment.attachment_url);
            },
            'key': '3217'
        }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, '\n                                        ', attachment.attachment_name, '\n                                    ')) : null, this.props.document.practice && this.props.document.practice.id !== this.props.selectedRole.practice.id ? React.createElement(Chip, { 'key': '3846' }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, '\n                                        ', attachment.attachment_name, '\n                                    ')) : null);
    }
    return React.createElement('div', { 'className': 'row document-template-edit' }, React.createElement(Button, {
        'className': 'm-t-10 m-b-10 d-flex d-flex back-btn c-grey text-capital',
        'onClick': this.goBack
    }, '\n        Back\n    '), React.createElement(Card, { 'className': 'w-100 bg-none no-box-shadow' }, React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {
        'className': 'bg-none d-flex bg-white no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'add-info box-shadow-10' }, React.createElement(CardTitle, {
        'className': 'c-black text-capital',
        'title': (this.props.match.params.document_id ? 'Edit ' : 'Add ') + this.props.match.params.document_type
    }), React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement(Field, {
            'label': 'Name',
            'className': 'w-100 m-b-20',
            'name': 'name',
            'type': 'text',
            'component': TextInput,
            'onClick': this.inputClick
        }),
        React.createElement(Field, {
            'multiline': true,
            'className': 'w-100 m-b-20',
            'name': 'description',
            'type': 'text',
            'component': TextInput,
            'label': 'Note',
            'rows': 4
        }),
        React.createElement('label', { 'className': 'w-100 m-t-20 text-left' }, 'Attachments'),
        this.state.attachments.length === 0 ? React.createElement('div', {
            'className': 'm-b-10',
            'key': '2833'
        }, React.createElement('label', { 'className': 'material-label f-italic f-13 w-100' }, 'No existing attachments')) : null,
        _.map(this.state.attachments, repeatAttachment1.bind(this)),
        !this.state.attachments.length > 0 ? React.createElement('div', {
            'className': 'w-100',
            'key': '4306'
        }, !this.props.document.practice || this.props.document.practice && this.props.document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Dropzone, {
            'multiple': true,
            'accept': 'application/pdf',
            'onDrop': this.onDrop,
            'style': {
                height: '60px',
                width: '100%',
                border: '2px dashed #ccc'
            },
            'key': '4402'
        }, React.createElement('div', { 'className': 'p-20' }, ' Click here or drag and drop to add new attachment')) : null) : null
    ])), React.createElement('div', { 'className': 'publish-info' }, !this.props.document.practice || this.props.document.practice && this.props.document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Card, {
        'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24',
        'key': '5172'
    }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-b-10 middle-xs' }, React.createElement(Button, {
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success w-100',
        'label': 'Publish',
        'primary': true,
        'raised': true
    }))) : null)))));
}