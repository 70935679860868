import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import PanelGroup from '../../../vendor/react-panelgroup/panelgroup';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatTemplate1(template, templateIndex) {
        return React.createElement('div', {
            'className': 'width-150 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'template' + template.id
        }, React.createElement('div', {
            'className': 'thumbnail box-150-90',
            'style': { backgroundImage: 'url(' + encodeURI(template.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (template.description ? '9' : '12') + ' f-16 truncate',
            'tooltip': template.name
        }, '\n                                ', template.name, '\n                            '), template.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': template.description,
            'icon': 'info',
            'key': '1993'
        }) : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-b-2 m-0' }, React.createElement(Button, {
            'tooltip': 'Delete',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'className': 'bg-danger micro',
            'onClick': () => {
                this.removeTemplate(template);
            }
        }))));
    }
    function repeatTemplate2(template, templateIndex) {
        return [!(template.id in this.props.partner_template_ids) ? React.createElement('div', {
                'className': 'width-150 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
                'key': '35471'
            }, React.createElement('div', {
                'className': 'thumbnail box-150-90',
                'style': { backgroundImage: 'url(' + template.image_url + ')' }
            }), React.createElement('div', {
                'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
                'style': { height: '30px' }
            }, React.createElement(TooltipWrapper, {
                'className': 'col-xs-' + (template.description ? '9' : '12') + ' f-16 truncate',
                'tooltip': template.name
            }, '\n                                    ', template.name, '\n                                '), template.description ? React.createElement(IconButton, {
                'className': 'col-xs-2 c-black center-xs',
                'tooltip': template.description,
                'icon': 'info',
                'key': '4308'
            }) : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-b-2 m-0' }, React.createElement(Button, {
                'tooltip': 'Add',
                'icon': 'add',
                'floating': true,
                'mini': true,
                'className': 'bg-success micro',
                'onClick': () => {
                    this.addTemplate(template);
                }
            })))) : null];
    }
    return React.createElement('div', { 'className': 'row start-xs  m-t-20 m-l-20 custom-divider' }, React.createElement(PanelGroup, { 'borderColor': '#eee' }, React.createElement('div', { 'className': 'col-xs-12 full-height' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Shared workout templates')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
        'tooltip': 'Remove all workout templates from this shared content library',
        'label': 'REMOVE ALL',
        'className': 'bg-danger small-button c-white',
        'onClick': () => {
            this.removeAllWorkoutTemplates();
        }
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-10' },
        _.map(this.props.partner_library.templates, repeatTemplate1.bind(this))
    ])), React.createElement('div', {
        'className': 'col-xs-12',
        'style': { minHeight: '400px' }
    }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Workout templates you can share')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
        'tooltip': 'Add all workout templates to this shared content library',
        'label': 'ADD ALL',
        'className': 'bg-success small-button c-white',
        'onClick': () => {
            this.addAllWorkoutTemplates();
        }
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-10' },
        _.map(this.props.templateList, repeatTemplate2.bind(this))
    ]))));
}