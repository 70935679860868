let initialState = {
    messages: [],
    paginate_info: {
        page: 0,
    },    
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "MESSAGE_LIST_FILL":
            if (!action.messages || !action.paginate_info){
                return state;
            }    
            return Object.assign({}, state, {
                messages: action.messages,
                paginate_info: action.paginate_info,
            });
        case "MESSAGE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
