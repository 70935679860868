import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../redux/store';
import Swal from 'sweetalert2'
import { getGroupClassSchedulerList,groupClassSchedulerDelete } from '../../redux/calendar/network/group_class_network';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';

class GroupClassList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addGroupClassScheduler = this.addGroupClassScheduler.bind(this);
        this.editGroupClassScheduler = this.editGroupClassScheduler.bind(this);
        this.deleteGroupClassScheduler = this.deleteGroupClassScheduler.bind(this);
        this.bookGroupClassScheduler = this.bookGroupClassScheduler.bind(this);
    }

    componentWillMount() {
        this.props.getGroupClassSchedulerList({
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id 
        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    addGroupClassScheduler(){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/group_class/add");
    }

    editGroupClassScheduler(group_class) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/group_class/edit/"+group_class.id);
    }
    deleteGroupClassScheduler(group_class) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to delete a group class",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then((result) => {
            if(result.value){
                that.props.groupClassSchedulerDelete({
                    'id': group_class.id,
                    app_version: "5.2",
                    current_role_type: "practitioner",
                    package_version: "1",
                    "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id 
                });
            }
        }, ()=> { });
    }

    bookGroupClassScheduler(group_class) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/group_class/"+group_class.id +"/booking");
    }

    viewCalendarGroupClassScheduler(group_class){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/group_class/"+group_class.id +"/view_calendar");
    }

    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        groupClassList: state.calendar.groupClassReducer.group_class_list,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGroupClassSchedulerList,
        groupClassSchedulerDelete,
        cleanUp: groupClassActionCreator.cleanModule
    }, dispatch
    );
}


const GroupClassListConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassList);
export default GroupClassListConnected;