// Action Creators
const engagementInstanceFill = (engagement) => ({
    type: 'ENGAGEMENT_INSTANCE_FILL',
    engagement: engagement
});
const engagementAssessmentInstanceFill = (assessment) => ({
    type: 'ENGAGEMENT_ASSESSMENT_INSTANCE_FILL',
    assessment: assessment
});
const engagementAssessmentInstanceCleanup = () => ({
    type: 'ENGAGEMENT_ASSESSMENT_INSTANCE_CLEANUP'
});

const engagementInstanceCleanup = () => ({
    type: 'ENGAGEMENT_INSTANCE_CLEANUP',
});

export default {
    engagementInstanceFill,
    engagementAssessmentInstanceFill,
    engagementAssessmentInstanceCleanup,
    engagementInstanceCleanup,
};
