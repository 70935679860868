import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/engaging_videos";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';

class EngagingVideos extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.openLink = this.openLink.bind(this);
    }
    
    componentWillMount() {
    }

    componentWillUnmount() {
    }

    openLink(indexCount) {
        if(indexCount === 1) {
            var win = window.open("https://app.gomotive.com/site_media/static/files/create_videos.pdf", '_blank');
            win.focus();
        } else if(indexCount === 2) {
            var win = window.open("https://wistia.com/library/get-loose-for-the-camera", '_blank');
            win.focus();
        } else if(indexCount === 3) {
            var win = window.open("https://wistia.com/library/shooting-video-with-an-iphone", '_blank');
            win.focus();            
        } else if(indexCount === 4) {
            var win = window.open("http://wistia.com/learning/sent-from-my-iphone", '_blank');
            win.focus();            
        } else if(indexCount === 5) {
            var win = window.open("https://wistia.com/library/down-and-dirty-lighting-kit", '_blank');
            win.focus();       
        } else if(indexCount === 6) {
            var win = window.open("http://wistia.com/learning/lighting-for-a-webcam", '_blank');
            win.focus();            
        } else if(indexCount === 7) {
            var win = window.open("https://www.youtube.com/watch?v=edzWTw0Yp_U", '_blank');
            win.focus();            
        } 
    }

    render() {
        return Template.apply(this);
    }
}

export default EngagingVideos;
