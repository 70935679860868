import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import { NavLink } from 'react-router-dom';
import FontIcon from 'react-toolbox/lib/font_icon';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import EditPracticePartnerPlan from '../practice_partner_edit_plans.jsx';
import { Dialog } from 'react-toolbox/lib/dialog';
import Inp from 'react-toolbox/lib/input';
export default function () {
    function repeatAvailable_plan1(practice_partner, practice_partnerIndex, available_plan, available_planIndex) {
        return React.createElement(Chip, { 'key': available_plan.id }, '\n                        ', available_plan.name, '\n                    ');
    }
    function repeatAvailable_plan2(practice_partner, practice_partnerIndex, available_plan, available_planIndex) {
        return React.createElement(Chip, { 'key': available_plan.id }, '\n                        ', available_plan.name, '\n                    ');
    }
    function repeatPractice_partner3(practice_partner, practice_partnerIndex) {
        return React.createElement(Tr, { 'key': practice_partner.id }, React.createElement(Td, { 'column': 'created_date' }, React.createElement('span', {}, moment(practice_partner.created_date).calendar())), React.createElement(Td, { 'column': 'practice_id' }, React.createElement('span', {}, practice_partner.practice.id)), React.createElement(Td, { 'column': 'practice_name' }, React.createElement('span', {}, practice_partner.practice.name)), React.createElement(Td, { 'column': 'practice_owner' }, React.createElement('span', {}, practice_partner.practice.owner.name)), React.createElement(Td, { 'column': 'practice_owner_email' }, React.createElement('span', {}, practice_partner.practice.owner.email)), React.createElement(Th, { 'column': 'client_count' }, React.createElement('strong', {}, practice_partner.client_count)), React.createElement(Td, {
            'column': 'state',
            'style': { textAlign: 'center' }
        }, React.createElement('span', {}, practice_partner.state == 1 ? React.createElement(Chip, {
            'className': 'bg-primary',
            'key': '5475'
        }, ' ', practice_partner.__state, ' ') : null, practice_partner.state == 2 ? React.createElement(Chip, {
            'className': 'bg-danger',
            'key': '5599'
        }, ' ', practice_partner.__state, ' ') : null, practice_partner.state == 3 ? React.createElement(Chip, {
            'className': 'bg-success',
            'key': '5722'
        }, ' ', practice_partner.__state, ' ') : null)), React.createElement(Td, {
            'column': 'plan',
            'style': { textAlign: 'center' }
        }, React.createElement('span', {}, !practice_partner.plan ? React.createElement('span', { 'key': '5972' }, ' - ') : null, practice_partner.plan ? React.createElement(Chip, { 'key': '6040' }, ' ', practice_partner.plan.name, ' ') : null)), React.createElement(Td, { 'column': 'specific_plans' }, React.createElement.apply(this, [
            'span',
            {},
            _.map(practice_partner.available_plans, repeatAvailable_plan1.bind(this, practice_partner, practice_partnerIndex))
        ])), React.createElement(Td, { 'column': 'available_plans' }, React.createElement.apply(this, [
            'span',
            {},
            _.map(practice_partner.paid_plans, repeatAvailable_plan2.bind(this, practice_partner, practice_partnerIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': { textAlign: 'center' }
        }, React.createElement('div', {}, React.createElement('i', {
            'onClick': () => {
                this.editPracticePartner(practice_partner);
            },
            'className': 'material-icons pointer c-primary'
        }, '\n                        create\n                    '))), React.createElement(Td, {
            'style': { width: '5%' },
            'column': 'activate_workfit'
        }, React.createElement('div', {}, React.createElement(Inp, {
            'type': 'checkbox',
            'checked': practice_partner.plan ? practice_partner.plan.name == 'WorkFit Plan' ? true : false : false,
            'onClick': event => this.activatePracticePartner(practice_partner.practice.id, event.target.checked ? true : false)
        }))));
    }
    return React.createElement('div', { 'className': 'p-20' }, React.createElement('div', { 'className': 'row' }, !this.props.partner.is_payment_gateway_ready ? React.createElement('div', {
        'className': 'bg-danger col-xs-11 text-center m-10 p-10 f-15',
        'key': '1147'
    }, ' \n            You have not yet configured your Stripe Account to process payments. \n            Click ', React.createElement(NavLink, { 'to': `/practice/${ this.props.selectedRole.practice.id }/payments/stripe_config` }, 'here'), '    to add now\n        ') : null), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 col-sm-10 col-md-8 p-relative search-box m-b-20 p-5 vertical-align' }, React.createElement(Inp, {
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'placeholder': 'Search practices by name, owner name, owner email address ...',
        'onChange': this.updateSearchText,
        'onKeyPress': this.searchPartners,
        'type': 'text',
        'name': 'search'
    }))), React.createElement('div', { 'className': 'row' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable col-xs-12',
            'id': 'table',
            'noDataText': 'No Business Partners have been added !',
            'sortable': ['created_date'],
            'isServerPaginate': true,
            'onSort': this.onSort,
            'onPageChange': this.onPageChange,
            'itemsPerPage': this.props.paginate_info.per_page,
            'currentPage': this.props.paginate_info.page,
            'numPages': this.props.paginate_info.total
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'created_date' }, React.createElement('strong', {}, 'Invited Date')), React.createElement(Th, { 'column': 'practice_id' }, React.createElement('strong', {}, 'Practice Id')), React.createElement(Th, { 'column': 'practice_name' }, React.createElement('strong', {}, 'Practice Name')), React.createElement(Th, { 'column': 'practice_owner' }, React.createElement('strong', {}, 'Practice Owner Name')), React.createElement(Th, { 'column': 'practice_owner_email' }, React.createElement('strong', {}, 'Practice Owner Email')), React.createElement(Th, { 'column': 'client_count' }, React.createElement('strong', {}, 'Client Count')), React.createElement(Th, {
            'column': 'state',
            'style': { textAlign: 'center' }
        }, React.createElement('strong', {}, 'Status')), React.createElement(Th, {
            'column': 'plan',
            'style': { textAlign: 'center' }
        }, React.createElement('strong', {}, 'Plan Selected')), React.createElement(Th, { 'column': 'specific_plans' }, React.createElement('strong', {}, 'Assigned Plans')), React.createElement(Th, { 'column': 'available_plans' }, React.createElement('strong', {}, 'Plans Available For Subscription')), React.createElement(Th, {
            'column': 'action',
            'style': { textAlign: 'center' }
        }, React.createElement('strong', {}, ' Action ')), React.createElement(Th, {
            'style': { width: '5%' },
            'column': 'activate_workfit'
        }, React.createElement('strong', {}, 'Activate Workfit'))),
        _.map(this.props.practice_partners, repeatPractice_partner3.bind(this))
    ])), React.createElement(Dialog, {
        'active': this.state.selected_practice_partner,
        'onEscKeyDown': this.closePracticePartnerDialog,
        'onOverlayClick': this.closePracticePartnerDialog
    }, React.createElement(EditPracticePartnerPlan, {
        'practice_partner': this.state.selected_practice_partner,
        'partner_plans': this.props.partner_plans,
        'handleClose': this.closePracticePartnerDialog,
        'handleCloseAndRefresh': this.closePracticePartnerDialogAndRefresh
    })), this.props.partner.subdomain != 'app' && this.props.partner.subdomain != 'gi' ? React.createElement(Button, {
        'style': { top: '90px' },
        'className': 'floating-add-button',
        'onClick': this.addPartner,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'key': '8163'
    }) : null);
}