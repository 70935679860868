import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import { Chip } from 'react-toolbox/lib/chip';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GroupClassBookingEdit from '../group_class_booking_edit.jsx';
export default function () {
    function repeatBookedDate1(bookedClient, bookedClientIndex, bookedDate, bookedDateIndex) {
        return React.createElement('span', { 'key': 'row' + bookedDateIndex }, React.createElement(Chip, {}, moment(bookedDate.group_class_schedule.date, 'YYYY-MM-DD').format('MMMM Do, YYYY'), '  (', moment(bookedDate.group_class_schedule.start_time).format('h:mm a'), ' to ', moment(bookedDate.group_class_schedule.end_time).format('h:mm a'), ')'));
    }
    function repeatBookedClient2(bookedClient, bookedClientIndex) {
        return React.createElement(Tr, { 'key': 'row' + bookedClientIndex }, React.createElement(Td, {
            'column': 'name',
            'value': bookedClient.name
        }, React.createElement('span', {}, bookedClient.name)), this.props.groupClass.booking_type === 2 ? React.createElement(Td, {
            'column': 'entire_series',
            'style': {
                width: '120px',
                textAlign: 'center'
            },
            'key': '2386'
        }, React.createElement('span', {}, bookedClient.entire_series ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '2561'
        }) : null, !bookedClient.entire_series ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '2747'
        }) : null)) : null, this.props.groupClass.booking_type === 2 ? React.createElement(Td, {
            'column': 'booked_dates',
            'key': '2970'
        }, React.createElement.apply(this, [
            'span',
            {},
            _.map(bookedClient.bookings, repeatBookedDate1.bind(this, bookedClient, bookedClientIndex))
        ])) : null, React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, this.props.groupClass.booking_type === 2 ? React.createElement(Button, {
            'onClick': () => {
                this.editGroupClassBooking(bookedClient);
            },
            'icon': 'create',
            'className': 'micro m-r-5',
            'floating': true,
            'primary': true,
            'mini': true,
            'key': '3908'
        }) : null, React.createElement(Button, {
            'onClick': () => {
                this.deleteClient(bookedClient.id);
            },
            'icon': 'delete_forever',
            'className': 'micro m-r-5 bg-danger',
            'floating': true,
            'primary': true,
            'mini': true
        }))));
    }
    return React.createElement('div', { 'className': 'm-30' }, React.createElement('h4', { 'className': 'm-b-5' }, 'Bookings for ', this.props.groupClass.name), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to book a client for this group class',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Client Name')), this.props.groupClass.booking_type === 2 ? React.createElement(Th, {
            'column': 'entire_series',
            'style': {
                width: '120px',
                textAlign: 'center'
            },
            'key': '1417'
        }, React.createElement('strong', {}, 'Booked entire series?')) : null, this.props.groupClass.booking_type === 2 ? React.createElement(Th, {
            'column': 'booked_dates',
            'key': '1637'
        }, React.createElement('strong', {}, 'Booked dates')) : null, React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.bookedClients, repeatBookedClient2.bind(this))
    ]), React.createElement(Button, {
        'style': { top: '100px' },
        'className': 'floating-add-button',
        'onClick': this.addGroupClassBooking,
        'icon': 'add',
        'floating': true,
        'mini': true,
        'ripple': true
    }), React.createElement(Dialog, {
        'active': this.state.showBookingDialog,
        'onEscKeyDown': this.closeBookingDialog,
        'onOverlayClick': this.closeBookingDialog
    }, this.state.showBookingDialog ? React.createElement(GroupClassBookingEdit, {
        'groupClassId': this.props.match.params.group_class_id,
        'bookedClientId': this.state.bookedClientId,
        'closeBookingDialog': this.closeBookingDialog,
        'key': '4865'
    }) : null));
}