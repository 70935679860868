import  React from "react";
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import HomeTemplate from "./templates/homefit_home.rt";
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import moment from 'moment';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../../redux/store';
import MenuAction from '../../../redux/core/actions/menu_state_creator';
import authActionCreator from  '../../../redux/auth/actions/auth_creator';
import {getHomeFitVerifyUser} from '../../../redux/dhf/move/network/move_network';
import RadialProgressChart from '../../../vendor/radial-progress-chart/index';
import { HOMEFIT_WELCOME_VIDEO } from '../../../utils/help_video';
import HelpVideoCreator from '../../../redux/core/actions/help_video_creator';

class HomeFitHome extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doLogout = this.doLogout.bind(this);
        this.closeWelcomeVideo = this.closeWelcomeVideo.bind(this);
        this.subscribeHomeFit = this.subscribeHomeFit.bind(this);
        this.state = {
            weekly_movement_score_text:"You are not a DHF HomeFit User",
            show_movement_graph:false,
            homefit_pin: " - ",
            welcome_video: null,
        };
    }


    doLogout() {
        this.props.doLogout();
        Utils.removeLocalStorageValue("token");
        localStorage.removeItem("selectedRole");
        history.push("/auth/login");
    }

    subscribeHomeFit() {
        history.push("/hf/subscriptions");
    }
    
    componentWillMount() {
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);
    }

    componentDidMount() {
        this.props.updateHelpVideo(HOMEFIT_WELCOME_VIDEO);
        this.props.getHomeFitVerifyUser().then((response)=>{
            let cwmp =  response.user.current_week_movement_points;
            let mobility_percentage =   (cwmp.mobility_percentage > 3) ?  cwmp.mobility_percentage : 3; 
            let strength_percentage =   (cwmp.strength_percentage > 3) ?  cwmp.strength_percentage : 3.2; 
            let metabolic_percentage =   (cwmp.metabolic_percentage > 5) ?  cwmp.metabolic_percentage : 5; 
            let power_percentage =   (cwmp.power_percentage > 7) ?  cwmp.power_percentage : 7; 
            let weeklyMovementText = `Weekly Movement Score
            Mobility: ${cwmp.mobility_percentage} %
            Strength: ${cwmp.strength_percentage} %
            Metabolic: ${cwmp.metabolic_percentage} %
            Power: ${cwmp.power_percentage} %
            `;
            this.setState({
                homefit_pin:response.homefit_pin,
                show_movement_graph:true,
                weekly_movement_score_text:weeklyMovementText
            },()=>{
                new RadialProgressChart('.radial-chart', {diameter: 130,series: [
                    {labelStart: '\uf06d', value: power_percentage},
                    {labelStart: '\uf004', value: metabolic_percentage},
                    {labelStart: '\uf0e7', value: strength_percentage},
                    {labelStart: '\uf1ae', value: mobility_percentage},
                ]});
                });
            if (response.has_viewed_homefit_video === false) {
                let homefit_welcome_video = {};
                homefit_welcome_video["title"] = "HomeFit Welcome Video";
                homefit_welcome_video["video_guid"] = response.homefit_welcome_video;
                this.setState({
                    welcome_video: homefit_welcome_video
                });
            } 
        }).catch((error)=>{

        });
    }

    componentWillUnmount() {
        this.props.clearHelpVideo();
    }


    closeWelcomeVideo() {
        this.setState({
            welcome_video: null,
        });
    }


    
    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return HomeTemplate.apply(this);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        menuopen:state.core.appMenu.menu_open,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getHomeFitVerifyUser,
            doLogout:authActionCreator.doLogout,
            updateMenuOpen:MenuAction.updateMenuOpen,
            updateMenuPinned: MenuAction.updateMenuPinned,
            updateHelpVideo: HelpVideoCreator.updateHelpVideo,
            clearHelpVideo: HelpVideoCreator.clearHelpVideo,
        }, 
        dispatch
    );
}

const HomeFitHomeConnected = connect(mapStateToProps, mapDispatchToProps)(HomeFitHome);
export default HomeFitHomeConnected;
