import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import {bindActionCreators} from 'redux';
import Template from "./templates/homefit_subscription";
import {connect} from 'react-redux';
import Utils from '../../../utils/utils';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getHomeFitVerifyUser,
    getMoveFamilyMembers,
    subscribeToHomeFitPlan,
    unSubscribeToHomeFitPlan,
    addMoveFamilyMembers,
    removeMoveFamilyMembers
} from '../../../redux/dhf/move/network/move_network';
import homeFitAction from '../../../redux/dhf/move/actions/move_actions';
import Swal from 'sweetalert2';
import {showProgress} from '../../../redux/core/actions/progress_creator';

class HomeFitSubscription extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.subscribe = this.subscribe.bind(this);
        this.unSubscribe = this.unSubscribe.bind(this);
        this.showInviteMemberModal = this.showInviteMemberModal.bind(this);
        this.hideInviteMemberModal = this.hideInviteMemberModal.bind(this);
        this.inviteFamilyMember = this.inviteFamilyMember.bind(this);
        this.deleteFamilyMember = this.deleteFamilyMember.bind(this);
        this.state = {
            subject : "",
            body : "",
            showInviteMemberModal:false
        };
    }

    componentWillMount() {
        this.getData();
    }

    componentDidMount () {
        this.getMessageBody();
    }
    
    showInviteMemberModal(){
        this.setState({"showInviteMemberModal":true});
    }

    hideInviteMemberModal(){
        this.setState({"showInviteMemberModal":false});
    }

    inviteFamilyMember(data){
        this.props.addMoveFamilyMembers(data).then((response)=>{
            this.setState({"showInviteMemberModal":false});
            Utils.showSuccessAlert("Invited successfully");
            this.getData();
        }).catch((error)=>{

        });
    }

    getData(){
        this.props.getHomeFitVerifyUser();
        this.props.getMoveFamilyMembers();
    }

    getMessageBody(){
        this.props.addMoveFamilyMembers({"request_type":"get_form"}).then((response)=>{
            this.setState({subject:response.subject,body:response.body});
        }).catch((error)=>{

        });
    }

    deleteFamilyMember(member){
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete this family member's subscription?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            that.props.removeMoveFamilyMembers({ remove_user_id: member.id }).then(() => {
                that.getData();
            }).catch(() => { });
        }, ()=> { });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    subscribe(token, plan) {
        this.props.showProgress();        
        let params = {};
        if(plan){
            params['plan_id'] = plan.id;
        }           
        if (token != null) {
            params["token"] = token.id;
        }

        this.props.subscribeToHomeFitPlan(params).then(() => {    
            this.getData();
        }).catch((exception) => {                 
        });                        
    }

    unSubscribe(plan){
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to unsubscribe.",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {};
            params['plan_id'] = plan.id;
            that.props.unSubscribeToHomeFitPlan(params).then(() => {    
                that.getData();       
            }).catch((exception) => {                 
            });                        
        }, function (dismiss) {
                        
        });        
    }



    render() {
        if (_.isEmpty(this.props.move_user)) {
            return (
                <div></div>
            );
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        move_user: state.move.moveReducer.move_user,
        family_member_list:state.move.moveReducer.family_member_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getHomeFitVerifyUser,
        getMoveFamilyMembers,
        subscribeToHomeFitPlan,
        unSubscribeToHomeFitPlan,
        addMoveFamilyMembers,
        removeMoveFamilyMembers,
        showProgress,
        cleanUp: homeFitAction.cleanModule
    }, dispatch);
}
const HomeFitSubscriptionConnected = connect(mapStateToProps, mapDispatchToProps)(HomeFitSubscription);
export default HomeFitSubscriptionConnected;