import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/homefit_container";
import store,{history} from '../../../redux/store';
import Utils from '../../../utils/utils';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Cookies from 'js-cookie';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../../redux/core/actions/menu_state_creator';


class HomeFitContainer extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.toggleDrawerActive = this.toggleDrawerActive.bind(this);
    };

    toggleDrawerActive(){
        let path = this.props.location.pathname;
        if(_.includes(path, '/home')){
            this.props.updateMenuPinned(false);
            this.props.updateMenuOpen(!this.props.menuopen);
        }else{
            this.props.updateMenuPinned(!this.props.pinned);
            this.props.updateMenuOpen(!this.props.menuopen); 
        }    
    }

    render() {
        if(!this.props.isAuthenticated){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }      
        return Template.apply(this);
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned:MenuAction.updateMenuPinned
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen:state.core.appMenu.menu_open,
        pinned:state.core.appMenu.pinned
    };
}
const HomeFitContainerConnected = connect(mapStateToProps, mapDispatchToProps)(HomeFitContainer);
export default HomeFitContainerConnected;
