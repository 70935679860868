import  React from "react";
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import EngagementTemplate from "./templates/client_engagement_treatment_item_engagement.rt";
import DashboardTemplate from "./templates/client_engagement_treatment_item_dashboard.rt";
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { connect } from 'react-redux';
import { history } from '../../redux/store';
import moment from 'moment';


class ClientEngagementTreatmentItem extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.showPreview = this.showPreview.bind(this);
        let todayString = moment().format("YYYY-MM-DD");
        this.state = { today_string: todayString };
    }
    
    componentWillMount() {
    }

    showPreview(engagement_id, date) {
        if (this.props.selectedRole.role.name === "client"){
           history.push("/client/engagement/" + engagement_id + "/workout/preview/"+date);
        }
    }

    
    render() {
        if (_.isEmpty(this.props.workouts) && _.isEmpty(this.props.habits) && _.isEmpty(this.props.ongoing_workouts)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        if(this.props.pageType === "engagement") {
            return EngagementTemplate.apply(this);
        } else {
            return DashboardTemplate.apply(this);
        }
        
    }
}
export default ClientEngagementTreatmentItem;

