import  React  from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/move_videos.rt";
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { bindActionCreators } from 'redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import swal from 'sweetalert2';
import { getMoveVideoList, deleteMoveVideo } from '../../../redux/dhf/move/network/move_network';
import MoveAction from '../../../redux/dhf/move/actions/move_actions';

class MoveVideos extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.viewMoveVideo = this.viewMoveVideo.bind(this);
        this.closeMoveVideo = this.closeMoveVideo.bind(this);
        this.editMoveVideo = this.editMoveVideo.bind(this);
        this.addMoveVideo = this.addMoveVideo.bind(this);
        this.deleteMoveVideo = this.deleteMoveVideo.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state = {
            search: null,
            show_move_video: false,
            move_video: {}
        };
    }

    componentWillMount() {
        this.props.cleanUp();
        this.fetchData(null, 0);
    }

    fetchData(search_text, page) {
        this.props.getMoveVideoList({
            'search_text': search_text,
            'page': page
        });
    }

    loadMore() {
        this.fetchData(this.state.search_text, this.props.paginate_info.page+1)
    }


    onSearch(text){
        this.setState({search:text},()=>{
            this.fetchData(text, 0);
        });
    }

    viewMoveVideo(video) {
        let move_video = {};
        move_video['title'] = video.name;
        move_video['welcome_text'] = "";
        move_video["video_guid"] = video.video_guid;
        move_video['thumbnail'] = video.thumbnail;
        this.setState({
            show_move_video: true,
            move_video: move_video,
        });
    }

    closeMoveVideo() {
        this.setState({
            show_move_video: false,
            move_video: {}
        });
    }

    editMoveVideo(video) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/edit/'+video.id);
    }

    addMoveVideo() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/add');
    }

    deleteMoveVideo(video) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You will be deleting HomeFit workout permanently! Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = { move_video_id: video.id };
            that.props.deleteMoveVideo(params).then(() => {
                that.loadData(that.state.search_txt);
            });
            swal.close();              
        }, function (dismiss) {
                        
        });    

    }


    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}


function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        move_videos: state.move.moveReducer.move_videos,
        paginate_info: state.move.moveReducer.paginate_info,

    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getMoveVideoList,
        deleteMoveVideo,
        cleanUp: MoveAction.cleanModule,
    }, dispatch),
    dispatch
});

const MoveVideosConnected = connect(mapStateToProps,mapDispatchToProps)(MoveVideos);
export default MoveVideosConnected;
