let initialState = {
    habit_template_list: [],
    paginate_info:{page:0},    
    habit_template: {},
    supporting_data: {},
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "HABIT_TEMPLATE_LIST_FILL":
            if (!action.habit_template_list){
                return state;
            }
            let newHabitTemplateList = [];
            if(action.paginate_info.page === 0){
                newHabitTemplateList = [ ...action.habit_template_list];
            }else{
                newHabitTemplateList = [...state.habit_template_list, ...action.habit_template_list];
            }
            return Object.assign({}, state, {
                habit_template_list: newHabitTemplateList,
                paginate_info:action.paginate_info
            });
        case "HABIT_TEMPLATE_FILL":
            if (!action.habit_template){
                return state;
            }
            return Object.assign({}, state, {
                habit_template: action.habit_template,
                supporting_data: action.supporting_data,
            });
        case "HABIT_TEMPLATE_CLEANUP":
            return Object.assign({}, initialState);    
        default:
            return state;
    }
};
