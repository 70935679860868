import Axios from '../../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';
import Utils from '../../../../utils/utils';
import {showProgress, hideProgress} from '../../../core/actions/progress_creator';
import WebinarAction from '../actions/webinar_actions';
import moment from 'moment';

const getWebinarList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_webinar_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(WebinarAction.webinarListFill(
                response['webinars'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const getWebinar = (webinar_id) => {
    let params = {  
        webinar_id:webinar_id
    };

    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_webinar_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(WebinarAction.webinarFill(
                response['webinar'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const webinarCreate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_webinar_post";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            if(response.status === "200"){
                Utils.showSuccessAlert("Webinar has been created");
            }else{
                Utils.showErrorAlert('Something went wrong');
            }
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const webinarEdit = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_webinar_post";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            if(response.status === "200"){
                Utils.showSuccessAlert("Webinar has been edited");
            }else{
                Utils.showErrorAlert('Something went wrong');
            }
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const deleteWebinar = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_webinar_delete";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const publishWebinar = (webinar_id,publish_status) => {
    let params = {
        webinar_id : webinar_id,
        is_publish : publish_status
    };
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_webinar_publish";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

export {
    getWebinarList,
    webinarCreate,
    deleteWebinar,
    webinarEdit,
    publishWebinar,
    getWebinar
};