import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import Select from '../../../utils/common_components/select';
import { FieldArray } from 'redux-form';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'text-left' }, React.createElement(Button, {
        'className': 'm-t-20 d-flex back-btn c-grey',
        'onClick': this.cancel
    }, '\n        Back\n    ')), React.createElement(Card, { 'className': 'no-box-shadow m-t-10 m-r-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', { 'className': 'add-goal d-flex no-box-shadow' }, React.createElement('div', { 'className': 'add-info box-shadow' }, React.createElement(CardTitle, {
        'title': (this.props.match.params.goal_id ? 'Edit' : 'Add') + ' Goal Template',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement(Field, {
        'className': 'w-100',
        'name': 'text',
        'type': 'text',
        'component': TextInput,
        'label': 'Goal Title',
        'hint': 'Goal Title'
    }), React.createElement('div', { 'className': 'm-t-25' }, React.createElement(FieldArray, {
        'name': 'goal_tracking',
        'component': this.renderGoalTrackingQuestions
    }))), React.createElement('div', { 'className': 'publish-info' }, !this.props.goal.practice || this.props.goal.practice && this.props.goal.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '2852' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs  m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Save as draft',
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Publish',
        'onClick': this.onPublish,
        'className': 'm-l-10 bg-success w-100',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true
    })))) : null)))));
}