import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_dashboard";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import swal from 'sweetalert2';
import programActions from '../../redux/program/actions/program';
import {
    deleteWorkout, workoutOrderUpdate, workoutVersionUpgrade, workoutPdfGenerate,
    nonScheduledWorkoutMarkComplete
} from '../../redux/program/network/program_list';
import { TEMPLATE_SELECTION_MODE, WORKOUT_TARGET_GROUP } from '../../redux/program/constants/constants';
import { listWorkouts } from '../../redux/group/network/group_workout';
import workoutActions from '../../redux/group/actions/group_workout';
import { getGroup,saveGroup, publishGroup,toggleVisibilityGroup,  groupDocumentPublishStatusToggle } from '../../redux/group/network/group_network_new';
import { createGroupHabitFromTemplate } from '../../redux/group/network/group_habit_new';
import groupActions from '../../redux/group/actions/group_creator';
import healthDocumentActions from '../../redux/group/actions/health_document';
import {
    listGuidanceDocuments, listNutritionDocuments, postHealthDocument, deleteHealthDocument
} from '../../redux/group/network/health_document_new';
import { getHabitTemplateList } from '../../redux/habit/network/habit_network';
import { getGoalList } from '../../redux/goal/network/goal_network';
import goalActions from '../../redux/goal/actions/goal_creator';
import { addGroupGoal, deleteGroupGoal } from '../../redux/group/network/group_goal';
import Swal from 'sweetalert2';
import {messageGroupClients} from '../../redux/message/network/message_network';

class GroupDashboard extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addWorkout = this.addWorkout.bind(this);
        this.editGroupWorkoutToggle = this.editGroupWorkoutToggle.bind(this);
        this.editWorkout = this.editWorkout.bind(this);
        this.doWorkoutPdf = this.doWorkoutPdf.bind(this);
        this.deleteWorkout = this.deleteWorkout.bind(this);
        this.handleWorkoutSort = this.handleWorkoutSort.bind(this);
        this.unAllocateSpace = this.unAllocateSpace.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.allocateTreatmentSpace = this.allocateTreatmentSpace.bind(this);
        this.handleTreatmentTabChange = this.handleTreatmentTabChange.bind(this);
        this.finishEditingWorkout = this.finishEditingWorkout.bind(this);
        this.editGoal = this.editGoal.bind(this);
        this.toggleDocumentSelectionDialog = this.toggleDocumentSelectionDialog.bind(this);
        this.assignHealthDocument = this.assignHealthDocument.bind(this);
        this.unAssignHealthDocument = this.unAssignHealthDocument.bind(this);
        this.afterCreateWorkoutFromTemplate = this.afterCreateWorkoutFromTemplate.bind(this);
        this.editHabit = this.editHabit.bind(this);
        this.finishEditingHabit = this.finishEditingHabit.bind(this);
        this.allocateHabitSpace = this.allocateHabitSpace.bind(this);
        this.finishEditingGoal = this.finishEditingGoal.bind(this);
        this.assignGoal = this.assignGoal.bind(this);
        this.onClickEditGoal = this.onClickEditGoal.bind(this);
        this.hideGoalChooser = this.hideGoalChooser.bind(this);
        this.deleteGoal = this.deleteGoal.bind(this);
        this.showGoalIntervalModal = this.showGoalIntervalModal.bind(this);
        this.hideGoalIntervalModal = this.hideGoalIntervalModal.bind(this);
        this.publishGroup = this.publishGroup.bind(this);
        this.hidePaymentModal = this.hidePaymentModal.bind(this);
        this.showPaymentModal = this.showPaymentModal.bind(this);
        this.editGroup = this.editGroup.bind(this);
        this.hideHabitTemplateModal = this.hideHabitTemplateModal.bind(this);
        this.createGroupHabitFromTemplate = this.createGroupHabitFromTemplate.bind(this);
        this.healthDocumentPublishStatusToggle = this.healthDocumentPublishStatusToggle.bind(this);


        this.showMailComposer = this.showMailComposer.bind(this);
        this.hideGroup = this.hideGroup.bind(this);
        this.hideMailComposer = this.hideMailComposer.bind(this);
        this.onMailComposerSubmit = this.onMailComposerSubmit.bind(this);
        this.save = this.save.bind(this);
        this.state = {
            topPanelVisible: true,
            treatmentSpaceAllocated: false,
            editingWorkoutId: null,
            tabIndex: 0,
            treatmentTabIndex: 0,
            showWorkoutEditForm: false,
            workoutPreviewMode: false,
            showTemplateSelection: false,
            showGoalEditForm: false,
            workoutProgramTypeParams: Utils.getProgramTypeParams('workout'),
            templateProgramTypeParams: Utils.getProgramTypeParams('template'),
            programTypeParams: Utils.getProgramTypeParams('workout'),
            documentSelectionDialogVisible: false,
            habitSpaceAllocated: false,
            editingHabitId: null,
            openGoalIntervalModal: false,
            openPaymentModal: false,
            healthDocumentType: null,
            openHabitTemplateModal:false,
            groupStatus: "active",
        };
    };
    save(){
        let associated_content = [] 
        this.props.group.associated_content.forEach((item)=>{
            associated_content.push(item.id)
        })
        this.props.saveGroup({
            ...this.props.group,
            trainer: this.props.group.trainer ? this.props.group.trainer.id : null,
            associated_content: associated_content,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "3",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
        }).then(()=>{
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/groups');
        })
    }
    editGroupWorkoutToggle(){
        this.setState(state => ({ 
            showWorkoutEditForm: !state.showWorkoutEditForm,
            topPanelVisible: true
        }));
    }

    componentWillMount() {
        this.loadGroupDetails();
    }
    componentDidMount() {

    }
    showMailComposer(group) {
        this.setState({
            mailComposerVisible: true,
            currentlyMailingGroup: group,
        });
    }

    hideMailComposer() {
        this.setState({
            mailComposerVisible: false,
            currentlyMailingGroup: null,
        });
    }

    onMailComposerSubmit(data) {
        this.props.messageGroupClients({
            ...data,
            package_version: "3",
            'group_id': this.state.currentlyMailingGroup.id
        }).then(() => {
            this.hideMailComposer();
        }).catch(() => {
        });
    }

    loadGroupDetails() {
        this.props.getGroup({ 
            id: this.props.match.params.group_id,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "3",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id, })
            .then((resp)=>{
                if(resp.group.active===false){
                    this.setState({
                        groupStatus: 'inactive'
                    })
                }
            }).then(()=>{
                this.props.getGoalList({ fetch_type: "practitioner_assign" });
            })

    }

    hideGroup(groupId) {
        let display_text = "";
        if (this.state.groupStatus === 'active') {
            display_text = "Would you like to deactivate this groupo from your practice? Are you sure?"
        } else {
            display_text = "Would you like to activate this group for your practice? Are you sure?"
        }
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (click) {
            let params = {
                app_version: "5.1.12",
                current_role_type: "practitioner",
                package_version: "2",
                practice_id: that.props.user.id===1599 ? 276 : that.props.practice_id,
            };
            params['group_id'] = groupId;
            params['group_type'] = that.state.groupStatus;
            if(click.value){
                that.props.toggleVisibilityGroup(params).then(() => {
                    that.loadGroupDetails();
                }).catch((error) => { });
            } else if(click.dismiss){
                return
            }

        }, function (dismiss) {
                        
        });
    }

    toggleDocumentSelectionDialog(documentType) {
        this.setState({
            documentSelectionDialogVisible: !this.state.documentSelectionDialogVisible,
            healthDocumentType: documentType ? documentType : this.state.healthDocumentType,
        });
    }

    handleTabChange(tabIndex) {
        if(tabIndex === 1){
            this.props.listWorkouts({ 
                group_id: this.props.match.params.group_id,
                app_version: "5.1.12",
                current_role_type: "practitioner",
                package_version: "2",
                "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,  });
            this.props.listGuidanceDocuments({ 
                group_id: this.props.match.params.group_id,
                package_version: "2",
                app_version: "5.2",
                current_role_type: "practice_admin",
                practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
            this.props.listNutritionDocuments({ 
                group_id: this.props.match.params.group_id,
                package_version: "2",
                app_version: "5.2",
                current_role_type: "practice_admin",
                practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
        }
        this.setState({
            tabIndex: tabIndex,
        });
        this.unAllocateSpace();
    }

    editGroup() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/group/edit/'+this.props.match.params.group_id);
    }

    handleTreatmentTabChange(treatmentTabIndex) {
        this.setState({ treatmentTabIndex });
    }

    finishEditingWorkout() {
        this.unAllocateSpace();
        this.setState({
            editingWorkoutId: null,
            showWorkoutEditForm: false,
        });
        this.props.listWorkouts({ 
            group_id: this.props.match.params.group_id,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            practice_id: this.props.practice_id,
         });
    }

    afterCreateWorkoutFromTemplate(workout_id) {
        this.setState({
            showTemplateSelection: false,
            showWorkoutEditForm: true,
            editingWorkoutId: workout_id,
        });
    }

    hidePaymentModal() {
        this.setState({ openPaymentModal: false});
    }
    showPaymentModal() {
        this.setState({ openPaymentModal: true});
    }


    assignHealthDocument(document) {
        this.props.postHealthDocument({
            group_id: this.props.match.params.group_id,
            health_document_id: document.id,
            document_type: this.state.healthDocumentType,
            package_version: "2",
            app_version: "5.2",
            current_role_type: "practice_admin",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id
        }).then(() => {
            this.setState({
                documentSelectionDialogVisible: false,
            });
            this.props.groupDocumentPublishStatusToggle({
                group_id: this.props.match.params.group_id,
                health_document_id: document.id,
                package_version: "2",
                app_version: "5.2",
                current_role_type: "practice_admin",
                practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id
            }).then(() => {
                if (document.document_type === 1) {
                    this.props.listNutritionDocuments({ 
                        group_id: this.props.match.params.group_id,
                        package_version: "2",
                        app_version: "5.2",
                        current_role_type: "practice_admin",
                        practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
                } else {
                    this.props.listGuidanceDocuments({ 
                        group_id: this.props.match.params.group_id,
                        package_version: "2",
                        app_version: "5.2",
                        current_role_type: "practice_admin",
                        practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
                }
            }).catch(() => { });
            })
        }

    finishEditingHabit() {
        this.unAllocateSpace();
        this.setState({
            editingHabitId: null,
            habitSpaceAllocated: false,
        });
    }

    editHabit(id) {
        let that = this;
        if(id){
            this.setState({
                editingHabitId: id,
            }, () => this.allocateHabitSpace());
        }else{
            swal.fire({
                title: "Select an option",
                text: "How would you like to create Habit?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Create from template",
                cancelButtonText: "Create new",
                useRejections:true
            }).then(function (result) {
                if(result.value) {
                    that.props.getHabitTemplateList({
                        show_published:true,
                        show_partner_templates:true
                    });
                    that.setState({
                        openHabitTemplateModal:true
                    });
                }
                if (result.dismiss === "cancel") {
                    that.setState({
                        editingHabitId: null,
                    }, () => that.allocateHabitSpace());
                }
            }, function (dismiss) {
                if (dismiss === "cancel") {
                    that.setState({
                        editingHabitId: null,
                    }, () => that.allocateHabitSpace());
                }
            });
        }
    }

    createGroupHabitFromTemplate(habit_template) {
        this.props.createGroupHabitFromTemplate({
            group_id: this.props.group.id,
            habit_template_id: habit_template.id,
            package_version: 2,
            app_version: "5.2",
            current_role_type: "practitioner",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id
        }).then((response) => {
            this.editHabit(response.habit_id);
            this.setState({ openHabitTemplateModal: false });
        }).catch((error) => {
            //do nothing;
        });
    }

    unAssignHealthDocument(document) {
        let that = this;
        swal.fire({
            title: "Confirmation",
            text: "Are you sure to remove the association of this nutrition document from this group?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes",
            cancelButtonText: "No, Cancel",
            useRejections:true
        }).then((click) => {
            if(click.value){
                that.props.deleteHealthDocument({
                    group_id: that.props.match.params.group_id,
                    health_document_id: document.id,
                    package_version: "2",
                app_version: "5.2",
                current_role_type: "practice_admin",
                practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id
                }).then(() => {
                    if (document.document_type === 1) {
                        that.props.listNutritionDocuments({ 
                            group_id: that.props.match.params.group_id,
                            package_version: "2",
                            app_version: "5.2",
                            current_role_type: "practice_admin",
                            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
                    } else {
                        that.props.listGuidanceDocuments({ 
                            group_id: that.props.match.params.group_id,
                            package_version: "2",
                            app_version: "5.2",
                            current_role_type: "practice_admin",
                            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
                    }
                }).catch(() => { });
            }
            swal.close()
        }, () => { });
    }

    toggleDocumentSelectionDialog(documentType) {
        this.setState({
            documentSelectionDialogVisible: !this.state.documentSelectionDialogVisible,
            healthDocumentType: documentType ? documentType : this.state.healthDocumentType,
        });
    }

    unAllocateSpace() {
        this.setState({
            topPanelVisible: true,
        });
    }

    addWorkout() {
        swal.fire({
            title: "Select an option",
            text: "How would you like to create Workout?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Create from template",
            cancelButtonText: "Create new",
            useRejections:true
        }).then((result) => {
            if(result.value) {
                this.allocateTreatmentSpace();
                this.setState({
                    showTemplateSelection: true,
                    showWorkoutEditForm: false,
                    workoutPreviewMode: false,
                });
            }
            if (result.dismiss === "cancel") {
                this.allocateTreatmentSpace();
                this.setState({
                    editingWorkoutId: null,
                    showTemplateSelection: false,
                    showWorkoutEditForm: true,
                    workoutPreviewMode: false,
                });
            }
        }, (dismiss) => {
            if (dismiss !== 'cancel') {
                return;
            }
            this.allocateTreatmentSpace();
            this.setState({
                editingWorkoutId: null,
                showTemplateSelection: false,
                showWorkoutEditForm: true,
                workoutPreviewMode: false,
            });
        });
    }

    allocateTreatmentSpace() {
        this.setState({
            topPanelVisible: false,
            treatmentSpaceAllocated: true,
        });
    }

    allocateHabitSpace() {
        this.setState({
            topPanelVisible: false,
            habitSpaceAllocated: true,
        });
    }

    doWorkoutPdf(workout) {
        this.props.workoutPdfGenerate({ id: workout.id }).then((response) => {     
            swal.fire({
                title: '',
                type: 'success',
                html:
                    '<a target="_blank" href="' + response["path"] + '">Click here to download the pdf</a> ',
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
                confirmButtonText: '',
                confirmButtonAriaLabel: '',
                useRejections:true
            });

        }).catch(() => { });         
    }

    markWorkoutComplete(workout) {
        swal.fire({
            title: "Are you sure?",
            text: "Mark this workout as completed? This is irreversible!",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            this.props.nonScheduledWorkoutMarkComplete({ id: workout.id }).then(() => {
                this.props.listWorkouts({ 
                    group_id: this.props.match.params.group_id,
                    app_version: "5.1.12",
                    current_role_type: "practitioner",
                    package_version: "2",
                    practice_id: this.props.practice_id,
                 });
            }).catch(() => { });
        }, () => {});
    }

    editWorkout(workout) {
        let startEdit = (previewOnly) => {
            this.allocateTreatmentSpace();
            this.setState({
                editingWorkoutId: workout.id,
                showWorkoutEditForm: true,
                workoutPreviewMode: previewOnly,
            });
        };
        if (workout.is_being_used) {
            startEdit(true);
            // swal.fire({
            //     title: "Workout In Use",
            //     text: "You can either Preview Workout or Change its content and scheduled workout days will move forward. Choose one of the options from below",
            //     type: "question",
            //     showCancelButton: true,
            //     showCloseButton: true,
            //     confirmButtonColor: "#0292d3",
            //     cancelButtonColor: "#4C555C",
            //     confirmButtonText: "Edit Workout",
            //     cancelButtonText: "Preview Workout",
            // }).then(() => {
            //     this.props.workoutVersionUpgrade({ id: workout.id }).then(() => {
            //         startEdit(false);
            //     }).catch(() => { });
            // }, (dismiss) => { 
            //     if (dismiss === 'cancel') {
            //         startEdit(true);
            //     }
            // });
        } else {
            startEdit(false);
        }
    }

    deleteWorkout(workout) {
        let active = workout.is_being_used;
        swal.fire({
            title: "Are you sure?",
            text: (active ? "Workout is being used. " : "") + "Are you sure to delete this workout?",
            type: active ? "warning" : "question",
            showCancelButton: true,
            confirmButtonColor: active ? "#f16767" : "#0292d3",
            confirmButtonText: "Yes, I am sure!",
            useRejections:true
        }).then((click) => {
            if(click.value){
                this.props.deleteWorkout({ id: workout.id }).then(() => {
                    this.props.listWorkouts({ 
                        group_id: this.props.match.params.group_id,
                        app_version: "5.1.12",
                        current_role_type: "practitioner",
                        package_version: "2",
                        practice_id: this.props.practice_id,
                     });
                }).catch(() => { });
            }
            swal.close()
        }, () => { });
    }

    handleWorkoutSort(workouts) {
        let orders = [];
        for (let index in workouts) {
            orders.push({
                id: workouts[index].id,
                order: parseInt(index) + 1,
            });
        }
        this.props.workoutOrderUpdate({
            workout_orders: orders,
            group_id: this.props.match.params.group_id
        }).then(() => {
            this.props.listWorkouts({ 
                group_id: this.props.match.params.group_id,
                app_version: "5.1.12",
                current_role_type: "practitioner",
                package_version: "2",
                practice_id: this.props.practice_id,
             });
        }).catch(() => { });
    }

    //////// All goal related functions /////

    onClickEditGoal() {
        if (!this.props.group.goal) {
            // show goal chooser
            this.setState({ goalChooserVisible: true });
        } else {
            this.editGoal();
        }
    }

    hideGoalChooser() {
        this.setState({ goalChooserVisible: false });
    }

    // starts editing group.goal
    editGoal() {
        this.setState({
            showGoalEditForm: true,
            topPanelVisible: false,
            goalChooserVisible: false,
        });
    }

    deleteGoal() {
        swal.fire({
            title: "Confirmation",
            text: "You are about to delete this goal. Are you sure?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            useRejections:true
        }).then((click) => {
            if(click.value){
                this.props.deleteGroupGoal({ 
                    id: this.props.group.goal.id,
                    package_version: 3,
                    app_version: "5.1.12",
                    current_role_type: "practitioner",
                    "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id, 
                 }).then(() => {
                    this.props.getGroup({ 
                        id: this.props.match.params.group_id,
                        package_version: 3,
                        app_version: "5.1.12",
                        current_role_type: "practitioner",
                        "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id, 
                     }).then(() => {
                        this.setState({
                            showGoalEditForm: false,
                            topPanelVisible: true,
                            goalChooserVisible: false,
                        });
                    });
                }).catch(() => { });
            }
            swal.close()
        }, () => { });
    }

    finishEditingGoal() {
        this.unAllocateSpace();
        this.props.getGroup({ 
            id: this.props.match.params.group_id,
            package_version: 3,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
     });
    }

    assignGoal(goal) {
        this.props.addGroupGoal({
            group_id: this.props.match.params.group_id,
            goal_id: goal.id,
            package_version: 2
        }).then(() => {
            this.props.getGroup({ 
                id: this.props.match.params.group_id,
                package_version: 3,
                app_version: "5.1.12",
                current_role_type: "practitioner",
                "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
             }).then(() => {
                this.setState({ goalChooserVisible: false });
                this.editGoal();
            }).catch(() => { });
        }).catch(() => {
            //do nothing;
        });
    }

    hideGoalIntervalModal() {
        this.setState({ openGoalIntervalModal: false });
    }
    showGoalIntervalModal() {
        this.setState({ openGoalIntervalModal: true });
    }

    publishGroup() {
        this.props.publishGroup({
            group_id: this.props.match.params.group_id,
        }).then(() => {
            this.loadGroupDetails();
        }).catch(() => {
            //do nothing;
        });        
    }

    hideHabitTemplateModal() {
        this.setState({ openHabitTemplateModal: false });
    }

    healthDocumentPublishStatusToggle(document) {
        this.props.groupDocumentPublishStatusToggle({
            group_id: this.props.match.params.group_id,
            health_document_id: document.id,
        }).then(() => {
            if (document.document_type === 1) {
                this.props.listNutritionDocuments({ 
                    group_id: this.props.match.params.group_id,
                    package_version: "2",
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
            } else {
                this.props.listGuidanceDocuments({ 
                    group_id: this.props.match.params.group_id,
                    package_version: "2",
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id });
            }
        }).catch(() => { });
    }



    componentWillUnmount() {
        this.props.cleanGroup();
        this.props.cleanWorkouts();
        this.props.cleanHealthDocuments();
        this.props.cleanGoal();
    }

    render() {
        if (_.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        store,
        user: state.auth.authReducer.user,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        selectedRole: state.auth.roleReducer.selectedRole,
        workouts: state.group.groupWorkouts.workouts,
        group: state.group.groupReducer.group,
        clients: state.group.groupReducer.clients,
        nutritionDocuments: state.group.healthDocument.nutritionDocuments,
        guidanceDocuments: state.group.healthDocument.guidanceDocuments,
        goal_list: state.goal.goalReducer.goal_list,
        habit_template_list: state.habit.habitTemplateReducer.habit_template_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setProgramMode: programActions.setMode,
        setWorkoutMode: programActions.setWorkoutMode,
        deleteWorkout,
        listWorkouts,
        cleanWorkouts: workoutActions.cleanModule,
        cleanGroup: groupActions.groupCleanup,
        cleanGoal: goalActions.goalCleanup,
        getGroup,
        workoutOrderUpdate,
        listGuidanceDocuments,
        listNutritionDocuments,
        postHealthDocument,
        deleteHealthDocument,
        cleanHealthDocuments: healthDocumentActions.cleanModule,
        workoutVersionUpgrade,
        workoutPdfGenerate,
        getGoalList,
        addGroupGoal,
        deleteGroupGoal,
        publishGroup,
        saveGroup,
        nonScheduledWorkoutMarkComplete,
        getHabitTemplateList,
        createGroupHabitFromTemplate,
        groupDocumentPublishStatusToggle,
        toggleVisibilityGroup,
        messageGroupClients
    }, dispatch);
}

const GroupDashboardConnected = connect(mapStateToProps, mapDispatchToProps)(GroupDashboard);
export default GroupDashboardConnected;
