let initialState = {
    coach_list: [],
    coach: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "COACH_LIST_FILL":
            if (!action.coaches){
                return state;
            }
            return Object.assign({}, state, {
                coach_list: action.coaches,
            });
        case "COACH_FILL":
            if (!action.coach){
                return state;
            }
            return Object.assign({}, state, {
                coach: action.coach,
            });
        case "COACH_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};