import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import { reduxForm, formValueSelector } from 'redux-form';
import Template from "./templates/habit_template_edit_new.rt";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import Swal from 'sweetalert2';
import { saveHabitTemplate, getHabitTemplate } from '../../redux/habit/network/habit_network';
import HabitTemplateActionCreator from '../../redux/habit/actions/habit_template_creator';

class HabitTemplateAddEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveHabitTemplateText = this.saveHabitTemplateText.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);

        this.state = {
            publish: false,
        };
    };
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        let params = {};
        if(this.props.match.params.habit_template_id !== undefined) {
            params = {
                'habit_template_id': this.props.match.params.habit_template_id
            };
        } else {
            params = {
                'habit_template_id': ''
            };
        }
        this.props.getHabitTemplate(params);        
    }

    componentWillReceiveProps (nextProps) {
        if (this.props.schedule_type && nextProps.schedule_type  && this.props.schedule_type !== nextProps.schedule_type  ) {
            if (nextProps.schedule_type === 4 && nextProps.duration_type === 1) {
                nextProps.change("duration_type", 2);
            }else if (nextProps.schedule_type === 5 && (nextProps.duration_type === 1 || nextProps.duration_type === 2)) {
                nextProps.change("duration_type", 3);
            }
        }
    }

    doCancel(){
        if(confirm("Are you sure? All changes will be discarded.")){
        history.goBack();
        }
    }
    

    componentWillUnmount() {
        this.props.cleanUp();
    }

    saveAsDraft() {
        this.setState({publish:false},()=>{
            this.props.handleSubmit(this.saveHabitTemplateText)();
       });
    }

    saveAndPublish() {
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.saveHabitTemplateText)();
        });
    }


    saveHabitTemplateText(data) { 
        let params = _.omit(data, ['can_be_deleted']);
        params.is_published = this.state.publish;
        this.props.saveHabitTemplate(params).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/habit_templates');
        }).catch((error) => {
        });
    }

    deleteVideo() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete the video?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
                that.props.change('video_guid', null);
                that.props.change('thumbnail', null);         
            }
        }, function (dismiss) {
                        
        });
    }

    onVideoUploadCompleted(result) {
        this.props.change('video_guid', result.guid);
        this.props.change('thumbnail', result.thumbnail);
    }

    onVideoUploadFailed(error) {
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    render() {
        if(_.isEmpty(this.props.habit_template)){
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

const selector = formValueSelector('HabitTemplateAddEditForm');
function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: state.habit.habitTemplateReducer.habit_template,
        habit_template: state.habit.habitTemplateReducer.habit_template,
        supporting_data: state.habit.habitTemplateReducer.supporting_data,
        video_guid: selector(state, 'video_guid'),
        schedule_type: selector(state, 'schedule_type'),
        duration_type: selector(state, 'duration_type'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            saveHabitTemplate,
            getHabitTemplate,
            cleanUp: HabitTemplateActionCreator.habitTemplateCleanup,
        },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name','text','schedule_type','duration_type','duration_count'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
        if (field === "schedule_type") {
            if (values[field] === 4) {
                if (values["schedule_week_day"] === undefined || values["schedule_week_day"] === null) {
                    errors["schedule_week_day"] = 'Required';
                } 
                if (values["duration_type"] === 1) {
                    errors["duration_type"] = 'Duration type cannot be in days when selected schedule type is once a week';
                }
            } else if (values[field] === 5) {
                if (values["schedule_month_date"] === undefined || values["schedule_month_date"] === null) {
                    errors["schedule_month_date"] = 'Required';
                } else if (values["schedule_month_date"] > 31) {
                    errors["schedule_month_date"] = 'Day of month must be less than or equal to 31';
                }
                if (values["duration_type"] === 1 || values["duration_type"] === 2) {
                    errors["duration_type"] = 'Duration type cannot be in days or weeks when selected schedule type is once a month';
                }
            }
        }
    });
    return errors;
};


const HabitTemplateAddEditForm = reduxForm(
    {
        form: 'HabitTemplateAddEditForm',
        enableReinitialize: true,
        validate
    }
)(HabitTemplateAddEdit);

const HabitTemplateAddEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(HabitTemplateAddEditForm);
export default HabitTemplateAddEditFormConnected;