import React  from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/welcome_video.rt";
import PropTypes from 'prop-types';

class WelcomeVideo extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        return Template.apply(this);
    }

}

WelcomeVideo.propTypes = {
    onCloseHandle: PropTypes.func.isRequired,
};

export default WelcomeVideo;
