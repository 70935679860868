let initialState = {
    habit: {},
    supporting_data: {},
};

export default function engagementHabit(state = initialState, action) {
    switch (action.type) {
        case "ENGAGEMENT_HABIT_INSTANCE_FILL":
            if (!action.habit){
                return state;
            }            
            return Object.assign({}, state, {
                habit: action.habit,
                supporting_data: action.supporting_data,
            });
        case "ENGAGEMENT_HABIT_INSTANCE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
