// Action Creators
const clientInstanceFill = (client, engagements, groups, active_engagement, support_multiple_gameplan) => ({
    type: 'CLIENT_INSTANCE_FILL',
    client:client,
    engagements: engagements,
    groups: groups,
    active_engagement: active_engagement,
    support_multiple_gameplan: support_multiple_gameplan
});

const clientDetailsFill = (client) => ({
    type: 'CLIENT_DETAILS_FILL',
    client:client,
});

const clientInstanceCleanup = () => ({
    type: 'CLIENT_INSTANCE_CLEANUP',
});

const clientSelectionFill = (client_obj) => ({
    type: 'CLIENT_SELECTION_FILL',
    client_obj
});

const clientSelectionClear = () => ({
    type: 'CLIENT_SELECTION_CLEAR',
});

export default {
    clientInstanceFill,
    clientDetailsFill,
    clientInstanceCleanup,
    clientSelectionFill,
    clientSelectionClear,
};
