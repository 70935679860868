import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/group_payments.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field , FieldArray} from 'redux-form';
import { history } from '../../redux/store';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import DatePicker from '../../utils/common_components/datepicker_form';
import TimePicker from '../../utils/common_components/time_picker_form';
import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Button from '../../utils/common_components/tooltipButton';
import { groupPaymentPost} from '../../redux/group/network/group_network';
import {getClientPaymentPlanList} from '../../redux/payment/network/client_payment_plan_network';
import moment from 'moment';


class GroupPayments extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doClose = this.doClose.bind(this);
        this.selectPlan = this.selectPlan.bind(this);
        this.state = {selectedPlan:-1};
    };

    
    componentWillMount() {
        if(this.props.group.payment_plan){
            this.setState({selectedPlan:this.props.group.payment_plan});
        }
        
        this.props.getClientPaymentPlanList({get_published:true});
    }


    doClose() {
        this.props.handleClose();
    }

    doSave() {     
        let params = {group_id: this.props.group.id};
        if(this.state.selectedPlan !== null){
            params.payment_plan = this.state.selectedPlan.id;
        }
        this.props.groupPaymentPost(params).then((response) => {
            this.doClose();
        }).catch((error) => {
            //do nothing
        });
    }

    selectPlan(plan){
        this.setState({selectedPlan:plan});
    }

    
    render() {
        if (_.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

GroupPayments.propTypes = {
    group:PropTypes.object.isRequired,    
    handleClose:PropTypes.func.isRequired,    
};



function mapStateToProps(state, ownprops) {
    let payment_plans = [{id:-1,name:'Bronze Plan'},...state.payment.clientPaymentPlanReducer.client_payment_plans];
    return {
        user: state.auth.authReducer.user,
        group: ownprops.group,
        handleClose:ownprops.handleClose,
        plans: payment_plans,
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        groupPaymentPost,
        getClientPaymentPlanList,
        }, 
        dispatch
    );
}


const GroupPaymentsConnected = connect(mapStateToProps,mapDispatchToProps)(GroupPayments);
export default GroupPaymentsConnected;
