import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import NewsView from '../news_view.jsx';
export default function () {
    function repeatNews1(news, index) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5 pointer',
            'key': 'news' + index
        }, React.createElement('div', {
            'onClick': () => {
                this.previewNews(news);
            }
        }, React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '40px' }
        }, React.createElement('div', { 'className': 'col-xs-12 f-16' }, React.createElement(TooltipWrapper, {
            'tooltip': news.title,
            'className': 'truncate'
        }, '\n                        ', news.title, '\n                    ')), news.business_partner ? React.createElement('div', {
            'className': 'col-xs-12 m-5 f-12 f-300 c-primary text-left',
            'key': '2514'
        }, React.createElement('p', {}, 'Author: ', news.business_partner.name)) : null, !news.business_partner ? React.createElement('div', {
            'className': 'col-xs-12 m-5 f-12 f-300 c-primary text-left',
            'key': '2716'
        }, React.createElement('p', {}, 'Author: ', news.practice.name)) : null), React.createElement('div', { 'className': 'box-300-225 m-t-5' }, news.cover_media && news.cover_media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225',
            'style': { backgroundImage: 'url(' + encodeURI(news.cover_media.thumbnail_url) + ')' },
            'onClick': () => {
                this.previewImage(media);
            },
            'key': '2990'
        }) : null, news.cover_media && news.cover_media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225',
            'key': '3277'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'gomotive_video' + news.cover_media.guid,
            'videoList': [news.cover_media.guid]
        })) : null, !news.cover_media ? React.createElement('div', {
            'className': 'p-12',
            'style': {
                height: '205px',
                overflow: 'hidden'
            },
            'key': '3576'
        }, '\n                    ', renderHTML(news.content), '\n                ') : null)));
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.newsList, repeatNews1.bind(this)),
        this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
            'className': 'row center-xs m-30 w-100',
            'key': '3783'
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
            'label': 'Load more news...',
            'className': 'bg-primary c-black',
            'onClick': () => {
                this.loadMoreNews(true);
            }
        }))) : null
    ]);
}