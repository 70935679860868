import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../../practice/constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import RecommendGomotiveCreator from '../actions/recommend_gomotive_creator';

const getRecommendGomotiveDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_recommend_gomotive";
        return Axios.post(url, params).then((response) => {
            dispatch(RecommendGomotiveCreator.recommendGomotiveFill(response.data));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const sendRecommendGomotiveEmail = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_recommend_gomotive_send_email";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("An email has been sent recommending GoMotive to your friend. Thank you for the same");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    getRecommendGomotiveDetails,
    sendRecommendGomotiveEmail
};