import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
import StripeCheckout from 'react-stripe-checkout';
import { STRIPE_KEY } from '../../../../payment/constants/constants';
import { CONSULTANT_SUBSCRIPTION_PAYMENT as CONSULTANT_SUBSCRIPTION_AMOUNT } from '../../../../payment/constants/constants';
export default function () {
    function repeatPractitioner1(practitioner, practitionerIndex) {
        return React.createElement('div', {
            'className': 'box-shadow bg-white m-10 width-300',
            'key': 'practitioner' + practitioner.id
        }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(ProfilePicture, { 'user': practitioner.user })), React.createElement('div', {
            'className': 'col-xs-6 p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', {
            'className': 'end-xs',
            'style': {
                wordBreak: 'break-word',
                wordSpace: 'pre-line'
            }
        }, practitioner.user.user_state === 3 ? React.createElement('div', {
            'className': 'f-16',
            'key': '2539'
        }, '\n                            ', practitioner.user.name, '\n                        ') : null, practitioner.user.user_state === 2 ? React.createElement('div', {
            'className': 'f-16 c-danger',
            'key': '2709'
        }, '\n                            Not Logged In\n                        ') : null, React.createElement('div', { 'className': 'f-12 f-300 p-t-5' }, '\n                            ', practitioner.user.email, '\n                        '), React.createElement('div', { 'className': 'm-t-5' }, practitioner.payment_live ? React.createElement('span', {
            'className': 'badge badge-success p-l-5 p-r-5 f-10',
            'key': '3069'
        }, 'SUBSCRIBED') : null, !practitioner.payment_live ? React.createElement('span', {
            'className': 'badge badge-danger p-l-5 p-r-5 f-10',
            'key': '3204'
        }, 'UNSUBSCRIBED') : null)), React.createElement('div', {
            'className': 'p-absolute',
            'style': {
                top: '115px',
                right: '10px'
            }
        }, practitioner.user.user_state === 2 && this.state.practitionerCategory !== 'inactive' ? React.createElement(IconButton, {
            'tooltip': 'Edit depenent partner details',
            'icon': 'create',
            'onClick': () => {
                this.editDetails(practitioner);
            },
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '3472'
        }) : null, practitioner.user.user_state === 2 && this.state.practitionerCategory !== 'inactive' ? React.createElement(IconButton, {
            'tooltip': 'Resend email invitation to join your practice',
            'icon': 'email',
            'onClick': () => {
                this.resendInvitation(practitioner);
            },
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '3871'
        }) : null, this.state.practitionerCategory === 'active' ? React.createElement(IconButton, {
            'tooltip': 'Update dependent partners title and bio',
            'icon': 'description',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.updateTitleBio(practitioner);
            },
            'key': '4315'
        }) : null, practitioner.role.id !== 2 && this.state.practitionerCategory === 'active' ? React.createElement(IconButton, {
            'tooltip': 'Make this dependent partner inactive from your practice',
            'icon': 'visibility_off',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.hide(practitioner);
            },
            'key': '4723'
        }) : null, this.state.practitionerCategory === 'invited' ? React.createElement(IconButton, {
            'tooltip': 'Make this dependent partner inactive from your practice',
            'icon': 'visibility_off',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.hide(practitioner);
            },
            'key': '5170'
        }) : null, this.state.practitionerCategory === 'inactive' ? React.createElement(IconButton, {
            'tooltip': 'Make this dependent partner active your practice',
            'icon': 'visibility',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.hide(practitioner);
            },
            'key': '5588'
        }) : null, practitioner.payment_live === false && this.state.practitionerCategory !== 'inactive' ? React.createElement(StripeCheckout, {
            'name': 'GoMotive, Inc.',
            'description': 'Monthly Subscription - Dependent Partner',
            'image': '/site_media/static/images/touch-icon-iphone.png',
            'panelLabel': 'Pay',
            'amount': CONSULTANT_SUBSCRIPTION_AMOUNT,
            'currency': 'USD',
            'stripeKey': STRIPE_KEY,
            'email': this.props.user.email,
            'zipCode': false,
            'allowRememberMe': false,
            'token': token => {
                this.subscribeToPlan(token, practitioner);
            },
            'key': '5996'
        }, practitioner.payment_live === false ? React.createElement(IconButton, {
            'tooltip': 'Subscribe by paying USD ' + CONSULTANT_SUBSCRIPTION_AMOUNT / 100 + '/month',
            'icon': 'attach_money',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '6927'
        }) : null) : null))));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement(Select, {
        'className': 'col-xs-4',
        'value': this.state.practitionerCategory,
        'options': this.props.selectionList,
        'simpleValue': true,
        'onChange': val => {
            this.onChangePractitionerCategory(val);
        }
    })), this.props.consultantList.length === 0 ? React.createElement('div', {
        'className': 'row m-20 center-xs',
        'key': '1521'
    }, React.createElement('div', { 'className': 'alert-warning' }, 'There are no ', this.state.practitionerCategory, ' dependent partners')) : null, this.props.consultantList.length > 0 && this.state.practitionerCategory !== 'inactive' ? React.createElement('div', {
        'className': 'row m-20 center-xs',
        'key': '1721'
    }, React.createElement('div', { 'className': 'alert-warning' }, 'Subscribed dependent partners will only be able to invite clients and assign game plans.')) : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.consultantList, repeatPractitioner1.bind(this))
    ]), React.createElement(Button, {
        'className': 'floating-add-button m-r-8',
        'style': { top: '110px' },
        'onClick': this.invitePractitionerScreen,
        'icon': 'add',
        'floating': true,
        'ripple': true
    }));
}