import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "../templates/facility/facility_view_calendar.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { getfacilityBookingList,facilityDelete } from '../../../redux/calendar/network/facility_network.js';
import  facilityActionCreator from '../../../redux/calendar/actions/facility_action';
import { Calendar as BigCalendar,momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment)
class FacilityViewCalendar extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.closeEventDialog = this.closeEventDialog.bind(this);
        this.state = {
            viewEventDialog: false,
            scheduleId: null,
        };
        
    }

    componentWillMount() {
                // BigCalendar.setLocalizer(
        //     BigCalendar.momentLocalizer(moment)
        // );
        this.loadCalendarEvents();
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    loadCalendarEvents() {
        let params = {};
        params.facility_id = this.props.match.params.facility_id;
        this.props.getfacilityBookingList({
            ...params,
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id });

    }

    viewEvent(event){
        this.setState({
            viewEventDialog: true,
            scheduleId: event.id,
        });
    }



    closeEventDialog(reloadData=false) {
        this.setState({
            viewEventDialog: false,
            scheduleId: null,
        },()=>{
            if(reloadData === true){
                this.loadCalendarEvents();
            }
        });
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownProps) {
    let facility = {...state.calendar.facilityReducer.facility};
    let booking_slots = _.cloneDeep(state.calendar.facilityReducer.booking_slots);
    let eventList = [];
    _.forEach(booking_slots, function(item) {
        let event = {};
        event.id = item.id;
        event.title = facility.name + " (" + item.booking_count + ")";
        event.start = moment(item.start_time).toDate();
        event.end = moment(item.end_time).toDate();
        eventList.push(event);
    });

    return {
        localizer,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        facility: facility,
        eventList: eventList,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getfacilityBookingList,
        facilityDelete,
        cleanUp: facilityActionCreator.cleanModule
    }, dispatch
    );
}

const FacilityViewCalendarConnected = connect(mapStateToProps, mapDispatchToProps)(FacilityViewCalendar);
export default FacilityViewCalendarConnected;