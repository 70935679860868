import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_home.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {getPartnerDetails} from '../../redux/partner/network/partner_network';
import partnerActions from '../../redux/partner/actions/partner_action';


class PartnerHome extends BaseComponent {
    constructor(props, context) {
        super(props, context);
    };

    
    componentWillMount() {
        this.props.getPartnerDetails({});
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    render() {
        if(_.isEmpty(this.props.partner)){
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerDetails,
        cleanUp:partnerActions.partnerInstanceFill
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        partner: state.partner.partnerReducer.partner,
    };
}
const PartnerHomeConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerHome);
export default PartnerHomeConnected;
