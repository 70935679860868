import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { FieldArray } from 'redux-form';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs m-20' }, React.createElement('h3', { 'className': 'text-left' }, '\n        Tracking Goals for ', moment(this.props.track_date).format('MMMM Do YYYY'), '\n    '), React.createElement('form', {
        'className': 'col-xs-12 medium-labels',
        'onSubmit': this.props.handleSubmit(this.saveGoal)
    }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(FieldArray, {
        'name': 'goals',
        'component': this.renderGoals
    })))));
}