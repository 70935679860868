let initialState = {
    start_count: 0,    
    checkin_count: 0,
    alert_count: 0,
    home_page_title:"",
    unread_chat_count:0,
    unread_messages_count:0,
    untracked_goals:[],
    intakes_assigned:[],
    engagement_list:[],
    group_list:[],
    quickstart_list:[],
    start_list:[],
    practitioners:[],
    available_dates:[],
    v2_show_movement_graph: false,
    v2_current_week_movement_points: {},
    v2_home_page_title: " ",
    v2_has_viewed_welcome_video: false,
    v2_client_welcome_video_url: "",
    v2_workout_image_url: "",
    v2_associated_practice_count: 0,
    v2_program_count: 0,
    v2_program_type: "",
    v2_program_id: 0,
    v2_news_list: null,
    v2_practitioners: [],
    v2_unread_message_count: 0,
    v2_unread_chat_count: 0,
    v2_selected_program: null,
    v2_program_list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {        
        case "CLIENT_HOME_FILL":          
            return Object.assign({}, state, {
                v2_show_movement_graph: action.show_movement_graph,
                v2_current_week_movement_points: action.current_week_movement_points,
                v2_home_page_title: action.home_page_title,
                v2_has_viewed_welcome_video: action.has_viewed_welcome_video,
                v2_client_welcome_video_url: action.client_welcome_video_url,
                v2_workout_image_url: action.workout_image_url,
                v2_associated_practice_count: action.associated_practice_count,
                v2_program_count: action.program_count,
                v2_program_type: action.program_type,
                v2_program_id: action.program_id,
                v2_news_list: action.news_list,
                v2_practitioners: action.practitioners,
                v2_unread_message_count: action.unread_message_count,
                v2_unread_chat_count: action.unread_chat_count,
            });
        case "CLIENT_HOME_ALERTS_FILL":      
            if(!action.untracked_goals){
                return state;
            }
            return Object.assign({}, state, {
                unread_chat_count: action.unread_chat_count,
                unread_messages_count: action.unread_messages_count,
                untracked_goals: action.untracked_goals,
                intakes_assigned: action.intakes_assigned,
            });
        case "CLIENT_CHECKIN_LIST_FILL":      
            if(!action.engagement_list){
                return state;
            }
            return Object.assign({}, state, {
                checkin_count: action.checkin_count,
                engagement_list: action.engagement_list,
                group_list: action.group_list,
                quickstart_list: action.quickstart_list,
                available_dates:action.available_dates
            });
        case "CLIENT_START_LIST_FILL":      
            if(!action.start_list){
                return state;
            }
            return Object.assign({}, state, {
                start_list: action.start_list,
            });
        case "CLIENT_CONNECT_PRACTITIONERS":      
            if(!action.practitioners){
                return state;
            }
            return Object.assign({}, state, {
                practitioners: action.practitioners,
            });
        case "CLIENT_PROGRAM_FILL":      
            if(!action.program){
                return state;
            }
            return Object.assign({}, state, {
                v2_selected_program: action.program,
            });            
        case "CLIENT_PROGRAM_LIST_FILL":  
            if(!action.programs){
                return state;
            }
            return Object.assign({}, state, {
                v2_program_list: action.programs,
            });            
        case "CLIENT_INTAKE_FORM_LIST_FILL":  
            if(!action.intake_forms){
                return state;
            }
            return Object.assign({}, state, {
                intakes_assigned: action.intake_forms,
            });            
        case "CLIENT_HOME_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
