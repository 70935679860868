// Action Creators
const fillCoursesList = (courseList, paginateInfo) => ({
    type: 'COURSES_LIST_FILL',
    courseList,
    paginateInfo
});
const fillCoursesFilterData = (filterCoursesList,first) => ({
    type: 'COURSES_FILTER_DATA_FILL',
    filterCoursesList,
    first
});
const fillAssociatedContentList = (associatedContentList) => ({
    type: 'ASSOCIATED_CONTENT_DATA_FILL',
    associatedContentList,
});
const deleteAssociatedContentItem = (associatedContentList) => ({
    type: 'ASSOCIATED_CONTENT_ITEM_DELETE',
    associatedContentList,
});

const removeCoursesList = (course) => ({
    type: 'COURSES_LIST_REMOVE',
    course
});
const categoryFill = (categories) => ({
    type: 'COURSES_CATEGORY_FILL',
    categories
});
const addCoursesList = (courses) => ({
    type: 'COURSES_LIST_ADD',
    courses
});
const updateCoursesListAfterToggleFavorite = (courses) => ({
    type: 'COURSES_LIST_UPDATE_AFTER_TOGGLE_FAVORITE',
    courses
});
const cleanModule = () => ({
    type: 'COURSES_LIST_CLEANUP',
});
const addCoursesToWorkoutCart = (courses) => ({
    type: 'ADD_COURSES_TO_WORKOUT_CART',
    courses,
});
const clearWorkoutCart = () => ({
    type: 'CLEAR_WORKOUT_CART',
});
export default {
    categoryFill,
    fillCoursesList,
    fillCoursesFilterData,
    fillAssociatedContentList,
    deleteAssociatedContentItem,
    cleanModule,
    removeCoursesList,
    addCoursesList,
    updateCoursesListAfterToggleFavorite,
    addCoursesToWorkoutCart,
    clearWorkoutCart
};