import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/client_engagement_list.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import clientEngagementAction from '../../redux/engagement/actions/client_engagement_creator';
import {  getClientEngagementList} from '../../redux/engagement/network/client_engagement_network';

class ClientEnagagementList extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.showEngagementDetails = this.showEngagementDetails.bind(this);
        this.state = {openClientGoalModal:false,selectedEngagement:null};
    };
    
    componentWillMount() {
        this.props.getClientEngagementList({});
    }

    componentWillUnmount() {
        this.props.cleanUp([]);
    }


    showEngagementDetails(engagement) {
        history.push("/client/engagement/"+engagement.id);
        // if (engagement.goal_state){
        //     history.push("/client/engagement/"+engagement.id);
        // } else {
        //     history.push("/client/engagement/goals/"+engagement.id);
        // }
    }

    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        engagements: state.engagement.clientEngagementReducer.engagement_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getClientEngagementList,
            cleanUp: clientEngagementAction.clientEngagementListFill
        }, 
        dispatch
    );
}

const ClientEnagagementListConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEnagagementList);
export default ClientEnagagementListConnected;
