import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/assessment_edit.rt";
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import store from '../../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm,formValueSelector } from 'redux-form';
import DhfAction from '../../../redux/dhf/assess/actions/assess_creator';
import { Card, CardText } from 'react-toolbox/lib/card';
import PropTypes from 'prop-types';


class AssessmentEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.onSectionCheckboxChange = this.onSectionCheckboxChange.bind(this);
        this.showQuestionVideo = this.showQuestionVideo.bind(this);
        this.closeQuestionVideo = this.closeQuestionVideo.bind(this);
        this.state = {
            sections:null,
            radio_options : [
                {
                    label : 'Yes',
                    value:true
                },
                {
                    label : 'No',
                    value:false
                }                                 
            ],
            video_object:null
        };
    }

    componentWillReceiveProps (nextProps) {
        if(_.isEqual(this.props.assessment,nextProps.assessment)){
            if(!_.isEqual(this.props.sections,nextProps.sections)){
                //this.calculateRisks(nextProps);
            }
        }else{
            this.setState({sections:null});
        }
    }
    
    // calculateRisks(props){
    //     let sections = _.map(props.sections,(section)=>{
    //         let sectionObject = {...section};
    //         sectionObject.risk = null;
    //         sectionObject.riskName = null;
    //         sectionObject.questions = _.map(sectionObject.questions,(question)=>{
    //             let questionObject = {...question};
    //             questionObject.risk = null;
    //             questionObject.riskName = null;
    //             if(questionObject.value !== null){
    //                 if(_.has(questionObject,"calculations")){
    //                     for(let calculation of questionObject.calculations){
    //                         if(_.has(calculation,"min_value") && _.has(calculation,"max_value")){
    //                             if(calculation.max_value >= questionObject.value && questionObject.value  >= calculation.min_value){
    //                                 questionObject.risk = calculation.id;
    //                                 questionObject.riskName = calculation.name;
    //                                 break; 
    //                             }
    //                         }else if(_.has(calculation,"min_value")){
    //                             if(questionObject.value >= calculation.min_value){
    //                                     questionObject.risk = calculation.id;
    //                                     questionObject.riskName = calculation.name;
    //                                     break; 
    //                             }
    //                         }else if(_.has(calculation,"max_value")){
    //                             if(questionObject.value <= calculation.max_value){
    //                                 questionObject.risk = calculation.id;
    //                                 questionObject.riskName = calculation.name;
    //                                 break; 
    //                             }
    //                         } 
    //                     }    
    //                 }
    //             }
    //             return questionObject;
    //         });
    //         if(_.has(sectionObject,"calculations") && sectionObject.questions.length >= 2){
    //             if(sectionObject.questions[0].value !== null && sectionObject.questions[1].value !== null){
    //                 let diffPercentage = 0;
    //                 let diffValue = 0;
    //                 if(sectionObject.questions[0].value === sectionObject.questions[1].value ){
    //                    diffPercentage = 100;
    //                    diffValue = 0;
    //                 }else if(sectionObject.questions[0].value > sectionObject.questions[1].value){
    //                    diffPercentage = (sectionObject.questions[1].value/sectionObject.questions[0].value) * 100; 
    //                    diffValue = sectionObject.questions[0].value - sectionObject.questions[1].value;
    //                 }else{
    //                    diffPercentage = (sectionObject.questions[0].value/sectionObject.questions[1].value) * 100; 
    //                    diffValue = sectionObject.questions[1].value - sectionObject.questions[0].value;
    //                 }
    //                 diffPercentage = Math.round(diffPercentage);
    //                 for(let calculation of sectionObject.calculations){
    //                     let difference = 0;
    //                     if(calculation.measure === "percent"){
    //                          difference =  100 - diffPercentage;
    //                     }else{
    //                          difference =  diffValue;
    //                     }
    //                    if(_.has(calculation,"min_difference") && _.has(calculation,"max_difference")){
    //                        if(calculation.max_difference >= difference && difference  >= calculation.min_difference){
    //                            sectionObject.risk = calculation.id;
    //                            sectionObject.riskName = calculation.name;
    //                             break; 
    //                        }
    //                    }else if(_.has(calculation,"min_difference")){
    //                         if(difference >= calculation.min_difference){
    //                            sectionObject.risk = calculation.id;
    //                            sectionObject.riskName = calculation.name;
    //                            break; 
    //                         }
    //                    }else if(_.has(calculation,"max_difference")){
    //                         if(difference <= calculation.max_difference){
    //                            sectionObject.risk = calculation.id;
    //                            sectionObject.riskName = calculation.name;
    //                            break; 
    //                         }
    //                    } 
    //             }
    //         }   
    //        }
    //         return sectionObject;
    //     });
    //     this.setState({sections:sections});
    // }

    doSave(data){
        let assessment =   _.cloneDeep(data); 
        let assessed_sections = _.filter(assessment.sections,{assessed:true});
        if(assessed_sections.length === 0){
            assessment.assessed = false;
        }else{
            assessment.assessed = true;
        }
        this.props.dhfAssessmentSave(assessment);
        Utils.showInfoAlert(assessment["name"] + " assessment values have been recorded");
       
    }
    
    onSectionCheckboxChange(val,sectionIndex,section){
        let questions = section.questions;
        if(_.has(this.props.assessment,"selected_game_plan")){
            if(val === false){
               let is_assessed =  _.reduce(this.props.sections, function(currentValue, sectionObj) {
                   if(currentValue){
                        return true;
                   }else{
                        if(sectionObj.id === section.id){
                            return false;
                        }else{
                            return sectionObj.assessed;
                        }
                   }
                  }, false);
                if(is_assessed === false){
                    this.props.change("selected_game_plan",-1);
                }  
            }
        }
        if(_.has(this.props.assessment.sections[sectionIndex],"selected_game_plan")){
            this.props.change("sections["+sectionIndex+"]['selected_game_plan']",-1);
        }
        for (let i = 0; i < questions.length; i++) { 
            this.props.change("sections["+sectionIndex+"]['questions']["+i+"]['value']",null);
        }
    }

    showQuestionVideo(video_object){
        this.setState({video_object:video_object});
    }
    closeQuestionVideo(){
        this.setState({video_object:null});
    }
    
    render() {
        if (this.props.assessment === null ||  _.isEmpty(this.props.assessment)) {
            return (
                <Card className="w-100 full-height" >
                    <CardText className="p-t-5">    
                        <div className="row center-xs m-t-25">
                            <h4 className="bg-light-info m-20 p-10 w-100">
                                Please select an assessment from the left section!    
                            </h4> 
                        </div>
                    </CardText>    
                </Card>    
            );
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('matEditForm'); 
function mapStateToProps(state, ownprops) {
    let assessment = { ...ownprops.assessment };
    if(assessment){
        assessment.sections = _.map(assessment.sections,(item)=>{
            if(!_.has(item,"assessed")){
                item.assessed = false;
            }
            return item;
        });
    }

    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        assessment:  assessment,
        initialValues: { ...assessment },
        sections: selector(state, 'sections'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        dhfAssessmentSave: DhfAction.dhfAssessmentFill,
    }, dispatch);
}

const validate = (values, props) => {
    const errors = {sections:[]};
    if(!_.has(values,"sections")){
        return errors;
    }
    for (let i = 0; i < values.sections.length; i++) {
        let section = values.sections[i];
        errors["sections"][i] = {questions:[]};
        if (section["assessed"] === false){
            continue;
        }
        for (let j = 0; j < section.questions.length; j++) {
            let question = section.questions[j];
            errors["sections"][i]["questions"][j] = {};
            if(question.value != null){
                if(_.has(question,"max_value")){
                    if(question.value > question.max_value){
                        errors["sections"][i]["questions"][j].value = 'Value should be less than or equal to '+ question.max_value;
                    }
                }
                if(_.has(question,"min_value")){
                    if(question.value < question.min_value){
                        errors["sections"][i]["questions"][j].value = 'Value should be more than '+ question.min_value;
                    }
                }
            }else{
                errors["sections"][i]["questions"][j].value = '* Required';
            }
        }
    }
    return errors;
};

AssessmentEdit.propTypes = {
    previewMode: PropTypes.bool,
};
AssessmentEdit.defaultProps = {
    previewMode: false,
};

const AssessmentEditForm = reduxForm(
    {
        form: 'matEditForm',
        enableReinitialize: true,
        validate
    }
)(AssessmentEdit);
const AssessmentEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(AssessmentEditForm);
export default AssessmentEditFormConnected;