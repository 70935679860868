import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actionCreator from '../actions/program_list';

const listProgram = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            if(params.category==""){
                dispatch(actionCreator.fillProgramListAssociated(
                    response['programs'],
                    response['paginate_info']
                ));
              }
            dispatch(actionCreator.fillProgramList(
                response['programs'],
                response['paginate_info']
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteProgram = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_delete";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            if(params.visibility === 'hide') {
                Utils.showSuccessAlert(params.program_type + " has been hidden successfully");
            } else {
                Utils.showSuccessAlert(params.program_type + " has been activated");
            }
            dispatch(actionCreator.deleteProgram(params.id));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const toggleFavorite = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_favorite_toggle";
        return Axios.post(url, params).then(async (response) => {
            dispatch(actionCreator.updateFavoriteProgramList(
                response['program']
            ));
            dispatch(hideProgress());
            if(response['program'].is_favorite) {
                Utils.showSuccessAlert("Selected template has been added to your favorite list");
            } else {
                Utils.showSuccessAlert("Selected template has been removed from your favorite list");
            }

        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveAs = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_save_as";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Saved Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const clone = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_clone";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.insertProgramClone(
                params.id,
                response['cloned_program']
            ));
            Utils.showSuccessAlert("Cloned Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteWorkout = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_delete";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Deleted Successfully");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const createWorkoutFromProgram = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_create_from_program";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Created Successfully");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const workoutOrderUpdate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_order_update";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Updated Successfully");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const workoutVersionUpgrade = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_version_upgrade";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Workout version upgraded successfully");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const workoutPdfGenerate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_pdf_generate";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getProgramFilters = (params) => {
    return (dispatch) => {
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_search_params_get";
        return Axios.post(url, params).then(async (response) => {
            let first = false;
            if(params.first){
                first = true;
            }
            dispatch(actionCreator.fillProgramFilterData(
                response['supporting_data'],first
            ));
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const nonScheduledWorkoutMarkComplete = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/non_scheduled_workout_mark_complete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Workout marked as complete");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const createWorkoutFromWorkoutCart = (params) => {    
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_post";
        return Axios.post(url, params).then(async (response) => { 
            dispatch(hideProgress());
            let params2 = {}      
            params2["workout_id"] = response["id"];
            params2["name"] = params["progression_name"];
            params2["description"] = params["progression_description"];
            params2["duration"] = params["progression_duration"];      
            params2["exercises"] = params["progression_exercises"];     
            const url2 = "/" + API_VERSION + "/" + PACKAGE + "/progression_post";
            return Axios.post(url2, params2).then(async (response2) => {
                dispatch(hideProgress());
                Utils.showSuccessAlert("Workout has been successfully created");                
                return response;
            }).catch((error) => {
                dispatch(hideProgress());
                if (error.response && error.response.status && error.response.status === 400) {
                    Utils.showErrorAlert(error.response.data.reason);
                }
            });
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const generateScheduledWorkoutsUsageReport = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/scheduled_workouts_usage_report";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const generateOngoingWorkoutsUsageReport = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/ongoing_workouts_usage_report";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    listProgram,
    deleteProgram,
    toggleFavorite,
    saveAs,
    clone,
    deleteWorkout,
    createWorkoutFromProgram,
    workoutOrderUpdate,
    workoutVersionUpgrade,
    workoutPdfGenerate,
    getProgramFilters,
    nonScheduledWorkoutMarkComplete,
    createWorkoutFromWorkoutCart,  
    generateScheduledWorkoutsUsageReport, 
    generateOngoingWorkoutsUsageReport,
};