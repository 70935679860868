import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/bundles_edit";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { uploadFiles } from '../../redux/core/network/file_upload';


class BundlesEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.cancel = this.cancel.bind(this);
        this.transformData = this.transformData.bind(this);
    };


    cancel() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/bundles');
    } 

    transformData(data) {
        let params = Utils.flatternFormData(data, ['position_pictures']);
        return params;
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        is_image_uploaded: selector(state, 'is_image_uploaded'),
        image_url: selector(state, 'image_url'),
        user_guid: selector(state, 'user_guid'),
        image_name: selector(state, 'image_name'),

    };
}
const selector = formValueSelector('BundlesEditForm');
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        uploadFiles,
    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const BundlesEditForm = reduxForm(
    {
        form: 'BundlesEditForm',
        enableReinitialize: true,
        validate
    }
)(BundlesEdit);

const BundlesEditConnected = connect(mapStateToProps, mapDispatchToProps)(BundlesEditForm);
export default BundlesEditConnected;
