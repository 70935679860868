import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progression_track";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { postProgressionProgress,getProgressionProgress } from '../../redux/program/network/workout_progress';
import { history } from "../../redux/store";
import actionCreator from '../../redux/program/actions/progression_edit';
import { reduxForm, formValueSelector , isValid} from 'redux-form';
import Utils from '../../utils/utils';
import moment from 'moment';

class ProgressionTrack extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doClose = this.doClose.bind(this);
        this.state = {
            has_tracked_options:[
                {label:"Did it",value:3},
                {label:"Did Part of it",value:2},
                {label:"Didn't do it",value:1}]
        };
    }

    componentWillMount() {
        if (!this.props.match.params.progression_id  ||  !this.props.match.params.date ) {
            history.goBack();
        }        
        var d = moment(this.props.match.params.date,'YYYY-MM-DD');
        if(d == null || !d.isValid()){
             history.goBack();
             Utils.showWarningAlert("Workout cannot be tracked for this date");
        }
        this.props.getProgressionProgress({ progression_id: this.props.match.params.progression_id,track_date:this.props.match.params.date });
    }

    doSave(data) {
        let params = _.pick(data,["progression_id","feedback","has_tracked"]);
        params.date = this.props.progression.progress.progression_date.date;
        this.props.postProgressionProgress(params).then((response)=>{
            if(this.props.match.params.engagement_id){
                history.push("/client/engagement/"+this.props.match.params.engagement_id+"/workout/preview/"+this.props.match.params.date);
            }else if(this.props.match.params.group_id){
                history.push("/client/group/"+this.props.match.params.group_id+"/workout/preview/"+this.props.match.params.date);
            }
        }).catch((error)=>{

        });
    }

    doClose(){
        if(this.props.match.params.engagement_id){
            history.push("/client/engagement/"+this.props.match.params.engagement_id+"/workout/preview/"+this.props.match.params.date);
        }else if(this.props.match.params.group_id){
            history.push("/client/group/"+this.props.match.params.group_id+"/workout/preview/"+this.props.match.params.date);
        }else{
            history.goBack();
        }
    }

    render() {
        if (_.isEmpty(this.props.progression)) {
            return (<div></div>);
        }        
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
    }

}

const validate = (values, props) => {
    let requiredFields = [
        'has_tracked'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ProgressionTrackForm = reduxForm(
    {
        form: 'ProgressionTrackForm',
        enableReinitialize: true,
        validate
    }
)(ProgressionTrack);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postProgressionProgress,
        getProgressionProgress,
        clean: actionCreator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let progression = state.program.progressionEdit.progression;
    let initialValues = undefined;
    if (!_.isEmpty(progression)) {
        initialValues = {
            progression_id: progression.id,
            feedback: progression.progress.feedback,
            has_tracked:progression.progress.has_tracked ? progression.progress.has_tracked.toString() : null, 
        };
    }
    return {
        ...ownProps,
        progression: progression,
        initialValues: initialValues,
    };
}

const ProgressionTrackConnected = connect(mapStateToProps, mapDispatchToProps)(ProgressionTrackForm);
export default ProgressionTrackConnected;
