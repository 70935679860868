import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import DatePicker from '../../../utils/common_components/datepicker_form';
export default function () {
    return React.createElement('div', {}, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, this.props.image_url ? [this.props.image_url ? React.createElement('div', {
            'className': 'm-b-20 row start-xs',
            'key': '13491'
        }, React.createElement('div', { 'className': 'col' }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(this.props.image_url) + ')' }
        })), React.createElement('div', { 'className': 'col-xs-4 end-xs m-t-30' }, React.createElement(Dropzone, {
            'className': 'dummy_suppresses_warnings',
            'multiple': false,
            'accept': 'image/*',
            'onDrop': this.uploadProgramImage
        }, React.createElement(Button, {
            'label': this.props.image_guid ? 'Change Custom Thumbnail' : 'Upload Custom Thumbnail',
            'primary': true,
            'raised': true
        })), this.props.image_guid ? React.createElement(Button, {
            'onClick': this.deleteProgramImage,
            'label': 'Remove Custom Thumbnail',
            'className': 'm-t-10 bg-danger',
            'primary': true,
            'raised': true,
            'key': '2025'
        }) : null)) : null] : null, React.createElement('div', { 'className': 'row m-l-0' }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Publish?'), React.createElement(Field, {
        'name': 'published',
        'component': Switch
    })), React.createElement('div', { 'className': 'row end-xs' }, React.createElement(Button, {
        'label': 'Submit',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'bg-success-red c-white',
        'raised': true
    }))));
}