import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/banners";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { listPromoBanner, hidePromoBanner, deleteBanner } from '../../redux/promo_banners/network/promo_banner_network'
import { param } from 'jquery';
import BannerActionCreator from '../../redux/promo_banners/actions/banner_list'
import Swal from 'sweetalert2';

class PromoBanner extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            index: this.props.match.params.banner_type==='feature-banners'
                    ? 1
                    : this.props.match.params.banner_type==='upcoming-banners'
                    ? 2 : 0
        }
        this.handleChange = this.handleChange.bind(this)
        this.edit = this.edit.bind(this);
        this.add = this.add.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.deleteBanner = this.deleteBanner.bind(this);
    };
    handleChange(value) {
        this.setState({ index: value })
        value===0
        ? this.props.history.push(`/practice/${this.props.selectedRole.practice.id}/marketing/banners/promo-banners`)
        : value===1
        ? this.props.history.push(`/practice/${this.props.selectedRole.practice.id}/marketing/banners/feature-banners`)
        : this.props.history.push(`/practice/${this.props.selectedRole.practice.id}/marketing/banners/upcoming-banners`)
        let params = {
            "package_version": "1",
            "app_version": "5.2",
            "banner_type": value === 0 ? 3 : value === 1 ? 2 : 1
        }
        this.props.listPromoBanner(params)
    }
    componentWillMount() {
        let params = {
            "package_version": "1",
            "app_version": "5.2",
            "banner_type": this.state.index === 0 ? 3 : this.state.index === 1 ? 2 : 1
        }
        this.props.listPromoBanner(params)
    }
    componentWillUnmount() {
        this.props.cleanModule();
    }

    edit(banner_type, id) {
        history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners/' + banner_type + '/edit/' + id);
    }
    add(banner_type) {
        history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners/' + banner_type + '/add');
    }
    deleteBanner(banner){
        let that = this;
        let display_text = `Are you sure you want to delete this banner?`;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (willDelete) {
            if (willDelete.isConfirmed) {
                that.props.deleteBanner({
                    "package_version": "1",
                    "app_version": "5.2",
                    "id": banner.id,
                }).then(()=>{
                    that.props.listPromoBanner({
                        "package_version": "1",
                        "app_version": "5.2",
                        "banner_type": that.state.index === 0 ? 3 : that.state.index === 1 ? 2 : 1
                    })
                }).catch((err)=>{console.log(err)});
            }
        }, function (dismiss) {
            console.log('log')
        });
    }
    toggleVisibility(banner) {
        this.props.hidePromoBanner({
            "package_version": "1",
            "app_version": "5.2",
            "id": banner.id,
            "visibility_type": banner.hidden ? 'unhide' : 'hide',
        }).then(() => {
            this.props.listPromoBanner({
                "package_version": "1",
                "app_version": "5.2",
                "banner_type": this.state.index === 0 ? 3 : this.state.index === 1 ? 2 : 1
            })
                .catch((err) => {
                    console.log(err, "err")
                })
        })
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        bannerList: state.promo_banner.bannerReducer.banner_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listPromoBanner,
        hidePromoBanner,
        deleteBanner,
        cleanModule: BannerActionCreator.cleanModule,
    },
        dispatch
    );
}

const PromoBannerConnected = connect(mapStateToProps, mapDispatchToProps)(PromoBanner);
export default PromoBannerConnected;