import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import Dialog from 'react-toolbox/lib/dialog';
export default function () {
    function repeatIntake1(intake, intakeIndex) {
        return React.createElement(Tr, { 'key': 'row' + intakeIndex }, React.createElement(Td, {
            'column': 'name',
            'value': intake.name
        }, React.createElement('span', {}, intake.name)), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, intake.practice.name)), React.createElement(Td, {
            'column': 'published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, intake.published ? React.createElement('span', { 'key': '2790' }, 'Published') : null, !intake.published ? React.createElement('span', { 'key': '2858' }, 'Not Published') : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, intake.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'goal-template-action-container',
            'key': '3066'
        }, React.createElement(Button, {
            'onClick': () => {
                this.editIntake(intake.id);
            },
            'raised': true,
            'ripple': true,
            'className': 'm-r-12 goal-template-action',
            'primary': true
        }, 'Edit'), React.createElement(Button, {
            'raised': true,
            'ripple': true,
            'onClick': () => {
                this.deleteIntake(intake.id);
            },
            'className': 'goal-template-action delete-button'
        }, 'Delete')) : null, intake.practice.id !== this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'goal-template-action-container',
            'key': '3611'
        }, React.createElement(Button, {
            'onClick': () => {
                this.editIntake(intake.id);
            },
            'className': 'm-r-12 goal-template-action',
            'raised': true,
            'ripple': true,
            'primary': true
        }, 'Preview'), React.createElement(Button, {
            'onClick': () => {
                this.cloneIntake(intake.id);
            },
            'className': 'goal-template-action',
            'raised': true,
            'ripple': true,
            'primary': true
        }, 'Clone')) : null)));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'style': { marginTop: '65px' },
            'noDataText': 'Click on + button to add an intake form for your practice.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Author')), React.createElement(Th, {
            'column': 'published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Available for use?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.intake_list, repeatIntake1.bind(this))
    ]), React.createElement(Button, {
        'style': { top: '100px' },
        'className': 'floating-add-button m-r-5',
        'onClick': this.addIntake,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    }));
}