// Action Creators
const fillPractitionerList = (practitioner_list, selection_list, canned_message) => ({
    type: 'PRACTICE_PRACTITIONER_LIST_FILL',
    practitioner_list,
    selection_list,
    canned_message
});
const fillPractitioner = (practitioner) => ({
    type: 'PRACTICE_PRACTITIONER_FILL',
    practitioner,
});
const cleanModule = () => ({
    type: 'PRACTICE_PRACTITIONER_CLEANUP',
});

export default {
    fillPractitionerList,
    cleanModule,
    fillPractitioner,
};
