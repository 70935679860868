import { combineReducers } from 'redux';
import calendarList from './calendar_list';
import calendarEdit from './calendar_edit';
import groupClassReducer from './group_class_reducer';
import facilityReducer from './facility_reducer';

export default combineReducers({
    calendarList,
    calendarEdit,
    groupClassReducer,
    facilityReducer
});