import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actionCreator from '../actions/practice_consultant';

const getConsultantList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/consultant_list";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.fillConsultantList(
                response['consultant_list'],
                response['selection_list'],
                response['canned_message']
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getConsultant = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/consultant_get";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.fillConsultant(
                response['consultant'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postConsultant = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/consultant_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Your dependent partner title & bio have been updated successfully.");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const inviteConsultant = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/consultant_invite";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            if(response.invited){
                Utils.showSuccessAlert("An invitation email has been successfully send to your dependent partner.");
            }else{
                Utils.showInfoAlert("Dependent partner is already part of your practice!");               
            }
            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const toggleHideConsultant = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/consultant_toggle_hide";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            if (params['category'] === 'active' || params['category'] === 'invited') {
                Utils.showSuccessAlert("Dependent partner has been set to inactive state. He will no more be able to access your practice details");
            } else {
                Utils.showSuccessAlert("Dependent partner has been set to active state.");
            }
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const resendConsultantInvitation = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/consultant_resend_invitation";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Email invitation has been resent to the dependent partner");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



export {
    getConsultantList,
    getConsultant,
    postConsultant,
    inviteConsultant,
    toggleHideConsultant,
    resendConsultantInvitation,
};