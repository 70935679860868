import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../../utils/common_components/switch_form';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement('h4', {}, '\n        Services Offered\n    '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', {}, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'title',
        'type': 'text',
        'component': TextInput,
        'label': 'Title'
    }), React.createElement(Field, {
        'id': 'descriptionRichText',
        'name': 'description',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Description'
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Publish',
        'onClick': this.onPublish,
        'className': 'm-r-10',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Save as draft',
        'className': 'bg-accent',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }))))));
}