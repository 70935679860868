import React  from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_calendar_view";


class ClientCalendarView extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        if (_.isEmpty(this.props.event)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}


export default ClientCalendarView;