import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import moment from 'moment';
import { getClientIntakes } from '../../redux/client/network/client_network.js';
import Template from './templates/client_intakes.rt';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import ClientAction from '../../redux/client/actions/client_actions';
import ProgressBar from 'react-toolbox/lib/progress_bar';

class ClientIntakes extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);        
        this.showPreviewIntakeModal = this.showPreviewIntakeModal.bind(this);  
        this.hidePreviewIntakeModal = this.hidePreviewIntakeModal.bind(this);
        this.state = {
            previewIntake: {},
            openPreviewIntakeModal: false,
        };       
    };
        
    componentWillMount() {
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);    
    }

    componentDidMount() {
        this.loadIntakes();
    }

    loadIntakes() {
        this.props.getClientIntakes({});      
    }
        
    showPreviewIntakeModal(intake) {
        this.setState({ previewIntake: intake }, () => {
             this.setState({ openPreviewIntakeModal: true});
        });
    }

    hidePreviewIntakeModal() {
        this.setState({ openPreviewIntakeModal: false, previewIntake: {} }, () => {
            this.loadIntakes();
        });
    }    
    
    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        if(this.props.program !== null) {
            return Template.apply(this);
        } else {
            return (
                <div className="row center-xs m-t-25">
                    <ProgressBar type="circular" mode="indeterminate" multicolor />
                </div>
            );
        }
    }

}

function mapStateToProps(state) {  
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        intake_forms: state.client.clientReducer.intakes_assigned,        
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({        
        getClientIntakes,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
        cleanUp: ClientAction.cleanModule,
        }, 
        dispatch
    );
}

const ClientIntakesConnected = connect(mapStateToProps,mapDispatchToProps)(ClientIntakes);
export default ClientIntakesConnected;
