import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import Button from '../../../../utils/common_components/tooltipButton';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../../utils/common_components/tooltipWrapper';
export default function () {
    function repeatChallenge1(challenge, index) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'challenge' + index
        }, !challenge.is_published ? React.createElement('div', {
            'className': 'bg-warning ribbon f-11',
            'key': '1745'
        }, '\n                DRAFT\n            ') : null, challenge.is_published ? React.createElement('div', {
            'className': 'bg-success ribbon f-11',
            'key': '1867'
        }, '\n                PUBLISHED\n            ') : null, challenge.latest_version.thumbnail_url !== null ? React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(challenge.latest_version.thumbnail_url) + ')' },
            'key': '1992'
        }) : null, challenge.latest_version.thumbnail_url === null ? React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI('') + ')' },
            'key': '2197'
        }) : null, React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-12 f-16 truncate',
            'tooltip': challenge.latest_version.name
        }, '\n                    ', challenge.latest_version.name, '\n                ')), React.createElement('div', { 'className': 'row center-xs m-0' }, challenge.deleted_date === null ? [React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '27251'
            }, React.createElement(IconButton, {
                'tooltip': 'Edit Challenge',
                'icon': 'create',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.editChallenge(challenge);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Delete Challenge',
                'icon': 'delete',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.deleteChallenge(challenge);
                }
            }))] : null));
    }
    return React.createElement('div', { 'className': 'row w-100 m-20' }, this.props.challenge_list.length === 0 ? React.createElement('div', {
        'className': 'col-xs-10 m-30 alert-warning',
        'key': '1253'
    }, '\n        You have not created any challenges so far. Click on Add button to create a new challenge                \n    ') : null, this.props.challenge_list.length > 0 ? React.createElement.apply(this, [
        'div',
        {
            'className': 'row start-xs  m-10',
            'key': '1476'
        },
        _.map(this.props.challenge_list, repeatChallenge1.bind(this))
    ]) : null, React.createElement(Button, {
        'tooltip': 'Create new challenge',
        'className': 'floating-add-button m-r-8',
        'style': { top: '90px' },
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true,
        'onClick': this.addChallenge
    }));
}