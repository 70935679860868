// Action Creators
const nutritionDocumentsFill = (documents) => ({
    type: 'ENGAGEMENT_DOCUMENTS_NUTRITION_FILL',
    documents
});

const guidanceDocumentsFill = (documents) => ({
    type: 'ENGAGEMENT_DOCUMENTS_GUIDANCE_FILL',
    documents
});

const cleanModule = () => ({
    type: 'ENGAGEMENT_DOCUMENTS_CLEANUP',
});

export default {
    nutritionDocumentsFill,
    guidanceDocumentsFill,
    cleanModule,
};
