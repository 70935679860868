import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Select from '../../../utils/common_components/select';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Education Content',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.saveEducationContent) }, React.createElement('div', { 'className': 'row m-25' }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'education_category',
        'name': 'education_category',
        'type': 'text',
        'component': Select,
        'label': 'Education Category',
        'valueKey': 'id',
        'labelKey': 'name',
        'options': this.props.education_categories,
        'clearable': false + '/'
    })))), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'name',
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    })))), !this.state.video_guid ? React.createElement('div', {
        'className': 'col-xs-6 start-xs m-t-10',
        'key': '2858'
    }, React.createElement('div', { 'className': 'row' }, React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed
    }))) : null, this.state.video_guid ? React.createElement('div', {
        'className': 'col-xs-4 m-t-10 box-300-225',
        'key': '3397'
    }, React.createElement(VimeoPlayer, {
        'handleUploadProcessing': true,
        'identifier': 'workfit_video_' + this.state.video_guid,
        'videoList': [this.state.video_guid]
    })) : null, React.createElement('div', { 'className': 'col-xs-12 end-xs m-20' }, React.createElement(Button, {
        'label': 'Save & Publish',
        'className': 'bg-success m-r-10',
        'disabled': this.props.submitting,
        'type': 'submit',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    })))))));
}