import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import Checkbox from 'react-toolbox/lib/checkbox';
import { NavLink } from 'react-router-dom';
import FontIcon from 'react-toolbox/lib/font_icon';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import ViewMessage from '../view_message';
import { Dialog } from 'react-toolbox/lib/dialog';
export default function () {
    function repeatMessage1(message, messageIndex) {
        return React.createElement(Tr, {
            'key': message.id,
            'className': 'pointer'
        }, React.createElement(Td, {
            'column': 'message_select',
            'className': true,
            'style': {
                width: '10px',
                textAlign: 'center'
            }
        }, React.createElement(Checkbox, {
            'className': 'm-b-0',
            'checked': _.includes(this.state.message_selected_state, message.id),
            'onChange': val => {
                this.handleCheckboxChange(message.id, val);
            },
            'label': true
        })), React.createElement(Td, { 'column': 'sender' }, React.createElement('span', {}, !message.read_datetime ? React.createElement('span', {
            'className': 'f-400',
            'key': '3568'
        }, message.sender.name) : null, message.read_datetime ? React.createElement('span', {
            'className': 'f-300',
            'key': '3668'
        }, message.sender.name) : null)), React.createElement(Td, { 'column': 'subject' }, React.createElement('span', {}, !message.read_datetime ? React.createElement('span', {
            'className': 'f-400',
            'key': '3873'
        }, message.subject) : null, message.read_datetime ? React.createElement('span', {
            'className': 'f-300',
            'key': '3969'
        }, message.subject) : null)), React.createElement(Td, { 'column': 'sent_datetime' }, React.createElement('span', {}, !message.read_datetime ? React.createElement('span', {
            'className': 'f-400',
            'key': '4175'
        }, moment(message.sent_datetime).calendar()) : null, message.read_datetime ? React.createElement('span', {
            'className': 'f-300',
            'key': '4296'
        }, moment(message.sent_datetime).calendar()) : null)), React.createElement(Td, {
            'column': 'action',
            'style': { textAlign: 'center' }
        }, React.createElement(Button, {
            'label': 'View',
            'onClick': () => {
                this.messageView(message);
            },
            'className': 'height-fit m-r-5 bg-success small-button',
            'type': 'button',
            'raised': true
        })));
    }
    return React.createElement('div', { 'className': 'row start-xs  m-10' }, React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-5 m-b-10' }, React.createElement(Button, {
        'label': 'Delete',
        'onClick': this.messagesDelete,
        'disabled': !this.state.enableButtons,
        'className': 'm-r-5 bg-danger',
        'type': 'button',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Mark as read',
        'onClick': this.messagesMarkRead,
        'disabled': !this.state.enableButtons,
        'type': 'button',
        'primary': true,
        'raised': true
    })), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable col-xs-12',
            'id': 'table',
            'noDataText': 'No messages have been sent to you',
            'sortable': ['sent_datetime'],
            'isServerPaginate': true,
            'onSort': this.onSort,
            'onPageChange': this.onPageChange,
            'itemsPerPage': this.props.paginate_info.per_page,
            'currentPage': this.props.paginate_info.page,
            'numPages': this.props.paginate_info.total
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'message_select',
            'style': {
                width: '10px',
                textAlign: 'center'
            }
        }, React.createElement(Checkbox, {
            'className': 'm-b-0',
            'checked': this.state.checkboxHeaderState,
            'onChange': val => {
                this.handleCheckboxHeaderChange(val);
            },
            'label': true
        })), React.createElement(Th, {
            'column': 'sender',
            'style': {
                width: '200px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Sender')), React.createElement(Th, { 'column': 'subject' }, React.createElement('strong', {}, 'Subject')), React.createElement(Th, {
            'column': 'sent_datetime',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Date')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '100px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.messages, repeatMessage1.bind(this))
    ]), React.createElement(Dialog, {
        'active': this.state.viewMessage,
        'onEscKeyDown': this.closeViewMessage,
        'onOverlayClick': this.closeViewMessage
    }, React.createElement(ViewMessage, {
        'message': this.state.viewMessage,
        'handleClose': this.closeViewMessage
    })));
}