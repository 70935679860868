let initialState = {
    group_list: [],
    paginate_info: {
        page: 0,
    },
    group: {},
    clients: [],
    group_template_exists:false,
    group_selection_list:[],
    consultant_list:[],
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "GROUP_LIST_FILL":
            if (!action.group_list){
                return state;
            }
            let group_list = [];
            if(action.paginate_info && action.paginate_info.page ===0 ){
                group_list = action.group_list;
            }else{
                let newIds = _.map(action.group_list, (item) => { return item.id; });
                let oldRetain = _.filter(state.group_list, (item) => { return newIds.indexOf(item.id) === -1; });
                group_list = oldRetain.concat(action.group_list);
            }
            return Object.assign({}, state, {
                group_list: group_list,
                paginate_info: _.isEmpty(action.paginate_info) ?  {page: 0}:action.paginate_info,
                group_template_exists:action.group_template_exists
            });
        case "GROUP_FILTER_LIST_FILL":
            if (!action.group_selection_list || !action.consultant_list){
                return state;
            }
            return Object.assign({}, state, {
                group_selection_list: action.group_selection_list,
                consultant_list: action.consultant_list,
            });
        case "GROUP_GET_FILL":
            if (!action.group){
                return state;
            }
            let guid = "";
            let thumbnail_url = "";
            if(action.group && Object.keys(action.group).length) {
                if(action.group.group_media) {
                    if(action.group.group_media.video_url){
                        guid = action.group.group_media.url;
                        thumbnail_url = action.group.group_media.thumbnail_url;
                    }
                }
            }
            return Object.assign({}, state, {
                group: { 
                    ...action.group,
                    video_thumbnail_url : thumbnail_url,
                    video_guid : guid,
                }
            });
        case "GROUP_AVAILABLE_CLIENTS_FILL":
            if (!action.clients){
                return state;
            }
            return Object.assign({}, state, {
                clients: action.clients,
            });
        case "GROUP_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
