import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/workfit_detail_report.rt";
import store,{history} from '../../../redux/store';
import { verifyToken } from '../../../redux/auth/network/auth_network';
import Utils from '../../../utils/utils';
import BaseComponent from '../../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../../redux/core/actions/menu_state_creator';
import { getWorkfitPracticeList, generateWorkfitUsageReport } from '../../../redux/dhf/workfit/network/workfit_network';
import { formValueSelector, reduxForm } from 'redux-form';

class WorkfitDetailReport extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.generateReport = this.generateReport.bind(this);
        this.state = {
            donwload_report: null
        };
    }

    componentWillMount() {        
    }

    generateReport(data) {
        this.setState({
            download_report: null
        });
        let params = {};
        params["start_date"] = Utils.formatServerDate(data["start_date"]);
        params["end_date"] = Utils.formatServerDate(data["end_date"]);
        this.props.generateWorkfitUsageReport(params).then((response) => {
            Utils.showSuccessAlert("Click on Download Report button to view the report");
            this.setState({
                download_report: response["file_url"]
            });
        }).catch(() => { });;
    }
    
    render() {
        if (!this.props.isAuthenticated || _.isEmpty(this.props.selectedRole)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar type='circular' mode='indeterminate' multicolor />
            </div>);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            verifyToken,
            updateMenuOpen: MenuAction.updateMenuOpen,
            getWorkfitPracticeList,
            generateWorkfitUsageReport,
        },
        dispatch
    );
}
function mapStateToProps(state, ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen: state.core.appMenu.menu_open,
        pinned: state.core.appMenu.pinned,
        practice_list: state.workfit.workfitReducer.workfit_practice_list,
    };
}

const validate = (values, props) => {
    let requiredFields = ['workfit_practice','start_date','end_date'];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const selector = formValueSelector('WorkfitReportForm');

const WorkfitDetailReportForm = reduxForm({
    form: 'WorkfitReportForm',
    enableReinitialize: true,
    validate
})(WorkfitDetailReport);

const WorkfitDetailReportFormConnected = connect(mapStateToProps, mapDispatchToProps)(WorkfitDetailReportForm);
export default WorkfitDetailReportFormConnected;
