import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import Sortable from 'react-anything-sortable';
import SortableItem from '../../../utils/common_components/sortable_item';
import { WORKOUT_TARGET_GROUP } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import GoalEdit from '../goal_edit';
import ProgramEdit from '../../../program_new/components/program_edit_new';
import ProgramList from '../../../program_new/components/program_list_new';
import HealthDocumentList from '../../../documents_new/components/document_list_new';
import Chip from 'react-toolbox/lib/chip';
import HabitList from '../treatment_template_habit_list';
import HabitEdit from '../treatment_template_habit_edit';
export default function () {
    function repeatGoal_question1(goal_question, index) {
        return React.createElement('div', {
            'className': 'm-b-2 col-xs-12',
            'key': 'goal_question' + index
        }, React.createElement('div', { 'className': 'row box-shadow bg-white  f-16 p-10' }, React.createElement('div', { 'className': 'col-xs-10' }, '\n                                    ', index + 1, ': ', goal_question.question, ' \n                                '), React.createElement('div', { 'className': 'col-xs-2' }, '\n                                    Metric Unit: ', goal_question.metric_unit.unit_name, '\n                                ')));
    }
    function repeatGoal2(goal, goalIndex) {
        return React.createElement('tr', { 'key': goal.id }, React.createElement('td', {}, goal.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.assignGoal(goal);
            },
            'tooltip': 'Assign goal for this  template',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    function repeatWorkout3(workout, workoutIndex) {
        return React.createElement(SortableItem, {
            'className': 'w-100 d-flex  align-center border-dark no-box-shadow bg-white p-t-8 p-b-8 p-l-18 p-r-23 m-l-10 m-r-10 m-t-12 ribbon-container',
            'key': 'workout' + workout.id,
            'sortData': workout
        }, React.createElement('div', {}, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
            'icon': 'reorder',
            'style': { cursor: 'move' },
            'tooltip': 'Re-Order Workout',
            'className': 'm-r-5 c-black small-icon-button handle',
            'key': '11877'
        }) : null), React.createElement('div', {
            'className': 'thumbnail box-77-48',
            'style': { backgroundImage: 'url(' + encodeURI(workout.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'w-70 row bg-white d-flex flex-column m-0 m-t-8 p-l-5 m-r-5',
            'style': { width: '68%' }
        }, React.createElement(TooltipWrapper, { 'className': 'col-xs-' + (workout.description ? '9' : '12') + ' f-16 truncate' }, '\n                                        ', workout.name, ' \n                                    '), React.createElement('div', { 'className': 'width-fit badge-blue c-white p-t-2 p-b-2 p-l-4 p-r-4 f-10 f-300 m-b-2' }, '\n                                        Gray Institute\n                                    ')), workout.description ? React.createElement('div', {
            'className': 'w-10',
            'key': '12998'
        }, workout.description ? React.createElement(IconButton, {
            'className': 'col-xs-4 c-grey center-xs',
            'tooltip': workout.description,
            'icon': 'info',
            'key': '13081'
        }) : null) : null, React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'goal-template-action-container d-flex col-xs-12 p-2 m-0' }, this.props.treatment_template.owner !== this.props.selectedRole.practice.id ? React.createElement(IconButton, {
            'tooltip': 'View Workout',
            'className': 'width-fit goal-template-action m-r-5 c-grey p-r-10 p-l-10',
            'onClick': () => {
                this.editWorkout(workout);
            },
            'key': '13451'
        }, 'View ') : null, this.props.treatment_template.owner == this.props.selectedRole.practice.id ? React.createElement(IconButton, {
            'icon': 'edit',
            'tooltip': 'Edit Workout',
            'className': 'm-r-5 c-grey small-icon-button',
            'onClick': () => {
                this.editWorkout(workout);
            },
            'key': '13784'
        }) : null, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
            'icon': 'delete-forever',
            'tooltip': 'Delete Workout',
            'className': 'm-r-5 c-red small-icon-button',
            'onClick': () => {
                this.deleteWorkout(workout);
            },
            'key': '14086'
        }) : null)));
    }
    function repeatAttachment4(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument5(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment4.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document',
            'className': 'm-l-5 box-shadow-none c-red',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'key': '18490'
        }) : null)));
    }
    function repeatAttachment6(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument7(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment6.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document to this engagement',
            'className': 'm-l-5 c-red micro',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'key': '21972'
        }) : null)));
    }
    function repeatHabit_template8(habit_template, habit_templateIndex) {
        return React.createElement('tr', { 'key': habit_template.id }, React.createElement('td', {}, habit_template.name), React.createElement('td', {}, habit_template.text), React.createElement('td', { 'className': 'w-20 text-right' }, React.createElement(Button, {
            'onClick': () => {
                this.createHabitFromHabitTemplate(habit_template);
            },
            'tooltip': 'Add habit for this Game Plan',
            'className': 'p-0 height-fit text-transform-none bg-success',
            'mini': true
        }, 'Add')));
    }
    return React.createElement('div', { 'className': 'row p-15 m-b-25 game-plan-dashboard' }, React.createElement('div', { 'className': 'col-xs-12' }), React.createElement(Tabs, {
        'hideMode': 'display',
        'inverse': true,
        'fixed': true,
        'className': 'custom-tab accent col-xs-12',
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'hidden': this.props.params.treatment_type === 'gameplan',
        'label': 'Goal',
        'icon': 'gm-goal',
        'gomotive': true
    }, this.state.topPanelVisible ? [
        !this.props.treatment_template.goal ? React.createElement('div', {
            'className': 'row middle-xs',
            'key': '38171'
        }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'col-xs-10 alert-warning p-l-10',
            'key': '3973'
        }, '\n                        Click on + icon to define a goal and activate this group template.                    \n                    ') : null, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'col-xs-2',
            'key': '4266'
        }, React.createElement(Button, {
            'tooltip': 'Add goal text & metrics',
            'onClick': this.onClickEditGoal,
            'className': 'bg-success pull-right',
            'icon': 'add',
            'floating': true,
            'ripple': true,
            'mini': true
        })) : null) : null,
        this.props.treatment_template.goal ? [
            React.createElement('div', {
                'className': 'row middle-xs p-5 bg-primary',
                'key': '47531'
            }, React.createElement('h4', { 'className': 'col-xs-13' }, '\n                            ', this.props.treatment_template.goal.text, '\n                            ', this.props.treatment_template.goal.goal_questions.length > 0 && this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
                'onClick': this.onClickEditGoal,
                'tooltip': 'Edit goal text & metrics',
                'className': 'c-white',
                'icon': 'create',
                'key': '5017'
            }) : null, this.props.treatment_template.goal.goal_questions.length === 0 && this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
                'onClick': this.onClickEditGoal,
                'tooltip': 'Edit goal text & metrics',
                'label': 'Create Metrics',
                'className': 'bg-warning',
                'raised': true,
                'key': '5453'
            }) : null)),
            React.createElement.apply(this, [
                'div',
                {
                    'className': 'row',
                    'key': '47533'
                },
                _.map(this.props.treatment_template.goal.goal_questions, repeatGoal_question1.bind(this))
            ])
        ] : null,
        ,
        React.createElement(Dialog, {
            'active': this.state.goalChooserVisible,
            'title': 'Please select a goal from your library or create a custom goal',
            'onEscKeyDown': this.hideGoalChooser,
            'onOverlayClick': this.hideGoalChooser,
            'key': '38177'
        }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable no-box-shadow' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
            'tbody',
            {},
            _.map(this.props.goal_list, repeatGoal2.bind(this))
        ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
            'label': 'Add Custom Goal',
            'onClick': this.editGoal,
            'tooltip': 'Click this button if you want to add a custom goal for this  template',
            'className': 'bg-success m-r-10',
            'raised': true
        }), React.createElement(Button, {
            'label': 'Close!',
            'tooltip': 'Close dialog box',
            'accent': true,
            'raised': true,
            'onClick': this.hideGoalChooser
        }))))
    ] : null, !this.state.topPanelVisible ? [this.state.showGoalEditForm ? React.createElement(GoalEdit, {
            'treatment_template_id': this.props.params.treatment_template_id,
            'goal': this.props.treatment_template.goal,
            'finishEditing': this.finishEditingGoal,
            'deleteGoal': this.deleteGoal,
            'key': '88371'
        }) : null] : null), React.createElement(Tab, {
        'gomotive': true,
        'disabled': this.props.params.treatment_type === 'group' && !this.props.treatment_template.goal
    }, this.state.topPanelVisible ? React.createElement('div', {
        'style': { marginTop: '-17px' },
        'key': '9465'
    }, React.createElement('ul', { 'className': 'tab-bar grey-tab  clear-row' }, React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 0 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(0);
        }
    }, '\n                            Workouts\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 1 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(1);
        }
    }, '\n                            Habits\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 2 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(2);
        }
    }, '\n                            Nutrition\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 3 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(3);
        }
    }, '\n                            Guidance\n                        '))), this.state.treatmentTabIndex === 0 && !this.state.showTemplateSelection ? React.createElement('div', {
        'className': 'row start-xs  m-b-10',
        'key': '10770'
    }, this.props.workouts.length === 0 && !this.state.showWorkoutEditForm ? React.createElement('div', {
        'className': 'col-xs-9 alert-warning m-t-10 m-b-10',
        'key': '10904'
    }, '\n                        Click on Add from template button to create a workout.\n                    ') : null, !this.state.showWorkoutEditForm && !this.state.showTemplateSelection ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '11255'
    }, React.createElement.apply(this, [
        Sortable,
        {
            'className': 'vertical-container',
            'sortHandle': 'handle',
            'onSort': this.handleWorkoutSort,
            'containment': true,
            'dynamic': true
        },
        _.map(this.props.workouts, repeatWorkout3.bind(this))
    ])) : null, !this.state.showTemplateSelection && !this.state.showWorkoutEditForm ? React.createElement('div', {
        'className': 'w-100 center-xs m-t-10 pull-right',
        'key': '14613'
    }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'onClick': this.addWorkout,
        'className': 'height-fit f-14 text-transform-none bg-success-red c-white m-b-10 m-r-12',
        'mini': true,
        'key': '14762'
    }, '\n                            Add from template\n                        ') : null) : null, this.state.showWorkoutEditForm ? React.createElement('div', {
        'className': 'w-100 d-flex flex-sb',
        'key': '15459'
    }, React.createElement('div', { 'className': 'm-10 c-grey f-18 page-title' }, 'Edit Workout'), React.createElement(Button, {
        'onClick': this.onCancel,
        'label': 'Cancel',
        'className': 'height-fit m-t-10 bg-success-border',
        'raised': true
    })) : null) : null, this.state.treatmentTabIndex === 1 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '15812'
    }, React.createElement(HabitList, {
        'habitSpaceAllocated': this.state.habitSpaceAllocated,
        'editHabit': this.editHabit
    })) : null, this.state.treatmentTabIndex === 2 ? React.createElement('div', {
        'className': 'row start-xs treatment-template-nutrition m-b-20',
        'key': '16046'
    }, React.createElement('div', { 'className': 'w-100 row m-t-10 bg-white no-box-shadow' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a nutrition plan.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('span', { 'className': 'table-heading' }, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.nutritionDocuments, repeatDocument5.bind(this))
    ]), React.createElement('div', { 'className': 'w-100 d-flex    center-xs' }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('nutrition'),
        'tooltip': 'Add nutrition document',
        'className': 'center-xs height-fit text-transform-none bg-success-border m-b-10',
        'mini': true,
        'key': '19066'
    }, '\n                                Add another\n                            ') : null))) : null, this.state.treatmentTabIndex === 3 ? React.createElement('div', {
        'className': 'row start-xs treatment-template-guidance m-b-20',
        'key': '19522'
    }, React.createElement('div', { 'className': 'w-100 row m-t-10 bg-white no-box-shadow' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a guidance document .',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('span', { 'className': 'table-heading' }, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.guidanceDocuments, repeatDocument7.bind(this))
    ]), React.createElement('div', { 'className': 'w-100 d-flex center-xs' }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('guidance'),
        'tooltip': 'Add guidance document',
        'className': 'center-xs height-fit text-transform-none bg-success-border m-b-10',
        'mini': true,
        'key': '22553'
    }, '\n                                    Add another\n                            ') : null))) : null) : null, this.state.topPanelVisible ? React.createElement('div', { 'key': '23026' }, React.createElement('div', { 'className': 'col-xs-12' }, this.state.showWorkoutEditForm ? React.createElement(ProgramEdit, _.assign({}, {
        'listWorkouts': this.props.listWorkouts,
        'editWorkoutToggle': this.editWorkoutToggle,
        'showWorkoutEditForm': this.state.showWorkoutEditForm,
        'finishEditing': this.finishEditingWorkout,
        'usedInside': true,
        'treatment_template_id': this.props.treatment_template_id || this.props.params.treatment_template_id,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'program_id': this.state.editingWorkoutId,
        'selection_treatment_template_id': this.props.treatment_template_id || this.props.params.treatment_template_id,
        'disableEditing': false,
        'isNonSyncGroup': this.props.treatment_template.treatment_template_cycle == 2,
        'gamePlanSelection': true,
        'from_gameplan': 'true',
        'key': '23127'
    }, this.state.workoutProgramTypeParams)) : null, this.state.showTemplateSelection ? React.createElement(ProgramList, _.assign({}, {
        'usedInside': true,
        'cancelTemplateSelection': this.unAllocateSpace,
        'user_subscription_active': this.state.user_subscription_active,
        'isTemplatesPurchased': this.state.isTemplatesPurchased,
        'listWorkouts': this.props.listWorkouts,
        'selectionWorkoutToggle': this.selectionWorkoutToggle,
        'treatment_template_id': this.props.treatment_template_id || this.props.params.treatment_template_id,
        'mode': TEMPLATE_SELECTION_MODE,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'afterCreateWorkoutFromProgram': this.afterCreateWorkoutFromTemplate,
        'key': '24009'
    }, this.state.templateProgramTypeParams)) : null, this.state.habitSpaceAllocated ? React.createElement(HabitEdit, {
        'onHabitCancel': this.onHabitCancel,
        'finishEditing': this.finishEditingHabit,
        'treatment_template': this.props.treatment_template,
        'habitId': this.state.editingHabitId,
        'key': '24797'
    }) : null)) : null)), React.createElement(Dialog, {
        'active': this.state.documentSelectionDialogVisible,
        'className': 'full-dialog',
        'title': 'Click \'+\' to assign',
        'onEscKeyDown': () => this.toggleDocumentSelectionDialog(),
        'onOverlayClick': () => this.toggleDocumentSelectionDialog()
    }, this.state.documentSelectionDialogVisible ? React.createElement(HealthDocumentList, {
        'store': this.props.store,
        'selectionMode': true,
        'match': {
            params: {
                document_type: this.state.healthDocumentType,
                id: this.props.params.treatment_template_id
            }
        },
        'onSelected': this.assignHealthDocument,
        'key': '25420'
    }) : null), React.createElement(Dialog, {
        'active': this.state.openHabitTemplateModal,
        'title': 'Please select a habit from your library',
        'onEscKeyDown': this.hideHabitTemplateModal,
        'onOverlayClick': this.hideHabitTemplateModal
    }, this.state.openHabitTemplateModal ? React.createElement('div', {
        'className': 'row',
        'key': '26018'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', {}, 'Description'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.habit_template_list, repeatHabit_template8.bind(this))
    ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Close',
        'tooltip': 'Close dialog box',
        'className': 'height-fit text-transform-none bg-success-border',
        'raised': true,
        'onClick': this.hideHabitTemplateModal
    }))) : null));
}