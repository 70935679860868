import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/conditions_treated.rt";
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { getConditionsTreatedList } from '../../../redux/admin/conditions_treated/network/conditions_treated_network';

class ConditionsTreated extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.addConditionTreated = this.addConditionTreated.bind(this);
        this.editConditionTreated = this.editConditionTreated.bind(this);
    };
    
    componentWillMount() {
        this.props.getConditionsTreatedList();
    }

    componentWillUnmount() {
    }

    addConditionTreated() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/branding/conditions_treated/add');
    }

    editConditionTreated(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/branding/conditions_treated/edit/'+id);
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        conditions_treated: state.admin.conditionsTreatedReducer.conditions_treated_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getConditionsTreatedList,
        }, 
        dispatch
    );
}

const ConditionsTreatedConnected = connect(mapStateToProps,mapDispatchToProps)(ConditionsTreated);
export default ConditionsTreatedConnected;