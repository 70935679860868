import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import Select from '../../../utils/common_components/select';
import { FieldArray } from 'redux-form';
import RadioInput from '../../../utils/common_components/radio_group_form';
import Checkbox from '../../../utils/common_components/checkbox_form';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'text-left' }, React.createElement(Button, {
        'className': 'm-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.cancel
    }, '\n        Back\n    ')), React.createElement(Card, { 'className': 'no-box-shadow m-t-10 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', { 'className': 'add-user d-flex no-box-shadow' }, React.createElement('div', { 'className': 'add-info w-76 m-r-16' }, React.createElement('div', { 'className': 'w-100 box-shadow bg-white m-b-16 p-24' }, React.createElement('div', { 'className': true }, React.createElement(CardTitle, {
        'title': (this.props.match.params.content_id ? 'Edit' : 'Add New') + ' User',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement('div', { 'className': 'w-100 d-flex m-r-16 m-t-10 m-b-24' }, React.createElement('div', { 'className': 'w-50 m-r-16 border-around' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20 p-r-9',
        'name': 'user_type',
        'component': Select,
        'label': 'User Type',
        'options': this.props.userType,
        'clearable': false
    })), React.createElement('div', { 'className': 'w-50' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'email',
        'type': 'text',
        'component': TextInput,
        'hint': 'Email'
    }))), React.createElement('div', { 'className': 'd-flex' }, React.createElement('div', { 'className': 'w-50 m-r-16' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'first_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'First Name'
    })), React.createElement('div', { 'className': 'w-50 m-r-16' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'last_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'Last Name'
    }))), React.createElement('div', { 'className': 'c-grey f-16 m-t-24 m-b-36' }, 'User will be asked to set a password when they first log in.'))), React.createElement('div', { 'className': 'bg-white box-shadow p-t-15 p-l-24 p-r-24 p-b-36' }, React.createElement(CardTitle, {
        'title': 'Onboarding',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement('div', { 'className': 'w-50' }, React.createElement(Field, {
        'component': Select,
        'name': 'role',
        'label': 'Are you a',
        'options': this.props.select_options
    })), React.createElement('div', { 'className': 'certifications' }, React.createElement('p', {
        'style': { textAlign: 'left' },
        'className': 'f-16 m-b-24'
    }, 'Certifications/Specializations from Gray Institute'), React.createElement('div', { 'className': 'w-50 m-b-23 border-around' }, React.createElement(Field, {
        'component': TextInput,
        'name': 'role',
        'label': 'Certifications',
        'options': this.props.select_options
    })), React.createElement('div', { 'className': 'w-50 m-b-23 border-around' }, React.createElement(Field, {
        'component': TextInput,
        'name': 'role',
        'label': 'Specializations',
        'options': this.props.select_options
    })), React.createElement('div', { 'className': 'w-50 border-around' }, React.createElement(Field, {
        'component': TextInput,
        'name': 'role',
        'label': 'GIFT Mentorship',
        'options': this.props.select_options
    }))), React.createElement('div', { 'className': 'areas-interests w-50' }, React.createElement('p', {
        'style': { textAlign: 'left' },
        'className': 'f-16 m-b-18'
    }, 'Your areas of interests'), React.createElement('div', { 'style': { textAlign: 'left' } }, React.createElement(Field, {
        'name': 'injuryPrevention',
        'component': Checkbox,
        'label': 'Injury Prevention'
    }), React.createElement(Field, {
        'name': 'reconditiongFromInjury',
        'component': Checkbox,
        'label': 'Reconditiong From Injury'
    }), React.createElement(Field, {
        'name': 'performanceTraining',
        'component': Checkbox,
        'label': 'Performance Training'
    }), React.createElement(Field, {
        'name': 'healthAndWellness',
        'component': Checkbox,
        'label': 'Health & Wellness'
    }), React.createElement(Field, {
        'name': 'show_on_coach_locator',
        'component': Checkbox,
        'label': 'Show me on Coach Locator to be discovered by new clients'
    }))), React.createElement('div', { 'className': 'location m-t-30' }, React.createElement('p', {
        'style': { textAlign: 'left' },
        'className': 'f-16 m-b-24'
    }, 'Location'), React.createElement('div', { 'className': 'w-50 m-b-23 border-around' }, React.createElement(Field, {
        'component': TextInput,
        'name': 'role',
        'label': 'Country',
        'options': this.props.select_options
    })), React.createElement('div', { 'className': 'w-50 m-b-23 border-around' }, React.createElement(Field, {
        'component': TextInput,
        'name': 'role',
        'label': 'State',
        'options': this.props.select_options
    })), React.createElement('div', { 'className': 'w-50 border-around' }, React.createElement(Field, {
        'component': TextInput,
        'name': 'role',
        'label': 'Zip Code',
        'options': this.props.select_options
    }))))), React.createElement('div', { 'className': 'publish-info' }, React.createElement('div', { 'className': 'm-t-10' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-10' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-30 m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Add User',
        'onClick': this.onPublish,
        'className': 'm-l-10 bg-success w-100',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true
    })))))))));
}