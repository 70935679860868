import { combineReducers } from 'redux';
import auth from './auth/reducers/index';
import core from './core/reducers/index';
import intake from './intake/reducers/index';
import conversation from './conversation/reducers/index';
import health_document from './documents/reducers/index';
import notificationReducer from './notifications/reducers/notification_reducer';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import practice from './practice/reducers/index';
import message from './message/reducers/index';
import exercise from './exercise/reducers/index';

import content from './content/reducers/index';
import courses from './courses/reducers/index';
import engagement from './engagement/reducers/index';
import admin from './admin/reducers/index';
import program from './program/reducers/index';
import buildmypractice from './buildmypractice/reducers/index';
import goal from './goal/reducers/index';
import group from './group/reducers/index';
import treatment_template from './treatment_template/reducers/index';
import news from './news/reducers/index';
import payment from './payment/reducers/index';
import partner from './partner/reducers/index';
import user from './user/reducers/index';
import calendar from './calendar/reducers/index';
import client from './client/reducers/index';
import practitioner from './practitioner/reducers/index';
import habit from './habit/reducers/index';
import mat from './mat/reducers/index';
import workfit from './dhf/workfit/reducers/index';
import move from './dhf/move/reducers/index';
import education from './education/reducers/index';
import dhfassess from './dhf/assess/reducers/index';
import users from './users/reducers/index';
import subscriptionLog from './subscription/reducers/index';
import promo_banner from './promo_banners/reducers/index'
import dashboard from './dashboard/reducers/courses'
import { connectRouter } from 'connected-react-router';

const scReducers = {
    auth,
    core,
    dashboard,
    practice,
    intake,
    message,
    health_document,
    conversation,
    exercise,
    subscriptionLog,
    users,
    content,
    courses,
    engagement,
    admin,
    program,
    buildmypractice,
    goal,
    group,
    news,
    payment,
    partner,
    user,
    calendar,
    client,
    practitioner,
    habit,
    treatment_template,
    mat,
    workfit,
    move,
    education,
    dhfassess,
    promo_banner,
};

const createRootReducer = (history) => combineReducers({
    ...scReducers,
    notificationReducer,
    form: formReducer,
    router: connectRouter(history),
  })
  
let appReducer = (history) => combineReducers({ 
    ...scReducers,
    notificationReducer,
    form: formReducer,
    routing: routerReducer 
});

const initialState = appReducer({}, {});
const rootReducer = (state, action) => {
    if (action.type === 'AUTH_USER_LOGOUT') {
      state = initialState;
    }
    return appReducer(state, action);
  };

  export { createRootReducer}
export default rootReducer;
