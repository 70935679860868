import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ExerciseList from '../../../exercise/components/exercise_list';
import PanelGroup from '../../../vendor/react-panelgroup/panelgroup';
import { FieldArray } from 'redux-form';
import MultiDatePicker from '../../../utils/common_components/multi_date_picker';
import Chip from 'react-toolbox/lib/chip';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { PARTNER } from '../../../core/constants/constants';
export default function () {
    function repeatDay1(day, index) {
        return React.createElement(Chip, {
            'className': 'bg-success-red c-white chip-thin',
            'key': 'days' + index
        }, '\n                                    ', day, '\n                                ');
    }
    return React.createElement('div', { 'className': 'row' }, this.state.datePickerVisible ? React.createElement(MultiDatePicker, {
        'active': this.state.datePickerVisible,
        'enabledDays': this.props.progression.available_dates,
        'selectedDays': this.props.selected_dates,
        'onCancel': () => this.showDatePicker(false),
        'disableSelection': this.props.disableEditing,
        'onAccept': selectedDays => this.finishDatesSelection(selectedDays),
        'key': '1820'
    }) : null, React.createElement(PanelGroup, {
        'borderColor': '#eee',
        'panelWidths': [
            {
                size: '40%',
                resize: 'stretch',
                minSize: '10%'
            },
            {
                size: '60%',
                resize: 'stretch',
                minSize: '10%'
            }
        ]
    }, React.createElement('div', { 'className': 'col-xs-12  bg-white p-20' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), this.props.program.schedule_type !== 2 ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '2899'
    }, this.props.programType !== 'workout' ? React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'days',
        'component': Select,
        'label': 'Days',
        'options': this.props.supportingData.days,
        'multi': true,
        'key': '2994'
    }) : null, this.props.programType === 'workout' && this.props.days_display ? React.createElement('div', {
        'className': 'row bg-accent middle-xs',
        'key': '3230'
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-11 start-xs m-t-6 m-b-5' },
        _.map(this.props.days_display.slice(0, 10), repeatDay1.bind(this)),
        this.props.days_display.length > 10 ? React.createElement(TooltipWrapper, {
            'className': 'di-block',
            'tooltip': 'Click calendar icon to view all workout dates',
            'key': '3687'
        }, React.createElement(Chip, { 'className': 'bg-success-red c-white chip-thin' }, '\n                                        more...\n                                    ')) : null
    ]), this.props.days_display.length === 0 ? React.createElement('div', {
        'className': 'col-xs-11 start-xs m-t-6 m-b-5 middle-xs',
        'key': '4115'
    }, '\n                                Click on calendar icon to choose dates\n                             ') : null, React.createElement('div', { 'className': 'col-xs-1 end-xs' }, React.createElement(IconButton, {
        'tooltip': 'Click to choose dates',
        'icon': 'event',
        'className': 'c-white',
        'onClick': () => this.showDatePicker(true)
    }))) : null) : null, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Workout duration (in minutes) *'
    })), PARTNER.show_movement_graph === true ? React.createElement('div', {
        'style': { marginLeft: '75px' },
        'key': '4965'
    }, React.createElement('label', { 'className': 'f-12 c-primary' }, 'Contribution to Weekly Movement Goals'), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'mobility_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Mobility (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'strength_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Strength (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'metabolic_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Metabolic (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'power_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Power (In minutes)'
    }))) : null, React.createElement('div', { 'className': 'col-xs-12 f-18 start-xs' }, '\n                        Selected Exercises\n                    '), _.isEmpty(this.props.exercises) ? React.createElement('div', {
        'className': 'col bg-danger f-16 start-xs m-t-10 p-5 m-l-5',
        'key': '6546'
    }, '\n                        Click \'Apply filters\' on right to find and select exercises\n                    ') : null, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row m-r-0' }, React.createElement(FieldArray, {
        'name': 'exercises',
        'component': this.renderExercises
    }))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': this.props.disableEditing ? 'Close' : 'Cancel',
        'className': 'm-r-5',
        'accent': true,
        'raised': true,
        'onClick': this.cancel
    }), !this.props.disableEditing ? React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.submitting,
        'onClick': this.submitForm,
        'className': 'm-l-5 bg-success-red c-white m-r-5',
        'raised': true,
        'key': '7227'
    }) : null)))), React.createElement('div', { 'className': 'col-xs-12 p-l-10' }, React.createElement(ExerciseList, {
        'exerciseSelectionMode': true,
        'onExerciseSelection': this.addExercise
    }))));
}