import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import { NavLink } from 'react-router-dom';
export default function () {
    function repeatCourse1(course, index) {
        return React.createElement('div', {
            'className': 'dashboard-banner',
            'style': {
                padding: '0',
                position: 'relative'
            },
            'key': 'course' + index
        }, React.createElement('div', {
            'className': course.blockClass,
            'style': {
                padding: '24px 18px',
                borderRadius: '8px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '179px'
            }
        }, React.createElement('p', { 'className': 'title' }, course.title), React.createElement('p', { 'className': 'subtext' }, course.subtext), React.createElement('div', {
            'className': 'courses-btns m-t-24 align-center d-flex flex-sb',
            'style': {
                position: 'absolute',
                bottom: '0',
                width: '90%'
            }
        }, course.category !== 'GIFT' ? React.createElement('a', {
            'target': 'blank',
            'className': 'launch-btn width-fit',
            'href': course.inspire ? course.inspire.educationIdUrl : course.link,
            'key': '1040'
        }, course.inspire ? 'Launch' : 'View', ' Course') : null, this.props.user.is_lms_active ? React.createElement('div', { 'key': '1264' }, course.category === 'GIFT' ? React.createElement('a', {
            'target': '_self',
            'className': 'launch-btn width-fit',
            'href': true,
            'onClick': this.redirectToLMS,
            'key': '1336'
        }, 'Launch Course') : null) : null, this.props.user.is_lms_active === false ? React.createElement('div', { 'key': '1530' }, course.category === 'GIFT' ? React.createElement('a', {
            'target': 'blank',
            'className': 'launch-btn width-fit',
            'href': course.link,
            'key': '1612'
        }, 'View Course') : null) : null, React.createElement('div', { 'className': 'd-logo d-logo ' + course.logoClass }))));
    }
    return React.createElement('div', {
        'className': 'dashboard-container',
        'style': {
            width: '95%',
            margin: '0 auto'
        }
    }, React.createElement('div', {}, React.createElement('div', {}, '\n        ', this.renderImage(), '\n        ', React.createElement('h2', { 'className': 'm-b-15 f-24 page-title' }, 'Courses'), this.state.newCoursesArr.length ? React.createElement.apply(this, [
        'div',
        {
            'className': 'course-banners d-flex flex-wrap p-b-30',
            'key': '323'
        },
        _.map(this.state.newCoursesArr, repeatCourse1.bind(this))
    ]) : null, !this.state.newCoursesArr.length ? React.createElement('div', {
        'className': 'm-b-30',
        'key': '1931'
    }, 'Loading...') : null), React.createElement('div', { 'className': 'get-app-banner dashboard-banner' }, React.createElement('p', { 'className': 'f-20 f-500 c-white' }, 'Get The App'), React.createElement('p', { 'className': 'f-14 f-400 m-b-30' }, 'The GI App hosts exclusive webinars, forum discussions, and templated workouts.'), React.createElement('div', { 'className': 'buttons d-flex' }, React.createElement('div', { 'className': 'width-fit m-r-30' }, React.createElement('a', {
        'target': 'blank',
        'href': 'https://play.google.com/store/apps/details?id=com.gomotive.dhf'
    }, React.createElement('div', { 'className': 'dashboard-app-links g-play' }))), React.createElement('div', { 'className': 'width-fit' }, React.createElement('a', {
        'target': 'blank',
        'href': 'https://apps.apple.com/us/app/dhf-coachfit/id1130877074'
    }, React.createElement('div', { 'className': 'dashboard-app-links app-store' }))))), React.createElement('p', { 'className': 'note-text' }, 'Note: Users on the mobile phone browsers kindly use a Desktop computer or a Tablet to build workouts '), React.createElement('div', {}, React.createElement('h2', { 'className': 'm-b-15 f-24 page-title' }, 'Client  Management'), React.createElement('div', { 'className': 'd-flex content-block-container' }, React.createElement('div', { 'className': 'dashboard-banner p-relative invite-client-block' }, window.innerWidth > 768 ? React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/client?invite`,
        'key': '3158'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Invite Clients'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Invite your client to our HomeFit+ App'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null, window.innerWidth < 768 ? React.createElement('div', {
        'onClick': this.openMobilePopup,
        'key': '3640'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Invite Clients'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Invite your client to our HomeFit+ App'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null), React.createElement('div', { 'className': 'dashboard-banner p-relative access-client' }, window.innerWidth > 768 ? React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/client`,
        'key': '4162'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Access Clients'), React.createElement('p', { 'className': 'subtext p-b-5' }, 'Send personal workouts to your client '), React.createElement('p', { 'className': 'p-b-30' }, 'Make sure to check out our help desk below for tutorials'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null, window.innerWidth < 768 ? React.createElement('div', {
        'onClick': this.openMobilePopup,
        'key': '4735'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Access Clients'), React.createElement('p', { 'className': 'subtext p-b-5' }, 'Send personal workouts to your client '), React.createElement('p', { 'className': 'p-b-30' }, 'Make sure to check out our help desk below for tutorials'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null), React.createElement('div', { 'className': 'dashboard-banner p-relative educational-content' }, window.innerWidth > 768 ? React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/content`,
        'key': '5361'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Educational Content'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Send blogs, vlogs, and podcasts to your clients '), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null, window.innerWidth < 768 ? React.createElement('div', {
        'onClick': this.openMobilePopup,
        'key': '5863'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Educational Content'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Send blogs, vlogs, and podcasts to your clients '), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null))), React.createElement('div', {}, React.createElement('h2', { 'className': 'm-b-15 f-24 page-title' }, 'Explore and Build'), React.createElement('div', { 'className': 'd-flex content-block-container' }, React.createElement('div', { 'className': 'dashboard-banner p-relative gi-library' }, window.innerWidth > 768 ? React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/exercise`,
        'key': '6552'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'GI Move Library'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Explore 3DMAPS\xAE, 3DMATRIX, Golf, CAFS, and FSTT libraries'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null, window.innerWidth < 768 ? React.createElement('div', {
        'onClick': this.openMobilePopup,
        'key': '7061'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'GI Move Library'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Explore 3DMAPS\xAE, 3DMATRIX, Golf, CAFS, and FSTT libraries'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null), React.createElement('div', { 'className': 'dashboard-banner p-relative gi-temp' }, window.innerWidth > 768 ? React.createElement('div', {
        'onClick': this.goToTemplates,
        'key': '7598'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'GI Templates'), React.createElement('p', { 'className': 'subtext p-b-5' }, 'Access GI Templates'), React.createElement('p', { 'className': 'p-b-30' }, 'Subscribe within mobile app'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null, window.innerWidth < 768 ? React.createElement('div', {
        'onClick': this.openMobilePopup,
        'key': '8084'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'GI Templates'), React.createElement('p', { 'className': 'subtext p-b-5' }, 'Access GI Templates'), React.createElement('p', { 'className': 'p-b-30' }, 'Subscribe within mobile app'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null), React.createElement('div', { 'className': 'dashboard-banner p-relative your-temp' }, window.innerWidth > 768 ? React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/p/template/list/${ this.state.view }`,
        'key': '8651'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Your Templates'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Build your own workout templates'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null, window.innerWidth < 768 ? React.createElement('div', {
        'onClick': this.openMobilePopup,
        'key': '9160'
    }, React.createElement('p', { 'className': 'title p-b-8' }, 'Your Templates'), React.createElement('p', { 'className': 'subtext p-b-30' }, 'Build your own workout templates'), React.createElement('div', {
        'className': 'p-absolute d-flex flex-right',
        'style': {
            right: '20px',
            bottom: '15px'
        }
    }, React.createElement('div', { 'className': 'arrow' }))) : null)))));
}