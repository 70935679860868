import React from 'react';
import  ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/engagement_goal_edit.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field , FieldArray, propTypes} from 'redux-form';
import { history } from '../../redux/store';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import Select  from '../../utils/common_components/select';
import IconButton from '../../utils/common_components/tooltipIconButton';
import TooltipButton from '../../utils/common_components/tooltipButton';
import Button from 'react-toolbox/lib/button';

import { getGoalTrackingUnitList } from '../../redux/goal/network/goal_network';
import { updateGoalEngagement,getEngagementInstance} from '../../redux/engagement/network/engagement_network';
import goalReducer from '../../redux/goal/reducers/goal_reducer';
import roleReducer from '../../redux/auth/reducers/role_reducer';

class ClientEngagementGoalEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveGoal = this.saveGoal.bind(this);
        this.renderGoalTrackingQuestions = this.renderGoalTrackingQuestions.bind(this);
        this.renderGoals = this.renderGoals.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.goalsFieldsArray = null;
    };

    componentWillMount() {
        this.props.getGoalTrackingUnitList({});
    }    

    
    componentWillUnmount() {
        this.props.cleanUp();
    }


    doCancel() {
        this.props.handleClose();
    }

    scrollToBottom() {
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    }

    saveGoal(data) {     
        let client_goals = _.cloneDeep(data.goals);
        client_goals = _.map(client_goals,(item)=>{
            item.goal_tracking = _.map(item.goal_tracking,(tracking_item)=>{
                tracking_item.track_unit =  (tracking_item.track_unit && tracking_item.track_unit.id) ? tracking_item.track_unit.id : tracking_item.track_unit;
                return tracking_item;
            });
            return item;
        });
        this.props.updateGoalEngagement({ 
            engagement: this.props.engagement.id, 
            client_goals: client_goals,
            package_version: 4 
        }).then((response) => {
            this.props.handleClose(true);
        }).catch((error) => {
            //do nothing;
        });
    }

    renderGoals({ fields, meta }) {
        this.goalsFieldsArray = fields.getAll();
        let noGoalText = null;
        if (this.goalsFieldsArray.length === 0){
            noGoalText =  (
                <div className="col-xs-12 start-xs m-t-10 m-b-10">        
                    <div className="alert-warning p-10">
                        Click on save button to update all your changes.
                    </div>                 
                </div>  
            );           
        }        
        return (
            <div className="row"> 
                {noGoalText}    
                <div className="col-xs-12 m-t-15">                
                {
                    fields.map((item, index) => {
                        return (
                            <div key={index} className="panel m-b-10" >
                                <div className="row panel-heading bg-beige panel-bottom-border p-l-10 p-r-10 p-t-15 p-b-0 m-0 ">    
                                    <div className="col-xs-11">
                                        <Field
                                            label="Goal text"  
                                            style={{width: '100%', height: '42px', marginBottom:'16px'}}  
                                            name={`${item}.text`}
                                            type="text"
                                            component={TextInput}
                                            multiline />
                                    </div>   
                                    <div className="col-xs-1">
                                        {  
                                            !this.goalsFieldsArray[index].is_tracked &&
                                                    <IconButton    
                                                        tooltip="Delete goal and start afresh"    
                                                        icon="delete-forever"
                                                        className="c-danger"
                                                        ripple
                                                        onClick={() => fields.remove(index)} />
                                        }
                                    </div>
                                </div>        
                                <div className="panel-body m-t-0 ">
                                    <FieldArray name={`${item}.goal_tracking`} component={this.renderGoalTrackingQuestions}/>
                                </div>        
                            </div>    
                        );
                    })
                }
                </div>
                <div className="col-xs-12 end-xs" >
                    <Button onClick={this.doCancel} label="Close" className="height-fit text-capital bg-success-border m-r-5"
                        type="button"  raised/>                    
                    <Button label="Save" className="bg-success-red text-capital c-white" disabled={this.props.pristine || this.props.submitting} 
                        type="submit"  raised/>                    
                </div>   
            </div>    
        );
    }
    renderGoalTrackingQuestions({ fields, meta }) {
        let goalsTracking = fields.getAll();
        let noGoalTrackingText = null;
        if (goalsTracking.length === 0){
            noGoalTrackingText =  (
                <div className="col-xs-12 start-xs m-t-10 m-b-10">        
                    <div className="alert-warning p-10">
                        Please create a new quantifiable measure to reach this goal.
                    </div>                 
                </div>  
            );           
        }        
        return (
            <div className="row">
                <h6>Goal Metrics</h6> 
                {noGoalTrackingText}
                <div className="col-xs-12">                
                {
                    fields.map((item, index) => {
                            return (
                                <div key={index} className="row m-t-10 flex-align-center flex-sb">
                                    <div  className="col-xs-1 center-xs">
                                        <div className="round-number di-block"> <span>{index+1}</span></div>        
                                    </div>    
                                    <div className="col-xs-4">
                                        <Field
                                            style={{width: '100%', height: '42px'}}
                                            label="Quantifiable measure to reach this goal"    
                                            name={`${item}.track_question`}
                                            type="text"
                                            component={TextInput}
                                            multiline />
                                    </div>     
                                    <div className="col-xs-2"> 
                                        {                                      
                                            goalsTracking[index].is_tracked &&
                                                <Field
                                                    label="Metric Unit"    
                                                    name={`${item}.track_unit`}
                                                    component={Select}
                                                    options={this.props.goal_tracking_unit}
                                                    valueKey="id" 
                                                    labelKey="unit_name" 
                                                    clearable={false}    
                                                    simpleValue={true} 
                                                    disabled={true}/>
                                        }
                                        {                                      
                                            !goalsTracking[index].is_tracked &&
                                                <Field
                                                    label="Metric Unit"    
                                                    name={`${item}.track_unit`}
                                                    component={Select}
                                                    options={this.props.goal_tracking_unit}
                                                    valueKey="id" 
                                                    labelKey="unit_name" 
                                                    clearable={false}    
                                                    simpleValue={true} />
                                        }                                                
                                    </div>
                                    <div className="w-13">
                                        <Field
                                            label="Baseline Value"    
                                            name={`${item}.initial_value`}
                                            type="number"
                                            data-support-float={true}
                                            component={TextInput}
                                        />
                                    </div>                                     
                                    <div className="w-13">
                                        <Field
                                            label="Target Value"    
                                            name={`${item}.target_value`}
                                            type="number"
                                            data-support-float={true}
                                            component={TextInput}
                                        />
                                    </div>                                     
                                    <div className="col-xs-1"> 
                                    {  
                                        !goalsTracking[index].is_tracked &&
                                                    <IconButton    
                                                        tooltip="Delete metric"    
                                                        icon="delete-forever"
                                                        className="c-danger"
                                                        ripple
                                                        onClick={() => fields.remove(index)} />
                                    }
                                    {                                      
                                        goalsTracking[index].is_tracked &&
                                                    <IconButton  
                                                        tooltip="You cannot delete this metric as client has already tracked."    
                                                        icon="delete-forever"
                                                        className="c-gray"
                                                        ripple
                                                        />
                                    }
                                    {                                      
                                        goalsTracking[index].is_tracked &&
                                                    <IconButton    
                                                        tooltip="Click here for your client to stop tracking this metric."    
                                                        icon="highlight_off"
                                                        className="c-danger"
                                                        ripple
                                                        onClick={() => fields.remove(index)} />
                                    }        
                                    </div>
                                </div>    
                        );
                    })
                }
                </div>
                 <div className="col-xs-12 start-xs">
                    <TooltipButton    
                        tooltip="Add new metric"    
                        icon="add"
                        label="Add"
                        className="bg-success-red text-capital f-14 c-white"
                        raised
                        onClick={() => fields.push({track_question:"",track_unit:1,initial_value:null,target_value:null})}/>       
                </div>                
            </div>    
        );
    }
    
    render() {
        if (this.props.goal_tracking_unit.length === 0 || _.isEmpty(this.props.engagement)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

ClientEngagementGoalEdit.propTypes = {
    engagement:PropTypes.object.isRequired,    
    handleClose: PropTypes.func.isRequired,    
};

function mapStateToProps(state, ownprops) {
    let goals = [];
    let engagement = ownprops.engagement;
    if (engagement && !_.isEmpty(engagement)){
        goals = [...engagement.goals];
    }
    let initialValues = null;
    if (goals.length > 0) {
        initialValues = { goals: goals };
    } else {
        let goal = { text: "", goal_tracking: [{ track_question: "", track_unit: 1,"initial_value":null,"target_value":null }] };
        goals.push(goal);
        initialValues = { goals: goals };
    }
    return {
        handleClose: ownprops.handleClose,
        engagement:engagement,
        goals:goals,
        initialValues: initialValues,
        goal_tracking_unit: state.goal.goalReducer.goal_tracking_unit,
        selectedRole: state.auth.roleReducer.selectedRole,

    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getGoalTrackingUnitList,
            updateGoalEngagement,
            getEngagementInstance,
            cleanUp:GoalActionCreator.goalCleanup //We are cleaning up the tracking unit list
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    const goalArrayErrors = [];
    if(values.goals){
        values.goals.forEach((goal, index) => {
            const goalErrors = {};
            const goalTrackingArrayErrors = [];
            if(!goal.text || goal.text === ""){
                goalErrors.text = "Required";                
            }
            goal.goal_tracking.forEach((goal_track, i) => {
                 const goalTrackErrors = {};
                if(!goal_track.track_question || goal_track.track_question === ""){
                    goalTrackErrors.track_question = "Required";
                }
                if(!goal_track.track_unit || goal_track.track_unit === "" || goal_track.track_unit === null ){
                    goalTrackErrors.track_unit = "Required";
                }
                
                if(!goal_track.initial_value || goal_track.initial_value === "" || goal_track.initial_value === null ){
                    goalTrackErrors.initial_value = "Required";
                }
                if(!goal_track.target_value || goal_track.target_value === "" || goal_track.target_value === null ){
                    goalTrackErrors.target_value = "Required";
                }
                
                 goalTrackingArrayErrors[i] = goalTrackErrors;
            }); 
            goalErrors.goal_tracking = goalTrackingArrayErrors;
            goalArrayErrors[index] = goalErrors;
        });  
    }   
    errors.goals = goalArrayErrors;
    return errors;
};


const ClientEngagementGoalEditForm = reduxForm(
    {
        form: 'clientEngagementGoalEditForm',
        enableReinitialize: true,
        validate
    }
)(ClientEngagementGoalEdit);
const ClientEngagementGoalEditConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagementGoalEditForm);
export default ClientEngagementGoalEditConnected;
