let initialState = {
    program: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PROGRAM_PREVIEW_FILL":
            return Object.assign({}, state, {
                program: action.program,
            });
        case "PROGRAM_PREVIEW_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};