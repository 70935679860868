// Action Creators
const conversationListFill = (conversations) => ({
    type: 'CONVERSATION_LIST_FILL',
    conversations
});

const chatListFill = (conversation,chats,paginate_info) => ({
    type: 'CHATS_LIST_FILL',
    conversation,
    chats,
    paginate_info
});
const chatListAppend = (chat) => ({
    type: 'CHATS_LIST_APPEND',
    chat,
});

const chatListClean = () => ({
    type: 'CHATS_LIST_CLEANUP',
});
const cleanModule = () => ({
    type: 'CONVERSATION_CLEANUP',
});

export default {
    conversationListFill,
    chatListFill,
    cleanModule,
    chatListClean,
    chatListAppend
};
