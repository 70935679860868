import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/mat_edit.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm,formValueSelector } from 'redux-form';
import matAction from '../../redux/mat/actions/mat_creator';
import { Card, CardText } from 'react-toolbox/lib/card';
import swal from 'sweetalert2';
import PropTypes from 'prop-types';

class MatEdit extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.state = {sections:null};
    }


    componentWillReceiveProps (nextProps) {
        if(_.isEqual(this.props.assessment,nextProps.assessment)){
            if(!_.isEqual(this.props.sections,nextProps.sections)){
                this.calculateRisks(nextProps);
            }
        }else{
            this.setState({sections:null});
        }

    }
    
    calculateRisks(props){
        let sections = _.map(props.sections,(section)=>{
            let sectionObject = {...section};
            sectionObject.risk = null;
            sectionObject.riskName = null;
            sectionObject.questions = _.map(sectionObject.questions,(question)=>{
                let questionObject = {...question};
                questionObject.risk = null;
                questionObject.riskName = null;
                if(questionObject.value !== null){
                    if(_.has(questionObject,"calculations")){
                        for(let calculation of questionObject.calculations){
                            if(_.has(calculation,"min_value") && _.has(calculation,"max_value")){
                                if(calculation.max_value >= questionObject.value && questionObject.value  >= calculation.min_value){
                                    questionObject.risk = calculation.id;
                                    questionObject.riskName = calculation.name;
                                    break; 
                                }
                            }else if(_.has(calculation,"min_value")){
                                if(questionObject.value >= calculation.min_value){
                                        questionObject.risk = calculation.id;
                                        questionObject.riskName = calculation.name;
                                        break; 
                                }
                            }else if(_.has(calculation,"max_value")){
                                if(questionObject.value <= calculation.max_value){
                                    questionObject.risk = calculation.id;
                                    questionObject.riskName = calculation.name;
                                    break; 
                                }
                            } 
                        }    
                    }
                }
                return questionObject;
            });
            if(_.has(sectionObject,"calculations") && sectionObject.questions.length >= 2){
                if(sectionObject.questions[0].value !== null && sectionObject.questions[1].value !== null){
                    let diffPercentage = 0;
                    let diffValue = 0;
                    if(sectionObject.questions[0].value === sectionObject.questions[1].value ){
                       diffPercentage = 100;
                       diffValue = 0;
                    }else if(sectionObject.questions[0].value > sectionObject.questions[1].value){
                       diffPercentage = (sectionObject.questions[1].value/sectionObject.questions[0].value) * 100; 
                       diffValue = sectionObject.questions[0].value - sectionObject.questions[1].value;
                    }else{
                       diffPercentage = (sectionObject.questions[0].value/sectionObject.questions[1].value) * 100; 
                       diffValue = sectionObject.questions[1].value - sectionObject.questions[0].value;
                    }
                    diffPercentage = Math.round(diffPercentage);
                    for(let calculation of sectionObject.calculations){
                        let difference = 0;
                        if(calculation.measure === "percent"){
                             difference =  100 - diffPercentage;
                        }else{
                             difference =  diffValue;
                        }
                       if(_.has(calculation,"min_difference") && _.has(calculation,"max_difference")){
                           if(calculation.max_difference >= difference && difference  >= calculation.min_difference){
                               sectionObject.risk = calculation.id;
                               sectionObject.riskName = calculation.name;
                                break; 
                           }
                       }else if(_.has(calculation,"min_difference")){
                            if(difference >= calculation.min_difference){
                               sectionObject.risk = calculation.id;
                               sectionObject.riskName = calculation.name;
                               break; 
                            }
                       }else if(_.has(calculation,"max_difference")){
                            if(difference <= calculation.max_difference){
                               sectionObject.risk = calculation.id;
                               sectionObject.riskName = calculation.name;
                               break; 
                            }
                       } 
                }
            }   
           }
            return sectionObject;
        });
        this.setState({sections:sections});
    }

    doSave(data){
        let assessment = { ...data };        
        assessment.assessed = true;
        this.props.matAssessmentSave(assessment);
        Utils.showInfoAlert(assessment["name"] + " assessment values have been recorded");
    }   
    
    render() {
        if (this.props.assessment === null ||  _.isEmpty(this.props.assessment)) {
            return (
                <Card className="w-100 full-height" >
                    <CardText className="p-t-5">    
                        <div className="row center-xs m-t-25">
                            <h4 className="bg-light-info m-20 p-10 w-100">
                                Please select an assessment from the left section!    
                            </h4> 
                        </div>
                    </CardText>    
                </Card>    
            );
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('matEditForm'); 
function mapStateToProps(state, ownprops) {
    let assessment = { ...ownprops.assessment };
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        assessment:  assessment,
        initialValues: { ...assessment },
        sections: selector(state, 'sections'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        matAssessmentSave: matAction.matAssessmentFill,
    }, dispatch);
}

const validate = (values, props) => {
    const errors = {sections:[]};
    if(!_.has(values,"sections")){
        return errors;
    }
    for (let i = 0; i < values.sections.length; i++) {
        let section = values.sections[i];
        errors["sections"][i] = {questions:[]};
        for (let j = 0; j < section.questions.length; j++) {
            let question = section.questions[j];
            errors["sections"][i]["questions"][j] = {};
            if(question.value != null){
                if(_.has(question,"max_value")){
                    if(question.value > question.max_value){
                        errors["sections"][i]["questions"][j].value = 'Value should be less than or equal to '+ question.max_value;
                    }
                }
                if(_.has(question,"min_value")){
                    if(question.value < question.min_value){
                        errors["sections"][i]["questions"][j].value = 'Value should be more than '+ question.min_value;
                    }
                }
            }
        }
        if(_.has(section,"validations") && section.questions.length >= 2 && (section.questions[0].value != null ||  section.questions[1].value != null)){
            if(_.has(section.validations,"atleast_one_leg")){
                    let left_leg_value = section.questions[0].value || 0;
                    let right_leg_value = section.questions[1].value || 0;
                    if(_.has(section.validations.atleast_one_leg,"max_value") && _.has(section.validations.atleast_one_leg,"min_value")){
                        if(!(section.validations.atleast_one_leg.max_value 
                             >= left_leg_value && left_leg_value  
                             >= section.validations.atleast_one_leg.min_value) 
                             && 
                             !(section.validations.atleast_one_leg.max_value 
                                 >= right_leg_value && right_leg_value 
                                 >= section.validations.atleast_one_leg.min_value)
                         ){
                            errors["sections"][i]["questions"][0].value =  section.validations.atleast_one_leg.min_value + " - "  + section.validations.atleast_one_leg.max_value  + " " + section.questions[0].measure +  " - One of the legs must be in that range";
                            errors["sections"][i]["questions"][1].value =  section.validations.atleast_one_leg.min_value + " - "  + section.validations.atleast_one_leg.max_value  + " " + section.questions[0].measure +  " - One of the legs must be in that range";
                        }
                    }else if(_.has(section.validations.atleast_one_leg,"min_value")){
                        if(!(left_leg_value  
                            >= section.validations.atleast_one_leg.min_value) 
                            && 
                            !(right_leg_value 
                                >= section.validations.atleast_one_leg.min_value)
                        ){
                           errors["sections"][i]["questions"][0].value = "One of the legs must have minimum value of atleast "+section.validations.atleast_one_leg.min_value;
                           errors["sections"][i]["questions"][1].value = "One of the legs must have minimum value of atleast "+section.validations.atleast_one_leg.min_value;
                       }
                    }else if(_.has(section.validations.atleast_one_leg,"max_value")){
                        if(!(section.validations.atleast_one_leg.max_value 
                            >= left_leg_value) 
                            && 
                            !(section.validations.atleast_one_leg.max_value 
                                >= right_leg_value)
                        ){
                           errors["sections"][i]["questions"][0].value = "One of the legs must have value below "+section.validations.atleast_one_leg.max_value;
                           errors["sections"][i]["questions"][1].value = "One of the legs must have value below "+section.validations.atleast_one_leg.max_value;
                       }
                    }
            }
        }
    }
    return errors;
};

MatEdit.propTypes = {
    previewMode: PropTypes.bool,
};
MatEdit.defaultProps = {
    previewMode: false,
};

const MatEditForm = reduxForm(
    {
        form: 'matEditForm',
        enableReinitialize: true,
        validate
    }
)(MatEdit);
const MatEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(MatEditForm);
export default MatEditFormConnected;