import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Tab } from 'react-toolbox';
import { Tabs } from 'react-toolbox';
import ProgramGeneralEdit from '../program_general_edit';
import ProgressionsBuild from '../progressions_build';
import ProgramPreview from '../program_preview';
import ProgressionEdit from '../progression_edit';
import ProgramPublishEdit from '../program_publish_edit';
import { IconButton } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs ' + (this.props.usedInside ? '' : 'm-20') }, React.createElement('div', {
        'className': 'col-xs-12 end-xs c-white',
        'style': { height: '37px' }
    }, React.createElement(TooltipWrapper, {
        'className': 'pull-right',
        'tooltip': 'Close workout'
    }, !this.props.disableEditing ? React.createElement(Button, {
        'className': 'bg-danger c-white',
        'icon': 'clear',
        'floating': true,
        'mini': true,
        'style': { zIndex: '10' },
        'onClick': this.finishEditingMoveBack,
        'key': '1170'
    }) : null)), React.createElement(Card, {
        'className': 'w-100',
        'style': { marginTop: '-40px' }
    }, !this.props.usedInside ? React.createElement(CardTitle, {
        'title': (this.props.match.params.program_id ? 'Edit' : 'Add') + ' ' + this.props.typeUCase,
        'className': 'm-b-10 c-black p-l-20',
        'key': '1465'
    }) : null, React.createElement(CardText, { 'className': 'p-0' }, React.createElement(Tabs, {
        'className': `custom-tab ${ this.state.tabIndex === 1 || this.state.tabIndex === 2 ? 'beige-section' : '' }  `,
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, { 'label': this.props.typeUCase + ' Setup' }, React.createElement(ProgramGeneralEdit, _.assign({}, {
        'isNonSyncGroup': this.props.isNonSyncGroup,
        'moveToBuild': this.moveToBuild,
        'program': this.props.program,
        'getProgram': this.getProgram,
        'postProgram': this.postProgram,
        'disableEditing': this.props.disableEditing,
        'disableProgression': this.disableProgression
    }, this.props))), React.createElement(Tab, {
        'label': 'Build Progressions',
        'className': 'tab-no-padding',
        'disabled': this.state.disableProgression
    }, !this.state.editingProgressionId ? React.createElement(ProgressionsBuild, _.assign({}, {
        'program': this.props.program,
        'getProgram': this.getProgram,
        'postProgression': this.postProgression,
        'updateProgressionDays': this.updateProgressionDays,
        'editProgression': this.editProgression,
        'finishEditingMoveBack': this.finishEditingMoveBack,
        'toggleProgramPublish': this.toggleProgramPublish,
        'disableEditing': this.props.disableEditing,
        'key': '2445'
    }, this.props)) : null, this.state.editingProgressionId ? React.createElement(ProgressionEdit, _.assign({}, {
        'program': this.props.program,
        'getProgression': this.getProgression,
        'moveToBuild': this.moveToBuild,
        'postProgression': this.postProgression,
        'getProgram': this.getProgram,
        'program_id': this.props.program_id,
        'progression_id': this.state.editingProgressionId,
        'disableEditing': this.props.disableEditing,
        'key': '2943'
    }, this.props)) : null)))));
}