import Axios from '../../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../../utils/utils';
import {showProgress, hideProgress} from '../../../core/actions/progress_creator';
import DhfAction from '../actions/assess_creator';

const getDHFAssessmentForm = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/dhf_assess_form_get";
        return Axios.post(url, params).then((response) => {
            dispatch(DhfAction.dhfAssessFormFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const postDHFAssessmentForm = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/dhf_assess_form_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



export {
    getDHFAssessmentForm,
    postDHFAssessmentForm
};