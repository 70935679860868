import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';
import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import bannerAction from '../actions/banner_list';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();

const listPromoBanner = (params) => {
    return (dispatch) => {
        dispatch(bannerAction.bannerFill(
          [],
        ));
        dispatch(showProgress());
        const url =  "/" + API_VERSION + "/" + PACKAGE + "/banner_list";
        return Axios.post(url, params).then((response) => {
            dispatch(bannerAction.bannerFill(
                response.banner_list,
            ));
            dispatch(hideProgress());    
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
  const getPromoBanner= (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = "/" + API_VERSION + "/" + PACKAGE + "/banner_get";
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(bannerAction.getBanner(
              response['banner'],
              response['supporting_data']
            ));
          dispatch(hideProgress());
          return response;
           }) 
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const postPromoBanner= (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = "/" + API_VERSION + "/" + PACKAGE + "/banner_post";
      return Axios.post(url, params)
        .then(async (response) => {

          dispatch(hideProgress());
          if(response.banner.banner_type === 1 ) {
            Utils.showSuccessAlert(`Upcoming Banner Successfully Saved`);
          } else if(response.banner.banner_type === 2 ) {
            Utils.showSuccessAlert(`Feature Banner Successfully Saved`);
          } else {
            Utils.showSuccessAlert(`Promo Banner Successfully Saved`);
          }
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const hidePromoBanner = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = baseURL + "/" + API_VERSION + "/" + PACKAGE + "/banner_toggle_hide";
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          Utils.showSuccessAlert(params.visibility_type==='unhide' ? 'Published Successfully' : 'Unpublished Successfully');
          return response;
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const deleteBanner = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = baseURL + "/" + API_VERSION + "/" + PACKAGE + "/banner_delete";
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          Utils.showSuccessAlert('Upcoming Banner Deleted Successfully');
          return response;
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
export {
    listPromoBanner,
    hidePromoBanner,
    getPromoBanner,
    postPromoBanner,
    deleteBanner
};