// import axios from 'axios';
import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/homefit_constants';

import Utils from '../../../utils/utils';
import {
  showProgress,
  hideProgress
} from '../../core/actions/progress_creator';
import contentListAction from '../actions/homefitContent_list';
import exerciseAction from '../actions/exercise';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const baseURLMobile = '//' + window.location.host.replace('gi','dhf') + '/api/';

const listHomeFitContent = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = baseURL + '/' + API_VERSION + '/' + PACKAGE + '/news_list';
    return Axios.post(url, params).then(async (response) => {
        dispatch(hideProgress());
        dispatch(
          contentListAction.fillContentList(
            response['news_list'],
            response['paginate_info']
          )
        );
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};
const listTrainerContent = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = baseURLMobile + 'm/' + API_VERSION + '/dhfmove/news_list';
    return Axios.post(url, params).then(async (response) => {
        dispatch(hideProgress());
        dispatch(
          contentListAction.fillContentList(
            response['news_list'],
            response['paginate_info']
          )
        );
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};
const getHomeFitContent = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = baseURL + '/' + API_VERSION + '/' + PACKAGE + '/news_get';

    return Axios.post(url, params)
      .then(async (response) => {

        dispatch(hideProgress());
        dispatch(
          contentListAction.fillHomeFitContent(
            response['news'],
            // response['supporting_data']
          )
        );
         }) 
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const postHomeFitContent = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = baseURL + '/' + API_VERSION + '/' + PACKAGE + '/news_post';
    return Axios.post(url, params)
      .then(async (response) => {
  
        dispatch(hideProgress());
        Utils.showSuccessAlert(` ${params.content_type} Successfully Saved`);
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};


const getCategoryHomeFit = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
       const url = baseURL + '/' + API_VERSION + '/' + PACKAGE + '/categories';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          dispatch(
            exerciseAction.categoryFill(
              response['news_categories']
            )
          );
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
        });
    };
  };
  const homefitContentSearch = (params) => {
    return (dispatch) => {
      const url = baseURL + '/' + API_VERSION + '/' + PACKAGE + '/content_search';
      return Axios.post(url, params)
        .then(async (response) => {
          let first = false;
          if (params.first) {
            first = true;
          }
          dispatch(
            contentListAction.fillHomeFitContentFilterData(
              response['content'],
              first
            )
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const hideHomefitContent = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = baseURL + '/' + API_VERSION + '/' + PACKAGE + '/news_toggle_hide';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          Utils.showSuccessAlert(params.visibility_type=="unhide" ? 'Published Successfully' : 'Unpublished Successfully');
          return response;
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const deleteHomeFitContent = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = baseURL +  '/' + API_VERSION + '/' + PACKAGE + '/news_delete';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(contentListAction.removeHomeFitContentList(params));
          dispatch(hideProgress());
          Utils.showSuccessAlert('Content has been deleted');
          return response;
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
export {
  hideHomefitContent,
  deleteHomeFitContent,
  listHomeFitContent,
  listTrainerContent,
  getHomeFitContent,
  postHomeFitContent,
  homefitContentSearch,
  // uploadMedia,
  // toggleExerciseFavorite,
  // saveSearchPreferences,
  getCategoryHomeFit,
};
