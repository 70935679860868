import * as React from 'react';
import * as _ from 'lodash';
import { Tab } from 'react-toolbox';
import { Tabs } from 'react-toolbox';
import { IconButton } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import { Panel } from 'react-toolbox';
import Input from 'react-toolbox/lib/input';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Chip } from 'react-toolbox/lib/chip';
import TabsOptions from '../courses_tabs';
import moment from 'moment';
export default function () {
    function repeatCourse1(course, index) {
        return React.createElement('div', {
            'className': 'width-260 box-shadow bg-white m-l-10 m-r-10 m-b-16',
            'key': 'course' + index
        }, React.createElement('div', {
            'className': 'thumbnail box-260-150 p-relative',
            'style': { backgroundImage: 'url(' + encodeURI(course.courses_media.length ? course.courses_media[0].thumbnail_url : '') + ')' }
        }, course.published === false ? React.createElement('div', { 'key': '1869' }, React.createElement('div', { 'className': 'bg-warning ribbon' }, '\n                                        Draft\n                                    ')) : null, course.published === true ? React.createElement('div', { 'key': '2137' }, React.createElement('div', { 'className': 'bg-published ribbon' }, '\n                                        Published\n                                    ')) : null, course.courses_media.length > 0 ? React.createElement('div', {
            'className': 'duration f-12 c-grey p-absolute d-flex',
            'key': '2410'
        }, '\n                                    ', moment.utc().startOf('day').add(course.courses_media[0].duration, 'seconds').format('HH:mm:ss'), '\n                                ') : null), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 p-l-12 p-t-12',
            'style': { height: '140px' }
        }, React.createElement('div', { 'className': 'w-100 d-flex align-center m-b-16' }, React.createElement('div', {
            'className': 'certification-logo',
            'style': {
                backgroundImage: 'url(' + encodeURI(course.logo_image) + ')',
                backgroundSize: 'contain'
            }
        }), React.createElement(TooltipWrapper, {
            'className': 'w-76 m-l-8 f-16 text-left text-capital',
            'style': { width: '77% !important' },
            'tooltip': course.title
        }, '\n                                         ', course.title.length > 25 ? course.title.substring(0, 36) + '...' : course.title, '\n                                    ')), React.createElement('div', { 'className': 'w-100 d-flex' }, React.createElement('div', { 'className': 'subtitle f-12 p-l-5' }, 'Added by ', course.author, ' \u2022'), React.createElement('span', { 'className': 'subtitle f-12 m-l-4' }, moment(course.publishedAt).format('MMM D, h:mm A'))), React.createElement('div', { 'className': 'view-btn w-100 d-flex' }, React.createElement('div', {
            'tooltip': 'Views',
            'className': 'w-100 text-left c-grey small-icon-button'
        }, React.createElement('span', { 'className': 'views-icon' }), React.createElement('span', { 'className': 'p-l-5' }, course.views, ' views')), React.createElement('div', {
            'tooltip': '9999 CTA clicks',
            'className': 'w-100 text-left c-grey small-icon-button'
        }, React.createElement('span', { 'className': 'clicks-icon' }), React.createElement('span', { 'className': 'p-l-5' }, course.clicks, ' CTA clicks')))), React.createElement('div', { 'className': 'row center-xs m-0' }, [React.createElement('div', {
                'className': 'd-flex p-b-2 m-0',
                'key': '47971'
            }, React.createElement(IconButton, {
                'tooltip': 'Edit Course',
                'className': 'edit-icon w-50 edit-btn f-14 d-flex m-r-5 c-grey',
                'onClick': () => {
                    this.editEducationContent(course.id);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Delete ' + this.props.content_type,
                'className': 'w-50 delete-red-icon m-r-23 c-red small-icon-button',
                'onClick': () => {
                    this.deleteCourse(course.id);
                }
            }))]));
    }
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'className': 'add-btn p-fixed bg-success m-r-10',
        'style': { zIndex: '10' },
        'onClick': this.addEducationContent,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    }), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-t-24' },
        _.map(this.props.courseList, repeatCourse1.bind(this))
    ])));
}