import  React  from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/document_list_new.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import HealthDocumentAction from '../../redux/documents/actions/health_document';
import swal from 'sweetalert2';
import {getHealthDocumentList,deleteHealthDocument} from '../../redux/documents/network/health_document';
import PropTypes from 'prop-types';

class HealthDocumentList extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addHealthDocument = this.addHealthDocument.bind(this);
        this.editHealthDocument = this.editHealthDocument.bind(this);
        this.deleteHealthDocument = this.deleteHealthDocument.bind(this);
    }

    componentWillMount() {
        this.props.cleanUp();
        this.loadData(this.props);
    }

    loadData(props) {
        if (props.match.params.document_type && props.match.params.document_type === 'nutrition') {
            props.getHealthDocumentList({
                document_type:1,
                package_version: "1",
                "fetch_type": "all"
            });
        } else if ( props.match.params.document_type && props.match.params.document_type === 'guidance'){
            props.getHealthDocumentList({
                document_type:2,
                package_version: "1",
                "fetch_type": "all"
            });
        } else {
            history.goBack();
        }        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.document_type !== nextProps.match.params.document_type){
            nextProps.cleanUp();
            this.loadData(nextProps);
        }
    }
        

    componentWillUnmount() {
        this.props.cleanUp();
    }

    deleteHealthDocument(id) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You will be deleting this document permanently! Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value) {
                let params = { id: id, package_version: 1 };
                that.props.deleteHealthDocument(params)
                .then(() => {
                    that.loadData(that.props);
                }).catch(() => { });
            } else {
                swal.close();
                return
            }
        }, function (dismiss) {
                        
        });    
    }

    editHealthDocument(id) {
        if (this.props.match.params.document_type && this.props.match.params.document_type === 'nutrition') {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/d/nutrition/edit/'+id);
        }else if ( this.props.match.params.document_type && this.props.match.params.document_type === 'guidance'){
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/d/guidance/edit/'+id);
        } else {
            history.goBack();
        }        
    }
    addHealthDocument() {
        if (this.props.match.params.document_type && this.props.match.params.document_type === 'nutrition') {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/d/nutrition/add');
        }else if ( this.props.match.params.document_type && this.props.match.params.document_type === 'guidance'){
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/d/guidance/add');
        } else {
            history.goBack();
        }    
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}

HealthDocumentList.propTypes = {
    selectionMode: PropTypes.bool,
    onSelected: PropTypes.func,
};
HealthDocumentList.defaultProps = {
    selectionMode: false,
    onSelected: () => { },
};

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        health_documents: state.health_document.documentReducer.health_documents
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getHealthDocumentList,
        deleteHealthDocument,
        cleanUp: HealthDocumentAction.cleanModule,
    }, dispatch),
    dispatch
});

const HealthDocumentListConnected = connect(mapStateToProps,mapDispatchToProps,)(HealthDocumentList);
export default HealthDocumentListConnected;
