let initialState = {
    banner_list: [],
    paginate_info:{page:0},    
    banner: {},
    supporting_data: {},
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "BANNER":
            if (!action.banner_list){
                return state;
            }
            let newBannerList = [];
            // if(action.paginate_info.page === 0){
            //     newBannerList = [ ...action.banner_list];
            // }else{
                newBannerList = [ ...action.banner_list];
            // }
            return Object.assign({}, state, {
                banner_list: newBannerList,
                paginate_info:action.paginate_info
            });
        case "GET_BANNER":
            if (!action.banner){
                return state;
            }
            return Object.assign({}, state, {
                banner: action.banner,
                supporting_data: action.supporting_data,
            });
        case "BANNER_CLEANUP":
            return Object.assign({}, initialState);    
        default:
            return state;
    }
};
