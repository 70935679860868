import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GroupClassBooking from '../group_class_booking.jsx';
export default function () {
    function repeatGroupClass1(groupClass, groupClassIndex) {
        return React.createElement(Tr, { 'key': 'row' + groupClassIndex }, React.createElement(Td, {
            'column': 'name',
            'value': groupClass.name
        }, React.createElement('span', {}, groupClass.name)), React.createElement(Td, {
            'column': 'max_client_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, groupClass.max_client_count)), React.createElement(Th, {
            'column': 'client_booking_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, groupClass.client_booking_count)), React.createElement(Td, {
            'column': 'booking_type',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, groupClass.__booking_type)), React.createElement(Td, {
            'column': 'start_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, moment(groupClass.start_date).format('MMMM Do YYYY'))), React.createElement(Td, {
            'column': 'end_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, moment(groupClass.end_date).format('MMMM Do YYYY'))), React.createElement(Td, {
            'column': 'class_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, groupClass.class_count)), React.createElement(Td, {
            'column': 'created_by',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, groupClass.created_by.name)), React.createElement(Td, {
            'column': 'is_published',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, groupClass.is_published ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '4639'
        }) : null, !groupClass.is_published ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '4822'
        }) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, React.createElement(Button, {
            'onClick': () => {
                this.editGroupClassScheduler(groupClass);
            },
            'tooltip': 'Edit group class',
            'icon': 'create',
            'className': 'micro m-r-5',
            'floating': true,
            'primary': true,
            'mini': true
        }), groupClass.is_published === true ? React.createElement(Button, {
            'onClick': () => {
                this.bookGroupClassScheduler(groupClass);
            },
            'tooltip': 'Book client',
            'icon': 'credit_card',
            'className': 'micro m-r-5',
            'floating': true,
            'primary': true,
            'mini': true,
            'key': '5444'
        }) : null, React.createElement(Button, {
            'onClick': () => {
                this.viewCalendarGroupClassScheduler(groupClass);
            },
            'tooltip': 'View calendar',
            'icon': 'date_range',
            'className': 'micro m-r-5 bg-accent',
            'floating': true,
            'primary': true,
            'mini': true
        }), React.createElement(Button, {
            'onClick': () => {
                this.deleteGroupClassScheduler(groupClass);
            },
            'tooltip': 'Delete group class',
            'icon': 'delete_forever',
            'className': 'micro  bg-danger',
            'floating': true,
            'primary': true,
            'mini': true
        }))));
    }
    return React.createElement('div', { 'className': 'm-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'You have not scheduled any group class.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'max_client_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Max client count')), React.createElement(Th, {
            'column': 'client_booking_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Client Booking Count')), React.createElement(Th, {
            'column': 'booking_type',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Booking Type')), React.createElement(Th, {
            'column': 'start_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Start date')), React.createElement(Th, {
            'column': 'end_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'End date')), React.createElement(Th, {
            'column': 'created_by',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Created by')), React.createElement(Th, {
            'column': 'class_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Class count')), React.createElement(Th, {
            'column': 'is_published',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Published')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.groupClassList, repeatGroupClass1.bind(this))
    ]), React.createElement(Button, {
        'className': 'floating-add-button floating-add-button-bottom',
        'tooltip': 'Add new group class',
        'onClick': this.addGroupClassScheduler,
        'icon': 'add',
        'floating': true,
        'mini': true,
        'ripple': true
    }));
}