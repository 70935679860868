import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import { Field } from 'redux-form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import Input from 'react-toolbox/lib/input';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12 m-20' }, React.createElement(Card, {}, React.createElement(CardTitle, {
        'className': 'm-b-20 text-left',
        'title': 'Assessment Summary',
        'subtitle': this.props.client.name + ' (' + this.props.client.email + ')'
    }), React.createElement(CardText, {}, React.createElement('div', { 'className': 'row c-accent-light' }, '\n                    Relative Success Code\n                '), React.createElement('div', { 'className': 'row f-18 m-t-5' }, '\n                    ', this.props.rsc, '\n                '), React.createElement('div', { 'className': 'row m-t-20 c-accent-light' }, '\n                    Performance Modality\n                '), React.createElement('div', { 'className': 'row f-18 m-t-5' }, '\n                    ', this.props.performanceModality.name, '\n                '), React.createElement('div', { 'className': 'row m-t-20 c-accent-light' }, '\n                    Analysis Type\n                '), React.createElement('div', { 'className': 'row m-t-5' }, React.createElement(Select, {
        'className': 'w-100 p-t-0 m-b-20',
        'label': 'Analysis Type',
        'options': this.state.analysisTypes,
        'value': this.state.selectedAnalysisType,
        'onChange': val => {
            this.onAnalysisTypeSelected(val);
        }
    })), React.createElement('div', { 'className': 'row c-accent-light' }, '\n                    Notes\n                '), React.createElement('div', { 'className': 'row f-14' }, React.createElement(Input, {
        'className': 'w-100 p-0',
        'type': 'text',
        'multiline': true,
        'value': this.props.notes,
        'disabled': true
    }))), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Generate Draft Workout',
        'className': 'bg-success',
        'onClick': () => {
            this.generateWorkout();
        },
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': () => {
            this.doCancel();
        },
        'type': 'button',
        'raised': true
    })))));
}