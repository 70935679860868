import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_health_documents.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getPartnerHealthDocumentList, addPartnerLibraryHealthDocument, removePartnerLibraryHealthDocument,
    addAllHealthDocumentsPartnerLibrary, removeAllHealthDocumentsPartnerLibrary
} from '../../redux/partner/network/partner_network';
import {getHealthDocumentList} from '../../redux/documents/network/health_document';
import partnerActions from '../../redux/partner/actions/partner_action';


class PartnerLibraryHealthDocument extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addHealthDocument = this.addHealthDocument.bind(this);
        this.removeHealthDocument = this.removeHealthDocument.bind(this);
        this.addAllHealthDocuments = this.addAllHealthDocuments.bind(this);
        this.removeAllHealthDocuments = this.removeAllHealthDocuments.bind(this);
    };

    componentWillMount() {
        if(!this.props.match.params.library_id && !this.props.match.params.document_type){
            history.goBack();
        }
        this.props.getPartnerHealthDocumentList({
            id: this.props.match.params.library_id,
            document_type: this.props.match.params.document_type,
        });
        this.props.getHealthDocumentList({
            document_type:this.props.match.params.document_type,
            fetch_type: "practice"
        });
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    addHealthDocument(document){
        this.props.addPartnerLibraryHealthDocument({
            id: this.props.match.params.library_id,
            document_type: this.props.match.params.document_type,
            document_id:document.id
        });
    }

    removeHealthDocument(document){
        this.props.removePartnerLibraryHealthDocument({
            id: this.props.match.params.library_id,
            document_type: this.props.match.params.document_type,
            document_id:document.id
        });
    }

    addAllHealthDocuments() {
        let params = {  
            library_id: this.props.match.params.library_id,
            document_type: this.props.match.params.document_type,
        };
        this.props.addAllHealthDocumentsPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.getPartnerHealthDocumentList({
                id: this.props.match.params.library_id,
                document_type: this.props.match.params.document_type,
            });
            this.props.getHealthDocumentList({
                document_type:this.props.match.params.document_type,
                fetch_type: "practice"
            });
        }).catch((error) => {            
        });  
    }

    removeAllHealthDocuments() {
        let params = {  
            library_id: this.props.match.params.library_id,
            document_type: this.props.match.params.document_type,
        };
        this.props.removeAllHealthDocumentsPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.getPartnerHealthDocumentList({
                id: this.props.match.params.library_id,
                document_type: this.props.match.params.document_type,
            });
            this.props.getHealthDocumentList({
                document_type:this.props.match.params.document_type,
                fetch_type: "practice"
            });
        }).catch((error) => {            
        });  
    }


    render() {
        if(_.isEmpty(this.props.partner_library)){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerHealthDocumentList,
        addPartnerLibraryHealthDocument,
        removePartnerLibraryHealthDocument,
        getHealthDocumentList,
        cleanUp: partnerActions.cleanUp,
        addAllHealthDocumentsPartnerLibrary,
        removeAllHealthDocumentsPartnerLibrary
    }, dispatch);
}

function mapStateToProps(state,ownProps) {
    let document_ids = _.keyBy(state.partner.partnerReducer.partner_library.documents, "id");
    return {
        partner_library: state.partner.partnerReducer.partner_library,
        documentList: state.health_document.documentReducer.health_documents,
        partner_document_ids:document_ids
    };
}

const PartnerLibraryHealthDocumentConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryHealthDocument);
export default PartnerLibraryHealthDocumentConnected;
