import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/courses_content_edit";
import store,{history} from '../../redux/store';
import Utils from '../../utils/utils';
import React from 'react';
import axios from 'axios';
import moment from 'moment';

export default class EducationContentEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.onChangeContent = this.onChangeContent.bind(this);
        this.onChangeWorkout = this.onChangeWorkout.bind(this);

        this.onVideoUploaded = this.onVideoUploaded.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.transformData = this.transformData.bind(this);
        this.listAssociatedContent = this.listAssociatedContent.bind(this);
        this.listContent = this.listContent.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.setFilterPreferences = this.setFilterPreferences.bind(this);
        this.removeWorkoutItem = this.removeWorkoutItem.bind(this);
        this.removeContentItem = this.removeContentItem.bind(this);

        this.uploadLogoImage = this.uploadLogoImage.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.onCancelImage = this.onCancelImage.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);
        this.captureThumbnail = this.captureThumbnail.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.yesNoOptions = [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ];
        this.state = {
            published: false,
            contentType: "",
            image_name: "",
            tab: "",
            optionsA:"",
            search: "",
            searchText: "",
            searchCategory: ""

        };
        this.player = null;
    }
    selectCategory(value){
        this.setState({
            searchCategory: value.id,
            searchCategoryName: value.name
        })
        this.onSearch()
    }

    // handleDelete(itemId) {
    //     const items = this.props.properties.content_associated.filter(item => item.id !== itemId);
    //     this.setState({ items: items });
    //   };

    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    cancel() {
        if(confirm("Are you sure? All changes will be discarded.")){
            this.setState({
                tab: this.props.properties.match.params.course_type,
            })
            history.goBack();
        }
    }

    listContent(data) {
        let params = {
            // "search_key" : filterData.search,
            "category" : data,   
        };
        if(this.props.content==='gi_content'){
            this.props.properties.contentSearch(params);
        }
    }
    removeContentItem(id){
        this.props.properties.removeContent(id)
    }

    removeWorkoutItem(id){
        this.props.properties.removeWorkout(id)
    }

    onSearch(text){
        if(text == null){
            this.loadMore();
        }
        this.setState({search:text.db_value,},()=>{

            this.loadMore();
            this.props.properties.associatedContentSearch({
                "package_version": "2",
                "app_version": "5.2",
                "device_type": "web",
                "category": this.state.search,
                "page":1,
            })
        });
    }

    loadMore(increment=false,first=false) {
        let params = { 
                "package_version": "2",
                "app_version": "5.2",
                "device_type": "web",
                "content_type": "video",
                "page": 1,
            ...this.props.properties.paginateInfo,
            ...this.state.filterPreferences,
        };

        if(increment){
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        if(!this.state.filterPreferences){
            params.page= 1
            this.props.properties.listContent(params);
            this.props.properties.associatedContentSearch({
                "package_version": "2",
                "app_version": "5.2",
                "device_type": "web",
                "category": this.state.search,
                "page": 1,
            })
            this.props.properties.listProgram({
                category: this.state.searchCategory ? this.state.searchCategory : "",
                "program_type": 0,
                "package_version": 3,
                "quickstart_type": "visible",
                "app_version": "5.4",
                "current_role_type": "practice_admin",
                practice_id: this.props.practice_id
            });
        }
    }

    setFilterPreferences(filterData) {
        let params = {
            "search_key" : filterData.search,
            "category" : filterData.category   
        };
        this.setState({filterPreferences:params},()=>{
            this.props.properties.contentSearch(params);
        });
    }


    saveAndPublish() {
        let submit = this.props.properties.handleSubmit(this.doSave);
        this.setState({
            published: true
        }, () => {
            submit();
        });
    }

    saveAsDraft() {
        let submit = this.props.properties.handleSubmit(this.doSave);
        this.setState({
            published: false
        }, () => {
            submit();
        });
    }

    uploadLogoImage(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        this.props.properties.uploadFiles(formData).then((resp) => {
            try {
                let result = resp.upload_results[0];
                this.props.properties.change('logo_image', result.url);
                this.props.properties.change('image_name', result.file_name);
                this.props.properties.change('is_image_uploaded', true);
                this.props.properties.change('dummy_to_trigger_save', true); 
            } catch (error) {
                console.log(error, "uploadFiles -then")
            }
        }).catch((error) => {
            console.log(error, "uploadFiles")
        });
    }

    onCancelImage(){
        this.props.properties.change('is_image_uploaded', false);
        this.props.properties.change('logo_image', null);
    }

    componentWillMount() {
        if(this.props.properties.match.params.course_id){
            this.props.properties.getCourse({ id: this.props.properties.match.params.course_id })
            .then(()=>{
                this.props.properties.selectedAssociatedContentList(this.props.properties.course.content_associated)
                this.props.properties.selectedAssociatedWorkoutList(this.props.properties.course.courses_workout_templates)
            });;
        }
        
        this.props.properties.getCategory({
            practice_id: this.props.properties.practice_id,
            page: 1
        });
        this.props.properties.getCoursesCategory({
            "package_version": "2",
            "app_version": "5.2",
            "device_type": "web"
        })
        this.props.properties.getProgramFilters({
            app_version: "5.2",
            current_role_type: "practice_admin",
            is_favorite: false,
            my_deleted_templates: false,
            my_practice_programs: true,
            my_programs: true,
            package_version: "3",
            practice_id: 276
        })
        this.props.properties.associatedContentSearch({
            "package_version": "2",
            "app_version": "5.2",
            "device_type": "web",
            "category": null,
            "page": 1,
        })

        this.onSearch()
    }

    listAssociatedContent(text) {
        if(text.label.length > 2){
            let params = {
                "package_version": "2",
                "app_version": "5.2",
                "device_type": "web",
                "search_key": text.label
            }
            this.props.properties.getAssociatedContent(params)
        }
    }

    deletePositionPicture(url, del) {
        let pics = _.cloneDeep(this.props.properties.position_pictures);
        var pic = _.find(pics, (item) => { return item.url === url; });

        if ("id" in pic) {
            pic.delete = true;
        } else {
            pics = _.remove(pics, function(item) {
                if (item.url !== pic.url){
                    return true;
                }
            });
        }
        this.props.properties.change('position_pictures', pics);
    }

    deleteVideo() {
        this.props.properties.change('video_guid', null);
        this.props.properties.change('video_thumbnail_url', null);
        this.props.properties.change('duration', 0);
    }

    
    onVideoUploaded(uploadResult) {
        this.props.properties.change('video_guid', uploadResult.guid);
        this.props.properties.change('video_thumbnail_url', uploadResult.thumbnail);
        this.props.properties.change('duration', uploadResult.duration);
    }

    captureThumbnail() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type':'application/json',
            }
        });
        if (that.player) {
            this.props.properties.showProgress();
            that.player.getCurrentTime().then(function(time) {
                let params = {time:time,active:true};
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.properties.video_guid + "/pictures",params
                ).then((response) => {
                    let thumbnail_url =  "";
                    if(response.data.sizes.length > 3 ){
                        thumbnail_url = response.data.sizes[3].link;
                    }else{
                        thumbnail_url = response.data.sizes[response.data.sizes.length -1].link;
                    }
                    that.props.properties.change('video_thumbnail_url', thumbnail_url);    
                    that.props.properties.hideProgress();
                    Utils.showSuccessAlert("Your new thumbnail image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.properties.hideProgress();
                });
            }).catch(function(error) {
                that.props.properties.hideProgress();
            });
        }
    }

    onVideoUploadFailed() {
        Utils.showErrorAlert("Video upload failed! Please try again.");
    }
    onChangeContent(obj){
        this.props.properties.selectedAssociatedContentList(obj).then(()=>
        this.props.change('content_associated',[]))
    }
    onChangeWorkout(obj){
            this.props.properties.selectedAssociatedWorkoutList(obj).then(()=>
            this.props.change('courses_workout_templates',[]))
    }
    doSave(data) {
        let params = this.transformData(data);
        let associatedContent = Object.keys(this.props.properties.selectedAssociatedContent)
        let associatedWorkout = Object.keys(this.props.properties.selectedAssociatedWorkout)
        params.published = this.state.published;
        
        let publish_date = moment(params.publish_date).format("YYYY-MM-DD")
        let publish_time = moment(params.publish_time).format("HH:mm:ss")  
        params.published_at = `${publish_date} ${publish_time}` 
        params.associated_content = associatedContent
        params.courses_workout_templates = associatedWorkout
        // params.associated_content = params.content_associated
        // params.courses_workout_templates = params.courses_workout_templates
       
        // delete publish_date;
        // delete publish_time;
        if(!this.props.properties.video_guid && !this.props.properties.is_image_uploaded ){ 
            Utils.showErrorAlert("Please add media before publishing.");
            } else {                   
                    params.courses_media_items = [{
                        "is_cover_image": true, 
                        "guid": params.video_guid,
                        "thumbnail": params.video_thumbnail_url,
                        "filename": params.image_name,
                        "video_url":"",
                        "duration":this.props.properties.duration
                    }];
                    this.props.properties.postCourse({ ...params, course_type: this.props.properties.match.params.course_type}).then((response) => {
                         history.goBack();
                     }).catch((error) => { });           
            }

        
    }

    transformData(data) {
        let params = Utils.flatternFormData(data, ['position_pictures']);
        return params;
    }

    render() {
        if (_.isEmpty(this.props.properties.initialValues)&& (this.props.properties.match.params.course_id)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {

        this.props.properties.cleanModule();
        this.props.properties.cleanModuleContent();
    }

}
