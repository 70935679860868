import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/client_engagement_workout_preview.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import clientEngagementProgressionDatesAction from '../../redux/engagement/actions/client_engagement_progression_dates_creator.js';
import {  getClientProgressionPerDate, engagementTrackHabit } from '../../redux/engagement/network/client_engagement_network';
import { postProgressionProgress } from '../../redux/program/network/workout_progress';
import moment from 'moment';
import roleReducer from '../../redux/auth/reducers/role_reducer';

class ClientEngagementWorkoutPreview extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        let todayString = moment().format("YYYY-MM-DD");
        this.goBack = this.goBack.bind(this);
        this.goToEngagementHome = this.goToEngagementHome.bind(this);
        this.trackWorkout = this.trackWorkout.bind(this);
        this.playWorkout = this.playWorkout.bind(this);
        this.trackHabit = this.trackHabit.bind(this);
        this.trackProgressionCallback = this.trackProgressionCallback.bind(this);
        this.state = { today_string: todayString,selectedDateString:""};
    };
    
    componentWillMount() {
        if (!this.props.match.params.engagement_id  ||  !this.props.match.params.date ) {
            history.goBack();
        }else{
            let d = moment(this.props.match.params.date,'YYYY-MM-DD');
            if(d == null || !d.isValid()){
                history.goBack();
                Utils.showWarningAlert("Workout cannot be previewed for this date");
            }   
            this.setState({selectedDateString:d.format("MMMM Do YYYY")});
            this.props.cleanUp([]);
            this.loadWorkouts();
        }  
    }
    
    loadWorkouts() {
          this.props.getClientProgressionPerDate({ id: this.props.match.params.engagement_id,date: this.props.match.params.date});
    }

    trackProgressionCallback(params){
        this.props.postProgressionProgress(params)
        .then((response)=>{
            this.loadWorkouts(); 
        }).catch((error)=>{

        });
    }

    goBack(){
        history.goBack();
    }
    goToEngagementHome(){
        history.push("/client/engagement/"+this.props.match.params.engagement_id);
    }

    playWorkout(progression){
        if (this.props.selectedRole.role.name === "client"){
            history.push("/client/engagement/"+this.props.match.params.engagement_id+"/workout/play/"+progression.id+"/?date="+this.props.match.params.date);
        }else if (this.props.selectedRole.role.name === "practitioner" || this.props.selectedRole.role.name === "practice_admin" ){
            history.push("/practice/"+this.props.selectedRole.practice.id+"/client/engagement/"+this.props.match.params.engagement_id+"/workout/play/"+progression.id+"/?date="+this.props.match.params.date);
        }        
    }
    trackWorkout(progression){
        if (this.props.selectedRole.role.name === "client"){
            history.push("/client/engagement/"+this.props.match.params.engagement_id+"/workout/track/"+progression.id+"/"+this.props.match.params.date);
        }else if (this.props.selectedRole.role.name === "practitioner" || this.props.selectedRole.role.name === "practice_admin" ){
            history.push("/practice/"+this.props.selectedRole.practice.id+"/client/engagement/"+this.props.match.params.engagement_id+"/workout/track/"+progression.id);
        }          
    }

    trackHabit(habit, track_status) {
        let params = {};
        params['engagement_id'] = this.props.engagement.id;
        params['habit_track_id'] = habit.id;
        params['track_status'] = track_status;
        this.props.engagementTrackHabit(params).then(() => {
            this.loadWorkouts(); 
        });
    }


    componentWillUnmount() {
        this.props.cleanUp([],{});
    }


    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state, ownprops) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        progression_preview: state.engagement.clientEngagementProgressionDatesReducer.progression_preview,
        habits: state.engagement.clientEngagementProgressionDatesReducer.habits,
        engagement: state.engagement.clientEngagementProgressionDatesReducer.engagement
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getClientProgressionPerDate,
            postProgressionProgress,
            engagementTrackHabit,
            cleanUp: clientEngagementProgressionDatesAction.fillProgressionDateInstance
        }, 
        dispatch
    );
}

const ClientEngagementWorkoutPreviewConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagementWorkoutPreview);
export default ClientEngagementWorkoutPreviewConnected;
