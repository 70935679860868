// Action Creators
const servicesOfferedListFill = (services_offered_list) => ({
    type: 'SERVICES_OFFERED_LIST_FILL',
    services_offered_list: services_offered_list
});

const servicesOfferedGetFill = (services_offered) => ({
    type: 'SERVICES_OFFERED_GET_FILL',
    services_offered: services_offered
});

const servicesOfferedCleanup = () => ({
    type: 'SERVICES_OFFERED_CLEANUP',
});

export default {
    servicesOfferedListFill,
    servicesOfferedGetFill,
    servicesOfferedCleanup,
};
