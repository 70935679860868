import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import moment from 'moment';
import { Checkbox } from 'react-toolbox/lib/checkbox';
import DatePicker from 'react-toolbox/lib/date_picker';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import Chip from 'react-toolbox/lib/chip';
export default function () {
    function repeatWorkout1(workout, workoutIndex) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10',
            'key': 'workout' + workout.id
        }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(workout.image_url) + ')' }
        }), React.createElement('div', { 'className': 'row bg-white start-xs  m-0  p-l-5' }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (workout.description ? '9' : '12') + ' f-16 truncate m-t-8',
            'tooltip': workout.name
        }, '\n                                ', workout.name, '\n                            '), workout.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': workout.description,
            'icon': 'info',
            'key': '3636'
        }) : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-5 m-0' }, React.createElement(Checkbox, {
            'className': 'm-b-0 m-t-15',
            'checked': _.find(this.state.selectedWorkouts, { 'id': workout.id }) ? true : false,
            'onChange': val => {
                this.onClickWorkoutCheckbox(workout, val);
            }
        }), this.props.treatment_template.treatment_template_cycle === 1 && _.find(this.state.selectedWorkouts, { 'id': workout.id }) ? React.createElement('div', { 'key': '4284' }, React.createElement(DatePicker, {
            'minDate': this.state.today,
            'value': _.find(this.state.selectedWorkouts, { 'id': workout.id }).start_date,
            'onChange': val => {
                this.changeWorkoutDate(val, workout);
            }
        })) : null)));
    }
    function repeatHabit2(habit, habitIndex) {
        return React.createElement('div', {
            'className': 'panel col-xs-8 m-t-10',
            'key': 'habit' + habit.id
        }, React.createElement('div', { 'className': 'panel-body row p-0' }, React.createElement('div', { 'className': 'col-xs-2 bg-beige center-xs p-5' }, React.createElement(Checkbox, {
            'className': 'm-b-0 p-t-15',
            'checked': _.find(this.state.selectedHabits, { 'id': habit.id }) ? true : false,
            'onChange': val => {
                this.onClickHabitCheckbox(habit, val);
            }
        }), this.props.treatment_template.treatment_template_cycle === 1 && _.find(this.state.selectedHabits, { 'id': habit.id }) && _.find(this.state.selectedHabits, { 'id': habit.id })['start_date'] ? React.createElement('div', { 'key': '6116' }, React.createElement(DatePicker, {
            'minDate': this.state.today,
            'value': _.find(this.state.selectedHabits, { 'id': habit.id }).start_date,
            'onChange': val => {
                this.changeHabitDate(val, habit);
            }
        })) : null), React.createElement('div', { 'className': 'col-xs-10 p-5' }, React.createElement('h6', {}, habit.name), React.createElement('p', {}, habit.text))));
    }
    function repeatAttachment3(nutrition, nutritionIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatNutrition4(nutrition, nutritionIndex) {
        return React.createElement('div', {
            'className': 'panel col-xs-8 m-t-10',
            'key': 'nutrition' + nutrition.id
        }, React.createElement('div', { 'className': 'panel-body row p-0' }, React.createElement('div', { 'className': 'col-xs-2 bg-beige center-xs p-5' }, React.createElement(Checkbox, {
            'className': 'm-b-0 p-t-15',
            'checked': _.find(this.state.selectedNutrition, { 'id': nutrition.id }) ? true : false,
            'onChange': val => {
                this.onClickNutritionCheckbox(nutrition, val);
            }
        })), React.createElement.apply(this, [
            'div',
            { 'className': 'col-xs-10 p-5' },
            React.createElement('h6', {}, nutrition.name),
            React.createElement('p', { 'className': 'f-10' }, nutrition.description),
            _.map(nutrition.attachments, repeatAttachment3.bind(this, nutrition, nutritionIndex))
        ])));
    }
    function repeatAttachment5(guidance, guidanceIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatGuidance6(guidance, guidanceIndex) {
        return React.createElement('div', {
            'className': 'panel col-xs-8 m-t-10',
            'key': 'guidance' + guidance.id
        }, React.createElement('div', { 'className': 'panel-body row p-0' }, React.createElement('div', { 'className': 'col-xs-2 bg-beige center-xs p-5' }, React.createElement(Checkbox, {
            'className': 'm-b-0 p-t-15',
            'checked': _.find(this.state.selectedGuidance, { 'id': guidance.id }) ? true : false,
            'onChange': val => {
                this.onClickGuidanceCheckbox(guidance, val);
            }
        })), React.createElement.apply(this, [
            'div',
            { 'className': 'col-xs-10 p-5' },
            React.createElement('h6', {}, guidance.name),
            React.createElement('p', { 'className': 'f-10' }, guidance.description),
            _.map(guidance.attachments, repeatAttachment5.bind(this, guidance, guidanceIndex))
        ])));
    }
    return React.createElement('div', { 'className': 'm-15' }, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs' },
        React.createElement('div', { 'className': 'vertical-align w-100' }, this.props.treatment_template.treatment_template_type === 1 ? React.createElement('h3', {
            'className': 'flex-auto',
            'key': '1421'
        }, '\n                        ', this.props.treatment_template.name, '\n                    ') : null, this.props.treatment_template.treatment_template_type === 2 ? React.createElement('h3', {
            'className': 'flex-auto',
            'key': '1619'
        }, React.createElement(Input, {
            'name': 'group_name',
            'label': 'Group Name',
            'type': 'text',
            'value': this.state.updatedGroupName,
            'onChange': this.updateGroupName
        })) : null, React.createElement(IconButton, {
            'tooltip': 'Close',
            'icon': 'clear',
            'className': 'c-danger',
            'onClick': this.props.hideTreatmentCreationModal
        })),
        this.props.treatment_template.treatment_template_cycle === 1 ? React.createElement('div', {
            'className': 'col-xs-12',
            'key': '2192'
        }, React.createElement('label', {
            'className': 'material-label',
            'style': { marginBottom: '-15px' }
        }, 'Select Start Date'), React.createElement(DatePicker, {
            'minDate': this.state.today,
            'value': this.state.selectedDate,
            'onChange': val => {
                this.changeSelectedDate(val);
            }
        })) : null,
        React.createElement('h4', { 'className': 'm-t-20' }, 'Workouts'),
        this.props.template_workouts.length === 0 ? React.createElement('div', {
            'className': 'col-xs-12 alert-warning m-t-10',
            'key': '2672'
        }, '\n                    No workouts are available\n                ') : null,
        this.props.template_workouts.length > 0 ? React.createElement('p', {
            'className': 'material-label w-100 m-b-10 m-t-0',
            'key': '2854'
        }, 'Please click on the checkbox to activate workouts') : null,
        _.map(this.props.template_workouts, repeatWorkout1.bind(this)),
        React.createElement.apply(this, [
            'div',
            { 'className': 'row w-100 m-t-10' },
            React.createElement('h4', { 'className': 'm-t-20' }, 'Habits'),
            this.props.template_habits.length === 0 ? React.createElement('div', {
                'className': 'col-xs-12 alert-warning m-t-10',
                'key': '5065'
            }, '\n                                No habits are available\n                        ') : null,
            this.props.template_habits.length > 0 ? React.createElement('p', {
                'className': 'material-label w-100 m-b-10 m-t-0',
                'key': '5271'
            }, 'Please click on the checkbox to activate habits') : null,
            _.map(this.props.template_habits, repeatHabit2.bind(this))
        ]),
        React.createElement.apply(this, [
            'div',
            { 'className': 'row w-100 m-t-10' },
            React.createElement('h4', { 'className': 'm-t-20' }, 'Nutrition Documents'),
            this.props.template_nutrition.length === 0 ? React.createElement('div', {
                'className': 'col-xs-12 alert-warning m-t-10',
                'key': '7295'
            }, '\n                                No nutrition documents are available\n                        ') : null,
            this.props.template_nutrition.length > 0 ? React.createElement('p', {
                'className': 'material-label w-100 m-b-10 m-t-0',
                'key': '7517'
            }, 'Please click on the checkbox to add nutrition documents') : null,
            _.map(this.props.template_nutrition, repeatNutrition4.bind(this))
        ]),
        React.createElement.apply(this, [
            'div',
            { 'className': 'row w-100 m-t-10' },
            React.createElement('h4', { 'className': 'm-t-20' }, 'Guidance Documents'),
            this.props.template_guidance.length === 0 ? React.createElement('div', {
                'className': 'col-xs-12 alert-warning m-t-10',
                'key': '9225'
            }, '\n                                No guidance documents are available\n                        ') : null,
            this.props.template_guidance.length > 0 ? React.createElement('p', {
                'className': 'material-label w-100 m-b-10 m-t-0',
                'key': '9445'
            }, 'Please click on the checkbox to add guidance documents') : null,
            _.map(this.props.template_guidance, repeatGuidance6.bind(this))
        ]),
        React.createElement('div', {
            'className': 'col-xs-12 center-xs',
            'style': { marginTop: '50px' }
        }, React.createElement(Button, {
            'label': 'Create',
            'tooltip': 'Create from template',
            'onClick': this.createTreatmentFromTreatmentTemplate,
            'disabled': this.state.selectedWorkouts.length + this.state.selectedHabits.length + this.state.selectedGuidance.length + this.state.selectedNutrition.length === 0,
            'type': 'button',
            'className': 'c-white bg-success-red',
            'raised': true
        }), React.createElement(Button, {
            'onClick': this.props.hideTreatmentCreationModal,
            'label': 'Close',
            'className': 'bg-accent m-l-5',
            'tooltip': 'Close',
            'type': 'button',
            'raised': true
        }))
    ]));
}