import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/group_edit";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';
import { getGroup, saveGroup, } from '../../redux/group/network/group_network';
import GroupActionCreator from '../../redux/group/actions/group_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';

class GroupEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSubmit = this.onSubmit.bind(this);
        this.hideGroup = this.hideGroup.bind(this);
        this.deleteLogo = this.deleteLogo.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.uploadThumbnailImage = this.uploadThumbnailImage.bind(this);
        this.holdImage = this.holdImage.bind(this);
    
        this.state = {
            publish: false,
            treatment_cycle:[
                {value:1,label:'Scheduled'},
                {value:2,label:'Ongoing'},
            ],
            holdImage:{}
        };
    };

    componentWillMount() {
        this.props.getGroup({ id: this.props.match.params.group_id });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    holdImage(files){
        this.setState({
            holdImage:files
        });
    }

    deleteholdImage(){
        this.setState({
            holdImage:{}
        });
    }

    uploadThumbnailImage(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        return this.props.uploadFiles(formData).then((resp)=>{
            return resp.upload_results[0];
        }).catch(()=>{ });
    }

    save(publish) {
        this.setState({
            publish: publish
        }, () => {
            this.props.handleSubmit(this.onSubmit)();
        });
    }

    onSubmit(data) {

        if(Object.keys(this.state.holdImage).length){
            this.uploadThumbnailImage(this.state.holdImage).then((res)=>{
                if(res.thumbnail_url){
                    let params = _.omit(data, ['can_be_deleted']);
                    params = Utils.flatternFormData(params);
                    params.thumbnail_url = res.thumbnail_url;
                    
                    this.props.saveGroup(params).then((response) => {
                        history.push('/practice/'+this.props.selectedRole.practice.id+'/group/dashboard/'+response.group.id);
                    }).catch(() => {
                    });

                }else{
                    Utils.showErrorAlert("Upload error");

                }
            }); 
        }else{

            Utils.showErrorAlert("Please select a thumbnail image");
        }

    }

    uploadLogo(files) {
        let formData = new FormData();
        formData.append('guid', this.props.guid);
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('logo_url', result.url);
            this.props.change('is_logo_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
        }).catch((error) => {
        });
    }

    deleteLogo() {
        this.props.change('is_logo_uploaded', false);
    }

    cancel() {
        history.goBack();
    }

    hideGroup(groupId) {

    }

    render() {
        if (_.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('groupEditForm');
function mapStateToProps(state) {
    let group = {...state.group.groupReducer.group};
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        group: group,
        initialValues: group,
        is_logo_uploaded: selector(state, 'is_logo_uploaded'),
        logo_url: selector(state, 'logo_url'),
        guid: selector(state, 'guid'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGroup,
        saveGroup,
        cleanUp: GroupActionCreator.groupCleanup,
        uploadFiles,
    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const GroupEditForm = reduxForm(
    {
        form: 'groupEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupEdit);
const GroupEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(GroupEditForm);
export default GroupEditFormConnected;
