import * as React from 'react';
import * as _ from 'lodash';
import { Layout } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import Input from 'react-toolbox/lib/input';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../../utils/common_components/tooltipWrapper';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import Switch from '../../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Checkbox from '../../../../utils/common_components/checkbox_group_form';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import VimeoPlayer from '../../../../utils/common_components/vimeo_player';
export default function () {
    function repeatVideo1(video, index) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'move_video' + index
        }, !video.is_free && !this.props.is_homefit_subscription_valid ? React.createElement('div', {
            'className': 'bg-danger ribbon f-11',
            'key': '2759'
        }, '\n                    SUBSCRIBE\n                ') : null, React.createElement('div', {
            'className': 'thumbnail box-250-150 pointer',
            'style': { backgroundImage: 'url(' + encodeURI(video.thumbnail_url) + ')' },
            'onClick': () => {
                this.viewMoveVideo(video);
            }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-12 f-16 truncate',
            'tooltip': video.name
        }, '\n                        ', video.name, '\n                    ')), React.createElement('div', { 'className': 'row center-xs m-0' }, video.deleted_date === null ? [React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '34971'
            }, video.is_free || this.props.is_homefit_subscription_valid ? React.createElement(IconButton, {
                'tooltip': 'Play Workout Video',
                'icon': 'play_circle_filled',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.viewMoveVideo(video);
                },
                'key': '3632'
            }) : null, !video.is_free && !this.props.is_homefit_subscription_valid ? React.createElement(IconButton, {
                'tooltip': 'Subscribe for DHF HomeFit',
                'icon': 'verified_user',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.subscribeForHomeFit(video);
                },
                'key': '4083'
            }) : null, video.is_free || this.props.is_homefit_subscription_valid ? React.createElement(IconButton, {
                'tooltip': video.is_favorite ? 'Remove workout from favorites' : 'Add workout to favorites',
                'icon': video.is_favorite ? 'star' : 'star_border',
                'className': video.is_favorite ? 'm-r-5 small-icon-button round-border c-success' : 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.toggleFavorite(video);
                },
                'key': '4544'
            }) : null)] : null));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'row start-xs m-10' }, React.createElement('div', { 'className': 'col-xs-12 col-sm-10 col-md-8 p-relative search-box m-b-20 p-5  vertical-align' }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search workouts by name',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    }))), this.props.move_videos.length === 0 ? [React.createElement('div', {
            'className': 'row center-xs alert-warning m-20',
            'key': '23051'
        }, '\n                There are no DHF HomeFit Workouts\n            ')] : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-10' },
        _.map(this.props.move_videos, repeatVideo1.bind(this))
    ]), this.props.paginate_info.total_pages && this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '5237'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more workouts...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.loadMore();
        }
    }))) : null, this.state.show_move_video ? React.createElement(Dialog, {
        'title': this.state.move_video.name,
        'onOverlayClick': this.closeMoveVideo,
        'active': this.state.move_video !== null,
        'type': 'large',
        'key': '5674'
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-15px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.closeMoveVideo
    })), this.state.move_video ? React.createElement('div', {
        'className': 'row',
        'key': '6217'
    }, React.createElement('div', { 'className': 'col-xs-12 start-xs' }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-4' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video' + this.state.move_video.video_guid,
        'videoList': [this.state.move_video.video_guid]
    }))), React.createElement('div', { 'className': 'row m-20' }, React.createElement('div', { 'className': 'col-xs-12' }, '\n                            ', this.state.move_video.description, '\n                        ')), React.createElement('div', { 'className': 'row m-20 f-12' }, React.createElement('div', { 'className': 'col-xs-12' }, '\n                            Workout Duration: ', this.state.move_video.workout_duration, ' minutes\n                        ')))) : null) : null), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow',
        'width': '33'
    }, React.createElement('form', {
        'className': 'medium-labels p-b-30',
        'onSubmit': this.props.handleSubmit(this.applyFilter)
    }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n                  Filters\n            '), React.createElement(Button, {
        'type': 'submit',
        'className': 'small-button bg-success m-l-10',
        'label': 'Apply',
        'raised': true
    }), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), React.createElement('div', { 'className': 'filter-section p-10' }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'show_favorites',
        'label': 'Show My Favorite Workouts',
        'component': Switch
    })), React.createElement('div', {}, React.createElement('hr', { 'className': 'dashed' }), React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Movement Meter'), React.createElement(Field, {
        'name': 'move_meter',
        'component': Select,
        'options': this.movement_meter_list,
        'valueKey': 'value',
        'labelKey': 'label',
        'clearable': true,
        'simpleValue': true,
        'optionRenderer': this.selectRender
    }), React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Equipment'), React.createElement(Field, {
        'name': 'equipments',
        'component': Select,
        'options': this.equipment_list,
        'valueKey': 'value',
        'labelKey': 'label',
        'clearable': true,
        'multi': true,
        'optionRenderer': this.selectRender
    }), React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Workout Type'), React.createElement(Field, {
        'name': 'workout_type',
        'component': Select,
        'options': this.workout_type_list,
        'valueKey': 'value',
        'labelKey': 'label',
        'clearable': true,
        'simpleValue': true,
        'optionRenderer': this.selectRender
    }), React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Workout Duration'), React.createElement(Field, {
        'name': 'workout_duration',
        'component': Select,
        'options': this.workout_duration_list,
        'valueKey': 'value',
        'labelKey': 'label',
        'clearable': true,
        'simpleValue': true,
        'optionRenderer': this.selectRender
    }), React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Workout Level'), React.createElement(Field, {
        'name': 'workout_level',
        'component': Select,
        'options': this.workout_level_list,
        'valueKey': 'value',
        'labelKey': 'label',
        'clearable': true,
        'simpleValue': true,
        'optionRenderer': this.selectRender
    }))))));
}