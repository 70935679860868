let initialState = {
    all_dates: [],
    date_workouts: {},
    ongoing_workouts: {},
    date_habits: {},
    progression_preview: [],
    habits: [],
    engagement: {}
};

export default function progressionDates(state = initialState, action) {
    switch (action.type) {
        case "CLIENT_ENGAGEMENT_PROGRESSION_DATES_FILL":
            if (!action.all_dates || !action.date_workouts || !action.date_habits){
                return state;
            }  
            return Object.assign({}, state, {
                all_dates: action.all_dates,
                date_workouts: action.date_workouts,
                ongoing_workouts: action.ongoing_workouts,
                date_habits: action.date_habits,
            });
        case "CLIENT_ENGAGEMENT_PROGRESSION_PER_DATE_INSTANCE_FILL":
            if (!action.progression_preview || !action.habits) {
                return state;
            }    
            return Object.assign({}, state, {
                progression_preview: action.progression_preview,
                habits: action.habits,
                engagement: action.engagement,
            });
        case "CLIENT_ENGAGEMENT_PROGRESSION_DATES_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
