let initialState = {
    dhf_assess_form: [],
};
export default function intake(state = initialState, action) {
    switch (action.type) {
        case "DHF_ASSESSMENT_FORM_FILL": 
            if (!action.dhf_assess_form){
                return state;
            }    
            return Object.assign({}, state, {
                dhf_assess_form: action.dhf_assess_form,
            });
        case "DHF_ASSESSMENT_UPDATE":
            if (state.dhf_assess_form.length === 0 || !action.dhf_assessment){
                return state;
            }    
            let updated_assess_form =  _.map(state.dhf_assess_form,(item)=>{
                if(item.id === action.dhf_assessment.id){
                    return {...action.dhf_assessment};
                }else{
                    return {...item};
                }
            }) ;
            return Object.assign({}, state, {
                dhf_assess_form: updated_assess_form,
            });
        case "DHF_ASSESSMENT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
