import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/challenge_edit.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import { formValueSelector, reduxForm } from 'redux-form';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../../redux/store';
import { getChallenge, saveChallenge } from '../../../redux/dhf/move/network/challenge_network';
import ChallengeAction from '../../../redux/dhf/move/actions/challenge_action';


class ChallengeEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);    
        this.saveChallenge = this.saveChallenge.bind(this);      
        this.doNext = this.doNext.bind(this);
        this.doCancel = this.doCancel.bind(this);
    }
    
    componentWillMount() {
        this.props.getChallenge({
            challenge_id: this.props.match.params.challenge_id,
        }).then((response) => {            
        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    doNext() {
        if(this.props.match.params.challenge_id !== undefined) {
            history.push("/practice/"+this.props.selectedRole.practice.id+"/move/challenge/"+this.props.match.params.challenge_id+"/workouts");
        } else {
            Utils.showErrorAlert("Save challenge details before creating workouts");
        }
        
    }


    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/move/challenges");
    }


    saveChallenge(data) {
        let params = _.omit(data, ['can_be_deleted']);
        if(this.props.match.params.challenge_id !== null) {
            params.challenge_id = this.props.match.params.challenge_id;
        }
        if(typeof params.schedule_type === "object") {
            params.schedule_type = params.schedule_type.value;
        }        
        this.props.saveChallenge(params).then((response) => {
            if(response.status !== undefined) {
                Utils.showSuccessAlert("Your have created a challenge.");
                history.push("/practice/"+this.props.selectedRole.practice.id+"/move/challenge/"+response.id+"/workouts");
            }
        }).catch((error) => {
            //do nothing
        })
    }
        
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        challenge: state.move.challengeReducer.challenge,
        initialValues: state.move.challengeReducer.challenge.latest_version,
        supportingData: state.move.challengeReducer.supporting_data,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getChallenge,    
            saveChallenge,        
            cleanUp: ChallengeAction.cleanModule
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description',
        'coach',
        'schedule_type',
        'length',        
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ChallengeEditForm = reduxForm(
    {
        form: 'challengeEditForm',
        enableReinitialize: true,
        validate
    }
)(ChallengeEdit);


const ChallengeEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(ChallengeEditForm);
export default ChallengeEditFormConnected;