import { combineReducers } from 'redux';
import groupReducer from './group_reducer';
import clientGroupReducer from './client_group_reducer';
import groupWorkouts from './group_workouts';
import healthDocument from './health_document';
import groupHabit from './group_habit';
import clientGoalTracking from './client_goal_tracking';
import clientGroupProgressionDatesReducer from './client_group_progressions_dates_reducer';


export default combineReducers({
    groupReducer,
    groupWorkouts,
    healthDocument,
    groupHabit,
    clientGoalTracking,
    clientGroupReducer,
    clientGroupProgressionDatesReducer
});