import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/treatment_template_habit_edit";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';
import Swal from 'sweetalert2';
import { getHabit, postHabit } from '../../redux/treatment_template/network/treatment_template_program';
import actions from '../../redux/treatment_template/actions/treatment_template_program_creator';

class TreatmentHabitEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
    };

    componentWillMount() {
        this.props.getHabit({
            id: this.props.habitId,
        });
    }

    componentWillReceiveProps (nextProps) {
        if (this.props.schedule_type && nextProps.schedule_type  && this.props.schedule_type !== nextProps.schedule_type  ) {
            if (nextProps.schedule_type === 4 && nextProps.duration_type === 1) {
                nextProps.change("duration_type", 2);
            }else if (nextProps.schedule_type === 5 && (nextProps.duration_type === 1 || nextProps.duration_type === 2)) {
                nextProps.change("duration_type", 3);
            }
        }
    }

    save(data) {
        console.log('habit data', data)
        let params = Utils.flatternFormData(data);
        params.treatment_template_id = this.props.treatment_template.id;
        this.props.postHabit(params).then(() => {
            this.props.finishEditing();
        }).catch(() => {
        });
    }

    cancel() {
        this.props.finishEditing();
    }

    componentWillUnmount() {
        this.props.fillHabit({});
    }

    deleteVideo() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete the video?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
                that.props.change('video_guid', null);
                that.props.change('thumbnail', null);         
            }
        }, function (dismiss) {
                        
        });
    }

    onVideoUploadCompleted(result) {
        this.props.change('video_guid', result.guid);
        this.props.change('thumbnail', result.thumbnail);
    }

    onVideoUploadFailed(error) {
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }


    render() {
        if (_.isEmpty(this.props.habit)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('TreatmentHabitEditForm');
function mapStateToProps(state) {
    return {
        habit: state.treatment_template.treatmentTemplateProgramReducer.habit,
        initialValues: state.treatment_template.treatmentTemplateProgramReducer.habit,
        supporting_data: state.treatment_template.treatmentTemplateProgramReducer.supportingData,
        video_guid: selector(state, 'video_guid'),
        schedule_type: selector(state, 'schedule_type'),
        duration_type: selector(state, 'duration_type'),
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getHabit,
        postHabit,
        fillHabit: actions.fillHabit,
    }, dispatch);
}

const validate = (values, props) => {
    let requiredFields = [
        'name','text','schedule_type','duration_type','duration_count'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
        if (field === "schedule_type") {
            if (values[field] === 4) {
                if (values["schedule_week_day"] === undefined || values["schedule_week_day"] === null) {
                    errors["schedule_week_day"] = 'Required';
                } 
                if (values["duration_type"] === 1) {
                    errors["duration_type"] = 'Duration type cannot be in days when selected schedule type is once a week';
                }
            } else if (values[field] === 5) {
                if (values["schedule_month_date"] === undefined || values["schedule_month_date"] === null) {
                    errors["schedule_month_date"] = 'Required';
                } else if (values["schedule_month_date"] > 31) {
                    errors["schedule_month_date"] = 'Day of month must be less than or equal to 31';
                }
                if (values["duration_type"] === 1 || values["duration_type"] === 2) {
                    errors["duration_type"] = 'Duration type cannot be in days or weeks when selected schedule type is once a month';
                }
            }
        }
    });
    return errors;
};


const TreatmentHabitEditForm = reduxForm(
    {
        form: 'TreatmentHabitEditForm',
        enableReinitialize: true,
        validate
    }
)(TreatmentHabitEdit);
const TreatmentHabitEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(TreatmentHabitEditForm);
export default TreatmentHabitEditFormConnected;
