
import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {
  showProgress,
  hideProgress
} from '../../core/actions/progress_creator';
import exerciseListAction from '../actions/exercise_list';
import exerciseAction from '../actions/exercise';

const listContent = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
     const url = '/' + API_VERSION + '/' + PACKAGE + '/news_list';
    return Axios.post(url, params)
      .then(async (response) => {
        if(params.category){
          dispatch(showProgress());
          dispatch(
          exerciseListAction.fillExerciseListCategory(
            response['news_list'],
            response['paginate_info']
          ))
        }
        else {
        dispatch(hideProgress());
        dispatch(
          exerciseListAction.fillExerciseList(
            response['news_list'],
            response['paginate_info']
          )
        );
      }
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};

const getCategory = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
     const url = '/' + API_VERSION + '/' + PACKAGE + '/categories';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(hideProgress());
        dispatch(
          exerciseAction.categoryFill(
            response['news_categories']
          )
        );
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};
const contentSearch = (params) => {

  return (dispatch) => {
    const url =
      '/' + API_VERSION + '/' + PACKAGE + '/content_search';
    return Axios.post(url, params)
      .then(async (response) => {

        let first = false;
        if (params.first) {
          first = true;
        }
        dispatch(
          exerciseListAction.fillExerciseFilterData(
            response['content'],
            first
          )
        );
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};

const associatedContentSearch = (params) => {
  return (dispatch) => {
    const url =
      '/' + API_VERSION + '/' + PACKAGE + '/associated_content_search';
    return Axios.post(url, params)
      .then(async (response) => {

        let first = false;
        if (params.first) {
          first = true;
        }
        if(params.category==null){
          dispatch(
            exerciseListAction.fillContentFilterList(
              response['content'],
              first
            )
          );
        }
        
          dispatch(
            exerciseListAction.fillExerciseFilterData(
              response['content'],
              first
            )
          );
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};

const getAssociatedContent = (params) => {
  
  return (dispatch) => {
    const url =
      '/' + API_VERSION + '/' + PACKAGE + '/program_search';
    return Axios.post(url, params)
      .then(async (response) => {

        // let first = false;
        // if (params.first) {
        //   first = true;
        // }
        dispatch(
          exerciseListAction.fillAssociatedContentList(
            response['program'],
          )
        );
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};


const saveSearchPreferences = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url =
      '/' + API_VERSION + '/' + PACKAGE + '/exercise_save_search_preference';
    return Axios.post(url, params)
      .then(async (response) => {
        Utils.showSuccessAlert('Successfully saved search preferences');
        dispatch(hideProgress());
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const getContent = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/news_get';
    return Axios.post(url, params)
      .then(async (response) => {

        dispatch(hideProgress());
        dispatch(
          exerciseAction.fillExercise(
            response['news'],
            // response['supporting_data']
          )
        );
         }) 
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const postExercise = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/news_post';
    return Axios.post(url, params)
      .then(async (response) => {
  
        dispatch(hideProgress());
        Utils.showSuccessAlert(` ${params.content_type} Successfully Saved`);
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const deleteExercise = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/news_delete';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(exerciseListAction.removeExerciseList(params));
        dispatch(hideProgress());
        Utils.showSuccessAlert('Content has been deleted');
        return response;
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const toggleExerciseFavorite = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_toggle_favorite';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(
          exerciseListAction.updateExerciseListAfterToggleFavorite(
            response['exercise']
          )
        );
        dispatch(hideProgress());
        if (response['exercise'].is_favorite) {
          Utils.showSuccessAlert(
            'Selected exercise has been added to your favorite list'
          );
        } else {
          Utils.showSuccessAlert(
            'Selected exercise has been removed from your favorite list'
          );
        }
        return response;
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};
const uploadMedia = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_media_upload';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(hideProgress());
        Utils.showSuccessAlert('Successfully Uploaded');
        return response;
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const hideContent = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/news_toggle_hide';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(hideProgress());
        Utils.showSuccessAlert('Unpublished Successfully');
        return response;
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};

export {
  hideContent,
  listContent,
  getCategory,
  getAssociatedContent,
  associatedContentSearch,
  contentSearch,
  getContent,
  postExercise,
  deleteExercise,
  uploadMedia,
  toggleExerciseFavorite,
  saveSearchPreferences
};
