import React  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_goal_target_edit.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, arrayPush, arrayRemove } from 'redux-form';
import { history } from '../../redux/store';
import { postClientGroupGoalTarget } from '../../redux/group/network/client_group_network';
import store from '../../redux/store';
import { getGoalTrackingUnitList } from '../../redux/goal/network/goal_network';

class GroupClientGoalEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
    };

    componentWillMount() {
        this.props.getGoalTrackingUnitList({});
    }

    doSave(params) {
        params = {
            ...params,
            goal_questions: _.map(params.goal_questions, (tracking) => { return Utils.flatternFormData(tracking); }),
        };
        params.group_id = this.props.group.id;
        params.goal_id = this.props.goal.id;
        this.props.postClientGroupGoalTarget(params);
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }
}

const selector = formValueSelector('ClientGroupGoalEditForm');

function mapStateToProps(state, ownProps) {
    let group = ownProps.group;
    let goal = group.goal;
    return {
        initialValues: {goal_questions: goal.client_goal_questions},
        goal: goal,
        group:group,
        goalTrackingUnits: state.goal.goalReducer.goal_tracking_unit,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postClientGroupGoalTarget,
        getGoalTrackingUnitList,
    }, dispatch);
}

const validate = (values, props) => {
    const errors = {};
    const goalTrackingArrayErrors = [];
    values.goal_questions.forEach((goal_question, i) => {
        const goalTrackErrors = {};
        if(!goal_question.question || goal_question.question === ""){
            goalTrackErrors.question = "Required";
        }
        if(goal_question.initial_value === null || goal_question.initial_value === ""){
            goalTrackErrors.initial_value = "Required";
        }
        if(goal_question.target_value === null || goal_question.target_value === ""){
            goalTrackErrors.target_value = "Required";
        }
        if(!goal_question.track_unit || goal_question.track_unit === "" || goal_question.track_unit === null ){
            goalTrackErrors.track_unit = "Required";
        }
        goalTrackingArrayErrors[i] = goalTrackErrors;
    });
    errors.goal_questions = goalTrackingArrayErrors;
    return errors;
};

const GroupClientGoalEditForm = reduxForm(
    {
        form: 'ClientGroupGoalEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupClientGoalEdit);

const GroupClientGoalEditConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClientGoalEditForm);
export default GroupClientGoalEditConnected;
