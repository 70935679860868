import React  from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/program_list_new";
import { reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    listProgramDHF, deleteProgramDHF, toggleFavoriteDHF, saveAsDHF, cloneDHF, getProgramFiltersDHF
} from '../../redux/program/network/program_list_dhf';
import { getProgram, getWorkout, postProgram, postWorkout } from '../../redux/program/network/program_edit';
import {
    listProgram, deleteProgram, toggleFavorite, saveAs, clone,
    createWorkoutFromProgram, getProgramFilters
} from '../../redux/program/network/program_list';
import { getMoveVideoList } from '../../redux/dhf/move/network/move_network_new';
import actionCreator from '../../redux/program/actions/program_list';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import Swal from 'sweetalert2';
import { WORKOUT_TARGET_CLIENT, TEMPLATE_SELECTION_MODE, WORKOUT_TARGET_GROUP } from '../../redux/program/constants/constants';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { PROGRAM_LIST_VIDEO } from '../../utils/help_video';
import ClientAction from '../../redux/engagement/actions/client_creator';
import { getPracticePaymentSubscriptionDetails } from '../../redux/payment/network/plan_subscribe_network' 

let categoryLevel2 = [];
let categoryLevel3 = [];
class ProgramList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.refreshProgramList = this.refreshProgramList.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.saveAs = this.saveAs.bind(this);
        this.goToPaywall = this.goToPaywall.bind(this);
        this.clone = this.clone.bind(this);
        this.cancel = this.cancel.bind(this);
        this.createWorkoutFromProgram = this.createWorkoutFromProgram.bind(this);
        this.createWorkoutFromWorkoutTemplate = this.createWorkoutFromWorkoutTemplate.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.drawerToggle = this.drawerToggle.bind(this);
        this.setFilterPreferences = _.debounce(this.setFilterPreferences.bind(this),1000);
        this.selectRender = this.selectRender.bind(this);
        this.valueRender = this.valueRender.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);      
        this.refillData = this.refillData.bind(this);      
        this.onChangeQuickstartSelectionList = this.onChangeQuickstartSelectionList.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onChangeCategoryLevel1 = this.onChangeCategoryLevel1.bind(this);
        this.onChangeCategoryLevel2 = this.onChangeCategoryLevel2.bind(this);

        this.state = {
            search:"",
            filterPreferences:{first:true},
            previewingExercise: null,
            showFilter: false,
            all_templates_checked: true,
            'quickstart_type': 'visible',
            'quickstartSelectionList': [
                {
                    'value': 'visible',
                    'label': 'Visible Quickstarts'
                },
                {
                    'value': 'hidden',
                    'label': 'Hidden Quickstarts'
                }
            ]
        };

    }
    goBack(){
        if(this.props.group_id){
            this.props.cancelTemplateSelection()
        }else {
            history.push(`/practice/${this.props.selectedRole.practice.id}/home`)
        }
    }
    componentDidUpdate() {

    }
    cancel(){
        this.props.selectionWorkoutToggle()
    }
        setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    onChangeCategoryLevel1(value) {
        let selectedCategoryLevel2 = _.filter(
          this.props.filterData.category_level2,
          { category: value }
        );
        return categoryLevel2 = selectedCategoryLevel2
    }
    onChangeCategoryLevel2(value) {
        let selectedCategoryLevel3 = _.filter(
            this.props.filterData.category_level3,
            { category_level2: value }
            );
      return categoryLevel3 = selectedCategoryLevel3
    }
    saveAs(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to save this " + that.props.typeLCase + " as " +
            (that.props.programType === 'template' ? 'quickstart' : 'template') + "?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
                that.props.saveAs({ id: id, save_as_type: that.props.typeParam === 0 ? 1 : 0 });
            } else {
                return
            }
        }, function (dismiss) {

        });
    }

    clone(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to clone this " + this.props.typeLCase + "?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
                that.props.clone({ id: id }).then(() => {
                }).catch(() => { });
            } else {
                return
            }
        }, function (dismiss) {
            
        });
    }

    toggleFavorite(id, is_favorite) {
        this.props.toggleFavorite({
            id: id,
        }).then(() => {
            this.fetchFilterData();
        }).catch(() => { });
    }

    onChangeQuickstartSelectionList(quickstart_type) {
        this.setState({ 'quickstart_type': quickstart_type }, () => {
            this.refreshProgramList(this.props);
        });
    }

    refreshProgramList(props, increment = false, first = false) {
        let params = {
            search : this.state.search,
            program_type: props.typeParam,
            practice_id: props.practice_id,
            package_version: 3,
            ...props.paginateInfo,
            ...this.state.filterPreferences
        };
        if(first){
            params.first = first;
        }
        if (increment) {
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        if(this.state.all_templates_checked===false){
            if(!this.props.user.gi_isadmin){
                params.admin_program = false
            }
        }else {
            if(!this.props.user.gi_isadmin){
                params.admin_program = true
            }
        }
        params["quickstart_type"] = this.state.quickstart_type;
        if(this.props.match && this.props.match.params && this.props.match.params.type == 'my_templates'){
            params.first = false;
            params.my_programs = true,	
            params.my_practice_programs = true,	
            params.admin_program = false,	
            params.partners = [	
                "my_programs",	
                "my_practice_programs"	
            ]
        }
        this.props.listProgram(params);
    }

    onSearch(text){
        this.setState({search:text.target.value},()=>{
            this.refreshProgramList(this.props);
        });
    }

    drawerToggle = () => {
        this.setState({showFilter: !this.state.showFilter});
    };


    componentDidMount() {
        this.refillData(this.props);
        window.scrollTo(0, 0);
    }

    refillData(props) {
        this.refreshProgramList(props,false, true);
        this.fetchFilterData();
        if (props.programType === "template") {
            props.updateHelpVideo(PROGRAM_LIST_VIDEO);
        } 
        this.setState({ showFilter: false });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.typeParam !== nextProps.typeParam) {
            this.refillData(nextProps);
        } else {
            if(!_.isEmpty(this.props.current_filters) && !_.isEqual(this.props.current_filters,nextProps.current_filters)){
                this.setFilterPreferences(nextProps.current_filters);
            }  
        }
    }

    delete(id, toggle_visibility) {
        let display_text = "";
        if(toggle_visibility === "hide") {
            display_text = "Are you sure you want to hide this " + this.props.typeLCase + "?";
        } else {
            display_text = "Are you sure you want to unhide this " + this.props.typeLCase + "?";
        }
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
            that.props.deleteProgram({ 
                'program_type': that.props.typeLCase,
                id: id,
                'visibility': toggle_visibility, 
            })
        } else {
            return
        }
        }, function () {
        });
    }

    goToPaywall() {
        let that = this;
        Swal.fire({
            customClass: {
                container: 'paywall-modal',
                title: 'paywall-title',
                html:'paywall-text',
                confirmButton: 'paywall-confirm-button',
                cancelButton: 'paywall-cancel-button',
              },
            title: "Active Subscription Required",
            html: '<p>Use of this premium template requires an active Trainer Plus Subscription, which can be purchased from the Gray Institute mobile app.</p></br>' + "<p>To access this template and all other premium templates, please purchase the required subscription from your App Store.</p>",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#fff",
            confirmButtonText: "Go to App Store",
            cancelButtonText: "Go to Play Store",
            useRejections:true
        }).then((result) => {
            if (result.value) {
                window.open('https://apps.apple.com/us/app/dhf-coachfit/id1130877074', '_blank');
              } else if (result.dismiss=='cancel') {
                window.open('https://play.google.com/store/apps/details?id=com.gomotive.dhf', '_blank');
              }
        }, function (dismiss) {
        });
    }

    createWorkoutFromProgram(id) {
        let params = {
            program_id: id,
        };
        if (_.has( this.props,"treatment_template_id")) {
            params.treatment_template_id = this.props.treatment_template_id;
        } else if(this.props.workoutTargetType === WORKOUT_TARGET_CLIENT){
            params.engagement_id = this.props.engagement_id;
        }else if(this.props.workoutTargetType === WORKOUT_TARGET_GROUP){
            params.group_id = this.props.group_id;
        }
        if(this.props.user.gi_isadmin){
            this.props.createWorkoutFromProgram(params).then((response) => {
                this.props.afterCreateWorkoutFromProgram(response.id);
            }).catch(() => { });
        }else  {
            this.props.getPracticePaymentSubscriptionDetails({
                "package_version": "3", 
                "app_version": "5.4", 
                "current_role_type": "practice_admin", 
                "practice_id": this.props.practice_id
            }).then((res)=>{ })
            this.props.getProgram({"id": id}).then((res) =>{
            if(res.program.is_free){
                if(this.props.user.id !== res.program.owner.id){
                    if(!this.props.isTemplatesPurchased){
                        this.goToPaywall()
                    }else {
                        this.props.createWorkoutFromProgram(params).then((response) => {
                            this.props.afterCreateWorkoutFromProgram(response.id);
                        }).catch(() => { });
                    }
                }else {
                    this.props.createWorkoutFromProgram(params).then((response) => {
                        this.props.afterCreateWorkoutFromProgram(response.id);
                    }).catch(() => { });
                }
            } else {
                this.props.createWorkoutFromProgram(params).then((response) => {
                    this.props.afterCreateWorkoutFromProgram(response.id);
                }).catch(() => { });
            }

        })
    }  
    }

    createWorkoutFromWorkoutTemplate(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to create a workout for your client " + this.props.selectedClientForAction["name"] + " ?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {
                program_id: id,
            };
            params.engagement_id = that.props.selectedClientForAction["active_engagement_id"];        
            that.props.createWorkoutFromProgram(params).then((response) => {
                if(that.props.business_partner.app_type === "gi") {
                    history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + that.props.selectedClientForAction["active_engagement_id"] + "?tab=1&workout="+response["id"]);
                } else {
                    history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + that.props.selectedClientForAction["active_engagement_id"] + "?tab=3&workout="+response["id"]);
                }
            }).catch(() => { });
        }, function () {
        });        
    }

    add() {
        if(this.props.match && this.props.match.params && this.props.match.params.type == 'my_templates'){
            history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + "/add/" + this.props.match.params.type);
        } else {
            history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + "/add");
        }
    }

    edit(id) {
        if(this.props.match && this.props.match.params && this.props.match.params.type == 'my_templates'){
            history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + "/edit/"+id+"/"+this.props.match.params.type);
        } else {
            history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + "/edit/" + id);
        }
    }

    applyFilter(){
        let that = this;
        this.setState({showFilter:false},()=>{
            setTimeout(function() {
                that.refreshProgramList(that.props);
            }, 500);  
        });
    }

    resetFilter(){
        this.props.reset();
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
        this.props.clearHelpVideo();
        this.props.clearClientSelection();
    }

    fetchFilterData(){
        this.props.getProgramFilters(this.state.filterPreferences);
    }

    setFilterPreferences(filterData){
        let params = {};
        if(filterData.my_deleted_templates) {
            params["my_deleted_templates"] = true;
            this.setState({filterPreferences:params});
        } else {
            params["my_deleted_templates"] = false;
            if(_.has(filterData,"is_favorite")){
                params.is_favorite = filterData.is_favorite;
            }
            if(filterData.category && filterData.category){
                params.category = [filterData.category];
                if(filterData.category === 5 &&  filterData.sports && filterData.sports.length > 0){
                    params.sports =  Utils.makeObjectArrayFlat(filterData.sports,"id"); 
                }
                if(filterData.category === 5  && filterData.sports_exercise_type){
                    params.sports_exercise_type =  [filterData.sports_exercise_type];
                }
            }
            if(filterData.category_level2){
                params.category_level2 = filterData.category_level2;
            }
            if(filterData.category_level3){
                params.category_level3 = filterData.category_level3;
            }
            if(_.includes(filterData.program_sources, "my_programs")){
                params.my_programs = true;
            }
            if(_.includes(filterData.program_sources, "my_practice_programs")){
                params.my_practice_programs = true;
            }
            let partners  = _.filter(filterData.program_sources,(item)=>{
                if(item === "my_programs" || item === "my_practice_programs"){
                    return true;
                }else{
                    return true;
                }
            });   

            if(partners.length > 0){
                if(this.props.program_sources.includes("all_templates")){
                    this.setState({
                        all_templates_checked: true
                    })
                    params.partners= ["my_programs", "my_practice_programs",'2','10'];
                }else {
                    this.setState({
                        all_templates_checked: false
                    })
                    params.partners= ["my_programs", "my_practice_programs"];
                }
            }
            if(this.props.match && this.props.match.params && this.props.match.params.type == 'my_templates'){
                params.first = false
                params.my_programs = true,	
                params.my_practice_programs = true,	
                params.admin_program = false,	
                params.partners = [	
                    "my_programs",	
                    "my_practice_programs"	
                ]
            }
            params.package_version = 3
            this.setState({filterPreferences:params},()=>{
                this.fetchFilterData();
            });
        }
    }

    selectRender(option){
        return (
            <span>{option.label ? option.label : option.name}<span className="pull-right badge bg-success" >{option.count ? option.count : 0}</span></span>
        );
    }

    valueRender(option){
        return (
            <span className="vertical-align">{option.label ? option.label : option.name}<span className="badge bg-success m-l-10" >{option.count ? option.count : 0}</span></span>
        );
    }

}

ProgramList.propTypes = {
    // indicates whether this component is re-used inside another component
    usedInside: PropTypes.bool,
    afterCreateWorkoutFromProgram: PropTypes.func,
    cancelTemplateSelection: PropTypes.func,
};
ProgramList.defaultProps = {
    usedInside: false,
    afterCreateWorkoutFromProgram: (workout_id) => {        
        history.push(Utils.getProgramUrlPrefix('workout',this.props.selectedRole) + "/edit/" + workout_id);
    },
    cancelTemplateSelection: () => { },
};
const selector = formValueSelector('ProgramFilterForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPracticePaymentSubscriptionDetails,
        getProgram,
        deleteProgram,
        listProgram,
        listProgramDHF,
        getMoveVideoList,
        toggleFavorite,
        saveAs,
        clone,
        clean: actionCreator.cleanModule,
        createWorkoutFromProgram,
        getProgramFilters,
        updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearHelpVideo: HelpVideoCreator.clearHelpVideo,
        clearClientSelection: ClientAction.clientSelectionClear,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let programSources = [];
    let filterData = state.program.programList.filterData;
    let searchPreferences = state.program.programList.searchPreferences;
    let initialValues = {
        program_sources:[],
        category:null,
        sports_exercise_type:[],
        is_favorite:_.has(searchPreferences,"is_favorite")  ? searchPreferences.is_favorite : false,
    };
    if(_.has(filterData,"partners")){
        initialValues.program_sources.push('all_templates');
    }
    if(_.has(searchPreferences,"my_practice_programs") && searchPreferences.my_practice_programs){
        initialValues.program_sources.push("my_practice_programs");
    }
    if(_.has(searchPreferences,"my_programs") && searchPreferences.my_programs){
        initialValues.program_sources.push("my_programs");
    }
    if(!_.isEmpty(filterData)){
        programSources.push({label:"My Templates",value:"my_programs",count:filterData.my_programs});
        programSources.push({label:"My Practice Templates",value:"my_practice_programs",count:filterData.practice});
        // _.forEach(filterData.partners,(item)=>{
        //     programSources.push({label:"All Templates",value: item.id, count: item.count});
        // });
        if(!_.isEmpty(filterData.partners)){
            let count= 0;
            // let id = [];
            _.forEach(filterData.partners,(item)=>{
                count+=item.count;
            });

            programSources.push({label:"All Templates", value: 'all_templates', count: count});
        }
    }
    return {
        categoryLevel2,
        categoryLevel3,
        supportingData: state.program.programEdit.supportingData,
        category_level2: selector(state, 'category_level2'),
        category_level3: selector(state, 'category_level3'),

        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        programList: state.program.programList.programList,
        program_is_paid: state.program.programList.programList,
        move_videos: state.move.moveReducer.move_videos,
        paginateInfo: state.program.programList.paginateInfo,
        user: state.auth.authReducer.user,
        user_subscription: state.auth.authReducer.user.user_subscription_active,
        isTemplatesPurchased : state.auth.authReducer.user.isTemplatesPurchased,
        selectedRole: state.auth.roleReducer.selectedRole,
        filterData:filterData,
        programSources:programSources,
        initialValues:initialValues,
        my_deleted_templates: selector(state, 'my_deleted_templates'),
        category: selector(state, 'category'),
        program_sources: selector(state, 'program_sources'),
        selectedClientForAction: state.engagement.clientReducer.selected_client_for_action,
        business_partner: state.practitioner.practitionerReducer.business_partner,
        current_filters: selector(
            state,
            'my_deleted_templates',
            'category', 
            'category_level2', 
            'category_level3', 
            'sports', 
            'program_sources',
            'sports_exercise_type',
            'is_favorite'
        ),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ProgramListFilterForm = reduxForm(
    {
        form: 'ProgramFilterForm',
        enableReinitialize: true,
        validate
    }
)(ProgramList);


const ProgramListFilterFormConnected = connect(mapStateToProps, mapDispatchToProps)(ProgramListFilterForm);
export default ProgramListFilterFormConnected;
