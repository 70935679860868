import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/engagement_appointments";
import swal from 'sweetalert2';
import { deleteAppointmentForEngagement } from '../../redux/engagement/network/engagement_network';


class EngagementAppointments extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.hideAppointmentEditModal = this.hideAppointmentEditModal.bind(this);
        this.showAppointmentEditModal = this.showAppointmentEditModal.bind(this);
        this.deleteAppointment = this.deleteAppointment.bind(this);
        this.state = {
            openAppointmentEditModal: false,
            editAppointmentObject: null,
        };
    };

    doClose() {
    }

    hideAppointmentEditModal() {
        this.setState({ 
            openAppointmentEditModal: false,
            editAppointmentObject: null
        });
    }

    showAppointmentEditModal(appointment) {
        this.setState({ 
            openAppointmentEditModal: true,
            editAppointmentObject: appointment
        });
    }

    deleteAppointment(appointment) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You are about delete an appointment option permanently!",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.deleteAppointmentForEngagement({ engagement: that.props.engagement.id, id: appointment.id }).then((reponse) => {
                swal.close();
            }).catch((error) => {
                swal.close();
            });
        }, function (dismiss) {

        });
    }



    render() {
        if (_.isEmpty(this.props.engagement)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

EngagementAppointments.propTypes = {
    engagement:PropTypes.object.isRequired,    
};


function mapStateToProps(state, ownprops) {
    return {
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            deleteAppointmentForEngagement,            
        }, 
        dispatch
    );
}

const EngagementAppointmentsConnected = connect(mapStateToProps,mapDispatchToProps)(EngagementAppointments);
export default EngagementAppointmentsConnected;
