import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GroupClassBooking from '../group_class_booking.jsx';
export default function () {
    function repeatGroupClass1(groupClass, groupClassIndex) {
        return React.createElement(Tr, { 'key': 'row' + groupClassIndex }, React.createElement(Td, {
            'column': 'name',
            'value': groupClass.name
        }, React.createElement('span', {}, groupClass.name)), React.createElement(Td, { 'column': 'practice' }, React.createElement('span', {}, groupClass.practice.name)), React.createElement(Td, {
            'column': 'start_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, moment(groupClass.start_date).format('MMMM Do YYYY'))), React.createElement(Td, {
            'column': 'end_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, moment(groupClass.end_date).format('MMMM Do YYYY'))), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, React.createElement(Button, {
            'onClick': () => {
                this.bookGroupClassScheduler(groupClass);
            },
            'tooltip': 'Book Group Class',
            'label': 'Book',
            'className': 'small-button',
            'primary': true,
            'raised': true
        }))));
    }
    return React.createElement('div', { 'className': 'm-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'No group class.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, { 'column': 'practice' }, React.createElement('strong', {}, 'Practice')), React.createElement(Th, {
            'column': 'start_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Start date')), React.createElement(Th, {
            'column': 'end_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'End date')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.groupClassList, repeatGroupClass1.bind(this))
    ]));
}