import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  MenuTemplate from "./templates/practitioner_menu";
import store,{history} from '../../redux/store';
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import {Link} from 'react-router-dom';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import RoleAction from '../../redux/auth/actions/role_creator';
import {PARTNER} from '../../core/constants/constants';
import { ssoLoginLMS } from '../../redux/auth/network/auth_network';



class Menu extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.goto = this.goto.bind(this);
        this.switchRole = this.switchRole.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.toggleMarketingMenu = this.toggleMarketingMenu.bind(this);
        this.toggleHomefitMenu = this.toggleHomefitMenu.bind(this);
        this.toggleTrainerMenu = this.toggleTrainerMenu.bind(this);
        this.redirectToLMS = this.redirectToLMS.bind(this);
        this.state = {
            showSidebar: false,
            showMarketingMenu: false,
            showHomefitMenu: false,
            showTrainerMenu: false,
        }
    };

    redirectToLMS(userType){
        this.props.ssoLoginLMS({
            "userId": this.props.user.remote_user_id,
            "token": this.props.auth.ssoData.PHPSESSID,
            "userType" : userType
        }).then((resp)=>{
            console.log('sso resp', resp)
            window.open(`${resp.data.url}?${resp.data.access_token}`, "_self")
        })
    }
    goto(url){
        history.push(url);
    } 

    switchRole() {
        this.props.chooseRole({});
        localStorage.setItem("selectedRole",0);
        history.push("/");
    }

    doLogout() {
        this.props.doLogout();
        try {
            window.zE(function () {
                zE.hide();
            });
        } catch (err) {            
        }  
    }

    toggleSidebar() {
        let newState = !this.state.showSidebar
        this.setState({
            showSidebar: newState,
        })
    }
    closeSidebar() {
        this.setState({
            showSidebar: false,
        })
    }  
    toggleMarketingMenu() {
        let newState = !this.state.showMarketingMenu
        this.setState({
            showMarketingMenu: newState,
        })
    }    
    toggleTrainerMenu() {
        let newState = !this.state.showTrainerMenu
        this.setState({
            showTrainerMenu: newState,
        })
    }
    toggleHomefitMenu() {
        let newState = !this.state.showHomefitMenu
        this.setState({
            showHomefitMenu: newState,
        })
    }

    render() {
        if(!this.props.isAuthenticated ||  _.isEmpty(this.props.selectedRole) || (this.props.selectedRole.role.name !== "practitioner" &&  this.props.selectedRole.role.name !== "practice_admin") ){
          return (<div>Loading....</div>);
        }
        return MenuTemplate.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        chooseRole:RoleAction.chooseRole,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
        ssoLoginLMS
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        user: state.auth.authReducer.user,
        auth: state.auth.authReducer,
        roles: state.auth.roleReducer.roles,
    };
}

const MenuConnected = connect(mapStateToProps, mapDispatchToProps)(Menu);
export default MenuConnected;

