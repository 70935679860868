import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_booking_edit";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { reduxForm, formValueSelector } from 'redux-form';
import { groupClassSchedulerBookingGet, groupClassSchedulerBookingPost } from '../../redux/calendar/network/group_class_network';
import moment from 'moment';

class GroupClassBookingEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.state = {
            data_loaded: false,
        };
    }

    componentWillMount() {
        this.props.groupClassSchedulerBookingGet({
            'group_class_id': this.props.groupClassId,
            'client_id':this.props.bookedClientId,
        }).then((response) => {
            this.setState({
                data_loaded: true,
            });
        })
        .catch((error) => { 

        });
    }

    doSave(data){
        let that = this;
        let params = _.omit(data,"client_bookings");
        params['group_class_schedule'] = [];
        _.forEach(data.client_bookings, function(value, key) {
            params['group_class_schedule'].push.apply(params['group_class_schedule'], value.bookings);
        }) ;   
        this.props.groupClassSchedulerBookingPost({
            ...params,
            app_version: "5.2",
            current_role_type: "practice_admin",
            package_version: "1",
            practice_id: 276,
        })
        .then((response) => {
            that.props.closeBookingDialog();
        })
        .catch((error) => { 

        });
    }

    componentWillUnmount() {        
    }

    render() {
        return Template.apply(this);
    }
}

const validate = (values, props) => {
    let requiredFields = [
        'client',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const GroupClassBookingEditForm = reduxForm({
        form: 'GroupClassBookingEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupClassBookingEdit);

const selector = formValueSelector('GroupClassBookingEditForm');


function mapStateToProps(state, ownProps) {
    let group_class = _.cloneDeep(state.calendar.groupClassReducer.group_class);
    let bookedClient = state.calendar.groupClassReducer.booked_client;
    let initialValues = {entire_series:false,client:null,group_class_id:ownProps.groupClassId,client_bookings:{}};
    if(!_.isEmpty(bookedClient)){
        initialValues["client"] = bookedClient.id;
        _.forEach(group_class.group_class_frequency,(frequency)=>{
            initialValues["client_bookings"][frequency.id]= {'bookings':[]};
            _.forEach(bookedClient.bookings,(booking)=>{
                if(booking.group_class_schedule.group_class_frequency === frequency.id){
                    initialValues["client_bookings"][frequency.id]["bookings"].push(booking.group_class_schedule.id);
                }
            });    
        });
    }else{
        _.forEach(group_class.group_class_frequency,(frequency)=>{
            initialValues["client_bookings"][frequency.id] = {'bookings':[]};
        });
    }

    let scheduledList = state.calendar.groupClassReducer.schedule_date_list;
    let scheduledDateList = [];
    _.forEach(scheduledList, function(schedule) {
        let dateObj = {};
        dateObj['id'] = schedule.id;
        const start_time = moment(schedule.start_time).format('h:mm a');
        const end_time = moment(schedule.end_time).format('h:mm a');
        const date = moment(schedule.date,"YYYY-MM-DD").format("MMM DD, YYYY");
        dateObj['name'] = date + " ( "+start_time+ " to "+end_time+" )";
        scheduledDateList.push(dateObj);
    });   
    group_class.group_class_frequency = _.map(group_class.group_class_frequency,(frequency)=>{
        frequency.schedule_dates =  _.map(frequency.schedule_dates,(item)=>{
            item['name'] =moment(item.date,"YYYY-MM-DD").format("MMM DD, YYYY");
            return item;
        });    
        return frequency;
    });
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: initialValues,
        group_class: group_class,
        bookedClient: bookedClient,
        clientList: state.calendar.groupClassReducer.client_list,
        scheduledDateList: scheduledDateList,
        entire_series: selector(state, 'entire_series')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        groupClassSchedulerBookingGet,
        groupClassSchedulerBookingPost
    }, dispatch
    );
}


const GroupClassBookingEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassBookingEditForm);
export default GroupClassBookingEditFormConnected;