import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { history } from '../../../redux/store';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, this.props.practice_partner ? React.createElement(Card, {
        'className': 'col-xs-12  p-0',
        'key': '1260'
    }, React.createElement(CardTitle, {
        'title': 'Update Assigned Plans',
        'subtitle': 'Choose specific plans you wish to make available for the Independent Partner'
    }), React.createElement(CardText, { 'className': 'p-t-5' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'available_plans',
        'component': Select,
        'label': 'Assign specific plans for this practice (Leave it empty if you wish to show all your Regular Subscription Plans)',
        'options': this.props.partner_plans,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true + '/'
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Update',
        'className': 'bg-success',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'm-l-10',
        'onClick': this.props.handleClose,
        'disabled': this.props.submitting,
        'type': 'button',
        'accent': true,
        'raised': true
    }))))))) : null);
}