import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_terms_of_use";

class ClientTermsOfUse extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }
    
    componentWillMount() {
    }

    componentWillUnmount() {
    }
    
    render() {
        return Template.apply(this);
    }
}

export default ClientTermsOfUse;
