// Action Creators
const fillCalendarEvent = (event, supportingData) => ({
    type: 'CALENDAR_EVENT_EDIT_FILL',
    event,
    supportingData,
});

const fillClientCalendarEvent = (event, supportingData) => ({
    type: 'CLIENT_CALENDAR_EVENT_FILL',
    event,
    supportingData,
});

const cleanModule = () => ({
    type: 'CALENDAR_EVENT_EDIT_CLEANUP',
});

export default {
    fillCalendarEvent,
    fillClientCalendarEvent,
    cleanModule,
};
