import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Select from '../../../utils/common_components/select';
import SelectComponent from 'react-select';
import { Field } from 'redux-form';
export default function () {
    return React.createElement('div', { 'className': 'row w-100' }, React.createElement('form', {
        'className': 'w-100 no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Field, {
        'name': 'client',
        'component': Select,
        'label': 'Client',
        'options': this.props.clients,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true
    }), React.createElement('div', { 'className': 'col-xs-12 end-xs d-flex justify-center' }, React.createElement(Button, {
        'label': 'Save',
        'style': { height: 'fit-content !important' },
        'type': 'submit',
        'className': 'bg-success',
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.doClose,
        'label': 'Close',
        'className': 'bg-success-border m-l-5',
        'type': 'button',
        'raised': true
    }))));
}