import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
export default function () {
    function repeatPractitioner1(practitioner, practitionerIndex) {
        return React.createElement('div', {
            'className': 'box-shadow bg-white m-10 width-300',
            'key': 'practitioner' + practitioner.id
        }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(ProfilePicture, { 'user': practitioner.user })), React.createElement('div', {
            'className': 'col-xs-6 p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', {
            'className': 'end-xs',
            'style': {
                wordBreak: 'break-word',
                wordSpace: 'pre-line'
            }
        }, practitioner.user.user_state === 3 ? React.createElement('div', {
            'className': 'f-16',
            'key': '1924'
        }, '\n                            ', practitioner.user.name, '\n                        ') : null, practitioner.user.user_state === 2 ? React.createElement('div', {
            'className': 'f-16 c-danger',
            'key': '2094'
        }, '\n                            Not Logged In\n                        ') : null, React.createElement('div', { 'className': 'f-12 f-300 p-t-5' }, '\n                            ', practitioner.user.email, '\n                        ')), React.createElement('div', {
            'className': 'p-absolute',
            'style': {
                top: '115px',
                right: '10px'
            }
        }, practitioner.user.user_state === 2 && this.state.practitionerCategory !== 'inactive' ? React.createElement(IconButton, {
            'tooltip': 'Edit practitioner details',
            'icon': 'create',
            'onClick': () => {
                this.editDetails(practitioner);
            },
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '2507'
        }) : null, practitioner.user.user_state === 2 && this.state.practitionerCategory !== 'inactive' ? React.createElement(IconButton, {
            'tooltip': 'Resend email invitation to join your practice',
            'icon': 'email',
            'onClick': () => {
                this.resendInvitation(practitioner);
            },
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '2902'
        }) : null, this.state.practitionerCategory === 'active' ? React.createElement(IconButton, {
            'tooltip': 'Update practitioners title and bio',
            'icon': 'description',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.updateTitleBio(practitioner);
            },
            'key': '3346'
        }) : null, practitioner.role.id !== 2 && this.state.practitionerCategory === 'active' ? React.createElement(IconButton, {
            'tooltip': 'Make this practitioner inactive from your practice',
            'icon': 'visibility_off',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.hide(practitioner);
            },
            'key': '3749'
        }) : null, this.state.practitionerCategory === 'invited' ? React.createElement(IconButton, {
            'tooltip': 'Make this practitioner inactive from your practice',
            'icon': 'visibility_off',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.hide(practitioner);
            },
            'key': '4191'
        }) : null, this.state.practitionerCategory === 'inactive' ? React.createElement(IconButton, {
            'tooltip': 'Make this practitioner active your practice',
            'icon': 'visibility',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.hide(practitioner);
            },
            'key': '4604'
        }) : null))));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement(Select, {
        'className': 'col-xs-4',
        'value': this.state.practitionerCategory,
        'options': this.props.selectionList,
        'simpleValue': true,
        'onChange': val => {
            this.onChangePractitionerCategory(val);
        }
    })), this.props.practitionerList.length === 0 ? React.createElement('div', {
        'className': 'row m-20 center-xs',
        'key': '1212'
    }, '\n        There are no ', this.state.practitionerCategory, ' practitioners\n    ') : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.practitionerList, repeatPractitioner1.bind(this))
    ]), React.createElement(Button, {
        'className': 'floating-add-button m-r-8',
        'style': { top: '110px' },
        'onClick': this.invitePractitionerScreen,
        'icon': 'add',
        'floating': true,
        'ripple': true
    }));
}