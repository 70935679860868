import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Chip from 'react-toolbox/lib/chip';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Dialog from 'react-toolbox/lib/dialog';
export default function () {
    function repeatAttachment1(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument2(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name,
            'style': { width: '200px' }
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '270px' },
            'className': 'truncate'
        }, React.createElement(TooltipWrapper, {
            'tooltip': document.description,
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description))), React.createElement(Td, {
            'column': 'attachments',
            'style': {
                width: '370px',
                display: 'flex'
            }
        }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5 truncate' },
            _.map(document.attachments, repeatAttachment1.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container document-list edit-btn-small justify-center' }, !this.props.selectionMode && document.practice.id === this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'primary': true,
            'onClick': () => {
                this.editHealthDocument(document.id);
            },
            'className': 'goal-template-action m-r-12',
            'key': '5004'
        }, 'Edit') : null, !this.props.selectionMode && document.practice.id === this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'ripple': true,
            'raised': true,
            'primary': true,
            'tooltip': 'Delete',
            'onClick': () => {
                this.deleteHealthDocument(document.id);
            },
            'className': 'delete-red-icon goal-template-action-delete small-icon-button goal-template-action delete-button',
            'key': '5863'
        }) : null, !this.props.selectionMode && document.practice.id !== this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'onClick': () => {
                this.editHealthDocument(document.id);
            },
            'className': 'goal-template-action m-r-12',
            'raised': true,
            'ripple': true,
            'primary': true,
            'key': '6470'
        }, 'Preview') : null, this.props.selectionMode ? React.createElement(ButtonDefault, {
            'style': { padding: '0 !important' },
            'className': 'red-add-button',
            'onClick': () => {
                this.props.onSelected(document);
            },
            'raised': true,
            'ripple': true,
            'primary': true,
            'key': '6892'
        }, 'Add') : null)));
    }
    return React.createElement('div', { 'className': 'document-list' }, React.createElement('div', { 'className': 'm-t-15 page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Templates'), React.createElement('h2', { 'className': 'page-title with-subtitle text-capital' }, this.props.match.params.document_type)), !this.props.selectionMode ? React.createElement(Button, {
        'className': 'floating-add-button  red-add-button m-r-5',
        'style': { top: '100px' },
        'onClick': this.addHealthDocument,
        'key': '1762'
    }, '\n            Add new\n        ') : null), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-24',
            'id': 'table',
            'noDataText': this.props.selectionMode ? 'You have not uploaded ' + this.props.match.params.document_type + ' documents to GoMotive.' : 'Click on the + icon to add a ' + this.props.match.params.document_type + ' document to your library',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'name',
            'style': { width: '200px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '270px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, {
            'column': 'attachments',
            'style': { width: '370px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.health_documents, repeatDocument2.bind(this))
    ]));
}