import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_exercises.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getAllExerciseList,
    getPartnerExerciseList,
    addPartnerLibraryExercise, 
    removePartnerLibraryExercise,
    addAllExercisesPartnerLibrary,
    removeAllExercisesPartnerLibrary,
} from '../../redux/partner/network/partner_network';
import {listExercise} from '../../redux/exercise/network/exercise';
import partnerActions from '../../redux/partner/actions/partner_action';
import ExerciseActionCreator from '../../redux/exercise/actions/exercise_list';

class PartnerLibraryExercises extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addExercise = this.addExercise.bind(this);
        this.removeExercise = this.removeExercise.bind(this);
        this.loadMoreAllExercises = this.loadMoreAllExercises.bind(this);
        this.addAllExercises = this.addAllExercises.bind(this);
        this.removeAllExercises = this.removeAllExercises.bind(this);
    };

    componentWillMount() {
        if(!this.props.match.params.library_id){
            history.goBack();
        }
        this.loadMoreLibraryExercises();
        this.loadMoreAllExercises();
    }

    loadMoreAllExercises(increment=false) {
        let params = {  
            id:this.props.match.params.library_id,
            ...this.props.paginateInfo
        };
        if(increment){
            params.page = params.page + 1;
        }
        this.props.getAllExerciseList(params);
    }

    loadMoreLibraryExercises(increment=false) {
        let params = {  
            id:this.props.match.params.library_id,
            ...this.props.partner_library.paginate_info
        };
        if(increment){
            params.page = params.page + 1;
        }
        this.props.getPartnerExerciseList(params);
    }

    addAllExercises() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.addAllExercisesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.loadMoreAllExercises();
            this.loadMoreLibraryExercises();
        }).catch((error) => {            
        });  
    }

    removeAllExercises() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.removeAllExercisesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.loadMoreLibraryExercises();
            this.loadMoreAllExercises();
        }).catch((error) => {            
        });  
    }


    componentWillUnmount() {
        this.props.cleanUp({});
    }

    addExercise(exercise){
        this.props.addPartnerLibraryExercise({id:this.props.match.params.library_id,exercise_id:exercise.id});
    }
    removeExercise(exercise){
        this.props.removePartnerLibraryExercise({id:this.props.match.params.library_id,exercise_id:exercise.id});
    }

    render() {
        if(_.isEmpty(this.props.partner_library)){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllExerciseList,
        getPartnerExerciseList,
        addPartnerLibraryExercise,
        removePartnerLibraryExercise,
        listExercise,
        cleanUp:partnerActions.cleanUp,
        exerciseCleanUp: ExerciseActionCreator.cleanModule,
        addAllExercisesPartnerLibrary,
        removeAllExercisesPartnerLibrary,
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    let partner_exercises_ids = _.keyBy(state.partner.partnerReducer.partner_library.exercises,"id");
    return {
        partner_library: state.partner.partnerReducer.partner_library,
        exerciseList: state.exercise.exerciseList.exerciseList,
        paginateInfo: state.exercise.exerciseList.paginateInfo,
        partner_exercises_ids:partner_exercises_ids
    };
}
const PartnerLibraryExercisesConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryExercises);
export default PartnerLibraryExercisesConnected;
