import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import Button from '../../../../utils/common_components/tooltipButton';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
import { Table } from '../../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import moment from 'moment';
export default function () {
    function repeatWebinar1(webinar, webinarIndex) {
        return React.createElement(Tr, { 'key': 'webinar[webinarIndex]' }, React.createElement(Td, {
            'column': 'webinar_title',
            'value': webinar.title
        }, React.createElement('div', {}, '\n                       ', webinar.title, '\n                    ')), React.createElement(Td, {
            'column': 'program_link',
            'value': webinar.shopify_link
        }, React.createElement('div', {}, '\n                       ', webinar.shopify_link, '\n                    ')), React.createElement(Td, {
            'column': 'duration',
            'value': webinar.duration
        }, React.createElement('div', {}, '\n                        ', moment.duration(webinar.duration, 'HH:mm').asMinutes(), ':00\n                    ')), React.createElement(Td, {
            'column': 'publish_datetime',
            'value': webinar.publish_datetime
        }, React.createElement('div', {}, '\n                        ', moment(webinar.publish_datetime).format('MMMM Do, YYYY HH:mm'), '\n                    ')), React.createElement(Td, {
            'column': 'is_published',
            'value': webinar.is_published
        }, React.createElement('div', { 'className': 'text-center' }, webinar.is_published ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '4017'
        }) : null, !webinar.is_published ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '4193'
        }) : null)), React.createElement(Td, { 'column': 'action' }, React.createElement('div', { 'className': true }, !webinar.is_published ? React.createElement(Button, {
            'tooltip': 'publish',
            'className': 'micro bg-success m-r-10',
            'onClick': () => {
                this.publishingWebinar(webinar, !webinar.is_published);
            },
            'key': '4568'
        }, 'publish') : null, webinar.is_published ? React.createElement(Button, {
            'tooltip': 'is_published',
            'className': 'micro bg-lightgray m-r-10',
            'onClick': () => {
                this.publishingWebinar(webinar, !webinar.is_published);
            },
            'key': '4880'
        }, '\n                            Unpublish\n                        ') : null, React.createElement(Button, {
            'tooltip': 'Edit',
            'onClick': () => {
                this.editWebinar(webinar);
            },
            'className': 'micro m-r-10 c-primary'
        }, '\n                            Edit\n                        '), !webinar.is_published ? React.createElement(Button, {
            'tooltip': 'Delete',
            'className': 'micro c-danger',
            'onClick': () => {
                this.deleteWebinar(webinar);
            },
            'key': '5532'
        }, '\n                            Delete\n                        ') : null)));
    }
    return React.createElement('div', { 'className': 'row w-100 m-20' }, React.createElement('div', { 'className': 'col-xs-12 m-t-30 p-r-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'No webinar has been published yet.',
            'sortable': ['webinar_title'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'webinar_title' }, React.createElement('strong', {}, 'Title')), React.createElement(Th, { 'column': 'program_link' }, React.createElement('strong', {}, 'Program Link')), React.createElement(Th, { 'column': 'duration' }, React.createElement('strong', {}, 'Duration')), React.createElement(Th, { 'column': 'publish_datetime' }, React.createElement('strong', {}, ' Publish Date ')), React.createElement(Th, { 'column': 'is_published' }, React.createElement('strong', {}, ' is Published ')), React.createElement(Th, {
            'column': 'action',
            'className': 'text-center'
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.webinars, repeatWebinar1.bind(this))
    ])), React.createElement(Button, {
        'tooltip': 'Create Webinar',
        'className': 'floating-add-button m-r-8',
        'style': { top: '90px' },
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true,
        'onClick': () => {
            this.createWebinar();
        }
    }));
}