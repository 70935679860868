import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practitioner_client_list.rt";
import Utils from '../../utils/utils';
import {
    getClientList,
    getClientFilters,
    resendClientInvitation,
    clientToggleVisibility,
    deleteClientFromPractice,
    clientResetPassword
} from '../../redux/engagement/network/client_list_network';
import ClientListAction from '../../redux/engagement/actions/client_list_creator';
import ClientAction from '../../redux/engagement/actions/client_creator';
import { history } from '../../redux/store';
import Swal from 'sweetalert2';
import { personalChannel } from '../../redux/conversation/network/conversation';
import { messageUser } from '../../redux/message/network/message_network';
import { reduxForm, formValueSelector } from 'redux-form';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { CLIENT_LIST_VIDEO } from '../../utils/help_video';
import { notifyUsers } from '../../redux/dhf/workfit/network/workfit_network';

class ClientList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.gotoClientHome = this.gotoClientHome.bind(this);
        this.onChangeClientSelectionList = this.onChangeClientSelectionList.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);
        this.editDetails = this.editDetails.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.searchClients = this.searchClients.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.inviteClientScreen = this.inviteClientScreen.bind(this);
        this.loadMoreClients = this.loadMoreClients.bind(this);
        this.chatWithClient = this.chatWithClient.bind(this);
        this.showMailComposer = this.showMailComposer.bind(this);
        this.hideMailComposer = this.hideMailComposer.bind(this);
        this.onMailComposerSubmit = this.onMailComposerSubmit.bind(this);
        this.drawerToggle = this.drawerToggle.bind(this);
        this.deleteClientFromPractice = this.deleteClientFromPractice.bind(this);
        this.showNotifyComposer = this.showNotifyComposer.bind(this);
        this.resetClientPassword = this.resetClientPassword.bind(this);
        this.goToDashboard = this.goToDashboard.bind(this);
        this.onClosePopup = this.onClosePopup.bind(this);

        this.state = {
            selectedClientList: "my_all",
            selectedClientListOption: "",
            search_text: "",
            subject: "",
            body: "",
            reloadPage: false,
            mailComposerVisible: false,
            currentlyMailingClient: null,
            showFilter: false,
            openmodal: false
        };
    };
    goToDashboard() {
        history.push(`/practice/${this.props.selectedRole.practice.id}/home`)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.search == '?invite') {
            history.push("/practice/" + this.props.selectedRole.practice.id + "/client/invite/" + 0);
        }
        let that = this;
        this.props.getClientFilters({}).then((response) => {
            if (response.search_preference !== "my_all") {
                that.setState({ selectedClientList: response.search_preference }, () => {
                    that.loadMoreClients();
                });
            } else {
                that.loadMoreClients();
            }
        }).catch((error) => { });
        this.props.updateHelpVideo(CLIENT_LIST_VIDEO);
    }


    drawerToggle = () => {
        this.setState({ showFilter: !this.state.showFilter });
    };

    chatWithClient(client) {
        if (client.conversation_id) {
            history.push('/practice/' + this.props.selectedRole.practice.id + '/conversation/' + client.conversation_id);
        } else {
            this.props.personalChannel({
                'to_user': client.id
            }).then((response) => {
                history.push('/practice/' + this.props.selectedRole.practice.id + '/conversation/' + response.conversation_id);
            }).catch(() => {
            });
        }
    }

    showMailComposer(client) {
        this.setState({
            mailComposerVisible: true,
            currentlyMailingClient: client,
        });
    }

    hideMailComposer() {
        this.setState({
            mailComposerVisible: false,
            currentlyMailingClient: null,
        });
    }

    onMailComposerSubmit(data) {
        this.props.messageUser({
            ...data,
            package_version: '1',
            'user_id': this.state.currentlyMailingClient.id
        }).then(() => {
            this.hideMailComposer();
        }).catch(() => {
        });
    }

    loadMoreClients(increment = false) {
        console.log(this.state.search_text)
        let params = {
            'client_type': this.state.selectedClientList,
            ...this.props.paginateInfo
        };
        if (this.state.reloadPage) {
            params.page = 0;
            this.setState({
                reloadPage: false,
            });
        } else {
            if (increment) {
                params.page = params.page + 1;
            }
        }
        if (this.state.search_text !== "") {
            params['search'] = this.state.search_text;
        }
        this.props.getClientList(params);
    }


    componentWillUnmount() {
        this.props.cleanUp();
        this.props.clearHelpVideo();
    }

    gotoClientHome(e, client, index) {
        if (this.props.user.isUnlimitedClient) {
            if (_.has(this.props.match.params, "selectionType")) {
                this.props.clientSelectionFill(client);
                if (this.props.match.params.selectionType === "maps3d") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/maps3d/" + client.id + "/analysis");
                } else if (this.props.match.params.selectionType === "mat") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/mat/" + client.id);
                } else if (this.props.match.params.selectionType === "mobility" || this.props.match.params.selectionType === "strength" || this.props.match.params.selectionType === "metabolic" || this.props.match.params.selectionType === "power") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/dhfassess/" + this.props.match.params.selectionType + "/" + client.id);
                } else if (this.props.match.params.selectionType === "mobility_exercises" || this.props.match.params.selectionType === "strength_exercises" || this.props.match.params.selectionType === "metabolic_exercises" || this.props.match.params.selectionType === "power_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/" + this.props.match.params.selectionType);
                } else if (this.props.match.params.selectionType === "mobility_workout_templates" || this.props.match.params.selectionType === "strength_workout_templates" || this.props.match.params.selectionType === "metabolic_workout_templates" || this.props.match.params.selectionType === "power_workout_templates") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/p/template/list");
                } else if (this.props.match.params.selectionType === "maps3d_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/3dmaps");
                } else if (this.props.match.params.selectionType === "workout_templates") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/p/template/list");
                } else if (this.props.match.params.selectionType === "golf_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/gi_golf");
                } else if (this.props.match.params.selectionType === "gi_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/gi");
                }
            } else if (e.target.type !== "button" || e.target.name === "active_game_plan" || e.target.name === "inactive_game_plan") {
                if (this.props.support_multiple_gameplan) {
                    if (client.active_engagement_count === 1) {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/engagement/" + client.active_engagement_id);
                    } else {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/dashboard/" + client.id);
                    }
                } else {
                    if (client.active_engagement_id) {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/engagement/" + client.active_engagement_id);
                    } else {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/dashboard/" + client.id);
                    }
                }
            }
        }
        else if (!this.props.user.isUnlimitedClient && index < 3) {
            if (_.has(this.props.match.params, "selectionType")) {
                this.props.clientSelectionFill(client);
                if (this.props.match.params.selectionType === "maps3d") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/maps3d/" + client.id + "/analysis");
                } else if (this.props.match.params.selectionType === "mat") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/mat/" + client.id);
                } else if (this.props.match.params.selectionType === "mobility" || this.props.match.params.selectionType === "strength" || this.props.match.params.selectionType === "metabolic" || this.props.match.params.selectionType === "power") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/dhfassess/" + this.props.match.params.selectionType + "/" + client.id);
                } else if (this.props.match.params.selectionType === "mobility_exercises" || this.props.match.params.selectionType === "strength_exercises" || this.props.match.params.selectionType === "metabolic_exercises" || this.props.match.params.selectionType === "power_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/" + this.props.match.params.selectionType);
                } else if (this.props.match.params.selectionType === "mobility_workout_templates" || this.props.match.params.selectionType === "strength_workout_templates" || this.props.match.params.selectionType === "metabolic_workout_templates" || this.props.match.params.selectionType === "power_workout_templates") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/p/template/list");
                } else if (this.props.match.params.selectionType === "maps3d_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/3dmaps");
                } else if (this.props.match.params.selectionType === "workout_templates") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/p/template/list");
                } else if (this.props.match.params.selectionType === "golf_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/gi_golf");
                } else if (this.props.match.params.selectionType === "gi_exercises") {
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/exercise/gi");
                }
            } else if (e.target.type !== "button" || e.target.name === "active_game_plan" || e.target.name === "inactive_game_plan") {
                if (this.props.support_multiple_gameplan) {
                    if (client.active_engagement_count === 1) {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/engagement/" + client.active_engagement_id);
                    } else {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/dashboard/" + client.id);
                    }
                } else {
                    if (client.active_engagement_id) {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/engagement/" + client.active_engagement_id);
                    } else {
                        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/dashboard/" + client.id);
                    }
                }
            }
        }
        else {
            this.onShowPopup();
        }
    }

    resendInvitation(client) {
        this.props.resendClientInvitation({
            client_id: client.id,
        }).then(() => {
        }).catch((error) => { });
    }

    editDetails(client) {
        history.push("/practice/" + this.props.selectedRole.practice.id + "/client/details/" + client.id);
    }

    toggleVisibility(client, visibility_type) {
        this.props.clientToggleVisibility({
            client_id: client.id,
            visibility_type: visibility_type,
        }).then(() => {
            this.props.getClientFilters({})
            this.setState({
                reloadPage: true
            }, () => {
                this.loadMoreClients();
            });
        }).catch((error) => { });
    }

    deleteClientFromPractice(client) {
        let that = this;
        var title = "Deleting your client " + client.name + " from your practice?";
        Swal.fire({
            title: "Are you sure",
            text: title,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure",
            cancelButtonText: "No",
            useRejections: true
        }).then(function (result) {
            if (result.value) {
                that.props.deleteClientFromPractice({
                    client_id: client.id,
                }).then(() => {
                    that.props.getClientFilters({})
                    that.setState({
                        reloadPage: true
                    }, () => {
                        that.loadMoreClients();
                    });
                }).catch((error) => { });
            }
        }, function (dismiss) {
        });
    }

    updateSearchText(e) {
        this.setState({
            search_text: e.target.value
        });
    }

    searchClients(e) {
        console.log(e)
        if (e.key === "Enter") {
            this.setState({
                reloadPage: true
            }, () => {
                this.loadMoreClients();
            });
        }
    }

    inviteClientScreen() {
        let that = this;
        console.log(this,'this');
        if(this.props.user.isUnlimitedClient || !this.props.user.isUnlimitedClient && this.props.clientList.length < 3){
            if (this.props.is_workfit_practice) {
                history.push("/practice/" + this.props.selectedRole.practice.id + "/client/invite/" + 2);
            } else if (this.props.group_count > 0) {
                Swal.fire({
                    title: "Select an option",
                    text: "",
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#0292d3",
                    cancelButtonColor: "#4C555C",
                    confirmButtonText: "Invite client to start a program",
                    cancelButtonText: "Invite client to a group",
                    useRejections: true
                }).then(function (result) {
                    if (result.value) {
                        history.push("/practice/" + that.props.selectedRole.practice.id + "/client/invite/" + 0);
                    } else if (result.dismiss == 'cancel') {
                        history.push("/practice/" + that.props.selectedRole.practice.id + "/client/invite/" + 1);
                    } else if (result.dismiss == 'close') {
                        Swal.close()
                    }
                }, function (dismiss) {
                });
            } else {
                history.push("/practice/" + this.props.selectedRole.practice.id + "/client/invite/" + 0);
            }
        }
        else{
            this.onShowPopup();
        }
    }

    onChangeClientSelectionList(val) {
        if (!val) {
            return;
        }
        this.setState({
            selectedClientList: typeof val === 'object' ? val.value : val,
            selectedClientListOption: val,
            reloadPage: true,
            showFilter: false
        }, () => {
            this.loadMoreClients();
        });
    }

    showNotifyComposer(client) {
        let that = this;
        Swal.fire({
            title: 'Enter Push Notification Text',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Send',
            showLoaderOnConfirm: true,
            preConfirm: (notification_text) => {
                let params = {};
                params["client_id"] = client.id;
                params["notification_text"] = notification_text
                that.props.notifyUsers(params);
                Swal.close();
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            Swal.close();
        })
    }

    resetClientPassword(client) {
        let that = this;
        Swal.fire({
            title: 'Enter new password for the client ' + client.email,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Reset Password',
            showLoaderOnConfirm: true,
            preConfirm: (password) => {
                let params = {};
                params["client_id"] = client.id;
                password.length ? params["password"] = password : alert("Enter valid password");
                that.props.clientResetPassword(params);
                Swal.close();
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            Swal.close();
        })
    }

    onShowPopup() {
        console.log("click")
        this.setState({
            openmodal: true
        })
    }

    onClosePopup() {
        console.log("close")
        this.setState({
            openmodal: false
        })
    }


    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    let all_practice_client_filter_text = "";
    let all_hidden_client_filter_text = "";
    let clientSelectionList = state.engagement.clientListReducer.client_selection_list;
    clientSelectionList = _.filter(clientSelectionList, (item) => {
        if (item.value === "all") {
            all_practice_client_filter_text = item.label;
            return false;
        } else if (item.value === "hidden") {
            all_hidden_client_filter_text = item.label;
            return false;
        } else {
            return true;
        }
    });
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        clientList: state.engagement.clientListReducer.client_list,
        paginateInfo: state.engagement.clientListReducer.paginate_info,
        clientSelectionList: clientSelectionList,
        practitionerList: state.engagement.clientListReducer.practitioner_list,
        consultantList: state.engagement.clientListReducer.consultant_list,
        group_count: state.engagement.clientListReducer.group_count,
        support_multiple_gameplan: state.engagement.clientListReducer.support_multiple_gameplan,
        is_workfit_practice: state.engagement.clientListReducer.is_workfit_practice,
        all_practice_client_filter_text: all_practice_client_filter_text,
        all_hidden_client_filter_text: all_hidden_client_filter_text,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientList,
        getClientFilters,
        resendClientInvitation,
        clientToggleVisibility,
        deleteClientFromPractice,
        cleanUp: ClientListAction.clientListCleanup,
        clientSelectionFill: ClientAction.clientSelectionFill,
        personalChannel,
        messageUser,
        notifyUsers,
        clientResetPassword,
        updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearHelpVideo: HelpVideoCreator.clearHelpVideo,
    },
        dispatch
    );
}

const ClientListConnected = connect(mapStateToProps, mapDispatchToProps)(ClientList);
export default ClientListConnected;
