import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function repeatExercise1(exercise, index) {
        return React.createElement('div', {
            'key': 'exercise' + index,
            'className': 'p-5 m-t-10'
        }, '\n            ', exercise.name, ' - ', exercise.video_url, '\n        ');
    }
    return React.createElement('div', {}, React.createElement('div', { 'className': 'row start-xs m-t-20 text-center' }, React.createElement('div', { 'className': 'col-md-12 f-20' }, '\n            Summary\n        ')), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-t-20' },
        React.createElement('p', { 'className': 'text-center w-100' }, React.createElement('b', {}, 'Note: '), React.createElement('i', {}, 'The selected exercises have been added as a Workout Template as a draft in the selected coach\'s primary practice account.')),
        _.map(this.props.workoutCartExeciseList, repeatExercise1.bind(this))
    ]));
}