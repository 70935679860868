import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actionCreator from '../actions/practice_details';

const practiceDetailsPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_details_post";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Saved Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const practiceDetailsGet = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_details_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.practiceDetailsFill(
                response['data'],
                response['supporting_data'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    practiceDetailsPost,
    practiceDetailsGet,
};