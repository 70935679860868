import * as React from 'react';
import * as _ from 'lodash';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    return React.createElement('div', {
        'className': 'row center-xs middle-xs full-height c-dark w-100 p-20',
        'style': { marginTop: '-130px' }
    }, React.createElement('div', { 'className': 'header2' }, React.createElement('h2', {}, 'Privacy Policy')), React.createElement('div', { 'className': 'panel col-xs-11 m-t-15 p-20' }, React.createElement(RichTextViewer, {
        'className': 'm-t-10 m-b-10 text-left  c-dark',
        'value': this.state.homefit_privacy_text
    })));
}