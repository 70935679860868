import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
export default function () {
    function repeatHabit1(habit, habitIndex) {
        return React.createElement(Tr, { 'key': 'row' + habitIndex }, React.createElement(Td, {
            'column': 'name',
            'value': habit.name,
            'style': {
                textAlign: 'left',
                width: '10%'
            },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, habit.name)), React.createElement(Td, {
            'column': 'text',
            'value': habit.text,
            'style': { textAlign: 'left' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate pre-wrap' }, habit.text)), React.createElement(Td, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.__schedule_type)), React.createElement(Td, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.duration_count, ' ', habit.__duration_type)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'onClick': () => {
                this.editHabit(habit.id);
            },
            'tooltip': 'Modify existing habit',
            'className': 'bg-primary m-l-5 micro',
            'icon': 'create',
            'floating': true,
            'mini': true,
            'key': '3355'
        }) : null, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'onClick': () => {
                this.deleteHabit(habit.id);
            },
            'tooltip': 'Delete existing habit',
            'className': 'bg-danger m-l-5 micro',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'key': '3695'
        }) : null)));
    }
    return React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row m-t-10 pull-right' }, this.props.treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'onClick': () => this.editHabit(),
        'tooltip': 'Add new habit',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true,
        'key': '1484'
    }) : null), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-10',
            'id': 'table',
            'noDataText': 'Click on + icon to create a new habit.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'name',
            'style': {
                textAlign: 'left',
                width: '10%'
            }
        }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'text',
            'style': { textAlign: 'left' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('strong', {}, 'Schedule')), React.createElement(Th, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('strong', {}, 'Duration')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.habits, repeatHabit1.bind(this))
    ]));
}