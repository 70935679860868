import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import SelectComponent from 'react-select';
import Select from '../../../utils/common_components/select';
import { SelectInput } from '../../../utils/common_components/select';
import Switch from '../../../utils/common_components/switch_form';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { DEFAULT_MODE } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { PROGRESSION_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { Panel } from 'react-toolbox';
import { SearchInput as Input } from '../../../utils/common_components/textInput_form';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import { NavLink } from 'react-router-dom';
export default function () {
    function repeatProgram1(program, programIndex) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-6 m-t-18 ribbon-container',
            'key': 'program' + programIndex
        }, !program.published ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '5300'
        }, '\n                Draft\n            ') : null, program.published ? React.createElement('div', {
            'className': 'bg-published ribbon',
            'key': '5412'
        }, '\n                Published\n            ') : null, program.is_free ? React.createElement('div', {
            'className': 'paid-icon ribbon-right',
            'key': '5529'
        }, '\n                $\n            ') : null, !program.is_free ? React.createElement('div', {
            'className': 'free-badge ribbon-right',
            'key': '5639'
        }, '\n                Free\n            ') : null, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(program.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'col-xs-' + (program.description ? '9' : '12') + ' text-left' }, React.createElement(TooltipWrapper, {
            'className': 'f-16 truncate p-b-5',
            'tooltip': program.name
        }, '\n                    ', program.name, '\n                '), React.createElement('div', { 'className': 'f-10 truncate' }, program.practice.name)), program.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-grey center-xs',
            'tooltip': program.description,
            'icon': 'info',
            'key': '6301'
        }) : null), React.createElement('div', { 'className': 'row center-xs m-t-10' }, React.createElement('div', { 'className': 'col-xs-12 p-2 m-0' }, program.deleted_date === null ? [
            this.props.mode === DEFAULT_MODE ? [
                program.is_owner ? React.createElement(IconButton, {
                    'icon': 'edit',
                    'tooltip': 'Edit ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.edit(program.id);
                    },
                    'key': '66311'
                }) : null,
                !program.is_owner ? React.createElement(IconButton, {
                    'icon': 'info-fill',
                    'tooltip': 'View ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.edit(program.id);
                    },
                    'key': '66313'
                }) : null,
                program.is_owner ? React.createElement(IconButton, {
                    'icon': 'deactivate',
                    'tooltip': 'Hide ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.delete(program.id, 'hide');
                    },
                    'key': '66315'
                }) : null,
                program.is_practice_member ? React.createElement(IconButton, {
                    'icon': 'clone',
                    'tooltip': 'Clone this ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.clone(program.id);
                    },
                    'key': '66317'
                }) : null,
                program.is_practice_member ? React.createElement(IconButton, {
                    'icon': 'screen-share',
                    'tooltip': 'Save as ' + (this.props.programType === 'template' ? 'QuickStart' : 'Template'),
                    'className': 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.saveAs(program.id);
                    },
                    'key': '66319'
                }) : null,
                this.props.programType === 'template' ? React.createElement(IconButton, {
                    'icon': program.is_favorite ? 'star-fill' : 'star',
                    'tooltip': program.is_favorite ? 'Unfavorite ' + this.props.programType : 'Favorite ' + this.props.programType,
                    'className': program.is_favorite ? 'm-r-5 c-green small-icon-button c-grey' : 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.toggleFavorite(program.id);
                    },
                    'key': '663111'
                }) : null,
                this.props.selectedClientForAction !== null ? React.createElement(IconButton, {
                    'tooltip': 'Create workout from workout template',
                    'icon': 'add',
                    'className': 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.createWorkoutFromWorkoutTemplate(program.id);
                    },
                    'key': '663113'
                }) : null
            ] : null,
            this.props.mode === TEMPLATE_SELECTION_MODE ? [React.createElement(IconButton, {
                    'icon': 'add',
                    'tooltip': 'Select this Template',
                    'className': 'm-r-5 c-black small-icon-button c-grey',
                    'onClick': () => {
                        this.createWorkoutFromProgram(program.id);
                    },
                    'key': '91341'
                })] : null
        ] : null, program.deleted_date !== null ? [program.is_owner ? React.createElement(IconButton, {
                'icon': 'deactivate',
                'tooltip': 'Unhide ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                'className': 'm-r-5 c-black small-icon-button c-grey',
                'onClick': () => {
                    this.delete(program.id, 'unhide');
                },
                'key': '95111'
            }) : null] : null)));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, {
        'className': 'workouts full-panel',
        'style': { overflowX: 'hidden' }
    }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.goBack
    }, '\n        Back\n    '), this.props.mode === DEFAULT_MODE ? React.createElement('div', {
        'className': 'program-search-box row vertical-align flex-sb m-t-10 start-xs m-10',
        'key': '1937'
    }, React.createElement('div', { 'className': true }, React.createElement('h3', { 'className': 'f-14 page-subtitle' }, 'Templates'), React.createElement('h2', { 'className': 'm-0 page-title' }, 'Workout Templates')), this.props.typeLCase === 'template' ? React.createElement('div', {
        'className': 'w-60 p-relative search-box  no-box-shadow m-b-20 p-5 vertical-align',
        'key': '2233'
    }, React.createElement(Input, {
        'label': 'Search text',
        'style': {
            width: '76%',
            marginRight: '25px',
            height: '40px'
        },
        'onChange': this.onSearch,
        'type': 'text',
        'onClick': this.inputClick,
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'width-fit bg-success m-r-10 m-t-7',
        'onClick': this.add,
        'floating': true,
        'ripple': true,
        'mini': true
    }, 'Add New'), React.createElement(Button, {
        'className': 'bg-primary m-t-7 width-41',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    })) : null, this.props.typeLCase === 'quickstart' ? React.createElement('div', {
        'className': 'col-xs-12 end-xs',
        'key': '3002'
    }, React.createElement(SelectComponent, {
        'className': 'col-xs-4',
        'value': this.state.quickstart_type,
        'options': this.state.quickstartSelectionList,
        'simpleValue': true,
        'onChange': val => {
            this.onChangeQuickstartSelectionList(val);
        }
    }), React.createElement(Button, {
        'className': 'bg-success m-r-10',
        'onClick': this.add,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    })) : null) : null, this.props.mode === TEMPLATE_SELECTION_MODE ? React.createElement('div', {
        'className': 'row vertical-align flex-sb end-xs m-10 search-box p-5 vertical-align',
        'key': '3592'
    }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'style': { height: '40px' },
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search text',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    }), React.createElement(IconButton, {
        'tooltip': 'Close template selection',
        'className': 'bg-danger m-r-15',
        'onClick': this.props.cancelTemplateSelection,
        'icon': 'close',
        'ripple': true
    })) : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs' },
        _.map(this.props.programList, repeatProgram1.bind(this)),
        this.props.programList.length == 0 ? React.createElement('h3', {
            'className': 'f-14 page-subtitle justify-center',
            'key': '9949'
        }, 'There are currently no workout templates designed by you') : null
    ]), this.props.paginateInfo.page < this.props.paginateInfo.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '10115'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more...',
        'className': 'height-fit bg-success-border text-capital c-black',
        'onClick': () => {
            this.refreshProgramList(this.props, true, false);
        }
    }))) : null), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow',
        'width': 50,
        'style': { width: '50% !important' }
    }, React.createElement('form', {
        'className': 'medium-labels p-b-30',
        'onSubmit': this.props.handleSubmit(this.applyFilter)
    }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n                  Filters\n            '), React.createElement(Button, {
        'onClick': () => {
            this.resetFilter();
        },
        'className': 'small-button',
        'label': 'Reset',
        'raised': true,
        'primary': true
    }), React.createElement(Button, {
        'onClick': () => {
            this.applyFilter();
        },
        'className': 'small-button m-l-10 text-capital',
        'label': 'Apply',
        'raised': true
    }), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), React.createElement('div', { 'className': 'filter-section p-10' }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'my_deleted_templates',
        'label': 'View My Hidden Templates',
        'component': Switch
    })), React.createElement('hr', { 'className': 'dashed' }), !this.props.my_deleted_templates ? React.createElement('div', { 'key': '11763' }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'is_favorite',
        'label': 'Show Just My Favorite Templates',
        'component': Switch
    }), React.createElement('span', { 'className': 'badge bg-success m-l-10' }, this.props.filterData.favorites ? this.props.filterData.favorites : 0)), React.createElement(Field, {
        'className': 'm-t-10',
        'name': 'program_sources',
        'label': 'Program Sources',
        'component': Checkbox,
        'checked': true,
        'showCountLabel': true,
        'options': this.props.programSources
    }), React.createElement('div', { 'className': 'm-t-10' }, React.createElement(Field, {
        'name': 'category',
        'label': 'Program Category',
        'component': Select,
        'options': this.props.filterData.category,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectRender,
        'onChange': val => {
            this.onChangeCategoryLevel1(val);
        }
    })), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category === 5 ? React.createElement(Field, {
        'name': 'sports',
        'label': 'Sports Name',
        'component': Select,
        'options': this.props.filterData.sports,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'multi': true,
        'optionRenderer': this.selectRender,
        'key': '13276'
    }) : null), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category === 5 ? React.createElement(Field, {
        'name': 'sports_exercise_type',
        'label': 'Sports Condition',
        'component': Select,
        'options': this.props.filterData.sports_exercise_type,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectRender,
        'key': '13875'
    }) : null), this.props.category && this.props.category !== 5 ? [
        React.createElement('div', { 'key': '144301' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-25',
            'name': 'category_level2',
            'component': Select,
            'label': 'Category Level 2',
            'options': this.props.categoryLevel2,
            'valueKey': 'id',
            'labelKey': 'name',
            'onChange': val => {
                this.onChangeCategoryLevel2(val);
            }
        })),
        React.createElement('div', { 'key': '144303' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-25',
            'name': 'category_level3',
            'component': Select,
            'label': 'Category Level 3',
            'options': this.props.categoryLevel3,
            'valueKey': 'id',
            'labelKey': 'name'
        }))
    ] : null) : null))));
}