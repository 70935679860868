import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Chip from 'react-toolbox/lib/chip';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { history } from '../../../redux/store';
import Dialog from 'react-toolbox/lib/dialog';
export default function () {
    function repeatChat1(chat, chatIndex) {
        return React.createElement('li', {
            'className': 'p-l-10 p-r-10',
            'key': 'row' + chatIndex
        }, React.createElement('div', { 'className': `  bubble-container ${ this.props.user.id !== chat.user.id ? '' : 'bubble-direction-reverse' } ` }, React.createElement(ProfilePicture, {
            'user': chat.user,
            'profile_type': 'circle'
        }), React.createElement('div', { 'className': `  bubble  ${ this.props.user.id === chat.user.id ? 'me' : 'you' } ` }, chat.message)));
    }
    return React.createElement('div', {}, React.createElement('div', {
        'className': _.transform({
            row: true,
            'center-xs': true,
            'p-20': this.state.displayTitle
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement(Card, { 'className': 'col-xs-12 p-0' }, this.state.displayTitle ? React.createElement(CardTitle, {
        'avatar': true,
        'title': this.props.conversation.name,
        'key': '1057'
    }) : null, React.createElement(CardText, { 'className': 'p-0 box-shadow-inset bg-lightgray' }, React.createElement('div', {
        'className': 'thin-scroll',
        'style': {
            height: '60vh',
            overflow: 'auto'
        },
        'ref': div => {
            this.messageList = div;
        }
    }, React.createElement.apply(this, [
        'ul',
        {
            'style': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                minHeight: '100%'
            }
        },
        this.props.paginate_info.page + 1 < this.props.paginate_info.total_pages ? React.createElement('li', {
            'style': { color: '#999' },
            'className': 'text-center pointer f-italic',
            'onClick': this.loadMoreData,
            'key': '1556'
        }, 'Click to load more') : null,
        _.map(this.props.chats, repeatChat1.bind(this))
    ]))), React.createElement(CardActions, { 'className': 'p-0' }, React.createElement('div', { 'className': 'row w-100 vertical-align' }, React.createElement(Input, {
        'multiline': true,
        'className': 'flex-auto m-l-10',
        'type': 'text',
        'hint': 'Type your message here',
        'name': 'message',
        'value': this.state.message,
        'onChange': this.onChangeMessage
    }), React.createElement('div', { 'className': 'text-center' }, React.createElement(Button, {
        'onClick': this.sendMessage,
        'icon': 'send',
        'floating': true,
        'accent': true,
        'mini': true
    }))))), this.state.displayTitle ? React.createElement(Button, {
        'style': {
            top: '70px',
            right: '40px'
        },
        'className': 'floating-add-button small-button bg-success',
        'label': 'Go Back',
        'onClick': () => {
            history.goBack();
        },
        'icon': 'undo',
        'ripple': true,
        'mini': true,
        'key': '2962'
    }) : null));
}