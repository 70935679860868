import React  from 'react';
import EasyPieChart from 'easy-pie-chart';
import ReactDOM from 'react-dom';
import BaseComponent from './basecomponent';


class EasyPie extends BaseComponent {
  constructor(props, context) {
      super(props, context);
      this.state = {"sizeClass":""};    
  }

  
  componentWillMount() {
      if (this.props.size <= 40){
          this.setState({ sizeClass: "very-small" });
      } else if (this.props.size <= 60) {
           this.setState({ sizeClass: "small" });
      }
  }
    

 componentDidMount() {
      new EasyPieChart(ReactDOM.findDOMNode(this), {
        size:this.props.size,
        animate:{ duration: 600, enabled: true },
        barColor: '#4CAF50',
        trackColor:'#ccc',
        lineWidth: 3,
        scaleColor: false,
    });
  }
//   componentWillUpdate (nextProps, nextState) {
//           $(ReactDOM.findDOMNode(this)).data('easyPieChart').update(nextProps.percent);
//   }

 render() {
     return (
         <div className={`chart ${this.state.sizeClass} `} data-percent={this.props.percent}  >
            <span className="percent f-9" > {this.props.percent}</span>
        </div>
    );
  }
} 

EasyPie.defaultProps = {
    size: 60
};

export default EasyPie;