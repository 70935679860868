import  React from "react";
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/gi_pro_specialities";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import Swal from 'sweetalert2';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import {accessSpecialities} from '../../redux/gi/network/gi_network';


class GIProSpecialities extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.show3dmapsSelection = this.show3dmapsSelection.bind(this);
        this.showGIGolfSelection = this.showGIGolfSelection.bind(this);
        this.showGISelection = this.showGISelection.bind(this); 
        this.state = {
            access_specialities: false
        };     
    }

    
    componentWillMount() {       
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);       
        this.props.accessSpecialities().then(() => {
            this.setState({
                access_specialities: true
            });
        }).catch(() => {
        });
    }

    showGISelection() {
        history.push('/gipro/exercises/gi');                    
    }
    
    show3dmapsSelection() {
        history.push('/gipro/exercises/3dmaps');                    
    }

    showGIGolfSelection() {
        history.push('/gipro/exercises/gi_golf');                            
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);        
    }
}
function mapStateToProps(state) {    
    return {
        user: state.auth.authReducer.user,      
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({               
            updateMenuOpen:MenuAction.updateMenuOpen,
            updateMenuPinned:MenuAction.updateMenuPinned, 
            accessSpecialities,           
        }, 
        dispatch
    );
}

const GIProSpecialitiesConnected = connect(mapStateToProps,mapDispatchToProps)(GIProSpecialities);
export default GIProSpecialitiesConnected;
