import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_intake_form.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store, {history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm,formValueSelector } from 'redux-form';
import Swal from 'sweetalert2';
import { Card, CardText } from 'react-toolbox/lib/card';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {postClientIntakeForm} from '../../redux/engagement/network/client_engagement_network'
import moment from "moment";





class IntakeForm extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
    }

    doSave(data_val){        
        if(typeof this.props.intake.engagement === "object") {
            this.props.intake.engagement = this.props.intake.engagement.id;
        }
        let data = {...data_val};
        let questions = _.keyBy(this.props.intake.questions,"field_guid");
        _.forEach(questions, (value, key)=> {
            if(value.field_type === 5){//Date field
                data[key] = (data[key] !== null  && data[key] !=="") ? moment(data[key]).format("YYYY-MM-DD")  : "" ;
            }
        });    
        this.props.postClientIntakeForm({
            engagement:this.props.intake.engagement,
            id:this.props.intake.id,
            package_version: 4,
            form:data
        }).then((response)=>{
            this.props.handleClose();
        }).catch((error)=>{
            //do nothing
        });
    }    

    render() {
        console.log('inside')
        if (_.isEmpty(this.props.intake)) {
            return (<div className="row center-xs m-t-25">
        
                    { console.log('circular') }
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}

IntakeForm.defaultProps = {
    intake: {}
};


const selector = formValueSelector('intakeEditForm'); 
function mapStateToProps(state, ownprops) {
    let intake = {...ownprops.intake};
     intake.questions = [...intake.form];
    let initialValues = {};
     if(intake.questions){
        _.forEach(intake.questions, (item) => {
            if(item.field_type === 5){//Date field
                initialValues[item.field_guid] =  (item.value && item.value !=="") ? moment(item.value).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()  : "" ;
            }else{
                initialValues[item.field_guid] = item.value;
            }
            initialValues[item.field_guid+"_additional_value"] = item.additional_value;
        });
    }

    return {
        intake: intake,
        initialValues: initialValues,
        handleClose:ownprops.handleClose,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postClientIntakeForm
    }, dispatch);
}

const validate = (values, props) => {
    let requiredFields = [];
    let arrayFillCheck = [];
    let errors = {};
    _.forEach(props.intake.questions, (item) => {
        if (item.field_type !== 3 && item.field_type !== 4 && item.field_type !== 14 && item.is_required){
            requiredFields.push(item.field_guid);    
        } else if ((item.field_type === 3 || item.field_type === 4 || item.field_type === 14) && item.is_required){
            arrayFillCheck.push(item.field_guid);
        }
    });
    requiredFields.forEach(field => {
        let key = field;
        if (!values[key] || values[key] === "") {
            errors[key] = 'Required';
        }
    });
    arrayFillCheck.forEach(field => {
        let key = field;
        if (!values[key] || values[key].length === 0) {
            errors[key] = 'Required';
        }
    });

    return errors;
};

const IntakeFormRedux = reduxForm(
    {
        form: 'intakeEditForm',
        enableReinitialize: true,
        validate,
        onSubmitFail: (errors, dispatch) => {
            Swal.fire({
                type: 'error',
                title: 'Validation Errors!',
                text: 'Values for all mandatory fields are not entered. Kindly review the intake form once more and update all fields.',
            });
        },
    }
)(IntakeForm);
const IntakeFormConnected = connect(mapStateToProps,mapDispatchToProps)(IntakeFormRedux);
export default IntakeFormConnected;
