// Action Creators
const messageListFill = (messages, paginate_info) => ({
    type: 'MESSAGE_LIST_FILL',
    messages:messages,
    paginate_info: paginate_info,
});


const messageCleanup = () => ({
    type: 'MESSAGE_CLEANUP',
});

export default {
    messageListFill,    
    messageCleanup,
};
