import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/conversation_list.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { bindActionCreators } from 'redux';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import ConversationAction from '../../redux/conversation/actions/conversation_creator.js';
import {getConversationList, personalChannel} from '../../redux/conversation/network/conversation';


class Conversations extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.showChatMessage = this.showChatMessage.bind(this);
        this.state = {addConversationModalOpen:false,user_type:""};
        this.showAddConversationModal =  this.showAddConversationModal.bind(this);
        this.hideAddConversationModal =  this.hideAddConversationModal.bind(this);
        this.selectUser =  this.selectUser.bind(this);
    }

    componentWillMount() {
        this.props.getConversationList({});
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    showAddConversationModal(user_type){
         this.setState({addConversationModalOpen:true,user_type:user_type});
    }

    selectUser(selected_user){
        if(!selected_user || selected_user === null ||  _.isEmpty(selected_user)){
            return;
        }
        this.props.personalChannel({
            'to_user': selected_user.id
        }).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/conversation/' + response.conversation_id);
        }).catch((error) => {
        });  
        this.setState({addConversationModalOpen:false,user_type:""});
    }

    hideAddConversationModal(){
        this.setState({addConversationModalOpen:false,user_type:""});
    }
    
    showChatMessage(conversation) {
        if(this.props.selectedRole.role.name !== "client") {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/conversation/' + conversation.id);
        } else {
            history.push('/client/conversation/' + conversation.id);
        }
    }


    render() {
        return Template.apply(this);
    }
}
function mapStateToProps(state) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        conversations: state.conversation.conversationReducer.conversations,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getConversationList,
        personalChannel,
        cleanUp: ConversationAction.cleanModule,
    }, dispatch),
    dispatch
});

const ConversationsConnected = connect(mapStateToProps,mapDispatchToProps,)(Conversations);
export default ConversationsConnected;
