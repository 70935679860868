import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_list.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {getPartnerDetails,updatePartnerLibraryName,addPartnerLibrary,deletePartnerLibrary} from '../../redux/partner/network/partner_network';
import partnerActions from '../../redux/partner/actions/partner_action';
import Swal from 'sweetalert2';

class PartnerLibraryList extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.showLibraryGoals = this.showLibraryGoals.bind(this);
        this.showLibraryIntakes = this.showLibraryIntakes.bind(this);
        this.showLibraryExercises = this.showLibraryExercises.bind(this);
        this.showLibraryTemplates = this.showLibraryTemplates.bind(this);
        this.showLibraryHabitTemplates = this.showLibraryHabitTemplates.bind(this);
        this.showLibraryNutrition = this.showLibraryNutrition.bind(this);        
        this.showLibraryGuidance = this.showLibraryGuidance.bind(this);        
        this.showLibraryGroupTemplates = this.showLibraryGroupTemplates.bind(this);        
        this.showLibraryGameplanTemplates = this.showLibraryGameplanTemplates.bind(this);        
        this.updateLibraryName = this.updateLibraryName.bind(this);
        this.addLibrary = this.addLibrary.bind(this);
        this.deleteLibrary = this.deleteLibrary.bind(this);
    };

    
    componentWillMount() {
        this.props.getPartnerDetails({});
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }
    showLibraryGoals(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/goals/"+library.id);
    }
    showLibraryIntakes(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/intakes/"+library.id);
    }
    showLibraryExercises(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/exercises/"+library.id);
    }
    showLibraryTemplates(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/templates/"+library.id);
    }
    showLibraryHabitTemplates(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/habit_templates/"+library.id);
    }
    showLibraryGroupTemplates(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/treatment_templates/group/"+library.id);
    }
    showLibraryGameplanTemplates(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/treatment_templates/gameplan/"+library.id);
    }
    showLibraryNutrition(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/document/1/"+library.id);
    }
    showLibraryGuidance(library){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/library/document/2/"+library.id);
    }

    addLibrary() {
        let that = this;
        Swal.fire({
            title: "Add Partner Library",
            text: "You are about to create a new partner library",
            input: "text",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Create",
            cancelButtonText: "Close",
            useRejections:true
        }).then(function (inputValue) {
            if (inputValue === false) {
                return false;
            };
            if (inputValue === "") {
                Swal.showInputError("Provide a new name for partner library!");
                return false;
            }
            that.props.addPartnerLibrary({
                name: inputValue.value
            }).then(() => {
                that.props.getPartnerDetails({
                });
            }).catch((error) => {
            });
            Swal.close();                            
        }, function (dismiss) {
                        
        });  
    }


    updateLibraryName(library) {        
        let that = this;
        Swal.fire({
            title: "Partner Library Name",
            text: "You are about to change the partner library name",
            input: "text",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Update",
            cancelButtonText: "Close",
            inputValue: library.name,
            useRejections:true
        }).then(function (inputValue) {
            if (inputValue === false) return false;            
            if (inputValue === "") {
                Swal.showInputError("Provide a new name for partner library!");
                return false;
            } 
            that.props.updatePartnerLibraryName({
                'id': library.id,
                'name': inputValue.value
            }).then(() => {
                that.props.getPartnerDetails({
                });
            }).catch((error) => {
            });
            Swal.close();            
        }, function (dismiss) {                        
        });                
    }    

    deleteLibrary(library) {
        let that = this;
        Swal.fire({
            title: "Are you sure",
            text: "You are about to delete a content sharing library",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure",
            cancelButtonText: "Cancel",
            useRejections:true
        }).then(function (inputValue) {
            that.props.deletePartnerLibrary({
                library_id: library.id                
            }).then(() => {
                that.props.getPartnerDetails({
                });
            }).catch((error) => {
            });
            Swal.close();            
        }, function (dismiss) {                        
        });                
    }

    render() {
        if(_.isEmpty(this.props.partner)){
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerDetails,
        updatePartnerLibraryName,
        addPartnerLibrary,
        deletePartnerLibrary,
        cleanUp:partnerActions.partnerInstanceFill
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        partner: state.partner.partnerReducer.partner,
    };
}
const PartnerLibraryListConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryList);
export default PartnerLibraryListConnected;
