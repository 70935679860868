import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/coach_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../../redux/store';
import { getCoachList } from '../../../redux/dhf/move/network/coach_network';

class CoachList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addCoach = this.addCoach.bind(this);
        this.editCoach = this.editCoach.bind(this);
    }
    
    componentWillMount() {
        this.props.getCoachList();
    }

    componentWillUnmount() {
    }

    addCoach() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/coaches/add');
    }

    editCoach(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/coaches/edit/'+id);
    }
    
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        coach_list: state.move.coachReducer.coach_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getCoachList,
        }, 
        dispatch
    );
}


const CoachListConnected = connect(mapStateToProps,mapDispatchToProps)(CoachList);
export default CoachListConnected;