let initialState = {
    mat_form: [],
};
export default function intake(state = initialState, action) {
    switch (action.type) {
        case "MAT_FILL":
            if (!action.mat_form){
                return state;
            }    
            return Object.assign({}, state, {
                mat_form: action.mat_form,
            });
        case "MAT_ASSESSMENT_FILL":
        if (state.mat_form.length === 0 || !action.mat_assessment){
            return state;
        }    
        let updated_mat_form =  _.map(state.mat_form,(item)=>{
            if(item.id === action.mat_assessment.id){
                return {...action.mat_assessment};
            }else{
                return {...item};
            }
        }) ;
        return Object.assign({}, state, {
            mat_form: updated_mat_form,
        });
        case "MAT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
