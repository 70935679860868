import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import facilityActionCreator from '../actions/facility_action';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getFacilityList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_list";
        return Axios.post(url, params).then((response) => {
            dispatch(facilityActionCreator.facilityListFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getClientFacilityList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_facility_list";
        return Axios.post(url, params).then((response) => {
            dispatch(facilityActionCreator.facilityListFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const facilityDelete = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_delete";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Successfully deleted facility");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const facilityPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_post";
        return Axios.post(url, params).then((response) => {
            dispatch(facilityActionCreator.facilityObjectFill(response.data));
            Utils.showSuccessAlert("Successfully saved facility");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const facilityGet = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_get";
        return Axios.post(url, params).then((response) => {
            dispatch(facilityActionCreator.facilityObjectFill(
                response.data,
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getfacilityBookingList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_booking_list";
        return Axios.post(url, params).then((response) => {      
            dispatch(facilityActionCreator.facilityBookingListFill(
                response.facility, 
                response.booking_slots
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getClientFacilityBookingList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_facility_booking_list";
        return Axios.post(url, params).then((response) => {      
            dispatch(facilityActionCreator.facilityBookingListFill(
                response.facility, 
                response.booking_slots
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const facilityBookingGet = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_booking_get";
        return Axios.post(url, params).then((response) => {      
            dispatch(facilityActionCreator.facilityBookingObjectFill(
                response.facility,
                response.booking_slot, 
                response.client_list,
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const clientFacilityBookingGet = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_facility_booking_get";
        return Axios.post(url, params).then((response) => {      
            dispatch(facilityActionCreator.facilityBookingObjectFill(
                response.facility,
                response.booking_slot, 
                [],
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const clientFacilityBookingPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_facility_book_slot";
        return Axios.post(url, params).then((response) => {      
            dispatch(facilityActionCreator.facilityBookingObjectFill(
                response.facility,
                response.booking_slot, 
                [],
            ));      
            dispatch(hideProgress());
            Utils.showSuccessAlert("You have successfully booked!");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const clientFacilityBookingCancel = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_facility_bookings_cancel";
        return Axios.post(url, params).then((response) => {      
            dispatch(facilityActionCreator.facilityBookingObjectFill(
                response.facility,
                response.booking_slot, 
                [],
            ));      
            dispatch(hideProgress());
            Utils.showSuccessAlert("You have successfully cancelled your bookings");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const facilityBookingPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_booking_post";
        return Axios.post(url, params).then((response) => {      
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully updated bookings!");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



const facilitySlotAvailabilityToggle = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/facility_booking_availability_toggle";
        return Axios.post(url, params).then((response) => {   
            dispatch(hideProgress());
            Utils.showSuccessAlert("Selected facility slot availability has been updated");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getFacilityList,
    getClientFacilityList,
    facilityDelete,
    facilityPost,
    facilityGet,
    getfacilityBookingList,
    getClientFacilityBookingList,
    facilityBookingGet,
    clientFacilityBookingGet,
    facilityBookingPost,
    facilitySlotAvailabilityToggle,
    clientFacilityBookingCancel,
    clientFacilityBookingPost
};