import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'col-xs-6 m-20',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, {}, React.createElement(CardTitle, { 'title': 'Global Metric Settings' }), React.createElement(CardText, { 'className': 'm-t-15' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'sets',
        'type': 'number',
        'component': TextInput,
        'label': 'Sets'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'reps',
        'type': 'number',
        'component': TextInput,
        'label': 'Reps'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'distance',
        'type': 'number',
        'component': TextInput,
        'label': 'Distance'
    })), React.createElement('div', { 'className': 'col-xs-6 input-text-dark' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'distance_unit',
        'component': Select,
        'label': 'Distance Unit',
        'options': this.props.supportingData.distance_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Duration'
    })), React.createElement('div', { 'className': 'col-xs-6 input-text-dark' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'duration_unit',
        'component': Select,
        'label': 'Duration Unit',
        'options': this.props.supportingData.duration_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'hold',
        'type': 'number',
        'component': TextInput,
        'label': 'Hold Position'
    })), React.createElement('div', { 'className': 'col-xs-6 input-text-dark' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'hold_unit',
        'component': Select,
        'label': 'Hold Unit',
        'options': this.props.supportingData.hold_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'pause',
        'type': 'number',
        'component': TextInput,
        'label': 'Rest Between Sets'
    })), React.createElement('div', { 'className': 'col-xs-6 input-text-dark' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'pause_unit',
        'component': Select,
        'label': 'Rest Unit',
        'options': this.props.supportingData.duration_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'weight',
        'type': 'number',
        'component': TextInput,
        'label': 'Weight'
    })), React.createElement('div', { 'className': 'col-xs-6 input-text-dark' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'weight_unit',
        'component': Select,
        'label': 'Weight Unit',
        'options': this.props.supportingData.weight_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'heart_rate',
        'type': 'number',
        'component': TextInput,
        'label': 'Target Heart Rate'
    })))), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.pristine || this.props.submitting,
        'className': 'bg-success',
        'type': 'submit',
        'primary': true,
        'raised': true
    })))));
}