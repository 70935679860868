import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { NavLink } from 'react-router-dom';
import { Select as SelectNew } from 'react-select';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import { tetheredSelectAsynFunction as AsyncSelect } from '../../../utils/common_components/select_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from '../../../utils/common_components/dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Input from 'react-toolbox/lib/input';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
export default function () {
    function repeatAssociated_content1(associated_content, index) {
        return React.createElement('div', {
            'key': 'associated_list' + index,
            'className': 'associated-content'
        }, associated_content ? React.createElement('div', {
            'className': 'associated-title',
            'key': '11641'
        }, React.createElement('div', {
            'className': 'image-container',
            'style': {
                backgroundImage: 'url(' + associated_content.thumbnail_url + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }
        }), React.createElement('div', { 'className': 'text' }, '\n                                    ', associated_content.title, '\n                                '), React.createElement(IconButton, {
            'icon': 'close',
            'tooltip': 'Remove',
            'style': { cursor: 'pointer' },
            'onClick': () => {
                this.removeContentItem(associated_content.id);
            }
        })) : null);
    }
    function repeatAssociated_workout2(associated_workout, index) {
        return React.createElement('div', {
            'key': 'associated_list' + index,
            'className': 'associated-content'
        }, React.createElement('div', { 'className': 'associated-title' }, React.createElement('div', {
            'className': 'image-container',
            'style': {
                backgroundImage: 'url(' + (associated_workout.image_url || associated_workout.thumbnail_url) + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }
        }), React.createElement('div', { 'className': 'text' }, '\n                                    ', associated_workout.name, '\n                                    '), React.createElement(IconButton, {
            'icon': 'close',
            'tooltip': 'Remove',
            'style': { cursor: 'pointer' },
            'onClick': () => {
                this.removeWorkoutItem(associated_workout.id);
            }
        })));
    }
    return React.createElement('div', { 'className': 'row' }, React.createElement(NavLink, {
        'to': '#',
        'className': 'm-t-20 m-b-10 d-flex d-flex back-btn c-grey',
        'onClick': this.cancel
    }, '\n        Education\n    '), React.createElement(Card, { 'className': 'no-box-shadow m-r-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', {
        'className': 'add-media d-flex no-box-shadow',
        'onSubmit': this.props.properties.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'media-info p-b-36' }, React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(Card, { 'className': 'no-box-shadow bg-white' }, React.createElement(CardTitle, {
        'title': (this.props.properties.match.params.course_id ? 'Edit ' : 'Add new ') + this.props.properties.match.params.course_type,
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement(CardText, { 'className': 'w-100 p-t-20 p-0' }, React.createElement(Field, {
        'className': 'w-100',
        'label': 'Title',
        'name': 'title',
        'type': 'text',
        'component': TextInput
    }), !this.props.properties.match.params.course_id ? React.createElement('div', {
        'className': 'm-b-20',
        'key': '3132'
    }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'label': 'Content',
        'className': 'w-100',
        'name': 'description',
        'type': 'text',
        'component': RichTextEditor,
        'hint': 'Description',
        'multiline': true
    })) : null, this.props.properties.initialValues.description ? React.createElement('div', {
        'className': 'm-b-20',
        'key': '3475'
    }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'label': 'Content',
        'className': 'w-100',
        'name': 'description',
        'type': 'text',
        'component': RichTextEditor,
        'hint': 'Description',
        'multiline': true
    })) : null), React.createElement('h5', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Intro Video'), React.createElement('div', { 'className': 'd-flex' }, React.createElement('div', { 'className': 'preview-container d-flex w-100' }, this.props.properties.video_guid ? React.createElement('div', {
        'className': 'm-b-20 w-60',
        'style': { width: '300px !important' },
        'key': '4091'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-player',
        'handleUploadProcessing': true,
        'videoList': [this.props.properties.video_guid],
        'onPlayerInitialized': player => {
            this.player = player;
        }
    })) : null, React.createElement('div', { 'className': 'row video-btn-container p-t-8' }, React.createElement(VimeoUploader, {
        'className': 'col w-100 m-l-4 add-media-btn',
        'onUploadCompleted': this.onVideoUploaded,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'name': 'add_media',
        'buttonText': this.props.properties.video_guid ? 'Change Video' : 'Add Media'
    })))), React.createElement('div', { 'className': 'center-xs w-60' }, React.createElement(CardTitle, {
        'title': 'Thumbnail',
        'className': 'c-black f-14'
    }), React.createElement('div', { 'className': 'f-12 c-grey m-t-8 m-b-8 text-left' }, '\n                                            Select Play on the video and pause on the desired frame.\n                                            Then select "Update Thumbnail".\n                                        '), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, this.props.properties.video_thumbnail_url ? React.createElement(Card, {
        'className': 'bg-white p-0 m-l-5 m-r-5 m-b-20',
        'key': '6586'
    }, React.createElement('div', {
        'className': 'thumbnail h-200',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.properties.video_thumbnail_url) + ')' }
    })) : null, React.createElement(Button, {
        'className': 'm-b-20 bg-success-red p-l-18 p-r-18 f-14',
        'label': 'Update Thumbnail',
        'onClick': this.captureThumbnail,
        'primary': true,
        'raised': true
    })))), React.createElement('div', { 'className': 'media m-b-15' }, React.createElement('h5', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Logo'), React.createElement('div', { 'className': 'preview-container d-flex w-100' }, this.props.properties.logo_image || this.props.properties.is_image_uploaded ? React.createElement('div', {
        'className': 'image-list-container p-6 w-80 d-flex align-center',
        'key': '7792'
    }, React.createElement('div', { 'className': 'image-list-title w-100 d-flex flex-sb align-center' }, React.createElement('div', { 'className': 'd-flex align-center' }, React.createElement('div', {
        'className': 'image-container',
        'style': {
            backgroundImage: 'url(' + this.props.properties.logo_image + ')',
            width: '25px',
            height: '25px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }), React.createElement('div', { 'className': 'text p-l-7' }, '\n                                                        ', this.props.properties.image_name, '\n                                                    ')), React.createElement(IconButton, {
        'icon': 'close',
        'tooltip': 'Remove',
        'style': { cursor: 'pointer' },
        'onClick': this.onCancelImage
    }))) : null, React.createElement(Dropzone, {
        'style': { width: '290px' },
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadLogoImage
    }, React.createElement(Button, {
        'className': 'add-media-btn',
        'style': { height: '42px' },
        'label': this.props.properties.logo_image || this.props.properties.is_image_uploaded ? 'Change Image' : 'Add Image',
        'primary': true,
        'raised': true
    })))), React.createElement(Field, {
        'className': 'w-100 m-t-15',
        'label': 'Enrollment Link (Inspire360)',
        'name': 'enrollment_link',
        'type': 'text',
        'component': TextInput,
        'hint': 'https://'
    }))), React.createElement.apply(this, [
        Card,
        { 'className': 'related-content box-shadow p-t-24 p-b-36 p-l-24 p-r-24' },
        React.createElement(CardTitle, {
            'title': 'Associated Content (Optional)',
            'className': 'c-black form-subtitle m-b-28 f-18 w-100'
        }),
        React.createElement('div', {
            'className': 'p-relative search-box no-box-shadow m-b-20 p-5  vertical-align flex-space-between',
            'style': { alignItems: 'start' }
        }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
            'name': 'associated_category',
            'component': Select,
            'label': 'Category',
            'options': this.props.properties.contentCategories,
            'valueKey': 'db_value',
            'labelKey': 'label',
            'onChange': this.onSearch
        })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
            'component': Select,
            'multi': true,
            'icon': 'search',
            'onChange': this.onChangeContent,
            'options': this.props.properties.associatedContentOptions,
            'valueKey': 'id',
            'labelKey': 'title',
            'type': 'text',
            'name': 'content_associated',
            'label': 'Search by title'
        }))),
        _.map(this.props.properties.content_associated, repeatAssociated_content1.bind(this))
    ]), React.createElement.apply(this, [
        Card,
        { 'className': 'related-content box-shadow p-t-24 m-t-10 p-b-36 p-l-24 p-r-24' },
        React.createElement(CardTitle, {
            'title': 'Associated Workout Templates (Optional)',
            'className': 'c-black form-subtitle m-b-28 f-18 w-100'
        }),
        React.createElement('div', { 'className': 'p-relative search-box no-box-shadow m-b-20 p-5  vertical-align flex-space-between' }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
            'name': 'associated_workout_category',
            'component': Select,
            'label': 'Category',
            'options': this.props.properties.workoutCategories,
            'valueKey': 'id',
            'labelKey': 'name',
            'onChange': this.selectCategory
        })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
            'component': Select,
            'multi': true,
            'icon': 'search',
            'onChange': this.onChangeWorkout,
            'options': this.props.properties.associatedWorkouts,
            'valueKey': 'id',
            'labelKey': 'name',
            'type': 'text',
            'name': 'courses_workout_templates',
            'label': 'Search by title'
        }))),
        _.map(this.props.properties.courses_workout_templates, repeatAssociated_workout2.bind(this))
    ])), React.createElement('div', { 'className': 'publish-info' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24' }, React.createElement(Field, {
        'name': 'category',
        'component': Select,
        'label': 'Forum Category',
        'options': this.props.properties.categories,
        'valueKey': 'db_value',
        'labelKey': 'label'
    }), React.createElement('div', {}, React.createElement(Field, {
        'name': 'publish_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'Publish Date'
    })), React.createElement('div', { 'style': { marginTop: '20px' } }, React.createElement(Field, {
        'name': 'publish_time',
        'component': TimePicker,
        'label': 'Publish Time'
    })), React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-30 m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Save as Draft',
        'disabled': this.props.properties.submitting,
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'raised': true,
        'onClick': this.saveAsDraft
    }), React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.properties.submitting,
        'className': 'm-l-10 bg-success w-100',
        'raised': true,
        'onClick': this.saveAndPublish
    }))))))));
}