import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import GoalEdit from '../../../goal/components/goal_edit';
import GoalEditNew from '../../../goal_new/components/goal_edit';
import GoalList from '../../../goal/components/goal_list';
import GoalListNew from '../../../goal_new/components/goal_list';
import IntakeForm from '../../../intake/components/intake_form';
import IntakeFormNew from '../../../intake_new/components/intake_form';
import IntakeConfigure from '../../../intake/components/intake_configure';
import IntakeConfigureNew from '../../../intake_new/components/intake_configure';
import IntakeList from '../../../intake/components/intake_list';
import IntakeListNew from '../../../intake_new/components/intake_list';
import ExerciseEdit from '../../../exercise/components/exercise_edit';
import ExerciseList from '../../../exercise/components/exercise_list';
import ExerciseEditNew from '../../../exercise_new/components/exercise_edit_new';
import ExerciseListNew from '../../../exercise_new/components/exercise_list_new';
import CueEdit from '../../../exercise/components/cue_edit';
import CueList from '../../../exercise/components/cue_list';
import CueEditNew from '../../../exercise_new/components/cue_edit';
import CueListNew from '../../../exercise_new/components/cue_list';
import WrapperEdit from '../../../practice/wrapper/components/wrapper_video_edit';
import WrapperList from '../../../practice/wrapper/components/wrapper_video_list';
import DocumentEdit from '../../../documents/components/document_edit';
import DocumentList from '../../../documents/components/document_list';
import DocumentEditNew from '../../../documents_new/components/document_edit_new';
import DocumentListNew from '../../../documents_new/components/document_list_new';
import ProgramContainer from '../../../program_new/components/program_container';
import ProgramContainerOld from '../../../program/components/program_container';
import HomefitProgramList from '../../../homefit_workout/components/move_videos';
import HomefitProgramEdit from '../../../homefit_workout/components/move_video_edit';
import HomefitGroups from '../../../group_new/components/group_list';
import HomefitGroupsEdit from '../../../group_new/components/group_edit';
import TreatmentTemplateList from '../../../treatment_template/components/treatment_template_list';
import TreatmentTemplateEdit from '../../../treatment_template/components/treatment_template_edit';
import TreatmentTemplateListNew from '../../../treatment_template_new/components/treatment_template_list_new';
import TreatmentTemplateEditNew from '../../../treatment_template_new/components/treatment_template_edit_new';
import TreatmentTemplateDashboard from '../../../treatment_template/components/treatment_template_dashboard';
import TreatmentTemplateDashboardNew from '../../../treatment_template_new/components/treatment_template_dashboard';
import HabitTemplateList from '../../../habit/components/habit_template_list';
import HabitTemplateAddEdit from '../../../habit/components/habit_template_edit';
import HabitTemplateListNew from '../../../habit_new/components/habit_template_list_new';
import HabitTemplateAddEditNew from '../../../habit_new/components/habit_template_edit_new';
import NotificationTemplate from '../../../notification_templates/components/workfit_notification_template.jsx';
import NotificationTemplateEdit from '../../../notification_templates/components/workfit_notification_template_edit.jsx';
import { PARTNER } from '../../../core/constants/constants';
import GiContent from '../../../content/components/giContent';
import HomeFitContent from '../../../content/components/homeFitContent';
import ContentVideoEdit from '../../../content/components/content_video_edit';
import ContentVideoEditContainer from '../../../content/components/content_edit_container';
import ContentVideoAddContainer from '../../../content/components/content_add_container';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, PARTNER.app_type === 'dhf' || PARTNER.app_type === 'tri' ? React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu m-t-20',
        'key': '6217'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/goal`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Goal Templates')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/intake`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Intake Forms')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/exercise`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Exercises')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/p/template`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Workout Templates')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/habit_templates`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Habit Templates')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/d/nutrition`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Nutrition')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/d/guidance`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Guidance')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/treatment_templates/gameplan`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Game Plan Templates')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/treatment_templates/group`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Group Templates')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/cue`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Cues')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/wrapper`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Intro Videos'))) : null, React.createElement(Switch, {}, PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/goal/edit/:goal_id`,
        'component': GoalEditNew,
        'key': '9503'
    }) : null, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/goal/edit/:goal_id`,
        'component': GoalEdit
    }), PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/goal/add`,
        'component': GoalEditNew,
        'key': '9743'
    }) : null, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/goal/add`,
        'component': GoalEdit
    }), PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/goal`,
        'component': GoalListNew,
        'key': '9963'
    }) : null, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/goal`,
        'component': GoalList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/gi_content`,
        'component': GiContent
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/gi_content/:content_type/add`,
        'component': ContentVideoAddContainer
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/gi_content/:content_type/edit/:content_id`,
        'component': ContentVideoEditContainer
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/content`,
        'component': HomeFitContent
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/content/:content_type/add`,
        'component': ContentVideoAddContainer,
        'type': 'add'
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/content/:content_type/edit/:content_id`,
        'component': ContentVideoEditContainer,
        'type': 'edit'
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/intake/preview/:intake_id`,
        'component': IntakeFormNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/intake/edit/:intake_id`,
        'component': IntakeConfigureNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/intake/add`,
        'component': IntakeConfigureNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/intake`,
        'component': IntakeListNew
    }), PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercise/edit/:exercise_id`,
        'component': ExerciseEditNew,
        'key': '11436'
    }) : null, PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercise/add`,
        'component': ExerciseEditNew,
        'key': '11587'
    }) : null, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercise/edit/:exercise_id`,
        'component': ExerciseEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercise/add`,
        'component': ExerciseEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercise/:library`,
        'component': ExerciseList
    }), PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercise`,
        'component': ExerciseListNew,
        'key': '12043'
    }) : null, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercise`,
        'component': ExerciseList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/cue/edit/:cue_id`,
        'component': CueEditNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/cue/add`,
        'component': CueEditNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/cue`,
        'component': CueListNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/wrapper/edit/:wrapper_id`,
        'component': WrapperEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/wrapper/add`,
        'component': WrapperEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/wrapper`,
        'component': WrapperList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/d/:document_type/edit/:document_id`,
        'component': DocumentEditNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/d/:document_type/add`,
        'component': DocumentEditNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/d/:document_type`,
        'component': DocumentListNew
    }), PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'path': `${ this.props.match.url }/p/:program_type`,
        'component': ProgramContainer,
        'key': '13320'
    }) : null, React.createElement(Route, {
        'path': `${ this.props.match.url }/p/:program_type`,
        'component': ProgramContainerOld
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/habit_templates`,
        'component': HabitTemplateListNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/habit_templates/add`,
        'component': HabitTemplateAddEditNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/habit_templates/edit/:habit_template_id`,
        'component': HabitTemplateAddEditNew
    }), PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type`,
        'component': TreatmentTemplateListNew,
        'key': '14009'
    }) : null, PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type/edit/:treatment_template_id`,
        'component': TreatmentTemplateEditNew,
        'key': '14177'
    }) : null, PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type/add`,
        'component': TreatmentTemplateEditNew,
        'key': '14373'
    }) : null, PARTNER.app_type === 'gi' ? React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type/dashboard/:treatment_template_id`,
        'component': TreatmentTemplateDashboardNew,
        'key': '14545'
    }) : null, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type`,
        'component': TreatmentTemplateList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type/edit/:treatment_template_id`,
        'component': TreatmentTemplateEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type/add`,
        'component': TreatmentTemplateEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/treatment_templates/:treatment_type/dashboard/:treatment_template_id`,
        'component': TreatmentTemplateDashboard
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/notification_templates/add`,
        'component': NotificationTemplateEdit
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/notification_templates/edit/:notification_template_id`,
        'component': NotificationTemplateEdit
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/notification_templates`,
        'component': NotificationTemplate
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workout/list`,
        'component': HomefitProgramList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workout/add`,
        'component': HomefitProgramEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workout/edit/:workout_id`,
        'component': HomefitProgramEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/groups`,
        'component': HomefitGroups
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group/add`,
        'component': HomefitGroupsEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group/edit/:group_id`,
        'component': HomefitGroupsEdit
    })));
}