import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import { bindActionCreators } from 'redux';
import Template from "./templates/stripe_config";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import { getStripeConfiguration, deleteStripeConfiguration, goliveStripeConfiguration } from '../../redux/payment/network/stripeconfig_network';
import actionCreator from '../../redux/payment/actions/stripeconfig_action';
import Swal from 'sweetalert2';

class StripeConfig extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.stripeConfigurationStart = this.stripeConfigurationStart.bind(this);
        this.stripeConfigurationDelete = this.stripeConfigurationDelete.bind(this);
        this.stripeConfigurationGoLive = this.stripeConfigurationGoLive.bind(this);
    }
    
    componentWillMount() {
        this.props.getStripeConfiguration({
            practice_id: this.props.practice_id
        });
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

    stripeConfigurationStart() {

        const ratio = window.devicePixelRatio || 1;
        const w = screen.width * ratio;
        const h = screen.height * ratio;
        let win = this.popupCenter(this.props.stripe_url, "GoMotive", w-100,h-100);
        let pollTimer   =   window.setInterval(()=> {
            try{
                if(win.closed){
                    this.props.getStripeConfiguration();
                    window.clearInterval(pollTimer);
                }  
            }catch(e){
                if(win.closed){
                    this.props.getStripeConfiguration();
                    window.clearInterval(pollTimer);
                }  
            }
        },250);  
    }

    popupCenter(url, title, w, h) {                      
        let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
        let dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;
    
        let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    
        let left = ((width / 2) - (w / 2)) + dualScreenLeft;
        let top = ((height / 2) - (h / 2)) + dualScreenTop;
        let newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        if (window.focus) {
            newWindow.focus();
        }
        return newWindow;
    }

    stripeConfigurationDelete() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete your stripe configuration?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.deleteStripeConfiguration().then(() => {                
            }).catch((exception) => {                 
            });                        
        }, function (dismiss) {
                        
        });
    }
    
    stripeConfigurationGoLive(value) {
        let that = this;
        let display_text = "";
        if(value === true){
            display_text = "Would you like to go live with your current stripe configuration?. Are you sure?";
        } else {
            display_text = "Would you like to disable go live of your current stripe configuration?. Are you sure?";
        }

        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.goliveStripeConfiguration({
                'go_live': value
            }).then(() => {                
            }).catch((exception) => {                 
            });            
        }, function (dismiss) {
                        
        });
    }
    
    render() {
        // if(_.isEmpty(this.props.stripeAccountDetails)){
        //     return (<div></div>);
        // }
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        stripe_url: state.payment.stripeConfigReducer.stripe_url,
        stripeAccountDetails: state.payment.stripeConfigReducer.stripeAccountDetails,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getStripeConfiguration,
            deleteStripeConfiguration,
            goliveStripeConfiguration,
            cleanModule:actionCreator.cleanModule
        }, 
        dispatch
    );
}
const StripeConfigConnected = connect(mapStateToProps,mapDispatchToProps)(StripeConfig);
export default StripeConfigConnected;