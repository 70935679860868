import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { FieldArray } from 'redux-form';
import Switch from '../../../utils/common_components/switch_form';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement('form', {
        'className': 'col-xs-12 medium-labels',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'label': 'Appointment Date',
        'name': 'appointment_date',
        'component': DatePicker,
        'minDate': moment().subtract(1, 'days').toDate()
    })), React.createElement('div', { 'className': 'col-xs-3' }, React.createElement(Field, {
        'label': 'Start Time',
        'name': 'start_time',
        'component': TimePicker
    })), React.createElement('div', { 'className': 'col-xs-3' }, React.createElement(Field, {
        'label': 'End Time',
        'name': 'end_time',
        'component': TimePicker
    }))), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'bg-success',
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.doClose,
        'label': 'Close',
        'className': 'bg-accent m-l-5',
        'type': 'button',
        'raised': true
    })))));
}