import React from 'react';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/practitioner_update_bio";
import { reduxForm } from 'redux-form';
import store,{history} from '../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getPractitioner, postPractitioner} from '../../../redux/practice/network/practice_practitioner';
import actionCreator from '../../../redux/practice/actions/practice_practitioner';

class PractitionerEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.yesNoOptions=[
            {value: true, label: 'Yes'},
            {value: false, label: 'No'},
        ];
    }

    componentWillMount() {
        if(this.props.match.params.practitioner_id){
            this.props.getPractitioner({id: this.props.match.params.practitioner_id});
        }
    }

    doSave(data){
        let params = { ...data };
        this.props.postPractitioner({...params}).then(()=>{
            history.goBack();
        });
    }

    render() {
        if(_.isEmpty(this.props.practitioner)){
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPractitioner,
        postPractitioner,
        cleanModule: actionCreator.cleanModule
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        practitioner: state.practice.practicePractitioner.practitioner,
        initialValues: state.practice.practicePractitioner.practitioner,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'title',
        'bio',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const PractitionerEditForm = reduxForm(
    {
        form: 'PractitionerEditForm',
        enableReinitialize: true,
        validate
    }
)(PractitionerEdit);

const PractitionerEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PractitionerEditForm);

export default PractitionerEditConnected;
