import * as React from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-toolbox/lib/date_picker';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import EasyPie from '../../../utils/common_components/easypie.jsx';
export default function () {
    return React.createElement('div', {}, React.createElement('div', { 'className': 'panel w-100' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, this.props.habit.group_habit.name), React.createElement('div', { 'className': 'panel-subtitle' }, this.props.habit.group_habit.text)), this.props.trackHabitProgressCallback ? React.createElement('div', {
        'className': 'panel-footer bg-white vertical-align  center-xs',
        'key': '841'
    }, React.createElement(Button, {
        'onClick': () => {
            this.trackHabit(3);
        },
        'icon': 'done_all',
        'className': ` br-50em ${ this.props.habit.has_tracked === 3 ? 'bg-success' : 'c-accent-dark button-border' } `,
        'tooltip': 'Did it',
        'label': 'Did It',
        'flat': true
    }), React.createElement(Button, {
        'onClick': () => {
            this.trackHabit(2);
        },
        'icon': 'done',
        'className': ` br-50em m-l-10 ${ this.props.habit.has_tracked === 2 ? 'bg-primary' : 'c-accent-dark button-border' } `,
        'tooltip': 'Did Part of it',
        'label': 'Did Part of it',
        'flat': true
    }), React.createElement(Button, {
        'onClick': () => {
            this.trackHabit(1);
        },
        'icon': 'clear',
        'className': ` br-50em m-l-10 ${ this.props.habit.has_tracked === 1 ? 'bg-danger' : 'c-accent-dark button-border' } `,
        'tooltip': 'Didn\'t do it',
        'label': 'Didn\'t do it',
        'flat': true
    })) : null));
}