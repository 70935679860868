import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ProgramList from '../program_list_new';
import ProgramEdit from '../program_edit_new';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, this.props.programType ? React.createElement(Switch, { 'key': '1750' }, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/edit/:program_id`,
        'render': routeProps => {
            return this.returnProgramEdit(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/edit/:program_id/:type`,
        'render': routeProps => {
            return this.returnProgramEdit(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/add`,
        'render': routeProps => {
            return this.returnProgramEdit(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/add/:type`,
        'render': routeProps => {
            return this.returnProgramEdit(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/list`,
        'render': routeProps => {
            return this.returnProgramList(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/list/:type`,
        'render': routeProps => {
            return this.returnProgramList(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/`,
        'render': routeProps => {
            return this.returnProgramList(routeProps);
        }
    })) : null);
}