import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import PanelGroup from '../../../vendor/react-panelgroup/panelgroup';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatDocument1(document, documentIndex) {
        return React.createElement('div', { 'key': 'document' + document.id }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, '\n                                    ', document.name, '                    \n                                '), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
            'tooltip': 'Delete',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'className': 'bg-danger micro',
            'onClick': () => {
                this.removeHealthDocument(document);
            }
        }))))));
    }
    function repeatDocument2(document, documentIndex) {
        return [!(document.id in this.props.partner_document_ids) ? React.createElement('div', { 'key': '35531' }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-11' }, '\n                                        ', document.name, '                    \n                                    '), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
                'tooltip': 'Add',
                'icon': 'add',
                'floating': true,
                'mini': true,
                'className': 'bg-success micro',
                'onClick': () => {
                    this.addHealthDocument(document);
                }
            })))))) : null];
    }
    return React.createElement('div', { 'className': 'row start-xs  m-t-20 m-l-20 custom-divider' }, React.createElement('h3', { 'className': 'text-center m-5' }, '\n        Shared content library \'', this.props.partner_library.name, '\'\n    '), React.createElement(PanelGroup, { 'borderColor': '#eee' }, React.createElement('div', { 'className': 'col-xs-12 full-height' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, this.props.match.params.document_type === '1' ? React.createElement('h6', {
        'className': 'm-b-15',
        'key': '807'
    }, '\n                        Shared nutrition documents\n                    ') : null, this.props.match.params.document_type === '2' ? React.createElement('h6', {
        'className': 'm-b-15',
        'key': '978'
    }, '\n                        Shared guidance documents\n                    ') : null), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
        'tooltip': 'Remove all health documents from this shared content library',
        'label': 'REMOVE ALL',
        'className': 'bg-danger small-button c-white',
        'onClick': () => {
            this.removeAllHealthDocuments();
        }
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'm-10' },
        _.map(this.props.partner_library.documents, repeatDocument1.bind(this))
    ])), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, this.props.match.params.document_type === '1' ? React.createElement('h6', {
        'className': 'm-b-15',
        'key': '2764'
    }, '\n                        Nutrition documents you can share\n                    ') : null, this.props.match.params.document_type === '2' ? React.createElement('h6', {
        'className': 'm-b-15',
        'key': '2942'
    }, '\n                        Guidance documents you can share\n                    ') : null), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
        'tooltip': 'Add all health documents to this shared content library',
        'label': 'ADD ALL',
        'className': 'bg-success small-button c-white',
        'onClick': () => {
            this.addAllHealthDocuments();
        }
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'm-10' },
        _.map(this.props.documentList, repeatDocument2.bind(this))
    ]))));
}