// Action Creators
const conditionsTreatedListFill = (conditions_treated_list) => ({
    type: 'CONDITIONS_TREATED_LIST_FILL',
    conditions_treated_list: conditions_treated_list
});

const conditionsTreatedGetFill = (condition_treated) => ({
    type: 'CONDITIONS_TREATED_GET_FILL',
    condition_treated: condition_treated
});

const conditionsTreatedCleanup = () => ({
    type: 'CONDITIONS_TREATED_CLEANUP',
});

export default {
    conditionsTreatedListFill,
    conditionsTreatedGetFill,
    conditionsTreatedCleanup,
};
