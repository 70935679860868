import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/signup";
import GiTemplate from "./templates/gi_signup";
import { reduxForm } from 'redux-form';
import store,{history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signup, timezone_list } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import {PARTNER} from '../../core/constants/constants';

class Signup extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.acceptPrivacyPolicyChange = this.acceptPrivacyPolicyChange.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.state = {
            acceptPrivacyPolicy: true , //false
            is_gi_subdomain: PARTNER.subdomain === "gi" ? true : false
        };

    }
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        window.scrollTo(0, 0);
        if (this.props.isAuthenticated) {
            history.push('/home');
        } else {
            this.props.timezone_list();
        }
    }

    doSave(data) {
        if (!this.state.acceptPrivacyPolicy) {
            Utils.showInfoAlert("Please accept our privacy terms");
            return;
        }
        let params = { ...data };
        if (this.props.match.params.practice_guid && this.props.match.params.practice_guid !== null) {
            params["practice_guid"] = this.props.match.params.practice_guid;
            if(_.has(this.props.match.params,"user_role_type")){
                if(this.props.match.params.user_role_type === "p"){
                    params["user_role_type"] = "practice";                    
                }else if(this.props.match.params.user_role_type === "c"){
                    params["user_role_type"] = "client";
                }
            }
        }
        let hot_direct = sessionStorage.getItem("hot_redirect")
        console.log(hot_direct,'sign k baad hot')
        this.props.signup({...params})
        .then((resp)=>{
            console.log(resp, 'signup k baad')
            // if(window.location.pathname !== hot_redirect){
                    history.push(hot_direct);
                // }
            // history.push("/auth/onboard");
        })
    }

    acceptPrivacyPolicyChange() {
        this.setState({
            acceptPrivacyPolicy: !this.state.acceptPrivacyPolicy,
        });
    }
    
    gotoLogin(){
        history.push("/auth/login");
    }

    gotoOnboard(){
        history.push("/auth/onboard");
    }

    render() {
        if(!this.state.is_gi_subdomain){
            return Template.apply(this);
        } else {
            return GiTemplate.apply(this);
        }
    }
    
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        signup,
        timezone_list,
    }, dispatch);
}

function mapStateToProps(state) {
    let initialValues = {};
    initialValues['timezone'] = 'US/Pacific';
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        display_signup_form: state.auth.signupReducer.displaySignupForm,
        timezones: state.auth.authReducer.timezones,
        initialValues: initialValues,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'first_name',
        'last_name',
        'email',
        'password',
        'confirm_password',
        'timezone',
    ];
    if(_.has(props.match.params,"user_role_type") && props.match.params.user_role_type === "p"){
        requiredFields.push("practice_name");
        requiredFields.push("city");
        requiredFields.push("zipcode");
    }    
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });

    if (values['password'] !== undefined) {
        if (values['password'] !== null && values['password'].length < 6) {
            errors['password'] = 'Password must be minimum 6 characters in length.';
        }
    }    
    // if(values['password'] !== values['confirm_password']){
    //     errors['password'] = 'Passwords does not match';
    // } else if (values['password'] !== undefined) {
    //     if (values['password'].length < 6) {
    //         errors['password'] = 'Password must be minimum 6 characters in length.';
    //     }
    // }
    return errors;
}; 


const SignupForm = reduxForm(
    {
        form: 'SignupForm',
        enableReinitialize: true,
        validate
    }
)(Signup);

const SignupConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupForm);

export default SignupConnected;
