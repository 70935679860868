let initialState = {
    videoTutorials: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "NEWS_VIDEO_TUTORIALS_FILL":
            return Object.assign({}, state, {
                videoTutorials: action.videoTutorials,
            });
        case "NEWS_VIDEO_TUTORIALS_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};