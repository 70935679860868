import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/my_news";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { myNews } from '../../redux/news/network/news_list';

import actionCreator from '../../redux/news/actions/news_list';
import { history } from "../../redux/store";

class MyNews extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.loadMoreNews = this.loadMoreNews.bind(this);
        this.previewNews = this.previewNews.bind(this);
    }

    componentWillMount() {
        this.loadMoreNews(false);
    }

    loadMoreNews(increment=false) {
        let params = {  
            ...this.props.paginate_info
        };
        if(increment) {
            params.page = params.page + 1;
        }
        this.props.myNews(params);
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    previewNews(news) {
        if (this.props.selectedRole.role.name === 'client') {
            if(_.includes(this.props.location.pathname,"/workfit")){
                history.push("/client/workfit/news/view/" + news.id);
            }else{
                history.push("/client/news/view/" + news.id);
            }    
        } else {
            history.push("/practice/"+this.props.selectedRole.practice.id+"/news/view/" + news.id);
        }
    }
    


    render() {
        return Template.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        myNews,
        cleanUp: actionCreator.cleanModule
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let newsList = _.cloneDeep(state.news.newsList.newsList);
    _.forEach(newsList, (news) => {
        news.start_date = Utils.parseServerDateAsString(news.start_date);
        news.end_date = Utils.parseServerDateAsString(news.end_date);
    });
    return {
        newsList: newsList,
        paginate_info: state.news.newsList.paginate_info,
        selectedRole: state.auth.roleReducer.selectedRole,
        ...ownProps,
    };
}

const MyNewsConnected = connect(mapStateToProps, mapDispatchToProps)(MyNews);
export default MyNewsConnected;
