// Action Creators
const practiceDetailsFill = (data, supporting_data) => ({
    type: 'PRACTICE_DETAILS_FILL',
    data,
    supporting_data,
});
const cleanModule = () => ({
    type: 'PRACTICE_DETAILS_CLEANUP',
});
export default {
    practiceDetailsFill,
    cleanModule,
};