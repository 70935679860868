

// Action Creators
const updateAllRoles = (roles) => ({ type: 'AUTH_FILL_ROLES', roles});
const chooseRole = (selectedRole) => ({ type: 'AUTH_CHOOSE_ROLE', selectedRole });

let actionCreators = {
    updateAllRoles: updateAllRoles,
    chooseRole: chooseRole,
};

export default actionCreators;