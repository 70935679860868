// Action Creators
const fill = (videoTutorials) => ({
    type: 'NEWS_VIDEO_TUTORIALS_FILL',
    videoTutorials
});
const cleanModule = () => ({
    type: 'NEWS_VIDEO_TUTORIALS_CLEANUP',
});

export default {
    fill,
    cleanModule,
};