// Action Creators
const engagementGoalTrackingChartFill = (goal_track_id,goal_track_details) => ({
    type: 'ENGAGEMENT_GOAL_TRACKING_CHART_FILL',
    goal_track_id: goal_track_id,
    goal_track_details: goal_track_details,
});

const engagementGoalTrackingFormFill = (goal_targets) => ({
    type: 'CLIENT_ENGAGEMENT_GOAL_TARGETS_FILL',
    goal_targets: goal_targets,
});

const engagementGoalTrackingChartCleanup = () => ({
    type: 'ENGAGEMENT_GOAL_TRACKING_CHART_CLEANUP',
});

export default {
    engagementGoalTrackingChartFill,
    engagementGoalTrackingFormFill,
    engagementGoalTrackingChartCleanup,
};
