import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import actions from '../actions/group_habit';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const listHabits = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/" + API_VERSION + "/" + PACKAGE + "/group_habit_list";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(actions.fillHabitList(
                response['habits']
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getHabit = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url =baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_habit_get";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.fillHabit(
                response['habit'],
                response['supporting_data']
            ));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postHabit = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url =baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_habit_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Saved Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const createGroupHabitFromTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url =baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/create_group_habit_from_template";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteHabit = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url =baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_habit_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Deleted Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    listHabits,
    getHabit,
    postHabit,
    deleteHabit,
    createGroupHabitFromTemplate
};