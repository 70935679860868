import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Chip from 'react-toolbox/lib/chip';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { creatableFunction as Creatable } from '../../../utils/common_components/select_form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import Dropzone from 'react-dropzone';
export default function () {
    function repeatAttachment1(attachment, attachmentIndex) {
        return React.createElement('div', {
            'key': attachmentIndex,
            'className': 'm-t-5'
        }, !this.props.document.practice || this.props.document.practice && this.props.document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Chip, {
            'deletable': true,
            'onDeleteClick': () => {
                this.deleteAttachment(attachment.attachment_url);
            },
            'key': '2712'
        }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, '\n                                    ', attachment.attachment_name, '\n                                ')) : null, this.props.document.practice && this.props.document.practice.id !== this.props.selectedRole.practice.id ? React.createElement(Chip, { 'key': '3313' }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, '\n                                    ', attachment.attachment_name, '\n                                ')) : null);
    }
    return React.createElement('div', { 'className': 'row m-t-10 center-xs' }, React.createElement(Card, { 'className': 'col-xs-10 card-full-height' }, React.createElement(CardTitle, { 'title': (this.props.match.params.document_id ? 'EDIT ' : 'ADD ') + this.props.match.params.document_type.toUpperCase() }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement(Field, {
            'className': 'col-xs-12',
            'name': 'name',
            'type': 'text',
            'component': TextInput,
            'label': 'Name'
        }),
        React.createElement(Field, {
            'multiline': true,
            'className': 'col-xs-12',
            'name': 'description',
            'type': 'text',
            'component': TextInput,
            'label': 'Note',
            'rows': 4
        }),
        React.createElement('label', { 'className': 'w-100 text-left' }, 'Attachments'),
        this.state.attachments.length === 0 ? React.createElement('div', {
            'className': 'm-b-10',
            'key': '2344'
        }, React.createElement('label', { 'className': 'material-label f-italic f-13 w-100' }, 'No existing attachments')) : null,
        _.map(this.state.attachments, repeatAttachment1.bind(this)),
        !this.props.document.practice || this.props.document.practice && this.props.document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Dropzone, {
            'className': 'm-t-10',
            'multiple': true,
            'accept': 'application/pdf',
            'onDrop': this.onDrop,
            'style': {
                height: '60px',
                width: '100%',
                border: '2px dashed #ccc'
            },
            'key': '3749'
        }, React.createElement('div', { 'className': 'p-20' }, ' Click here or drag and drop to add new attachment')) : null,
        React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, !this.props.document.practice || this.props.document.practice && this.props.document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'disabled': this.props.submitting,
            'type': 'button',
            'onClick': this.goBack,
            'className': 'pull-right bg-accent',
            'label': 'Cancel',
            'raised': true,
            'key': '4400'
        }) : null, !this.props.document.practice || this.props.document.practice && this.props.document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'disabled': this.props.submitting,
            'type': 'submit',
            'className': 'pull-right m-r-5 bg-success',
            'label': 'Save',
            'primary': true,
            'raised': true,
            'key': '4833'
        }) : null)
    ])))));
}