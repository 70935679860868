// Action Creators
const fillQuickstartList = (acquiredQuickStarts, availableQuickStarts) => ({
    type: 'CLIENT_QUICKSTART_FILL',
    acquiredQuickStarts,
    availableQuickStarts,
});
const cleanModule = () => ({
    type: 'CLIENT_QUICKSTART_CLEANUP',
});

export default {
    fillQuickstartList,
    cleanModule,
};
