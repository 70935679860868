import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import NewsView from '../news_view.jsx';
export default function () {
    function repeatNews1(news, newsIndex) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-b-8 m-t-10 m-l-5 m-r-5',
            'key': 'news' + newsIndex
        }, React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '40px' }
        }, React.createElement('div', { 'className': 'col-xs-12 f-16' }, React.createElement(TooltipWrapper, {
            'tooltip': news.title,
            'className': 'truncate'
        }, '\n                    ', news.title, '\n                ')), !news.published ? React.createElement('div', {
            'className': 'col-xs-12 m-5 f-300 c-danger',
            'key': '2613'
        }, '\n                (Draft)\n            ') : null), React.createElement('div', { 'className': 'box-300-225 m-t-5 m-b-5' }, news.cover_media && news.cover_media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225',
            'style': { backgroundImage: 'url(' + encodeURI(news.cover_media.thumbnail_url) + ')' },
            'key': '2807'
        }) : null, news.cover_media && news.cover_media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225',
            'key': '3019'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'gomotive_video' + news.cover_media.guid,
            'videoList': [news.cover_media.guid]
        })) : null, !news.cover_media && news.content ? React.createElement('div', {
            'className': 'p-12',
            'style': {
                height: '205px',
                overflow: 'hidden'
            },
            'key': '3302'
        }, '\n                ', renderHTML(news.content), '\n            ') : null), React.createElement('div', { 'className': 'row center-xs m-0' }, !this.props.exerciseSelectionMode ? React.createElement('div', {
            'className': 'col-xs-12 p-b-2 m-0',
            'key': '3533'
        }, React.createElement(IconButton, {
            'icon': 'info_outline',
            'tooltip': 'Preview',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.showNewsPreviewDialog(news);
            }
        }), React.createElement(IconButton, {
            'icon': 'create',
            'tooltip': 'Edit',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.edit(news.id);
            }
        }), React.createElement(IconButton, {
            'icon': 'delete_forever',
            'tooltip': 'Delete',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.delete(news.id);
            }
        })) : null));
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        this.props.newsList.length === 0 ? React.createElement('div', {
            'className': 'alert-warning m-t-30 w-100 text-center',
            'key': '1982'
        }, '\n        Click \'+\' button to create news for your users.\n    ') : null,
        _.map(this.props.newsList, repeatNews1.bind(this)),
        this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
            'className': 'row center-xs m-30 w-100',
            'key': '4345'
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
            'tooltip': 'Load more news item',
            'label': 'Load more news...',
            'className': 'bg-primary c-black',
            'onClick': () => {
                this.loadMoreNews(true);
            }
        }))) : null,
        React.createElement(Button, {
            'tooltip': 'Create news article',
            'className': 'floating-add-button m-r-8',
            'onClick': this.add,
            'icon': 'add',
            'floating': true,
            'ripple': true
        }),
        React.createElement(Dialog, {
            'active': this.state.news,
            'className': 'full-dialog',
            'onEscKeyDown': this.hideNewsPreviewDialog,
            'onOverlayClick': this.hideNewsPreviewDialog
        }, React.createElement(NewsView, {
            'news': this.state.news,
            'handleClose': this.hideNewsPreviewDialog
        }))
    ]);
}