import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/client_group_list.rt";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { getClientGroupList } from '../../redux/group/network/client_group_network';
import Swal from 'sweetalert2';
import actions from '../../redux/group/actions/client_group_creator';

class ClientGroupList extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.goToGroupDashboard = this.goToGroupDashboard.bind(this);
    };
    
    componentWillMount() {
        this.props.getClientGroupList();
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

    goToGroupDashboard(group){
        history.push("/client/group/"+group.id);
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        group_list: state.group.clientGroupReducer.group_list,
        cleanModule: actions.clientGroupCleanup,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getClientGroupList,
        }, 
        dispatch
    );
}

const ClientGroupListConnected = connect(mapStateToProps,mapDispatchToProps)(ClientGroupList);
export default ClientGroupListConnected;