import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/calendar_home";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../redux/store';
import { Calendar as BigCalendar,momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getCalendarEventList } from '../../redux/calendar/network/calendar_list';

const localizer = momentLocalizer(moment)
class CalendarHome extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.renderEvent = this.renderEvent.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.finishEditing = this.finishEditing.bind(this);
        this.state = {
            editDialogShown: false,
            editingEventId: null,
        };
    }

    componentWillMount() {
        // BigCalendar.setLocalizer(
        //     BigCalendar.momentLocalizer(moment)
        // );
        this.props.getCalendarEventList({
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id,
        });
    }

    edit(event){
        this.setState({
            editDialogShown: true,
            editingEventId: event.id,
        });
    }

    finishEditing() {
        this.setState({
            editDialogShown: false,
            editingEventId: null,
        });
        this.props.getCalendarEventList({
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id,
        });
    }

    add(){
        this.setState({
            editDialogShown: true,
            editingEventId: null,
        });
    }

    renderEvent(event) {
        if (event.event) {
            event = event.event;
        }
        return (
            <span>{event.title}</span>
        );
    }

    componentWillUnmount() {
    }

    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state) {    
    let new_event_list = _.map([...state.calendar.calendarList.event_list], function (itemData) {
        let item = { ...itemData };
        if (item.calendar_type.name === "appointment") {
            item.event_type =  "calendar-individual-appointment";    
        } else if (item.calendar_type.name === "group_class") {
            item.event_type =  "calendar-group-class";    
        } else if (item.calendar_type.name === "facility_booking") {
            item.event_type =  "calendar-facility-booking";    
        } else {
            item.event_type =  "calendar-holiday";    
        }                
        item.title = item.display_name;
        item.start = moment(item.start_time).toDate();
        item.end = moment(item.end_time).toDate();
        item.allDay = item.all_day;
        return item;
    });
    return {
        localizer,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        eventList: new_event_list,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCalendarEventList,
    },
        dispatch
    );
}


const CalendarHomeConnected = connect(mapStateToProps, mapDispatchToProps)(CalendarHome);
export default CalendarHomeConnected;