
import BaseComponent from '../../utils/common_components/basecomponent';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import { useHistory } from 'react-router-dom'

import store,{history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listContent,getCategory,associatedContentSearch} from '../../redux/content/network/content';
import { postCourse, getCourse,getCoursesCategory, contentSearch, uploadMedia, getAssociatedContent, } from '../../redux/courses/network/courses'; 
import actionCreator from '../../redux/courses/actions/courses';
import {listProgram, getProgramFilters} from '../../redux/program/network/program_list';
import Utils from '../../utils/utils';
import React from 'react';
import axios from 'axios';
import Axios from '../../utils/network/axios';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';
import moment from 'moment';
import { param } from 'jquery';
import CourseEdit from './courses_content_edit'
import CourseActionCreator from '../../redux/courses/actions/courses_list'
import actionCreatorList from '../../redux/content/actions/exercise_list';


class CourseEditContainer extends BaseComponent {

    constructor(props, context) {
        super(props, context);

    }

    render() {
        return <CourseEdit properties={this.props}/>;
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectedAssociatedContentList : (content) => {
            return dispatch(actionCreatorList.selectedContent(
                content
            ));
        },
        selectedAssociatedWorkoutList : (workout) => {
            return dispatch(actionCreatorList.selectedWorkout(
                workout
            ));
        },
        removeContent: actionCreatorList.removeItem,
        removeWorkout: actionCreatorList.removeItemWorkout,
        listProgram,
        getCourse,
        associatedContentSearch,
        uploadFiles,
        getCategory,
        getCoursesCategory,
        getAssociatedContent,
        postCourse,
        listContent,
        contentSearch,
        getProgramFilters,
        uploadMedia,
        cleanModule: actionCreator.cleanModule,
        cleanModuleContent: actionCreatorList.cleanModule,
        showProgress,
        hideProgress,
        deleteItem: CourseActionCreator.deleteAssociatedContentItem,
    }, dispatch);
}
const selector = formValueSelector('CourseEditContainerForm');

function mapStateToProps(state, ownProps) {

    // let responseTime =  selector(state, 'published_at')
    // let newtime = Utils.parseServerDate(responseTime)
    // console.log(responseTime, "new time")
    let course = state.courses.courses.course
    let currentDate = moment().format("D MMM YYYY");
    let currentTime = moment().format();

    let initialValues = {
        publish_date: Utils.parseServerDate(currentDate),
        publish_time: Utils.parseServerDate(currentTime),
    }

    if(Object.keys(course).length){
     initialValues  = {
        ...course,
        publish_date: Utils.parseServerDate(state.courses.courses.course.publishedAt),
        publish_time: Utils.parseServerDate(state.courses.courses.course.publishedAt),
        // publish_date: Utils.parseServerDate(course.publish_date),
        // publish_time:Utils.parseServerDate(moment(`${course.publish_date} ${course.publish_time}`).format()),
    };
}

    // let selectedAssociatedContents =  selector(state, 'content_associated')
    // let contents = state.content.content.contentList  
    // let displayAssociatedContents =[];
    // if(selectedAssociatedContents && Object.keys(selectedAssociatedContents).length){
    //     selectedAssociatedContents.forEach((selectedAssociatedContent,index) => {
    //         if(typeof(selectedAssociatedContent)  === "object"){
    //             displayAssociatedContents.push(selectedAssociatedContent)
    //         }else{
    //             displayAssociatedContents.push( contents.find(content => content.id == selectedAssociatedContent))                
    //         }
    //     })
    // } 
    let selectedAssociatedContents =  state.content.content.selectedAssociatedContent
    let contents = state.content.content.contentListAssociated
    let displayAssociatedContents =[];
    if(selectedAssociatedContents && Object.keys(selectedAssociatedContents).length){
        Object.keys(selectedAssociatedContents).forEach((key) => {
            if(typeof(selectedAssociatedContents[key])  === "object"){
                displayAssociatedContents.push(selectedAssociatedContents[key])
            }else{
                let foundContent = contents.find(content => content.id == selectedAssociatedContents[key])
                if(foundContent){
                    displayAssociatedContents.push(foundContent)
                }            
            }
        })
    } 
    let selectedAssociatedWorkouts =  state.content.content.selectedAssociatedWorkout
    // let selectedAssociatedWorkouts =  selector(state, 'news_workout_templates')
    let workoutContents = state.program.programList.programListAssociated  
    let displayAssociatedWorkoutContents =[];
    if(selectedAssociatedWorkouts && Object.keys(selectedAssociatedWorkouts).length){
        Object.keys(selectedAssociatedWorkouts).forEach((key) => {
            if(typeof(selectedAssociatedWorkouts[key])  === "object"){
                displayAssociatedWorkoutContents.push(selectedAssociatedWorkouts[key])
            }else{
                let foundWorkout = workoutContents.find(workout => workout.id == selectedAssociatedWorkouts[key])
                if(foundWorkout){
                    displayAssociatedWorkoutContents.push(foundWorkout)
                }                
            }
        })
    }  
        // For Category response
        let categories = state.courses.courses.categories
        initialValues.category = categories.find(category => {
            return category.db_value === initialValues.category
          })
          initialValues.category = initialValues.category ? initialValues.category.db_value : "";
    return {
        selectedAssociatedContents: selectedAssociatedContents,
        selectedAssociatedWorkouts: selectedAssociatedWorkouts,
        selectedAssociatedContent: state.content.content.selectedAssociatedContent,
        selectedAssociatedWorkout: state.content.content.selectedAssociatedWorkout,
        // profile: profile,
        course: state.courses.courses.course,
        associatedWorkouts: state.program.programList.programList,
        content_associated:  displayAssociatedContents,
        workoutCategories: state.program.programList.filterData.category,
        courses_workout_templates: displayAssociatedWorkoutContents,
        is_image_uploaded: selector(state, 'is_image_uploaded'),
        is_audio_uploaded: selector(state, 'is_audio_uploaded'),
        logo_image: selector(state, 'logo_image'),
        user_guid: selector(state, 'user_guid'),
        image_name: selector(state, 'image_name'),
        // news: state.content.content.news,
        categories: categories,
        contentCategories: state.content.content.categories,
        associatedContentOptions: state.content.content.contentList ,
        initialValues:  initialValues,
        supportingData: state.exercise.exercise.supportingData,
        position_pictures: selector(state, 'position_pictures'),
        // video_thumbnail_url: selector(state, 'video_thumbnail_url') ? selector(state, "video_thumbnail_url") : media_data.thumbnail_url,
        video_thumbnail_url: selector(state, 'video_thumbnail_url'),
        video_guid: selector(state, "courses_media[0].url") || selector(state, 'video_guid'),
        is_client_specific: selector(state, 'is_client_specific'),
        duration: 'courses_media' in initialValues ? initialValues.courses_media[0].duration : 0,
        // category: selector(state, 'category'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'title','client', 'publish_date', 'add_media'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const CourseEditContainerForm = reduxForm(
    {
        form: 'CourseEditContainerForm',
        enableReinitialize:  true,
        validate
    }
)(CourseEditContainer);

const CourseEditContainerConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseEditContainerForm);

export default CourseEditContainerConnected;


