import * as React from 'react';
import * as _ from 'lodash';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import Button from 'react-toolbox/lib/button';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement('div', { 'className': 'col-xs-12 m-t-20' }, !this.props.welcome_video.is_uploaded ? React.createElement('div', {
        'className': 'm-b-10 p-10 alert-warning w-100 text-center',
        'key': '342'
    }, '\n            Upload a welcome video your clients will see the first time they login.\n        ') : null, !this.props.welcome_video.is_uploaded ? React.createElement('div', {
        'className': 'row center-xs',
        'key': '566'
    }, React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onUploadFailed
    })) : null, this.props.welcome_video.is_uploaded ? React.createElement('div', {
        'className': 'row center-xs',
        'key': '909'
    }, React.createElement('div', { 'className': 'col-xs-4 m-t-20' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video_' + this.props.welcome_video.guid,
        'videoList': [this.props.welcome_video.guid],
        'handleUploadProcessing': true
    })), React.createElement('div', { 'className': 'col-xs-12 m-t-20' }, !this.props.welcome_video.is_publish ? React.createElement(Button, {
        'onClick': this.publishWelcomeVideo,
        'label': 'Publish',
        'className': 'm-l-10 bg-success',
        'primary': true,
        'raised': true,
        'key': '1272'
    }) : null, this.props.welcome_video.is_publish ? React.createElement(Button, {
        'onClick': this.unpublishWelcomeVideo,
        'label': 'Unpublish',
        'className': 'm-l-10 bg-primary',
        'primary': true,
        'raised': true,
        'key': '1535'
    }) : null, React.createElement(Button, {
        'onClick': this.deleteWelcomeVideo,
        'label': 'Delete',
        'className': 'm-l-10 bg-danger',
        'primary': true,
        'raised': true
    }))) : null));
}