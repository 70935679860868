import React  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/engagement_habit.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';
import moment from 'moment';
import store from '../../redux/store';
import Swal from 'sweetalert2';
import { editHabit, getHabit } from '../../redux/engagement/network/engagement_network';


class EngagementHabit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.state = {
            publish: false,
        };
    }

    componentWillMount() {
        let params = {};
        if(this.props.habitId !== null) {
            params = {
                'id': this.props.habitId,
                'engagement_id': this.props.engagementId,
            };
        } else {
            params = {
                'id': null,
                'engagement_id': this.props.engagementId,
            };
        }
        this.props.getHabit(params);        
    }

    componentWillReceiveProps (nextProps) {
        if (this.props.schedule_type && nextProps.schedule_type  && this.props.schedule_type !== nextProps.schedule_type  ) {
            if (nextProps.schedule_type === 4 && nextProps.duration_type === 1) {
                nextProps.change("duration_type", 2);
            }else if (nextProps.schedule_type === 5 && (nextProps.duration_type === 1 || nextProps.duration_type === 2)) {
                nextProps.change("duration_type", 3);
            }
        }
    }


    componentWillUnmount() {
    }

    saveAsDraft() {
        this.setState({publish:false},()=>{
            this.props.handleSubmit(this.doSave)();
       });
    }

    saveAndPublish() {
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.doSave)();
        });
    }

    doSave(data) {
        let params = { ...data };   
        params["engagement_id"] = this.props.engagementId; 
        if(this.props.habitId) {
            params['habit_id'] = this.props.habitId;
        }
        params.is_published = this.state.publish;
        params["start_date"] = Utils.formatServerDate(params.start_date);        
        this.props.editHabit(params).then((response) => {
            if (params.is_published) {
                Utils.showSuccessAlert("Habit has been published");
            } else {
                Utils.showSuccessAlert("Habit has been saved. Kindly publish it later to be visible for your client.");
            }
            this.props.finishEditing();
        }).catch((error) => {
        });

    }

    doCancel() {
        this.props.finishEditing();
    }

    deleteVideo() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete the video?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.change('video_guid', null);
            that.props.change('thumbnail', null);         
        }, function (dismiss) {
                        
        });
    }

    onVideoUploadCompleted(result) {
        this.props.change('video_guid', result.guid);
        this.props.change('thumbnail', result.thumbnail);
    }

    onVideoUploadFailed(error) {
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }


    render() {
        return Template.apply(this);
    }
}

EngagementHabit.propTypes = {
    finishEditing: PropTypes.func,
};
EngagementHabit.defaultProps = {
    finishEditing: () => { },
};

const selector = formValueSelector('EngagementHabitForm');
function mapStateToProps(state, ownProps) {
    let initialValues = { ...state.engagement.engagementHabitReducer.habit };
    if (!_.isEmpty(initialValues)) {
        initialValues.start_date = Utils.parseServerDate(initialValues.start_date);
    }
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.authReducer.selectedRole,
        engagement: state.engagement.engagementReducer.engagement,
        initialValues: initialValues,
        supporting_data: state.engagement.engagementHabitReducer.supporting_data,
        video_guid: selector(state, 'video_guid'),
        schedule_type: selector(state, 'schedule_type'),
        duration_type: selector(state, 'duration_type'),

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            editHabit,
            getHabit,
        },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name','text','schedule_type','duration_type','duration_count','start_date'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
        if (field === "schedule_type") {
            if (values[field] === 4) {
                if (values["schedule_week_day"] === undefined || values["schedule_week_day"] === null) {
                    errors["schedule_week_day"] = 'Required';
                } 
                if (values["duration_type"] === 1) {
                    errors["duration_type"] = 'Duration type cannot be in days when selected schedule type is once a week';
                }
            } else if (values[field] === 5) {
                if (values["schedule_month_date"] === undefined || values["schedule_month_date"] === null) {
                    errors["schedule_month_date"] = 'Required';
                } else if (values["schedule_month_date"] > 31) {
                    errors["schedule_month_date"] = 'Day of month must be less than or equal to 31';
                }
                if (values["duration_type"] === 1 || values["duration_type"] === 2) {
                    errors["duration_type"] = 'Duration type cannot be in days or weeks when selected schedule type is once a month';
                }
            }
        }
    });
    return errors;
};

const EngagementHabitForm = reduxForm(
    {
        form: 'EngagementHabitForm',
        enableReinitialize: true,
        validate
    }
)(EngagementHabit);

const EngagementHabitFormConnected = connect(
        mapStateToProps,mapDispatchToProps
    )(EngagementHabitForm);
export default EngagementHabitFormConnected;
