import * as React from 'react';
import * as _ from 'lodash';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GoalTrack from '../client_engagement_goal_tracking';
import GoalTrackingLineChart from '../engagement_goal_tracking_chart';
import ClientGoalEdit from '../client_engagement_goal_edit';
import ChatList from '../../../conversation/components/chat_list';
import WorkoutProgressions from '../../../client_app/components/client_checkin_list';
import ClientPayment from '../../../payment/components/client_payment';
import IntakeForm from '../client_intake_form';
import EasyPie from '../../../utils/common_components/easypie.jsx';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import WelcomeVideo from '../../../client_app/components/welcome_video';
import AssessmentView from '../client_engagement_assessment_view';
export default function () {
    function repeatMissed_date1(missed_date, missed_dateIndex) {
        return React.createElement(Chip, {
            'onClick': () => {
                this.showGoalModal(missed_date);
            },
            'className': 'bg-danger box-shadow pointer',
            'key': missed_dateIndex
        }, '\n                                        ', moment(missed_date).format('MMMM Do YYYY'), '\n                                    ');
    }
    function repeatTrack2(goal, goalIndex, track, trackIndex) {
        return React.createElement('div', {
            'className': 'panel m-t-10',
            'key': track.id
        }, React.createElement('div', { 'className': 'panel-heading bg-beige panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, trackIndex + 1, '. ', track.track_question)), React.createElement('div', { 'className': 'panel-body p-0 row m-0' }, React.createElement('div', { 'className': 'col-xs-12  text-center' }, React.createElement('div', {
            'className': 'row middle-xs center-xs text-center',
            'style': { height: '100px' }
        }, React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'tooltip': 'Baseline Value',
            'className': 'bg-accent f-18 cursor-default',
            'label': track.initial_value !== null ? track.initial_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                        '), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'tooltip': 'Current Value',
            'className': 'bg-primary f-18 cursor-default',
            'label': track.current_value !== null ? track.current_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                        '), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'tooltip': 'Target Value',
            'className': 'bg-success f-18 cursor-default',
            'label': track.target_value !== null ? track.target_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                        ')))));
    }
    function repeatGoal3(goal, goalIndex) {
        return [
            React.createElement('h4', {
                'className': 'di-block m-l-10 c-dark',
                'style': { width: 'auto' },
                'key': '81761'
            }, '\n                            ', goal.text, '\n                        '),
            goal.goal_tracking.length === 0 ? React.createElement('div', {
                'className': 'p-10  bg-white box-shadow',
                'key': '81763'
            }, React.createElement('div', { 'className': 'p-10 f-16 alert-warning' }, '\n                                Please give me a little time to set up some tracking questions to match the goal you created. You will see them here once in place. Thanks.\n                            ')) : null,
            _.map(goal.goal_tracking, repeatTrack2.bind(this, goal, goalIndex))
        ];
    }
    function repeatIntake4(intake, intakeIndex) {
        return React.createElement('tr', { 'key': intake.id }, React.createElement('td', {}, intake.intake.name), React.createElement('td', { 'className': 'w-20 text-center bg-warning' }, React.createElement('span', {}, intake.submitted ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '12267'
        }) : null, !intake.submitted ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '12527'
        }) : null)), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'tooltip': 'Click to fill the intake form',
            'onClick': () => {
                this.showPreviewIntakeModal(intake);
            },
            'icon': 'create',
            'primary': true,
            'floating': true,
            'mini': true
        })));
    }
    function repeatAssessment5(assessment, assessmentIndex) {
        return [React.createElement('tr', { 'key': '148221' }, React.createElement('td', { 'className': 'text-center w-10' }, assessment.practitioner.name), React.createElement('td', { 'className': 'text-center w-10' }, moment(assessment.created_date).format('MMMM Do YYYY, h:mm a ')), React.createElement('td', { 'className': 'text-center w-60' }, assessment.assessment_name), React.createElement('td', { 'className': 'text-center w-20' }, React.createElement(Button, {
                'tooltip': 'View Assessment Details',
                'onClick': () => {
                    this.viewAssessmentDetails(assessment);
                },
                'icon': 'info',
                'primary': true,
                'floating': true,
                'mini': true
            })))];
    }
    function repeatTrack6(goal, goalIndex, track, trackIndex) {
        return React.createElement('div', {
            'className': 'panel m-t-10',
            'key': track.id
        }, React.createElement('div', { 'className': 'panel-heading bg-beige panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, trackIndex + 1, '. ', track.track_question)), React.createElement('div', { 'className': 'panel-body p-0 row m-0' }, React.createElement('div', {
            'className': 'col-xs-12 col-md-8',
            'style': { borderRight: '2px dashed #6C7F8D' }
        }, React.createElement('div', {
            'style': {
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            },
            'className': 'text-center p-relative'
        }, track.initial_value !== null || track.target_value !== null ? React.createElement(GoalTrackingLineChart, {
            'engagement': this.props.engagement,
            'goal_track': track,
            'key': '18400'
        }) : null)), React.createElement('div', { 'className': 'col-xs-12 col-md-4 text-center' }, React.createElement('div', {
            'className': 'row middle-xs text-center',
            'style': { height: '250px' }
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'tooltip': 'Baseline Value',
            'className': 'bg-accent f-18 cursor-default',
            'label': track.initial_value !== null ? track.initial_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                        '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'tooltip': 'Current Value',
            'className': 'bg-primary f-18 cursor-default',
            'label': track.current_value !== null ? track.current_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                        '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'tooltip': 'Target Value',
            'className': 'bg-success f-18 cursor-default',
            'label': track.target_value !== null ? track.target_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                        ')))));
    }
    function repeatGoal7(goal, goalIndex) {
        return [
            React.createElement('h4', {
                'className': 'di-block m-l-10 c-dark',
                'style': { width: 'auto' },
                'key': '170941'
            }, '\n                            ', goal.text, '\n                        '),
            goal.goal_tracking.length === 0 ? React.createElement('div', {
                'className': 'p-10  bg-white box-shadow',
                'key': '170943'
            }, React.createElement('div', { 'className': 'p-10 f-16 alert-warning' }, '\n                                Please give me a little time to set up some tracking questions to match the goal you created. You will see them here once in place. Thanks.\n                            ')) : null,
            _.map(goal.goal_tracking, repeatTrack6.bind(this, goal, goalIndex))
        ];
    }
    function repeatAppointment8(appointment, appointmentIndex) {
        return [React.createElement('tr', { 'key': '224341' }, React.createElement('td', { 'className': 'text-center' }, appointment.practitioner.name), React.createElement('td', { 'className': 'text-center' }, moment(appointment.calendar.start_time).format('MMMM Do YYYY, h:mm a ')), React.createElement('td', { 'className': 'text-center' }, moment(appointment.calendar.end_time).format('MMMM Do YYYY, h:mm a ')))];
    }
    return React.createElement('div', { 'className': 'row p-15 m-b-25' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'panel m-b-0' }, React.createElement('div', { 'className': 'panel-body p-t-10' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement(TooltipWrapper, {
        'onClick': this.viewAllEngagements,
        'tooltip': 'Click to view all game plans',
        'className': 'pointer',
        'style': {
            borderRadius: '50%',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
            border: '2px solid #eee'
        }
    }, React.createElement(Avatar, {
        'size': 65,
        'user': this.props.engagement.practitioner,
        'profile_type': 'circle'
    })), React.createElement('div', { 'className': 'flex-auto m-l-5' }, React.createElement('h4', { 'className': 'm-b-2' }, this.props.engagement.name), React.createElement('div', {}, this.props.engagement.practitioner.name, ' (', this.props.engagement.practice.name, ')   '), React.createElement('div', { 'className': 'f-12 c-accent-light' }, ' \n                            ', moment(this.props.engagement.created_date).format('MMMM Do YYYY '), '\n                        '), React.createElement('div', { 'className': 'f-12 c-accent-light' }, React.createElement('span', { 'className': 'vertical-align' }, !this.props.engagement.payment_plan ? React.createElement(Chip, { 'key': '3081' }, '\n                                    Bronze Plan\n                                ') : null, this.props.engagement.payment_plan ? React.createElement(Chip, { 'key': '3252' }, React.createElement('i', { 'className': 'material-icons f-14' }, 'verified_user'), '\n                                    ', this.props.engagement.payment_plan.name, '\n                                ') : null))), React.createElement('div', { 'className': 'd-flex flex-column' }, React.createElement('span', { 'style': { height: '25px' } }, this.props.engagement.is_active ? React.createElement('span', {
        'className': 'p-4 bg-success m-r-5',
        'style': { height: '25px' },
        'key': '3736'
    }, '\n                                Active\n                            ') : null, !this.props.engagement.is_active ? React.createElement('span', {
        'className': 'p-4 bg-danger m-r-5',
        'style': { height: '25px' },
        'key': '3936'
    }, '\n                                Closed\n                            ') : null))), React.createElement('div', { 'className': 'd-flex w-100' }, React.createElement('div', { 'className': 'flex-auto' }), React.createElement('div', { 'className': 'text-right m-t-5 m-r-5' }))))), React.createElement(Tabs, {
        'inverse': true,
        'fixed': true,
        'className': 'custom-tab accent col-xs-12',
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'label': 'Goal',
        'icon': 'gm-goal',
        'gomotive': true
    }, this.props.needs_goal_tracking_target_update ? React.createElement('div', { 'key': '6163' }, React.createElement('div', { 'className': 'p-10 f-16 alert-warning m-b-10' }, '\n                        Please update the baseline and target values below\n                    '), React.createElement(ClientGoalEdit, {
        'inlineDisplay': true,
        'engagement': this.props.engagement,
        'refreshData': this.refreshData
    })) : null, !this.props.needs_goal_tracking_target_update ? React.createElement('div', {
        'className': 'p-t-20',
        'style': { margin: '-17px' },
        'key': '6564'
    }, this.props.engagement.client_untracked_goal_dates.length > 0 ? React.createElement('div', {
        'className': 'row w-100 center-xs p-15',
        'key': '6683'
    }, React.createElement('h4', { 'className': 'c-dark text-left' }, '\n                            Goal - Not tracked\n                        '), React.createElement('div', { 'className': 'alert-warning m-t-15 w-100 text-center' }, '\n                            Goal completion summary will be displayed once you finish tracking.\n                        '), React.createElement('div', { 'className': 'triangle-with-shadow auto-margin' }), React.createElement('div', { 'className': 'row w-100 center-xs' }, React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-5 p-20 search-box text-center' },
        _.map(this.props.engagement.client_untracked_goal_dates, repeatMissed_date1.bind(this))
    ]))) : null, this.props.engagement.client_untracked_goal_dates.length === 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '8055' },
        _.map(this.props.engagement.goals, repeatGoal3.bind(this))
    ]) : null) : null), React.createElement(Tab, {
        'label': 'Intake',
        'icon': 'gm-intake',
        'gomotive': true
    }, this.props.engagement.intakes.length > 0 ? React.createElement('div', {
        'className': 'panel timeline-panel',
        'key': '11105'
    }, React.createElement('div', { 'className': 'panel-heading bg-beige' }, React.createElement('h4', { 'className': 'panel-title' }, 'Please complete any forms shown in yellow')), React.createElement('div', { 'className': 'panel-body p-0' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Status'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.engagement.intakes, repeatIntake4.bind(this))
    ])))) : null, this.props.engagement.intakes.length === 0 ? React.createElement('div', { 'key': '13366' }, React.createElement('div', { 'className': 'alert-warning' }, 'No intake form has been assigned to you.')) : null), React.createElement(Tab, {
        'label': 'Assessments',
        'icon': 'gm-assessment',
        'gomotive': true
    }, React.createElement('div', {
        'className': 'p-20',
        'style': { margin: '-17px' }
    }, React.createElement('table', { 'className': 'table reactable col-xs-12' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', { 'className': 'text-center w-10 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Practitioner')), React.createElement('th', { 'className': 'text-center w-10 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Date')), React.createElement('th', { 'className': 'text-center w-60 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Title')), React.createElement('th', { 'className': 'text-center w-20 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Action')))), React.createElement.apply(this, [
        'tbody',
        {},
        this.props.engagement.assessments.length === 0 ? React.createElement('tr', { 'key': '14490' }, React.createElement('td', {
            'colSpan': '100',
            'className': 'p-20'
        }, '\n                                            No Assessments have been added.\n                                        ')) : null,
        _.map(this.props.engagement.assessments, repeatAssessment5.bind(this))
    ])))), React.createElement(Tab, {
        'label': 'Program',
        'icon': 'gm-treatment',
        'gomotive': true
    }, !this.props.engagement.show_treatment ? React.createElement('div', {
        'className': 'full-panel',
        'key': '15970'
    }, React.createElement('div', { 'className': 'alert-warning w-100 text-center' }, ' \n                    Please subscribe below to start this program.\n                '), React.createElement(ClientPayment, {
        'subscribeSuccessCallback': this.subscribeSuccessCallback,
        'current_plan': this.props.engagement.client_payment,
        'choose_plan': this.props.engagement.payment_plan
    })) : null, this.props.engagement.show_treatment ? React.createElement(WorkoutProgressions, {
        'engagement_id': this.props.engagement.id,
        'key': '16492'
    }) : null), React.createElement(Tab, {
        'label': 'Results',
        'icon': 'gm-results',
        'gomotive': true
    }, this.props.needs_goal_tracking_target_update ? React.createElement('div', { 'key': '16710' }, React.createElement('div', { 'className': 'p-10 f-16 alert-warning m-b-10' }, '\n                        Please update the baseline and target values under the Goals tab!\n                    ')) : null, !this.props.needs_goal_tracking_target_update ? React.createElement.apply(this, [
        'div',
        { 'key': '16990' },
        _.map(this.props.engagement.goals, repeatGoal7.bind(this))
    ]) : null)), this.state.selectedTrackingData !== null ? React.createElement(Dialog, {
        'active': this.state.openGoalModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideGoalModal,
        'onOverlayClick': this.hideGoalModal,
        'key': '20683'
    }, React.createElement(GoalTrack, {
        'engagement': this.props.engagement,
        'track_date': this.state.selectedTrackingData,
        'handleClose': this.hideGoalModal
    })) : null, !_.isEmpty(this.state.previewIntake) ? React.createElement(Dialog, {
        'active': this.state.openPreviewIntakeModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hidePreviewIntakeModal,
        'onOverlayClick': this.hidePreviewIntakeModal,
        'key': '21079'
    }, React.createElement(IntakeForm, {
        'intake': this.state.previewIntake,
        'handleClose': this.hidePreviewIntakeModal
    })) : null, React.createElement(Dialog, {
        'active': this.state.openAppointmentModal,
        'title': 'Appointments',
        'onEscKeyDown': this.hideAppointmentModal,
        'onOverlayClick': this.hideAppointmentModal
    }, this.state.openAppointmentModal ? React.createElement('div', { 'key': '21639' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', { 'className': 'text-center' }, 'Practitioner'), React.createElement('th', { 'className': 'text-center' }, 'Start Time'), React.createElement('th', { 'className': 'text-center' }, 'End Time'))), React.createElement.apply(this, [
        'tbody',
        {},
        this.props.engagement.appointments.length === 0 ? React.createElement('tr', { 'key': '22073' }, React.createElement('td', { 'colSpan': '8' }, React.createElement('div', { 'className': 'alert-warning' }, '\n                                Practitioner has not set up any appointment schedule as of now\n                            '))) : null,
        _.map(this.props.engagement.appointments, repeatAppointment8.bind(this))
    ]))) : null), React.createElement(Dialog, {
        'active': this.state.openChatModal,
        'title': 'Chat Conversations',
        'className': 'full-dialog',
        'onEscKeyDown': this.hideChatModal,
        'onOverlayClick': this.hideChatModal
    }, this.props.engagement.conversation ? React.createElement(ChatList, {
        'conversation_id': this.props.engagement.conversation.id,
        'handleClose': this.hideChatModal,
        'key': '23235'
    }) : null), this.state.show_welcome_video ? React.createElement(WelcomeVideo, {
        'onCloseHandle': this.closeWelcomeVideo,
        'welcome_video': this.props.engagement.show_welcome_video,
        'key': '23437'
    }) : null, !_.isEmpty(this.state.previewAssessment) ? React.createElement(Dialog, {
        'active': this.state.viewAssessmentModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideAssessmentModal,
        'onOverlayClick': this.hideAssessmentModal,
        'key': '23628'
    }, React.createElement(AssessmentView, {
        'assessment': this.state.previewAssessment,
        'handleClose': this.hideAssessmentModal
    })) : null);
}