import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/messages.rt";
import { getMessageList, deleteMessages, readMessages } from '../../redux/message/network/message_network';
import MessageAction from '../../redux/message/actions/message_creator';


class Messages extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.messagesMarkRead = this.messagesMarkRead.bind(this);
        this.messagesDelete = this.messagesDelete.bind(this);
        this.messageView = this.messageView.bind(this);
        this.closeViewMessage = this.closeViewMessage.bind(this);
        this.state = {
            message_selected_state: [],
            checkboxHeaderState: false,
            enableButtons: false,
            viewMessage: null,
        };
    };
    
    componentWillMount() {
        this.props.getMessageList(this.props.paginate_info);
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    onPageChange(page){
        this.props.getMessageList({
            page:page,
            sort_by:this.props.paginate_info.sort_by,
            asc:this.props.paginate_info.asc,
            filters: {...this.props.paginate_info.filters}
        });
        this.setState({
            message_selected_state:[],
            checkboxHeaderState: false,
            enableButtons: false,
        });
    }    
    onSort(sortData){
        let asc = false;
        if(sortData.direction === 1){
            asc = true;
        }
        this.props.getMessageList({
            page:0,
            sort_by:sortData.column,
            asc:asc,
            filters: {...this.props.paginate_info.filters}
        });
    } 

    handleCheckboxChange(field, value){
        let message_selected_state = this.state.message_selected_state;
        if(value){
            message_selected_state.push(field);
        }else{
            message_selected_state = _.pull(message_selected_state, field);
        }
        let enableButtons = false;
        if(message_selected_state.length > 0) {
            enableButtons = true;
        }
        this.setState({
            message_selected_state: _.uniq(message_selected_state),
            enableButtons: enableButtons
        });
    }

    handleCheckboxHeaderChange(value){
        if(value){
            let message_selected_state = [];
            _.forEach(this.props.messages, function(item){
                message_selected_state.push(item.id);
            });
            this.setState({
                message_selected_state: message_selected_state,
                checkboxHeaderState: value,
                enableButtons: true,
            });        
         } else {
            this.setState({
                message_selected_state:[],
                checkboxHeaderState: value,
                enableButtons: false,
            });
        }
    }


    messagesDelete() {
        this.props.deleteMessages({
            messages_list: this.state.message_selected_state
        }).then((response) => {
            this.setState({
                message_selected_state:[],
                checkboxHeaderState: false,
                enableButtons: false,
            });
            this.props.getMessageList(this.props.paginate_info);
        }).catch((error) => {
            //do nothing
        });  
    }

    messagesMarkRead() {
        this.props.readMessages({
            messages_list: this.state.message_selected_state
        }).then((response) => {
            this.setState({
                message_selected_state:[],
                checkboxHeaderState: false,
                enableButtons: false,
            });
            this.props.getMessageList(this.props.paginate_info);
        }).catch((error) => {
            //do nothing
        });  
    }

    messageView(message) {
        this.setState({
            viewMessage: message,
        });
    }

    closeViewMessage() {
        this.props.getMessageList(this.props.paginate_info)
        .then((response) => {
            this.setState({
                viewMessage: null,
            });    
        }).catch((error) => {
            //do nothing
        });  
    }

    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        messages: state.message.messageReducer.messages,
        paginate_info:state.message.messageReducer.paginate_info,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getMessageList,
        deleteMessages,
        readMessages,
        cleanUp: MessageAction.messageCleanup,
    }, dispatch),
    dispatch
});

const MessagesConnected = connect(mapStateToProps,mapDispatchToProps,)(Messages);
export default MessagesConnected;
