import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {
  showProgress,
  hideProgress
} from '../../core/actions/progress_creator';
import exerciseListAction from '../actions/exercise_list';
import exerciseAction from '../actions/exercise';

const listExercise = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_list';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(hideProgress());
        dispatch(
          exerciseListAction.fillExerciseList(
            response['exercises'],
            response['paginate_info']
          )
        );
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};
const listExerciseFilter = (params) => {
  return (dispatch) => {
    // dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_search_filter';
    return Axios.post(url, params)
      .then(async (response) => {
        // dispatch(hideProgress());
        dispatch(
          exerciseListAction.fillExerciseFilterList(
            response['filters'],
          )
        );
      })
      .catch((error) => {
        // dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};
const getExerciseFilters = (params) => {
  return (dispatch) => {
    const url =
      '/' + API_VERSION + '/' + PACKAGE + '/exercise_search_params_get';
    return Axios.post(url, params)
      .then(async (response) => {
        let first = false;
        if (params.first) {
          first = true;
        }
        dispatch(
          exerciseListAction.fillExerciseFilterData(
            response['supporting_data'],
            first
          )
        );
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const saveSearchPreferences = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url =
      '/' + API_VERSION + '/' + PACKAGE + '/exercise_save_search_preference';
    return Axios.post(url, params)
      .then(async (response) => {
        Utils.showSuccessAlert('Successfully saved search preferences');
        dispatch(hideProgress());
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const getExercise = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_get';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(hideProgress());
        dispatch(
          exerciseAction.fillExercise(
            {
              ...response['exercise'],
              exercise_filter_subcategory_options: response['exercise_filter_subcategory_options'],
              exercise_filter_subcategory_options_phase: response['exercise_filter_subcategory_options_phase']
            },
            response['supporting_data'],
            response['shopify_equipment']
          )
        );
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const postExercise = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_post';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(hideProgress());
        Utils.showSuccessAlert('Exercise Successfully Saved');
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const deleteExercise = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_delete';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(exerciseListAction.removeExerciseList(response['exercise']));
        dispatch(hideProgress());
        if (params.toggle_visibility === 'hide') {
          Utils.showSuccessAlert('Exercise has been hidden successfully');
        } else {
          Utils.showSuccessAlert('Exercise has been activated');
        }
        return response;
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};
const toggleExerciseFavorite = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_toggle_favorite';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(
          exerciseListAction.updateExerciseListAfterToggleFavorite(
            response['exercise']
          )
        );
        dispatch(hideProgress());
        if (response['exercise'].is_favorite) {
          Utils.showSuccessAlert(
            'Selected exercise has been added to your favorite list'
          );
        } else {
          Utils.showSuccessAlert(
            'Selected exercise has been removed from your favorite list'
          );
        }
        return response;
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
      });
  };
};
const uploadMedia = (params) => {
  return (dispatch) => {
    dispatch(showProgress());
    const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_media_upload';
    return Axios.post(url, params)
      .then(async (response) => {
        dispatch(hideProgress());
        Utils.showSuccessAlert('Successfully Uploaded');
        return response;
      })
      .catch((error) => {
        dispatch(hideProgress());
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          Utils.showErrorAlert(error.response.data.reason);
        }
        throw error;
      });
  };
};

export {
  listExerciseFilter,
  listExercise,
  getExerciseFilters,
  getExercise,
  postExercise,
  deleteExercise,
  uploadMedia,
  toggleExerciseFavorite,
  saveSearchPreferences
};
