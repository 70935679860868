import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import StripeCheckout from 'react-stripe-checkout';
import { STRIPE_KEY } from '../../constants/constants';
export default function () {
    function repeatPlan1(plan, planIndex) {
        return [plan.plan_type === 3 ? React.createElement('div', {
                'className': 'col-md-3 col-sm-6',
                'key': '4421'
            }, React.createElement('div', { 'className': 'pricing-widget text-center' }, React.createElement('div', {
                'className': `pricing-head f-20 d-flex flex-column justify-center  ${ this.props.partner_plan.selected_plan && this.props.partner_plan.selected_plan.id === plan.id ? 'bg-primary' : '' }    `,
                'style': { minHeight: '200px' }
            }, React.createElement('div', { 'className': 'w-100 f-400' }, plan.name), React.createElement('div', { 'className': `f-18 w-100  ${ this.props.partner_plan.selected_plan && this.props.partner_plan.selected_plan.id === plan.id ? '' : 'c-primary' }    ` }, plan.plan_payment_type === 1 ? React.createElement('span', { 'key': '1155' }, ' ', plan.default_currency.toUpperCase(), ' ', plan.fee_amount, ' / month ') : null, plan.plan_payment_type === 2 ? React.createElement('span', { 'key': '1305' }, ' ', plan.default_currency.toUpperCase(), ' ', plan.fee_amount, ' / year ') : null, plan.plan_payment_type === 3 ? React.createElement('span', { 'key': '1454' }, ' ', plan.default_currency.toUpperCase(), ' ', plan.fee_amount, ' / ', plan.month_duration_count, ' month(s) ') : null), React.createElement('span', {}, !this.props.partner_plan.selected_plan || this.props.partner_plan.selected_plan.id !== plan.id ? React.createElement(StripeCheckout, {
                'name': 'GoMotive, Inc.',
                'description': this.props.partner_plan.partner.name,
                'image': '/site_media/static/images/touch-icon-iphone.png',
                'panelLabel': 'Pay',
                'amount': plan.fee_amount * 100,
                'currency': plan.default_currency.toUpperCase(),
                'stripeKey': STRIPE_KEY,
                'email': this.props.user.email,
                'zipCode': false,
                'allowRememberMe': false,
                'token': token => {
                    this.subscribe(token, plan.id, this.props.partner_plan.partner.id);
                },
                'key': '1792'
            }, React.createElement(Button, {
                'className': 'bg-success small-button m-t-20',
                'icon': 'card_membership',
                'label': 'Subscribe',
                'primary': true,
                'raised': true
            })) : null, this.props.partner_plan.selected_plan && this.props.partner_plan.selected_plan.id === plan.id ? React.createElement('span', { 'key': '3153' }, this.props.partner_plan.selected_plan.plan_payment_type !== 3 ? React.createElement(Button, {
                'className': 'bg-accent small-button m-t-20',
                'icon': 'money_off',
                'onClick': () => {
                    this.unSubscribe(plan.id, this.props.partner_plan.partner.id);
                },
                'label': 'Unsubscribe',
                'primary': true,
                'raised': true,
                'key': '3302'
            }) : null, this.props.partner_plan.selected_plan.plan_payment_type === 3 ? React.createElement('label', {
                'className': 'f-italic m-t-25 f-18',
                'key': '3916'
            }, 'Paid') : null) : null)), plan.discount_coupon !== null ? React.createElement('div', {
                'className': 'discount-body p-t-5 p-b-5',
                'key': '4152'
            }, '\n                        ', plan.discount_coupon.discount_percentage, '% discount\n                    ') : null, React.createElement('div', { 'className': 'pricing-body' }, React.createElement('ul', { 'className': 'pricing-list text-center' }, React.createElement('li', {}, 'Client Count ', React.createElement('strong', {}, plan.__client_count))), plan.name === 'Private Label Plan' && plan.partner === 1 ? React.createElement('ul', {
                'className': 'pricing-list text-center',
                'key': '4591'
            }, React.createElement('li', {}, 'Custom mobile app'), React.createElement('li', {}, 'Passive Income'), React.createElement('li', {}, 'Train the Trainer'), React.createElement('li', {}, 'International Coverage')) : null))) : null];
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row center-xs' },
        _.map(this.props.partner_plan.plans, repeatPlan1.bind(this))
    ]);
}