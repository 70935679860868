import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/intake_form.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store, {history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm,formValueSelector } from 'redux-form';

import IntakeAction from '../../redux/intake/actions/intake_creator';
import { getIntakeInstance,saveIntakeQuestion } from '../../redux/intake/network/intake_network';
import { Card, CardText } from 'react-toolbox/lib/card';
import ProgressBar from 'react-toolbox/lib/progress_bar';




class IntakeForm extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
    }

    
    componentWillMount() {
        let intake_id = this.props.match.params.intake_id;
        if (intake_id){
            if(_.isEmpty(this.props.intake) ||  this.props.intake.id !== intake_id  ){
                this.props.getIntakeInstance({form_type:1,id:intake_id});
            }            
        } else {
            history.goBack();
        }
    }
    

    doSave(data){
        return false;
    }    



    render() {
        if (_.isEmpty(this.props.intake)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('intakeEditForm'); 
function mapStateToProps(state, ownprops) {
    let intake = { ...state.intake.intakeReducer.intake };
    let initialValues = {};
    _.forEach(intake.questions, (item) => {
        initialValues[item.field_guid] = item.value;
    });

    return {
        intake: state.intake.intakeReducer.intake,
        initialValues: initialValues,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getIntakeInstance,
        saveIntakeQuestion,
        cleanUp: IntakeAction.intakeCleanUp,
    }, dispatch);
}

const validate = (values, props) => {
    let requiredFields = [];
    let arrayFillCheck = [];
    let errors = {};
    _.forEach(props.intake.questions, (item) => {
        if (item.field_type !== 3 && item.field_type !== 4 &&  item.field_type !== 14 && item.is_required){
            requiredFields.push(item.field_guid);    
        } else if ((item.field_type === 3 || item.field_type === 4 || item.field_type === 14) && item.is_required){
            arrayFillCheck.push(item.field_guid);
        }
    });
    requiredFields.forEach(field => {
        let key = field;
        if (!values[key] || values[key] === "") {
            errors[key] = 'Required';
        }
    });
    arrayFillCheck.forEach(field => {
        let key = field;
        if (!values[key] || values[key].length === 0) {
            errors[key] = 'Required';
        }
    });

    return errors;
};

const IntakeFormRedux = reduxForm(
    {
        form: 'intakeEditForm',
        enableReinitialize: true,
        validate
    }
)(IntakeForm);
const IntakeFormConnected = connect(mapStateToProps,mapDispatchToProps)(IntakeFormRedux);
export default IntakeFormConnected;
