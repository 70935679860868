import * as React from 'react';
import * as _ from 'lodash';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { history } from '../../../../redux/store';
import AssessmentEdit from '../assessment_edit';
export default function () {
    return React.createElement('div', { 'className': 'row m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-10' }, React.createElement('h3', { 'className': 'di-block w-60' }), !this.props.previewMode ? React.createElement(Button, {
        'className': 'pull-right small-button m-r-5 bg-success',
        'label': 'Submit Assessment',
        'tooltip': 'Create Program',
        'onClick': this.createWorkout,
        'icon': 'public',
        'raised': true,
        'key': '558'
    }) : null), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(AssessmentEdit, {
        'assessment': this.state.selected_assessment,
        'previewMode': this.props.previewMode
    })));
}