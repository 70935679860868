import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
export default function () {
    return React.createElement('div', { 'className': 'row cue-edit m-t-20' }, React.createElement(Card, { 'className': 'm-t-15 w-100 bg-none no-box-shadow' }, React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {
        'className': 'bg-none d-flex bg-white no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.saveCue)
    }, React.createElement('div', {
        'className': 'add-info box-shadow-10',
        'style': { margin: 'unset' }
    }, React.createElement('div', { 'className': 'row' }, React.createElement(CardTitle, {
        'title': (this.props.match.params.cue_id ? this.props.cue.is_owner == 'false' ? 'View' : 'Edit ' : 'Add ') + ' Cue',
        'className': 'c-black text-capital'
    }), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'label': 'Name',
        'name': 'name',
        'className': 'w-100 m-b-20',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement('div', { 'className': 'm-b-30 h-150' }, React.createElement(Field, {
        'id': 'descriptionRichText',
        'name': 'description',
        'label': 'Description',
        'className': 'w-100 m-b-20',
        'type': 'text',
        'component': TextInput,
        'multiline': true,
        'onClick': this.inputClick,
        'rows': 6
    }))))), this.props.cue.is_owner ? React.createElement('div', {
        'className': 'publish-info',
        'key': '2565'
    }, React.createElement(Card, { 'className': 'bg-white box-shadow p-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-b-10 middle-xs' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'bg-success w-100',
        'disabled': this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    })))) : null))));
}