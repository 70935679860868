import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/content_video_edit";
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import { useHistory } from 'react-router-dom'

import store,{history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listContent, contentSearch,
    getContent, postExercise, deleteExercise,
    uploadMedia, getCategory, getAssociatedContent,
} from '../../redux/content/network/content';
import actionCreator from '../../redux/content/actions/exercise';
import {listProgram} from '../../redux/program/network/program_list';
import Utils from '../../utils/utils';
import React from 'react';
import axios from 'axios';
import Axios from '../../utils/network/axios';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';
import moment from 'moment';
import { param } from 'jquery';


export default class VideoEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);

        this.onVideoUploaded = this.onVideoUploaded.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.transformData = this.transformData.bind(this);
        // this.listAssociatedContent = this.listAssociatedContent.bind(this);

        this.loadMore = this.loadMore.bind(this);
        this.listContent = this.listContent.bind(this);
        this.removeWorkoutItem = this.removeWorkoutItem.bind(this);
        this.removeContentItem = this.removeContentItem.bind(this);
        this.onChangeContent = this.onChangeContent.bind(this);
        this.onChangeWorkout = this.onChangeWorkout.bind(this);

        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.setFilterPreferences = this.setFilterPreferences.bind(this);

        this.uploadCoverImage = this.uploadCoverImage.bind(this);
        this.uploadAudio = this.uploadAudio.bind(this);
        this.captureThumbnail = this.captureThumbnail.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.onCancelImage = this.onCancelImage.bind(this);
        this.onCancelAudio = this.onCancelAudio.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.yesNoOptions = [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ];
        this.state = {
            published: false,
            contentType: "",
            image_name: "",
            tab: "",
            optionsA:"",
            videoDuration:"",
            searchCategory: ""
        };
        this.player = null;
    }
    selectCategory(value){
        if(this.props.content==='gi_content'){
            this.setState({
                searchCategory: value.id
            })
        } else if(this.props.content==="homefit_content") {
            this.setState({
                searchCategory: value.db_value
            })
        }
        this.onSearch()
    }

    setActive(el, active = true){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    cancel() {
        if(confirm("Are you sure? All changes will be discarded.")){
            this.setState({
                tab: this.props.properties.match.params.content_type,
            })
            history.goBack();
        }
    }

    onSearch(text){
        if(text == null){
            this.loadMore();
        }
        this.setState({search:text.db_value},()=>{
            this.loadMore();
            if(this.props.content==="gi_content"){
                this.props.properties.associatedContentSearch({
                    "package_version": "2",
                    "app_version": "5.2",
                    "device_type": "web",
                    "category": this.state.search,
                    "page": 1,
                })
            } else if(this.props.content==="homefit_content"){
                this.props.properties.homefitContentSearch({
                    app_version:"5.2",
                    category: this.state.search,
                    package_version: "2",
                    practice_id:this.props.properties.practice_id,
                    current_role_type:"practice_admin",
                })
            }
        });

    }

    listContent(data) {
        let params = {
            // "search_key" : filterData.search,
            "category" : data.db_value,
            practice_id: this.props.properties.practice_id 
        };
        if(data.db_value.length){
        if(this.props.content==='gi_content'){
            if(this.props.properties.user.gi_isadmin){
                this.props.properties.listContent({
                    practice_id: this.props.properties.practice_id,
                    "package_version": "2",
                      "app_version": "5.2",
                      "device_type": "web",
                      "content_type": this.props.content=='gi' && this.props.content_type=='video' ? 'vlog' : this.props.content_type,
                })
            }else {
                // this.props.properties.contentSearch(params);
                this.props.properties.associatedContentSearch({
                    "package_version": "2",
                    "app_version": "5.2",
                    "device_type": "web",
                    "category": this.state.search,
                    "page": 1,
                })
            }
        } else if(this.props.content==='homefit_content'){
            this.props.properties.homefitContentSearch({
                ...params, 
                "package_version": "2",
                "app_version": "5.2",
                "device_type": "web",
            });
        }
        }
    }

    loadMore(increment=false,first=false) {
        let params = { 
                "package_version": "2",
                "app_version": "5.2",
                "device_type": "web",
                "content_type": this.props.properties.match.params.content_type,
                // total_pages: null,
            ...this.props.properties.paginateInfo,
            ...this.state.filterPreferences,
        };
        if(increment){
            params.page = params.page + 1;
        }else{
            params.page = 1;
        }
        this.props.properties.getProgramFilters({
            app_version: "5.2",
            current_role_type: "practice_admin",
            is_favorite: false,
            my_deleted_templates: false,
            my_practice_programs: true,
            my_programs: true,
            package_version: "3",
            practice_id: 276
        })
        if(!this.state.filterPreferences){
            if(this.props.content==='gi_content'){
                this.props.properties.associatedContentSearch({
                    "package_version": "2",
                    "app_version": "5.2",
                    "device_type": "web",
                    "category": null,
                    "page": 1,
                })
                this.props.properties.listProgram({
                    "program_type": 0,
                    "package_version": 3,
                    "quickstart_type": "visible",
                    "app_version": "5.4",
                    "current_role_type": "practice_admin",
                    practice_id: this.props.properties.practice_id,
                    category: this.state.searchCategory ? this.state.searchCategory : ""
                });
            } else if(this.props.content==='homefit_content'){
                this.props.properties.homefitContentSearch({
                    app_version:"5.2",
                    category: "",
                    package_version: "2",
                    practice_id:this.props.properties.practice_id,
                    current_role_type:"practice_admin",
                })
                this.props.properties.getMoveVideoList({
                    app_version: "5.1.12",
                    current_role_type: "practitioner",
                    package_version: "1",
                    practice_id: this.props.practice_id,
                    'search_text': this.state.searchCategory ? this.state.searchCategory : "",
                });
            }
        }
    }

    setFilterPreferences(filterData) {
        let params = {
            "search_key" : filterData.search,
            "category" : filterData.category   
        };
        this.setState({filterPreferences:params},()=>{
            this.props.properties.contentSearch(params);
        });
    }


    saveAndPublish() {
        let submit = this.props.properties.handleSubmit(this.doSave);
        this.setState({
            published: true
        }, () => {
            submit();
        });
    }

    saveAsDraft() {
        let submit = this.props.properties.handleSubmit(this.doSave);
        this.setState({
            published: false
        }, () => {
            submit();
        });
    }

    uploadCoverImage(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        this.props.properties.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.properties.change('image_url', result.url);
            this.props.properties.change('image_name', result.file_name);
            this.props.properties.change('is_image_uploaded', true);
            this.props.properties.change('dummy_to_trigger_save', true);
        }).catch((error) => {
            console.log(error, "uploadFiles")
        });
    }

    uploadAudio(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        let audio = document.createElement('AUDIO');
        audio.src = files[0].preview;
        audio.addEventListener('loadedmetadata', () => {
            this.props.properties.change('audioDuration', audio.duration);
        });
        this.props.properties.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.properties.change('audio_url', result.url);
            this.props.properties.change('audio_name', result.file_name);
            this.props.properties.change('is_audio_uploaded', true);
            this.props.properties.change('dummy_to_trigger_save', true);
        }).catch((error) => {
            console.log(error, "audio error")
        });
    }


    onCancelImage(){
        this.props.properties.change('is_image_uploaded', false);
        this.props.properties.change('image_url', null);
        this.props.properties.change('image_name', null);
        this.props.properties.change('news_media[0].thumbnail_url', null);
    }
    onCancelAudio(){
        this.props.properties.change('is_audio_uploaded', false);
        this.props.properties.change('audio_url', null);
        this.props.properties.change('audio_name', null);
        this.props.properties.change('news_media[0].url', null);
    }

    componentWillMount() {
        if(this.props.content==="gi_content"){
            this.props.properties.listProgram({
                "program_type": 0,
                "package_version": 3,
                "quickstart_type": "visible",
                "app_version": "5.4",
                "current_role_type": "practice_admin",
                practice_id: this.props.properties.practice_id,
                category: this.state.searchCategory ? this.state.searchCategory : ""
            });
            if(this.props.properties.match.params.content_id){
                this.props.properties.getContent({ 
                    id: this.props.properties.match.params.content_id,
                    practice_id: this.props.properties.practice_id })
                    .then(()=>{
                        this.props.properties.selectedAssociatedContentList(this.props.properties.news.news_associated)
                        this.props.properties.selectedAssociatedWorkoutList(this.props.properties.news.news_workout_templates)
                    });
            }
            this.props.properties.getCategory({
                "device_type": "web",
                "page":1
            });
            this.onSearch()
        }else if(this.props.content==="homefit_content"){
            this.props.properties.getCategoryHomeFit({
                app_version: "5.2",
                current_role_type: "practice_admin",
                device_type: "web",
                package_version: "2",
                practice_id: this.props.properties.practice_id
            });
            this.props.properties.homefitContentSearch({
                app_version:"5.2",
                category: "",
                package_version: "2",
                practice_id:this.props.properties.practice_id,
                current_role_type:"practice_admin",
                // device_type: "web",
                // page: 0,
                // content_type: "video"
            })
            if(this.props.properties.match.params.content_id){
                this.props.properties.getHomeFitContent({ 
                    "package_version": "2",
                "app_version": "5.2",
                "device_type": "web",
                practice_id: this.props.properties.practice_id,
                id: this.props.properties.match.params.content_id })
                .then(()=>{
                    this.props.properties.selectedAssociatedContentList(this.props.properties.homeFitNews.news_associated)
                    this.props.properties.selectedAssociatedWorkoutList(this.props.properties.homeFitNews.news_workout_templates)
                });
            }
            this.props.properties.getMoveVideoList({
                    app_version: "5.1.12",
                    current_role_type: "practitioner",
                    package_version: "1",
                    practice_id: this.props.practice_id,
                    'search_text': this.state.searchCategory ? this.state.searchCategory : "",
                })
            this.onSearch()
        }

    }

    deletePositionPicture(url, del) {
        let pics = _.cloneDeep(this.props.properties.position_pictures);
        var pic = _.find(pics, (item) => { return item.url === url; });

        if ("id" in pic) {
            pic.delete = true;
        } else {
            pics = _.remove(pics, function(item) {
                if (item.url !== pic.url){
                    return true;
                }
            });
        }
        this.props.properties.change('position_pictures', pics);
    }

    deleteVideo() {
        this.props.properties.change('video_guid', null);
        this.props.properties.change('video_thumbnail_url', null);
        this.props.properties.change('duration', 0);
    }

    
    onVideoUploaded(uploadResult) {
        this.props.properties.change('video_guid', uploadResult.guid);
        this.props.properties.change('video_thumbnail_url', uploadResult.thumbnail);
        this.props.properties.change('duration', uploadResult.duration);
    }
    captureThumbnail() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type':'application/json',
            }
        });
        if (that.player) {
            this.props.properties.showProgress();
            that.player.getCurrentTime().then(function(time) {
                let params = {time:time,active:true};
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.properties.video_guid + "/pictures",params
                ).then((response) => {
                    let thumbnail_url =  "";
                    if(response.data.sizes.length > 3 ){
                        thumbnail_url = response.data.sizes[3].link;
                    }else{
                        thumbnail_url = response.data.sizes[response.data.sizes.length -1].link;
                    }
                    that.props.properties.change('video_thumbnail_url', thumbnail_url);    
                    that.props.properties.hideProgress();
                    Utils.showSuccessAlert("Your new thumbnail image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.properties.hideProgress();
                });
            }).catch(function(error) {
                that.props.hideProgress();
            });
        }
    }
    onVideoUploadFailed() {
        Utils.showErrorAlert("Video upload failed! Please try again.");
    }
    removeContentItem(id){
        this.props.properties.removeContent(id)
    }

    removeWorkoutItem(id){
        this.props.properties.removeWorkout(id)
    }
    onChangeContent(obj){
        if(this.props.properties.match.params.content_id){
            this.props.properties.selectedAssociatedContentList(obj).then(()=>
            this.props.change('news_associated',[]))
        }
    }
    onChangeWorkout(obj){
        if(this.props.properties.match.params.content_id){
        this.props.properties.selectedAssociatedWorkoutList(obj).then(()=>
        this.props.change('news_workout_templates',[]))
        }
    }
    doSave(data) {
        let params = this.transformData(data);
        let associatedContent;
        let associatedWorkout;
        associatedContent = this.props.properties.selectedAssociatedContent.length ? Object.keys(this.props.properties.selectedAssociatedContent) : this.props.properties.selectedAssociatedContents
        associatedWorkout = this.props.properties.selectedAssociatedWorkout.length ? Object.keys(this.props.properties.selectedAssociatedWorkout) : this.props.properties.selectedAssociatedWorkouts
        params.published = this.state.published;
        params.publish_date = moment(params.publish_date).format("YYYY-MM-DD")
        params.publish_time = moment(params.publish_time).format("HH:mm:ss") 
        params.associated_content = associatedContent
        params.associated_workout_templates = associatedWorkout
        const videoGuid = this.props.properties.video_guid ? this.props.properties.video_guid.length : false;
        if(!videoGuid && !this.props.properties.is_image_uploaded && params.published){ 
            Utils.showErrorAlert("Please add media or cover image before publishing.")
            } else {
                if(this.props.properties.match.params.content_type === 'video'){
                    params.media_items = [{
                        "media_type": 2,
                        "is_cover_image": true, 
                        "guid": params.video_guid,
                        "thumbnail": params.video_thumbnail_url,
                        "duration": this.props.properties.duration,
                    }];                   
                    if(this.props.content==="gi_content"){
                    this.props.properties.postExercise({ 
                        ...params,
                        content_type: this.props.properties.match.params.content_type,
                        content_id: this.props.properties.match.params.content_id,
                        "practice_id": this.props.properties.practice_id,
                    }).then((response) => {
                         history.goBack();
                     }).catch((error) => { });
                    } else if(this.props.content==="homefit_content"){
                        this.props.properties.postHomeFitContent({ 
                            ...params, 
                            content_type: this.props.properties.match.params.content_type, 
                            content_id: this.props.properties.match.params.content_id,
                            "package_version": "2",
                            "app_version": "5.2",
                            "device_type": "web",
                            "practice_id": this.props.properties.practice_id,
                        }).then((response) => {
                            history.goBack();
                        }).catch((error) => { });
                    } 
                }else if(this.props.properties.match.params.content_type=='blog'){
                    params.media_items = [{
                        "media_type": 1,
                        "is_cover_image": true, 
                        // "guid": params.video_guid,
                        "thumbnail": this.props.properties.match.params.content_id? this.props.properties.image_url: params.image_url,
                    }]
                    if(this.props.content==="gi_content"){
                    this.props.properties.postExercise({ 
                        ...params,
                        content_type: this.props.properties.match.params.content_type,
                        content_id: this.props.properties.match.params.content_id,
                        "practice_id": this.props.properties.practice_id,
                    }).then((response) => {
                        history.goBack();
                    }).catch((error) => { });
                    } else if(this.props.content==="homefit_content"){
                        this.props.properties.postHomeFitContent({ 
                            ...params, 
                            content_type: this.props.properties.match.params.content_type, 
                            content_id: this.props.properties.match.params.content_id,
                            "package_version": "2",
                            "app_version": "5.2",
                            "device_type": "web",
                            "practice_id": this.props.properties.practice_id,
                        }).then((response) => {
                            history.goBack();
                        }).catch((error) => { });
                    }
                } else if(this.props.properties.match.params.content_type=='podcast'){
                    params.media_items = [{
                        "media_type": 3,
                        "is_cover_image": true, 
                        "guid": params.audio_url,
                        "file_name": this.props.properties.audio_name,
                        "thumbnail": this.props.properties.match.params.content_id? this.props.properties.image_url: params.image_url,
                        "duration": this.props.properties.audioDuration,
                    }]
                    if(this.props.content==="gi_content"){
                        this.props.properties.postExercise({ 
                            ...params,
                            content_type: this.props.properties.match.params.content_type,
                            content_id: this.props.properties.match.params.content_id,
                            "practice_id": this.props.properties.practice_id,
                        }).then((response) => {
                            history.goBack();
                        }).catch((error) => { });
                        } else if(this.props.content==="homefit_content"){
                            this.props.properties.postHomeFitContent({ 
                                ...params, 
                                content_type: this.props.properties.match.params.content_type, 
                                content_id: this.props.properties.match.params.content_id,
                                "package_version": "2",
                                "app_version": "5.2",
                                "device_type": "web",
                                "practice_id": this.props.properties.practice_id,
                            }).then((response) => {
                                history.goBack();
                            }).catch((error) => { });
                        }
                }
            }

        
    }

    transformData(data) {
        let params = Utils.flatternFormData(data, ['position_pictures']);
        return params;
    }

    render() {
        if (_.isEmpty(this.props.properties.initialValues)&& (this.props.properties.match.params.content_id)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.properties.cleanModule();
    }
}
