import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { history } from '../../../redux/store';
import QuestionEdit from '../question_edit.jsx';
import Sortable from 'react-anything-sortable';
import SortableItem from '../../../utils/common_components/sortable_item';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
export default function () {
    function repeatQuestion1(question, questionIndex) {
        return React.createElement(SortableItem, {
            'key': 'question' + questionIndex,
            'className': 'vertical w-100',
            'sortData': question
        }, React.createElement('div', {
            'onClick': () => {
                this.selectQuestion(question);
            },
            'className': 'm-t-10 m-l-10 d-flex'
        }, React.createElement('span', { 'className': 'bg-white p-5 m-r-1 box-shadow vertical-align' }, question.field_type === 99 ? React.createElement(FontIcon, {
            'value': 'note',
            'key': '3952'
        }) : null, question.field_type !== 99 ? React.createElement(FontIcon, {
            'value': 'help_outline',
            'key': '4055'
        }) : null), React.createElement('div', { 'className': ` f-12 p-10 box-shadow pointer flex-auto w-90 ${ !_.isEmpty(this.props.selected_question) && question.field_guid === this.props.selected_question.field_guid ? 'bg-primary box-shadow' : 'bg-white' }   ` }, '\n                                        ', question.name, '\n                                    '), this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement('span', {
            'className': 'bg-white p-5 m-l-1 box-shadow handle vertical-align',
            'style': { cursor: 'ns-resize' },
            'key': '4568'
        }, React.createElement(FontIcon, { 'value': 'reorder' })) : null));
    }
    return React.createElement('div', { 'className': 'row m-t-20' }, React.createElement(Button, {
        'onClick': () => {
            history.goBack();
        },
        'className': 'bg-none d-flex back-btn c-grey text-capital',
        'label': 'Go Back'
    }), React.createElement(Card, { 'className': 'box-shadow-10' }, React.createElement('div', { 'className': 'd-flex align-center flex-sb p-5' }, React.createElement(CardTitle, {
        'title': 'Intake form',
        'className': 'width-fit c-black m-t-8 m-b-18'
    }), React.createElement('div', { 'className': 'm-r-10' }, this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.publishIntakeForm,
        'style': { height: 'fit-content !important' },
        'className': 'no-box-shadow height-fit p-t-2 p-l-22 p-r-22 pull-right m-l-10 bg-success' + ' ' + _.transform({
            'bg-danger': this.props.intake.published === true,
            'bg-primary': this.props.intake.published === false
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'label': this.props.intake.published === true ? 'Unpublish' : 'Publish',
        'tooltip': this.props.intake.published === true ? 'Unpublish form from usage' : 'Publish form for usage',
        'raised': true,
        'key': '1530'
    }) : null, React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.viewPreview,
        'style': { height: 'fit-content !important' },
        'className': 'no-box-shadow p-l-22 p-r-22 pull-right f-16 bg-success-border m-b-12 m-l-10',
        'label': 'Preview',
        'raised': true
    }))), React.createElement('div', { 'className': 'm-l-20 m-r-20 m-b-10' }, React.createElement('span', { 'className': 'text-capital f-18' }, this.props.intake.name), this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.updateFormName,
        'className': 'p-0 name-edit-icon no-box-shadow c-grey small-button bg-none m-l-10',
        'icon': 'create',
        'raised': true,
        'key': '2843'
    }) : null), React.createElement('div', { 'className': 'd-flex' }, React.createElement('div', { 'className': 'col-xs-5 p-5' }, React.createElement.apply(this, [
        Sortable,
        {
            'className': 'vertical-container',
            'sortHandle': 'handle',
            'onSort': this.handleSort,
            'direction': 'vertical',
            'containment': true,
            'dynamic': true
        },
        _.map(this.props.intake.questions, repeatQuestion1.bind(this))
    ]), React.createElement('div', { 'className': 'w-100 d-flex m-t-20 center-xs' }, this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.addQuestion,
        'className': 'center-xs height-fit text-transform-none bg-success-border m-b-10',
        'label': 'Add Question',
        'icon': 'add',
        'key': '5049'
    }) : null)), React.createElement('div', { 'className': 'col-xs-7 box-shadow' }, React.createElement(QuestionEdit, {})))));
}