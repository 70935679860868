import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_view_calendar_event";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';
import { Calendar as BigCalendar,momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getUserListForGroupClassScheduledDate } from '../../redux/calendar/network/group_class_network';

class GroupClassViewCalendarEvent extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentWillMount() {
        this.props.getUserListForGroupClassScheduledDate({
            'group_class_id': this.props.groupClassId,
            'schedule_id': this.props.scheduleId,
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id  
        });
    }

    componentWillUnmount() {
        //this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }

    deleteEvent(){
        let that = this;
        Swal.fire({
            title: "Would you like to delete this group class event for this day?",
            text: "All client bookings for this day will also be deleted!",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            that.props.deleteEvent();
        }, ()=> { });
    }
}

function mapStateToProps(state, ownProps) {        
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        groupClass: state.calendar.groupClassReducer.group_class,
        bookedClientList: state.calendar.groupClassReducer.booked_client_list,
        scheduleDate: Utils.parseServerDateAsString(state.calendar.groupClassReducer.schedule_date),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserListForGroupClassScheduledDate,
    }, dispatch
    );
}

const GroupClassViewCalendarEventConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassViewCalendarEvent);
export default GroupClassViewCalendarEventConnected;