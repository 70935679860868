
let initialState = {
    workfit_videos: [],
    workfit_video: {},
    workfit_practice_list: [],
    notification_templates: [],
    notification_template: {},
    notification_schedules: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "WORKFIT_VIDEOS_FILL":
            if (!action.workfit_videos){
                return state;
            }                
            return Object.assign({}, state, {
                workfit_videos: action.workfit_videos,
            });
        case "WORKFIT_VIDEO_FILL":
            if (!action.workfit_video){
                return state;
            }                
            return Object.assign({}, state, {
                workfit_video: action.workfit_video,
            });        
        case "WORKFIT_PRACTICE_LIST_FILL":
            if (!action.practice_list){
                return state;
            }                
            return Object.assign({}, state, {
                workfit_practice_list: action.practice_list,
            }); 
        case "WORKFIT_NOTIFICATION_TEMPLATE_LIST_FILL":
            if (!action.notification_templates){
                return state;
            }                
            return Object.assign({}, state, {
                notification_templates: action.notification_templates,
            });      
        case "WORKFIT_NOTIFICATION_TEMPLATE_GET_FILL":
                if (!action.notification_template){
                    return state;
                }                
                return Object.assign({}, state, {
                    notification_template: action.notification_template,
                });      
        case "WORKFIT_NOTIFICATION_SCHEDULE_LIST_FILL":
                if (!action.notification_schedules){
                    return state;
                }                
                return Object.assign({}, state, {
                    notification_schedules: action.notification_schedules,
                });      
        case "WORKFIT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
