let initialState = {
    client_payment_plans: [],
    client_payment_plan: {},
    client_payment_plan_subscriptions: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "CLIENT_PAYMENT_PLAN_LIST_FILL":
            if(!action.client_payment_plans ) {
                return state;
            }
            return Object.assign({}, state, {
                client_payment_plans: action.client_payment_plans,
            });
        case "CLIENT_PAYMENT_PLAN_INSTANCE_FILL":
            if(!action.client_payment_plan) {
                return state;
            }
            return Object.assign({}, state, {
                client_payment_plan: action.client_payment_plan,
            });
        case "CLIENT_PAYMENT_PLAN_SUBSCRIPTION_LIST_FILL":
            if(!action.client_payment_plan_subscriptions) {
                return state;
            }
            return Object.assign({}, state, {
                client_payment_plan_subscriptions: action.client_payment_plan_subscriptions,
            });

        default:
            return state;
    }
};