import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import { Chip } from 'react-toolbox/lib/chip';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'm-30' }, React.createElement(Card, {}, React.createElement(CardTitle, {
        'title': this.props.scheduleEvent.group_name + ' group class event',
        'className': 'm-b-10'
    }), React.createElement('div', { 'className': 'm-10' }, '\n                Date: ', moment(this.props.scheduleEvent.start).format('MMMM Do YYYY'), '                \n            '), React.createElement('div', { 'className': 'm-10' }, '\n                Start time: ', this.props.scheduleEvent.start_time, '                \n            '), React.createElement('div', { 'className': 'm-10' }, '\n                End time: ', this.props.scheduleEvent.end_time, '                \n            '), React.createElement('div', { 'className': 'm-t-10 text-center' }, React.createElement(Button, {
        'onClick': () => {
            this.deleteEvent();
        },
        'label': 'Delete Event',
        'className': 'bg-danger'
    })), React.createElement(CardText, {})));
}