import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import Template from "./templates/group_list.rt";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { getGroupList, toggleVisibilityGroup,getGroupFilterList } from '../../redux/group/network/group_network_new';
import Swal from 'sweetalert2';
import actions from '../../redux/group/actions/group_creator';
import {messageGroupClients} from '../../redux/message/network/message_network';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { GROUP_LIST_VIDEO } from '../../utils/help_video';
import { getAuthorList, } from '../../redux/partner/network/partner_network';

class GroupList extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.addGroup = this.addGroup.bind(this);
        this.editGroup = this.editGroup.bind(this);
        this.onChangeGroupCategory = this.onChangeGroupCategory.bind(this);
        this.onChangeGroupType = this.onChangeGroupType.bind(this);
        this.goToGroupDashboard = this.goToGroupDashboard.bind(this);
        this.searchGroups = this.searchGroups.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.showMailComposer = this.showMailComposer.bind(this);
        this.hideMailComposer = this.hideMailComposer.bind(this);
        this.onMailComposerSubmit = this.onMailComposerSubmit.bind(this);
        this.hideGroupTemplateSelection = this.hideGroupTemplateSelection.bind(this);
        this.getData = this.getData.bind(this);
        this.drawerToggle = this.drawerToggle.bind(this);
        this.onChangeConsultantSelection = this.onChangeConsultantSelection.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.hideReport = this.hideReport.bind(this);
        this.selectTrainer = this.selectTrainer.bind(this);

        this.state = {
            groupStatus: "active",
            groupType: "-1",
            search_text: "",
            mailComposerVisible: false,
            currentlyMailingClient: null,
            showGroupTemplates:false,
            showFilter: false,
            selectedConsultant:null,
            rows: []
        };
        this.groupStatusList = [
            {
                value: "active",
                label: "Visible",
            },
            {
                value: "inactive",
                label: "Hidden",
            },
        ];
        this.groupTypes = [
            {
                value: "-1",
                label: "All Groups",
            },
            {
                value: "1",
                label: "Scheduled Groups",
            },
            {
                value: "2",
                label: "Ongoing Groups",
            },
        ];

    };
    selectTrainer(trainer){
        this.setState({
            selectedTrainer: trainer.value,
            selectedTrainerName: trainer.label
        })
    }
    hideReport(){
        this.setState({
            showReport: false,
            rows: []
        })
    }
    generateReport() {
        let params = {
            'search': this.state.search_text,
            'group_status': this.state.groupStatus.value,
            'group_type': this.state.groupType,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
            trainer: this.state.selectedTrainer,
        }
        if(this.state.selectedTrainer !== null){
        this.props.getGroupList(params).then((resp)=>{
            resp.group_list.forEach((group)=>{
                let temp = {
                    Group_Id: group.id,
                    Group_Name: group.name,
                    Author_Name: group.trainer.owner.name,
                    Views: group.client_views_count
                }
                this.state.rows.push(temp);
              })
            })
        .then(()=>{
            this.setState({
                showReport: true,
            })
        })
    } else {
        alert("Please select a Coach")
        }
    }
    drawerToggle = () => {
        this.setState({showFilter: !this.state.showFilter});
    };

    getData(increment=false){
        let params = {
            'search': this.state.search_text,
            'group_status': this.state.groupStatus.value,
            'group_type': this.state.groupType.value,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
            'page':increment ?  (this.props.paginate_info.page + 1) : 0
        };
        if(this.state.selectedConsultant){
            params.consultant_filter = this.state.selectedConsultant.value;
        }
        this.props.getGroupList(params).then((res)=>{
            this.props.getGroupFilterList(params);
        });
        this.props.getAuthorList({
            "app_version": "5.2",
            "practice_id": 276,
            "current_role_type": "practice_admin",
            package_version: "2"
        })
    }
    
    componentWillMount() {
        let params = {
            "package_version":"2",
            "app_version":"5.2",
            "current_role_type":"practice_admin",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id
        }
        // this.props.getGroupFilterList(params);
        this.getData();

    }

    componentDidMount() {
        this.props.updateHelpVideo(GROUP_LIST_VIDEO);
    }


    componentWillUnmount() {
        this.props.cleanModule();
        this.props.clearHelpVideo();
    }

    showMailComposer(group) {
        this.setState({
            mailComposerVisible: true,
            currentlyMailingGroup: group,
        });
    }

    hideMailComposer() {
        this.setState({
            mailComposerVisible: false,
            currentlyMailingGroup: null,
        });
    }

    onMailComposerSubmit(data) {
        this.props.messageGroupClients({
            ...data,
            'group_id': this.state.currentlyMailingGroup.id
        }).then(() => {
            this.hideMailComposer();
        }).catch(() => {
        });
    }

    addGroup() {
        let that = this;
        if(this.props.group_template_exists){
            Swal.fire({
                title: "Select an option",
                text: "",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Create new group",
                // cancelButtonText: "Create a group from group template",
                useRejections:true
            }).then(function (click) {
                if(click.value){
                    history.push('/practice/'+that.props.selectedRole.practice.id+'/engagement/group/add');
                }else if (dismiss === 'cancel') {
                        that.setState({showGroupTemplates:true});   
                    } else if (click.dismiss === 'close'){
                        return
                    }
            });   
        }else{
            history.push('/practice/'+that.props.selectedRole.practice.id+'/engagement/group/add');
        }
    }

    hideGroupTemplateSelection(){
        this.setState({showGroupTemplates:false});  
    }

    editGroup(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/group/edit/'+id);
    }
    
    goToGroupDashboard(event, id) {
        if (this.state.groupStatus.value === 'inactive') {
            return;
        }
        if (event.target.type === "button") {
            return;
        }
        history.push('/practice/'+this.props.selectedRole.practice.id+'/group/dashboard/'+id);
    }

    onChangeGroupCategory(val) {
        if(!val){
            return;
        }
        this.setState({
            groupStatus: val,
        });
    }

    onChangeGroupType(val){
        if(!val){
            return;
        }
        this.setState({
            groupType: val,
        });
    }

    hideGroup(groupId) {
        let display_text = "";
        if (this.state.groupStatus.value === 'active') {
            display_text = "Would you like to deactivate this groupo from your practice? Are you sure?"
        } else {
            display_text = "Would you like to activate this group for your practice? Are you sure?"
        }
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (click) {
            let params = {
                app_version: "5.1.12",
                current_role_type: "practitioner",
                package_version: "2",
                practice_id: that.props.user.id===1599 ? 276 : that.props.practice_id,
            };
            params['group_id'] = groupId;
            params['group_type'] = that.state.groupStatus;
            if(click.value){
                that.props.toggleVisibilityGroup(params).then(() => {
                    that.getData();
                }).catch((error) => { });
            } else if(click.dismiss){
                return
            }

        }, function (dismiss) {
                        
        });
    }

    onChangeConsultantSelection(val) {
        this.setState({
            selectedConsultant: val,
        });
    }

    applyFilter(){
        this.setState({showFilter:false});
        this.getData();
    }

    updateSearchText(value) {
        this.setState({
            search_text: value.target.value
        });
    }    

    searchGroups(e) {             
        if (e.key === "Enter") {        
            this.getData();    
        } 
    }

    render() {
        if(typeof this.props.trainer === 'object'){
            this.props.trainer ? this.props.change('trainer', this.props.trainer.value) :null
        }
        return Template.apply(this);
    }
}

const selector = formValueSelector('MoveVideoFilterForm');
function mapStateToProps(state) {
    return {
        store,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        group_list: state.group.groupReducer.group_list,
        consultant_list: state.group.groupReducer.consultant_list,
        paginate_info: state.group.groupReducer.paginate_info,
        group_template_exists: state.group.groupReducer.group_template_exists,
        practice_partners:state.partner.partnerReducer.practice_partners,
        authors:state.partner.partnerReducer.authors,
        trainer: selector(state, 'trainer')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getAuthorList,
            getGroupList,
            getGroupFilterList,
            toggleVisibilityGroup,
            messageGroupClients,
            cleanModule: actions.groupCleanup,
            updateHelpVideo: HelpVideoCreator.updateHelpVideo,
            clearHelpVideo: HelpVideoCreator.clearHelpVideo,
        }, 
        dispatch
    );
}

const GroupFilterForm = reduxForm(
    {
        form: 'MoveVideoFilterForm',
        enableReinitialize:  true,
    }
)(GroupList);

const GroupListConnected = connect(mapStateToProps,mapDispatchToProps)(GroupFilterForm);
export default GroupListConnected;