import React  from 'react';
import PropTypes from 'prop-types';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/program_edit_new";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreator from '../../redux/program/actions/program_edit';
import { getProgram, getWorkout, postProgram, postWorkout } from '../../redux/program/network/program_edit';
import { getProgression, postProgression, updateProgressionDays } from '../../redux/program/network/progression_edit';
import {
    getProgramPreview, getWorkoutPreview, toggleProgramPublish, toggleWorkoutPublish
} from '../../redux/program/network/program_preview';
import { history } from "../../redux/store";
import { WORKOUT_TARGET_CLIENT } from '../../redux/program/constants/constants';
import Utils from '../../utils/utils';
import { getMetricSettings } from '../../redux/user/network/practitioner_metric_settings';
import { getPracticePaymentSubscriptionDetails } from '../../redux/payment/network/plan_subscribe_network'

class ProgramEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.getProgression = this.getProgression.bind(this);
        this.postProgression = this.postProgression.bind(this);
        this.getProgram = this.getProgram.bind(this);
        this.getProgramPreview = this.getProgramPreview.bind(this);
        this.postProgram = this.postProgram.bind(this);
        this.finishEditingMoveBack = this.finishEditingMoveBack.bind(this);
        this.updateProgressionDays = this.updateProgressionDays.bind(this);
        this.moveToBuild = this.moveToBuild.bind(this);
        this.editProgression = this.editProgression.bind(this);
        this.toggleProgramPublish = this.toggleProgramPublish.bind(this);
        this.disableProgression = this.disableProgression.bind(this);
        this.state = {
            tabIndex: 0,
            editingProgressionId: null,
            disableProgression:false,
            user_subscription_active: false,
            isTemplatesPurchased : false
        };
    }

    componentWillMount() {
        this.props.getPracticePaymentSubscriptionDetails({
            "package_version": "3", 
            "app_version": "5.4", 
            "current_role_type": "practice_admin", 
            "practice_id": this.props.practice_id
        }).then((res)=>{ 
            this.setState({
                user_subscription_active : res.data.user_subscription_active,
                isTemplatesPurchased: res.data.isTemplatesPurchased
            })
        })

        this.getProgram({
            id: this.props.program_id,
            package_version: 2,
        })
        .then((resp)=> {
            let progression_id = resp.program.progressions[0].id ? resp.program.progressions[0].id : ""
            this.setState({
                editingProgressionId: progression_id
            })
        });
        if(this.props.group_id || this.props.clientWorkout){
            this.props.getWorkout({
                id: this.props.program_id,
                package_version: 2,
            }).then((resp)=> {
                // if(resp.workout.progressions.length > 1) {
                //     Utils.showErrorAlert("This workout cannot be published because it has more than one progressions in this workout. It can only be saved as draft.");
                // }
                let progression_id = resp.workout.progressions[0].id ? resp.workout.progressions[0].id : ""
                this.setState({
                    editingProgressionId: progression_id
                })
            })
        }
        this.props.getMetricSettings({});
    }

    componentWillReceiveProps(nextProps) {
        // if (this.props.match.params.landingTabIndex === nextProps.match.params.landingTabIndex) {
        //     return;
        // }
        // let index = nextProps.match.params.landingTabIndex;
        // if (index) {
        //     this.setState({ tabIndex: parseInt(index) });
        // }
    }

    disableProgression(disable) {
        this.setState({disableProgression:disable});    
    }

    finishEditingMoveBack() {
        if (this.props.usedInside) {
            this.props.finishEditing();
        } else {
            if (this.props.clientWorkout) {
                history.push('/practice/'+this.props.selectedRole.practice.id+'/client/engagement/' + this.props.engagement_id);
            }
            if (this.props.programType === 'workout') {
                 // TODO: won't be used anymore
                if (this.props.workoutTargetType === WORKOUT_TARGET_CLIENT) {
                    // TODO: not used anymore
                    history.push('/practice/'+this.props.selectedRole.practice.id+'/client/engagement/' + this.props.engagement_id);
                } else {
                    history.push('/practice/'+this.props.selectedRole.practice.id+'/group/dashboard/' + this.props.group_id);
                }
            } else {
                if(this.props.match && this.props.match.params && this.props.match.params.type == 'my_templates') {
                    history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + '/list/'+this.props.match.params.type);
                } else {
                    // history.push('/practice/'+this.props.selectedRole.practice.id+'engagement/p/template/list');
                    history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + '/list');
                } 
            }
        }
    }

    getProgression(params) {
        if (this.props.programType === 'workout') {
            params.workout_id = this.props.program_id;
            // delete params.program_id;
        } else {
            params.program_id = this.props.program_id;
        }
        return this.props.getProgression0(params);
    }

    postProgression(params) {
        if (this.props.programType === 'workout') {
            params.workout_id = params.program_id;
            delete params.program_id;
        }
        return this.props.postProgression0(params);
    }

    updateProgressionDays(params) {
        if (this.props.programType === 'workout') {
            params.workout_id = params.program_id;
            delete params.program_id;
        }
        return this.props.updateProgressionDays(params);
    }

    getProgram(params) {
        if (this.props.workoutTargetType === WORKOUT_TARGET_CLIENT) {
            params.engagement_id = this.props.engagement_id;
        } else {
            params.group_id = this.props.group_id;
        }
        if (this.props.programType === 'workout') {
            return this.props.getWorkout({
                id: this.props.program_id,
                ...params,
            });
        } else {
            return this.props.getProgram0({
                id: this.props.program_id,
                program_type: this.props.typeParam,
                package_version: 3,
                ...params
            });
        }
    }

    getProgramPreview(params) {
        if (this.props.programType === 'workout') {
            return this.props.getWorkoutPreview(params);
        } else {
            return this.props.getProgramPreview0(params);
        }
    }

    postProgram(params) {
        params.treatment_template_id = this.props.selection_treatment_template_id;
        params.package_version = 5;
        if (this.props.programType === 'workout') {
            // params.published = true;
            return this.props.postWorkout(params);
        } else {
            return this.props.postProgram0(params);
        }
    }

    toggleProgramPublish(params) {
         if (this.props.programType === 'workout') {
            return this.props.toggleWorkoutPublish(params);
        } else {
            return this.props.toggleProgramPublish0(params);
        }
    }

    moveToBuild() {
        this.setState({
            tabIndex: 1,
            editingProgressionId: null,
        });
    }

    editProgression(id) {
        this.setState({
            tabIndex: 1,
            editingProgressionId: id,
        });
    }

    handleTabChange(tabIndex) {
        // if (tabIndex > 0 && !this.props.program_id) {
        //     return;
        // }
        this.setState({
            tabIndex: tabIndex,
        });
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
    }

}

ProgramEdit.propTypes = {
    // indicates whether this component is re-used inside another component
    usedInside: PropTypes.bool,
    finishEditing: PropTypes.func,
};
ProgramEdit.defaultProps = {
    usedInside: false,
    finishEditing: () => { },
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProgram0: getProgram,
        clean: actionCreator.cleanModule,
        getWorkout,
        postProgram0: postProgram,
        postWorkout,
        getProgression0: getProgression,
        postProgression0: postProgression,
        getProgramPreview0: getProgramPreview,
        getWorkoutPreview,
        updateProgressionDays,
        toggleProgramPublish0: toggleProgramPublish,
        toggleWorkoutPublish: toggleWorkoutPublish,
        getMetricSettings,
        getPracticePaymentSubscriptionDetails,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let selectedRole =  state.auth.roleReducer.selectedRole;
    let program = {...state.program.programEdit.program};
    let disableEditing = false;
    if(_.has(ownProps,"disableEditing")){
        disableEditing = ownProps.disableEditing;
    }
    if(disableEditing === false && !_.isEmpty(program) && ownProps.programType !== "workout"){
        if(program.practice.id !== selectedRole.practice.id){
            disableEditing = true;
        }else if(_.has(ownProps,"programType") && _.has(program,"is_owner")){
                if(program.is_owner === false){
                    disableEditing = true;
                }
        }
    }
    let isNonSyncGroup = _.has(ownProps,"isNonSyncGroup") ? ownProps.isNonSyncGroup : false;
    if (!_.isEmpty(program)) {
        if (program.start_date) {
            program.start_date = Utils.parseServerDate(program.start_date);
        }
    }
    return {
        // typeUCase: state.program.program.typeUCase,
        // typeLCase: state.program.program.typeLCase,
        // typeParam: state.program.program.typeParam,
        // engagement_id: state.program.program.engagement_id,
        // group_id: state.program.program.group_id,
        // workoutMode: state.program.program.workoutMode,
        selectedRole:selectedRole,
        program: program,
        ...ownProps,
        disableEditing: disableEditing,
        isNonSyncGroup:isNonSyncGroup,
        program_id:   _.get(ownProps, 'match.params.program_id') ? ownProps.match.params.program_id : ( ownProps.program_id ?  ownProps.program_id :   program.id   ) ,
    };
}

const ProgramEditConnected = connect(mapStateToProps, mapDispatchToProps)(ProgramEdit);
export default ProgramEditConnected;
