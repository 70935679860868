import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ProfileEdit from '../../../user/components/profile_edit';
import UserEmailUpdate from '../../../user/components/user_email_update';
import UserPasswordUpdate from '../../../user/components/user_password_update';
import PracticeCreate from '../../../practice/components/practice_create';
import SetPrimaryPractice from '../../../user/components/set_primary_practice';
import VideoTutorials from '../../../news/components/video_tutorials';
import MovementMeterConfiguration from '../../../user/components/user_movement_meter_configuration';
import ClientTermsOfUse from '../client_terms_of_use';
import { PARTNER } from '../../../core/constants/constants';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, PARTNER.app_type === 'dhf' || PARTNER.app_type === 'tri' ? React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu',
        'key': '1970'
    }, React.createElement(NavLink, {
        'to': `${ this.props.match.url }/profile_edit`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Edit Profile')), React.createElement(NavLink, {
        'to': `${ this.props.match.url }/change_email`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Change Email')), React.createElement(NavLink, {
        'to': `${ this.props.match.url }/update_password`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Change Password')), _.includes(this.props.location.pathname, '/client/settings') ? React.createElement(NavLink, {
        'to': `${ this.props.match.url }/set_primary_practice`,
        'activeClassName': 'active',
        'key': '2586'
    }, React.createElement('abbr', {}, 'Set Primary Practice')) : null, _.includes(this.props.location.pathname, '/client/settings') ? React.createElement(NavLink, {
        'to': `${ this.props.match.url }/movement_meter`,
        'activeClassName': 'active',
        'key': '3306'
    }, React.createElement('abbr', {}, 'Movement Meter Preferences')) : null, _.includes(this.props.location.pathname, '/client/settings') ? React.createElement(NavLink, {
        'to': `${ this.props.match.url }/client_terms_of_use`,
        'activeClassName': 'active',
        'key': '3848'
    }, React.createElement('abbr', {}, 'Client Terms of Use')) : null) : null, PARTNER.app_type === 'gi' ? React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu',
        'key': '4144'
    }, React.createElement(NavLink, {
        'to': `${ this.props.match.url }/profile_edit`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Edit Profile')), React.createElement(NavLink, {
        'to': `${ this.props.match.url }/change_email`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Change Email')), React.createElement(NavLink, {
        'to': `${ this.props.match.url }/update_password`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Change Password')), _.includes(this.props.location.pathname, '/client/settings') ? React.createElement(NavLink, {
        'to': `${ this.props.match.url }/client_terms_of_use`,
        'activeClassName': 'active',
        'key': '4737'
    }, React.createElement('abbr', {}, 'Client Terms of Use')) : null) : null, React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/profile_edit`,
        'component': ProfileEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/change_email`,
        'component': UserEmailUpdate
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/update_password`,
        'component': UserPasswordUpdate
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/practice/create`,
        'component': PracticeCreate
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/set_primary_practice`,
        'component': SetPrimaryPractice
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/video_tutorials`,
        'component': VideoTutorials
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/movement_meter`,
        'component': MovementMeterConfiguration
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/client_terms_of_use`,
        'component': ClientTermsOfUse
    })));
}