import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/workfit";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../redux/client/actions/client_workfit_actions';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import { generateWorkfitPin } from "../../redux/auth/network/auth_network";
import { getWorkfitProgress } from "../../redux/client/network/client_workfit_network";
import swal from 'sweetalert2';


class Workfit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onPageChange = this.onPageChange.bind(this);
        this.generatePin = this.generatePin.bind(this);
    }

    componentWillMount() {
        this.generatePin();
        this.props.getWorkfitProgress(this.props.paginate_info);
    }

    generatePin() {
        this.props.generateWorkfitPin({ 'practice_id': this.props.workfitPracticeId });
    }

    onPageChange(page){
        this.props.getWorkfitProgress({
            page:page,
            sort_by:this.props.paginate_info.sort_by,
            asc:this.props.paginate_info.asc,
            filters: {...this.props.paginate_info.filters}
        });
    }     


    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
       // this.props.cleanModule();
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        generateWorkfitPin,
        getWorkfitProgress,
        cleanModule: actions.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        workfitPin: state.auth.authReducer.workfitPin,
        workfitPracticeId: state.auth.roleReducer.selectedRole['workfit_practice_id'],
        sessions: state.client.clientWorkfitReducer.sessions,
        paginate_info:state.client.clientWorkfitReducer.paginate_info,        
        ...ownProps,
    };
}

const WorkfitConnected = connect(mapStateToProps, mapDispatchToProps)(Workfit);
export default WorkfitConnected;
