import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_details";
import { reduxForm, formValueSelector } from 'redux-form';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {practiceDetailsPost, practiceDetailsGet} from '../../redux/practice/network/practice_details';
import actionCreator from '../../redux/practice/actions/practice_details';
import Swal from 'sweetalert2';
import React from 'react';
import { uploadFiles } from '../../redux/core/network/file_upload';
import { verifyToken } from '../../redux/auth/network/auth_network';

class PracticeDetails extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.deleteLogo = this.deleteLogo.bind(this);
        this.yesNoOptions=[
            {value: true, label: 'Yes'},
            {value: false, label: 'No'},
        ];
    }

    componentWillMount() {
        this.props.practiceDetailsGet({id: this.props.selectedRole.practice.id});
    }

    deleteLogo() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "You are trying to delete your practice logo. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(()=> {
            that.props.change('is_avatar_uploaded', false);
        }, ()=> { });
    }

    uploadLogo(files) {
        let formData = new FormData();
        formData.append('guid', this.props.guid);
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp)=>{
            let result = resp.upload_results[0];
            this.props.change('logo', result.thumbnail_url);
            this.props.change('is_avatar_uploaded', true);
        }).catch((error)=>{
        });
    }

    doSave(data){
        let params = { ...data };
        this.props.practiceDetailsPost({...params}).then(()=>{
            this.props.practiceDetailsGet({ id: this.props.selectedRole.practice.id });
            this.props.verifyToken({
                package_version : 2
            });
        });
    }

    render() {
        if (_.isEmpty(this.props.initialValues)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}

const selector = formValueSelector('PracticeDetailsForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        practiceDetailsGet,
        practiceDetailsPost,
        cleanModule: actionCreator.cleanModule,
        uploadFiles,
        verifyToken,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let initialValues = { ...state.practice.practiceDetails.practice };
    initialValues['logo'] = initialValues['logo_thumbnail'];
    return {
        practice: state.practice.practiceDetails.practice,
        initialValues: initialValues,
        practiceTypes: state.practice.practiceDetails.practiceTypes,
        selectedRole: state.auth.roleReducer.selectedRole,
        is_avatar_uploaded: selector(state, 'is_avatar_uploaded'),
        logo: selector(state, 'logo'),
        guid: selector(state, 'guid'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    const errors = {};
    if (values["short_name"] && values["short_name"].length >= 15) {
        errors["short_name"] = "Short name for your practice cannot be more than 15 characters"
    }
    let requiredFields = [
        'name',
        'city',
        'zipcode',
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const PracticeDetailsForm = reduxForm(
    {
        form: 'PracticeDetailsForm',
        enableReinitialize: true,
        validate
    }
)(PracticeDetails);

const PracticeDetailsConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PracticeDetailsForm);

export default PracticeDetailsConnected;
