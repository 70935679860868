import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/user_email_update";
import { formValueSelector, reduxForm } from 'redux-form';

import { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ChangeEmail } from '../../redux/user/network/user_profile';
import Swal from 'sweetalert2';
import authActionCreator from '../../redux/auth/actions/auth_creator';
import utils from '../../utils/utils';

class EmailUpdate extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.delete = this.delete.bind(this);
        this.success = this.success.bind(this);
        this.doAppLogout = this.doAppLogout.bind(this);
    }


    doSave(data) {
        let params = {
            "app_version": "5.4",
            "package_version": "3",
        }
        let newParams = { ...data, ...params };
        this.delete(newParams);
    }

    doCancel() {
        history.goBack();
    }

    doAppLogout() {
        this.props.doLogout();
        history.push("/auth/login");
    }

    delete(data) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "You will get logged out immediately and need to login back with the new registered email ID.",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, change email ID",
            cancelButtonText: "Cancel",
            useRejections: true
        }).then(function () {
            console.log("ok", that, data);
            that.props.ChangeEmail(data).then((res) => {
                if (res?.status === "200") {
                    utils.removeLocalStorageValue("token");
                    localStorage.removeItem("selectedRole");
                    try {
                        window.zE(function () {
                            zE.hide();
                        });
                    } catch (err) {
                    }
                    that.success(data);
                }
                else if (res?.response?.status >= 400) {
                    console.log("error", error);
                }
            }).catch((err) => {
                console.log(err, 'err');
            })
        }, function (dismiss) {
            console.log("cancel", dismiss);
        });
    }

    success(data) {
        let that = this;
        Swal.fire({
            title: "Email changed successfully",
            html: `You’ve updated your registered email ID to ${data?.new_email}.<br/><br/> We have shared a verification link on the registered email ID. Click on the link to verify your email ID and log in back.`,
            type: "info",
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Okay",
            icon: "success"
        }).then(function () {
            that.doAppLogout();
        }, function (dismiss) {
            that.doAppLogout();
        });
    }


    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

const selector = formValueSelector('EmailUpdateForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ChangeEmail,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'old_email',
        'new_email',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values['old_email'] === values['new_email']) {
        errors['new_email'] = "Emails should not match.";
    }
    return errors;
};

const EmailUpdateForm = reduxForm(
    {
        form: 'EmailUpdateForm',
        enableReinitialize: true,
        validate
    }
)(EmailUpdate);

const EmailUpdateConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailUpdateForm);

export default EmailUpdateConnected;
