import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/practitioner_container";
import store,{history} from '../../redux/store';

import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import { acceptPartnerRequest, rejectPartnerRequest } from '../../redux/partner/network/partner_network';
import { showProgress } from '../../redux/core/actions/progress_creator';

class PractitionerContainer extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.acceptPartnerRequest = this.acceptPartnerRequest.bind(this);
        this.rejectPartnerRequest = this.rejectPartnerRequest.bind(this);
        this.toggleDrawerActive = this.toggleDrawerActive.bind(this);
        this.state = {
            hasSupportRole: false,
        }
    };
    componentDidMount() {
        if(this.props.user.gi_roles.includes('ROLE_SUPPORT')){
            this.setState({
                hasSupportRole: true,
            })
        }
    }
    acceptPartnerRequest(invited_partner){
        this.props.acceptPartnerRequest({
            practice_partner_id: invited_partner.id,
            partner_id: invited_partner.partner.id,
        }).then((response) => {
            this.props.showProgress();
            setTimeout(function() {
                window.location.reload();
            }, 1000);
        })
        .catch((error) => { 
        });
    }

    rejectPartnerRequest(invited_partner) {
        this.props.rejectPartnerRequest({
            practice_partner_id: invited_partner.id,
            partner_id: invited_partner.partner.id,
        }).then((response) => {
            this.props.showProgress();
            setTimeout(function() {
                window.location.reload();
            }, 1000);
        })
        .catch((error) => { 
        });
    }

    toggleDrawerActive(){
        let path = this.props.location.pathname;
        if(_.includes(path, '/home')){
            // this.props.updateMenuPinned(false);
            this.props.updateMenuPinned(true);
            this.props.updateMenuOpen(!this.props.menuopen);
        }else{
            this.props.updateMenuPinned(!this.props.pinned);
            this.props.updateMenuOpen(!this.props.menuopen); 
        }
    }


    render() {
        if(!this.props.isAuthenticated || _.isEmpty(this.props.selectedRole)){
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }else if(this.props.selectedRole.role.name !== "practitioner" &&  this.props.selectedRole.role.name !== "practice_admin" ){
            history.push("/");
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned:MenuAction.updateMenuPinned,
        acceptPartnerRequest,
        rejectPartnerRequest,
        showProgress,
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen:state.core.appMenu.menu_open,
        pinned:state.core.appMenu.pinned,
        user: state.auth.authReducer.user
    };
}
const PractitionerContainerConnected = connect(mapStateToProps, mapDispatchToProps)(PractitionerContainer);
export default PractitionerContainerConnected;
