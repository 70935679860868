import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import AvailableQuickStarts from '../quickstart_available_list';
import AvailableQuickStartView from '../quickstart_available_view';
import AcquiredQuickStarts from '../quickstart_acquired_list';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu'
    }, React.createElement(NavLink, {
        'to': '/client/quickstart/acquired',
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Acquired QuickStarts')), React.createElement(NavLink, {
        'to': '/client/quickstart/available',
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Available QuickStarts'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/available`,
        'component': AvailableQuickStarts
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/available/:id`,
        'component': AvailableQuickStartView
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/acquired`,
        'component': AcquiredQuickStarts
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/acquired/:id`,
        'component': AvailableQuickStartView
    })));
}