import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import FontIcon from 'react-toolbox/lib/font_icon';
import { NavLink } from 'react-router-dom';
import { IconMenu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { PARTNER } from '../../../../core/constants/constants';
export default function () {
    return React.createElement('ul', { 'className': 'm-t-20' }, React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/workfit/home',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n            Home\n        ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/workfit/details',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'fitness_center' }), '\n            Sessions\n        ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/workfit/scheduler',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-appointments',
        'gomotive': true
    }), '\n                Scheduler\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/client/workfit/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n            User Settings\n        ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n            Sign out\n        ')));
}