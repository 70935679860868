import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/gi_pro_container";
import store,{history} from '../../redux/store';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { showProgress } from '../../redux/core/actions/progress_creator';

class GIProContainer extends BaseComponent {
    constructor(props, context) {
        super(props, context);
    };

    render() {        
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({        
        showProgress,
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,        
    };
}
const GIProContainerConnected = connect(mapStateToProps, mapDispatchToProps)(GIProContainer);
export default GIProContainerConnected;
