import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import calendarActionCreator from '../actions/calendar_list';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getCalendarEventList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/calendar_list";
        return Axios.post(url, params).then((response) => {
            dispatch(calendarActionCreator.calendarEventListFill(
                response['event_list']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    getCalendarEventList,
};