import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs middle-xs' }, React.createElement(Card, { 'className': 'm-20 col-xs-8 p-0' }, React.createElement(CardTitle, {
        'title': 'Welcome to Remote Trainer!',
        'className': 'm-b-10'
    }), React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Button, {
        'className': 'col-xs-12 bg-accent-dark',
        'style': { whiteSpace: 'normal' },
        'onClick': this.goToApp,
        'raised': true
    }, React.createElement('span', {}, 'Open App')), React.createElement('br', {}), React.createElement(Button, {
        'className': 'col-xs-12 m-t-20 bg-beige',
        'style': { whiteSpace: 'normal' },
        'onClick': this.goToAppStore,
        'raised': true
    }, React.createElement('span', {}, 'Download app'))))));
}