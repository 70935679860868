import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import clientActions from '../actions/client_actions';
import authActions from '../../auth/actions/auth_creator';

const getClientHomeDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/"  + PACKAGE + "/client_home";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientHomeFill(
                response["show_movement_graph"],
                response["current_week_movement_points"],
                response["home_page_title"],
                response["has_viewed_welcome_video"],
                response["client_welcome_video_url"],
                response["workout_image_url"],
                response["associated_practice_count"],
                response["program_count"],
                response["program_type"],
                response["program_id"],
                response["news_list"],
                response["practitioners"],
                response["unread_message_count"],
                response["unread_chat_count"]
            ));               
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientHomeAlerts = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_alerts";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientHomeAlertsFill(
                response['unread_chat_count'],
                response['unread_messages_count'],
                response['untracked_goals'],
                response['intakes_assigned'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getClientCheckinList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_checkin";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientCheckinListFill(
                response['checkin_count'],
                response['engagement_list'],
                response['group_list'],
                response['quickstart_list'],
                response['available_dates'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientStart = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_start";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientStartListFill(
                response['start_list'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getClientConnectPractitionerList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_connect";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientConnectPractitionersFill(
                response['practitioners'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const postClientWelcomeVideoWatched = (params) => {
    return (dispatch) => {
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_welcome_video_watched";
        return Axios.post(url, params).then((response) => {
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientPrograms = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/2/" + PACKAGE + "/client_programs";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientProgramListFill(
                response['program_list'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientWorkouts = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/2/" + PACKAGE + "/client_workouts";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientProgramFill(
                response['program'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientAllWorkouts = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/2/" + PACKAGE + "/client_workouts_all";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientProgramFill(
                response['program'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



const getClientHabits = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/2/" + PACKAGE + "/client_habits";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientProgramFill(
                response['program'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientHealthDocuments = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/2/" + PACKAGE + "/client_nutrition";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientProgramFill(
                response['program'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientIntakes = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/2/" + PACKAGE + "/client_intake_forms";
        return Axios.post(url, params).then((response) => {
            dispatch(clientActions.clientIntakeFormListFill(
                response['intake_forms'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getClientHomeDetails,
    getClientHomeAlerts,
    getClientCheckinList,
    getClientStart,
    getClientConnectPractitionerList,
    postClientWelcomeVideoWatched,
    getClientWorkouts,
    getClientPrograms,
    getClientHabits,
    getClientHealthDocuments,
    getClientAllWorkouts,
    getClientIntakes,
};