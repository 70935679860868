import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_plan_edit.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm,formValueSelector } from 'redux-form';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import { getClientPaymentPlan,postClientPaymentPlan} from '../../redux/payment/network/client_payment_plan_network';
import actionCreator from '../../redux/payment/actions/client_payment_plan_action';

class ClientPlanEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.state = {publish:false};
        this.onPublish = this.onPublish.bind(this);
        this.onDraft = this.onDraft.bind(this);        
    }
    
    componentWillMount() {
        let params = {};
        if(this.props.match.params.plan_id) {
               params.id = this.props.match.params.plan_id;
        }else{
            history.goBack();
        }
        this.props.getClientPaymentPlan(params);      
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }
    
    onPublish() { 
        this.setState({publish:true},()=>{
             this.props.handleSubmit(this.doSave)();
        });
       
    }
    onDraft() {  
        this.setState({publish:false},()=>{
             this.props.handleSubmit(this.doSave)();
        });
    }
    

    doSave(data){
        let params = _.pick(data, [
                'id',
                'name',
                'description',
                'fee_amount',
                ]
        );
        params.fee_amount = parseFloat(params.fee_amount);
        params.is_published = this.state.publish;
        this.props.postClientPaymentPlan(params).then(()=>{
            history.push("/practice/"+this.props.selectedRole.practice.id+"/payments/client_plans");
        }).catch((error)=>{
            //Do nothing
        });
    }


    render() {
        if (_.isEmpty(this.props.plan)) {
            return (<div></div>);
        }else{
            return Template.apply(this);
        }
    }
}
function mapStateToProps(state) {
    let  plan = state.payment.clientPaymentPlanReducer.client_payment_plan;
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: {...plan},
        plan: plan,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getClientPaymentPlan,
            postClientPaymentPlan,
            cleanUp: actionCreator.clientPaymentPlanInstanceFill
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name','description','fee_amount'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ClientPlanEditForm = reduxForm(
    {
        form: 'clientPlanEditForm',
        enableReinitialize: true,
        validate
    }
)(ClientPlanEdit);


const ClientPlanEditConnected = connect(mapStateToProps,mapDispatchToProps)(ClientPlanEditForm);
export default ClientPlanEditConnected;