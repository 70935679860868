import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import {getVimeoUrlFromId} from '../../redux/video/network/video_network';
import store from '../../redux/store';
import {showProgress, hideProgress} from '../../redux/core/actions/progress_creator';
import PropTypes from 'prop-types';

class VimeoPlayer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.player = null;
        this.initialized = false;
        this.currentIndex = 0;
        this.onEnd = this.onEnd.bind(this);
        this.play = this.play.bind(this);
        this.init = this.init.bind(this);
        this.handleUploadProcessing = this.handleUploadProcessing.bind(this);
        this.state = {upload_processing:false,timerInterval:null,video_urls:{},videoError:false};
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (this.initialized) {
            return false;
        } else {
            return true;
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.initialized){
            if(this.props.videoList.length === 1 && nextProps.videoList.length === 1){
                if (this.player && this.props.videoList[0] !== nextProps.videoList[0]) {
                    this.play(nextProps,0);
                }    
            }else{
                if (this.player && nextProps.currentIndex !== undefined && this.currentIndex !== nextProps.currentIndex) {
                    this.currentIndex = nextProps.currentIndex;
                    this.play(nextProps,this.currentIndex);
                }
            }
        }
    }

    componentDidMount() {
        this.init();
    }


    play(props,videoIndex){
        if(this.player !== null){
            this.player.loadVideo(parseInt(props.videoList[videoIndex].toString().split(",")[0])).then(function() {
                this.player.play();
            }).catch(function(error) {
                this.setState({videoError:true});
            });
        }else{
            this.setState({videoError:true});
        }
    }

    init(counter = 0){
        if(this.props.videoList.length > 0){
            let that = this;
            let options = this.getOptions(this.props);
            options.id =parseInt(this.props.videoList[0].toString().split(",")[0]);
            let player =new Vimeo.Player(options.identifier, options);
            this.player = player;
            player.ready().then((success)=>{
                if(this.state.timerInterval !== null){
                    clearInterval(this.state.timerInterval);
                    this.setState({upload_processing:false,timerInterval:null});
                }
                player.on('ended', function(data) {
                    that.onEnd(data);
                });
                that.props.onPlayerInitialized(player);
                that.initialized = true;
            }).catch((error)=>{
                if(_.includes(error.message,"was not found") && that.props.handleUploadProcessing){
                    if(counter < 10){
                        that.handleUploadProcessing(counter);
                    }else{
                        that.setState({videoError:true});
                    }
                }else{
                    that.setState({videoError:true});
                }
            });

        }
    }

    handleUploadProcessing(counter){
        let that = this;
        if(this.state.timerInterval === null){
            let timerInterval = setInterval(function () {
                counter = counter + 1; 
                that.init(counter);
            }, 6000);
            that.setState({upload_processing:true,timerInterval:timerInterval});
        }
    }



    getOptions(props){
        const width = _.has(props, 'width') ? props.width : '100%';
        const height = _.has(props, 'height') ? props.height : '100%';
        const autoPlay = _.has(props, 'autoPlay') ? props.autoPlay : false;
        const identifier = _.has(props, 'identifier') ? props.identifier : "gomotive_video";
        let options = {
            transparent:false,
            showByline:false,
            autoplay:autoPlay,
            loop:false,
            color:'45bc00',
            responsive: true,
            identifier:identifier,
            preventCache:new Date()
        };
        return options;

    }


    onEnd() {
        let that = this;
        if (that.player) {
            if (that.props.playlistLoop) {
                that.play(that.props,that.currentIndex);
            } else {
                if (that.currentIndex === that.props.videoList.length - 1) {
                    this.props.onPlaybackEnd();
                    return;
                }
                that.currentIndex = that.currentIndex + 1;
                that.props.onChangePlayingIndex(that.currentIndex);
                that.play(that.props,that.currentIndex);
            }
        }
    }


    componentWillUnmount() {
        if (this.player) {
            const identifier = _.has(this.props, 'identifier') ? this.props.identifier : "gomotive_video";
            $("#"+identifier).remove();
        }
        if(this.state.timerInterval !== null){
            clearInterval(this.state.timerInterval);
        }
    }


    render() {
        const width = _.has(this.props, 'width') ? this.props.width : '100%';
        const height = _.has(this.props, 'height') ? this.props.height : '100%';
        const identifier = _.has(this.props, 'identifier') ? this.props.identifier : "gomotive_video";

        // if(this.props.videoList.length === 0 || this.state.videoError)
        if(this.props.splashVideo){
            return (
                <div
                    id={identifier}
                    className="splash-video"
                    style={{ width: width, height: height }} >
                </div>
            );
        }else if(this.props.videoList.length === 0) {
            return (
                <div
                    id={identifier}
                    className="row center-xs middle-xs"
                    style={{ width: width, height: height }} >
                    <div className="alert-warning col-xs-12 f-20">Video Not Available!</div>
                </div>
            );
        }else if(this.state.upload_processing && this.props.handleUploadProcessing) {
            return (
                <div
                id={identifier}
                className="row center-xs middle-xs"
                style={{ width: width, height: height }} >
                    <div className="alert-warning col-xs-12 f-20">Uploaded video is being processing...</div>
                </div>
            );
        }
        return (
            <div
                id={identifier}
                className="vimeo-holder"
                style={{ width: width, height: height }} >
            </div>
        );
    }


}

VimeoPlayer.propTypes = {
    identifier: PropTypes.string,
    onChangePlayingIndex: PropTypes.func,
    onPlayerInitialized: PropTypes.func,
    onPlaybackEnd: PropTypes.func,
    currentIndex: PropTypes.number,
    playlistLoop: PropTypes.bool,
    handleUploadProcessing: PropTypes.bool,
    videoList: PropTypes.array.isRequired,
};

VimeoPlayer.defaultProps = {
    identifier: "gomotive_video",
    currentIndex: 0,
    videoList: [],
    playlistLoop:false,
    handleUploadProcessing:false,
    onPlaybackEnd: () => { },
    onChangePlayingIndex: () => { },
    onPlayerInitialized: () => { },
};


export default VimeoPlayer;