import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import Select from '../../../utils/common_components/select_form';
import { history } from '../../../redux/store';
import HelpTip from '../../../utils/common_components/help_tip';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement(Card, { 'className': 'col-xs-12  p-0' }, React.createElement(CardTitle, { 'title': 'Add Business Partner' }), React.createElement(CardText, { 'className': 'p-t-5' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'email',
        'type': 'email',
        'component': TextInput,
        'label': 'Practitioner Email',
        'needsOnBlur': true,
        'onBlur': val => {
            this.onEmailChange(val);
        }
    }), React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'subject',
        'type': 'text',
        'component': TextInput,
        'label': 'Subject'
    }), React.createElement('div', { 'className': 'col-xs-12 m-b-10' }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'className': 'w-100',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Body'
    })), React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement('label', { 'className': 'material-label' }, '\n                                            Private Plan Offer:\n                                            ', React.createElement(HelpTip, {}, '\n                                                    If you want this practitioner to choose from Private Plan(s) at different price points than normal, create Private Plans in advance and select them below before sending this invite. Use of Private Plans hides your public plans from view.\n                                            ')), React.createElement(Field, {
        'className': 'w-100',
        'name': 'available_plans',
        'component': Select,
        'options': this.props.partner_plans,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true
    })), this.state.practice_list.length > 0 ? React.createElement('div', {
        'className': 'alert-warning',
        'key': '3520'
    }, '\n                                ', this.state.practitioner.name, '(', this.state.practitioner.email, ') owns a practice in GoMotive. Please select the practice below\n                            ') : null, React.createElement('div', { 'className': 'col-xs-12 p-0' }, this.state.practice_list.length > 0 ? React.createElement(Field, {
        'className': 'w-100',
        'name': 'selected_practice',
        'component': Select,
        'label': 'Practice',
        'options': this.state.practice_list,
        'simpleValue': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'key': '3908'
    }) : null), this.state.practice_list.length === 0 ? React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'practice_name',
        'type': 'text',
        'component': TextInput,
        'label': 'Practice Name',
        'key': '4483'
    }) : null, this.state.practice_list.length === 0 ? React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'practice_city',
        'type': 'text',
        'component': TextInput,
        'label': 'City',
        'key': '4853'
    }) : null, this.state.practice_list.length === 0 ? React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'practice_zipcode',
        'type': 'text',
        'component': TextInput,
        'label': 'Zipcode',
        'key': '5214'
    }) : null, React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Invite',
        'className': 'bg-success',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'm-l-10',
        'onClick': () => {
            history.goBack();
        },
        'disabled': this.props.submitting,
        'type': 'button',
        'accent': true,
        'raised': true
    }))))))));
}