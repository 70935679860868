import * as React from 'react';
import * as _ from 'lodash';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import IntakeForm from '../client_intake_form';
import Appointments from '../engagement_appointments';
import Payment from '../engagement_payments';
import AssessmentSummary from '../engagement_assessment_summary';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import ChatList from '../../../conversation/components/chat_list';
import DefinePlan from '../engagement_define_plan.jsx';
import Sortable from 'react-anything-sortable';
import SortableItem from '../../../utils/common_components/sortable_item';
import GoalTrackingLineChart from '../engagement_goal_tracking_chart';
import GoalEdit from '../engagement_goal_edit';
import WorkoutProgressions from '../client_engagement_progression_dates';
import EasyPie from '../../../utils/common_components/easypie.jsx';
import HealthDocumentList from '../engagement_document_list';
import ProgramEditOld from '../../../program/components/program_edit';
import ProgramListOld from '../../../program/components/program_list';
import ProgramEdit from '../../../program_new/components/program_edit_new';
import ProgramList from '../../../program_new/components/program_list_new';
import AssessmentEdit from '../engagement_assessment_summary';
import EngagementIntake from '../engagement_intake.jsx';
import EngagementHabit from '../engagement_habit.jsx';
import Chip from 'react-toolbox/lib/chip';
import { WORKOUT_TARGET_CLIENT } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import TreatmentTemplateList from '../../../treatment_template/components/treatment_template_list';
import TreatmentTemplateListNew from '../../../treatment_template_new/components/treatment_template_list_new';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import { PARTNER } from '../../../core/constants/constants';
import { Provider } from 'react-redux';
import store from '../../../redux/store';
export default function () {
    function repeatTrack1(goal, goalIndex, track, trackIndex) {
        return React.createElement('div', {
            'className': 'panel m-b-10 row flex-column',
            'key': track.id
        }, React.createElement('div', { 'className': 'panel-heading bg-beige panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, trackIndex + 1, '. ', track.track_question)), track.initial_value === null || track.target_value === null ? React.createElement('div', {
            'className': 'panel-body p-20 vertical-align m-0 center-xs',
            'key': '12772'
        }, React.createElement('div', { 'className': 'alert-warning' }, '\n                                Results  will be displayed only after initial & target values are populated either by client or practitioner.\n                            ')) : null, track.initial_value !== null && track.target_value !== null ? React.createElement('div', {
            'className': 'panel-body p-0 row m-0',
            'key': '13188'
        }, React.createElement('div', { 'className': 'col-xs-12 text-center' }, React.createElement('div', {
            'className': 'row middle-xs center-xs text-center',
            'style': { height: '100px' }
        }, React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'tooltip': 'Baseline Value',
            'className': 'bg-accent f-18 cursor-default',
            'label': track.initial_value !== null ? track.initial_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                    '), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'tooltip': 'Current Value',
            'className': 'bg-primary f-18 cursor-default',
            'label': track.current_value !== null ? track.current_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                    '), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'tooltip': 'Target Value',
            'className': 'p-l-22 bg-success f-18 cursor-default',
            'label': track.target_value !== null ? track.target_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                    ')))) : null);
    }
    function repeatTrack2(goal, goalIndex, track, trackIndex) {
        return React.createElement('div', {
            'className': 'panel m-b-10 row flex-column',
            'key': track.id
        }, React.createElement('div', { 'className': 'panel-heading bg-danger panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, track.track_question, ' - (Metric has been deleted)')), track.initial_value && track.target_value ? React.createElement('div', {
            'className': 'panel-body p-0 row m-0',
            'key': '15271'
        }, React.createElement('div', {
            'className': 'col-xs-12 col-md-8',
            'style': { borderRight: '2px dashed #6C7F8D' }
        }, React.createElement('div', {
            'style': {
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            },
            'className': 'text-center p-relative'
        }, React.createElement(GoalTrackingLineChart, {
            'engagement': this.props.engagement,
            'goal_track': track
        }))), React.createElement('div', { 'className': 'col-xs-12 col-md-4 text-center' }, React.createElement('div', {
            'className': 'row middle-xs text-center',
            'style': { height: '250px' }
        }, React.createElement('div', {
            'className': 'col-xs-12',
            'style': { fontSize: '16px' }
        }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'tooltip': 'Baseline Value',
            'className': 'bg-accent f-18 cursor-default',
            'label': track.initial_value !== null ? track.initial_value.toString() : ' - ',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                    '), React.createElement('div', {
            'className': 'col-xs-12',
            'style': { fontSize: '16px' }
        }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'tooltip': 'Current Value',
            'className': 'bg-primary f-18 cursor-default',
            'label': track.current_value !== null ? track.current_value.toString() : ' - ',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                    '), React.createElement('div', {
            'className': 'col-xs-12',
            'style': { fontSize: '16px' }
        }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'tooltip': 'Target Value',
            'className': 'bg-success f-18 cursor-default',
            'label': track.target_value !== null ? track.target_value.toString() : ' - ',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                    ')))) : null);
    }
    function repeatGoal3(goal, goalIndex) {
        return [
            React.createElement('div', {
                'className': 'row middle-xs p-5 bg-primary',
                'key': '104101'
            }, React.createElement('h4', { 'className': 'col-xs-10' }, '\n                            ', goal.text, '\n                            ', goal.goal_tracking.length > 0 ? React.createElement(IconButton, {
                'onClick': this.showGoalModal,
                'tooltip': 'Edit goal text & metrics',
                'className': 'c-white',
                'icon': 'edit',
                'key': '10659'
            }) : null, goal.goal_tracking.length === 0 ? React.createElement(Button, {
                'onClick': this.showGoalModal,
                'tooltip': 'Edit goal text & metrics',
                'label': 'Create Metrics',
                'className': 'bg-warning',
                'raised': true,
                'key': '10983'
            }) : null), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement('div', { 'className': 'row end-xs p-r-5' }, React.createElement('div', { 'className': 'col-xs-12 p-b-5' }, '\n                                    Weekly Check-In\n                                '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
                'tooltip': 'Click to change the goal tracking day',
                'onClick': this.showGoalIntervalModal,
                'className': 'small-button bg-success m-l-5',
                'icon': 'date_range',
                'label': 'Every ' + this.props.engagement.__goal_tracking_day,
                'raised': true
            }))))),
            _.map(goal.goal_tracking, repeatTrack1.bind(this, goal, goalIndex)),
            _.map(goal.inactive_goal_tracking, repeatTrack2.bind(this, goal, goalIndex))
        ];
    }
    function repeatIntake4(intake, intakeIndex) {
        return React.createElement('tr', { 'key': intake.id }, React.createElement('td', {}, intake.intake.name), React.createElement('td', { 'className': 'w-20 text-center' }, intake.submitted ? React.createElement('span', { 'key': '19307' }, '\n                                    Updated by client\n                                ') : null, !intake.submitted ? React.createElement('span', { 'key': '19465' }, '\n                                    Sent to client\n                                ') : null), React.createElement('td', { 'className': 'w-20 text-center' }, intake.submitted ? React.createElement(Button, {
            'tooltip': 'View form updated by client',
            'onClick': () => {
                this.showPreviewIntakeModal(intake);
            },
            'className': 'micro',
            'icon': 'remove_red_eye',
            'accent': true,
            'floating': true,
            'mini': true,
            'key': '19713'
        }) : null, !intake.submitted ? React.createElement(Button, {
            'tooltip': 'View form sent to client',
            'onClick': () => {
                this.showPreviewIntakeModal(intake);
            },
            'className': 'micro',
            'icon': 'remove_red_eye',
            'accent': true,
            'floating': true,
            'mini': true,
            'key': '20085'
        }) : null, React.createElement(Button, {
            'onClick': () => {
                this.deleteIntake(intake);
            },
            'tooltip': 'Delete intake form',
            'className': 'bg-danger micro m-l-5',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true
        })));
    }
    function repeatAssessment5(assessment, assessmentIndex) {
        return [React.createElement('tr', { 'key': '228431' }, React.createElement('td', { 'className': 'text-center w-20' }, assessment.practitioner.name), React.createElement('td', { 'className': 'text-center w-20' }, moment(assessment.assessment_date).format('MMMM Do YYYY')), React.createElement('td', { 'className': 'text-center w-45' }, assessment.assessment_name), React.createElement('td', { 'className': 'text-center w-10' }, React.createElement(Button, {
                'onClick': () => {
                    this.editAssessment(assessment);
                },
                'tooltip': 'Add summary note for assessment along with videos & images',
                'className': 'bg-primary m-l-5 micro',
                'icon': 'create',
                'floating': true,
                'mini': true
            }), React.createElement(Button, {
                'onClick': () => {
                    this.deleteAssessment(assessment);
                },
                'tooltip': 'Delete assessment',
                'className': 'bg-danger m-l-5 micro',
                'icon': 'delete_forever',
                'floating': true,
                'mini': true
            })))];
    }
    function repeatHabit6(habit, habitIndex) {
        return React.createElement(Tr, { 'key': 'row' + habitIndex }, React.createElement(Td, {
            'column': 'name',
            'value': habit.name,
            'style': { textAlign: 'center' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, habit.name)), React.createElement(Td, {
            'column': 'start_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, moment(habit.start_date).format('MMMM Do YYYY'))), React.createElement(Td, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.__schedule_type)), React.createElement(Td, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.duration_count, ' ', habit.__duration_type)), React.createElement(Td, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, habit.is_published ? React.createElement(IconButton, {
            'icon': 'check',
            'className': 'c-success icon-scale',
            'key': '34150'
        }) : null, !habit.is_published ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '34361'
        }) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, React.createElement(Button, {
            'onClick': () => {
                this.editHabit(habit);
            },
            'tooltip': 'Modify existing habit',
            'className': 'bg-primary m-l-5 micro',
            'icon': 'create',
            'floating': true,
            'mini': true
        }), React.createElement(Button, {
            'onClick': () => {
                this.deleteHabit(habit);
            },
            'tooltip': 'Delete existing habit',
            'className': 'bg-danger m-l-5 micro',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true
        }))));
    }
    function repeatAttachment7(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument8(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment7.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'className': 'm-l-5 bg-danger micro',
            'tooltip': 'Remove the association of this nutrition document to this engagement',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'accent': true,
            'mini': true
        }))));
    }
    function repeatAttachment9(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument10(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment9.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'className': 'm-l-5 bg-danger micro',
            'tooltip': 'Remove the association of this guidance document to this engagement',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'accent': true,
            'mini': true
        }))));
    }
    function repeatTrack11(goal, goalIndex, track, trackIndex) {
        return React.createElement('div', {
            'className': 'panel m-b-10 row flex-column',
            'key': track.id
        }, React.createElement('div', { 'className': 'panel-heading bg-beige panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, trackIndex + 1, '. ', track.track_question)), track.initial_value === null || track.target_value === null ? React.createElement('div', {
            'className': 'panel-body p-20 vertical-align m-0 center-xs',
            'key': '48361'
        }, React.createElement('div', { 'className': 'alert-warning' }, '\n                                        Graph will be populated only after initial & target values are populated either by client or practitioner. Please select Goal and then click on edit icon to enter values\n                                    ')) : null, track.initial_value !== null && track.target_value !== null ? React.createElement('div', {
            'className': 'panel-body p-0 row m-0',
            'key': '48877'
        }, React.createElement('div', {
            'className': 'col-xs-6 col-md-8',
            'style': { borderRight: '2px dashed #6C7F8D' }
        }, React.createElement('div', {
            'style': {
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            },
            'className': 'text-center p-relative'
        }, React.createElement(GoalTrackingLineChart, {
            'engagement': this.props.engagement,
            'goal_track': track
        }))), React.createElement('div', { 'className': 'col-xs-6 col-md-4 text-center' }, React.createElement('div', {
            'className': 'row middle-xs text-center',
            'style': { height: '250px' }
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'className': 'bg-accent f-18',
            'label': track.initial_value !== null ? track.initial_value.toString() : ' - ',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                            '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'className': 'bg-primary f-18',
            'label': track.current_value !== null ? track.current_value.toString() : ' - ',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                            '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'className': 'bg-success f-18',
            'label': track.target_value !== null ? track.target_value.toString() : ' - ',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                            ')))) : null);
    }
    function repeatTrack12(goal, goalIndex, track, trackIndex) {
        return React.createElement('div', {
            'className': 'panel m-b-10 row flex-column',
            'key': track.id
        }, React.createElement('div', { 'className': 'panel-heading bg-danger panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, track.track_question, ' - (Metric has been deleted)')), track.initial_value !== null && track.target_value !== null ? React.createElement('div', {
            'className': 'panel-body p-0 row m-0',
            'key': '51536'
        }, React.createElement('div', {
            'className': 'col-xs-12 col-md-8',
            'style': { borderRight: '2px dashed #6C7F8D' }
        }, React.createElement('div', {
            'className': 'row middle-xs text-center',
            'style': { height: '250px' }
        }, React.createElement('h4', {}, track.result_message))), React.createElement('div', { 'className': 'col-xs-12 col-md-4 text-center' }, React.createElement('div', {
            'className': 'row middle-xs text-center',
            'style': { height: '250px' }
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'className': 'bg-accent f-18',
            'label': track.initial_value !== null ? track.initial_value.toString() : ' - ',
            'floating': true
        }), ' Kgs\n                                            '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'className': 'bg-primary f-18',
            'label': track.current_value !== null ? track.current_value.toString() : ' - ',
            'floating': true
        }), ' Kgs\n                                            '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'className': 'bg-success f-18',
            'label': track.target_value !== null ? track.target_value.toString() : ' - ',
            'floating': true
        }), ' Kgs\n                                            ')))) : null);
    }
    function repeatGoal13(goal, goalIndex) {
        return [
            React.createElement('div', {
                'className': 'row middle-xs p-5  c-dark',
                'key': '476011'
            }, React.createElement('h4', { 'className': 'col-xs-12' }, '\n                                    ', goal.text, '                         \n                                ')),
            _.map(goal.goal_tracking, repeatTrack11.bind(this, goal, goalIndex)),
            _.map(goal.inactive_goal_tracking, repeatTrack12.bind(this, goal, goalIndex))
        ];
    }
    function repeatGoal_template14(goal_template, goal_templateIndex) {
        return React.createElement('tr', { 'key': goal_template.id }, React.createElement('td', {}, goal_template.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.addGoal(goal_template);
            },
            'tooltip': 'Assign goal for this Game Plan',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    function repeatHabit_template15(habit_template, habit_templateIndex) {
        return React.createElement('tr', { 'key': habit_template.id }, React.createElement('td', {}, habit_template.name), React.createElement('td', {}, habit_template.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.createEngagementHabitFromTemplate(habit_template);
            },
            'tooltip': 'Add habit for this Game Plan',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    return React.createElement('div', { 'className': 'row p-15 m-b-25' }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.goToClient
    }, '\n        Back\n    '), React.createElement('div', {
        'className': 'col-xs-12',
        'style': { width: '100%' }
    }, React.createElement('div', { 'className': 'panel m-b-0' }, React.createElement('div', { 'className': 'panel-body p-t-10' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement(TooltipWrapper, {
        'onClick': this.viewClientProfile,
        'tooltip': 'Click to view profile',
        'style': {
            borderRadius: '50%',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
            border: '2px solid #eee'
        }
    }, React.createElement(Avatar, {
        'className': 'pointer',
        'size': 65,
        'user': this.props.engagement.client,
        'profile_type': 'circle'
    })), React.createElement('div', { 'className': 'flex-auto m-l-5' }, React.createElement('h5', { 'className': 'm-b-8' }, '\n                            Client name: ', this.props.engagement.client.name, ' (', this.props.engagement.client.email, ') \n                            '), React.createElement('div', { 'className': 'f-14 m-b-5' }, this.props.engagement.name), React.createElement('div', { 'className': 'f-12 c-accent-light f-300' }, ' \n                            Start date: ', moment(this.props.engagement.created_date).format('MMMM Do YYYY '), '\n                        '))), React.createElement('div', { 'className': 'd-flex w-100' }, React.createElement('div', { 'className': 'text-right m-t-5 m-r-5' }))))), React.createElement(Tabs, {
        'inverse': true,
        'fixed': true,
        'className': 'w-100 custom-tab accent col-xs-12',
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'label': 'Goal',
        'gomotive': true
    }, this.state.showGoalEdit ? React.createElement(GoalEdit, {
        'engagement': this.props.engagement,
        'handleClose': this.unAllocateSpace,
        'key': '9451'
    }) : null, !this.state.showGoalEdit ? React.createElement.apply(this, [
        'div',
        {
            'className': 'p-t-20',
            'style': { marginTop: '-17px' },
            'key': '9634'
        },
        this.props.engagement.goals.length === 0 ? React.createElement('div', {
            'className': 'row middle-xs',
            'key': '9732'
        }, React.createElement('div', { 'className': 'col-xs-10 alert-warning p-l-10' }, '\n                        Define a general goal to get started (select +), or wait for client to create one.\n                    '), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement(Button, {
            'tooltip': 'Add goal text & metrics',
            'onClick': this.showGoalModal,
            'className': 'bg-success pull-right',
            'icon': 'add',
            'floating': true,
            'ripple': true,
            'mini': true
        }))) : null,
        _.map(this.props.engagement.goals, repeatGoal3.bind(this))
    ]) : null), React.createElement(Tab, {
        'label': 'Intake',
        'gomotive': true,
        'disabled': this.props.engagement.goals.length === 0 || this.props.engagement.goals[0].goal_tracking.length + this.props.engagement.goals[0].inactive_goal_tracking.length === 0
    }, !this.state.intakeSpaceAllocated ? React.createElement('div', {
        'className': 'p-10 row',
        'style': { marginTop: '-17px' },
        'key': '17795'
    }, React.createElement('div', { 'className': 'col-xs-12 d-flex flex-end' }, React.createElement(Button, {
        'onClick': this.showAssignIntakeForm,
        'tooltip': 'Assign intake form',
        'className': 'bg-success-red f-16 text-capital height-fit c-white pull-right m-b-10',
        'label': 'Add',
        'floating': true
    })), React.createElement('table', { 'className': 'table reactable  box-shadow col-xs-12' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', { 'className': 'text-center w-20 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement('th', { 'className': 'text-center w-20 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Status')), React.createElement('th', { 'className': 'text-center w-45 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Action')))), React.createElement.apply(this, [
        'tbody',
        {},
        this.props.engagement.intakes.length === 0 ? React.createElement('tr', { 'key': '18808' }, React.createElement('td', {
            'colSpan': '100',
            'className': 'p-20'
        }, '\n                                Click on + icon to assign an intake form.\n                            ')) : null,
        _.map(this.props.engagement.intakes, repeatIntake4.bind(this))
    ]))) : null, this.state.intakeSpaceAllocated ? React.createElement('div', { 'key': '20907' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(EngagementIntake, {
        'engagement_id': this.props.match.params.engagement_id,
        'finishEditing': this.unAllocateSpace
    }))) : null), React.createElement(Tab, {
        'label': 'Assessment',
        'gomotive': true,
        'disabled': this.props.engagement.goals.length === 0 || this.props.engagement.goals[0].goal_tracking.length + this.props.engagement.goals[0].inactive_goal_tracking.length === 0
    }, !this.state.assessmentSpaceAllocated ? React.createElement('div', {
        'className': 'p-10 row',
        'style': { marginTop: '-17px' },
        'key': '21480'
    }, React.createElement('div', { 'className': 'col-xs-12 d-flex flex-end' }, React.createElement(Button, {
        'onClick': () => {
            this.addAssessment();
        },
        'tooltip': 'Add new assessment',
        'className': 'bg-success-red f-16 text-capital height-fit c-white pull-right m-b-10',
        'label': 'Add',
        'floating': true
    })), React.createElement('table', { 'className': 'table reactable  box-shadow col-xs-12' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', { 'className': 'text-center w-20 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Practitioner')), React.createElement('th', { 'className': 'text-center w-20 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Date')), React.createElement('th', { 'className': 'text-center w-45 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Title')), React.createElement('th', { 'className': 'text-center w-30 bg-beige' }, React.createElement('span', { 'className': 'table-heading' }, 'Action')))), React.createElement.apply(this, [
        'tbody',
        {},
        this.props.engagement.assessments.length === 0 ? React.createElement('tr', { 'key': '22564' }, React.createElement('td', {
            'colSpan': '100',
            'className': 'p-20'
        }, '\n                                Click on + icon to assess this client.\n                            ')) : null,
        _.map(this.props.engagement.assessments, repeatAssessment5.bind(this))
    ]))) : null, this.state.assessmentSpaceAllocated ? React.createElement('div', { 'key': '24382' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(AssessmentEdit, {
        'finishEditing': this.unAllocateSpace,
        'engagement_id': this.props.match.params.engagement_id,
        'assessment_id': this.state.editingAssessmentId
    }))) : null), React.createElement(Tab, {
        'label': 'Program',
        'gomotive': true,
        'disabled': this.props.engagement.goals.length === 0 || this.props.engagement.goals[0].goal_tracking.length + this.props.engagement.goals[0].inactive_goal_tracking.length === 0
    }, !this.state.treatmentSpaceAllocated && !this.state.habitSpaceAllocated ? React.createElement('div', {
        'style': { marginTop: '-17px' },
        'key': '25021'
    }, React.createElement('ul', { 'className': 'tab-bar grey-tab  clear-row' }, React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 0 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(0);
        }
    }, '\n                    Workouts\n                ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 1 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(1);
        }
    }, '\n                    Habits\n                ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 2 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(2);
        }
    }, '\n                    Nutrition\n                ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 3 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(3);
        }
    }, '\n                    Guidance\n                '))), this.state.treatmentTabIndex === 0 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '26193'
    }, React.createElement('div', { 'className': 'col-xs-11' }, this.props.workouts.length === 0 ? React.createElement('div', {
        'className': 'alert-warning m-t-20',
        'key': '26324'
    }, 'Please click on the + button to add Workouts') : null), React.createElement('div', { 'className': 'col-xs-1 m-t-10 pull-right' }, React.createElement(Button, {
        'onClick': this.addWorkout,
        'tooltip': 'Add new workout',
        'className': 'bg-success-red text-capital height-fit f-16 c-white pull-right m-b-10',
        'label': 'Add',
        'floating': true
    })), React.createElement('div', { 'className': 'col-xs-12' }, '\n                ', this.renderWorkoutList(), '\n            ')) : null, this.state.treatmentTabIndex === 1 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '30982'
    }, React.createElement('div', { 'className': 'col-xs-11' }, this.props.engagement.habits.length === 0 ? React.createElement('div', {
        'className': 'alert-warning m-t-20',
        'key': '31125'
    }, 'Please click on the + button to add Habits') : null), React.createElement('div', { 'className': 'col-xs-1 m-t-10 pull-right' }, React.createElement(Button, {
        'onClick': this.addHabit,
        'tooltip': 'Add new habit',
        'className': 'bg-success-red f-16 c-white text-capital height-fit c-white pull-right m-b-10',
        'label': 'Add',
        'floating': true
    })), this.props.engagement.habits.length > 0 ? React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a habit to this client.',
            'sortable': ['name'],
            'isServerPaginate': false,
            'key': '31669'
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'name',
            'style': { textAlign: 'center' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Schedule')), React.createElement(Th, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Duration')), React.createElement(Th, {
            'column': 'start_date',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Start date')), React.createElement(Th, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Is Published?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Action'))),
        _.map(this.props.engagement.habits, repeatHabit6.bind(this))
    ]) : null) : null, this.state.treatmentTabIndex === 2 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '35667'
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-10 d-flex flex-end' }, React.createElement(Button, {
        'onClick': () => {
            this.toggleDocumentSelectionDialog('nutrition');
        },
        'tooltip': 'Add new nutrition documents',
        'className': 'bg-success-red f-16 text-capital c-white height-fit m-b-10 pull-right',
        'label': 'Add',
        'floating': true
    })), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a nutrition plan to this client.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('span', { 'className': 'table-heading' }, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.nutritionDocuments, repeatDocument8.bind(this))
    ])) : null, this.state.treatmentTabIndex === 3 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '39562'
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-10 d-flex flex-end' }, React.createElement(Button, {
        'onClick': () => {
            this.toggleDocumentSelectionDialog('guidance');
        },
        'tooltip': 'Add new guidance documents',
        'className': 'bg-success-red f-16 c-white text-capital height-fit m-b-10 pull-right',
        'label': 'Add',
        'floating': true
    })), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a guidance document to this client.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('span', { 'className': 'table-heading' }, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.guidanceDocuments, repeatDocument10.bind(this))
    ])) : null) : null, this.state.treatmentSpaceAllocated ? React.createElement('div', { 'key': '43262' }, React.createElement('div', { 'className': 'col-xs-12 p-relative' }, PARTNER.app_type === 'gi' ? React.createElement('div', { 'key': '43367' }, this.state.showWorkoutEditForm ? React.createElement(ProgramEdit, _.assign({}, {
        'finishEditing': this.finishEditingWorkout,
        'usedInside': true,
        'clientWorkout': 'true',
        'engagement_id': this.props.match.params.engagement_id,
        'workoutTargetType': WORKOUT_TARGET_CLIENT,
        'disableEditing': this.state.workoutPreviewMode,
        'program_id': this.state.editingWorkoutId,
        'key': '43423'
    }, this.state.workoutProgramTypeParams)) : null, this.state.showTemplateSelection ? React.createElement(ProgramList, _.assign({}, {
        'usedInside': true,
        'cancelTemplateSelection': this.unAllocateSpace,
        'isTemplatesPurchased': this.state.isTemplatesPurchased,
        'user_subscription_active': this.state.user_subscription_active,
        'engagement_id': this.props.match.params.engagement_id,
        'mode': TEMPLATE_SELECTION_MODE,
        'workoutTargetType': WORKOUT_TARGET_CLIENT,
        'afterCreateWorkoutFromProgram': this.afterCreateWorkoutFromTemplate,
        'key': '43881'
    }, this.state.templateProgramTypeParams)) : null, this.state.showTreatmentTemplates ? React.createElement(TreatmentTemplateListNew, {
        'match': { params: { treatment_type: 'gameplan' } },
        'create_treatment': true,
        'engagement_id': this.props.engagement.id,
        'hideTreatmentTemplateSelection': this.hideTreatmentTemplateSelection,
        'user_subscription_active': this.state.user_subscription_active,
        'isTemplatesPurchased': this.state.isTemplatesPurchased,
        'key': '44459'
    }) : null) : null, PARTNER.app_type === 'dhf' || PARTNER.app_type === 'tri' ? React.createElement('div', { 'key': '45032' }, this.state.showWorkoutEditForm ? React.createElement(ProgramEditOld, _.assign({}, {
        'finishEditing': this.finishEditingWorkout,
        'usedInside': true,
        'clientWorkout': 'true',
        'engagement_id': this.props.match.params.engagement_id,
        'workoutTargetType': WORKOUT_TARGET_CLIENT,
        'disableEditing': this.state.workoutPreviewMode,
        'program_id': this.state.editingWorkoutId,
        'key': '45119'
    }, this.state.workoutProgramTypeParams)) : null, this.state.showTemplateSelection ? React.createElement(ProgramListOld, _.assign({}, {
        'usedInside': true,
        'cancelTemplateSelection': this.unAllocateSpace,
        'user_subscription_active': this.state.user_subscription_active,
        'isTemplatesPurchased': this.state.isTemplatesPurchased,
        'engagement_id': this.props.match.params.engagement_id,
        'mode': TEMPLATE_SELECTION_MODE,
        'workoutTargetType': WORKOUT_TARGET_CLIENT,
        'afterCreateWorkoutFromProgram': this.afterCreateWorkoutFromTemplate,
        'key': '45580'
    }, this.state.templateProgramTypeParams)) : null, this.state.showTreatmentTemplates ? React.createElement(TreatmentTemplateList, {
        'match': { params: { treatment_type: 'gameplan' } },
        'create_treatment': true,
        'engagement_id': this.props.engagement.id,
        'hideTreatmentTemplateSelection': this.hideTreatmentTemplateSelection,
        'key': '46177'
    }) : null) : null)) : null, this.state.habitSpaceAllocated ? React.createElement('div', { 'key': '46563' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(EngagementHabit, {
        'finishEditing': this.unAllocateSpace,
        'engagementId': this.props.match.params.engagement_id,
        'habitId': this.state.editHabitId
    }))) : null), React.createElement(Tab, {
        'label': 'Tracking',
        'disabled': this.props.engagement.goals.length === 0 || this.props.engagement.goals[0].goal_tracking.length + this.props.engagement.goals[0].inactive_goal_tracking.length === 0
    }, React.createElement('div', {
        'className': 'p-t-20',
        'style': { marginTop: '-17px' }
    }, React.createElement(WorkoutProgressions, { 'engagement': this.props.engagement }))), React.createElement(Tab, {
        'label': 'Results',
        'gomotive': true,
        'disabled': this.props.engagement.goals.length === 0 || this.props.engagement.goals[0].goal_tracking.length + this.props.engagement.goals[0].inactive_goal_tracking.length === 0
    }, React.createElement.apply(this, [
        'div',
        {
            'className': 'p-t-20',
            'style': { marginTop: '-17px' }
        },
        _.map(this.props.engagement.goals, repeatGoal13.bind(this))
    ]))), !_.isEmpty(this.state.previewIntake) ? React.createElement(Dialog, {
        'active': this.state.openPreviewIntakeModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hidePreviewIntakeModal,
        'onOverlayClick': this.hidePreviewIntakeModal,
        'key': '53709'
    }, React.createElement(Provider, { 'store': this.props.store }, React.createElement(IntakeForm, {
        'intake': this.state.previewIntake,
        'handleClose': this.hidePreviewIntakeModal
    }))) : null, React.createElement(Dialog, {
        'active': this.state.openAppointmentModal,
        'title': 'Appointments',
        'className': 'full-dialog',
        'onEscKeyDown': this.hideAppointmentModal,
        'onOverlayClick': this.hideAppointmentModal
    }, React.createElement(Appointments, {
        'engagement': this.props.engagement,
        'handleClose': this.hideAppointmentModal
    })), React.createElement(Dialog, {
        'active': this.state.openPaymentModal,
        'title': 'Payment Plan',
        'onEscKeyDown': this.hidePaymentModal,
        'onOverlayClick': this.hidePaymentModal
    }, this.state.openPaymentModal ? React.createElement(Payment, {
        'engagement': this.props.engagement,
        'handleClose': this.hidePaymentModal,
        'store': this.props.store,
        'key': '54693'
    }) : null), React.createElement(Dialog, {
        'active': this.state.openGoalIntervalModal,
        'title': 'Client is prompted to check-in on this day',
        'onEscKeyDown': this.hideGoalIntervalModal,
        'onOverlayClick': this.hideGoalIntervalModal
    }, React.createElement(Provider, { 'store': this.props.store }, React.createElement(DefinePlan, {
        'engagement': this.props.engagement,
        'store': this.props.store,
        'handleClose': this.hideGoalIntervalModal
    }))), React.createElement(Dialog, {
        'active': this.state.openGoalModal,
        'title': 'Please select a goal from your library or create a custom goal for this client',
        'onEscKeyDown': this.hideGoalModal,
        'onOverlayClick': this.hideGoalModal
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.goal_list, repeatGoal_template14.bind(this))
    ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Add Custom Goal',
        'onClick': () => {
            this.goToGoalEdit();
        },
        'tooltip': 'Click this button if you want to add a custom goal for this engagement',
        'className': 'bg-success m-r-10',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Close',
        'tooltip': 'Close dialog box',
        'accent': true,
        'raised': true,
        'onClick': this.hideGoalModal
    })))), React.createElement(Dialog, {
        'active': this.state.openHabitTemplateModal,
        'title': 'Please select a habit from your library',
        'onEscKeyDown': this.hideHabitTemplateModal,
        'onOverlayClick': this.hideHabitTemplateModal
    }, this.state.openHabitTemplateModal ? React.createElement('div', {
        'className': 'row',
        'key': '57601'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', {}, 'Description'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.habit_template_list, repeatHabit_template15.bind(this))
    ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Close',
        'tooltip': 'Close dialog box',
        'accent': true,
        'raised': true,
        'onClick': this.hideHabitTemplateModal
    }))) : null), React.createElement(Dialog, {
        'active': this.state.documentSelectionDialogVisible,
        'className': 'full-dialog',
        'title': 'Click \'+\' to assign',
        'onEscKeyDown': () => this.toggleDocumentSelectionDialog(),
        'onOverlayClick': () => this.toggleDocumentSelectionDialog()
    }, this.state.documentSelectionDialogVisible ? React.createElement(HealthDocumentList, {
        'store': this.props.store,
        'selectionMode': true,
        'params': { document_type: this.state.healthDocumentType },
        'engagement': this.props.engagement,
        'onSelected': this.assignHealthDocument,
        'key': '59485'
    }) : null), this.props.engagement.conversation ? React.createElement(Dialog, {
        'active': this.state.openChatModal,
        'title': 'Chat Conversations',
        'className': 'full-dialog',
        'onEscKeyDown': this.hideChatModal,
        'onOverlayClick': this.hideChatModal,
        'key': '59893'
    }, React.createElement(ChatList, {
        'store': this.props.store,
        'conversation_id': this.props.engagement.conversation.id,
        'handleClose': this.hideChatModal
    })) : null);
}