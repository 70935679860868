import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/goal_edit";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { history } from '../../redux/store';
import { getGoal, saveGoal, } from '../../redux/goal/network/goal_network';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import Select from '../../utils/common_components/select';

import Button from '../../utils/common_components/tooltipButton';
import IconButton from '../../utils/common_components/tooltipIconButton';
// import Input from 'react-select/src/components/Input';

class GoalEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveGoal = this.saveGoal.bind(this);
        this.renderGoalTrackingQuestions = this.renderGoalTrackingQuestions.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onDraft = this.onDraft.bind(this);        
        this.state = {publish:false};
    };
    
    cancel() {
        if(confirm("Are you sure? All changes will be discarded.")){
            history.goBack();
        }
    }


    componentWillMount() {
        let params = {};
        if(this.props.match.params.goal_id !== undefined) {
            params = {
                'goal_id': this.props.match.params.goal_id
            };
        } else {
            params = {
                'goal_id': ''
            };
        }
        this.props.getGoal(params);        
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    saveGoal(data) {     
        let params = _.omit(data, ['can_be_deleted']);
        params.is_publish = this.state.publish;
        params.package_version = 2;
        this.props.saveGoal(params).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/goal');
        }).catch((error) => {
            //do nothing
        });  
    }

    onPublish() { 
        this.setState({publish:true},()=>{
             this.props.handleSubmit(this.saveGoal)();
        });
       
    }
    onDraft() {  
        this.setState({publish:false},()=>{
             this.props.handleSubmit(this.saveGoal)();
        });
    }
    

    renderGoalTrackingQuestions({fields,meta}) {
        return (
            <div className=" row">
                <div className="col-xs-12">                
                {
                    fields.map((item, index) => {
                            return (
                            <div key={index} className="metric-container p-8 m-t-10">
                                <div className="metric-title p-t-10">
                                    <div > Goal Metric {index+1}</div>        
                                </div>
                                <div class='p-relative full-width m-b-20'>                              
                                    <Field
                                        style={{width:"100%"}}
                                        label="Quantifiable measure to reach this goal"    
                                        name={`${item}.track_question`}
                                        type="text"
                                        component={TextInput}
                                         />
                                         </div>
                                <div className="w-100 full-width">    
                                    <Field 
                                        label="Metric Unit"    
                                        name={`${item}.track_unit[id]`}
                                        component={Select}
                                        options={this.props.goal_tracking_unit}
                                        valueKey="id" 
                                        labelKey="unit_name" 
                                        simpleValue={true} />
                                </div>
                                <div className="metric-delete">    
                                    {
                                        (!this.props.goal.practice || (this.props.goal.practice && this.props.goal.practice.id === this.props.selectedRole.practice.id)) &&
                                            <Button    
                                                tooltip="Delete tracking question"    
                                                className="c-danger f-18"
                                                ripple
                                                onClick={() => fields.remove(index)}>
                                                Remove
                                            </Button>
                                    }
                                </div>

                            </div>    
                        );
                    })
                }
                </div>
                {
                    (!this.props.goal.practice || (this.props.goal.practice && this.props.goal.practice.id === this.props.selectedRole.practice.id)) &&
                        <div className="col-xs-12 text-center start-xs">
                            <Button    
                                tooltip="Add a quantifiable measure to reach this goal"    
                                label="Add Another Metric"
                                className="secondary-button bg-success-border"
                                onClick={() => fields.push({})}/>       
                        </div>    
                }    
            </div>    
        );
    }
    
    render() {
        if (_.isEmpty(this.props.goal)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    let goal = state.goal.goalReducer.goal;
    if (goal && goal.goal_tracking) {
        if (goal.goal_tracking.length === 0) {
            goal.goal_tracking.push({});
        }
    }
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        goal: state.goal.goalReducer.goal,
        goal_types: state.goal.goalReducer.goal_types,
        goal_tracking_unit: state.goal.goalReducer.goal_tracking_unit,
        initialValues: goal,
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getGoal,
            saveGoal,
            cleanUp: GoalActionCreator.goalCleanup,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'text',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    const trackingArrayErrors = [];
    if(values.goal_tracking){
        values.goal_tracking.forEach((item, index) => {
            const trackingErrors = {};
            if(! item.track_question || item.track_question === ""){
                trackingErrors.track_question = "Tracking question needs to be entered";
            }
            if(!item.track_unit || item.track_unit === null || item.track_unit === ""){
                trackingErrors.track_unit = "Unit of measurement for tracking question needs to be selected";
            }
            trackingArrayErrors[index] = trackingErrors;

        });
    }
    errors.goal_tracking = trackingArrayErrors;
    return errors;
};


const GoalEditForm = reduxForm(
    {
        form: 'goalEditForm',
        enableReinitialize: true,
        validate
    }
)(GoalEdit);
const GoalEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(GoalEditForm);
export default GoalEditFormConnected;
