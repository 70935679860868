import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { FieldArray } from 'redux-form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
import HelpTip from '../../../utils/common_components/help_tip';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'col-xs-10 m-20 p-0' }, React.createElement(CardTitle, {
        'title': 'Group',
        'className': 'm-b-10'
    }), React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.onSubmit) }, React.createElement('div', { 'className': 'row center-xs m-t-10' }, React.createElement('div', { 'className': 'col-xs-12' }, this.props.guid ? [
        this.props.is_logo_uploaded ? React.createElement('div', {
            'className': 'm-b-20 row center-xs',
            'key': '18041'
        }, React.createElement(Avatar, { 'className': 'avatar-image-100 box-100 bg-transparent' }, React.createElement('img', { 'src': this.props.logo_url }))) : null,
        React.createElement('div', {
            'className': 'row center-xs',
            'key': '18043'
        }, React.createElement(Dropzone, {
            'className': 'dummy-suppress-warning',
            'multiple': false,
            'accept': 'image/*',
            'onDrop': this.uploadLogo
        }, React.createElement(Button, {
            'label': this.props.is_logo_uploaded ? 'Change Logo' : 'Upload Logo',
            'primary': true,
            'raised': true
        })), this.props.is_logo_uploaded ? React.createElement(Button, {
            'onClick': this.deleteLogo,
            'label': 'Delete Logo',
            'className': 'm-l-10 bg-danger',
            'primary': true,
            'raised': true,
            'key': '2578'
        }) : null)
    ] : null, React.createElement('div', {}, React.createElement(Field, {
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Group Name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), React.createElement('div', {}, React.createElement(Field, {
        'name': 'group_treatment_cycle',
        'component': Select,
        'label': 'Type',
        'options': this.state.treatment_cycle,
        'clearable': false,
        'disabled': this.props.group.is_group_in_progress
    })), React.createElement('div', { 'className': 'row col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'col-md-6' }, Object.keys(this.state.holdImage).length ? React.createElement('div', {
        'className': 'thumbnail box-250-150',
        'style': { backgroundImage: 'url(' + encodeURI(this.state.holdImage[0].preview) + ')' },
        'key': '3684'
    }) : null, !Object.keys(this.state.holdImage).length && this.props.initialValues.thumbnail_url ? React.createElement('div', {
        'className': 'thumbnail box-250-150',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.initialValues.thumbnail_url) + ')' },
        'key': '3881'
    }) : null), React.createElement('div', { 'className': 'col-md-6 end-xs' }, React.createElement(Dropzone, {
        'className': 'dummy-suppress-warning',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.holdImage
    }, React.createElement(Button, {
        'label': 'Upload Thumbnail image',
        'primary': true,
        'raised': true
    })), Object.keys(this.state.holdImage).length ? React.createElement(Button, {
        'onClick': this.deleteholdImage,
        'label': 'Delete Profile Picture',
        'className': 'm-t-10 m-x-10 col bg-danger',
        'primary': true,
        'raised': true,
        'key': '4491'
    }) : null)), React.createElement('div', { 'className': 'text-left' }, React.createElement('p', { 'className': 'f-13 f-300' }, '\n                            SCHEDULED groups let you set the start and end date for the Workouts, Habits, and \n                            other content you prescribe. All clients are prompted to do the workout on the \n                            same schedule. You can prescribe "Scheduled" and "Ongoing" type workouts.\n                            '), React.createElement('p', { 'className': 'f-13 f-300' }, '\n                            ONGOING groups let you add in content and clients at any time, and you manually activate a client when \n                            they are ready to proceed at their own pace. You can prescribe only "Ongoing" type workouts.\n                            '))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-20' }, React.createElement(Button, {
        'label': 'Save',
        'onClick': () => this.save(false),
        'className': 'm-l-10',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'onClick': this.cancel,
        'className': 'm-l-10',
        'accent': true,
        'raised': true
    })))))));
}