import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actionCreator from '../actions/program_preview';

const getProgramPreview = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_preview_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.fillProgramPreview(
                response['program'],
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getWorkoutPreview = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_preview_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.fillProgramPreview(
                response['workout'],
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const toggleProgramPublish = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/program_publish_toggle";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            if(response.published==true){
            Utils.showSuccessAlert('Published Successfully');
            }
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                // Utils.showErrorAlert(error.response.data.reason);
                Utils.showErrorAlert("Can not be published. There are no exercises. Please add one or more exercises before publish.");
            }
            throw error;
        });
    };
};

const toggleWorkoutPublish = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workout_publish_toggle";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert((response.published ? 'Published' : 'Un-Published') + ' Successfully');
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    getProgramPreview,
    getWorkoutPreview,
    toggleProgramPublish,
    toggleWorkoutPublish,
};