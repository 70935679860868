import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    function repeatCue1(cue, cueIndex) {
        return React.createElement(Chip, { 'key': 'cues' + cue.id }, cue.description);
    }
    function repeatEquipment2(equipment, equipmentIndex) {
        return React.createElement(Chip, { 'key': 'equipments' + equipment.id }, equipment.name);
    }
    return React.createElement(Dialog, { 'active': this.props.exercise !== null }, this.props.exercise ? React.createElement('div', {
        'className': 'row',
        'key': '1026'
    }, React.createElement('div', { 'className': 'col-xs-12 center-xs' }, React.createElement('div', { 'className': 'row between-xs f-18' }, React.createElement('div', {
        'className': 'col-xs-10 center-xs',
        'style': { paddingLeft: '40px' }
    }, '\n                    ', this.props.exercise.name, '\n                '), React.createElement('div', { 'className': 'col-xs-2 end-xs' }, React.createElement(IconButton, {
        'accent': true,
        'tooltip': 'Close window',
        'icon': 'close',
        'style': {
            right: '-15px',
            top: '-15px'
        },
        'className': 'c-white bg-danger',
        'onClick': () => {
            this.props.onClosePreview();
        }
    }))), React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12 video-player' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video' + this.props.exercise.video_guid,
        'videoList': [this.props.exercise.video_guid]
    }))), React.createElement('div', { 'className': 'row start-xs m-10' }, React.createElement('h5', { 'className': 'm-b-5' }, 'Description'), React.createElement('p', { 'className': 'col-xs-12 f-12 pre-wrap' }, '\n                    ', this.props.exercise.description ? this.props.exercise.description : 'No description has been provided', '\n                ')), React.createElement('table', { 'className': 'm-10 f-300 f-12' }, React.createElement('tr', { 'className': 'm-t-5 m-b-5' }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Sets:\n                    '), this.props.exercise.sets ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '2424'
    }, React.createElement('span', { 'className': 'badge' }, this.props.exercise.sets)) : null, !this.props.exercise.sets ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '2628'
    }, '\n                        Not defined\n                    ') : null), this.props.exercise.metric === 1 ? React.createElement('tr', {
        'className': 'm-t-5 m-b-5',
        'key': '2829'
    }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Reps:\n                    '), this.props.exercise.reps ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '3041'
    }, React.createElement('span', { 'className': 'badge' }, this.props.exercise.reps)) : null, !this.props.exercise.reps ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '3249'
    }, '\n                        Not defined\n                    ') : null) : null, this.props.exercise.metric === 2 ? React.createElement('tr', {
        'className': 'm-t-5 m-b-5',
        'key': '3450'
    }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Distance:\n                    '), this.props.exercise.distance ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '3667'
    }, React.createElement('span', { 'className': 'badge' }, this.props.exercise.distance), ' ', this.props.exercise.__distance_unit, '\n                    ') : null, !this.props.exercise.distance ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '3921'
    }, '\n                        Not defined\n                    ') : null) : null, this.props.exercise.metric === 3 ? React.createElement('tr', {
        'className': 'm-t-5 m-b-5',
        'key': '4126'
    }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Duration:\n                    '), this.props.exercise.duration ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '4343'
    }, React.createElement('span', { 'className': 'badge' }, ' ', this.props.exercise.duration, ' '), this.props.exercise.__duration_unit, '\n                    ') : null, !this.props.exercise.duration ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '4598'
    }, '\n                        Not defined\n                    ') : null) : null, React.createElement('tr', { 'className': 'm-t-5 m-b-5' }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Hold position:\n                    '), this.props.exercise.hold ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '5022'
    }, React.createElement('span', { 'className': 'badge' }, ' ', this.props.exercise.hold), ' ', this.props.exercise.__hold_unit, '\n                    ') : null, !this.props.exercise.hold ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '5265'
    }, '\n                        Not defined\n                    ') : null), React.createElement('tr', { 'className': 'm-t-5 m-b-5' }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Rest between Sets:\n                    '), this.props.exercise.pause ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '5689'
    }, React.createElement('span', { 'className': 'badge' }, this.props.exercise.pause), ' ', this.props.exercise.__pause_unit, '\n                    ') : null, !this.props.exercise.pause ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '5934'
    }, '\n                        Not defined\n                    ') : null), React.createElement('tr', { 'className': 'm-t-5 m-b-5' }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Weight:\n                    '), this.props.exercise.weight ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '6365'
    }, React.createElement('span', { 'className': 'badge' }, this.props.exercise.weight), ' ', this.props.exercise.__weight_unit, '\n                    ') : null, !this.props.exercise.weight ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '6613'
    }, '\n                        Not defined\n                    ') : null), React.createElement('tr', { 'className': 'm-t-5 m-b-5' }, React.createElement('td', { 'className': 'width-150 f-500 f-13 text-right p-r-10' }, '\n                        Heart Rate:\n                    '), this.props.exercise.heart_rate ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '7069'
    }, React.createElement('span', { 'className': 'badge' }, ' ', this.props.exercise.heart_rate, ' ')) : null, !this.props.exercise.heart_rate ? React.createElement('td', {
        'className': 'text-left p-5',
        'key': '7291'
    }, '\n                        Not defined\n                    ') : null)), _.has(this.props.exercise, 'notes') ? React.createElement('div', {
        'className': 'row start-xs f-300 m-10 p-5',
        'key': '7619'
    }, React.createElement('h5', { 'className': 'm-b-5' }, 'Notes'), React.createElement('p', { 'className': 'col-xs-12 pre-wrap' }, '\n                    ', this.props.exercise.notes, '\n                ')) : null, React.createElement('div', { 'className': 'row start-xs f-300 m-10 p-5' }, React.createElement('h5', { 'className': 'm-b-5' }, 'Cues'), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12' },
        _.map(this.props.exercise.cues, repeatCue1.bind(this))
    ]), this.props.exercise.cues.length === 0 ? React.createElement('div', {
        'className': 'col-xs-12  alert-warning text-center',
        'key': '8180'
    }, '\n                    No cues have been defined for this exercise\n                ') : null), React.createElement('div', { 'className': 'row start-xs f-300 m-10 p-5' }, React.createElement('h5', { 'className': 'm-b-5' }, 'Equipment'), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12' },
        _.map(this.props.exercise.equipments, repeatEquipment2.bind(this))
    ]), this.props.exercise.equipments.length === 0 ? React.createElement('div', {
        'className': 'col-xs-12 alert-warning text-center',
        'key': '8702'
    }, '\n                    No equipment has been defined for this exercise\n                ') : null))) : null);
}