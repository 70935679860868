import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import Dialog from 'react-toolbox/lib/dialog';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select_form';
import RadioGroup from '../../../utils/common_components/radio_group_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Switch from '../../../utils/common_components/switch_form';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { history } from '../../../redux/store';
export default function () {
    function repeatField1(field, fieldIndex) {
        return [
            field.field_type === 99 ? React.createElement('div', {
                'className': 'col-xs-12',
                'key': '17221'
            }, React.createElement('div', { 'className': 'm-t-10 text-center f-20 f-500' }, field.name), field.note !== '' ? React.createElement('div', {
                'className': 'bg-body border-body w-100 p-10 m-t-10 m-b-10',
                'style': { borderLeft: '4px solid #0288C4' },
                'key': '1964'
            }, React.createElement('p', { 'className': 'c-accent-dark' }, field.note)) : null) : null,
            field.field_type === 1 ? React.createElement('div', {
                'className': 'bg-body border-body p-l-10 p-r-10 p-t-10 m-5',
                'key': '17223'
            }, React.createElement(Field, {
                'multiline': true,
                'placeholder': 'Please enter information',
                'name': field.field_guid,
                'type': 'text',
                'component': TextInput,
                'label': field.name.toUpperCase()
            })) : null,
            field.field_type === 2 ? React.createElement('div', {
                'className': 'bg-body border-body p-l-10 p-r-10 p-t-10 m-5',
                'key': '17225'
            }, React.createElement(Field, {
                'className': 'p-b-10',
                'placeholder': 'Please enter information',
                'name': field.field_guid,
                'type': 'number',
                'component': TextInput,
                'label': field.name.toUpperCase()
            })) : null,
            field.field_type === 3 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '17227'
            }, React.createElement(Field, {
                'className': 'blue-highlight',
                'name': field.field_guid,
                'component': Select,
                'label': field.name.toUpperCase(),
                'options': field.field_choices,
                'placeholder': 'Please enter information'
            }), field.show_additional_field ? React.createElement('div', {
                'className': 'row w-100',
                'key': '3308'
            }, React.createElement(Field, {
                'className': 'col-xs-12',
                'placeholder': 'Enter any additional Info',
                'multiline': true,
                'name': field.field_guid + '_additional_value',
                'type': 'text',
                'component': TextInput
            })) : null) : null,
            field.field_type === 14 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '17229'
            }, React.createElement(Field, {
                'className': 'vertical-align w-100 row',
                'name': field.field_guid,
                'component': RadioGroup,
                'labelString': field.name.toUpperCase(),
                'options': field.field_choices,
                'colXSNum': 4,
                'placeholder': 'Please enter information'
            }), field.show_additional_field ? React.createElement('div', {
                'className': 'row w-100',
                'key': '4070'
            }, React.createElement(Field, {
                'className': 'col-xs-12',
                'placeholder': 'Enter any additional Info',
                'multiline': true,
                'name': field.field_guid + '_additional_value',
                'type': 'text',
                'component': TextInput
            })) : null) : null,
            field.field_type === 4 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '172211'
            }, React.createElement(Field, {
                'name': field.field_guid,
                'label': field.name.toUpperCase(),
                'component': Checkbox,
                'options': field.field_choices
            }), field.show_additional_field ? React.createElement('div', {
                'className': 'row w-100',
                'key': '4740'
            }, React.createElement(Field, {
                'className': 'col-xs-12',
                'placeholder': 'Enter any additional Info',
                'multiline': true,
                'name': field.field_guid + '_additional_value',
                'type': 'text',
                'component': TextInput
            })) : null) : null,
            field.field_type === 5 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '172213'
            }, React.createElement(Field, {
                'name': field.field_guid,
                'component': DatePicker,
                'label': field.name.toUpperCase()
            })) : null
        ];
    }
    return React.createElement('form', {
        'className': 'medium-labels',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row m-0' }, React.createElement('div', { 'className': 'col-xs-12 bg-accent p-5' }, React.createElement('div', { 'className': 'row p-l-5 p-r-5' }, React.createElement(Button, {
        'onClick': () => {
            history.goBack();
        },
        'className': 'small-button m-l-5 m-r-5 bg-beige',
        'label': 'Go Back',
        'icon': 'undo',
        'raised': true
    }), React.createElement('h6', { 'className': 'flex-auto' }, this.props.intake.name), React.createElement(Button, {
        'type': 'submit',
        'disabled': this.props.submitting,
        'className': 'pull-right small-button bg-success m-r-5',
        'label': 'Save',
        'icon': 'check',
        'raised': true
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 bg-white full-height p-10' },
        _.map(this.props.intake.questions, repeatField1.bind(this))
    ])));
}