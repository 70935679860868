import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Sidemenu from '../client_menu';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from '../../../core/components/not_found';
import ClientHome from '../client_home';
import ClientCheckinList from '../client_checkin_list';
import ClientWorkouts from '../client_workouts';
import ClientHabits from '../client_habits';
import ClientHealthDocuments from '../client_health_documents';
import ClientGoals from '../client_goals';
import MessageList from '../../../message/components/messages';
import MyNews from '../../../news/components/my_news';
import NewsView from '../../../news/components/news_view';
import ClientMyPlans from '../../../payment/components/client_my_plans';
import TrackProgression from '../../../program/components/progression_track';
import EngagementWorkoutPreview from '../../../engagement/components/client_engagement_workout_preview';
import PlayProgression from '../../../program/components/progression_play';
import Engagement from '../../../engagement/components/client_engagment';
import EngagementGoalEdit from '../../../engagement/components/client_engagement_goal_edit';
import EngagementList from '../../../engagement/components/client_engagement_list';
import GroupWorkoutPreview from '../../../group/components/client_group_workout_preview';
import ClientGroup from '../../../group/components/client_group_dashboard';
import ClientGroupList from '../../../group/components/client_group_list';
import ChatList from '../../../conversation/components/chat_list';
import ConversationList from '../../../conversation/components/conversation_list';
import ClientCalendarContainer from '../../../calendar/components/client_calendar_container';
import QuickStartContainer from '../quickstart_container';
import QuickStartTrack from '../quickstart_track';
import ClientSettings from '../client_settings_container';
import ClientWorkoutsAll from '../client_workouts_all';
import WorkoutView from '../workout_view';
import WorkFitContainer from '../../../dhf/workfit/components/workfit_container';
import WorkFit from '../workfit';
import { PARTNER } from '../../../core/constants/constants';
import ClientIntakes from '../client_intakes';
export default function () {
    return React.createElement('div', { 'className': 'd-flex' }, !_.includes(this.props.location.pathname, '/workfit') ? React.createElement(NavDrawer, {
        'className': 'main-drawer',
        'active': this.props.menuopen,
        'pinned': this.props.pinned,
        'onOverlayClick': this.toggleDrawerActive,
        'key': '4077'
    }, React.createElement(Sidemenu, {
        'isAuthenticated': true,
        'location': this.props.location,
        'doLogout': this.props.doLogout
    })) : null, React.createElement('div', {
        'className': `content-zone   ${ _.includes(this.props.location.pathname, '/workfit') ? '' : 'p-l-5 p-r-5' }  `,
        'style': {
            flex: '1',
            paddingTop: '55px'
        }
    }, React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/home`,
        'component': ClientHome
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workouts/:type/:id`,
        'component': ClientWorkouts
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workouts/:type/:id/all`,
        'component': ClientWorkoutsAll
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workouts/:type/:id/all/:workout_id`,
        'component': WorkoutView
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/habits/:type/:id`,
        'component': ClientHabits
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/documents/:type/:id`,
        'component': ClientHealthDocuments
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/goals/:type/:id`,
        'component': ClientGoals
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/intakes`,
        'component': ClientIntakes
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/messages`,
        'component': MessageList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/my_news`,
        'component': MyNews
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/news/view/:news_id`,
        'component': NewsView
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/my_plans`,
        'component': ClientMyPlans
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/conversation/:conversation_id`,
        'component': ChatList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/conversation`,
        'component': ConversationList
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/scheduler`,
        'component': ClientCalendarContainer
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/settings`,
        'component': ClientSettings
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/progression_play/:progression_id`,
        'component': PlayProgression
    }), React.createElement(Route, { 'component': NotFound }))));
}