// Action Creators

const fillTrackingHistory = (goal_question_id,trackingHistory) => ({
    type: 'GROUP_CLIENT_GOAL_TRACKING_HISTORY_FILL',
    goal_question_id,
    trackingHistory
});
const fillTrackingList = (trackings,paginate_info) => ({
    type: 'GROUP_CLIENT_GOAL_TRACKING_LIST',
    trackings,
    paginate_info
});

const onClientTreatmentAdd = (client_workouts,client_habits,treatment_started_date,client_id) => ({
    type: 'GROUP_CLIENT_ADD_TREATMENT',
    client_workouts,
    client_habits,
    treatment_started_date,
    client_id
});

const groupClientRemoval = (client_id) => ({
    type: 'GROUP_CLIENT_REMOVAL',
    client_id,
});
const groupClientsAdd = (clients) => ({
    type: 'GROUP_CLIENT_ADD',
    clients,
});

const cleanModule = () => ({
    type: 'GROUP_CLIENT_GOAL_TRACKING_CLEANUP',
});

export default {
    fillTrackingList,
    fillTrackingHistory,
    groupClientRemoval,
    groupClientsAdd,
    onClientTreatmentAdd,
    cleanModule,
};
