import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import { Dialog } from 'react-toolbox/lib/dialog';
import Button from 'react-toolbox/lib/button';
import ClientEngagementTreatmentItem from '../../../engagement/components/client_engagement_treatment_item';
import Avatar from '../../../utils/common_components/profile_picture';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { history } from '../../../redux/store';
import MailComposer from '../../../utils/common_components/mail_composer';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import WelcomeVideo from '../welcome_video';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { PARTNER } from '../../../core/constants/constants';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    function repeatNews1(news, index) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5 pointer',
            'key': 'news' + index
        }, React.createElement('div', {
            'onClick': () => {
                this.previewNews(news);
            }
        }, React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '40px' }
        }, React.createElement('div', { 'className': 'col-xs-12 f-16' }, React.createElement(TooltipWrapper, {
            'tooltip': news.title,
            'className': 'truncate'
        }, '\n                            ', news.title, '\n                        ')), news.business_partner ? React.createElement('div', {
            'className': 'col-xs-12 m-5 f-12 f-300 c-primary text-left',
            'key': '7268'
        }, React.createElement('p', {}, 'Author: ', news.business_partner.name)) : null, !news.business_partner ? React.createElement('div', {
            'className': 'col-xs-12 m-5 f-12 f-300 c-primary text-left',
            'key': '7482'
        }, React.createElement('p', {}, 'Author: ', news.practice.name)) : null), React.createElement('div', { 'className': 'box-300-225 m-t-5' }, news.cover_media && news.cover_media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225',
            'style': { backgroundImage: 'url(' + encodeURI(news.cover_media.thumbnail_url) + ')' },
            'onClick': () => {
                this.previewImage(media);
            },
            'key': '7776'
        }) : null, news.cover_media && news.cover_media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225',
            'key': '8075'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'gomotive_video' + news.cover_media.guid,
            'videoList': [news.cover_media.guid]
        })) : null, !news.cover_media ? React.createElement('div', {
            'className': 'p-12',
            'style': {
                height: '205px',
                overflow: 'hidden'
            },
            'key': '8390'
        }, '\n                        ', renderHTML(news.content), '\n                    ') : null)));
    }
    function repeatPractitioner2(practitioner, practitionerIndex) {
        return React.createElement('div', {
            'className': 'panel no-box-shadow',
            'key': practitionerIndex
        }, React.createElement('div', { 'className': `panel-heading vertical-align p-0  ${ this.props.practitioners.length > 1 ? 'panel-bottom-border' : '' }` }, React.createElement(Avatar, {
            'size': 65,
            'user': practitioner,
            'profile_type': 'circle'
        }), React.createElement('h5', { 'className': 'flex-auto m-l-5' }, practitioner.name), practitioner.id !== this.props.user.id ? React.createElement(Button, {
            'icon': 'chat',
            'className': 'bg-primary  m-r-5',
            'floating': true,
            'mini': true,
            'onClick': () => {
                this.chatWithPractitioner(practitioner);
            },
            'key': '9286'
        }) : null, React.createElement(Button, {
            'icon': 'email',
            'className': 'bg-primary',
            'floating': true,
            'mini': true,
            'onClick': () => {
                this.showMailComposer(practitioner);
            }
        })));
    }
    function repeatProgram3(program, programIndex) {
        return React.createElement('div', {
            'className': 'panel no-box-shadow',
            'key': programIndex
        }, React.createElement('div', {
            'onClick': () => {
                this.loadProgramSelectedContent(program);
            },
            'className': 'pointer p-t-5 p-b-5 ' + `panel-heading vertical-align p-0  ${ this.props.v2_program_list.length > 1 ? 'panel-bottom-border' : '' }`
        }, program.type === 'group' ? React.createElement('i', {
            'className': 'gomotive-icons c-black m-r-10 f-25 gm-group',
            'key': '10246'
        }) : null, program.type === 'engagement' ? React.createElement('i', {
            'className': 'gomotive-icons c-black m-r-10 f-25 gm-game-plan',
            'key': '10359'
        }) : null, React.createElement('h5', { 'className': 'flex-auto m-l-5' }, program.details.name)));
    }
    return React.createElement('div', {}, PARTNER.app_type === 'gi' ? React.createElement('div', {
        'className': 'row start-xs m-30 client-homepage-images-middle',
        'key': '1783'
    }, this.props.v2_workout_image_url !== null ? React.createElement('div', {
        'className': 'col-md-6 col-sm-6 col-xs-6 pointer',
        'key': '1888'
    }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': this.props.v2_workout_image_url,
        'onClick': this.doWorkouts,
        'className': 'img-responsive',
        'style': {
            height: '350px',
            maxHeight: '350px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }), React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '30px' }
    }, 'MY WORKOUTS'))) : null, this.props.v2_workout_image_url === null ? React.createElement('div', {
        'className': 'col-md-6 col-sm-6 col-xs-6',
        'key': '2363'
    }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': '/site_media/static/images/gi_clients.jpeg',
        'onClick': this.doWorkouts,
        'className': 'img-responsive',
        'style': {
            height: '350px',
            maxHeight: '350px'
        }
    }), React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '30px' }
    }, 'MY WORKOUTS'))) : null, React.createElement('div', { 'className': 'col-md-6 col-sm-6 col-xs-6' }, React.createElement('div', {
        'className': 'image-container pointer',
        'onClick': this.doConnect
    }, React.createElement('img', {
        'src': '/site_media/static/images/gi_connect.jpeg',
        'className': 'img-responsive',
        'style': {
            height: '350px',
            maxHeight: '350px'
        }
    }), React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '30px' }
    }, 'CONNECT')))) : null, PARTNER.app_type === 'dhf' || PARTNER.app_type === 'tri' ? React.createElement('div', {
        'className': 'row start-xs m-30 client-homepage-images-middle',
        'key': '3209'
    }, this.props.v2_workout_image_url !== null ? React.createElement('div', {
        'className': 'col-md-4 col-sm-4 col-xs-4 pointer',
        'key': '3345'
    }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': this.props.v2_workout_image_url,
        'onClick': this.doWorkouts,
        'className': 'img-responsive',
        'style': {
            maxHeight: '270px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }), React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '24px' }
    }, 'MY WORKOUTS'))) : null, this.props.v2_workout_image_url === null ? React.createElement('div', {
        'className': 'col-md-4 col-sm-4 col-xs-4',
        'key': '3805'
    }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': '/site_media/static/images/dhf_clients.jpeg',
        'onClick': this.doWorkouts,
        'className': 'img-responsive',
        'style': { maxHeight: '270px' }
    }), React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '24px' }
    }, 'MY WORKOUTS'))) : null, React.createElement('div', { 'className': 'col-md-4 col-sm-4 col-xs-4' }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': PARTNER.app_type === 'tri' ? '/site_media/static/images/tri_habits.jpeg' : '/site_media/static/images/dhf_habits.jpeg',
        'onClick': this.doHabits,
        'className': 'img-responsive',
        'style': { maxHeight: '270px' }
    }), React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '24px' }
    }, 'MY HABITS'))), React.createElement('div', { 'className': 'col-md-4 col-sm-4 col-xs-4' }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': PARTNER.app_type === 'tri' ? '/site_media/static/images/tri_guidance.jpeg' : '/site_media/static/images/dhf_nutrition.jpeg',
        'onClick': this.doHealthDocuments,
        'className': 'img-responsive',
        'style': { maxHeight: '270px' }
    }), React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '24px' }
    }, 'MY NUTRITION')))) : null, PARTNER.app_type === 'dhf' || PARTNER.app_type === 'tri' ? React.createElement('div', {
        'className': 'row start-xs m-30 m-t-20 m-b-20',
        'key': '5171'
    }, React.createElement('div', { 'className': 'col-xs-12 text-center' }, React.createElement('h3', {}, ' \n                TRACK\n            ')), React.createElement('div', {
        'className': 'col-xs-6 pointer',
        'onClick': this.doGoals
    }, React.createElement('div', {
        'className': 'box-shadow m-10 bg-accent pull-right',
        'style': {
            width: '250px',
            minHeight: '250px',
            borderRadius: '50%'
        }
    }, React.createElement('div', {
        'className': 'text-center',
        'style': { paddingTop: '100px' }
    }, React.createElement('div', { 'className': 'f-25' }, 'GOAL')))), React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('div', { 'className': 'm-10' }, React.createElement('div', { 'className': 'text-center' }, React.createElement(TooltipWrapper, {
        'className': 'f-18 truncate pre-wrap',
        'tooltip': this.state.weekly_movement_score_text,
        'tooltipPosition': 'top'
    }, React.createElement('div', {
        'className': 'radial-chart',
        'style': {
            height: '250px',
            width: '250px'
        }
    })))))) : null, this.props.newsList.length > 0 ? React.createElement('div', {
        'className': 'row start-xs m-t-20 m-b-20 m-l-20',
        'key': '6279'
    }, React.createElement('div', { 'className': 'col-xs-11 text-center' }, React.createElement('h3', {}, ' \n                Discover Posts\n            ')), React.createElement('div', {
        'className': 'col-xs-1 pull-right p-t-10 c-success pointer',
        'onClick': () => {
            history.push('/client/my_news');
        }
    }, '\n            View All\n        ')) : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row center-xs m-30' },
        _.map(this.props.newsList, repeatNews1.bind(this))
    ]), React.createElement.apply(this, [
        Dialog,
        {
            'active': this.state.showConnect,
            'title': 'Connect',
            'onEscKeyDown': this.closeConnect,
            'onOverlayClick': this.closeConnect
        },
        _.map(this.props.practitioners, repeatPractitioner2.bind(this))
    ]), React.createElement.apply(this, [
        Dialog,
        {
            'active': this.state.showProgramSelectionDialog,
            'title': 'Select Program',
            'onEscKeyDown': this.closeProgramSelectionDialog,
            'onOverlayClick': this.closeProgramSelectionDialog
        },
        _.map(this.props.v2_program_list, repeatProgram3.bind(this))
    ]), React.createElement(Dialog, {
        'active': this.state.mailComposerVisible,
        'className': 'dialog-title-center',
        'title': this.state.currentlyMailingPractitioner ? 'Message ' + this.state.currentlyMailingPractitioner.name : '',
        'onEscKeyDown': this.hideMailComposer,
        'onOverlayClick': this.hideMailComposer
    }, React.createElement(MailComposer, {
        'hideEmail': true,
        'onSubmit': this.onMailComposerSubmit
    })), this.state.welcome_video ? React.createElement(WelcomeVideo, {
        'onCloseHandle': this.closeWelcomeVideo,
        'welcome_video': this.state.welcome_video,
        'key': '11018'
    }) : null);
}