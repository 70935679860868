import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import MoveVideos from '../../../move/components/move_videos.jsx';
import Coaches from '../../../move/components/coach_list.jsx';
import CoachEdit from '../../../move/components/coach_edit.jsx';
import Challenges from '../../../move/components/challenge_list.jsx';
import ChallengeEdit from '../../../move/components/challenge_edit.jsx';
import ChallengeWorkouts from '../../../move/components/challenge_workouts.jsx';
import ChallengeWorkoutEdit from '../../../move/components/challenge_workout_edit.jsx';
import HomefitUsageReport from '../../../move/components/homefit_usage_report.jsx';
import WebinarList from '../../../move/components/webinar_list.jsx';
import WebinarListAdd from '../../../move/components/webinar_add.jsx';
import WebinarListEdit from '../../../move/components/webinar_edit.jsx';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu m-t-20'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/move/coaches`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Coach')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/move/workouts`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Workouts')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/move/challenges`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Challenges')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/move/usage_report`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Usage Report')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/move/webinar`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Webinar'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/coaches/edit/:coach_id`,
        'component': CoachEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/coaches/add`,
        'component': CoachEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/coaches`,
        'component': Coaches
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workouts`,
        'component': MoveVideos
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/usage_report`,
        'component': HomefitUsageReport
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/challenges/edit/:challenge_id`,
        'component': ChallengeEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/challenges/add`,
        'component': ChallengeEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/challenges`,
        'component': Challenges
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/challenge/:challenge_id/workout/add`,
        'component': ChallengeWorkoutEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/challenge/:challenge_id/workout/edit/:challenge_workout_id`,
        'component': ChallengeWorkoutEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/challenge/:challenge_id/workouts`,
        'component': ChallengeWorkouts
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/webinar`,
        'component': WebinarList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/webinar/add`,
        'component': WebinarListAdd
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/webinar/:webinar_id/edit`,
        'component': WebinarListEdit
    })));
}