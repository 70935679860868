let initialState = {
    habits: [],
    habit: {},
    supportingData: {},
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "GROUP_HABIT_LIST_FILL":
            return Object.assign({}, state, {
                habits: action.habits
            });
        case "GROUP_HABIT_FILL":
            return Object.assign({}, state, {
                habit: action.habit,
                supportingData: action.supportingData,
            });
        case "GROUP_HABIT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
