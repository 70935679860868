import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Select from '../../../utils/common_components/select';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement('form', {
        'className': 'col-xs-12 medium-labels',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'm-t-10',
        'style': { background: '#fff' },
        'name': 'goal_tracking_day',
        'options': this.state.goal_tracking_day_options,
        'component': Select,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'bg-success',
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.doClose,
        'label': 'Close',
        'className': 'text-capital bg-accent m-l-5',
        'type': 'button',
        'raised': true
    })))));
}