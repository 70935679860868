import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import planActionCreator from '../actions/plan_subscribe_action';

const getPaymentSubscriptionDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/get_payment_plans";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(planActionCreator.planSubscriptListFill(
                response.data
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const subscribeToPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/subscribe_payment_plan";
        let config = {};
        config["headers"] = {
            'USER_AGENT': navigator.userAgent,
            'Accept': "application/json",
            'Content-type': "application/json",
        };
        if (_.has(params,"token")){
            config["headers"]["STRIPETOKEN"] = params.token;
        }
        let updated_params = Object.assign({},params);
        delete updated_params.token;
        return Axios.post(url, updated_params,config).then((response) => {
            dispatch(hideProgress());
            dispatch(planActionCreator.planSubscriptListFill(
                response.data
            ));
            Utils.showSuccessAlert("Successfully subscribed to plan");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const unSubscribeToPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/unsubscribe_payment_plan";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(planActionCreator.planSubscriptListFill(
                response.data
            ));
            Utils.showSuccessAlert("Successfully unsubscribed from plan");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getPracticePaymentSubscriptionDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_purchased_payment_plans";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            // dispatch(planActionCreator.planSubscriptListFill(
            //     response.data
            // ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getPaymentSubscriptionDetails,
    subscribeToPlan,
    unSubscribeToPlan,
    getPracticePaymentSubscriptionDetails,
};