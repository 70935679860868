const setProgramType = (displayUCase, displayLCase, typeParam, programType) => ({
    type: 'PROGRAM_TYPE_SET',
    displayUCase,
    displayLCase,
    typeParam,
    programType,
});
const setMode = (mode) => ({
    type: 'PROGRAM_MODE_SET',
    mode,
});
const setWorkoutTarget = (workoutTargetType, workoutTargetId) => ({
    type: 'PROGRAM_WORKOUT_TARGET_SET',
    workoutTargetType,
    workoutTargetId,
});
const cleanModule = () => ({
    type: 'PROGRAM_CLEANUP',
});
export default {
    setProgramType,
    setMode,
    setWorkoutTarget,
    cleanModule,
};