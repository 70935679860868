import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { NavLink } from 'react-router-dom';
import { Select as SelectNew } from 'react-select';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import { Selector } from '../content_video_edit';
import { tetheredSelectAsynFunction as AsyncSelect } from '../../../utils/common_components/select_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from '../../../utils/common_components/dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Input from 'react-toolbox/lib/input';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
export default function () {
    function repeatAssociated_content1(associated_content, index) {
        return React.createElement('div', {
            'key': 'associated_list' + index,
            'className': 'associated-content'
        }, associated_content ? React.createElement('div', {
            'className': 'associated-title',
            'key': '12978'
        }, React.createElement('div', {
            'className': 'image-container',
            'style': {
                backgroundImage: 'url(' + associated_content.thumbnail_url + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }
        }), React.createElement('div', { 'className': 'text' }, '\n\n                                    ', associated_content.title, '\n                                ')) : null);
    }
    function repeatAssociated_workout2(associated_workout, index) {
        return React.createElement('div', {
            'key': 'associated_list' + index,
            'className': 'associated-content'
        }, associated_workout ? React.createElement('div', {
            'className': 'associated-title',
            'key': '16278'
        }, React.createElement('div', {
            'className': 'image-container',
            'style': {
                backgroundImage: 'url(' + (associated_workout.thumbnail_url || associated_workout.image_url) + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }
        }), React.createElement('div', { 'className': 'text' }, '\n                                    ', associated_workout.name, '\n                                    '), React.createElement(IconButton, {
            'icon': 'close',
            'tooltip': 'Remove',
            'style': { cursor: 'pointer' },
            'onClick': () => {
                this.removeWorkoutItem(associated_workout.id);
            }
        })) : null);
    }
    return React.createElement('div', { 'className': 'row m-b-5' }, React.createElement(NavLink, {
        'to': '#',
        'className': 'm-t-20 m-b-10 d-flex d-flex back-btn align-center c-grey',
        'onClick': this.cancel
    }, '\n        Back\n    '), React.createElement(Card, { 'className': 'content-edit no-box-shadow m-r-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', {
        'className': 'add-media d-flex no-box-shadow',
        'onSubmit': this.props.properties.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'media-info p-b-36' }, React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(Card, { 'className': 'no-box-shadow bg-white' }, React.createElement(CardTitle, {
        'title': (this.props.properties.match.params.content_id ? 'Edit ' : 'Add new ') + this.props.properties.match.params.content_type,
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement(CardText, { 'className': 'w-100 p-t-20 p-0' }, React.createElement(Field, {
        'className': 'w-100',
        'label': 'Title',
        'name': 'title',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }), !this.props.properties.match.params.content_id ? React.createElement('div', {
        'className': 'm-b-20',
        'key': '3256'
    }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'label': 'Content',
        'className': 'w-100',
        'name': 'content',
        'type': 'text',
        'component': RichTextEditor,
        'hint': 'Description',
        'multiline': true
    })) : null, this.props.properties.initialValues.content ? React.createElement('div', {
        'className': 'm-b-20',
        'key': '3596'
    }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'label': 'Content',
        'className': 'w-100',
        'name': 'content',
        'type': 'text',
        'component': RichTextEditor,
        'hint': 'Description',
        'multiline': true + '/'
    })) : null), this.props.properties.match.params.content_type === 'video' || this.props.properties.match.params.content_type === 'mini_course' ? React.createElement(CardTitle, {
        'title': 'Media',
        'className': 'c-black  form-subtitle f-18 w-100',
        'key': '3968'
    }) : null, this.props.content === 'homefit_content' && this.props.properties.match.params.content_type === 'podcast' ? React.createElement(CardTitle, {
        'title': 'Media',
        'className': 'c-black  form-subtitle f-18 w-100',
        'key': '4203'
    }) : null, this.props.properties.match.params.content_type === 'video' || this.props.properties.match.params.content_type === 'mini_course' ? React.createElement('div', {
        'className': 'd-flex p-t-30 p-b-30',
        'key': '4417'
    }, React.createElement('div', { 'className': 'preview-container d-flex w-100' }, this.props.properties.video_guid ? React.createElement('div', {
        'className': 'm-b-20 w-60',
        'key': '4711'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-player',
        'handleUploadProcessing': true,
        'videoList': [this.props.properties.video_guid],
        'onPlayerInitialized': player => {
            this.player = player;
        }
    })) : null, this.props.content === 'homefit_content' ? React.createElement('div', { 'key': '5349' }, this.props.properties.match.params.content_type === 'video' || this.props.properties.match.params.content_type === 'mini_course' ? React.createElement('div', {
        'className': 'row video-btn-container',
        'key': '5446'
    }, React.createElement(VimeoUploader, {
        'className': 'col w-100 m-l-4 add-media-btn',
        'onUploadCompleted': this.onVideoUploaded,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'name': 'add_media',
        'buttonText': this.props.properties.video_guid ? 'Change Video' : 'Add Media'
    }), this.props.properties.video_guid ? React.createElement(Button, {
        'onClick': this.deleteVideo,
        'label': 'Delete Video',
        'className': 'm-l-6 bg-danger-light',
        'primary': true,
        'raised': true,
        'key': '6379'
    }) : null) : null) : null)) : null, this.props.content === 'homefit_content' && this.props.properties.match.params.content_type === 'video' ? React.createElement('div', {
        'className': 'center-xs w-48',
        'key': '6990'
    }, React.createElement(CardTitle, {
        'title': 'Thumbnail',
        'className': 'c-black f-14'
    }), React.createElement('div', { 'className': 'f-12 c-grey m-t-8 m-b-8 text-left' }, '\n                                            Select Play on the video and pause on the desired frame.\n                                            Then select "Update Thumbnail".\n                                        '), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, this.props.properties.video_thumbnail_url ? React.createElement(Card, {
        'className': 'bg-white p-0 m-l-5 m-r-5 m-b-20',
        'key': '7709'
    }, React.createElement('div', {
        'className': 'thumbnail h-200',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.properties.video_thumbnail_url) + ')' }
    })) : null, React.createElement(Button, {
        'className': 'm-b-20 bg-success-red p-l-18 p-r-18 f-14',
        'label': 'Update Thumbnail',
        'onClick': this.captureThumbnail,
        'primary': true,
        'raised': true
    })))) : null, this.props.content === 'homefit_content' && this.props.properties.match.params.content_type === 'podcast' ? React.createElement('div', {
        'className': 'd-flex p-t-30 p-b-30',
        'key': '8712'
    }, React.createElement('div', {
        'className': 'preview-container d-flex w-100',
        'style': { flexDirection: 'column' }
    }, this.props.properties.is_audio_uploaded ? React.createElement('div', {
        'className': 'associated-content w-50 m-b-20',
        'key': '9014'
    }, React.createElement('div', { 'className': 'associated-title podcast-preview d-flex align-center flex-sb' }, React.createElement('div', {
        'className': 'podcast-image-container m-r-15',
        'style': {
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    }), this.props.properties.audio_name ? React.createElement('div', {
        'className': 'text w-80',
        'key': '9578'
    }, '\n                                                    ', this.props.properties.audio_name, '\n                                                ') : null, !this.props.properties.audio_name ? React.createElement('div', {
        'className': 'text w-80',
        'key': '9835'
    }, '\n                                                    ', this.props.properties.initialValues.news_media[0].file_name, '\n                                                ') : null, React.createElement(IconButton, {
        'icon': 'close',
        'tooltip': 'Remove',
        'style': { cursor: 'pointer' },
        'onClick': this.onCancelAudio
    }))) : null, React.createElement(Dropzone, {
        'className': 'dummy-suppress-warning w-50',
        'multiple': false,
        'accept': 'audio/*',
        'onDrop': this.uploadAudio
    }, React.createElement(Button, {
        'className': 'add-media-btn',
        'label': this.props.properties.is_audio_uploaded ? 'Change Audio' : 'Add Audio',
        'primary': true,
        'raised': true
    })))) : null)), React.createElement.apply(this, [
        Card,
        { 'className': 'related-content box-shadow p-t-24 p-b-36 p-l-24 p-r-24' },
        React.createElement(CardTitle, {
            'title': 'Associated Content (Optional)',
            'className': 'c-black form-subtitle m-b-28 f-18 w-100'
        }),
        React.createElement('div', { 'className': 'p-relative search-box no-box-shadow m-b-20 p-5  vertical-align' }, React.createElement('div', { 'className': 'w-50 m-r-15' }, React.createElement(Field, {
            'className': 'w-100 p-t-0',
            'name': 'associated_category',
            'component': Select,
            'label': 'Category',
            'options': this.props.properties.categories,
            'valueKey': 'db_value',
            'labelKey': 'label',
            'onChange': this.onSearch
        })), React.createElement('div', { 'className': 'associated-item p-relative w-50' }, React.createElement('span', { 'className': 'placeholder-text' }, 'Search by title'), React.createElement(Field, {
            'component': Select,
            'multi': true,
            'className': 'flex-auto p-0 m-r-5',
            'icon': 'search',
            'onChange': this.onChangeContent,
            'options': this.props.content === 'gi_content' ? this.props.properties.associatedContentOptions : this.props.properties.homefitAssociatedContentOptions,
            'valueKey': 'id',
            'labelKey': 'title',
            'type': 'text',
            'name': 'news_associated'
        }))),
        _.map(this.props.properties.news_associated, repeatAssociated_content1.bind(this))
    ]), this.props.properties.user.gi_isadmin ? React.createElement.apply(this, [
        Card,
        {
            'className': 'related-content box-shadow p-t-24 m-t-10 p-b-36 p-l-24 p-r-24',
            'key': '13872'
        },
        React.createElement(CardTitle, {
            'title': this.props.content === 'gi_content' ? 'Associated Workout Templates (Optional)' : 'Associated Workouts (Optional)',
            'className': 'c-black form-subtitle m-b-28 f-18 w-100'
        }),
        React.createElement('div', { 'className': 'p-relative search-box no-box-shadow m-b-20 p-5  vertical-align' }, this.props.content === 'gi_content' ? React.createElement('div', {
            'className': 'w-50 m-r-15',
            'key': '14336'
        }, React.createElement(Field, {
            'className': 'w-100 p-t-0',
            'name': 'associated_workout_category',
            'component': Select,
            'label': 'Category',
            'options': this.props.properties.workoutCategories,
            'onChange': this.selectCategory,
            'valueKey': 'id',
            'labelKey': 'name'
        })) : null, this.props.content === 'homefit_content' ? React.createElement('div', {
            'className': 'w-50 m-r-15',
            'key': '14758'
        }, React.createElement(Field, {
            'className': 'w-100 p-t-0',
            'name': 'associated_workout_category',
            'component': Select,
            'label': 'Category',
            'options': this.props.properties.homefitCategories,
            'onChange': this.selectCategory,
            'valueKey': 'db_value',
            'labelKey': 'label'
        })) : null, React.createElement('div', { 'className': 'associated-item p-relative w-50' }, React.createElement('span', { 'className': 'placeholder-text' }, 'Search by title'), React.createElement(Field, {
            'component': Select,
            'multi': true,
            'className': 'flex-auto p-0 m-r-5',
            'icon': 'search',
            'onChange': this.onChangeWorkout,
            'options': this.props.content === 'gi_content' ? this.props.properties.associatedWorkouts : this.props.properties.homefitAssociatedWorkoutsOptions,
            'valueKey': 'id',
            'labelKey': 'name',
            'type': 'text',
            'name': 'news_workout_templates'
        }))),
        _.map(this.props.properties.news_workout_templates, repeatAssociated_workout2.bind(this))
    ]) : null), React.createElement('div', { 'className': 'publish-info' }, this.props.properties.match.params.content_type === 'blog' || this.props.properties.match.params.content_type === 'podcast' ? React.createElement('div', {
        'className': 'cover-image box-shadow bg-white m-b-16 p-t-24 p-l-24 p-r-24 p-b-36',
        'key': '17374'
    }, React.createElement(CardTitle, {
        'title': 'Cover Image',
        'className': 'c-black  form-subtitle f-18 w-100'
    }), this.props.properties.news.news_media ? React.createElement('div', { 'key': '17712' }, this.props.properties.news.news_media || this.props.properties.is_image_uploaded ? React.createElement('div', {
        'className': 'associated-content',
        'key': '17792'
    }, this.props.properties.image_url ? React.createElement('div', {
        'className': 'associated-title',
        'key': '17948'
    }, React.createElement('div', {
        'className': 'image-container',
        'style': {
            backgroundImage: 'url(' + this.props.properties.image_url + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    }), this.props.properties.image_name ? React.createElement('div', {
        'className': 'text',
        'key': '18320'
    }, '\n                                        ', this.props.properties.image_name, '\n                                    ') : null, !this.props.properties.image_name ? React.createElement('div', {
        'className': 'text',
        'key': '18536'
    }, '\n                                        cover-image\n                                    ') : null) : null) : null) : null, this.props.content === 'homefit_content' ? React.createElement(Dropzone, {
        'className': 'dummy-suppress-warning',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadCoverImage,
        'key': '18985'
    }, React.createElement(Button, {
        'className': 'add-media-btn',
        'label': this.props.properties.is_image_uploaded ? 'Change Image' : 'Add Image',
        'primary': true,
        'raised': true
    })) : null) : null, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'category',
        'component': Select,
        'label': 'Category',
        'options': this.props.properties.categories,
        'valueKey': 'db_value',
        'labelKey': 'label'
    })), React.createElement('div', { 'className': 'date-picker p-relative' }, React.createElement(Field, {
        'name': 'publish_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'Publish Date'
    })), React.createElement('div', { 'className': 'time-picker p-relative' }, React.createElement(Field, {
        'name': 'publish_time',
        'component': TimePicker,
        'label': 'Publish Time'
    })), React.createElement('div', { 'className': this.props.content === 'homefit_content' ? 'row submit-buttons end-xs m-t-30 m-b-10 m-r-10 middle-xs' : 'row submit-buttons end-xs m-b-10 m-r-10 middle-xs' }, this.props.content === 'homefit_content' ? React.createElement(Button, {
        'label': 'Save as Draft',
        'disabled': this.props.properties.submitting,
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'raised': true,
        'onClick': this.saveAsDraft,
        'key': '20370'
    }) : null, React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.properties.submitting,
        'className': 'm-l-10 bg-success w-100',
        'raised': true,
        'onClick': this.saveAndPublish
    }))))))));
}