import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/quickstart_available_view";
import store, { history } from '../../redux/store';
import Utils from '../../utils/utils';
import { acquireQuickStart } from '../../redux/client/network/quickstart_network';
import { getClientQuickStart } from '../../redux/client/network/quickstart_network';

class QuickStartAvailableView extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.acquire = this.acquire.bind(this);
    };

    acquire() {
        this.props.acquireQuickStart({ quickstart_id: this.props.quickStart.id }).then(() => {
            history.goBack();
            this.props.getClientQuickStart();
        }).catch(() => { });
    }

    render() {
        if (_.isEmpty(this.props.quickStart)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        acquireQuickStart,
        getClientQuickStart,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    let quickStart;
    if (_.isEmpty(ownProps.quickStart)) {
        let quickStarts = _.filter(state.client.quickStartReducer.availableQuickStarts, q => {
            return q.id === parseInt(ownProps.match.params.id)
        });
        if (quickStarts.length === 0) {
            quickStart = null;
        } else {
            quickStart = quickStarts[0];
        }
    } else {
        quickStart = ownProps.quickStart;
    }
    return {
        ...ownProps,
        quickStart: quickStart,
    };
}
const QuickStartAvailableViewConnected = connect(mapStateToProps, mapDispatchToProps)(QuickStartAvailableView);
export default QuickStartAvailableViewConnected;
