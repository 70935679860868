import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import ButtonDefault from 'react-toolbox/lib/button';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    function repeatHabit1(habit, habitIndex) {
        return React.createElement(Tr, { 'key': 'habit' + habitIndex }, React.createElement(Td, {
            'column': 'name',
            'className': 'w-20',
            'value': habit.name
        }, React.createElement('span', {}, habit.name)), React.createElement(Td, {
            'column': 'note',
            'className': 'truncate-wrap',
            'style': { maxWidth: '350px' },
            'value': habit.text
        }, React.createElement(TooltipWrapper, {
            'tooltip': habit.text,
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, ' ', habit.text))), React.createElement(Td, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.__schedule_type)), React.createElement(Td, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.duration_count, ' ', habit.__duration_type)), React.createElement(Td, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, habit.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '4520' }, habit.is_published ? React.createElement('span', { 'key': '5007' }, 'Published') : null, !habit.is_published ? React.createElement('span', { 'key': '5081' }, 'Not Published') : null) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container edit-btn-small justify-center' }, habit.practice.id === this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'primary': true,
            'tooltip': 'Edit',
            'className': 'm-r-12 goal-template-action',
            'onClick': () => {
                this.editHabitTemplate(habit.id);
            },
            'key': '5436'
        }, 'Edit') : null, habit.practice.id === this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'ripple': true,
            'raised': true,
            'primary': true,
            'tooltip': 'Delete template',
            'onClick': () => {
                this.deleteHabitTemplate(habit.id);
            },
            'className': 'delete-red-icon goal-template-action-delete small-icon-button goal-template-action delete-button',
            'key': '6341'
        }) : null, habit.practice.id !== this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'onClick': () => {
                this.editHabitTemplate(habit.id);
            },
            'className': 'goal-template-action m-r-12',
            'raised': true,
            'ripple': true,
            'primary': true,
            'key': '6920'
        }, 'Preview') : null)));
    }
    return React.createElement('div', {}, React.createElement('div', { 'className': 'm-t-15 page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Templates'), React.createElement('h2', { 'className': 'page-title with-subtitle' }, 'Habit')), React.createElement(Button, {
        'className': 'floating-add-button  red-add-button m-r-5',
        'style': { top: '100px' },
        'onClick': () => {
            this.addHabitTemplate();
        }
    }, '\n            Add new\n        ')), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-24',
            'id': 'table',
            'noDataText': 'Create your own habit template by clicking on + icon',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Title')), React.createElement(Th, { 'column': 'note' }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Frequency')), React.createElement(Th, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Duration')), React.createElement(Th, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Is Published?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Action'))),
        _.map(this.props.habit_templates, repeatHabit1.bind(this))
    ]));
}