import * as React from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-toolbox/lib/date_picker';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import HelpTip from '../../../utils/common_components/help_tip';
import ProgressionView from '../../../program/components/progression_view';
import EngagementHabitView from '../../../engagement/components/client_engagement_habit_view';
import GroupHabitView from '../../../group/components/client_group_habit_view';
import { Link } from 'react-router-dom';
import { history } from '../../../redux/store';
export default function () {
    function repeatProgression1(engagement, engagementIndex, progression, progressionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': progressionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, this.state.type === 'start' ? React.createElement(Button, {
            'onClick': () => {
                this.playWorkout(progression);
            },
            'tooltip': 'Play Workout',
            'className': 'bg-success  m-t-20',
            'icon': 'gm-workouts',
            'gomotive': true,
            'floating': true,
            'key': '7601'
        }) : null, this.state.type !== 'start' ? React.createElement('i', {
            'className': 'gomotive-icons m-r-10 p-t-10 f-35 gm-workouts',
            'key': '7890'
        }) : null), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(ProgressionView, _.assign({}, {
            'display_workout_name': true,
            'display_schedule_type_info': true,
            'track_date': this.state.selectedDateString,
            'progression': progression,
            'showTracking': true,
            'trackProgressionCallback': this.trackProgressionCallback
        }, this.props))));
    }
    function repeatHabit2(engagement, engagementIndex, habit, habitIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': habitIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-habits' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(EngagementHabitView, {
            'habit': habit,
            'trackHabitProgressCallback': this.trackEngagementHabitCallback
        })));
    }
    function repeatAttachment3(engagement, engagementIndex, nutrition, nutritionIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('i', { 'className': 'material-icons vertical-middle f-18' }, 'insert_drive_file'), React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatNutrition4(engagement, engagementIndex, nutrition, nutritionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': nutritionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-nutrition' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'panel w-100' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, nutrition.name), React.createElement('div', { 'className': 'panel-subtitle pre-wrap' }, nutrition.description)), React.createElement('div', { 'className': 'panel-body  vertical-align' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(nutrition.attachments, repeatAttachment3.bind(this, engagement, engagementIndex, nutrition, nutritionIndex))
        ])))));
    }
    function repeatAttachment5(engagement, engagementIndex, guidance, guidanceIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('i', { 'className': 'material-icons vertical-middle f-18' }, 'insert_drive_file'), React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatGuidance6(engagement, engagementIndex, guidance, guidanceIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': guidanceIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-guidance' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'panel w-100' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, guidance.name), React.createElement('div', { 'className': 'panel-subtitle pre-wrap' }, guidance.description)), React.createElement('div', { 'className': 'panel-body  vertical-align' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(guidance.attachments, repeatAttachment5.bind(this, engagement, engagementIndex, guidance, guidanceIndex))
        ])))));
    }
    function repeatEngagement7(engagement, engagementIndex) {
        return React.createElement('div', { 'key': engagementIndex }, this.state.fetch_type === 'all' ? React.createElement('div', {
            'className': 'row vertical-align m-b-15',
            'key': '2481'
        }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-40 m-r-10 gm-game-plan' }), React.createElement('h4', {
            'className': 'di-block flex-auto',
            'style': { width: 'auto' }
        }, engagement.name, ' ')) : null, this.state.type === 'start' && engagement.action_type === 'goal_selection' ? [React.createElement('div', {
                'className': 'row',
                'key': '27491'
            }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-goal' })), React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' }
            }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Goal Selection'), React.createElement('div', { 'className': 'panel-subtitle' }, engagement.mobile_display_text)), React.createElement('div', { 'className': 'panel-body' }, React.createElement(Button, {
                'onClick': () => {
                    history.push('/client/engagement/' + engagement.id);
                },
                'className': 'bg-success',
                'icon': 'add',
                'label': 'Add Goal',
                'tooltip': 'Click to select a goal for ' + engagement.name,
                'raised': true,
                'primary': true
            })))))] : null, this.state.type === 'start' && engagement.action_type === 'welcome_video' ? [React.createElement('div', {
                'className': 'row',
                'key': '38091'
            }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-video' })), React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' }
            }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Welcome Video'), React.createElement('div', { 'className': 'panel-subtitle' }, engagement.mobile_display_text)), React.createElement('div', { 'className': 'panel-body' }, React.createElement(Button, {
                'onClick': () => {
                    history.push('/client/engagement/' + engagement.id);
                },
                'className': 'bg-success',
                'icon': 'play_circle_outline',
                'label': 'View Welcome Video',
                'tooltip': 'Click to view the welcome message from your practitioner ' + engagement.details.practitioner.name,
                'raised': true,
                'primary': true
            })))))] : null, this.state.type === 'start' && engagement.action_type === 'display_payment' ? [React.createElement('div', {
                'className': 'row',
                'key': '49451'
            }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'material-icons m-r-10 f-35 round-border' }, 'credit_card')), React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' }
            }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Payment Subscription'), React.createElement('div', { 'className': 'panel-subtitle' }, 'Complete the payment to view treatment details for your game plan ', engagement.name)), React.createElement('div', { 'className': 'panel-body' }, React.createElement(Button, {
                'onClick': () => {
                    history.push('/client/engagement/' + engagement.id);
                },
                'className': 'bg-success',
                'icon': 'credit_card',
                'label': 'Subscribe',
                'tooltip': 'Click to complete payment for you to view treatment details for your game plan ' + engagement.name,
                'raised': true,
                'primary': true
            })))))] : null, this.state.type === 'checkin' && engagement.action_type === 'goal_tracking' ? [React.createElement('div', {
                'className': 'row',
                'key': '61531'
            }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-checkin' })), React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' }
            }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, '\n                            Confirm Progress\n                        '), React.createElement('div', { 'className': 'panel-body' }, React.createElement(Link, { 'to': '/client/engagement/' + engagement.id }, 'Click here'), ' to let us know your \n                            progress in reaching your health and fitness goals to ', engagement.name, '. \n                            ', React.createElement('div', { 'className': 'alert-warning m-t-15 w-100 text-center' }, '\n                                On clicking the link, you will be taken to game plan page. Update your progress under goal section.\n                            ')))))] : null, engagement.action_type === 'view_treatment' ? [
            _.map(engagement.progressions, repeatProgression1.bind(this, engagement, engagementIndex)),
            _.map(engagement.habits, repeatHabit2.bind(this, engagement, engagementIndex)),
            this.state.type === 'start' ? [_.map(engagement.nutrition, repeatNutrition4.bind(this, engagement, engagementIndex))] : null,
            this.state.type === 'start' ? [_.map(engagement.guidance, repeatGuidance6.bind(this, engagement, engagementIndex))] : null
        ] : null);
    }
    function repeatProgression8(group, groupIndex, progression, progressionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': progressionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, this.state.type === 'start' ? React.createElement(Button, {
            'onClick': () => {
                this.playWorkout(progression);
            },
            'tooltip': 'Play Workout',
            'className': 'bg-success  m-t-20',
            'icon': 'gm-workouts',
            'gomotive': true,
            'floating': true,
            'key': '16074'
        }) : null, this.state.type !== 'start' ? React.createElement('i', {
            'className': 'gomotive-icons m-r-10 f-35 gm-workouts',
            'key': '16363'
        }) : null), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(ProgressionView, _.assign({}, {
            'display_workout_name': true,
            'display_schedule_type_info': true,
            'track_date': this.state.selectedDateString,
            'progression': progression,
            'showTracking': true,
            'trackProgressionCallback': this.trackProgressionCallback
        }, this.props))));
    }
    function repeatHabit9(group, groupIndex, habit, habitIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': habitIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-habits' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(GroupHabitView, {
            'habit': habit,
            'trackHabitProgressCallback': this.trackGroupHabitCallback
        })));
    }
    function repeatAttachment10(group, groupIndex, nutrition, nutritionIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('i', { 'className': 'material-icons vertical-middle f-18' }, 'insert_drive_file'), React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatNutrition11(group, groupIndex, nutrition, nutritionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': nutritionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-nutrition' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'panel w-100' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, nutrition.name), React.createElement('div', { 'className': 'panel-subtitle pre-wrap' }, nutrition.description)), React.createElement('div', { 'className': 'panel-body  vertical-align' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(nutrition.attachments, repeatAttachment10.bind(this, group, groupIndex, nutrition, nutritionIndex))
        ])))));
    }
    function repeatAttachment12(group, groupIndex, guidance, guidanceIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('i', { 'className': 'material-icons vertical-middle f-18' }, 'insert_drive_file'), React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatGuidance13(group, groupIndex, guidance, guidanceIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': guidanceIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-guidance' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'panel w-100' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, guidance.name), React.createElement('div', { 'className': 'panel-subtitle pre-wrap' }, guidance.description)), React.createElement('div', { 'className': 'panel-body  vertical-align' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(guidance.attachments, repeatAttachment12.bind(this, group, groupIndex, guidance, guidanceIndex))
        ])))));
    }
    function repeatGroup14(group, groupIndex) {
        return React.createElement('div', { 'key': groupIndex }, this.state.fetch_type === 'all' ? React.createElement('div', {
            'className': 'row vertical-align m-b-15',
            'key': '12151'
        }, React.createElement('i', { 'className': 'gomotive-icons  f-40 gm-group m-r-10' }), React.createElement('h4', {
            'className': 'di-block flex-auto',
            'style': { width: 'auto' }
        }, group.name, ' ')) : null, this.state.type === 'start' && group.action_type === 'welcome_video' ? [React.createElement('div', {
                'className': 'row',
                'key': '124041'
            }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-video' })), React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' }
            }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Welcome Video'), React.createElement('div', { 'className': 'panel-subtitle' }, group.mobile_display_text)), React.createElement('div', { 'className': 'panel-body' }, React.createElement(Button, {
                'onClick': () => {
                    history.push('/client/group/' + group.id);
                },
                'className': 'bg-success',
                'icon': 'play_circle_outline',
                'label': 'View Welcome Video',
                'tooltip': 'Click to view the welcome message from your practitioner ' + group.details.owner.name,
                'raised': true,
                'primary': true
            })))))] : null, this.state.type === 'start' && group.action_type === 'display_payment' ? [React.createElement('div', {
                'className': 'row',
                'key': '135081'
            }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'material-icons m-r-10 f-35' }, 'credit_card')), React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' }
            }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Payment Subscription'), React.createElement('div', { 'className': 'panel-subtitle' }, 'Complete the payment to view treatment details for group ', group.name)), React.createElement('div', { 'className': 'panel-body' }, React.createElement(Button, {
                'onClick': () => {
                    history.push('/client/group/' + group.id);
                },
                'className': 'bg-success',
                'icon': 'credit_card',
                'label': 'Subscribe',
                'tooltip': 'Click to complete payment for you to view treatment details for group ' + group.name,
                'raised': true,
                'primary': true
            })))))] : null, this.state.type === 'checkin' && group.action_type === 'goal_tracking' ? [React.createElement('div', {
                'className': 'row',
                'key': '146521'
            }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-checkin' })), React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' }
            }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, '\n                            Confirm Progress\n                        '), React.createElement('div', { 'className': 'panel-body' }, React.createElement(Link, { 'to': '/client/group/' + group.id }, 'Click here'), ' to let us know your \n                            progress in reaching your health and fitness goals to ', group.name, '. \n                            ', React.createElement('div', { 'className': 'alert-warning m-t-15 w-100 text-center' }, '\n                                On clicking the link, you will be taken to group page. Update your progress under goal section.\n                            ')))))] : null, group.action_type === 'view_treatment' ? [
            _.map(group.progressions, repeatProgression8.bind(this, group, groupIndex)),
            _.map(group.habits, repeatHabit9.bind(this, group, groupIndex)),
            this.state.type === 'start' ? [_.map(group.nutrition, repeatNutrition11.bind(this, group, groupIndex))] : null,
            this.state.type === 'start' ? [_.map(group.guidance, repeatGuidance13.bind(this, group, groupIndex))] : null
        ] : null);
    }
    function repeatProgression15(quickstart, quickstartIndex, progression, progressionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': progressionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, this.state.type === 'checkin' ? React.createElement('i', {
            'className': 'gomotive-icons m-r-10 f-35 gm-workouts',
            'key': '20997'
        }) : null, this.state.type === 'start' ? React.createElement(Button, {
            'onClick': () => {
                this.playWorkout(progression);
            },
            'tooltip': 'Play Workout',
            'className': 'bg-success  m-t-20',
            'icon': 'gm-workouts',
            'gomotive': true,
            'floating': true,
            'key': '21130'
        }) : null), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(ProgressionView, _.assign({}, {
            'display_workout_name': true,
            'display_schedule_type_info': true,
            'track_date': this.state.selectedDateString,
            'progression': progression,
            'showTracking': true,
            'trackProgressionCallback': this.trackProgressionCallback
        }, this.props))));
    }
    function repeatQuickstart16(quickstart, quickstartIndex) {
        return React.createElement.apply(this, [
            'div',
            { 'key': quickstartIndex },
            this.state.fetch_type === 'all' ? React.createElement('div', {
                'className': 'row vertical-align m-b-15',
                'key': '20569'
            }, React.createElement('i', { 'className': 'gomotive-icons  f-50 gm-quick-start' }), React.createElement('h4', {
                'className': 'di-block flex-auto',
                'style': { width: 'auto' }
            }, quickstart.name, ' ')) : null,
            _.map(quickstart.progressions, repeatProgression15.bind(this, quickstart, quickstartIndex))
        ]);
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'm-10' },
        React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('div', { 'className': 'row middle-xs' }, React.createElement('div', { 'className': 'col' }, React.createElement('h3', {}, '\n                        Program for :\n                    ')), React.createElement('div', { 'className': 'col m-l-20 m-t-10' }, React.createElement('i', { 'className': 'gomotive-icons col gm-appointments' })), React.createElement('div', { 'className': 'width-300 col m-l-10' }, React.createElement(DatePicker, {
            'enabledDates': this.props.available_dates,
            'value': this.state.selectedDate,
            'onChange': val => {
                this.changeSelectedDate(val);
            }
        })), React.createElement('div', { 'className': 'col m-l-10' }, React.createElement(HelpTip, {}, '\n                        Workouts and Habits will be displayed for this selected date. Change date to display older workouts\n                    ')))), React.createElement('div', { 'className': 'col-xs-6 end-xs row middle-xs' }, React.createElement(Button, {
            'label': 'View All Workouts',
            'tooltip': 'View all workouts',
            'primary': true,
            'raised': true,
            'onClick': () => {
                this.gotoAllWorkouts();
            }
        }))),
        this.props.engagement_list.length === 0 && this.props.group_list.length === 0 && this.props.quickstart_list.length === 0 ? React.createElement('div', {
            'className': 'alert-warning m-20',
            'key': '2109'
        }, '\n        You do not have any workouts or habits assigned for you today\n    ') : null,
        _.map(this.props.engagement_list, repeatEngagement7.bind(this)),
        _.map(this.props.group_list, repeatGroup14.bind(this)),
        _.map(this.props.quickstart_list, repeatQuickstart16.bind(this))
    ]);
}