// Action Creators
const dhfAssessFormFill = (dhf_assess_form) => ({
    type: 'DHF_ASSESSMENT_FORM_FILL',
    dhf_assess_form
});
const dhfAssessmentFill = (dhf_assessment) => ({
    type: 'DHF_ASSESSMENT_UPDATE',
    dhf_assessment
});
const dhfCleanUp = () => ({
    type: 'DHF_ASSESSMENT_CLEANUP',
});

export default {
    dhfAssessFormFill,
    dhfAssessmentFill,
    dhfCleanUp
};
