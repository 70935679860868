import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/summary";
import { formValueSelector, reduxForm } from 'redux-form';
import { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { generateDraftWorkout } from '../../redux/gi/network/gi_network';

class Summary extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.generateWorkout = this.generateWorkout.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.onAnalysisTypeSelected = this.onAnalysisTypeSelected.bind(this);
        this.state = {
            analysisTypes: [
                {
                    value: 'mobility',
                    label: 'Mobility Analysis',
                },
                {
                    value: 'stability',
                    label: 'Stability Analysis',
                },
                {
                    value: 'mostability',
                    label: 'Mostability Analysis',
                },
            ],
            selectedAnalysisType: null,
        };
    }

    componentWillMount() {
        this.setState({ selectedAnalysisType: this.props.analysisType });
    }

    onAnalysisTypeSelected(val) {
        this.setState({ selectedAnalysisType: val });
    }

    generateWorkout() {
        let client_id = this.props.match.params.client_id;
        this.props.generateDraftWorkout({
            client_id: client_id,
            analysis_type: this.state.selectedAnalysisType.value,
            rsc: this.props.rsc.replace(/ /g, ","),
            performance_modality: this.props.performanceModality.id,
            notes: this.props.notes
        }).then((success) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/client/engagement/' + success.engagement_id +"/?tab=3&workout="+success.workout_id );
        }).catch(() => { });
    }

    doCancel() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/maps3d/'+this.props.match.params.client_id+'/analysis' );
    }

    render() {
        return Template.apply(this);
    }

}

const selector = formValueSelector('SummaryForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        generateDraftWorkout
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const SummaryForm = reduxForm(
    {
        form: 'SummaryForm',
        enableReinitialize: true,
        validate
    }
)(Summary);

const SummaryConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(SummaryForm);

export default SummaryConnected;
