import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import PartnerPlanDetails from '../plan_selection_details';
import moment from 'moment';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import StripeCheckout from 'react-stripe-checkout';
import { STRIPE_KEY } from '../../constants/constants';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer';
export default function () {
    function repeatPlan1(subscription, subscriptionIndex, plan, planIndex) {
        return [React.createElement('div', {
                'className': 'col-md-3 col-sm-6',
                'key': '30171'
            }, React.createElement('div', { 'className': 'pricing-widget text-center ribbon-container' }, subscription.selected_plan && subscription.selected_plan.plan.id === plan.id ? React.createElement('div', {
                'className': 'bg-success ribbon',
                'key': '3230'
            }, '\n                                    ACTIVE\n                        ') : null, React.createElement('div', {
                'className': `pricing-head f-20 d-flex flex-column p-0 c-white `,
                'style': {
                    minHeight: '250px',
                    background: plan.color_code
                }
            }, React.createElement('div', {
                'className': 'w-100 f-400  p-15 m-b-10',
                'style': { background: 'rgba(0,0,0,0.25)' }
            }, plan.name), React.createElement('i', {
                'className': 'material-icons  icon-shadow',
                'style': { fontSize: '80px' }
            }, 'verified_user'), React.createElement('div', { 'className': 'f-18 w-100 f-200 m-t-15' }, React.createElement('span', {}, ' ', plan.default_currency.toUpperCase(), ' ', plan.fee_amount, ' / month ')), !subscription.selected_plan || subscription.selected_plan.plan.id !== plan.id ? React.createElement(StripeCheckout, {
                'name': 'GoMotive, Inc.',
                'description': subscription.practice.name,
                'image': '/site_media/static/images/touch-icon-iphone.png',
                'panelLabel': 'Pay',
                'amount': plan.fee_amount * 100,
                'currency': plan.default_currency.toUpperCase(),
                'stripeKey': STRIPE_KEY,
                'email': this.props.user.email,
                'zipCode': false,
                'allowRememberMe': false,
                'token': token => {
                    this.subscribe(token, subscription, plan);
                },
                'key': '4040'
            }, React.createElement(Button, {
                'className': 'm-t-20',
                'icon': 'card_membership',
                'label': 'Subscribe',
                'primary': true,
                'raised': true
            })) : null, React.createElement('span', {}, subscription.selected_plan && subscription.selected_plan.plan.id === plan.id ? React.createElement(Button, {
                'className': 'bg-accent  m-t-20',
                'icon': 'money_off',
                'label': 'Unsubscribe',
                'primary': true,
                'raised': true,
                'onClick': () => {
                    this.unSubscribe(subscription, plan);
                },
                'key': '5358'
            }) : null)), React.createElement('div', { 'className': 'p-10 text-left f-15 f-300 pricing-body' }, React.createElement(RichTextViewer, {
                'className': 'col-xs-12 p-10  text-left',
                'value': plan.description
            }))))];
    }
    function repeatSubscription2(subscription, subscriptionIndex) {
        return [
            React.createElement('div', {
                'className': 'panel col-xs-12 text-left m-b-10 p-0',
                'key': '7961'
            }, React.createElement('div', {
                'className': 'panel-body f-15',
                'style': { borderLeft: '5px solid #0292D3' }
            }, React.createElement('div', {}, '\n                    Practice : ', subscription.practice.name, '\n                '), subscription.selected_plan ? React.createElement('p', {
                'className': 'f-15 material-label',
                'key': '1125'
            }, '\n                    Current plan : ', subscription.selected_plan.plan.name, '\n                ') : null, !subscription.selected_plan ? React.createElement('p', {
                'className': 'f-15 material-label',
                'key': '1304'
            }, '\n                    Current plan : Bronze plan\n                ') : null)),
            React.createElement('div', {
                'className': 'col-xs-12',
                'key': '7963'
            }, React.createElement.apply(this, [
                'div',
                { 'className': 'row center-xs' },
                React.createElement('div', { 'className': 'col-md-3 col-sm-6' }, React.createElement('div', { 'className': 'pricing-widget text-center ribbon-container' }, subscription.selected_plan === null ? React.createElement('div', {
                    'className': 'bg-success ribbon',
                    'key': '1736'
                }, '\n                        ACTIVE\n                    ') : null, React.createElement('div', {
                    'className': `pricing-head f-20 d-flex flex-column p-0 c-white     `,
                    'style': {
                        minHeight: '250px',
                        background: '#8B572A'
                    }
                }, React.createElement('div', {
                    'className': 'w-100 f-400 p-15 m-b-10',
                    'style': { background: 'rgba(0,0,0,0.25)' }
                }, 'Bronze Plan'), React.createElement('i', {
                    'className': 'material-icons icon-shadow',
                    'style': { fontSize: '80px' }
                }, 'verified_user'), React.createElement('div', { 'className': 'f-18 w-100 f-200 m-t-15' }, React.createElement('span', {}, ' Free ')), React.createElement('span', {}, subscription.selected_plan !== null ? React.createElement(Button, {
                    'className': 'm-t-20',
                    'icon': 'card_membership',
                    'label': 'Subscribe',
                    'primary': true,
                    'raised': true,
                    'onClick': () => {
                        this.subscribe(null, subscription, null);
                    },
                    'key': '2454'
                }) : null)))),
                _.map(subscription.all_plans, repeatPlan1.bind(this, subscription, subscriptionIndex))
            ]))
        ];
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row m-20' },
        _.map(this.props.subscriptions, repeatSubscription2.bind(this))
    ]);
}