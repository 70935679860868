import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import ToolBoxButton from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import moment from 'moment';
export default function () {
    function repeatEngagement1(engagement, engagementIndex) {
        return React.createElement('div', {
            'onClick': () => {
                this.showEngagementDetails(engagement);
            },
            'className': 'col-xs-10 bg-white border-body p-10 m-t-10 m-l-10 pointer',
            'key': 'engagement' + engagement.id
        }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 vertical-align m-b-5' }, React.createElement('h5', { 'className': 'flex-auto' }, engagement.name, ' '), engagement.is_active ? React.createElement('span', {
            'className': 'bg-primary p-5 m-l-5',
            'key': '1560'
        }, 'Active') : null, !engagement.is_active ? React.createElement('span', {
            'className': 'bg-danger p-5 m-l-5',
            'key': '1662'
        }, 'Closed') : null), React.createElement('hr', { 'className': 'dashed' }), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row middle-xs p-l-10' }, React.createElement('div', { 'className': 'col-md-2 col-sm-12 f-15 f-500 p-b-5' }, '    \n                                    Status:\n                            '), React.createElement('div', { 'className': 'col-sm-12 col-md-10  f-13  c-darkgray p-0' }, engagement.goal_state === 1 ? React.createElement(ToolBoxButton, {
            'icon': 'check_circle',
            'className': 'small-button m-r-5 m-b-5  bg-success br-50em p-10 box-shadow-inset',
            'label': 'Goal',
            'key': '2196'
        }) : null, engagement.goal_state === 0 ? React.createElement(ToolBoxButton, {
            'icon': 'add_circle',
            'className': 'rotate-button-icon small-button m-r-5 m-b-5 bg-danger br-50em p-10 box-shadow-inset',
            'label': 'Goal',
            'key': '2511'
        }) : null, engagement.intake_state === 1 ? React.createElement(ToolBoxButton, {
            'icon': 'check_circle',
            'className': 'small-button m-r-5 m-b-5 bg-success br-50em p-5 box-shadow-inset',
            'label': 'Intake',
            'key': '2843'
        }) : null, engagement.intake_state === 0 ? React.createElement(ToolBoxButton, {
            'icon': 'add_circle',
            'className': 'rotate-button-icon small-button m-r-5 m-b-5 bg-danger br-50em p-5 box-shadow-inset',
            'label': 'Intake',
            'key': '3221'
        }) : null, engagement.initial_assessment_state === 1 ? React.createElement(ToolBoxButton, {
            'icon': 'check_circle',
            'className': 'small-button m-r-5 m-b-5 bg-success br-50em p-5 box-shadow-inset',
            'label': 'Assesssment',
            'key': '3556'
        }) : null, engagement.initial_assessment_state === 0 ? React.createElement(ToolBoxButton, {
            'icon': 'add_circle',
            'className': 'rotate-button-icon small-button m-r-5 m-b-5 bg-danger br-50em p-5 box-shadow-inset',
            'label': 'Assesssment',
            'key': '3891'
        }) : null, engagement.health_and_fitness_treatment_state === 1 ? React.createElement(ToolBoxButton, {
            'icon': 'check_circle',
            'className': 'small-button m-r-5 m-b-5 bg-success br-50em p-5 box-shadow-inset',
            'label': 'Program',
            'key': '4243'
        }) : null, engagement.health_and_fitness_treatment_state === 0 ? React.createElement(ToolBoxButton, {
            'icon': 'add_circle',
            'className': 'rotate-button-icon small-button m-r-5 m-b-5 bg-danger br-50em p-5 box-shadow-inset',
            'label': 'Program',
            'key': '4584'
        }) : null, engagement.health_and_fitness_treatment_state === 1 ? React.createElement(ToolBoxButton, {
            'icon': 'check_circle',
            'className': 'small-button m-r-5 m-b-5 bg-success br-50em p-5 box-shadow-inset',
            'label': 'Result',
            'key': '5003'
        }) : null, engagement.health_and_fitness_treatment_state === 0 ? React.createElement(ToolBoxButton, {
            'icon': 'add_circle',
            'className': 'rotate-button-icon small-button m-r-5 m-b-5 bg-danger br-50em p-5 box-shadow-inset',
            'label': 'Result',
            'key': '5343'
        }) : null), React.createElement('div', { 'className': 'col-sm-12 col-md-2 f-15 f-500 p-b-5' }, '    \n                                Practitioner: \n                            '), React.createElement('div', { 'className': 'col-sm-12 col-md-10  f-13 c-darkgray p-b-5' }, React.createElement(ProfilePicture, {
            'user': engagement.practitioner,
            'profile_type': 'circle'
        }), ' ', engagement.practitioner.name, ' (', engagement.practice.name, ')\n                            '), React.createElement('div', { 'className': 'col-sm-12 col-md-2 f-15 f-500 p-b-5' }, '    \n                                    Start Date:\n                            '), React.createElement('div', { 'className': 'col-sm-12 col-md-10  f-13  c-darkgray p-b-5' }, ' \n                                ', moment(engagement.created_date).calendar(), '\n                            '), React.createElement('div', { 'className': 'col-sm-12 col-md-2 f-15 f-500 p-b-5' }, '    \n                                    Goal:\n                            '), React.createElement('div', { 'className': 'col-sm-12 col-md-10  f-13  c-darkgray p-b-5' }, ' \n                                    ', engagement.goal_state === 1 ? engagement.goal_name : ' -  ', '\n                            ')))));
    }
    return React.createElement('div', { 'className': 'm-20' }, this.props.engagements.length === 0 ? React.createElement('div', {
        'className': 'row m-30 center-xs',
        'key': '908'
    }, React.createElement('div', { 'className': 'col-xs-12 alert-warning' }, 'There are no game plans assigned to you')) : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row  p-10' },
        _.map(this.props.engagements, repeatEngagement1.bind(this))
    ]));
}