let initialState = {
    partner_plans: [],
    discount_plans: [],
    partner_plan:{}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "BUSINESS_PARTNER_PLAN_LIST_FILL":
            if(!action.partner_plans) {
                return state;
            }
            return Object.assign({}, state, {
                partner_plans: action.partner_plans,
                discount_plans: action.discount_plans,
            });
        case "BUSINESS_PARTNER_PLAN_INSTANCE_FILL":
            if(!action.partner_plan) {
                return state;
            }
            return Object.assign({}, state, {
                partner_plan: action.partner_plan,
            });
        case "BUSINESS_PARTNER_PLAN_CLEANUP":
            return Object.assign({}, initialState);                        
        default:
            return state;
    }
};