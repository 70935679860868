import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import DatePicker from '../../../../utils/common_components/datepicker_form';
import TimePicker from '../../../../utils/common_components/time_picker_form';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'w-50 m-20 start-xs' }, React.createElement(CardTitle, {
        'title': 'Add New Webinar',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.webinarCreate) }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'title',
        'className': 'w-100',
        'name': 'title',
        'type': 'text',
        'component': TextInput,
        'label': 'Webinar Title'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Duration (In minutes)'
    })), React.createElement('div', { 'className': 'col-md-12' }, React.createElement(Field, {
        'name': 'publish_date',
        'type': 'date',
        'label': 'Publish Date',
        'component': DatePicker
    })), React.createElement('div', { 'className': 'col-md-12' }, React.createElement(Field, {
        'label': 'Publish Time',
        'name': 'publish_time',
        'component': TimePicker
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'shopify_link',
        'className': 'w-100',
        'name': 'shopify_link',
        'type': 'text',
        'component': TextInput,
        'label': 'Shopify Link'
    })), React.createElement('div', { 'className': 'row col-xs-12' }, React.createElement('div', { 'className': 'col-md-6' }, Object.keys(this.state.holdImage).length ? React.createElement('div', {
        'className': 'thumbnail box-250-150',
        'style': { backgroundImage: 'url(' + encodeURI(this.state.holdImage[0].preview) + ')' },
        'key': '3491'
    }) : null), React.createElement('div', { 'className': 'col-md-6 end-xs' }, React.createElement(Dropzone, {
        'className': 'dummy-suppress-warning',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.holdImage
    }, React.createElement(Button, {
        'label': 'Upload Thumbnail image',
        'primary': true,
        'raised': true
    })), Object.keys(this.state.holdImage).length ? React.createElement(Button, {
        'onClick': this.deleteholdImage,
        'label': 'Delete Thumbnail',
        'className': 'm-t-10 m-x-10 col bg-danger',
        'primary': true,
        'raised': true,
        'key': '4074'
    }) : null)))), React.createElement('div', { 'className': 'col-xs-12 m-t-25 center-xs m-20' }, React.createElement(Button, {
        'label': 'Create',
        'className': 'bg-success m-r-10',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'submit',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    })))))));
}