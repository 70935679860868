import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/workfit_notification_template.rt";
import store,{history} from '../../../redux/store';
import { verifyToken } from '../../../redux/auth/network/auth_network';
import Utils from '../../../utils/utils';
import BaseComponent from '../../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../../redux/core/actions/menu_state_creator';
import { getNotificationTemplateList } from '../../../redux/dhf/workfit/network/workfit_network';

class WorkfitNotificationTemplate extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);    
        this.addNotificationTemplate = this.addNotificationTemplate.bind(this);  
        this.editNotificationTemplate = this.editNotificationTemplate.bind(this);  
    }

    addNotificationTemplate() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/workfit/notification_templates/add');
    }

    editNotificationTemplate(notificationTemplate) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/workfit/notification_templates/edit/' + notificationTemplate.id);
    }

    componentWillMount() {  
        this.props.getNotificationTemplateList();     
    }

    
    render() {
        if (!this.props.isAuthenticated || _.isEmpty(this.props.selectedRole)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar type='circular' mode='indeterminate' multicolor />
            </div>);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            verifyToken,
            updateMenuOpen: MenuAction.updateMenuOpen,
            getNotificationTemplateList,
        },
        dispatch
    );
}
function mapStateToProps(state, ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen: state.core.appMenu.menu_open,
        pinned: state.core.appMenu.pinned,
        notificationTemplates: state.workfit.workfitReducer.notification_templates,
    };
}


const WorkfitNotificationTemplateConnected = connect(mapStateToProps, mapDispatchToProps)(WorkfitNotificationTemplate);
export default WorkfitNotificationTemplateConnected;
