import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_location_edit";
import { reduxForm } from 'redux-form';
import {history} from '../../redux/store';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {practiceLocationGet, practiceLocationPost} from '../../redux/practice/network/practice_location';
import actionCreator from '../../redux/practice/actions/practice_location';
import Utils from '../../utils/utils';

class PracticeLocation extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentWillMount() {
        let id = this.props.match.params.location_id;
        this.props.practiceLocationGet({id: id});
    }

    save(data){
        let params = {...data};
        this.props.practiceLocationPost(params).then((response)=>{
            history.goBack();
        }).catch((error)=>{});
    }

    cancel(){
        history.goBack();
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        practiceLocationGet,
        practiceLocationPost,
        clean: actionCreator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        practiceLocation: state.practice.practiceLocation.practiceLocation,
        initialValues: state.practice.practiceLocation.practiceLocation,
        selectedRole: state.auth.roleReducer.selectedRole,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'city',
        'zipcode',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const PracticeLocationForm = reduxForm(
    {
        form: 'PracticeLocationForm',
        enableReinitialize: true,
        validate
    }
)(PracticeLocation);

const PracticeLocationConnected = connect(mapStateToProps,mapDispatchToProps)(PracticeLocationForm);
export default PracticeLocationConnected;
