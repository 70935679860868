import * as React from 'react';
import * as _ from 'lodash';
import { ResponsiveContainer } from 'recharts';
import { LineChart } from 'recharts';
import { Line } from 'recharts';
import { XAxis } from 'recharts';
import { YAxis } from 'recharts';
import { ReferenceLine } from 'recharts';
import { ReferenceDot } from 'recharts';
import { Tooltip } from 'recharts';
import { CartesianGrid } from 'recharts';
import { Legend } from 'recharts';
import { Brush } from 'recharts';
import { ErrorBar } from 'recharts';
import { AreaChart } from 'recharts';
import { Area } from 'recharts';
export default function () {
    return React.createElement('div', { 'className': 'line-chart-wrapper' }, React.createElement(LineChart, {
        'width': 700,
        'height': 250,
        'data': this.props.track_details,
        'margin': {
            top: 40,
            right: 40,
            bottom: 20,
            left: 20
        }
    }, React.createElement(CartesianGrid, {
        'vertical': false,
        'strokeDasharray': '3 3'
    }), React.createElement(XAxis, {
        'dataKey': 'tracking_date',
        'label': 'Dates'
    }), React.createElement(YAxis, {
        'domain': [
            'auto',
            'auto'
        ],
        'label': 'Progress'
    }), React.createElement(Tooltip, {}), React.createElement(Line, {
        'type': 'monotone',
        'dataKey': 'initial_value',
        'name': 'Baseline',
        'stroke': '#4c555c',
        'dot': false
    }), React.createElement(Line, {
        'dataKey': 'value',
        'name': 'Current',
        'stroke': '#0292d3',
        'dot': this.props.dots
    }), React.createElement(Line, {
        'type': 'monotone',
        'dataKey': 'target',
        'name': 'Target',
        'stroke': '#45BC00',
        'dot': false
    }), this.props.track_details.length > 15 ? React.createElement(Brush, {
        'dataKey': 'tracking_date',
        'startIndex': 10,
        'key': '1539'
    }, React.createElement(AreaChart, {}, React.createElement(CartesianGrid, {}), React.createElement(YAxis, {
        'hide': true,
        'domain': [
            'auto',
            'auto'
        ]
    }), React.createElement(Area, {
        'dataKey': 'value',
        'stroke': '#0292d3',
        'fill': '#0292d3',
        'dot': this.props.dots
    }))) : null));
}