// Action Creators
const fillHabitList = (habits) => ({
    type: 'GROUP_HABIT_LIST_FILL',
    habits
});

const fillHabit = (habit, supportingData) => ({
    type: 'GROUP_HABIT_FILL',
    habit,
    supportingData,
});

const cleanModule = () => ({
    type: 'GROUP_HABIT_CLEANUP',
});

export default {
    fillHabitList,
    fillHabit,
    cleanModule,
};
