import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import { Dialog } from 'react-toolbox/lib/dialog';
import Button from 'react-toolbox/lib/button';
import Avatar from '../../../../utils/common_components/profile_picture';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { history } from '../../../../redux/store';
import TooltipWrapper from '../../../../utils/common_components/tooltipWrapper';
import MailComposer from '../../../../utils/common_components/mail_composer';
import WelcomeVideo from '../../../../client_app/components/welcome_video';
export default function () {
    function repeatPractitioner1(practitioner, practitionerIndex) {
        return React.createElement('div', {
            'className': 'panel no-box-shadow',
            'key': practitionerIndex
        }, React.createElement('div', { 'className': `panel-heading vertical-align p-0  ${ this.props.practitioners.length > 1 ? 'panel-bottom-border' : '' }` }, React.createElement(Avatar, {
            'size': 65,
            'user': practitioner,
            'profile_type': 'circle'
        }), React.createElement('h5', { 'className': 'flex-auto m-l-5' }, practitioner.name), practitioner.id !== this.props.user.id ? React.createElement(Button, {
            'icon': 'chat',
            'className': 'bg-primary  m-r-5',
            'floating': true,
            'mini': true,
            'onClick': () => {
                this.chatWithPractitioner(practitioner);
            },
            'key': '3819'
        }) : null, React.createElement(Button, {
            'icon': 'email',
            'className': 'bg-primary',
            'floating': true,
            'mini': true,
            'onClick': () => {
                this.showMailComposer(practitioner);
            }
        })));
    }
    return React.createElement('div', { 'className': 'row start-xs c-white' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', {
        'className': 'row flex-column',
        'style': { minHeight: '75vh' }
    }, React.createElement('div', {
        'className': 'p-relative  self-center',
        'style': { marginTop: '80px' }
    }, React.createElement('h3', {
        'onClick': () => {
            history.push('/client/workfit/scheduler');
        },
        'className': 'c-white di-block f-50 p-t-15 p-b-15 p-l-25 p-r-25  pointer',
        'style': {
            border: '2px solid white',
            width: 'auto'
        }
    }, 'SCHEDULE')), this.state.show_movement_graph ? React.createElement('div', {
        'className': 'col-xs-12',
        'style': { marginTop: '35px' },
        'key': '1533'
    }, React.createElement('div', {
        'className': 'row center-xs middle-xs',
        'style': { minHeight: '200px' }
    })) : null, React.createElement('p', { 'style': { flexGrow: '1' } }), React.createElement('span', { 'className': 'm-b-25' }, React.createElement('div', { 'className': 'row around-xs' }, React.createElement('div', {
        'onClick': () => {
            this.gotoAirFit();
        },
        'className': 'vertical-align p-15 pointer p-relative',
        'style': { border: '2px solid white' }
    }, React.createElement('i', { 'className': 'gomotive-icons c-white m-r-10 f-50 gm-group' }), React.createElement('h3', {
        'className': 'c-white di-block f-50',
        'style': { width: 'auto' }
    }, 'AirFit')), React.createElement('div', {
        'onClick': () => {
            this.gotoOfficeFit();
        },
        'className': 'vertical-align p-15 m-l-1 pointer',
        'style': { border: '2px solid white' }
    }, React.createElement('i', { 'className': 'gomotive-icons c-white m-r-10 f-50 gm-group' }), React.createElement('h3', {
        'className': 'c-white di-block f-50',
        'style': { width: 'auto' }
    }, 'Office Fit')))))), React.createElement.apply(this, [
        Dialog,
        {
            'active': this.state.showConnect,
            'title': 'Connect',
            'onEscKeyDown': this.closeConnect,
            'onOverlayClick': this.closeConnect
        },
        _.map(this.props.practitioners, repeatPractitioner1.bind(this))
    ]), React.createElement(Dialog, {
        'active': this.state.mailComposerVisible,
        'className': 'dialog-title-center',
        'title': this.state.currentlyMailingPractitioner ? 'Message ' + this.state.currentlyMailingPractitioner.name : '',
        'onEscKeyDown': this.hideMailComposer,
        'onOverlayClick': this.hideMailComposer
    }, React.createElement(MailComposer, {
        'hideEmail': true,
        'onSubmit': this.onMailComposerSubmit
    })), this.state.welcome_video ? React.createElement(WelcomeVideo, {
        'onCloseHandle': this.closeWelcomeVideo,
        'welcome_video': this.state.welcome_video,
        'key': '4593'
    }) : null);
}