import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import { SearchInput as Input } from '../../../utils/common_components/textInput_form';
import Select from '../../../utils/common_components/select';
import SelectComponent from 'react-select';
import MailComposer from '../../../utils/common_components/mail_composer';
import TreatmentTemplateList from '../../../treatment_template_new/components/treatment_template_list_new';
import { Panel } from 'react-toolbox';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Field } from 'redux-form';
import { CSVLink } from 'react-csv';
export default function () {
    function repeatGroup1(group, groupIndex) {
        return React.createElement('div', {
            'className': 'width-260 m-b-20 cursor-pointer height-fit box-shadow bg-white m-r-16 ribbon-container',
            'key': 'group' + group.id
        }, React.createElement('div', {
            'className': 'w-100 d-flex flex-column',
            'onClick': event => this.goToGroupDashboard(event, group.id)
        }, React.createElement('div', { 'className': 'box-260-150' }, group.thumbnail_url ? React.createElement('div', {
            'className': 'thumbnail profile-picture-normal',
            'style': {
                backgroundImage: 'url(' + encodeURI(group.thumbnail_url || group.logo_url) + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center 65%'
            },
            'key': '4761'
        }) : null, !group.thumbnail_url ? React.createElement(ProfilePicture, {
            'user': group,
            'key': '5024'
        }) : null), React.createElement('div', {
            'className': 'w-100 text-center p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', {}, React.createElement(TooltipWrapper, { 'tooltip': group.name }, React.createElement('h6', { 'className': 'f-14 text-center text-capital c-black two-lines' }, '\n                                        ', group.name, '\n                                    ')), React.createElement('div', {
            'className': 'view-btn w-100 p-b-5 d-flex',
            'style': { alignItems: 'baseline' }
        }, React.createElement('div', {
            'tooltip': 'Views',
            'className': 'w-50 c-grey small-icon-button text-center'
        }, React.createElement('span', { 'className': 'views-icon' }), React.createElement('span', { 'className': 'p-l-5' }, group.client_views_count, ' views')), React.createElement('div', { 'className': 'f-12 text-center f-100 m-t-5 truncate' }, '\n                                    ', group.__group_treatment_cycle, '\n                                '))), this.state.groupStatus.value === 'active' ? React.createElement('div', {
            'className': 'text-center m-t-6 d-flex justify-center',
            'key': '6235'
        }, React.createElement(IconButton, {
            'tooltip': 'Deactivate this group so all content is hidden from clients\' view',
            'icon': 'deactivate',
            'className': 'c-grey',
            'onClick': () => {
                this.hideGroup(group.id);
            }
        }), React.createElement(IconButton, {
            'tooltip': 'Email all clients',
            'icon': 'email',
            'gomotive': true,
            'className': 'c-grey m-l-5',
            'onClick': () => {
                this.showMailComposer(group);
            }
        })) : null, this.state.groupStatus.value === 'inactive' ? React.createElement('div', {
            'className': 'text-center m-t-6 d-flex justify-center',
            'key': '7052'
        }, React.createElement(IconButton, {
            'tooltip': 'Activate this group so all content is hidden from clients\' view',
            'className': 'c-red',
            'icon': 'reactivate',
            'onClick': () => {
                this.hideGroup(group.id);
            }
        })) : null)));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'm-t-15 m-r-20 homefit-group-list' }, !this.state.showGroupTemplates ? React.createElement('div', { 'key': '1753' }, React.createElement('div', { 'className': 'program-search-box row vertical-align flex-sb start-xs m-10' }, React.createElement('div', { 'className': 'm-l-4' }, React.createElement('h3', { 'className': 'f-14 page-subtitle' }, 'HomeFit'), React.createElement('h2', { 'className': 'm-0 page-title' }, 'Group (Programs)')), React.createElement('div', {}, React.createElement('div', { 'className': 'flex-end col-xs-12 p-relative search-box  no-box-shadow m-b-20 p-5 vertical-align' }, React.createElement(Input, {
        'label': 'Search text',
        'style': {
            marginRight: '25px',
            height: '40px'
        },
        'icon': 'search',
        'onChange': this.updateSearchText,
        'onKeyPress': this.searchGroups,
        'type': 'text',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'width-fit bg-success m-r-10',
        'onClick': this.addGroup,
        'floating': true,
        'ripple': true,
        'mini': true
    }, 'Add New'), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    })), React.createElement('div', { 'className': 'w-100 d-flex flex-end align-center' }, React.createElement('div', {
        'className': 'w-48 m-r-16',
        'style': { height: '48px' }
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'trainer',
        'component': Select,
        'returnValue': 'object',
        'label': 'Author',
        'options': this.props.authors,
        'valueKey': 'id',
        'labelKey': 'practice_name',
        'onChange': this.selectTrainer
    })), React.createElement(Button, {
        'className': 'bg-primary m-r-10 text-capital',
        'onClick': this.generateReport,
        'label': 'Generate Report'
    }))), this.props.typeLCase === 'quickstart' ? React.createElement('div', {
        'className': 'col-xs-12 end-xs',
        'key': '3592'
    }, React.createElement(SelectComponent, {
        'className': 'col-xs-4',
        'value': this.state.quickstart_type,
        'options': this.state.quickstartSelectionList,
        'simpleValue': true,
        'onChange': val => {
            this.onChangeQuickstartSelectionList(val);
        }
    }), React.createElement(Button, {
        'className': 'bg-success m-r-10',
        'onClick': this.add,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    })) : null), this.props.group_list.length === 0 ? React.createElement('div', {
        'className': 'row m-20 center-xs alert-warning',
        'key': '4181'
    }, '\n            Click on + button to create a new group for your practice.\n        ') : null, React.createElement.apply(this, [
        'div',
        { 'className': 'd-flex m-t-20 flex-wrap' },
        _.map(this.props.group_list, repeatGroup1.bind(this))
    ])) : null, this.props.paginate_info.total_pages && this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '7626'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more groups...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.getData(true);
        }
    }))) : null, React.createElement(Dialog, {
        'active': this.state.mailComposerVisible,
        'className': 'dialog-title-center',
        'title': this.state.currentlyMailingGroup ? 'Message ' + this.state.currentlyMailingGroup.name : '',
        'onEscKeyDown': this.hideMailComposer,
        'onOverlayClick': this.hideMailComposer
    }, React.createElement(MailComposer, {
        'store': this.props.store,
        'hideEmail': true,
        'onSubmit': this.onMailComposerSubmit
    }))), this.state.showGroupTemplates ? React.createElement('div', { 'key': '8490' }, React.createElement(TreatmentTemplateList, {
        'match': { params: { treatment_type: 'group' } },
        'create_treatment': true,
        'hideTreatmentTemplateSelection': this.hideGroupTemplateSelection
    })) : null, React.createElement(Dialog, {
        'active': this.state.showReport,
        'className': 'full-dialog bg-beige',
        'style': { minHeight: '100px !important' },
        'onEscKeyDown': this.hideReport,
        'onOverlayClick': this.hideReport
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-20px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.hideReport
    })), React.createElement('div', {}, React.createElement(CSVLink, { 'data': this.state.rows }, 'Download the Report')))), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow',
        'width': 50
    }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'p-b-30 col-xs-12' }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n                        Filters\n                    '), React.createElement(Button, {
        'onClick': this.applyFilter,
        'className': 'small-button bg-success-red c-white  m-l-10',
        'label': 'Apply',
        'raised': true
    }), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), React.createElement('div', {}, React.createElement('div', { 'className': 'filter-section p-10 text-left' }, React.createElement('label', { 'className': 'medium-label' }, 'Group Status'), React.createElement(SelectComponent, {
        'className': 'col-xs-11',
        'value': this.state.groupStatus,
        'options': this.groupStatusList,
        'simpleValue': true,
        'clearable': false,
        'onChange': val => {
            this.onChangeGroupCategory(val);
        }
    })), React.createElement('div', { 'className': 'filter-section p-10 text-left' }, React.createElement('label', { 'className': 'medium-label' }, 'Group Types'), React.createElement(SelectComponent, {
        'className': 'col-xs-11',
        'value': this.state.groupType,
        'options': this.groupTypes,
        'simpleValue': true,
        'clearable': false,
        'onChange': val => {
            this.onChangeGroupType(val);
        }
    })), this.props.selectedRole.practice.is_business_partner && !this.props.selectedRole.is_consultant ? React.createElement('div', {
        'className': 'filter-section p-10 text-left',
        'key': '11298'
    }, React.createElement('label', { 'className': 'medium-label' }, 'Dependent Practitioner\'s Groups'), React.createElement(SelectComponent, {
        'className': 'col-xs-11',
        'value': this.state.selectedConsultant,
        'options': this.props.consultant_list,
        'simpleValue': true,
        'clearable': true,
        'onChange': val => {
            this.onChangeConsultantSelection(val);
        }
    })) : null)))));
}