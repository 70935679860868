import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'col-xs-12',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Bookings for ' + this.props.facility.name,
        'subtitle': moment(this.props.booking_slot.date, 'YYYY-MM-DD').format('MMM DD, YYYY - dddd') + '(' + moment(this.props.booking_slot.start_time).format('h:mm a') + ' to ' + moment(this.props.booking_slot.end_time).format('h:mm a') + ' )',
        'className': 'm-b-10 text-left'
    }), React.createElement(CardText, {}, React.createElement('div', {}, this.props.booking_slot.is_available ? React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'clients',
        'component': Select,
        'label': 'Book Clients',
        'options': this.props.clientList,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true,
        'key': '1286'
    }) : null), React.createElement('div', {}, React.createElement(Button, {
        'label': ` ${ this.props.booking_slot.is_available ? 'Mark UnAvailable' : 'Mark Available' }  `,
        'onClick': this.updateSlotAvailability,
        'className': ` ${ this.props.booking_slot.is_available ? 'bg-danger' : 'bg-primary' }  ` + ' m-r-10',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true
    }), this.props.booking_slot.is_available ? React.createElement(Button, {
        'label': 'Book Client',
        'className': 'bg-success m-r-10',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true,
        'key': '2242'
    }) : null, React.createElement(Button, {
        'label': 'Close',
        'onClick': this.props.closeBookingDialog,
        'className': 'bg-accent',
        'type': 'button',
        'primary': true,
        'raised': true
    }))))));
}