import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_view_calendar";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { groupClassSchedulerGet,groupClassScheduleEventDelete } from '../../redux/calendar/network/group_class_network';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';
import { Calendar as BigCalendar,momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment)
class GroupClassViewCalendar extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.closeEventDialog = this.closeEventDialog.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.state = {
            viewEventDialog: false,
            scheduleId: null,
        };
        
    }

    componentWillMount() {
                // BigCalendar.setLocalizer(
        //     BigCalendar.momentLocalizer(moment)
        // );
        this.loadCalendarEvents();
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    loadCalendarEvents() {
        let params = {};
        if(this.props.match.params.group_class_id) {
            params["group_class_id"] = this.props.match.params.group_class_id;
        }
        this.props.groupClassSchedulerGet({ ...params });

    }

    viewEvent(event){
        this.setState({
            viewEventDialog: true,
            scheduleId: event.id,
        });
    }

    deleteEvent() {
        let params = {};
        params['group_class_id'] = this.props.match.params.group_class_id;
        params['schedule_id'] = this.state.scheduleId;
        this.props.groupClassScheduleEventDelete({ ...params })
        .then((response) => {
            let params2 = {};
            params2["group_class_id"] =this.props.match.params.group_class_id;    
            this.props.groupClassSchedulerGet({ ...params2 })
            .then((response) => {  
                this.closeEventDialog();            
            })
            .catch((error) => { 
            });        
        })
        .catch((error) => { 
        });
    }

    closeEventDialog() {
        this.setState({
            viewEventDialog: false,
            scheduleId: null,
        });
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownProps) {
    let groupClassObject = {...state.calendar.groupClassReducer.group_class};
    let eventList = [];
    _.map(groupClassObject.group_class_frequency, function (item) {
        _.map(item.schedule_dates, function(item2) {
            let event = {};
            event.id = item2.id;
            event.title = groupClassObject.name + " (" + item2.booking_count + ")";
            event.start = moment(item2.start_time).toDate();
            event.end = moment(item2.end_time).toDate();
            eventList.push(event);
        });
    });    
    return {
        localizer,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        eventList: eventList,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        groupClassSchedulerGet,
        groupClassScheduleEventDelete,
        cleanUp: groupClassActionCreator.cleanModule
    }, dispatch
    );
}

const GroupClassViewCalendarConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassViewCalendar);
export default GroupClassViewCalendarConnected;