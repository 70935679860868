let initialState = {
    roles:[],
    selectedRole: {},
};

export default function role(state = initialState, action) {
    switch (action.type) {
        case "AUTH_FILL_ROLES":
            if (!action.roles){
                return state;
            } 
            return Object.assign({}, state, {
                roles: action.roles,
                // selectedRole:{...action.roles[0]}
            });    


        case "AUTH_CHOOSE_ROLE":
            if (!action.selectedRole) {
                return state;
            }
            return Object.assign({}, state, {
                'selectedRole':action.selectedRole
            });
        default:
            return state;
    }
}
