import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../../practice/constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import WrapperVideoCreator from '../actions/practice_wrapper_video';

const getWrapperVideoList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/wrapper_video_list";
        return Axios.post(url, params).then((response) => {
            dispatch(WrapperVideoCreator.wrapperVideoListFill(response.wrapper_videos));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveWrapperVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/wrapper_video_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Intro video has been successfully saved");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getWrapperVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/wrapper_video_get";
        return Axios.post(url, params).then((response) => {
            dispatch(WrapperVideoCreator.wrapperVideoGetFill(response.wrapper_video));
            dispatch(hideProgress());
            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteWrapperVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/wrapper_video_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Intro video has been deleted successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



export {
    getWrapperVideoList,
    saveWrapperVideo,
    getWrapperVideo,
    deleteWrapperVideo,
};