import React  from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/calendar_edit";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import { history } from '../../redux/store';
import moment from 'moment';
import {
    getCalendarEvent, postCalendarEvent, deleteCalendarEvent
} from '../../redux/calendar/network/calendar_edit';
import actions from '../../redux/calendar/actions/calendar_edit';
import { reduxForm, formValueSelector } from 'redux-form';
import Swal from 'sweetalert2';

class CalendarEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentWillMount() {
        this.props.getCalendarEvent({
            id: this.props.calendarEventId,
        });
    }

    doSave(data) {
        let params = { ...data };
        if (params.all_day) {
            params.start_time = moment(params.date).toDate();
            params.end_time = moment(params.date).toDate();
        } else {
            let start_time = moment(params.start_time);
            let end_time = moment(params.end_time);
            params.start_time = moment(params.date).hour(start_time.hour()).minute(start_time.minute()).seconds(0).toDate();
            params.end_time = moment(params.date).hour(end_time.hour()).minute(end_time.minute()).seconds(0).toDate();
        }
        this.props.postCalendarEvent({ ...params }).then(() => {
            this.props.finishEditing();
        }).catch(() => { });
    }

    delete() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete this event?",
            showCancelButton: true,
            showCloseButton: true,
            type: "info",
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.deleteCalendarEvent({ id: that.props.calendarEventId }).then(() => {
                that.props.finishEditing();
            }).catch(() => { });
        }, function (dismiss) {                        
        });        
    }

    componentWillUnmount() {
        this.props.clean();
    }

    render() {
        if (_.isEmpty(this.props.event)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

const validate = (values, props) => {
    let requiredFields = [
        'calendar_type',
        'client',
        'name',
        'date',  
        'start_time',
        'end_time'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const CalendarEditForm = reduxForm({
        form: 'CalendarEditForm',
        enableReinitialize: true,
        validate
    }
)(CalendarEdit);

const selector = formValueSelector('CalendarEditForm');

function mapStateToProps(state, ownProps) {
    let supportingData = {...state.calendar.calendarEdit.supportingData};
    supportingData.calendar_type = _.filter(supportingData.calendar_type, (item) => {
        if (item.name === "group_class" || item.name === "facility_booking") {
            return false;
        } else {
            return true;
        }
    });
    let event = { ...state.calendar.calendarEdit.event };
    let calendar_type = null;
    if(event.calendar_type) {
        calendar_type = event.calendar_type.name;    
    }    
    if (!_.isEmpty(event)) {
        event = {
            ...event,
            calendar_type: calendar_type,
            start_time: Utils.parseServerDate(event.start_time),
            end_time: Utils.parseServerDate(event.end_time),
            date: Utils.parseServerDate(event.start_time),
            client: state.calendar.calendarEdit.supportingData.event_client_id,
        };
    }
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        supportingData: supportingData,        
        selectedCalendarType: selector(state, 'calendar_type'),        
        all_day: selector(state, 'all_day'),        
        initialValues: event,
        event: event,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCalendarEvent,
        postCalendarEvent,
        deleteCalendarEvent,
        clean: actions.cleanModule,
    }, dispatch);
}


const CalendarEditConnected = connect(mapStateToProps, mapDispatchToProps)(CalendarEditForm);
export default CalendarEditConnected;