import React from 'react';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
export const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#DB303C',
        },
        '& .MuiInputLabel-root': {
            top: "-7px"
        },
        '& .MuiOutlinedInput-root': {
            background: "#ffffff",
            '&:hover fieldset': {
                borderColor: '#DB303C',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#DB303C',
            },
        },

    },
})(TextField);

export const SearchInput = (props) => {
    return <CssTextField {...props} onChange={props.onChange} InputProps={{
        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>
    }}
    />
}

const InputFunction = (props) => {
    let { meta: meta, input: input, needsOnBlur, ...mainprops } = props;
    let { value, onChange, onFocus, onBlur } = input;
    const hint = _.has(props, 'hint') ? props.hint : "";
    const support_float = _.has(props, 'data-support-float') ? props["data-support-float"] : false;
    let isNumber = false;
    let isFloat = false;
    if (_.has(props, "type")) {
        if (props.type === "number") {
            // if (support_float) {
            //     isFloat = true;
            // } else {
                isNumber = true;
            // }
        }
    }
    needsOnBlur = _.has(props, 'needsOnBlur') ? props.needsOnBlur : false;
    if (!needsOnBlur) {
        onBlur = () => { };
    }
    let min
    if (props.type === "number") {
        min = "1";
    }
    let currentValue = value;
    const error = (meta.error && (meta.dirty || meta.submitFailed)) ? meta.error : "";
    return (
        <CssTextField
            {...(!props.multiline && { style: { height: error ? 56 : 40 } })}
            {...mainprops}
            error={error}
            {...(error && { helperText: error })}
            inputProps={{ maxLength: props.maxLength, min: props.type === "number" ? 1 : null }}
            onInput={props.onInput}
            onChange={props.onChange ? props.onChange : (val) => {
                currentValue = val.target.value;
                if(currentValue==="") currentValue = null
                else if (isFloat) {
                    currentValue = parseFloat(currentValue);
                    if (isNaN(currentValue)) {
                        currentValue = null;
                    }
                }  
                onChange(currentValue);
            }}
            onBlur={() => {
                onBlur(currentValue);
            }}
            value={value}
            hint={hint}
        />
    );
};
export default InputFunction;