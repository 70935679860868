import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/video_tutorials";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import { listVideoTutorials } from '../../redux/news/network/video_tutorials';
import actionCreator from '../../redux/news/actions/video_tutorials';

class VideoTutorials extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.play = this.play.bind(this);
    }

    componentWillMount() {
        this.props.listVideoTutorials();
    }

    play(tutorial) {
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listVideoTutorials,
        cleanUp: actionCreator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        videoTutorials: state.news.videoTutorials.videoTutorials,
        ...ownProps,
    };
}

const VideoTutorialsConnected = connect(mapStateToProps, mapDispatchToProps)(VideoTutorials);
export default VideoTutorialsConnected;
