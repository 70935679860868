import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/homeFitContent";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from "../../redux/store";

class Content extends BaseComponent { 
    constructor(props){
        super(props)
        this.goToDashboard = this.goToDashboard.bind(this);
    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    goToDashboard(){
        history.push(`/practice/${this.props.selectedRole.practice.id}/home`)
    }
    render() {
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        user: state.auth.authReducer.user,
        roles: state.auth.roleReducer.roles,
    };
}

const ContentConnected = connect(mapStateToProps, mapDispatchToProps)(Content);
export default ContentConnected;
