import  React from "react";
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import HomeTemplate from "./templates/workfit_home.rt";
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import moment from 'moment';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../../redux/store';
import MenuAction from '../../../redux/core/actions/menu_state_creator';
import authActionCreator from  '../../../redux/auth/actions/auth_creator';
import {getWorkfitHome} from '../../../redux/dhf/workfit/network/workfit_network';
import { getClientConnectPractitionerList } from '../../../redux/client/network/client_network.js';
import { messageUser } from '../../../redux/message/network/message_network';
import { WORKFIT_WELCOME_VIDEO } from '../../../utils/help_video';
import HelpVideoCreator from '../../../redux/core/actions/help_video_creator';



class WorkFitHome extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doLogout = this.doLogout.bind(this);
        this.gotoAirFit = this.gotoAirFit.bind(this);
        this.gotoOfficeFit = this.gotoOfficeFit.bind(this);
        this.showMailComposer = this.showMailComposer.bind(this);
        this.hideMailComposer = this.hideMailComposer.bind(this);
        this.onMailComposerSubmit = this.onMailComposerSubmit.bind(this);
        this.chatWithPractitioner = this.chatWithPractitioner.bind(this);
        this.closeWelcomeVideo = this.closeWelcomeVideo.bind(this);
        this.state = {
            weekly_movement_score_text:"",
            homefit_pin : " - ",
            mailComposerVisible: false,
            currentlyMailingPractitioner: null,
            welcome_video: null,
            office_fit_group_id: -1,
            airfit_group_id: -1
        };
    }


    doLogout() {
        this.props.doLogout();
        Utils.removeLocalStorageValue("token");
        localStorage.removeItem("selectedRole");
        history.push("/auth/login");        }


    componentWillMount() {
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);
    }
    componentDidMount() {
        this.props.updateHelpVideo(WORKFIT_WELCOME_VIDEO);
        this.props.updateClientTitle(this.props.selectedRole.workfit_practice_name);
        this.props.getWorkfitHome({ 'workfit_practice_id': this.props.selectedRole.workfit_practice_id }).then((response) => {
            this.setState({
                office_fit_group_id: response.office_fit_group_id,
                airfit_group_id: response.airfit_group_id
            });
            if (response.has_viewed_workfit_video === false) {
                let workfit_welcome_video = {};
                workfit_welcome_video["title"] = "HomeFit Welcome Video";
                workfit_welcome_video["video_guid"] = response.workfit_welcome_video;
                this.setState({
                    welcome_video: workfit_welcome_video
                });
            }                 
        }).catch((error)=>{

        });
    }

    componentWillUnmount() {
        this.props.clearHelpVideo();
    }


    chatWithPractitioner(practitioner) {
        if (practitioner.conversation_id) { 
            history.push('/client/workfit/conversation/' + practitioner.conversation_id);
        } else {
            this.props.personalChannel({
                'to_user': practitioner.id
            }).then((response) => {
                history.push('/client/workfit/conversation/' + response.conversation_id);
            }).catch(() => {
            });
        }
    }

    showMailComposer(practitioner) {
        this.setState({
            mailComposerVisible: true,
            currentlyMailingPractitioner: practitioner,
        });
    }

    hideMailComposer() {
        this.setState({
            mailComposerVisible: false,
            currentlyMailingPractitioner: null,
        });
    }

    onMailComposerSubmit(data) {
        this.props.messageUser({
            ...data,
            'user_practice_id': this.state.currentlyMailingPractitioner.practice.id,
            'user_id': this.state.currentlyMailingPractitioner.id
        }).then(() => {
            this.hideMailComposer();
        }).catch(() => {
        });
    }

    gotoOfficeFit() {
        if (this.state.office_fit_group_id !== -1) {
            history.push("/client/workfit/group/" + this.state.office_fit_group_id);
        } else {
            Utils.showInfoAlert("Office Fit group has not been configured for your account.");
        }
    }

    gotoAirFit() {
        if (this.state.airfit_group_id !== -1) {
            history.push("/client/workfit/group/" + this.state.airfit_group_id);
        } else {
            Utils.showInfoAlert("AirFit group has not been configured for your account.");
        }
    }

    closeWelcomeVideo() {
        this.setState({
            welcome_video: null,
        });
    }


    
    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return HomeTemplate.apply(this);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        menuopen:state.core.appMenu.menu_open,
        practitioners: state.client.clientReducer.practitioners,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getWorkfitHome,
            getClientConnectPractitionerList,
            messageUser,
            doLogout: authActionCreator.doLogout,
            updateClientTitle: authActionCreator.updateClientTitle,
            updateMenuOpen:MenuAction.updateMenuOpen,
            updateMenuPinned: MenuAction.updateMenuPinned,
            updateHelpVideo: HelpVideoCreator.updateHelpVideo,
            clearHelpVideo: HelpVideoCreator.clearHelpVideo,
        }, 
        dispatch
    );
}

const WorkFitHomeConnected = connect(mapStateToProps, mapDispatchToProps)(WorkFitHome);
export default WorkFitHomeConnected;
