import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PracticeLocationEdit from '../practice_location_edit';
import PracticeLocations from '../practice_locations';
import PractitionerEdit from '../../practitioner/components/practitioner_edit';
import PractitionerUpdateBio from '../../practitioner/components/practitioner_update_bio';
import Practitioners from '../../practitioner/components/practice_practitioners';
import EnrollClientsDetails from '../../../buildmypractice/components/enroll_clients';
import RecommendGoMotive from '../../../buildmypractice/components/recommend_gomotive';
import HipaaDetails from '../hipaa';
import GdprDetails from '../gdpr';
import PractitionerServiceOfTerms from '../practitioner_service_of_terms';
import PrivacyPolicy from '../privacy_policy';
import ScheduledWorkoutsUsageReport from '../scheduled_workouts_usage_report';
import OngoingWorkoutsUsageReport from '../ongoing_workouts_usage_report';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu m-t-20'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/location`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Locations')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/practitioner`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Practitioners')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/enroll_clients`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Enroll clients on your website')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/recommend_gomotive`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Recommend GoMotive')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/hippa`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'How HIPAA Works')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/gdpr`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'How GDPR Works')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/privacy_policy`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Privacy Policy')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/practitioner_terms_of_service`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Terms of Service')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/scheduled_workouts_usage_report`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Scheduled Workouts Usage Report')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin/ongoing_workouts_usage_report`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Ongoing Workouts Usage Report'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/location/edit/:location_id`,
        'component': PracticeLocationEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/location/add`,
        'component': PracticeLocationEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/location`,
        'component': PracticeLocations
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/practitioner/edit/:practitioner_id`,
        'component': PractitionerEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/practitioner/update_bio/:practitioner_id`,
        'component': PractitionerUpdateBio
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/practitioner`,
        'component': Practitioners
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/enroll_clients`,
        'component': EnrollClientsDetails
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/recommend_gomotive`,
        'component': RecommendGoMotive
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/hippa`,
        'component': HipaaDetails
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/gdpr`,
        'component': GdprDetails
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/practitioner_terms_of_service`,
        'component': PractitionerServiceOfTerms
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/privacy_policy`,
        'component': PrivacyPolicy
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/scheduled_workouts_usage_report`,
        'component': ScheduledWorkoutsUsageReport
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/ongoing_workouts_usage_report`,
        'component': OngoingWorkoutsUsageReport
    })));
}