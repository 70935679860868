import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/enroll_clients";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import EnrollClientsAction from '../../redux/buildmypractice/actions/enroll_clients_creator';
import { getEnrollClientsDetails } from '../../redux/buildmypractice/network/enroll_clients_network';

class EnrollClients extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }
    
    componentWillMount() {
        this.props.getEnrollClientsDetails();
    }

    componentWillUnmount() {
        //this.props.cleanUp();
    }
    
    render() {
        return Template.apply(this);
    }
}
function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        enroll_clients: state.buildmypractice.enrollClientsReducer.enroll_clients
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getEnrollClientsDetails,
            cleanUp: EnrollClientsAction.servicesOfferedCleanup,
        }, 
        dispatch
    );
}


const EnrollClientsConnected = connect(mapStateToProps,mapDispatchToProps)(EnrollClients);
export default EnrollClientsConnected;
