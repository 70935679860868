import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import { SearchInput as Input } from '../../../utils/common_components/textInput_form';
import Select from 'react-select';
import TreatmentCreate from '../treatment_create';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import ButtonDefault from 'react-toolbox/lib/button';
import store from '../../../redux/store';
export default function () {
    function repeatTreatment_template1(treatment_template, treatment_templateIndex) {
        return React.createElement(Tr, {
            'key': 'treatment_template' + treatment_template.id,
            'style': { height: '50px' }
        }, React.createElement(Td, {
            'column': 'name',
            'value': treatment_template.name
        }, React.createElement('div', {
            'style': {
                display: 'flex',
                gap: '6px'
            }
        }, React.createElement('span', {}, treatment_template.name), treatment_template.is_free ? React.createElement('div', {
            'className': 'paid-icon',
            'style': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            'key': '8438'
        }, '$') : null)), React.createElement(Td, {
            'column': 'author',
            'value': treatment_template.practice.name,
            'style': {
                width: '250px',
                textAlign: 'left'
            }
        }, React.createElement('span', {}, '\n                    ', treatment_template.practice.name, '\n                ')), React.createElement(Td, {
            'column': 'is_publish',
            'style': {
                width: '170px',
                textAlign: 'left'
            }
        }, React.createElement('div', {}, treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '9064' }, '\n                                    ', treatment_template.is_publish, '\n                                    ', treatment_template.published ? React.createElement('span', { 'key': '9666' }, 'Published') : null, !treatment_template.published ? React.createElement('span', { 'key': '9762' }, 'Not Published') : null) : null, treatment_template.practice.id !== this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '9874' }, treatment_template.published ? React.createElement('span', { 'key': '9995' }, 'Published') : null, !treatment_template.published ? React.createElement('span', { 'key': '10091' }, 'Hidden') : null) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container edit-btn-small' }, treatment_template.owner !== this.props.selectedRole.practice.id && !this.props.create_treatment ? [
            ,
            React.createElement(ButtonDefault, {
                'raised': true,
                'ripple': true,
                'primary': true,
                'tooltip': 'Preview goal template',
                'onClick': event => this.goToTreatmentDashboard(event, treatment_template.id),
                'className': 'w-100 goal-template-action',
                'key': '104723'
            }, '\n                                Preview\n                        ')
        ] : null, treatment_template.owner === this.props.selectedRole.practice.id && !this.props.create_treatment ? [
            React.createElement(ButtonDefault, {
                'ripple': true,
                'raised': true,
                'primary': true,
                'tooltip': 'Edit game plan',
                'onClick': event => this.goToTreatmentDashboard(event, treatment_template.id),
                'className': 'm-r-12 goal-template-action',
                'key': '127681'
            }, '\n                                Edit\n                        '),
            React.createElement(ButtonDefault, {
                'ripple': true,
                'tooltip': 'Deactivate game plan',
                'onClick': () => {
                    this.hideTreatmentTemplate(treatment_template.id);
                },
                'style': {
                    height: '36px !important',
                    paddingTop: '5px !important'
                },
                'className': 'bg-success-border  delete-button',
                'key': '127683'
            }, '\n                            ', this.state.treatmentCategory === 'active' ? 'Hide' : 'Unhide', '\n                        ')
        ] : null, [this.props.create_treatment ? React.createElement(ButtonDefault, {
                'tooltip': 'Use this',
                'className': 'c-white bg-success-red text-capital',
                'onClick': () => {
                    this.showTreatmentCreationModal(treatment_template);
                },
                'key': '149861'
            }, '\n                            Add\n                        ') : null])));
    }
    return React.createElement('div', { 'className': 'm-r-15' }, this.props.treatment_template_list.length === 0 && this.state.treatmentCategory === 'active' ? React.createElement('div', {
        'className': 'row m-20 center-xs alert-warning',
        'key': '1693'
    }, '\n        ', this.props.match.params.treatment_type === 'group' ? 'Click on + button to create a new group template for your practice.' : 'Click on + button to create a new game plan template for your practice.', '\n    ') : null, React.createElement('div', { 'className': 'm-t-20' }, this.props.create_treatment ? React.createElement(Button, {
        'tooltip': 'Click to go back',
        'className': 'f-14 height-fit bg-success-border text-capital float-right m-r-8',
        'onClick': this.props.hideTreatmentTemplateSelection,
        'key': '5652'
    }, ' Cancel ') : null, !this.props.create_treatment ? React.createElement('div', {
        'className': 'page-title-container d-flex',
        'key': '5871'
    }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Templates'), React.createElement('h2', { 'className': 'page-title with-subtitle' }, 'Game Plans')), React.createElement('div', { 'className': 'd-flex flex-align-center flex-sb w-75' }, !this.props.create_treatment ? React.createElement(Select, {
        'className': 'col-xs-4 p-t-6',
        'clearable': false,
        'value': this.state.treatmentCategory,
        'options': this.treatmentCategories,
        'simpleValue': true,
        'onChange': val => {
            this.onChangeTreatmentCategory(val);
        },
        'style': { height: '48px' },
        'key': '6180'
    }) : null, React.createElement(Input, {
        'style': {
            width: '500px',
            marginLeft: '10px',
            marginRight: '10px',
            height: '40px'
        },
        'label': this.props.match.params.treatment_type === 'group' ? 'Search group templates' : 'Search game plan templates',
        'onChange': this.updateSearchText,
        'onKeyPress': this.searchTreatments,
        'onClick': this.inputClick
    }), React.createElement(Button, {
        'className': 'floating-add-button  red-add-button',
        'style': { top: '100px' },
        'onClick': this.addTreatment
    }, '\n                Add new\n            '))) : null, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-24',
            'id': 'table',
            'noDataText': 'Create your own game plan by clicking on + icon',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, ' Title')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '250px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Author')), React.createElement(Th, {
            'column': 'is_publish',
            'style': {
                width: '170px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Status')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Action'))),
        _.map(this.props.treatment_template_list, repeatTreatment_template1.bind(this))
    ])), this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '16097'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'tooltip': 'Load more treatment templates',
        'label': 'Load more treatment templates...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.getData(this.props, true);
        }
    }))) : null, this.state.showTreatmentCreationModal ? React.createElement(Dialog, {
        'active': this.state.showTreatmentCreationModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideTreatmentCreationModal,
        'onOverlayClick': this.hideTreatmentCreationModal,
        'key': '16612'
    }, React.createElement(TreatmentCreate, {
        'engagement_id': this.props.engagement_id,
        'store': this.props.store,
        'treatment_template': this.state.selected_treatment_template,
        'hideTreatmentCreationModal': this.hideTreatmentCreationModal
    })) : null);
}