// Action Creators
const practiceFill = (param) => ({
    type: 'PRACTICE_CREATE_FILL',
    param,
});
const cleanModule = () => ({
    type: 'PRACTICE_CREATE_CLEANUP',
});

export default {
    practiceFill,
    cleanModule,
};
