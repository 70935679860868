import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_edit_container";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { groupClassSchedulerGet } from '../../redux/calendar/network/group_class_network';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';

class GroupClassEditContainer extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.moveToReviewAndPublish = this.moveToReviewAndPublish.bind(this);
        this.state = {
            tabIndex: 0
        };
    }

    componentWillMount() {
        let params = {};
        if(this.props.match.params.group_class_id) {
            params["group_class_id"] = this.props.match.params.group_class_id;
        }
        this.props.groupClassSchedulerGet({ ...params });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    handleTabChange(tabIndex){
        this.setState({
            tabIndex: tabIndex
        });
    }

    moveToReviewAndPublish() {
        this.setState({
            tabIndex: 1,
        });
    }


    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        groupClassSchedulerGet,
        cleanUp: groupClassActionCreator.cleanModule
    }, dispatch
    );
}

const GroupClassEditContainerConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassEditContainer);
export default GroupClassEditContainerConnected;