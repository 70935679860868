import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import DatePicker from '../../../utils/common_components/datepicker_form';
import moment from 'moment';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
export default function () {
    return React.createElement('div', {
        'className': 'program-general-edit ' + (this.props.from_gameplan || this.props.group_id ? 'p-t-24 p-r-24 p-l-24' : 'p-24'),
        'style': { marginTop: '65px !important' }
    }, React.createElement('form', { 'className': this.props.from_gameplan || this.props.group_id ? 'bg-white add-media flex-column d-flex no-box-shadow' : 'bg-white add-media d-flex no-box-shadow' }, React.createElement('div', { 'className': this.props.from_gameplan || this.props.group_id ? 'media-info no-box-shadow bg-white w-100' : 'media-info no-box-shadow bg-white w-100 p-b-36' }, React.createElement(Field, {
        'label': 'Name',
        'className': 'w-100 m-b-10',
        'name': 'name',
        'type': 'text',
        'onClick': this.inputClick,
        'component': TextInput,
        'disabled': this.props.program.owner === 'false'
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'description',
        'type': 'text',
        'label': 'Description',
        'component': TextInput,
        'hint': 'Description',
        'multiline': true
    }), React.createElement('div', { 'className': 'd-flex m-b-10' }, React.createElement('div', { 'className': 'w-32 m-r-14 m-l-0' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-25',
        'name': 'schedule_type',
        'component': Select,
        'label': 'Schedule Type',
        'options': this.props.supportingData.schedule_type,
        'simpleValue': true,
        'valueKey': 'value',
        'labelKey': 'label',
        'disabled': this.props.isNonSyncGroup,
        'clearable': false
    }), this.props.isNonSyncGroup ? React.createElement('p', {
        'style': { marginTop: '-10px' },
        'className': 'material-label w-100',
        'key': '2719'
    }, '* Ongoing groups can only have ongoing workouts') : null), this.props.programType === 'quickstart' || this.props.schedule_type === 1 ? React.createElement('div', {
        'className': 'w-32',
        'key': '2902'
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'total_days',
        'type': 'number',
        'component': TextInput,
        'label': 'Length (in days)'
    })) : null, this.props.schedule_type === 2 ? [
        React.createElement('div', {
            'className': 'w-32 m-r-16',
            'key': '31651'
        }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'per_week',
            'type': 'number',
            'component': TextInput,
            'label': 'Per Week'
        })),
        React.createElement('div', {
            'className': 'w-32',
            'key': '31653'
        }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'per_day',
            'type': 'number',
            'component': TextInput,
            'label': 'Per Day'
        }))
    ] : null), this.props.programType === 'workout' && !this.props.isNonSyncGroup ? [
        React.createElement('div', {
            'className': 'w-100 m-l-0 m-t-20',
            'key': '36491'
        }, React.createElement(Field, {
            'name': 'start_date',
            'type': 'date',
            'label': 'Start Date',
            'component': DatePicker,
            'minDate': moment().subtract(1, 'days').toDate()
        })),
        React.createElement('div', {
            'className': 'w-100 m-l-0 m-t-10',
            'key': '36493'
        }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'intro',
            'component': Select,
            'label': 'Intro Video',
            'options': this.props.wrapper_videos,
            'simpleValue': true,
            'valueKey': 'id',
            'labelKey': 'title'
        }))
    ] : null, React.createElement('div', { 'className': 'd-flex m-b-10' }, this.props.programType !== 'workout' ? [
        React.createElement('div', {
            'className': 'w-33 m-r-16',
            'key': '43631'
        }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-25',
            'name': 'category',
            'component': Select,
            'label': 'Category Level 1',
            'options': this.props.supportingData.category,
            'valueKey': 'id',
            'labelKey': 'name',
            'onChange': val => {
                this.onChangeCategoryLevel1(val);
            }
        })),
        this.props.category && (this.props.category != 5 && this.props.category.id != 5) ? [
            React.createElement('div', {
                'className': 'w-50 m-r-16',
                'key': '49481'
            }, React.createElement(Field, {
                'className': 'w-100 p-t-0 m-b-25',
                'name': 'category_level2',
                'component': Select,
                'label': 'Category Level 2',
                'options': this.props.categoryLevel2,
                'valueKey': 'id',
                'labelKey': 'name',
                'onChange': val => {
                    this.onChangeCategoryLevel2(val);
                }
            })),
            React.createElement('div', {
                'className': 'w-50',
                'key': '49483'
            }, React.createElement(Field, {
                'className': 'w-100 p-t-0 m-b-25',
                'name': 'category_level3',
                'component': Select,
                'label': 'Category Level 3',
                'options': this.props.categoryLevel3,
                'valueKey': 'id',
                'labelKey': 'name'
            }))
        ] : null,
        this.props.category && (this.props.category == 5 || this.props.category.id == 5) ? [
            React.createElement('div', {
                'className': 'w-50 m-r-16',
                'key': '61601'
            }, React.createElement(Field, {
                'className': 'w-100 p-t-0 m-b-20',
                'name': 'sports',
                'component': Select,
                'label': 'Sports Name',
                'options': this.props.supportingData.sports,
                'multi': true,
                'valueKey': 'id',
                'labelKey': 'name'
            })),
            React.createElement('div', {
                'className': 'w-50',
                'key': '61603'
            }, React.createElement(Field, {
                'className': 'w-100 p-t-0 m-b-20',
                'name': 'sports_exercise_type',
                'component': Select,
                'label': 'Sports Condition',
                'options': this.props.supportingData.sports_exercise_type,
                'valueKey': 'id',
                'labelKey': 'name'
            }))
        ] : null
    ] : null), this.props.selectedRole.practice.id === 2 ? [
        React.createElement('div', {
            'className': 'row m-l-0',
            'key': '70321'
        }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Is 3DMAPS Template?'), React.createElement(Field, {
            'name': 'is_gi_3dmaps',
            'component': Switch
        })),
        React.createElement('div', {
            'className': 'row m-l-0',
            'key': '70323'
        }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Is Golf Template?'), React.createElement(Field, {
            'name': 'is_gi_golf',
            'component': Switch
        }))
    ] : null, this.props.has_scientific_evidence ? React.createElement('div', {
        'className': 'row m-l-0',
        'key': '7846'
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'scientific_evidence_text',
        'type': 'text',
        'component': TextInput,
        'label': 'Scientific Evidence',
        'multiline': true,
        'rows': 4
    }), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row end-xs' }, this.props.scientific_evidence_guid ? React.createElement('div', {
        'className': 'col m-r-10',
        'key': '8224'
    }, React.createElement('a', {
        'href': this.props.scientific_evidence_url,
        'target': '_blank',
        'className': 'm-r-5'
    }, React.createElement(Button, {
        'label': 'View Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    })), React.createElement(Button, {
        'onClick': this.deleteScientificEvidence,
        'label': 'Remove Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    })) : null, !this.props.scientific_evidence_guid ? React.createElement('div', {
        'className': 'col',
        'key': '8727'
    }, React.createElement(Dropzone, {
        'className': 'col end-xs',
        'multiple': false,
        'accept': 'application/pdf',
        'onDrop': this.uploadScientificEvidence
    }, React.createElement(Button, {
        'label': 'Upload Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    }))) : null))) : null, this.props.programType === 'quickstart' ? [,] : null, this.props.user.gi_isadmin ? React.createElement('div', { 'key': '10882' }, !this.props.from_gameplan && !this.props.group_id && !this.props.clientWorkout ? React.createElement(Card, {
        'className': 'monetization w-70 box-shadow-10 p-t-24 p-b-6 p-l-24 p-r-24 m-r-26 m-t-24 m-b-16',
        'style': { width: '97%' },
        'key': '10939'
    }, React.createElement(CardTitle, {
        'title': 'Monetization',
        'className': 'c-black form-subtitle m-b-8 f-18 w-100'
    }), React.createElement('div', { 'className': 'w-48 text-left d-flex align-center m-t-3 m-b-10' }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'is_free',
        'component': Checkbox,
        'label': 'This template is paid'
    }))) : null) : null), React.createElement('div', { 'className': this.props.from_gameplan || this.props.group_id ? 'w-100 publish-gameplan-info flex-column p-right-0 p-top-0' : 'publish-info p-absolute p-right-0 p-top-0' }, this.props.program.practice.id == this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '12111' }, React.createElement(Card, { 'className': this.props.from_gameplan || this.props.group_id ? 'bg-white no-box-shadow' : 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('div', { 'className': this.props.from_gameplan || this.props.group_id ? 'row submit-buttons end-xs middle-xs' : 'row submit-buttons end-xs m-r-10 middle-xs' }, !this.props.from_gameplan && !this.props.group_id ? React.createElement(Button, {
        'onClick': this.onDraft,
        'label': 'Save as Draft',
        'value': 'draft',
        'disabled': this.props.submitting,
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'key': '12544'
    }) : null, !this.props.disableEditing ? React.createElement(Button, {
        'onClick': this.onPublish,
        'value': 'publish',
        'className': this.props.from_gameplan || this.props.group_id && this.props.group_id ? 'bg-success m-b-12 m-l-10 w-30' : 'bg-success m-b-12 m-l-10 w-100',
        'label': !this.props.urlProgramID ? 'Next' : this.props.from_gameplan ? 'Save' : 'Publish',
        'raised': true,
        'key': '12780'
    }) : null))) : null, this.props.program.practice.id !== this.props.selectedRole.practice.id && !this.props.from_gameplan && !this.props.group_id ? React.createElement('div', { 'key': '13248' }, !this.props.isTemplatesPurchased ? React.createElement('div', { 'key': '13402' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('p', { 'className': 'paywall-msg m-b-10' }, 'To access this template and all other premium templates, please purchase the required subscription from your App Store.'), React.createElement('div', {
        'className': 'width-fit',
        'style': { margin: '0 auto' }
    }, React.createElement('a', {
        'href': 'https://apps.apple.com/us/app/dhf-coachfit/id1130877074',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to App Store',
        'className': 'paywall-appstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    }))), React.createElement('div', {
        'className': 'width-fit',
        'style': { margin: '0 auto' }
    }, React.createElement('a', {
        'href': 'https://play.google.com/store/apps/details?id=com.gomotive.dhf',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to Play Store',
        'className': 'paywall-playstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    }))))) : null) : null)));
}