import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs branding-setup' }, React.createElement(Card, { 'className': 'col-xs-10 m-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Logo',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, this.props.is_avatar_uploaded ? React.createElement('div', {
        'className': 'm-b-20 row center-xs',
        'key': '1301'
    }, React.createElement(Avatar, { 'className': 'avatar-image-100 box-100 bg-transparent' }, React.createElement('img', { 'src': this.props.logo }))) : null, React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Dropzone, {
        'className': 'dummy-suppress-warning',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadLogo
    }, React.createElement(Button, {
        'label': this.props.is_avatar_uploaded ? 'Change Logo' : 'Upload Logo',
        'primary': true,
        'raised': true
    })), this.props.is_avatar_uploaded ? React.createElement(Button, {
        'onClick': this.deleteLogo,
        'label': 'Delete Logo',
        'className': 'm-l-10 bg-danger',
        'primary': true,
        'raised': true,
        'key': '1974'
    }) : null))), React.createElement('hr', { 'className': 'dashed' }), React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'General',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Practice Name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'practice_type',
        'component': Select,
        'label': 'Practice Type',
        'options': this.props.practiceTypes,
        'valueKey': 'id',
        'labelKey': 'name',
        'simpleValue': true
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'website',
        'type': 'text',
        'component': TextInput,
        'label': 'Website'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'short_name',
        'type': 'text',
        'component': TextInput,
        'label': 'Short name for mobile display'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'facebook_link',
        'type': 'text',
        'component': TextInput,
        'label': 'Facebook Page URL'
    })))), React.createElement('hr', { 'className': 'dashed' }), React.createElement(Card, { 'className': 'm-t-10 no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Settings',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'referred_by',
        'type': 'text',
        'component': TextInput,
        'label': 'Referred by'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'npi_number',
        'type': 'text',
        'component': TextInput,
        'label': 'NPI (if applicable)'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'practitioners_count',
        'type': 'number',
        'component': TextInput,
        'label': 'Total number of practitioners'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'clients_count',
        'type': 'number',
        'component': TextInput,
        'label': 'Estimated number of clients your practice serves annually'
    })))), React.createElement('div', { 'className': 'row end-xs m-t-10 m-b-10 m-r-10' }, React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'bg-success',
        'raised': true
    }))))));
}