

let initialState = {
    partner: {},
    practice_partners:[],
    practice_partners_paginate_info: {
        page: 0,
    },    
    partner_library: {
        paginate_info: {
            page: 0,
        },
    },    
	invitation_email: {},
	partner_plans: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "AUTHOR_LIST":
            if(!action.authors) {
                return state;
            }
            return Object.assign({}, state, {
                authors: action.authors,
            });
        case "BUSINESS_PARTNER_PRACTICE_PARTNER_LIST":
            if(!action.practice_partners || !action.practice_partners_paginate_info) {
                return state;
            }
            return Object.assign({}, state, {
                practice_partners: action.practice_partners,
                practice_partners_paginate_info: action.practice_partners_paginate_info
            });
        case "BUSINESS_PARTNER_INSTANCE_FILL":
            if(!action.partner || !action.invitation_email) {
                return state;
            }
			return Object.assign({}, state, {
				partner: action.partner,
				partner_plans: action.partner_plans,
                invitation_email: action.invitation_email
            });
        case "BUSINESS_PARTNER_LIBRARY_FILL":
            if(!action.partner_library) {
                return state;
            }
            let partner_library = action.partner_library;
            if(state.partner_library.exercises) {
                partner_library.exercises = _.uniqBy(
                        [...state.partner_library.exercises,...action.partner_library.exercises],
                        'id'
                    );
            } 

            return Object.assign({}, state, {
                partner_library: partner_library,
            });
        case "BUSINESS_PARTNER_LIBRARY_EXERCISE_ADD":   
            if(!action.exercise) {
                return state;
            }
            let partner_library_exercise_add = {...state.partner_library};
            partner_library_exercise_add.exercises = [action.exercise,...state.partner_library.exercises];            
            return Object.assign({}, state, {
                partner_library: partner_library_exercise_add,
            });
        case "BUSINESS_PARTNER_LIBRARY_EXERCISE_REMOVE":   
            if(!action.exercise) {
                return state;
            }
            let partner_library_exercise_remove = {...state.partner_library};
            let exercises_after_removed = _.filter(partner_library_exercise_remove.exercises, function(exerciseObj) { 
                if(exerciseObj.id !== action.exercise.id) {
                    return true;
                }else{
                    return false;
                }                
            });
            partner_library_exercise_remove.exercises = exercises_after_removed;
            return Object.assign({}, state, {
                partner_library: partner_library_exercise_remove,
            });
        case "BUSINESS_PARTNER_LIBRARY_CLEANUP":
            return Object.assign({}, initialState);            
        default:
            return state;
    }
};