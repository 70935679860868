// Action Creators
const fillProgramList = (programList, paginateInfo) => ({
    type: 'PROGRAM_LIST_FILL',
    programList,
    paginateInfo,
});
const fillProgramListAssociated = (programList, paginateInfo) => ({
    type: 'PROGRAM_LIST_FILL_ASSOCIATED',
    programList,
    paginateInfo,
});
const updateFavoriteProgramList = (program) => ({
    type: 'PROGRAM_UPDATE_FAVORITE_FILL',
    program,
});
const insertProgramClone = (srcId, clonedProgram) => ({
    type: 'PROGRAM_INSERT_CLONE',
    srcId,
    clonedProgram,
});
const deleteProgram = (id) => ({
    type: 'PROGRAM_DELETE',
    id,
});
const cleanModule = () => ({
    type: 'PROGRAM_LIST_CLEANUP',
});
const fillProgramFilterData = (filterData,first) => ({
    type: 'PROGRAM_FILTER_DATA_FILL',
    filterData,
    first
});


export default {
    fillProgramList,
    fillProgramListAssociated,
    updateFavoriteProgramList,
    insertProgramClone,
    deleteProgram,
    cleanModule,
    fillProgramFilterData,
};