import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/group_habit_list";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { listHabits, deleteHabit } from '../../redux/group/network/group_habit';
import Swal from 'sweetalert2';
import actions from '../../redux/group/actions/group_habit';

class GroupHabitList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.editHabit = this.editHabit.bind(this);
        this.deleteHabit = this.deleteHabit.bind(this);
    };

    componentWillMount() {
        this.props.listHabits({
            id: this.props.group.id
        });
    }

    editHabit(id) {
        this.props.editHabit(id);
    }

    deleteHabit(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "You are about delete an assigned habit permanently!",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.deleteHabit({ 
                group_id: that.props.group.id, 
                habit_id: id 
            }).then((response) => {
                that.props.listHabits({
                    id: that.props.group.id
                });                
                Swal.close();
            }).catch((error) => {
                Swal.close();
            });
        }, function (dismiss) {
        });        
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        habits: state.group.groupHabit.habits,
        cleanModule: actions.cleanModule,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listHabits,
        deleteHabit,
    },
        dispatch
    );
}

const GroupHabitListConnected = connect(mapStateToProps, mapDispatchToProps)(GroupHabitList);
export default GroupHabitListConnected;