import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/education_practice.rt";
import store, { history} from '../../redux/store';
import {    
    getPracticeEducationContentList
} from '../../redux/education/network/education_network';
import swal from 'sweetalert2';
import EducationAction from '../../redux/education/actions/education_actions';

class EducationPractice extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            search: null,
            selectedPartner: null,
            selectedEducationCategory: null,
            show_education_video: false,
            education_video: {}
        };
        this.viewEducationContent = this.viewEducationContent.bind(this);
        this.closeEducationContent = this.closeEducationContent.bind(this);
        this.onSelectPartner = this.onSelectPartner.bind(this);
        this.onSelectCategory = this.onSelectCategory.bind(this);
        this.onSearch = this.onSearch.bind(this);
        
    };    

    componentWillMount() {
        this.loadEducationContentData();
    }

    loadEducationContentData(increment=false) {
        let that = this;
        this.props.getPracticeEducationContentList({            
            'search_text': this.state.search,
            'education_category_id': this.state.selectedEducationCategory,
            'partner_id': this.state.selectedPartner,
            'page':increment ?  (this.props.paginate_info.page + 1) : 0
        }).then((response) => {
            if (that.state.selectedPartner === null) {
                if (response["partner_list"].length > 0) {
                    let first_partner = response['partner_list'][0];
                    that.setState({
                        selectedPartner: first_partner["id"]
                    });
                }
            }
        }).catch((error) => { 
        });      
    }

    onSearch(text){
        this.setState({search:text},()=>{
            this.loadEducationContentData();
        });
    }

    onSelectCategory(education_category_id) {
        this.setState({ selectedEducationCategory: education_category_id, search: null }, () => {
            this.loadEducationContentData();
        });
    }

    onSelectPartner(partner_id) {
        this.setState({ selectedPartner: partner_id, selectedEducationCategory: null, search: null }, () => {
            this.loadEducationContentData();
        });
    }

    viewEducationContent(video) {
        let education_video = {};
        education_video['title'] = video.name;
        education_video['welcome_text'] = "";
        education_video["video_guid"] = video.video_guid;
        education_video['thumbnail'] = video.thumbnail;
        this.setState({
            show_education_video: true,
            education_video: education_video,
        });
    }

    closeEducationContent() {
        this.setState({
            show_education_video: false,
            education_video: {}
        });
    }


    componentWillUnmount() {     
        this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        partner_list: state.education.educationReducer.partner_list,
        education_categories: state.education.educationReducer.education_categories,
        education_content: state.education.educationReducer.education_content_list,
        paginate_info: state.education.educationReducer.paginate_info,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPracticeEducationContentList,
        cleanUp: EducationAction.cleanModule,
        }, 
        dispatch
    );
}

const EducationPracticeConnected = connect(mapStateToProps,mapDispatchToProps)(EducationPractice);
export default EducationPracticeConnected;