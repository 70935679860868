let initialState = {
    engagement: {},
    assessment: {},
};

export default function engagement(state = initialState, action) {
    switch (action.type) {
        case "ENGAGEMENT_INSTANCE_FILL":
            if (!action.engagement){
                return state;
            }
            return Object.assign({}, state, {
                engagement: action.engagement,
            });
        case "ENGAGEMENT_ASSESSMENT_INSTANCE_FILL":
            if (!action.assessment){
                return state;
            }
            return Object.assign({}, state, {
                assessment: action.assessment,
            });
        case "ENGAGEMENT_ASSESSMENT_INSTANCE_CLEANUP":
            return Object.assign({}, state, {
                assessment: {},
            });

        case "ENGAGEMENT_INSTANCE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
