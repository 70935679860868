import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ButtonDefault from 'react-toolbox/lib/button';
import { Field } from 'redux-form';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import { SearchInput as Input } from '../../../utils/common_components/textInput_form';
import Select from '../../../utils/common_components/select';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import moment from 'moment';
import DatePicker from '../../../utils/common_components/datepicker_form';
import { CSVLink } from 'react-csv';
export default function () {
    function repeatAdmin1(admin, adminIndex) {
        return React.createElement(Tr, { 'key': 'admin' + adminIndex }, React.createElement(Td, {
            'column': 'name',
            'value': admin.name
        }, React.createElement('span', {}, admin.name)), React.createElement(Td, {
            'column': 'email',
            'value': admin.email
        }, React.createElement('span', {}, '\n                    ', admin.email, '\n                ')), React.createElement(Td, { 'column': 'is_member_since' }, React.createElement('span', {}, '\n                    ', moment(admin.created_date).format('MMM D, YYYY'), '\n                ')), React.createElement(Td, {
            'column': 'number_of_clients',
            'style': { textAlign: 'center' }
        }, React.createElement('span', {}, '  \n                    ', admin.clients_count, '\n                ')), React.createElement(Td, {
            'column': 'registration_mode',
            'style': {
                textAlign: 'center',
                maxWidth: '135px',
                width: '135px'
            }
        }, React.createElement('span', {}, '\n                    ', admin.auth_method.name, '\n                ')), React.createElement(Td, { 'column': 'last_login' }, React.createElement('span', {}, '\n                   ', moment(admin.last_login).format('MMM D, YYYY'), '\n                ')));
    }
    function repeatCoach2(coach, coachIndex) {
        return React.createElement(Tr, { 'key': 'coach' + coachIndex }, React.createElement(Td, {
            'className': 'truncate',
            'column': 'name',
            'value': coach.practice.name,
            'style': { maxWidth: '200px' }
        }, React.createElement('span', {}, coach.practice.owner.name)), React.createElement(Td, {
            'className': 'truncate',
            'column': 'email',
            'value': coach.practice.owner.email,
            'style': { maxWidth: '200px' }
        }, React.createElement(TooltipWrapper, { 'tooltip': coach.practice.owner.email }, React.createElement('span', { 'className': 'truncate' }, '\n                        ', coach.practice.owner.email, '\n                    '))), React.createElement(Td, { 'column': 'is_member_since' }, React.createElement('span', {}, '\n                    ', moment(coach.practice.owner.created_date).format('MMM D, YYYY'), '\n                ')), React.createElement(Td, {
            'column': 'number_of_clients',
            'style': { maxWidth: '120px' }
        }, React.createElement('span', {}, '\n                    ', coach.practice.client_count, '\n                ')), React.createElement(Td, {
            'column': 'registration_mode',
            'style': {
                maxWidth: '135px',
                width: '135px'
            }
        }, React.createElement('span', {}, '\n                    ', coach.practice.owner.auth_method.name, '\n                ')), React.createElement(Td, {
            'column': 'payment_mode',
            'style': {
                maxWidth: '135px',
                width: '135px'
            }
        }, React.createElement('span', {}, '\n                    ', coach.webhook ? coach.webhook.source : '-', '\n                ')), React.createElement(Td, {
            'column': 'template_subscription',
            'style': {
                maxWidth: '135px',
                width: '135px',
                position: 'relative',
                left: '4px',
                textAlign: 'left'
            }
        }, React.createElement('span', {}, '\n                    ', coach.payment_status == null ? 'Not Subscribed' : coach.payment_status == 2 ? 'Subscribed' : coach.payment_status == 3 ? 'Cancelled' : 'Not Subscribed', '\n                ')), React.createElement(Td, {
            'column': 'subscription_amount',
            'style': { maxWidth: '80px' }
        }, React.createElement('span', {}, '\n                    ', coach.plan ? coach.plan.fee_amount !== 0 ? `${ '$' + coach.plan.fee_amount }` : '--' : '--', '\n                ')), React.createElement(Td, {
            'column': 'subscription_date',
            'style': { maxWidth: '180px' }
        }, React.createElement('span', {}, '\n                    ', coach.stripe_subscription === null ? '--' : Object.keys(coach.stripe_subscription).length !== 0 ? coach.stripe_subscription.transaction_date ? moment(coach.stripe_subscription.transaction_date).format('MMM D, YYYY') : moment(coach.stripe_subscription.original_transaction_date_ios).format('MMM D, YYYY') : '--', '\n                ')), React.createElement(Td, {
            'column': 'action',
            'style': { maxWidth: '180px' }
        }, React.createElement('span', {
            'className': 'capitalize cursor-pointer',
            'style': {
                top: '100px',
                color: '#DB303C'
            },
            'onClick': () => {
                this.showDetails(coach, 'coach');
            }
        }, '\n                    Check Details\n                ')));
    }
    function repeatClient3(client, clientIndex) {
        return React.createElement(Tr, { 'key': 'client' + clientIndex }, React.createElement(Td, {
            'className': 'truncate',
            'column': 'name',
            'value': client.name,
            'style': { maxWidth: '200px' }
        }, React.createElement('span', { 'className': 'truncate' }, client.name)), React.createElement(Td, {
            'className': 'truncate',
            'column': 'email',
            'value': client.email,
            'style': { maxWidth: '200px' }
        }, React.createElement(TooltipWrapper, {
            'tooltip': client.email,
            'style': {
                width: '250px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }, React.createElement('span', { 'className': 'truncate' }, '\n                        ', client.email, '\n                    '))), React.createElement(Td, {
            'column': 'is_member_since',
            'style': { maxWidth: '120px' }
        }, React.createElement('span', {}, '\n                    ', moment(client.created_date).format('MMM D, YYYY'), '\n                ')), React.createElement(Td, {
            'column': 'last_login',
            'style': { maxWidth: '120px' }
        }, React.createElement('span', {}, '\n                 ', client.last_login ? moment(client.last_login).format('MMM D, YYYY') : '--', '\n                ')), React.createElement(Td, {
            'column': 'homefit_subscription',
            'style': {
                maxWidth: '80px',
                position: 'relative',
                left: '4px',
                textAlign: 'left'
            }
        }, React.createElement('span', {}, '\n                    ', client.is_subscribed == null ? 'Not Subscribed' : client.is_subscribed ? 'Subscribed' : 'Not Subscribed', '\n                ')), React.createElement(Td, {
            'column': 'trainer_name',
            'style': {
                maxWidth: '150px',
                width: '150px !important'
            }
        }, React.createElement('span', {}, '\n                    ', client.practice ? client.practice.owner.name : '--', '\n                ')), React.createElement(Td, {
            'column': 'subscribed_trainer_name',
            'style': {
                textAlign: 'center',
                width: '100px !important'
            }
        }, React.createElement('span', {}, '\n                    ', client.is_subscribed == null ? '--' : client.is_subscribed ? client.subscription_referrer ? client.subscription_referrer.owner.name : '--' : '--', '\n                ')), React.createElement(Td, {
            'column': 'subscription_amount',
            'style': { maxWidth: '80px' }
        }, React.createElement('span', {}, '\n                ', client.dhf_move_user && Object.keys(client.dhf_move_user.stripe_subscription).length !== 0 ? client.dhf_move_user.plan ? '$' + client.dhf_move_user.plan.amount : '--' : '--', '\n                ')), React.createElement(Td, {
            'column': 'subscription_date',
            'style': { maxWidth: '180px' }
        }, React.createElement('span', {}, '\n                    ', client.dhf_move_user && Object.keys(client.dhf_move_user.stripe_subscription).length !== 0 ? client.dhf_move_user.stripe_subscription.transaction_date ? moment(client.dhf_move_user.stripe_subscription.transaction_date).format('MMM D, YYYY') : moment(client.dhf_move_user.stripe_subscription.original_transaction_date_ios).format('MMM D, YYYY') : '--', '\n                ')), React.createElement(Td, {
            'column': 'action',
            'style': { maxWidth: '180px' }
        }, React.createElement('span', {
            'className': 'capitalize cursor-pointer',
            'style': {
                top: '100px',
                color: '#DB303C'
            },
            'onClick': () => {
                this.showDetails(client, 'client');
            }
        }, '\n                    Check Details\n                ')));
    }
    return React.createElement('div', { 'className': 'user-content' }, React.createElement('div', {
        'className': 'filters-row d-flex m-t-30',
        'style': { justifyContent: 'space-between' }
    }, this.props.type === 'coaches' || this.props.type === 'clients' ? React.createElement('div', {
        'className': 'm-r-16',
        'style': {
            position: 'absolute',
            top: '-55px',
            right: '0',
            width: '407px',
            display: 'flex'
        },
        'key': '2015'
    }, React.createElement('span', {
        'style': {
            color: '#666',
            width: '122px',
            fontSize: '18px'
        }
    }, 'Filter By:'), React.createElement('div', { 'style': { width: '100%' } }, React.createElement(Field, {
        'className': 'w-6 p-t-0',
        'name': 'filterbysubscription',
        'component': Select,
        'label': 'Select Subcription List',
        'options': this.props.filterbySubscriptionOptions,
        'valueKey': 'value',
        'labelKey': 'label',
        'onChange': this.FilterBySubscription,
        'defaultValue': this.state.filterbysubcriptionToggle
    }))) : null, React.createElement('div', {
        'className': 'm-r-8 no-box-shadow p-relative search-box pull-right p-5 vertical-align m-t-10',
        'style': {
            height: '40px',
            width: '38%',
            backgroundColor: 'white !important'
        }
    }, React.createElement(Input, {
        'style': {
            width: '800px',
            height: '40px'
        },
        'label': 'Search by email',
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'name': 'search',
        'onClick': this.inputClick
    })), this.props.type == 'clients' ? React.createElement('div', {
        'className': 'w-23 m-r-16 m-l-10',
        'key': '3278'
    }, React.createElement(Field, {
        'name': 'trainer',
        'component': Select,
        'label': 'Coaches',
        'options': this.props.authors,
        'valueKey': 'id',
        'labelKey': 'practice_name',
        'onChange': this.selectTrainer
    })) : null, this.props.type == 'clients' || this.props.type == 'coaches' ? React.createElement('div', {
        'className': (this.props.type == 'clients' ? 'w-23' : 'w-23') + ' m-r-16',
        'key': '3653'
    }, React.createElement(Field, {
        'className': 'w-6 p-t-0',
        'name': 'subscription',
        'component': Select,
        'label': 'Subscription',
        'options': this.state.subscriptionOptionsData,
        'valueKey': 'value',
        'labelKey': 'label',
        'onChange': this.selectSubscription,
        'value': this.state.selectedOption
    })) : null, this.props.type == 'coaches' ? React.createElement('div', {
        'className': 'w-23 m-r-16',
        'key': '4209'
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'payment_mode',
        'component': Select,
        'label': 'Payment Mode',
        'options': this.props.PaymentOptions,
        'valueKey': 'value',
        'labelKey': 'label',
        'onChange': this.selectPayment,
        'value': this.state.selectedPayment
    })) : null, this.props.type == 'clients' || this.props.type == 'coaches' ? React.createElement('div', {
        'className': (this.props.type == 'clients' ? 'w-23' : 'w-23') + ' m-r-16',
        'key': '4682'
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'subscription_name',
        'component': Select,
        'label': 'Subscription Name',
        'options': this.props.subscriptionNameList,
        'valueKey': 'id',
        'labelKey': 'name',
        'onChange': this.selectSubscriptionName
    })) : null, this.props.type == 'clients' || this.props.type == 'coaches' ? React.createElement('div', {
        'className': this.props.type == 'clients' ? 'd-flex' : 'd-flex',
        'style': { width: '58%' },
        'key': '5197'
    }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.sendRangeDate) }, React.createElement('div', {
        'className': 'w-40 date-picker p-relative border-around m-r-16 small-input',
        'style': { height: '38px' }
    }, React.createElement(Field, {
        'name': 'start_date',
        'type': 'date',
        'onChange': this.handleStartDateChange,
        'value': this.state.startDate,
        'component': DatePicker,
        'label': 'From Date'
    })), React.createElement('div', {
        'className': 'w-40 date-picker p-relative border-around m-r-16 small-input',
        'style': { height: '38px' }
    }, React.createElement(Field, {
        'name': 'end_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'To Date'
    })), React.createElement(Button, {
        'label': 'Search Date',
        'className': 'f-14 bg-success-border m-b-12',
        'style': {
            padding: '2px 48px 0 23px',
            height: '38px'
        },
        'type': 'submit'
    }), React.createElement(Button, {
        'label': 'Export Data',
        'className': 'bg-primary m-l-12 m-r-10 text-capital',
        'style': {
            padding: '2px 48px 0 23px',
            height: '38px'
        },
        'onClick': () => this.ExportData()
    }))) : null), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable' + ' ' + true,
            'id': 'table',
            'noDataText': 'There is no user, please select different filters.',
            'isServerPaginate': true,
            'onSort': this.onSort,
            'onPageChange': this.onPageChange,
            'itemsPerPage': this.props.type == 'coaches' ? this.props.coaches_paginate_info.per_page : this.props.clients_paginate_info.per_page,
            'currentPage': this.props.type == 'coaches' ? this.props.coaches_paginate_info.page : this.props.clients_paginate_info.page,
            'numPages': this.props.type == 'coaches' ? this.props.coaches_paginate_info.total : this.props.clients_paginate_info.total
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'name',
            'style': { width: '200px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'email',
            'style': { width: '200px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Email')), React.createElement(Th, {
            'column': 'is_member_since',
            'style': { width: '120px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Member since')), this.props.type == 'coaches' || this.props.type == 'admins' ? React.createElement(Th, {
            'column': 'number_of_clients',
            'style': { width: '120px' },
            'key': '7732'
        }, React.createElement('span', { 'className': 'table-heading' }, 'No. of Clients')) : null, this.props.type == 'coaches' || this.props.type == 'admins' ? React.createElement(Th, {
            'column': 'registration_mode',
            'key': '7947'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Registration Mode')) : null, this.props.type == 'coaches' ? React.createElement(Th, {
            'column': 'payment_mode',
            'style': { width: '140px' },
            'key': '8144'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Payment Mode')) : null, this.props.type == 'clients' || this.props.type == 'admins' ? React.createElement(Th, {
            'column': 'last_login',
            'style': { width: '120px' },
            'key': '8322'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Last login')) : null, this.props.type == 'clients' ? React.createElement(Th, {
            'column': 'trainer_name',
            'style': { width: '200px' },
            'key': '8524'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Trainer')) : null, this.props.type == 'coaches' ? React.createElement(Th, {
            'column': 'template_subscription',
            'style': { width: '150px' },
            'key': '8696'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Status')) : null, this.props.type == 'clients' ? React.createElement(Th, {
            'column': 'homefit_subscription',
            'style': { width: '150px' },
            'key': '8888'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Status')) : null, this.props.type == 'coaches' || this.props.type == 'clients' ? React.createElement(Th, {
            'column': 'action',
            'key': '9748'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscriptions Details')) : null),
        this.props.type == 'admins' ? _.map(this.props.adminList, repeatAdmin1.bind(this)) : null,
        this.props.type == 'coaches' ? _.map(this.props.practice_partners, repeatCoach2.bind(this)) : null,
        this.props.type == 'clients' ? _.map(this.props.clientList, repeatClient3.bind(this)) : null
    ]), React.createElement(Dialog, {
        'active': this.state.showReport,
        'className': 'full-dialog bg-beige',
        'style': { minHeight: '100px !important' },
        'onEscKeyDown': this.hideReport,
        'onOverlayClick': this.hideReport
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-20px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.hideReport
    })), React.createElement('div', {}, React.createElement(CSVLink, {
        'filename': this.props.type == 'clients' ? 'Clients_User_Report' : 'Coaches_User_Report',
        'data': this.state.rows
    }, 'Download the Report'))));
}