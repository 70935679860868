
const fillProgression = (progression, supportingData) => ({
    type: 'PROGRAM_PROGRESSION_FILL',
    progression,
    supportingData,
});
const cleanModule = (progression) => ({
    type: 'PROGRAM_PROGRESSION_CLEANUP',
    progression,
});
export default {
    fillProgression,
    cleanModule,
};