
import React from 'react';
import {Tab, Tabs} from 'react-toolbox';
import BundlesContent from './bundles_content';

class TabsOptions extends React.Component {
  
  state = {
      index: 0,
      fixedIndex: 0,
      inverseIndex: 1,
      selectedIndex:0
  };
    

  handleTabChange = (index) => {
    this.setState({index});
  };

  handleFixedTabChange = (index) => {
    this.setState({
      fixedIndex: index , 
    });
  };

  handleInverseTabChange = (index) => {
    this.setState({inverseIndex: index});
  };

  handleSelect = (index) => {
    this.setState({selectedIndex: index})
}

  render () {
    return (
        <Tabs class="tabs_title" index={this.state.fixedIndex} onChange={this.handleFixedTabChange} fixed>
          <Tab activeClassName="active-tab" label='Workouts Templates'><BundlesContent type='workouts' /></Tab>
          <Tab activeClassName="active-tab" label='Mini Courses'><BundlesContent type='mini_courses'/></Tab>
        </Tabs>                                                                                                                                                                 
    );
  }
}

export default TabsOptions;