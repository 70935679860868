import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../../practice/constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import EnrollClientsCreator from '../actions/enroll_clients_creator';

const getEnrollClientsDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_enroll_clients";
        return Axios.post(url, params).then((response) => {
            dispatch(EnrollClientsCreator.enrollClientsFill(response.data));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    getEnrollClientsDetails,
};