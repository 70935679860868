
import React from 'react';
import { Tab, Tabs } from 'react-toolbox';
import UsersContent from './users_content';

class TabsOptions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
      fixedIndex: 0,
      inverseIndex: 1,
      selectedIndex: 0,
      tabchange: false
    };
  }

  componentDidMount() {
    if (this.props?.propsData?.location?.type === "coaches") {
      this.setState({
        fixedIndex: 1
      })
    }
    else if (this.props?.propsData?.location?.type === "clients") {
      this.setState({
        fixedIndex: 2
      })
    }
    else {
      this.setState({
        fixedIndex: 0
      })
    }

  }

  handleTabChange = (index) => {
    this.setState({index});
  };

  handleFixedTabChange = (index) => {
    this.setState({
      fixedIndex: index,
      tabchange: true
    });
  };

  handleInverseTabChange = (index) => {
    this.setState({inverseIndex: index});
  };

  handleSelect = (index) => {
    this.setState({selectedIndex: index});
  }

  render() {
    return (
      <Tabs class="tabs_title" index={this.state.fixedIndex} onChange={this.handleFixedTabChange} fixed>
        {this.props.isAdmin || this.props.isGiAdmin ? <Tab activeClassName="active-tab" label='Admins'><UsersContent tabchange={this.state.tabchange} type='admins' /></Tab> : ""}
        {this.props.isAdmin || this.props.isGiAdmin ? <Tab activeClassName="active-tab" label='Coaches'><UsersContent tabchange={this.state.tabchange} type='coaches' /></Tab> : ""}
        {this.props.isAdmin ? <Tab activeClassName="active-tab" label='Clients'><UsersContent tabchange={this.state.tabchange} type='clients' /></Tab> : ""}
      </Tabs>

    );
  }
}

export default TabsOptions;