// Action Creators
const practiceLocationListFill = (locations) => ({
    type: 'PRACTICE_LOCATION_LIST_FILL',
    locations,
});
const practiceLocationFill = (data) => ({
    type: 'PRACTICE_LOCATION_FILL',
    data,
});
const cleanModule = () => ({
    type: 'PRACTICE_LOCATION_CLEANUP',
});

export default {
    practiceLocationListFill,
    cleanModule,
    practiceLocationFill,
};
