import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'col-xs-6 m-20',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, {}, React.createElement(CardTitle, {
        'title': 'Create Practice',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5' }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Practice Name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'city',
        'type': 'text',
        'component': TextInput,
        'label': 'City'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'zipcode',
        'type': 'number',
        'component': TextInput,
        'label': 'Zipcode'
    }))), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Create',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': () => {
            this.doCancel();
        },
        'type': 'button',
        'raised': true
    })))));
}