import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/client_group_workout_preview.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import clientGroupProgressionDatesAction from '../../redux/group/actions/client_group_progression_dates_creator';
import {  getGroupClientProgressionPerDate,groupTrackHabit } from '../../redux/group/network/client_group_network';
import moment from 'moment';
import roleReducer from '../../redux/auth/reducers/role_reducer';
import { postProgressionProgress } from '../../redux/program/network/workout_progress';

class ClientGroupWorkoutPreview extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        let todayString = moment().format("YYYY-MM-DD");
        this.goBack = this.goBack.bind(this);
        this.goToGroupHome = this.goToGroupHome.bind(this);
        this.trackWorkout = this.trackWorkout.bind(this);
        this.playWorkout = this.playWorkout.bind(this);
        this.trackHabit = this.trackHabit.bind(this);
        this.trackProgressionCallback = this.trackProgressionCallback.bind(this);
        this.state = { today_string: todayString,selectedDateString:""};
    };
    
    componentWillMount() {
        if (!this.props.match.params.group_id  ||  !this.props.match.params.date ) {
            history.goBack();
        }else{
            let d = moment(this.props.match.params.date,'YYYY-MM-DD');
            if(d == null || !d.isValid()){
                history.goBack();
                Utils.showWarningAlert("Workout cannot be previewed for this date");
            }   
            this.setState({selectedDateString:d.format("MMMM Do YYYY")});
            this.props.cleanUp([]);
            this.loadWorkouts();
        }  
    }
    
    loadWorkouts() {
          this.props.getGroupClientProgressionPerDate({ id: this.props.match.params.group_id,date: this.props.match.params.date});
    }

    trackProgressionCallback(params){
        this.props.postProgressionProgress(params)
        .then((response)=>{
            this.loadWorkouts(); 
        }).catch((error)=>{

        });
    }

    goBack(){
        history.goBack();
    }
    goToGroupHome(){
        history.push("/client/group/"+this.props.match.params.group_id);
    }

    playWorkout(progression){
        if (this.props.selectedRole.role.name === "client"){
            history.push("/client/group/"+this.props.match.params.group_id+"/workout/play/"+progression.id+"/?date="+this.props.match.params.date);
        }else if (this.props.selectedRole.role.name === "practitioner" || this.props.selectedRole.role.name === "practice_admin" ){
            history.push("/practice/"+this.props.selectedRole.practice.id+"/client/group/"+this.props.match.params.group_id+"/workout/play/"+progression.id+"/?date="+this.props.match.params.date);
        }        
    }
    trackWorkout(progression){
        if (this.props.selectedRole.role.name === "client"){
            history.push("/client/group/"+this.props.match.params.group_id+"/workout/track/"+progression.id+"/"+this.props.match.params.date);
        }else if (this.props.selectedRole.role.name === "practitioner" || this.props.selectedRole.role.name === "practice_admin" ){
            history.push("/practice/"+this.props.selectedRole.practice.id+"/client/group/"+this.props.match.params.group_id+"/workout/track/"+progression.id);
        }          
    }

    trackHabit(habit, track_status) {
        let params = {};
        params['group_id'] = this.props.group.id;
        params['habit_track_id'] = habit.id;
        params['track_status'] = track_status;
        this.props.groupTrackHabit(params).then(() => {
            this.loadWorkouts(); 
        });
    }


    componentWillUnmount() {
        this.props.cleanUp([],[],{});
    }


    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state, ownprops) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        progression_preview: state.group.clientGroupProgressionDatesReducer.progression_preview,
        habits: state.group.clientGroupProgressionDatesReducer.habits,
        group: state.group.clientGroupProgressionDatesReducer.group
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getGroupClientProgressionPerDate,
            groupTrackHabit,
            postProgressionProgress,
            cleanUp: clientGroupProgressionDatesAction.fillProgressionDateInstance
        }, 
        dispatch
    );
}

const ClientGroupWorkoutPreviewConnected = connect(mapStateToProps,mapDispatchToProps)(ClientGroupWorkoutPreview);
export default ClientGroupWorkoutPreviewConnected;
