import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/webinar_list.rt";
import {history} from '../../../redux/store';
import { verifyToken } from '../../../redux/auth/network/auth_network';
import Utils from '../../../utils/utils';
import BaseComponent from '../../../utils/common_components/basecomponent';
import MenuAction from '../../../redux/core/actions/menu_state_creator';
import { getWebinarList,deleteWebinar,publishWebinar } from '../../../redux/dhf/move/network/webinar_network';

class WebinarList extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);    
        this.createWebinar = this.createWebinar.bind(this);
        this.deleteWebinar = this.deleteWebinar.bind(this);
        this.publishingWebinar = this.publishingWebinar.bind(this);
    }
    
    componentWillMount() { 
        this.props.getWebinarList();     
    }

    createWebinar() {  
        history.push(`${this.props.match.url}/add`);
    }

    editWebinar(webinar) {  
        history.push(`${this.props.match.url}/${webinar.id}/edit`);
    }

    deleteWebinar(webinar) {
        let params = {webinar_id: webinar.id};
        this.props.deleteWebinar(params).then((response) => {
            if(response.status === "200"){
                Utils.showSuccessAlert(webinar.title + "Webinar has been deleted");
            }else{
                Utils.showDangerAlert("something went wrong");
            }
            this.props.getWebinarList(); 
        }).catch(() => { });
    }

    publishingWebinar(webinar, publish_status) {
        this.props.publishWebinar(webinar.id,publish_status).then((response) => {
            if(response.status === "200"){
                Utils.showSuccessAlert('Publish status has been changed');
            }else{
                Utils.showDangerAlert("something went wrong");
            }
            this.props.getWebinarList(); 
        }).catch(() => { });
    }

    render() {
        if (!this.props.isAuthenticated || _.isEmpty(this.props.selectedRole)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar type='circular' mode='indeterminate' multicolor />
            </div>);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            verifyToken,
            updateMenuOpen: MenuAction.updateMenuOpen,
            getWebinarList,
            deleteWebinar,
            publishWebinar
        },
        dispatch
    );
}
function mapStateToProps(state, ownProps) {    
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        // new 
        webinars: state.move.webinarReducer.webinars

    };
}


const WorkfitNotificationScheduleConnected = connect(mapStateToProps, mapDispatchToProps)(WebinarList);
export default WorkfitNotificationScheduleConnected;
