import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { history } from '../../../redux/store';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement(Card, { 'className': 'col-xs-12  p-0' }, React.createElement(CardTitle, { 'title': 'Edit Client Plan' }), React.createElement(CardText, { 'className': 'p-t-5' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    }), React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'description',
        'id': 'descriptionRichText',
        'type': 'text',
        'basic_toolbar': true,
        'component': RichTextEditor,
        'label': 'Description'
    }), this.props.plan.subscription_count === 0 ? React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'fee_amount',
        'type': 'number',
        'component': TextInput,
        'label': 'Monthly Subscription Amount',
        'key': '2260'
    }) : null, this.props.plan.subscription_count > 0 ? React.createElement('div', {
        'className': 'alert-warning text-center m-10 p-10',
        'key': '2669'
    }, '\n                            Currently there are ', this.props.plan.subscription_count, ' client(s) associated with this plan. Thus the pricing of the plan cannot be modified\n                        ') : null, this.props.plan.subscription_count === 0 && this.props.plan.has_been_assigned ? React.createElement('div', {
        'className': 'alert-warning text-center m-10 p-10',
        'key': '2993'
    }, '\n                            You have already assigned this payment plan to game plan and/or groups. Hence you cannot unpublish this plan.\n                        ') : null, React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Available for Use',
        'className': 'bg-success-red',
        'onClick': this.onPublish,
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true
    }), this.props.plan.subscription_count === 0 && !this.props.plan.has_been_assigned ? React.createElement(Button, {
        'label': 'Save as Draft',
        'className': 'bg-primary m-l-10',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true,
        'key': '3718'
    }) : null, React.createElement(Button, {
        'label': 'Cancel',
        'className': 'm-l-10',
        'onClick': () => {
            history.goBack();
        },
        'disabled': this.props.submitting,
        'type': 'button',
        'accent': true,
        'raised': true
    })))))))));
}