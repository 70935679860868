import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ProfileEdit from '../../../user/components/profile_edit';
import UserEmailUpdate from '../../../user/components/user_email_update';
import UserPasswordUpdate from '../../../user/components/user_password_update';
import MetricSettings from '../../../user/components/metric_settings';
import SearchPreference from '../../../user/components/search_preferences';
import PracticeCreate from '../../../practice/components/practice_create';
import VideoTutorials from '../../../news/components/video_tutorials';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/settings/search_preference`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Search Preferences')), React.createElement(NavLink, {
        'target': '_blank',
        'to': '/site_media/static/files/product_text_tutorial.pdf',
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Text Tutorials'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/profile_edit`,
        'component': ProfileEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/change_email`,
        'component': UserEmailUpdate
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/update_password`,
        'component': UserPasswordUpdate
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/metric_settings`,
        'component': MetricSettings
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/search_preference`,
        'component': SearchPreference
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/practice/create`,
        'component': PracticeCreate
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/video_tutorials`,
        'component': VideoTutorials
    })));
}