import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import ClientListCreator from '../actions/client_list_creator';

const getClientList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_list";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientListCreator.clientListFill(
                response.clients,
                response.paginate_info,
                response.group_count,    
                response.support_multiple_gameplan,
                response.is_workfit_practice
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getAllClientList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/all_client_list";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientListCreator.allClientListFill(
                response.clients,
                response.paginate_info,
                response.group_count,    
                response.support_multiple_gameplan,
                response.is_workfit_practice
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getAllClientListForReport = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/all_client_list";
        return Axios.post(url, params).then((response) => {
            // dispatch(ClientListCreator.allClientListFill(
            //     response.clients,
            //     response.paginate_info,
            //     response.group_count,    
            //     response.support_multiple_gameplan,
            //     response.is_workfit_practice
            // ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getClientFilters = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_filter_list";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientListCreator.clientFilterFill(
                response.client_selection_list,
                response.practitioner_list,
                response.consultant_list,                
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const inviteClient = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_invite";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Your client has been successfully invited to join your practice");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const resendClientInvitation = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_resend_invitation";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("New invitation email has been sent to your client");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const clientToggleVisibility = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_toggle_visibility";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            if (params.visibility_type === "hide") {
                Utils.showSuccessAlert("Your client has been hidden from your practice");
            } else {
                Utils.showSuccessAlert("Your hidden client has been activated");
            }
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteClientFromPractice = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_practice_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Your client has been removed from your practice");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const clientResetPassword = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_reset_password";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Your client password has been reset. Kindly inform the new password to your client and ask them to change the password after sign-in.");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



export {
    getClientList,
    getAllClientList,
    inviteClient,
    resendClientInvitation,
    clientToggleVisibility,
    getClientFilters,
    deleteClientFromPractice,
    clientResetPassword,
    getAllClientListForReport,
};