let initialState = {
    wrapper_videos: [],
    wrapper_video: {}
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "WRAPPER_VIDEO_LIST_FILL":
            if (!action.wrapper_videos){
                return state;
            }
            return Object.assign({}, state, {
                wrapper_videos: action.wrapper_videos
            });
        case "WRAPPER_VIDEO_GET_FILL":
            if (!action.wrapper_video){
                return state;
            }
            return Object.assign({}, state, {
                wrapper_video: action.wrapper_video
            });
        case "WRAPPER_VIDEO_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
