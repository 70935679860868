let initialState = {
    homefitLog: [],
    GiLog: [],
    homefit_paginate_info: {},
    gi_paginate_info: {},
    subscription_list: []
    // coach: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "HOMEFIT_SUBSCRIPTION_LOG_FILL":
            if (!action.data){
                return state;
            }
            return Object.assign({}, state, {
                homefitLog: action.data,
                homefit_paginate_info: action.paginate_info
            });
        case "GI_SUBSCRIPTION_LOG_FILL":
            if (!action.data){
                return state;
            }
            return Object.assign({}, state, {
                GiLog: action.data,
                gi_paginate_info: action.paginate_info
            });
            case "SUBSCRIPTION_LIST_FILL":
                if (!action.subscription_list){
                    return state;
                }
                return Object.assign({}, state, {
                    subscription_list: action.subscription_list,
                });
        default:
            return state;
    }
};