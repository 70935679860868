import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import moment from 'moment';
import Template from "./templates/group_goal_tracking_latest";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
// import { getClientGroupGoalTrackingList , removeClientFromGroup, addTreatmentToGroupClient} from '../../redux/group/network/client_goal_tracking';
import { getClientGroupGoalTrackingList , removeClientFromGroup, addTreatmentToGroupClient} from '../../redux/group/network/client_goal_tracking_new';
import Swal from 'sweetalert2';
import actions from '../../redux/group/actions/client_goal_tracking';
import { getGroupAvailableClientList} from '../../redux/group/network/group_network_new';

class GroupLatestGoalTracking extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.showGraph = this.showGraph.bind(this);
        this.hideGraph = this.hideGraph.bind(this);
        this.loadMoreClients = this.loadMoreClients.bind(this);
        this.deleteClient = this.deleteClient.bind(this);
        this.hideClientAddModal = this.hideClientAddModal.bind(this);
        this.showClientAddModal = this.showClientAddModal.bind(this);
        this.hideTreatmentAddModal = this.hideTreatmentAddModal.bind(this);
        this.showTreatmentAddModal = this.showTreatmentAddModal.bind(this);
        this.onClickTreatmentCheckbox = this.onClickTreatmentCheckbox.bind(this);
        this.onClickHabitCheckbox = this.onClickHabitCheckbox.bind(this);
        this.changeSelectedDate = this.changeSelectedDate.bind(this);
        this.changeTreatmentDate = this.changeTreatmentDate.bind(this);
        this.changeHabitDate = this.changeHabitDate.bind(this);
        this.addClientTreatment = this.addClientTreatment.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.state = {
            search:"",
            selectedClient: null,
            showGraph: false,
            openClientAddModal: false,
            openTreatmentAddModal: false,
            selectedTreatment:[],
            selectedHabits:[],
            today:moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
            selectedDate:moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
        };
    };

    componentWillMount() {
        this.props.getClientGroupGoalTrackingList({
            group_id: this.props.group.id,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id,
            ...this.props.paginate_info,
        });
        this.props.getGroupAvailableClientList({
            id:this.props.group.id,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id,
        });
    }


    loadMoreClients(increment = true){
        let page = this.props.paginate_info.page + 1;
        if(!increment){
             page = 0;
        }
        this.props.getClientGroupGoalTrackingList({
            group_id: this.props.group.id,
            page:page,
            search: this.state.search,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id,
        });
    }

    onSearch(text){
        this.setState({search:text},()=>{
                this.loadMoreClients(false);
        });
    }

    hideClientAddModal() {
        this.setState({ openClientAddModal: false});
    }
    showClientAddModal() {
        this.setState({ openClientAddModal: true});
    }
    hideTreatmentAddModal() {
        this.setState({ openTreatmentAddModal: false,selectedClient: null,selectedTreatment:[],selectedHabits:[]});
    }
    showTreatmentAddModal(client) {
        let clientObj = {...client};
        let clientWorkoutIds = _.map(clientObj.client_workouts,"id");
        let client_workouts_dict = _.keyBy(clientObj.client_workouts,"id");
        let clientHabitsIds = _.map(clientObj.client_habits,"id");
        let client_habits_dict = _.keyBy(clientObj.client_habits,"id");
        let selectedTreatments = [];
        let selectedHabits = [];
        clientObj.workouts = _.map(clientObj.workouts,(itemObj)=>{
                let item = {...itemObj};
                if(_.includes(clientWorkoutIds, item.id)){
                    let start_date = moment(client_workouts_dict[item.id].start_date,"YYYY-MM-DD").hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
                    item.original_selected = true;
                    item.start_date = start_date;
                    selectedTreatments.push(item);
                }else{
                    item.selected = true;
                    item.start_date = this.state.today;
                    selectedTreatments.push(item);
                }
                return item;
        });
        clientObj.habits = _.map(clientObj.habits,(itemObj)=>{
            let item = {...itemObj};
                if(_.includes(clientHabitsIds, item.id)){
                    let start_date = moment(client_habits_dict[item.id].start_date,"YYYY-MM-DD").hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
                    item.original_selected = true;
                    item.start_date = start_date;
                    selectedHabits.push(item);
                }else{
                    item.selected = true;
                    item.start_date = this.state.today;
                    selectedHabits.push(item);
                }
                return item;
        });
        this.setState({ openTreatmentAddModal: true,selectedClient: clientObj,selectedTreatment:selectedTreatments,selectedHabits:selectedHabits});
    }

    onClickTreatmentCheckbox(data,boolVal){
        let updatedSelection;
        if(boolVal){
            data.start_date = this.state.selectedDate;
            updatedSelection = this.state.selectedTreatment.concat(data);
        } else {
            updatedSelection = _.filter(this.state.selectedTreatment,(item)=>{
                return item.id !== data.id;
            });
        }
        this.setState({
            selectedTreatment:updatedSelection,
        });
    }
    onClickHabitCheckbox(data,boolVal){
        let updatedSelection;
        if(boolVal){
            data.start_date = this.state.selectedDate;
            updatedSelection = this.state.selectedHabits.concat(data);
        } else {
            updatedSelection = _.filter(this.state.selectedHabits,(item)=>{
                return item.id !== data.id;
            });
        }
        this.setState({
            selectedHabits:updatedSelection,
        });
    }

    changeSelectedDate(date){
        let selectedTreatment =  _.map(this.state.selectedTreatment,(item)=>{
            if(!item.original_selected && item.start_date >= this.state.today){
                item.start_date = date;
            }    
            return item;
        });
        let selectedHabits =  _.map(this.state.selectedHabits,(item)=>{
            if(!item.original_selected && item.start_date >= this.state.today){
                item.start_date = date;
            }     
            return item;
        });
        this.setState({selectedDate:date,selectedTreatment:selectedTreatment,selectedHabits:selectedHabits});
    }

    changeTreatmentDate(date,workout){
        let selectedTreatment =  _.map(this.state.selectedTreatment,(item)=>{
            if(item.id === workout.id){
                item.start_date = date;
            }    
            return item;
        });
        this.setState({selectedTreatment:selectedTreatment});
    }
    changeHabitDate(date,habit){
        let selectedHabits =  _.map(this.state.selectedHabits,(item)=>{
            if(item.id === habit.id){
                item.start_date = date;
            }    
            return item;
        });
        this.setState({selectedHabits:selectedHabits});
    }

    deleteClient(client_id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to remove the client from this group?",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then((click) => {
            if(click.value){
                that.props.removeClientFromGroup({ 
                    client: client_id,id:that.props.group.id,
                    app_version: "5.1.12",
                    current_role_type: "practitioner",
                    package_version: "2",
                    practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id,
                }).then(() => {
                }).catch(() => { });
            }
            Swal.close()
        }, ()=> { });
    }   
     

    showGraph(client) {
        this.setState({
            selectedClient: client,
            showGraph: true,
        });
    }

    hideGraph() {
        this.setState({
            selectedClient: null,
            showGraph: false,
        });
    }

    addClientTreatment(){
        let workouts = [...this.state.selectedTreatment];
        workouts = _.filter(workouts,(item)=>{
            if(item.start_date < this.state.today){
                return false;
            }else{
                return true;
            }
        });
        workouts = _.map(workouts,(itemObj)=>{
            let item = _.pick(itemObj,['id','start_date']);
            item.start_date = moment(item.start_date).format("YYYY-MM-DD");
            return item;
        });
        let habits = [...this.state.selectedHabits];
        habits = _.filter(habits,(item)=>{
            if(item.start_date < this.state.today){
                return false;
            }else{
                return true;
            }
        });
        habits = _.map(habits,(itemObj)=>{
            let item = _.pick(itemObj,['id','start_date']);
            item.start_date = moment(item.start_date).format("YYYY-MM-DD");
            return item;
        });
        let params = {
            id:this.props.group.id,
            workouts: workouts,
            habits: habits,
            client: this.state.selectedClient.id,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id,
        };
        this.props.addTreatmentToGroupClient(params).then((response)=>{
            this.hideTreatmentAddModal();
        }).catch((error)=>{

        });
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownProps) {
    let trackings = [...state.group.clientGoalTracking.trackings];
    trackings = _.map(trackings,(itemObj)=>{
        let item = {...itemObj};
        if(item.treatment_started_date !== null){
            item.treatment_started_date = moment(item.treatment_started_date ).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        }
        return item;  
    });


    return {
        store,
        user: state.auth.authReducer.user,
        group:ownProps.group,
        trackings:trackings,
        paginate_info: state.group.clientGoalTracking.paginate_info,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientGroupGoalTrackingList,
        addTreatmentToGroupClient,
        removeClientFromGroup,
        getGroupAvailableClientList,
        cleanModule: actions.cleanModule,
    }, dispatch);
}

const GroupLatestGoalTrackingConnected = connect(mapStateToProps, mapDispatchToProps)(GroupLatestGoalTracking);
export default GroupLatestGoalTrackingConnected;