import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/webinar_add.rt";
import Utils from '../../../utils/utils';
import { history} from '../../../redux/store';
import { reduxForm } from 'redux-form';
import { webinarCreate } from '../../../redux/dhf/move/network/webinar_network';
import WebinarAction from '../../../redux/dhf/move/actions/webinar_actions';
import { uploadFiles } from '../../../redux/core/network/file_upload';
import moment from 'moment';
import { param } from 'jquery';


class WebinarCreate extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);   
        this.doCancel = this.doCancel.bind(this);     
        this.webinarCreate = this.webinarCreate.bind(this); 
        this.holdImage = this.holdImage.bind(this);   
        this.deleteholdImage = this.deleteholdImage.bind(this);   
        
        this.state = {
            holdImage:{}
        };
    };

    webinarCreate(data) {
        let params = {...data};
        let time = moment(params.publish_time);
        
        params.publish_datetime = moment(params.publish_date).hour(time.hour()).minute(time.minute()).seconds(0).toDate();
        params.duration = moment.utc().startOf('day').add(params.duration, 'minutes').format('HH:mm');

        delete params.publish_date;   
        delete params.publish_time;    
        if(Object.keys(this.state.holdImage).length){
            this.uploadImage(this.state.holdImage).then((res)=>{
                if(res.thumbnail_url){
                    params.thumbnail_url = res.thumbnail_url;
                    this.props.webinarCreate(params).then((response) => {
                        if(response.status === "200"){
                            history.push("/practice/" + this.props.selectedRole.practice.id + "/move/webinar");
                        }
                    }).catch((error) => {
                    });
                }else{
                    Utils.showErrorAlert("Upload error");

                }
            }); 
        }else{

            Utils.showErrorAlert("Please select a thumbnail image");
        }
    }
    
    holdImage(files){

        this.setState({
            holdImage:files
        });

    }
    deleteholdImage(){

        this.setState({
            holdImage:{}
        });
    }

    uploadImage(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        return this.props.uploadFiles(formData).then((resp)=>{
            return resp.upload_results[0];
        }).catch(()=>{ });
    }
    

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/move/webinar");
    }
    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        webinar: state.webinar,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            webinarCreate,     
            uploadFiles,    
            cleanUp: WebinarAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'title',
        'duration',
        'publish_date',
        'publish_time',
        'shopify_link',
    ];
    const errors = {};
    let regex = "((http|https)://)[a-zA-Z0-9@:%._\\+~#?&//=-]{2,256}\.[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
        if(values['shopify_link']){
            console.log('values[shopify_link]',values['shopify_link']);
            console.log('match',values['shopify_link'].match(regex));
            if(!values['shopify_link'].match(regex)){
                errors['shopify_link'] = 'Enter a valid url i.e http://www.example.com'; 
            }
        }
    });

    return errors;

};

const WebinarCreateFormContainer = reduxForm({
    form: 'WebinarCreateForm', 
    enableReinitialize: true, 
    validate
})(WebinarCreate);


const WebinarCreateForm = connect(mapStateToProps,mapDispatchToProps)(WebinarCreateFormContainer);
export default WebinarCreateForm;