let initialState = {
    practice: {},
    practiceTypes: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_DETAILS_FILL":
            return Object.assign({}, state, {
                practice: action.data,
                practiceTypes: action.supporting_data.practice_types
            });
        case "PRACTICE_DETAILS_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
