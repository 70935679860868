import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import moment from 'moment';
import { getClientCheckinList } from '../../redux/client/network/client_network.js';
import Template from './templates/client_checkin_list.rt';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import { postProgressionProgress } from '../../redux/program/network/workout_progress';
import {  engagementTrackHabit } from '../../redux/engagement/network/client_engagement_network';
import { groupTrackHabit } from '../../redux/group/network/client_group_network';
import ClientAction from '../../redux/client/actions/client_actions';

class ClientCheckinList extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        let selectedDate = moment().minutes(0).seconds(0).milliseconds(0);
        this.callBackAfterSave = this.callBackAfterSave.bind(this);
        this.loadPage = this.loadPage.bind(this); 
        this.trackProgressionCallback = this.trackProgressionCallback.bind(this); 
        this.trackEngagementHabitCallback = this.trackEngagementHabitCallback.bind(this); 
        this.trackGroupHabitCallback = this.trackGroupHabitCallback.bind(this); 
        this.changeSelectedDate = this.changeSelectedDate.bind(this); 
        this.gotoAllWorkouts = this.gotoAllWorkouts.bind(this); 
        this.state={
            displayGoalSelectionPage: false,
            selectedDateString:selectedDate.format("YYYY-MM-DD"),
            selectedDate:selectedDate.toDate(),
            type:'start',
            fetch_type:'all',
        };
    };

    gotoAllWorkouts() {
        history.push('/client/all_workouts');
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.available_dates, nextProps.available_dates)) {
            if (nextProps.available_dates.length > 0) {
                let lastDate = moment(nextProps.available_dates[nextProps.available_dates.length - 1]);
                if (lastDate.isBefore(this.selectedDate)) {
                    this.setState({
                        selectedDateString:lastDate.format("YYYY-MM-DD"),
                        selectedDate: lastDate.toDate()
                    }, () => {
                        this.loadPage();
                    });
                }
            }
        }
    }
    
    componentWillMount() {
        if(this.props.match){
            this.props.updateMenuPinned(false);
            this.props.updateMenuOpen(false);
        }
    }
    componentDidMount() {
        if (this.props.match && this.props.match.params && _.has(this.props.match.params, 'type')) {
            this.setState({"type":this.props.match.params.type});
        }
        if(_.has(this.props,"engagement_id") ){
            this.setState({"fetch_type":"engagement"}); 
        }else if(_.has(this.props,"group_id") ){
            this.setState({"fetch_type":"group"}); 
        } else if(_.has(this.props,"quickstart_id") ){
            this.setState({"fetch_type":"quickstart"}); 
        }
        this.loadPage();
    }
    

    loadPage() {
        let params = {
            todays_date:moment(this.state.selectedDate).format("YYYY-MM-DD"),
            fetch_type: this.state.fetch_type,
            type: this.props.match ? this.props.match.params.type : 'start'  ,
        };
        if(_.has(this.props,"engagement_id")){
            params.fetch_type = "engagement";
            params.engagement_id =this.props.engagement_id;
        }
        if(_.has(this.props,"group_id")){
            params.fetch_type = "group";
            params.group_id =this.props.group_id;
        }
        if(_.has(this.props,"quickstart_id")){
            params.fetch_type = "quickstart";
            params.quickstart_id =this.props.quickstart_id;
        }
        this.props.getClientCheckinList(params);
    }
    trackProgressionCallback(params){
        this.props.postProgressionProgress(params)
        .then((response)=>{
            this.loadPage(); 
        }).catch((error)=>{

        });
    }
    trackEngagementHabitCallback(params){
        this.props.engagementTrackHabit(params).then(() => {
            this.loadPage(); 
        }).catch((error)=>{
            
        });
    }
    trackGroupHabitCallback(params){
        this.props.groupTrackHabit(params).then(() => {
            this.loadPage(); 
        }).catch((error)=>{
            
        });
    }

    changeSelectedDate(value) {
        let selectedDate = moment(value).minutes(0).seconds(0).milliseconds(0);
        this.setState({
            selectedDate:selectedDate.toDate(),
            selectedDateString:selectedDate.format("YYYY-MM-DD")
        },()=>{ this.loadPage()});
    }

    playWorkout(progression){
        history.push("/client/progression_play/"+progression.id+"/?date="+moment(this.state.selectedDate).format("YYYY-MM-DD"));
    }

    callBackAfterSave() {
        history.push("/client/engagement/"+this.props.engagementsWithoutGoal[0].id);    
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    let available_dates = _.map(state.client.clientReducer.available_dates,(item)=>{
            return moment(item).minutes(0).seconds(0).milliseconds(0).toDate();
    });
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        checkin_count: state.client.clientReducer.checkin_count,
        engagement_list: state.client.clientReducer.engagement_list,
        group_list: state.client.clientReducer.group_list,
        quickstart_list: state.client.clientReducer.quickstart_list,
        available_dates:available_dates
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientCheckinList, 
        postProgressionProgress,
        engagementTrackHabit,
        groupTrackHabit,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
        cleanUp: ClientAction.cleanModule,
        }, 
        dispatch
    );
}

const ClientCheckinListConnected = connect(mapStateToProps,mapDispatchToProps)(ClientCheckinList);
export default ClientCheckinListConnected;
