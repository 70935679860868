import BaseComponent from '../../utils/common_components/basecomponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Template from "./templates/users";
import store, { history } from '../../redux/store';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';

class Users extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);

        this.onSearch = _.debounce(this.onSearch.bind(this),1000);

    };
    onSearch(text){
        if(text == null){
            this.loadMore();
        }
        this.setState({search:text},()=>{
        });

    }


    addUser() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/users/add');
    }

    editUser(user) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/user/edit/' + user.id);
    }
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,

    };
}

const selector = formValueSelector('UsersForm');
function mapDispatchToProps(dispatch) {
    return bindActionCreators({


    },
        dispatch
    );
}

const UsersForm = reduxForm(
    {
        form: 'UsersForm',
        enableReinitialize:  true,
    }
)(Users);

const UsersConnected = connect(mapStateToProps, mapDispatchToProps)(UsersForm);
export default UsersConnected;