import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import moment from 'moment';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import ChooseUserModal from '../../../practice/components/choose_user_modal';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { IconMenu } from 'react-toolbox/lib/menu';
import Dialog from 'react-toolbox/lib/dialog';
export default function () {
    function repeatConversation1(conversation, conversationIndex) {
        return React.createElement(Tr, { 'key': 'row' + conversationIndex }, React.createElement(Td, { 'column': 'name' }, React.createElement('div', {
            'className': 'row w-100 p-5 pointer p-10',
            'onClick': () => {
                this.showChatMessage(conversation);
            }
        }, React.createElement(ProfilePicture, {
            'user': conversation,
            'profile_type': 'circle'
        }), React.createElement('div', { 'className': 'flex-auto m-l-10' }, React.createElement('h6', {
            'className': 'truncate',
            'style': { maxWidth: '600px' }
        }, conversation.name), React.createElement('div', {
            'className': 'truncate',
            'style': { maxWidth: '600px' }
        }, conversation.last_message)), React.createElement('div', {
            'style': { width: '150px' },
            'className': 'text-right'
        }, conversation.last_conversation_time ? React.createElement('div', {
            'className': 'f-11 c-body',
            'key': '3156'
        }, '\n                                    ', moment(conversation.last_conversation_time).calendar(), '\n                                ') : null, conversation.not_viewed_count !== 0 ? React.createElement('span', {
            'className': 'badge bg-success m-r-10',
            'key': '3390'
        }, '\n                                    ', conversation.not_viewed_count.toString(), '\n                                ') : null))));
    }
    return React.createElement('div', { 'className': 'row center-xs p-20' }, React.createElement(Card, { 'className': 'col-xs-12  p-0' }, React.createElement(CardText, { 'className': 'p-0 card-full-height' }, React.createElement('div', { 'className': 'row' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable no-box-shadow',
            'id': 'table',
            'noDataText': 'You have no prior conversation history. Click + button to start a new conversation',
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Conversations'))),
        _.map(this.props.conversations, repeatConversation1.bind(this))
    ])))), this.state.addConversationModalOpen === true ? React.createElement(ChooseUserModal, {
        'handleClose': this.hideAddConversationModal,
        'sendBackData': this.selectUser,
        'title': 'FIND ' + this.state.user_type.toUpperCase(),
        'user_type': this.state.user_type,
        'open': this.state.addConversationModalOpen,
        'button_name': 'Start Conversation',
        'key': '3812'
    }) : null, this.props.selectedRole.practice ? React.createElement(IconMenu, {
        'style': {
            top: '70px',
            right: '40px',
            borderRadius: '50%'
        },
        'className': 'floating-add-button icon-menu-medium-white bg-success box-shadow',
        'icon': 'add',
        'position': 'topRight',
        'menuRipple': true,
        'key': '4190'
    }, React.createElement(MenuItem, {
        'onClick': () => {
            this.showAddConversationModal('client');
        },
        'icon': 'search',
        'caption': 'Find Clients'
    }), React.createElement(MenuItem, {
        'onClick': () => {
            this.showAddConversationModal('practitioner');
        },
        'icon': 'search',
        'caption': 'Find Practitioners'
    }), this.props.selectedRole.practice.is_business_partner && this.props.selectedRole.is_consultant === false ? React.createElement(MenuItem, {
        'onClick': () => {
            this.showAddConversationModal('consultant');
        },
        'icon': 'search',
        'caption': 'Find Dependent Partners',
        'key': '4661'
    }) : null) : null);
}