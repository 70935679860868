// Action Creators
const cannedMessageListFill = (canned_message_list) => ({
    type: 'CANNED_MESSAGE_LIST_FILL',
    canned_message_list:canned_message_list
});

const cannedMessageGet = (canned_message) => ({
    type: 'CANNED_MESSAGE_GET',
    canned_message:canned_message
});

const cannedMessageCleanup = () => ({
    type: 'CANNED_MESSAGE_CLEANUP',
});

export default {
    cannedMessageListFill,
    cannedMessageGet,
    cannedMessageCleanup,
};
