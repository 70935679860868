import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import {bindActionCreators} from 'redux';
import Template from "./templates/client_plan.rt";
import {connect} from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {getClientPaymentPlanList} from '../../redux/payment/network/client_payment_plan_network';
import {history} from '../../redux/store';
import actionCreator from '../../redux/payment/actions/client_payment_plan_action';

class ClientPlans extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doEdit = this.doEdit.bind(this);
    }

    componentWillMount() {
        this.props.getClientPaymentPlanList({});
    }

    componentWillUnmount() {
        this.props.cleanup([]);
    }

    doEdit(plan){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/payments/client_plans/"+plan.id);
    }
 
    render() {
        if (this.props.plans.length === 0) {
            return (
                <div></div>
            );
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        plans: state.payment.clientPaymentPlanReducer.client_payment_plans,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientPaymentPlanList,
        cleanup:actionCreator.clientPaymentPlanListFill
    }, dispatch);
}
const ClientPlansConnected = connect(mapStateToProps, mapDispatchToProps)(ClientPlans);
export default ClientPlansConnected;