
import React from 'react';
import {Tab, Tabs} from 'react-toolbox';
import SubscriptionContent from './subscription_content';

class TabsOptions extends React.Component {
  constructor (props) {
      super(props)
      
      this.state = {
          index: 0,
          fixedIndex: 0,
          inverseIndex: 1,
          selectedIndex:0
      };
  }    

  handleTabChange = (index) => {
    this.setState({index});
  };

  handleFixedTabChange = (index) => {
    this.setState({
      fixedIndex: index , 
    });
  };

  handleInverseTabChange = (index) => {
    this.setState({inverseIndex: index});
  };

  handleSelect = (index) => {
    this.setState({selectedIndex: index});
}

  render () {
    return (
        <Tabs class="tabs_title" index={this.state.fixedIndex} onChange={this.handleFixedTabChange} fixed>
        {this.props.isAdmin || this.props.isGiAdmin ? <Tab activeClassName="active-tab" label='HomeFit'><SubscriptionContent type='homefit'/></Tab> : ""}
           {this.props.isAdmin || this.props.isGiAdmin ? <Tab activeClassName="active-tab" label='Gray Institue'><SubscriptionContent type='gi'/></Tab> : ""}
        </Tabs>                                                                                                                                                                             
        
    );
  }
}

export default TabsOptions;