import  React  from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_goal_selection.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {verifyToken} from '../../redux/auth/network/auth_network';
import { getGoalList } from '../../redux/goal/network/goal_network';
import { connectGuestToPractice } from '../../redux/engagement/network/client_engagement_network';
import {history} from '../../redux/store';
import goalReducer from '../../redux/goal/reducers/goal_reducer';

class ClientGoalSelection extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
    }
    
    componentWillMount() {
        if(!this.props.practice_id || !this.props.practitioner_id){
           this.props.handleClose();
      }
        this.props.getGoalList({
            fetch_type:"client_selection",
            practice_id:this.props.practice_id
        });    
    }

    doSave(data) {
        let params = {... data};
        params.practice_id = this.props.practice_id;
        params.practitioner_id = this.props.practitioner_id;
        this.props.connectGuestToPractice(params).then((response) => {
            this.props.verifyToken({
                package_version: 2
            }).then(()=>{
                this.props.handleClose();
                localStorage.setItem("selectedRole","client");
                history.push("/");
            }).catch(() => {
                history.replace("/");
                window.location.reload();
                this.props.handleClose();
             });

        }).catch(() => {  });
    }
    
    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    let goal_templates = [...state.goal.goalReducer.goal_list,{id:-1,text:"Create your own"}];
    const selector = formValueSelector(ownProps.form);
    let initialValues = {goal:null,custom_goal_text:null};
    return {
        practice_id:ownProps.practice_id,
        practitioner_id:ownProps.practitioner_id,
        handleClose:ownProps.handleClose,
        goal_templates: goal_templates,
        user: state.auth.authReducer.user,
        initialValues: initialValues,
        selectedGoal: selector(state, 'goal'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        connectGuestToPractice,
            getGoalList,
            verifyToken
        }, 
        dispatch
    );
}



const validate = (values, props) => {
    let requiredFields = [
        'goal',
        'custom_goal_text'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};
const ClientGoalSelectionForm = reduxForm({
        enableReinitialize: true,
        validate
    }
)(ClientGoalSelection);


const ClientGoalSelectionFormConnected = connect(
    mapStateToProps, mapDispatchToProps
)(ClientGoalSelectionForm);
export default ClientGoalSelectionFormConnected;
