import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import PartnerPlanDetails from '../plan_selection_details';
import moment from 'moment';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
export default function () {
    return React.createElement('div', { 'className': 'row  text-center flex-column align-center' }, React.createElement('div', { 'className': 'triangle-with-shadow auto-margin' }), React.createElement('div', { 'className': 'col-xs-8 w-100' }, React.createElement('div', { 'className': 'pricing-widget text-center m-t-0' }, React.createElement('div', {
        'className': 'pricing-head f-20 d-flex flex-column justify-center',
        'style': { minHeight: '200px' }
    }, React.createElement('div', { 'className': 'w-100 f-400' }, this.props.choose_plan.name), React.createElement('div', { 'className': 'f-18 w-100 c-primary' }, React.createElement('span', {}, ' ', this.props.choose_plan.default_currency.toUpperCase(), ' ', this.props.choose_plan.fee_amount, ' / month ')), React.createElement('i', {
        'className': 'material-icons',
        'style': {
            fontSize: '80px',
            color: this.props.choose_plan.color_code
        }
    }, 'verified_user')), React.createElement('div', { 'className': 'pricing-body' }, React.createElement('ul', { 'className': 'pricing-list text-center' }, React.createElement('li', {}, React.createElement(Button, {
        'onClick': this.subscribe,
        'className': 'bg-success',
        'icon': 'card_membership',
        'label': 'Subscribe',
        'primary': true,
        'raised': true
    })), React.createElement('li', { 'className': 'text-left f-12 row m-l-10' }, React.createElement(RichTextViewer, {
        'className': 'col-xs-12 p-10  text-left',
        'value': this.props.choose_plan.description
    })))))));
}