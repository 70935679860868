import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import ProgressionEditableWrapper from '../progression_editable_wrapper_new';
import ProgressionEdit from '../progression_edit_new';
import MultiDatePicker from '../../../utils/common_components/multi_date_picker';
import { Card } from 'react-toolbox/lib/card';
export default function () {
    function repeatProgression1(progression, progressionIndex) {
        return React.createElement('div', {
            'className': 'col-xs-12',
            'style': { zIndex: '1' },
            'key': 'progression' + progression.id
        }, React.createElement(ProgressionEditableWrapper, _.assign({}, {
            'progression': progression,
            'program': this.props.program,
            'disableEditing': false
        }, this.props)));
    }
    return React.createElement('div', {}, React.createElement.apply(this, [
        'div',
        { 'style': { position: 'relative' } },
        React.createElement('div', { 'className': 'row' }),
        !this.props.disableEditing && this.props.program.progressions.length === 0 ? React.createElement('div', {
            'className': 'col-xs-12 f-18 text-center m-t-20 p-0',
            'style': { zIndex: '1' },
            'key': '3068'
        }, React.createElement('span', { 'className': 'c-grey p-5' }, '\n                    Click on the button to add new exercises.\n                ')) : null,
        _.map(this.props.program.progressions, repeatProgression1.bind(this)),
        this.props.program.progressions.length === 0 ? React.createElement(Button, {
            'onClick': this.add,
            'className': 'bg-success-border square-btn f-14 m-l-10 m-t-20',
            'ripple': true,
            'key': '3705'
        }, 'Add Exercises ') : null
    ]), React.createElement('div', { 'className': 'publish-info p-absolute p-right-0 p-top-0' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-30 m-b-10 m-r-10 middle-xs' }, !this.props.disableEditing ? React.createElement(Button, {
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'label': 'Save as Draft',
        'onClick': () => {
            this.togglePublish(false);
        },
        'raised': true,
        'key': '4134'
    }) : null, !this.props.disableEditing ? React.createElement(Button, {
        'className': 'bg-success m-b-12 m-l-10 w-100',
        'label': 'Publish',
        'onClick': () => {
            this.togglePublish(true);
        },
        'raised': true,
        'key': '4342'
    }) : null))));
}