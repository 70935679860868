import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import { NavLink } from 'react-router-dom';
import FontIcon from 'react-toolbox/lib/font_icon';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import PartnerPlanDiscount from '../partner_plan_discount';
export default function () {
    function repeatLibrary1(plan, planIndex, library, libraryIndex) {
        return React.createElement(Chip, { 'key': library.id }, library.name);
    }
    function repeatPlan2(plan, planIndex) {
        return React.createElement('div', {
            'className': 'panel p-0 m-r-10 col-md-5 col-xs-11 col-sm-10 ribbon-container',
            'key': plan.id
        }, !plan.is_published ? React.createElement('div', {
            'className': 'bg-warning ribbon f-10',
            'key': '1250'
        }, '\n                        DRAFT\n            ') : null, plan.is_published ? React.createElement('div', {
            'className': 'bg-success ribbon f-10',
            'key': '1375'
        }, '\n                        PUBLISHED\n            ') : null, React.createElement('div', { 'className': 'panel-heading text-center border-bottom vertical-align p-5' }, React.createElement('span', { 'className': 'panel-title f-20 flex-auto' }, plan.name), plan.plan_type === 3 && this.props.discount_plans.length > 0 ? React.createElement(IconButton, {
            'tooltip': 'Set discount plan',
            'onClick': () => {
                this.discountForPlan(plan);
            },
            'icon': 'book',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '1656'
        }) : null, plan.can_be_deleted ? React.createElement(IconButton, {
            'tooltip': 'Edit plan',
            'onClick': () => {
                this.editPlan(plan);
            },
            'icon': 'create',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '1971'
        }) : null, plan.can_be_deleted ? React.createElement(IconButton, {
            'tooltip': 'Delete plan',
            'onClick': () => {
                this.deletePlan(plan);
            },
            'icon': 'delete_forever',
            'className': 'm-r-5 c-danger small-icon-button round-border',
            'key': '2235'
        }) : null), React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', {
            'className': 'row m-b-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                    Payment Type :\n                '), React.createElement('div', { 'className': 'w-60 vertical-align' }, React.createElement(Chip, { 'className': 'bg-primary' }, plan.__plan_payment_type))), React.createElement('div', {
            'className': 'row m-b-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                    Amount:\n                '), React.createElement('div', { 'className': 'w-60 vertical-align' }, '\n                    ', plan.default_currency.toUpperCase(), ' ', plan.fee_amount, '\n                ')), plan.discount_coupon !== null ? React.createElement('div', {
            'className': 'row m-b-5 c-danger',
            'style': { height: '30px' },
            'key': '3281'
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                    Discount:\n                '), React.createElement('div', { 'className': 'w-60 vertical-align' }, '\n                    25 %\n                ')) : null, plan.plan_payment_type === 3 ? React.createElement('div', {
            'className': 'row m-b-5',
            'style': { height: '30px' },
            'key': '3632'
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                    Plan Duration:\n                '), React.createElement('div', { 'className': 'w-60 vertical-align' }, '\n                    ', plan.month_duration_count, ' Months\n                ')) : null, React.createElement('div', {
            'className': 'row m-b-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                    Client Count:\n                '), plan.client_count !== 2147483647 ? React.createElement('div', {
            'className': 'w-60 vertical-align',
            'key': '4234'
        }, '\n                     ', plan.client_count, '\n                ') : null, plan.client_count === 2147483647 ? React.createElement('div', {
            'className': 'w-60 vertical-align',
            'key': '4390'
        }, '\n                    Unlimited\n               ') : null), React.createElement('div', {
            'className': 'row m-b-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                    Libraries:\n                '), React.createElement.apply(this, [
            'div',
            { 'className': 'w-60 vertical-align' },
            _.map(plan.libraries, repeatLibrary1.bind(this, plan, planIndex))
        ])), React.createElement('div', {
            'className': 'row m-b-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                    Subscription Count:\n                '), React.createElement('div', { 'className': 'w-60 vertical-align' }, '\n                        ', plan.subscription_count, '\n                ')), React.createElement('div', {
            'className': 'row m-b-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'w-30 vertical-align justify-right m-r-5 f-500' }, '\n                        Special Plan:\n                    '), React.createElement('div', { 'className': 'w-60 vertical-align' }, !plan.available_for_subscription ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '5596'
        }) : null, plan.available_for_subscription ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '5788'
        }) : null))));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs' },
        !this.props.partner.is_payment_gateway_ready ? React.createElement('div', {
            'className': 'bg-danger col-xs-11 text-center m-10 p-10 f-15',
            'key': '602'
        }, ' \n            You have not yet configured your Stripe Account to process payments. \n            Click ', React.createElement(NavLink, { 'to': `/practice/${ this.props.selectedRole.practice.id }/payments/stripe_config` }, 'here'), '    to add now\n    ') : null,
        this.props.partner_plans.length === 0 ? React.createElement('div', {
            'className': 'alert-warning',
            'key': '955'
        }, '\n         You have not yet created any partner plans.\n    ') : null,
        _.map(this.props.partner_plans, repeatPlan2.bind(this)),
        this.props.partner.subdomain != 'app' && this.props.partner.subdomain != 'gi' ? React.createElement(Button, {
            'style': { top: '100px' },
            'className': 'floating-add-button',
            'onClick': this.addPlan,
            'icon': 'add',
            'floating': true,
            'ripple': true,
            'key': '6059'
        }) : null,
        React.createElement(Dialog, {
            'active': this.state.openDiscountDialog,
            'title': 'Discount for plan',
            'onEscKeyDown': this.closeDicountForPlan,
            'onOverlayClick': this.closeDicountForPlan
        }, this.state.openDiscountDialog ? React.createElement(PartnerPlanDiscount, {
            'plan': this.state.discountForPlan,
            'handleClose': this.closeDicountForPlan,
            'key': '6526'
        }) : null)
    ]));
}