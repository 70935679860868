// Action Creators
const calendarEventListFill = (event_list) => ({
    type: 'CALENDAR_EVENT_LIST_FILL',
    event_list,
});


const cleanModule = () => ({
    type: 'CALENDAR_EVENT_LIST_CLEANUP',
});

export default {
    calendarEventListFill,
    cleanModule,
};
