let initialState = {
    trackings:[],
    paginate_info:{page:0},
    trackingHistory:{}
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "GROUP_CLIENT_GOAL_TRACKING_HISTORY_FILL":
            if (!action.goal_question_id || !action.trackingHistory){
                return state;
            }
            let key_val = "goal_question_" + action.goal_question_id;
            let updateObject = {};
            updateObject[key_val] = action.trackingHistory;
            let trackingHistory = {...state.trackingHistory,...updateObject};
            return Object.assign({}, state, {trackingHistory:trackingHistory});
        case "GROUP_CLIENT_GOAL_TRACKING_LIST":
            if (!action.trackings || !action.paginate_info){
                return state;
            }        
            if(action.paginate_info.page === 0){
                return Object.assign({}, state,{trackings:action.trackings,paginate_info:action.paginate_info});
            }else{
                let trackings = [...state.trackings,...action.trackings];
                return Object.assign({}, state,{trackings:trackings,paginate_info:action.paginate_info});
            }
        case "GROUP_CLIENT_REMOVAL":
            if (!action.client_id){
                return state;
            }      
            let updatedClientTracking = _.filter(state.trackings,(item)=>{
                  if(item.id  === action.client_id){
                        return false;
                  }else{
                      return true;
                  }  
            });
            return Object.assign({}, state,{trackings:updatedClientTracking});

        case "GROUP_CLIENT_ADD":
            if (!action.clients){
                return state;
            }      
            let addTrackings = [...action.clients,...state.trackings];
            return Object.assign({}, state,{trackings:addTrackings});

        case "GROUP_CLIENT_ADD_TREATMENT":
            if (!action.client_workouts &&  !action.client_id  && !action.client_habits ){
                return state;
            }      
            let trackings = _.map(state.trackings,(item)=>{
                if(item.id === action.client_id){
                    let itemObj = {...item};
                    itemObj.client_workouts = action.client_workouts;
                    itemObj.client_habits = action.client_habits;
                    itemObj.treatment_started_date = action.treatment_started_date;
                    return itemObj;
                }else{
                    return item;
                }
            });
            return Object.assign({}, state,{trackings:trackings});

        case "GROUP_CLIENT_GOAL_TRACKING_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
