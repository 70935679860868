let initialState = {
    payment_plans: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PLAN_SUBSCRIBE_LIST_FILL":
            if(!action.payment_plans ) {
                return state;
            }
            return Object.assign({}, state, {
                payment_plans: action.payment_plans,
            });
        default:
            return state;
    }
};