

// Action Creators
const updateHelpVideo = (video_guid) => ({
    type: 'UPDATE_HELP_VIDEO', video_guid
});
const clearHelpVideo = () => ({
    type: 'CLEAR_HELP_VIDEO'
});

let actionCreators = {
    updateHelpVideo: updateHelpVideo,
    clearHelpVideo: clearHelpVideo,
};

export default actionCreators;