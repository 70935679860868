import Cookies from 'js-cookie';
import $ from 'jquery';
import Store from '../redux/store';
import {displayNotification as DisplayNotification} from '../redux/notifications/actions/notification_creator';
import moment from 'moment';

const utils = {
    getDevice: () => {
        return 'web';
    },
    showSuccessAlert: (message)=>{
         Store.dispatch(DisplayNotification('Success',message,"success"));    
    },
    showErrorAlert: (message) => {
        Store.dispatch(DisplayNotification('Failure',message,"error"));    
    },
    showInfoAlert: (message) => {
        Store.dispatch(DisplayNotification('Information',message,"info"));      
    },
    showWarningAlert: (message) => {
        Store.dispatch(DisplayNotification('Warning',message,"warning"));   
    },
    getLocalStorageValue: (cname) => {
        const cookie = Cookies.get(cname);
        if (typeof cookie === 'undefined') {
            return "";
        }
        else {
            return cookie;
        }
    },
    setLocalStorageValue:  (cname, cvalue,expires=6) => {
        Cookies.set(cname, cvalue, { expires: expires });
        let cookie = {};
        cookie[cname] = cvalue;
        return cookie;
    },
    removeLocalStorageValue:  (cname) => {
        Cookies.remove(cname);
        return "";
    },
    makeObjectArrayFlat:(arrayVal,valueKey="id")=>{
        let valueList = _.map(arrayVal,(selectedItem)=>{
            if (selectedItem instanceof Object){
                return selectedItem[valueKey];
            }else{
                return selectedItem;
            }    
        });  
        return valueList;  
    },
    flatternFormData: (formData, excludeKeys=[])=>{
        let dataObject = _.cloneDeep(formData);
        for(let key in dataObject){
            if (excludeKeys.includes(key)){
                continue;
            }
            let value = dataObject[key];
            if(Object.prototype.toString.call(value) === '[object Array]'){
                for(let index in value){
                    let innerValue = value[index];
                    if(Object.prototype.toString.call(innerValue) === '[object Object]'){
                        value[index] = innerValue.id ? innerValue.id : innerValue.value;
                    }
                }
            } else if(Object.prototype.toString.call(value) === '[object Object]'){
                dataObject[key] = value.id ? value.id : value.value;
            }
        }
        return dataObject;
    },
    formatServerDate: (date_object) => {
        if (!date_object) {
            return null;
        }
        return moment(date_object).format("YYYY-MM-DD");
    },
    parseServerDate: (date_string) => {
        if (_.isEmpty(date_string)) {
            return null;
        }
        return moment(date_string).seconds(0).milliseconds(0).toDate();
    },
    parseServerDateAsString: (date_string) => {
        if (_.isEmpty(date_string)) {
            return '';
        }
        return moment(date_string).format("DD MMM YYYY");
    },
    parseDateAsString: (date_object) => {
        if (!date_object) {
            return '';
        }
        return moment(date_object).format("DD MMM YYYY");
    },
    toDateArray: (startDate, days) => {
        return _.map(days, (item) => { return moment(startDate).add(item - 1, "days").toDate(); });
    },
    toDayArray: (startDate, dates) => {
        return _.map(dates, (item) => { return moment(item).diff(moment(startDate), 'days') + 1; });
    },
    getProgramUrlPrefix: (program_type,selectedRole) => {
        return '/practice/'+selectedRole.practice.id+'/engagement/p/'+program_type;
    },
    getProgramTypeParams: (type) => {
        let typeParam = 0;
        let typeUCase = type.charAt(0).toUpperCase() + type.substring(1);
        if(type === "template" ){
            typeParam = 0;
        }else if(type === "quickstart"){
            typeParam = 1;
        }else if(type === "treatment_template_workout"){
            typeParam = 2;
            typeUCase = "Workout";
        }
        return {
            typeUCase: typeUCase,
            typeLCase: type,
            typeParam: typeParam,
            programType: type,
        };
    },
    getBackgroundColor: (name) => {
        let flat_ui_colors = [
            '#E53935',
            '#D81B60',
            '#8E24AA',
            '#5E35B1',
            '#3949AB',
            '#1E88E5',
            '#039BE5',
            '#00ACC1',
            '#00897B',
            '#43A047',
            '#7CB342',
            '#C0CA33',
            '#FDD835',
            '#FFB300',
            '#FB8C00',
            '#F4511E',
            '#6D4C41',
            '#757575',
            '#546E7A'
        ];
        if (name === null) {
            return flat_ui_colors[0];
        }
        name = _.trim(name);
        let nameArray = name.split(" ");
        let firstNameChar =  nameArray[0].charAt(0);
        let lastNameChar =  nameArray.length > 1 ? nameArray[nameArray.length-1].charAt(0) : nameArray[0].charAt(1);
        let profileShortName = firstNameChar +  lastNameChar ;
        profileShortName = profileShortName.toUpperCase();
        let charCode = profileShortName.charCodeAt(0) + profileShortName.charCodeAt(1);
        // modulus
        charCode = charCode % flat_ui_colors.length;    
        let background_color = flat_ui_colors[charCode];  
        return background_color;      
    },

    dateEquals: (d1, d2) => {
        return (d1.getFullYear() === d2.getFullYear()) &&
           (d1.getMonth() === d2.getMonth()) &&
           (d1.getDate() === d2.getDate());
    },

    getSubdomain: () => {
        let subdomain_arr = window.location.host.split(".");
        if (subdomain_arr.length > 0) {
            return subdomain_arr[0];
        } else {
            return "app";
        }
    },
};
export default utils;
