import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Select from 'react-select';
export default function () {
    return React.createElement('div', { 'className': 'row w-100' }, React.createElement(Select, {
        'className': 'col-xs-12',
        'name': 'discount_coupon',
        'value': this.state.discountCoupon,
        'options': this.props.discount_plans,
        'clearable': true,
        'labelKey': 'name',
        'valueKey': 'id',
        'onChange': this.selectDiscountCoupon
    }), React.createElement('div', {
        'className': 'col-xs-12 end-xs',
        'style': { marginTop: '50px' }
    }, React.createElement(Button, {
        'label': 'Save',
        'onClick': this.doSave,
        'type': 'button',
        'className': 'bg-success',
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.doClose,
        'label': 'Close',
        'className': 'bg-accent m-l-5',
        'type': 'button',
        'raised': true
    })));
}