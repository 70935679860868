import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
import { Table } from '../../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatCondition1(condition, conditionIndex) {
        return React.createElement(Tr, { 'key': 'condition' + conditionIndex }, React.createElement(Td, {
            'column': 'name',
            'value': condition.title
        }, React.createElement('span', { 'className': 'f-400' }, condition.title)), React.createElement(Td, {
            'column': 'is_publish',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, condition.is_publish ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '2554'
        }) : null, !condition.is_publish ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '2734'
        }) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'icon': 'create',
            'onClick': () => {
                this.editConditionTreated(condition.id);
            },
            'floating': true,
            'primary': true,
            'mini': true
        }))));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Conditions and goals treated by your practice have not been configured!',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Condition')), React.createElement(Th, {
            'column': 'is_publish',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Is Published?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.conditions_treated, repeatCondition1.bind(this))
    ]), React.createElement(Button, {
        'className': 'floating-add-button m-r-8',
        'style': { top: '90px' },
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'onClick': this.addConditionTreated
    }));
}