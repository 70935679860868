import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import TextInput from '../../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import Button from 'react-toolbox/lib/button';
import Select from '../../../../utils/common_components/select';
import { PARTNER } from '../../../../core/constants/constants';
export default function () {
    return React.createElement('div', { 'className': 'row invite-client' }, React.createElement('div', { 'className': 'text-left' }, React.createElement(Button, {
        'className': 'm-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.props.handleCancel
    }, '\n            Back\n        ')), React.createElement(Card, { 'className': 'w-100  no-box-shadow' }, React.createElement('form', {
        'className': 'add-user d-flex no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.inviteUser)
    }, React.createElement('div', { 'className': 'bg-white box-shadow-10 p-24 w-76 m-r-16' }, React.createElement(CardTitle, {
        'title': this.props.title,
        'className': 'c-black form-title m-b-8 p-0'
    }), React.createElement('div', { 'className': 'w-100 d-flex' }, React.createElement('div', { 'className': 'w-50 m-r-16' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'first_name',
        'type': 'text',
        'component': TextInput,
        'label': 'First Name'
    })), React.createElement('div', { 'className': 'w-50' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'last_name',
        'type': 'text',
        'component': TextInput,
        'label': 'Last Name'
    }))), React.createElement('div', { 'className': 'm-t-16 col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'email',
        'type': 'email',
        'component': TextInput,
        'label': 'Email'
    })), this.props.groups && this.props.groups.length > 0 ? React.createElement('div', {
        'className': 'col-xs-12 m-t-26',
        'key': '2692'
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'group',
        'component': Select,
        'label': 'Select group',
        'valueKey': 'id',
        'labelKey': 'name',
        'options': this.props.groups,
        'simpleValue': true
    })) : null, React.createElement('div', { 'className': 'm-t-26 col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'subject',
        'type': 'text',
        'component': TextInput,
        'label': 'Subject',
        'autoComplete': 'off'
    })), React.createElement('div', { 'className': 'col-xs-12 p-t-10' }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Body'
    }))), React.createElement('div', { 'className': 'publish-info' }, React.createElement('div', {}, React.createElement(Card, { 'className': 'bg-white box-shadow p-10' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-10 m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Invite',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'm-l-10 bg-success w-100',
        'primary': true,
        'raised': true
    }))))))));
}