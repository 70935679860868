import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "../templates/facility/facility_booking_list.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../../redux/store';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getfacilityBookingList,facilityDelete } from '../../../redux/calendar/network/facility_network.js';
import  facilityActionCreator from '../../../redux/calendar/actions/facility_action';

class FacilityBookingList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.changeFrequency = this.changeFrequency.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);
        this.bookFacility = this.bookFacility.bind(this);
        this.closeBookingDialog = this.closeBookingDialog.bind(this);
        this.state = {
            'start_date':moment().toDate(),
            'end_date':moment().add('months',1).toDate(),
            'facility_frequency':null,
            'showBookingDialog': false,
            'selected_booking_slot': null,
        };
    }

    componentWillMount() {
        let params = {};
        params.facility_id = this.props.match.params.facility_id;
        params.start_date = moment(this.state.start_date).format("YYYY-MM-DD");
        params.end_date = moment(this.state.end_date).format("YYYY-MM-DD");
        this.props.getfacilityBookingList({
            ...params,
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id });
    }

    fetchBookingData(){
        let params = {};
        params.facility_id = this.props.match.params.facility_id;
        params.start_date = moment(this.state.start_date).format("YYYY-MM-DD");
        params.end_date = moment(this.state.end_date).format("YYYY-MM-DD");
        params.facility_frequency = this.state.facility_frequency.id;
        params.package_version = "1"
        this.props.getfacilityBookingList(params);
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    changeFrequency(frequency){
        this.setState({facility_frequency:frequency},this.fetchBookingData);
    }

    changeStartDate(date) {
        this.setState({start_date:date},this.fetchBookingData);
    }
    changeEndDate(date) {
        this.setState({end_date:date},this.fetchBookingData);
    }

    bookFacility(booking) {
        this.setState({selected_booking_slot:booking.id,'showBookingDialog':true});
    }
    closeBookingDialog(reload_data = false) {
        this.setState({selected_booking_slot:null,'showBookingDialog':false},()=>{
            if(reload_data === true){
                this.fetchBookingData();
            }
        });
    }


    render() {
        if(_.isEmpty(this.props.facility)){
            return (<div></div>);
        }
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    let facility = _.cloneDeep(state.calendar.facilityReducer.facility);
    let booking_slots = _.cloneDeep(state.calendar.facilityReducer.booking_slots);
    let facility_frequency = _.map(facility.facility_frequency,(item)=>{
        const start_time = moment(item.start_time,"HH:mm:ss").format('h:mm a');
        const end_time = moment(item.end_time,"HH:mm:ss").format('h:mm a');
        item.label = item.__day + " ( "+start_time+ " to "+end_time+" )";
        item.value = item.id;
        return item;
    });
    booking_slots = _.groupBy(booking_slots,'date');
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        facility: facility,
        booking_slots: booking_slots,
        facility_frequency:facility_frequency
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getfacilityBookingList,
        facilityDelete,
        cleanUp: facilityActionCreator.cleanModule
    }, dispatch
    );
}


const FacilityBookingListConnected = connect(mapStateToProps, mapDispatchToProps)(FacilityBookingList);
export default FacilityBookingListConnected;