import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/services_offered_edit.rt";
import Utils from '../../../utils/utils';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import { history } from '../../../redux/store';
import { getServicesOffered, saveServicesOffered, getServicesOfferedList } from '../../../redux/admin/services_offered/network/services_offered_network';
import ServicesOfferedAction from '../../../redux/admin/services_offered/actions/services_offered_creator';

class ServicesOfferedEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveServicesOffered = this.saveServicesOffered.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onDraft = this.onDraft.bind(this);        
        this.state = {publish:false};
    };
    
    componentWillMount() {
        let params = {};
        if(this.props.match.params.service_offered_id !== undefined) {
            params = {
                'service_offered_id': this.props.match.params.service_offered_id
            };
        } else {
            params = {
                'service_offered_id': ''
            };
        }
        this.props.getServicesOffered(params);        
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    saveServicesOffered(data) {     
        let params = _.omit(data, ['can_be_deleted']);
        params.is_publish = this.state.publish;
        this.props.saveServicesOffered(params).then(() => {
            history.push('/practice/'+this.props.practice.id+'/branding/services_offered');
        }).catch(() => {
            //do nothing
        });  
    }

    onPublish() { 
        this.setState({publish:true},()=>{
             this.props.handleSubmit(this.saveServicesOffered)();
        });
       
    }
    onDraft() {  
        this.setState({publish:false},()=>{
             this.props.handleSubmit(this.saveServicesOffered)();
        });
    }

    
    render() {
        if (_.isEmpty(this.props.services_offered)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        services_offered: state.admin.servicesOfferedReducer.services_offered,
        initialValues: state.admin.servicesOfferedReducer.services_offered,
        practice: state.auth.roleReducer.selectedRole.practice,
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getServicesOffered,
            saveServicesOffered,
            getServicesOfferedList,
            cleanUp: ServicesOfferedAction.servicesOfferedCleanup,
        }, 
        dispatch
    );
}

const validate = (values) => {
    let requiredFields = [
        'title',
        'description'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ServicesOfferedEditForm = reduxForm(
    {
        form: 'servciesOfferedEditForm',
        enableReinitialize: true,
        validate,
    }
)(ServicesOfferedEdit);
const ServicesOfferedEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(ServicesOfferedEditForm);
export default ServicesOfferedEditFormConnected;
