import React  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/engagement_intake.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';

import moment from 'moment';
import store from '../../redux/store';
import IntakeAction from '../../redux/intake/actions/intake_creator';
import { getIntakeList, addIntakeInstance } from '../../redux/intake/network/intake_network';
import { getCannedMessage } from '../../redux/message/network/canned_message_network';
import { assignIntakeToEngagement } from '../../redux/engagement/network/engagement_network';
import CannedMessageAction from '../../redux/message/actions/canned_message_creator';
import { ENGAGEMENT_PRACTITIONER_INTAKE_ASSIGN } from '../../redux/message/constants/constants';


class ClientEngagementIntake extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
    };

    componentWillMount() {
        this.props.intakeCleanUp();
        this.props.getIntakeList({ 
            form_type: 1, 
            fetch_type: "practitioner_assign" ,
            package_version: "1"
        }).then(() => {
        }).catch((error) => {
            this.props.intakeCleanUp();
        });
        this.props.getCannedMessage({
            message_type:ENGAGEMENT_PRACTITIONER_INTAKE_ASSIGN,
            package_version: "1"
        });
    }

    componentWillUnmount() {
        this.props.messageCleanup();
        this.props.intakeCleanUp();
    }

    doSave(data) {
        let params = { ...data,package_version: 4 };
        params['engagement_id'] = this.props.engagement_id;
        this.props.assignIntakeToEngagement(params).then(() => {
            this.props.finishEditing();
        });
    }

    doCancel() {
        this.props.finishEditing();
    }

    render() {
        if (_.isEmpty(this.props.canned_message)) {
            return (<div></div>);
        }

        return Template.apply(this);
    }
}

ClientEngagementIntake.propTypes = {
    finishEditing: PropTypes.func,
};
ClientEngagementIntake.defaultProps = {
    finishEditing: () => { },
};

const selector = formValueSelector('ClientEngagementIntakeForm');

function mapStateToProps(state, ownProps) {
    let initialValues = {};
    initialValues["intake_forms"] = state.intake.intakeReducer.intake_list,
    initialValues["subject"] = state.message.cannedMessageReducer.canned_message.subject;
    initialValues["body"] = state.message.cannedMessageReducer.canned_message.body;    
    return {
        intakes: state.intake.intakeReducer.intake_list,
        canned_message: state.message.cannedMessageReducer.canned_message,
        initialValues: initialValues,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getIntakeList,
            getCannedMessage,
            intakeCleanUp: IntakeAction.intakeCleanUp,
            assignIntakeToEngagement,
            messageCleanup: CannedMessageAction.cannedMessageCleanup,
        },
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    let requiredFields = [
        "intake_form",
        "subject",
        "body"
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const ClientEngagementIntakeForm = reduxForm(
    {
        form: 'ClientEngagementIntakeForm',
        enableReinitialize: true,
        validate
    }
)(ClientEngagementIntake);

const ClientEngagementIntakeFormConnected = connect(
        mapStateToProps,mapDispatchToProps
    )(ClientEngagementIntakeForm);
export default ClientEngagementIntakeFormConnected;
