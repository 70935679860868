
// Action Creators
const authSignupComplete = () => ({
    type: 'AUTH_SIGNUP_COMPLETE'
});

let actionCreators = {
    authSignupComplete
};

export default actionCreators;