import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import ProgressionPreviewWrapper from '../progression_preview_wrapper';
import ProgressionEdit from '../progression_edit';
export default function () {
    function repeatRest_day1(rest_day, rest_dayIndex) {
        return React.createElement(Chip, {
            'className': 'bg-danger chip-thin',
            'key': 'rest_day' + rest_day
        }, '\n                        Day ', rest_day, '\n                    ');
    }
    function repeatProgressionPreview2(progressionPreview, index) {
        return React.createElement('div', {
            'className': 'col-xs-12',
            'style': { zIndex: '1' },
            'key': 'progressionPreview' + index
        }, React.createElement(ProgressionPreviewWrapper, _.assign({}, { 'progressionPreview': progressionPreview }, this.props)));
    }
    return React.createElement('div', {}, React.createElement(Button, {
        'className': 'bg-' + (this.props.program.published ? 'danger' : 'primary') + ' c-white',
        'label': (this.props.program.published ? 'Un' : '') + 'Publish ' + this.props.typeUCase,
        'style': {
            position: 'absolute',
            zIndex: '10',
            top: this.props.programType === 'quickstart' ? '230px' : '350px',
            right: this.props.programType === 'quickstart' ? '60px' : '60px'
        },
        'onClick': this.togglePublish
    }), React.createElement('div', { 'style': { position: 'relative' } }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement('div', {
            'className': 'bg-accent',
            'style': {
                marginLeft: '32px',
                zIndex: '0',
                height: 'calc(100% - 1px)',
                width: '10px',
                position: 'absolute'
            }
        }),
        React.createElement('div', {
            'className': 'col-xs-12 bg-accent p-20 box-shadow',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'row f-20' }, '\n                    ', this.props.program.name, '\n                '), React.createElement('div', { 'className': 'row middle-xs m-t-10 f-16' }, '\n                    Total Length:\n                    ', React.createElement(Chip, { 'className': 'bg-primary m-l-10 chip-thin' }, this.props.program.total_days, ' days')), React.createElement.apply(this, [
            'div',
            { 'className': 'row middle-xs f-16 m-t-5' },
            React.createElement('span', { 'className': 'm-r-10' }, 'Days without Workout (Rest Days):'),
            _.map(this.props.program.rest_days, repeatRest_day1.bind(this))
        ])),
        _.map(this.props.program.progressions_preview, repeatProgressionPreview2.bind(this))
    ])));
}