import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import DatePicker from '../../../utils/common_components/datepicker_form';
import moment from 'moment';
export default function () {
    return React.createElement('div', {}, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), React.createElement('div', { 'className': 'w-100 m-l-0' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'schedule_type',
        'component': Select,
        'label': 'Schedule Type',
        'options': this.props.supportingData.schedule_type,
        'simpleValue': true,
        'valueKey': 'value',
        'labelKey': 'label',
        'disabled': this.props.isNonSyncGroup,
        'clearable': false
    }), this.props.isNonSyncGroup ? React.createElement('p', {
        'style': { marginTop: '-10px' },
        'className': 'material-label w-100',
        'key': '2064'
    }, '* Ongoing groups can only have ongoing workouts') : null), this.props.programType === 'quickstart' || this.props.schedule_type === 1 ? React.createElement('div', { 'key': '2231' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'total_days',
        'type': 'number',
        'component': TextInput,
        'label': 'Length (in days)'
    })) : null, this.props.schedule_type === 2 ? [
        React.createElement('div', { 'key': '24571' }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'per_week',
            'type': 'number',
            'component': TextInput,
            'label': 'Per Week'
        })),
        React.createElement('div', { 'key': '24573' }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'per_day',
            'type': 'number',
            'component': TextInput,
            'label': 'Per Day'
        }))
    ] : null, this.props.programType === 'workout' && !this.props.isNonSyncGroup ? [
        React.createElement('div', {
            'className': 'w-100 m-l-0',
            'key': '28291'
        }, React.createElement(Field, {
            'name': 'start_date',
            'type': 'date',
            'label': 'Start Date',
            'component': DatePicker,
            'minDate': moment().subtract(1, 'days').toDate()
        })),
        React.createElement('div', {
            'className': 'w-100 m-l-0',
            'key': '28293'
        }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'intro',
            'component': Select,
            'label': 'Intro Video',
            'options': this.props.wrapper_videos,
            'simpleValue': true,
            'valueKey': 'id',
            'labelKey': 'title'
        }))
    ] : null, this.props.programType !== 'workout' ? [
        React.createElement('div', { 'key': '34451' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'category',
            'component': Select,
            'label': 'Category Level 1',
            'options': this.props.supportingData.category,
            'valueKey': 'id',
            'labelKey': 'name',
            'onChange': val => {
                this.onChangeCategoryLevel1(val);
            }
        })),
        this.props.category && this.props.category.name !== 'Sports' ? [] : null,
        React.createElement('div', { 'key': '34455' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'category_level2',
            'component': Select,
            'label': 'Category Level 2',
            'options': this.state.categoryLevel2,
            'valueKey': 'id',
            'labelKey': 'name',
            'onChange': val => {
                this.onChangeCategoryLevel2(val);
            }
        })),
        React.createElement('div', { 'key': '34457' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'category_level3',
            'component': Select,
            'label': 'Category Level 3',
            'options': this.state.categoryLevel3,
            'valueKey': 'id',
            'labelKey': 'name'
        }))
    ] : null, this.props.category && this.props.category.name === 'Sports' ? [
        React.createElement('div', { 'key': '48671' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'sports',
            'component': Select,
            'label': 'Sports Name',
            'options': this.props.supportingData.sports,
            'multi': true,
            'valueKey': 'id',
            'labelKey': 'name'
        })),
        React.createElement('div', { 'key': '48673' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'sports_exercise_type',
            'component': Select,
            'label': 'Sports Condition',
            'options': this.props.supportingData.sports_exercise_type,
            'valueKey': 'id',
            'labelKey': 'name'
        }))
    ] : null, this.props.selectedRole.practice.id === 2 ? [
        React.createElement('div', {
            'className': 'row m-l-0',
            'key': '55511'
        }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Is 3DMAPS Template?'), React.createElement(Field, {
            'name': 'is_gi_3dmaps',
            'component': Switch
        })),
        React.createElement('div', {
            'className': 'row m-l-0',
            'key': '55513'
        }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Is Golf Template?'), React.createElement(Field, {
            'name': 'is_gi_golf',
            'component': Switch
        }))
    ] : null, React.createElement('div', { 'className': 'row m-l-0' }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Is Evidence Based?'), React.createElement(Field, {
        'name': 'has_scientific_evidence',
        'component': Switch
    })), this.props.has_scientific_evidence ? React.createElement('div', {
        'className': 'row m-l-0',
        'key': '6299'
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'scientific_evidence_text',
        'type': 'text',
        'component': TextInput,
        'label': 'Scientific Evidence',
        'multiline': true,
        'rows': 4
    }), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row end-xs' }, this.props.scientific_evidence_guid ? React.createElement('div', {
        'className': 'col m-r-10',
        'key': '6657'
    }, React.createElement('a', {
        'href': this.props.scientific_evidence_url,
        'target': '_blank',
        'className': 'm-r-5'
    }, React.createElement(Button, {
        'label': 'View Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    })), React.createElement(Button, {
        'onClick': this.deleteScientificEvidence,
        'label': 'Remove Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    })) : null, !this.props.scientific_evidence_guid ? React.createElement('div', {
        'className': 'col',
        'key': '7136'
    }, React.createElement(Dropzone, {
        'className': 'col end-xs',
        'multiple': false,
        'accept': 'application/pdf',
        'onDrop': this.uploadScientificEvidence
    }, React.createElement(Button, {
        'label': 'Upload Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    }))) : null))) : null, this.props.programType === 'quickstart' ? [,] : null, !this.props.disableEditing ? React.createElement('div', {
        'className': 'row end-xs m-t-10',
        'key': '8945'
    }, React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success-red c-white',
        'raised': true
    })) : null));
}