import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actionCreator from '../actions/client_payment_plan_action';

const getClientPaymentPlanList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/get_client_payment_plan_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.clientPaymentPlanListFill(
                response.data
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getClientPaymentPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/get_client_payment_plan";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.clientPaymentPlanInstanceFill(
                response.data
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const postClientPaymentPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/post_client_payment_plan";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.clientPaymentPlanInstanceFill(
                response.data
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const subscribeToClientPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        let config = {};
        config["headers"] = {
            'USER_AGENT': navigator.userAgent,
            'Accept': "application/json",
            'Content-type': "application/json",
        };
        if (_.has(params,"token")){
            config["headers"]["STRIPETOKEN"] = params.token;
        }
        let updated_params = Object.assign({},params);
        delete updated_params.token;
        const url = "/" + API_VERSION + "/" + PACKAGE + "/subscribe_client_payment_plan";
        return Axios.post(url, updated_params,config).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully subscribed to plan");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const unSubscribeFromClientPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/unsubscribe_client_payment_plan";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully unsubscribed from plan");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const clientGetPaymentPlanList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_get_payment_plans";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.clientPaymentPlanSubscriptionListFill(
                response.client_payment_plan_subscriptions
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};




export {
    getClientPaymentPlanList,
    getClientPaymentPlan,
    postClientPaymentPlan,
    subscribeToClientPlan,
    unSubscribeFromClientPlan,
    clientGetPaymentPlanList,
};
    