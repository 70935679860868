import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/gi_api_login";
import { reduxForm } from 'redux-form';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { giApiLogin } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import {history} from "../../redux/store";

class GIApiLogin extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }
    
    componentWillMount() {
        if (this.props.from_authenticated){
            Utils.removeLocalStorageValue("token");
            localStorage.removeItem("selectedRole"); 
            window.location.reload();
        }
    }
    

    componentDidMount() {        
        let params = {};
        params["token"] = this.props.match.params.token;
        this.props.giApiLogin({ ...params }).then((response) => {     
            
        }).catch((error) => {
            //do nothing                
        }); 
    }

    render() {
        return Template.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        giApiLogin,
     }, dispatch);
}
function mapStateToProps(state) {
    let initialValues = {};
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        initialValues: initialValues,
    };
}

const GIApiLoginConnected = connect(mapStateToProps, mapDispatchToProps)(GIApiLogin);
export default GIApiLoginConnected;
