import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { PARTNER } from '../../../core/constants/constants';
import { Link } from 'react-router-dom';
import Checkbox from 'react-toolbox/lib/checkbox';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs middle-xs  full-height c-white w-100' }, React.createElement('form', {
        'className': 'no-box-shadow bg-none col-xs-11 col-sm-10 col-md-6 big-labels white-labels',
        'onSubmit': this.props.handleSubmit(this.doLogin)
    }, React.createElement('h1', { 'className': 'c-white' }, 'SIGN IN'), React.createElement(Field, {
        'icon': 'email',
        'name': 'email',
        'type': 'text',
        'component': TextInput,
        'hint': 'Email Address'
    }), React.createElement(Field, {
        'icon': 'lock',
        'name': 'password',
        'type': 'password',
        'component': TextInput,
        'hint': 'Password'
    }), React.createElement('div', { 'className': 'f-300 f-16 c-white m-b-10' }, React.createElement(Checkbox, {
        'checked': this.state.acceptPrivacyPolicy,
        'className': 'c-white di-block m-b-0',
        'label': true,
        'onChange': this.acceptPrivacyPolicyChange
    }), '\n                I accept ', PARTNER.name, ' \n                    ', React.createElement(Link, {
        'to': '/terms_of_use',
        'className': 'c-success'
    }, 'User Agreement'), ' and \n                    ', React.createElement(Link, {
        'to': '/site_media/static/files/privacy_policy.pdf',
        'target': '_blank',
        'className': 'c-success'
    }, 'Privacy policy')), React.createElement('div', { 'className': 'row w-100 center-xs m-b-25' }, React.createElement(Button, {
        'label': 'Sign in',
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success big-button',
        'raised': true,
        'style': { width: '180px' }
    })), !this.state.is_gi_subdomain ? React.createElement('div', {
        'className': 'row w-100 center-xs m-b-20',
        'key': '1809'
    }, React.createElement(Button, {
        'label': 'Sign up',
        'type': 'button',
        'flat': true,
        'className': 'c-white  col-xs-5  m-t-25',
        'style': { border: '2px solid white' },
        'onClick': this.gotoSignup
    }), React.createElement(Button, {
        'label': 'Reset Password',
        'type': 'button',
        'flat': true,
        'className': 'c-white  col-xs-5 m-l-25  m-t-25',
        'style': { border: '2px solid white' },
        'onClick': this.gotoResetPassword
    })) : null, this.state.is_gi_subdomain ? React.createElement('div', {
        'className': 'row w-100 center-xs m-b-20',
        'key': '2460'
    }, React.createElement(Button, {
        'label': 'Reset Password',
        'type': 'button',
        'flat': true,
        'className': 'c-white  col-xs-5 m-l-25  m-t-25',
        'style': { border: '2px solid white' },
        'onClick': this.gotoResetPassword
    })) : null));
}