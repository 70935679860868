import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { DatePicker } from 'react-toolbox/lib/date_picker';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Checkbox from '../../../../utils/common_components/checkbox_form';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
import VimeoUploader from '../../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../../utils/common_components/vimeo_player';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'HomeFit Workout',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {}, React.createElement('div', { 'className': 'row m-25' }, React.createElement('h4', { 'className': 'bg-lightgray text-left p-5 m-b-10' }, 'Workout Video'), !this.state.video_guid ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '1714'
    }, React.createElement(VimeoUploader, {
        'className': 'text-center',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed
    })) : null, this.state.video_guid ? React.createElement('div', {
        'className': 'col-xs-4 m-t-10 box-300-225',
        'key': '2103'
    }, React.createElement(VimeoPlayer, {
        'handleUploadProcessing': true,
        'identifier': 'move_video_' + this.state.video_guid,
        'videoList': [this.state.video_guid]
    })) : null, React.createElement('h4', { 'className': 'bg-lightgray text-left p-5 m-t-10 m-b-10' }, 'Intro Video'), !this.state.intro_video_guid ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '2583'
    }, React.createElement(VimeoUploader, {
        'className': 'text-center',
        'onUploadCompleted': this.onIntroVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onIntroVideoUploadFailed
    })) : null, this.state.intro_video_guid ? React.createElement('div', {
        'className': 'col-xs-4 m-t-10 box-300-225',
        'key': '2988'
    }, React.createElement(VimeoPlayer, {
        'handleUploadProcessing': true,
        'identifier': 'move_video_' + this.state.intro_video_guid,
        'videoList': [this.state.intro_video_guid]
    })) : null, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'name',
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Title'
    })))), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(DatePicker, {
        'label': 'Visible Date',
        'onChange': this.handleChange,
        'onDismiss': this.handleReset,
        'value': this.state.date != 'Invalid Date' ? this.state.date : 'null'
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'workout_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Workout Duration'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'workout_type',
        'component': Select,
        'label': 'Workout Type',
        'options': this.props.supporting_data.workout_type,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'trainer_level',
        'component': Select,
        'label': 'Training Level',
        'options': this.props.supporting_data.trainer_level,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12 text-left' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'is_free',
        'component': Checkbox,
        'label': 'Is Workout Free'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'coach',
        'labelKey': 'name',
        'valueKey': 'id',
        'component': Select,
        'label': 'Coach',
        'options': this.props.supporting_data.coaches,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'equipments',
        'labelKey': 'name',
        'valueKey': 'id',
        'component': Select,
        'multi': true,
        'label': 'Equipment',
        'options': this.props.supporting_data.equipments,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'sports',
        'labelKey': 'name',
        'valueKey': 'id',
        'component': Select,
        'label': 'Sports',
        'options': this.props.supporting_data.sports,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'labelKey': 'name',
        'valueKey': 'id',
        'name': 'movement_meter_type',
        'component': Select,
        'label': 'Movement Meter Type',
        'options': this.props.supporting_data.movement_meter_type,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'labelKey': 'full_name',
        'valueKey': 'id',
        'name': 'movement_meter_sub_type',
        'component': Select,
        'label': 'Movement Meter Sub Type',
        'options': this.props.supporting_data.movement_meter_sub_type,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-6 start-xs m-t-10 m-l-20' }, React.createElement('label', { 'className': 'f-12 c-primary' }, 'Contribution to Weekly Movement Goals'), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'mobility_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Mobility (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'strength_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Strength (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'metabolic_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Metabolic (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'power_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Power (In minutes)'
    }))), React.createElement('div', { 'className': 'clearfix' }), React.createElement('div', { 'className': 'col-xs-12 end-xs m-20' }, React.createElement(Button, {
        'label': 'Save as Draft',
        'className': 'bg-primary m-r-10',
        'onClick': this.saveAsDraft,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Save & Publish',
        'className': 'bg-success m-r-10',
        'onClick': this.saveAndPublish,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    })))))));
}