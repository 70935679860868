import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import FontIcon from 'react-toolbox/lib/font_icon';
import { NavLink } from 'react-router-dom';
import { IconMenu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { PARTNER } from '../../../core/constants/constants';
export default function () {
    return React.createElement('ul', { 'className': 'm-t-24' }, PARTNER.app_type === 'dhf' && this.props.selectedRole.is_workfit_practice === false ? React.createElement('span', { 'key': '821' }, this.props.roles.length > 1 ? React.createElement('li', { 'key': '932' }, React.createElement('a', {
        'onClick': this.switchRole,
        'className': 'pointer'
    }, React.createElement(FontIcon, {
        'value': 'gm-switch-role',
        'gomotive': true
    }), '\n                Switch Role\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/home`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n                Home\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/client`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-clients',
        'gomotive': true
    }), '\n                Clients\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/group`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-group',
        'gomotive': true
    }), '\n                Groups\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/education`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'library_books' }), '\n                Education\n            ')), !this.props.selectedRole.is_consultant ? React.createElement('li', { 'key': '2150' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-appointments',
        'gomotive': true
    }), '\n                Scheduler\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-content-library',
        'gomotive': true
    }), '\n                Content Library\n            ')), !this.props.selectedRole.is_consultant ? React.createElement('li', { 'key': '2740' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-branding-tools',
        'gomotive': true
    }), '\n                Branding Tools\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/payments`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'credit_card' }), '\n                Plans\n            ')), this.props.selectedRole.role.name === 'practice_admin' ? React.createElement('li', { 'key': '3303' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-admin',
        'gomotive': true
    }), '\n                Admin\n            ')) : null, this.props.selectedRole.role.name === 'practice_admin' && this.props.selectedRole.practice.is_business_partner === true ? React.createElement('li', { 'key': '3611' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/partner`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'device_hub' }), '\n                Business Partners\n            ')) : null, this.props.user.id === 1599 ? React.createElement('li', { 'key': '3989' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/workfit`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'fitness_center' }), '\n                WorkFit\n            ')) : null, this.props.user.id === 1599 ? React.createElement('li', { 'key': '4279' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/move`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'fitness_center' }), '\n                HomeFit\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n                User Settings\n            ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n                Sign out\n            '))) : null, PARTNER.app_type === 'dhf' && this.props.selectedRole.is_workfit_practice === true ? React.createElement('span', { 'key': '5014' }, this.props.roles.length > 1 ? React.createElement('li', { 'key': '5124' }, React.createElement('a', {
        'onClick': this.switchRole,
        'className': 'pointer'
    }, React.createElement(FontIcon, {
        'value': 'gm-switch-role',
        'gomotive': true
    }), '\n                Switch Role\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/home`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n                Home\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/client`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-clients',
        'gomotive': true
    }), '\n                Clients\n            ')), !this.props.selectedRole.is_consultant ? React.createElement('li', { 'key': '5851' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-appointments',
        'gomotive': true
    }), '\n                Scheduler\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/workfit_reports`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-user-terms',
        'gomotive': true
    }), React.createElement('abbr', {}, 'Reports'))), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-branding-tools',
        'gomotive': true
    }), '\n                Branding Tools\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/payments`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'credit_card' }), '\n                Plans\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-admin',
        'gomotive': true
    }), '\n                Admin\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n                User Settings\n            ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n                Sign out\n            '))) : null, PARTNER.app_type === 'gi' ? React.createElement('span', {
        'className': 'scroll',
        'key': '7753'
    }, React.createElement('li', { 'className': 'home-tab m-t-10' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/home`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'className': 'home-icon d-flex align-center h-30',
        'gomotive': true
    }), '\n                Home\n            ')), this.props.selectedRole.role.name === 'practice_admin' && this.props.user.gi_isadmin === true || this.props.user.id === 1599 ? React.createElement('li', {
        'className': 'gicontent-tab',
        'key': '8558'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/gi_content`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'className': 'gicontent-icon m-r-15',
        'gomotive': true
    }), '\n                GI Content\n            ')) : null, this.props.selectedRole.role.name === 'practice_admin' && this.props.user.gi_isadmin === true || this.props.user.id === 1599 ? React.createElement('li', {
        'className': 'education-tab',
        'key': '9001'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/education/courses`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'className': 'edu-icon m-r-10' }), '\n                Education\n            ')) : null, React.createElement('li', {
        'onClick': this.toggleTrainerMenu,
        'className': 'trainer-tab'
    }, React.createElement(NavLink, {
        'to': `#`,
        'activeClassName': true
    }, React.createElement(FontIcon, {
        'className': 'trainer-icon m-r-10',
        'gomotive': true
    }), React.createElement('div', { 'className': 'active-red' }, 'Trainer'), React.createElement(FontIcon, {
        'className': 'downarrow-icon m-b-6 m-l-10 d-flex m-l-0 m-r-20',
        'gomotive': true
    }))), this.state.showTrainerMenu ? React.createElement('li', { 'key': '9793' }, React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/client`,
        'activeClassName': 'active'
    }, '\n                Clients\n            '), this.props.user.id !== 1599 ? React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/content`,
        'activeClassName': 'active',
        'key': '10031'
    }, '\n                My Content\n            ') : null, this.props.user.id !== 1599 ? React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler`,
        'activeClassName': 'active',
        'key': '10268'
    }, '\n                Scheduler\n            ') : null) : null, React.createElement('li', {
        'onClick': this.toggleSidebar,
        'className': 'template-tab'
    }, React.createElement(NavLink, {
        'to': `#`,
        'activeClassName': true
    }, React.createElement(FontIcon, {
        'className': 'template-icon m-r-10',
        'gomotive': true
    }), React.createElement('div', { 'className': 'active-red' }, 'Templates'), React.createElement(FontIcon, {
        'className': 'downarrow-icon m-b-6 m-l-10 d-flex m-l-0 m-r-20',
        'gomotive': true
    }))), this.state.showSidebar ? React.createElement('li', {
        'className': 'scroll',
        'key': '10891'
    }, React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/goal`,
        'activeClassName': 'active'
    }, '\n                        Goal\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/treatment_templates/gameplan`,
        'activeClassName': 'active'
    }, '\n                        Game Plans\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/p/template/list`,
        'activeClassName': 'active'
    }, '\n                        Workouts\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/exercise`,
        'activeClassName': 'active'
    }, '\n                        Exercises\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/habit_templates`,
        'activeClassName': 'active'
    }, '\n                        Habits\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/d/nutrition`,
        'activeClassName': 'active'
    }, '\n                        Nutrition\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/d/guidance`,
        'activeClassName': 'active'
    }, '\n                        Guidance\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/intake`,
        'activeClassName': 'active'
    }, '\n                        Intakes\n            '), this.props.user.id === 1599 ? React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/notification_templates`,
        'activeClassName': 'active',
        'key': '12665'
    }, '\n                        Notification\n            ') : null, this.props.user.id == 1599 ? React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/cue`,
        'activeClassName': 'active',
        'key': '12927'
    }, '\n                        Cues\n            ') : null, React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/email_template`,
        'activeClassName': 'active'
    }, '\n                        Emails\n            ')) : null, this.props.selectedRole.role.name === 'practice_admin' && this.props.user.gi_isadmin === true || this.props.user.id === 1599 ? React.createElement('li', {
        'className': 'users-tab',
        'key': '13384'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/users`,
        'activeClassName': true
    }, React.createElement(FontIcon, {
        'className': 'user-icon m-r-10',
        'gomotive': true
    }), '\n                Users\n            ')) : null, this.props.user.id === 1599 ? React.createElement('li', {
        'onClick': this.toggleMarketingMenu,
        'className': 'marketing-tab',
        'key': '13787'
    }, React.createElement(NavLink, {
        'to': `#`,
        'activeClassName': true
    }, React.createElement(FontIcon, {
        'className': 'marketing-icon m-r-10',
        'gomotive': true
    }), React.createElement('div', { 'className': 'active-red' }, 'Marketing'), React.createElement(FontIcon, {
        'className': 'downarrow-icon m-b-6 m-l-10 d-flex m-l-0 m-r-20',
        'gomotive': true
    }))) : null, this.state.showMarketingMenu ? React.createElement('li', { 'key': '14209' }, React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/marketing/banners`,
        'activeClassName': 'active'
    }, '\n                Banners\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/marketing/how-to-videos`,
        'activeClassName': 'active'
    }, '\n                How-to Videos\n            ')) : null, this.props.user.is_lms_admin ? React.createElement('li', {
        'onClick': () => {
            this.redirectToLMS(1);
        },
        'className': 'users-tab',
        'key': '15150'
    }, React.createElement(NavLink, {
        'to': `#`,
        'activeClassName': true
    }, React.createElement(FontIcon, {
        'className': 'user-icon m-r-15',
        'gomotive': true
    }), '\n                LMS Admin\n            ')) : null, this.props.user.id === 1599 ? React.createElement('li', {
        'onClick': this.toggleHomefitMenu,
        'className': 'homefit-tab',
        'key': '15739'
    }, React.createElement(NavLink, {
        'to': `#`,
        'activeClassName': true
    }, React.createElement(FontIcon, {
        'className': 'homefit-icon m-r-10',
        'gomotive': true
    }), React.createElement('div', { 'className': 'active-red' }, 'HomeFit'), React.createElement(FontIcon, {
        'className': 'downarrow-icon m-b-6 m-l-10 d-flex m-l-0 m-r-20',
        'gomotive': true
    }))) : null, this.state.showHomefitMenu ? React.createElement('li', {
        'style': { paddingBottom: '100px' },
        'key': '16153'
    }, React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/content`,
        'activeClassName': 'active'
    }, '\n                Content\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/workout/list`,
        'activeClassName': 'active'
    }, '\n                Workouts\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler`,
        'activeClassName': 'active'
    }, '\n                Scheduler\n            '), React.createElement(NavLink, {
        'className': 'dropdown-content',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/groups`,
        'activeClassName': 'active'
    }, '\n                Groups (Programs)\n            ')) : null) : null, PARTNER.app_type === 'tri' ? React.createElement('span', { 'key': '19310' }, this.props.roles.length > 1 ? React.createElement('li', { 'key': '19364' }, React.createElement('a', {
        'onClick': this.switchRole,
        'className': 'pointer'
    }, React.createElement(FontIcon, {
        'value': 'gm-switch-role',
        'gomotive': true
    }), '\n                Switch Role\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/home`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n                Home\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/client`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-clients',
        'gomotive': true
    }), '\n                Clients\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/group`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-group',
        'gomotive': true
    }), '\n                Groups\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/education`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'library_books' }), '\n                Education\n            ')), !this.props.selectedRole.is_consultant ? React.createElement('li', { 'key': '20584' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-appointments',
        'gomotive': true
    }), '\n                Scheduler\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-content-library',
        'gomotive': true
    }), '\n                Content Library\n            ')), !this.props.selectedRole.is_consultant ? React.createElement('li', { 'key': '21174' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-branding-tools',
        'gomotive': true
    }), '\n                Branding Tools\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/payments`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'credit_card' }), '\n                Plans\n            ')), this.props.selectedRole.role.name === 'practice_admin' ? React.createElement('li', { 'key': '21737' }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/admin`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-admin',
        'gomotive': true
    }), '\n                Admin\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n                User Settings\n            ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n                Sign out\n            '))) : null);
}