import * as React from 'react';
import * as _ from 'lodash';
import { Dialog } from 'react-toolbox/lib/dialog';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Dropzone from '../dropzone';
import Button from 'react-toolbox/lib/button';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement(Dialog, { 'active': this.state.uploading }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, '\n                Uploading Video...\n            ')), React.createElement(ProgressBar, {
        'className': 'm-t-10 progress-bar-height',
        'type': 'linear',
        'mode': 'determinate',
        'value': this.state.uploadProgress,
        'buffer': 0
    }), React.createElement('div', { 'className': 'row end-xs m-t-10 m-r-0' }, React.createElement(Button, {
        'label': 'Cancel',
        'primary': true,
        'raised': true,
        'onClick': this.cancelUpload
    }))), React.createElement(Dialog, { 'active': this.state.processing }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, '\n                Processing Uploaded Video...\n            ')), React.createElement(ProgressBar, {
        'className': 'm-t-10 progress-bar-height',
        'type': 'linear',
        'mode': 'determinate',
        'value': this.state.processingProgress,
        'buffer': 0
    }), React.createElement('div', { 'className': 'row end-xs m-t-10 m-r-0' }, React.createElement(Button, {
        'label': 'Cancel',
        'primary': true,
        'raised': true,
        'onClick': this.cancelProcessing
    }))), React.createElement(Dropzone, {
        'multiple': false,
        'accept': '.mov,.f4v,.m4v,.asf,.wmv,.vob,.divx,.xvid,.webm,.3gp,.mp4,.flv,.mkv,.x-m4v,video/mp4,video/  x-m4v,video/quicktime,video/*',
        'onDrop': this.upload
    }, React.createElement(Button, {
        'className': 'col m-l-10',
        'label': this.props.buttonText,
        'primary': true,
        'raised': true
    })));
}