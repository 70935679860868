import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import template from "./templates/buy_plans_splash.rt";
import Swal from 'sweetalert2';
import { getPracticePaymentSubscriptionDetails } from '../../redux/payment/network/plan_subscribe_network' 
import { history } from "../../redux/store";

class SplashPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.openMobilePopup = this.openMobilePopup.bind(this)
        this.goToDashboard = this.goToDashboard.bind(this)
        this.state = {
            userSubscriptionStatus: false,
        }
    }
    goToDashboard(){
        history.push(`/practice/${this.props.selectedRole.practice.id}/home`)
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.getPracticePaymentSubscriptionDetails({
            "package_version": "3", 
            "app_version": "5.4", 
            "current_role_type": "practice_admin", 
            "practice_id": this.props.practice_id
        }).then((res)=>{ 
            this.setState({
                userSubscriptionStatus: true
            })
        })
    }
    openMobilePopup() {
        let that = this;
        Swal.fire({
            customClass: {
                container: 'paywall-modal splash-swal',
                title: 'paywall-title',
                html:'paywall-text',
                confirmButton: 'paywall-confirm-button',
                cancelButton: 'paywall-cancel-button',
              },
            title: "Active Subscription Required",
            html: '<p>Use of this premium template requires an active Trainer Plus Subscription, which can be purchased from the Gray Institute mobile app.</p>' + "<p>To access this template and all other premium templates, please purchase the required subscription from your App Store.</p>",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#fff",
            confirmButtonText: "Go to App Store",
            cancelButtonText: "Go to Play Store",
            useRejections:true
        }).then((result) => {
            if (result.value) {
                window.open('https://apps.apple.com/us/app/dhf-coachfit/id1130877074', '_blank');
              } else if (result.dismiss=='cancel') {
                window.open('https://play.google.com/store/apps/details?id=com.gomotive.dhf', '_blank');
              }
            return
        }, function (dismiss) {
        });
    }
    render() {
        return template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPracticePaymentSubscriptionDetails,
    }, dispatch);
}
function mapStateToProps(state) {    
    return {
        user: state.auth.authReducer.user,
        roles: state.auth.roleReducer.roles,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,

    };
}
const SplashPageComponent = connect(mapStateToProps, mapDispatchToProps)(SplashPage);
export default SplashPageComponent;