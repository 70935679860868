import BaseComponent from '../../utils/common_components/basecomponent';
import LoginTemplate from "./templates/login";
import GiLoginTemplate from "./templates/gi_login";
import { reduxForm } from 'redux-form';
import store,{history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login, facebookLogin } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import {PARTNER} from '../../core/constants/constants';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['email', 'password'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    // if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //     errors.email = 'Invalid email address!';
    // }
    //  if (values.password.length < 6) {
    //     errors.password = 'Password must be at least 6 letter long!'
    //   }  
    return errors;
};
class Login extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doLogin = this.doLogin.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.gotoSignup = this.gotoSignup.bind(this);
        this.gotoResetPassword = this.gotoResetPassword.bind(this);
        this.gotoReset = this.gotoReset.bind(this);
        this.acceptPrivacyPolicyChange = this.acceptPrivacyPolicyChange.bind(this);

        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.state = {
            acceptPrivacyPolicy: false,
            is_gi_subdomain: PARTNER.subdomain === "gi" ? true : false
        };
    }
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        window.scrollTo(0, 0);
        if(this.props.user.is_guest){
            history.push('/guest');
        } else if (this.props.isAuthenticated) {
            history.push('/');
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if(nextProps.user.is_guest){
    //         history.push('/guest');
    //     } else if (nextProps.isAuthenticated) {
    //         history.push('/');
    //     }
    // }
    doLogin(data) {
        // if (!this.state.acceptPrivacyPolicy) {
        //     Utils.showInfoAlert("Please accept our privacy terms");
        //     return;
        // }
        this.props.login(data.email, data.password).then((response) => {
            try {
                window.zE(function () {
                    zE.show();
                });
                window.zE(function () {
                    zE.identify({
                        name: response.user.name,
                        email: response.user.email,
                    });
                });
                // window.location.reload();
            } catch (err){ 
                console.log('login 111111111111',err);           
            }
        }).catch((err) => {
            console.log('login then 222222222222222222',err);           
            
        });
    }

    acceptPrivacyPolicyChange() {
        this.setState({
            acceptPrivacyPolicy: !this.state.acceptPrivacyPolicy,
        });
    }

    gotoSignup(){
        history.push("/auth/signup");
    }
    gotoReset(){
        history.push("/auth/password_reset");
    }
    gotoResetPassword(){
        history.push("/auth/password_reset");
    }

    responseFacebook(response) {
        if (response.status !== "unknown") {
            this.props.facebookLogin(response);
        }    
    }
    render() {
        if(!this.state.is_gi_subdomain){
            return LoginTemplate.apply(this);
        } else {
            return GiLoginTemplate.apply(this);
        }
    }
}
const LoginForm = reduxForm(({ form: 'login', validate }))(Login);
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        login,
        facebookLogin,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        user: state.auth.authReducer.user,
    };
}
const LoginConnected = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default LoginConnected;
