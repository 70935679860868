import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import PanelGroup from '../../../vendor/react-panelgroup/panelgroup';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatGoal1(goal, goalIndex) {
        return React.createElement('div', { 'key': 'goal' + goal.id }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, '\n                                    ', goal.text, '                    \n                                '), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
            'tooltip': 'Delete',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'className': 'bg-danger micro',
            'onClick': () => {
                this.removeGoal(goal);
            }
        }))))));
    }
    function repeatGoal2(goal, goalIndex) {
        return [!(goal.id in this.props.partner_goal_ids) ? React.createElement('div', { 'key': '28461' }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-11' }, '\n                                        ', goal.text, '                    \n                                    '), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
                'tooltip': 'Add',
                'icon': 'add',
                'floating': true,
                'mini': true,
                'className': 'bg-success micro',
                'onClick': () => {
                    this.addGoal(goal);
                }
            })))))) : null];
    }
    return React.createElement('div', { 'className': 'row start-xs  m-t-20 m-l-20 custom-divider' }, React.createElement('h3', { 'className': 'text-center m-5' }, '\n        Shared content library \'', this.props.partner_library.name, '\'\n    '), React.createElement(PanelGroup, { 'borderColor': '#eee' }, React.createElement('div', { 'className': 'col-xs-12 full-height' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Shared goal tempaltes')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
        'tooltip': 'Remove all gaol templates from this shared content library',
        'label': 'REMOVE ALL',
        'className': 'bg-danger small-button c-white',
        'onClick': () => {
            this.removeAllGoalTemplates();
        }
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'm-10' },
        _.map(this.props.partner_library.goals, repeatGoal1.bind(this))
    ])), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Goal templates you can share')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
        'tooltip': 'Add all goal templates to this shared content library',
        'label': 'ADD ALL',
        'className': 'bg-success small-button c-white',
        'onClick': () => {
            this.addAllGoalTemplates();
        }
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'm-10' },
        _.map(this.props.goalList, repeatGoal2.bind(this))
    ]))));
}