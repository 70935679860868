import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../../redux/store';
import AppContainer from './app';
import PrivacyPolicy from './privacy_policy';
import TermsOfUse from './terms_of_use';
import TermsOfUsePractitioner  from './terms_of_use_practitioner';
import TermsOfUseClient  from './terms_of_use_client';
import PrivacyPolicyHomefit from './privacy_policy_homefit';

export default class Root extends React.Component {
    constructor(props) {
        super(props);
    };
    render() {
            return (
                <Provider store={this.props.store}>
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route exact path="/privacy_policy" component={PrivacyPolicy} />
                            <Route exact path="/redoc"><div id='redoc'></div></Route>                            
                            <Route exact path="/privacy_policy_homefit" component={PrivacyPolicyHomefit} />                            
                            <Route exact path="/terms_of_use" component={TermsOfUse} />
                            <Route exact path="/practitioner_terms_of_use" component={TermsOfUsePractitioner} />
                            <Route exact path="/client_terms_of_use" component={TermsOfUseClient} />
                            <Route path="/" component={AppContainer} />
                        </Switch>    
                    </ConnectedRouter>
                </Provider>
            );
    }
}
