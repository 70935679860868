import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    return React.createElement('div', { 'className': 'bundles-content' }, React.createElement(Table, {
        'className': 'table reactable' + ' ' + true,
        'id': 'table',
        'noDataText': 'Create new bundles by clicking on add new',
        'sortable': [
            'name',
            'author'
        ],
        'isServerPaginate': true
    }, React.createElement(Thead, {}, React.createElement(Th, {
        'column': 'name',
        'style': { width: '30%' }
    }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, { 'column': 'sku' }, React.createElement('span', { 'className': 'table-heading' }, 'SKU')), React.createElement(Th, { 'column': 'category' }, React.createElement('span', { 'className': 'table-heading' }, 'Category')), React.createElement(Th, { 'column': 'price' }, React.createElement('span', { 'className': 'table-heading' }, 'Price')), React.createElement(Th, { 'column': 'status' }, React.createElement('span', { 'className': 'table-heading' }, 'Status')), React.createElement(Th, {
        'column': 'action',
        'style': { width: '65px' }
    }, React.createElement('span', { 'className': 'table-heading' }, 'Action'))), React.createElement(Tr, {}, React.createElement(Td, {
        'column': 'name',
        'style': { width: '30%' }
    }, React.createElement('span', {}, 'Bundle Name')), React.createElement(Td, { 'column': 'sku' }, React.createElement('span', {}, '\n                    XXXXXX\n                ')), React.createElement(Td, { 'column': 'category' }, React.createElement('span', {}, '\n                    3DMAPS\n                ')), React.createElement(Td, { 'column': 'price' }, React.createElement('span', {}, '\n                   $ 4.99\n                ')), React.createElement(Td, { 'column': 'status' }, React.createElement('span', {}, '\n                    Published\n                ')), React.createElement(Td, { 'column': 'action' }, React.createElement('span', {
        'className': 'd-flex justify-center',
        'style': { width: '65px' }
    }, React.createElement(IconButton, {
        'tooltip': 'Edit Workout Bundle',
        'className': 'edit-icon  m-r-23 c-grey small-icon-button',
        'onClick': () => {
            this.edit(1);
        }
    }), React.createElement(IconButton, {
        'tooltip': 'Hide Workout Bundle',
        'className': 'unpublish-icon m-r-23 c-grey small-icon-button',
        'onClick': () => {
            this.unpublish();
        }
    }))))));
}