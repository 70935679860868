import BaseComponent from '../../utils/common_components/basecomponent';
import OnboardTemplate from "./templates/welcome_aboard";
import { reduxForm } from 'redux-form';
import store, { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onboardSearchParams, onboardUser } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import { PARTNER } from '../../core/constants/constants';
import React from 'react';
import { uploadFiles } from '../../redux/core/network/file_upload';



const validate = (values) => {
    const errors = {};
    const requiredFields = ['role', 'isCertified'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};
class WelcomeOnboard extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.onChangeContent = this.onChangeContent.bind(this);
        this.countryChange = this.countryChange.bind(this);
        this.stateChange = this.stateChange.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.fileInputRef = React.createRef();
        this.renderImage = this.renderImage.bind(this);
        this.onCheckBoxValue = this.onCheckBoxValue.bind(this);
        this.state = {
            acceptPrivacyPolicy: false,
            is_gi_subdomain: PARTNER.subdomain === "gi" ? true : false,
            radio_options: [
                {
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                }
            ],
            areas_of_interest: [
                {
                    "id": 1,
                    "value": "Injury prevention",
                    "label": "Injury prevention",
                    "can_be_deleted": false
                },
                {
                    "id": 2,
                    "value": "Reconditioning from injury",
                    "label": "Reconditioning from injury",
                    "can_be_deleted": false
                },
                {
                    "id": 3,
                    "value": "Performance training",
                    "label": "Performance training",
                    "can_be_deleted": false
                },
                {
                    "id": 4,
                    "value": "Health & wellness",
                    "label": "Health & wellness",
                    "can_be_deleted": false
                },
            ],
            select_options: [
                {
                    "id": 1,
                    "name": "Physical Therapist",
                    "can_be_deleted": false
                },
                {
                    "id": 2,
                    "name": "Personal Trainer",
                    "can_be_deleted": false
                },
                {
                    "id": 3,
                    "name": "Strength Coach",
                    "can_be_deleted": false
                },
                {
                    "id": 4,
                    "name": "Holistic Practitioner",
                    "can_be_deleted": false
                },
                {
                    "id": 5,
                    "name": "Athletic Trainer",
                    "can_be_deleted": false
                },
                {
                    "id": 6,
                    "name": "Other",
                    "can_be_deleted": false
                }
            ],
            updatedStates: [],
            selectedImage: "",
            imageSrc: null,
            checkvalue: false,
            content: {}

        };
    }

    onChangeContent(val) {
        console.log('val', val)
    }

    onCheckBoxValue(val) {
        this.setState({
            checkvalue: val
        })
    }

    countryChange(val) {
        console.log('country', val)
        const states = this.props.onboardData.state.filter(item => item.country === val);
        this.setState({
            updatedStates: states
        })
    }
    stateChange(val) {
        console.log('state', val)
    }
    componentWillMount() {
        console.log('on onboard mount new', this.props)
        if (this.props.user.is_onboarded) {
            history.push('/practice/' + this.props.selectedRole.practice.id + '/home');
        }
        this.props.onboardSearchParams({
            "app_version": "5.2",
            "package_version": "2"
        })
            .then((resp) => {
                this.setState({
                    content: resp?.supporting_data?.coach_locator_markting_copy
                })
                console.log('onboard search resp', resp)
            })
        // if(this.props.user.is_guest){
        //     history.push('/guest');
        // } else if (this.props.isAuthenticated) {
        //     history.push('/');
        // }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user.is_onboarded) {
            history.push('/practice/' + nextProps.selectedRole.practice.id + '/home');
        }
        //     if(nextProps.user.is_guest){
        //         history.push('/guest');
        //     } else if (nextProps.isAuthenticated) {
        //         history.push('/');
        //     }
    }
    doSave(data) {        
        let values = Utils.flatternFormData(data, ['']);
        this.props.onboardUser({
            ...values,
            "hold_certification": false,
            "consented_coach_locator": this.state.checkvalue,
            "app_version": "5.2",
            "package_version": "3",
            profile_pic: this.state.imageSrc,
        })
            .then((resp) => {
                history.push("/practice/" + this.props.selectedRole.practice.id + "/home");
            })
    }

    setActive(el, active) {
        const formField = el.parentNode.parentNode
        if (active) {
            formField.classList.add('form-field--is-active')
        } else {
            formField.classList.remove('form-field--is-active')
            el.value === '' ?
                formField.classList.remove('form-field--is-filled') :
                formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el) {
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
                el.onblur = () => {
                    this.setActive(el, false)
                }
                el.onfocus = () => {
                    this.setActive(el, true)
                }
            }
        )
    }

    handleImageChange = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);

        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.setState({
                imageSrc: result.thumbnail_url
            })
        }).catch((error) => {
        });

    };

    AddPhoto = () => {
        this.fileInputRef.current.click();
    };

    renderImage() {
        const { selectedImage, imageSrc } = this.state;

        if (imageSrc) {
            return (
                <img
                    name="profile_pic"
                    src={imageSrc}
                    alt="Selected Profile Pic"
                    style={{ maxWidth: '68px', borderRadius: '50%' }}
                />
            );
        }
        else {
            return (
                <div className='relative'>
                    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34 0.25C15.4375 0.25 0.25 15.4375 0.25 34C0.25 52.5625 15.4375 67.75 34 67.75C52.5625 67.75 67.75 52.5625 67.75 34C67.75 15.4375 52.5625 0.25 34 0.25ZM34 13.375C38.125 13.375 41.5 16.75 41.5 20.875C41.5 25 38.125 28.375 34 28.375C29.875 28.375 26.5 25 26.5 20.875C26.5 16.75 29.875 13.375 34 13.375ZM15.25 52.75C15.25 42.4375 23.6875 34 34 34C44.3125 34 52.75 42.4375 52.75 52.75H15.25Z" fill="#666666" />
                    </svg>
                </div>
            )
        }

        return null;
    }


    render() {
        return OnboardTemplate.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onboardSearchParams,
        onboardUser,
        uploadFiles
    }, dispatch);
}

function mapStateToProps(state) {
    let alertCondition = (state.auth.authReducer.user.certificates.length === 0 && !state.auth.authReducer.user.is_lms_active) ? true : false;
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        user: state.auth.authReducer.user,
        onboardData: state.auth.authReducer.onboardSearchData,
        alertCondition: alertCondition
    };
}
const OnboardForm = reduxForm(
    {
        form: 'OnboardForm',
        enableReinitialize: true,
    }
)(WelcomeOnboard);
const WelcomeOnboardConnected = connect(mapStateToProps, mapDispatchToProps)(OnboardForm);
export default WelcomeOnboardConnected;
