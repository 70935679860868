import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_templates.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getPartnerTemplateList,
    addPartnerLibraryTemplate,
    removePartnerLibraryTemplate, 
    addAllWorkoutTemplatesPartnerLibrary,
    removeAllWorkoutTemplatesPartnerLibrary
} from '../../redux/partner/network/partner_network';

import {listProgram} from '../../redux/program/network/program_list';
import programListActions from '../../redux/program/actions/program_list';
import partnerActions from '../../redux/partner/actions/partner_action';
import exerciseList from '../../redux/exercise/actions/exercise_list';


class PartnerLibraryTemplates extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addTemplate = this.addTemplate.bind(this);
        this.removeTemplate = this.removeTemplate.bind(this);
        this.addAllWorkoutTemplates = this.addAllWorkoutTemplates.bind(this);
        this.removeAllWorkoutTemplates = this.removeAllWorkoutTemplates.bind(this);
    };

    componentWillMount() {
        if(!this.props.match.params.library_id){
            history.goBack();
        }
        this.props.getPartnerTemplateList({id:this.props.match.params.library_id});
        this.props.listProgram({
            program_type:0,
            fetch_type: "partner_library"
        });
    }

    componentWillUnmount() {
        this.props.cleanUp({});
        this.props.cleanTemplateList({});
    }

    addTemplate(template){
        this.props.addPartnerLibraryTemplate({id:this.props.match.params.library_id,template_id:template.id});
    }
    removeTemplate(template){
        this.props.removePartnerLibraryTemplate({id:this.props.match.params.library_id,template_id:template.id});
    }

    addAllWorkoutTemplates() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.addAllWorkoutTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.cleanTemplateList({});
            this.props.getPartnerTemplateList({id:this.props.match.params.library_id});
            this.props.listProgram({
                program_type:0,
                fetch_type: "partner_library"
            });    
        }).catch((error) => {            
        });  
    }

    removeAllWorkoutTemplates() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.removeAllWorkoutTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.cleanTemplateList({});
            this.props.getPartnerTemplateList({id:this.props.match.params.library_id});
            this.props.listProgram({
                program_type:0,
                fetch_type: "partner_library"
            });    
        }).catch((error) => {            
        });  
    }


    render() {
        if(_.isEmpty(this.props.partner_library)){
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerTemplateList,
        addPartnerLibraryTemplate,
        removePartnerLibraryTemplate,
        listProgram,
        cleanUp:partnerActions.cleanUp,
        cleanTemplateList: programListActions.cleanModule,
        addAllWorkoutTemplatesPartnerLibrary,
        removeAllWorkoutTemplatesPartnerLibrary
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    let partner_template_ids = _.keyBy(state.partner.partnerReducer.partner_library.templates,"id");
    let templateList = _.filter(state.program.programList.programList,'published');
    return {
        partner_library: state.partner.partnerReducer.partner_library,
        templateList: templateList,
        partner_template_ids:partner_template_ids
    };
}
const PartnerLibraryTemplatesConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryTemplates);
export default PartnerLibraryTemplatesConnected;
