import { combineReducers } from 'redux';
import practiceIntake from './practice_intake';
import practiceCreate from './practice_create';
import practiceList from './practice_list';
import practiceDetails from './practice_details';
import practiceDescription from './practice_description';
import practiceLocation from './practice_location';
import practicePractitioner from './practice_practitioner';
import practiceConsultants from './practice_consultants';
import practiceWelcomeVideo from './practice_welcome_video';
import practiceWrapperVideo from './practice_wrapper_video';

export default combineReducers({
    practiceCreate,
    practiceList,
    practiceDetails,
    practiceDescription,
    practiceLocation,
    practiceIntake,
    practicePractitioner,
    practiceWelcomeVideo,
    practiceWrapperVideo,
    practiceConsultants
});