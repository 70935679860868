import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import TextInput from '../../utils/common_components/textInput_form';
import Template from "./templates/move_video_edit.rt";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { reduxForm, formValueSelector, arrayPush, Field  } from 'redux-form';
import { getMoveVideo, saveMoveVideo } from '../../redux/dhf/move/network/move_network_new';
import { uploadFiles } from '../../redux/core/network/file_upload';
import MoveAction from '../../redux/dhf/move/actions/move_actions';
import moment from 'moment';
import axios from 'axios';
// const dateState = new Date();
import Dropzone from '../../utils/common_components/dropzone';
import Button from 'react-toolbox/lib/button';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';
import { getPracticePartnerList,  getAuthorList } from '../../redux/partner/network/partner_network';


let movement_meter_list = [
    {
        value: "Mobility",
        label: "Mobility",
    },
    {
        value: "Strength",
        label: "Strength",
    },
    {
        value: "Metabolic",
        label: "Metabolic",
    },
    {
        value: "Power",
        label: "Power",
    },
];
let equipment_list = [
    {
        value: "bw",
        label: "Body weight",
    },
    {
        value: "db",
        label: "Dumbbells",
    },
    {
        value: "kb",
        label: "Kettlebells",
    },
    {
        value: "pp",
        label: "Power Plate",
    },
    {
        value: "fm",
        label: "Foam Roller",
    },
    {
        value: "bd",
        label: "Bands",
    }
];
let workout_type_list = [
    {
        value: "Traditional",
        label: "Traditional",
    },
    {
        value: "Functional",
        label: "Functional",
    }
];
let workout_level_list = [
    {
        value: "Beginner",
        label: "Beginner",
    },
    {
        value: "Intermediate",
        label: "Intermediate",
    },
    {
        value: "Advanced",
        label: "Advanced",
    }
];
let workout_duration_list = [
    {
        value: 5,
        label: "5 minutes",
    },
    {
        value: 10,
        label: "10 minutes",
    },
    {
        value: 15,
        label: "15 minutes",
    },
    {
        value: 20,
        label: "20 minutes",
    },
    {
        value: 25,
        label: "25 minutes",
    },
    {
        value: 30,
        label: "30 minutes",
    },
];
class MoveVideoEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveMoveVideo = this.saveMoveVideo.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.renderEquipments = this.renderEquipments.bind(this);
        this.uploadEquipmentImage = this.uploadEquipmentImage.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.onIntroVideoUploadCompleted = this.onIntroVideoUploadCompleted.bind(this);
        this.captureThumbnail = this.captureThumbnail.bind(this);
        this.onIntroVideoUploadFailed = this.onIntroVideoUploadFailed.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.selectTrainer = this.selectTrainer.bind(this);
        this.changeCoach = this.changeCoach.bind(this);
        this.state = {
            video_guid: null,
            thumbnail_url: null,
            intro_video_guid: null,
            intro_thumbnail_url: null,
            publish: false,
            date: null,
            selectedTrainer: null,
            changeCoach: false,
        };        
        
    };
    changeCoach(){
        this.setState({
            changeCoach: true
        })
    }
    selectTrainer(value){
        this.setState({
            selectedTrainer: value.value
        })
    }
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        if(this.props.match.params.workout_id) {
            let params = {
                'move_video_id': this.props.match.params.workout_id,
                app_version: "5.1.12",
                current_role_type: "practitioner",
                package_version: "2",
                practice_id: this.props.practice_id,
            };
            this.props.getMoveVideo(params)
        } else { 
            this.props.getMoveVideo({
                app_version: "5.1.12",
                current_role_type: "practioner",
                package_version: "2",
                practice_id: this.props.practice_id,
            });
        }
        this.props.getAuthorList({
            "app_version": "5.2",
            "practice_id": 276,
            "current_role_type": "practice_admin",
            package_version: "2"
        });
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.move_video.video_guid) {
            this.setState({
                video_guid: nextProps.move_video.video_guid,
                thumbnail_url: nextProps.move_video.thumbnail_url,
                date:moment(nextProps.move_video.visible_date, moment.defaultFormat).toDate()
            });
        }
        if(nextProps.move_video.intro_video_guid) {
            this.setState({
                intro_video_guid: nextProps.move_video.intro_video_guid,
                intro_thumbnail_url: nextProps.move_video.intro_thumbnail_url,
                date:moment(nextProps.move_video.visible_date, moment.defaultFormat).toDate()
            });
        }
    }    
    renderEquipments({fields,meta}) {
        
        if(fields.length === 0 && this.props.initialValues.shopify_equipment){
            fields.removeAll();
            this.props.initialValues.shopify_equipment.forEach(() => {
                fields.push({});
            });
        }else if(fields.length === 0){
            fields.push({});
        }
        let backgroundStyle= (index) => {
            return {
                backgroundImage: `url(${this.props.equipment_thumbnail[index]})`,
                height: "30px",
                width: "30px",
                backgroundSize:"cover",
            }
        };
        return (
            <div className=" row">
                <div className="col-xs-12">                
                {
                    fields.map((item, index) => {
                            return (
                            <div key={index} className="border p-12 m-b-10">                           
                                <Field label="Equipment" className="w-100 m-b-20" name={`shopify_equipment[${index}][name]`} 
                                        component={TextInput} onClick={this.inputClick} options={this.props.supporting_data.equipments}
                                        valueKey="id" labelKey="name" multi={true} />
                                <Field label="Equipment Price" className="w-100 m-b-20" name={`shopify_equipment[${index}][price]`} component={TextInput} onClick={this.inputClick} />
                                <Field label="Shopify URL" className="w-100 m-b-20" name={`shopify_equipment[${index}][shopify_url]`} component={TextInput} onClick={this.inputClick} />

                                <div class="c-black small-title f-14 text-left">Thumbnail</div>
                                <div class="d-flex equipment-content">
                                    {this.props.equipment_filename && this.props.equipment_filename[index] ?
                                        <div class="equipment-title" >
                                            <div class="image-container m-r-20" 
                                            style={{
                                                backgroundImage: `url(${this.props.equipment_url ? this.props.equipment_url[index] : this.props.equipment_thumbnail[index]})`,
                                                height: "30px",
                                                width: "30px",
                                                backgroundSize: 'cover'}}>
                                            </div>
                                            <div class="text text-left">
                                            {this.props.equipment_filename ? this.props.equipment_filename[index] : ''}
                                            </div>
                                            <div class="cancel">
                                            </div>
                                        </div>
                                        : <div></div>
                                    }
                                    <Dropzone name={`shopify_equipment[${index}][thumbnail]`} multiple={false} accept="image/*" onDrop={(file) => {this.uploadEquipmentImage(file, index);}} class="dropzone">
                                        <Button class="add-media-btn" label={this.props.equipment_filename && this.props.equipment_filename[index] ? 'Change Media' : 'Add Media'} primary raised/>
                                    </Dropzone>

                                    {/*<Button    
                                    tooltip="Add a quantifiable measure to reach this goal"    
                                    label="Add Another"
                                    className="secondary-button bg-success-border"
                                    onClick={() => fields.push({})}
                                    />*/}   
                                </div>
                                <div class="d-flex">
                                    <Button    
                                    tooltip="Add a quantifiable measure to reach this goal"    
                                    label="Remove Equipment"
                                    className="secondary-button bg-success-border"
                                    onClick={() => {
                                        this.props.initialValues.shopify_equipment.splice(index,1)
                                        fields.remove(index)}}/>   
                                </div>
                            </div>    
                        );
                    })
                }
                </div>
                <div className="col-xs-12  end-xs">
                    <Button 
                        tooltip="Add a quantifiable measure to reach this goal"    
                        label="Add Another"
                        className="secondary-button bg-success-border"
                        onClick={() => fields.push({})}
                    />      
                </div> 
            </div>    
        );
    }
    uploadEquipmentImage(files, index) {
        let formData = new FormData();
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('shopify_equipment['+ index +'][thumbnail]', result.url );
            // this.props.change('shopify_equipment['+ index +'][media]', result );
            this.props.change('equipmentDisplay['+index+']', result.url);
            this.props.change('equipment_url['+index+']', result.url);
            this.props.change('equipment_filename['+index+']', result.file_name);
            this.props.change('is_equipImage_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
        }).catch((error) => {
        });
    }

    handleChange = (item, value) => {
        this.setState({...this.state,
             date: item});
      };
    handleReset = () => {
        this.setState({...this.state,
            date: null});
      };

    componentWillUnmount() {
        this.props.cleanUp();
    }

    onVideoUploadCompleted(result) {
        let that = this;
        that.props.change('thumbnail_url', result.thumbnail_url);
        that.props.change('video_guid', result.video_guid);
        setTimeout(function() {
            that.setState({
                video_guid: result.guid,
                thumbnail_url: result.thumbnail,
            }); 
        }, 500);

    }

    onVideoUploadFailed(error) {        
        this.setState({
            video_guid: null,
            thumbnail_url: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    onIntroVideoUploadCompleted(result) {
        let that = this;
        setTimeout(function() {
            that.setState({
                intro_video_guid: result.guid,
                intro_thumbnail_url: result.thumbnail,
            }); 
        }, 500);

    }

    onIntroVideoUploadFailed(error) {        
        this.setState({
            intro_video_guid: null,
            intro_thumbnail_url: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }


    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/workout/list");
    }

    saveAsDraft() {
        this.setState({publish:false},()=>{
            this.props.handleSubmit(this.saveMoveVideo)();
       });
    }

    saveAndPublish() {
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.saveMoveVideo)();
        });
    }

    captureThumbnail() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type':'application/json',
            }
        });
        if (that.player) {
            this.props.showProgress();
            that.player.getCurrentTime().then(function(time) {
                let params = {time:time,active:true};
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.video_guid + "/pictures",params
                ).then((response) => {
                    let thumbnail_url =  "";
                    if(response.data.sizes.length > 3 ){
                        thumbnail_url = response.data.sizes[3].link;
                    }else{
                        thumbnail_url = response.data.sizes[response.data.sizes.length -1].link;
                    }
                    that.props.change('thumbnail_url', thumbnail_url);    
                    that.props.hideProgress();
                    Utils.showSuccessAlert("Your new thumbnail image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.hideProgress();
                });
            }).catch(function(error) {
                that.props.hideProgress();
            });
        }
    }

    saveMoveVideo(data) {
        if(!this.state.video_guid) {
            Utils.showErrorAlert("You have not uploaded video for HomeFit Workout");
            return;
        }
        let params = Utils.flatternFormData(data, ['position_pictures']);
        if(this.props.match.params.workout_id) {
            params['move_video_id'] = this.props.match.params.workout_id;
        }
        params['is_published'] = this.state.publish;
        // params['equipments'] = this.props.move_video.equipments.map(equipment => equipment.id );
        params['video_guid'] = this.state.video_guid;
        params['thumbnail_url'] = this.props.thumbnail_url || this.state.thumbnail_url;
        params['intro_video_guid'] = this.state.intro_video_guid;
        params['intro_thumbnail_url'] = this.state.intro_thumbnail_url;
        params['visible_date'] = this.state.date;
        params['trainer'] = this.state.selectedTrainer ? this.state.selectedTrainer : this.props.trainer ? this.props.trainer.id : null;
        params.shopify_equipment = data.shopify_equipment;
        
        let checkShopify
        if(params.shopify_equipment && params.shopify_equipment.length){
            checkShopify = params.shopify_equipment.map((o)=> {
                if(o.thumbnail == undefined || o.name == undefined ||o.price == undefined || o.shopify_url == undefined ){
                    return o
                }
            })
        }
        let keysToCheck = ['thumbnail', 'name', 'price', 'shopify_url']
            if(checkShopify && checkShopify.length && !checkShopify.every(i => i === undefined)){
                let shopifyIndices = []
                checkShopify.map((i,index)=> {
                    if(i !== undefined){
                        keysToCheck.map(key => {
                            if(Object.keys(i).indexOf(key) == -1){
                                Utils.showErrorAlert("Please add "+ key +" in equipment no. " + (index+1))
                            }
                        })
                        return shopifyIndices.push(index+1)
                    }
                })
                // shopifyIndices.map((x) => {
                //     if(x != undefined){
                        // Utils.showErrorAlert("Please add thumbnail in equipment no. " + shopifyIndices.map(i => i))
                //     }
                // })
            }else if(!params.movement_meter_type){
                Utils.showErrorAlert("Please add Movement Meter Type ")
            } else {
            this.props.saveMoveVideo({
                ...params,
                app_version: "5.1.12",
                current_role_type: "practitioners",
                package_version: "2",
                practice_id: this.props.practice_id,
            }).then((response) => {
                Utils.showSuccessAlert("Your HomeFit workout has been successfully saved");
                history.push("/practice/" + this.props.selectedRole.practice.id + "/engagement/workout/list");
            }).catch(() => { });
        }
    }

    render() {
        if(typeof this.props.trainer === 'object'){
            this.props.trainer ? this.props.change('trainer', this.props.trainer.value) :null
        }
        return Template.apply(this);
    }
}

const selector = formValueSelector('WrapperVideoEditForm');

function mapStateToProps(state) {
    let workout = state.move.moveReducer.move_video;
    let equipment_thumbnail = [];
    let equipment_filename = [];
    let equipments = [];
    let is_equipImage_uploaded = [];
    if(workout.shopify_equipment){
        workout.shopify_equipment.map((equip, index) => {
            equipment_filename[index] = "thumbnail";  
            equipment_thumbnail[index] = equip.thumbnail;  
            is_equipImage_uploaded[index] = true;
        });
    }
    if(workout.equipments){
        equipments = workout.equipments.map((equipment)=> equipment.id)
    }
    let initialValues = { 
        ...workout, 
        equipments: equipments,
        equipment_thumbnail,
        equipment_filename,
        is_equipImage_uploaded,
    }
    // delete initialValues.equipments;

    return {
        practice_partners:state.partner.partnerReducer.practice_partners,
        authors:state.partner.partnerReducer.authors,
        thumbnail_url: selector(state, 'thumbnail_url'),
        video_guid: selector(state, 'video_guid'),
        is_equipImage_uploaded: selector(state, 'is_equipImage_uploaded'),
        equipment_thumbnail: selector(state, 'equipment_thumbnail'),
        equipment_url: selector(state, 'equipment_url'),
        equipment_filename: selector(state, 'equipment_filename'),
        shopify_equipment: selector(state, 'shopify_equipment'),
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        move_video: workout,
        supporting_data: state.move.moveReducer.supporting_data,
        initialValues:initialValues,
        movement_meter_list,
        equipment_list,
        workout_type_list,
        workout_level_list,
        workout_duration_list,
        trainer: selector(state,'trainer')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getAuthorList,
            getMoveVideo,
            saveMoveVideo,
            uploadFiles,
            showProgress,
            hideProgress,
            cleanUp: MoveAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const MoveVideoEditForm = reduxForm({
    form: 'WrapperVideoEditForm', 
    enableReinitialize: true, 
    validate
})(MoveVideoEdit);


const MoveVideoEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(MoveVideoEditForm);
export default MoveVideoEditFormConnected;