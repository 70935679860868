// Action Creators
const fillExercise = (exercise, supportingData, shopify_equipment) => ({
    type: 'EXERCISE_FILL',
    exercise,
    supportingData,
    shopify_equipment,
});
const cleanModule = () => ({
    type: 'EXERCISE_CLEANUP',
});

export default {
    fillExercise,
    cleanModule,
};