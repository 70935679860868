import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
export default function () {
    function repeatMedia1(media, index) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5',
            'key': 'media' + index
        }, media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225 pointer',
            'style': { backgroundImage: 'url(' + encodeURI(media.thumbnail_url) + ')' },
            'onClick': () => {
                this.previewImage(media);
            },
            'key': '1634'
        }) : null, media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225 pointer',
            'key': '1899'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'gomotive_video' + media.guid,
            'videoList': [media.guid]
        })) : null);
    }
    return React.createElement('div', { 'className': 'row' }, React.createElement(Card, { 'className': 'col-xs-12 no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Assessment ' + this.props.assessment.assessment_name,
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height m-t-10' }, React.createElement('div', { 'className': 'f-20' }, '\n                Asssessment was carried out by ', this.props.assessment.practitioner.name, ' on ', moment(this.props.assessment.assessment_date).format('MMMM Do YYYY'), '\n            '), React.createElement('div', { 'className': 'm-t-20' }, '\n                Assessment Note: ', this.props.assessment.assessment_note, '\n            '), React.createElement.apply(this, [
        'div',
        { 'className': 'row m-t-20' },
        _.map(this.props.assessment.media, repeatMedia1.bind(this))
    ]))), this.state.showMediaPreview ? React.createElement(Lightbox, {
        'mainSrc': this.state.displayImageUrl,
        'onCloseRequest': () => this.setState({
            showMediaPreview: false,
            displayImageUrl: null
        }),
        'key': '2285'
    }) : null);
}