import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import { Switch as Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
export default function () {
    return React.createElement('div', { 'className': 'row center-profile' }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.doCancel
    }, '\n        Back\n    '), React.createElement('div', { 'className': 'max-con' }, '\n          ', this.ViewProfileInfo(), '\n        '), React.createElement(Routes, {}, React.createElement(Route, {
        'exact': true,
        'path': '/edit_profile',
        'render': () => {
            return this.returnEditProfileApp();
        }
    })));
}