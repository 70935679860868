import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_booking";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import Swal from 'sweetalert2'
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../redux/store';
import { groupClassSchedulerBookingList,deleteGroupClassClient } from '../../redux/calendar/network/group_class_network';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';

class GroupClassBooking extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addGroupClassBooking = this.addGroupClassBooking.bind(this);
        this.editGroupClassBooking = this.editGroupClassBooking.bind(this);
        this.closeBookingDialog = this.closeBookingDialog.bind(this);
        this.deleteClient = this.deleteClient.bind(this);
        this.state = {
            showBookingDialog: false,
            bookedClientId: null,
        };
    }

    componentWillMount() {
        this.props.groupClassSchedulerBookingList({
            'group_class_id': this.props.match.params.group_class_id
        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    deleteClient(booked_client_id){
        let that = this;
        Swal.fire({
            title: "Would you like to delete this client from the group?",
            text: "All client bookings  will also be deleted!",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            that.props.deleteGroupClassClient({
                'group_class_id': that.props.match.params.group_class_id,
                'client_id':booked_client_id
            });
        }, ()=> { });
    }

    addGroupClassBooking(){
        this.setState({
            showBookingDialog: true,
            bookedClientId: null,
        });
    }

    editGroupClassBooking(bookedClient) {             
        this.setState({
            showBookingDialog: true,
            bookedClientId: bookedClient.id,
        });
    }

    closeBookingDialog() {
        this.setState({
            showBookingDialog: false,
            bookedClientId: null,
        });
        this.props.groupClassSchedulerBookingList({
            'group_class_id': this.props.match.params.group_class_id
        });
    }

    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,   
        groupClass: state.calendar.groupClassReducer.group_class,
        bookedClients: state.calendar.groupClassReducer.booked_client_list,     
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        groupClassSchedulerBookingList,
        deleteGroupClassClient,
        cleanUp: groupClassActionCreator.cleanModule
    }, dispatch
    );
}


const GroupClassBookingConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassBooking);
export default GroupClassBookingConnected;