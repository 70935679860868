import  React  from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/search_preferences.rt";
import { formValueSelector, reduxForm } from 'redux-form';

import { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../redux/user/actions/user_profile';
import Utils from '../../utils/utils';
import {getExerciseFilters,saveSearchPreferences} from '../../redux/exercise/network/exercise';
import {getProgramFilters} from '../../redux/program/network/program_list';
import {getClientSearchFilterPreference} from '../../redux/user/network/user_profile';


class SearchPreference extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.state = {
            client_search_preferences : [
                {
                    label : 'My Clients (both active & idle)',
                    value:'my_all'
                },
                {
                    label : 'My Active Clients',
                    value:'active'
                },
                {
                    label : 'All Practice Clients',
                    value:'all'
                }                                  
            ]
        };
    }

    componentDidMount() {
        this.props.getExerciseFilters({first:true});
        this.props.getProgramFilters({first:true});
        this.props.getClientSearchFilterPreference({});
    }


    doSave(data) {
        let exercise_params = {};
        exercise_params.is_favorite = data.exercise_is_favorite;
        if(_.includes(data.exercise_sources, "my_exercises")){
            exercise_params.my_exercises = true;
        }else{
            exercise_params.my_exercises = false;
        }
        if(_.includes(data.exercise_sources, "my_practice_exercise")){
            exercise_params.my_practice_exercise = true;
        }else{
            exercise_params.my_practice_exercise = false;
        }
        let partners  = _.filter(data.exercise_sources,(item)=>{
            if(item === "my_exercises" || item === "my_practice_exercise"){
                return false;
            }else{
                return true;
            }
        });
        partners = _.map(partners,(item)=>{
               return parseInt(item); 
        });    
        if(partners.length > 0){
            exercise_params.partners = partners;
        }        

        let template_params = {};
        template_params.is_favorite = data.program_is_favorite;

        if(_.includes(data.program_sources, "my_programs")){
            template_params.my_programs = true;
        }else{
            template_params.my_programs = false;
        }
        
        if(_.includes(data.program_sources, "my_practice_programs")){
            template_params.my_practice_programs = true;
        }else{
            template_params.my_practice_programs = false;
        }

        let program_partners = _.filter(data.program_sources,(item)=>{
            if(item === "my_programs" || item === "my_practice_programs"){
                return false;
            }else{
                return true;
            }
        });
        program_partners = _.map(program_partners,(item)=>{
            return parseInt(item); 
        });    
        if(program_partners.length > 0){
            template_params.partners = program_partners;
        }        

        let params = {};
        params['exercise_search_preferences'] = exercise_params;
        params['program_search_preferences'] = template_params;
        params['client_search_preference'] = data["client_search_preference"];
        this.props.saveSearchPreferences(params);

    }

    doCancel() {
        history.goBack();
    }


    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

const selector = formValueSelector('SearchPreferenceForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getExerciseFilters,
        getProgramFilters,
        getClientSearchFilterPreference,
        saveSearchPreferences,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let exerciseSources = [];
    let exerciseSearchData = state.exercise.exerciseList.filterData; 
    if(!_.isEmpty(exerciseSearchData)){
        exerciseSources.push({label:"My Exercises",value:"my_exercises"});
        exerciseSources.push({label:"My Practice Exercises",value:"my_practice_exercise"});
        _.forEach(exerciseSearchData.partners,(item)=>{
            exerciseSources.push({label:item.name,value:item.id.toString()});
        });
    }
    let programSources = [];
    let programSearchData = state.exercise.exerciseList.filterData;
    if(!_.isEmpty(programSearchData)){
        programSources.push({label:"My Templates",value:"my_programs"});
        programSources.push({label:"My Practice Templates",value:"my_practice_programs"});
        _.forEach(exerciseSearchData.partners,(item)=>{
            programSources.push({label:item.name,value:item.id.toString()});
        });
    }


    let exerciseSearchPreferences = state.exercise.exerciseList.searchPreferences;
    let programSearchPreferences = state.program.programList.searchPreferences;
    let initialValues = {
        exercise_is_favorite:_.has(exerciseSearchPreferences,"is_favorite")  ? exerciseSearchPreferences.is_favorite : false,
        exercise_sources:[],
        program_is_favorite:_.has(programSearchPreferences,"is_favorite")  ? programSearchPreferences.is_favorite : false,
        program_sources:[],
    };
    if(_.has(exerciseSearchPreferences,"partners")){
        _.forEach(exerciseSearchPreferences.partners,(item)=>{
            initialValues.exercise_sources.push(item.toString());
        });
    }
    if(_.has(exerciseSearchPreferences,"my_exercises") && exerciseSearchPreferences.my_exercises){
        initialValues.exercise_sources.push("my_exercises");
    }
    if(_.has(exerciseSearchPreferences,"my_practice_exercise") && exerciseSearchPreferences.my_practice_exercise){
        initialValues.exercise_sources.push("my_practice_exercise");
    }    

    if(_.has(programSearchPreferences,"partners")){
        _.forEach(programSearchPreferences.partners,(item)=>{
            initialValues.program_sources.push(item.toString());
        });
    }
    if(_.has(programSearchPreferences,"my_programs") && programSearchPreferences.my_programs){
        initialValues.program_sources.push("my_programs");
    }
    if(_.has(programSearchPreferences,"my_practice_programs") && programSearchPreferences.my_practice_programs){
        initialValues.program_sources.push("my_practice_programs");
    } 
    
    initialValues["client_search_preference"] = state.user.userProfile.client_search_preference;

    return {
        initialValues:initialValues,
        exerciseSources:exerciseSources,
        exercise_sources: selector(state, 'exercise_sources'),
        programSources: programSources,
        program_sources: selector(state, 'program_sources'),
    };
}



const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const SearchPreferenceForm = reduxForm(
    {
        form: 'SearchPreferenceForm',
        enableReinitialize: true,
        validate
    }
)(SearchPreference);

const SearchPreferenceConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPreferenceForm);

export default SearchPreferenceConnected;
