
import React from 'react';
import { Tab, Tabs } from 'react-toolbox';
import TabContent from './homeFit_tab_content';
import { connect } from 'react-redux';

class TabsOptions extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.setContentTab = this.setContentTab.bind(this)

    this.state = {
      index: 0,
      fixedIndex: 0,
      inverseIndex: 1,
      selectedIndex: 0,
    };
  }

  componentDidMount() {
    this.setContentTab()
  }

  handleChange(value) {
    const { history } = this.props.props;
    let search = '';
    if (value === 0) {
      search = '?type=movement';
      this.setState({
        fixedIndex: 0,
      });
    } else if (value === 1) {
      search = '?type=fuel';
      this.setState({
        fixedIndex: 1,
      });
    } else if (value === 2) {
      search = '?type=sleep';
      this.setState({
        fixedIndex: 2,
      });
    }
    else if (value === 3) {
      search = '?type=resilience';
      this.setState({
        fixedIndex: 3,
      });
    }
    else if (value === 4) {
      search = '?type=enhancers';
      this.setState({
        fixedIndex: 4,
      });
    }
    else if (value === 5) {
      search = '?type=community';
      this.setState({
        fixedIndex: 5,
      });
    }
    history.push({
      // pathname: this.props.props.location.path,
      // search: search
    });
  }

  setContentTab() {
    const {
      location: { search },
    } = this.props.props;

    if (search.includes('?type=movement')) {
      this.setState({
        fixedIndex: 0,
      });
    }
    else if (search.includes('?type=fuel')) {
      this.setState({
        fixedIndex: 1,
      });
    }
    else if (search.includes('?type=sleep')) {
      this.setState({
        fixedIndex: 2,
      });
    }
    else if (search.includes('?type=resilience')) {
      this.setState({
        fixedIndex: 3,
      });
    }
    else if (search.includes('?type=enhancers')) {
      this.setState({
        fixedIndex: 4,
      });
    }
    else if (search.includes('?type=community')) {
      this.setState({
        fixedIndex: 5,
      });
    }

  }

  render() {
    return (
      <Tabs class="tabs_title" index={this.state.fixedIndex} onChange={this.handleChange} fixed>
        <Tab activeClassName="active-tab" label='Movement'><TabContent content="homefit" content_type="movement" category="9" /></Tab>
        <Tab activeClassName="active-tab" label='Fuel'><TabContent content="homefit" content_type="fuel" category="10" /></Tab>
        <Tab activeClassName="active-tab" label='Sleep'><TabContent content="homefit" content_type="sleep" category="11" /> </Tab>
        <Tab activeClassName="active-tab" label='Resilience'><TabContent content="homefit" content_type="resilience" category="12" /></Tab>
        <Tab activeClassName="active-tab" label='Enhancers'><TabContent content="homefit" content_type="enhancers" category="13" /></Tab>
        <Tab activeClassName="active-tab" label='Community'><TabContent content="homefit" content_type="community" category="14" /> </Tab>
      </Tabs>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  }
}


const TabsOptionsConnected = connect(
  mapStateToProps,
)(TabsOptions);

export default TabsOptionsConnected;
