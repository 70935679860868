import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import GroupCreator from '../actions/group_creator';
import {engagementPaymentPost} from '../../engagement/network/engagement_network';

const getGroupList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_list";
        return Axios.post(url, params).then((response) => {
            dispatch(GroupCreator.groupListFill(
                response.group_list,
                response.paginate_info,
                response.group_template_exists));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getGroupFilterList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_filter_list";
        return Axios.post(url, params).then((response) => {
            dispatch(GroupCreator.groupFilterListFill(
                response.group_selection_list,
                response.consultant_list));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getGroupAvailableClientList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_available_client_list";
        return Axios.post(url, params).then((response) => {
            dispatch(GroupCreator.groupAvailableClientsFill(response.clients));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveGroup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Group has been successfully saved");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const groupPaymentPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_payment_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(GroupCreator.groupGetFill(
                response.group,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getGroup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_get";
        return Axios.post(url, params).then((response) => {
            dispatch(GroupCreator.groupGetFill(
                response.group,
            ));
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const toggleVisibilityGroup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_toggle_visibility";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updateGroupTrackingDay = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_update_tracking_day";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Client weekly check-in day for this group has been updated");
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const publishGroup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_publish";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Group has been successfully published.");
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const groupDocumentPublishStatusToggle = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_document_publish_status_toggle";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getGroupList,
    getGroupFilterList,
    saveGroup,
    getGroup,
    toggleVisibilityGroup,
    updateGroupTrackingDay,
    publishGroup,
    groupPaymentPost,
    getGroupAvailableClientList,
    groupDocumentPublishStatusToggle
};