let initialState = {
    recommend_gomotive: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "RECOMMEND_GOMOTIVE_FILL":
            if (!action.recommend_gomotive){
                return state;
            }    
            return Object.assign({}, state, {
                recommend_gomotive: action.recommend_gomotive
            });
        case "RECOMMEND_GOMOTIVE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
