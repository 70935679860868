// Action Creators
const fillNews = (news, supportingData) => ({
    type: 'NEWS_EDIT_NEWS_FILL',
    news,
    supportingData,
});
const cleanModule = () => ({
    type: 'NEWS_EDIT_CLEANUP',
});

export default {
    fillNews,
    cleanModule,
};