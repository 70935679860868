import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import Switch from '../../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import { editorConfig } from '../../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
export default function () {
    function repeatVideo1(video, videoIndex) {
        return React.createElement(Card, {
            'className': 'width-250 bg-white p-0 m-t-10 m-l-5 m-r-5',
            'key': 'video' + videoIndex
        }, React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '40px' }
        }, React.createElement('div', { 'className': 'col-xs-12 f-16' }, React.createElement(TooltipWrapper, { 'tooltip': video.title }, '\n                    ', video.title, '\n                '))), React.createElement('div', { 'className': 'box-250-150 m-t-5 m-b-5' }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(video.thumbnail) + ')' }
        })), React.createElement('div', { 'className': 'row center-xs m-5' }, React.createElement('div', { 'className': 'col-xs-12 p-b-2 m-0' }, React.createElement(IconButton, {
            'icon': 'create',
            'tooltip': 'Edit',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.editWrapperVideo(video.id);
            }
        }), video.can_be_deleted ? React.createElement(IconButton, {
            'icon': 'delete_forever',
            'tooltip': 'Delete',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.deleteWrapperVideo(video.id);
            },
            'key': '2743'
        }) : null)));
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        this.props.wrapper_videos.length === 0 ? React.createElement('div', {
            'className': 'alert-warning w-100 m-t-15 p-10 text-center',
            'key': '1575'
        }, '\n        Click \'+\' button to create a new introductory video to accompany workouts.\n    ') : null,
        _.map(this.props.wrapper_videos, repeatVideo1.bind(this)),
        React.createElement(Button, {
            'tooltip': 'Create wrapper video',
            'className': 'floating-add-button m-r-8',
            'onClick': this.addWrapperVideo,
            'icon': 'add',
            'floating': true,
            'ripple': true
        })
    ]);
}