
const timezoneListFill = (timezones) => ({
    type: 'AUTH_TIMEZONE_LIST',
    'timezones': timezones
});

// Action Creators
const authSuccess = (response) => ({ type: 'AUTH_LOGIN_SUCCESS', response });
const authFailure = (error) => ({ type: 'AUTH_FAILURE', error });
const updateAuthUser = (user) => ({ type: 'AUTH_UPDATE_USER', user });
const generateWorkfitPin = (pin) => ({ type: 'AUTH_WORKFIT_GENERATE_PIN', pin });
const updateClientTitle = (clientTitle) => ({
    type: 'AUTH_UPDATE_CLIENT_TITLE',
    clientTitle
});
const getOnboardSearchData = (data) => ({
    type: 'GET_SEARCH_ONBOARD_DATA',
    data
});
const getOnboardData = (data) => ({
    type: 'GET_ONBOARD_DATA',
    data
});
const storeLMSData = (data) => ({
    type: 'STORE_LMS_DATA',
    data
});
const doLogout = () => ({ type: 'AUTH_USER_LOGOUT' });

let actionCreators = {
    timezoneListFill: timezoneListFill,
    authSuccess: authSuccess,
    authFailure: authFailure,
    updateAuthUser: updateAuthUser,
    generateWorkfitPin: generateWorkfitPin,
    doLogout: doLogout,
    updateClientTitle: updateClientTitle,
    getOnboardData: getOnboardData,
    getOnboardSearchData: getOnboardSearchData,
    storeLMSData: storeLMSData
};

export default actionCreators;