import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/webinar_edit.rt";
import Utils from '../../../utils/utils';
import moment from 'moment';
import { history} from '../../../redux/store';
import { reduxForm } from 'redux-form';
import { webinarEdit,getWebinar } from '../../../redux/dhf/move/network/webinar_network';
import WebinarAction from '../../../redux/dhf/move/actions/webinar_actions';
import { uploadFiles } from '../../../redux/core/network/file_upload';

class WebinarEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);   
        this.doCancel = this.doCancel.bind(this);     
        this.saveWebinar = this.saveWebinar.bind(this); 
        this.holdImage = this.holdImage.bind(this);  
        this.deleteholdImage = this.deleteholdImage.bind(this);   
        
        this.state = {
            holdImage:{}
        };
    };
    
    componentDidMount(){      
        this.props.getWebinar(this.props.match.params.webinar_id);
    }   
    
    deleteholdImage(){

        this.setState({
            holdImage:{}
        });
    }
    saveWebinar(data) {
        let params = {...data};
        let time = moment(params.publish_time);
        
        params.publish_datetime = moment(params.publish_date).hour(time.hour()).minute(time.minute()).seconds(0).toDate();
        params.duration = moment.utc().startOf('day').add(params.duration, 'minutes').format('HH:mm');

        delete params.publish_date;   
        delete params.publish_time;   
        // editing image shouldnt be compulsary
        if(Object.keys(this.state.holdImage).length){
            this.uploadImage(this.state.holdImage).then((res)=>{
                if(res.thumbnail_url){
                    params.thumbnail_url = res.thumbnail_url;
                    this.props.webinarEdit(params).then((response) => {
                        if(response.status === "200"){
                            history.push("/practice/" + this.props.selectedRole.practice.id + "/move/webinar");
                        }
                    }).catch(() => {
                    
                    });
                }else{
                    Utils.showErrorAlert("Upload error");
                }
            }); 
        }else{
            params.thumbnail_url = this.props.webinar.thumbnail_url;
            this.props.webinarEdit(params).then((response) => {
                if(response.status === "200"){
                    history.push("/practice/" + this.props.selectedRole.practice.id + "/move/webinar");
                }
            }).catch((error) => {
            
            });
        }
    }

    holdImage(files){

        this.setState({
            holdImage:files
        });

    }

    uploadImage(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        return this.props.uploadFiles(formData).then((resp)=>{
            return resp.upload_results[0];
        }).catch(()=>{ });
    }
    

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/move/webinar");
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    let initialValues = {};
    if(!_.isEmpty(state.move.webinarReducer.webinar)){
        initialValues  = {
            webinar_id: state.move.webinarReducer.webinar.id,
            title: state.move.webinarReducer.webinar.title,
            shopify_link:state.move.webinarReducer.webinar.shopify_link,
            duration: moment.duration(state.move.webinarReducer.webinar.duration,'HH:mm').asMinutes(),
            publish_date: Utils.parseServerDate(state.move.webinarReducer.webinar.publish_datetime),
            publish_time: Utils.parseServerDate(state.move.webinarReducer.webinar.publish_datetime),
            thumbnail_url: state.move.webinarReducer.webinar.thumbnail_url
        };
    }

    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        webinar: state.move.webinarReducer.webinar,
        initialValues: initialValues
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            webinarEdit,   
            uploadFiles,    
            cleanUp: WebinarAction.cleanModule,
            getWebinar
        },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'title',
        'duration',
        'publish_date',
        'publish_time',
        'shopify_link',
    ];
    let regex = "((http|https)://)[a-zA-Z0-9@:%._\\+~#?&//=-]{2,256}\.[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if(values['shopify_link']){
        if(!values['shopify_link'].match(regex)){
            errors['shopify_link'] = 'Enter a valid url'; 
        }
    }
    // if(this.state.holdImage === {}){
    //     errors['thumbnail_url'] = 'Required';
    // }
    return errors;

};

const WebinarEditFormContainer = reduxForm({
    form: 'WebinarEditForm', 
    enableReinitialize: true, 
    validate
})(WebinarEdit);


const WebinarEditForm = connect(mapStateToProps,mapDispatchToProps)(WebinarEditFormContainer);
export default WebinarEditForm;