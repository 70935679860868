import React from 'react';
import createReactClass from 'create-react-class';
import { sortable,SortableItemMixin,SortableContainer }  from 'react-anything-sortable';
import 'react-anything-sortable/sortable.css';

let SortableItem = createReactClass({
  mixins: [SortableItemMixin],

  render() {
    const { children } = this.props;
    return (
      <SortableContainer {...this.props}>
        <div  >
            {children}
        </div>
      </SortableContainer>
    );
  }  
});


export default SortableItem;