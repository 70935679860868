import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/chat_list.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { bindActionCreators } from 'redux';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import ConversationAction from '../../redux/conversation/actions/conversation_creator.js';
import {getChatList,postChat} from '../../redux/conversation/network/conversation';
import authReducer from '../../redux/auth/reducers/auth_reducer';
import {showProgress} from '../../redux/core/actions/progress_creator';



class Chats extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.state = { message: ""};
        this.onChangeMessage = this.onChangeMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this);
        this.state = {
            displayTitle: false,
            conversation_id: null
        };
    }

    componentWillMount() {
         if(!this.props.conversation_id && !this.props.match.params.conversation_id){
            history.goBack();
        }
        let conversation_id = this.props.conversation_id;
        if(conversation_id === undefined) {
            conversation_id = this.props.match.params.conversation_id;
            this.setState({
                displayTitle: true
            });
        }
        this.setState({
            conversation_id: conversation_id
        });
        this.props.getChatList({
            conversation_id:conversation_id,
            ...this.props.paginate_info
        }).then((response)=>{
            setTimeout(()=>{this.scrollToBottom();},150);   
        }).catch((error)=>{

        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    loadMoreData() {
        store.dispatch(showProgress());
        let paginate_info = {... this.props.paginate_info };
        paginate_info.page = paginate_info.page + 1;
        this.props.getChatList({
            conversation_id:this.state.conversation_id,
            ...paginate_info
        });
    }



    onChangeMessage(data) {
        this.setState({message:data});
    }
    sendMessage() {
        if (this.state.message === ""){
            return;
        }
        this.props.postChat({ 
            message: this.state.message, 
            'conversation_id': this.state.conversation_id 
        }).then(() => {
            this.setState({ message: "" });
            this.scrollToBottom();
        }).catch(() => {
            
        });

    }

    scrollToBottom() {
        if (this.messageList) {
            const scrollHeight = this.messageList.scrollHeight;
            const height = this.messageList.clientHeight;
            const maxScrollTop = scrollHeight - height;
            this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;      
            //this.messageList.scrollTop = scrollHeight;          
        }

    }   



    render() {
        if (_.isEmpty(this.props.conversation)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }        
        return Template.apply(this);
    }
}
function mapStateToProps(state) {
    return {
        paginate_info: state.conversation.conversationReducer.paginate_info,
        conversation: state.conversation.conversationReducer.conversation,
        chats: state.conversation.conversationReducer.chats,
        user: state.auth.authReducer.user,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getChatList,
        postChat,
        cleanUp: ConversationAction.chatListClean,
    }, dispatch),
    dispatch
});

const ChatsConnected = connect(mapStateToProps,mapDispatchToProps,)(Chats);
export default ChatsConnected;
