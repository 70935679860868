import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/all_group_class_view_calendar";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { allGroupClassEventList } from '../../redux/calendar/network/group_class_network';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';
import { Calendar as BigCalendar,momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment)
class AllGroupClassCalendar extends BaseComponent {

    constructor(props, context) {
        super(props, context);        
    }

    componentWillMount() {
                // BigCalendar.setLocalizer(
        //     BigCalendar.momentLocalizer(moment)
        // );
        this.loadCalendarEvents();
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    loadCalendarEvents() {
        this.props.allGroupClassEventList({
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id   
        })
        .then((response) => {  
        })
        .catch((error) => { 
        });

    }


    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownProps) {
    let new_event_list = _.map([...state.calendar.groupClassReducer.all_group_class_calendar], function (itemData) {
        let item = { ...itemData };
        item.event_type =  "calendar-group-class";    
        item.title = item.display_name;
        item.start = moment(item.start_time).toDate();
        item.end = moment(item.end_time).toDate();
        item.allDay = item.all_day;
        return item;
    });
    return {
        localizer,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        eventList: new_event_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        allGroupClassEventList,
        cleanUp: groupClassActionCreator.cleanModule
    }, dispatch
    );
}

const AllGroupClassCalendarConnected = connect(mapStateToProps, mapDispatchToProps)(AllGroupClassCalendar);
export default AllGroupClassCalendarConnected;