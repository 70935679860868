import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_engagement_progression_dates.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import clientEngagementProgressionDatesAction from '../../redux/engagement/actions/client_engagement_progression_dates_creator.js';
import {  getClientProgressionDatesList,setEngagementWorkoutTimings } from '../../redux/engagement/network/client_engagement_network';
import {
    listGuidanceDocuments, listNutritionDocuments
} from '../../redux/engagement/network/health_document';
import moment from 'moment';
import roleReducer from '../../redux/auth/reducers/role_reducer';
import healthDocument from '../../redux/documents/actions/health_document';

class ClientEngagementProgressionDates extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        let todayString = moment().format("YYYY-MM-DD");
        let selectedDate = moment().subtract(1, "days").toDate();
        this.changeSelectedDate = this.changeSelectedDate.bind(this);
        this.showPreview = this.showPreview.bind(this);
        this.hideWorkoutTimingModal = this.hideWorkoutTimingModal.bind(this);
        this.showWorkoutTimingModal = this.showWorkoutTimingModal.bind(this); 
        this.changeWorkoutTimings = this.changeWorkoutTimings.bind(this); 
        this.saveWorkoutTimings = this.saveWorkoutTimings.bind(this); 
        this.state = {
            openWorkoutTimingModal: false,
            workout_timings : undefined,
            today_string: todayString,selectedDate:selectedDate, habits:[] 
        };
    };
    
    componentWillMount() {
        if (!_.isEmpty(this.props.engagement)) {
            this.loadWorkouts();
            if (this.props.selectedRole.role.name === "client"){
                this.props.listGuidanceDocuments({ engagement_id: this.props.engagement.id });
                this.props.listNutritionDocuments({ engagement_id: this.props.engagement.id });
            }
        }  
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.engagement)) {
               if(this.props.engagement.selected_workout_time){
                    this.setState({workout_timings:moment(this.props.engagement.selected_workout_time,'HH:mm:ss').toDate()});
               } 
        }    
    }

    loadWorkouts() {
          this.props.getClientProgressionDatesList({ id: this.props.engagement.id,start_date: moment(this.state.selectedDate).format("YYYY-MM-DD") });
    }

    hideWorkoutTimingModal() {
        this.setState({ openWorkoutTimingModal: false });
    }   

    showWorkoutTimingModal() {
        this.setState({ openWorkoutTimingModal: true });
    } 

    changeWorkoutTimings(datetime){
        this.setState({ workout_timings: datetime });
    }

    saveWorkoutTimings(){
        if(!this.state.workout_timings){
            Utils.showWarningAlert("Please enter your preferrred workout timings");
            return;
        }
        this.props.setEngagementWorkoutTimings({
            engagement_id: this.props.engagement.id,
            workout_timings: moment(this.state.workout_timings).second(0).format("HH:mm:ss")
        }).then((response)=>{
            this.setState({ openWorkoutTimingModal: false });
        }).catch((error)=>{

        });
    }


    componentWillUnmount() {
        this.props.cleanUp({});
    }

    changeSelectedDate(value) {
        this.setState({selectedDate:value},()=>{ this.loadWorkouts();});
    }

    showPreview(date) {
        if (this.props.selectedRole.role.name === "client"){
           history.push("/client/engagement/" + this.props.engagement.id+ "/workout/preview/"+date);
        }
    }


    render() {
        if (_.isEmpty(this.props.engagement)){
            return (<div></div>);
        }
        return Template.apply(this);
    }

}

function mapStateToProps(state, ownprops) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        engagement: ownprops.engagement,
        all_dates: state.engagement.clientEngagementProgressionDatesReducer.all_dates,
        date_workouts: state.engagement.clientEngagementProgressionDatesReducer.date_workouts,
        ongoing_workouts: state.engagement.clientEngagementProgressionDatesReducer.ongoing_workouts,
        date_habits: state.engagement.clientEngagementProgressionDatesReducer.date_habits,
        nutritionDocuments: state.engagement.healthDocument.nutritionDocuments,
        guidanceDocuments: state.engagement.healthDocument.guidanceDocuments,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getClientProgressionDatesList,
            listGuidanceDocuments,
            listNutritionDocuments,
            setEngagementWorkoutTimings,
            cleanUp: clientEngagementProgressionDatesAction.fillProgressionDatesList
        }, 
        dispatch
    );
}

const ClientEngagementProgressionDatesConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagementProgressionDates);
export default ClientEngagementProgressionDatesConnected;
