import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import Dialog from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import Chip from 'react-toolbox/lib/chip';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer';
import ToolTipWrapper from '../../../utils/common_components/tooltipWrapper';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import GoalSelection from '../../../guest_app/components/client_goal_selection';
export default function () {
    function repeatPractitioner1(practitioner, practitionerIndex) {
        return [practitioner.user.user_state === 3 ? React.createElement('div', {
                'className': 'panel bg-beige col-sm-8 col-md-5 d-flex',
                'key': '24871'
            }, React.createElement('div', { 'className': 'width-150' }, React.createElement(ProfilePicture, { 'user': practitioner.user })), React.createElement('div', { 'className': 'flex-auto p-10 p-relative text-left' }, React.createElement('div', { 'className': 'f-16' }, '\n                                            ', practitioner.user.name, '\n                                        '), React.createElement('div', { 'className': 'f-12 m-b-3' }, '\n                                            ', practitioner.user.email, '\n                                        '), React.createElement('div', { 'className': 'f-12 m-b-3' }, '\n                                            ', practitioner.user.mobile_number, '\n                                        '))) : null];
    }
    function repeatMedia2(media, index) {
        return React.createElement('div', {
            'className': 'width-300 panel p-0 m-t-10 m-l-5 m-r-5',
            'key': 'practice_media' + index
        }, media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225 pointer',
            'style': { backgroundImage: 'url(' + encodeURI(media.thumbnail_url) + ')' },
            'key': '4285'
        }) : null, media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225 pointer',
            'key': '4467'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'practice_media_wistia_player' + media.guid,
            'videoList': [media.guid]
        })) : null);
    }
    function repeatService3(service, serviceIndex) {
        return React.createElement(Chip, {
            'className': 'pointer',
            'onClick': () => {
                this.showDialog(service.title, service.description);
            },
            'key': service.id
        }, '\n                    ', service.title, '\n                ');
    }
    function repeatCondition4(condition, conditionIndex) {
        return React.createElement(Chip, {
            'className': 'pointer',
            'onClick': () => {
                this.showDialog(condition.title, condition.description);
            },
            'key': condition.id
        }, '\n                    ', condition.title, '\n                ');
    }
    return React.createElement('div', { 'className': 'row center-xs m-20 text-left' }, React.createElement('div', { 'className': 'panel col-md-9 col-sm-10 p-0' }, React.createElement('div', {
        'className': 'panel-heading  row p-15 m-0 c-white',
        'style': { background: ` ${ this.getBackgroundColor(this.props.practice.name) }` }
    }, React.createElement(ProfilePicture, {
        'user': {
            avatar_url: this.props.practice.logo,
            name: this.props.practice.name
        },
        'profile_type': 'circle',
        'size': 80
    }), React.createElement('div', { 'className': 'flex-auto text-left m-l-10' }, React.createElement('h4', {}, this.props.practice.name), this.props.practice.main_center ? React.createElement('h6', { 'key': '1435' }, ' \n                    ', this.props.practice.main_center.address ? this.props.practice.main_center.address : ' ', ' \n                    ', this.props.practice.main_center.city ? this.props.practice.main_center.city : ' ', ' \n                    ', this.props.practice.main_center.zipcode ? this.props.practice.main_center.zipcode : ' ', '\n                ') : null, this.props.practice.main_center && this.props.practice.main_center.phone ? React.createElement('h6', { 'key': '1850' }, '\n                    ', this.props.practice.main_center.phone, '\n                ') : null), React.createElement(Button, {
        'label': 'CONNECT',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'button',
        'raised': true,
        'onClick': () => {
            this.doConnect();
        }
    })), React.createElement('div', { 'className': 'panel-body row' }, React.createElement('h5', {}, 'Practitioners'), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement.apply(this, [
        'div',
        { 'className': 'row around-xs' },
        _.map(this.props.practice.active_practitioners, repeatPractitioner1.bind(this))
    ])), this.props.practice.description ? React.createElement('div', { 'key': '3757' }, React.createElement('h5', {}, 'Description'), React.createElement(RichTextViewer, {
        'className': 'col-xs-12 m-t-10 m-b-10',
        'value': this.props.practice.description
    })) : null, this.props.description.media_items && this.props.description.media_items.length > 0 ? React.createElement.apply(this, [
        'div',
        {
            'className': 'col-xs-12 d-flex',
            'key': '3981'
        },
        _.map(this.props.description.media_items, repeatMedia2.bind(this))
    ]) : null, this.props.practice.services.length > 0 ? React.createElement.apply(this, [
        'div',
        {
            'className': 'col-xs-6',
            'key': '4760'
        },
        React.createElement('h5', { 'className': 'm-b-10' }, 'Services Offered'),
        _.map(this.props.practice.services, repeatService3.bind(this))
    ]) : null, this.props.practice.conditions.length > 0 ? React.createElement.apply(this, [
        'div',
        {
            'className': 'col-xs-6',
            'key': '5179'
        },
        React.createElement('h5', { 'className': 'm-b-10' }, 'Conditions and Goals Treated'),
        _.map(this.props.practice.conditions, repeatCondition4.bind(this))
    ]) : null)), React.createElement(Dialog, {
        'active': this.state.show_dialog,
        'title': this.state.dialog_title,
        'onEscKeyDown': this.hideDialog,
        'onOverlayClick': this.hideDialog
    }, this.state.show_dialog ? React.createElement(RichTextViewer, {
        'className': 'w-100',
        'value': this.state.dialog_body,
        'key': '5798'
    }) : null), React.createElement(Dialog, {
        'active': this.state.show_goal_selection,
        'onEscKeyDown': this.hideGoalSelectionDialog,
        'onOverlayClick': this.hideGoalSelectionDialog
    }, this.state.selected_practitioner_id ? React.createElement(GoalSelection, {
        'form': 'goalSelection',
        'className': 'w-100',
        'handleClose': this.hideGoalSelectionDialog,
        'practice_id': this.props.match.params.practice_id,
        'practitioner_id': this.state.selected_practitioner_id,
        'key': '6067'
    }) : null));
}