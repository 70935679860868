let initialState = {
    admin_list: [],
    subscription_profile: {}
    // coach: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "ADMIN_LIST_FILL":
            if (!action.admin_list){
                return state;
            }
            return Object.assign({}, state, {
                admin_list: action.admin_list,
            });
        case "SUBSCRIPTION_PROFILE_FILL":
            if (!action.subscription_profile){
                return state;
            }
            return Object.assign({}, state, {
                subscription_profile: action.subscription_profile,
            });

        default:
            return state;
    }
};