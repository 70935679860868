import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/client_engagement.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import EnagagementAction from '../../redux/engagement/actions/engagement_creator';
import IntakeAction from '../../redux/intake/actions/intake_creator';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';
import {getClientEngagementInstance} from '../../redux/engagement/network/client_engagement_network';
import {postClientWelcomeVideoWatched} from '../../redux/client/network/client_network';

class ClientEngagement extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.hideGoalModal = this.hideGoalModal.bind(this);
        this.showGoalModal = this.showGoalModal.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.hidePreviewIntakeModal = this.hidePreviewIntakeModal.bind(this);
        this.showPreviewIntakeModal = this.showPreviewIntakeModal.bind(this); 
        this.hideAppointmentModal = this.hideAppointmentModal.bind(this);
        this.showAppointmentModal = this.showAppointmentModal.bind(this);   
        this.hideChatModal = this.hideChatModal.bind(this);
        this.showChatModal = this.showChatModal.bind(this);    
        this.refreshData = this.refreshData.bind(this);
        this.viewAllEngagements = this.viewAllEngagements.bind(this);
        this.subscribeSuccessCallback = this.subscribeSuccessCallback.bind(this);
        this.closeWelcomeVideo = this.closeWelcomeVideo.bind(this);
        this.callBackAfterGoalSelectionSave = this.callBackAfterGoalSelectionSave.bind(this);
        this.viewAssessmentDetails = this.viewAssessmentDetails.bind(this);
        this.hideAssessmentModal = this.hideAssessmentModal.bind(this);
        this.state = {
            tabIndex:0,
            openGoalModal: false,
            selectedTrackingData:null,
            openPreviewIntakeModal: false,
            openAppointmentModal: false,
            openChatModal: false,
            previewIntake: {},
            previewAssessment: {},
            showTreatment: false,
            show_welcome_video: false,
            viewAssessmentModal: false,
        };
    };
    
    componentDidMount() {
        if (!this.props.match.params.engagement_id){
            history.goBack();
        }
        this.refreshData();
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    refreshData(){
        let that = this;
        this.props.getClientEngagementInstance({'id':this.props.match.params.engagement_id}).then((response)=>{
            if(response.data.goal_state === 1){
                let needs_goal_tracking_target_update = false;
                if(!_.isEmpty(response.data)){
                    if(response.data.goals.length  > 0 &&  response.data.goals[0].goal_tracking.length > 0 ){
                        _.forEach(response.data.goals[0].goal_tracking,(item)=>{
                            if(item.initial_value === null || item.target_value === null){
                                needs_goal_tracking_target_update = true;
                            }
                        });
                    }
                }
                let state = {tabIndex:0,show_welcome_video:false};
                let change_state = false;
                if(response.data.show_treatment === false){
                    state.tabIndex = 3; //Show Needs Payment
                    change_state = true;
                }
                if(response.data.show_welcome_video !== null){
                    state.show_welcome_video= true;
                    change_state = true;
                    that.props.postClientWelcomeVideoWatched({
                        'object_type':'engagement',
                        'id':that.props.match.params.engagement_id
                    });
                }else if(response.data.show_treatment && needs_goal_tracking_target_update === false && response.data.client_untracked_goal_dates && response.data.client_untracked_goal_dates.length > 0 ){
                    state.openGoalModal = true;
                    state.selectedTrackingData = response.data.client_untracked_goal_dates[0]; 
                    change_state = true;
                }
                if(change_state){
                    that.setState(state); 
                }
            }

        }).catch((error)=>{

        });
        return true;
    }   

    hidePreviewIntakeModal() {
        this.setState({ openPreviewIntakeModal: false, previewIntake: {} });
    }    

    showPreviewIntakeModal(intake) {
        this.setState({ previewIntake: intake }, () => {
             this.setState({ openPreviewIntakeModal: true});
        });
    }

    viewAssessmentDetails(assessment) {
        this.setState({ previewAssessment: assessment }, () => {
            this.setState({ viewAssessmentModal: true});
        });
    }

    hideAssessmentModal() {
        this.setState({ viewAssessmentModal: false, previewAssessment: {} });
    }

    subscribeSuccessCallback(){
        this.refreshData();
    }
    

    handleTabChange(tabIndex){
        this.setState({tabIndex});
    }

    hideAppointmentModal() {
        this.setState({ 
            openAppointmentModal: false
        });
    }

    showAppointmentModal() {
        this.setState({ 
            openAppointmentModal: true
        });
    }

    hideChatModal() {
        this.setState({
            openChatModal: false,
        });
    }

    showChatModal() {
        this.setState({
            openChatModal: true,
        });
    }   
 
    hideGoalModal() {
        this.setState({ openGoalModal: false,selectedTrackingData:null });
    }
    
    showGoalModal(tracking_date) {
        this.setState({ openGoalModal: true,selectedTrackingData:tracking_date });
    }

    viewAllEngagements() {
        history.push("/client/engagement/list");
    }

    callBackAfterGoalSelectionSave() {
        this.refreshData();  
    }
    closeWelcomeVideo() {
        this.refreshData();  
    }

    render() {
        if (_.isEmpty(this.props.engagement)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }else{            
            return Template.apply(this);            
        }            
       
    }

}

function mapStateToProps(state) {
    let engagement = state.engagement.clientEngagementReducer.engagement;
    let needs_goal_tracking_target_update = false;
    if(!_.isEmpty(engagement)){
        if(engagement.goals.length  > 0 &&  engagement.goals[0].goal_tracking.length > 0 ){
            _.forEach(engagement.goals[0].goal_tracking,(item)=>{
                if(item.initial_value === null || item.target_value === null){
                    needs_goal_tracking_target_update = true;
                    return false;
                }
            });
        }
    }

    return {
        user: state.auth.authReducer.user,
        engagement: engagement,
        intakes: [],
        needs_goal_tracking_target_update:needs_goal_tracking_target_update
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientEngagementInstance,
        showProgress,
        postClientWelcomeVideoWatched,
        cleanUp: EnagagementAction.engagementInstanceCleanup,
        intakeCleanUp: IntakeAction.intakeCleanUp,
        }, 
        dispatch
    );
}

const EngagementConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagement);
export default EngagementConnected;
