import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/notification_edit";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { uploadFiles } from '../../redux/core/network/file_upload';
import { getNotificationTemplateList, notificationScheduleCreate } from '../../redux/dhf/workfit/network/workfit_network';

class NotificationEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.cancel = this.cancel.bind(this);
        this.transformData = this.transformData.bind(this)
           
        this.scheduleNotificationTemplate = this.scheduleNotificationTemplate.bind(this);   
    };

    componentWillMount() {
        this.props.getNotificationTemplateList({
            package_version: "2"
        });
    }

    scheduleNotificationTemplate(data) {
        let params = {...data};
        params["scheduled_date"] = Utils.formatServerDate(params.scheduled_date);
        this.props.notificationScheduleCreate(params).then((response) => {
            Utils.showSuccessAlert("Notification has been scheduled");
            history.push("/practice/"+this.props.selectedRole.practice.id+"/marketing/notifications");
        }).catch(() => { });
    }
    

    cancel() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/notifications');
    }

    transformData(data) {
        let params = Utils.flatternFormData(data, ['position_pictures']);
        return params;
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        is_image_uploaded: selector(state, 'is_image_uploaded'),
        image_url: selector(state, 'image_url'),
        user_guid: selector(state, 'user_guid'),
        image_name: selector(state, 'image_name'),
        notification_templates: state.workfit.workfitReducer.notification_templates,

    };
}
const selector = formValueSelector('NotificationEditForm');
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        notificationScheduleCreate,
        getNotificationTemplateList
    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const NotificationEditForm = reduxForm(
    {
        form: 'NotificationEditForm',
        enableReinitialize: true,
        validate
    }
)(NotificationEdit);

const NotificationEditConnected = connect(mapStateToProps, mapDispatchToProps)(NotificationEditForm);
export default NotificationEditConnected;
