import { contains } from "jquery";

let initialState = {
    courseList: [],
    paginateInfo: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "INSPIRE_COURSES_LIST_FILL":
            return Object.assign({}, state, {
                courseList: action.courseList.data,
                paginateInfo: action.paginateInfo
            });
        case "COURSES_CLEANUP":
            return Object.assign({}, initialState);

        default:
            return state;
    }
};