import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Chip from 'react-toolbox/lib/chip';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import Dialog from 'react-toolbox/lib/dialog';
export default function () {
    function repeatAttachment1(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument2(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, {
            'column': 'attachments',
            'style': { width: '250px' }
        }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment1.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'author',
            'value': document.practice.name,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, document.practice.name)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, !this.props.selectionMode && document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'onClick': () => {
                this.editHealthDocument(document.id);
            },
            'icon': 'create',
            'className': 'micro',
            'floating': true,
            'primary': true,
            'mini': true,
            'key': '3546'
        }) : null, !this.props.selectionMode && document.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
            'className': 'm-l-5 bg-danger micro',
            'disabled': !document.can_be_deleted,
            'onClick': () => {
                this.deleteHealthDocument(document.id);
            },
            'icon': 'delete_forever',
            'floating': true,
            'accent': true,
            'mini': true,
            'key': '3875'
        }) : null, !this.props.selectionMode && document.practice.id !== this.props.selectedRole.practice.id ? React.createElement(Button, {
            'onClick': () => {
                this.editHealthDocument(document.id);
            },
            'icon': 'pageview',
            'className': 'micro bg-primary',
            'floating': true,
            'mini': true,
            'key': '4293'
        }) : null, this.props.selectionMode ? React.createElement(Button, {
            'className': 'm-l-5 bg-success',
            'onClick': () => {
                this.props.onSelected(document);
            },
            'icon': 'add',
            'floating': true,
            'accent': true,
            'mini': true,
            'key': '4663'
        }) : null)));
    }
    return React.createElement('div', {}, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Please add  ' + this.props.params.document_type + ' documents in the Nutrition Section of your Content Library.',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '350px' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, {
            'column': 'attachments',
            'style': { width: '250px' }
        }, React.createElement('strong', {}, 'Attachments')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Author ')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.health_documents, repeatDocument2.bind(this))
    ]));
}