import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/services_offered.rt";
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { getServicesOfferedList } from '../../../redux/admin/services_offered/network/services_offered_network';

class ServicesOffered extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.addServiceOffered = this.addServiceOffered.bind(this);
        this.editServiceOffered = this.editServiceOffered.bind(this);
    };
    
    componentWillMount() {
        this.props.getServicesOfferedList();
    }

    componentWillUnmount() {
    }

    addServiceOffered() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/branding/services_offered/add');
    }

    editServiceOffered(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/branding/services_offered/edit/'+id);
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        services_offered: state.admin.servicesOfferedReducer.services_offered_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getServicesOfferedList,
        }, 
        dispatch
    );
}

const ServicesOfferedConnected = connect(mapStateToProps,mapDispatchToProps)(ServicesOffered);
export default ServicesOfferedConnected;