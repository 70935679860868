import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import Button from '../../../utils/common_components/tooltipButton';
import { history } from '../../../redux/store';
import { Dialog } from 'react-toolbox/lib/dialog';
import MailComposer from '../../../utils/common_components/mail_composer';
import FontIcon from 'react-toolbox/lib/font_icon';
import { Link } from 'react-router-dom';
export default function () {
    return React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-md-12 text-center m-t-5' }, React.createElement('h3', {
        'className': 'di-block flex-auto',
        'style': { width: 'auto' }
    }, this.props.selectedRole.practice.name)), React.createElement('div', {
        'className': `col-md-3 col-sm-4 col-xs-5   full-height    ${ this.props.menuopen ? 'v-hide' : '' } `,
        'style': { borderRight: '1px solid rgba(255,255,255,0.4)' }
    }, React.createElement('div', { 'className': 'row vertical-align m-t-15' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 gm-alerts' }), React.createElement('h4', {
        'className': 'di-block flex-auto',
        'style': { width: 'auto' }
    }, ' Alerts ')), React.createElement(List, {
        'selectable': true,
        'ripple': true,
        'className': true
    }, React.createElement(ListItem, {
        'onClick': () => {
            history.push('/practice/' + this.props.selectedRole.practice.id + '/conversation');
        },
        'className': true,
        'caption': this.props.unread_chat_list.length + ' new conversation',
        'leftIcon': 'gm-chat',
        'gomotive': true
    }), React.createElement(ListItem, {
        'onClick': () => {
            history.push('/practice/' + this.props.selectedRole.practice.id + '/messages');
        },
        'className': true,
        'caption': this.props.unread_message_count + ' new messages',
        'leftIcon': 'gm-message',
        'gomotive': true
    })), React.createElement('div', { 'className': 'col-md-9 col-sm-8 col-xs-7' }, React.createElement('div', { 'className': 'row vertical-align m-t-15' }, React.createElement(FontIcon, {
        'className': 'm-r-10',
        'value': 'bookmark_border'
    }), React.createElement('h4', {
        'className': 'di-block flex-auto',
        'style': { width: 'auto' }
    }, ' Shortcuts ')), this.props.selectedRole.is_workfit_practice === true ? React.createElement(List, {
        'selectable': true,
        'ripple': true,
        'className': true,
        'key': '2149'
    }, React.createElement(ListItem, {
        'onClick': () => {
            this.showNotifyComposer();
        },
        'caption': 'Notify All Clients',
        'leftIcon': 'email'
    })) : null, this.props.selectedRole.is_workfit_practice === false ? React.createElement(List, {
        'selectable': true,
        'ripple': true,
        'className': true,
        'key': '2457'
    }, React.createElement(ListItem, {
        'onClick': () => {
            this.showGIExerciseSelection();
        },
        'caption': 'GI Exercises',
        'leftIcon': 'gm-exercises',
        'gomotive': true
    }), React.createElement(ListItem, {
        'onClick': () => {
            this.showEducation();
        },
        'caption': 'Education',
        'leftIcon': 'library_books'
    }), React.createElement(ListItem, {
        'onClick': () => {
            this.showMailComposer();
        },
        'caption': 'Email All Clients',
        'leftIcon': 'email'
    })) : null)), React.createElement('div', { 'className': 'col-md-9 col-sm-8 col-xs-7' }, React.createElement('div', { 'className': 'row start-xs m-t-20' }, React.createElement('div', { 'className': 'col-md-4 col-sm-4 col-xs-4' }, this.props.business_partner.app_type === 'dhf' ? React.createElement(Link, {
        'to': '/site_media/static/files/dhf_coachfit_training_manual.pdf',
        'target': '_blank',
        'className': 'c-success',
        'key': '3187'
    }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': '/site_media/static/images/dhf_usermanual.jpeg',
        'className': 'img-responsive'
    }))) : null, this.props.business_partner.app_type === 'gi' ? React.createElement(Link, {
        'to': '/site_media/static/files/gi_remote_trainer_training_manual.pdf',
        'target': '_blank',
        'className': 'c-success',
        'key': '3630'
    }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': '/site_media/static/images/gi_usermanual.png',
        'className': 'img-responsive',
        'style': {
            height: '180px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }))) : null, this.props.business_partner.app_type === 'tri' ? React.createElement(Link, {
        'to': '/site_media/static/files/dhf_coachfit_training_manual.pdf',
        'target': '_blank',
        'className': 'c-success',
        'key': '4123'
    }, React.createElement('div', { 'className': 'image-container pointer' }, React.createElement('img', {
        'src': '/site_media/static/images/dhf_usermanual.jpeg',
        'className': 'img-responsive',
        'style': {
            height: '180px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }))) : null), React.createElement('div', { 'className': 'col-md-4 col-sm-4 col-xs-4' }, React.createElement('div', {
        'className': 'image-container pointer',
        'onClick': () => {
            this.gotoClientList('');
        }
    }, this.props.business_partner.app_type === 'gi' ? React.createElement('img', {
        'src': '/site_media/static/images/gi_clients.jpeg',
        'className': 'img-responsive',
        'key': '4814'
    }) : null, this.props.business_partner.app_type === 'dhf' ? React.createElement('img', {
        'src': '/site_media/static/images/dhf_clients.jpeg',
        'className': 'img-responsive',
        'key': '4967'
    }) : null, this.props.business_partner.app_type === 'tri' ? React.createElement('img', {
        'src': '/site_media/static/images/tri_clients.jpeg',
        'className': 'img-responsive',
        'key': '5122'
    }) : null, React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '20px' }
    }, 'CLIENTS'))), React.createElement('div', { 'className': 'col-md-4 col-sm-4 col-xs-4' }, React.createElement('div', {
        'className': 'image-container pointer',
        'onClick': () => {
            this.inviteClient('');
        }
    }, this.props.business_partner.app_type === 'gi' ? React.createElement('img', {
        'src': '/site_media/static/images/gi_connect.jpeg',
        'className': 'img-responsive',
        'key': '5556'
    }) : null, this.props.business_partner.app_type === 'dhf' ? React.createElement('img', {
        'src': '/site_media/static/images/dhf_invite_client.jpeg',
        'className': 'img-responsive',
        'key': '5708'
    }) : null, this.props.business_partner.app_type === 'tri' ? React.createElement('img', {
        'src': '/site_media/static/images/dhf_invite_client.jpeg',
        'className': 'img-responsive',
        'key': '5868'
    }) : null, React.createElement('div', {
        'className': 'image-text-center',
        'style': { fontSize: '20px' }
    }, 'INVITE CLIENT')))), this.props.business_partner.app_type === 'dhf' || this.props.business_partner.app_type === 'tri' ? React.createElement('div', {
        'className': 'row start-xs m-t-20 text-center',
        'key': '6182'
    }, React.createElement('div', { 'className': 'col-md-12 f-20' }, '\n                MOVEMENT METERS\n            ')) : null, this.props.business_partner.app_type === 'dhf' || this.props.business_partner.app_type === 'tri' ? React.createElement('div', {
        'className': 'row start-xs m-t-20',
        'key': '6448'
    }, React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.showMovementMeterSelection('mobility');
        }
    }, React.createElement('div', {
        'className': 'br-50 m-10 width-150 bg-mobility',
        'style': {
            minHeight: '150px',
            borderRadius: '50% !important'
        }
    }, React.createElement('div', { 'className': 'text-center p-t-30' }, React.createElement('img', { 'src': '/site_media/static/images/dhf_mobility.png' }), React.createElement('div', {}, 'Mobility')))), React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.showMovementMeterSelection('strength');
        }
    }, React.createElement('div', {
        'className': 'br-50 m-10 width-150 bg-strength',
        'style': {
            minHeight: '150px',
            borderRadius: '50% !important'
        }
    }, React.createElement('div', { 'className': 'text-center p-t-30' }, React.createElement('img', { 'src': '/site_media/static/images/dhf_strength.png' }), React.createElement('div', {}, 'Strength')))), React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.showMovementMeterSelection('metabolic');
        }
    }, React.createElement('div', {
        'className': 'br-50 m-10 width-150 bg-metabolic',
        'style': {
            minHeight: '150px',
            borderRadius: '50% !important'
        }
    }, React.createElement('div', { 'className': 'text-center p-t-30' }, React.createElement('img', { 'src': '/site_media/static/images/dhf_metabolic.png' }), React.createElement('div', {}, 'Metabolic')))), React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.showMovementMeterSelection('power');
        }
    }, React.createElement('div', {
        'className': 'br-50 m-10 width-150 bg-power',
        'style': {
            minHeight: '150px',
            borderRadius: '50% !important'
        }
    }, React.createElement('div', { 'className': 'text-center p-t-30' }, React.createElement('img', { 'src': '/site_media/static/images/dhf_power.png' }), React.createElement('div', {}, 'Power'))))) : null, React.createElement('div', { 'className': 'row start-xs m-t-20 text-center' }, React.createElement('div', { 'className': 'col-md-12 f-20' }, '\n                GI SPECIALITIES\n            ')), React.createElement('div', { 'className': 'row start-xs m-t-20' }, React.createElement('div', { 'className': 'col-md-3 col-sm-3 col-xs-3' }), React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.show3dmapsSelection();
        }
    }, React.createElement('img', {
        'src': '/site_media/static/images/gi_3dmaps.jpg',
        'className': 'img-responsive'
    })), React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.showGIGolfSelection();
        }
    }, React.createElement('img', {
        'src': '/site_media/static/images/gi_golf.jpg',
        'className': 'img-responsive'
    })), React.createElement('div', { 'className': 'col-md-3 col-sm-3 col-xs-3' }))), React.createElement(Dialog, {
        'active': this.state.showMovementMeterSelectionDialog,
        'onEscKeyDown': this.hideMovementMeterSelection,
        'onOverlayClick': this.hideMovementMeterSelection
    }, React.createElement('div', {
        'className': 'row center-xs middle-xs',
        'style': { height: '500px' }
    }, React.createElement('h3', {
        'style': { marginTop: '-100px' },
        'className': 'capitalize'
    }, this.state.selectedMovementMeter, ' Selection'), React.createElement('div', { 'style': { marginTop: '-100px' } }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'onClick': () => {
            this.gotoClientList('move_assess');
        }
    }, 'Assess')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'onClick': () => {
            this.gotoClientList('move_exercises_assign');
        }
    }, 'Assign Exercises')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'onClick': () => {
            this.gotoClientList('move_exercises_browse');
        }
    }, 'Browse Exercises')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'onClick': () => {
            this.gotoClientList('move_workout_templates_assign');
        }
    }, 'Assign Workout Templates')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'onClick': () => {
            this.gotoClientList('move_workout_templates_browse');
        }
    }, 'Browse Workout Templates')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'onClick': () => {
            this.gotoClientList('move_gameplan_templates_browse');
        }
    }, 'Browse Gameplan Templates'))))), React.createElement(Dialog, {
        'active': this.state.show3dmapsSelectionDialog,
        'onEscKeyDown': this.hide3dmapsSelection,
        'onOverlayClick': this.hide3dmapsSelection
    }, React.createElement('div', {
        'className': 'row center-xs middle-xs',
        'style': { height: '350px' }
    }, React.createElement('h3', { 'style': { marginTop: '-100px' } }, '3DMAPS Selection'), React.createElement('div', { 'style': { marginTop: '-175px' } }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Assess client using 3DMAPS',
        'onClick': () => {
            this.gotoClientList('maps3d');
        }
    }, 'Assess Client')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Assign 3DMAPS exercises to a client',
        'onClick': () => {
            this.gotoClientList('maps3d_exercises');
        }
    }, 'Assign Exercises')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Assign workout template to a client',
        'onClick': () => {
            this.gotoClientList('workout_templates');
        }
    }, 'Assign Workout Template')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Browse 3DMAPS exercise library',
        'onClick': () => {
            this.goto3dmapsExercises();
        }
    }, 'Browse Library'))))), React.createElement(Dialog, {
        'active': this.state.showGIGolfSelectionDialog,
        'onEscKeyDown': this.hideGIGolfSelection,
        'onOverlayClick': this.hideGIGolfSelection
    }, React.createElement('div', {
        'className': 'row center-xs middle-xs',
        'style': { height: '350px' }
    }, React.createElement('h3', { 'style': { marginTop: '-100px' } }, 'Golf Selection'), React.createElement('div', { 'style': { marginTop: '-175px' } }, React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Assign Golf exercises to a client',
        'onClick': () => {
            this.gotoClientList('golf_exercises');
        }
    }, 'Assign Exercises')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Assign workout template to a client',
        'onClick': () => {
            this.gotoClientList('workout_templates');
        }
    }, 'Assign Workout Template')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Browse Golf exercise library',
        'onClick': () => {
            this.showGolfProgram();
        }
    }, 'Browse Library'))))), React.createElement(Dialog, {
        'active': this.state.showGIExerciseSelectionDialog,
        'onEscKeyDown': this.showGIExerciseSelection,
        'onOverlayClick': this.hideGIExerciseSelection
    }, React.createElement('div', {
        'className': 'row center-xs middle-xs',
        'style': { height: '350px' }
    }, React.createElement('h3', { 'style': { marginTop: '-100px' } }, 'GI Exercise Selection'), React.createElement('div', { 'style': { marginTop: '-175px' } }, React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Assign GI exercises to a client',
        'onClick': () => {
            this.gotoClientList('gi_exercises');
        }
    }, 'Assign Exercises')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Assign workout template to a client',
        'onClick': () => {
            this.gotoClientList('workout_templates');
        }
    }, 'Assign Workout Template')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Button, {
        'className': 'bg-accent m-l-10 large f-16',
        'tooltip': 'Browse GI exercise library',
        'onClick': () => {
            history.push('/practice/' + this.props.selectedRole.practice.id + '/engagement/exercise/gi');
        }
    }, 'Browse Library'))))), React.createElement(Dialog, {
        'active': this.state.mailComposerVisible,
        'className': 'dialog-title-center',
        'title': 'Email All Clients',
        'onEscKeyDown': this.hideMailComposer,
        'onOverlayClick': this.hideMailComposer
    }, React.createElement(MailComposer, {
        'hideEmail': true,
        'onSubmit': this.onMailComposerSubmit
    })));
}