import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progression_editable_wrapper";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteProgression } from '../../redux/program/network/program_edit';
import actionCreator from '../../redux/program/actions/program_edit';
import Swal from 'sweetalert2';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import moment from 'moment';

class ProgressionEditableWrapper extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.finishEditingDays = this.finishEditingDays.bind(this);
        this.startEditingDays = this.startEditingDays.bind(this);
        this.postUpdatedDays = this.postUpdatedDays.bind(this);
        this.state = {
            isEditingDays: false,
            updatedDays: [],
            showDatePicker: false,
        };
    }

    componentWillMount() {
    }

    startEditingDays() {
        if (this.props.programType === 'workout') {
            this.setState({
                showDatePicker: true,
            });
        } else {
            this.setState({
                isEditingDays: true,
                updatedDays: this.props.progression.days_values,
            });
        }
    }

    postUpdatedDays(onSuccess) {
        let days = this.state.updatedDays;
        if (this.props.programType === 'workout') {
            days = Utils.toDayArray(this.props.program.start_date, days);
        } else {
            days = _.map(days, (item) => { return item.value; });
        }
        this.props.updateProgressionDays({
            id: this.props.progression.id,
            days: days,
        }).then(() => {
            onSuccess();
            this.props.getProgram({ id: this.props.program.id });
        }).catch(() => { });
    }

    finishEditingDays(doSave) {
        if (doSave) {
            this.postUpdatedDays(() => {
                this.finishEditingDays(false);
            })
        } else {
            this.setState({
                isEditingDays: false,
                updatedDays: [],
                showDatePicker: false,
            });
        }
    }

    edit() {
        this.props.editProgression(this.props.progression.id);
        // history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + "/edit/" + this.props.match.params.program_id + "/1/edit_progression/" + this.props.progression.id);
    }

    delete() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure of deleting this progression?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.deleteProgression({ id: that.props.progression.id }).then(() => {
                that.props.getProgram({ id: that.props.program.id });
            }).catch(() => { });
        }, function (dismiss) {
        });
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteProgression,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let program = ownProps.program ? _.cloneDeep(ownProps.program) : undefined;
    if (program) {
        program.rest_days_options = _.map(program.rest_days, (item) => { return { label: "Day " + item, value: item } });
    }
    let progression = ownProps.progression ? _.cloneDeep(ownProps.progression) : undefined;
    if (progression) {
        progression.days_values = _.map(progression.days, (item) => { return { label: "Day " + item, value: item } });
        progression.days_options = progression.days_values.concat(program.rest_days_options);
        if (ownProps.programType === 'workout') {
            let startDate = ownProps.program.start_date;
            progression.selected_dates = Utils.toDateArray(startDate, progression.days);
            progression.available_dates = progression.selected_dates.concat(Utils.toDateArray(startDate, program.rest_days));
            // let today_date = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
            // progression.available_dates = _.filter(progression.available_dates,(available_date)=>{
            //     let date =   moment(available_date).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
            //     if(date >= today_date){
            //         return true;
            //     }else{
            //         return false;
            //     }
            // });
            progression.days_display = _.map(progression.selected_dates, (item) => { return Utils.parseDateAsString(item) });
        } else {
            progression.days_display = _.map(progression.days, (item) => { return "Day " + item });
        }
    }
    return {
        ...ownProps,
        program: program,
        progression: progression,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

const ProgressionEditableWrapperConnected = connect(mapStateToProps, mapDispatchToProps)(ProgressionEditableWrapper);
export default ProgressionEditableWrapperConnected;
