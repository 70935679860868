// Action Creators
const wrapperVideoListFill = (wrapper_videos) => ({
    type: 'WRAPPER_VIDEO_LIST_FILL',
    wrapper_videos: wrapper_videos
});

const wrapperVideoGetFill = (wrapper_video) => ({
    type: 'WRAPPER_VIDEO_GET_FILL',
    wrapper_video: wrapper_video
});

const wrapperVideoCleanup = () => ({
    type: 'WRAPPER_VIDEO_CLEANUP',
});

export default {
    wrapperVideoListFill,
    wrapperVideoGetFill,
    wrapperVideoCleanup,
};
