import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Useful links to create engaging videos',
        'className': 'p-b-5 text-left'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('div', { 'className': 'row' }, React.createElement(List, {
        'selectable': true,
        'ripple': true
    }, React.createElement(ListItem, {
        'caption': 'Get loose for the camera',
        'leftIcon': 'photo_camera',
        'onClick': () => {
            this.openLink(2);
        },
        'rightIcon': 'launch'
    }), React.createElement(ListItem, {
        'caption': 'Shooting video with an iPhone',
        'leftIcon': 'phone_iphone',
        'onClick': () => {
            this.openLink(3);
        },
        'rightIcon': 'launch'
    }), React.createElement(ListItem, {
        'caption': 'Videography tips',
        'leftIcon': 'camera_roll',
        'onClick': () => {
            this.openLink(4);
        },
        'rightIcon': 'launch'
    }), React.createElement(ListItem, {
        'caption': 'iPhone video tips on YouTube',
        'leftIcon': 'youtube_searched_for',
        'onClick': () => {
            this.openLink(7);
        },
        'rightIcon': 'launch'
    }), React.createElement(ListItem, {
        'caption': 'Lighting',
        'leftIcon': 'lightbulb_outline',
        'onClick': () => {
            this.openLink(5);
        },
        'rightIcon': 'launch'
    }), React.createElement(ListItem, {
        'caption': 'Lighting for a webcam',
        'leftIcon': 'camera',
        'onClick': () => {
            this.openLink(6);
        },
        'rightIcon': 'launch'
    }))))));
}