import Axios from '../../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';
import Utils from '../../../../utils/utils';
import { showProgress, hideProgress } from '../../../core/actions/progress_creator';
import MoveActions from '../actions/move_actions';
import authActions from '../../../auth/actions/auth_creator';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getMoveVideoList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/" + 6 + "/" + PACKAGE + "/move_videos_list";
        return Axios.post(url, params).then((response) => {
            dispatch(MoveActions.moveVideosFill(
                response['move_videos'],
                response['paginate_info'],
                response['favorite_list'],
                response['is_homefit_subscription_valid'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const getMoveVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + 6 + "/" + PACKAGE + "/move_video_get";
        return Axios.post(url, params).then((response) => {
            dispatch(MoveActions.moveVideoFill(
                response['move_video'],response['supporting_data'],response['shopify_equipment']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveMoveVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + 6 + "/" + PACKAGE + "/move_video_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteMoveVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/" + 6 + "/" + PACKAGE + "/move_video_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getHomeFitVerifyUser = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/dhf_move_verify_user";
        return Axios.post(url, params).then((response) => {
            dispatch(MoveActions.moveUserFill(response["user"]));
            dispatch(authActions.updateClientTitle("HomeFit"));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getMoveFamilyMembers = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_get_family_members";
        return Axios.post(url, params).then((response) => {
            dispatch(MoveActions.moveFamilyMembersFill(response["family_member_list"]));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addMoveFamilyMembers = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_add_family_member";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeMoveFamilyMembers = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_remove_family_members";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully unsubscribed family member");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const subscribeToHomeFitPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        let config = {};
        config["headers"] = {
            'USER_AGENT': navigator.userAgent,
            'Accept': "application/json",
            'Content-type': "application/json",
        };
        if (_.has(params,"token")){
            config["headers"]["STRIPETOKEN"] = params.token;
        }
        let updated_params = Object.assign({},params);
        delete updated_params.token;
        const url = "/" + API_VERSION + "/" + PACKAGE + "/dhf_move_stripe_subscribe";
        return Axios.post(url, updated_params,config).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully Subscribed");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const unSubscribeToHomeFitPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/dhf_move_stripe_unsubscribe";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully Unsubscribed");
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const toggleFavoriteMoveVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_toggle_favorite";
        return Axios.post(url, params).then((response) => {
            dispatch(MoveActions.moveVideoToggleFavorite(
                response['workout_id'],response['action_type']
            ));   

            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const trackHomeFitWorkout = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/dhf_move_activity_done";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const generateUsageReport = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/usage_report";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const hideHomefitWorkout = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = baseURL + '/' + 6 + '/' + PACKAGE + '/move_video_toggle_hide';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          Utils.showSuccessAlert(params.visibility_type==='unhide' ? 'Published Successfully' : 'Unpublished Successfully');
          return response;
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };

export {
    hideHomefitWorkout,
    getMoveVideoList,
    getMoveVideo,
    saveMoveVideo,
    deleteMoveVideo,
    getHomeFitVerifyUser,
    getMoveFamilyMembers,
    addMoveFamilyMembers,
    removeMoveFamilyMembers,
    subscribeToHomeFitPlan,
    unSubscribeToHomeFitPlan,
    toggleFavoriteMoveVideo,
    trackHomeFitWorkout,
    generateUsageReport,
};