// Action Creators
const practiceListFill = (practices,paginate_info) => ({
    type: 'PRACTICE_LIST_FILL',
    practices,
    paginate_info
});
const practiceListCleanup = () => ({
    type: 'PRACTICE_LIST_CLEANUP',
});
export default {
    practiceListFill,
    practiceListCleanup
};
