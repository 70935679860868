import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import TreatmentCreate from '../treatment_create';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
export default function () {
    function repeatTreatment_template1(treatment_template, treatment_templateIndex) {
        return React.createElement('div', {
            'className': 'box-shadow bg-white m-10 width-300 ribbon-container',
            'key': 'treatment_template' + treatment_template.id
        }, !treatment_template.published ? React.createElement('div', {
            'className': 'bg-warning ribbon f-10',
            'key': '2278'
        }, '\n                    DRAFT\n                ') : null, treatment_template.published ? React.createElement('div', {
            'className': 'bg-published ribbon f-10',
            'key': '2418'
        }, '\n                    PUBLISHED\n                ') : null, React.createElement('div', {
            'className': 'row pointer',
            'onClick': event => this.goToTreatmentDashboard(event, treatment_template.id)
        }, React.createElement('div', { 'className': 'col-xs-6' }, treatment_template.is_logo_uploaded ? React.createElement('div', {
            'className': 'thumbnail profile-picture-normal',
            'style': { backgroundImage: 'url(' + encodeURI(treatment_template.logo_url) + ')' },
            'key': '2723'
        }) : null, !treatment_template.is_logo_uploaded ? React.createElement(ProfilePicture, {
            'user': treatment_template,
            'key': '2929'
        }) : null), React.createElement('div', {
            'className': 'col-xs-6 p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', { 'className': 'end-xs' }, React.createElement(TooltipWrapper, { 'tooltip': treatment_template.name }, React.createElement('h6', { 'className': 'two-lines text-right' }, '\n                                        ', treatment_template.name, '\n                                    ')), React.createElement('div', { 'className': 'f-10 truncate text-right' }, treatment_template.practice.name)), React.createElement('div', {
            'className': 'p-absolute',
            'style': {
                top: '80px',
                right: '10px'
            }
        }, !this.props.create_treatment ? [
            this.state.treatmentCategory === 'active' && treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
                'tooltip': this.props.match.params.treatment_type === 'group' ? 'Deactivate this group template' : 'Deactivate this game plan template',
                'icon': 'gm-hide',
                'gomotive': true,
                'className': 'c-black small-icon-button round-border',
                'onClick': () => {
                    this.hideTreatmentTemplate(treatment_template.id);
                },
                'key': '36871'
            }) : null,
            this.state.treatmentCategory === 'inactive' && treatment_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(IconButton, {
                'tooltip': this.props.match.params.treatment_type === 'group' ? 'Activate this group template' : 'Activate this game plan template',
                'icon': 'gm-show',
                'gomotive': true,
                'className': 'c-black small-icon-button round-border',
                'onClick': () => {
                    this.hideTreatmentTemplate(treatment_template.id);
                },
                'key': '36873'
            }) : null
        ] : null, this.props.create_treatment ? React.createElement(IconButton, {
            'tooltip': 'Use this',
            'icon': 'add_circle_filled',
            'className': 'c-black small-icon-button round-border',
            'onClick': () => {
                this.showTreatmentCreationModal(treatment_template);
            },
            'key': '4987'
        }) : null))));
    }
    return React.createElement('div', { 'className': 'm-15' }, React.createElement('div', { 'className': 'row start-xs' }, !this.props.create_treatment ? React.createElement(Select, {
        'className': 'col-xs-4 p-t-20',
        'clearable': false,
        'value': this.state.treatmentCategory,
        'options': this.treatmentCategories,
        'simpleValue': true,
        'onChange': val => {
            this.onChangeTreatmentCategory(val);
        },
        'key': '1168'
    }) : null, React.createElement(Input, {
        'hint': this.props.match.params.treatment_type === 'group' ? 'Search group templates' : 'Search game plan templates',
        'className': 'm-l-15  width-300',
        'value': this.state.search_text,
        'onChange': this.updateSearchText,
        'onKeyPress': this.searchTreatments
    })), this.props.treatment_template_list.length === 0 ? React.createElement('div', {
        'className': 'row m-20 center-xs alert-warning',
        'key': '1705'
    }, '\n        ', this.props.match.params.treatment_type === 'group' ? 'Click on + button to create a new group template for your practice.' : 'Click on + button to create a new game plan template for your practice.', '\n    ') : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.treatment_template_list, repeatTreatment_template1.bind(this))
    ]), this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '5415'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'tooltip': 'Load more treatment templates',
        'label': 'Load more treatment templates...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.getData(this.props, true);
        }
    }))) : null, !this.props.create_treatment ? React.createElement(Button, {
        'tooltip': 'Create new template',
        'className': 'floating-add-button m-r-8 m-t-30',
        'onClick': this.addTreatment,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'key': '5897'
    }) : null, this.props.create_treatment ? React.createElement(Button, {
        'tooltip': 'Click to go back',
        'className': 'floating-cancel-button m-r-8',
        'onClick': this.props.hideTreatmentTemplateSelection,
        'icon': 'clear',
        'floating': true,
        'ripple': true,
        'key': '6076'
    }) : null, this.state.showTreatmentCreationModal ? React.createElement(Dialog, {
        'active': this.state.showTreatmentCreationModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideTreatmentCreationModal,
        'onOverlayClick': this.hideTreatmentCreationModal,
        'key': '6308'
    }, React.createElement(TreatmentCreate, {
        'engagement_id': this.props.engagement_id,
        'treatment_template': this.state.selected_treatment_template,
        'hideTreatmentCreationModal': this.hideTreatmentCreationModal
    })) : null);
}