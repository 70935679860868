import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import DatePicker from '../../../utils/common_components/datepicker_form';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row m-l-30 m-t-10 m-r-30 m-b-30' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Habit Template',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'className': 'col-xs-12 m-t-30' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'component': TextInput,
        'label': 'Name'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'text',
        'component': TextInput,
        'label': 'Habit text',
        'multiline': true
    })), this.props.group.group_treatment_cycle == 1 ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '2226'
    }, React.createElement(Field, {
        'name': 'start_date',
        'type': 'date',
        'label': 'Start date',
        'component': DatePicker,
        'minDate': moment().subtract(1, 'days').toDate()
    })) : null, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'schedule_type',
        'component': Select,
        'label': 'How frequently should your clients follow this habit?',
        'options': this.props.supporting_data.frequency_schedule,
        'simpleValue': true
    })), React.createElement('div', { 'className': 'col-xs-6' }, this.props.schedule_type === 4 ? React.createElement(Field, {
        'className': 'w-100',
        'name': 'schedule_week_day',
        'component': Select,
        'label': 'Which day of the week?',
        'options': this.props.supporting_data.week_day_list,
        'simpleValue': true,
        'key': '3259'
    }) : null, this.props.schedule_type === 5 ? React.createElement(Field, {
        'className': 'w-100',
        'name': 'schedule_month_date',
        'type': 'number',
        'component': TextInput,
        'label': 'Which day of the month?',
        'key': '3745'
    }) : null))), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'duration_count',
        'type': 'number',
        'label': 'How long should your clients follow this habit?',
        'component': TextInput
    })), React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100 m-t-19',
        'name': 'duration_type',
        'component': Select,
        'options': this.props.supporting_data.duration_type,
        'simpleValue': true
    })))), React.createElement(Card, {
        'className': 'no-box-shadow',
        'style': { marginTop: '30px' }
    }, React.createElement(CardTitle, {
        'title': 'Video',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', { 'className': 'row center-xs middle-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, this.props.video_guid ? React.createElement('div', {
        'className': 'm-b-20',
        'key': '5586'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-player',
        'handleUploadProcessing': true,
        'videoList': [this.props.video_guid]
    })) : null, React.createElement('div', { 'className': 'row center-xs' }, React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'buttonText': this.props.video_guid ? 'Change Video' : 'Upload Video'
    }), this.props.video_guid ? React.createElement(Button, {
        'onClick': this.deleteVideo,
        'label': 'Delete Video',
        'className': 'm-l-10 bg-danger',
        'primary': true,
        'raised': true,
        'key': '6549'
    }) : null))))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save as Draft',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true,
        'onClick': this.saveAsDraft
    }), React.createElement(Button, {
        'label': 'Save and Publish',
        'disabled': this.props.submitting,
        'className': 'bg-success m-l-5',
        'type': 'button',
        'raised': true,
        'onClick': this.saveAndPublish
    }), React.createElement(Button, {
        'label': 'Cancel',
        'onClick': this.props.finishEditing,
        'className': 'bg-accent m-l-5',
        'type': 'button',
        'raised': true
    })))))));
}