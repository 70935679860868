import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/activate";
import { reduxForm } from 'redux-form';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { activate, timezone_list, checkUserActivationGuid } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import {history} from "../../redux/store";

class Activate extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.activateUserAccount = this.activateUserAccount.bind(this);
    }

    componentDidMount() {
        let params = {};
        params['activation_guid'] = this.props.match.params.activation_guid;
        this.props.checkUserActivationGuid(params).then((response) => {
            if (response['status'] === "200") {
                if (this.props.match.params.activation_user_type === "s") {
                    let params = {};
                    params['activation_user_type'] = this.props.match.params.activation_user_type;
                    params['activation_guid'] = this.props.match.params.activation_guid;
                    params['password'] = '';
                    this.props.activate({ ...params }).then((response) => {
                    }).catch((error) => {
                        //do nothing                
                    });
                } else if (this.props.match.params.activation_user_type === 'i') {
                    this.props.timezone_list();
                }                
            } else {
                history.push('/auth/login');
                if (this.props.match.params.activation_user_type === "s") {
                    Utils.showErrorAlert("You have already activated the account. Login using your email and password.");
                }else if (this.props.match.params.activation_user_type === "i") {
                    Utils.showErrorAlert("You have already activated the account. Login using your email and password.");
                }else if (this.props.match.params.activation_user_type === "r") {
                    Utils.showErrorAlert("Invalid URL");
                }                  
            }
        }).catch((error) => {
        });
  
    }

    activateUserAccount(data) {
        if (this.props.match.params.activation_user_type === "i" || this.props.match.params.activation_user_type === "r") {
            let params = { ...data };
            params['activation_user_type'] = this.props.match.params.activation_user_type;
            params['activation_guid'] = this.props.match.params.activation_guid;
            this.props.activate({ ...params }).then((response) => {
            }).catch((error) => {
                //do nothing
            });
        }
    }    

    render() {
        return Template.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        activate,
        checkUserActivationGuid,
        timezone_list
     }, dispatch);
}
function mapStateToProps(state) {
    let initialValues = {};
    initialValues['timezone'] = 'US/Pacific';
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        timezones: state.auth.authReducer.timezones,
        initialValues: initialValues,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'first_name',
        'last_name',
        'password',
        'confirm_password',
        'timezone'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });

    if(values['password'] !== values['confirm_password']){
        errors['password'] = 'Passwords does not match';
    } else if (values['password'] !== undefined) {
        if (values['password'].length < 6) {
            errors['password'] = 'Password must be minimum 6 characters in length.';
        }
    }
    return errors;
}; 


const ActivateForm = reduxForm(
    {
        form: 'SignupForm',
        enableReinitialize: true,
        validate
    }
)(Activate);

const ActivateFormConnected = connect(mapStateToProps, mapDispatchToProps)(ActivateForm);
export default ActivateFormConnected;
