import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "../templates/facility/client_facility_booking.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { reduxForm, formValueSelector } from 'redux-form';
import { clientFacilityBookingGet,clientFacilityBookingPost,clientFacilityBookingCancel } from '../../../redux/calendar/network/facility_network';
import facilityActionCreator from '../../../redux/calendar/actions/facility_action';
import moment from 'moment';
import Swal from 'sweetalert2';

class ClientFacilityBooking extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doBook = this.doBook.bind(this);
    }

    componentWillMount() {
        this.props.clientFacilityBookingGet({
            'facility_id': this.props.facility_id,
            'id':this.props.booking_slot_id,
        });
    }

    doBook(){
        let that = this;
        let params = {};
        params['facility_id'] = this.props.facility_id;
        params['id'] = this.props.booking_slot_id;
        if(this.props.is_booked){
            this.props.clientFacilityBookingCancel({...params})
            .then((response) => {
                that.props.closeBookingDialog(true);
            })
            .catch((error) => { 
    
            });
        }else{
            this.props.clientFacilityBookingPost({...params})
            .then((response) => {
                that.props.closeBookingDialog(true);
            })
            .catch((error) => { 
    
            });
        }
    }



    componentWillUnmount() {      
        this.props.cleanUp(this.props.facility,{},[]);  
    }

    render() {
        if(_.isEmpty(this.props.booking_slot)){
            return (<div></div>);
        }
        return Template.apply(this);
    }
}




function mapStateToProps(state, ownProps) {
    let facility = state.calendar.facilityReducer.facility;
    let user = state.auth.authReducer.user;
    let booking_slot = _.cloneDeep(state.calendar.facilityReducer.booking_slot);
    let is_booked = false;
    let disable_booking = false;
    if(!_.isEmpty(booking_slot)){
        let client_ids = _.map(booking_slot.clients,"id");
        if(_.indexOf(client_ids, user.id) > -1){
            is_booked = true;
        }
    }
    if(is_booked === false){
        if(booking_slot.booking_count >=  facility.max_client_count){
            disable_booking = true;
        }
    }


    return {
        user: user,
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: booking_slot,
        facility: facility,
        booking_slot: booking_slot,
        clientList: state.calendar.facilityReducer.client_list,
        is_booked:is_booked,
        disable_booking:disable_booking
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clientFacilityBookingGet,
        clientFacilityBookingPost,
        clientFacilityBookingCancel,
        cleanUp: facilityActionCreator.facilityBookingObjectFill
    }, dispatch
    );
}


const ClientFacilityBookingConnected = connect(mapStateToProps, mapDispatchToProps)(ClientFacilityBooking);
export default ClientFacilityBookingConnected;