import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_group_class_booking_edit.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { reduxForm, formValueSelector } from 'redux-form';
import { clientGroupClassSchedulerBookingPost } from '../../redux/calendar/network/group_class_network';
import moment from 'moment';
import Swal from 'sweetalert2';

class ClientGroupClassBookingEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
    }

    doSave(data){
        let that = this;
        let params = _.omit(data,"client_bookings");
        params['group_class_schedule'] = [];
        _.forEach(data.client_bookings, function(value, key) {
            params['group_class_schedule'].push.apply(params['group_class_schedule'], value.bookings);
        }) ;
        this.props.clientGroupClassSchedulerBookingPost({
            ...params,
            app_version: "5.2",
            current_role_type: "practice_admin",
            package_version: "1",
            practice_id: 276,
        })
        .then((response) => {
            that.props.closeBookingDialog();
        })
        .catch((error) => { 

        });
    }

    componentWillUnmount() {        
    }

    render() {
        if (_.isEmpty(this.props.group_class)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }  
        return Template.apply(this);
    }
}

const validate = (values, props) => {
    let requiredFields = [
        'client',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};
const selector = formValueSelector('ClientGroupClassBookingEditForm');
function mapStateToProps(state, ownProps) {
    let groupClass =  _.cloneDeep(state.calendar.groupClassReducer.group_class);
    let bookedClient = _.cloneDeep(state.calendar.groupClassReducer.booked_client);
    let initialValues = {entire_series:false,client:null,group_class_id:ownProps.groupClassId,client_bookings:{}};
    if(!_.isEmpty(bookedClient)){
        initialValues["client"] = bookedClient.id;
        _.forEach(groupClass.group_class_frequency,(frequency)=>{
            initialValues["client_bookings"][frequency.id] = {'bookings':[]};
        });
    }

    // groupClass.group_class_frequency = _.map(groupClass.group_class_frequency,(frequency)=>{
    //     frequency.available_booking_dates =  _.filter(frequency.available_booking_dates,(item)=>{
    //         let available = true;
    //         _.forEach(bookedClient.bookings,(booking)=>{
    //             if(booking.group_class_schedule.id === item.id){
    //                 available = false;
    //             }
    //         });   
    //         return available;
    //     });    
    //     return frequency;
    // });

    groupClass.group_class_frequency = _.map(groupClass.group_class_frequency,(frequency)=>{
        frequency.available_booking_dates =  _.map(frequency.available_booking_dates,(item)=>{
            item['name'] = moment(item.date,"YYYY-MM-DD").format("MMM DD, YYYY");
            return item;
        });    
        return frequency;
    });
    return {
        initialValues: initialValues,
        group_class: groupClass,
        bookedClient: bookedClient,
        scheduledDateList: state.calendar.groupClassReducer.schedule_date_list,
        entire_series: selector(state, 'entire_series')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clientGroupClassSchedulerBookingPost
    }, dispatch
    );
}

const ClientGroupClassBookingEditForm = reduxForm({
    form: 'ClientGroupClassBookingEditForm',
    enableReinitialize: true,
    validate
}
)(ClientGroupClassBookingEdit);

const ClientGroupClassBookingEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(ClientGroupClassBookingEditForm);
export default ClientGroupClassBookingEditFormConnected;