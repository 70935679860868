import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
import moment from 'moment';
export default function () {
    function repeatLog1(log, logIndex) {
        return React.createElement(Tr, { 'key': 'log' + logIndex }, React.createElement(Td, {
            'column': 'user',
            'value': log.user.first_name,
            'style': { width: '140px !important' }
        }, React.createElement('span', {}, log.user.first_name + ' ' + log.user.last_name)), React.createElement(Td, {
            'column': 'platform',
            'style': { width: '140px !important' }
        }, React.createElement('span', {}, '\n                    ', log.source, '\n                ')), React.createElement(Td, {
            'column': 'subscription_name',
            'className': 'truncate-wrap',
            'style': { maxWidth: '80px !important' }
        }, React.createElement('span', { 'className': 'truncate' }, '\n                    ', log.product_id, '\n                ')), React.createElement(Td, {
            'column': 'plan_amount',
            'style': { width: '120px !important' }
        }, React.createElement('span', {}, '\n                    ', '$' + log.plan.amount, '\n                ')), React.createElement(Td, {
            'column': 'subscription_status',
            'style': { width: '120px !important' }
        }, React.createElement('span', {}, '  \n                    ', log.notification_type, '\n                ')), React.createElement(Td, {
            'column': 'last_updated',
            'style': { width: '140px !important' }
        }, React.createElement('span', {}, '\n                    ', moment(log.publish_datetime).format('MMM D, YYYY'), '\n                ')), React.createElement(Td, {
            'column': 'action',
            'style': { width: '100px !important' }
        }, React.createElement('span', {}, React.createElement(Button, {
            'label': 'View Full Receipt',
            'className': 'height-fit bg-success-border m-b-12 w-100',
            'raised': true,
            'onClick': () => this.viewReceipt(logIndex, 'homefit')
        }))));
    }
    function repeatGi_log2(gi_log, gi_logIndex) {
        return React.createElement(Tr, { 'key': 'gi_log' + gi_logIndex }, React.createElement(Td, {
            'column': 'user',
            'value': gi_log.practice.owner.name,
            'style': { width: '140px !important' }
        }, React.createElement('span', {}, gi_log.practice.owner.name)), React.createElement(Td, {
            'column': 'platform',
            'style': { width: '140px !important' }
        }, React.createElement('span', {}, '\n                    ', gi_log.source, '\n                ')), React.createElement(Td, {
            'column': 'subscription_name',
            'className': 'truncate-wrap',
            'style': { width: '140px !important' }
        }, React.createElement('span', { 'className': 'truncate' }, '\n                    ', gi_log.product_id, '\n                ')), React.createElement(Td, {
            'column': 'plan_amount',
            'style': { width: '120px !important' }
        }, React.createElement('span', {}, '\n                    ', '$' + gi_log.plan.fee_amount, '\n                ')), React.createElement(Td, {
            'column': 'subscription_status',
            'style': { width: '120px !important' }
        }, React.createElement('span', {}, '  \n                    ', gi_log.notification_type, '\n                ')), React.createElement(Td, {
            'column': 'last_updated',
            'style': { width: '140px !important' }
        }, React.createElement('span', {}, '\n                    ', moment(gi_log.publish_datetime).format('MMM D, YYYY'), '\n                ')), React.createElement(Td, {
            'column': 'action',
            'style': { width: '100px !important' }
        }, React.createElement('span', {}, React.createElement(Button, {
            'label': 'View Full Receipt',
            'className': 'height-fit bg-success-border m-b-12 w-100',
            'raised': true,
            'onClick': () => this.viewReceipt(gi_logIndex, 'gi')
        }))));
    }
    return React.createElement('div', { 'className': 'user-content' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable' + ' ' + true,
            'id': 'table',
            'noDataText': 'There is no subscriptions log to show',
            'sortable': [
                'name',
                'is_member_since',
                'number_of_clients',
                'last_login'
            ],
            'isServerPaginate': true,
            'onSort': this.onSort,
            'onPageChange': this.onPageChange,
            'itemsPerPage': this.props.type == 'homefit' ? this.props.homefit_paginate_info.per_page : this.props.gi_paginate_info.per_page,
            'currentPage': this.props.type == 'homefit' ? this.props.homefit_paginate_info.page : this.props.gi_paginate_info.page,
            'numPages': this.props.type == 'homefit' ? this.props.homefit_paginate_info.total : this.props.gi_paginate_info.total
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'user',
            'style': { width: '140px !important' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'User')), React.createElement(Th, {
            'column': 'platform',
            'style': { width: '140px !important' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Platform')), React.createElement(Th, {
            'column': 'subscription_name',
            'style': { width: '140px !important' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Name')), React.createElement(Th, {
            'column': 'plan_amount',
            'style': { width: '120px !important' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Amount')), React.createElement(Th, {
            'column': 'subscription_status',
            'style': { width: '120px !important' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Status')), React.createElement(Th, {
            'column': 'last_updated',
            'style': { width: '140px !important' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Last Updated')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '100px !important',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Action'))),
        this.props.type == 'homefit' ? _.map(this.props.homefit_log, repeatLog1.bind(this)) : null,
        this.props.type == 'gi' ? _.map(this.props.gi_log, repeatGi_log2.bind(this)) : null
    ]), React.createElement(Dialog, {
        'active': this.state.showReceipt,
        'className': 'full-dialog bg-beige',
        'style': { minHeight: '100px !important' },
        'onEscKeyDown': this.hideReceipt,
        'onOverlayClick': this.hideReceipt
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-20px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.hideReceipt
    })), React.createElement('div', {}, React.createElement('pre', {}, ' ', JSON.stringify(this.state.completeReceiptObject, null, 2)))));
}