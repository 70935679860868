import React  from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/exercise_preview";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
class ExercisePreview extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.state = {
            tabIndex: 0,
        }
    }

    handleTabChange(index) {
        this.setState({
            tabIndex: index,
        });
    }

    render() {
        return Template.apply(this);
    }

}

ExercisePreview.propTypes = {
    exercise: PropTypes.object,
    onClosePreview: PropTypes.func.isRequired,
};
ExercisePreview.defaultProps = {
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

const ExercisePreviewConnected = connect(mapStateToProps, mapDispatchToProps)(ExercisePreview);
export default ExercisePreviewConnected;
