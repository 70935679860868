// Action Creators

const treatmentTemplateProgramCleanup = () => ({
    type: 'TREATMENT_TEMPLATE_PROGAM_CLEANUP',
});

const fillHabitList = (habits) => ({
    type: 'TREATMENT_TEMPLATE_HABIT_LIST_FILL',
    habits
});

const fillHabit = (habit, supportingData) => ({
    type: 'TREATMENT_TEMPLATE_HABIT_FILL',
    habit,
    supportingData,
});

const fillWorkoutList = (workouts) => ({
    type: 'TREATMENT_TEMPLATE_WORKOUT_LIST_FILL',
    workouts
});

const deleteWorkout = (workout_id) => ({
    type: 'TREATMENT_TEMPLATE_WORKOUT_DELETE',
    workout_id
});

const nutritionDocumentsFill = (documents) => ({
    type: 'TREATMENT_TEMPLATE_DOCUMENTS_NUTRITION_FILL',
    documents
});

const guidanceDocumentsFill = (documents) => ({
    type: 'TREATMENT_TEMPLATE_DOCUMENTS_GUIDANCE_FILL',
    documents
});

export default {
    treatmentTemplateProgramCleanup,
    fillHabitList,
    fillWorkoutList,
    nutritionDocumentsFill,
    guidanceDocumentsFill,
    fillHabit,
    deleteWorkout
};
