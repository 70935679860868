import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import {bindActionCreators} from 'redux';
import Template from "./templates/plan_selection_details.rt";
import {connect} from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {subscribeToPlan,unSubscribeToPlan} from '../../redux/payment/network/plan_subscribe_network';
import {history} from '../../redux/store';
import planSubscribeReducer from '../../redux/payment/reducers/plan_subscribe_reducer';
import {showProgress, hideProgress} from '../../redux/core/actions/progress_creator';
import Swal from 'sweetalert2';
import { verifyToken } from '../../redux/auth/network/auth_network';

class PlanSelectionDetails extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.subscribe = this.subscribe.bind(this);
        this.unSubscribe = this.unSubscribe.bind(this);
    }

    subscribe(token,plan_id,partner_id){
        let that = this;
        this.props.showProgress();
        this.props.subscribeToPlan({token:token.id,plan_id,partner_id});
    }

    unSubscribe(plan_id,partner_id){
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to unsubscribe!",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if (result.value ) {
                that.props.unSubscribeToPlan({plan_id,partner_id});
            }
        }, function (dismiss) {
                        
        });        
    }

    render() {
        if (_.isEmpty(this.props.partner_plan)) {
            return (
                <div></div>
            );
        }
        return Template.apply(this);
    }
}

PlanSelectionDetails.defaultProps = {
    partner_plan: {}
};

function mapStateToProps(state, ownprops) {
    let partner_plan = ownprops.partner_plan;
    return {
        partner_plan: partner_plan,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        subscribeToPlan,
        unSubscribeToPlan,
        showProgress,
        verifyToken
    }, dispatch);
}
const PlanSelectionDetailsConnected = connect(mapStateToProps, mapDispatchToProps)(PlanSelectionDetails);
export default PlanSelectionDetailsConnected;