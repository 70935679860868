// Action Creators
const fillExerciseList = (exerciseList, paginateInfo) => ({
    type: 'EXERCISE_LIST_FILL',
    exerciseList,
    paginateInfo
});
const fillExerciseFilterList = (filters) => ({
    type: 'EXERCISE_FILTERS_LIST_FILL',
    filters
});
const fillExerciseFilterData = (filterData,first) => ({
    type: 'EXERCISE_FILTER_DATA_FILL',
    filterData,
    first
});
const removeExerciseList = (exercise) => ({
    type: 'EXERCISE_LIST_REMOVE',
    exercise
});
const addExerciseList = (exercise) => ({
    type: 'EXERCISE_LIST_ADD',
    exercise
});
const updateExerciseListAfterToggleFavorite = (exercise) => ({
    type: 'EXERCISE_LIST_UPDATE_AFTER_TOGGLE_FAVORITE',
    exercise
});
const cleanModule = () => ({
    type: 'EXERCISE_LIST_CLEANUP',
});
const addExerciseToWorkoutCart = (exercise) => ({
    type: 'ADD_EXERCISE_TO_WORKOUT_CART',
    exercise,
});
const clearWorkoutCart = () => ({
    type: 'CLEAR_WORKOUT_CART',
});
export default {
    fillExerciseList,
    fillExerciseFilterList,
    fillExerciseFilterData,
    cleanModule,
    removeExerciseList,
    addExerciseList,
    updateExerciseListAfterToggleFavorite,
    addExerciseToWorkoutCart,
    clearWorkoutCart
};