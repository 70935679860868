import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/group_client_add.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field , FieldArray} from 'redux-form';
import { history } from '../../redux/store';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import DatePicker from '../../utils/common_components/datepicker_form';
import TimePicker from '../../utils/common_components/time_picker_form';
import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Button from '../../utils/common_components/tooltipButton';
import { getGroupAvailableClientList} from '../../redux/group/network/group_network';
import {addClientForGroup} from '../../redux/group/network/client_goal_tracking';
import moment from 'moment';


class GroupClientAdd extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doClose = this.doClose.bind(this);
        this.selectClient = this.selectClient.bind(this);
        this.state = {selectedClients:[]};
    };

    componentWillMount() {
        this.props.getGroupAvailableClientList({
            id:this.props.group.id,
            practice_id: this.props.practice_id,
        });
    }

    doClose() {
        this.props.handleClose();
    }

    doSave() {     
        let params = {id: this.props.group.id};
        params["clients"] = _.map(this.state.selectedClients,"id");
        this.props.addClientForGroup(params).then((response) => {
            this.doClose();
        }).catch((error) => {
            //do nothing
        });
    }

    selectClient(clients){
        if(clients == null){
            clients = [];
        }
        this.setState({selectedClients:clients});
    }

    
    render() {
        if (_.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

GroupClientAdd.propTypes = {
    group:PropTypes.object.isRequired,    
    handleClose:PropTypes.func.isRequired,    
};



function mapStateToProps(state, ownprops) {
    return {
        group: ownprops.group,
        handleClose:ownprops.handleClose,
        clients: state.group.groupReducer.clients,
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGroupAvailableClientList,
        addClientForGroup,
        }, 
        dispatch
    );
}


const GroupClientAddConnected = connect(mapStateToProps,mapDispatchToProps)(GroupClientAdd);
export default GroupClientAddConnected;
