import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Sidemenu from '../workfit_menu';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from '../../../../core/components/not_found';
import ClientSettings from '../../../../client_app/components/client_settings_container';
import Workfit from '../../../../client_app/components/workfit';
import WorkFitHome from '../workfit_home';
import ClientFacilityList from '../../../../calendar/components/facility/client_facility_list';
import ClientFacilityViewCalendar from '../../../../calendar/components/facility/client_facility_view_calendar';
import ChatList from '../../../../conversation/components/chat_list';
import ConversationList from '../../../../conversation/components/conversation_list';
import MessageList from '../../../../message/components/messages';
import MyNews from '../../../../news/components/my_news';
import NewsView from '../../../../news/components/news_view';
import ClientGroup from '../../../../group/components/client_group_dashboard';
export default function () {
    return React.createElement('div', { 'className': 'd-flex' }, React.createElement(NavDrawer, {
        'className': 'main-drawer',
        'active': this.props.menuopen,
        'pinned': this.props.pinned,
        'onOverlayClick': this.toggleDrawerActive
    }, React.createElement(Sidemenu, {
        'isAuthenticated': true,
        'location': this.props.location,
        'doLogout': this.props.doLogout
    })), React.createElement('div', {
        'className': 'content-zone m-t-20',
        'style': {
            flex: '1',
            padding: '20px 15px'
        }
    }, React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/home`,
        'component': WorkFitHome
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/details`,
        'component': Workfit
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/settings`,
        'component': ClientSettings
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/conversation/:conversation_id`,
        'component': ChatList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/conversation`,
        'component': ConversationList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/scheduler/:facility_id`,
        'component': ClientFacilityViewCalendar
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/scheduler`,
        'component': ClientFacilityList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/messages`,
        'component': MessageList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/my_news`,
        'component': MyNews
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/news/view/:news_id`,
        'component': NewsView
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/group/:group_id`,
        'component': ClientGroup
    }), React.createElement(Route, { 'component': NotFound }))));
}