import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PlanSelection from '../plan_selection';
import PaymentStripeConfig from '../stripe_config';
import ClientPlanEdit from '../client_plan_edit';
import ClientPlans from '../client_plan';
export default function () {
    return React.createElement('div', { 'className': 'm-t-10 col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/payments/my_plans`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'My Subscription Plans')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/payments/stripe_config`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Stripe Config')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/payments/client_plans`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Plans for my Clients'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/my_plans`,
        'component': PlanSelection
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/stripe_config`,
        'component': PaymentStripeConfig
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/client_plans/:plan_id`,
        'component': ClientPlanEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/client_plans`,
        'component': ClientPlans
    })));
}