import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';
import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import actions from '../actions/quickstart_actions';

const getClientQuickStart = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_kickstarts";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.fillQuickstartList(
                response.acquired_kickstarts,
                response.available_kickstarts
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const acquireQuickStart = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_kickstart_acquire";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("QuickStart acquired successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const setQuickStartDate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_kickstart_start_date_set";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Start Date has been set successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getClientQuickStart,
    acquireQuickStart,
    setQuickStartDate,
};