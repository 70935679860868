let initialState = {
    webinars:{},
    webinar:{}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "WEBINAR_LIST_FILL":
            if (!action.webinars_list){
                return state;
            }
            return Object.assign({}, state, {
                ...state,
                webinars: action.webinars_list,
            });
        case "WEBINAR_FILL":
            if(!action.webinar){
                return state;
            }

            return Object.assign({}, state, {
                ...state,
                webinar: action.webinar
            });
        case "WEBINAR_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};