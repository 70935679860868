import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from './basecomponent';
import Template from "./templates/mail_composer";
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

class MailComposer extends BaseComponent {

    constructor(props) {
        super(props);
        this.doSave = this.doSave.bind(this);
    }

    doSave(data) {
        this.props.onSubmit(data);
    }

    render() {
        return Template.apply(this);
    }
}

MailComposer.propTypes = {
    email: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    submitLabel: PropTypes.string,
    hideEmail: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};
MailComposer.defaultProps = {
    subject: "",
    body: "",
    email: "",
    submitLabel: "Send",
    hideEmail: false,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: { email: ownProps.email, subject: ownProps.subject, body: ownProps.body },
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const MailComposerForm = reduxForm(
    {
        form: 'MailComposerForm',
        enableReinitialize: true,
        validate
    }
)(MailComposer);

const ConnectedMailComposer = connect(mapStateToProps)(MailComposerForm);
export default ConnectedMailComposer;