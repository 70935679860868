import React  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/engagement_assessment_summary.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field, FieldArray, arrayPush, arrayRemove } from 'redux-form';
import { history } from '../../redux/store';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import DatePicker from '../../utils/common_components/datepicker_form';
import TimePicker from '../../utils/common_components/time_picker_form';
import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Button from '../../utils/common_components/tooltipButton';
import {getEngagmentAssessmentSummary, updateAssessmentSummary} from '../../redux/engagement/network/engagement_network';
import EnagagementAction from '../../redux/engagement/actions/engagement_creator';
import moment from 'moment';
import { uploadFiles } from '../../redux/core/network/file_upload';
import store from '../../redux/store';


class ClientEngagementAssessment extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.closePreview = this.closePreview.bind(this);
        this.previewMedia = this.previewMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.showMatAssessmentPreview = this.showMatAssessmentPreview.bind(this);
        this.hideMatAssessmentPreview = this.hideMatAssessmentPreview.bind(this);
        this.showDhfAssessmentPreview = this.showDhfAssessmentPreview.bind(this);
        this.hideDhfAssessmentPreview = this.hideDhfAssessmentPreview.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.state = {
            imageIndex: 0,
            showMatAssessmentPreview : false,
            showDhfAssessmentPreview : false,
        };
    };
    setActive(el, active = true){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        if (!this.props.engagement_id){
            history.goBack();
        }
        let params = { 'engagement_id': this.props.engagement_id};
        if(this.props.assessment_id){
            params["id"] =  this.props.assessment_id;
        }
        this.props.getEngagmentAssessmentSummary( params);

    }

    componentWillUnmount () {
        this.props.cleanUp();      
    }
   
    showMatAssessmentPreview(){
        this.setState({showMatAssessmentPreview:true});
    }
    
    hideMatAssessmentPreview(){
        this.setState({showMatAssessmentPreview:false});
    }
    showDhfAssessmentPreview(){
        this.setState({showDhfAssessmentPreview:true});
    }
    
    hideDhfAssessmentPreview(){
        this.setState({showDhfAssessmentPreview:false});
    }


    uploadImage(files){
        let formData = new FormData();
        for (let file of files) {
            formData.append("files", file);            
        }
        this.props.uploadFiles(formData).then((resp)=>{
            let results = resp.upload_results;
            for (let result of results) {
                store.dispatch(arrayPush(this.props.form, 'media', {
                    url: result.url,
                    guid: result.guid,
                    media_type: 1,
                }));
            }
        }).catch((error)=>{
        });
    }

    onVideoUploadCompleted(result){
        Utils.showSuccessAlert("Uploaded Successfully");
        store.dispatch(arrayPush(this.props.form, 'media', {
            guid: result.guid,
            media_type: 2,
            thumbnail: result.thumbnail
        }));
    }

    deleteMedia(index) {
        store.dispatch(arrayRemove(this.props.form, 'media', index));
    }

    onVideoUploadFailed() {
        Utils.showErrorAlert("Upload failed! Please try again.");
    }

    closePreview() {
        this.setState({
            showMediaPreview: false,
        });
    }

    previewMedia(media) {
        this.setState({
            showMediaPreview: true,
            imageIndex: this.props.images.indexOf(media),
        });
    }

    doSave(data) {
        let params = { ...data };
        params['package_version'] = 4;
        params['engagement_id'] = this.props.engagement_id;
        if(this.props.assessment_id) {
            params['assessment_id'] = this.props.assessment_id;
        }
        if (params.assessment_date) {
            params.assessment_date = Utils.formatServerDate(params.assessment_date);
        }
        this.props.updateAssessmentSummary(params).then((response) => {
            Utils.showSuccessAlert("Assessment has been successfully saved");
            this.props.finishEditing();
        }).catch((error) => {
        });
    }

    doCancel() {
        this.props.finishEditing();
    }


    render() {
        return Template.apply(this);
    }
}

ClientEngagementAssessment.propTypes = {
    finishEditing: PropTypes.func,
};
ClientEngagementAssessment.defaultProps = {
    finishEditing: () => { },
};

const selector = formValueSelector('ClientEngagementAssessmentForm');

function mapStateToProps(state, ownProps) {
    let initialValues = {};
    let engagement = state.engagement.engagementReducer.engagement;
    let assessment = state.engagement.engagementReducer.assessment;
    initialValues = {...assessment};
    if(initialValues.assessment_date !==  null && initialValues.assessment_date !==  ""){
        initialValues.assessment_date = moment(initialValues.assessment_date).minutes(0).seconds(0).milliseconds(0).toDate();
    }
    let media = selector(state, 'media');
    return {
        user: state.auth.authReducer.user,
        engagement: engagement,
        assessment: assessment,
        initialValues: initialValues,
        media: media,
        images: _.filter(media, (item) => { return item.media_type === 1; }),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
             cleanUp: EnagagementAction.engagementAssessmentInstanceCleanup,
            getEngagmentAssessmentSummary,
            updateAssessmentSummary,
            uploadFiles,
        },
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    let requiredFields = [
        'assessment_name',
        'assessment_note',
        'assessment_date'
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const ClientEngagementAssessmentForm = reduxForm(
    {
        form: 'ClientEngagementAssessmentForm',
        enableReinitialize: true,
        validate
    }
)(ClientEngagementAssessment);

const ClientEngagementAssessmentFormConnected = connect(
        mapStateToProps,mapDispatchToProps
    )(ClientEngagementAssessmentForm);
export default ClientEngagementAssessmentFormConnected;
