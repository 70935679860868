import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import partnerActionCreator from '../actions/partner_action';
import exerciseListAction from '../../exercise/actions/exercise_list';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getPartnerDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerInstanceFill(
				response.business_partner,
				response.partner_plans,
                response.partner_network_invitation_email
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPracticePartnerList= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/" + 6 + "/" + PACKAGE + "/practice_partner_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.practicePartnerListFill(
                response.data,
                response.paginate_info
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPracticePartnerNewPlanList= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/" + 6 + "/" + PACKAGE + "/practice_partner_new_plan_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.practicePartnerListFill(
                response.data,
                response.paginate_info
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getPracticePartnerListForReport= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/" + 6 + "/" + PACKAGE + "/practice_partner_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            // dispatch(partnerActionCreator.practicePartnerListFill(
            //     response.data,
            //     response.paginate_info
            // ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getAuthorList= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + 6 + "/" + PACKAGE + "/author_list";
        // const url = baseURL + "/" + 6 + "/" + PACKAGE + "/author_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.authorListFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const activatePartner= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_partner_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.practicePartnerListFill(
                response.data,
                response.paginate_info
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const addPartnerLibrary = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerInstanceFill(
                response.data,
            ));
            Utils.showSuccessAlert("Successfully added new content sharing library");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const updatePartnerLibraryName = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_name_update";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerInstanceFill(
                response.data,
            ));
            Utils.showSuccessAlert("Successfully updated name of content sharing library");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const deletePartnerLibrary = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_delete";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerInstanceFill(
                response.data,
            ));
            Utils.showSuccessAlert("Successfully deleted the content sharing library");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getAllExerciseList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/library_exercises_all";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(exerciseListAction.fillExerciseList(
                response['exercises'],
                response['paginate_info']
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPartnerTreatmentTemplateList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_treatment_templates_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const addPartnerTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_treatment_templates_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const removePartnerTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_treatment_templates_remove";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPartnerHabitTemplateList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_habit_templates_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const addPartnerHabitTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_habit_templates_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const removePartnerHabitTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_habit_templates_remove";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPartnerExerciseList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_exercises_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getPartnerTemplateList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_templates_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPartnerGoalList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_goals_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPartnerIntakeList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_intakes_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPartnerHealthDocumentList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_documents_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const addPartnerLibraryExercise = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_exercises_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryExerciseAdd(
                response.data,
            ));
            dispatch(exerciseListAction.removeExerciseList(
                response.data
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const removePartnerLibraryExercise = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_exercises_remove";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryExerciseRemove(
                response.data,
            ));
            dispatch(exerciseListAction.addExerciseList(
                response.data
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addPartnerLibraryTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_templates_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const removePartnerLibraryTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_templates_remove";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addPartnerLibraryGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_goals_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const removePartnerLibraryGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_goals_remove";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addPartnerLibraryIntake = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_intakes_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const removePartnerLibraryIntake = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_intakes_remove";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addPartnerLibraryHealthDocument = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_documents_add";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const removePartnerLibraryHealthDocument = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_documents_remove";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerActionCreator.partnerLibraryFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const searchPracticePartners  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_admin_search";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const invitePartner  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_invite";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully invited business partner");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const acceptPartnerRequest  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_request_accept";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("You have accepted your partner request. Please click on My Plans to upgrade to your desired plan.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const rejectPartnerRequest  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_request_reject";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("You have rejected your partner request");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updatePracticePartnerPlans  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_update_plans";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("List of available plans for your practice partner has been updated");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const sendMessagePartners  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_send_message";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addAllExercisesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_exercises_add_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeAllExercisesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_exercises_remove_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addAllGoalTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_goal_templates_add_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeAllGoalTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_goal_templates_remove_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addAllIntakesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_intakes_add_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeAllIntakesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_intakes_remove_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addAllWorkoutTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_workout_templates_add_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeAllWorkoutTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_workout_templates_remove_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addAllHabitTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_habit_templates_add_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeAllHabitTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_habit_templates_remove_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addAllTreatmentTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_treatment_templates_add_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeAllTreatmentTemplatesPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_treatment_templates_remove_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addAllHealthDocumentsPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_health_documents_add_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeAllHealthDocumentsPartnerLibrary  = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_library_health_documents_remove_all";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getAuthorList,
    addPartnerLibrary,
    deletePartnerLibrary,
    searchPracticePartners,
    invitePartner,
    getPartnerDetails,
    getPracticePartnerList,
    activatePartner,
    updatePartnerLibraryName,
    getAllExerciseList,
    getPartnerExerciseList,
    addPartnerLibraryExercise,
    removePartnerLibraryExercise,
    getPartnerTemplateList,
    addPartnerLibraryTemplate,
    removePartnerLibraryTemplate,
    getPartnerGoalList,
    addPartnerLibraryGoal,
    removePartnerLibraryGoal,
    getPartnerIntakeList,
    addPartnerLibraryIntake,
    removePartnerLibraryIntake,
    getPartnerHealthDocumentList,
    addPartnerLibraryHealthDocument,
    removePartnerLibraryHealthDocument,
    acceptPartnerRequest,
    rejectPartnerRequest,
    updatePracticePartnerPlans,
    getPartnerTreatmentTemplateList,
    addPartnerTreatmentTemplate,
    removePartnerTreatmentTemplate,
    getPartnerHabitTemplateList,
    addPartnerHabitTemplate,
    removePartnerHabitTemplate,
    sendMessagePartners,
    addAllExercisesPartnerLibrary,
    removeAllExercisesPartnerLibrary,
    addAllGoalTemplatesPartnerLibrary,
    removeAllGoalTemplatesPartnerLibrary,
    addAllIntakesPartnerLibrary,
    removeAllIntakesPartnerLibrary,
    addAllWorkoutTemplatesPartnerLibrary,
    removeAllWorkoutTemplatesPartnerLibrary,
    addAllHabitTemplatesPartnerLibrary,
    removeAllHabitTemplatesPartnerLibrary,
    addAllTreatmentTemplatesPartnerLibrary,
    removeAllTreatmentTemplatesPartnerLibrary,
    addAllHealthDocumentsPartnerLibrary,
    removeAllHealthDocumentsPartnerLibrary,

    getPracticePartnerListForReport,
    getPracticePartnerNewPlanList
};