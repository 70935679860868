// Action Creators
const clientListFill = (clients, paginate_info, group_count, support_multiple_gameplan, is_workfit_practice) => ({
    type: 'CLIENT_LIST_FILL',
    client_list:clients,
    paginate_info: paginate_info,
    group_count: group_count,
    support_multiple_gameplan: support_multiple_gameplan,
    is_workfit_practice: is_workfit_practice
});
const allClientListFill = (clients, paginate_info, group_count, support_multiple_gameplan, is_workfit_practice) => ({
    type: 'ALL_CLIENT_LIST_FILL',
    client_list:clients,
    paginate_info: paginate_info,
    group_count: group_count,
    support_multiple_gameplan: support_multiple_gameplan,
    is_workfit_practice: is_workfit_practice
});
const clientFilterFill = (client_selection_list, practitioner_list, consultant_list) => ({
    type: 'CLIENT_FILTER_FILL',
    client_selection_list:client_selection_list,
    practitioner_list: practitioner_list,
    consultant_list: consultant_list,
});

const clientListCleanup = () => ({
    type: 'CLIENT_LIST_CLEANUP',
});

export default {
    clientListFill,
    allClientListFill,
    clientFilterFill,
    clientListCleanup,
};
