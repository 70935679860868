import BaseComponent from '../../utils/common_components/basecomponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Template from "./templates/notifications";
import store, { history } from '../../redux/store';
import { getNotificationScheduleList, notificationScheduleDelete } from '../../redux/dhf/workfit/network/workfit_network';

class Notifications extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addNotification = this.addNotification.bind(this);
        this.editNotification = this.editNotification.bind(this);

    };
    
    componentWillMount() { 
        this.props.getNotificationScheduleList({
            app_version: "5.1.12",
            current_role_type: "practice_admin",
            package_version: "2",
            practice_id: 8860,
        });
    }

    addNotification() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/notification/add');
    }

    editNotification(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/notification/edit/' + id);
    }
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getNotificationScheduleList,
    },
    dispatch
    );
}

const NotificationsConnected = connect(mapStateToProps, mapDispatchToProps)(Notifications);
export default NotificationsConnected;