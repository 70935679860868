let initialState = {
    intake_list: [],
    intake: {},
    question: {},
};
export default function intake(state = initialState, action) {
    switch (action.type) {
        case "INTAKE_LIST_FILL":
            if (!action.intake_list){
                return state;
            }    
            return Object.assign({}, state, {
                intake_list: action.intake_list,
            });
        case "INTAKE_INSTANCE_FILL":
            if (!action.intake){
                return state;
            }    
            let selected_question = {};
            if (!_.isEmpty(state.question)){
                let new_questions = action.intake.questions;
                let questionMap = _.keyBy(new_questions, 'field_guid');        
                let existingGuid = state.question.field_guid;
                if (_.has(questionMap,existingGuid)){
                    selected_question = questionMap[existingGuid];
                }
            }
            return Object.assign({}, state, {
                intake: action.intake,
                question:selected_question
            });
        case "INTAKE_PUBLISH_INSTANCE_FILL":
            let publishTempIntake = _.cloneDeep(state.intake);
            publishTempIntake.published = !publishTempIntake.published;
            return Object.assign({}, state, {
                intake: publishTempIntake,
                questions: state.questions,
            });            
        case "INTAKE_QUESTION_FILL":
            if (!action.question){
                return state;
            }    
            return Object.assign({}, state, {
                question: action.question,
            });
        case "INTAKE_QUESTION_ADD":
            if (_.isEmpty(state.intake)){
                return state;
            }    
            let intake = _.cloneDeep(state.intake);
            let initial_data = { ...intake.initial_data };
            initial_data.name = "Sample Question - " + (intake.questions.length + 1);
            initial_data.field_guid = "G"+(new Date).getTime();
            initial_data.field_order = intake.questions.length + 1;
            initial_data.is_new = true;
            intake.questions.push(initial_data);
            return Object.assign({}, state, {
                intake: intake,
            });
        case "INTAKE_QUESTION_REMOVE":
            if (!action.question || action.question.is_new !== true){//is_new can also be undefined
                return state;
            }    
            let intake2 = _.cloneDeep(state.intake);
            intake2.questions = _.filter(intake2.questions, (item) => {
                if (item.field_guid === action.question.field_guid) {
                    return false;
                } else {
                    return true;
                }
            });
            return Object.assign({}, state, {
                intake: intake2,
                question: {}
            });
        case "INTAKE_QUESTION_REORDER":
            if (!action.questions){
                return state;
            }    
            let tempIntake = _.cloneDeep(state.intake);
            tempIntake.questions = action.questions;
            return Object.assign({}, state, {
                intake: tempIntake,
            });
        case "INTAKE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
