import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import { NavLink } from 'react-router-dom';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    return React.createElement('div', {
        'className': true,
        'style': {
            width: '95%',
            margin: '0 auto'
        }
    }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.goToDashboard
    }, '\n        Back\n    '), React.createElement('div', { 'className': 'splash-container w-60 margin-auto' }, React.createElement('div', {}, React.createElement('h2', { 'className': 'm-b-15 page-title' }, 'Unlock the full potential of the Gray Institute app'), React.createElement('div', { 'className': 'image-container splash-image' }, React.createElement(VimeoPlayer, {
        'splashVideo': true,
        'identifier': 'gomotive-wisita-player',
        'handleUploadProcessing': true,
        'videoList': [749531027],
        'onPlayerInitialized': player => {
            this.player = player;
        }
    })), React.createElement('div', { 'className': 'subtitle' }, 'Save time and energy by subscribing to our growing list of workout templates developed for you to quickly access in the mobile app. From localized concerns to sports performance all powered by the Gray Institute! '), React.createElement('div', { 'className': 'points' }, React.createElement('div', { 'className': 'workouts-block' }, React.createElement('div', { 'className': 'd-flex flex-align-center' }, React.createElement('p', { 'className': 'red-bold' }, '300+ '), React.createElement('p', { 'className': 'bold' }, 'Workout Templates')), React.createElement('p', {}, 'Workouts built from the PSTs of the Gray Institute courses to common adaptation variable goals.')), React.createElement('div', { 'className': 'gp-block' }, React.createElement('div', { 'className': 'd-flex flex-align-center' }, React.createElement('p', { 'className': 'red-bold' }, '20+ '), React.createElement('p', { 'className': 'bold' }, 'Game Plans Templates')), React.createElement('div', {}, 'Progressive linear based programs designed to plan out your clients success over time.\n                '), React.createElement('div', { 'className': 'exercises-block' }, React.createElement('div', { 'className': 'd-flex flex-align-center' }, React.createElement('p', { 'className': 'red-bold' }, '6000+ '), React.createElement('p', { 'className': 'bold' }, 'Exercises')))), React.createElement('div', { 'className': 'd-flex justify-center' }, React.createElement(Button, {
        'className': 'bg-success m-b-12 m-l-10',
        'onClick': this.openMobilePopup
    }, 'See available plans'))))));
}