import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/choose_user_modal.rt";
import Utils from '../../utils/utils';
import { getClientList } from '../../redux/engagement/network/client_list_network';
import {getPractitionerList} from '../../redux/practice/network/practice_practitioner';
import {getConsultantList} from '../../redux/practice/network/practice_consultant';
import ClientListAction from '../../redux/engagement/actions/client_list_creator';
import PractitionerListAction from '../../redux/practice/actions/practice_practitioner';
import ConsultantListAction from '../../redux/practice/actions/practice_consultant';
import Avatar from '../../utils/common_components/profile_picture';

class ChooseUserModal extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.optionRenderer = this.optionRenderer.bind(this);
        this.filterOptions = this.filterOptions.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.onSelectUser = this.onSelectUser.bind(this);
        this.doSend = this.doSend.bind(this);
        this.state = {selected_user:""};
    };

    componentWillUnmount() {
        if(this.props.user_type === "practitioner"){
            this.props.cleanUpPractitionerList();
        }else if(this.props.user_type === "client"){
             this.props.cleanUpClientList();
        }else if(this.props.user_type === "consultant"){
            this.props.cleanUpConsultantList();
        }
    }

    onSelectUser(user){
        if(user && user !== null){
            this.setState({selected_user:user});
        }else{
            this.setState({selected_user:""});
        }
    }

    doSend(){
        let selected_user = this.state.selected_user;
        if(!selected_user || selected_user === null ||  _.isEmpty(selected_user) || selected_user === ""){
            Utils.showWarningAlert("Please select a person first!");
        }else{
            this.props.sendBackData(selected_user);
        }
    }

    filterOptions(options, filter,currentValues){
        options = _.filter(options,(item)=>{
            if(!filter || filter === null || filter.length < 2 ){
                return false;
            }
            if(_.includes(item.name,filter) || _.includes(item.email,filter) ){
                return true;
            }else{
                return false;
            }
        });
        return options;
    }    
    optionRenderer(option){
        return(
            <div >
               <Avatar className="m-b-0 m-t-0 m-r-10"  user={option}  profile_type="circle"></Avatar> 
                <span className="m-l-10 f-12 m-b-0">{option.name} </span><i className="f-10 c-gray"> ({option.email})</i> 
            </div>
            );
    }    


    getOptions(input){
        return new Promise((resolve, reject) => {
            if(input.length < 2){
                 resolve({options:[]});
                 return;
            }
            if(this.props.user_type === "practitioner"){
                this.props.getPractitionerList({'category': 'active'}).then((response)=>{
                    let options = _.map(response.practitioner_list,(item)=>{
                        return {id:item.user.id,name:item.user.name,email:item.user.email};
                    });
                    resolve({options:options}); 
                }).catch((error)=>{
                    resolve({options:[]});
                });
            }else if (this.props.user_type === "consultant"){
                this.props.getConsultantList({'category': 'active'}).then((response)=>{
                    let options = _.map(response.consultant_list,(item)=>{
                        return {id:item.user.id,name:item.user.name,email:item.user.email};
                    });
                    resolve({options:options}); 
                }).catch((error)=>{
                    resolve({options:[]});
                });
            } else if(this.props.user_type === "client"){
                this.props.getClientList({'client_type': 'all',search:input}).then((response)=>{
                    resolve({options:response.clients}); 
                }).catch((error)=>{
                    resolve({options:[]});
                });
            }else{
                resolve({options:[]});
            }
        });
    }


    render() {
        return Template.apply(this);
    }

}

ChooseUserModal.propTypes = {
    handleClose:PropTypes.func.isRequired,    
    sendBackData:PropTypes.func.isRequired,    
    title:PropTypes.string.isRequired,
    user_type:PropTypes.string.isRequired,
    button_name:PropTypes.string,
    open:PropTypes.Boolean
};

ChooseUserModal.defaultProps = {
  button_name: 'Add',
  open:false
};

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        clientList: state.engagement.clientListReducer.client_list,
        practitionerList: state.practice.practicePractitioner.practitionerList,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getClientList,
            getPractitionerList,
            getConsultantList,
            cleanUpClientList: ClientListAction.clientListCleanup,
            cleanUpPractitionerList: PractitionerListAction.cleanModule,
            cleanUpConsultantList: ConsultantListAction.cleanModule,
        }, 
        dispatch
    );
}

const ChooseUserModalConnected = connect(mapStateToProps,mapDispatchToProps)(ChooseUserModal);
export default ChooseUserModalConnected;
