import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import practitionerActions from '../actions/practitioner_actions';


const getPractitionerHomeAlerts = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practitioner_home";
        return Axios.post(url, params).then((response) => {
            dispatch(practitionerActions.practitionerHomeAlertsFill(
                response['unread_message_count'],
                response['unread_chat_list'],                
                response['alert_count'],
                response['metric_settings'],
                response['practitioner_welcome_video_url'],
                response['business_partner']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const listPractitioner = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = '/' + API_VERSION + '/' + PACKAGE + '/practitioner_list';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          dispatch(
            practitionerActions.fillPractitionerList(
              response['practitioners']
            )
          );
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
        });
    };
  };


export {
    getPractitionerHomeAlerts,
    listPractitioner
};