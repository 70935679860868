import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Select from '../../../utils/common_components/select';
import { FieldArray } from 'redux-form';
import Switch from '../../../utils/common_components/switch_form';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import Dropzone from '../../../utils/common_components/dropzone';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Lightbox from 'react-image-lightbox';
import MAT from '../../../mat/components/mat_configure';
import DHFAssessment from '../../../dhf/assess/components/assessment_list';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
export default function () {
    function repeatMedia1(media, index) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5',
            'key': 'media' + index
        }, media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225 pointer',
            'style': { backgroundImage: 'url(' + encodeURI(media.url) + ')' },
            'onClick': () => {
                this.previewMedia(media);
            },
            'key': '5402'
        }) : null, media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225 pointer',
            'key': '5645'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'gomotive_video' + media.guid,
            'videoList': [media.guid],
            'handleUploadProcessing': true
        })) : null, React.createElement('div', { 'className': 'bg-white row end-xs p-r-5' }, React.createElement(IconButton, {
            'tooltip': 'Delete the uploaded media file',
            'icon': 'delete_forever',
            'className': 'c-black',
            'onClick': () => {
                this.deleteMedia(index);
            }
        })));
    }
    return React.createElement('div', { 'className': 'row m-t-5 m-b-30' }, React.createElement(Card, { 'className': 'w-100' }, React.createElement(CardTitle, {
        'title': 'Assess ' + this.props.user.name,
        'className': 'm-l-15 p-b-5 c-black'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {
        'className': 'col-xs-12 no-box-shadow bg-white',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row assess-edit' }, React.createElement('div', { 'className': this.props.pristine ? 'form-field--is-filled col-xs-12 p-0 m-b-15 border-around-search p-relative' : 'col-xs-12 p-0 m-b-15 border-around-search p-relative' }, React.createElement('span', { 'className': 'placeholder-text' }, 'Assessment Title'), React.createElement(Field, {
        'name': 'assessment_name',
        'component': TextInput,
        'onClick': this.inputClick
    })), React.createElement('div', { 'className': this.props.pristine ? 'form-field--is-filled col-xs-12 m-b-15 border-around-search p-relative' : 'col-xs-12 m-b-15 border-around-search p-relative form-field--is-filled' }, React.createElement('span', { 'className': 'placeholder-text' }, 'Assessment Date'), React.createElement(Field, {
        'name': 'assessment_date',
        'component': DatePicker,
        'onClick': this.inputClick
    })), this.props.assessment_id === null || this.props.initialValues.assessment_note ? React.createElement('div', {
        'className': 'col-xs-12 p-0 m-b-10',
        'key': '3064'
    }, React.createElement(Field, {
        'label': 'Notes (write client observations on medical and physical condition here)',
        'name': 'assessment_note',
        'rows': 10,
        'component': RichTextEditor,
        'multiline': true
    })) : null, this.props.assessment.assessment_mode === 3 ? React.createElement('div', {
        'className': 'col-xs-12 m-b-10',
        'key': '3546'
    }, React.createElement('label', { 'className': 'material-label m-b-10' }, 'MAT Assessement Details'), React.createElement(Button, {
        'onClick': this.showMatAssessmentPreview,
        'icon': 'assessment',
        'label': 'View MAT Assessment Details',
        'primary': true,
        'raised': true
    })) : null, this.props.assessment.assessment_mode === 4 ? React.createElement('div', {
        'className': 'col-xs-12 m-b-10',
        'key': '3916'
    }, React.createElement('label', { 'className': 'material-label m-b-10' }, 'DHF Assessement Details'), React.createElement(Button, {
        'onClick': this.showDhfAssessmentPreview,
        'icon': 'assessment',
        'label': 'View D.M.S Details',
        'primary': true,
        'raised': true
    })) : null, React.createElement('div', { 'className': 'col-xs-12 start-xs m-t-10' }, React.createElement('label', { 'className': 'material-label m-b-10' }, 'Uploads'), React.createElement('div', { 'className': 'row' }, React.createElement(Dropzone, {
        'className': 'col m-l-4',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadImage
    }, React.createElement(Button, {
        'label': 'Upload Assessment Images',
        'primary': true,
        'raised': true
    })), React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'buttonText': 'Upload Assessment Videos',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed
    }))), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        _.map(this.props.media, repeatMedia1.bind(this))
    ])), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, '\n                        NOTE: you may upload your own or third party assessments from Spark Motion, Hudl, Motus, Camtasia, Krossover, Qualisys, Dartfish, or any system that outputs pictures and/or videos.\n                    '), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'bg-success',
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.doCancel,
        'label': 'Cancel',
        'className': 'bg-success-border m-l-5',
        'type': 'button',
        'raised': true
    })))))), this.state.showMediaPreview ? React.createElement(Lightbox, {
        'mainSrc': this.props.images[this.state.imageIndex].url,
        'onCloseRequest': () => this.setState({ showMediaPreview: false }),
        'key': '7061'
    }) : null, React.createElement(Dialog, {
        'active': this.state.showMatAssessmentPreview,
        'className': 'full-dialog bg-beige',
        'onEscKeyDown': this.hideMatAssessmentPreview,
        'onOverlayClick': this.hideMatAssessmentPreview
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-20px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.hideMatAssessmentPreview
    })), this.state.showMatAssessmentPreview && this.props.assessment.assessment_mode === 3 ? React.createElement(MAT, {
        'previewMode': true,
        'mat_form': this.props.assessment.assessment_json,
        'key': '7774'
    }) : null), React.createElement(Dialog, {
        'active': this.state.showDhfAssessmentPreview,
        'className': 'full-dialog bg-beige',
        'onEscKeyDown': this.hideDhfAssessmentPreview,
        'onOverlayClick': this.hideDhfAssessmentPreview
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-20px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.hideDhfAssessmentPreview
    })), this.state.showDhfAssessmentPreview && this.props.assessment.assessment_mode === 4 ? React.createElement(DHFAssessment, {
        'previewMode': true,
        'dhf_form': this.props.assessment.assessment_json,
        'key': '8488'
    }) : null));
}