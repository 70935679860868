import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import roleActionCreator from '../../auth/actions/role_creator';

const practicePost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_post";
        return Axios.post(url, params).then((response) => {
            localStorage.setItem('selectedRole',response['roles'][response['roles'].length -1].id);
            dispatch(roleActionCreator.chooseRole(response['roles'][response['roles'].length -1]));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Practice Successfully Created");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    practicePost
};