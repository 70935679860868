let initialState = {
    enroll_clients: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "ENROLL_CLIENTS_FILL":
            if (!action.enroll_clients){
                return state;
            }    
            return Object.assign({}, state, {
                enroll_clients: action.enroll_clients
            });
        case "ENROLL_CLIENTS_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
