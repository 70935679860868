import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import {
  showProgress,
  hideProgress
} from '../../core/actions/progress_creator';
import coursesListAction from '../actions/courses_list';
import coursesAction from '../actions/courses';
import exerciseAction from '../../content/actions/exercise';

const listCourses = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
       const url = '/' + API_VERSION + '/courses/course_list';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          dispatch(
            coursesListAction.fillCoursesList(
              response['courses_list'],
            )
          );
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
        });
    };
  };
  const getCoursesCategory = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
       const url = '/' + API_VERSION + '/' + PACKAGE + '/course_categories';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          dispatch(
            coursesListAction.categoryFill(
              response['news_categories']
            )
          );
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
        });
    };
  };
  const contentSearch = (params) => {

    return (dispatch) => {
      const url =
        '/' + API_VERSION + '/' + PACKAGE + '/content_search';
      return Axios.post(url, params)
        .then(async (response) => {
  
          let first = false;
          if (params.first) {
            first = true;
          }
          dispatch(
            coursesListAction.fillCoursesFilterData(
              response['content'],
              first
            )
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  
  const getAssociatedContent = (params) => {
    
    return (dispatch) => {
      const url =
        '/' + API_VERSION + '/' + PACKAGE + '/program_search';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(
            coursesListAction.fillAssociatedContentList(
              response['program'],
            )
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const getCourse = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = '/' + API_VERSION + '/courses/course_get';
      return Axios.post(url, params)
        .then(async (response) => {
  
          dispatch(hideProgress());
          dispatch(
            coursesAction.fillCourses(
              response['course'],
              // response['supporting_data']
            )
          );
           }) 
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const postCourse = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = '/' + API_VERSION + '/courses/course_post';
      return Axios.post(url, params)
        .then(async (response) => {

          dispatch(hideProgress());
          Utils.showSuccessAlert(` ${params.course_type} Successfully Saved`);
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };

  const uploadMedia = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = '/' + API_VERSION + '/' + PACKAGE + '/exercise_media_upload';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          Utils.showSuccessAlert('Successfully Uploaded');
          return response;
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  const deleteCourse = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
      const url = '/' + API_VERSION + '/' + PACKAGE + '/course_delete';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(coursesListAction.removeCoursesList(params));
          dispatch(hideProgress());
          return response;
        }).then(()=>{
          Utils.showSuccessAlert('Course has been deleted');
          this.listCourses();  
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            Utils.showErrorAlert(error.response.data.reason);
          }
          throw error;
        });
    };
  };
  export {
    listCourses,
    deleteCourse,
    getCoursesCategory,
    getAssociatedContent,
    contentSearch,
    getCourse,
    postCourse,
    uploadMedia
  };