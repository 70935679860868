import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/goal_list";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { getGoalList, changePartnerGoalVisibilityStatus, deleteGoal } from '../../redux/goal/network/goal_network';
import swal from 'sweetalert2';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { ENGAGEMENT_GOAL_VIDEO } from '../../utils/help_video';

class GoalList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addGoal = this.addGoal.bind(this);
        this.editGoal = this.editGoal.bind(this);
        this.changeVisibilityStatus = this.changeVisibilityStatus.bind(this);
        this.deleteGoal = this.deleteGoal.bind(this);
    };

    componentWillMount() {
        this.props.getGoalList({
            fetch_type: "manage"
        });
    }

    componentDidMount() {
        this.props.updateHelpVideo(ENGAGEMENT_GOAL_VIDEO);
    }
    
    componentWillUnmount() {
        this.props.clearHelpVideo();
    }

    addGoal() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/goal/add');
    }

    deleteGoal(id) {
        let that = this;
        swal.fire({
            title: "Confirm",
            text: "Are you sure to delete this goal?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes",
            useRejections:true
        }).then((result) => {
            if(result.value) {
                that.props.deleteGoal({ id: id }).then(() => {
                    that.props.getGoalList({
                        fetch_type: "manage"
                    });
                }).catch(() => { });
            } else {
                return
            }
        }, () => {
        });
    }

    editGoal(goal) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/goal/edit/' + goal.id);
    }

    changeVisibilityStatus(goal, visibility) {
        this.props.changePartnerGoalVisibilityStatus({
            goal_id: goal.id,
            visibility: visibility
        }).then((response) => {
            this.props.getGoalList({
                fetch_type: "manage"
            });
        }).catch((error) => {
            //do nothing
        });  ;
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        goals: state.goal.goalReducer.goal_list,
        hidden_goals: state.goal.goalReducer.hidden_goals,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGoalList,
        deleteGoal,
        changePartnerGoalVisibilityStatus,
        updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearHelpVideo: HelpVideoCreator.clearHelpVideo,
    },
        dispatch
    );
}

const GoalListConnected = connect(mapStateToProps, mapDispatchToProps)(GoalList);
export default GoalListConnected;