import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import SubscriptionAction from '../actions/subscription_action';

const baseURL = '//' + window.location.host.replace('gi', 'dhf') + '/api/' + Utils.getDevice();
const getSubscriptionNameList = (params, type) => {
    return (dispatch) => {
        dispatch(showProgress());
        let url = "";
        if (type === "coaches") {
            url = "/6/partner/subscription_plan_list"
        } else {
            url = "/6/dhfmove/dhf_move_plan_list";
        };
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(SubscriptionAction.subscriptionListFill(
                response['data'],
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};
const getHomefitSubscriptionLog = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/6/dhfmove/dhfmove_webhook_record";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(SubscriptionAction.homefitSubscriptionLogFill(
                response['data'],
                response['paginate_info'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};
const getGISubscriptionLog = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_partner_webhook_record";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(SubscriptionAction.GISubscriptionLogFill(
                response['data'],
                response['paginate_info'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};


export {
    getHomefitSubscriptionLog,
    getGISubscriptionLog,
    getSubscriptionNameList
};