import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/quickstart_acquired_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../redux/client/actions/quickstart_actions';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import { setQuickStartDate } from "../../redux/client/network/quickstart_network";

class QuickStartAcuiredList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.gotoQuickStartView = this.gotoQuickStartView.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.hideDatePicker = this.hideDatePicker.bind(this);
        this.finishDateSelection = this.finishDateSelection.bind(this);
        this.state = {
            datePickerVisible: false,
            selectedQuickStart: null,
        };
    }

    gotoQuickStartView(quickstart) {
        if (!quickstart.latest_version.start_date) {
            this.setState({
                datePickerVisible: true,
                selectedQuickStart: quickstart,
            });
        } else {
            history.push('/client/quickstart_track/' + quickstart.id);
        }
    }

    hideDatePicker() {
        this.setState({
            datePickerVisible: false,
            selectedQuickStart: null,
        });
    }

    finishDateSelection() {
        this.props.setQuickStartDate({
            workout_id: this.state.selectedQuickStart.id,
            start_date: Utils.formatServerDate(this.state.selectedDate)
        }).then(() => {
            history.push('/client/quickstart_track/' + this.state.selectedQuickStart.id);
        }).catch(() => { });
    }

    onDateChanged(date) {
        this.setState({
            selectedDate: date,
        });
    }

    render() {
        return Template.apply(this);
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setQuickStartDate
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        acquiredQuickStarts: state.client.quickStartReducer.acquiredQuickStarts,
        ...ownProps,
    };
}

const QuickStartAcuiredListConnected = connect(mapStateToProps, mapDispatchToProps)(QuickStartAcuiredList);
export default QuickStartAcuiredListConnected;
