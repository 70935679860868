import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_edit";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import Swal from 'sweetalert2'
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../redux/store';
import { reduxForm, formValueSelector } from 'redux-form';
import { groupClassSchedulerPost, groupClassSchedulerGet } from '../../redux/calendar/network/group_class_network';
import moment from 'moment';

class GroupClassEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveGroupClass = this.saveGroupClass.bind(this);
        this.closeScreen = this.closeScreen.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onDraft = this.onDraft.bind(this);        
        this.addGroupClassFrequency = this.addGroupClassFrequency.bind(this);        
        this.deleteGroupClassFrequency = this.deleteGroupClassFrequency.bind(this);        

        this.state = {
            'publish': false,
            'booking_type': [
                // {
                //     'id': 1,
                //     'name': 'Allow booking for entire series only'
                // },                
                {
                    'id': 2,
                    'name': 'Allow booking for specific sessions'
                }
            ],
            'day_of_week_list': [
                {
                    value: 6,
                    label: 'Sunday'
                },
                {
                    value: 0,
                    label: 'Monday'
                },
                {
                    value: 1,
                    label: 'Tuesday'
                },
                {
                    value: 2,
                    label: 'Wednesday'
                },
                {
                    value: 3,
                    label: 'Thursday'
                },
                {
                    value: 4,
                    label: 'Friday'
                },
                {
                    value: 5,
                    label: 'Saturday'
                }
            ]
        };
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    saveGroupClass(data) {
        if(this.props.groupClassObject.client_booking_count > 0) {
            let that = this;
            Swal.fire({
                title: "Are you sure?",
                text: "As you are editing a group class that has client bookings, all future client bookings will be deleted. Kindly ask the client to book once more",
                type: "warning",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections:true
            }).then(() => {
                if(data.group_class_frequency.length === 0){
                    Utils.showErrorAlert("Group Classes needs class timings!");
                    return;
                }
    
                let params =  _.cloneDeep(data);
                params.start_date = moment(params.start_date).format("YYYY-MM-DD");
                params.end_date = moment(params.end_date).format("YYYY-MM-DD");
                params.is_publish = this.state.publish;
                params.group_class_frequency = _.map(params.group_class_frequency, function (item) {
                    item.start_time  =  moment(item.start_time).format("HH:mm:ss.SSS");
                    item.end_time  =  moment(item.end_time).format("HH:mm:ss.SSS");
                    return item;
                }); 
                if(this.props.match.params.group_class_id) {
                    params['group_class_id'] = this.props.match.params.group_class_id;
                }      
                this.props.groupClassSchedulerPost({ 
                    ...params,
                    app_version: "5.2",
                    current_role_type: "practitioner",
                    package_version: "1",
                    "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id   })
                .then((response) => {
                    let params = {};
                    if(!this.props.match.params.group_class_id) {
                        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/group_class/edit/"+response.group_class.id);
                        params["group_class_id"] = response.group_class.id;
                    } else {
                        if(this.props.match.params.group_class_id) {
                            params["group_class_id"] = this.props.match.params.group_class_id;
                        }       
                    }   
                    this.props.groupClassSchedulerGet({ 
                        ...params,
                        app_version: "5.2",
                        current_role_type: "practitioner",
                        package_version: "1",
                        "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id   })
                    .then((response) => {  
                        this.props.moveToReviewAndPublish();
                    })
                    .catch((error) => { 
                    });                    
                })
                .catch((error) => { 
    
                });
            }, ()=> { });
        } else {
            if(data.group_class_frequency.length === 0){
                Utils.showErrorAlert("Group Classes needs class timings!");
                return;
            }

            let params =  _.cloneDeep(data);
            params.start_date = moment(params.start_date).format("YYYY-MM-DD");
            params.end_date = moment(params.end_date).format("YYYY-MM-DD");
            params.is_publish = this.state.publish;
            params.group_class_frequency = _.map(params.group_class_frequency, function (item) {
                item.start_time  =  moment(item.start_time).format("HH:mm:ss.SSS");
                item.end_time  =  moment(item.end_time).format("HH:mm:ss.SSS");
                return item;
            }); 
            if(this.props.match.params.group_class_id) {
                params['group_class_id'] = this.props.match.params.group_class_id;
            }      
            this.props.groupClassSchedulerPost({ 
                ...params,
                app_version: "5.2",
                current_role_type: "practitioner",
                package_version: "1",
                "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id   })
            .then((response) => {
                let params = {};
                if(!this.props.match.params.group_class_id) {
                    history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/group_class/edit/"+response.group_class.id);
                    params["group_class_id"] = response.group_class.id;
                } else {
                    if(this.props.match.params.group_class_id) {
                        params["group_class_id"] = this.props.match.params.group_class_id;
                    }       
                }   
                this.props.groupClassSchedulerGet({ 
                    ...params,
                    app_version: "5.2",
                    current_role_type: "practitioner",
                    package_version: "1",
                    "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id   })
                .then((response) => {  
                    this.props.moveToReviewAndPublish();
                })
                .catch((error) => { 
                });                    
            })
            .catch((error) => { 

            });
        }
    }

    onPublish() { 
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.saveGroupClass)();
        });
       
    }
    onDraft() {  
        this.setState({ publish: false }, () => {
            this.props.handleSubmit(this.saveGroupClass)();
        });
    }

    addGroupClassFrequency(){
        let currentFrequencies = [...this.props.group_class_frequency];
        currentFrequencies.push({day:null,start_time:undefined,end_time:undefined});
        this.props.change('group_class_frequency',currentFrequencies);
    }

    deleteGroupClassFrequency(index){
        let currentFrequencies = [...this.props.group_class_frequency];
        currentFrequencies.splice(index, 1);
        this.props.change('group_class_frequency',currentFrequencies);
    }


    closeScreen(){
        history.goBack();
    }

    render() {
        return Template.apply(this);
    }
}

const selector = formValueSelector('GroupClassEditForm');

function mapStateToProps(state, ownProps) {
    let groupClassObject = _.cloneDeep(state.calendar.groupClassReducer.group_class); 
    groupClassObject.start_date =  Utils.parseServerDate(groupClassObject.start_date);
    groupClassObject.end_date = Utils.parseServerDate(groupClassObject.end_date);
    if(groupClassObject.group_class_frequency &&  groupClassObject.group_class_frequency.length === 0){
        groupClassObject.group_class_frequency.push({day:null,start_time:undefined,end_time:undefined});
    }else{
        groupClassObject.group_class_frequency = _.map(groupClassObject.group_class_frequency, function (item) {
            let pickedItem = _.pick(item,["start_time","end_time","day"]);
            pickedItem.start_time  = moment(pickedItem.start_time,"hh:mm:ss").toDate();
            pickedItem.end_time  = moment(pickedItem.end_time,"hh:mm:ss").toDate();
            return pickedItem;
        });
    }

    let practitioner_id_list = [];
    _.map(groupClassObject.practitioners, function (item) {
        practitioner_id_list.push(item.id);
    });
    groupClassObject.practitioners = practitioner_id_list;

    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: groupClassObject,
        groupClassObject:groupClassObject,
        group_class_frequency: selector(state, 'group_class_frequency'),
        selected_day_of_week: selector(state, 'day_of_week'),
        practitioners: state.calendar.groupClassReducer.practitioners,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        groupClassSchedulerPost,
        groupClassSchedulerGet
    }, dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description',
        'max_client_count',
        'booking_type',
        'start_date',
        'end_date',        
        'practitioners',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if(values.group_class_frequency){
        const timingErrorList = [];
        values.group_class_frequency.forEach((timing, index) => {
            const timingErrors = {};
            if(timing.day === "" || timing.day === null ){
                timingErrors.day = "Required";
            }
            if(!timing.start_time || timing.start_time === "" || timing.start_time === null ){
                timingErrors.start_time = "Required";
            }else  if(!timing.end_time || timing.end_time === "" || timing.end_time === null ){
                timingErrors.end_time = "Required";
            }else{
                let orignal_start = moment(timing.start_time);
                let orignal_end = moment(timing.end_time);
                let start_date = moment();
                start_date.hours(orignal_start.hour());
                start_date.minutes(orignal_start.minute());
                start_date.seconds(0);
                start_date.milliseconds(0);
                let end_date = moment();
                end_date.hours(orignal_end.hour());
                end_date.minutes(orignal_end.minute());
                end_date.seconds(0);
                end_date.millisecond(0);
                if(start_date >= end_date){
                    timingErrors.end_time = "End time has to be later than start time";
                }
            }
            timingErrorList[index] = timingErrors;
        });  
        errors["group_class_frequency"] = timingErrorList;
    }
    return errors;
};

const GroupClassEditForm = reduxForm({
        form: 'GroupClassEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupClassEdit);


const GroupClassEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassEditForm);
export default GroupClassEditFormConnected;