import axios from 'axios';
import Utils from '../utils';
import * as $ from 'jquery';
import authActions from '../../redux/auth/actions/auth_creator';
import webstore,{history} from '../../redux/store';
import {APP_VERSION,API_VERSION} from '../../core/constants/constants';

let DEVICE = Utils.getDevice();

const baseURL = "//" + window.location.host + "/api/" + Utils.getDevice();
const commonParams = {};
//const baseURL = "http://localhost/api/" + Utils.getDevice();

let Axios =  axios.create({
    baseURL: baseURL,
    timeout: 1200000,
    headers: {
        // 'USER_AGENT': navigator.userAgent,
        'Accept': "application/json",
        'Content-type': "application/json",
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Authorization': ''
    }
});


const setParam = async (name, value) => {
    commonParams[name] = value;
};

const clearParam = async (name) => {
    delete commonParams[name];
};



Axios.interceptors.request.use((config) => {  
    // if(! _.includes(config.url,window.location.host) ){
    //     let token = Utils.getLocalStorageValue("token");
    //     config.headers.Authorization = token;
    //     console.log("reached here", config)
    //     return config;
    // }
    let update_url = config.url;
    let update_url_list = update_url.split("web/");
    let second_segment = update_url_list[1];
    let token = Utils.getLocalStorageValue("token");
    config.headers.Authorization = token;
    let data = {}; 
    if(config.data.package_version){
        data.package_version = config.data.package_version
    } else if(update_url.split("/")[3] ===  'file_upload' || update_url.split("/")[3] ===  'document_attachment_upload'){
        data.package_version = 1;
    } else if(update_url.split("/")[3] ===  'program_search_params_get' ){
        data.package_version = 3;
    }
    else {
        data.package_version = 2;
    }
    data.app_version = APP_VERSION;
    data.current_role_type = "";
    let store = webstore.getState();
    let selectedRole = store.auth.roleReducer.selectedRole;
    if (!_.isEmpty(selectedRole)){
        data.current_role_type = selectedRole.role.name;
    }
    
    if(!_.isEmpty(selectedRole) && (selectedRole.role.name === "practitioner" 
    || selectedRole.role.name === "practice_admin")){

        if(!config.data.practice_id && config.data.allClient){
            return config
        }else if(!config.data.practice_id){
            // if(!update_url.split("/")[3] === 'login'){
            //     console.log('login url hai')
                data.practice_id = selectedRole.practice.id;
            // }
        }
    }
    if(Object.prototype.toString.call(config.data) === "[object FormData]"){
            for(let key in data){
                config.data.append(key, data[key]);
            }
            for(let key in commonParams){
                config.data.append(key, commonParams[key]);
            }
    } else {
        config.data = {...config.data,...data,...commonParams};
    }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });    

// Add a response interceptor
Axios.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    // if (!error.response) {
    //     Utils.showErrorAlert("Network Error: Please check your internet connection & retry!");
    // }else 
    if (error.response) {
        if (parseInt(error.response.status) === 401) {
            Utils.removeLocalStorageValue("token");
            localStorage.removeItem("selectedRole");
            webstore.dispatch(authActions.doLogout());
            history.push('/auth/login');
            window.location.reload();
        }else if(parseInt(error.response.status) === 459){
            Utils.showInfoAlert("An updated web app is available. Reloading page to make your web experience better!");
            setTimeout(()=>{
                window.location.reload(true);
            },3000);
        }
        else if (parseInt(error.response.status) === 500) {
            Utils.showErrorAlert(error.response.data.error_message);
        }
    }
    // Do something with response error   
    return Promise.reject(error);
    });


export {setParam,clearParam};
export default Axios;
