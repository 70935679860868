import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/treatment_template_edit";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';
import { getTreatmentTemplate, saveTreatmentTemplate, } from '../../redux/treatment_template/network/treatment_template_network';
import TreatmentTemplateActionCreator from '../../redux/treatment_template/actions/treatment_template_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';

class TreatmentTemplateEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteLogo = this.deleteLogo.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.state = {
            publish: false,
            treatment_cycle:[
                {value:1,label:'Scheduled'},
                {value:2,label:'Ongoing'},
            ],            
        };
    };

    componentWillMount() {
        this.props.getTreatmentTemplate({ id: this.props.match.params.treatment_template_id });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    save(publish) {
        this.setState({
            publish: publish
        }, () => {
            this.props.handleSubmit(this.onSubmit)();
        });
    }

    onSubmit(data) {
        let params = _.omit(data, ['can_be_deleted']);
        params = Utils.flatternFormData(params);
        if(!_.has(data,"id")){
            if(this.props.match.params.treatment_type === "group"){
                params.treatment_template_type = 2;
            }else if(this.props.match.params.treatment_type === "gameplan"){
                params.treatment_template_type = 1;
            }
        }
        this.props.saveTreatmentTemplate(params).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type+'/dashboard/'+response.treatment_template.id);
        }).catch(() => {
        });
    }

    uploadLogo(files) {
        let formData = new FormData();
        formData.append('guid', this.props.guid);
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('logo_url', result.url);
            this.props.change('is_logo_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
        }).catch((error) => {
        });
    }

    deleteLogo() {
        this.props.change('is_logo_uploaded', false);
    }

    cancel() {
        history.goBack();
    }



    render() {
        if (_.isEmpty(this.props.treatment_template)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('treatmentTemplateEditForm');
function mapStateToProps(state) {
    let treatment_template = {...state.treatment_template.treatmentTemplateReducer.treatment_template};
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        treatment_template: treatment_template,
        supporting_data: state.treatment_template.treatmentTemplateReducer.treatment_template_supporting_data,
        initialValues: treatment_template,
        is_logo_uploaded: selector(state, 'is_logo_uploaded'),
        logo_url: selector(state, 'logo_url'),
        guid: selector(state, 'guid'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTreatmentTemplate,
        saveTreatmentTemplate,
        cleanUp: TreatmentTemplateActionCreator.treatmentTemplateCleanup,
        uploadFiles,
    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const TreatmentTemplateEditForm = reduxForm(
    {
        form: 'treatmentTemplateEditForm',
        enableReinitialize: true,
        validate
    }
)(TreatmentTemplateEdit);
const TreatmentTemplateEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(TreatmentTemplateEditForm);
export default TreatmentTemplateEditFormConnected;
