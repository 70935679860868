import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatLocation1(location, locationIndex) {
        return React.createElement('div', {
            'className': 'w-100px box-shadow bg-white m-l-10 m-r-10 m-t-10',
            'key': 'location' + location.id
        }, React.createElement('div', {
            'className': 'row bg-white p-5 middle-xs',
            'style': {
                width: '250px',
                height: '100px'
            }
        }, React.createElement('div', { 'className': 'col-xs-12 f-18' }, '\n                    ', location.name, '\n                '), React.createElement('div', { 'className': 'col-xs-12 f-12' }, '\n                    ', location.city, ', ', location.zipcode, '\n                ')), React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12 bg-lightgray p-5 m-0' }, React.createElement(IconButton, {
            'tooltip': 'Edit your location details',
            'icon': 'create',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => this.edit(location.id)
        }), location.can_be_deleted === true ? React.createElement(IconButton, {
            'tooltip': 'Delete this location of your practice',
            'icon': 'delete_forever',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => this.delete(location.id),
            'key': '2696'
        }) : null)));
    }
    return React.createElement('div', {}, React.createElement('div', {}, React.createElement(Button, {
        'className': 'floating-add-button',
        'style': { top: '110px' },
        'onClick': this.add,
        'icon': 'add',
        'floating': true,
        'ripple': true
    })), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.practiceLocations, repeatLocation1.bind(this))
    ]));
}