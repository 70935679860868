import { contains } from "jquery";

let initialState = {
    courseList: [],
    paginateInfo: {
        page: 0,
    },
    categories: [],
    filterData:{},
    searchPreferences:{},
    course: {},
    filterCourseList: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "COURSES_CATEGORY_FILL":
            return Object.assign({}, state, {
                categories: action.categories,
            });

        case "COURSES_FILL":
            
            let guid = "";
            let thumbnail_url = "";
            if(action.course && Object.keys(action.course).length) {
                if(action.course.courses_media && action.course.courses_media.length) {     
                    if(action.course.courses_media[0].video_url){      
                        const path = action.course.courses_media[0].video_url.split('/');
                        guid = action.course.courses_media[0].url;
                        thumbnail_url = action.course.courses_media[0].thumbnail_url;
                    }
                }
            }

            return Object.assign({}, state, {
                course: { 
                    ...action.course,
                    video_thumbnail_url : thumbnail_url,
                    video_guid : guid,
                } ,
                

            });
        case "COURSES_LIST_REMOVE":
                let newCourseList = _.filter(state.courseList, function(courseObj) {
                    if(courseObj.id !== action.course.id) {
                        return true;
                    } else {
                        return false;
                    }
                });
                return Object.assign({}, state, {
                    courseList: newCourseList,
                    paginateInfo: state.paginateInfo
                });
        case "COURSES_CLEANUP":
            return Object.assign({}, initialState);

        case "COURSES_LIST_FILL":
            if(!action.courseList) {
                return state;
            }
            let courseContent = [];
            if(action.paginateInfo && action.paginateInfo.page ===0 ){
                courseContent = action.courseList;
            }else{
                let newIds = _.map(action.courseList, (item) => { return item.id; });
                let oldRetain = _.filter(state.courseList, (item) => { return newIds.indexOf(item.id) === -1; });
                courseContent = oldRetain.concat(action.courseList);
            }

            // let courseContents =[]
            // courseContent.forEach(cont => {
            //         courseContents[cont.id] =  cont
            // })

            return  Object.assign({}, state, {
                courseList: courseContent,
                paginateInfo: action.paginateInfo
            });


        case "COURSES_FILTER_DATA_FILL":
            if(!action.filterCourseList) {
                return state;
            }        
            if(action.first){
                return Object.assign({}, state, {
                    courseList: action.filterCourseList,
                    // searchPreferences:{...action.filterData.search_params}
                });
            }else{
                return Object.assign({}, state, {
                    courseList: action.filterCourseList
                });
            }

            case "ASSOCIATED_CONTENT_DATA_FILL":
            if(!action.associatedContentList) {
                return state;
            }        
            if(action.first){
                return Object.assign({}, state, {
                    associatedContentList: action.associatedContentList,
                    // searchPreferences:{...action.filterData.search_params}
                });
            }else{
                return Object.assign({}, state, {
                    associatedContentList: action.associatedContentList
                });
            }

            case "ASSOCIATED_CONTENT_ITEM_DELETE":
                return Object.assign({}, state, {
                    ...state,
                    associatedContentList: state.associatedContentList.filter((content, index) => index !== action.payload)
                  });

        case "COURSES_LIST_ADD":
            if(!action.course) {
                return state;
            }
            let addExerciseList = [action.course,...state.courseList];
            return Object.assign({}, state, {
                courseList: addExerciseList,
                paginateInfo: state.paginateInfo

            });
        case "COURSES_LIST_REMOVE":
            let newExerciseList = _.filter(state.courseList, function(courseObj) {
                if(courseObj.id !== action.course.id) {
                    return true;
                } else {
                    return false;
                }
            });
            return Object.assign({}, state, {
                courseList: newExerciseList,
                paginateInfo: state.paginateInfo
            });
        case "COURSES_LIST_UPDATE_AFTER_TOGGLE_FAVORITE":
            if(!action.course) {
                return state;
            }
            let toggleExerciseList = _.map(state.courseList, (item) => {
                if (item.id === action.course.id) {
                    return action.course;
                } else {
                    return item;
                }
            });
            return Object.assign({}, state, {
                courseList: toggleExerciseList,
                paginateInfo: state.paginateInfo
            });

        case "COURSES_LIST_CLEANUP":
            return Object.assign({}, initialState);
        case "ADD_COURSES_TO_WORKOUT_CART":
            if(!action.exercise) {
                return state;
            }
            let exerciseObj = {...action.exercise};
            exerciseObj["exercise_id"] = action.exercise["id"];
            exerciseObj["id"] = null;
            exerciseObj["sets"] = 1;
            exerciseObj["reps"] = 10;
            exerciseObj["distance"] = 1;
            exerciseObj["duration"] = 1;
            if(state.workoutCartExeciseList.length == 0) {
                exerciseObj["order"] = 0;
            } else {
                exerciseObj["order"] = state.workoutCartExeciseList.length;
            }
            let newWorkoutCartExerciseList = [...state.workoutCartExeciseList];
            newWorkoutCartExerciseList.push(exerciseObj)
            return Object.assign({}, state, {
                workoutCartExeciseList: newWorkoutCartExerciseList
            });
        case "CLEAR_WORKOUT_CART":            
            return Object.assign({}, state, {
                workoutCartExeciseList: []
            });
        default:
            return state;
    }
};