import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../../utils/common_components/tooltipButton';
import { Table } from '../../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GroupClassBooking from '../../group_class_booking.jsx';
export default function () {
    function repeatFacility1(facility, facilityIndex) {
        return React.createElement(Tr, { 'key': 'row' + facilityIndex }, React.createElement(Td, {
            'column': 'name',
            'value': facility.name
        }, React.createElement('span', {}, facility.name)), React.createElement(Td, {
            'column': 'max_client_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, facility.max_client_count)), React.createElement(Td, {
            'column': 'booking_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, facility.booking_count)), React.createElement(Td, {
            'column': 'created_by',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, facility.created_by.name)), React.createElement(Td, {
            'column': 'is_published',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, facility.is_published ? React.createElement('span', { 'key': '3621' }, 'Published') : null, !facility.is_published ? React.createElement('span', { 'key': '3694' }, 'Unublished') : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'd-flex' }, React.createElement(Button, {
            'onClick': () => {
                this.editFacility(facility);
            },
            'tooltip': 'Edit Facility',
            'icon': 'create',
            'className': 'c-grey micro m-r-5 m-b-5',
            'style': { minWidth: '10px' },
            'primary': true,
            'mini': true
        }), facility.is_published === true ? React.createElement(Button, {
            'onClick': () => {
                this.bookFacility(facility);
            },
            'tooltip': 'Book client',
            'icon': 'credit_card',
            'className': 'c-grey micro m-r-5 m-b-5',
            'style': { minWidth: '10px' },
            'primary': true,
            'mini': true,
            'key': '4638'
        }) : null, React.createElement(Button, {
            'onClick': () => {
                this.viewFacilitySlots(facility);
            },
            'tooltip': 'View calendar',
            'icon': 'date_range',
            'className': 'c-grey micro m-r-5 m-b-5',
            'style': { minWidth: '10px' },
            'primary': true,
            'mini': true
        }), React.createElement(Button, {
            'onClick': () => {
                this.deleteFacility(facility);
            },
            'tooltip': 'Delete Facility',
            'icon': 'delete_forever',
            'className': 'micro c-grey',
            'style': { minWidth: '10px' },
            'primary': true,
            'mini': true
        }))));
    }
    return React.createElement('div', { 'className': 'm-t-15' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'You have not added any facilities',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'max_client_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Max client count')), React.createElement(Th, {
            'column': 'booking_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Booking Count')), React.createElement(Th, {
            'column': 'created_by',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Created by')), React.createElement(Th, {
            'column': 'is_published',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Published')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Action'))),
        _.map(this.props.facilityList, repeatFacility1.bind(this))
    ]), React.createElement(Button, {
        'className': 'add-btn p-fixed bg-success m-r-10',
        'tooltip': 'Add New Studio Facility',
        'onClick': this.addFacility,
        'icon': 'add',
        'floating': true,
        'mini': true,
        'ripple': true
    }));
}