import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/banner_edit";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { uploadFiles } from '../../redux/core/network/file_upload';
import { getPromoBanner, postPromoBanner } from '../../redux/promo_banners/network/promo_banner_network'
import moment from 'moment';
import { listCourses } from '../../redux/courses/network/courses';
import CourseActionCreator from '../../redux/courses/actions/courses_list'

const action_type_options = [
    {
        label: "Action URL",
        value: 1,
    },
    {
        label: "Course Id",
        value: 2,
    }
]

const course_type_options = [
    {
        label: "Certification",
        value: "certification"
    },
    {
        label: "Specializations",
        value: "specialization"
    },
    {
        label: "Gift Mentorship",
        value: "giftMentorship"
    }
]


class PromoBannerEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            fileError: "",
            prevCourseType: "",
            fileType: ""
        }
        this.uploadCoverImage = this.uploadCoverImage.bind(this);
        this.onCancelImage = this.onCancelImage.bind(this);
        this.cancel = this.cancel.bind(this);
        this.transformData = this.transformData.bind(this);
        this.saveBanner = this.saveBanner.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.onVideoUploaded = this.onVideoUploaded.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.onCancelVideo = this.onCancelVideo.bind(this);
        this.upload = this.upload.bind(this);
    };
    setActive(el, active) {
        const formField = el.parentNode.parentNode
        if (active) {
            formField.classList.add('form-field--is-active')
        } else {
            formField.classList.remove('form-field--is-active')
            el.value === '' ?
                formField.classList.remove('form-field--is-filled') :
                formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el) {
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
                el.onblur = () => {
                    this.setActive(el, false)
                }
                el.onfocus = () => {
                    this.setActive(el, true)
                }
            }
        )
    }
    componentWillMount() {
        this.props.getPromoBanner({
            "package_version": "1",
            "app_version": "5.2",
            "id": this.props.match.params.banner_id || null,
            "banner_type": this.props.match.params.banner_type === "promo-banners" ? 3 : this.props.match.params.banner_type === "feature-banners" ? 2 : 1
        }).then(() => {
            this.setState({
                fileType: this.props.banner
                    ? this.props.banner.guid
                        ? "video"
                        : this.props.banner.thumbnail
                            ? "image"
                            : ""
                    : ""
            })
            const course_type = course_type_options.find((type) => type.value === this.props.banner.course.course_type)
            this.props.change('course_type',course_type.value)
        })

    }
    componentDidUpdate() {
        if (this.state.prevCourseType !== this.props.course_type) {
            this.props.cleanModule();
        }
        if (this.props.course_type) {
            let params = {
                "package_version": "2",
                "app_version": "5.2",
                "page": 0,
                "course_type": this.props.course_type,
            }
            this.props.listCourses(params)
            this.setState({
                prevCourseType: this.props.course_type
            })
        }

    }
    cancel() {
        this.props.match.params.banner_type==="feature-banners"
        ? history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners/feature-banners')
        : this.props.match.params.banner_type==="upcoming-banners"
        ? history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners/upcoming-banners')
        : history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners');
    }

    onVideoUploaded(uploadResult) {
        this.props.change('video_guid', uploadResult.guid);
        this.props.change('thumbnail', uploadResult.thumbnail);
        this.setState({ fileType: "video" })
    }
    onCancelVideo() {
        this.props.change('video_guid', null);
        this.props.change('thumbnail', null);
        this.setState({ fileType: "" })
    }
    onVideoUploadFailed() {
        this.setState({ fileType: "" })
        Utils.showErrorAlert("Video upload failed! Please try again.");
    }
    upload(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('thumbnail', result.url);
            this.props.change('file_name', result.file_name);
            this.props.change('is_image_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
            this.setState({ fileType: "image" })
        }).catch((error) => {
        });
    }
    uploadCoverImage(files) {
        if (this.props.match.params.banner_type === "feature-banners") {
            if (files.length !== 0) {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                const self = this;
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        const height = this.height;
                        const width = this.width;
                        const aspect_ratio = width / height;
                        if (width !== 155)
                            self.setState({ fileError: "Image should have a width of 155px" });
                        else if (height !== 198)
                            self.setState({ fileError: "Image should have a height of 198px" });
                        else {
                            self.setState({ fileError: "" })
                            self.upload(files)
                        }

                    };
                }
            }
            else this.setState({ fileError: "Please upload a png file" })
        }
        else this.upload(files);
    }
    onCancelImage() {
        this.props.change('is_image_uploaded', false);
        this.props.change('thumbnail', null);
        this.props.change('file_name', null);
        this.setState({ fileType: "" })
    }

    onNumberInput(e) {
        if (e.target.value.length > 2) e.target.value = e.target.value.slice(0, 2)
    }
    saveBanner(data) {
        let params = this.transformData(data);
        params.location = this.props.initialValues.banner_location
        params.banner_type = this.props.match.params.banner_type === "promo-banners" ? 3 : this.props.match.params.banner_type === "feature-banners" ? 2 : 1
        params.theme = this.props.themes || this.props.banner.themes
        params.button_action = this.props.button_actions || this.props.banner.button_actions
        if (!this.props.match.params.banner_id) delete params.id
        if (this.props.match.params.banner_type === "upcoming-banners") {
            params.guid = this.props.video_guid
            params.media_type = this.state.fileType === 'video' ? 2 : this.state.fileType === 'image' ? 1 : null; 
            params.start_date = moment(this.props.start_date).format('YYYY-MM-DD');
            params.end_date = moment(this.props.end_date).format('YYYY-MM-DD');
        }
        params.priority = this.props.priority ? this.props.priority : 0
        if (this.props.thumbnail)
            {   
                if(this.props.match.params.banner_type === "upcoming-banners") {
                    if(this.props.start_date<this.props.end_date)
                        this.props.postPromoBanner({
                        ...params,
                        "package_version": "1",
                        "app_version": "5.2",
                        }).then(() => {
                            history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners/upcoming-banners')
                        });
                    else Utils.showErrorAlert("Start Date should be before End Date");
                } else  {
                    this.props.postPromoBanner({
                        ...params,
                        "package_version": "1",
                        "app_version": "5.2",
                        }).then(()=> {
                            this.props.match.params.banner_type==="feature-banners"
                                    ? history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners/feature-banners')
                                    : history.push('/practice/' + this.props.selectedRole.practice.id + '/marketing/banners');
                        })
                }
            }
            else
                Utils.showErrorAlert("Please upload media");
        }
    transformData(data) {
        let params = Utils.flatternFormData(data, ['position_pictures']);
        return params;
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {

    let themes, button_actions, course_type, initialValues;
    let banner = state.promo_banner.bannerReducer.banner
    if (state.promo_banner.bannerReducer.banner.banner_type === 1) {

        if (Object.keys(banner).length) {
            initialValues = {
                ...banner,
                start_date: Utils.parseServerDate(state.promo_banner.bannerReducer.banner.start_date),
                end_date: Utils.parseServerDate(state.promo_banner.bannerReducer.banner.end_date),
            };
        }
        if (state.promo_banner.bannerReducer.banner.course) {
            course_type = course_type_options.find((type) => type.value === state.promo_banner.bannerReducer.banner.course.course_type)
        }
    }
    if (state.promo_banner.bannerReducer.supporting_data.themes)
        themes = state.promo_banner.bannerReducer.supporting_data.themes.find((theme) => theme['color_name'] === selector(state, 'themes'))

    if (state.promo_banner.bannerReducer.supporting_data.button_action)
        button_actions = state.promo_banner.bannerReducer.supporting_data.button_action.find((action) => action.id === selector(state, 'button_actions'))
    return {
        action_type_options: action_type_options,
        course_type_options: course_type_options,
        banner: state.promo_banner.bannerReducer.banner,
        supporting_data: state.promo_banner.bannerReducer.supporting_data,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        is_image_uploaded: selector(state, 'is_image_uploaded'),
        image_url: selector(state, 'image_url'),
        user_guid: selector(state, 'user_guid'),
        image_name: selector(state, 'image_name'),
        action_type: selector(state, 'action_type'),
        start_date: selector(state, 'start_date'),
        end_date: selector(state, 'end_date'),
        thumbnail: selector(state, 'thumbnail'),
        priority: selector(state, 'priority'),
        file_name: selector(state, 'file_name'),
        // initialValues: state.promo_banner.bannerReducer.banner,
        initialValues: initialValues ? initialValues : state.promo_banner.bannerReducer.banner,
        themes: themes,
        button_actions: button_actions,
        video_guid: selector(state, 'video_guid'),
        course_type: selector(state, 'course_type') ? selector(state, 'course_type') : course_type ? course_type.value : "",
        courseList: state.courses.courses.courseList,

    };
}
const selector = formValueSelector('BannerEditForm');
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        uploadFiles,
        getPromoBanner,
        postPromoBanner,
        listCourses,
        cleanModule: CourseActionCreator.cleanModule,
    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const PromoBannerEditForm = reduxForm(
    {
        form: 'BannerEditForm',
        enableReinitialize: true,
        validate
    }
)(PromoBannerEdit);

const PromoBannerEditConnected = connect(mapStateToProps, mapDispatchToProps)(PromoBannerEditForm);
export default PromoBannerEditConnected;
