import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from './templates/program_general_edit';
import { reduxForm, formValueSelector } from 'redux-form';

import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import {getProgram, postProgram} from '../../redux/program/network/program_edit';
import { uploadFiles } from '../../redux/core/network/file_upload';
import { getWrapperVideoList } from '../../redux/practice/network/practice_wrapper_video';
import actionCreator from '../../redux/program/actions/program_edit';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import moment from 'moment';

class ProgramGeneralEdit extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.doSave = this.doSave.bind(this);
    this.transformData = this.transformData.bind(this);
    this.uploadScientificEvidence = this.uploadScientificEvidence.bind(this);
    this.deleteScientificEvidence = this.deleteScientificEvidence.bind(this);
    this.onChangeCategoryLevel1 = this.onChangeCategoryLevel1.bind(this);
    this.onChangeCategoryLevel2 = this.onChangeCategoryLevel2.bind(this);
    this.state = {
      categoryLevel2: [],
      categoryLevel3: []
    };
  }

  componentWillMount() {
    // this.props.getProgram({id: this.props.match.params.program_id});
    this.props.getWrapperVideoList();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pristine !== nextProps.pristine) {
      this.props.disableProgression(!nextProps.pristine);
    }    
    if (nextProps.category !== null && nextProps.category !== undefined) {
      if (nextProps.supportingData !== null) {
        let selectedCategoryLevel2 = _.filter(
          nextProps.supportingData.category_level2,
          { category: nextProps.category.id }
        );
        this.setState({
          categoryLevel2: selectedCategoryLevel2
        });
      }
    }    
    if (nextProps.category_level2 !== null && nextProps.category_level2 !== undefined   ) {
      if (nextProps.supportingData !== null) {
        let selectedCategoryLevel3 = _.filter(
          nextProps.supportingData.category_level3,
          { category_level2: nextProps.category_level2.id }
        );
        this.setState({
          categoryLevel3: selectedCategoryLevel3
        });
      }
    }
  }

  uploadScientificEvidence(files) {
    let formData = new FormData();
    formData.append('files', files[0]);
    this.props
      .uploadFiles(formData)
      .then((resp) => {
        let result = resp.upload_results[0];
        this.props.change('scientific_evidence_url', result.url);
        this.props.change('scientific_evidence_guid', result.guid);
      })
      .catch((error) => {});
  }

  deleteScientificEvidence() {
    this.props.change('scientific_evidence_url', null);
    this.props.change('scientific_evidence_guid', null);
  }

  onChangeCategoryLevel1(value) {
    let selectedCategoryLevel2 = _.filter(
      this.props.supportingData.category_level2,
      { category: value.id }
    );
    this.setState({
      categoryLevel2: selectedCategoryLevel2
    });
  }

  onChangeCategoryLevel2(value) {
    let selectedCategoryLevel3 = _.filter(
      this.props.supportingData.category_level3,
      { category_level2: value.id }
    );
    this.setState({
      categoryLevel3: selectedCategoryLevel3
    });
  }

  doSave(data) {
    let params = this.transformData(data);
    if (params.start_date) {
      params.start_date = Utils.formatServerDate(params.start_date);
    }
    // let isNew = !this.props.match.params.program_id;
    this.props
      .postProgram({ ...params })
      .then((response) => {
        this.props.getProgram({ id: response.id }).then(() => {
          this.props.moveToBuild();
        });
      })
      .catch((error) => {});
  }

  transformData(data) {
    return Utils.flatternFormData(data);
  }

  render() {
    if (_.isEmpty(this.props.program)) {
      return <div />;
    }
    return Template.apply(this);
  }

  componentWillUnmount() {
    // this.props.cleanModule();
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // getProgram,
      // postProgram,
      uploadFiles,
      getWrapperVideoList,
      cleanModule: actionCreator.cleanModule
    },
    dispatch
  );
}
const selector = formValueSelector('ProgramGeneralEditForm');

function mapStateToProps(state, ownProps) {
  // let today = moment().seconds(0).milliseconds(0).toDate();
  // let program = { ...ownProps.program };
  // program.start_date = program.start_date ? program.start_date : today;
  let initialValues = { ...ownProps.program };
  if (ownProps.isNonSyncGroup) {
    initialValues.schedule_type = 2;
  }
  if (ownProps.programType === 'treatment_template_workout') {
    initialValues.treatment_template_id = ownProps.treatment_template_id;
  }
  return {
    selectedRole: state.auth.roleReducer.selectedRole,
    // program: state.program.programEdit.program,
    // initialValues: state.program.programEdit.program,
    supportingData: state.program.programEdit.supportingData,
    has_scientific_evidence: selector(state, 'has_scientific_evidence'),
    scientific_evidence_url: selector(state, 'scientific_evidence_url'),
    scientific_evidence_guid: selector(state, 'scientific_evidence_guid'),
    is_free: selector(state, 'is_free'),
    apple_pay_enabled: selector(state, 'apple_pay_enabled'),
    published: selector(state, 'published'),
    image_url: selector(state, 'image_url'),
    image_guid: selector(state, 'image_guid'),
    category: selector(state, 'category'),
    category_level2: selector(state, 'category_level2'),
    category_level3: selector(state, 'category_level3'),
    schedule_type: selector(state, 'schedule_type'),
    is_gi_3dmaps: selector(state, 'is_gi_3dmaps'),
    is_gi_golf: selector(state, 'is_gi_golf'),
    wrapper_videos: state.practice.practiceWrapperVideo.wrapper_videos,
    ...ownProps,
    initialValues: initialValues
  };
}

const validate = (values, props) => {
  let requiredFields = ['name', 'category', 'start_date'];
  const errors = {};
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (values['schedule_type'] === 2) {
    let per_week = parseInt(values['per_week']);
    if (isNaN(per_week)) {
      errors['per_week'] = 'Must be between 1 to 7';
    } else if (per_week < 1 || per_week > 7) {
      errors['per_week'] = 'Must be between 1 to 7';
    }
    let per_day = parseInt(values['per_day']);
    if (isNaN(per_week)) {
      errors['per_day'] = 'Must be between 1 to 10';
    } else if (per_day < 1 || per_day > 10) {
      errors['per_day'] = 'Must be between 1 to 10';
    }
  }
  return errors;
};

const ProgramGeneralEditForm = reduxForm({
  form: 'ProgramGeneralEditForm',
  enableReinitialize: true,
  validate
})(ProgramGeneralEdit);

const ProgramGeneralEditConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramGeneralEditForm);

export default ProgramGeneralEditConnected;
