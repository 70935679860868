import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/challenge_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../../redux/store';
import { getChallengeList, deleteChallenge } from '../../../redux/dhf/move/network/challenge_network';
import swal from 'sweetalert2';

class ChallengeList extends BaseComponent {

    constructor(props, context) {
        super(props, context);        
        this.addChallenge = this.addChallenge.bind(this);
        this.editChallenge = this.editChallenge.bind(this);
        this.deleteChallenge = this.deleteChallenge.bind(this);
    }
    
    componentWillMount() {
        this.loadChallenges();
    }

    loadChallenges() {
        this.props.getChallengeList();
    }

    componentWillUnmount() {
    }

    addChallenge() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/challenges/add');
    }

    editChallenge(challenge) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/challenges/edit/'+challenge.id);
    }

    deleteChallenge(challenge) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You will be deleting HomeFit Challenge permanently! Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = { challenge_id: challenge.id };
            that.props.deleteChallenge(params).then(() => {
                that.loadChallenges();
            });
            swal.close();              
        }, function (dismiss) {
                        
        });    
    }

    
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        challenge_list: state.move.challengeReducer.challenge_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChallengeList,
        deleteChallenge,
        }, 
        dispatch
    );
}


const ChallengeListConnected = connect(mapStateToProps,mapDispatchToProps)(ChallengeList);
export default ChallengeListConnected;