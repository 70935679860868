import Template from "./templates/terms_of_use";
import React from 'react';
import Utils from '../../utils/utils';

class TermsOfUse extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            display_content: "gm",
        };
    }

    componentWillMount() {
        if (Utils.getSubdomain() === "gi") {
            this.setState({
                display_content: "gi",
            });
        }
    }
        
    render() {
        return Template.apply(this);
    }    
}
export default TermsOfUse;