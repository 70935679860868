import * as React from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-toolbox/lib/date_picker';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import HelpTip from '../../../utils/common_components/help_tip';
import ProgressionView from '../../../program/components/progression_view';
import { Link } from 'react-router-dom';
import { history } from '../../../redux/store';
export default function () {
    function repeatProgression1(progression, progressionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': progressionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.playWorkout(progression);
            },
            'tooltip': 'Play Workout',
            'className': 'bg-success  m-t-20',
            'icon': 'gm-workouts',
            'gomotive': true,
            'floating': true
        })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(ProgressionView, _.assign({}, {
            'display_workout_name': true,
            'track_date': this.state.tracking_date,
            'display_schedule_type_info': true,
            'progression': progression,
            'showTracking': true,
            'trackProgressionCallback': this.trackProgressionCallback
        }, this.props))));
    }
    return React.createElement('div', { 'className': 'm-10' }, React.createElement('div', { 'className': 'row m-b-30' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('div', { 'className': 'row middle-xs' }, React.createElement('div', { 'className': 'col' }, React.createElement('h3', {}, '\n                        ', this.props.program.name, '\n                    ')))), React.createElement('div', { 'className': 'col-xs-6 end-xs row middle-xs' }, React.createElement(Button, {
        'label': 'View All Workouts',
        'tooltip': 'View all workouts',
        'primary': true,
        'raised': true,
        'onClick': () => {
            this.gotoAllWorkouts();
        }
    }))), this.props.program.action_type === 'display_payment' ? React.createElement('div', { 'key': '1296' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', {
        'className': 'col-xs-11',
        'style': { zIndex: '1' }
    }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Payment Subscription'), React.createElement('div', { 'className': 'panel-subtitle' }, 'Complete the payment to view treatment details ')), this.props.program.type === 'engagement' ? React.createElement('div', {
        'className': 'panel-body',
        'key': '1802'
    }, React.createElement(Button, {
        'onClick': () => {
            history.push('/client/engagement/' + this.props.program.id);
        },
        'className': 'bg-success',
        'icon': 'credit_card',
        'label': 'Subscribe',
        'tooltip': 'Click to complete payment for you to view treatment details',
        'raised': true,
        'primary': true
    })) : null, this.props.program.type === 'group' ? React.createElement('div', {
        'className': 'panel-body',
        'key': '2176'
    }, React.createElement(Button, {
        'onClick': () => {
            history.push('/client/engagement/' + this.props.program.id);
        },
        'className': 'bg-success',
        'icon': 'credit_card',
        'label': 'Subscribe',
        'tooltip': 'Click to complete payment for you to view treatment details',
        'raised': true,
        'primary': true
    })) : null)))) : null, this.props.program.action_type === 'view_treatment' && this.props.program.progressions.length > 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '2598' },
        _.map(this.props.program.progressions, repeatProgression1.bind(this))
    ]) : null, this.props.program.action_type === 'view_treatment' && this.props.program.progressions.length === 0 ? React.createElement('div', {
        'className': 'alert-warning m-20',
        'key': '3694'
    }, '\n        You do not have any workouts assigned for today. Click on View All Workouts.\n    ') : null);
}