import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/assessment_list.rt";
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import { history} from '../../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import Swal from 'sweetalert2';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import DhfAction from '../../../redux/dhf/assess/actions/assess_creator';
import { getDHFAssessmentForm,postDHFAssessmentForm } from '../../../redux/dhf/assess/network/assess_network';
import PropTypes from 'prop-types';


class AssessmentList extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.selectAssessment = this.selectAssessment.bind(this);
        this.createWorkout = this.createWorkout.bind(this);
        this.state = { selected_assessment: null };
    }
    

    componentWillMount() {
        if(!this.props.previewMode){
            this.props.getDHFAssessmentForm({
                "package_version": "2",
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "device_type": "web",
            }).then(() => {
                for(const [index, assessment] of this.props.dhf_form.entries()) {
                    if(assessment["id"].toLowerCase() == this.props.match.params.assess_type.toLowerCase()) {
                        this.selectAssessment(assessment);
                        break;
                    }
                }
            });            
        }
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    selectAssessment(assessment){
        this.setState({selected_assessment:assessment});
        window.scrollTo(0, 0);
    } 
    
    createWorkout(){
        let that = this;
        let completed_assessments = _.filter(this.props.dhf_form,{assessed:true});
        if(completed_assessments.length === 0){
            Utils.showErrorAlert("Please fill at least one assessment before proceeding!");
            return;
        }else{
            let params = {};
            params["assessments"] = this.props.dhf_form;
            if( _.has(this.props,"match")){
                params["client"] =  this.props.match.params.client_id;
            }
            Swal.fire({
                title: "Are you sure?",
                text: "Would you like to submit this assessment?.",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections:true
            }).then(() => {
                that.props.postDHFAssessmentForm(params).then((response)=>{
                    Utils.showSuccessAlert(response.message);
                    if(response.program_created){
                        history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + response.engagement_id + "/?tab=3");
                    }else{
                        history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + response.engagement_id + "/?tab=2");
                    }
                }).catch((error)=>{
        
                });
            }, ()=> { });
        }
    }

    render() {
        if (_.isEmpty(this.props.dhf_form)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}
function mapStateToProps(state,ownProps) {
    let dhf_form = [];
    if(ownProps.previewMode){
        dhf_form = ownProps.dhf_form;
    }else{
        dhf_form = state.dhfassess.assessReducer.dhf_assess_form;
    }
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        dhf_form: dhf_form,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDHFAssessmentForm,
        postDHFAssessmentForm,
        cleanUp: DhfAction.dhfCleanUp,
    }, dispatch);
}

AssessmentList.propTypes = {
    previewMode: PropTypes.bool,
};
AssessmentList.defaultProps = {
    previewMode: false,
};

const AssessmentListConnected = connect(mapStateToProps,mapDispatchToProps)(AssessmentList);
export default AssessmentListConnected;
