import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import UsersAction from '../actions/users_action';

const getAdminsList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/admin_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(UsersAction.adminListFill(
                response['admin_list'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};
const getUserSubscriptionProfile = (params) => {
    console.log("####",params)
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_subscription_profile";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(UsersAction.subscriptionProfileFill(
                response['data'],
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    getAdminsList,
    getUserSubscriptionProfile
};