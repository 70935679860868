import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_edit_calendar_event";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';


class GroupClassEditCalendarEvent extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentWillMount() {        
    }

    componentWillUnmount() {
    }

    deleteEvent(){
        this.props.deleteEvent();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownProps) {        
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch
    );
}

const GroupClassEditCalendarEventConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassEditCalendarEvent);
export default GroupClassEditCalendarEventConnected;