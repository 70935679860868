import * as React from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-toolbox/lib/date_picker';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import EasyPie from '../../../utils/common_components/easypie.jsx';
import ProgressionView from '../../../program/components/progression_view';
export default function () {
    function repeatProgression1(progression, progressionIndex) {
        return [
            React.createElement('div', {
                'className': 'col-xs-1  middle-xs center-xs d-flex',
                'style': { flexDirection: 'column' },
                'key': '17751'
            }, React.createElement('div', { 'className': 'text-center m-10' }, React.createElement('div', {
                'className': 'bg-warning box-shadow text-center p-5',
                'style': { width: '70px' }
            }, 'Duration ', progression.duration, ' mins')), React.createElement('div', { 'className': 'd-block w-100 m-10' }, React.createElement(Button, {
                'onClick': () => {
                    this.playWorkout(progression);
                },
                'tooltip': 'Play workout',
                'icon': 'play_arrow',
                'className': 'bg-success',
                'floating': true
            }))),
            React.createElement('div', {
                'className': 'col-xs-11',
                'style': { zIndex: '1' },
                'key': '17753'
            }, React.createElement(ProgressionView, _.assign({}, {
                'track_date': this.props.match.params.date,
                'progression': progression,
                'trackProgressionCallback': this.trackProgressionCallback
            }, this.props)))
        ];
    }
    function repeatHabit2(habit, habitIndex) {
        return [React.createElement('div', {
                'className': 'panel w-100',
                'key': '31041'
            }, React.createElement('div', { 'className': 'panel-heading' }, React.createElement('div', { 'className': 'panel-title' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-10' }, React.createElement('div', { 'className': 'col-xs-12' }, '\n                                ', habit.client_engagement_habit.text, '\n                            '), habit.has_tracked === 1 ? React.createElement('div', {
                'className': 'col-xs-12 f-12 f-300 c-danger',
                'key': '3555'
            }, React.createElement(Chip, {}, 'Not done')) : null, habit.has_tracked === 2 ? React.createElement('div', {
                'className': 'col-xs-12 f-12 f-300 c-primary',
                'key': '3746'
            }, '\n                                Partially done\n                            ') : null, habit.has_tracked === 3 ? React.createElement('div', {
                'className': 'col-xs-12 f-12 f-300 c-success',
                'key': '3931'
            }, '\n                                Completely done\n                            ') : null), React.createElement('div', { 'className': 'col-xs-2 text-right' }, React.createElement(Button, {
                'onClick': () => {
                    this.trackHabit(habit, 3);
                },
                'tooltip': 'Completely done this habit',
                'icon': 'done_all',
                'className': 'bg-success micro m-r-5',
                'floating': true,
                'mini': true
            }), React.createElement(Button, {
                'onClick': () => {
                    this.trackHabit(habit, 2);
                },
                'tooltip': 'Partially done this habit',
                'icon': 'done',
                'className': 'bg-primary micro m-r-5',
                'floating': true,
                'mini': true
            }), React.createElement(Button, {
                'onClick': () => {
                    this.trackHabit(habit, 1);
                },
                'tooltip': 'Not done this habit',
                'icon': 'clear',
                'className': 'bg-danger micro m-r-5',
                'floating': true,
                'mini': true
            }))))))];
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row m-20' },
        React.createElement('ol', { 'className': 'breadcrumb  col-xs-12 text-left' }, React.createElement('li', { 'className': 'p-relative' }, React.createElement('a', { 'onClick': this.goToEngagementHome }, !_.isEmpty(this.props.engagement) ? React.createElement('span', { 'key': '1157' }, this.props.engagement.name) : null)), React.createElement('li', { 'className': 'p-relative' }, React.createElement('a', { 'className': 'active' }, '\n                ', this.state.selectedDateString, '\n            '))),
        this.props.progression_preview.length > 0 ? React.createElement('h3', {
            'className': 'col-xs-12 m-b-10',
            'key': '1437'
        }, '\n        Workouts\n    ') : null,
        this.props.progression_preview.length === 0 ? React.createElement('div', {
            'className': 'col-xs-12 center-xs',
            'key': '1548'
        }, React.createElement('div', { 'className': 'col-xs-12 alert-warning' }, '\n            No workouts assigned for the selected date\n        ')) : null,
        _.map(this.props.progression_preview, repeatProgression1.bind(this)),
        this.props.habits.length > 0 ? React.createElement('h3', {
            'className': 'col-xs-12 m-t-10 m-b-10',
            'key': '2782'
        }, '\n        Habits\n    ') : null,
        this.props.habits.length === 0 ? React.createElement('div', {
            'className': 'col-xs-12 center-xs m-t-5',
            'key': '2886'
        }, React.createElement('div', { 'className': 'col-xs-12 alert-warning' }, '\n            No habits assigned for the selected date\n        ')) : null,
        _.map(this.props.habits, repeatHabit2.bind(this))
    ]);
}