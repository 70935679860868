import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/user_password_update";
import { formValueSelector, reduxForm } from 'redux-form';

import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updatePassword } from '../../redux/user/network/user_profile';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';

class PasswordUpdate extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
    }

    doSave(data) {
        let params = { ...data };
        params.id = this.props.user.id;
        this.props.updatePassword(params).then(() => {
            history.back();
        }).catch(() => { });
    }
    doCancel() {
        history.goBack();
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

const selector = formValueSelector('PasswordUpdateForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updatePassword,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'current_password',
        'new_password',
        'new_password_confirm'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values['new_password'] !== values['new_password_confirm']) {
        errors['email_confirm'] = "Passwords don't match";
    }
    return errors;
};

const PasswordUpdateForm = reduxForm(
    {
        form: 'PasswordUpdateForm',
        enableReinitialize: true,
        validate
    }
)(PasswordUpdate);

const PasswordUpdateConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordUpdateForm);

export default PasswordUpdateConnected;
