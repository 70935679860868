let initialState = {
    event: {},
    supportingData: {},
};

export default (state = initialState, action) => {
    switch (action.type) {        
        case "CALENDAR_EVENT_EDIT_FILL":
            return Object.assign({}, state, {
                event: action.event,
                supportingData: action.supportingData,
            });
        case "CLIENT_CALENDAR_EVENT_FILL":
            return Object.assign({}, state, {
                event: action.event,
            });            
        case "CALENDAR_EVENT_EDIT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
