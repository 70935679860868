import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { creatableFunction as Creatable } from '../../../utils/common_components/select_form';
import Input from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import HelpTip from '../../../utils/common_components/help_tip';
export default function () {
    function repeatQuestion1(section, sectionIndex, question, questionIndex) {
        return [
            React.createElement('div', {
                'className': 'col-xs-11',
                'key': '28711'
            }, React.createElement(Field, {
                'className': 'w-100 p-b-10',
                'hint': _.has(question, 'placeholder') ? question.placeholder : `Enter ${ question.name } value in ${ question.measure }`,
                'name': 'sections[' + sectionIndex + '].questions[' + questionIndex + '].value',
                'type': 'number',
                'component': Input
            })),
            React.createElement('div', {
                'className': 'col-xs-1',
                'key': '28713'
            }, this.state.sections && this.state.sections[sectionIndex]['questions'][questionIndex].risk == 'LOWRISK' ? React.createElement('span', {
                'className': `badge badge-success  f-10 no-wrap`,
                'key': '3513'
            }, this.state.sections[sectionIndex]['questions'][questionIndex].riskName) : null, this.state.sections && this.state.sections[sectionIndex]['questions'][questionIndex].risk == 'MEDIUMRISK' ? React.createElement('span', {
                'className': `badge badge-warning  f-10 no-wrap`,
                'key': '3790'
            }, this.state.sections[sectionIndex]['questions'][questionIndex].riskName) : null, this.state.sections && this.state.sections[sectionIndex]['questions'][questionIndex].risk == 'HIGHRISK' ? React.createElement('span', {
                'className': `badge badge-danger  f-10 no-wrap`,
                'key': '4070'
            }, this.state.sections[sectionIndex]['questions'][questionIndex].riskName) : null)
        ];
    }
    function repeatSection2(section, sectionIndex) {
        return React.createElement('div', {
            'className': 'col-xs-12 m-t-15',
            'key': 'section' + sectionIndex
        }, React.createElement('h5', { 'className': 'di-block w-auto m-r-10' }, section.name), this.state.sections && this.state.sections[sectionIndex].risk == 'LOWRISK' ? React.createElement('span', {
            'className': `badge badge-success  f-10 no-wrap`,
            'key': '2189'
        }, this.state.sections[sectionIndex].riskName) : null, this.state.sections && this.state.sections[sectionIndex].risk == 'MEDIUMRISK' ? React.createElement('span', {
            'className': `badge badge-warning  f-10 no-wrap`,
            'key': '2394'
        }, this.state.sections[sectionIndex].riskName) : null, this.state.sections && this.state.sections[sectionIndex].risk == 'HIGHRISK' ? React.createElement('span', {
            'className': `badge badge-danger  f-10 no-wrap`,
            'key': '2602'
        }, this.state.sections[sectionIndex].riskName) : null, React.createElement.apply(this, [
            'div',
            {
                'className': 'row',
                'style': { marginTop: '-15px' }
            },
            _.map(section.questions, repeatQuestion1.bind(this, section, sectionIndex))
        ]));
    }
    return React.createElement(Card, {
        'className': 'w-100',
        'style': { minHeight: '100vh' }
    }, React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {
        'className': 'big-labels',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement('div', { 'className': 'col-xs-12' }, !this.props.previewMode ? React.createElement(Button, {
            'disabled': this.props.pristine || this.props.submitting,
            'type': 'submit',
            'className': 'pull-right small-button  bg-success',
            'label': 'Done',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'key': '1422'
        }) : null),
        React.createElement('h4', {}, this.props.assessment.name),
        React.createElement('p', {
            'className': 'f-12',
            'style': { lineHeight: '1.5rem' }
        }, this.props.assessment.description),
        _.map(this.props.assessment.sections, repeatSection2.bind(this))
    ]))));
}