import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import Lightbox from 'react-image-lightbox';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    function repeatMedia1(media, index) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5',
            'key': 'practice_media' + index
        }, media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225 pointer',
            'style': { backgroundImage: 'url(' + encodeURI(media.thumbnail_url) + ')' },
            'onClick': () => {
                this.previewMedia(media);
            },
            'key': '3114'
        }) : null, media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225 pointer',
            'key': '3343'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'practice_media_wistia_player' + media.guid,
            'videoList': [media.guid],
            'handleUploadProcessing': true
        })) : null, React.createElement('div', { 'className': 'bg-white row end-xs p-r-5' }, React.createElement(IconButton, {
            'tooltip': 'Delete the uploaded media file',
            'icon': 'delete_forever',
            'className': 'c-black',
            'onClick': () => {
                this.deleteMedia(index);
            }
        })));
    }
    return React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.saveDescription) }, React.createElement('div', { 'className': 'row m-25' }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'descriptionRichText',
        'className': 'w-100',
        'name': 'description',
        'type': 'text',
        'component': RichTextEditor,
        'label': true
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    })))), React.createElement('div', { 'className': 'col-xs-6 start-xs m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement(Dropzone, {
        'className': 'col m-l-4',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadImage
    }, React.createElement(Button, {
        'label': 'Upload Image',
        'primary': true,
        'raised': true
    })), React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed
    }))), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        _.map(this.props.media_items, repeatMedia1.bind(this))
    ])), this.state.showMediaPreview ? React.createElement(Lightbox, {
        'mainSrc': this.props.images[this.state.imageIndex].url,
        'onCloseRequest': () => this.setState({ showMediaPreview: false }),
        'key': '3950'
    }) : null));
}