// Action Creators
const webinarListFill = (webinars_list) => ({
    type: 'WEBINAR_LIST_FILL',
    webinars_list,
});

const webinarFill = (webinar) => ({
    type: 'WEBINAR_FILL',
    webinar
});

const cleanModule = () => ({
    type: 'WEBINAR_CLEANUP',
});


export default {
    cleanModule,
    webinarListFill,
    webinarFill
};