let initialState = {
    sessions: [],
    paginate_info: {
        page: 0,
    },    
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "CLIENT_WORKFIT_SESSION_LIST_FILL":
            if (!action.sessions || !action.paginate_info){
                return state;
            }    
            return Object.assign({}, state, {
                sessions: action.sessions,
                paginate_info: action.paginate_info,
            });
        case "CLIENT_WORKFIT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
