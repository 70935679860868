import * as React from 'react';
import * as _ from 'lodash';
import Input from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button';
import { Field } from 'redux-form';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
export default function () {
    function repeatSubscription1(subscription, subscriptionIndex) {
        return React.createElement(Tr, { 'key': 'subscription' + subscriptionIndex }, React.createElement(Td, {
            'column': 'num-ber',
            'value': subscription.id,
            'style': {
                width: '20px',
                textAlign: 'left',
                padding: '4px 0'
            }
        }, React.createElement('span', {}, subscription.id)), React.createElement(Td, {
            'column': 'name',
            'value': subscription.name,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            }
        }, React.createElement('span', { 'className': 'capitalize' }, subscription.plan ? subscription.plan.name : '--')), this.props.location.state.type == 'clients' ? React.createElement(Td, {
            'column': 'taken_for',
            'value': subscription.taken_for,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            },
            'key': '5849'
        }, React.createElement('span', { 'className': 'capitalize' }, '\n                             ', subscription.plan ? subscription.plan.__plan_payment_type : '--', '\n                         ')) : null, this.props.location.state.type == 'clients' ? React.createElement(Td, {
            'column': 'amount',
            'value': subscription.amount,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            },
            'key': '6235'
        }, subscription.plan ? React.createElement('span', { 'key': '6411' }, '\n                            $', subscription.plan.fee_amount, '\n                        ') : null) : null, React.createElement(Td, {
            'column': 'date',
            'value': subscription.date,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            }
        }, React.createElement('span', {}, '\n                            ', moment(subscription.created_date).format('MMM D, YYYY'), '\n                        ')), this.props.location.state.type == 'coaches' ? React.createElement(Td, {
            'column': 'Subscription Plan Details',
            'value': subscription.amount,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            },
            'key': '6877'
        }, subscription.plan ? React.createElement('span', { 'key': '7072' }, '\n                            ', subscription.plan ? subscription.plan.__plan_payment_type : '--', ' ($', subscription.plan.fee_amount, ')\n                        ') : null) : null, this.props.location.state.type == 'coaches' ? React.createElement(Td, {
            'column': 'Payment Mode',
            'value': subscription.webhook,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            },
            'key': '7313'
        }, React.createElement('span', {}, subscription.webhook ? subscription.webhook.source : '-')) : null, this.props.location.state.type == 'coaches' ? React.createElement(Td, {
            'column': 'Subscription Status',
            'value': subscription.webhook,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            },
            'key': '7615'
        }, React.createElement('span', {}, subscription.payment_status == null ? 'Not Subscribed' : subscription.payment_status == 2 ? 'Subscribed' : subscription.payment_status == 3 ? 'Cancelled' : 'Not Subscribed')) : null, this.props.location.state.type == 'coaches' ? React.createElement(Td, {
            'column': 'Renewal/Cancellation Date',
            'value': subscription.webhook,
            'style': {
                width: '120px',
                textAlign: 'left',
                padding: '4px 0'
            },
            'key': '8038'
        }, React.createElement('span', {}, '\n                            ', subscription.webhook ? subscription.payment_status === 2 ? subscription.webhook.notification_type.includes('RENEW') ? 'RENEWED' : 'Subscribed' : subscription.payment_status === 3 ? 'Cancelled' : '-' : '-', ' \n                            ', subscription.webhook ? 'on ' + moment(subscription.publish_datetime).format('D MMM YYYY') : '-', '\n                        ')) : null);
    }
    return React.createElement('div', { 'className': 'user-container user-detail full-height m-30' }, React.createElement('div', { 'className': 'text-left' }, React.createElement(Button, {
        'className': 'm-t-20 m-b-20 d-flex text-capital back-btn c-grey',
        'onClick': this.cancel
    }, '\n            Back\n        '), this.props.subscriptionProfile ? React.createElement('div', {
        'className': 'p-24 box-shadow m-b-30',
        'key': '912'
    }, React.createElement('div', { 'className': 'section-title m-b-24 capitalize' }, this.props.subscriptionProfile.first_name, ' ', this.props.subscriptionProfile.last_name), React.createElement('div', { 'className': 'basic-info d-flex flex-wrap' }, React.createElement('div', {}, React.createElement('div', { 'className': 'table-heading m-b-4' }, 'Member Since'), React.createElement('div', {}, moment(this.props.subscriptionProfile.created_date).format('MMM D, YYYY'))), React.createElement('div', {}, React.createElement('div', { 'className': 'table-heading m-b-4' }, 'Email'), React.createElement('div', {}, this.props.subscriptionProfile.email)), React.createElement('div', {}, React.createElement('div', { 'className': 'table-heading m-b-4' }, 'No. of Client'), React.createElement('div', {}, this.props.subscriptionProfile.primary_practice ? this.props.subscriptionProfile.primary_practice.clients_count ? this.props.subscriptionProfile.primary_practice.clients_count : '--' : '--')), React.createElement('div', {}, React.createElement('div', { 'className': 'table-heading m-b-4' }, 'Registered Mode'), React.createElement('div', {}, this.props.subscriptionProfile.primary_practice ? this.props.subscriptionProfile.auth_method.name : '--'))), React.createElement('div', { 'className': 'basic-info d-flex flex-wrap' }, React.createElement('div', {}, React.createElement('div', { 'className': 'table-heading m-b-4' }, 'Last login'), React.createElement('div', {}, moment(this.props.subscriptionProfile.last_login).format('MMM D, YYYY'))), React.createElement('div', {}, React.createElement('div', { 'className': 'table-heading m-b-4' }, 'Subscription Status'), React.createElement('div', {}, this.props.subscriptionProfile.subscription_status)))) : null, React.createElement('div', { 'className': 'p-24 box-shadow' }, React.createElement('div', { 'className': 'section-title m-b-24' }, 'Subscription Details'), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-24',
            'id': 'table',
            'noDataText': 'There are no subscriptions.',
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'num-ber',
            'style': {
                width: '20px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Sr No.')), React.createElement(Th, {
            'column': 'name',
            'style': {
                width: '100px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Name')), React.createElement(Th, {
            'column': 'date',
            'style': {
                width: '120px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Date')), this.props.location.state.type == 'clients' ? React.createElement(Th, {
            'column': 'taken_for',
            'style': {
                width: '120px',
                textAlign: 'left'
            },
            'key': '3700'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Taken For')) : null, this.props.location.state.type == 'clients' ? React.createElement(Th, {
            'column': 'amount',
            'style': {
                width: '120px',
                textAlign: 'left'
            },
            'key': '3939'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Amount')) : null, this.props.location.state.type == 'coaches' ? React.createElement(Th, {
            'column': 'Subscription Plan Details',
            'style': {
                width: '120px',
                textAlign: 'left'
            },
            'key': '4193'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Plan Details')) : null, this.props.location.state.type == 'coaches' ? React.createElement(Th, {
            'column': 'Payment Mode',
            'style': {
                width: '120px',
                textAlign: 'left'
            },
            'key': '4451'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Payment Mode')) : null, this.props.location.state.type == 'coaches' ? React.createElement(Th, {
            'column': 'Subscription Status',
            'style': {
                width: '120px',
                textAlign: 'left'
            },
            'key': '4683'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Subscription Status')) : null, this.props.location.state.type == 'coaches' ? React.createElement(Th, {
            'column': 'Renewal/Cancellation Date',
            'style': {
                width: '120px',
                textAlign: 'left'
            },
            'key': '4929'
        }, React.createElement('span', { 'className': 'table-heading' }, 'Renewal/Cancellation Date')) : null),
        _.map(this.props.subscriptionList, repeatSubscription1.bind(this))
    ]))));
}