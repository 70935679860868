let initialState = {
    practitioner: {},
    practitionerList: [],
    selectionList: [],
    canned_message: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_PRACTITIONER_LIST_FILL":
            return Object.assign({}, state, {
                practitionerList: action.practitioner_list,
                selectionList: action.selection_list,
                canned_message: action.canned_message
            });
        case "PRACTICE_PRACTITIONER_FILL":
            return Object.assign({}, state, {
                practitioner: action.practitioner,
            });
        case "PRACTICE_PRACTITIONER_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
