import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import stripeConfigActionCreator from '../actions/stripeconfig_action';

const getStripeConfiguration = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/stripe_configuration_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(stripeConfigActionCreator.stripeConfigFill(
                response.stripe_url,
                response.stripe_account_details
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteStripeConfiguration = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/stripe_configuration_delete";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(stripeConfigActionCreator.stripeConfigFill(
                response.stripe_url,
                response.stripe_account_details
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const goliveStripeConfiguration = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/stripe_configuration_go_live";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(stripeConfigActionCreator.stripeConfigFill(
                response.stripe_url,
                response.stripe_account_details
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getStripeConfiguration,
    deleteStripeConfiguration,
    goliveStripeConfiguration
};