import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import Switch from '../../../utils/common_components/switch_form';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import RadioGroup from '../../../utils/common_components/radio_group_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'medium-labels col-xs-8 m-20',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, {}, React.createElement(CardTitle, { 'title': 'Search Preferences' }), React.createElement(CardText, { 'className': 'm-t-15 text-left' }, React.createElement('h4', {}, 'Exercises'), React.createElement('div', { 'className': 'filter-section p-10' }, React.createElement('div', { 'className': 'm-t-5 m-b-10' }, React.createElement(Field, {
        'name': 'exercise_is_favorite',
        'label': 'Show Just My Favorite Exercises',
        'component': Switch
    })), React.createElement(Field, {
        'name': 'exercise_sources',
        'label': 'Exercise Sources',
        'component': Checkbox,
        'options': this.props.exerciseSources
    })), React.createElement('h4', { 'className': 'm-t-20' }, 'Templates'), React.createElement('div', { 'className': 'filter-section p-10' }, React.createElement('div', { 'className': 'm-t-5 m-b-10' }, React.createElement(Field, {
        'name': 'program_is_favorite',
        'label': 'Show Just My Favorite Templates',
        'component': Switch
    })), React.createElement(Field, {
        'name': 'program_sources',
        'label': 'Template Sources',
        'component': Checkbox,
        'options': this.props.programSources
    })), React.createElement('h4', { 'className': 'm-t-20' }, 'Client List Filter'), React.createElement('div', { 'className': 'filter-section p-10' }, React.createElement(Field, {
        'className': 'vertical-align w-100 row',
        'name': 'client_search_preference.client_filter',
        'component': RadioGroup,
        'colXSNum': 4,
        'options': this.state.client_search_preferences
    }))), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Save Preferences',
        'disabled': this.props.submitting,
        'className': 'bg-success',
        'type': 'submit',
        'raised': true
    })))));
}