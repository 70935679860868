import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_engagement_assessment_view.rt";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class ClientEngagementAssessmentView extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showMediaPreview: false,
            displayImageUrl: null,
        };
    }

    previewImage(media) {
        this.setState({
            showMediaPreview: true,
            displayImageUrl: media.url,
        });
    }    

    render() {
        if (_.isEmpty(this.props.assessment)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownprops) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

const ClientEngagementAssessmentViewConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagementAssessmentView);
export default ClientEngagementAssessmentViewConnected;
