import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import {bindActionCreators} from 'redux';
import Template from "./templates/plan_selection";
import {connect} from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {getPaymentSubscriptionDetails} from '../../redux/payment/network/plan_subscribe_network';
import {history} from '../../redux/store';
import planSubscribeReducer from '../../redux/payment/reducers/plan_subscribe_reducer';


class PlanSelection extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {tabIndex:0};
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentWillMount() {
        this.props.getPaymentSubscriptionDetails({
            practice_id: 276
        });
    }

    handleTabChange(tabIndex){
        this.setState({tabIndex});
    }    

    render() {
        if (this.props.payment_plans.length === 0) {
            return (
                <div></div>
            );
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        payment_plans: state.payment.planSubscribeReducer.payment_plans,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPaymentSubscriptionDetails
    }, dispatch);
}
const PlanSelectionConnected = connect(mapStateToProps, mapDispatchToProps)(PlanSelection);
export default PlanSelectionConnected;