let initialState = {
    workouts: [],
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "GROUP_WORKOUT_LIST_FILL":
            return Object.assign({}, state, {
                workouts: action.workouts
            });
        case "GROUP_WORKOUT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
