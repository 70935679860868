import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
export default function () {
    return React.createElement('div', { 'className': 'row  m-20' }, React.createElement(Card, { 'className': 'col-md-8 col-sm-9 col-xs-10 p-0' }, React.createElement(CardTitle, { 'title': 'Business Partner Details' }), React.createElement(CardText, { 'className': 'p-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-3 m-b-15 text-right f-15 f-500' }, '\n                    Name: \n                '), React.createElement('div', { 'className': 'col-xs-9' }, React.createElement('span', {}, ' ', this.props.partner.name, ' ')), React.createElement('div', { 'className': 'col-xs-3 m-b-15 text-right f-15 f-500' }, '\n                    Subdomain: \n                '), React.createElement('div', { 'className': 'col-xs-9' }, '\n                    ', this.props.partner.subdomain, '\n                '), React.createElement('div', { 'className': 'col-xs-3 m-b-15 text-right f-15 f-500' }, '\n                    Android App url: \n                '), React.createElement('div', { 'className': 'col-xs-9' }, '\n                    ', this.props.partner.android_app_url, '\n                '), React.createElement('div', { 'className': 'col-xs-3 m-b-15 text-right f-15 f-500' }, '\n                    IOS App url: \n                '), React.createElement('div', { 'className': 'col-xs-9' }, '\n                    ', this.props.partner.ios_app_url, '\n                ')))));
}