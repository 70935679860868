import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_goals.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getPartnerGoalList, addPartnerLibraryGoal, removePartnerLibraryGoal,
    addAllGoalTemplatesPartnerLibrary, removeAllGoalTemplatesPartnerLibrary
} from '../../redux/partner/network/partner_network';
import {getGoalList} from '../../redux/goal/network/goal_network';
import partnerActions from '../../redux/partner/actions/partner_action';


class PartnerLibraryGoals extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addGoal = this.addGoal.bind(this);
        this.removeGoal = this.removeGoal.bind(this);
        this.addAllGoalTemplates = this.addAllGoalTemplates.bind(this);
        this.removeAllGoalTemplates = this.removeAllGoalTemplates.bind(this);
    };

    componentWillMount() {
        if(!this.props.match.params.library_id){
            history.goBack();
        }
        this.props.getPartnerGoalList({id:this.props.match.params.library_id});
        this.props.getGoalList({
            fetch_type: "partner_library"
        });
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    addGoal(goal){
        this.props.addPartnerLibraryGoal({
            id:this.props.match.params.library_id,
            goal_id:goal.id
        });
    }

    removeGoal(goal){
        this.props.removePartnerLibraryGoal({
            id:this.props.match.params.library_id,
            goal_id:goal.id
        });
    }

    addAllGoalTemplates() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.addAllGoalTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.getPartnerGoalList({id:this.props.match.params.library_id});
            this.props.getGoalList({
                fetch_type: "partner_library"
            });
        }).catch((error) => {            
        });  
    }

    removeAllGoalTemplates() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.removeAllGoalTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.getPartnerGoalList({id:this.props.match.params.library_id});
            this.props.getGoalList({
                fetch_type: "partner_library"
            });
        }).catch((error) => {            
        });  
    }


    render() {
        if(_.isEmpty(this.props.partner_library)){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerGoalList,
        addPartnerLibraryGoal,
        removePartnerLibraryGoal,
        getGoalList,
        cleanUp: partnerActions.cleanUp,
        addAllGoalTemplatesPartnerLibrary,
        removeAllGoalTemplatesPartnerLibrary,
    }, dispatch);
}

function mapStateToProps(state,ownProps) {
    let partner_goal_ids = _.keyBy(state.partner.partnerReducer.partner_library.goals,"id");
    return {
        partner_library: state.partner.partnerReducer.partner_library,
        goalList: state.goal.goalReducer.goal_list,
        partner_goal_ids:partner_goal_ids
    };
}

const PartnerLibraryGoalsConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryGoals);
export default PartnerLibraryGoalsConnected;
