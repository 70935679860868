import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/canned_message_edit.rt";
import { getCannedMessage, postCannedMessage, loadDefaultCannedMessage } from '../../redux/message/network/canned_message_network';
import CannedMessageAction from '../../redux/message/actions/canned_message_creator';
import Swal from 'sweetalert2';
import { history} from '../../redux/store';

class CannedMessageEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.updateEmailTemplate = this.updateEmailTemplate.bind(this);
        this.loadDefaultTemplate = this.loadDefaultTemplate.bind(this);
        this.cancel = this.cancel.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.state = {
            "message_type": ""
        };
    };
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        let message_type = this.props.match.params.message_type;
        this.setState({
            "message_type": message_type
        });
        if (message_type){
            this.props.getCannedMessage({
                message_type:message_type,
                replace_variables: false,
                package_version: "1",
            });
        } else {
            history.goBack();
        }        
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    updateEmailTemplate(data) {        
        let params = {...data};
        params.package_version =  1;
        params.practice_id = this.props.user.id===1599 ? 276 : this.props.practice_id
        this.props.postCannedMessage(params).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/email_template');
        }).catch((error) => {
            //do nothing
        });  
    }
    
    loadDefaultTemplate() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to load the default Email Template? All your changes will be lost. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, Load Default Template!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.loadDefaultCannedMessage({ message_type: that.state.message_type }).then((response)=>{
                tinyMCE.get("templateBodyRichText").setContent(response.canned_message.body); 
            }).catch((error)=>{
                //Do nothing
            });
        }, function (dismiss) {
        });
    }

    cancel() {
        history.goBack();
    }

    render() {
        if (_.isEmpty(this.props.canned_message)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        canned_message: state.message.cannedMessageReducer.canned_message,
        initialValues: state.message.cannedMessageReducer.canned_message
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getCannedMessage,
        postCannedMessage,
        loadDefaultCannedMessage,
        cleanUp: CannedMessageAction.cannedMessageCleanup,
    }, dispatch),
    dispatch
});

const validate = (values, props) => {
    let requiredFields = [
        'subject',
        'body',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const CannedMessageEditForm = reduxForm(
    {
        form: 'CannedMessageEditForm',
        enableReinitialize: true,
        validate
    }
)(CannedMessageEdit);

const CannedMessageEditFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(CannedMessageEditForm);
export default CannedMessageEditFormConnected;
