import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import CueAction from '../actions/cue_action';

const getCueList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/cue_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(CueAction.cueListFill(
                response['cue_list'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const getCue = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/cue_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(CueAction.cueFill(
                response['cue'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const saveCue = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/cue_post";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

export {
    getCueList,
    getCue,
    saveCue
};