import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/ongoing_workouts_usage_report.rt";
import store,{history} from '../../redux/store';
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import { generateOngoingWorkoutsUsageReport } from '../../redux/program/network/program_list';
import { formValueSelector, reduxForm } from 'redux-form';

class OngoingWorkoutsUsageReport extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.generateReport = this.generateReport.bind(this);
        this.state = {
            donwload_report: null
        };
    }

    componentWillMount() {       
    }

    generateReport(data) {
        this.setState({
            download_report: null
        });
        let params = {};
        params["start_date"] = Utils.formatServerDate(data["start_date"]);
        params["end_date"] = Utils.formatServerDate(data["end_date"]);
        params.package_version = 2;
        this.props.generateOngoingWorkoutsUsageReport(params).then((response) => {
            Utils.showSuccessAlert("Click on Download Report button to view the report");
            this.setState({
                download_report: response["file_url"]
            });
        }).catch(() => { });;
    }
    
    render() {
        if (!this.props.isAuthenticated || _.isEmpty(this.props.selectedRole)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar type='circular' mode='indeterminate' multicolor />
            </div>);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateMenuOpen: MenuAction.updateMenuOpen,
            generateOngoingWorkoutsUsageReport,
        },
        dispatch
    );
}
function mapStateToProps(state, ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen: state.core.appMenu.menu_open,
        pinned: state.core.appMenu.pinned,
    };
}

const validate = (values, props) => {
    let requiredFields = ['start_date','end_date'];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const selector = formValueSelector('OngoingWorkoutsUsageReport');

const OngoingWorkoutsUsageReportForm = reduxForm({
    form: 'OngoingWorkoutsUsageReport',
    enableReinitialize: true,
    validate
})(OngoingWorkoutsUsageReport);

const OngoingWorkoutsUsageReportFormConnected = connect(mapStateToProps, mapDispatchToProps)(OngoingWorkoutsUsageReportForm);
export default OngoingWorkoutsUsageReportFormConnected;
