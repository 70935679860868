import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actionCreator from '../actions/progression_edit';

const getProgression = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/progression_get";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.fillProgression(
                response['progression'],
                response['supporting_data']
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postProgression = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/progression_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            // Utils.showSuccessAlert("Progression was saved successfully. You can  now add one more progression or publish by clicking on Preview & Publish");
            Utils.showSuccessAlert("Exercises were saved successfully.");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updateProgressionDays = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/progression_days_update";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Updated Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getProgression,
    postProgression,
    updateProgressionDays,
};