import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    function repeatCoach1(coach, coachIndex) {
        return React.createElement(Tr, { 'key': 'coach' + coachIndex }, React.createElement(Td, {
            'column': 'name',
            'value': coach.name
        }, React.createElement('span', {}, coach.name)), React.createElement(Td, {
            'column': 'email',
            'value': coach.contact
        }, React.createElement('span', {}, '\n                    ', coach.contact, '\n                ')), React.createElement(Td, { 'column': 'is_member_since' }, React.createElement('span', {}, '\n                    Jan 20, 2021\n                ')), React.createElement(Td, { 'column': 'number_of_clients' }, React.createElement('span', {}, '\n                    99\n                ')), React.createElement(Td, { 'column': 'registration_mode' }, React.createElement('span', {}, '\n                    GI Coach Invite\n                ')), React.createElement(Td, { 'column': 'last_login' }, React.createElement('span', {}, '\n                   Jan 20, 2021\n                ')));
    }
    function repeatClient2(client, clientIndex) {
        return React.createElement(Tr, { 'key': 'client' + clientIndex }, React.createElement(Td, {
            'column': 'name',
            'value': client.name
        }, React.createElement('span', {}, client.name)), React.createElement(Td, {
            'column': 'email',
            'value': client.email
        }, React.createElement('span', {}, '\n                    ', client.email, '\n                ')), React.createElement(Td, { 'column': 'is_member_since' }, React.createElement('span', {}, '\n                    Jan 20, 2021\n                ')), React.createElement(Td, { 'column': 'number_of_clients' }, React.createElement('span', {}, '\n                    99\n                ')), React.createElement(Td, { 'column': 'registration_mode' }, React.createElement('span', {}, '\n                    GI Coach Invite\n                ')), React.createElement(Td, { 'column': 'last_login' }, React.createElement('span', {}, '\n                   Jan 20, 2021\n                ')));
    }
    return React.createElement('div', { 'className': 'user-content' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable' + ' ' + true,
            'id': 'table',
            'noDataText': 'Create your own users by clicking on add user',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': true
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, { 'column': 'email' }, React.createElement('span', { 'className': 'table-heading' }, 'Email')), React.createElement(Th, { 'column': 'is_member_since' }, React.createElement('span', { 'className': 'table-heading' }, 'Member since')), React.createElement(Th, { 'column': 'number_of_clients' }, React.createElement('span', { 'className': 'table-heading' }, 'No. of Clients')), React.createElement(Th, { 'column': 'registration_mode' }, React.createElement('span', { 'className': 'table-heading' }, 'Registration Mode')), React.createElement(Th, { 'column': 'last_login' }, React.createElement('span', { 'className': 'table-heading' }, 'Last login'))),
        this.props.type == 'coaches' ? _.map(this.props.coachList, repeatCoach1.bind(this)) : null,
        this.props.type == 'clients' ? _.map(this.props.clientList, repeatClient2.bind(this)) : null
    ]));
}