import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import Root from './core/components/root';
import store, {configureStore} from './redux/store';
import './vendor/reactable/reactable'
import './vendor/reactable/reactable.less'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../styles/gomotive-font';
import '../styles/index.less'
import '../styles/giCss.less'
import "swagger-ui-react/swagger-ui.css";

class App extends React.Component {
    constructor(props) {
        super(props);
    }
render() {
        return (
            <div>
                This is my React app!
                    <Root store={store}/>
            </div>);
    }
}
const router = (
    <AppContainer>  
        <Root store={store} />
    </AppContainer>
    );

ReactDOM.render(router, document.getElementById('react-app'));
if (module && module.hot && __DEV__) {
    module.hot.accept("./core/components/root", () => {
      const NextApp = require("./core/components/root").default;
      ReactDOM.render(
        <AppContainer>
            {console.log('above next')}
          <NextApp store={store} />
        </AppContainer>
        ,
         document.getElementById('react-app')
      );
    });
  } 
