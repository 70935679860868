import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/password_reset.rt";
import { reduxForm } from 'redux-form';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { passwordReset } from '../../redux/auth/network/auth_network';
import { history } from '../../redux/store';
import {PARTNER} from '../../core/constants/constants';

class PasswordReset extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        console.log("clicked")
        history.push("/auth/login");
    }
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    
    componentWillMount() {
        if (this.props.isAuthenticated) {
            history.push('/home');
        }
    }

    doSave(data) {
        let params = { ...data };
        if(PARTNER.app_type=='tri' || PARTNER.app_type=='dhf'){
            params.package_version = '1'
        }
        this.props.passwordReset({
            ...params          
        }).then(() => {
            history.push("/auth/login");
        });
    }
        
    render() {
        return Template.apply(this);
    }
    
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        passwordReset
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'email',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
}; 


const PasswordResetForm = reduxForm(
    {
        form: 'PasswordResetForm',
        enableReinitialize: true,
        validate
    }
)(PasswordReset);


const PasswordResetFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordResetForm);

export default PasswordResetFormConnected;
