let initialState = {
    newsList: {},
    paginate_info: {
        page: 0,
    },
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "NEWS_LIST_FILL":
            let newNewsList = [];
            if(action.paginate_info.page === 0){
                newNewsList = [ ...action.newsList];
            }else{
                newNewsList = [...state.newsList, ...action.newsList];
            }
            return Object.assign({}, state, {
                newsList: newNewsList,
                paginate_info: action.paginate_info,
            });
        case "NEWS_LIST_REMOVE":
            let updatedNewsList = _.filter(state.newsList, function(obj) {
                if(obj.id !== action.news_id) {
                    return true;
                } else {
                    return false;
                }
            });
            return Object.assign({}, state, {
                newsList: updatedNewsList,
                paginateInfo: state.paginateInfo
            });
            
        case "NEWS_LIST_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};