import Axios from '../../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';
import Utils from '../../../../utils/utils';
import { showProgress, hideProgress } from '../../../core/actions/progress_creator';
import WorkfitActions from '../actions/workfit_actions';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getWorkfitVideoList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_videos_list";
        return Axios.post(url, params).then((response) => {
            dispatch(WorkfitActions.workfitVideosFill(
                response['workfit_videos']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getWorkfitVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_video_get";
        return Axios.post(url, params).then((response) => {
            dispatch(WorkfitActions.workfitVideoFill(
                response['workfit_video']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveWorkfitVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_video_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteWorkfitVideo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_video_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(WorkfitActions.workfitVideoFill(
                response['workfit_video']
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getWorkfitHome = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_home";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getWorkfitPracticeList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_get_practice_list";
        return Axios.post(url, params).then((response) => {
            dispatch(WorkfitActions.practiceListFill(
                response['practice_list']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const generateWorkfitUsageReport = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_usage_report";
        return Axios.post(url, params).then((response) => {            
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const generateWorkfitSummaryReport = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_summary_report";
        return Axios.post(url, params).then((response) => {            
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const notifyUsers = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_notify_client";
        return Axios.post(url, params).then((response) => {            
            dispatch(hideProgress());
            Utils.showSuccessAlert("Notification has been successfully sent to the chosen user");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getNotificationTemplateList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_notification_template_list";
        return Axios.post(url, params).then((response) => {
            dispatch(WorkfitActions.notificationTemplateListFill(
                response['notification_templates']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getNotificationTemplate= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_notification_template_get";
        return Axios.post(url, params).then((response) => {
            dispatch(WorkfitActions.notificationTemplateGetFill(
                response['notification_template']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveNotificationTemplate= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_notification_template_save";
        return Axios.post(url, params).then((response) => {            
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getNotificationScheduleList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url =baseURL + "/" + 6 + "/" + PACKAGE + "/workfit_notification_schedule_list";
        return Axios.post(url, params).then((response) => {
            dispatch(WorkfitActions.notificationScheduleListFill(
                response['notification_schedules']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const notificationScheduleCreate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_notification_schedule_create";
        return Axios.post(url, params).then((response) => {            
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const notificationScheduleDelete = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_notification_schedule_delete";
        return Axios.post(url, params).then((response) => {            
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getWorkfitVideoList,
    getWorkfitVideo,
    saveWorkfitVideo,
    getWorkfitHome,
    deleteWorkfitVideo,
    getWorkfitPracticeList,
    generateWorkfitUsageReport,
    notifyUsers,
    generateWorkfitSummaryReport,
    getNotificationTemplateList,
    getNotificationTemplate,
    saveNotificationTemplate,
    getNotificationScheduleList,
    notificationScheduleCreate,
    notificationScheduleDelete
};