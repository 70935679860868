import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import DatePicker from '../../../../utils/common_components/datepicker_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import { Link } from 'react-toolbox/lib/link';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'col-xs-6 m-20',
        'onSubmit': this.props.handleSubmit(this.generateReport)
    }, React.createElement(Card, {}, React.createElement(CardTitle, { 'title': 'Summary Report' }), React.createElement(CardText, { 'className': 'm-t-15' }, React.createElement('div', { 'className': 'row-margin' }, React.createElement(Field, {
        'name': 'start_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'Start Date'
    })), React.createElement('div', { 'className': 'row-margin' }, React.createElement(Field, {
        'name': 'end_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'End Date'
    }))), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Generate Report',
        'disabled': this.props.pristine || this.props.submitting,
        'className': 'bg-primary',
        'type': 'submit',
        'primary': true,
        'raised': true
    }), this.state.download_report ? React.createElement(Link, {
        'active': true,
        'href': this.state.download_report,
        'label': 'Download Report',
        'key': '1674'
    }) : null))));
}