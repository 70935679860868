import BaseComponent from '../../utils/common_components/basecomponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Template from "./templates/users_detail";
import store, { history } from '../../redux/store';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import { getUserSubscriptionProfile } from '../../redux/users/network/users_network';
const data = [
    {
        id: 1,
        name: "Movement Specialist Locator",
        taken_for: "monthly",
        amount: "9.99",
        date: "Apr 7, 2023"
    },
    {
        id: 2,
        name: "Movement Specialist Locator",
        taken_for: "monthly",
        amount: "9.99",
        date: "Apr 7, 2023"
    },
    {
        id: 3,
        name: "Movement Specialist Locator",
        taken_for: "monthly",
        amount: "9.99",
        date: "Apr 7, 2023"
    },
    {
        id: 4,
        name: "Movement Specialist Locator",
        taken_for: "monthly",
        amount: "9.99",
        date: "Apr 7, 2023"
    },
]
class UsersDetail extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.cancel = this.cancel.bind(this);
        this.state = {
            type: this.props?.location?.state?.type
        }
    };
    componentWillMount() {
        console.log(this.props, 'details props');
        this.props.getUserSubscriptionProfile({
            "app_version": "5.2",
            "current_role_type": "practice_admin",
            "package_version": 3,
            "user_id": this.props.match.params.user_id,
            "type": this.props?.location?.state?.type
        })
    }
    cancel() {
        const { history } = this.props;
        history.push({
            pathname: this.props?.location?.state?.urlData, type: this.props?.location?.state?.type,
            startDate: this.props?.location?.state?.startDate,
            Subscription: this.props?.location?.state?.Subscription,
            subscriptionList: typeof this.props?.location?.state?.subscriptionList === "object" ? this.props?.location?.state?.subscriptionList.value : this.props?.location?.state?.subscriptionList
        });
    }
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    console.log(state,'state');
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        subscriptionProfile: state.users.usersReducer.subscription_profile.user,
        subscriptionList: state.users.usersReducer.subscription_profile.plans,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserSubscriptionProfile
    },
        dispatch
    );
}

const UsersDetailConnected = connect(mapStateToProps, mapDispatchToProps)(UsersDetail);
export default UsersDetailConnected;