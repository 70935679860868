import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { IconMenu } from 'react-toolbox/lib/menu';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Notification from '../../../utils/common_components/notification_component';
import { PARTNER } from '../../constants/constants';
import { Switch as Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import AuthContainer from '../../../auth/components/auth_container';
import NotFound from '../not_found';
import ErrorBoundary from '../../../utils/common_components/error_boundaries';
import GIApiLogin from '../../../auth/components/gi_api_login.jsx';
import { Dialog } from 'react-toolbox/lib/dialog';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import UserEmailUpdate from '../../../user/components/user_email_update';
import ViewProfile from '../../../user/components/view_profile';
import DownIcon from '../../../../assets/chevron-down.png';
export default function () {
    function repeatRole1(role, roleIndex) {
        return React.createElement('div', {
            'className': 'bg-white p-10 text-left f-16 pointer',
            'style': {
                borderBottom: '1px solid #ccc',
                borderTop: '1px solid #D8DDE6'
            },
            'key': roleIndex,
            'onClick': () => {
                this.selectRole(role);
            }
        }, role.name !== 'Client' ? React.createElement(ProfilePicture, {
            'user': role,
            'profile_type': 'circle',
            'className': 'm-r-5',
            'key': '8659'
        }) : null, role.name === 'Client' ? React.createElement(ProfilePicture, {
            'user': this.props.user,
            'profile_type': 'circle',
            'className': 'm-r-5',
            'key': '8822'
        }) : null, '\n                        ', role.name, '\n                    ');
    }
    return React.createElement(Layout, {
        'className': `  full-height main-container ${ this.checkBackgroundImage() } `,
        'ref': n => this.layout = n
    }, React.createElement('div', { 'className': 'holder flex-auto' }, React.createElement(Notification, {}), React.createElement(Panel, { 'className': 'full-panel' }, this.state.openProfileMenu ? React.createElement('div', {
        'className': 'overlay',
        'onClick': this.closeProfileMenu,
        'key': '3326'
    }) : null, this.props.isUpdatingServer ? React.createElement('div', {
        'id': 'progress_modal',
        'className': 'nsProgress_container',
        'key': '3444'
    }, React.createElement(ProgressBar, {
        'type': 'circular',
        'mode': 'indeterminate',
        'multicolor': true
    })) : null, this.props.isUpdatingServer ? React.createElement('div', {
        'className': 'nsProgress_block',
        'key': '3644'
    }) : null, !this.props.isAuthenticated ? React.createElement(AppBar, {
        'className': 'main-app-bar',
        'title': PARTNER.name,
        'key': '3727'
    }) : null, !this.props.isAuthenticated ? React.createElement('div', { 'key': '3862' }, React.createElement(Routes, {}, React.createElement(Route, {
        'exact': true,
        'path': `/api-login/:token`,
        'component': GIApiLogin
    }), React.createElement(Route, {
        'path': '/auth',
        'component': AuthContainer
    }))) : null, this.state.giPro && this.props.isAuthenticated && _.isEmpty(this.props.selectedRole) ? React.createElement(AppBar, {
        'className': 'main-app-bar main-app-bar-gi',
        'theme': { leftIcon: 'menu-left-icon' },
        'title': this.getTitle(),
        'style': { width: '100%' },
        'key': '4135'
    }, React.createElement('img', {
        'height': '30',
        'src': this.props.logo_url
    })) : null, !this.state.giPro && this.props.isAuthenticated && _.isEmpty(this.props.selectedRole) ? React.createElement(AppBar, {
        'className': 'main-app-bar',
        'title': PARTNER.name,
        'key': '4605'
    }, React.createElement(Button, {
        'label': 'Logout',
        'onClick': () => {
            this.doAppLogout();
        },
        'className': 'c-white small-button'
    })) : null, !this.state.giPro && this.props.isAuthenticated && !_.isEmpty(this.props.selectedRole) ? React.createElement(AppBar, {
        'className': 'no-flex main-app-bar main-app-bar-gi',
        'theme': { leftIcon: 'menu-icon' },
        'leftIcon': 'menu',
        'onLeftIconClick': this.toggleDrawerActive,
        'title': this.getTitle(),
        'style': { width: '100%' },
        'key': '4990'
    }, React.createElement('div', { 'className': 'd-flex flex-sb' }, React.createElement('a', { 'href': `/practice/${ this.props.selectedRole.practice.id }/home` }, this.props.selectedRole.role.id != 4 ? React.createElement('img', {
        'height': '30',
        'width': 'auto',
        'src': this.props.logo_url,
        'key': '5535'
    }) : null), React.createElement('span', { 'style': { flexGrow: '1' } }), React.createElement('a', {
        'className': 'view-profile',
        'onClick': this.openProfileMenu
    }, '\n                 ', this.profilePicRender(), '\n                 ', React.createElement('span', {}, this.props.user.first_name, ' ', this.props.user.last_name), React.createElement('img', {
        'src': DownIcon,
        'style': { marginTop: '2px' },
        'alt': 'icon'
    })), this.state.openProfileMenu ? React.createElement('div', {
        'className': 'p-t-8 p-b-8 profile-menu',
        'key': '6612'
    }, React.createElement('li', { 'className': 'p-t-10 p-b-10 p-l-15 p-r-15' }, React.createElement('div', {
        'onClick': () => {
            this.pushRoute('profile');
        },
        'className': 'pointer c-black'
    }, '  \n                        View Profile\n                    ')), React.createElement('li', { 'className': 'p-t-10 p-b-10 p-l-15 p-r-15' }, React.createElement('div', {
        'onClick': () => {
            this.pushRoute('email');
        },
        'className': 'pointer c-black'
    }, '  \n                        Change Email\n                    ')), React.createElement('li', { 'className': 'p-t-10 p-b-10 p-l-15 p-r-15' }, React.createElement('a', {
        'onClick': this.doAppLogout,
        'className': 'pointer active-color'
    }, ' \n                        Log out\n                    '))) : null), React.createElement('div', { 'className': 'd-flex align-center width-fit flex-sb p-t-15 p-b-20' }, this.props.user.gi_isadmin ? React.createElement('div', {
        'className': 'hamburger-menu m-r-30',
        'onClick': this.toggleDrawerActive,
        'key': '7603'
    }) : null, React.createElement('p', { 'className': 'welcome-text c-white f-30' }, 'Welcome, ', this.props.user.first_name, ' '))) : null, !this.state.giPro && this.props.isAuthenticated && _.isEmpty(this.props.selectedRole) && this.props.roles.length > 0 ? React.createElement('div', {
        'className': 'row center-xs middle-xs bg-accent',
        'style': { minHeight: '100vh' },
        'key': '7858'
    }, React.createElement(Card, { 'className': 'col-md-6 col-sm-8 col-xs-12 m-t-20 bg-lightgray c-accent' }, React.createElement(CardTitle, {
        'title': 'Choose Role',
        'className': 'p-b-5 bg-lightgray c-accent f-22'
    }), React.createElement.apply(this, [
        CardText,
        { 'className': 'p-t-5' },
        _.map(this.props.roles, repeatRole1.bind(this))
    ]))) : null, !this.state.giPro && this.props.isAuthenticated && (this.props.roles.length > 0 && !_.isEmpty(this.props.selectedRole) || this.props.roles.length === 0) ? React.createElement('div', { 'key': '9107' }, React.createElement(ErrorBoundary, this.props, React.createElement(Routes, {}, React.createElement(Route, {
        'exact': true,
        'path': `/api-login/:token`,
        'component': GIApiLogin,
        'from_authenticated': true
    }), React.createElement(Route, {
        'path': '/guest',
        'render': routeProps => {
            return this.returnGuestApp(routeProps);
        }
    }), PARTNER.subdomain === 'dhf' ? React.createElement(Route, {
        'path': '/hf',
        'render': routeProps => {
            return this.returnHomeFitApp(routeProps);
        },
        'key': '9617'
    }) : null, React.createElement(Route, {
        'path': '/client',
        'render': routeProps => {
            return this.returnClientApp(routeProps);
        }
    }), React.createElement(Route, {
        'path': '/practice/:practice_id',
        'render': routeProps => {
            return this.returnPractionerApp(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': '/change_email',
        'render': routeProps => {
            return this.returnChangeEmailApp(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': '/view_profile',
        'render': routeProps => {
            return this.returnViewProfileApp(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': '/edit_profile',
        'render': routeProps => {
            return this.returnEditProfileApp(routeProps);
        }
    }), React.createElement(Route, {
        'exact': true,
        'path': '/edit_profile_info',
        'render': routeProps => {
            return this.returnEditProfileInfoApp(routeProps);
        }
    }), React.createElement(Redirect, { 'to': '/' })))) : null, this.state.giPro ? React.createElement('div', { 'key': '10685' }, React.createElement(ErrorBoundary, this.props, React.createElement(Routes, {}, React.createElement(Route, {
        'path': '/gipro',
        'render': routeProps => {
            return this.returnGIProApp(routeProps);
        }
    })))) : null), React.createElement(Dialog, {
        'title': 'Help Video',
        'onEscKeyDown': this.closeHelpVideo,
        'onOverlayClick': this.closeHelpVideo,
        'active': this.state.openHelpVideoDialog && this.props.help_video_guid !== null
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(IconButton, {
        'accent': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-45px'
        },
        'className': 'c-white bg-danger pull-right',
        'onClick': this.closeHelpVideo
    })), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 center-xs' }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video' + this.props.help_video_guid,
        'videoList': [this.props.help_video_guid]
    }))))))));
}