import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import { Dialog } from 'react-toolbox/lib/dialog';
import Button from 'react-toolbox/lib/button';
import Avatar from '../../../../utils/common_components/profile_picture';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { history } from '../../../../redux/store';
import TooltipWrapper from '../../../../utils/common_components/tooltipWrapper';
import WelcomeVideo from '../../../../client_app/components/welcome_video';
export default function () {
    return React.createElement('div', { 'className': 'row start-xs c-white' }, React.createElement('div', {
        'className': 'row w-100 text-center',
        'style': { minHeight: '75vh' }
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-30' }, React.createElement('h2', {
        'className': 'di-block p-20',
        'style': { width: '450px' }
    }, 'Weekly Movement Goals')), this.state.show_movement_graph ? React.createElement('div', {
        'className': 'col-xs-12',
        'style': { marginTop: '35px' },
        'key': '1199'
    }, React.createElement('div', {
        'className': 'row center-xs middle-xs',
        'style': { minHeight: '200px' }
    }, React.createElement(TooltipWrapper, {
        'className': 'f-18 truncate pre-wrap',
        'tooltip': this.state.weekly_movement_score_text,
        'tooltipPosition': 'top'
    }, React.createElement('div', {
        'className': 'radial-chart',
        'style': {
            height: '250px',
            width: '250px'
        }
    })))) : null, React.createElement('div', { 'className': 'col-xs-5' }), React.createElement('div', {
        'onClick': () => {
            this.subscribeHomeFit();
        },
        'className': 'col-xs-2 m-t-30 pointer',
        'style': { border: '2px solid white' }
    }, React.createElement('h3', { 'className': 'p-t-20 p-b-20' }, 'SUBSCRIBE'))), this.state.welcome_video ? React.createElement(WelcomeVideo, {
        'onCloseHandle': this.closeWelcomeVideo,
        'welcome_video': this.state.welcome_video,
        'key': '1981'
    }) : null);
}