import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import AppointmentEdit from '../engagement_appointment_edit';
import { Dialog } from 'react-toolbox/lib/dialog';
import moment from 'moment';
export default function () {
    function repeatAppointment1(appointment, appointmentIndex) {
        return [React.createElement('tr', { 'key': '12931' }, React.createElement('td', { 'className': 'text-center' }, appointment.practitioner.name), React.createElement('td', { 'className': 'text-center' }, moment(appointment.calendar.start_time).format('MMMM Do YYYY, h:mm a ')), React.createElement('td', { 'className': 'text-center' }, moment(appointment.calendar.end_time).format('MMMM Do YYYY, h:mm a ')), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
                'onClick': () => {
                    this.showAppointmentEditModal(appointment);
                },
                'tooltip': 'Edit appointment details',
                'icon': 'create',
                'primary': true,
                'floating': true,
                'mini': true
            }), React.createElement(Button, {
                'onClick': () => {
                    this.deleteAppointment(appointment);
                },
                'tooltip': 'Delete appointment',
                'className': 'bg-danger m-l-5',
                'icon': 'delete_forever',
                'floating': true,
                'mini': true
            })))];
    }
    return React.createElement('div', {
        'className': 'row p-10',
        'style': { marginTop: '-17px' }
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'onClick': () => {
            this.showAppointmentEditModal(null);
        },
        'tooltip': 'Add new appointment',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true
    })), React.createElement('table', { 'className': 'table reactable box-shadow col-xs-12' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', { 'className': 'text-center bg-beige' }, 'Appointment with'), React.createElement('th', { 'className': 'text-center bg-beige' }, 'Start Time'), React.createElement('th', { 'className': 'text-center bg-beige' }, 'End Time'), React.createElement('th', { 'className': 'w-20 text-center bg-beige' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        React.createElement('tr', {}, this.props.engagement.appointments.length === 0 ? React.createElement('td', {
            'className': 'p-10',
            'colSpan': '100',
            'key': '1072'
        }, '\n                    Click on + icon to create an appointment for this game plan.\n                ') : null),
        _.map(this.props.engagement.appointments, repeatAppointment1.bind(this))
    ])), React.createElement(Dialog, {
        'active': this.state.openAppointmentEditModal,
        'onEscKeyDown': this.hideAppointmentEditModal,
        'onOverlayClick': this.hideAppointmentEditModal
    }, React.createElement(AppointmentEdit, {
        'engagement': this.props.engagement,
        'appointment': this.state.editAppointmentObject,
        'handleClose': this.hideAppointmentEditModal
    })));
}