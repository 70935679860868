import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import BaseComponent from '../../../utils/common_components/basecomponent';

import Template from "./templates/user_invitation_new.rt";

class UserInvitation extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.inviteUser = this.inviteUser.bind(this);
    };
    
    componentWillMount() {
    }

    componentWillUnmount() {
    }

    inviteUser(data) {
        let params = { ...data };
        this.props.handleInvite(params);
    }

    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state, ownProps) {
    let initialValues = {};
    initialValues['subject'] = ownProps.subject;
    initialValues['body'] = ownProps.body;
    return {
        user: state.auth.authReducer.user,   
        selectedRole: state.auth.roleReducer.selectedRole,     
        initialValues: initialValues,
        handleCancel: ownProps.handleCancel,
        handleInvite: ownProps.handleInvite,
        groups: ownProps.groupList,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
    }, dispatch),
    dispatch
});

const validate = (values, props) => {
    let requiredFields = [
        'email',
        'group',
        'subject',
        'body',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const UserInvitationForm = reduxForm(
    {
        form: 'UserInvitationForm',
        enableReinitialize: true,
        validate
    }
)(UserInvitation);

const UserInvitationFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInvitationForm);
export default UserInvitationFormConnected;
