import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/challenge_workouts";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../../redux/store';
import { getChallenge, publishChallenge, deleteChallengeWorkout } from '../../../redux/dhf/move/network/challenge_network';
import swal from 'sweetalert2';

class ChallengeWorkouts extends BaseComponent {

    constructor(props, context) {
        super(props, context);     
        this.addChallengeWorkout = this.addChallengeWorkout.bind(this); 
        this.editChallengeWorkout = this.editChallengeWorkout.bind(this);    
        this.deleteChallengeWorkout = this.deleteChallengeWorkout.bind(this);          
        this.saveAsDraft = this.saveAsDraft.bind(this);          
        this.saveAndPublish = this.saveAndPublish.bind(this);   
        this.doClose = this.doClose.bind(this);       
    }
    
    componentWillMount() {  
        this.loadChallenge();   
    }

    loadChallenge() {
        this.props.getChallenge({
            challenge_id: this.props.match.params.challenge_id,
        }).then((response) => {            
        });
    }

    addChallengeWorkout() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/challenge/'+this.props.match.params.challenge_id+'/workout/add');
    }

    editChallengeWorkout(workout) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/challenge/'+this.props.match.params.challenge_id+'/workout/edit/'+workout.id);
    }

    saveAsDraft() {
        this.props.publishChallenge({
            challenge_id: this.props.match.params.challenge_id,
            is_publish: false
        }).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/move/challenges');    
        });
    }

    saveAndPublish() {
        this.props.publishChallenge({
            challenge_id: this.props.match.params.challenge_id,
            is_publish: true
        }).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/move/challenges');    
        });
    }

    doClose() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/move/challenges');    
    }


    deleteChallengeWorkout(workout) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You are deleting a workout! Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = { 
                challenge_id: that.props.match.params.challenge_id,
                challenge_workout_id: workout.id
            };
            that.props.deleteChallengeWorkout(params).then(() => {
                that.loadChallenge();
            });
            swal.close();              
        }, function (dismiss) {
                        
        });    
    }

    
    
    render() {
        if(this.props.challenge != null && this.props.challenge.latest_version != null) {
            return Template.apply(this);
        } else {
            return (<div className="row center-xs m-t-25">
                <ProgressBar type='circular' mode='indeterminate' multicolor />
            </div>);
        }
    }
}

function mapStateToProps(state) {    
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        challenge: state.move.challengeReducer.challenge,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({   
            getChallenge,
            publishChallenge,
            deleteChallengeWorkout,     
        }, 
        dispatch
    );
}


const ChallengeWorkoutsConnected = connect(mapStateToProps,mapDispatchToProps)(ChallengeWorkouts);
export default ChallengeWorkoutsConnected;