import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { NavLink } from 'react-router-dom';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from '../../../utils/common_components/dropzone';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Select from '../../../utils/common_components/select';
export default function () {
    return React.createElement('div', { 'className': 'row how-to-videos-edit' }, React.createElement(NavLink, {
        'to': '/practice/' + this.props.selectedRole.practice.id + '/marketing/how-to-videos',
        'className': 'm-t-20 d-flex back-btn c-grey align-center',
        'onClick': this.doCancel
    }, '\n        Back\n    '), React.createElement(Card, { 'className': 'bg-none no-box-shadow w-100 m-t-20' }, React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {
        'className': 'bg-none add-media d-flex no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.saveEducationContent)
    }, React.createElement('div', { 'className': 'media-info p-b-36' }, React.createElement('div', { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(CardTitle, {
        'title': (this.props.match.params.video_id ? 'Edit ' : 'Add new ') + ' How-To Video',
        'className': 'c-black m-b-20 p-b-5'
    }), React.createElement('div', { 'className': 'row col-xs-12' }, this.props.match.params.video_id ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '2099'
    }, React.createElement(Field, {
        'id': 'education_category',
        'name': 'education_category',
        'type': 'text',
        'component': Select,
        'label': 'Education Category',
        'valueKey': 'id',
        'labelKey': 'name',
        'options': this.props.education_categories,
        'disabled': true,
        'clearable': false + '/'
    })) : null, !this.props.match.params.video_id ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '2846'
    }, React.createElement(Field, {
        'id': 'education_category',
        'name': 'education_category',
        'type': 'text',
        'component': Select,
        'label': 'Education Category',
        'valueKey': 'id',
        'labelKey': 'name',
        'options': this.props.education_categories,
        'clearable': false + '/'
    })) : null), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement(Field, {
        'id': 'name',
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'label': 'Name',
        'component': TextInput
    }))), React.createElement('div', { 'className': 'd-flex flex-sb' }, React.createElement('div', { 'className': 'w-48' }, this.state.video_guid ? React.createElement('div', {
        'className': 'm-t-10 box-400-225',
        'style': { width: '300px !important' },
        'key': '4276'
    }, React.createElement(VimeoPlayer, {
        'handleUploadProcessing': true,
        'identifier': 'workfit_video_' + this.state.video_guid,
        'videoList': [this.state.video_guid],
        'onPlayerInitialized': player => {
            this.player = player;
        }
    })) : null, React.createElement('div', { 'className': 'col-xs-6 start-xs m-t-15' }, React.createElement('div', { 'className': 'row' }, React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'buttonText': this.state.video_guid ? 'Change Video' : 'Add Media'
    })))), React.createElement('div', { 'className': 'w-48' }, React.createElement('div', { 'className': 'center-xs' }, React.createElement(CardTitle, {
        'title': 'Thumbnail',
        'className': 'c-black f-14'
    }), React.createElement('div', { 'className': 'f-12 c-grey m-t-8 m-b-8 text-left' }, '\n                                        Select Play on the video and pause on the desired frame.\n                                        Then select "Update Thumbnail".\n                                    '), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, this.props.thumbnail ? React.createElement(Card, {
        'className': 'bg-white p-0 m-l-5 m-r-5 m-b-20',
        'key': '6231'
    }, React.createElement('div', {
        'className': 'thumbnail h-200',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.thumbnail) + ')' }
    })) : null, React.createElement(Button, {
        'className': 'm-b-20 bg-success-red p-l-18 p-r-18 f-14',
        'label': 'Update Thumbnail',
        'onClick': this.captureThumbnail,
        'primary': true,
        'raised': true
    })))))))), React.createElement('div', { 'className': 'publish-info bg-white box-shadow-10 p-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs middle-xs' }, React.createElement(Button, {
        'label': 'Publish',
        'className': 'bg-success w-100',
        'disabled': this.props.submitting,
        'type': 'submit',
        'raised': true
    })))))));
}