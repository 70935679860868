import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { history } from '../../../redux/store';
import HelpTip from '../../../utils/common_components/help_tip';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement(Card, { 'className': 'col-xs-12  p-0' }, React.createElement(CardTitle, { 'title': 'Business Partner Plan' }), React.createElement(CardText, { 'className': 'p-t-5' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', {}, React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    }), React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'plan_payment_type',
        'component': Select,
        'label': 'Plan Payment Type',
        'options': this.props.partner_plan.plan_payment_options,
        'simpleValue': true,
        'clearable': false
    })), this.props.plan_payment_type === 3 ? React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'month_duration_count',
        'type': 'number',
        'component': TextInput,
        'label': '# of months',
        'key': '2321'
    }) : null, React.createElement(Field, {
        'className': 'col-xs-12',
        'name': 'fee_amount',
        'type': 'number',
        'component': TextInput,
        'label': `${ this.props.plan_payment_type === 3 ? 'One time fee' : 'Subscription fee' }`
    }), React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'client_count',
        'component': Select,
        'label': 'Client Count',
        'options': this.props.partner_plan.client_count_options,
        'simpleValue': true,
        'clearable': true
    })), React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'libraries',
        'component': Select,
        'label': 'Libraries shared with this plan',
        'valueKey': 'id',
        'labelKey': 'name',
        'options': this.props.partner_plan.library_source_list,
        'multi': true
    })), React.createElement('div', { 'className': 'col-xs-12 p-0 text-left' }, React.createElement('label', { 'className': 'material-label' }, '\n                                        Private Subscription Plan (Dedicated to a business partner who will only see this and other private plans you assign; your public plans will be hidden)\n                                        ', React.createElement(HelpTip, {}, '\n                                                If marked true, the plan is treated as a special plan which needs to be added manually to an Independent Partner for subscription.\n                                        ')), React.createElement(Field, {
        'className': 'w-100',
        'name': 'available_for_subscription',
        'component': Switch
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, this.props.partner_plan.subscription_count === 0 ? React.createElement(Button, {
        'onClick': this.doPublish,
        'className': 'bg-success',
        'label': 'Save and Publish',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true,
        'key': '5083'
    }) : null, this.props.partner_plan.subscription_count === 0 ? React.createElement(Button, {
        'onClick': this.doDraftSave,
        'className': 'bg-primary m-l-10',
        'label': 'Save As Draft',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true,
        'key': '5391'
    }) : null, React.createElement(Button, {
        'label': 'Cancel',
        'className': 'm-l-10',
        'onClick': () => {
            history.goBack();
        },
        'disabled': this.props.submitting,
        'type': 'button',
        'accent': true,
        'raised': true
    }))))))));
}