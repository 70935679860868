// Action Creators
const fillContentList = (homeFitContentList, paginateInfo) => ({
    type: 'CONTENT_LIST_FILL',
    homeFitContentList,
    paginateInfo
});
const fillHomeFitContent = (homeFitNews) => ({
    type: 'CONTENT_FILL',
    homeFitNews,

});
const fillHomeFitContentFilterData = (filterHomeFitContentList,first) => ({
    type: 'CONTENT_FILTER_DATA_FILL',
    filterHomeFitContentList,
    first
});
const removeHomeFitContentList = (homefitContent) => ({
    type: 'CONTENT_LIST_REMOVE',
    homefitContent
});

export default {
    fillContentList,
    fillHomeFitContent,
    fillHomeFitContentFilterData,
    removeHomeFitContentList
};