import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import RadioInput from '../../../utils/common_components/radio_group_form';
import { Field } from 'redux-form';
import { PARTNER } from '../../../core/constants/constants';
import { Link } from 'react-router-dom';
import TextInput from '../../../utils/common_components/textInput_form';
import Checkbox from '../../../utils/common_components/checkbox_form';
import Checkboxes from '../../../utils/common_components/checkbox_group_form';
import Select from '../../../utils/common_components/select';
import GiLogo from './image.svg';
import { AppBar } from 'react-toolbox/lib/app_bar';
export default function () {
    return React.createElement('div', { 'className': 'd-flex center-xs flex-align-center full-height welcome-aboard' }, React.createElement('div', {}, React.createElement('form', {
        'className': 'bg-white big-labels white-labels onboarding-form',
        'style': { width: '600px' },
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('h1', { 'className': 'c-black p-t-24 center-xs' }, 'Welcome aboard!'), React.createElement('div', {
        'style': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px'
        }
    }, React.createElement('input', {
        'type': 'file',
        'accept': 'image/*',
        'ref': this.fileInputRef,
        'style': { display: 'none' },
        'onChange': this.handleImageChange
    }), React.createElement('div', { 'style': { marginBottom: '20px' } }, '\n                        ', this.renderImage(), '\n                    '), React.createElement('a', {
        'style': {
            padding: '0 12px 0 12px',
            height: '30px',
            borderRadius: '6px',
            border: '1px solid #DB303C',
            cursor: 'pointer',
            fontSize: '12px',
            fontWeight: '600',
            color: '#DB303C',
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Avenir Next Condensed'
        },
        'onClick': this.AddPhoto
    }, 'Add PHOTO')), React.createElement('div', { 'className': 'd-flex flex-sb m-b-20' }, React.createElement(Field, {
        'label': 'Add Company Name',
        'style': {
            width: '48%',
            height: '40px'
        },
        'name': 'company_name',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'Add Bio',
        'style': {
            width: '48%',
            height: '40px'
        },
        'name': 'bio',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    })), React.createElement('div', { 'className': 'm-b-16' }, React.createElement(Field, {
        'className': 'w-100',
        'component': Select,
        'name': 'user_type',
        'valueKey': 'id',
        'labelKey': 'name',
        'onChange': this.onChangeContent,
        'label': 'Are you a',
        'options': this.state.select_options
    })), React.createElement('div', { 'className': 'areas-interests' }, React.createElement('p', { 'style': { textAlign: 'left' } }, 'Your areas of interests'), React.createElement('div', { 'style': { textAlign: 'left' } }, React.createElement(Field, {
        'name': 'areas_of_interest',
        'component': Checkboxes,
        'options': this.state.areas_of_interest
    })), React.createElement('div', {}, '\n            ', console.log('this.props.onboardData', this.props.onboardData ? this.props.onboardData : this.state.radio_options), '\n            ', this.props.onboardData ? React.createElement('div', {
        'className': 'm-b-16',
        'key': '4938'
    }, React.createElement(Field, {
        'className': 'w-100',
        'component': Select,
        'name': 'country',
        'valueKey': 'id',
        'labelKey': 'name',
        'onChange': this.countryChange,
        'label': 'Select country',
        'options': this.props.onboardData && this.props.onboardData.country ? this.props.onboardData.country : this.state.radio_options
    })) : null, this.state.updatedStates.length > 1 ? React.createElement('div', {
        'className': 'm-b-16',
        'key': '5464'
    }, React.createElement(Field, {
        'className': 'w-100',
        'component': Select,
        'name': 'state',
        'valueKey': 'id',
        'labelKey': 'name',
        'onChange': this.onChangeContent,
        'label': 'Select state',
        'options': this.state.updatedStates
    })) : null, React.createElement('div', { 'className': 'm-b-16' }, React.createElement(Field, {
        'className': 'w-100 zipcode',
        'name': 'zipcode',
        'type': 'number',
        'component': TextInput,
        'label': 'Zipcode',
        'hint': 'Zipcode'
    })), React.createElement('div', { 'className': 'alert-container' }, React.createElement('span', { 'className': 'alert-header' }, 'Get found by clients searching for someone like you.'), React.createElement('ul', {}, React.createElement('li', {}, 'Movement Professional Locator makes it easy for clients to find you, so you can focus on what you do best: training and helping others reach their goals.\n                    ', React.createElement('li', {}, 'Get referrals from other GI movement professional for clients who need your expertise.\n                   '))), this.props.user.certificates === [] || this.props.user.is_lms_active === false ? React.createElement('div', {
        'className': 'error-check',
        'key': '8369'
    }, React.createElement('svg', {
        'xmlns': 'http://www.w3.org/2000/svg',
        'width': '40',
        'height': '20',
        'viewBox': '0 0 20 20',
        'fill': 'none'
    }, React.createElement('path', {
        'd': 'M10 2.5C8.51664 2.5 7.0666 2.93987 5.83323 3.76398C4.59986 4.58809 3.63856 5.75943 3.07091 7.12987C2.50325 8.50032 2.35472 10.0083 2.64411 11.4632C2.9335 12.918 3.64781 14.2544 4.6967 15.3033C5.7456 16.3522 7.08197 17.0665 8.53683 17.3559C9.99168 17.6453 11.4997 17.4968 12.8701 16.9291C14.2406 16.3614 15.4119 15.4001 16.236 14.1668C17.0601 12.9334 17.5 11.4834 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5ZM10 16.25C8.76387 16.25 7.5555 15.8834 6.52769 15.1967C5.49988 14.5099 4.6988 13.5338 4.22576 12.3918C3.75271 11.2497 3.62894 9.99307 3.8701 8.78069C4.11125 7.5683 4.70651 6.45466 5.58059 5.58058C6.45466 4.7065 7.56831 4.11125 8.78069 3.87009C9.99307 3.62893 11.2497 3.75271 12.3918 4.22575C13.5338 4.6988 14.5099 5.49988 15.1967 6.52769C15.8834 7.55549 16.25 8.76387 16.25 10C16.25 11.6576 15.5915 13.2473 14.4194 14.4194C13.2473 15.5915 11.6576 16.25 10 16.25Z',
        'fill': '#DB303C'
    }), React.createElement('path', {
        'd': 'M9.99999 8.56244C9.83423 8.56244 9.67526 8.62829 9.55805 8.7455C9.44084 8.86271 9.37499 9.02168 9.37499 9.18744V13.4374C9.37499 13.6032 9.44084 13.7622 9.55805 13.8794C9.67526 13.9966 9.83423 14.0624 9.99999 14.0624C10.1658 14.0624 10.3247 13.9966 10.4419 13.8794C10.5591 13.7622 10.625 13.6032 10.625 13.4374V9.18744C10.625 9.02168 10.5591 8.86271 10.4419 8.7455C10.3247 8.62829 10.1658 8.56244 9.99999 8.56244ZM9.99999 5.99994C9.8308 6.00631 9.67046 6.07721 9.5519 6.19809C9.43334 6.31897 9.36557 6.48066 9.36249 6.64994V6.74994C9.3622 6.83149 9.37888 6.9122 9.41148 6.98695C9.44408 7.06169 9.49188 7.12884 9.55185 7.1841C9.61182 7.23936 9.68263 7.28153 9.75978 7.30793C9.83694 7.33433 9.91874 7.34438 9.99999 7.33744C10.1638 7.33126 10.3192 7.26342 10.4351 7.14753C10.551 7.03163 10.6188 6.87623 10.625 6.71244V6.56244C10.6255 6.48301 10.6091 6.40437 10.5771 6.33171C10.545 6.25904 10.4978 6.194 10.4388 6.14086C10.3798 6.08772 10.3101 6.04768 10.2345 6.0234C10.1589 5.99911 10.0789 5.99112 9.99999 5.99994Z',
        'fill': '#DB303C'
    })), React.createElement('span', {}, this.state.content.warning_check)) : null, this.props.user.certificates === [] || this.props.user.is_lms_active === false ? React.createElement('div', {
        'className': 'note',
        'key': '10759'
    }, React.createElement('span', {}, 'Note:'), React.createElement('p', {}, this.state.content.note)) : null, React.createElement('div', { 'className': 'check-btn' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 coach-label',
        'disabled': this.props.alertCondition,
        'name': 'consented_coach_locator',
        'onChange': this.onCheckBoxValue,
        'component': Checkbox,
        'label': this.state.content.checkbox_label
    }))), React.createElement('div', { 'className': 'row w-100 center-xs p-t-10' }, React.createElement(Button, {
        'label': 'Get Started',
        'disabled': this.props.submitting,
        'type': 'submit',
        'onClick': this.gotoContent,
        'className': 'w-100 bg-success',
        'raised': true
    })))))));
}