import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement(Card, { 'className': 'col-xs-12 card-full-height' }, React.createElement(CardTitle, { 'title': 'Cue' }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.saveCue) }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    }), React.createElement(Field, {
        'id': 'descriptionRichText',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'multiline': true,
        'label': 'Description'
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'm-r-10',
        'disabled': this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }))))))));
}