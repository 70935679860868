import { combineReducers } from 'redux';
import clientListReducer from './client_list_reducer';
import clientReducer from './client_reducer';
import clientEngagementProgressionDatesReducer from './client_engagement_progressions_dates_reducer';
import engagementReducer from './engagement_reducer';
import clientEngagementReducer from './client_engagement_reducer';
import engagementWorkout from './engagement_workout';
import engagementGoalTrackingChartReducer from './engagement_goal_tracking_reducer';
import healthDocument from './health_document';
import engagementHabitReducer from './engagement_habit';

export default combineReducers({
    clientListReducer,
    clientReducer,
    clientEngagementProgressionDatesReducer,
    engagementReducer,
    clientEngagementReducer,
    engagementWorkout,
    engagementGoalTrackingChartReducer,
    healthDocument,
    engagementHabitReducer,
});