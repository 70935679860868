let initialState = {
    practice: {},
    practiceCenter: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_CREATE_FILL":
            return Object.assign({}, state, {
                practice: action.data.practice,
                practiceCenter: action.data.practiceCenter
            });
        case "PRACTICE_CREATE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
