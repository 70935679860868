import React, { useState } from 'react';
import CreatableSelect from "react-select/creatable";




function CreatableSelectComponent(props) {
    const [focus, setFocus] = useState(false)
    const { input, label, options, multi, valueKey, labelKey } = props;
    const { onChange, name, value, onBlur, onFocus } = input
    let userOptions;
    const isValidNewTag = (data) => {
        if (data) {
            let label = data.trim();
            if (label && label.length > 1 && label[0] && label[0] === label[0].toUpperCase()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    if (options) {
        userOptions = options.map((option) => {
            return {
                value: option[valueKey ? valueKey : 'value'],
                label: option[labelKey ? labelKey : 'label']
            }
        })
    }
    return (
        <div style={{ textAlign: "start", position: "relative" }}>
            <label
                style={{ color: focus ? "#DB303C" : "#c0c0c0", fontSize: "14px", position: "absolute", left: "10px", top: "-6px", backgroundColor: "#FFFFFF", zIndex: 9, padding: "0 5px 0 5px" }}
            >{label}</label>
            <CreatableSelect
                name={name}
                value={value}
                isMulti={multi}
                options={userOptions}
                onChange={onChange}
                isValidNewOption={isValidNewTag}
            />
        </div>
    )
}

export default CreatableSelectComponent;