// Action Creators
const fillExerciseList = (contentList, paginateInfo) => ({
    type: 'EXERCISE_LIST_FILL',
    contentList,
    paginateInfo
});
const fillExerciseListCategory = (contentList, paginateInfo) => ({
    type: 'EXERCISE_LIST_FILL_CATEGORY',
    contentList,
    paginateInfo
});
const fillExerciseFilterData = (filterContentList,first) => ({
    type: 'EXERCISE_FILTER_DATA_FILL',
    filterContentList,
    first
});
const fillContentFilterList = (filterContentListFull,first) => ({
    type: 'CONTENT_FILTER_LIST_FILL',
    filterContentListFull,
    first
});
const fillAssociatedContentList = (associatedContentList) => ({
    type: 'ASSOCIATED_CONTENT_DATA_FILL',
    associatedContentList,
});
const selectedContent = (associatedContent) => ({
    type: 'ASSOCIATED_CONTENT_SELECTED',
    associatedContent,
});
const selectedWorkout = (associatedWorkout) => ({
    type: 'ASSOCIATED_WORKOUT_SELECTED',
    associatedWorkout,
});
const removeExerciseList = (exercise) => ({
    type: 'EXERCISE_LIST_REMOVE',
    exercise
});
const addExerciseList = (exercise) => ({
    type: 'EXERCISE_LIST_ADD',
    exercise
});
const updateExerciseListAfterToggleFavorite = (exercise) => ({
    type: 'EXERCISE_LIST_UPDATE_AFTER_TOGGLE_FAVORITE',
    exercise
});
const cleanModule = () => ({
    type: 'EXERCISE_LIST_CLEANUP',
});
const addExerciseToWorkoutCart = (exercise) => ({
    type: 'ADD_EXERCISE_TO_WORKOUT_CART',
    exercise,
});
const clearWorkoutCart = () => ({
    type: 'CLEAR_WORKOUT_CART',
});
const removeItem = (id) => ({
    type: 'REMOVE_ITEM',
    id,
})
;const removeItemWorkout = (id) => ({
    type: 'REMOVE_ITEM_WORKOUT',
    id,
});

export default {
    removeItemWorkout,
    removeItem,
    selectedContent,
    selectedWorkout,
    fillExerciseList,
    fillExerciseListCategory,
    fillExerciseFilterData,
    fillContentFilterList,
    fillAssociatedContentList,
    cleanModule,
    removeExerciseList,
    addExerciseList,
    updateExerciseListAfterToggleFavorite,
    addExerciseToWorkoutCart,
    clearWorkoutCart
};