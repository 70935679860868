// Action Creators
const planSubscriptListFill = (payment_plans) => ({
    type: 'PLAN_SUBSCRIBE_LIST_FILL',
    payment_plans,
});



export default {
    planSubscriptListFill,
    
};