// Action Creators
const homefitSubscriptionLogFill = (data,paginate_info) => ({
    type: 'HOMEFIT_SUBSCRIPTION_LOG_FILL',
    data,
    paginate_info,
});
const GISubscriptionLogFill = (data,paginate_info) => ({
    type: 'GI_SUBSCRIPTION_LOG_FILL',
    data,
    paginate_info,
});
const subscriptionListFill = (subscription_list) => ({
    type: 'SUBSCRIPTION_LIST_FILL',
    subscription_list,
});

export default {
    homefitSubscriptionLogFill,
    GISubscriptionLogFill,
    subscriptionListFill,
};