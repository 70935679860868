import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_list.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {getPartnerDetails,getPracticePartnerList, activatePartner} from '../../redux/partner/network/partner_network';
import partnerActions from '../../redux/partner/actions/partner_action';
import Swal from 'sweetalert2';

class PartnerList extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addPartner = this.addPartner.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onFilter = this.onFilter.bind(this);     
        this.editPracticePartner = this.editPracticePartner.bind(this);
        this.activatePracticePartner = this.activatePracticePartner.bind(this);
        this.closePracticePartnerDialog = this.closePracticePartnerDialog.bind(this);
        this.closePracticePartnerDialogAndRefresh = this.closePracticePartnerDialogAndRefresh.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.searchPartners = this.searchPartners.bind(this);
        this.state = {
            selected_practice_partner: null,
            search_text: null
        };
    };
    
    componentWillMount() {
        this.props.getPracticePartnerList(this.props.paginate_info);
        this.props.getPartnerDetails({});
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    onFilter(nextProps){
        this.props.getPracticePartnerList({
            page:0,
            sort_by:this.props.paginate_info.sort_by,
            asc:this.props.paginate_info.asc,
            filters: { ...nextProps.additionalFilters },
            search_text: this.state.search_text
        });
    }    
    onPageChange(page){
        this.props.getPracticePartnerList({page:page,
            sort_by:this.props.paginate_info.sort_by,
            asc:this.props.paginate_info.asc,
            filters: { ...this.props.paginate_info.filters },
            search_text: this.state.search_text
        });
    }    
    onSort(sortData){
        let asc = false;
        if(sortData.direction === 1){
            asc = true;
        }
        this.props.getPracticePartnerList({
            page: 0,
            sort_by:sortData.column,
            asc:asc,
            filters: { ...this.props.paginate_info.filters },
            search_text: this.state.search_text
        });
    } 

    updateSearchText(value) {
        this.setState({
            search_text: value
        });
    }

    searchPartners(e) {
        if (e.key === "Enter") {
            this.props.getPracticePartnerList({
                page: 0,
                sort_by:this.props.paginate_info.sort_by,
                asc:this.props.paginate_info.asc,    
                search_text: this.state.search_text
            });    
        }
    }

    addPartner(){
        if(!this.props.partner.is_payment_gateway_ready){
            Utils.showWarningAlert("Please configure your stripe account and move it to 'LIVE' mode before adding business partners.   ");
            return false;
        }

        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/business_partners/add");
    }

    editPracticePartner(practice_partner) {
        this.setState({
            selected_practice_partner: practice_partner,
        });
    }

    activatePracticePartner(practice_partner_id, status) {
        this.props.activatePartner({
            activate_partner_id: practice_partner_id,
            activation_status: status
        });
    }

    closePracticePartnerDialog() {
        this.setState({
            selected_practice_partner: null,
        });
    }

    closePracticePartnerDialogAndRefresh() {
        this.props.getPracticePartnerList({
            page: this.props.paginate_info.page,
            sort_by:this.props.paginate_info.sort_by,
            asc:this.props.paginate_info.asc,
            filters: {...this.props.paginate_info.filters},
            search_text: this.state.search_text
        }).then((response) => {   
            this.setState({
                selected_practice_partner: null,
            });    
        }).catch((error)=>{
        });
    }

    render() {
        if(_.isEmpty(this.props.partner)){
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerDetails,
        getPracticePartnerList,
        activatePartner,
        cleanUp:partnerActions.cleanUp
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        partner: state.partner.partnerReducer.partner,
        partner_plans: state.partner.partnerReducer.partner_plans,
        practice_partners:state.partner.partnerReducer.practice_partners,
        paginate_info:state.partner.partnerReducer.practice_partners_paginate_info,
    };
}
const PartnerListConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerList);
export default PartnerListConnected;
