let initialState = {
    settings: {},
    supportingData: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_PRACTITIONER_METRIC_SETTINGS_FILL":
            return Object.assign({}, state, {
                settings: action.settings,
                supportingData: action.supportingData
            });
        case "PRACTICE_PRACTITIONER_METRIC_SETTINGS_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
