import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';

import Template from "./templates/wrapper_video_list.rt";
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { getWrapperVideoList, deleteWrapperVideo } from '../../../redux/practice/network/practice_wrapper_video';
import Swal from 'sweetalert2';

class WrapperVideoList extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.addWrapperVideo = this.addWrapperVideo.bind(this);
        this.editWrapperVideo = this.editWrapperVideo.bind(this);
        this.deleteWrapperVideo = this.deleteWrapperVideo.bind(this);
    };
    
    componentWillMount() {
        this.props.getWrapperVideoList();
    }

    componentWillUnmount() {
    }

    addWrapperVideo() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/wrapper/add');
    }

    editWrapperVideo(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/wrapper/edit/'+id);
    }

    deleteWrapperVideo(wrapper_id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete this media?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            let params = {};
            params['wrapper_id'] = wrapper_id;
            that.props.deleteWrapperVideo(params).then((response) => {
                this.props.getWrapperVideoList();
            }).catch(() => { });
        },() => {});
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        wrapper_videos: state.practice.practiceWrapperVideo.wrapper_videos,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getWrapperVideoList,
            deleteWrapperVideo,
        }, 
        dispatch
    );
}

const WrapperVideoListConnected = connect(mapStateToProps,mapDispatchToProps)(WrapperVideoList);
export default WrapperVideoListConnected;