// Action Creators
const clientGroupListFill = (group_list) => ({
    type: 'CLIENT_GROUP_LIST_FILL',
    group_list: group_list
});

const clientGroupGetFill = (group, clients) => ({
    type: 'CLIENT_GROUP_GET_FILL',
    group: group,
    clients: clients
});

const clientGroupGoalTargetsFill = (goal_targets) => ({
    type: 'CLIENT_GROUP_GOAL_TARGETS_FILL',
    goal_targets: goal_targets,
});

const clientGroupCleanup = () => ({
    type: 'CLIENT_GROUP_CLEANUP',
});

export default {
    clientGroupListFill,
    clientGroupGetFill,
    clientGroupGoalTargetsFill,
    clientGroupCleanup,
};
