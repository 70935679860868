let initialState = {
    stripeAccountDetails: {},
    stripe_url: null,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "STRIPE_CONFIG_FILL":
            if(!action.stripe_url && !action.stripe_account_details) {
                return state;
            }
            return Object.assign({}, state, {
                stripe_url: action.stripe_url,
                stripeAccountDetails: action.stripe_account_details,
            });
        case "STRIPE_CONFIG_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};