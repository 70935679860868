import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../../utils/common_components/tooltipButton';
import { Table } from '../../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GroupClassBooking from '../../group_class_booking.jsx';
export default function () {
    function repeatFacility1(facility, facilityIndex) {
        return React.createElement(Tr, { 'key': 'row' + facilityIndex }, React.createElement(Td, {
            'column': 'name',
            'value': facility.name
        }, React.createElement('span', {}, facility.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '500px' }
        }, React.createElement('span', {}, facility.description)), React.createElement(Td, {
            'column': 'practice',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, facility.practice.name)), React.createElement(Td, {
            'column': 'max_client_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, facility.max_client_count)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, React.createElement(Button, {
            'onClick': () => {
                this.viewFacilitySlots(facility);
            },
            'tooltip': 'Book Studio Facility',
            'icon': 'date_range',
            'label': 'Book',
            'className': 'm-r-5 small-button',
            'primary': true,
            'raised': true
        }))));
    }
    return React.createElement('div', { 'className': 'm-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'No Studio facilities are available for booking',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { maxWidth: '500px' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, {
            'column': 'practice',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Practice')), React.createElement(Th, {
            'column': 'max_client_count',
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Max client count')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.facilityList, repeatFacility1.bind(this))
    ]));
}