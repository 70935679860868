import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
export default function () {
    return React.createElement('div', { 'className': 'notification-temp-edit row' }, React.createElement(Button, {
        'className': 'm-t-20 d-flex back-btn c-grey',
        'onClick': this.doCancel
    }, '\n        Back\n    '), React.createElement(Card, {
        'className': 'w-100 m-t-10 m-r-20 no-box-shadow bg-none',
        'style': { background: 'none !important' }
    }, React.createElement(CardText, { 'className': 'p-t-5 card-full-height no-box-shadow' }, React.createElement('form', {
        'className': 'bg-none d-flex bg-white no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.saveWorkfitNotificationTemplate)
    }, React.createElement('div', { 'className': 'add-info box-shadow-10' }, React.createElement(CardTitle, {
        'title': 'Edit Notification Template',
        'className': 'c-black m-b-10 p-b-5'
    }), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'notification_text',
        'label': 'Notification Text',
        'multiline': true,
        'className': 'w-100 p-l-10',
        'name': 'notification_text',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick,
        'rows': 6
    })))), React.createElement('div', { 'className': 'publish-info' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs middle-xs' }, React.createElement(Button, {
        'label': 'Publish',
        'className': 'w-100 bg-success m-r-10',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'submit',
        'raised': true
    }))))))));
}