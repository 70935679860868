import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import ClientEngagementCreator from '../actions/client_engagement_creator';
import EngagementGoalTrackingChartCreator from '../actions/engagement_goal_tracking_creator';
import EngagementProgressionDateCreator from '../actions/client_engagement_progression_dates_creator';

const getClientEngagementList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_list";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientEngagementCreator.clientEngagementListFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientEngagementInstance = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_get";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientEngagementCreator.clientEngagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const assignGoalsToEngagement = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_goal_update";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientEngagementCreator.clientEngagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully updated goals.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const connectGuestToPractice= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_connect_guest";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully Connected.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getGoalTrackingChartDetails = (params) => {
    return (dispatch) => {
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_goal_tracking_chart";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementGoalTrackingChartCreator.engagementGoalTrackingChartFill(params.goal_track_id,response.data));
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientProgressionDatesList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_workout_progressions_list";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementProgressionDateCreator.fillProgressionDatesList(
                response.all_dates, response.date_workouts, response.ongoing_workouts, response.date_habits
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};

const getClientProgressionPerDate= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_workout_progressions_per_date";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementProgressionDateCreator.fillProgressionDateInstance(
                response.progressions, response.habits, response.engagement
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};

const getClientGoalTargetDetailsForm= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_goal_tracking_get";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementGoalTrackingChartCreator.engagementGoalTrackingFormFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};
const postClientGoalTargetDetailsForm= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_goal_tracking_post";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientEngagementCreator.clientEngagementInstanceFill(response.data));
            Utils.showSuccessAlert("Successfully tracked your goal");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};
const postClientIntakeForm= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_intake_submit";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientEngagementCreator.clientEngagementInstanceFill(response.data));
            Utils.showSuccessAlert("Successfully submitted intake form");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};

const assignGoalToEngagement= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_assign_goal";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientEngagementCreator.clientEngagementInstanceFill(response.data));
            Utils.showSuccessAlert("Your practitioner has been notified of your selected goal.");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};
const setEngagementWorkoutTimings= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_set_workout_timings";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientEngagementCreator.clientEngagementInstanceFill(response.data));
            Utils.showSuccessAlert("Workout Timings successfully updated");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};

const engagementTrackHabit= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_engagement_track_habit";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Your habit for the current selected date has been tracked successfully");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};


export {
    getClientEngagementList,
    getClientEngagementInstance,
    assignGoalsToEngagement,
    getGoalTrackingChartDetails,
    getClientProgressionDatesList,
    getClientProgressionPerDate,
    getClientGoalTargetDetailsForm,
    postClientGoalTargetDetailsForm,
    postClientIntakeForm,
    assignGoalToEngagement,
    engagementTrackHabit,
    connectGuestToPractice,
    setEngagementWorkoutTimings
};