

let initialState = {
    exerciseList: [],
    paginateInfo: {
        page: 0,
    },
    exerciseFilters: [],
    filterData:{},
    searchPreferences:{},
    workoutCartExeciseList: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "EXERCISE_LIST_FILL":
            if(!action.exerciseList) {
                return state;
            }
            let exercises = [];
            if(action.paginateInfo && action.paginateInfo.page ===0 ){
                exercises = action.exerciseList;
            }else{
                let newIds = _.map(action.exerciseList, (item) => { return item.id; });
                let oldRetain = _.filter(state.exerciseList, (item) => { return newIds.indexOf(item.id) === -1; });
                exercises = oldRetain.concat(action.exerciseList);
            }
            return Object.assign({}, state, {
                exerciseList: exercises,
                paginateInfo: action.paginateInfo
            });
        case "EXERCISE_FILTERS_LIST_FILL":
            if(!action.filters) {
                return state;
            }        
            if(action.first){
                return Object.assign({}, state, {
                    exerciseFilters: action.filters,
                });
            }else{
                return Object.assign({}, state, {
                    exerciseFilters: action.filters
                });
            }      
        case "EXERCISE_FILTER_DATA_FILL":
            if(!action.filterData) {
                return state;
            }        
            if(action.first){
                return Object.assign({}, state, {
                    filterData: action.filterData,
                    searchPreferences:{...action.filterData.search_params}
                });
            }else{
                return Object.assign({}, state, {
                    filterData: action.filterData
                });
            }

        case "EXERCISE_LIST_ADD":
            if(!action.exercise) {
                return state;
            }
            let addExerciseList = [action.exercise,...state.exerciseList];
            return Object.assign({}, state, {
                exerciseList: addExerciseList,
                paginateInfo: state.paginateInfo

            });
        case "EXERCISE_LIST_REMOVE":
            let newExerciseList = _.filter(state.exerciseList, function(exerciseObj) {
                if(exerciseObj.id !== action.exercise.id) {
                    return true;
                } else {
                    return false;
                }
            });
            return Object.assign({}, state, {
                exerciseList: newExerciseList,
                paginateInfo: state.paginateInfo
            });
        case "EXERCISE_LIST_UPDATE_AFTER_TOGGLE_FAVORITE":
            if(!action.exercise) {
                return state;
            }
            let toggleExerciseList = _.map(state.exerciseList, (item) => {
                if (item.id === action.exercise.id) {
                    return action.exercise;
                } else {
                    return item;
                }
            });
            return Object.assign({}, state, {
                exerciseList: toggleExerciseList,
                paginateInfo: state.paginateInfo
            });

        case "EXERCISE_LIST_CLEANUP":
            return Object.assign({}, initialState);
        case "ADD_EXERCISE_TO_WORKOUT_CART":
            if(!action.exercise) {
                return state;
            }
            let exerciseObj = {...action.exercise};
            exerciseObj["exercise_id"] = action.exercise["id"];
            exerciseObj["id"] = null;
            exerciseObj["sets"] = 1;
            exerciseObj["reps"] = 10;
            exerciseObj["distance"] = 1;
            exerciseObj["duration"] = 1;
            if(state.workoutCartExeciseList.length == 0) {
                exerciseObj["order"] = 0;
            } else {
                exerciseObj["order"] = state.workoutCartExeciseList.length;
            }
            let newWorkoutCartExerciseList = [...state.workoutCartExeciseList];
            newWorkoutCartExerciseList.push(exerciseObj)
            return Object.assign({}, state, {
                workoutCartExeciseList: newWorkoutCartExerciseList
            });
        case "CLEAR_WORKOUT_CART":            
            return Object.assign({}, state, {
                workoutCartExeciseList: []
            });
        default:
            return state;
    }
};