// Action Creators
const stripeConfigFill = (stripe_url,stripe_account_details) => ({
    type: 'STRIPE_CONFIG_FILL',
    stripe_url,
    stripe_account_details,
});
const cleanModule = () => ({
    type: 'STRIPE_CONFIG_CLEANUP',
});

export default {
    stripeConfigFill,
    cleanModule,
};