// Action Creators
const partnerInstanceFill = (partner, partner_plans, invitation_email) => ({
	type: 'BUSINESS_PARTNER_INSTANCE_FILL',
	partner,
	partner_plans,
    invitation_email,
});
const practicePartnerListFill = (practice_partners, practice_partners_paginate_info) => ({
    type: 'BUSINESS_PARTNER_PRACTICE_PARTNER_LIST',
    practice_partners,
    practice_partners_paginate_info,
});
const authorListFill = (authors, ) => ({
    type: 'AUTHOR_LIST',
    authors,
});
const partnerLibraryFill = (partner_library) => ({
    type: 'BUSINESS_PARTNER_LIBRARY_FILL',
    partner_library,
});
const partnerLibraryExerciseAdd = (exercise) => ({
    type: 'BUSINESS_PARTNER_LIBRARY_EXERCISE_ADD',
    exercise
});
const partnerLibraryExerciseRemove = (exercise) => ({
    type: 'BUSINESS_PARTNER_LIBRARY_EXERCISE_REMOVE',
    exercise
});
const cleanUp = () => ({
    type: 'BUSINESS_PARTNER_LIBRARY_CLEANUP'
});

export default {
    authorListFill,
    partnerInstanceFill,
    partnerLibraryFill,
    partnerLibraryExerciseAdd,
    partnerLibraryExerciseRemove,
    practicePartnerListFill,
    cleanUp
};