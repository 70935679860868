import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import { WORKOUT_TARGET_GROUP } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import GoalEdit from '../goal_edit';
import ProgramEdit from '../../../program/components/program_edit';
import ProgramList from '../../../program/components/program_list';
import HealthDocumentList from '../../../documents/components/document_list';
import Chip from 'react-toolbox/lib/chip';
import GroupClientGoalTrackingGraph from '../group_goal_tracking_graph.jsx';
import DefinePlan from '../group_define_plan.jsx';
import ClientGoalTargetEdit from '../client_goal_target_edit';
import ClientPayment from '../../../payment/components/client_payment';
import WorkoutProgressions from '../../../client_app/components/client_checkin_list';
import GoalTrack from '../client_group_goal_tracking';
import WelcomeVideo from '../../../client_app/components/welcome_video';
export default function () {
    function repeatMissed_date1(missed_date, missed_dateIndex) {
        return React.createElement(Chip, {
            'onClick': () => {
                this.showGoalTrackingModal(missed_date);
            },
            'className': 'bg-danger box-shadow pointer',
            'key': missed_dateIndex
        }, '\n                                        ', moment(missed_date).format('MMMM Do YYYY'), '\n                                    ');
    }
    function repeatGoal_question2(goal_question, goal_questionIndex) {
        return React.createElement('div', {
            'className': 'panel m-t-10 col-xs-12 p-0',
            'key': 'goal_question' + goal_questionIndex
        }, goal_question.question_deleted_date ? React.createElement('div', {
            'className': 'panel-heading bg-danger panel-bottom-border',
            'key': '5968'
        }, React.createElement('h5', { 'className': 'panel-title' }, goal_questionIndex + 1, '. ', goal_question.question, ' (Metric has been deleted)')) : null, !goal_question.question_deleted_date ? React.createElement('div', {
            'className': 'panel-heading bg-beige panel-bottom-border',
            'key': '6306'
        }, React.createElement('h5', { 'className': 'panel-title' }, goal_questionIndex + 1, '. ', goal_question.question)) : null, React.createElement('div', { 'className': 'panel-body p-0 row m-0' }, React.createElement('div', {
            'className': 'col-xs-12 col-md-8',
            'style': { borderRight: '2px dashed #6C7F8D' }
        }, React.createElement('div', {
            'style': {
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            },
            'className': 'text-center p-relative'
        }, goal_question.initial_value !== null || goal_question.target_value !== null ? React.createElement(GroupClientGoalTrackingGraph, {
            'group': this.props.group,
            'group_goal_client_question': goal_question,
            'client': this.props.user,
            'key': '7001'
        }) : null)), React.createElement('div', { 'className': 'col-xs-12 col-md-4 text-center' }, React.createElement('div', {
            'className': 'row middle-xs text-center',
            'style': { height: '250px' }
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'tooltip': 'Baseline Value',
            'className': 'bg-accent f-18 cursor-default',
            'label': goal_question.initial_value !== null ? goal_question.initial_value.toString() : '-',
            'floating': true
        }), ' ', goal_question.metric_unit.unit_name, '\n                                                    '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'tooltip': 'Current Value',
            'className': 'bg-primary f-18 cursor-default',
            'label': goal_question.current_value !== null ? goal_question.current_value.toString() : '-',
            'floating': true
        }), ' ', goal_question.metric_unit.unit_name, '\n                                                    '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'tooltip': 'Target Value',
            'className': 'bg-success f-18 cursor-default',
            'label': goal_question.target_value !== null ? goal_question.target_value.toString() : '-',
            'floating': true
        }), ' ', goal_question.metric_unit.unit_name, '\n                                                    ')))));
    }
    return React.createElement('div', { 'className': 'row p-15 m-b-25' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'panel m-b-0' }, React.createElement('div', { 'className': 'panel-body p-t-10' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement(Avatar, {
        'className': 'pointer',
        'size': 65,
        'user': {
            name: this.props.group.name,
            avatar_url: this.props.group.logo_url
        },
        'profile_type': 'circle'
    }), React.createElement('div', { 'className': 'flex-auto m-l-5' }, React.createElement('h5', { 'className': 'm-b-2' }, this.props.group.name), React.createElement('div', {}, this.props.group.owner.name), React.createElement('div', { 'className': 'f-12 c-accent-light' }, ' ', moment(this.props.group.created_date).format('MMMM Do YYYY '))))))), React.createElement(Tabs, {
        'inverse': true,
        'fixed': true,
        'className': 'custom-tab accent col-xs-12',
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'label': 'Goal',
        'icon': 'gm-goal',
        'gomotive': true
    }, !this.props.group.goal ? React.createElement('div', {
        'className': 'alert-warning text-center m-30',
        'key': '3156'
    }, '\n                Goal has not been assigned to this group by your practitioner\n            ') : null, this.props.needs_goal_tracking_target_update ? React.createElement('div', { 'key': '3361' }, React.createElement('div', { 'className': 'p-10 f-16 alert-warning m-b-10' }, '\n                            Please update the baseline and target values below\n                        '), React.createElement(ClientGoalTargetEdit, { 'group': this.props.group })) : null, this.props.group.goal && !this.props.needs_goal_tracking_target_update ? [
        this.props.group.client_untracked_goal_dates.length > 0 ? React.createElement('div', {
            'className': 'row w-100 center-xs p-15',
            'key': '37221'
        }, React.createElement('h4', { 'className': 'c-dark text-left' }, '\n                                Goal - Not tracked\n                            '), React.createElement('div', { 'className': 'alert-warning m-t-15 w-100 text-center' }, '\n                                List of dates where goals have not been tracked. Goal completion summary will be displayed once you finish tracking.\n                            '), React.createElement('div', { 'className': 'triangle-with-shadow auto-margin' }), React.createElement('div', { 'className': 'row w-100 center-xs' }, React.createElement.apply(this, [
            'div',
            { 'className': 'col-xs-5 p-20 search-box text-left' },
            React.createElement('label', { 'className': 'd-block m-b-10' }, 'Click on each date below to track your goals.'),
            _.map(this.props.group.client_untracked_goal_dates, repeatMissed_date1.bind(this))
        ]))) : null,
        this.props.group.client_untracked_goal_dates.length === 0 ? React.createElement('div', { 'key': '37223' }, React.createElement('h4', { 'className': 'c-dark' }, '\n                            ', this.props.group.goal.text, '              \n                        '), React.createElement.apply(this, [
            'div',
            { 'className': 'row' },
            _.map(this.props.group.goal.client_goal_questions, repeatGoal_question2.bind(this))
        ])) : null
    ] : null), React.createElement(Tab, {
        'label': 'Program',
        'icon': 'gm-treatment',
        'gomotive': true
    }, !this.props.group.show_treatment ? React.createElement('div', {
        'className': 'full-panel',
        'key': '9827'
    }, React.createElement('div', { 'className': 'alert-warning w-100' }, ' \n                            This group\'s program is currently not visible as it is associated with a payment plan.\n                            Kindly subscribe to payment plan to start viewing program details.\n                         '), React.createElement(ClientPayment, {
        'subscribeSuccessCallback': this.subscribeSuccessCallback,
        'current_plan': this.props.group.client_payment,
        'choose_plan': this.props.group.payment_plan
    })) : null, this.props.group.show_treatment ? React.createElement('div', { 'key': '10519' }, React.createElement(WorkoutProgressions, { 'group_id': this.props.group.id })) : null)), this.state.selectedTrackingData !== null ? React.createElement(Dialog, {
        'active': this.state.openGoalTrackingModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideGoalTrackingModal,
        'onOverlayClick': this.hideGoalTrackingModal,
        'key': '10718'
    }, React.createElement(GoalTrack, {
        'group': this.props.group,
        'track_date': this.state.selectedTrackingData,
        'handleClose': this.hideGoalTrackingModal
    })) : null, this.state.show_welcome_video ? React.createElement(WelcomeVideo, {
        'onCloseHandle': this.closeWelcomeVideo,
        'welcome_video': this.props.group.show_welcome_video,
        'key': '11094'
    }) : null);
}