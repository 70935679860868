let initialState = {
    nutritionDocuments: [],
    guidanceDocuments: [],
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "ENGAGEMENT_DOCUMENTS_NUTRITION_FILL":
            return Object.assign({}, state, {
                nutritionDocuments: action.documents,
            });
        case "ENGAGEMENT_DOCUMENTS_GUIDANCE_FILL":
            return Object.assign({}, state, {
                guidanceDocuments: action.documents,
            });
        case "ENGAGEMENT_DOCUMENTS_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
