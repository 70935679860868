// Action Creators
const fillMetricSettings = (settings, supportingData) => ({
    type: 'PRACTICE_PRACTITIONER_METRIC_SETTINGS_FILL',
    settings,
    supportingData,
});
const cleanModule = () => ({
    type: 'PRACTICE_PRACTITIONER_METRIC_SETTINGS_CLEANUP',
});

export default {
    fillMetricSettings,
    cleanModule,
};
