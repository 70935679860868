import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';
import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import HabitTemplateCreator from '../actions/habit_template_creator';

const getHabitTemplateList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/habit_template_list";
        return Axios.post(url, params).then((response) => {
            dispatch(HabitTemplateCreator.habitTemplateListFill(
                response.habit_templates,
                response.paginate_info
            ));
            dispatch(hideProgress());            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveHabitTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/habit_template_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getHabitTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/habit_template_get";
        return Axios.post(url, params).then((response) => {
            dispatch(HabitTemplateCreator.habitTemplateFill(
                response.habit_template,
                response.supporting_data,
            ));
            dispatch(hideProgress());            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const deleteHabitTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/habit_template_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    getHabitTemplateList,
    saveHabitTemplate,
    getHabitTemplate,
    deleteHabitTemplate,
};