// Action Creators
const cueListFill = (cue_list) => ({
    type: 'CUE_LIST_FILL',
    cue_list,
});
const cueFill = (cue) => ({
    type: 'CUE_FILL',
    cue,
});
const cleanModule = () => ({
    type: 'CUE_LIST_CLEANUP',
});

export default {
    cueListFill,
    cueFill,
    cleanModule,
};