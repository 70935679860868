import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { NavLink } from 'react-router-dom';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { DatePicker } from 'react-toolbox/lib/date_picker';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Checkbox from '../../../utils/common_components/checkbox_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from '../../../utils/common_components/dropzone';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Select from '../../../utils/common_components/select';
import { FieldArray } from 'redux-form';
export default function () {
    return React.createElement('div', { 'className': 'row homefit-workout-edit' }, React.createElement(NavLink, {
        'to': true,
        'className': 'm-t-20 m-b-20 d-flex back-btn c-grey',
        'onClick': this.doCancel
    }, '\n        HomeFit / Workouts\n    '), React.createElement(Card, { 'className': 'no-box-shadow m-r-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', { 'className': 'add-media d-flex no-box-shadow' }, React.createElement('div', { 'className': 'media-info p-b-36' }, React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(CardTitle, {
        'title': (this.props.match.params.workout_id ? 'Edit ' : 'Add ') + ' Workout',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement(Field, {
        'label': 'Title',
        'id': 'name',
        'className': 'w-100 m-b-20',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'rows': 4,
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), React.createElement('div', { 'className': 'd-flex w-100 flex-sb m-b-25' }, React.createElement('div', { 'className': 'w-48' }, React.createElement('h4', { 'className': 'c-black  form-subtitle f-18 w-100' }, 'Workout Video'), this.state.video_guid ? React.createElement('div', {
        'className': 'm-t-10 box-300-225',
        'key': '3057'
    }, React.createElement(VimeoPlayer, {
        'handleUploadProcessing': true,
        'identifier': 'move_video_' + this.state.video_guid,
        'videoList': [this.state.video_guid],
        'onPlayerInitialized': player => {
            this.player = player;
        }
    })) : null, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(VimeoUploader, {
        'className': 'text-center video-uploader add-media-btn',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'buttonText': this.props.video_guid ? 'Change Video' : 'Upload Video'
    })), React.createElement('div', { 'className': 'center-xs' }, React.createElement(CardTitle, {
        'title': 'Thumbnail',
        'className': 'c-black f-14'
    }), React.createElement('div', { 'className': 'f-12 c-grey m-t-8 m-b-8 text-left' }, '\n                                    Select Play on the video and pause on the desired frame.\n                                    Then select "Update Thumbnail".\n                                '), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, this.props.thumbnail_url ? React.createElement(Card, {
        'className': 'bg-white p-0 m-l-5 m-r-5 m-b-20',
        'key': '4701'
    }, React.createElement('div', {
        'className': 'thumbnail h-200',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.thumbnail_url) + ')' }
    })) : null, React.createElement(Button, {
        'className': 'm-b-20 bg-success-red p-l-18 p-r-18 f-14',
        'label': 'Update Thumbnail',
        'onClick': this.captureThumbnail,
        'primary': true,
        'raised': true
    }))))), React.createElement('div', { 'className': 'w-48' }, React.createElement('h4', { 'className': 'c-black  form-subtitle f-18 w-100' }, 'Intro Video'), this.state.intro_video_guid ? React.createElement('div', {
        'className': 'm-t-10 box-300-225',
        'key': '5703'
    }, React.createElement(VimeoPlayer, {
        'handleUploadProcessing': true,
        'identifier': 'move_video_' + this.state.intro_video_guid,
        'videoList': [this.state.intro_video_guid]
    })) : null, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(VimeoUploader, {
        'className': 'text-center video-uploader add-media-btn',
        'onUploadCompleted': this.onIntroVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onIntroVideoUploadFailed,
        'buttonText': this.props.video_guid ? 'Change Video' : 'Upload Video'
    })))), React.createElement('div', { 'className': 'w-100 d-flex m-b-25 align-center' }, !this.state.changeCoach ? React.createElement('div', {
        'className': 'coach-field m-r-16 p-relative w-48',
        'key': '6779'
    }, React.createElement(Field, {
        'label': 'Coach',
        'className': 'w-100',
        'name': 'trainer[practice_name]',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    })) : null, !this.state.changeCoach ? React.createElement('div', {
        'className': 'w-20',
        'key': '7320'
    }, React.createElement(Button, {
        'label': this.props.match.params.workout_id ? 'Change Coach' : 'Add Coach',
        'disabled': this.props.submitting,
        'className': 'm-l-10 bg-success-red small-button text-capital c-white w-100',
        'onClick': this.changeCoach,
        'style': { width: 'fit-content !important' }
    })) : null, this.state.changeCoach ? React.createElement('div', {
        'className': 'w-100 m-r-16',
        'key': '7774'
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'trainer',
        'component': Select,
        'label': 'Coach',
        'options': this.props.authors,
        'valueKey': 'id',
        'labelKey': 'practice_name',
        'returnValue': 'object',
        'onChange': this.selectTrainer,
        'clearable': false
    })) : null), React.createElement('div', { 'className': 'w-100 d-flex m-b-25' }, React.createElement('div', { 'className': 'w-48 m-r-16 input-padding' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'workout_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Workout Duration'
    })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'workout_type',
        'component': Select,
        'label': 'Workout Type',
        'options': this.props.supporting_data.workout_type,
        'labelKey': 'label',
        'valueKey': 'value',
        'clearable': false
    }))), React.createElement('div', { 'className': 'w-100 d-flex m-b-25' }, React.createElement('div', { 'className': 'w-48 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'trainer_level',
        'component': Select,
        'label': 'Training Level',
        'labelKey': 'label',
        'valueKey': 'value',
        'options': this.props.supporting_data.trainer_level,
        'clearable': false
    }))), React.createElement('div', { 'className': 'col-xs-12 m-b-25 input-padding' }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'equipments',
        'labelKey': 'name',
        'valueKey': 'id',
        'component': Select,
        'multi': true,
        'label': 'Equipment',
        'options': this.props.supporting_data.equipments,
        'clearable': false
    })), React.createElement('div', { 'className': 'w-100 d-flex m-b-25' }, React.createElement('div', { 'className': 'w-33 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'sports',
        'labelKey': 'name',
        'valueKey': 'id',
        'component': Select,
        'label': 'Focus',
        'options': this.props.supporting_data.sports,
        'clearable': false
    })), React.createElement('div', { 'className': 'w-33 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'labelKey': 'name',
        'valueKey': 'id',
        'name': 'movement_meter_type',
        'component': Select,
        'label': 'Movement Meter Type',
        'options': this.props.supporting_data.movement_meter_type,
        'clearable': false
    })), React.createElement('div', { 'className': 'w-33' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'labelKey': 'full_name',
        'valueKey': 'id',
        'name': 'movement_meter_sub_type',
        'component': Select,
        'label': 'Movement Meter Sub Type',
        'options': this.props.supporting_data.movement_meter_sub_type,
        'clearable': false
    }))), React.createElement('div', { 'className': 'col-xs-12 start-xs m-b-30' }, React.createElement('label', { 'className': 'c-black form-subtitle f-18 w-100' }, 'Contribution to Weekly Movement Goals'), React.createElement('div', { 'className': 'w-100 m-t-25 d-flex' }, React.createElement('div', { 'className': 'w-25 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'mobility_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Mobility (In minutes)'
    })), React.createElement('div', { 'className': 'w-25 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'strength_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Strength (In minutes)'
    })), React.createElement('div', { 'className': 'w-25 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'metabolic_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Metabolic (In minutes)'
    })), React.createElement('div', { 'className': 'w-25 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'power_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Power (In minutes)'
    }))))), React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(CardTitle, {
        'title': 'Equipment',
        'className': 'c-black m-b-18'
    }), React.createElement('div', { 'className': 'm-t-25' }, React.createElement(FieldArray, {
        'name': 'shopify_equipments',
        'component': this.renderEquipments
    })))), React.createElement('div', { 'className': 'publish-info' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'w-100 text-left d-flex align-center m-l-5' }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'featured_workout',
        'component': Checkbox,
        'label': 'Featured workout'
    })), React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-10 m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Save as Draft',
        'disabled': this.props.submitting,
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'raised': true,
        'onClick': this.saveAsDraft
    }), React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.submitting,
        'className': 'm-l-10 bg-success w-100',
        'raised': true,
        'onClick': this.saveAndPublish
    }))))))));
}