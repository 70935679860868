import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/quickstart_container";
import store, { history } from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import { getClientQuickStart } from '../../redux/client/network/quickstart_network';


class QuickstartContainer extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    };

    componentWillMount() {
        this.props.getClientQuickStart();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname === "/client/quickstart") {
            history.push("/client/quickstart/acquired");
        }
    }

    render() {
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getClientQuickStart
        },
        dispatch
    );
}
function mapStateToProps(state, ownProps) {
    return {
        availableQuickStarts: state.client.quickStartReducer.availableQuickStarts,
        acquiredQuickStarts: state.client.quickStartReducer.acquiredQuickStarts,
        ...ownProps,
    };
}
const QuickstartContainerConnected = connect(mapStateToProps, mapDispatchToProps)(QuickstartContainer);
export default QuickstartContainerConnected;
