import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/coach_edit.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import { formValueSelector, reduxForm } from 'redux-form';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../../redux/store';
import { getCoach, saveCoach } from '../../../redux/dhf/move/network/coach_network';
import CoachAction from '../../../redux/dhf/move/actions/coach_action';
import { uploadFiles } from '../../../redux/core/network/file_upload';


class CoachEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveCoach = this.saveCoach.bind(this);  
        this.state = { publish: false };
        this.deleteAvatar = this.deleteAvatar.bind(this);
        this.uploadAvatar = this.uploadAvatar.bind(this);        
    }
    
    componentWillMount() {
        this.props.getCoach({
            coach_id: this.props.match.params.coach_id,
        }).then((response) => {
            this.setState({ 'is_avatar_uploaded': this.props.coach.is_avatar_uploaded });
            this.setState({ 'avatar_url': this.props.coach.avatar_url });
        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    deleteAvatar() {
        this.setState({ 'is_avatar_uploaded': false});
        this.setState({ 'avatar_url': null});
    }

    uploadAvatar(files) {
        let formData = new FormData();        
        formData.append('guid', this.props.coach.guid);
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('avatar_url', result.thumbnail_url);
            this.props.change('is_avatar_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
            this.setState({ 'is_avatar_uploaded': true});
            this.setState({ 'avatar_url': result.thumbnail_url});            
        }).catch((error) => {
        });
    }    

    saveCoach(data) {     
        let params = _.omit(data, ['can_be_deleted']);
        params["is_avatar_uploaded"] = this.state.is_avatar_uploaded;
        this.props.saveCoach(params).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/move/coaches');
        }).catch((error) => {
            //do nothing
        });  
    }
    
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        coach: state.move.coachReducer.coach,
        initialValues: state.move.coachReducer.coach,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getCoach,
            saveCoach,
            uploadFiles,            
            cleanUp: CoachAction.cleanModule
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'bio'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const CoachEditForm = reduxForm(
    {
        form: 'coachEditForm',
        enableReinitialize: true,
        validate
    }
)(CoachEdit);


const CoachEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(CoachEditForm);
export default CoachEditFormConnected;