import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Avatar } from 'react-toolbox/lib/avatar';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import Slider from 'react-slick';
import SlickCss from 'slick-carousel/slick/slick.css';
import SlickThemeCss from 'slick-carousel/slick/slick-theme.css';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ExercisePreview from '../../../exercise/components/exercise_preview';
import IntroVideo from '../../../client_app/components/welcome_video';
export default function () {
    function repeatExercise1(exercise, exerciseIndex) {
        return React.createElement('div', {
            'className': 'width-200  box-shadow  m-l-18 m-r-12',
            'key': 'exercise' + exercise.id
        }, React.createElement('div', {
            'className': 'thumbnail box-200-120',
            'style': { backgroundImage: 'url(' + encodeURI(exercise.exercise_thumbnail_url) + ')' }
        }), React.createElement('div', { 'className': 'bg-white' }, React.createElement('div', {
            'className': 'vertical-align m-0  c-black',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'flex-auto p-l-5 text-left f-14 truncate',
            'tooltip': exercise.name
        }, '\n                                        ', exercise.name, '\n                                    ')), React.createElement('div', { 'className': 'vertical-align between-xs m-0 c-black p-l-8 p-r-8' }, React.createElement('div', { 'className': 'p-3 c-white badge-grey text-center f-12' }, '\n                                        Sets: ', exercise.sets, '\n                                    '), React.createElement(TooltipWrapper, {
            'className': 'vertical-align c-grey',
            'tooltip': 'View Exercise Details',
            'onClick': () => {
                this.preview(exercise);
            }
        }, React.createElement('i', { 'className': 'material-icons pointer' }, 'play_circle_filled')), exercise.description ? React.createElement(TooltipWrapper, {
            'className': 'd-flex m-r-10 c-grey text-center di-block',
            'tooltip': exercise.description,
            'key': '4168'
        }, React.createElement('i', { 'className': 'material-icons pointer' }, 'info')) : null, exercise.metric === 1 ? React.createElement('div', {
            'className': 'p-3 c-white badge-grey text-center f-12',
            'key': '4474'
        }, '\n                                        Reps: ', exercise.reps, '\n                                    ') : null, exercise.metric === 2 ? React.createElement('div', {
            'className': 'p-3 c-white badge-grey text-center f-12',
            'key': '4750'
        }, '\n                                        Distance: ', exercise.distance, ' ', exercise.__distance_unit, '\n                                    ') : null, exercise.metric === 3 ? React.createElement('div', {
            'className': 'p-3 c-white badge-grey text-center f-12',
            'key': '5061'
        }, '\n                                        Distance: ', exercise.duration, ' ', exercise.__duration_unit, '\n                                    ') : null)));
    }
    return React.createElement('div', {}, React.createElement('div', { 'className': 'panel-new panel' }, React.createElement('div', { 'className': 'panel-heading m-l-30 p-r-10 p-b-5 p-t-10' }, React.createElement('div', { 'className': 'panel-title' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'f-16 m-l-32 c-black' }, '\n                    ', `${ this.props.display_workout_name ? this.props.progression.workout_name + '  ( ' + this.props.progression.name + ')' : this.props.progression.name }`, '\n                '), this.props.progression.workout_description ? React.createElement(IconButton, {
        'className': 'col-xs-1 c-black center-xs',
        'tooltip': this.props.progression.workout_description,
        'icon': 'info',
        'key': '1537'
    }) : null)), this.props.display_schedule_type_info ? React.createElement('div', {
        'className': 'panel-subtitle m-t-5',
        'key': '1805'
    }, '\n            ', this.props.progression.schedule_type === 2 ? this.props.progression.per_week + ' times per week & ' + this.props.progression.per_day + ' times per day' : 'Scheduled', '\n        ') : null, this.props.progression.intro && this.props.progression.intro.video_guid !== null ? React.createElement('div', {
        'className': 'm-t-5 pointer',
        'onClick': () => {
            this.viewIntroVideo();
        },
        'key': '2204'
    }, '\n            View Intro Video\n        ') : null), React.createElement('div', { 'className': 'panel-body row m-0 p-10' }, React.createElement('div', {
        'className': 'col-xs-12  bg-body  c-black p-l-25 p-r-25 p-t-10 p-b-10',
        'style': { overflow: 'hidden' }
    }, this.props.progression.exercises.length !== 0 ? React.createElement.apply(this, [
        Slider,
        _.assign({}, { 'key': '2613' }, this.sliderSettings),
        _.map(this.props.progression.exercises, repeatExercise1.bind(this))
    ]) : null)), this.props.showTracking && this.props.trackProgressionCallback ? React.createElement('div', {
        'className': 'panel-footer bg-white vertical-align  center-xs',
        'key': '5531'
    }, React.createElement(Button, {
        'onClick': () => {
            this.do_track(3);
        },
        'icon': 'done_all',
        'className': ` br-50em ${ this.props.progression.has_tracked === 3 ? 'bg-success' : 'c-accent-dark button-border' } `,
        'tooltip': 'Did it',
        'label': 'Did It',
        'flat': true,
        'disabled': this.props.progression.schedule_type === 2 && !this.props.progression.is_tracking_allowed
    }), React.createElement(Button, {
        'onClick': () => {
            this.do_track(2);
        },
        'icon': 'done',
        'className': ` br-50em m-l-10 ${ this.props.progression.has_tracked === 2 ? 'bg-primary' : 'c-accent-dark button-border' } `,
        'tooltip': 'Did Part of it',
        'label': 'Did Part of it',
        'flat': true,
        'disabled': this.props.progression.schedule_type === 2 && !this.props.progression.is_tracking_allowed
    }), this.props.progression.schedule_type !== 2 ? React.createElement(Button, {
        'onClick': () => {
            this.do_track(1);
        },
        'icon': 'clear',
        'className': ` br-50em m-l-10 ${ this.props.progression.has_tracked === 1 ? 'bg-danger' : 'c-accent-dark button-border' } `,
        'tooltip': 'Didn\'t do it',
        'label': 'Didn\'t do it',
        'flat': true,
        'key': '6349'
    }) : null, this.props.progression.schedule_type === 2 && this.props.progression.track_count > 0 ? React.createElement('div', {
        'className': 'm-l-20',
        'key': '6641'
    }, '\n            (', this.props.progression.track_count, ' of ', this.props.progression.max_allowed_tracking, ' tracked this week)\n        ') : null) : null), React.createElement(ExercisePreview, {
        'exercise': this.state.previewingExercise,
        'onClosePreview': () => {
            this.setState({ previewingExercise: null });
        }
    }), this.state.show_intro_video ? React.createElement(IntroVideo, {
        'onCloseHandle': this.closeIntroVideo,
        'welcome_video': this.props.progression.intro,
        'key': '7064'
    }) : null);
}