import React from "react";
import BaseComponent from "../../utils/common_components/basecomponent";
import Template from "./templates/intake_list.rt";
import { connect } from "react-redux";
import Utils from "../../utils/utils";
import store, { history } from "../../redux/store";
import { bindActionCreators } from "redux";

import ProgressBar from "react-toolbox/lib/progress_bar";
import IntakeAction from "../../redux/intake/actions/intake_creator";
import swal from "sweetalert2";
import {
  getIntakeList,
  addIntakeInstance,
  cloneIntakeInstance,
  deleteIntakeInstance,
} from "../../redux/intake/network/intake_network";
import { ENGAGEMENT_INTAKE_VIDEO } from "../../utils/help_video";
import HelpVideoCreator from "../../redux/core/actions/help_video_creator";

const INTAKE_FORM_TYPE = 1;

class IntakeList extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.addIntake = this.addIntake.bind(this);
    this.editIntake = this.editIntake.bind(this);
  }

  componentWillMount() {
    this.props.getIntakeList({
      form_type: INTAKE_FORM_TYPE,
      fetch_type: "manage",
      package_version: '1'
    });
  }

  componentDidMount() {
    this.props.updateHelpVideo(ENGAGEMENT_INTAKE_VIDEO);
  }

  componentWillUnmount() {
    this.props.cleanUp();
    this.props.clearHelpVideo();
  }

  editIntake(id) {
    history.push(
      "/practice/" +
        this.props.selectedRole.practice.id +
        "/engagement/intake/edit/" +
        id
    );
  }

  addIntake() {
    let that = this;
    swal
      .fire({
        title: "Create Intake Form",
        text: "Please name this form",
        input: "text",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#DB303C",
        cancelButtonColor: "#4C555C",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        useRejections: true,
      })
      .then(
        function (inputValue) {
          if(inputValue.value){
            if (inputValue.value === "") {
                alert("Intake form needs a name!");
                return false;
            } else {
              that.props
              .addIntakeInstance({
                form_type: 1,
                name: inputValue.value,
                package_version: '1'
              })
              .then((response) => {
                let intakeId = response.data.id;
                history.push(
                  "/practice/" +
                    that.props.selectedRole.practice.id +
                    "/engagement/intake/edit/" +
                    intakeId
                );
                swal.close();
              })
              .catch((error) => {
                swal.close();
              });
            }
          }else { 
            return
          }
          // if (inputValue.value === false) {
          //   return false;
          // }
          // if (inputValue.value === "") {
          //   Utils.showErrorAlert("Intake form needs a name!");
          //   // swal.showInputError("Intake form needs a name!");
          //   return false;
          // }
        },
        function (dismiss) {}
      );
  }

  cloneIntake(intake_id) {
    let that = this;
    swal
      .fire({
        title: "Clone Intake",
        text:
          "You are about to create a clone copy of this intake form. Are you sure?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0292d3",
        cancelButtonColor: "#4C555C",
        confirmButtonText: "Yes, I am",
        cancelButtonText: "Cancel",
        useRejections: true,
      })
      .then(
        function (result) {
          if(result.value) {
            that.props
              .cloneIntakeInstance({
                form_type: 1,
                intake_id: intake_id,
                package_version: '1'
              })
              .then((response) => {
                that.props.getIntakeList({
                  form_type: INTAKE_FORM_TYPE,
                  fetch_type: "manage",
                  package_version: '1'
                });
              })
              .catch((error) => {
                swal.close();
              });
          } else swal.close();
        },
        function (dismiss) {}
      );
  }

  deleteIntake(intake_id) {
    let that = this;
    swal
      .fire({
        title: "Clone Intake",
        text: "You are about to delete an intake form. Are you sure?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0292d3",
        cancelButtonColor: "#4C555C",
        confirmButtonText: "Yes, I am",
        cancelButtonText: "Cancel",
        useRejections: true,
      })
      .then(
        function(result) {
          if(result.value){
            that.props
              .deleteIntakeInstance({
                form_type: 1,
                intake_id: intake_id,
                package_version: '1'
              })
              .then((response) => {
                that.props.getIntakeList({
                  form_type: INTAKE_FORM_TYPE,
                  fetch_type: "manage",
                  package_version: '1'
                });
              })
              .catch((error) => {
                swal.close();
              });
          } else swal.close()
        },
        function (dismiss) {}
      );
  }

  render() {
    if (_.isEmpty(this.props.user)) {
      return (
        <div className="row center-xs m-t-25">
          <ProgressBar type="circular" mode="indeterminate" multicolor />
        </div>
      );
    }
    return Template.apply(this);
  }
}
function mapStateToProps(state) {
  return {
    user: state.auth.authReducer.user,
    selectedRole: state.auth.roleReducer.selectedRole,
    intake_list: state.intake.intakeReducer.intake_list,
  };
}

let mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getIntakeList,
      addIntakeInstance,
      cloneIntakeInstance,
      deleteIntakeInstance,
      cleanUp: IntakeAction.intakeCleanUp,
      updateHelpVideo: HelpVideoCreator.updateHelpVideo,
      clearHelpVideo: HelpVideoCreator.clearHelpVideo,
    },
    dispatch
  ),
  dispatch,
});

const IntakeListConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntakeList);
export default IntakeListConnected;
