import React from 'react';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/practice_practitioners";
import { reduxForm } from 'redux-form';
import store from '../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getPractitionerList, getPractitioner,resendPractitionerInvitation,
    postPractitioner, invitePractitioner, toggleHidePractitioner} from '../../../redux/practice/network/practice_practitioner';
import actionCreator from '../../../redux/practice/actions/practice_practitioner';
import Utils from '../../../utils/utils';
import { history } from "../../../redux/store";
import Swal from 'sweetalert2';
import PractitionerInvitation from '../../../utils/common_components/invite/user_invitation';

class PracticePractitioners extends BaseComponent {

    constructor(props, context) {        
        super(props, context);
        this.editDetails = this.editDetails.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);        
        this.updateTitleBio = this.updateTitleBio.bind(this);
        this.hide = this.hide.bind(this);
        this.onChangePractitionerCategory = this.onChangePractitionerCategory.bind(this);
        this.invitePractitioner = this.invitePractitioner.bind(this);
        this.invitePractitionerCancel = this.invitePractitionerCancel.bind(this);
        this.invitePractitionerScreen = this.invitePractitionerScreen.bind(this);
        this.state = {
            'practitionerCategory': 'active',
            page_type : "practitioner_list"
        };
    }

    componentWillMount() {
        this.props.getPractitionerList({'category': 'active'});
    }

    onChangePractitionerCategory(val){
        if(!val){
            return;
        }
        this.setState({
            practitionerCategory: val
        });
        this.props.getPractitionerList({'category': val});
    }

    hide(practitioner) {
        let display_text = "";
        if (this.state.practitionerCategory === 'active' || this.state.practitionerCategory === 'invited') {
            display_text = "Would you like to make this practitioner inactive from your practice? Are you sure?"
        } else {
            display_text = "Would you like to make this practitioner active for your practice? Are you sure?"
        }
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {};
            params['id'] = practitioner.id;
            params['category'] = that.state.practitionerCategory;
            that.props.toggleHidePractitioner(params).then(() => {
                that.props.getPractitionerList({ 'category': that.state.practitionerCategory });
            }).catch((error) => { 

            });
        }, function (dismiss) {
        });
    }

    invitePractitionerScreen() {
        this.setState({
            page_type: "practitioner_invite"
        });        
    }
    
    invitePractitionerCancel() {
        this.setState({
            page_type: "practitioner_list"
        });        

    }    

    invitePractitioner(params) {
        this.props.invitePractitioner({
            email: params.email,
            subject: params.subject,
            body: params.body
        }).then(()=>{
            this.setState({
                page_type: "practitioner_list"
            });        
            this.props.getClientList({'client_type':'active'});
        }).catch((error)=>{});
    }    

    updateTitleBio(practitioner){
        history.push('/practice/'+this.props.selectedRole.practice.id+'/admin/practitioner/update_bio/'+practitioner.id);
    }

    editDetails(practitioner){
        history.push('/practice/'+this.props.selectedRole.practice.id+'/admin/practitioner/edit/'+practitioner.user.id);
    }

    resendInvitation(practitioner){
        this.props.resendPractitionerInvitation({
            practitioner_id: practitioner.user.id,
        }).then((response) => {
        }).catch((error) => {             
        });        
    }

    render() {
        if (this.state.page_type === "practitioner_list") {
            return Template.apply(this);
        } else {
            return <PractitionerInvitation
                        title="Invite a new practitioner"
                        subject={this.props.canned_message.subject}
                        body={this.props.canned_message.body}
                        handleCancel={this.invitePractitionerCancel}
                        handleInvite={this.invitePractitioner}/>;
        }
    }

    componentWillUnmount() {
        this.props.clean();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPractitionerList,
        getPractitioner,
        postPractitioner,
        invitePractitioner,
        toggleHidePractitioner,
        resendPractitionerInvitation,
        clean: actionCreator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        practitionerList: state.practice.practicePractitioner.practitionerList,
        selectionList: state.practice.practicePractitioner.selectionList,
        canned_message: state.practice.practicePractitioner.canned_message,
        ...ownProps,
    };
}

const PracticePractitionersConnected = connect(mapStateToProps,mapDispatchToProps)(PracticePractitioners);
export default PracticePractitionersConnected;
