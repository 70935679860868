import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import ProfileEdit from '../../../user/components/profile_edit';

class PractitionerEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
    };

    render() {
        return (
            <ProfileEdit user_id={this.props.match.params.practitioner_id} is_client={false} self_editing={false}/>
        );
    }

}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        }, 
        dispatch
    );
}

const PractitionerEditConnected = connect(mapStateToProps,mapDispatchToProps)(PractitionerEdit);
export default PractitionerEditConnected;
