import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/treatment_template_dashboard";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import swal from 'sweetalert2';
import programActions from '../../redux/program/actions/program';
import {
      workoutVersionUpgrade, workoutPdfGenerate,
    nonScheduledWorkoutMarkComplete
} from '../../redux/program/network/program_list';
import { TEMPLATE_SELECTION_MODE, WORKOUT_TARGET_GROUP } from '../../redux/program/constants/constants';
import { getTreatmentTemplate,publishTreatmentTemplate,createHabitFromHabitTemplate } from '../../redux/treatment_template/network/treatment_template_network';
import { listWorkouts, listGuidanceDocuments,
        listNutritionDocuments, postHealthDocument,
       deleteHealthDocument,workoutSort,workoutDelete } from '../../redux/treatment_template/network/treatment_template_program';    
import treatmentTemplateActions from '../../redux/treatment_template/actions/treatment_template_creator';
import treatmentTemplateProgramActions from '../../redux/treatment_template/actions/treatment_template_program_creator';
import { getGoalList } from '../../redux/goal/network/goal_network';
import goalActions from '../../redux/goal/actions/goal_creator';
import { addTreatmentTemplateGoal, deleteTreatmentTemplateGoal } from '../../redux/treatment_template/network/treatment_template_goal';
import { getHabitTemplateList } from '../../redux/habit/network/habit_network';
class TreatmentTemplateDashboard extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addWorkout = this.addWorkout.bind(this);
        this.editWorkout = this.editWorkout.bind(this);
        this.deleteWorkout = this.deleteWorkout.bind(this);
        this.handleWorkoutSort = this.handleWorkoutSort.bind(this);
        this.unAllocateSpace = this.unAllocateSpace.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.allocateTreatmentSpace = this.allocateTreatmentSpace.bind(this);
        this.handleTreatmentTabChange = this.handleTreatmentTabChange.bind(this);
        this.finishEditingWorkout = this.finishEditingWorkout.bind(this);
        this.editGoal = this.editGoal.bind(this);
        this.toggleDocumentSelectionDialog = this.toggleDocumentSelectionDialog.bind(this);
        this.assignHealthDocument = this.assignHealthDocument.bind(this);
        this.unAssignHealthDocument = this.unAssignHealthDocument.bind(this);
        this.afterCreateWorkoutFromTemplate = this.afterCreateWorkoutFromTemplate.bind(this);
        this.editHabit = this.editHabit.bind(this);
        this.finishEditingHabit = this.finishEditingHabit.bind(this);
        this.allocateHabitSpace = this.allocateHabitSpace.bind(this);
        this.finishEditingGoal = this.finishEditingGoal.bind(this);
        this.assignGoal = this.assignGoal.bind(this);
        this.onClickEditGoal = this.onClickEditGoal.bind(this);
        this.hideGoalChooser = this.hideGoalChooser.bind(this);
        this.deleteGoal = this.deleteGoal.bind(this);
        this.publishTreatmentTemplate = this.publishTreatmentTemplate.bind(this);
        this.editTreatmentTemplate = this.editTreatmentTemplate.bind(this);
        this.hideHabitTemplateModal = this.hideHabitTemplateModal.bind(this);
        this.createHabitFromHabitTemplate = this.createHabitFromHabitTemplate.bind(this);
        this.state = {
            topPanelVisible: true,
            treatmentSpaceAllocated: false,
            editingWorkoutId: null,
            tabIndex: 0,
            treatmentTabIndex: 0,
            showWorkoutEditForm: false,
            workoutPreviewMode: false,
            showTemplateSelection: false,
            showGoalEditForm: false,
            workoutProgramTypeParams: Utils.getProgramTypeParams('treatment_template_workout'),
            templateProgramTypeParams: Utils.getProgramTypeParams('template'),
            programTypeParams: Utils.getProgramTypeParams('treatment_template_workout'),
            documentSelectionDialogVisible: false,
            habitSpaceAllocated: false,
            editingHabitId: null,
            openGoalIntervalModal: false,
            openPaymentModal: false,
            healthDocumentType: null,
            openHabitTemplateModal:false,
            
        };
    };


    loadDetails() {
        this.props.listWorkouts({ treatment_template_id: this.props.match.params.treatment_template_id });
        this.props.getTreatmentTemplate({ id: this.props.match.params.treatment_template_id });
        this.props.listGuidanceDocuments({ treatment_template_id: this.props.match.params.treatment_template_id });
        this.props.listNutritionDocuments({ treatment_template_id: this.props.match.params.treatment_template_id });
        this.props.getGoalList({ fetch_type: "practitioner_assign" });
    }

    componentDidMount() {
        if(this.props.match.params.treatment_type === "gameplan"){
            this.setState({tabIndex:1});
        }
        this.loadDetails();
    }

    toggleDocumentSelectionDialog(documentType) {
        this.setState({
            documentSelectionDialogVisible: !this.state.documentSelectionDialogVisible,
            healthDocumentType: documentType ? documentType : this.state.healthDocumentType,
        });
    }

    handleTabChange(tabIndex) {
        this.setState({
            tabIndex: tabIndex,
        });
        if(tabIndex === 1){
            this.props.listWorkouts({ treatment_template_id: this.props.match.params.treatment_template_id });  
        }
        this.unAllocateSpace();
    }

    editTreatmentTemplate() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type+'/edit/'+this.props.match.params.treatment_template_id);
    }

    handleTreatmentTabChange(treatmentTabIndex) {
        this.setState({ treatmentTabIndex });
    }

    finishEditingWorkout() {
        this.unAllocateSpace();
        this.setState({
            editingWorkoutId: null,
            showWorkoutEditForm: false,
        });
        this.props.listWorkouts({ treatment_template_id: this.props.match.params.treatment_template_id });
    }

    afterCreateWorkoutFromTemplate(workout_id) {
        this.setState({
            showTemplateSelection: false,
            showWorkoutEditForm: true,
            editingWorkoutId: workout_id,
        });
    }



    assignHealthDocument(document) {
        this.props.postHealthDocument({
            treatment_template_id: this.props.match.params.treatment_template_id,
            health_document_id: document.id,
        }).then(() => {
            this.setState({
                documentSelectionDialogVisible: false,
            });
            if (this.state.healthDocumentType === 'nutrition') {
                this.props.listNutritionDocuments({ treatment_template_id: this.props.match.params.treatment_template_id });
            } else {
                this.props.listGuidanceDocuments({ treatment_template_id: this.props.match.params.treatment_template_id });
            }
        }).catch(() => { });
    }

    finishEditingHabit() {
        this.unAllocateSpace();
        this.setState({
            editingHabitId: null,
            habitSpaceAllocated: false,
        });
    }

    editHabit(id) {
        let that = this;
        if(id){
            this.setState({
                editingHabitId: id,
            }, () => this.allocateHabitSpace());
        }else{
            swal.fire({
                title: "Select an option",
                text: "How would you like to create Habit?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Create from template",
                cancelButtonText: "Create new",
                useRejections:true
            }).then(function () {
                that.props.getHabitTemplateList({
                    show_published:true,
                    show_partner_templates:true
                });
                that.setState({
                    openHabitTemplateModal:true
                });
            }, function (dismiss) {
                if (dismiss === "cancel") {
                    that.setState({
                        editingHabitId: null,
                    }, () => that.allocateHabitSpace());
                }
            });
        }
    }

    createHabitFromHabitTemplate(habit_template) {
        this.props.createHabitFromHabitTemplate({
            treatment_template_id: this.props.treatment_template.id,
            habit_template_id: habit_template.id
        }).then((response) => {
            this.editHabit(response.habit_id);
            this.setState({ openHabitTemplateModal: false });
        }).catch((error) => {
            //do nothing;
        });
    }

    unAssignHealthDocument(document) {
        let that = this;
        swal.fire({
            title: "Confirmation",
            text: "Are you sure to remove the association of this nutrition document from this treatment template?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes",
            cancelButtonText: "No, Cancel",
            useRejections:true
        }).then(() => {
            that.props.deleteHealthDocument({
                treatment_template_id: that.props.match.params.treatment_template_id,
                health_document_id: document.id,
            }).then(() => {
                if (document.document_type === 1) {
                    that.props.listNutritionDocuments({ treatment_template_id: that.props.match.params.treatment_template_id });
                } else {
                    that.props.listGuidanceDocuments({ treatment_template_id: that.props.match.params.treatment_template_id });
                }
            }).catch(() => { });
        }, () => { });
    }

    toggleDocumentSelectionDialog(documentType) {
        this.setState({
            documentSelectionDialogVisible: !this.state.documentSelectionDialogVisible,
            healthDocumentType: documentType ? documentType : this.state.healthDocumentType,
        });
    }

    unAllocateSpace() {
        this.setState({
            topPanelVisible: true,
        });
    }

    addWorkout() {
        swal.fire({
            title: "Select an option",
            text: "How would you like to create Workout?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Create from template",
            cancelButtonText: "Create new",
            useRejections:true
        }).then(() => {
            this.allocateTreatmentSpace();
            this.setState({
                showTemplateSelection: true,
                showWorkoutEditForm: false,
                workoutPreviewMode: false,
            });
        }, (dismiss) => {
            if (dismiss !== 'cancel') {
                return;
            }
            this.allocateTreatmentSpace();
            this.setState({
                editingWorkoutId: null,
                showWorkoutEditForm: true,
                workoutPreviewMode: false,
            });
        });
    }

    allocateTreatmentSpace() {
        this.setState({
            topPanelVisible: false,
            treatmentSpaceAllocated: true,
        });
    }

    allocateHabitSpace() {
        this.setState({
            topPanelVisible: false,
            habitSpaceAllocated: true,
        });
    }


    markWorkoutComplete(workout) {
        swal.fire({
            title: "Are you sure?",
            text: "Mark this workout as completed? This is irreversible!",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            this.props.nonScheduledWorkoutMarkComplete({ id: workout.id }).then(() => {
                this.props.listWorkouts({ treatment_template_id: this.props.match.params.treatment_template_id });
            }).catch(() => { });
        }, () => {});
    }

    editWorkout(workout) {
        let startEdit = (previewOnly) => {
            this.allocateTreatmentSpace();
            this.setState({
                editingWorkoutId: workout.id,
                showWorkoutEditForm: true,
                workoutPreviewMode: previewOnly,
            });
        };
        if (workout.is_being_used) {
            startEdit(true);
            // swal.fire({
            //     title: "Workout In Use",
            //     text: "You can either Preview Workout or Change its content and scheduled workout days will move forward. Choose one of the options from below",
            //     type: "question",
            //     showCancelButton: true,
            //     showCloseButton: true,
            //     confirmButtonColor: "#0292d3",
            //     cancelButtonColor: "#4C555C",
            //     confirmButtonText: "Edit Workout",
            //     cancelButtonText: "Preview Workout",
            //      useRejections:true
            // }).then(() => {
            //     this.props.workoutVersionUpgrade({ id: workout.id }).then(() => {
            //         startEdit(false);
            //     }).catch(() => { });
            // }, (dismiss) => { 
            //     if (dismiss === 'cancel') {
            //         startEdit(true);
            //     }
            // });
        } else {
            startEdit(false);
        }
    }

    deleteWorkout(workout) {
        swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete this workout?",
            type:  "warning",
            showCancelButton: true,
            confirmButtonColor: "#f16767" ,
            confirmButtonText: "Yes, I am sure!",
            useRejections:true
        }).then(() => {
            this.props.workoutDelete({ 
                workout_id: workout.id,
                treatment_template_id: this.props.match.params.treatment_template_id
             });
        }, () => { });
    }

    handleWorkoutSort(workouts) {
        let orders = [];
        for (let index in workouts) {
            orders.push({
                id: workouts[index].id,
                order: parseInt(index) + 1,
            });
        }
        this.props.workoutSort({
            workout_orders: orders,
            treatment_template_id: this.props.match.params.treatment_template_id
        }).then(() => {
            this.props.listWorkouts({ treatment_template_id: this.props.match.params.treatment_template_id });
        }).catch(() => { });
    }

    //////// All goal related functions /////

    onClickEditGoal() {
        if (!this.props.treatment_template.goal) {
            // show goal chooser
            this.setState({ goalChooserVisible: true });
        } else {
            this.editGoal();
        }
    }

    hideGoalChooser() {
        this.setState({ goalChooserVisible: false });
    }

    // starts editing treatment_template.goal
    editGoal() {
        this.setState({
            showGoalEditForm: true,
            topPanelVisible: false,
            goalChooserVisible: false,
        });
    }

    deleteGoal() {
        swal.fire({
            title: "Confirmation",
            text: "You are about to delete this goal. Are you sure?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            this.props.deleteTreatmentTemplateGoal({ id: this.props.treatment_template.goal.id }).then(() => {
                this.props.getTreatmentTemplate({ id: this.props.match.params.treatment_template_id }).then(() => {
                    this.setState({
                        showGoalEditForm: false,
                        topPanelVisible: true,
                        goalChooserVisible: false,
                    });
                });
            }).catch(() => { });
        }, () => { });
    }

    finishEditingGoal() {
        this.unAllocateSpace();
        this.props.getTreatmentTemplate({ id: this.props.match.params.treatment_template_id });
    }

    assignGoal(goal) {
        this.props.addTreatmentTemplateGoal({
            treatment_template_id: this.props.match.params.treatment_template_id,
            goal_id: goal.id
        }).then(() => {
            this.props.getTreatmentTemplate({ id: this.props.match.params.treatment_template_id }).then(() => {
                this.setState({ goalChooserVisible: false });
                this.editGoal();
            }).catch(() => { });
        }).catch(() => {
            //do nothing;
        });
    }


    publishTreatmentTemplate(published = false) {
        this.props.publishTreatmentTemplate({
            treatment_template_id: this.props.match.params.treatment_template_id,
            published: published,
        }).then(() => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type);
        }).catch(() => { });      
    }

    hideHabitTemplateModal() {
        this.setState({ openHabitTemplateModal: false });
    }


    componentWillUnmount() {
        this.props.cleanTreatmentTemplate();
        this.props.treatmentTemplateProgramCleanup();
        this.props.cleanGoal();
    }

    render() {
        if (_.isEmpty(this.props.treatment_template)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        workouts: state.treatment_template.treatmentTemplateProgramReducer.workouts,
        treatment_template: state.treatment_template.treatmentTemplateReducer.treatment_template,
        clients: [],
        nutritionDocuments: state.treatment_template.treatmentTemplateProgramReducer.nutritionDocuments,
        guidanceDocuments: state.treatment_template.treatmentTemplateProgramReducer.guidanceDocuments,
        goal_list: state.goal.goalReducer.goal_list,
        habit_template_list: state.habit.habitTemplateReducer.habit_template_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setProgramMode: programActions.setMode,
        setWorkoutMode: programActions.setWorkoutMode,
        cleanGoal: goalActions.goalCleanup,
        workoutDelete,
        listWorkouts,
        cleanTreatmentTemplate: treatmentTemplateActions.treatmentTemplateCleanup,
        treatmentTemplateProgramCleanup: treatmentTemplateProgramActions.treatmentTemplateProgramCleanup,
        getTreatmentTemplate,
        workoutSort,
        listGuidanceDocuments,
        listNutritionDocuments,
        postHealthDocument,
        deleteHealthDocument,
        workoutVersionUpgrade,
        workoutPdfGenerate,
        getGoalList,
        addTreatmentTemplateGoal,
        deleteTreatmentTemplateGoal,
        publishTreatmentTemplate,
        nonScheduledWorkoutMarkComplete,
        getHabitTemplateList,
        createHabitFromHabitTemplate
    }, dispatch);
}

const TreatmentTemplateDashboardConnected = connect(mapStateToProps, mapDispatchToProps)(TreatmentTemplateDashboard);
export default TreatmentTemplateDashboardConnected;
