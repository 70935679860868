import * as React from 'react';
import * as _ from 'lodash';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GoalTrack from '../../../engagement/components/client_engagement_goal_tracking';
import GoalTrackingLineChart from '../../../engagement/components/engagement_goal_tracking_chart';
import ClientGoalEdit from '../../../engagement/components/client_engagement_goal_edit';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
export default function () {
    function repeatMissed_date1(missed_date, missed_dateIndex) {
        return React.createElement(Chip, {
            'onClick': () => {
                this.showGoalModal(missed_date);
            },
            'className': 'bg-danger box-shadow pointer',
            'key': missed_dateIndex
        }, '\n                            ', moment(missed_date).format('MMMM Do YYYY'), '\n                        ');
    }
    function repeatTrack2(goal, goalIndex, track, trackIndex) {
        return React.createElement('div', {
            'className': 'panel m-t-10',
            'key': track.id
        }, React.createElement('div', { 'className': 'panel-heading bg-beige panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, trackIndex + 1, '. ', track.track_question)), React.createElement('div', { 'className': 'panel-body p-0 row m-0' }, React.createElement('div', { 'className': 'col-xs-12  text-center' }, React.createElement('div', {
            'className': 'row middle-xs center-xs text-center',
            'style': { height: '100px' }
        }, React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'tooltip': 'Baseline Value',
            'className': 'bg-accent f-18 cursor-default',
            'label': track.initial_value !== null ? track.initial_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                '), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'tooltip': 'Current Value',
            'className': 'bg-primary f-18 cursor-default',
            'label': track.current_value !== null ? track.current_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                '), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'tooltip': 'Target Value',
            'className': 'bg-success f-18 cursor-default',
            'label': track.target_value !== null ? track.target_value.toString() : '-',
            'floating': true
        }), ' ', track.track_unit.unit_name, '\n                                ')))), React.createElement('div', {
            'className': 'col-xs-12 col-md-8',
            'style': { borderRight: '2px dashed #6C7F8D' }
        }, React.createElement('div', {
            'style': {
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            },
            'className': 'text-center p-relative'
        }, track.initial_value !== null || track.target_value !== null ? React.createElement(GoalTrackingLineChart, {
            'engagement': this.props.engagement,
            'goal_track': track,
            'key': '5252'
        }) : null)));
    }
    function repeatGoal3(goal, goalIndex) {
        return [
            React.createElement('h4', {
                'className': 'di-block m-l-10 c-dark',
                'style': { width: 'auto' },
                'key': '25551'
            }, '\n                    ', goal.text, '\n                '),
            goal.goal_tracking.length === 0 ? React.createElement('div', {
                'className': 'p-10  bg-white box-shadow',
                'key': '25553'
            }, React.createElement('div', { 'className': 'p-10 f-16 alert-warning' }, '\n                        Please give me a little time to set up some tracking questions to match the goal you created. You will see them here once in place. Thanks.\n                    ')) : null,
            _.map(goal.goal_tracking, repeatTrack2.bind(this, goal, goalIndex))
        ];
    }
    return React.createElement('div', {}, React.createElement('div', {
        'className': 'm-20',
        'style': { margin: '-17px' }
    }, this.props.engagement.client_untracked_goal_dates.length > 0 ? React.createElement('div', {
        'className': 'row w-100 center-xs p-15',
        'key': '1298'
    }, React.createElement('h4', { 'className': 'c-dark text-left' }, '\n                Goal - Not tracked\n            '), React.createElement('div', { 'className': 'alert-warning m-t-15 w-100 text-center' }, '\n                Goal completion summary will be displayed once you finish tracking.\n            '), React.createElement('div', { 'className': 'triangle-with-shadow auto-margin' }), React.createElement('div', { 'className': 'row w-100 center-xs' }, React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-5 p-20 search-box text-center' },
        _.map(this.props.engagement.client_untracked_goal_dates, repeatMissed_date1.bind(this))
    ]))) : null, this.props.engagement.client_untracked_goal_dates.length === 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '2442' },
        _.map(this.props.engagement.goals, repeatGoal3.bind(this))
    ]) : null), this.state.selectedTrackingData !== null ? React.createElement(Dialog, {
        'active': this.state.openGoalModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideGoalModal,
        'onOverlayClick': this.hideGoalModal,
        'key': '5718'
    }, React.createElement(GoalTrack, {
        'engagement': this.props.engagement,
        'track_date': this.state.selectedTrackingData,
        'handleClose': this.hideGoalModal
    })) : null);
}