import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/workout_view";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import { getWorkout } from "../../redux/program/network/program_edit";
import actions from '../../redux/client/actions/all_workouts_actions';
import MenuAction from '../../redux/core/actions/menu_state_creator';

class WorkoutView extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.play = this.play.bind(this);
    }

    componentWillMount() {
        this.props.getWorkout({ id: this.props.match.params.workout_id });
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);    
    }

    play(progression) {
        history.push('/client/progression_play/'+progression.id);
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWorkout,
        cleanModule: actions.cleanModule,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        workout: state.program.programEdit.program,
        ...ownProps,
    };
}

const WorkoutViewConnected = connect(mapStateToProps, mapDispatchToProps)(WorkoutView);
export default WorkoutViewConnected;
