// Action Creators
const fillConsultantList = (consultantList, selection_list, canned_message) => ({
    type: 'PRACTICE_CONSULTANT_LIST_FILL',
    consultantList,
    selection_list,
    canned_message
});
const updateConsultantList = (consultant) => ({
    type: 'PRACTICE_CONSULTANT_UPDATE',
    consultant,
});
const fillConsultant = (consultant) => ({
    type: 'PRACTICE_CONSULTANT_FILL',
    consultant,
});
const cleanModule = () => ({
    type: 'PRACTICE_CONSULTANT_CLEANUP',
});

export default {
    fillConsultantList,
    cleanModule,
    fillConsultant,
    updateConsultantList
};
