import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_group_class_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../redux/store';
import Swal from 'sweetalert2'
import { getClientGroupClassSchedulerList } from '../../redux/calendar/network/group_class_network';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';

class GroupClassList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.bookGroupClassScheduler = this.bookGroupClassScheduler.bind(this);
    }

    componentWillMount() {
        this.props.getClientGroupClassSchedulerList({});
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    bookGroupClassScheduler(group_class) {
        history.push("/client/scheduler/group_class/"+group_class.id);
    }


    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        groupClassList: state.calendar.groupClassReducer.group_class_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientGroupClassSchedulerList,
        cleanUp: groupClassActionCreator.cleanModule
    }, dispatch
    );
}


const GroupClassListConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassList);
export default GroupClassListConnected;