import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/group_class_edit_calendar";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../redux/store';
import { Calendar as BigCalendar,momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { groupClassScheduleEventDelete, groupClassSchedulerGet } from "../../redux/calendar/network/group_class_network";

const localizer = momentLocalizer(moment)
class GroupClassEditCalendar extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.closeEventDialog = this.closeEventDialog.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.state = {
            viewEventDialog: false,
            schedule: null,
        };
    }

    componentWillMount() {
                // BigCalendar.setLocalizer(
        //     BigCalendar.momentLocalizer(moment)
        // );
    }

    componentWillUnmount() {
    }

    viewEvent(event) {
        this.setState({
            viewEventDialog: true,
            schedule: event,
        });
    }

    closeEventDialog() {
        this.setState({
            viewEventDialog: false,
            schedule: null,
        });
    }

    deleteEvent() {
        let params = {};
        params['group_class_id'] = this.state.schedule.group_id;
        params['schedule_id'] = this.state.schedule.id;
        this.props.groupClassScheduleEventDelete({ ...params })
        .then((response) => {
            let params2 = {};
            params2["group_class_id"] = this.state.schedule.group_id;    
            this.props.groupClassSchedulerGet({ ...params2 })
            .then((response) => {  
                this.closeEventDialog();            
            })
            .catch((error) => { 
            });        
        })
        .catch((error) => { 
        });
    }



    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state) {
    let groupClassObject = {...state.calendar.groupClassReducer.group_class};
    let eventList = [];
    _.map(groupClassObject.group_class_frequency, function (item) {
        _.map(item.schedule_dates, function (item2) {



            let event = {};
            event.id = item2.id;
            event.practitioners = groupClassObject.practitioners;
            event.booking_count = item2.booking_count;
            event.group_id = groupClassObject.id;
            event.group_name = groupClassObject.name;
            event.title = groupClassObject.name + " (" + item2.booking_count + ")";
            event.start = moment(item2.start_time).toDate();
            event.end = moment(item2.end_time).toDate();
            event.start_time =  moment(item2.start_time).format("hh:mm a");
            event.end_time = moment(item2.end_time).format("hh:mm a");
            eventList.push(event);
        });
    });
    return {
        localizer,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        eventList: eventList,
        groupClass: state.calendar.groupClassReducer.group_class,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        groupClassScheduleEventDelete,
        groupClassSchedulerGet
    },
        dispatch
    );
}


const GroupClassEditCalendarConnected = connect(mapStateToProps, mapDispatchToProps)(GroupClassEditCalendar);
export default GroupClassEditCalendarConnected;