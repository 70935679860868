let initialState = {
    group_list: [],
    group: {},
    goal_targets: [],
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "CLIENT_GROUP_LIST_FILL":
            if (!action.group_list){
                return state;
            }
            return Object.assign({}, state, {
                group_list: action.group_list
            });
        case "CLIENT_GROUP_GET_FILL":
            if (!action.group){
                return state;
            }
            return Object.assign({}, state, {
                group: action.group,
            });
        case "CLIENT_GROUP_GOAL_TARGETS_FILL":
            if (!action.goal_targets){
                return state;
            }
            return Object.assign({}, state, {
                goal_targets: action.goal_targets,
            });


        case "CLIENT_GROUP_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
