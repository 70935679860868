import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/exercise_edit";
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';

import store,{history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    getExercise, postExercise, deleteExercise,
    uploadMedia
} from '../../redux/exercise/network/exercise';
import actionCreator from '../../redux/exercise/actions/exercise';
import Utils from '../../utils/utils';
import React from 'react';
import axios from 'axios';
import Axios from '../../utils/network/axios';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';


class ExerciseEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.uploadScientificEvidence = this.uploadScientificEvidence.bind(this);
        this.deleteScientificEvidence = this.deleteScientificEvidence.bind(this);
        this.onVideoUploaded = this.onVideoUploaded.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.transformData = this.transformData.bind(this);
        this.captureThumbnail = this.captureThumbnail.bind(this);
        this.capturePositionPicture = this.capturePositionPicture.bind(this);
        this.deletePositionPicture = this.deletePositionPicture.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.yesNoOptions = [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ];
        this.state = {
            exerciseState: 1,
        };
        this.player = null;
    }

    saveAndPublish() {
        let submit = this.props.handleSubmit(this.doSave);
        this.setState({
            exerciseState: 2
        }, () => {
            submit();
        });
    }

    saveAsDraft() {
        let submit = this.props.handleSubmit(this.doSave);
        this.setState({
            exerciseState: 1
        }, () => {
            submit();
        });
    }

    componentWillMount() {
        this.props.getExercise({ id: this.props.match.params.exercise_id });
    }

    deletePositionPicture(url, del) {
        let pics = _.cloneDeep(this.props.position_pictures);
        var pic = _.find(pics, (item) => { return item.url === url; });

        if ("id" in pic) {
            pic.delete = true;
        } else {
            pics = _.remove(pics, function(item) {
                if (item.url !== pic.url){
                    return true;
                }
            });
        }
        this.props.change('position_pictures', pics);
    }

    deleteVideo() {
        this.props.change('video_guid', null);
        this.props.change('exercise_thumbnail_url', null);
    }

    uploadScientificEvidence(files) {
        let formData = new FormData();
        let params = {
        };
        formData.append("media", files[0]);
        for (let p in params) {
            formData.append(p, params[p]);
        }
        this.props.uploadMedia(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('scientific_evidence_url', result.url);
            //this.props.change('scientific_evidence_file_name', result.file_name);
            this.props.change('scientific_evidence_guid', result.guid);
        }).catch((error) => {
        });
    }
    
    deleteScientificEvidence() {
        this.props.change('scientific_evidence_url', null);
        this.props.change('scientific_evidence_guid', null);
        //this.props.change('scientific_evidence_file_name', null);
    }


    onVideoUploaded(uploadResult) {
        this.props.change('video_guid', uploadResult.guid);
        this.props.change('exercise_thumbnail_url', uploadResult.thumbnail);
    }
    

    captureThumbnail() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type':'application/json',
            }
        });
        if (that.player) {
            this.props.showProgress();
            that.player.getCurrentTime().then(function(time) {
                let params = {time:time,active:true};
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.video_guid + "/pictures",params
                ).then((response) => {
                    let thumbnail_url =  "";
                    if(response.data.sizes.length > 3 ){
                        thumbnail_url = response.data.sizes[3].link;
                    }else{
                        thumbnail_url = response.data.sizes[response.data.sizes.length -1].link;
                    }
                    that.props.change('exercise_thumbnail_url', thumbnail_url);    
                    that.props.hideProgress();
                    Utils.showSuccessAlert("Your new thumbnail image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.hideProgress();
                });
            }).catch(function(error) {
                that.props.hideProgress();
            });
        }
    }

    capturePositionPicture() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type':'application/json',
            }
        });
        if (that.player) {
            this.props.showProgress();
            that.player.getCurrentTime().then(function(time) {
                let params = {time:time};
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.video_guid + "/pictures",params
                ).then((response) => {
                    let thumbnail_url =  "";
                    if(response.data.sizes.length > 3 ){
                        thumbnail_url = response.data.sizes[3].link;
                    }else{
                        thumbnail_url = response.data.sizes[response.data.sizes.length -1].link;
                    }
                    store.dispatch(arrayPush('ExerciseEditForm', 'position_pictures', {
                        url: thumbnail_url,
                        time: time,
                    }));
                    that.props.hideProgress();
                    Utils.showSuccessAlert("Your new position pic image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.hideProgress();
                });
            }).catch(function(error) {
                that.props.hideProgress();
            });
        }
    }


    onVideoUploadFailed() {
        Utils.showErrorAlert("Video upload failed! Please try again.");
    }

    doSave(data) {
        let params = this.transformData(data);
        params.state = this.state.exerciseState;
        this.props.postExercise({ ...params }).then((response) => {
            history.goBack();
        }).catch((error) => { });
    }

    transformData(data) {
        let params = Utils.flatternFormData(data, ['position_pictures']);
        return params;
    }

    render() {
        if (_.isEmpty(this.props.initialValues)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getExercise,
        postExercise,
        deleteExercise,
        uploadMedia,
        cleanModule: actionCreator.cleanModule,
        showProgress,
        hideProgress,
    }, dispatch);
}
const selector = formValueSelector('ExerciseEditForm');

function mapStateToProps(state, ownProps) {
    return {
        exercise: state.exercise.exercise.exercise,
        initialValues: state.exercise.exercise.exercise,
        supportingData: state.exercise.exercise.supportingData,
        position_pictures: selector(state, 'position_pictures'),
        metric: selector(state, 'metric'),
        exercise_thumbnail_url: selector(state, 'exercise_thumbnail_url'),
        video_guid: selector(state, 'video_guid'),
        is_evidence_based: selector(state, 'is_evidence_based'),
        scientific_evidence_guid: selector(state, 'scientific_evidence_guid'),
        scientific_evidence_url: selector(state, 'scientific_evidence_url'),
        is_client_specific: selector(state, 'is_client_specific'),
        category: selector(state, 'category'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'name', 'category','client'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ExerciseEditForm = reduxForm(
    {
        form: 'ExerciseEditForm',
        enableReinitialize: true,
        validate
    }
)(ExerciseEdit);

const ExerciseEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExerciseEditForm);

export default ExerciseEditConnected;
