import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "../templates/facility/facility_booking_edit.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { reduxForm, formValueSelector } from 'redux-form';
import { facilityBookingGet, facilityBookingPost,facilitySlotAvailabilityToggle } from '../../../redux/calendar/network/facility_network';
import facilityActionCreator from '../../../redux/calendar/actions/facility_action';
import moment from 'moment';
import Swal from 'sweetalert2';

class FacilityBookingEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.updateSlotAvailability = this.updateSlotAvailability.bind(this);
    }

    componentWillMount() {
        this.props.facilityBookingGet({
            'facility_id': this.props.facility_id,
            'id':this.props.booking_slot_id,
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id 
        });
    }

    doSave(data){
        let that = this;
        let params = _.pick(data,["id","clients"]);
        params['facility_id'] = this.props.facility_id;
        this.props.facilityBookingPost({
            ...params,
            app_version: "5.2",
            current_role_type: "practitioner",
            package_version: "1",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id })
        .then((response) => {
            that.props.closeBookingDialog(true);
        })
        .catch((error) => { 

        });
    }

    updateSlotAvailability() {
        let that = this;
        let params = {};
        params['facility_id'] = this.props.facility_id;
        params['id'] =this.props.booking_slot_id;
        let title = "Are you sure make this facility slot unavailable?";
        let desc = "Any associated client bookings will be deleted!";
        if(!this.props.booking_slot.is_available){
            title = "Are you sure make this this facility slot available?";
            desc = "Clients can start booking for this facility!";
        }
        Swal.fire({
            title: title,
            text: desc,
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            that.props.facilitySlotAvailabilityToggle({
                ...params,
                app_version: "5.2",
                current_role_type: "practitioner",
                package_version: "1",
                "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id }).then(() => {
                that.props.closeBookingDialog(true);
            }).catch(() => { });
        }, ()=> { });
    }

    componentWillUnmount() {      
        this.props.cleanUp(this.props.facility,{},[]);  
    }

    render() {
        if(_.isEmpty(this.props.booking_slot)){
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

const validate = (values, props) => {
    const errors = {};
    if(values.client && values.client.length > props.facility.max_client_count){
        errors["clients"] = "This facility can have maximum of "+ props.facility.max_client_count +"clients";
    }
    return errors;
};


const FacilityBookingEditForm = reduxForm({
        form: 'FacilityBookingEditForm',
        enableReinitialize: true,
        validate
    }
)(FacilityBookingEdit);

const selector = formValueSelector('FacilityBookingEditForm');


function mapStateToProps(state, ownProps) {
    let booking_slot = _.cloneDeep(state.calendar.facilityReducer.booking_slot);
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        initialValues: booking_slot,
        facility: state.calendar.facilityReducer.facility,
        booking_slot: booking_slot,
        clientList: state.calendar.facilityReducer.client_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        facilityBookingGet,
        facilityBookingPost,
        facilitySlotAvailabilityToggle,
        cleanUp: facilityActionCreator.facilityBookingObjectFill
    }, dispatch
    );
}


const FacilityBookingEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(FacilityBookingEditForm);
export default FacilityBookingEditFormConnected;