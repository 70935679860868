import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ExerciseList from '../../../exercise_new/components/exercise_list_new';
import PanelGroup from '../../../vendor/react-panelgroup/panelgroup';
import { FieldArray } from 'redux-form';
import MultiDatePicker from '../../../utils/common_components/multi_date_picker';
import Chip from 'react-toolbox/lib/chip';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { PARTNER } from '../../../core/constants/constants';
export default function () {
    function repeatDay1(day, index) {
        return React.createElement(Chip, {
            'className': 'bg-success-red c-white chip-thin',
            'key': 'days' + index
        }, '\n                                    ', day, '\n                                ');
    }
    return React.createElement('div', { 'className': 'row progression-edit ' + (this.props.from_gameplan || this.props.group_id ? 'p-relative' : '') }, !this.props.program_id ? React.createElement('div', { 'key': '1893' }, '\n        no info\n    ') : null, this.state.datePickerVisible ? React.createElement(MultiDatePicker, {
        'active': this.state.datePickerVisible,
        'enabledDays': this.props.progression.available_dates,
        'selectedDays': this.props.selected_dates,
        'onCancel': () => this.showDatePicker(false),
        'onAccept': selectedDays => this.finishDatesSelection(selectedDays),
        'key': '1961'
    }) : null, React.createElement(PanelGroup, {
        'borderColor': '#eee',
        'panelWidths': [
            {
                size: '40%',
                resize: 'stretch',
                minSize: '10%'
            },
            {
                size: '60%',
                resize: 'stretch',
                minSize: '10%'
            }
        ]
    }, React.createElement('div', { 'className': 'col-xs-12  bg-white p-20' }, React.createElement('form', {
        'className': 'bg-white no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 description' }, React.createElement(Field, {
        'className': 'w-100 m-b-16',
        'style': { height: '48px' },
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), this.props.program.schedule_type !== 2 ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '2884'
    }, this.props.programType !== 'workout' ? React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'days',
        'component': Select,
        'label': 'Days',
        'options': this.props.supportingData.days,
        'multi': true,
        'key': '2979'
    }) : null, this.props.programType === 'workout' && this.props.days_display ? React.createElement('div', {
        'className': 'row bg-accent middle-xs',
        'key': '3215'
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'start-xs m-l-10 m-t-6 m-b-5' },
        _.map(this.props.days_display.slice(0, 10), repeatDay1.bind(this)),
        this.props.days_display.length > 10 ? React.createElement(TooltipWrapper, {
            'className': 'di-block',
            'tooltip': 'Click calendar icon to view all workout dates',
            'key': '3669'
        }, React.createElement(Chip, { 'className': 'bg-success chip-thin' }, '\n                                        more...\n                                    ')) : null
    ]), this.props.days_display.length === 0 ? React.createElement('div', {
        'className': 'col-xs-11 start-xs m-t-6 m-b-5 middle-xs',
        'key': '4085'
    }, '\n                                Click on calendar icon to choose dates\n                             ') : null, React.createElement('div', { 'className': 'col-xs-1 end-xs' }, React.createElement(IconButton, {
        'tooltip': 'Click to choose dates',
        'icon': 'calendar',
        'className': 'c-white',
        'onClick': () => this.showDatePicker(true)
    }))) : null) : null, React.createElement('div', { 'className': 'col-xs-12 m-t-15 m-b-10 duration border-around' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'duration',
        'min': '1',
        'type': 'number',
        'component': TextInput,
        'label': 'Workout duration (in minutes) *'
    })), React.createElement('div', { 'className': 'movement-values' }, React.createElement('p', { 'className': 'f-12 c-primary m-t-10 m-b-10' }, 'Contribution to Weekly Movement Goals'), React.createElement('div', {
        'className': 'col-xs-12 m-b-10 m-t-10',
        'style': { height: '48px' }
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'mobility_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Mobility (In minutes)'
    })), React.createElement('div', {
        'className': 'col-xs-12 m-b-10',
        'style': { height: '48px' }
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'strength_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Strength (In minutes)'
    })), React.createElement('div', {
        'className': 'col-xs-12 m-b-10',
        'style': { height: '48px' }
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'metabolic_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Metabolic (In minutes)'
    })), React.createElement('div', {
        'className': 'col-xs-12 m-b-10',
        'style': { height: '48px' }
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'power_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Power (In minutes)'
    }))), React.createElement('div', { 'className': 'col-xs-12 f-18 m-t-10 start-xs' }, '\n                        Selected Exercises\n                    '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row m-r-0' }, React.createElement(FieldArray, {
        'name': 'exercises',
        'component': this.renderExercises
    }))), this.props.program.practice.id == this.props.selectedRole.practice.id ? React.createElement('div', {
        'className': 'col-xs-12 d-flex flex-sb text-center m-t-10',
        'key': '7165'
    }, this.props.from_gameplan || this.props.group_id || this.props.clientWorkout ? React.createElement(Button, {
        'onClick': this.onPublish,
        'value': 'publish',
        'className': this.props.from_gameplan || this.props.group_id ? ' m-l-60 height-fit bg-success-red c-white text-capital m-b-12 m-l-10 w-30 d-flex' : 'bg-success-red c-white text-capital m-b-12 m-l-10 w-100',
        'label': this.props.from_gameplan || this.props.clientWorkout ? 'Save' : 'Publish',
        'raised': true,
        'key': '7509'
    }) : null) : null))), this.props.program.practice.id == this.props.selectedRole.practice.id ? React.createElement('div', {
        'className': 'col-xs-12 p-l-10',
        'key': '8324'
    }, React.createElement(ExerciseList, {
        'exerciseSelectionMode': true,
        'onExerciseSelection': this.addExercise
    })) : null), React.createElement('div', { 'className': this.props.from_gameplan || this.props.group_id ? 'w-100 publish-gameplan-info p-relative flex-column p-right-0 p-bottom-0 height-fit' : 'w-23 publish-info p-absolute p-right-0 p-top-0' }, this.props.program.practice.id == this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '8799' }, React.createElement(Card, { 'className': this.props.from_gameplan || this.props.group_id ? 'bg-white box-shadow' : 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('div', { 'className': this.props.from_gameplan || this.props.group_id ? 'w-100 row submit-buttons end-xs m-t-30 m-b-10 m-r-10 middle-xs' : 'row submit-buttons end-xs m-t-30 m-r-10 middle-xs' }, !this.props.from_gameplan && !this.props.group_id ? React.createElement(Button, {
        'onClick': this.onDraft,
        'label': 'Save as Draft',
        'value': 'draft',
        'disabled': this.props.submitting,
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'key': '9277'
    }) : null, !this.props.from_gameplan && !this.props.group_id ? React.createElement(Button, {
        'onClick': this.onPublish,
        'value': 'publish',
        'className': this.props.from_gameplan || this.props.group_id ? 'bg-success m-b-12 m-l-10 w-30 d-flex' : 'bg-success m-b-12 m-l-10 w-100',
        'label': this.props.from_gameplan ? 'Save' : 'Publish',
        'raised': true,
        'key': '9514'
    }) : null))) : null, this.props.program.practice.id !== this.props.selectedRole.practice.id && !this.props.from_gameplan && !this.props.group_id ? React.createElement('div', { 'key': '10002' }, !this.props.isTemplatesPurchased ? React.createElement('div', { 'key': '10156' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('p', { 'className': 'paywall-msg m-b-10' }, 'To access this template and all other premium templates, please purchase the required subscription from your App Store.'), React.createElement('div', {
        'className': 'width-fit',
        'style': { margin: '0 auto' }
    }, React.createElement('a', {
        'href': 'https://apps.apple.com/us/app/dhf-coachfit/id1130877074',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to App Store',
        'className': 'paywall-appstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    }))), React.createElement('div', {
        'className': 'width-fit',
        'style': { margin: '0 auto' }
    }, React.createElement('a', {
        'href': 'https://play.google.com/store/apps/details?id=com.gomotive.dhf',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to Play Store',
        'className': 'paywall-playstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    }))))) : null) : null));
}