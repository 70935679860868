// Action Creators
const fillProgram = (program, supportingData) => ({
    type: 'PROGRAM_EDIT_FILL',
    program,
    supportingData,
});
const cleanModule = () => ({
    type: 'PROGRAM_EDIT_CLEANUP',
});

export default {
    fillProgram,
    cleanModule,
};