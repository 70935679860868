import Axios from '../../../utils/network/axios';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import Utils from '../../../utils/utils';
import { API_VERSION, PARTNER } from '../../../core/constants/constants';

const uploadFiles = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/6/home/file_upload";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const sendReactError = (params) => {
    return (dispatch) => {
        const url = "/1/home/client_error";
        return Axios.post(url, params).then(async (response) => {
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    uploadFiles,
    sendReactError
};