// Action Creators
const practitionerHomeAlertsFill = (
    unread_message_count,
    unread_chat_list,
    alert_count,
    metric_settings,
    practitioner_welcome_video_url,
    business_partner
) => ({
    type: 'PRACTITIONER_HOME_ALERTS_FILL',
    unread_message_count,
    unread_chat_list,    
    alert_count,
    metric_settings,
    practitioner_welcome_video_url,
    business_partner,
});

const fillPractitionerList = (practitioners) => ({
    type: 'PRACTITIONER_LIST_FILL',
    practitioners
});

const cleanModule = () => ({
    type: 'PRACTITIONER_HOME_CLEANUP',
});

export default {
    practitionerHomeAlertsFill,
    fillPractitionerList,
    cleanModule,
};
