import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/engagement_goal_tracking_chart.rt";
import Utils from '../../utils/utils';
import {getGoalTrackingChartDetails } from '../../redux/engagement/network/client_engagement_network.js';
import { history } from '../../redux/store';
import moment from 'moment';

class GoalTrackingChart extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.state = {

        };
    };
    
    componentWillMount() {
        if (this.props.goal_track.id){
             this.props.getGoalTrackingChartDetails({
                'goal_track_id': this.props.goal_track.id,
                'engagement':this.props.engagement.id,
                'practice_id':this.props.engagement.practice.id
            });      
        }
    }

    render() {
        if (!this.props.track_details || typeof this.props.track_details === 'undefined') {
            return (<div>Loading....</div>);
        } else {
            return Template.apply(this);
        }
    }

}

function mapStateToProps(state, ownprops) {
    let tracking_key = "goal_track_" + ownprops.goal_track.id;
    let track_details = _.cloneDeep(state.engagement.engagementGoalTrackingChartReducer[tracking_key]);
    track_details = _.map(track_details, (item) => {
        item.tracking_date = moment(item.tracking_date).format("MMMM Do YYYY");
        item.value = item.current_value;
        item.target =ownprops.goal_track.target_value;
        return item;
    });
    let dots = (!track_details || typeof track_details === 'undefined' || track_details.length < 15) ? true : false;
    let returnProps =  {
        engagement: ownprops.engagement,
        goal_track:ownprops.goal_track,
        track_details: track_details,
        dots:dots
    };
    return returnProps;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getGoalTrackingChartDetails,
        }, 
        dispatch
    );
}

const GoalTrackingChartConnected = connect(mapStateToProps,mapDispatchToProps)(GoalTrackingChart);
export default GoalTrackingChartConnected;
