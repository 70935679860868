import Axios from '../../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../../utils/utils';
import {showProgress, hideProgress} from '../../../core/actions/progress_creator';
import CoachAction from '../actions/coach_action';

const getCoachList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_coach_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(CoachAction.coachListFill(
                response['coaches'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const getCoach = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_coach_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(CoachAction.coachFill(
                response['coach'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const saveCoach = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/move_coach_post";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

export {
    getCoachList,
    getCoach,
    saveCoach,
};