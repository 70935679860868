import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/document_edit_new.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm,formValueSelector } from 'redux-form';

import HealthDocumentAction from '../../redux/documents/actions/health_document';
import {getHealthDocument,uploadAttachments,postHealthDocument,deleteDocumentAttachment} from '../../redux/documents/network/health_document';
import swal from 'sweetalert2';
import ProgressBar from 'react-toolbox/lib/progress_bar';




class HealthDocumentEdit extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.deleteAttachment = this.deleteAttachment.bind(this);
        this.state ={attachments:[]};
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
    }

    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        this.loadData(this.props);
    }
    

    componentWillUnmount() {
        this.props.documentFill({});
    }
    
    componentWillReceiveProps(nextProps) {
        if (_.isEmpty(this.props.document) && !_.isEmpty(nextProps.document)) {
            this.setState({attachments:nextProps.document.attachments});
        }

    }
    

    loadData(props) {
        if(!props.match.params.document_type){
            history.goBack();
        }
        let params = {};
        if (props.match.params.document_type === 'nutrition') {
            params.document_type  = 1; 
        } else if ( props.match.params.document_type && props.match.params.document_type === 'guidance'){
            params.document_type  = 2; 
        }
        if(props.match.params.document_id && props.match.params.document_id !==  ""){
            params.id  = props.match.params.document_id; 
        }
        params.package_version = 1
        props.getHealthDocument(params);        
    }

    onDrop(files) {
        let that = this;
        const maxSize = 20 * 1024 * 1024; // 10 MiB
        let fileData = new FormData();
        let fileCheckPass = true;
        _.forEach(files, (file)=>{
            if (file.size > maxSize){
                swal.fire("Oops...", "Maximum upload limit is 20 MB.\nOne or more file exceeds this limit.", "error");    
                fileCheckPass  = false;          
                return;
            }
            fileData.append("attachment_files", file);
        });
        if(!fileCheckPass){
            return;
        }
        if(this.props.document.id){
            fileData.append("document_id", this.props.document.id);
        }
        this.props.uploadAttachments(fileData).then((response) => {
            let attachments = that.state.attachments.concat(response.data);
            that.setState({attachments:attachments});
        }).catch((error) => {
            //do nothing
        });  

    }   

    goBack() {
        if(confirm("Are you sure? All changes will be discarded.")){
        history.goBack();
        }
   } 

    doSave(data) {
        let params = _.omit(data, ['can_be_deleted', '__document_type', 'practice']);
        if (this.state.attachments.length === 0) {
            swal.fire("Oops", "No pdf file documents are attached!", "error");
            return;
        }
        params.attachments = this.state.attachments;
        params.package_version = 1;
        this.props.postHealthDocument(params).then((response) => {
            history.goBack();
        }).catch((error) => {
            //do nothing
        });  
    }    
    deleteAttachment(attachment_url) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You will PERMANENTLY deleting this attachement. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {attachment_url: attachment_url};
            if (that.props.document.id) {
                params.document_id = that.props.document.id;
            }
            params.package_version = 1;
            that.props.deleteDocumentAttachment(params).then((response) => {
                let attachments = _.cloneDeep(that.state.attachments);
                attachments = _ .filter(attachments,(item)=>{
                    if(item.attachment_url === attachment_url) {
                        return false;
                    } else {
                        return true;
                    }
                });
                that.setState({ attachments: attachments }, () => {
                    swal.close();
                });

            }).catch((error) => {
                swal.close();
            });              
        }, function (dismiss) {
                        
        });
    }    

    render() {
        if (_.isEmpty(this.props.document)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownprops) {
    let document = { ...state.health_document.documentReducer.health_document };
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        document: document,
        initialValues: { ...document },
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getHealthDocument,
        uploadAttachments,
        deleteDocumentAttachment,
        postHealthDocument,
        documentFill: HealthDocumentAction.documentFill,
    }, dispatch);
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const HealthDocumentEditForm = reduxForm(
    {
        form: 'healthDocumentEditForm',
        enableReinitialize: true,
        validate
    }
)(HealthDocumentEdit);
const HealthDocumentEditConnected = connect(mapStateToProps,mapDispatchToProps)(HealthDocumentEditForm);
export default HealthDocumentEditConnected;
