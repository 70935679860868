import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_workouts_all";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../redux/client/actions/client_actions';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import { getClientAllWorkouts } from "../../redux/client/network/client_network";
import swal from 'sweetalert2';
import { workoutPdfGenerate } from '../../redux/program/network/program_list';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import ProgressBar from 'react-toolbox/lib/progress_bar';

class ClientWorkoutsAll extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.gotoDetailedView = this.gotoDetailedView.bind(this);
        this.doWorkoutPdf = this.doWorkoutPdf.bind(this);
    }

    componentWillMount() {
        let params = {}
        params["fetch_type"] = this.props.match.params.type;
        params["id"] = this.props.match.params.id;
        this.props.getClientAllWorkouts(params);
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);    
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

    gotoDetailedView(workout) {
        history.push('/client/workouts/'+this.props.match.params.type+'/'+this.props.match.params.id+"/all/"+workout.id);
    }

    doWorkoutPdf(workout) {
        this.props.workoutPdfGenerate({ id: workout.id }).then((response) => {
            swal.fire({
                title: '',
                type: 'success',
                html:
                '<a target="_blank" href="' + response["path"] + '">Click here to download the pdf</a> ',
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
                confirmButtonText: '',
                confirmButtonAriaLabel: '',
                useRejections:true
            });

        }).catch(() => { });
    }


    render() {
        if(this.props.program !== null) {
            return Template.apply(this);
        } else {
            return (
                <div className="row center-xs m-t-25">
                    <ProgressBar type="circular" mode="indeterminate" multicolor />
                </div>
            );
        }
    }    
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientAllWorkouts,
        workoutPdfGenerate,
        cleanModule: actions.cleanModule,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        program: state.client.clientReducer.v2_selected_program, 
    };
}

const ClientWorkoutsAllConnected = connect(mapStateToProps, mapDispatchToProps)(ClientWorkoutsAll);
export default ClientWorkoutsAllConnected;
