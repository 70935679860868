import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import GoalCreator from '../actions/goal_creator';

const getGoalTrackingUnitList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/goal_tracking_unit_list";
        return Axios.post(url, params).then((response) => {
            dispatch(GoalCreator.goalTrackingUnitListFill(response.goal_tracking_unit_list));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getGoalList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/goal_list";
        return Axios.post(url, params).then((response) => {
            dispatch(GoalCreator.goalListFill(
                response.data.goal_list,
                response.data.hidden_goals,
            ));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/goal_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            if (params['id']) {
                Utils.showSuccessAlert("An existing goal was successfully updated");    
            } else {
                Utils.showSuccessAlert("New goal was successfully created");    
            }            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/goal_get";
        return Axios.post(url, params).then((response) => {
            dispatch(GoalCreator.goalGetFill(
                response.goal, 
                response.goal_types,
                response.goal_tracking_unit
            ));
            dispatch(hideProgress());
            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/goal_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Goal deleted successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const changePartnerGoalVisibilityStatus = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/goal_change_visibility_status";
        return Axios.post(url, params).then((response) => {
            // dispatch(GoalCreator.goalListFill(
            //     response.goal_list,
            //     response.hidden_goals,
            // ));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getGoalTrackingUnitList,
    getGoalList,
    saveGoal,
    getGoal,
    changePartnerGoalVisibilityStatus,
    deleteGoal,
};