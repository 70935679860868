import { combineReducers } from 'redux';
import clientReducer from './client_reducer';
import quickStartReducer from './quickstart_reducer';
import allWorkoutsReducer from './all_workouts_reducer';
import clientWorkfitReducer from './client_workfit_reducer';

export default combineReducers({
    clientReducer,
    quickStartReducer,
    allWorkoutsReducer,
    clientWorkfitReducer
});