import * as React from 'react';
import * as _ from 'lodash';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import HelpTip from '../../../utils/common_components/help_tip';
import ProgressionView from '../../../program/components/progression_view';
export default function () {
    function repeatProgression1(progression, progressionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': progressionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('i', { 'className': 'material-icons  f-35 round-border' }, 'accessibility')), React.createElement(Button, {
            'onClick': () => {
                this.play(progression);
            },
            'tooltip': 'Play Workout',
            'className': 'bg-success  m-t-20',
            'icon': 'play_arrow',
            'floating': true
        })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(ProgressionView, _.assign({}, {
            'display_schedule_type_info': true,
            'progression': progression,
            'showTracking': false
        }, this.props))));
    }
    return React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 m-t-20' }, React.createElement('div', { 'className': 'row middle-xs' }, React.createElement('div', { 'className': 'col-xs-1 center-xs' }, React.createElement('i', { 'className': 'material-icons m-r-10 f-50' }, 'games')), React.createElement('div', { 'className': 'col-xs-11' }, React.createElement('h3', {}, '\n                    ', this.props.workout.name, '\n                ')))), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 m-t-20' },
        _.map(this.props.workout.progressions, repeatProgression1.bind(this))
    ]));
}