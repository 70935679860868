import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_plan_discount.rt";

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import { updatePartnerPlanDiscount} from '../../redux/partner/network/partner_plan_network';

class PartnerPlanDiscount extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doClose = this.doClose.bind(this);
        this.doSave = this.doSave.bind(this);
        this.selectDiscountCoupon = this.selectDiscountCoupon.bind(this);
        this.state = {
            discountCoupon:null
        };
    };

    componentWillMount() {
        if(this.props.plan.discount_coupon !== null) {
            this.setState({
                discountCoupon: this.props.plan.discount_coupon
            });
        }
    }

    doClose() {
        this.props.handleClose();
    }

    doSave() {
        let params = {plan: this.props.plan.id};
        if(this.state.discountCoupon !== -1 && this.state.discountCoupon !== null) {
            params.discount_coupon = this.state.discountCoupon.id;
        } else {
            params.discount_coupon = -1;
        }
        this.props.updatePartnerPlanDiscount(params).then((response) => {
            this.doClose();
        }).catch((error) => {
            //do nothing
        });        
    }

    selectDiscountCoupon(discountCoupon){
        this.setState({
            discountCoupon:discountCoupon
        });
    }

    render() {
        if (_.isEmpty(this.props.plan)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

// PartnerPlanDiscount.propTypes = {
//     plan:PropTypes.object.isRequired,    
//     handleClose:PropTypes.func.isRequired,    
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updatePartnerPlanDiscount
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        discount_plans: state.partner.partnerPlanReducer.discount_plans,
    };
}
const PartnerPlanDiscountConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerPlanDiscount);
export default PartnerPlanDiscountConnected;
