
let initialState = {
    partner_list: [],
    plans: [],
    education_categories: [],
    education_category: {},
    education_content_list: [],
    education_content: {},
    paginate_info: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "EDUCATION_CATEGORY_LIST_FILL":
            if (!action.education_categories){
                return state;
            }                
            return Object.assign({}, state, {
                education_categories: action.education_categories,
            });
        case "EDUCATION_CATEGORY_FILL":
            if (!action.education_category){
                return state;
            }                
            return Object.assign({}, state, {
                plans: action.plans,
                education_category: action.education_category,
            });        
        case "EDUCATION_CONTENT_LIST_FILL":
            if (!action.education_content_list){
                return state;
            }                
            return Object.assign({}, state, {
                education_content_list: action.education_content_list,
            });        
        case "EDUCATION_CONTENT_FILL":
            if (!action.education_content){
                return state;
            }                
            return Object.assign({}, state, {
                education_categories: action.education_categories,
                education_content: action.education_content,
            });                
        case "EDUCATION_PRACTICE_CONTENT_FILL":
                
            if (!action.education_content_list){
                return state;
            }                
            let temp_education_content_list = [];
            if(action.paginate_info && action.paginate_info.page ===0 ){
                temp_education_content_list = action.education_content_list;
            }else{
                let newIds = _.map(action.education_content_list, (item) => { return item.id; });
                let oldRetain = _.filter(state.education_content_list, (item) => { return newIds.indexOf(item.id) === -1; });
                temp_education_content_list = oldRetain.concat(action.education_content_list);
            }

            return Object.assign({}, state, {
                partner_list: action.partner_list,
                education_categories: action.education_categories,
                education_content_list: temp_education_content_list,
                paginate_info: action.paginate_info,
            });                        
        case "EDUCATION_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
