import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../../utils/common_components/textInput_form';
import Switch from '../../../../utils/common_components/switch_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement(Card, { 'className': 'col-xs-12 card-full-height' }, React.createElement(CardTitle, { 'title': 'Coach' }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.saveCoach) }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, this.state.is_avatar_uploaded ? React.createElement('div', {
        'className': 'm-b-20 row center-xs',
        'key': '1532'
    }, React.createElement(Avatar, { 'className': 'avatar-image-100 box-100 bg-transparent' }, this.state.is_avatar_uploaded ? React.createElement('img', {
        'src': this.state.avatar_url,
        'key': '1734'
    }) : null)) : null, React.createElement('div', { 'className': 'row center-xs m-b-10' }, React.createElement(Dropzone, {
        'className': 'dummy-suppress-warning',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadAvatar
    }, React.createElement(Button, {
        'label': this.state.is_avatar_uploaded ? 'Change Profile Picture' : 'Upload Profile Picture',
        'primary': true,
        'raised': true
    })), this.state.is_avatar_uploaded ? React.createElement(Button, {
        'onClick': this.deleteAvatar,
        'label': 'Delete Profile Picture',
        'className': 'm-l-10 bg-danger',
        'primary': true,
        'raised': true,
        'key': '2317'
    }) : null), React.createElement(Field, {
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name',
        'value': '/'
    }, React.createElement(Field, {
        'id': 'descriptionRichText',
        'name': 'bio',
        'type': 'text',
        'component': TextInput,
        'multiline': true,
        'label': 'Bio'
    }), React.createElement(Field, {
        'name': 'contact',
        'type': 'text',
        'component': TextInput,
        'label': 'Contact Details'
    }), React.createElement(Field, {
        'name': 'website',
        'type': 'text',
        'component': TextInput,
        'label': 'Website'
    }))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'm-r-10',
        'disabled': this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }))))))));
}