import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import ClientGroupClassBookingEdit from '../client_group_class_booking_edit';
import ClientGroupClassBookingDelete from '../client_group_class_booking_delete';
export default function () {
    function repeatFrequency1(frequency, frequencyIndex) {
        return React.createElement(Chip, { 'key': frequencyIndex }, '\n                                ', frequency.__day, ' \n                                from \n                                ', moment(frequency.start_time, 'HH:mm:ss').format('h:mm a'), ' \n                                to\n                                ', moment(frequency.end_time, 'HH:mm:ss').format('h:mm a'), ' \n                        ');
    }
    function repeatBooking2(booking, bookingIndex) {
        return React.createElement(Chip, { 'key': bookingIndex }, '\n                            ', moment(booking.group_class_schedule.date).format('MMMM Do YYYY'), ' (', moment(booking.group_class_schedule.start_time).format('h:mm a'), ' to ', moment(booking.group_class_schedule.end_time).format('h:mm a'), ' )\n                        ');
    }
    return React.createElement('div', { 'className': 'm-30 row center-xs' }, React.createElement('div', { 'className': 'panel col-xs-12 col-md-10 col-sm-10 text-left p-0' }, React.createElement('div', { 'className': 'panel-heading panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, this.props.group_class.name), React.createElement('div', { 'className': 'panel-subtitle m-t-5 pre-wrap' }, '\n                ', this.props.group_class.description, '\n            ')), React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'Start Date: '), React.createElement('div', { 'className': 'col-xs-10 material-label' }, moment(this.props.group_class.start_date).format('MMMM Do YYYY'))), React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'End Date: '), React.createElement('div', { 'className': 'col-xs-10 material-label' }, moment(this.props.group_class.end_date).format('MMMM Do YYYY'))), React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'Frequency: '), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-10' },
        _.map(this.props.group_class.group_class_frequency, repeatFrequency1.bind(this))
    ])), React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'Maximum clients per slot: '), React.createElement('div', { 'className': 'col-xs-10' }, React.createElement('span', { 'className': 'badge badge-success' }, this.props.group_class.max_client_count))), React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'Your Bookings: '), React.createElement('div', { 'className': 'col-xs-10' }, _.isEmpty(this.props.booking_details) && this.props.booking_details.bookings.length === 0 ? React.createElement('span', {
        'className': 'alert-warning f-12 p-5',
        'key': '3274'
    }, '\n                            You currently do not have any bookings\n                        ') : null, !_.isEmpty(this.props.booking_details) && this.props.booking_details.bookings.length > 0 ? React.createElement.apply(this, [
        'span',
        { 'key': '3533' },
        _.map(this.props.booking_details.bookings, repeatBooking2.bind(this))
    ]) : null))), React.createElement('div', { 'className': 'panel-footer text-right' }, React.createElement(Button, {
        'onClick': this.doBook,
        'tooltip': 'Book Class',
        'className': 'bg-success',
        'label': 'Book',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.doCancelBooking,
        'tooltip': 'Cancel future bookings',
        'className': 'bg-danger m-l-5',
        'label': 'Cancel Booking',
        'primary': true,
        'raised': true
    }))), React.createElement(Dialog, {
        'active': this.state.showBookingDialog,
        'onEscKeyDown': this.closeBookingDialog,
        'onOverlayClick': this.closeBookingDialog
    }, this.state.showBookingDialog ? React.createElement(ClientGroupClassBookingEdit, {
        'groupClassId': this.props.group_class.id,
        'closeBookingDialog': this.closeBookingDialog,
        'key': '4618'
    }) : null), React.createElement(Dialog, {
        'active': this.state.showCancelBookingDialog,
        'onEscKeyDown': this.closeCancelBookingDialog,
        'onOverlayClick': this.closeCancelBookingDialog
    }, this.state.showCancelBookingDialog ? React.createElement(ClientGroupClassBookingDelete, {
        'groupClassId': this.props.group_class.id,
        'closeBookingDialog': this.closeCancelBookingDialog,
        'key': '4977'
    }) : null));
}