import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progression_play";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProgression } from '../../redux/program/network/progression_edit';
import actionCreator from '../../redux/program/actions/progression_edit';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import { parse } from 'query-string';
import { postProgressionProgress } from '../../redux/program/network/workout_progress';
import MenuAction from '../../redux/core/actions/menu_state_creator';

class ProgressionPlay extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.clickStartVideo = this.clickStartVideo.bind(this);
        this.updateCurrentIndex = this.updateCurrentIndex.bind(this);
        this.changeVideoLoop = this.changeVideoLoop.bind(this);
        this.onPlaybackEnd = this.onPlaybackEnd.bind(this);
        this.hideTrackDialog = this.hideTrackDialog.bind(this);
        this.sliderSettings = {
            dots: true,
            centerMode: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true
        };
        this.dataLoaded = false;

        this.state = {
            currentIndex: 0,
            currentExercise: null,
            loopCurrent: false,
            trackDialogVisible: false,
            trackDate: null,
        };
    }

    componentWillMount() {
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);    
        this.props.getProgression({ id: this.props.match.params.progression_id });
        this.setState({
            trackDate: parse(this.props.location.search).date
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!this.dataLoaded && !_.isEmpty(nextProps.progression)) {
            this.dataLoaded = true;
            this.setState({
                currentIndex: 0,
                currentExercise: nextProps.progression.exercises[0],
            });
        }
    }

    showTrackDialog() {
        this.setState({
            trackDialogVisible: true
        });
    }

    hideTrackDialog() {
        this.setState({
            trackDialogVisible: false
        });
    }

    trackWorkout(status) {
        this.props.postProgressionProgress({
            progression_id: this.props.progression.id,
            has_tracked: status,
            date: Utils.formatServerDate(this.state.trackDate),
        }).then(() => {
            this.hideTrackDialog();
            history.goBack();
        });
    }

    changeVideoLoop(val) {
        this.setState({
            loopCurrent: val,
        });
    }

    clickStartVideo(index) {
        this.setState({
            currentIndex: index,
            currentExercise: this.props.progression.exercises[index],
        });
    }

    onPlaybackEnd() {
        if (this.props.progression.schedule_type === 1 && this.state.trackDate) {
            this.showTrackDialog();
        }
    }

    updateCurrentIndex(index) {
        if (index === this.state.currentIndex) {
            return;
        }
        this.setState({
            currentIndex: index,
            currentExercise: this.props.progression.exercises[index]
        });
    }

    render() {
        if (_.isEmpty(this.props.progression)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProgression,
        clean: actionCreator.cleanModule,
        postProgressionProgress,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let progression = { ...state.program.progressionEdit.progression };
    let video_list = _.map(progression.exercises, (item) => {
        return item.video_guid;
    });
    return {
        ...ownProps,
        progression: progression,
        video_list: video_list,
    };
}

const ProgressionPlayConnected = connect(mapStateToProps, mapDispatchToProps)(ProgressionPlay);
export default ProgressionPlayConnected;
