import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PartnerHome from '../partner_home';
import PartnerAdd from '../partner_practice_add';
import PartnerList from '../partner_list';
import PartnerPlanEdit from '../partner_plan_edit';
import PartnerPlanList from '../partner_plan_list';
import PartnerLibraryHealthDocuments from '../partner_library_health_documents';
import PartnerLibraryTemplates from '../partner_library_templates';
import PartnerLibraryExercises from '../partner_library_exercises';
import PartnerLibraryIntakes from '../partner_library_intakes';
import PartnerLibraryGoals from '../partner_library_goals';
import PartnerLibraryTreatmentTemplates from '../partner_library_treatment_templates';
import PartnerLibraryHabitTemplates from '../partner_library_habit_templates';
import PartnerLibraryList from '../partner_library_list';
import ConsultantList from '../../../practice/consultants/components/practice_consultants';
import ConsultantUpdateBio from '../../../practice/consultants/components/consultant_update_bio';
import ConsultantEdit from '../../../admin/practitioner/components/practitioner_edit';
import PartnerInteraction from '../partner_interaction';
import EducationContent from '../../../education/components/education_content';
import EducationContentEdit from '../../../education/components/education_content_edit';
import EducationCategoryEdit from '../../../education/components/education_category_edit';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/partner/library`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Shared content')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/partner/plans`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Plans you offer')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/partner/business_partners`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Independent Partners')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/partner/consultant`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Dependent Partners')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/partner/education`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Education Materials')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/partner/interaction`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Interaction'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/consultant/edit/:practitioner_id`,
        'component': ConsultantEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/consultant/update_bio/:practitioner_id`,
        'component': ConsultantUpdateBio
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/consultant`,
        'component': ConsultantList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/interaction`,
        'component': PartnerInteraction
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/business_partners/add`,
        'component': PartnerAdd
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/business_partners`,
        'component': PartnerList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/plans/add`,
        'component': PartnerPlanEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/plans/:plan_id`,
        'component': PartnerPlanEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/plans`,
        'component': PartnerPlanList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library/document/:document_type/:library_id`,
        'component': PartnerLibraryHealthDocuments
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library/templates/:library_id`,
        'component': PartnerLibraryTemplates
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library/habit_templates/:library_id`,
        'component': PartnerLibraryHabitTemplates
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library/exercises/:library_id`,
        'component': PartnerLibraryExercises
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library/intakes/:library_id`,
        'component': PartnerLibraryIntakes
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library/goals/:library_id`,
        'component': PartnerLibraryGoals
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library/treatment_templates/:treatment_type/:library_id`,
        'component': PartnerLibraryTreatmentTemplates
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/library`,
        'component': PartnerLibraryList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/home`,
        'component': PartnerHome
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/`,
        'component': PartnerHome
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/education/category/add`,
        'component': EducationCategoryEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/education/category/edit/:category_id`,
        'component': EducationCategoryEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/education/content/add`,
        'component': EducationContentEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/education/content/edit/:content_id`,
        'component': EducationContentEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/education`,
        'component': EducationContent
    })));
}