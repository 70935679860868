import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Panel } from 'react-toolbox';
import Input from 'react-toolbox/lib/input';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-toolbox/lib/date_picker';
import { Dialog } from 'react-toolbox/lib/dialog';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
export default function () {
    function repeatSession1(session, sessionIndex) {
        return React.createElement(Tr, { 'key': session.id }, React.createElement(Td, { 'column': 'workout_completion_time' }, React.createElement('span', {}, !session.read_datetime ? React.createElement('span', {
            'className': 'f-400',
            'key': '3210'
        }, moment(session.workout_completion_time).calendar()) : null)), React.createElement(Td, {
            'column': 'before_feel',
            'style': { textAlign: 'center' }
        }, React.createElement('span', {}, React.createElement('img', {
            'style': { height: '60px' },
            'src': '/site_media/static/images/feels/feel_' + session.before_feel + '.png'
        }))), React.createElement(Td, {
            'column': 'after_feel',
            'style': { textAlign: 'center' }
        }, React.createElement('span', {}, React.createElement('img', {
            'style': { height: '60px' },
            'src': '/site_media/static/images/feels/feel_' + session.after_feel + '.png'
        }))));
    }
    return React.createElement('div', { 'className': 'row start-xs m-20' }, React.createElement('img', {
        'style': {
            height: '70px',
            margin: 'auto'
        },
        'src': '/site_media/static/images/workfit_logo.png'
    }), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable col-xs-12 m-t-20',
            'id': 'table',
            'noDataText': 'Looks like you are you new here. Once you use Workfit you\'ll get your Progress Details here.',
            'sortable': ['workout_completion_time'],
            'isServerPaginate': true,
            'onSort': this.onSort,
            'onPageChange': this.onPageChange,
            'itemsPerPage': this.props.paginate_info.per_page,
            'currentPage': this.props.paginate_info.page,
            'numPages': this.props.paginate_info.total
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'workout_completion_time',
            'style': { textAlign: 'center' }
        }, React.createElement('strong', {}, 'Completed On')), React.createElement(Th, {
            'column': 'before_feel',
            'style': { textAlign: 'center' }
        }, React.createElement('strong', {}, 'Before')), React.createElement(Th, { 'column': 'after_feel' }, React.createElement('strong', {}, 'After'))),
        _.map(this.props.sessions, repeatSession1.bind(this))
    ]));
}