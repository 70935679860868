import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/education_category_edit.rt";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { reduxForm } from 'redux-form';
import {
    getEducationCategory, saveEducationCategory
} from '../../redux/education/network/education_network';
import EducationAction from '../../redux/education/actions/education_actions';

class EducationCategoryEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveEducationCategoryData = this.saveEducationCategoryData.bind(this);
        this.doCancel = this.doCancel.bind(this);
    };
    
    componentWillMount() {
        if(this.props.match.params.category_id) {
            let params = {
                'category_id': this.props.match.params.category_id,
            };
            this.props.getEducationCategory(params);
        } else {
            this.props.getEducationCategory({});
        }
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education");
    }


    saveEducationCategoryData(data) {
        let params = { ...data };
        if (this.props.match.params.category_id) {
            params['category_id'] = this.props.match.params.category_id;
        }
        this.props.saveEducationCategory(params).then((response) => {
            Utils.showSuccessAlert("Your category has been saved.");
            history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education");
        }).catch(() => { });
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    let education_category = _.cloneDeep(state.education.educationReducer.education_category);
    education_category.plans = _.map(education_category.plans, "id");
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,       
        plans: state.education.educationReducer.plans,
        initialValues: education_category,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getEducationCategory,
            saveEducationCategory,
            cleanUp: EducationAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const EducationCategoryEditForm = reduxForm({
    form: 'EducationCategoryEditForm', 
    enableReinitialize: true, 
    validate
})(EducationCategoryEdit);


const EducationCategoryEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(EducationCategoryEditForm);
export default EducationCategoryEditFormConnected;