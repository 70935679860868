
export const CLIENT_LIST_VIDEO = "261405491";
export const ENGAGEMENT_GOAL_VIDEO = "257844036";
export const ENGAGEMENT_ASSESSMENT_VIDEO = "257844024";
export const ENGAGEMENT_PROGRAM_VIDEO = "257844029";
export const EXERCISE_LIST_VIDEO = "261404849";
export const PROGRAM_LIST_VIDEO = "259888654";
export const GROUP_LIST_VIDEO = "257844031";
export const ANALYSIS_3DMAPS_VIDEO = "";
export const ENGAGEMENT_INTAKE_VIDEO = "257844021";
export const PRACTITIONER_HOME_VIDEO = "257844018";
export const GAME_PLAN_TEMPLATE = "259889373";
export const GROUP_TEMPLATE = "259890528";
export const WORKFIT_WELCOME_VIDEO = "267110178";
export const HOMEFIT_WELCOME_VIDEO = "267109435";
export const STUDIOFIT_WELCOME_VIDEO = "267110431";
