import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "../templates/facility/client_facility_list.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../../redux/store';
import Swal from 'sweetalert2';
import { getClientFacilityList } from '../../../redux/calendar/network/facility_network.js';
import  facilityActionCreator from '../../../redux/calendar/actions/facility_action';

class FacilityList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.viewFacilitySlots = this.viewFacilitySlots.bind(this);
    }

    componentWillMount() {
        this.props.getClientFacilityList({});
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    viewFacilitySlots(facility){
        if(_.includes(this.props.location.pathname,"/workfit")){
            history.push("/client/workfit/scheduler/"+facility.id);
        }else{
            history.push("/client/scheduler/facility/"+facility.id);
        }
    }

    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        facilityList: state.calendar.facilityReducer.facility_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientFacilityList,
        cleanUp: facilityActionCreator.cleanModule
    }, dispatch
    );
}


const FacilityListConnected = connect(mapStateToProps, mapDispatchToProps)(FacilityList);
export default FacilityListConnected;