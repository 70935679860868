import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import DatePicker from 'react-toolbox/lib/date_picker';
import Select from 'react-select';
import Button from '../../../../utils/common_components/tooltipButton';
import { Table } from '../../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import FacilityBooking from '../../facility/facility_booking_edit';
export default function () {
    function repeatClient1(booking_slots, bookingKey, booking, bookingIndex, client, clientIndex) {
        return React.createElement(Chip, { 'key': clientIndex }, client.name);
    }
    function repeatBooking2(booking_slots, bookingKey, booking, bookingIndex) {
        return React.createElement('tr', { 'key': 'row' + bookingIndex }, React.createElement('td', {}, React.createElement('span', {}, this.props.facility.name)), React.createElement('td', {
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, moment(booking.start_time).format('h:mm a'), ' to ', moment(booking.end_time).format('h:mm a'))), React.createElement('td', {
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, this.props.facility.max_client_count)), React.createElement('td', {
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, booking.is_available ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '4887'
        }) : null, !booking.is_available ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '5139'
        }) : null)), React.createElement('td', {
            'style': {
                width: '80px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, booking.booking_count)), React.createElement.apply(this, [
            'td',
            {
                'style': {
                    width: '250px',
                    textAlign: 'center'
                }
            },
            _.map(booking.clients, repeatClient1.bind(this, booking_slots, bookingKey, booking, bookingIndex))
        ]), React.createElement('td', {
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, React.createElement(Button, {
            'onClick': () => {
                this.bookFacility(booking);
            },
            'tooltip': 'Book Clients',
            'icon': 'credit_card',
            'label': 'Book',
            'className': 'm-r-5 small-button',
            'primary': true,
            'raised': true
        }))));
    }
    function repeatBooking_slots3(booking_slots, bookingKey) {
        return [
            React.createElement('tr', { 'key': '37381' }, React.createElement('td', {
                'colSpan': '100',
                'className': 'bg-lightgray'
            }, moment(bookingKey, 'YYYY-MM-DD').format('MMM DD, YYYY - dddd'))),
            _.map(booking_slots, repeatBooking2.bind(this, booking_slots, bookingKey))
        ];
    }
    return React.createElement('div', { 'className': 'm-30' }, React.createElement('div', { 'className': 'row  p-relative search-box m-b-20 p-5  vertical-align' }, React.createElement('div', { 'className': 'col-xs-2' }, React.createElement(DatePicker, {
        'label': 'From Date',
        'onChange': this.changeStartDate,
        'value': this.state.start_date
    })), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement(DatePicker, {
        'label': 'To Date',
        'onChange': this.changeEndDate,
        'value': this.state.end_date
    })), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement(Select, {
        'placeholder': 'Slot Timings',
        'options': this.props.facility_frequency,
        'value': this.state.facility_frequency,
        'onChange': this.changeFrequency,
        'clearable': true,
        'simpleValue': true
    }))), React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, React.createElement('strong', {}, 'Name')), React.createElement('th', {
        'style': {
            width: '120px',
            textAlign: 'center'
        }
    }, React.createElement('strong', {}, 'Timings')), React.createElement('th', {
        'style': {
            width: '80px',
            textAlign: 'center'
        }
    }, React.createElement('strong', {}, 'Max client count')), React.createElement('th', {
        'style': {
            width: '80px',
            textAlign: 'center'
        }
    }, React.createElement('strong', {}, 'Available to book')), React.createElement('th', {
        'style': {
            width: '80px',
            textAlign: 'center'
        }
    }, React.createElement('strong', {}, 'Booking Count')), React.createElement('th', {}, React.createElement('strong', {}, 'Clients')), React.createElement('th', {
        'style': {
            width: '150px',
            textAlign: 'center'
        }
    }, React.createElement('strong', {}, 'Action')))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.booking_slots, repeatBooking_slots3.bind(this))
    ])), React.createElement(Dialog, {
        'active': this.state.showBookingDialog,
        'onEscKeyDown': this.closeBookingDialog,
        'onOverlayClick': this.closeBookingDialog
    }, this.state.showBookingDialog ? React.createElement(FacilityBooking, {
        'facility_id': this.props.match.params.facility_id,
        'booking_slot_id': this.state.selected_booking_slot,
        'closeBookingDialog': this.closeBookingDialog,
        'key': '6917'
    }) : null));
}