import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
export default function () {
    function repeatItem1(item, index) {
        return React.createElement('div', {
            'className': 'row bg-beige p-10',
            'onClick': () => this.selectPerformance(item),
            'key': 'performance' + index,
            'style': {
                cursor: 'pointer',
                borderBottom: '1px solid lightgray'
            }
        }, '\n                    ', item.name, '\n                ');
    }
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12 m-20' }, React.createElement(Card, {}, React.createElement(CardTitle, {
        'className': 'text-left',
        'title': 'Performance Modality',
        'subtitle': this.props.client.name + ' (' + this.props.client.email + ')'
    }), React.createElement.apply(this, [
        CardText,
        { 'className': 'p-0' },
        React.createElement('div', { 'className': 'row bg-accent-dark p-10' }, React.createElement('div', { 'className': 'col-xs-12 c-white' }, '\n                        Relative Success Code\n                    '), React.createElement('div', { 'className': 'col-xs-12 c-success m-t-5 f-16' }, '\n                        ', this.props.rsc, '\n                    ')),
        React.createElement('div', { 'className': 'row bg-accent-light p-10' }, '\n                    Select Performance Modality to generate draft workout\n                '),
        _.map(this.state.performanceModalities, repeatItem1.bind(this))
    ]))));
}