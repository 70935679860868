let initialState = {
    welcome_video: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_WELCOME_VIDEO_FILL":
            if (!action.welcome_video){
                return state;
            }
            return Object.assign({}, state, {
                welcome_video: action.welcome_video,
            });
        case "PRACTICE_WELCOME_VIDEO_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
