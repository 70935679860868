import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import {bindActionCreators} from 'redux';
import Template from "./templates/client_my_plans";
import {connect} from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {clientGetPaymentPlanList, subscribeToClientPlan, unSubscribeFromClientPlan} from '../../redux/payment/network/client_payment_plan_network';
import Swal from 'sweetalert2';
import {showProgress} from '../../redux/core/actions/progress_creator';

class ClientPlanSelection extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.subscribe = this.subscribe.bind(this);
        this.unSubscribe = this.unSubscribe.bind(this);
    }

    componentWillMount() {
        this.props.clientGetPaymentPlanList();
    }

    subscribe(token, subscription, plan = null) {
        this.props.showProgress();        
        let params = {};
        if(plan){
            params['plan_id'] = plan.id;
        } else {
            params['plan_id'] = -1;
        }            
        if (token != null) {
            params["token"] = token.id;
        } else {
            params["token"] = null;
        }
        params['practice_id'] = subscription.practice.id;
        this.props.subscribeToClientPlan(params).then(() => {    
            this.props.clientGetPaymentPlanList();         
        }).catch((exception) => {                 
        });                        
    }

    unSubscribe(subscription,plan){
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to unsubscribe.",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {};
            params['practice_id'] = subscription.practice.id;
            that.props.unSubscribeFromClientPlan(params).then(() => {    
                that.props.clientGetPaymentPlanList();         
            }).catch((exception) => {                 
            });                        
        }, function (dismiss) {
                        
        });        
    }



    render() {
        if (this.props.subscriptions.length === 0) {
            return (
                <div></div>
            );
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        subscriptions: state.payment.clientPaymentPlanReducer.client_payment_plan_subscriptions,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clientGetPaymentPlanList,
        subscribeToClientPlan,
        unSubscribeFromClientPlan,
        showProgress,
    }, dispatch);
}
const ClientPlanSelectionConnected = connect(mapStateToProps, mapDispatchToProps)(ClientPlanSelection);
export default ClientPlanSelectionConnected;