import React  from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/program_list";
import { reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    listProgram, deleteProgram, toggleFavorite, saveAs, clone,
    createWorkoutFromProgram, getProgramFilters
} from '../../redux/program/network/program_list';
import actionCreator from '../../redux/program/actions/program_list';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import Swal from 'sweetalert2';
import { WORKOUT_TARGET_CLIENT, TEMPLATE_SELECTION_MODE, WORKOUT_TARGET_GROUP } from '../../redux/program/constants/constants';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { PROGRAM_LIST_VIDEO } from '../../utils/help_video';
import ClientAction from '../../redux/engagement/actions/client_creator';


class ProgramList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.refreshProgramList = this.refreshProgramList.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.saveAs = this.saveAs.bind(this);
        this.clone = this.clone.bind(this);
        this.createWorkoutFromProgram = this.createWorkoutFromProgram.bind(this);
        this.createWorkoutFromWorkoutTemplate = this.createWorkoutFromWorkoutTemplate.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.drawerToggle = this.drawerToggle.bind(this);
        this.setFilterPreferences = _.debounce(this.setFilterPreferences.bind(this),1000);
        this.selectRender = this.selectRender.bind(this);
        this.valueRender = this.valueRender.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);      
        this.refillData = this.refillData.bind(this);      
        this.onChangeQuickstartSelectionList = this.onChangeQuickstartSelectionList.bind(this);
        this.state = {
            search:"",
            filterPreferences:{first:true},
            previewingExercise: null,
            showFilter: false,
            'quickstart_type': 'visible',
            'quickstartSelectionList': [
                {
                    'value': 'visible',
                    'label': 'Visible Quickstarts'
                },
                {
                    'value': 'hidden',
                    'label': 'Hidden Quickstarts'
                }
            ]
        };

    }

    saveAs(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to save this " + that.props.typeLCase + " as " +
            (that.props.programType === 'template' ? 'quickstart' : 'template') + "?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.saveAs({ id: id, save_as_type: that.props.typeParam === 0 ? 1 : 0 });
        }, function (dismiss) {

        });
    }

    clone(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to clone this " + this.props.typeLCase + "?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.clone({ id: id }).then(() => {
            }).catch(() => { });
        }, function (dismiss) {

        });
    }

    toggleFavorite(id, is_favorite) {
        this.props.toggleFavorite({
            id: id,
        }).then(() => {
            this.fetchFilterData();
        }).catch(() => { });
    }

    onChangeQuickstartSelectionList(quickstart_type) {
        this.setState({ 'quickstart_type': quickstart_type }, () => {
            this.refreshProgramList(this.props);
        });
    }

    refreshProgramList(props, increment = false, first = false) {
        let params = {
            search : this.state.search,
            program_type: props.typeParam,
            ...props.paginateInfo,
            ...this.state.filterPreferences
        };
        if(first){
            params.first = first;
        }
        if (increment) {
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        params["quickstart_type"] = this.state.quickstart_type;
        this.props.listProgram(params);
    }

    onSearch(text){
        this.setState({search:text},()=>{
            this.refreshProgramList(this.props);
        });
    }

    drawerToggle = () => {
        this.setState({showFilter: !this.state.showFilter});
    };


    componentDidMount() {
        this.refillData(this.props);
    }

    refillData(props) {
        this.refreshProgramList(props,false, true);
        this.fetchFilterData();
        if (props.programType === "template") {
            props.updateHelpVideo(PROGRAM_LIST_VIDEO);
        } 
        this.setState({ showFilter: false });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.typeParam !== nextProps.typeParam) {
            this.refillData(nextProps);
        } else {
            if(!_.isEmpty(this.props.current_filters) && !_.isEqual(this.props.current_filters,nextProps.current_filters)){
                this.setFilterPreferences(nextProps.current_filters);
            }  
        }
    }

    delete(id, toggle_visibility) {
        let display_text = "";
        if(toggle_visibility === "hide") {
            display_text = "Are you sure you want to hide this " + this.props.typeLCase + "?";
        } else {
            display_text = "Are you sure you want to unhide this " + this.props.typeLCase + "?";
        }
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.deleteProgram({ 
                'program_type': that.props.typeLCase,
                id: id,
                'visibility': toggle_visibility, 
            }).then(() => {
            }).catch(() => { });
        }, function () {
        });
    }

    createWorkoutFromProgram(id) {
        let params = {
            program_id: id,
        };
        if (_.has( this.props,"treatment_template_id")) {
            params.treatment_template_id = this.props.treatment_template_id;
        } else if(this.props.workoutTargetType === WORKOUT_TARGET_CLIENT){
            params.engagement_id = this.props.engagement_id;
        }else if(this.props.workoutTargetType === WORKOUT_TARGET_GROUP){
            params.group_id = this.props.group_id;
        }
        this.props.createWorkoutFromProgram(params).then((response) => {
            this.props.afterCreateWorkoutFromProgram(response.id);
        }).catch(() => { });
    }

    createWorkoutFromWorkoutTemplate(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to create a workout for your client " + this.props.selectedClientForAction["name"] + " ?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {
                program_id: id,
            };
            params.engagement_id = that.props.selectedClientForAction["active_engagement_id"];        
            that.props.createWorkoutFromProgram(params).then((response) => {
                if(that.props.business_partner.app_type === "gi") {
                    history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + that.props.selectedClientForAction["active_engagement_id"] + "?tab=1&workout="+response["id"]);
                } else {
                    history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + that.props.selectedClientForAction["active_engagement_id"] + "?tab=3&workout="+response["id"]);
                }
            }).catch(() => { });
        }, function () {
        });        
    }

    add() {
        history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + "/add");
    }

    edit(id) {
        history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + "/edit/" + id);
    }

    applyFilter(){
        let that = this;
        this.setState({showFilter:false},()=>{
            setTimeout(function() {
                that.refreshProgramList(that.props);
            }, 500);  
        });
    }

    resetFilter(){
        this.props.reset();
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
        this.props.clearHelpVideo();
        this.props.clearClientSelection();
    }

    fetchFilterData(){
        this.props.getProgramFilters(this.state.filterPreferences);
    }

    setFilterPreferences(filterData){
        let params = {};
        if(filterData.my_deleted_templates) {
            params["my_deleted_templates"] = true;
            this.setState({filterPreferences:params});
        } else {
            params["my_deleted_templates"] = false;
            if(_.has(filterData,"is_favorite")){
                params.is_favorite = filterData.is_favorite;
            }
            if(filterData.category && filterData.category.id){
                params.category = filterData.category.id;
                if(filterData.category.id === 5 &&  filterData.sports && filterData.sports.length > 0){
                    params.sports =  Utils.makeObjectArrayFlat(filterData.sports,"id"); 
                }
                if(filterData.category.id === 5  && filterData.sports_exercise_type && filterData.sports_exercise_type.id){
                    params.sports_exercise_type =  filterData.sports_exercise_type.id;
                }
            }
            if(_.includes(filterData.program_sources, "my_programs")){
                params.my_programs = true;
            }
            if(_.includes(filterData.program_sources, "my_practice_programs")){
                params.my_practice_programs = true;
            }
            let partners  = _.filter(filterData.program_sources,(item)=>{
                if(item === "my_programs" || item === "my_practice_programs"){
                    return false;
                }else{
                    return true;
                }
            });     
            if(partners.length > 0){
                params.partners = partners;
            }
            this.setState({filterPreferences:params},()=>{
                this.fetchFilterData();
            });
        }
    }

    selectRender(option){
        return (
            <span>{option.label ? option.label : option.name}<span className="pull-right badge bg-success" >{option.count ? option.count : 0}</span></span>
        );
    }

    valueRender(option){
        return (
            <span className="vertical-align">{option.label ? option.label : option.name}<span className="badge bg-success m-l-10" >{option.count ? option.count : 0}</span></span>
        );
    }

}

ProgramList.propTypes = {
    // indicates whether this component is re-used inside another component
    usedInside: PropTypes.bool,
    afterCreateWorkoutFromProgram: PropTypes.func,
    cancelTemplateSelection: PropTypes.func,
};
ProgramList.defaultProps = {
    usedInside: false,
    afterCreateWorkoutFromProgram: (workout_id) => {        
        history.push(Utils.getProgramUrlPrefix('workout',this.props.selectedRole) + "/edit/" + workout_id);
    },
    cancelTemplateSelection: () => { },
};
const selector = formValueSelector('ProgramFilterForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteProgram,
        listProgram,
        toggleFavorite,
        saveAs,
        clone,
        clean: actionCreator.cleanModule,
        createWorkoutFromProgram,
        getProgramFilters,
        updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearHelpVideo: HelpVideoCreator.clearHelpVideo,
        clearClientSelection: ClientAction.clientSelectionClear,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let programSources = [];
    let filterData = state.program.programList.filterData;
    let searchPreferences = state.program.programList.searchPreferences;
    let initialValues = {
        program_sources:[],
        category:null,
        sports_exercise_type:[],
        is_favorite:_.has(searchPreferences,"is_favorite")  ? searchPreferences.is_favorite : false,
    };
    if(_.has(searchPreferences,"partners")){
        _.forEach(searchPreferences.partners,(item)=>{
            initialValues.program_sources.push(item.toString());
        });
    }
    if(_.has(searchPreferences,"my_practice_programs") && searchPreferences.my_practice_programs){
        initialValues.program_sources.push("my_practice_programs");
    }
    if(_.has(searchPreferences,"my_programs") && searchPreferences.my_programs){
        initialValues.program_sources.push("my_programs");
    }
    if(!_.isEmpty(filterData)){
        programSources.push({label:"My Templates",value:"my_programs",count:filterData.my_programs});
        programSources.push({label:"My Practice Templates",value:"my_practice_programs",count:filterData.practice});
        _.forEach(filterData.partners,(item)=>{
            programSources.push({label:item.name,value:item.id.toString(),count:item.count});
        });
    }
    return {
        programList: state.program.programList.programList,
        paginateInfo: state.program.programList.paginateInfo,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        filterData:filterData,
        programSources:programSources,
        initialValues:initialValues,
        my_deleted_templates: selector(state, 'my_deleted_templates'),
        category: selector(state, 'category'),
        program_sources: selector(state, 'program_sources'),
        selectedClientForAction: state.engagement.clientReducer.selected_client_for_action,
        business_partner: state.practitioner.practitionerReducer.business_partner,
        current_filters: selector(
            state,
            'my_deleted_templates',
            'category', 
            'sports', 
            'program_sources',
            'sports_exercise_type',
            'is_favorite'
        ),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ProgramListFilterForm = reduxForm(
    {
        form: 'ProgramFilterForm',
        enableReinitialize: true,
        validate
    }
)(ProgramList);


const ProgramListFilterFormConnected = connect(mapStateToProps, mapDispatchToProps)(ProgramListFilterForm);
export default ProgramListFilterFormConnected;
