import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Input } from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button';
import { Calendar as BigCalendar } from 'react-big-calendar';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import CalendarView from '../client_calendar_view';
export default function () {
    return React.createElement('div', { 'className': 'm-20' }, React.createElement(Card, { 'className': 'w-100' }, React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement(BigCalendar, {
        'className': 'full-height',
        'localizer': this.props.localizer,
        'selectable': true,
        'popup': true,
        'defaultView': 'month',
        'step': 15,
        'views': [
            'month',
            'week',
            'day',
            'agenda'
        ],
        'events': this.props.eventList,
        'components': { event: this.renderEvent },
        'eventPropGetter': event => {
            return { style: { backgroundColor: event.calendar_type.color_code } };
        },
        'onSelectEvent': event => this.view(event)
    }))), React.createElement(Dialog, {
        'active': this.state.viewDialogShown,
        'onEscKeyDown': this.finishViewing,
        'onOverlayClick': this.finishViewing
    }, React.createElement(CalendarView, {
        'event': this.state.event,
        'finishViewing': this.finishViewing
    })));
}