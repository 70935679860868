// Action Creators
const educationCategoryListFill = (education_categories) => ({
    type: 'EDUCATION_CATEGORY_LIST_FILL',
    education_categories,
});

const educationCategoryFill = (education_category, plans) => ({
    type: 'EDUCATION_CATEGORY_FILL',
    education_category,
    plans,
});
const educationContentListFill = (education_content_list) => ({
    type: 'EDUCATION_CONTENT_LIST_FILL',
    education_content_list,
});

const educationContentFill = (education_categories, education_content) => ({
    type: 'EDUCATION_CONTENT_FILL',
    education_categories,
    education_content,
});

const practiceEducationContentListFill = (partner_list, education_categories, education_content_list, paginate_info) => ({
    type: 'EDUCATION_PRACTICE_CONTENT_FILL',
    partner_list,
    education_categories,
    education_content_list,
    paginate_info
});

const cleanModule = () => ({
    type: 'EDUCATION_CLEANUP',
});

export default {
    educationCategoryListFill,
    educationCategoryFill,
    educationContentListFill,
    educationContentFill,
    practiceEducationContentListFill,
    cleanModule,
};
