import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "../templates/facility/facility_edit";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../../redux/store';
import { reduxForm, formValueSelector } from 'redux-form';
import { facilityPost, facilityGet } from '../../../redux/calendar/network/facility_network';
import moment from 'moment';

class FacilityEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveFacility = this.saveFacility.bind(this);
        this.closeScreen = this.closeScreen.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onDraft = this.onDraft.bind(this);        
        this.addFacilityFrequency = this.addFacilityFrequency.bind(this);        
        this.deleteFacilityFrequency = this.deleteFacilityFrequency.bind(this);        

        this.state = {
            'publish': false,
            'day_of_week_list': [
                {
                    value: 6,
                    label: 'Sunday'
                },
                {
                    value: 0,
                    label: 'Monday'
                },
                {
                    value: 1,
                    label: 'Tuesday'
                },
                {
                    value: 2,
                    label: 'Wednesday'
                },
                {
                    value: 3,
                    label: 'Thursday'
                },
                {
                    value: 4,
                    label: 'Friday'
                },
                {
                    value: 5,
                    label: 'Saturday'
                }
            ]
        };
    }

    componentWillMount() {
        let params = {};
        if(this.props.match.params.facility_id) {
            params["facility_id"] = this.props.match.params.facility_id;
        }
        this.props.facilityGet(params);
    }

    saveFacility(data) {
        if(data.facility_frequency.length === 0){
            Utils.showErrorAlert("Facility needs slot timings!");
            return;
        }

        let params =  _.cloneDeep(data);
        params.start_date = moment(params.start_date).format("YYYY-MM-DD");
        params.end_date = moment(params.end_date).format("YYYY-MM-DD");
        params.is_publish = this.state.publish;
        params.facility_frequency = _.map(params.facility_frequency, function (item) {
            item.start_time  =  moment(item.start_time).format("HH:mm:ss.SSS");
            item.end_time  =  moment(item.end_time).format("HH:mm:ss.SSS");
            return item;
        }); 
        if(this.props.match.params.facility_id) {
            params['facility_id'] = this.props.match.params.facility_id;
        }      
        this.props.facilityPost({ ...params })
        .then((response) => {
            history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/facility");               
        })
        .catch((error) => { 

        });
        return true;
    }

    onPublish() { 
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.saveFacility)();
        });
        return true;
    }
    onDraft() {  
        this.setState({ publish: false }, () => {
            this.props.handleSubmit(this.saveFacility)();
        });
    }

    addFacilityFrequency(){
        let currentFrequencies = [...this.props.facility_frequency];
        currentFrequencies.push({day:null,start_time:undefined,end_time:undefined});
        this.props.change('facility_frequency',currentFrequencies);
    }

    deleteFacilityFrequency(index){
        let currentFrequencies = [...this.props.facility_frequency];
        currentFrequencies.splice(index, 1);
        this.props.change('facility_frequency',currentFrequencies);
    }


    closeScreen(){
        history.goBack();
    }

    render() {
        return Template.apply(this);
    }
}

const selector = formValueSelector('FacilityEditForm');

function mapStateToProps(state, ownProps) {
    let facilityObject = _.cloneDeep(state.calendar.facilityReducer.facility); 
    if(facilityObject.facility_frequency &&  facilityObject.facility_frequency.length === 0){
        facilityObject.facility_frequency.push({day:null,start_time:undefined,end_time:undefined});
    }else{
        facilityObject.facility_frequency = _.map(facilityObject.facility_frequency, function (item) {
            let pickedItem = _.pick(item,["id","start_time","end_time","day"]);
            pickedItem.start_time  = moment(pickedItem.start_time,"hh:mm:ss").toDate();
            pickedItem.end_time  = moment(pickedItem.end_time,"hh:mm:ss").toDate();
            return pickedItem;
        });
    }

    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: facilityObject,
        groupClassObject:facilityObject,
        facility_frequency: selector(state, 'facility_frequency'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        facilityPost,
        facilityGet,
    }, dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description',
        'max_client_count',
        'max_booking_days',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if(values.facility_frequency){
        const timingErrorList = [];
        values.facility_frequency.forEach((timing, index) => {
            const timingErrors = {};
            if(timing.day === "" || timing.day === null ){
                timingErrors.day = "Required";
            }
            if(!timing.start_time || timing.start_time === "" || timing.start_time === null ){
                timingErrors.start_time = "Required";
            }else  if(!timing.end_time || timing.end_time === "" || timing.end_time === null ){
                timingErrors.end_time = "Required";
            }else{
                let orignal_start = moment(timing.start_time);
                let orignal_end = moment(timing.end_time);
                let start_date = moment();
                start_date.hours(orignal_start.hour());
                start_date.minutes(orignal_start.minute());
                start_date.seconds(0);
                start_date.milliseconds(0);
                let end_date = moment();
                end_date.hours(orignal_end.hour());
                end_date.minutes(orignal_end.minute());
                end_date.seconds(0);
                end_date.millisecond(0);
                if(start_date >= end_date){
                    timingErrors.end_time = "End time has to be later than start time";
                }
            }
            timingErrorList[index] = timingErrors;
        });  
        errors["facility_frequency"] = timingErrorList;
    }
    return errors;
};

const FacilityEditForm = reduxForm({
        form: 'FacilityEditForm',
        enableReinitialize: true,
        validate
    }
)(FacilityEdit);


const FacilityEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(FacilityEditForm);
export default FacilityEditFormConnected;