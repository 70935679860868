import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/metric_settings";
import { formValueSelector, reduxForm } from 'redux-form';

import { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMetricSettings, postMetricSettings } from '../../redux/user/network/practitioner_metric_settings';
import Utils from '../../utils/utils';

class MetricSettings extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
    }

    componentWillMount() {
        this.props.getMetricSettings({});
    }

    doSave(data) {
        let params = Utils.flatternFormData(data);
        this.props.postMetricSettings(params).then(() => {
            history.back();
        }).catch(() => { });
    }

    doCancel() {
        history.goBack();
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

const selector = formValueSelector('MetricSettingsForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getMetricSettings,
        postMetricSettings,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        settings: state.user.practitionerMetricSettings.settings,
        initialValues: state.user.practitionerMetricSettings.settings,
        supportingData: state.user.practitionerMetricSettings.supportingData,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [

    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const MetricSettingsForm = reduxForm(
    {
        form: 'MetricSettingsForm',
        enableReinitialize: true,
        validate
    }
)(MetricSettings);

const MetricSettingsConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(MetricSettingsForm);

export default MetricSettingsConnected;
