let initialState = {
};

export default function engagement(state = initialState, action) {
    switch (action.type) {
        case "ENGAGEMENT_GOAL_TRACKING_CHART_FILL":
            if (!action.goal_track_id || !action.goal_track_details){
                return state;
            }
            let key_val = "goal_track_" + action.goal_track_id;
            let updateObject = {};
            updateObject[key_val] = action.goal_track_details;
            return Object.assign({}, state, updateObject);
        case "ENGAGEMENT_GOAL_TRACKING_CHART_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
