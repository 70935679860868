let initialState = {
    help_video_guid:null,
};
export default function menu(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_HELP_VIDEO":
            return Object.assign({}, state, {
                help_video_guid: action.video_guid,
            });        
        case "CLEAR_HELP_VIDEO":
            return Object.assign({}, state, {
                 help_video_guid: null,
            });
        default:
            return state;
    }
}
