import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/workfit_notification_schedule_add.rt";
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { reduxForm } from 'redux-form';
import { getNotificationTemplateList, notificationScheduleCreate } from '../../../redux/dhf/workfit/network/workfit_network';
import WorkfitAction from '../../../redux/dhf/workfit/actions/workfit_actions';
import Swal from 'sweetalert2';

class WorkfitNotificationScheduleAdd extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);   
        this.scheduleNotificationTemplate = this.scheduleNotificationTemplate.bind(this);     
    };
    
    componentWillMount() {
        this.props.getNotificationTemplateList();
    }

    scheduleNotificationTemplate(data) {
        let params = {...data};
        params["scheduled_date"] = Utils.formatServerDate(params.scheduled_date);
        this.props.notificationScheduleCreate(params).then((response) => {
            Utils.showSuccessAlert("Notification has been scheduled");
            history.push("/practice/"+this.props.selectedRole.practice.id+"/branding/notification_schedules");
        }).catch(() => { });
    }
    
    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        notification_templates: state.workfit.workfitReducer.notification_templates,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getNotificationTemplateList,   
            notificationScheduleCreate,         
            cleanUp: WorkfitAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'notification_template',
        'scheduled_date'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const WorkfitNotificationScheduleAddForm = reduxForm({
    form: 'WorkfitNotificationScheduleAddForm', 
    enableReinitialize: true, 
    validate
})(WorkfitNotificationScheduleAdd);


const WorkfitNotificationScheduleAddFormConnected = connect(mapStateToProps,mapDispatchToProps)(WorkfitNotificationScheduleAddForm);
export default WorkfitNotificationScheduleAddFormConnected;