import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/users_content";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { getGoalList, changePartnerGoalVisibilityStatus, } from '../../redux/goal/network/goal_network';
import swal from 'sweetalert2';
import HelpVideoCreator from '../../redux/engagement/actions/client_list_creator';
import { ENGAGEMENT_GOAL_VIDEO } from '../../utils/help_video';
import { getCoachList } from '../../redux/dhf/move/network/coach_network';
import { getPractitionerList, } from '../../redux/practice/network/practice_practitioner';
import { getAdminsList } from '../../redux/users/network/users_network';
import { getAllClientList, getAllClientListForReport } from '../../redux/engagement/network/client_list_network';
import { getPartnerDetails, getPracticePartnerList, getPracticePartnerListForReport, getPracticePartnerNewPlanList, getAuthorList, activatePartner } from '../../redux/partner/network/partner_network';
import moment from 'moment';
import { getSubscriptionNameList } from '../../redux/subscription/network/subscription_network';
import { getUserSubscriptionProfile } from '../../redux/users/network/users_network';
import { API_VERSION } from '../../core/constants/constants';
import Axios from '../../utils/network/axios';

const subscriptionOptions = [
    { "label": "Subscribed", "value": "2", },
    { "label": "Not Subscribed", "value": "0", },
    { "label": "Cancelled", "value": "3", },
]

const ClinetSubscriptionOptions = [
    { "label": "Subscribed", "value": true, },
    { "label": "Not Subscribed", "value": false },
]


const filterbyCoachOptions = [
    { "label": "Registered users", "value": false, },
    { "label": "Subscribed users", "value": true, },
]

const filterbyClientsOptions = [
    { "label": "New Subscription Data", "value": true, },
    { "label": "Old Subscription Data", "value": false, },
]

const PaymentOptions = [
    { "label": "All", "value": "all" },
    { "label": "Apple", "value": "Applestore" },
    { "label": "Google", "value": "Playstore" }
]

let filterbySubscriptionOptions;

class UserList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.changeVisibilityStatus = this.changeVisibilityStatus.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this), 1000);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.selectTrainer = this.selectTrainer.bind(this);
        this.selectSubscription = this.selectSubscription.bind(this);
        this.selectPayment = this.selectPayment.bind(this);
        this.selectSubscriptionName = this.selectSubscriptionName.bind(this);
        this.sendRangeDate = this.sendRangeDate.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.hideReport = this.hideReport.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.ExportData = this.ExportData.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.FilterBySubscription = this.FilterBySubscription.bind(this);

        this.state = {
            paginate_info: "",
            subscriptionOptionsData: this.props.type === "coaches" ? subscriptionOptions : ClinetSubscriptionOptions,
            subscriptionNameToggle: null,
            selectedTrainer: "",
            startDate: null,
            endDate: null,
            showReport: false,
            rows: [],
            selectedOption: null,
            selectedPayment: null,
            filterbysubcriptionToggle: this.props.type === "coaches" ? filterbyCoachOptions[0] : filterbyClientsOptions[0]

        }
    };
    setActive(el, active = true) {
        const formField = el.parentNode.parentNode
        if (active) {
            formField.classList.add('form-field--is-active')
        } else {
            formField.classList.remove('form-field--is-active')
            el.value === '' ?
                formField.classList.remove('form-field--is-filled') :
                formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el) {
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
                el.onblur = () => {
                    this.setActive(el, false)
                }
                el.onfocus = () => {
                    this.setActive(el, true)
                }
            }
        )
    }
    showDetails(data) {
        let start_date = this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null;
        let pathId = null;
        if (this.props.type === "coaches") {
            pathId = data.practice.owner.id
        }
        else {
            pathId = data.id
        }

        history.push({
            pathname: '/practice/' + this.props.selectedRole.practice.id + '/users/' + pathId,
            state: {
                urlData: '/practice/' + this.props.selectedRole.practice.id + '/users',
                type: this.props.type,
                Subscription: { "label": "Subscribed", "value": "2" },
                startDate: this.state.startDate,
                subscriptionList: this.state.filterbysubcriptionToggle
            }
        });
    }

    handleStartDateChange = (value) => {
        this.setState({ startDate: moment(value).format('YYYY-MM-DD') }, () => {
        });
    };

    // Filter by Date
    sendRangeDate() {
        if (this.props.startDate && this.props.endDate) {
            let start_date = this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null;
            let end_date = this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null
            console.log("filterbysubcriptionToggle", this.state.filterbysubcriptionToggle)
            if (this.props.type === 'coaches') {
                if (this.state.filterbysubcriptionToggle == false || this.state.filterbysubcriptionToggle.value == false) {
                    this.props.getPracticePartnerList({
                        practice_id: 276,
                        page: 0,
                        app_version: "5.2",
                        current_role_type: "practice_admin",
                        package_version: "2",
                        subscribed: this.state.selectedOption,
                        payment_mode: this.state.selectedPayment,
                        subscription_id: this.state.subscriptionNameToggle,
                        "search_text": this.state.search,
                        start_date: start_date,
                        end_date: end_date,
                        "plan_type": "old"
                    });
                }
                else {
                    this.props.getPracticePartnerNewPlanList({
                        practice_id: 276,
                        page: 0,
                        app_version: "5.2",
                        current_role_type: "practice_admin",
                        package_version: "2",
                        subscribed: this.state.selectedOption,
                        payment_mode: this.state.selectedPayment,
                        subscription_id: this.state.subscriptionNameToggle,
                        "search_text": this.state.search,
                        start_date: start_date,
                        end_date: end_date,
                        "plan_type": "new"
                    });
                }


            } else if (this.props.type == 'clients') {
                let params = {
                    "app_version": "5.2",
                    "current_role_type": "practice_admin",
                    "package_version": "4",
                    asc: true,
                    page: 0,
                    per_page: 15,
                }
                if (this.state.selectedTrainer) {
                    params.practice_id = this.state.selectedTrainer
                }
                this.props.getAllClientList({
                    ...params,
                    start_date: start_date,
                    end_date: end_date,
                    subscribed: this.state.selectedOption,
                    subscription_id: this.state.subscriptionNameToggle,
                    "search": this.state.search,
                    allClient: true,
                    "plan_type": this.state.filterbysubcriptionToggle ? "new" : "old"
                });
            }
        } else {
            alert("Select the From Date or To Date")
        }
    }
    // Filter by Trainer/Coach
    selectTrainer(id) {
        let value = parseInt(id)
        this.setState({
            selectedTrainer: value
        })
        this.props.getAllClientList({
            practice_id: value,
            "package_version": "4",
            asc: true,
            page: 0,
            per_page: 15,
            subscribed: this.state.selectedOption,
            payment_mode: this.state.selectedPayment,
            subscription_id: this.state.subscriptionNameToggle,
            "search": this.state.search,
            start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
            end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
        });
    }

    //Filter By Subscription New and Old Data
    FilterBySubscription(option) {
        this.setState({
            filterbysubcriptionToggle: option
        })
        if (this.props.type == 'coaches') {
            if (option) {
                this.props.getPracticePartnerNewPlanList({
                    practice_id: 276,
                    page: 0,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    package_version: "2",
                    subscribed: null,
                    payment_mode: null,
                    subscription_id: null,
                    "search_text": this.state.search,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                });
                this.props.getSubscriptionNameList({
                    "package_version": "2",
                    "app_version": "5.4",
                    "plan_type": "new"
                }, 'coaches');
                this.props.getAuthorList({
                    "app_version": "5.2",
                    "practice_id": 276,
                    "current_role_type": "practice_admin",
                    package_version: "2",
                    "plan_type": "new"
                })
            }
            else {
                this.props.getPracticePartnerList({
                    practice_id: 276,
                    page: 0,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    package_version: "2",
                    subscribed: null,
                    subscription_id: null,
                    payment_mode: null,
                    "search_text": this.state.search,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                });
                this.props.getSubscriptionNameList({
                    "package_version": "2",
                    "app_version": "5.4",
                    "plan_type": "old"
                }, 'coaches');
                this.props.getAuthorList({
                    "app_version": "5.2",
                    "practice_id": 276,
                    "current_role_type": "practice_admin",
                    package_version: "2",
                    "plan_type": "old"
                })

            }

        } else if (this.props.type == 'clients') {
            let params = {
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "package_version": "4",
                asc: true,
                page: 0,
                per_page: 15,
            }
            if (this.state.selectedTrainer) {
                params.practice_id = this.state.selectedTrainer
            }
            if (option) {
                this.props.getAllClientList({
                    ...params,
                    subscribed: null,
                    "search": this.state.search,
                    subscription_id: null,
                    allClient: true,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    "plan_type": "new"
                });
                this.props.getSubscriptionNameList({
                    "package_version": "4",
                    "app_version": "5.4",
                    "plan_type": "new"
                }, 'clients');
            }
            else {
                this.props.getAllClientList({
                    ...params,
                    subscribed: null,
                    "search": this.state.search,
                    subscription_id: null,
                    allClient: true,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    "plan_type": "old"
                });
                this.props.getSubscriptionNameList({
                    "package_version": "4",
                    "app_version": "5.4",
                    "plan_type": "old"
                }, 'clients');

            }

        }
    }

    // Filter by Subscription status
    selectSubscription(option) {
        this.setState({
            selectedOption: option
        })
        if (this.props.type == 'coaches') {
            if (this.state.filterbysubcriptionToggle == false || this.state.filterbysubcriptionToggle.value == false) {
                this.props.getPracticePartnerList({
                    practice_id: 276,
                    page: 0,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    package_version: "2",
                    subscribed: option,
                    payment_mode: this.state.selectedPayment,
                    subscription_id: this.state.subscriptionNameToggle,
                    "search_text": this.state.search,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    "plan_type": "old"
                });
            }
            else {
                this.props.getPracticePartnerNewPlanList({
                    practice_id: 276,
                    page: 0,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    package_version: "2",
                    subscribed: option,
                    payment_mode: this.state.selectedPayment,
                    subscription_id: this.state.subscriptionNameToggle,
                    "search_text": this.state.search,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    "plan_type": "new"
                });
            }

        } else if (this.props.type == 'clients') {
            let params = {
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "package_version": "4",
                asc: true,
                page: 0,
                per_page: 15,
                "plan_type": this.state.filterbysubcriptionToggle ? "new" : "old"
            }
            if (this.state.selectedTrainer) {
                params.practice_id = this.state.selectedTrainer
            }
            this.props.getAllClientList({
                ...params,
                subscribed: option,
                "search": this.state.search,
                subscription_id: this.state.subscriptionNameToggle,
                allClient: true,
                start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
            });
        }
    }

    // Payment Mode Filter
    selectPayment(option) {
        this.setState({
            selectedPayment: option
        })
        if (this.state.filterbysubcriptionToggle == false || this.state.filterbysubcriptionToggle.value == false) {
            this.props.getPracticePartnerList({
                practice_id: 276,
                page: 0,
                app_version: "5.2",
                current_role_type: "practice_admin",
                package_version: "2",
                subscribed: this.state.selectedOption,
                payment_mode: option,
                subscription_id: this.state.subscriptionNameToggle,
                "search_text": this.state.search,
                start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                "plan_type": "old"
            });
        }
        else {
            this.props.getPracticePartnerNewPlanList({
                practice_id: 276,
                page: 0,
                app_version: "5.2",
                current_role_type: "practice_admin",
                package_version: "2",
                subscribed: this.state.selectedOption,
                payment_mode: option,
                subscription_id: this.state.subscriptionNameToggle,
                "search_text": this.state.search,
                start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                "plan_type": "new"
            });
        }
    }

    // Filter by Subscription Name
    selectSubscriptionName(option) {
        this.setState({
            subscriptionNameToggle: option
        })
        if (this.props.type == 'coaches') {
            if (this.state.filterbysubcriptionToggle == false || this.state.filterbysubcriptionToggle.value == false) {
                this.props.getPracticePartnerList({
                    practice_id: 276,
                    page: 0,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    package_version: "2",
                    subscribed: this.state.selectedOption,
                    payment_mode: this.state.selectedPayment,
                    subscription_id: option,
                    "search_text": this.state.search,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    "plan_type": "old"
                });
            }
            else {
                this.props.getPracticePartnerNewPlanList({
                    practice_id: 276,
                    page: 0,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    package_version: "2",
                    subscribed: this.state.selectedOption,
                    payment_mode: this.state.selectedPayment,
                    subscription_id: option,
                    "search_text": this.state.search,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    "plan_type": "new"
                });
            }


        } else if (this.props.type == 'clients') {
            let params = {
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "package_version": "4",
                asc: true,
                page: 0,
                per_page: 15,
            }
            if (this.state.selectedTrainer) {
                params.practice_id = this.state.selectedTrainer
            }
            this.props.getAllClientList({
                ...params,
                subscribed: this.state.selectedOption,
                subscription_id: option,
                "search": this.state.search,
                allClient: true,
                start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                "plan_type": this.state.filterbysubcriptionToggle ? "new" : "old"
            });
        }
    }

    // Filter by name and email
    onSearch(text) {
        if (text.target.value == null) {
            this.loadMore();
        }
        this.setState({ search: text.target.value }, () => {
            if (this.props.type == 'admins') {
                this.props.getAdminsList({
                    "app_version": "5.2",
                    "package_version": "2",
                    "search": this.state.search,
                    "page": 0,
                    "practice_id": 276,
                    "current_role_type": "practice_admin"
                })
            } else if (this.props.type == 'coaches') {
                if (this.state.filterbysubcriptionToggle == false || this.state.filterbysubcriptionToggle.value == false) {
                    this.props.getPracticePartnerList({
                        practice_id: 276,
                        page: 0,
                        app_version: "5.2",
                        current_role_type: "practice_admin",
                        package_version: "2",
                        "search_text": this.state.search,
                        subscribed: this.state.selectedOption,
                        payment_mode: this.state.selectedPayment,
                        subscription_id: this.state.subscriptionNameToggle,
                        start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                        end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                        "plan_type": "old"
                    });
                }
                else {
                    this.props.getPracticePartnerNewPlanList({
                        practice_id: 276,
                        page: 0,
                        app_version: "5.2",
                        current_role_type: "practice_admin",
                        package_version: "2",
                        "search_text": this.state.search,
                        subscribed: this.state.selectedOption,
                        payment_mode: this.state.selectedPayment,
                        subscription_id: this.state.subscriptionNameToggle,
                        start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                        end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                        "plan_type": "new"
                    });
                }

            } else if (this.props.type == 'clients') {
                let params = {
                    "app_version": "5.2",
                    "current_role_type": "practice_admin",
                    "package_version": "4",
                    asc: true,
                    page: 0,
                    per_page: 15,
                }
                if (this.state.selectedTrainer) {
                    params.practice_id = this.state.selectedTrainer
                }
                this.props.getAllClientList({
                    ...params,
                    "search": this.state.search,
                    subscribed: this.state.selectedOption,
                    subscription_id: this.state.subscriptionNameToggle,
                    allClient: true,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    "plan_type": this.state.filterbysubcriptionToggle ? "new" : "old"
                });
            }
        });

    }

    // Genereate User Report
    generateReport() {
        if (this.props.type == 'coaches') {
            this.props.getPracticePartnerListForReport({
                practice_id: 276,
                app_version: "5.2",
                current_role_type: "practice_admin",
                package_version: "2",
                "search_text": this.state.search,
                subscribed: this.state.selectedOption,
                payment_mode: this.state.selectedPayment,
                subscription_id: this.state.subscriptionNameToggle,
                start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
            }).then((response) => {
                response.data.forEach((coach) => {
                    let temp = {
                        Coach_Name: coach.practice_name,
                        Coach_Email: coach.practice.owner.email,
                        Member_Since: moment(coach.practice.owner.created_date).format("MMM D, YYYY"),
                        No_of_Clients: coach.client_count,
                        Registration_Mode: coach.practice.owner.auth_method.name,
                        Payment_Mode: null,
                        Last_Login: coach.practice.owner.last_login ? moment(coach.practice.owner.last_login).format("MMM D, YYYY") : '--',
                        Template_Subscriptions: coach.user_subscription_active == null ? "Inactive" : coach.user_subscription_active ? "Active" : "Cancelled",
                        Subscription_Amount: coach.plan ? coach.plan.fee_amount !== 0 ? `${"$" + coach.plan.fee_amount}` : "--" : "--",
                        Subscription_Date: Object.keys(coach.stripe_subscription).length !== 0 ? coach.stripe_subscription.transaction_date ?
                            moment(coach.stripe_subscription.transaction_date).format("MMM D, YYYY")
                            : moment(coach.stripe_subscription.original_transaction_date_ios).format("MMM D, YYYY")
                            : "--"
                    }
                    this.state.rows.push(temp);
                })
            }).then(() => {
                this.setState({
                    showReport: true,
                })
            })
        } else if (this.props.type == 'clients') {
            let params = {
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "package_version": "3",
                asc: true,
                per_page: 15,
            }
            if (this.state.selectedTrainer) {
                params.practice_id = this.state.selectedTrainer
            }
            this.props.getAllClientListForReport({
                ...params,
                "search": this.state.search,
                subscribed: this.state.selectedOption,
                subscription_id: this.state.subscriptionNameToggle,
                allClient: true,
                start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
            }).then((response) => {
                response.clients.forEach((client) => {
                    let temp = {
                        Client_Name: client.name,
                        Client_Email: client.email,
                        Member_since: moment(client.created_date).format("MMM D, YYYY"),
                        Last_Login: client.last_login ? moment(client.last_login).format("MMM D, YYYY") : '--',
                        Trainer_Name: client.practice ? client.practice.owner.name : "--",
                        Homefit_Subscription: client.is_subscribed == null ? "Inactive" : client.is_subscribed ? "Active" : "Cancelled",
                        Subscription_Amount: client.dhf_move_user && Object.keys(client.dhf_move_user.stripe_subscription).length !== 0 ? client.dhf_move_user.plan ? "$" + client.dhf_move_user.plan.amount : "--" : "--",
                        Subscription_Date: client.dhf_move_user && Object.keys(client.dhf_move_user.stripe_subscription).length !== 0 ? client.dhf_move_user.stripe_subscription.transaction_date ? moment(client.dhf_move_user.stripe_subscription.transaction_date).format("MMM D, YYYY") : moment(client.dhf_move_user.stripe_subscription.original_transaction_date_ios).format("MMM D, YYYY") : "--",
                        Subscription_Trainer: client.is_subscribed == null ? "--" : client.is_subscribed ? client.subscription_referrer ? client.subscription_referrer.owner.name : "--" : "--",
                    }
                    this.state.rows.push(temp);
                })
            }).then(() => {
                this.setState({
                    showReport: true,
                })
            })
        }
    }

    hideReport() {
        this.setState({
            showReport: false,
            rows: []
        })
    }
    loadMore() {
        let params = {
            "app_version": "5.2",
            "current_role_type": "practice_admin",
            page: 0,
        }
        if (this.props.type == 'admins') {
            this.props.getAdminsList({
                ...params,
                "practice_id": 276,
                "package_version": "2",
                "search": "",
            })
        } else if (this.props.type == 'coaches') {
            this.props.getPracticePartnerList({
                ...params,
                "practice_id": 276,
                package_version: "2",
                "plan_type": "old"
            });
            this.props.getSubscriptionNameList({
                "package_version": "2",
                "app_version": "5.4",
                "plan_type": "new"
            }, 'coaches');
        } else if (this.props.type == 'clients') {
            this.props.getAllClientList({
                ...params,
                "package_version": "4",
                asc: true,
                per_page: 15,
                allClient: true,
                "plan_type": "new"
            });
            this.props.getSubscriptionNameList({
                "package_version": "4",
                "app_version": "5.4",
                "plan_type": "new"
            }, 'clients');
            this.props.getAuthorList({
                "app_version": "5.2",
                "practice_id": 276,
                "current_role_type": "practice_admin",
                package_version: "2",
                "plan_type": "new"
            })
        }
    }

    onPageChange(page) {
        if (this.props.type == 'coaches') {
            if (this.state.filterbysubcriptionToggle == false || this.state.filterbysubcriptionToggle.value == false) {
                this.props.getPracticePartnerList({
                    practice_id: 276,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    "search_text": this.state.search,
                    package_version: "2",
                    page: page,
                    sort_by: this.props.coaches_paginate_info.sort_by,
                    asc: this.props.coaches_paginate_info.asc,
                    subscribed: this.state.selectedOption,
                    payment_mode: this.state.selectedPayment,
                    subscription_id: this.state.subscriptionNameToggle,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    filters: { ...this.props.coaches_paginate_info.filters },
                    "plan_type": "old"
                    // search_text: this.state.search_text
                })
            }
            else {
                this.props.getPracticePartnerNewPlanList({
                    practice_id: 276,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    "search_text": this.state.search,
                    package_version: "2",
                    page: page,
                    sort_by: this.props.coaches_paginate_info.sort_by,
                    asc: this.props.coaches_paginate_info.asc,
                    subscribed: this.state.selectedOption,
                    payment_mode: this.state.selectedPayment,
                    subscription_id: this.state.subscriptionNameToggle,
                    start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                    end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                    filters: { ...this.props.coaches_paginate_info.filters },
                    // search_text: this.state.search_text
                    "plan_type": "new"
                })
            }

        } else if (this.props.type == 'clients') {
            let params = {
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "package_version": "4",
            }
            if (this.state.selectedTrainer) {
                params.practice_id = this.state.selectedTrainer
            }
            this.props.getAllClientList({
                ...params,
                allClient: true,
                "search": this.state.search,
                asc: true,
                page: page,
                per_page: this.props.clients_paginate_info.per_page,
                sort_by: "",
                total: this.props.clients_paginate_info.total,
                total_pages: this.props.clients_paginate_info.total_pages,
                subscribed: this.state.selectedOption,
                subscription_id: this.state.subscriptionNameToggle,
                start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
                end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
                "plan_type": this.state.filterbysubcriptionToggle ? "new" : "old"
            })
        }
    }

    onSort(sortData) {
        let asc = false;
        if (sortData.direction === 1) {
            asc = true;
        }
        this.props.getPracticePartnerNewPlanList({
            page: 0,
            sort_by: sortData.column,
            asc: asc,
            filters: { ...this.props.paginate_info.filters },
            search_text: this.state.search_text
        })
    }
    // componentWillMount() {
    //     console.log("unmount");
    //     // this.props.getGoalList({
    //     //     fetch_type: "manage"
    //     // });
    //     this.loadMore()
    //     // this.props.getPractitionerList({'category': 'active'});
    // }

    componentDidMount() {
        console.log(this.state.filterbysubcriptionToggle, this.props, 'this.state.filterbysubcriptionToggle');
        let params = {
            "app_version": "5.2",
            "current_role_type": "practice_admin",
            page: 0,
        }
        if (this.props.type == 'admins') {
            this.props.getAdminsList({
                ...params,
                "practice_id": 276,
                "package_version": "2",
                "search": "",
            })
        }
        if (this.props.tabchange && (this.props.type === "coaches" || this.props.type === "clients")) {
            this.loadMore()
        }
        else {
            if (this.props?._reduxForm?.location?.type === "coaches") {
                this.setState({
                    selectedOption: this.props?._reduxForm?.location?.Subscription,
                    filterbysubcriptionToggle: this.props._reduxForm?.location?.subscriptionList ? { label: 'Subscribed users', value: true } : { label: 'Registered users', value: false }
                })
                if (this.props._reduxForm?.location?.subscriptionList) {
                    this.props.getPracticePartnerNewPlanList({
                        ...params,
                        "practice_id": 276,
                        package_version: "2",
                        "plan_type": "new"
                    });
                }
                else {
                    this.props.getPracticePartnerList({
                        ...params,
                        "practice_id": 276,
                        package_version: "2",
                        "plan_type": "old"
                    });
                }
            }
            else if (this.props?._reduxForm?.location?.type === "clients") {
                this.setState({
                    selectedOption: this.props?._reduxForm?.location?.Subscription,
                    filterbysubcriptionToggle: this.props._reduxForm?.location?.subscriptionList ? { label: 'New Subscription Data', value: true } : { label: 'Old Subscription Data', value: false }
                })
                if (this.props._reduxForm?.location?.subscriptionList) {
                    this.props.getAllClientList({
                        ...params,
                        "package_version": "4",
                        asc: true,
                        per_page: 15,
                        allClient: true,
                        "plan_type": "new"
                    });
                }
                else {
                    this.props.getAllClientList({
                        ...params,
                        "package_version": "4",
                        asc: true,
                        per_page: 15,
                        allClient: true,
                        "plan_type": "old"
                    });
                }
            }
        }

        // this.props.updateHelpVideo(ENGAGEMENT_GOAL_VIDEO);
    }

    componentWillUnmount() {
        this.props.clearList();
    }

    addUser() {
        history.push('/practice/' + this.props.selectedRole.practice.id + '/users/add');
    }

    editUser(user) {
        history.push('/practice/' + this.props.selectedRole.practice.id + '/user/edit/' + user.id);
    }

    changeVisibilityStatus(goal, visibility) {
        this.props.changePartnerGoalVisibilityStatus({
            goal_id: goal.id,
            visibility: visibility
        }).then((response) => {
            this.props.getGoalList({
                fetch_type: "manage"
            });
        }).catch((error) => {
            //do nothing
        });;
    }

    fetchData = async () => {
        const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();

        let myObj = {
            "app_version": "5.2",
            "current_role_type": "practice_admin",
            "page": 0,
            "package_version": this.props.type === "clients" ? "4" : "2",
            "action": "export",
            "subscribed": this.state.selectedOption,
            "subscription_id": this.state.subscriptionNameToggle,
            "start_date": this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
            "end_date": this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
            "plan_type": this.state.filterbysubcriptionToggle ? "new" : "old"

        }
        if (this.props.type === "clients") {
            myObj["search"] = this.state.search
        }
        else {
            myObj["search_text"] = this.state.search
            myObj["payment_mode"] = this.state.selectedPayment
        }
        let url = "";
        if (this.props.type === "clients") {
            url = baseURL + "/" + API_VERSION + "/" + 'engagement' + "/all_client_list";
        }
        else {
            if (this.state.filterbysubcriptionToggle == false || this.state.filterbysubcriptionToggle.value == false) {
                url = baseURL + "/" + API_VERSION + "/" + 'partner' + "/practice_partner_list";
            }
            else {
                url = baseURL + "/" + API_VERSION + "/" + 'partner' + "/practice_partner_new_plan_list";
            }
        }
        const response = await Axios.post(url, myObj)
        return response // Assuming the API returns an array of data
    }

    convertToCSV = (jsonData) => {
        const csv = jsonData
        return csv
    }

    ExportData = async () => {
        try {
            const jsonData = await this.fetchData()
            const csv = this.convertToCSV(jsonData)

            setTimeout(() => {
                const blob = new Blob([csv], {
                    type: "text/csv;charset=utf-8;",
                })
                const url = URL.createObjectURL(blob)
                const link = document.createElement("a")
                link.setAttribute("href", url)
                link.setAttribute("download", `user-list.csv`)
                link.style.display = "none"
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }, 2000)

        } catch (error) {
            console.error("Error fetching data", error)
        }
    }

    render() {
        filterbySubscriptionOptions = this.props.type === "coaches" ? filterbyCoachOptions : filterbyClientsOptions;
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    console.log(state,'state');
    // let paginate_info = this.state.paginate_info
    // if(this.props.type=='coaches'){
    //     paginate_info = 
    // } else if(this.props.type=='clients') {
    //     paginate_info = state.engagement.clientListReducer.paginate_info
    // } 
    return {
        subscriptionOptions,
        filterbySubscriptionOptions,
        PaymentOptions,
        trainerSelected: selector(state, 'trainer'),
        startDate: selector(state, 'start_date'),
        endDate: selector(state, 'end_date'),
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        coachList: state.move.coachReducer.coach_list,
        adminList: state.users.usersReducer.admin_list,
        clientList: state.engagement.clientListReducer.client_list,
        goals: state.goal.goalReducer.goal_list,
        hidden_goals: state.goal.goalReducer.hidden_goals,
        practice_partners: state.partner.partnerReducer.practice_partners,
        coaches_paginate_info: state.partner.partnerReducer.practice_partners_paginate_info,
        clients_paginate_info: state.engagement.clientListReducer.paginate_info,
        authors: state.partner.partnerReducer.authors,
        subscriptionNameList: state.subscriptionLog.subscriptionLogReducer.subscription_list
        // practice_parnters_newplan_list: 
        // paginate_info: paginate_info,
    };
}
const selector = formValueSelector('UsersFilterForm');
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGoalList,
        getAdminsList,
        getCoachList,
        getPractitionerList,
        getAllClientList,
        getPracticePartnerList,
        getAuthorList,
        changePartnerGoalVisibilityStatus,
        getAllClientListForReport,
        getPracticePartnerListForReport,
        getSubscriptionNameList,
        getUserSubscriptionProfile,
        // updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearList: HelpVideoCreator.clientListCleanup,
        getPracticePartnerNewPlanList,
    },
        dispatch
    );
}
const UsersFilterForm = reduxForm(
    {
        form: 'UsersFilterForm',
        enableReinitialize: true,
    }
)(UserList);
const UserListConnected = connect(mapStateToProps, mapDispatchToProps)(UsersFilterForm);
export default UserListConnected;