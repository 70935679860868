import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/treatment_template_edit_new";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';
import { getTreatmentTemplate, saveTreatmentTemplate, createHabitFromHabitTemplate, publishTreatmentTemplate, } from '../../redux/treatment_template/network/treatment_template_network';
import TreatmentTemplateActionCreator from '../../redux/treatment_template/actions/treatment_template_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';
import { listWorkouts, listGuidanceDocuments,
    listNutritionDocuments, postHealthDocument,
   deleteHealthDocument,workoutSort,workoutDelete } from '../../redux/treatment_template/network/treatment_template_program';    
import { param } from 'jquery';


class TreatmentTemplateEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteLogo = this.deleteLogo.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);

        this.editHabit = this.editHabit.bind(this);
        // this.createHabitFromHabitTemplate = this.createHabitFromHabitTemplate.bind(this);
        this.publishTreatmentTemplate = this.publishTreatmentTemplate.bind(this);
        this.state = {
            publish: false,
            treatment_template_id: "",
            treatment_cycle:[
                {value:1,label:'Scheduled'},
                {value:2,label:'Ongoing'},
            ],
            openHabitTemplateModal:false,            
        };
    };
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        this.props.getTreatmentTemplate({ 
            id: this.props.match.params.treatment_template_id,
            package_version: 2,
         });
        // this.props.listGuidanceDocuments({ treatment_template_id: this.props.match.params.treatment_template_id });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    loadDetails(id) {
        this.props.listWorkouts({ treatment_template_id: id });
        this.props.getTreatmentTemplate({ 
            id: id,
            package_version: 2,
         });
        this.props.listGuidanceDocuments({ treatment_template_id: id });
        this.props.listNutritionDocuments({ treatment_template_id: id });
        this.props.getGoalList({ fetch_type: "practitioner_assign" });
    }

    save(publish) {
        this.setState({
            publish: publish
        }, () => {
            this.props.handleSubmit(this.onSubmit)();
        });
    }

    onSubmit(data) {
        let params = _.omit(data, ['can_be_deleted']);
        params = Utils.flatternFormData(params);
        params.package_version = 2;
        if(!_.has(data,"id")){
            if(this.props.match.params.treatment_type === "group"){
                params.treatment_template_type = 2;
            }else if(this.props.match.params.treatment_type === "gameplan"){
                params.treatment_template_type = 1;
            }
        }
        this.props.saveTreatmentTemplate(params).then((response) => {
            this.setState({
                treatment_template_id: response.treatment_template.id
            })
            if(this.state.publish){
                this.publishTreatmentTemplate(true)
            } else if(!this.state.publish){
                this.publishTreatmentTemplate(false)
            }
            this.props.getTreatmentTemplate({ 
                id: response.treatment_template.id,
                package_version: 2,
             })
            this.loadDetails(response.treatment_template.id);
            // this.props.listWorkouts({ treatment_template_id: response.treatment_template.id });

            // history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type+'/dashboard/'+response.treatment_template.id);
        }).catch(() => {
        });
    }

    uploadLogo(files) {
        let formData = new FormData();
        formData.append('guid', this.props.guid);
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('logo_url', result.url);
            this.props.change('is_logo_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
        }).catch((error) => {
        });
    }

    deleteLogo() {
        this.props.change('is_logo_uploaded', false);
    }

    cancel() {
        if(confirm("Are you sure? All changes will be discarded.")){
            history.goBack();
        }
    }


    editHabit(id) {
        let that = this;
        if(id){
            this.setState({
                editingHabitId: id,
            }, () => this.allocateHabitSpace());
        }else{
            swal.fire({
                title: "Select an option",
                text: "How would you like to create Habit?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Create from template",
                // cancelButtonText: "Create new",
                useRejections:true
            }).then(function (value) {
                that.props.getHabitTemplateList({
                    show_published:true,
                    show_partner_templates:true
                });
                that.setState({
                    openHabitTemplateModal:true
                });
                if(value.dismiss == 'close'){
                    return
                }
            }, function (dismiss) {
                if (dismiss === "cancel") {
                    that.setState({
                        editingHabitId: null,
                    }, () => that.allocateHabitSpace());
                }
            });
        }
    }

    // createHabitFromHabitTemplate(habit_template) {
    //     this.props.createHabitFromHabitTemplate({
    //         treatment_template_id: this.props.treatment_template.id,
    //         habit_template_id: habit_template.id
    //     }).then((response) => {
    //         this.editHabit(response.habit_id);
    //         this.setState({ openHabitTemplateModal: false });
    //     }).catch((error) => {
    //         //do nothing;
    //     });
    // }

    publishTreatmentTemplate(published = false) {
        if(this.props.match.params.treatment_template_id){
            this.props.handleSubmit(this.onSubmit)
            this.props.publishTreatmentTemplate({
                treatment_template_id: this.props.match.params.treatment_template_id,
                published: published,
            }).then((response) => {
                history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type);
            }).catch(() => { });  
        } else {
            this.props.handleSubmit(this.onSubmit)
            this.props.publishTreatmentTemplate({
                treatment_template_id: this.state.treatment_template_id,
                published: published,
            }).then(() => {
                history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type);
            }).catch(() => { });      
        }
    }


    render() {
        if (_.isEmpty(this.props.treatment_template)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('treatmentTemplateEditForm');
function mapStateToProps(state) {
    let treatment_template = {...state.treatment_template.treatmentTemplateReducer.treatment_template};
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        treatment_template: treatment_template,
        supporting_data: state.treatment_template.treatmentTemplateReducer.treatment_template_supporting_data,
        initialValues: treatment_template,
        is_logo_uploaded: selector(state, 'is_logo_uploaded'),
        logo_url: selector(state, 'logo_url'),
        guid: selector(state, 'guid'),
        id_from_form: selector(state, 'id'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTreatmentTemplate,
        saveTreatmentTemplate,
        listWorkouts,
        cleanUp: TreatmentTemplateActionCreator.treatmentTemplateCleanup,
        uploadFiles,
        listGuidanceDocuments,
        listNutritionDocuments, 
        postHealthDocument,
        deleteHealthDocument,
        workoutSort,
        workoutDelete,
        createHabitFromHabitTemplate,
        publishTreatmentTemplate,
    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const TreatmentTemplateEditForm = reduxForm(
    {
        form: 'treatmentTemplateEditForm',
        enableReinitialize: true,
        validate
    }
)(TreatmentTemplateEdit);
const TreatmentTemplateEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(TreatmentTemplateEditForm);
export default TreatmentTemplateEditFormConnected;
