import React from 'react';
import  ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_engagement_goal_tracking.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field , FieldArray} from 'redux-form';
import { history } from '../../redux/store';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Button from '../../utils/common_components/tooltipButton';
import roleReducer from '../../redux/auth/reducers/role_reducer';
import clientEngagementReducer from '../../redux/engagement/reducers/client_engagement_reducer';
import goalTrackingCreator from '../../redux/engagement/actions/engagement_goal_tracking_creator';
import {getClientGoalTargetDetailsForm, postClientGoalTargetDetailsForm} from '../../redux/engagement/network/client_engagement_network';

class ClientEngagementGoalTracking extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveGoal = this.saveGoal.bind(this);
        this.renderGoalTrackingQuestions = this.renderGoalTrackingQuestions.bind(this);
        this.renderGoals = this.renderGoals.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.goalsFieldsArray = null;    
    };

    componentWillMount() {
        this.props.getClientGoalTargetDetailsForm({
                                                engagement:this.props.engagement.id,
                                                tracking_date:this.props.track_date,
                                            });   
    }    

    componentWillUnmount() {
        this.props.cleanUp([]);
    }

    doCancel() {
        this.props.handleClose();
    }



    saveGoal(data) {  
        let goals = data.goals;
        let goal_targets = _.cloneDeep(this.props.goal_targets);
        goal_targets = _.map(goal_targets,(goal_target)=>{
            _.forEach(goals,(goal)=>{
                _.forEach(goal.goal_tracking,(goal_tracking)=>{
                    if(goal_tracking.id === goal_target.client_engagement_goal_tracking){
                        goal_target.current_value =   goal_tracking.current_value;
                    }
                });
            });
            goal_target = _.pick(goal_target,["id","current_value"]);
            return goal_target;
        });
        this.props.postClientGoalTargetDetailsForm({
            engagement:this.props.engagement.id,
            tracking_details:goal_targets,
        }).then((response)=>{
             this.props.handleClose();
        }).catch((error)=>{
            //Do nothing
        });

    }

    renderGoals({ fields, meta }) {
        this.goalsFieldsArray = fields;
        let goals = fields.getAll();
        return (
            <div className="row"> 
                <div className="col-xs-12 m-t-15">                
                {
                    fields.map((item, index) => {
                            return (
                                <div key={index} className="panel m-b-10" >
                                    <div className="row panel-heading bg-beige panel-bottom-border p-l-10 p-r-10 p-t-15 p-b-0 m-0 ">    
                                         <h6 className="col-xs-12 text-left">{goals[index].text}</h6>
                                    </div>        
                                    <div className="panel-body m-t-0 ">
                                        <FieldArray name={`${item}.goal_tracking`} component={this.renderGoalTrackingQuestions}/>
                                    </div>        

                            </div>    
                        );
                    })
                }
                </div>
                <div className="col-xs-12 end-xs" >
                    <Button onClick={this.doCancel} label="Close" className="bg-accent m-r-5" 
                        type="button"  raised/>                    
                    <Button label="Save" className="bg-success" disabled={this.props.submitting} 
                        type="submit"  raised/>                    
                </div>   
            </div>    
        );
    }
    renderGoalTrackingQuestions({ fields, meta }) {
        let goalsTracking = fields.getAll();
        let noGoalTrackingText = null;
        if (goalsTracking.length === 0){
            noGoalTrackingText =  (
                    <div className="col-xs-12 start-xs m-t-10 m-b-10">        
                        <div className="bg-danger p-10 f-16">Goal Tracking Questions Are Mandatory!. Click on ADD button below to add one.</div>                 
                    </div>  
            );           
        }        
        return (
            <div className="row">
                {noGoalTrackingText}
                <div className="col-xs-12">                
                {
                    fields.map((item, index) => {
                            return (
                                <div key={index} className="row vertical-align text-left justify-left">  
                                    <div className="col-xs-8">
                                    <div className="round-number di-block m-r-10"> <span>{index+1}</span></div>        
                                       <label>{goalsTracking[index].track_question} </label>
                                    </div>     
                                    <div className="col-xs-4">    
                                            <Field
                                                className="di-block"
                                                hint="Current Value"  
                                                name={`${item}.current_value`}
                                                type="number"
                                                component={TextInput}
                                            />  
                                            /
                                             <label> {goalsTracking[index].target_value} {goalsTracking[index].track_unit.unit_name} </label>    

                                    </div>                                   
                            </div>    
                        );
                    })
                }
                </div>               
            </div>    
        );
    }
    
    render() {
        if (this.props.goal_targets.length === 0 || _.isEmpty(this.props.engagement)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

ClientEngagementGoalTracking.propTypes = {
    engagement:PropTypes.object.isRequired,    
    track_date:PropTypes.string.isRequired,    
};



function mapStateToProps(state, ownprops) {
    let goals = [];
    let engagement = ownprops.engagement;
    if (engagement && !_.isEmpty(engagement)){
        goals = [...engagement.goals];
    }
    let goal_targets =  state.engagement.clientEngagementReducer.goal_targets;
    let goal_track_dict = _.keyBy(goal_targets,"client_engagement_goal_tracking");
    goals = _.map(goals,(goal_val)=>{
        let goal = {...goal_val};
        goal.goal_tracking =  _.map(goal.goal_tracking,(track_item_val)=>{
            let track_item = {...track_item_val};
            if(_.has(goal_track_dict,track_item.id)){
                track_item.current_value = goal_track_dict[track_item.id].current_value;
            }else{
                 track_item.current_value = null;
            }
            return track_item;
        });
        goal.goal_tracking =  _.filter(goal.goal_tracking,(track_item_val)=>{
            if(_.has(goal_track_dict,track_item_val.id)){
                return true;
            }else{
                return false;
            }
        });
        return goal;
    }) ;





let initialValues = { goals: goals };
    return {
        engagement:engagement,
        goals:goals,
        track_date:ownprops.track_date,
        initialValues: initialValues,
        goal_targets: goal_targets,
        selectedRole: state.auth.roleReducer.selectedRole,
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientGoalTargetDetailsForm,
        postClientGoalTargetDetailsForm,
        cleanUp:goalTrackingCreator.engagementGoalTrackingFormFill //We are cleaning up the tracking unit list
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    const goalArrayErrors = [];
    if(values.goals){
        values.goals.forEach((goal, index) => {
            const goalErrors = {};
            const goalTrackingArrayErrors = [];
            goal.goal_tracking.forEach((goal_track, i) => {
                 const goalTrackErrors = {};
                if(goal_track.current_value === "" || goal_track.current_value === null){
                    goalTrackErrors.current_value = "Required";
                }

                 goalTrackingArrayErrors[i] = goalTrackErrors;
            }); 
            goalErrors.goal_tracking = goalTrackingArrayErrors;
            goalArrayErrors[index] = goalErrors;
        });  
    }
   
    errors.goals = goalArrayErrors;
    return errors;
};


const ClientEngagementGoalTrackingForm = reduxForm(
    {
        form: 'clientEngagementGoalTrackingForm',
        enableReinitialize: true,
        validate
    }
)(ClientEngagementGoalTracking);
const ClientEngagementGoalTrackingConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagementGoalTrackingForm);
export default ClientEngagementGoalTrackingConnected;
