import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_calendar_home";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Calendar as BigCalendar,momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getCalendarEventList } from '../../redux/calendar/network/calendar_list';

const localizer = momentLocalizer(moment)
class ClientCalendarHome extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.renderEvent = this.renderEvent.bind(this);
        this.view = this.view.bind(this);
        this.finishViewing = this.finishViewing.bind(this);
        this.state = {
            viewDialogShown: false,
            event: null,
        };
    }

    componentWillMount() {
        // BigCalendar.setLocalizer(
        //     BigCalendar.momentLocalizer(moment)
        // );
        this.props.getCalendarEventList();
    }

    view(event){
        this.setState({
            viewDialogShown: true,
            event: event,
        });
    }

    finishViewing() {
        this.setState({
            viewDialogShown: false,
            event: null,
        });
    }

    renderEvent(event) {
        if (event.event) {
            event = event.event;
        }
        return (
            <span>{event.title}</span>
        );
    }

    componentWillUnmount() {
    }

    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state) {
    let new_event_list = _.map([...state.calendar.calendarList.event_list], function (itemData) {
        let item = { ...itemData };
        if (item.calendar_type.name === "appointment") {
            item.event_type =  "calendar-individual-appointment";    
        } else if (item.calendar_type.name === "group_class") {
            item.event_type =  "calendar-group-class";    
        } else if (item.calendar_type.name === "facility_booking") {
            item.event_type =  "calendar-facility-booking";    
        } else {
            item.event_type =  "calendar-holiday";    
        }                
        item.title = item.client_display_name;
        item.start = moment(item.start_time).toDate();
        item.end = moment(item.end_time).toDate();
        item.allDay = item.all_day;
        return item;
    });
    return {
        localizer,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        eventList: new_event_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCalendarEventList,
    },
        dispatch
    );
}


const ClientCalendarHomeConnected = connect(mapStateToProps, mapDispatchToProps)(ClientCalendarHome);
export default ClientCalendarHomeConnected;