import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import TreatmentTemplateCreator from '../actions/treatment_template_creator';

const getTreatmentTemplateList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/treatment_template_list";
        return Axios.post(url, params).then((response) => {
            dispatch(TreatmentTemplateCreator.treatmentTemplateListFill(response.treatment_template_list,response.paginate_info));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const saveTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/treatment_template_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            if (params.treatment_template_type === 1) {
                Utils.showSuccessAlert("Draft Game plan Template has been saved");    
            } else {
                Utils.showSuccessAlert("Draft Group Template has been successfully saved");    
            }            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const createGroupFromTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/create_group_from_treatment_template";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Group has been successfully created");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const createEngagementFromTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/create_engagement_from_treatment_template";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Program has been successfully created from template");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const getTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/treatment_template_get";
        return Axios.post(url, params).then((response) => {
            dispatch(TreatmentTemplateCreator.treatmentTemplateGetFill(
                response.treatment_template,
                response.supporting_data
            ));
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const createHabitFromHabitTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/create_habit_from_habit_template";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const publishTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/treatment_template_toggle_publish";
        return Axios.post(url, params).then((response) => {
            dispatch(TreatmentTemplateCreator.treatmentTemplateGetFill(
                response.treatment_template,
            ));
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const toggleVisibilityTreatmentTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/treatment_template_toggle_visibility";
        return Axios.post(url, params).then((response) => {
            dispatch(TreatmentTemplateCreator.toggleTreatmentTemplateVisibility(
                response.treatment_template,
            ));
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};






export {
    getTreatmentTemplateList,
    saveTreatmentTemplate,
    getTreatmentTemplate,
    toggleVisibilityTreatmentTemplate,
    publishTreatmentTemplate,
    createGroupFromTreatmentTemplate,
    createEngagementFromTreatmentTemplate,
    createHabitFromHabitTemplate
};