import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practitioner_service_of_terms";

class PractitionerServiceOfTerms extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }
    
    componentWillMount() {
    }

    componentWillUnmount() {
    }
    
    render() {
        return Template.apply(this);
    }
}

export default PractitionerServiceOfTerms;
