import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';
import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import EducationActions from '../actions/education_actions';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getEducationCategoryList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/education_category_list";
        return Axios.post(url, params).then((response) => {
            dispatch(EducationActions.educationCategoryListFill(
                response['education_categories']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getEducationCategory = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/education_category_get";
        return Axios.post(url, params).then((response) => {
            dispatch(EducationActions.educationCategoryFill(
                response['education_category'],
                response["plans"]
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const saveEducationCategory = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/education_category_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteEducationCategory = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/education_category_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getEducationContentList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_education_content_list";
        return Axios.post(url, params).then((response) => {
            dispatch(EducationActions.educationContentListFill(
                response['education_content_list']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getEducationContent = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url ="/" + 6 + "/" + PACKAGE + "/education_content_get";
        return Axios.post(url, params).then((response) => {
            dispatch(EducationActions.educationContentFill(
                response['education_categories'],
                response['education_content'],
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const saveEducationContent = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/education_content_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteEducationContent = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/education_content_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPracticeEducationContentList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_education_content_list";
        return Axios.post(url, params).then((response) => {
            dispatch(EducationActions.practiceEducationContentListFill(
                response['partner_list'],
                response['education_categories'],
                response['education_content_list'],
                response["paginate_info"]
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPracticeEducationContentListDHF = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL + "/" + 6 + "/" + PACKAGE + "/practice_education_content_list";
        return Axios.post(url, params).then((response) => {
            dispatch(EducationActions.practiceEducationContentListFill(
                response['partner_list'],
                response['education_categories'],
                response['education_content_list'],
                response["paginate_info"]
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



export {
    getPracticeEducationContentListDHF,
    getEducationCategoryList,
    getEducationCategory,
    saveEducationCategory,
    deleteEducationCategory,
    getEducationContentList,
    getEducationContent,
    saveEducationContent,
    deleteEducationContent,
    getPracticeEducationContentList,
};