import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
export default function () {
    return this.props.match.params.activation_user_type === 'i' || this.props.match.params.activation_user_type === 'r' ? React.createElement('div', {
        'className': 'row center-xs middle-xs  full-height c-white w-100',
        'key': '711'
    }, React.createElement('form', {
        'className': 'col-xs-11 col-sm-10 col-md-6 big-labels white-labels',
        'onSubmit': this.props.handleSubmit(this.activateUserAccount)
    }, this.props.match.params.activation_user_type !== 'r' ? React.createElement('h1', {
        'className': 'c-white',
        'key': '1045'
    }, '\n            Activate My Account\n        ') : null, this.props.match.params.activation_user_type === 'r' ? React.createElement('h1', {
        'className': 'c-white',
        'key': '1213'
    }, '\n           Reset Password\n        ') : null, this.props.match.params.activation_user_type === 'i' ? React.createElement('div', { 'key': '1369' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'first_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'First Name'
    })) : null, this.props.match.params.activation_user_type === 'i' ? React.createElement('div', { 'key': '1603' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'last_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'Last Name'
    })) : null, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'password',
        'type': 'password',
        'component': TextInput,
        'hint': 'Password'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'confirm_password',
        'type': 'password',
        'component': TextInput,
        'hint': 'Confirm Password'
    })), this.props.match.params.activation_user_type === 'i' ? React.createElement('div', {
        'className': 'row-margin',
        'key': '2201'
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20 p-l-0 c-white',
        'name': 'timezone',
        'component': Select,
        'hint': 'Time Zone',
        'options': this.props.timezones,
        'simpleValue': true
    })) : null, React.createElement('div', { 'className': 'row w-100 center-xs m-b-25' }, this.props.match.params.activation_user_type !== 'r' ? React.createElement(Button, {
        'label': 'Activate',
        'disabled': this.props.submitting,
        'className': 'bg-success big-button',
        'type': 'submit',
        'raised': true,
        'style': { width: '180px' },
        'key': '2666'
    }) : null, this.props.match.params.activation_user_type === 'r' ? React.createElement(Button, {
        'label': 'Reset',
        'disabled': this.props.submitting,
        'className': 'bg-success big-button',
        'type': 'submit',
        'raised': true,
        'style': { width: '180px' },
        'key': '2941'
    }) : null))) : null;
}