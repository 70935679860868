import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';
import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';

const generateDraftWorkout = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/generate_draft_workout";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Draft Workout generated successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const accessSpecialities = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/access_specialities";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());            
            throw error;
        });
    };
};


export {
    generateDraftWorkout,
    accessSpecialities,
};