// Action Creators
const groupClassSchedulerListFill = (group_class_list) => ({
    type: 'GROUP_CLASS_SCHEDULER_LIST',
    group_class_list,
});

const groupClassSchedulerObjectFill = (group_class, practitioners) => ({
    type: 'GROUP_CLASS_SCHEDULER_OBJECT',
    group_class,
    practitioners
});

const groupClassSchedulerBookingListFill = (group_class, booked_client_list) => ({
    type: 'GROUP_CLASS_SCHEDULER_BOOKING_LIST',
    group_class,
    booked_client_list
});

const groupClassSchedulerBookingObjectFill = (group_class, booked_client, client_list, schedule_date_list) => ({
    type: 'GROUP_CLASS_SCHEDULER_BOOKING_OBJECT',
    group_class,
    booked_client,
    client_list,
    schedule_date_list
});

const groupClassSchedulerBookingUserListForDateFill = (group_class, booked_client_list, schedule_date) => ({
    type: 'GROUP_CLASS_SCHEDULER_BOOKING_USER_LIST_FOR_DATE',
    group_class,
    booked_client_list,
    schedule_date,
});

const allGroupClassEventListFill = (all_group_class_calendar) => ({
    type: 'ALL_GROUP_CLASS_EVENT_LIST',
    all_group_class_calendar,
});

const cleanModule = () => ({
    type: 'GROUP_CLASS_SCHEDULER_CLEANUP',
});

export default {
    groupClassSchedulerListFill,
    groupClassSchedulerObjectFill,
    groupClassSchedulerBookingListFill,
    groupClassSchedulerBookingObjectFill,
    groupClassSchedulerBookingUserListForDateFill,
    allGroupClassEventListFill,
    cleanModule,
};
