import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
export default function () {
    function repeatCanned_message1(canned_message, canned_message_index) {
        return React.createElement(Tr, { 'key': 'row' + canned_message_index }, React.createElement(Td, { 'column': 'message_type' }, React.createElement('span', {}, canned_message.__message_type)), React.createElement(Td, {
            'column': 'custom',
            'style': {
                width: '250px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, canned_message.is_custom ? React.createElement('span', { 'key': '2457' }, '\n                            ', this.props.selectedRole.practice.name, '\n                        ') : null, !canned_message.is_custom ? React.createElement('span', { 'key': '2623' }, '\n                            GoMotive\n                        ') : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'onClick': () => {
                this.editCannedMessage(canned_message.message_type);
            },
            'className': 'edit-icon'
        }))));
    }
    return React.createElement('div', { 'className': 'm-r-20' }, React.createElement('div', { 'className': 'm-t-15 page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Templates'), React.createElement('h2', { 'className': 'page-title with-subtitle' }, 'Emails'))), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-24',
            'id': 'table',
            'noDataText': 'There are no email templates to be customized!',
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'message_type' }, React.createElement('span', { 'className': 'table-heading' }, 'Message Type')), React.createElement(Th, {
            'column': 'custom',
            'style': {
                width: '250px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Author')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.canned_message_list, repeatCanned_message1.bind(this))
    ]));
}