import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/mat_configure.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import matAction from '../../redux/mat/actions/mat_creator';
import {getMatForm,postMatForm} from '../../redux/mat/network/mat_network';
import swal from 'sweetalert2';
import PropTypes from 'prop-types';

class MatConfigure extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.selectAssessment = this.selectAssessment.bind(this);
        this.onClickAssessmentCheckbox = this.onClickAssessmentCheckbox.bind(this);
        this.changeFitnessLevel = this.changeFitnessLevel.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
        this.createWorkout = this.createWorkout.bind(this);
        this.saveAssessment = this.saveAssessment.bind(this);
        this.state = { 
            selected_assessment: null,
            current_page: 0,
            client_treatment_levels:[
                {
                    value:"BEGINNER",
                    label:"Beginner"
                },
                {
                    value:"INTERMEDIATE",
                    label:"Intermediate"
                },
                {
                    value:"ADVANCED",
                    label:"Advanced"
                }
            ],
            Strength: "BEGINNER",
            Power: "BEGINNER",
            Endurance: "BEGINNER",
            assessment_types:[
                {
                    value:false,
                    label:"Strength"
                },
                {
                    value:false,
                    label:"Power"
                },
                {
                    value:false,
                    label:"Endurance"
                }                               
            ]
        };
    }
    

    componentWillMount() {
        if(!this.props.previewMode){
            this.props.getMatForm();
        }
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }
    

    selectAssessment(assessment){
        this.setState({selected_assessment:assessment});
        window.scrollTo(0, 0);
    }  
    
    onClickAssessmentCheckbox(field,value){
        let assessment_types = _.map(this.state.assessment_types,(item)=>{
            if (item.label === field) {
                item.value = value;
            }
            return item;
        });
        this.setState({assessment_types:assessment_types});
    }

    changeFitnessLevel(field,value){
        if(field === "Strength"){
            this.setState({Strength:value});
        }else if(field === "Power"){
            this.setState({Power:value});
        }else if(field === "Endurance"){
            this.setState({Endurance:value});
        }
    }

    gotoPage(page){
        if(this.state.current_page === page){
            return;
        }else{
            if(this.state.current_page === 0 && page === 1){
                let completed_assessments = _.filter(this.props.mat_form,{assessed:true});
                if(completed_assessments.length === 0){
                    Utils.showErrorAlert("Please fill at least one assessment before proceeding!");
                    return;
                }else{
                    this.setState({current_page:page});
                }
            }else if(this.state.current_page === 1 && page === 0){
                this.setState({current_page:page});
            }
        }
    }

    createWorkout(){
        let selected_assessment_types = _.filter(this.state.assessment_types,{value:true});
        let workout_types = {};
        if(selected_assessment_types.length === 0){
            Utils.showErrorAlert("Please select atleast one workout type!");
            return;
        }    
        for(let selected_assessment_type of selected_assessment_types){
            workout_types[selected_assessment_type['label']] = this.state[selected_assessment_type['label']];
        }
        let params = {};
        params["workout_types"] = workout_types;
        params["assessments"] = this.props.mat_form;
        if( _.has(this.props,"match")){
            params["client"] =  this.props.match.params.client_id;
        }
        params["create_program"] = true;
        this.props.postMatForm(params).then((response)=>{
            Utils.showSuccessAlert(response.message);
            history.push("/practice/"+this.props.selectedRole.practice.id+"/client/engagement/" + response.engagement_id + "/?tab=3");
        }).catch((error)=>{

        });

    }

    saveAssessment() {
        let params = {};
        params["assessments"] = this.props.mat_form;
        if( _.has(this.props,"match")){
            params["client"] =  this.props.match.params.client_id;
        }
        params["create_program"] = false;
        params["workout_types"] = {};
        this.props.postMatForm(params).then((response)=>{
            Utils.showSuccessAlert(response.message);
            history.push("/practice/"+this.props.selectedRole.practice.id+"/client/engagement/" + response.engagement_id + "/?tab=3");
        }).catch((error)=>{

        });

    }

    render() {
        if (_.isEmpty(this.props.mat_form)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}
function mapStateToProps(state,ownProps) {
    let mat_form = [];
    if(ownProps.previewMode){
        mat_form = ownProps.mat_form;
    }else{
        mat_form = state.mat.matReducer.mat_form;
    }

    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        mat_form: mat_form,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getMatForm,
        postMatForm,
        cleanUp: matAction.matCleanUp,
        // matTypeFill: matAction.matTypeFill
    }, dispatch);
}

MatConfigure.propTypes = {
    previewMode: PropTypes.bool,
};
MatConfigure.defaultProps = {
    previewMode: false,
};

const MatConfigureConnected = connect(mapStateToProps,mapDispatchToProps)(MatConfigure);
export default MatConfigureConnected;
