import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/practice_partner_edit_plans.rt";
import { reduxForm } from 'redux-form';

import { updatePracticePartnerPlans } from '../../redux/partner/network/partner_network';

class PracticePartnerEditPlans extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);        
    };
    
    componentWillMount() {
    }

    componentWillUnmount() {
    }

    doSave(data) {     
        let params = { ...data };
        params['practice_partner_id'] = this.props.practice_partner.id;
        this.props.updatePracticePartnerPlans(params).then((response)=>{
            this.props.handleCloseAndRefresh();
        }).catch((error)=>{
        });

    }

    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state, ownProps) {
    let initialValues = {};
    if (ownProps.practice_partner) {
        initialValues['available_plans'] = ownProps.practice_partner.available_plans;
    }
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: initialValues,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        updatePracticePartnerPlans
    }, dispatch),
    dispatch
});

const validate = (values, props) => {
    let requiredFields = [
        'available_plans',
    ];
    const errors = {};
    // requiredFields.forEach(field => {
    //     if (!values[field]) {
    //         errors[field] = 'Required';
    //     }
    //     if(values.available_plans  && values.available_plans.length === 0){
    //         errors.available_plans = 'Please select at least one plan!';
    //     }
    // });
    return errors;
};


const PracticePartnerEditPlansForm = reduxForm(
    {
        form: 'PracticePartnerEditPlansForm',
        enableReinitialize: true,
        validate
    }
)(PracticePartnerEditPlans);


const PracticePartnerEditPlansFormConnected = connect(mapStateToProps,mapDispatchToProps,)(PracticePartnerEditPlansForm);
export default PracticePartnerEditPlansFormConnected;
