import  React  from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/engagement_document_list.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import HealthDocumentAction from '../../redux/documents/actions/health_document';
import { getEngagementHealthDocumentList } from '../../redux/engagement/network/engagement_network';



class EngagementHealthDocumentList extends BaseComponent {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        this.loadData(this.props);
    }

    loadData(props) {
        if (props.params.document_type && props.params.document_type === 'nutrition') {
            props.getEngagementHealthDocumentList({
                engagement_id: this.props.engagement.id,
                document_type:1,
            });
        } else if ( props.params.document_type && props.params.document_type === 'guidance'){
            props.getEngagementHealthDocumentList({
                engagement_id: this.props.engagement.id,
                document_type:2,
            });
        } else {
            history.goBack();
        }        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.document_type !== nextProps.params.document_type){
            nextProps.cleanUp();
            this.loadData(nextProps);
        }
    }
        

    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}

EngagementHealthDocumentList.propTypes = {
    selectionMode: PropTypes.bool,
    onSelected: PropTypes.func,
};
EngagementHealthDocumentList.defaultProps = {
    selectionMode: false,
    onSelected: () => { },
};

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        health_documents: state.health_document.documentReducer.health_documents
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getEngagementHealthDocumentList,
        cleanUp: HealthDocumentAction.cleanModule,
    }, dispatch),
    dispatch
});

const EngagementHealthDocumentListConnected = connect(mapStateToProps,mapDispatchToProps,)(EngagementHealthDocumentList);
export default EngagementHealthDocumentListConnected;
