import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/program_preview";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../redux/program/actions/program_preview';

class ProgramPreview extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.togglePublish = this.togglePublish.bind(this);
        this.state = {
            cleanProgram: false,
        };
    }

    componentWillMount() {
        this.props.getProgramPreview({ id: this.props.program_id });
    }
    
    togglePublish() {        
        this.props.toggleProgramPublish({
            id: this.props.program.id,
            'is_publish': !this.props.program.published,
        }).then(() => {
            // this.props.getProgramPreview({ id: this.props.program_id });
            this.props.finishEditingMoveBack();
        }).catch(() => { });
    }

    render() {
        if (_.isEmpty(this.props.program)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanProgramPreview();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        cleanProgramPreview: actions.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        program: state.program.programPreview.program,
    };
}

const ProgramPreviewConnected = connect(mapStateToProps, mapDispatchToProps)(ProgramPreview);
export default ProgramPreviewConnected;
