import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { PARTNER } from '../../../core/constants/constants';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs middle-xs' }, React.createElement(Card, { 'className': 'm-20 col-xs-8 p-0' }, React.createElement(CardTitle, {
        'title': 'Please select an option',
        'className': 'm-b-10'
    }), React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement('div', { 'className': 'row center-xs' }, PARTNER.allow_partner_signup === true ? React.createElement(Button, {
        'className': 'card-full-height col-xs-5 bg-accent-dark',
        'style': { whiteSpace: 'normal' },
        'onClick': () => {
            this.gotoPracticeCreate();
        },
        'raised': true,
        'key': '915'
    }, React.createElement('div', {}, React.createElement('i', { 'className': 'material-icons f-50' }, 'fitness_center')), React.createElement('span', {
        'style': {
            wordWrap: 'break-word',
            lineHeight: '20px'
        }
    }, React.createElement('p', {
        'className': 'f-20',
        'style': { paddingBottom: '25px' }
    }, 'I AM A HEALTH OR FITNESS PROFESSIONAL.'), '\n                    I would like to use this app to serve my clients and help people.\n                ')) : null, PARTNER.allow_client_signup === true ? React.createElement(Button, {
        'className': 'card-full-height col-xs-5 m-l-10 bg-beige',
        'style': { whiteSpace: 'normal' },
        'onClick': () => {
            this.gotoClient();
        },
        'raised': true,
        'key': '1562'
    }, React.createElement('div', {}, React.createElement('i', { 'className': 'material-icons f-50' }, 'face')), React.createElement('span', {
        'style': {
            wordWrap: 'break-word',
            lineHeight: '20px'
        }
    }, React.createElement('p', {
        'className': 'f-20',
        'style': { paddingBottom: '25px' }
    }, 'I AM A CLIENT OR PATIENT.'), ' \n                    I am looking for a health or fitness professional.\n                ')) : null))));
}