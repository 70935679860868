import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../../utils/common_components/textInput_form';
import Switch from '../../../../utils/common_components/switch_form';
import Dropzone from 'react-dropzone';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement(Card, { 'className': 'col-xs-12 card-full-height' }, React.createElement(CardTitle, { 'title': 'Challenge Details' }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.saveChallenge) }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name',
        'value': '/'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'descriptionRichText',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'multiline': true,
        'label': 'Description'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'coach',
        'labelKey': 'name',
        'valueKey': 'id',
        'component': Select,
        'label': 'Coach',
        'options': this.props.supportingData.coaches,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'schedule_type',
        'labelKey': 'label',
        'valueKey': 'value',
        'component': Select,
        'label': 'Type',
        'options': this.props.supportingData.schedule_type,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'length',
        'type': 'number',
        'component': TextInput,
        'label': 'Length',
        'value': '/'
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'm-r-10',
        'disabled': this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), this.props.match.params.challenge_id !== undefined ? React.createElement(Button, {
        'label': 'Next',
        'className': 'm-r-10',
        'onClick': this.doNext,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true,
        'key': '4484'
    }) : null, React.createElement(Button, {
        'label': 'Cancel',
        'className': 'm-r-10',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }))))))));
}