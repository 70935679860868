import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/program_publish_edit";
import { reduxForm, formValueSelector } from 'redux-form';

import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import {history} from '../../redux/store';
import { uploadFiles } from '../../redux/core/network/file_upload';

class ProgramPublishEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.uploadProgramImage = this.uploadProgramImage.bind(this);
        this.deleteProgramImage = this.deleteProgramImage.bind(this);
    }

    componentWillMount() {
        // this.props.getProgram({id: this.props.match.params.program_id});
    }

    doSave(data){
        let params = this.transformData(data);
        if (params.start_date) {
            params.start_date = Utils.formatServerDate(params.start_date);            
        }
        this.props.postProgram({ ...params }).then(() => {
            this.props.finishEditingMoveBack();
        }).catch(()=>{});
    }

    transformData(data){
        return Utils.flatternFormData(data);
    }

    deleteProgramImage() {
        this.props.change('image_guid', null);
        this.props.change('image_url', this.props.program.derieved_image_url);
    }

    uploadProgramImage(files){
        let formData = new FormData();
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp)=>{
            let result = resp.upload_results[0];
            this.props.change('image_url', result.url);
            this.props.change('image_guid', result.guid);
        }).catch((error)=>{
        });
    }

    render() {
        if(_.isEmpty(this.props.program)){
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        // this.props.cleanModule();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        uploadFiles,
    }, dispatch);
}
const selector = formValueSelector('ProgramPublishEditForm');

function mapStateToProps(state, ownProps) {
    let program = state.program.programEdit.program;
    if (!_.isEmpty(program)) {
        program = { ...program };
        if (program.start_date) {
            program.start_date = Utils.parseServerDate(program.start_date);
        }
    }
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        program: program,
        initialValues: program,
        supportingData: state.program.programEdit.supportingData,
        is_free: selector(state, 'is_free'),
        apple_pay_enabled: selector(state, 'apple_pay_enabled'),
        published: selector(state, 'published'),
        image_url: selector(state, 'image_url'),
        image_guid: selector(state, 'image_guid'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ProgramPublishEditForm = reduxForm(
    {
        form: 'ProgramPublishEditForm',
        enableReinitialize: true,
        validate
    }
)(ProgramPublishEdit);

const ProgramPublishEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgramPublishEditForm);

export default ProgramPublishEditConnected;
