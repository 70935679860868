const fillCourses = (course, supportingData) => ({
    type: 'COURSES_FILL',
    course,
    supportingData,
});
const cleanModule = () => ({
    type: 'COURSES_CLEANUP',
});

export default {
    fillCourses,
    cleanModule,
};