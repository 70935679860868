import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Input } from 'react-toolbox/lib/input';
import { Calendar as BigCalendar } from 'react-big-calendar';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import CalendarEditEvent from '../group_class_edit_calendar_event';
import Button from 'react-toolbox/lib/button';
export default function () {
    return React.createElement('div', { 'className': 'm-30' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(BigCalendar, {
        'className': 'full-height',
        'localizer': this.props.localizer,
        'selectable': true,
        'popup': true,
        'defaultView': 'month',
        'views': [
            'month',
            'week',
            'day',
            'agenda'
        ],
        'events': this.props.eventList,
        'onSelectEvent': event => this.viewEvent(event),
        'eventPropGetter': event => {
            return { style: { backgroundColor: '#f1c40f' } };
        }
    }))), React.createElement(Dialog, {
        'active': this.state.viewEventDialog,
        'onEscKeyDown': this.closeEventDialog,
        'onOverlayClick': this.closeEventDialog
    }, React.createElement(CalendarEditEvent, {
        'scheduleEvent': this.state.schedule,
        'finishEditing': this.closeEventDialog,
        'deleteEvent': this.deleteEvent
    })));
}