
let initialPagination = { page: 0};
let initialState = {
    paginate_info: {...initialPagination},
    conversations: [],
    conversation: {},
    chats: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "CONVERSATION_LIST_FILL":
            if (!action.conversations){
                return state;
            }                
            return Object.assign({}, state, {
                conversations: action.conversations
            });
        case "CHATS_LIST_FILL":
            if (!action.chats){
                return state;
            }     
            _.reverse(action.chats);
            let chats = [...action.chats, ...state.chats];
            chats = _.uniqBy(chats, (item) => {
                return item.id;
            });
            return Object.assign({}, state, {
                conversation: action.conversation,
                chats: chats,
                paginate_info:action.paginate_info
            });
        case "CHATS_LIST_APPEND":
            if (!action.chat){
                return state;
            }     
            return Object.assign({}, state, {
                chats: [...state.chats,action.chat],
            });
        case "CHATS_LIST_CLEANUP":
            return Object.assign({}, state, {
                chats: [],
                paginate_info:{...initialPagination}
            });
        case "CONVERSATION_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
