import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/habit_template_list.rt";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { getHabitTemplateList, deleteHabitTemplate } from '../../redux/habit/network/habit_network';
import swal from 'sweetalert2';

class HabitTemplateList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addHabitTemplate = this.addHabitTemplate.bind(this);
        this.editHabitTemplate = this.editHabitTemplate.bind(this);
        this.deleteHabitTemplate = this.deleteHabitTemplate.bind(this);
    };

    componentWillMount() {
        this.props.getHabitTemplateList({show_partner_templates:true});
    }

    componentWillUnmount() {
    }

    addHabitTemplate() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/habit_templates/add');
    }

    editHabitTemplate(habit_template_id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/habit_templates/edit/'+habit_template_id);
    }

    deleteHabitTemplate(habit_template_id) {
        let that = this;
        swal.fire({
            title: "Confirm",
            text: "Are you sure to delete this habit template?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes",
            useRejections:true
        }).then(() => {
            that.props.deleteHabitTemplate({ habit_template_id: habit_template_id }).then(() => {
                this.props.getHabitTemplateList({
                    show_partner_templates: true
                });
            }).catch(() => { });
        }, () => {
        });

    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        habit_templates: state.habit.habitTemplateReducer.habit_template_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getHabitTemplateList,
            deleteHabitTemplate,
        },
        dispatch
    );
}

const HabitTemplateListConnected = connect(mapStateToProps, mapDispatchToProps)(HabitTemplateList);
export default HabitTemplateListConnected;