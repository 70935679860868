
let initialState = {
    health_documents: [],
    health_document: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "DOCUMENT_LIST_FILL":
            if (!action.health_documents){
                return state;
            }                
            return Object.assign({}, state, {
                health_documents: action.health_documents,
            });
        case "DOCUMENT_LIST_REMOVE":
            if (!action.remove_document_id){
                return state;
            }     
            let update_documents = _.cloneDeep(state.health_documents);
            update_documents = _.filter(update_documents, (item) => {
                if (item.id === action.remove_document_id){
                    return false;
                } else {
                     return true;
               } 
            }); 
            return Object.assign({}, state, {
                health_documents: update_documents,
            });
        case "DOCUMENT_INSTANCE_FILL":
            if (!action.health_document){
                return state;
            }                     
            return Object.assign({}, state, {
                health_document: action.health_document,
            });
        case "DOCUMENT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
