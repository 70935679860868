import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/news_edit";
import { reduxForm, arrayPush, arrayRemove, formValueSelector, arrayRemoveAll, arrayInsert } from 'redux-form';

import store, { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getNews, postNews, uploadMediaNews, deleteMediaNews, changeCoverMediaNews } from '../../redux/news/network/news_edit';
import actionCerator from '../../redux/news/actions/news_edit';
import React from 'react';
import Utils from '../../utils/utils';
import { uploadFiles } from '../../redux/core/network/file_upload';
import Swal from 'sweetalert2';


class NewsEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.uploadImages = this.uploadImages.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.doSave = this.doSave.bind(this);
        this.showNewsPreviewDialog = this.showNewsPreviewDialog.bind(this);
        this.hideNewsPreviewDialog = this.hideNewsPreviewDialog.bind(this);
        this.showNewsTargetDialog = this.showNewsTargetDialog.bind(this);
        this.hideNewsTargetDialog = this.hideNewsTargetDialog.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.setCoverMedia = this.setCoverMedia.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.uploadNewsMedia = this.uploadNewsMedia.bind(this);
        this.state = {
            showMediaPreview: false,
            publish: false,
            displayImageUrl: null,
            newsPreviewDialog: false,
            newsTargetDialog: false,
            notifyUsers: false,
        };
    }

    componentWillMount() {
        this.props.getNews({ id: this.props.news_id });
    }

    doSave(params) {
        params = {
            ...params,
            start_date: Utils.formatServerDate(params.start_date),
            end_date: Utils.formatServerDate(params.end_date),
            published: this.state.publish,
            notifyUsers: this.state.notifyUsers,
        };
        if(this.props.news_id) {
            params['id'] = this.props.news_id;
        }
        params.target = Utils.flatternFormData(params.target);
        this.props.postNews(params).then((response) => {
            if (!this.state.publish) {
                if (!this.props.match.params.news_id) {
                    history.push("/practice/"+this.props.selectedRole.practice.id+"/branding/news/edit/" + response.news.id);
                } 
            } else {
                history.push("/practice/"+this.props.selectedRole.practice.id+"/branding/news");
            }
        }).catch(() => { });
    }

    onPublish() {
        let that = this;
        let display_text = "";
        if (!this.props.news.notification_sent) {
            display_text = "Would you like to notify all targetted your users about this news article?";
        } else {
            display_text = "We have already notified your users about this news article. Would you like to notify once more?";
        }

        Swal.fire({
            title: "Notify Users?",
            text: display_text,
            type: "info",
            showCancelButton: false,
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#0292d3",
            // confirmButtonColor: "#4C555C",
            confirmButtonText: "Alert Users and Publish",
            // confirmButtonText: "Just Publish",
            useRejections:true

        }).then(function (response) {
            if (response.dismiss && ['cancel', 'close'].includes(response.dismiss)) {
                console.log(response.dismiss)
            } else {
                that.setState({
                    publish: true,
                    notifyUsers: true,
                }, () => {
                    that.props.handleSubmit(that.doSave)();
                });
            }

        }, function (dismiss) {});
    }

    onDraft() {
        this.setState({ publish: false }, () => {
            this.props.handleSubmit(this.doSave)();
        });
    }

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/branding/news");
    }

    showNewsPreviewDialog() {
        this.setState({ newsPreviewDialog: true });
    }

    hideNewsPreviewDialog() {
        this.setState({ newsPreviewDialog: false });
    }

    showNewsTargetDialog() {
        this.setState({ newsTargetDialog: true });
    }

    hideNewsTargetDialog() {
        this.setState({ newsTargetDialog: false });
        this.props.getNews({ id: this.props.news_id });
    }

    uploadImages(files) {
        let formData = new FormData();
        
        for (let file of files) {
            formData.append("files", file);
        }

        this.props.uploadFiles(formData).then((resp) => {
            let results = resp.upload_results;
            let uploaded_image_data = [];
            for (let result of results) {
                let image_data_obj = {};
                image_data_obj['media_type'] = 1;
                image_data_obj['guid'] = result.guid;
                image_data_obj['thumbnail'] = result.thumbnail_guid;
                image_data_obj['file_name'] = result.file_name;
                uploaded_image_data.push(image_data_obj);
            }
            this.uploadNewsMedia(uploaded_image_data);
        }).catch((error) => {console.log('ERROR2')
        });
    }

    onVideoUploadCompleted(result) {
        let video_data_obj = {};
        video_data_obj['media_type'] = 2;
        video_data_obj['thumbnail'] = result.thumbnail;
        video_data_obj['guid'] = result.guid;
        this.uploadNewsMedia([video_data_obj]);
    }

    uploadNewsMedia(mediaItems) {
        this.props.uploadMediaNews({
            news_id: this.props.news_id,
            media_items: mediaItems,
        }).then((response) => {
            Utils.showSuccessAlert("Media has been successfully uploaded for this news article");
            store.dispatch(arrayRemoveAll(this.props.form, 'media_items'));
            this.props.change('cover_media', response.cover_media);
            for (let item of response.media_items) {
                store.dispatch(arrayPush(this.props.form, "media_items", item));
            }
        }).catch(() => {
        });
    }

    onVideoUploadFailed(error) {
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    deleteMedia(media) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete this media?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            let params = {};
            params['news_id'] = that.props.match.params.news_id;
            params['media_id'] = media.id;
            that.props.deleteMediaNews(params).then((response) => {
                Utils.showSuccessAlert("Uploaded news media has been deleted");
                store.dispatch(arrayRemoveAll(that.props.form, 'media_items'));
                that.props.change('cover_media', response.cover_media);
                for (let item of response.media_items) {
                    store.dispatch(arrayPush(that.props.form, "media_items", item));
                }
            }).catch(() => {
            });
        },() => {});
    }

    previewImage(media) {
        this.setState({
            showMediaPreview: true,
            displayImageUrl: media.url,
        });
    }

    setCoverMedia(index) {
        let media = this.props.media_items[index];
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you change your cover media?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {};            
            params['news_id'] = that.props.news.id;
            params['media_id'] = media.id;
            that.props.changeCoverMediaNews(params).then(() => {
                Utils.showSuccessAlert("Selected media has been set as cover media");
                store.dispatch(arrayRemove(that.props.form, 'media_items', index));
                store.dispatch(arrayInsert(that.props.form, 'media_items', 0, that.props.cover_media));
                that.props.change('cover_media', media);
            }).catch(() => {
            });
        }, function (dismiss) {
        });

    }

    render() {
        if (_.isEmpty(this.props.news)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        uploadFiles,
        getNews,
        postNews,
        uploadMediaNews,
        deleteMediaNews,
        changeCoverMediaNews,
        cleanModule: actionCerator.cleanModule,
    }, dispatch);
}

const selector = formValueSelector('NewsEditForm');

function mapStateToProps(state, ownProps) {
    let news = {};
    if (!_.isEmpty(state.news.newsEdit.news)) {
        news = { ...state.news.newsEdit.news };
        news.start_date = Utils.parseServerDate(news.start_date);
        news.end_date = Utils.parseServerDate(news.end_date);
    }
    return {
        ...ownProps,
        selectedRole: state.auth.roleReducer.selectedRole,
        news: news,
        supportingData: state.news.newsEdit.supportingData,
        initialValues: news,
        all_clients: selector(state, 'target.all_clients'),
        media_items: selector(state, 'media_items'),
        cover_media: selector(state, 'cover_media'),
        news_id: news.id ? news.id : ownProps.match.params.news_id,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'title',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const NewsEditForm = reduxForm({
    form: 'NewsEditForm', enableReinitialize: true, validate
})(NewsEdit);

const NewsEditConnected = connect(mapStateToProps, mapDispatchToProps)(NewsEditForm);
export default NewsEditConnected;
