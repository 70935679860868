import * as React from 'react';
import * as _ from 'lodash';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import EasyPie from '../../../utils/common_components/easypie.jsx';
export default function () {
    function repeatTreatment1(treatment, treatmentIndex) {
        return [React.createElement('div', {
                'className': 'row bg-lightgray border-body p-10 m-10',
                'key': '3621'
            }, React.createElement('div', { 'className': 'col-xs-12 c-dark' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement('div', { 'className': 'p-r-10' }, React.createElement(TooltipWrapper, { 'tooltip': 'Workout' }, React.createElement('i', { 'className': 'material-icons c-black' }, 'accessibility'))), React.createElement('div', { 'className': 'flex-auto c-accent-dark' }, React.createElement('h6', {}, '\n                                ', treatment.progression_name, '(', treatment.workout_name, ')\n                            '), React.createElement('div', { 'className': 'f-13 c-darkgray' }, '\n                                Duration: ', treatment.progression_duration, ' mins\n                            ')), React.createElement('div', { 'className': 'c-accent-dark' }, treatment.has_tracked === 3 ? React.createElement('span', {
                'className': 'bg-success p-5',
                'key': '1358'
            }, 'Completed') : null, treatment.has_tracked === 2 ? React.createElement('span', {
                'className': 'bg-primary p-5',
                'key': '1470'
            }, 'Partially Done') : null, treatment.has_tracked === 1 ? React.createElement('span', {
                'className': 'bg-danger p-5',
                'key': '1587'
            }, 'Not Done') : null, treatment.has_tracked === 0 && this.props.treatment_date === this.state.today_string ? React.createElement('span', {
                'className': 'bg-primary p-5',
                'key': '1697'
            }, 'Today') : null, treatment.has_tracked === 0 && this.props.treatment_date < this.state.today_string ? React.createElement('span', {
                'className': 'bg-danger p-5',
                'key': '1862'
            }, 'Not Tracked') : null, treatment.has_tracked === 0 && this.props.treatment_date > this.state.today_string ? React.createElement('span', {
                'className': 'bg-success p-5',
                'key': '2030'
            }, 'Future') : null))))];
    }
    function repeatTreatment2(treatment, treatmentIndex) {
        return [React.createElement('div', {
                'className': 'row bg-lightgray border-body p-10 m-10',
                'key': '23271'
            }, React.createElement('div', { 'className': 'col-xs-12 c-dark' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement('div', { 'className': 'p-r-10' }, React.createElement(TooltipWrapper, { 'tooltip': 'Workout' }, React.createElement('i', { 'className': 'material-icons c-black' }, 'accessibility'))), React.createElement('div', { 'className': 'flex-auto c-accent-dark' }, React.createElement('h6', {}, '\n                                ', treatment.progression_name, '(', treatment.workout_name, ')\n                            '), React.createElement('div', { 'className': 'f-13 c-darkgray' }, '\n                                Duration: ', treatment.progression_duration, ' mins\n                            ')), React.createElement('div', { 'className': 'c-accent-dark' }, treatment.has_tracked === 3 ? React.createElement('span', {
                'className': 'bg-success p-5',
                'key': '3331'
            }, 'Completed') : null, treatment.has_tracked === 2 ? React.createElement('span', {
                'className': 'bg-primary p-5',
                'key': '3443'
            }, 'Partially Done') : null))))];
    }
    function repeatHabit3(habit, habitIndex) {
        return [React.createElement('div', {
                'className': 'row bg-lightgray border-body p-10 m-10',
                'key': '36931'
            }, React.createElement('div', { 'className': 'col-xs-12 c-dark' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement('div', { 'className': 'p-r-10' }, React.createElement(TooltipWrapper, { 'tooltip': 'Habit' }, React.createElement('i', { 'className': 'material-icons c-black' }, 'watch_later'))), React.createElement('div', { 'className': 'flex-auto c-accent-dark' }, React.createElement('h6', {}, '\n                                ', habit.client_engagement_habit.text, '\n                            ')), React.createElement('div', { 'className': 'c-accent-dark' }, habit.has_tracked === 0 ? React.createElement('span', {
                'className': `p-5 ${ this.props.treatment_date < this.state.today_string ? 'bg-danger' : 'bg-primary' }`,
                'key': '4487'
            }, '\n                                Not Tracked\n                            ') : null, habit.has_tracked === 1 ? React.createElement('span', {
                'className': 'bg-danger p-5',
                'key': '4738'
            }, '\n                                Not Done\n                            ') : null, habit.has_tracked === 2 ? React.createElement('span', {
                'className': 'bg-success p-5',
                'key': '4906'
            }, '\n                                Partially Done\n                            ') : null, habit.has_tracked === 3 ? React.createElement('span', {
                'className': 'bg-success p-5',
                'key': '5081'
            }, '\n                                Completed\n                            ') : null))))];
    }
    return React.createElement('div', {
        'onClick': () => {
            this.showPreview(this.props.engagement.id, this.props.treatment_date);
        },
        'className': 'w-100 pointer'
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'flex-auto' },
        _.map(this.props.workouts, repeatTreatment1.bind(this)),
        _.map(this.props.ongoing_workouts, repeatTreatment2.bind(this)),
        _.map(this.props.habits, repeatHabit3.bind(this))
    ]));
}