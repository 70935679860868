import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { NavLink } from 'react-router-dom';
import { Select as SelectNew } from 'react-select';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import { tetheredSelectAsynFunction as AsyncSelect } from '../../../utils/common_components/select_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from '../../../utils/common_components/dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Input from 'react-toolbox/lib/input';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
import { ImAttachment } from 'react-icons/im';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement('div', {
        'className': 'm-t-20 d-flex back-btn c-grey cursor-pointer',
        'onClick': this.cancel
    }, '\n        Back\n    '), React.createElement(Card, { 'className': 'no-box-shadow m-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', {
        'className': 'add-media d-flex no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.saveBanner)
    }, React.createElement('div', { 'className': 'media-info p-b-36' }, React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, this.props.match.params.banner_type === 'promo-banners' ? React.createElement(Card, {
        'className': 'no-box-shadow bg-white',
        'key': '2550'
    }, React.createElement(CardTitle, {
        'title': 'Edit Promo Banner',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement('div', { 'className': 'subtitle m-t-8' }, this.props.initialValues.banner_location), React.createElement(CardText, { 'className': 'w-100 p-t-20 p-0' }, React.createElement(Field, {
        'label': 'Heading',
        'className': 'w-100 m-b-20',
        'name': 'heading',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'Sub-heading',
        'className': 'w-100 m-b-20',
        'name': 'sub_heading',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'CTA Button Text',
        'className': 'w-100 m-b-20',
        'name': 'button_text',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'TA Button Link',
        'className': 'w-100 m-b-20',
        'name': 'button_link',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    })), React.createElement('div', { 'className': 'media m-t-16' }, React.createElement('h5', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Cover Image'), React.createElement('div', { 'className': 'preview-container d-flex w-100' }, this.props.thumbnail || this.props.initialValues.thumbnail_url ? React.createElement('div', {
        'className': 'image-list-container p-6 w-80 d-flex align-center',
        'key': '3982'
    }, React.createElement('div', { 'className': 'image-list-title w-100 d-flex flex-sb align-center' }, React.createElement('div', { 'className': 'd-flex align-center' }, React.createElement('div', {
        'className': 'image-container',
        'style': {
            backgroundImage: 'url(' + this.props.thumbnail + ')',
            width: '35px',
            height: '30px',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    }), React.createElement('div', { 'className': 'text p-l-7' }, '\n                                                        ', this.props.file_name, '\n                                                    ')), React.createElement('div', {
        'className': 'cancel m-r-3',
        'style': { cursor: 'pointer' },
        'onClick': this.onCancelImage
    }))) : null, React.createElement(Dropzone, {
        'className': 'dummy-suppress-warning  m-l-10',
        'style': { width: '290px' },
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadCoverImage
    }, React.createElement(Button, {
        'className': 'add-media-btn',
        'style': { height: '42px' },
        'label': this.props.initialValues.thumbnail_url ? 'Change Image' : 'Add Image',
        'primary': true,
        'raised': true
    })))), React.createElement('div', { 'className': 'row end-xs m-t-30 m-b-10 m-r-1 middle-xs' }, React.createElement(Button, {
        'label': 'Update Banner',
        'type': 'submit',
        'className': 'm-l-10 bg-success w-34',
        'raised': true,
        '<': true,
        'div': true
    }))) : null, this.props.match.params.banner_type === 'feature-banners' ? React.createElement(Card, {
        'className': 'no-box-shadow bg-white',
        'key': '6006'
    }, React.createElement(CardTitle, {
        'title': this.props.match.params.banner_id ? 'Edit Feature Banner' : 'Add Feature Banner',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement('div', { 'className': 'subtitle m-t-8' }, this.props.initialValues.banner_location), React.createElement(CardText, { 'className': 'w-100 p-t-20 p-0' }, React.createElement(Field, {
        'label': 'Heading',
        'required': true,
        'className': 'w-100 m-b-20',
        'name': 'heading',
        'type': 'text',
        'maxLength': '14',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'Body Text',
        'required': true,
        'className': 'w-100 m-b-20',
        'name': 'sub_heading',
        'type': 'text',
        'maxLength': '60',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement('h6', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Feature Image'), React.createElement('div', { 'className': 'flex-space-between flex-align-center' }, React.createElement('div', { 'className': 'w-45' }, React.createElement(Dropzone, {
        'multiple': false,
        'accept': 'image/png',
        'onDrop': this.uploadCoverImage
    }, React.createElement(Button, {
        'className': 'feature-add-media',
        'primary': true,
        'raised': true
    }, React.createElement(ImAttachment, {
        'style': {
            color: '#999999',
            marginRight: '10px'
        }
    }), this.props.thumbnail ? 'Change uploaded file' : 'Select file to upload', '\n                                            '))), React.createElement('div', { 'className': 'w-45' }, React.createElement('span', { 'className': 'c-red f-14' }, this.state.fileError), React.createElement('br', {}), React.createElement('span', { 'className': 'c-black' }, this.props.file_name))), React.createElement('div', { 'className': 'c-grey f-12 m-t-10 w-45' }, 'STRICTLY: PNG with alpha, 155x198 px '), React.createElement('h6', { 'className': 'c-black f-16 w-100 m-t-20' }, 'Action'), React.createElement('div', { 'className': 'flex-space-between m-t-20' }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'label': 'Button Label',
        'required': true,
        'className': 'w-100',
        'name': 'button_text',
        'type': 'text',
        'maxLength': '16',
        'component': TextInput,
        'onClick': this.inputClick
    })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'required': true,
        'name': 'button_actions',
        'component': Select,
        'label': 'Button Action',
        'options': this.props.supporting_data.button_action,
        'clearable': false,
        'labelKey': 'page_name',
        'valueKey': 'id'
    }))), React.createElement('div', { 'className': 'flex-space-between m-t-20' }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'required': true,
        'name': 'themes',
        'component': Select,
        'label': 'Color Scheme',
        'options': this.props.supporting_data.themes,
        'clearable': false,
        'labelKey': 'color_name',
        'valueKey': 'color_name'
    })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'label': 'Priority',
        'required': true,
        'className': 'w-100',
        'name': 'priority',
        'type': 'number',
        'onInput': this.onNumberInput,
        'component': TextInput,
        'onClick': this.inputClick
    })))), React.createElement('div', { 'className': 'm-t-35 m-b-10 text-end w-100' }, React.createElement(Button, {
        'label': this.props.match.params.banner_id ? 'Update Banner' : 'Add Banner',
        'type': 'submit',
        'className': 'bg-success w-200 border-r-10',
        'raised': true,
        '<': true,
        'div': true
    }))) : null, this.props.match.params.banner_type === 'upcoming-banners' ? React.createElement(Card, {
        'className': 'no-box-shadow bg-white',
        'key': '10196'
    }, React.createElement(CardTitle, {
        'title': this.props.match.params.banner_id ? 'Edit Upcoming Banner' : 'Add Upcoming Banner',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement(CardText, { 'className': 'w-100 p-t-20 p-0' }, React.createElement(Field, {
        'label': 'Heading',
        'className': 'w-100 m-b-20',
        'name': 'heading',
        'type': 'text',
        'maxLength': '14',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'Body Text',
        'className': 'w-100 m-b-20',
        'name': 'sub_heading',
        'type': 'text',
        'maxLength': '60',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement('h6', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Action'), React.createElement('div', { 'className': 'flex-space-between m-t-15' }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'name': 'action_type',
        'component': Select,
        'label': 'Action Type',
        'options': this.props.action_type_options,
        'clearable': false
    })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'label': 'URL',
        'className': 'w-100',
        'name': 'banner_url',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }))), this.props.action_type === 2 ? React.createElement('div', {
        'className': 'flex-space-between m-t-15',
        'key': '11772'
    }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'name': 'course_type',
        'label': 'Course Type',
        'component': Select,
        'options': this.props.course_type_options,
        'clearable': false
    })), this.props.course_type ? React.createElement('div', {
        'className': 'w-48',
        'key': '12151'
    }, React.createElement(Field, {
        'name': 'course',
        'label': 'Course',
        'component': Select,
        'options': this.props.courseList,
        'clearable': false,
        'labelKey': 'title',
        'valueKey': 'id'
    })) : null) : null, React.createElement('div', { 'className': 'flex-space-between' }, this.state.fileType === '' || this.state.fileType === 'image' ? React.createElement('div', {
        'className': 'w-45',
        'key': '12566'
    }, React.createElement('h6', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Featured Image')) : null, this.state.fileType === '' || this.state.fileType === 'video' ? React.createElement('div', {
        'className': 'w-45',
        'key': '12827'
    }, React.createElement('h6', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Featured Video')) : null), React.createElement('div', { 'className': 'flex-space-between flex-align-center' }, this.state.fileType === '' || this.state.fileType === 'image' ? React.createElement('div', {
        'className': 'w-45',
        'key': '13210'
    }, React.createElement(Dropzone, {
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.uploadCoverImage
    }, React.createElement(Button, {
        'className': 'feature-add-media',
        'primary': true,
        'raised': true
    }, React.createElement(ImAttachment, {
        'style': {
            color: '#999999',
            marginRight: '10px'
        }
    }), this.props.thumbnail ? 'Change uploaded file' : 'Select file to upload', '\n                                            '))) : null, this.state.fileType === 'image' ? React.createElement('div', {
        'className': 'w-45',
        'key': '13868'
    }, React.createElement('span', { 'className': 'c-red f-14' }, this.state.fileError), React.createElement('span', { 'className': 'c-black' }, this.props.file_name), React.createElement(IconButton, {
        'icon': 'delete',
        'tooltip': 'Remove Image',
        'className': 'c-red',
        'onClick': this.onCancelImage
    })) : null, this.state.fileType === '' ? React.createElement('h6', {
        'className': 'c-black f-14',
        'key': '14331'
    }, 'OR') : null, this.state.fileType === '' || this.state.fileType === 'video' ? React.createElement('div', {
        'className': 'w-45',
        'key': '14433'
    }, React.createElement(VimeoUploader, {
        'className': 'feature-add-media',
        'onUploadCompleted': this.onVideoUploaded,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'name': 'add_media',
        'buttonText': this.props.video_guid || this.props.banner.guid ? 'Change Video' : 'Add Media'
    })) : null, this.state.fileType === 'video' ? React.createElement('div', {
        'className': 'w-45 d-flex flex-align-center',
        'key': '15130'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-imagecreator',
        'handleUploadProcessing': true,
        'onPlayerInitialized': player => {
            this.player = player;
        },
        'videoList': [this.props.video_guid ? this.props.video_guid : this.props.banner.guid]
    }), React.createElement(IconButton, {
        'icon': 'delete',
        'tooltip': 'Remove Video',
        'className': 'c-red',
        'onClick': this.onCancelVideo
    })) : null), React.createElement('div', { 'className': 'm-t-20' }, React.createElement('h6', { 'className': 'c-black f-16 w-100 m-b-8' }, 'Visibility'), React.createElement('div', { 'className': 'flex-space-between m-t-15' }, React.createElement('div', { 'className': 'w-48 date-picker p-relative border-around' }, React.createElement(Field, {
        'name': 'start_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'Display from'
    })), React.createElement('div', { 'className': 'w-48 date-picker p-relative border-around' }, React.createElement(Field, {
        'name': 'end_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'Display until'
    }))))), React.createElement('div', { 'className': 'm-t-35 m-b-10 text-end w-100' }, React.createElement(Button, {
        'label': this.props.match.params.banner_id ? 'Update Banner' : 'Add Banner',
        'type': 'submit',
        'className': 'bg-success w-200 border-r-10',
        'raised': true,
        '<': true,
        'div': true
    }))) : null))))));
}