// Action Creators
const fillWorkoutList = (workouts) => ({
    type: 'GROUP_WORKOUT_LIST_FILL',
    workouts
});

const cleanModule = () => ({
    type: 'GROUP_WORKOUT_CLEANUP',
});

export default {
    fillWorkoutList,
    cleanModule,
};
