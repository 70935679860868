import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProfileEdit from '../../user/components/profile_edit';

class ClientEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
    };

    render() {
        return (
            <ProfileEdit user_id={this.props.match.params.client_id} is_client={true} self_editing={false}/>
        );
    }

}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        }, 
        dispatch
    );
}

const ClientEditConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEdit);
export default ClientEditConnected;
