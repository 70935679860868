import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
export default function () {
    function repeatMedia1(media, index) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5',
            'key': 'media' + index
        }, media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225 pointer',
            'style': { backgroundImage: 'url(' + encodeURI(media.thumbnail_url) + ')' },
            'onClick': () => {
                this.previewImage(media);
            },
            'key': '3751'
        }) : null, media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225 pointer',
            'key': '4004'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'gomotive_video' + media.guid,
            'videoList': [media.guid]
        })) : null);
    }
    return React.createElement('div', { 'className': 'row m-20' }, React.createElement('div', { 'className': 'col-xs-12 f-40 m-b-5' }, '\n        ', this.props.news.title, '\n    '), React.createElement('div', { 'className': 'col-xs-12 f-400 f-14 m-b-5' }, '\n        ', this.props.news.author.name, '\n    '), this.props.news.start_date ? React.createElement('div', {
        'className': 'col-xs-12 f-300 f-12',
        'key': '2214'
    }, '\n        ', this.props.news.start_date, '\n    ') : null, !this.props.news.start_date ? React.createElement('div', {
        'className': 'col-xs-12 f-300 f-12',
        'key': '2336'
    }, '\n        ', moment(this.props.news.created_date).calendar(), '\n    ') : null, React.createElement('hr', { 'className': 'dashed' }), this.props.news.cover_media ? React.createElement('div', {
        'className': 'col-xs-12 m-t-10',
        'key': '2504'
    }, React.createElement('div', { 'className': 'row start-xs' }, this.props.news.cover_media.media_type === 1 ? React.createElement('div', {
        'className': 'thumbnail pointer box-300-225',
        'onClick': () => {
            this.previewImage(this.props.news.cover_media);
        },
        'style': { backgroundImage: 'url(' + encodeURI(this.props.news.cover_media.thumbnail_url) + ')' },
        'key': '2618'
    }) : null, this.props.news.cover_media.media_type === 2 ? React.createElement('div', {
        'className': 'box-300-225 pointer',
        'key': '2945'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video' + this.props.news.cover_media.guid,
        'videoList': [this.props.news.cover_media.guid]
    })) : null)) : null, React.createElement(RichTextViewer, {
        'className': 'col-xs-12 m-t-10',
        'value': this.props.news.content
    }), !_.isEmpty(this.props.news.media_items) ? React.createElement('div', {
        'className': 'col-xs-12 m-t-10',
        'key': '3365'
    }, React.createElement('div', { 'className': 'row material-label m-l-0 m-b-10' }, '\n            Additional Media\n        '), React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        _.map(this.props.news.media_items, repeatMedia1.bind(this))
    ])) : null, !this.props.match ? React.createElement('div', {
        'className': 'col-xs-12 end-xs m-t-10',
        'key': '4294'
    }, React.createElement(Button, {
        'onClick': this.props.handleClose,
        'label': 'Close',
        'className': 'bg-accent m-l-5',
        'type': 'button',
        'raised': true
    })) : null, this.state.showMediaPreview ? React.createElement(Lightbox, {
        'mainSrc': this.state.displayImageUrl,
        'onCloseRequest': () => this.setState({
            showMediaPreview: false,
            displayImageUrl: null
        }),
        'key': '4507'
    }) : null);
}