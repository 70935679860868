// Action Creators
const intakeListFill = (intake_list) => ({
    type: 'INTAKE_LIST_FILL',
    intake_list:intake_list
});
const intakeInstanceFill = (intake) => ({
    type: 'INTAKE_INSTANCE_FILL',
    intake
});
const intakePublishInstanceFill = () => ({
    type: 'INTAKE_PUBLISH_INSTANCE_FILL'
});
const intakeQuestionFill = (question) => ({
    type: 'INTAKE_QUESTION_FILL',
    question
});
const intakeQuestionAdd = () => ({
    type: 'INTAKE_QUESTION_ADD',
});
const intakeQuestionReorder = (questions) => ({
    type: 'INTAKE_QUESTION_REORDER',
    questions
});
const intakeQuestionRemove = (question) => ({
    type: 'INTAKE_QUESTION_REMOVE',
    question
});
const intakeCleanUp = () => ({
    type: 'INTAKE_CLEANUP',
});

export default {
    intakeListFill,
    intakeInstanceFill,
    intakePublishInstanceFill,
    intakeQuestionFill,
    intakeQuestionAdd,
    intakeQuestionReorder,
    intakeQuestionRemove,
    intakeCleanUp,
};
