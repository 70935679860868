import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from './templates/program_general_edit_new';
import { reduxForm, formValueSelector } from 'redux-form';

import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import {getProgram, postProgram} from '../../redux/program/network/program_edit';
import {toggleProgramPublish, toggleWorkoutPublish} from '../../redux/program/network/program_preview';
import { uploadFiles } from '../../redux/core/network/file_upload';
import { getWrapperVideoList } from '../../redux/practice/network/practice_wrapper_video';
import actionCreator from '../../redux/program/actions/program_edit';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import moment from 'moment';
import { listWorkouts, listGuidanceDocuments,
  listNutritionDocuments, postHealthDocument,
 deleteHealthDocument,workoutSort,workoutDelete } from '../../redux/treatment_template/network/treatment_template_program';

let categoryLevel2 = [];
let categoryLevel3 = [];
class ProgramGeneralEdit extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.doSave = this.doSave.bind(this);
    this.transformData = this.transformData.bind(this);
    this.uploadScientificEvidence = this.uploadScientificEvidence.bind(this);
    this.deleteScientificEvidence = this.deleteScientificEvidence.bind(this);
    this.onChangeCategoryLevel1 = this.onChangeCategoryLevel1.bind(this);
    this.onChangeCategoryLevel2 = this.onChangeCategoryLevel2.bind(this);
    this.togglePublish = this.togglePublish.bind(this);
    this.onDraft = this.onDraft.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.finishEditingWorkout = this.finishEditingWorkout.bind(this);
    this.inputClick = this.inputClick.bind(this);
    this.setActive = this.setActive.bind(this);

    this.state = {
      publish: "",
      tabIndex:0,
      categoryLevel2: [],
      categoryLevel3: [],
      editingProgressionId: null,
      editingWorkoutId: "null",
      showWorkoutEditForm: false,
      progression_id: ""
    };
  }
  setActive(el, active){
    const formField = el.parentNode.parentNode
    if (active) {
      formField.classList.add('form-field--is-active')
    } else {
      formField.classList.remove('form-field--is-active')
      el.value === '' ? 
        formField.classList.remove('form-field--is-filled') : 
        formField.classList.add('form-field--is-filled')
    }
}
inputClick(el){
    [].forEach.call(
        document.querySelectorAll('.theme__inputElement___27dyY'),
        (el) => {
          el.onblur = () => {
            this.setActive(el, false)
          }
          el.onfocus = () => {
            this.setActive(el, true)
          }
        }
      )
}
  componentWillMount() {
    // this.props.getProgram({id: this.props.match.params.program_id});
    this.props.getWrapperVideoList();
      if(this.props.category && this.props.category.id){
        let selectedCategoryLevel2 = _.filter(
          this.props.supportingData.category_level2,
          { category: this.props.category.id }
        );
        return categoryLevel2 = selectedCategoryLevel2
      }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pristine !== nextProps.pristine) {
      this.props.disableProgression(!nextProps.pristine);
    }    
    if (nextProps.category !== null && nextProps.category !== undefined) {
      if (nextProps.supportingData !== null) {
        let selectedCategoryLevel2 = _.filter(
          nextProps.supportingData.category_level2,
          { category: nextProps.category.id }
        );
        this.setState({
          categoryLevel2: selectedCategoryLevel2
        });
        // return categoryLevel2 = selectedCategoryLevel2
      }
    }    
    if (nextProps.category_level2 !== null && nextProps.category_level2 !== undefined   ) {
      if (nextProps.supportingData !== null) {
        let selectedCategoryLevel3 = _.filter(
          nextProps.supportingData.category_level3,
          { category_level2: nextProps.category_level2.id }
        );
        this.setState({
          categoryLevel3: selectedCategoryLevel3
        });
        // return categoryLevel3 = selectedCategoryLevel3
      }
    }
  }

  uploadScientificEvidence(files) {
    let formData = new FormData();
    formData.append('files', files[0]);
    this.props
      .uploadFiles(formData)
      .then((resp) => {
        let result = resp.upload_results[0];
        this.props.change('scientific_evidence_url', result.url);
        this.props.change('scientific_evidence_guid', result.guid);
      })
      .catch((error) => {});
  }

  deleteScientificEvidence() {
    this.props.change('scientific_evidence_url', null);
    this.props.change('scientific_evidence_guid', null);
  }

  onChangeCategoryLevel1(value) {
    let selectedCategoryLevel2 = _.filter(
      this.props.supportingData.category_level2,
      { category: value }
    );
    return categoryLevel2 = selectedCategoryLevel2
  }

  onChangeCategoryLevel2(value) {
    let selectedCategoryLevel3 = _.filter(
      this.props.supportingData.category_level3,
      { category_level2: value }
    );
    return categoryLevel3 = selectedCategoryLevel3
  }

  togglePublish(publish) {
    this.props.toggleProgramPublish({
        id: this.props.program.id,
        'is_publish': publish,
    }).then(() => {
        // this.props.finishEditingMoveBack();
    }).catch((error) => {});

}

finishEditingWorkout() {
  // this.props.unAllocateSpace();
  this.setState({
      editingWorkoutId: null,
      showWorkoutEditForm: false,
  });
  this.props.listWorkouts({ treatment_template_id: this.props.treatment_template_id });
}

  doSave(data) {
    let params = this.transformData(data);
    if (params.start_date) {
      params.start_date = Utils.formatServerDate(params.start_date);
    }
    // let isNew = !this.props.match.params.program_id;
    this.props
      .postProgram({ ...params,engagement_id:this.props.engagement_id })
      .then((response) => {
        this.props.getProgram({ id: response.id }).then((resp) => {
          let progression_id;
          if(resp.program.progressions.length){
            progression_id = resp.program.progressions[0].id
          }
          if(this.state.publish===false){
            if(this.props.gamePlanSelection) {
              this.togglePublish(this.state.publish);
              // this.finishEditingWorkout()
              this.props.finishEditingGame();
            } else {
              this.togglePublish(this.state.publish);
            // history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + '/list');
            }
          }else if(this.state.publish===true){
            console.log("inside false",this.state.publish)
            // this.props.moveToBuild();
            if(progression_id){
              this.props.editProgression(progression_id);
            } else {
              this.props.editProgression();
            }
          }

        });
      })
      .catch((error) => {});
      if (this.props.programType === 'workout' || this.props.group_id ) {
        if(this.props.program_id){
            if(this.state.publish===true){
                this.props.toggleWorkoutPublish({
                 id: this.props.program.id,
                 'is_publish': true,
                });
            } else if(this.state.publish===false){
                this.props.toggleWorkoutPublish({
                    id: this.props.program.id,
                    'is_publish': false,
                   });
            }

        }
    } 
  }

  onDraft() {
    this.setState({ publish: false }, () => {
        this.props.handleSubmit(this.doSave)();
    });
  } 

  onPublish() {
    this.setState({ publish: true }, () => {
      // Utils.showErrorAlert("Can not be published. There are no exercises. Please add one or more exercises before publish.");
      this.props.handleSubmit(this.doSave)();
    });
  }

  transformData(data) {
    return Utils.flatternFormData(data);
  }

  render() {
    if (_.isEmpty(this.props.program)) {
      return <div />;
    }
    return Template.apply(this);
  }

  componentWillUnmount() {
    // this.props.cleanModule();
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // getProgram,
      // postProgram,
      toggleProgramPublish,
      toggleWorkoutPublish,
      uploadFiles,
      getWrapperVideoList,
      listWorkouts,
      cleanModule: actionCreator.cleanModule
    },
    dispatch
  );
}
const selector = formValueSelector('ProgramGeneralEditForm');

function mapStateToProps(state, ownProps) {
  // let today = moment().seconds(0).milliseconds(0).toDate();
  // let program = { ...ownProps.program };
  // program.start_date = program.start_date ? program.start_date : today;
  let initialValues = { ...ownProps.program };
  let sports = []
  if (ownProps.isNonSyncGroup) {
    initialValues.schedule_type = 2;
  }
  if (ownProps.programType === 'treatment_template_workout') {
    initialValues.treatment_template_id = ownProps.treatment_template_id;
  }
  if(initialValues.sports){
    sports = initialValues.sports.map((equipment)=> equipment.id)
}
  initialValues.sports = sports
  return {
    categoryLevel2,
    categoryLevel3,
    selectedRole: state.auth.roleReducer.selectedRole,
    practice: state.auth.roleReducer.selectedRole.practice,
    user: state.auth.authReducer.user,
    program: state.program.programEdit.program,
    // initialValues: state.program.programEdit.program,
    supportingData: state.program.programEdit.supportingData,
    has_scientific_evidence: selector(state, 'has_scientific_evidence'),
    scientific_evidence_url: selector(state, 'scientific_evidence_url'),
    scientific_evidence_guid: selector(state, 'scientific_evidence_guid'),
    is_free: selector(state, 'is_free'),
    apple_pay_enabled: selector(state, 'apple_pay_enabled'),
    published: selector(state, 'published'),
    image_url: selector(state, 'image_url'),
    image_guid: selector(state, 'image_guid'),
    category: selector(state, 'category'),
    category_level2: selector(state, 'category_level2'),
    category_level3: selector(state, 'category_level3'),
    schedule_type: selector(state, 'schedule_type'),
    is_gi_3dmaps: selector(state, 'is_gi_3dmaps'),
    is_gi_golf: selector(state, 'is_gi_golf'),
    wrapper_videos: state.practice.practiceWrapperVideo.wrapper_videos,
    ...ownProps,
    initialValues: initialValues
  };
}

const validate = (values, props) => {
  let requiredFields = ['name', 'category', 'start_date'];
  const errors = {};
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (values['schedule_type'] === 2) {
    let per_week = parseInt(values['per_week']);
    if (isNaN(per_week)) {
      errors['per_week'] = 'Must be between 1 to 7';
    } else if (per_week < 1 || per_week > 7) {
      errors['per_week'] = 'Must be between 1 to 7';
    }
    let per_day = parseInt(values['per_day']);
    if (isNaN(per_week)) {
      errors['per_day'] = 'Must be between 1 to 10';
    } else if (per_day < 1 || per_day > 10) {
      errors['per_day'] = 'Must be between 1 to 10';
    }
  }
  return errors;
};

const ProgramGeneralEditForm = reduxForm({
  form: 'ProgramGeneralEditForm',
  enableReinitialize: true,
  validate,
  asyncBlurFields: []
})(ProgramGeneralEdit);

const ProgramGeneralEditConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramGeneralEditForm);

export default ProgramGeneralEditConnected;
