import React  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/goal_edit";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, arrayPush, arrayRemove } from 'redux-form';
import { history } from '../../redux/store';
import { postGroupGoal, addGroupGoal } from '../../redux/group/network/group_goal';
import store from '../../redux/store';
import { getGoalTrackingUnitList } from '../../redux/goal/network/goal_network';

class GroupGoalEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.addGoalQuestion = this.addGoalQuestion.bind(this);
        this.deleteGoalQuestion = this.deleteGoalQuestion.bind(this);
        this.deleteGoal = this.deleteGoal.bind(this);
    };

    componentWillMount() {
        this.props.getGoalTrackingUnitList({});
    }

    doSave(params) {
        params = {
            ...params,
            goal_questions: _.map(params.goal_questions, (tracking) => { return Utils.flatternFormData(tracking); }),
        };
        params.group_id = this.props.group_id;
        this.props.postGroupGoal(params).then(() => {
            this.props.finishEditing();
        }).catch(() => { });
    }

    addGoalQuestion() {
        store.dispatch(arrayPush(this.props.form, 'goal_questions', {}));
    }

    deleteGoalQuestion(index) {
        store.dispatch(arrayRemove(this.props.form, 'goal_questions', index));
    }

    deleteGoal() {
        this.props.deleteGoal();
    }


    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }
}

const selector = formValueSelector('GroupGoalEditForm');

function mapStateToProps(state, ownProps) {
    let goal = ownProps.goal ? ownProps.goal : {
        goal_questions: [],is_tracked:false
    };
    return {
        ...ownProps,
        initialValues: {...goal},
        goalTrackingUnits: state.goal.goalReducer.goal_tracking_unit,
        goal:goal,
        goal_questions: selector(state, 'goal_questions'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postGroupGoal,
        addGroupGoal,
        getGoalTrackingUnitList,
    }, dispatch);
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const GroupGoalEditForm = reduxForm(
    {
        form: 'GroupGoalEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupGoalEdit);

const GroupGoalEditConnected = connect(mapStateToProps, mapDispatchToProps)(GroupGoalEditForm);
export default GroupGoalEditConnected;
