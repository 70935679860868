let initialState = {
    news: {},
    supportingData: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "NEWS_EDIT_NEWS_FILL":
            return Object.assign({}, state, {
                news: action.news,
                supportingData: action.supportingData,
            });
        case "NEWS_EDIT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};