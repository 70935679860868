// Action Creators
const clientEngagementListFill = (engagement_list) => ({
    type: 'CLIENT_ENGAGEMENT_LIST_FILL',
    engagement_list: engagement_list
});

const clientEngagementInstanceFill = (engagement) => ({
    type: 'CLIENT_ENGAGEMENT_INSTANCE_FILL',
    engagement: engagement
});

const clientEngagementCleanup = () => ({
    type: 'CLIENT_ENGAGEMENT_INSTANCE_CLEANUP',
});

export default {
    clientEngagementInstanceFill,
    clientEngagementCleanup,
    clientEngagementListFill
};
