import * as React from 'react';
import * as _ from 'lodash';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs middle-xs full-height c-dark w-100 p-20' }, React.createElement('div', { 'className': 'header2' }, this.state.display_content === 'gm' ? React.createElement('h2', { 'key': '216' }, '\n            GoMotive Practitioner Terms Of Use\n        ') : null, this.state.display_content === 'gi' ? React.createElement('h2', { 'key': '334' }, '\n            Gray Institute Practitioner Terms Of Use\n        ') : null), React.createElement('div', { 'className': 'panel col-xs-11 m-t-15 p-20' }, this.state.display_content === 'gm' ? React.createElement(RichTextViewer, {
        'className': 'm-t-10 m-b-10 text-left  c-dark',
        'value': this.state.gm_text,
        'key': '516'
    }) : null, this.state.display_content === 'gi' ? React.createElement(RichTextViewer, {
        'className': 'm-t-10 m-b-10 text-left  c-dark',
        'value': this.state.gi_text,
        'key': '681'
    }) : null));
}