let initialState = {
    treatment_template_list: [],
    paginate_info:{page:0},
    treatment_template: {},
    treatment_template_supporting_data: {},
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "TREATMENT_TEMPLATE_LIST_FILL":
            if (!action.treatment_template_list){
                return state;
            }
            let newTreatmentTemplateList = [];
            if(action.paginate_info.page === 0){
                newTreatmentTemplateList = [ ...action.treatment_template_list];
            }else{
                newTreatmentTemplateList = [...state.treatment_template_list, ...action.treatment_template_list];
            }

            return Object.assign({}, state, {
                treatment_template_list: newTreatmentTemplateList,
                paginate_info:action.paginate_info
            });
        case "TREATMENT_TEMPLATE_TOGGLE_VISIBILITY":
            if (!action.treatment_template){
                return state;
            }
            let treatment_template_list = [...state.treatment_template_list];
            treatment_template_list = _.filter(treatment_template_list,(item)=>{
                if(item.id === action.treatment_template.id){
                    return false;
                }else{
                    return true;
                }
            });
            return Object.assign({}, state, {
                treatment_template_list: treatment_template_list
            });
        case "TREATMENT_TEMPLATE_GET_FILL":
            if (!action.treatment_template){
                return state;
            }
            return Object.assign({}, state, {
                treatment_template: action.treatment_template,
                treatment_template_supporting_data: action.treatment_template_supporting_data
            });
        case "TREATMENT_TEMPLATE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
