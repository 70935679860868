import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/program_container";
import BaseComponent from '../../utils/common_components/basecomponent';
import {history} from "../../redux/store";
import actionCreator from '../../redux/program/actions/program';
import Utils from '../../utils/utils';
import ProgramEdit from './program_edit';
import ProgramList from './program_list';


class ProgramContainer extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.pushToIndex = this.pushToIndex.bind(this);
        this.updateProgramType = this.updateProgramType.bind(this);
        this.returnProgramEdit = this.returnProgramEdit.bind(this);
        this.returnProgramList = this.returnProgramList.bind(this);
    };

    pushToIndex(){
        let program_type = this.props.match.params.program_type;
        let urlPrefix = Utils.getProgramUrlPrefix(program_type,this.props.selectedRole);
        if(this.props.location.pathname === urlPrefix){
            history.push(urlPrefix+"/list");
        }
    }

    returnProgramEdit(routeProps){
        let props = {...this.props};
        let updatedMatch = {...props.match,...routeProps.match};
        props.match = updatedMatch;
        return (
            <ProgramEdit {...props}  /> 
        );
    }
    returnProgramList(routeProps){
        let props = {...this.props};
        let updatedMatch = {...props.match,...routeProps.match};
        props.match = updatedMatch;
        return (
            <ProgramList {...props}  /> 
        );
    }

    componentWillMount() {
        this.pushToIndex();
        this.updateProgramType(this.props.match.params.program_type);
        if (this.props.match.params.workout_target_type) {
            this.props.setWorkoutTarget(this.props.match.params.workout_target_type, this.props.match.params.workout_target_id);            
        }
        if (this.props.match.params.mode) {
            this.props.setMode(this.props.match.params.mode);
        }
    }

    updateProgramType(type) {
        this.props.setProgramType(
            type.charAt(0).toUpperCase() + type.substring(1),
            type,
            type === 'template' ? 0 : 1,
            type,
        );
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.program_type !== nextProps.match.params.program_type) {
            this.updateProgramType(nextProps.match.params.program_type);
        }
        if (this.props.match.params.workout_target_type !== nextProps.match.params.workout_target_type || 
            this.props.match.params.workout_target_id !== nextProps.match.params.workout_target_id) {
            this.props.setWorkoutTarget(nextProps.match.params.workout_target_type, nextProps.match.params.workout_target_id);
        }
        if (this.props.match.params.mode !== nextProps.match.params.mode) {
            this.props.setMode(nextProps.match.params.mode);
        }
    }

    componentDidUpdate (prevProps, prevState) {
        this.pushToIndex();
    }

    render() {
          return Template.apply(this);  
    }
 
    componentWillUnmount() {
        this.props.cleanModule();
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setProgramType: actionCreator.setProgramType,
        cleanModule: actionCreator.cleanModule,
        setMode: actionCreator.setMode,
        setWorkoutTarget: actionCreator.setWorkoutTarget,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    let program = state.program.programEdit.program;
    if (!_.isEmpty(program)) {
        program = { ...program };
        if (program.start_date) {
            program.start_date = Utils.parseServerDate(program.start_date);
        }
    }
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        typeUCase: state.program.program.typeUCase,
        typeLCase: state.program.program.typeLCase,
        typeParam: state.program.program.typeParam,
        programType: state.program.program.programType,
        mode: state.program.program.mode,
        workoutTargetType: state.program.program.workoutTargetType,
        engagement_id: state.program.program.engagement_id,
        group_id: state.program.program.group_id,
        program: program,
        ...ownProps,
    };
}
const ProgramContainerConnected = connect(mapStateToProps, mapDispatchToProps)(ProgramContainer);
export default ProgramContainerConnected;
