// Action Creators
const treatmentTemplateListFill = (treatment_template_list,paginate_info) => ({
    type: 'TREATMENT_TEMPLATE_LIST_FILL',
    treatment_template_list: treatment_template_list,
    paginate_info: paginate_info,
});

const treatmentTemplateGetFill = (treatment_template, treatment_template_supporting_data) => ({
    type: 'TREATMENT_TEMPLATE_GET_FILL',
    treatment_template: treatment_template,
    treatment_template_supporting_data: treatment_template_supporting_data,
});

const treatmentTemplateCleanup = () => ({
    type: 'TREATMENT_TEMPLATE_CLEANUP',
});

const fillHabitList = (habits) => ({
    type: 'TREATMENT_TEMPLATE_HABIT_LIST_FILL',
    habits
});

const fillHabit = (habit, supportingData) => ({
    type: 'TREATMENT_TEMPLATE_HABIT_FILL',
    habit,
    supportingData,
});

const fillWorkoutList = (workouts) => ({
    type: 'TREATMENT_TEMPLATE_WORKOUT_LIST_FILL',
    workouts
});

const nutritionDocumentsFill = (documents) => ({
    type: 'TREATMENT_TEMPLATE_DOCUMENTS_NUTRITION_FILL',
    documents
});

const guidanceDocumentsFill = (documents) => ({
    type: 'TREATMENT_TEMPLATE_DOCUMENTS_GUIDANCE_FILL',
    documents
});
const toggleTreatmentTemplateVisibility = (treatment_template) => ({
    type: 'TREATMENT_TEMPLATE_TOGGLE_VISIBILITY',
    treatment_template
});

export default {
    treatmentTemplateListFill,
    treatmentTemplateGetFill,
    treatmentTemplateCleanup,
    fillHabitList,
    fillWorkoutList,
    nutritionDocumentsFill,
    guidanceDocumentsFill,
    fillHabit,
    toggleTreatmentTemplateVisibility
};
