import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm,formValueSelector, change } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/courses_content.rt";
import store, { history} from '../../redux/store';
import CourseActionCreator from '../../redux/courses/actions/courses_list'
import Swal from 'sweetalert2';
import {listCourses, deleteCourse} from '../../redux/courses/network/courses';

class EducationContent extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.deleteCourse = this.deleteCourse.bind(this);
        this.loadCourses = this.loadCourses.bind(this);
        this.addEducationContent = this.addEducationContent.bind(this);
        this.editEducationContent = this.editEducationContent.bind(this);
    }

    componentWillMount() {
        this.loadCourses()
    }

    loadCourses(){
        this.props.location.search.split('=')
        // if(this.props.location.search.split('=')[1] === this.props.course_type) {
            let params = { 
                "package_version": "2",
                  "app_version": "5.2",
                  "page":0,
                  "course_type": this.props.location.search.split('=')[1] === this.props.course_type ? this.props.course_type : 'certification',
            }
            this.props.listCourses(params);
        // }
    }

    // deleteCourse(id){
    //     this.props.deleteCourse({id: id })
    // }
    deleteCourse(id){
        let that = this;
        // let display_text = "";
        // if(toggle_visibility === "hide") {
        //     display_text = `Are you sure you want to hide this ${this.props.course_type}`;
        // } else {
        //     display_text = `Are you sure you want to unhide this ${this.props.course_type}`;
        // }
        let display_text = `Are you sure you want to delete this ${this.props.course_type}`;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (willDelete) {
            console.log("willDelete",willDelete);
            if (willDelete.value) {
                that.props.deleteCourse({
                    "id": id,
                }).then(()=>{this.loadCourses()
                }).catch((err)=>{console.log(err)});
            }
        }, function (dismiss) {
            console.log('log')
        });
    }

    componentWillUnmount() {     
        this.props.cleanModule();
    }

    addEducationContent() { 
        history.push("/practice/"+this.props.selectedRole.practice.id+"/education/"+this.props.course_type+"/add");
    }   
    editEducationContent(course_id) { 
        history.push("/practice/"+this.props.selectedRole.practice.id+"/education/"+this.props.course_type+"/edit/"+course_id);
    }

    render() {
        return Template.apply(this);
    }
}

const selector = formValueSelector('EducationFilterForm');

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        courseList : state.courses.courses.courseList,

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            listCourses,
            deleteCourse,
            cleanModule: CourseActionCreator.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'category',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const EducationListFilterForm = reduxForm(
    {
        form: 'EducationFilterForm',
        enableReinitialize: true,
        validate
    }
)(EducationContent);

const EducationContentConnected = connect(mapStateToProps,mapDispatchToProps)(EducationListFilterForm);
export default EducationContentConnected;
