import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/group_edit";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';
import { getGroup, saveGroup, } from '../../redux/group/network/group_network_new';
import GroupActionCreator from '../../redux/group/actions/group_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';
import {homefitContentSearch, getCategoryHomeFit } from '../../redux/content/network/homefit_content';
import { getAuthorList } from '../../redux/partner/network/partner_network';

class GroupEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSubmit = this.onSubmit.bind(this);
        this.hideGroup = this.hideGroup.bind(this);
        this.removeContentItem = this.removeContentItem.bind(this);
        this.deleteLogo = this.deleteLogo.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.uploadThumbnailImage = this.uploadThumbnailImage.bind(this);
        this.holdImage = this.holdImage.bind(this);
        this.onVideoUploaded = this.onVideoUploaded.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.selectTrainer = this.selectTrainer.bind(this);
        this.changeCoach = this.changeCoach.bind(this);
        this.state = {
            publish: false,
            treatment_cycle:[
                {value:1,label:'Scheduled'},
                {value:2,label:'Ongoing'},
            ],
            holdImage:{},
            selectedTrainer: null,
            changeCoach: false,
        };
    };

    changeCoach(){
        this.setState({
            changeCoach: true
        })
    }
    selectTrainer(value){
        this.setState({
            selectedTrainer: value
        })
    }
    removeContentItem(id){
        let items = this.props.associated_content.filter(item => item.id !== id)
        this.props.change('associated_content', items);
    }


    deleteVideo() {
        this.props.change('video_guid', null);
        this.props.change('video_thumbnail_url', null);
        this.props.change('duration', 0);
    }

    
    onVideoUploaded(uploadResult) {
        this.props.change('video_guid', uploadResult.guid);
        this.props.change('video_thumbnail_url', uploadResult.thumbnail);
        this.props.change('duration', uploadResult.duration);
    }

    onVideoUploadFailed() {
        Utils.showErrorAlert("Video upload failed! Please try again.");
    }

    componentWillMount() {
        this.props.getGroup({ 
            id: this.props.match.params.group_id,
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "3",
            "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
         }).then(()=>{
             this.props.homefitContentSearch({
                app_version: "5.2",
                current_role_type: "practice_admin",
                package_version: "2",
                practice_id: this.props.user.id===1599 ? 276 : this.props.practice_id
             })
             this.props.getCategoryHomeFit({
                app_version: "5.2",
                current_role_type: "practice_admin",
                device_type: "web",
                package_version: "2",
                practice_id: this.props.practice_id
            });
         })
         this.props.getAuthorList({
            "app_version": "5.2",
            "practice_id": 276,
            "current_role_type": "practice_admin",
            package_version: "2"
        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    holdImage(files){
        this.setState({
            holdImage:files
        });
        this.uploadThumbnailImage(files)
        .then((result)=>{
            this.props.change('thumbnail_url', result.url);
        })
    }

    deleteholdImage(){
        this.setState({
            holdImage:{}
        });
    }

    uploadThumbnailImage(files) {
        let formData = new FormData();
        formData.append("files", files[0]);
        return this.props.uploadFiles(formData).then((resp)=>{
            return resp.upload_results[0];
        }).catch(()=>{ });
    }

    save(publish) {
        this.setState({
            publish: publish
        }, () => {
            this.props.handleSubmit(this.onSubmit)();
        });
    }

    onSubmit(data) {
        if(Object.keys(this.state.holdImage).length){
            this.uploadThumbnailImage(this.state.holdImage).then((res)=>{
                if(res.url){
                    let params = _.omit(data, ['can_be_deleted']);
                    params = Utils.flatternFormData(params);
                    params.thumbnail_url = res.url;
                    params.media_items = [{
                        "is_cover_image": true,
                        "guid": this.props.video_guid,
                        "thumbnail": this.props.video_thumbnail_url,
                        "filename": "",
                        "duration": this.props.duration
                    }]
                    params['trainer'] = this.state.selectedTrainer ? this.state.selectedTrainer : this.props.group.trainer ? this.props.group.trainer.id : null;
                    this.props.saveGroup({
                        ...params,
                        app_version: "5.1.12",
                        current_role_type: "practitioner",
                        package_version: "3",
                        "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
                    }).then((response) => {
                        history.push('/practice/'+this.props.selectedRole.practice.id+'/group/dashboard/'+response.group.id);
                    }).catch(() => {
                    });

                }else{
                    Utils.showErrorAlert("Upload error");

                }
            }); 
        }else if(this.props.group.thumbnail_url || this.props.group.logo_url){
            let params = Utils.flatternFormData(data);
            params.media_items = [{
                "is_cover_image": true,
                "guid": this.props.video_guid,
                "thumbnail": this.props.video_thumbnail_url,
                "filename": "",
                "duration": this.props.duration
            }]
            params['trainer'] = this.state.selectedTrainer ? this.state.selectedTrainer : this.props.group.trainer ? this.props.group.trainer.id : null;
            this.props.saveGroup({
                params,
                ...params,
                app_version: "5.1.12",
                current_role_type: "practitioner",
                "practice_id": this.props.user.id===1599 ? 276 : this.props.practice_id,
                package_version: "3",}).then((response) => {
                history.push('/practice/'+this.props.selectedRole.practice.id+'/group/dashboard/'+response.group.id);
            }).catch(() => {
            });

        }else{

            Utils.showErrorAlert("Please select a thumbnail image");
        }

    }

    uploadLogo(files) {
        let formData = new FormData();
        formData.append('guid', this.props.guid);
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('logo_url', result.url);
            this.props.change('is_logo_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
        }).catch((error) => {
        });
    }

    deleteLogo() {
        this.props.change('is_logo_uploaded', false);
    }

    cancel() {
        history.goBack();
    }

    hideGroup(groupId) {

    }

    render() {
        if (_.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('groupEditForm');
function mapStateToProps(state) {
    let group = {...state.group.groupReducer.group};
    let selectedAssociatedContentsHomeFit =  selector(state, 'associated_content')
    let HomefitContents = state.content.content.homeFitContentList;
    let displayAssociatedContentsHomeFit =[];
    if(selectedAssociatedContentsHomeFit && Object.keys(selectedAssociatedContentsHomeFit).length){
        selectedAssociatedContentsHomeFit.forEach((selectedAssociatedContent,index) => {
            if(typeof(selectedAssociatedContent)  === "object"){
                displayAssociatedContentsHomeFit.push(selectedAssociatedContent)
            }else{
                displayAssociatedContentsHomeFit.push( HomefitContents.find(content => content.id == selectedAssociatedContent))
            }
        })
    }
    return {
        practice_partners:state.partner.partnerReducer.practice_partners,
        authors:state.partner.partnerReducer.authors,
        categories: state.content.content.categories,
        homefitAssociatedContentOptions: state.content.content.homeFitContentList ,
        associated_content: displayAssociatedContentsHomeFit,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        group: group,
        initialValues: group,
        is_logo_uploaded: selector(state, 'is_logo_uploaded'),
        is_thumbnail_uploaded: selector(state, 'is_thumbnail_uploaded'),
        logo_url: selector(state, 'logo_url'),
        guid: selector(state, 'guid'),
        video_thumbnail_url: selector(state, 'video_thumbnail_url'),
        video_guid: selector(state, "video_guid"),
        thumbnail_url: selector(state, "thumbnail_url"),
        duration: selector(state, "duration"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGroup,
        getAuthorList,
        saveGroup,
        homefitContentSearch,
        cleanUp: GroupActionCreator.groupCleanup,
        uploadFiles,
        getCategoryHomeFit
    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const GroupEditForm = reduxForm(
    {
        form: 'groupEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupEdit);
const GroupEditFormConnected = connect(mapStateToProps, mapDispatchToProps)(GroupEditForm);
export default GroupEditFormConnected;
