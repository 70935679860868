import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/engagement.rt";
import GITemplate from "./templates/gi_engagement.rt";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getEngagementInstance,
    deleteIntakeToEngagement,
    updateEngagementStatus,
    addGoalEngagement,
    deleteEngagementAssessment,
    deleteHabit,
    createEngagementHabitFromTemplate,
    publishStatusToggleHealthDocument
} from '../../redux/engagement/network/engagement_network';
import EnagagementAction from '../../redux/engagement/actions/engagement_creator';
import swal from 'sweetalert2';
import { listWorkouts } from '../../redux/engagement/network/engagement_workout';
import workoutActions from '../../redux/engagement/actions/engagement_workout';
import programActions from '../../redux/program/actions/program';
import {
    deleteWorkout, workoutOrderUpdate, workoutVersionUpgrade, workoutPdfGenerate,
    nonScheduledWorkoutMarkComplete
} from '../../redux/program/network/program_list';
import healthDocumentActions from '../../redux/engagement/actions/health_document';
import {
    listGuidanceDocuments, listNutritionDocuments, postHealthDocument, deleteHealthDocument
} from '../../redux/engagement/network/health_document';
import { getHabitTemplateList } from '../../redux/habit/network/habit_network';
import { getGoalList } from '../../redux/goal/network/goal_network';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import { parse } from 'query-string';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import {
    ENGAGEMENT_GOAL_VIDEO, ENGAGEMENT_ASSESSMENT_VIDEO, ENGAGEMENT_PROGRAM_VIDEO, ENGAGEMENT_INTAKE_VIDEO
} from '../../utils/help_video';
import {PARTNER} from '../../core/constants/constants';
import { getPracticePaymentSubscriptionDetails } from '../../redux/payment/network/plan_subscribe_network'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment/moment';
import { CloseSharp, DeleteForever, Edit, InfoOutlined, PictureAsPdf, ReorderOutlined } from '@mui/icons-material';
import TooltipWrapper from '../../utils/common_components/tooltipWrapper';

const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
//   padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "none",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightgrey" : "none",
    padding: grid,
    width: '100%',
    display:'flex',
    flexWrap: 'wrap'
  });

class Engagement extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.hideGoalModal = this.hideGoalModal.bind(this);
        this.hideHabitTemplateModal = this.hideHabitTemplateModal.bind(this);
        this.showGoalModal = this.showGoalModal.bind(this);
        this.addGoal = this.addGoal.bind(this);
        this.createEngagementHabitFromTemplate = this.createEngagementHabitFromTemplate.bind(this);
        this.addEmptyGoal = this.addEmptyGoal.bind(this);
        this.hideGoalIntervalModal = this.hideGoalIntervalModal.bind(this);
        this.showGoalIntervalModal = this.showGoalIntervalModal.bind(this);


        this.showAssignIntakeForm = this.showAssignIntakeForm.bind(this);
        this.deleteIntake = this.deleteIntake.bind(this);

        this.addAssessment = this.addAssessment.bind(this);
        this.editAssessment = this.editAssessment.bind(this);
        this.deleteAssessment = this.deleteAssessment.bind(this);


        this.hidePaymentModal = this.hidePaymentModal.bind(this);
        this.showPaymentModal = this.showPaymentModal.bind(this);

        this.hideAppointmentModal = this.hideAppointmentModal.bind(this);
        this.showAppointmentModal = this.showAppointmentModal.bind(this);

        this.hidePreviewIntakeModal = this.hidePreviewIntakeModal.bind(this);
        this.showPreviewIntakeModal = this.showPreviewIntakeModal.bind(this);

        this.addWorkout = this.addWorkout.bind(this);
        this.editWorkout = this.editWorkout.bind(this);
        this.doWorkoutPdf = this.doWorkoutPdf.bind(this);
        this.deleteWorkout = this.deleteWorkout.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleTreatmentTabChange = this.handleTreatmentTabChange.bind(this);
        this.handleWorkoutSort = this.handleWorkoutSort.bind(this);

        this.updateEngagementStatus = this.updateEngagementStatus.bind(this);
        this.toggleDocumentSelectionDialog = this.toggleDocumentSelectionDialog.bind(this);
        this.assignHealthDocument = this.assignHealthDocument.bind(this);
        this.unAssignHealthDocument = this.unAssignHealthDocument.bind(this);
        this.allocateTreatmentSpace = this.allocateTreatmentSpace.bind(this);
        this.unAllocateSpace = this.unAllocateSpace.bind(this);
        this.finishEditingWorkout = this.finishEditingWorkout.bind(this);
        this.goToGoalEdit = this.goToGoalEdit.bind(this);
        this.afterCreateWorkoutFromTemplate = this.afterCreateWorkoutFromTemplate.bind(this);
        this.allocateAssessmentSpace = this.allocateAssessmentSpace.bind(this);
        this.viewClientProfile = this.viewClientProfile.bind(this);

        this.hideChatModal = this.hideChatModal.bind(this);
        this.showChatModal = this.showChatModal.bind(this);

        this.addHabit = this.addHabit.bind(this);
        this.editHabit = this.editHabit.bind(this);
        this.deleteHabit = this.deleteHabit.bind(this);
        this.markWorkoutComplete = this.markWorkoutComplete.bind(this);
        this.hideTreatmentTemplateSelection = this.hideTreatmentTemplateSelection.bind(this);
        this.createTreatmentFromTemplate = this.createTreatmentFromTemplate.bind(this);

        this.healthDocumentPublishStatusToggle = this.healthDocumentPublishStatusToggle.bind(this);
        this.goToClient = this.goToClient.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.renderWorkoutList = this.renderWorkoutList.bind(this);
        this.state = {
            tabIndex: 0,
            treatmentTabIndex: 0,

            openGoalModal: false,
            openGoalIntervalModal: false,

            openAssignIntakeModal: false,
            openPreviewIntakeModal: false,
            intakeSpaceAllocated: false,
            previewIntake: {},

            openAssessmentSummaryModal: false,
            assessmentSpaceAllocated: false,
            editingAssessmentId: null,

            openPaymentModal: false,

            openAppointmentModal: false,

            openChatModal: false,

            habitSpaceAllocated: false,
            editingHabitId: null,

            documentSelectionDialogVisible: false,
            healthDocumentType: null,
            editingWorkoutId: null,
            treatmentSpaceAllocated: false,
            workoutPreviewMode: false,
            programTypeParams: Utils.getProgramTypeParams('workout'),
            showGoalEdit: false,
            workoutProgramTypeParams: Utils.getProgramTypeParams('workout'),
            templateProgramTypeParams: Utils.getProgramTypeParams('template'),
            showTemplateSelection: false,
            showWorkoutEditForm: false,
            showTreatmentTemplates:false,
            openHabitTemplateModal:false,
            user_subscription_active: false,
            isTemplatesPurchased: false,
            items: getItems(10)
        };
        this.functions = {
            deleteWorkout: this.deleteWorkout,
        };
    };
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          this.state.items,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items
        });
      }
    renderWorkoutList(){
        return (
            <div>
        <DragDropContext onDragEnd={this.handleWorkoutSort}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.workouts.map((workout,index)=>(
                <Draggable key={String(workout.id)} draggableId={String(workout.id)} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {/* {item.content} */}
                    {/* <Sortable class="vertical-container d-flex flex-wrap" onSort={this.handleWorkoutSort} containment={true} dynamic={true}> */}
                                <div class="width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container">
                                    {
                                        !workout.published ? 
                                            <div class="bg-warning ribbon f-12 m-t-18" rt-if="!workout.published">
                                                Draft
                                            </div> : null
                                    }
                                    {
                                        workout.published &workout.is_being_used && !workout.is_completed ? 
                                            <div class="bg-published ribbon f-12 m-t-18" rt-if="workout.published &workout.is_being_used && !workout.is_completed">
                                            Active
                                            </div> : null
                                    }
                                    {
                                        workout.published & !workout.is_being_used ? 
                                        <div class="bg-published ribbon f-12 m-t-18" rt-if="workout.published &!workout.is_being_used">
                                            Future
                                        </div> :  workout.published &workout.is_completed ?                           
                                            <div class="bg-published ribbon f-12 m-t-18" rt-if="workout.published &workout.is_completed">
                                                Completed
                                            </div> : null
                                    }
                                    <div class="thumbnail box-250-150" style={{backgroundImage: `url(${workout.image_url})`}} />
                                    <div class="row bg-white start-xs middle-xs m-0 m-t-8 p-l-5 d-flex flex-align-center flex-sb" style={{height: "80px"}}>
                                        <div class="w-100 d-flex flex-align-center flex-sb">
                                            <TooltipWrapper class="col-xs-{workout.description ? '10': '12'} f-16 truncate" tooltip={workout.name}>
                                                {workout.name}
                                            </TooltipWrapper>
                                            {
                                                workout.description &&
                                                // <IconButton class="col-xs-2 c-black text-right" rt-if="workout.description" tooltip="{workout.description}" icon="info" />
                                                <div class="m-r-6 c-black small-icon-button handle" style={{lineHeight:'0px', cursor: 'pointer'}} onClick={()=>{this.deleteWorkout(workout)}}>
                                                    <Tooltip title={workout.description}>
                                                        <InfoOutlined fontSize='20px'/>
                                                    </Tooltip>
                                                </div>
                                            }
                                        </div>
                                        <div class="col-xs-12 f-12 f-300 m-b-2">
                                            Practitioner: {workout.practitioner?.name}
                                        </div>
                                        {
                                            workout.workout_start_date && 
                                            <div class="col-xs-12 f-12 f-300 m-b-2" rt-if="workout.workout_start_date">
                                                Start date: {moment(workout.workout_start_date).format('MMMM Do YYYY')}
                                            </div>
                                        }
                                        {
                                            workout.start_date && workout.workout_start_date !== workout.start_date ? 
                                            <div class="col-xs-12 f-12 f-300" rt-if="workout.start_date && workout.workout_start_date !== workout.start_date">
                                                Last revision date: {moment(workout.start_date).format('MMMM Do YYYY')}
                                            </div> :null

                                        }
                                    </div>
                                    <div class="client-workout-list row center-xs m-0 m-t-5 p-b-10">
                                        <div class="col-xs-12 p-2 m-0 d-flex justify-center">
                                            <div class="m-r-6 c-black small-icon-button round-border handle" onClick={()=>{this.editWorkout(workout)}}>
                                                <Tooltip title="Edit Workout">
                                                    <Edit sx={{fontSize:'16px'}}/> 
                                                </Tooltip>
                                            </div>
                                            <div class="m-r-6 c-black small-icon-button round-border handle" onClick={()=>{this.deleteWorkout(workout)}}>
                                                <Tooltip title="Delete Workout">
                                                    <DeleteForever sx={{fontSize:'16px'}}/> 
                                                </Tooltip>
                                            </div>
                                            <div class="m-r-6 c-black small-icon-button round-border handle" onClick={()=>{this.doWorkoutPdf(workout)}}>
                                                <Tooltip title="View PDF">
                                                    <PictureAsPdf sx={{fontSize:'16px'}}/> 
                                                </Tooltip>
                                            </div>
                                            <div {...provided.dragHandleProps} class="m-r-6 c-black small-icon-button round-border handle">
                                                <Tooltip title="Re-Order Workout">
                                                    <ReorderOutlined sx={{fontSize:'16px'}}/> 
                                                </Tooltip>
                                            </div>
                                            {/* <IconButton icon="reorder" style={{cursor: "move"}} tooltip="Re-Order Workout" 
                                            /> */}
                                            {
                                                workout.is_being_used && !workout.is_completed && workout.schedule_type === 2 ? 
                                                <div class="m-r-6 c-black small-icon-button round-border handle" onClick={()=>{this.markWorkoutComplete(workout)}}>
                                                    <Tooltip title="Mark workout as completed">
                                                        <CloseSharp sx={{fontSize:'16px'}}/> 
                                                    </Tooltip>
                                                </div>: null
                                            }
                                        </div>
                                    </div>
                                </div>

                    {/* </Sortable> */}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
            </div>
        )
    }
    goToClient(){
        history.push(`/practice/${this.props.selectedRole.practice.id}/client`)
    }
    componentWillMount() {
        if (!this.props.match.params.engagement_id) {
            history.goBack();
        } else {
            this.props.cleanUp();
            this.fetchEnagementDetails();
        }        
    }

    componentDidMount() {
        if(this.props.location.search !== "" ){
            let searchParams = parse(this.props.location.search);
            if(_.has(searchParams,"tab")){
                let tab = parseInt(searchParams.tab);
                this.setState({ tabIndex: tab });
                if(PARTNER.app_type === "gi") {
                    if (tab === 1) {
                        this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id });  
                    }
                } else {
                    if (tab === 3) {
                        this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id });  
                    }
                }
            }
            if(_.has(searchParams,"workout")){
                let workout_id = parseInt(searchParams.workout);
                this.openWorkoutEdit(workout_id);
            }
            history.replace(this.props.location.pathname);
        }
        this.props.updateHelpVideo(ENGAGEMENT_GOAL_VIDEO);
    }
    

    fetchEnagementDetails() {
        this.props.getEngagementInstance({ 'id': this.props.match.params.engagement_id });
    }


    allocateTreatmentSpace() {
        this.setState({
            treatmentSpaceAllocated: true,
        });
    }

    allocateAssessmentSpace() {
        this.setState({
            assessmentSpaceAllocated: true,
        });
    }

    viewClientProfile() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/client/dashboard/" + this.props.engagement.client.id);
    }

    finishEditingWorkout() {
        this.unAllocateSpace();
        this.setState({
            editingWorkoutId: null,
            showWorkoutEditForm: false,
        });
        this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id }); 
        
    }

    unAllocateSpace(doRefresh = false) {
        if (doRefresh) {
            this.fetchEnagementDetails();
        }
        this.setState({
            treatmentSpaceAllocated: false,
            assessmentSpaceAllocated: false,
            showGoalEdit: false,
            intakeSpaceAllocated: false,
            habitSpaceAllocated: false,
        });
    }

    assignHealthDocument(document) {
        this.props.postHealthDocument({
            engagement_id: this.props.match.params.engagement_id,
            health_document_id: document.id,
            document_type: this.state.healthDocumentType,
            package_version: 4
        }).then(() => {
            this.props.publishStatusToggleHealthDocument({
                engagement_id: this.props.match.params.engagement_id,
                health_document_id: document.id,
            })
            this.setState({
                documentSelectionDialogVisible: false,
            });
            if (this.state.healthDocumentType === 'nutrition') {
                this.props.listNutritionDocuments({ engagement_id: this.props.match.params.engagement_id });
            } else {
                this.props.listGuidanceDocuments({ engagement_id: this.props.match.params.engagement_id });
            }
        }).catch(() => { });
    }

    unAssignHealthDocument(document) {
        this.props.deleteHealthDocument({
            engagement_id: this.props.match.params.engagement_id,
            health_document_id: document.id,
        }).then(() => {
            if (document.document_type === 1) {
                this.props.listNutritionDocuments({ engagement_id: this.props.match.params.engagement_id });
            } else {
                this.props.listGuidanceDocuments({ engagement_id: this.props.match.params.engagement_id });
            }
        }).catch(() => { });
    }

    toggleDocumentSelectionDialog(documentType) {
        if(documentType === undefined){
            this.setState({
                documentSelectionDialogVisible: false,
                healthDocumentType: null,
            });
            return;    
        }
        let that = this;
        if(!this.props.engagement.engagement_template_exists){
            this.setState({
                documentSelectionDialogVisible: !this.state.documentSelectionDialogVisible,
                healthDocumentType: documentType ? documentType : this.state.healthDocumentType,
            });
        }else{
            swal.fire({
                type: 'info',
                title: 'Select an option',
                showConfirmButton: false,
                showCloseButton: true,
                text: 'How would you like to create '+documentType+' document ?',
                footer: '<div class="d-flex flex-column"><button class="action1 swal2-styled  bg-success">Create From Game Plan Template</button><button class="action2 bg-primary swal2-styled">Choose from Content Library</button></div>',
                useRejections:true
            });
            $(".swal2-container").on('click','.action1',()=>{
                swal.close();
                that.createTreatmentFromTemplate();
            });
            $(".swal2-container").on('click','.action2',()=>{
                swal.close();
                that.setState({
                    documentSelectionDialogVisible: !that.state.documentSelectionDialogVisible,
                    healthDocumentType: documentType ? documentType : that.state.healthDocumentType,
                });
            });
        }    
    }

    handleTabChange(tabIndex) {
        this.props.clearHelpVideo();
        if(PARTNER.app_type === "gi") {
            if (tabIndex === 3) {                
                this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id });
                this.props.listGuidanceDocuments({ engagement_id: this.props.match.params.engagement_id });
                this.props.listNutritionDocuments({ engagement_id: this.props.match.params.engagement_id });
                if(!this.props.user.gi_isadmin){
                    this.props.getPracticePaymentSubscriptionDetails({
                    "package_version": "3", 
                    "app_version": "5.4", 
                    "current_role_type": "practice_admin", 
                    "practice_id": this.props.practice_id
                }).then((res)=>{
                    this.setState({
                        user_subscription_active : res.data.user_subscription_active,
                        isTemplatesPurchased : res.data.isTemplatesPurchased
                    })
                })                
                }
            }
        } else {
            if (tabIndex === 0) {
                this.props.updateHelpVideo(ENGAGEMENT_GOAL_VIDEO);
            } else if (tabIndex === 1) {
                this.props.updateHelpVideo(ENGAGEMENT_INTAKE_VIDEO);
            } else if (tabIndex === 2) {
                this.props.updateHelpVideo(ENGAGEMENT_ASSESSMENT_VIDEO);
            } else if (tabIndex === 3) {
                this.props.updateHelpVideo(ENGAGEMENT_PROGRAM_VIDEO);
                this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id });
                this.props.listGuidanceDocuments({ engagement_id: this.props.match.params.engagement_id });
                this.props.listNutritionDocuments({ engagement_id: this.props.match.params.engagement_id });
            }
        }
        this.setState({ tabIndex });
        this.unAllocateSpace();
    }
    
    handleTreatmentTabChange(treatmentTabIndex) {
        this.setState({ treatmentTabIndex });
    }

    afterCreateWorkoutFromTemplate(workout_id) {
        this.setState({
            showTemplateSelection: false,
            showWorkoutEditForm: true,
            editingWorkoutId: workout_id,
        });
    }

    createTreatmentFromTemplate(){
        this.allocateTreatmentSpace();
        this.setState({
            showTreatmentTemplates:true,
            showTemplateSelection: false,
        });
    }

    hideTreatmentTemplateSelection(event,treatment_created=null){
        if(treatment_created !== null){
            this.fetchEnagementDetails();
            this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id });
            this.props.listGuidanceDocuments({ engagement_id: this.props.match.params.engagement_id });
            this.props.listNutritionDocuments({ engagement_id: this.props.match.params.engagement_id });
        }  
        this.setState({showTreatmentTemplates:false});   
        this.unAllocateSpace(); 
    }


    addWorkout() {
        let that = this;
        if(PARTNER.app_type === "gi") {
            swal.fire({
                type: 'info',
                title: 'Select an option',
                showConfirmButton: false,
                showCloseButton: true,
                text: 'How would you like to create Workout?',
                footer: '<div class="d-flex flex-column"><button class="action1 swal2-styled  bg-success">Create From Game Plan Template</button><button class="action2 bg-primary swal2-styled">Create From Workout Template</button><button class="action3 bg-accent swal2-styled">Create New</button></div>',
                useRejections:true
            });
            $(".swal2-container").on('click','.action1',()=>{
                that.createTreatmentFromTemplate();
                that.setState({
                    showTemplateSelection: false,
                })
                swal.close();
            });
            $(".swal2-container").on('click','.action2',()=>{
                that.allocateTreatmentSpace();
                that.setState({
                    showTemplateSelection: true,
                    showTreatmentTemplates: false,
                    showWorkoutEditForm: false,
                    workoutPreviewMode: false,
                    editingWorkoutId: null,
                });
                swal.close();
            });
            $(".swal2-container").on('click','.action3',()=>{
                that.setState({
                    editingWorkoutId: null,
                    showWorkoutEditForm: true,
                    showTemplateSelection: false,
                    workoutPreviewMode: false,
                }, () => {
                    that.allocateTreatmentSpace();
                    swal.close();
                });
            });
        } else {
            swal.fire({
                type: 'info',
                title: 'Select an option',
                showConfirmButton: false,
                showCloseButton: true,
                text: 'How would you like to create Workout?',
                footer: '<div class="d-flex flex-column"><button class="action1 swal2-styled  bg-success">Create From Game Plan Template</button><button class="action2 bg-primary swal2-styled">Create From Workout Template</button><button class="action3 bg-accent swal2-styled">Create New</button></div>',
                useRejections:true
            });
            if(this.props.engagement.engagement_template_exists){
                $(".swal2-container .action1").show();
            }else{
                $(".swal2-container .action1").hide();
            }
            $(".swal2-container").on('click','.action1',()=>{
                that.createTreatmentFromTemplate();
                swal.close();
            });
            $(".swal2-container").on('click','.action2',()=>{
                that.allocateTreatmentSpace();
                that.setState({
                    showTemplateSelection: true,
                    showWorkoutEditForm: false,
                    workoutPreviewMode: false,
                    editingWorkoutId: null,
                });
                swal.close();
            });
            $(".swal2-container").on('click','.action3',()=>{
                that.setState({
                    editingWorkoutId: null,
                    showWorkoutEditForm: true,
                    showTemplateSelection: false,
                    workoutPreviewMode: false,
                }, () => {
                    that.allocateTreatmentSpace();
                    swal.close();
                });
            });
        }
    }

    doWorkoutPdf(workout) {
        this.props.workoutPdfGenerate({ id: workout.id }).then((response) => {
            swal.fire({
                title: '',
                type: 'success',
                html:
                '<a target="_blank" href="' + response["path"] + '">Click here to download the pdf</a> ',
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
                confirmButtonText: '',
                confirmButtonAriaLabel: '',
                useRejections:true
            });

        }).catch(() => { });
    }

    markWorkoutComplete(workout) {
        swal.fire({
            title: "Are you sure?",
            text: "Mark this workout as completed? This is irreversible!",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            this.props.nonScheduledWorkoutMarkComplete({ id: workout.id }).then(() => {
                this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id });
            }).catch(() => { });
        }, () => {});
    }

    openWorkoutEdit(workout_id,counter = 0) {
        let found_workout = false;
        setTimeout(()=>{
            for (let workout of this.props.workouts) {
                if(workout.id === workout_id){
                    this.editWorkout(workout);
                    found_workout = true;
                    break;
                }
            }  
            counter = counter + 1;
            if(counter < 8 && found_workout === false){
                this.openWorkoutEdit(workout_id,counter);
            }
        },1000);
    }

    editWorkout(workout) {
        let startEdit = (previewOnly) => {
            this.allocateTreatmentSpace();
            this.setState({
                editingWorkoutId: workout.id,
                showWorkoutEditForm: true,
                workoutPreviewMode: previewOnly,
            });
        };
        if (workout.is_being_used) {
            startEdit(false);
            // swal.fire({
            //     title: "Workout In Use",
            //     text: "You can either Preview Workout or Change its content and scheduled workout days will move forward. Choose one of the options from below",
            //     type: "question",
            //     showCancelButton: true,
            //     showCloseButton: true,
            //     confirmButtonColor: "#0292d3",
            //     cancelButtonColor: "#4C555C",
            //     confirmButtonText: "Edit Workout",
            //     cancelButtonText: "Preview Workout",
            // }).then(() => {
            //     this.props.workoutVersionUpgrade({ id: workout.id }).then(() => {
            //         startEdit(false);
            //     }).catch(() => { });
            // }, (dismiss) => {
            //     if (dismiss === 'cancel') {
            //         startEdit(true);
            //     }
            // });
        } else {
            startEdit(false);
        }
    }

    deleteWorkout(workout) {
        let that = this;
        let active = workout.is_being_used;
        swal.fire({
            title: "Are you sure?",
            text: (active ? "Workout is being used by your client. " : "") + "Are you sure to delete this workout?",
            type: active ? "warning" : "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: active ? "#f16767" : "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value) {
                that.props.deleteWorkout({ id: workout.id }).then(() => {
                    that.props.listWorkouts({ engagement_id: that.props.match.params.engagement_id });
                }).catch(() => { });
            }else {
                return
            }
        }, function (dismiss) {
        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
        this.props.cleanWorkouts();
        this.props.cleanHealthDocuments();
        this.props.clearHelpVideo();
    }

    addAssessment() {
        this.setState({
            editingAssessmentId: null,
        });
        this.allocateAssessmentSpace();
    }

    editAssessment(assessment) {
        this.setState({
            editingAssessmentId: assessment.id,
        });
        this.allocateAssessmentSpace();
    }

    hidePaymentModal() {
        this.setState({ openPaymentModal: false });
    }
    showPaymentModal() {
        this.setState({ openPaymentModal: true });
    }


    hideAppointmentModal() {
        this.setState({
            openAppointmentModal: false
        });
    }

    showAppointmentModal() {
        this.setState({
            openAppointmentModal: true
        });
    }


    goToGoalEdit() {
        this.setState({
            showGoalEdit: true,
            openGoalModal: false
        });
    }

    // All goal related functions
    showGoalModal() {
        this.props.goalCleanUp();
        if (this.props.engagement.goals.length === 0) {
            this.props.getGoalList({
                fetch_type: "practitioner_assign"
            }).then(() => {
                this.setState({ openGoalModal: true });
            }).catch((error) => {
                this.props.goalCleanUp();
            });
        } else {
            this.goToGoalEdit();
        }
    }

    hideGoalModal() {
        this.setState({ openGoalModal: false });
    }

    hideHabitTemplateModal() {
        this.setState({ openHabitTemplateModal: false });
    }

    addGoal(goal) {
        this.props.addGoalEngagement({
            engagement: this.props.engagement.id,
            goal_id: goal.id,
            package_version: 4
        }).then((response) => {
            this.fetchEnagementDetails();
            this.setState({ openGoalModal: false });
            this.goToGoalEdit();
        }).catch((error) => {
            //do nothing;
        });
    }

    createEngagementHabitFromTemplate(habit_template) {
        this.props.createEngagementHabitFromTemplate({
            engagement_id: this.props.engagement.id,
            habit_template_id: habit_template.id,
            package_version: 4
        }).then((response) => {
            this.editHabit({id:response.habit_id});
            this.setState({ openHabitTemplateModal: false });
        }).catch((error) => {
            //do nothing;
        });
    }

    addEmptyGoal() {
        this.props.goalCleanUp();
        this.setState({ openGoalModal: false });
        history.push("/practice/"+this.props.selectedRole.practice.id+"/client/engagement/" + this.props.engagement.id + "/goal");
    }

    showAssignIntakeForm() {
        this.setState({ 'intakeSpaceAllocated': true });
    }

    showPreviewIntakeModal(intake) {
        this.setState({ previewIntake: intake }, () => {
            this.setState({ openPreviewIntakeModal: true });
        });
    }

    hidePreviewIntakeModal() {
        this.setState({ openPreviewIntakeModal: false, previewIntake: {} });
    }

    deleteIntake(intake) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You are about delete an assigned Intake form permanently!",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (click) {
            if(click.value){
                that.props.deleteIntakeToEngagement({ engagement: that.props.engagement.id, id: intake.id }).then((reponse) => {
                    swal.close();
                }).catch((error) => {
                    swal.close();
                });
            }
            swal.close();
        }, function (dismiss) {

        });
    }


    deleteAssessment(assessment) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You are about delete an assessment option permanently!",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value) {
                that.props.deleteEngagementAssessment({
                    engagement: that.props.engagement.id,
                    assessment_id: assessment.id
                }).then((reponse) => {
                    swal.close();
                }).catch((error) => {
                    swal.close();
                });
            }
        }, function (dismiss) {

        });
    }

    hideGoalIntervalModal() {
        this.setState({ openGoalIntervalModal: false });
    }
    showGoalIntervalModal() {
        this.setState({ openGoalIntervalModal: true });
    }

    handleWorkoutSort(result) {
        if (!result.destination) {
            return;
        }
        let orders = [];
        const items = reorder(
            this.props.workouts,
            result.source.index,
            result.destination.index
          );
        items.map((workout,index) =>{
            // item.order = index +1 
            // let indexVal = 'exercises[' + index + "]";
            // this.props.change(indexVal, item);
            // return item     
            orders.push({
                id: workout.id,
                order: parseInt(index) + 1,
            });
        })
        this.props.workoutOrderUpdate({
            workout_orders: orders,
            engagement_id: this.props.match.params.engagement_id
        }).then(() => {
            this.props.listWorkouts({ engagement_id: this.props.match.params.engagement_id });
        }).catch(() => { });
    }

    updateEngagementStatus() {
        let that = this;
        if (this.props.engagement.is_active) {
            swal.fire({
                title: "Closure Reason!",
                text: "",
                input: 'text',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Submit",
                cancelButtonText: "Cancel",
                useRejections:true,
                inputValidator: function (value) {
                    return !value &&  (
                            'You need to write something!'
                    )
                }
            }).then(function (inputValue) {
                if(!inputValue.dismiss){
                    that.props.updateEngagementStatus({
                        'engagement_id': that.props.engagement.id,
                        'closure_reason': inputValue,
                        'is_active': false
                    }).then((value) => {
                        history.push("/practice/"+this.props.selectedRole.practice.id+"/client/dashboard/" + that.props.engagement.client.id);
                    }).catch(() => { });
                }
                swal.close();
            }, function (dismiss) {
            });
        } else {
            that.props.updateEngagementStatus({
                'engagement_id': that.props.engagement.id,
                'closure_reason': "",
                'is_active': true
            });
        }
    }

    hideChatModal() {
        this.setState({
            openChatModal: false,
        });
    }

    showChatModal() {
        this.setState({
            openChatModal: true,
        });
    }

    addHabit() {
        let that = this;
        swal.fire({
            type: 'info',
            title: 'Select an option',
            showConfirmButton: false,
            showCloseButton: true,
            text: 'How would you like to create Habit?',
            footer: '<div class="d-flex flex-column"><button class="action1 swal2-styled  bg-success">Create From Game Plan Template</button><button class="action2 bg-primary swal2-styled">Create From Habit Template</button><button class="action3 bg-accent swal2-styled">Create New</button></div>',
            useRejections:true
          });
          if(this.props.engagement.engagement_template_exists){
            $(".swal2-container .action1").show();
          }else{
            $(".swal2-container .action1").hide();
          }
          $(".swal2-container").on('click','.action1',()=>{
            that.createTreatmentFromTemplate();
            swal.close();
         });
          $(".swal2-container").on('click','.action2',()=>{
            that.props.getHabitTemplateList({
                show_published:true,
                show_partner_templates:true
            });
            that.setState({
                openHabitTemplateModal:true
            });
            swal.close();
         });
          $(".swal2-container").on('click','.action3',()=>{
            that.setState({
                editHabitId: null,
                habitSpaceAllocated: true,
            });
            swal.close();
         });
    }


    editHabit(habit) {
        this.setState({
            editHabitId: habit.id,
            habitSpaceAllocated: true,
        });
    }

    deleteHabit(habit) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You are about delete an assigned habit permanently!",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value) {
                that.props.deleteHabit({
                    engagement_id: that.props.engagement.id,
                    habit_id: habit.id
                }).then((reponse) => {
                    swal.close();
                }).catch((error) => {
                    swal.close();
                });
            }
        }, function (dismiss) {
        });
    }

    healthDocumentPublishStatusToggle(document) {
        this.props.publishStatusToggleHealthDocument({
            engagement_id: this.props.match.params.engagement_id,
            health_document_id: document.id,
        }).then(() => {
            if (document.document_type === 1) {
                this.props.listNutritionDocuments({ engagement_id: this.props.match.params.engagement_id });
            } else {
                this.props.listGuidanceDocuments({ engagement_id: this.props.match.params.engagement_id });
            }
        }).catch(() => { });
    }



    render() {
        if (_.isEmpty(this.props.engagement)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar type='circular' mode='indeterminate' multicolor />
            </div>);
        }
        if(PARTNER.app_type === "gi"){
            return Template.apply(this);
        } else {
            return Template.apply(this);
        }
    }

}

function mapStateToProps(state) {
    return {
        store,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        engagement: state.engagement.engagementReducer.engagement,
        workouts: state.engagement.engagementWorkout.workouts,
        nutritionDocuments: state.engagement.healthDocument.nutritionDocuments,
        guidanceDocuments: state.engagement.healthDocument.guidanceDocuments,
        goal_list: state.goal.goalReducer.goal_list,
        habit_template_list: state.habit.habitTemplateReducer.habit_template_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPracticePaymentSubscriptionDetails,
        getEngagementInstance,
        updateEngagementStatus,
        deleteIntakeToEngagement,
        getGoalList,
        cleanUp: EnagagementAction.engagementInstanceCleanup,
        listWorkouts,
        cleanWorkouts: workoutActions.cleanModule,
        setWorkoutMode: programActions.setWorkoutMode,
        setProgramMode: programActions.setMode,
        deleteWorkout,
        workoutOrderUpdate,
        listGuidanceDocuments,
        listNutritionDocuments,
        postHealthDocument,
        deleteHealthDocument,
        cleanHealthDocuments: healthDocumentActions.cleanModule,
        goalCleanUp: GoalActionCreator.goalCleanup,
        addGoalEngagement,
        workoutVersionUpgrade,
        workoutPdfGenerate,
        deleteEngagementAssessment,
        deleteHabit,
        nonScheduledWorkoutMarkComplete,
        getHabitTemplateList,
        createEngagementHabitFromTemplate,
        updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearHelpVideo: HelpVideoCreator.clearHelpVideo,
        publishStatusToggleHealthDocument,
    },
        dispatch
    );
}

const EngagementConnected = connect(mapStateToProps, mapDispatchToProps)(Engagement);
export default EngagementConnected;
