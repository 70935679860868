import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import Creatable from '../../../utils/common_components/creatable_select';
import Input from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import HelpTip from '../../../utils/common_components/help_tip';
import Checkbox from '../../../utils/common_components/checkbox_form';
export default function () {
    return React.createElement(Card, {
        'className': 'w-100 bg-white',
        'style': { minHeight: '100vh' }
    }, React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {
        'className': 'big-labels no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row bg-white' }, React.createElement('div', { 'className': 'col-xs-12' }, this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'no-box-shadow height-fit pull-right p-l-25 p-r-25 c-white text-capital  m-l-10 bg-success-red',
        'label': 'Save',
        'primary': true,
        'raised': true,
        'key': '1524'
    }) : null, this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.props.submitting,
        'type': 'button',
        'onClick': this.doDelete,
        'className': 'no-box-shadow height-fit pull-right bg-success-border p-l-20 p-r-20 m-b-12 m-r-5',
        'style': { marginRight: '-10px' },
        'label': 'Delete',
        'raised': true,
        'key': '1971'
    }) : null), React.createElement('label', { 'className': 'col-xs-12 m-b-10' }, '\n                    Question Type\n                '), React.createElement('label', { 'className': 'col-xs-6 f-14 m-b-10' }, React.createElement(Field, {
        'name': 'field_type',
        'component': 'input',
        'type': 'radio',
        'value': '99'
    }), ' \n                    Section Title & Note\n                '), React.createElement('label', { 'className': 'col-xs-6 f-14 m-b-10' }, React.createElement(Field, {
        'name': 'field_type',
        'component': 'input',
        'type': 'radio',
        'value': '1'
    }), ' \n                    Open Answer\n                '), React.createElement('label', { 'className': 'col-xs-6  f-14 m-b-10' }, React.createElement(Field, {
        'name': 'field_type',
        'component': 'input',
        'type': 'radio',
        'value': '2'
    }), ' Numeric Answer\n                        '), React.createElement('label', { 'className': 'col-xs-6  f-14 m-b-10' }, React.createElement(Field, {
        'name': 'field_type',
        'component': 'input',
        'type': 'radio',
        'value': '3'
    }), ' Multiple Choice - Single Answer(Select Dropdown)\n                '), React.createElement('label', { 'className': 'col-xs-6  f-14 m-b-10' }, React.createElement(Field, {
        'name': 'field_type',
        'component': 'input',
        'type': 'radio',
        'value': '14'
    }), ' Multiple Choice - Single Answer(Radio Options)\n                '), React.createElement('label', { 'className': 'col-xs-6  f-14 m-b-10' }, React.createElement(Field, {
        'name': 'field_type',
        'component': 'input',
        'type': 'radio',
        'value': '4'
    }), ' Multiple Choice -Multiple Answer\n                '), React.createElement('label', { 'className': 'col-xs-6  f-14 m-b-10' }, React.createElement(Field, {
        'name': 'field_type',
        'component': 'input',
        'type': 'radio',
        'value': '5'
    }), ' Date\n                '), React.createElement(Field, {
        'multiline': true,
        'className': 'col-xs-12 m-t-10 m-b-10',
        'name': 'name',
        'type': 'text',
        'component': Input,
        'label': this.props.field_type != '99' ? 'Question' : 'Title'
    }), this.props.field_type === '99' ? React.createElement(Field, {
        'multiline': true,
        'className': 'col-xs-12  m-b-10',
        'name': 'note',
        'type': 'text',
        'component': Input,
        'label': 'Note (optional)',
        'key': '4195'
    }) : null, this.props.field_type !== '99' ? React.createElement('div', {
        'className': 'answer-question-type',
        'key': '4365'
    }, React.createElement('div', { 'className': 'd-flex aligb-center' }, React.createElement(Field, {
        'className': 'm-r-10',
        'name': 'is_required',
        'component': Checkbox
    }), React.createElement('label', { 'className': 'col-xs-12 m-b-10 m-l-10 f-17' }, '\n                            Is It Mandatory?\n                        ')), this.props.field_type === '3' || this.props.field_type === '4' || this.props.field_type === '14' ? React.createElement('div', {
        'className': 'col-xs-12 m-b-10',
        'key': '4799'
    }, React.createElement(Field, {
        'name': 'field_choices',
        'component': Creatable,
        'placeholder': 'Please create possible answers',
        'options': _.cloneDeep(this.props.question.field_choices),
        'label': 'Possible Answers',
        'multi': true
    }), React.createElement('label', { 'className': 'material-label c-accent f-12' }, '\n                            Enter a value thats NOT in the list, then hit return to create a new answer.\n                            All answers should start with capital case. Minimum of 2 characters needed\n                        ')) : null) : null, this.props.field_type === '3' || this.props.field_type === '4' || this.props.field_type === '14' ? React.createElement('div', {
        'className': 'row',
        'key': '5771'
    }, React.createElement('label', { 'className': 'col-xs-12 m-b-10 m-t-10 f-17' }, '\n                                Show Additional Text Field?\n                                ', React.createElement(HelpTip, {}, '\n                                        Use this to gather more descriptive information from the client\n                                ')), React.createElement(Field, {
        'className': 'col-xs-12 m-b-10',
        'name': 'show_additional_field',
        'component': Switch
    })) : null))));
}