import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    function repeatTutorial1(tutorial, tutorialIndex) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5',
            'key': 'tutorial' + tutorialIndex
        }, React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '40px' }
        }, React.createElement('div', { 'className': 'col-xs-12 f-16' }, React.createElement(TooltipWrapper, {
            'tooltip': tutorial.title,
            'className': 'truncate'
        }, '\n                    ', tutorial.title, '\n                '))), React.createElement('div', { 'className': 'box-300-225' }, React.createElement(VimeoPlayer, {
            'identifier': 'gomotive_video' + (tutorialIndex + tutorial.web_tutorial_video_guid),
            'videoList': [tutorial.web_tutorial_video_guid]
        })));
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        React.createElement('div', { 'className': 'col-xs-12 center-xs f-22 m-b-20 m-t-10' }, '\n        Video Tutorials\n    '),
        this.props.videoTutorials.length === 0 ? React.createElement('div', {
            'className': 'alert-warning m-t-30 w-100 text-center',
            'key': '1928'
        }, '\n        Currently no tutorial videos are available\n    ') : null,
        _.map(this.props.videoTutorials, repeatTutorial1.bind(this))
    ]);
}