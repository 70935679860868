let initialState = {
    primary_practice: {},
    practice_options: [],
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_CLIENT_PRIMARY_PRACTICE_FILL":
            return Object.assign({}, state, {
                primary_practice: action.primary_practice,
                practice_options: action.practice_options
            });
        case "PRACTICE_CLIENT_PRIMARY_PRACTICE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
