import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/partner_interaction.rt";
import Swal from 'sweetalert2';
import { history } from '../../redux/store';
import {sendMessagePartners} from '../../redux/partner/network/partner_network';

class PartnerInteraction extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.interacteWithPartners = this.interacteWithPartners.bind(this);
        this.cancel = this.cancel.bind(this);
        this.state = {
            before_action: false,
            after_action_message: "",
            partner_interaction_type:[
                { value: 1, label: 'All Partners' },            
                { value: 2, label: 'Independent Partners' },                
                { value: 3, label: 'Dependent Partners' },                
            ]
        };

    };
    
    componentWillMount() {
    }

    componentWillUnmount() {
        //this.props.cleanUp();
    }

    interacteWithPartners(data) {
        let params = {...data};
        this.props.sendMessagePartners(params).then((response) => { 
            this.setState({
                "before_action": true,
                "after_action_message": response.success_message, 
            });
        }).catch((error) => {            
        });  
    }

    cancel() {
        history.goBack();
    }

    render() {
        if (!this.state.before_action) {
            return Template.apply(this);
        } else {
            return (<div class='m-30 p-10 text-center bg-info'>{this.state.after_action_message}</div>);
        }
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        sendMessagePartners,
    }, dispatch),
    dispatch
});

const validate = (values, props) => {
    let requiredFields = [
        'partner_interaction_type',
        'subject',
        'body',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const PartnerInteractionForm = reduxForm(
    {
        form: 'PartnerInteractionForm',
        enableReinitialize: true,
        validate
    }
)(PartnerInteraction);

const PartnerInteractionFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PartnerInteractionForm);
export default PartnerInteractionFormConnected;
