import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_treatment_templates.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getPartnerTreatmentTemplateList,
    addPartnerTreatmentTemplate, 
    removePartnerTreatmentTemplate,
    addAllTreatmentTemplatesPartnerLibrary,
    removeAllTreatmentTemplatesPartnerLibrary
} from '../../redux/partner/network/partner_network';
import { 
    getTreatmentTemplateList 
} from '../../redux/treatment_template/network/treatment_template_network';
import partnerActions from '../../redux/partner/actions/partner_action';
import actions from '../../redux/treatment_template/actions/treatment_template_creator';

class PartnerLibraryTreatmentTemplates extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addTreatmentTemplate = this.addTreatmentTemplate.bind(this);
        this.removeTreatmentTemplate = this.removeTreatmentTemplate.bind(this);
        this.loadTreatmentTemplates = this.loadTreatmentTemplates.bind(this);
        this.loadPartnerTreatmentTemplateList = this.loadPartnerTreatmentTemplateList.bind(this);
        this.addAllTreatmentTemplates = this.addAllTreatmentTemplates.bind(this);
        this.removeAllTreatmentTemplates = this.removeAllTreatmentTemplates.bind(this);
    };

    componentWillMount() {
        if(!this.props.match.params.library_id){
            history.goBack();
        }
        this.props.cleanUp({});
        this.props.cleanUpTreatmentTemplateList();

        this.loadTreatmentTemplates();
        this.loadPartnerTreatmentTemplateList();
    }

    loadTreatmentTemplates(){
        let params = {
            'show_published':  true, 
        };
        if(this.props.match.params.treatment_type === "group"){
            params.treatment_type = 2;
            this.props.getTreatmentTemplateList(params);
        }else if(this.props.match.params.treatment_type === "gameplan"){
            params.treatment_type = 1;
            this.props.getTreatmentTemplateList(params);
        }
    }

    loadPartnerTreatmentTemplateList() {
        let params = {  
            id:this.props.match.params.library_id,
        };
        if(this.props.match.params.treatment_type === "group"){
            params.treatment_template_type = 2;
            this.props.getPartnerTreatmentTemplateList(params);
        }else if(this.props.match.params.treatment_type === "gameplan"){
            params.treatment_template_type = 1;
            this.props.getPartnerTreatmentTemplateList(params);
        }
    }



    componentWillUnmount() {
        this.props.cleanUp({});
        this.props.cleanUpTreatmentTemplateList();
    }

    addTreatmentTemplate(treatment_template){
        let params = {
            id:this.props.match.params.library_id,
            treatment_template_id:treatment_template.id
        };
        if(this.props.match.params.treatment_type === "group"){
            params.treatment_template_type = 2;
            this.props.addPartnerTreatmentTemplate(params);
        }else if(this.props.match.params.treatment_type === "gameplan"){
            params.treatment_template_type = 1;
            this.props.addPartnerTreatmentTemplate(params);
        }
    }
    removeTreatmentTemplate(treatment_template){
        let params = {
            id:this.props.match.params.library_id,
            treatment_template_id:treatment_template.id
        };
        if(this.props.match.params.treatment_type === "group"){
            params.treatment_template_type = 2;
            this.props.removePartnerTreatmentTemplate(params);
        }else if(this.props.match.params.treatment_type === "gameplan"){
            params.treatment_template_type = 1;
            this.props.removePartnerTreatmentTemplate(params);
        }
    }

    addAllTreatmentTemplates() {
        let params = {  
            library_id: this.props.match.params.library_id,
        };
        if(this.props.match.params.treatment_type === "group"){
            params.treatment_template_type = 2;
        }else if(this.props.match.params.treatment_type === "gameplan"){
            params.treatment_template_type = 1;
        }
        this.props.addAllTreatmentTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.cleanUpTreatmentTemplateList();    
            this.loadTreatmentTemplates();
            this.loadPartnerTreatmentTemplateList();
            }).catch((error) => {            
        });  
    }

    removeAllTreatmentTemplates() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        if(this.props.match.params.treatment_type === "group"){
            params.treatment_template_type = 2;
        }else if(this.props.match.params.treatment_type === "gameplan"){
            params.treatment_template_type = 1;
        }
        this.props.removeAllTreatmentTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.cleanUpTreatmentTemplateList();    
            this.loadTreatmentTemplates();
            this.loadPartnerTreatmentTemplateList();
            }).catch((error) => {            
        });  
    }


    render() {
        if(_.isEmpty(this.props.partner_library)){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTreatmentTemplateList,
        getPartnerTreatmentTemplateList,
        addPartnerTreatmentTemplate,
        removePartnerTreatmentTemplate,
        cleanUp:partnerActions.cleanUp,
        cleanUpTreatmentTemplateList: actions.treatmentTemplateCleanup,
        addAllTreatmentTemplatesPartnerLibrary,
        removeAllTreatmentTemplatesPartnerLibrary,
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    let partner_template_ids = _.keyBy(state.partner.partnerReducer.partner_library.treatment_templates,"id");
    return {
        treatment_template_list: state.treatment_template.treatmentTemplateReducer.treatment_template_list,
        partner_library: state.partner.partnerReducer.partner_library,
        partner_template_ids:partner_template_ids
    };
}
const PartnerLibraryTreatmentTemplatesConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryTreatmentTemplates);
export default PartnerLibraryTreatmentTemplatesConnected;
