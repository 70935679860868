import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_group_class_booking.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../redux/store';
import Swal from 'sweetalert2'
import { clientGroupClassSchedulerBookingGet,clientGroupClassSchedulerBookingPost,clientGroupClassSchedulerBookingCancel } from '../../redux/calendar/network/group_class_network';
import  groupClassActionCreator from '../../redux/calendar/actions/group_class_action';

class ClientGroupClassBooking extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.showBookGroupClassDialog = this.showBookGroupClassDialog.bind(this);
        this.closeBookingDialog = this.closeBookingDialog.bind(this);
        this.showCancelBookGroupClassDialog = this.showCancelBookGroupClassDialog.bind(this);
        this.closeCancelBookingDialog = this.closeCancelBookingDialog.bind(this);
        this.doBook = this.doBook.bind(this);
        this.doCancelBooking = this.doCancelBooking.bind(this);
        this.state = {
            showBookingDialog: false,
            showCancelBookingDialog: false
        };
    }

    componentWillMount() {
        this.props.clientGroupClassSchedulerBookingGet({group_class_id:this.props.match.params.group_class_id});
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    doBook(){
        let that = this;
        if(this.props.group_class.booking_type === 1){//Entire Series
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to book all the group class slots?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections:true
            }).then(function () {
                that.props.clientGroupClassSchedulerBookingPost({ 
                    'group_class_id':that.props.match.params.group_class_id,
                    app_version: "5.2",
                    current_role_type: "practice_admin",
                    package_version: "1",
                    practice_id: 276,
                }).then((response) => {
                    //Do nothing
                });            
            }, function (dismiss) {
                            
            });
        }else{
            this.showBookGroupClassDialog();
        }
    }
    doCancelBooking(){
        let that = this;
        if(this.props.group_class.booking_type === 1){//Entire Series
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to cancel booking for the entire group class?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections:true
            }).then(function () {
                that.props.clientGroupClassSchedulerBookingCancel({ 'group_class_id':that.props.match.params.group_class_id  }).then((response) => {
                    //Do nothing
                });            
            }, function (dismiss) {
                            
            });
        }else{
            this.showCancelBookGroupClassDialog();
        }
    }

    showBookGroupClassDialog() {
       this.setState({showBookingDialog:true});
    }
    closeBookingDialog() {
       this.setState({showBookingDialog:false});
    }
    showCancelBookGroupClassDialog() {
       this.setState({showCancelBookingDialog:true});
    }
    closeCancelBookingDialog() {
       this.setState({showCancelBookingDialog:false});
    }

    render() {
        if (_.isEmpty(this.props.group_class)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }  

        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        group_class: state.calendar.groupClassReducer.group_class,
        booking_details: state.calendar.groupClassReducer.booked_client,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clientGroupClassSchedulerBookingGet,
        clientGroupClassSchedulerBookingPost,
        clientGroupClassSchedulerBookingCancel,
        cleanUp: groupClassActionCreator.cleanModule
    }, dispatch
    );
}


const ClientGroupClassBookingConnected = connect(mapStateToProps, mapDispatchToProps)(ClientGroupClassBooking);
export default ClientGroupClassBookingConnected;