let initialState = {
    acquiredQuickStarts: [],
    availableQuickStarts: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLIENT_QUICKSTART_FILL":
            return Object.assign({}, state, {
                acquiredQuickStarts: action.acquiredQuickStarts,
                availableQuickStarts: action.availableQuickStarts,
            });
        case "CLIENT_QUICKSTART_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
