import  React from "react";
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/gi_pro_summary";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import Swal from 'sweetalert2';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import ExerciseActionCreator from '../../redux/exercise/actions/exercise_list';
import Axios from '../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../../redux/practitioner/constants/constants';

class GIProSummary extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.draftWorkouts = this.draftWorkouts.bind(this);   
    }

    
    componentWillMount() {       
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);        
    }

    componentWillUnmount() {
        this.props.clearWorkoutCartActionCreator();
    }

    componentDidMount() {
        const exerciseList = this.props.workoutCartExeciseList || [];
        const selectedCoach = sessionStorage.getItem('selectedCoach');
        if (selectedCoach && exerciseList.length > 0) {
            this.draftWorkouts(exerciseList, selectedCoach)
        }
    }

    draftWorkouts(exerciseList, selectedCoach) {
        const url = '/' + API_VERSION + '/' + PACKAGE + '/workout_draft';

        let params = {exerciseList, selectedCoach};
        Axios.post(url, params)
            .then(async (response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 400
                ) {
                    Utils.showErrorAlert(error.response.data.reason);
                };
            });
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);        
    }
}
function mapStateToProps(state) {     
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,  
        workoutCartExeciseList: state.exercise.exerciseList.workoutCartExeciseList,      
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({               
            updateMenuOpen:MenuAction.updateMenuOpen,
            updateMenuPinned:MenuAction.updateMenuPinned, 
            clearWorkoutCartActionCreator: ExerciseActionCreator.clearWorkoutCart,           
        }, 
        dispatch
    );
}

const GIProSummaryConnected = connect(mapStateToProps,mapDispatchToProps)(GIProSummary);
export default GIProSummaryConnected;
