// Action Creators
const adminListFill = (admin_list) => ({
    type: 'ADMIN_LIST_FILL',
    admin_list,
});
const subscriptionProfileFill = (subscription_profile) => ({
    type: 'SUBSCRIPTION_PROFILE_FILL',
    subscription_profile,
});
export default {
    adminListFill,
    subscriptionProfileFill
};