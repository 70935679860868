import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/education_content.rt";
import store, { history} from '../../redux/store';
import {
    getEducationCategoryList, deleteEducationCategory,
    getEducationContentList, deleteEducationContent
} from '../../redux/education/network/education_network';
import swal from 'sweetalert2';
import EducationAction from '../../redux/education/actions/education_actions';

class EducationContent extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            index: 0,
            search: null,
            selectedEducationCategory: null,
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.addEducationCategory = this.addEducationCategory.bind(this);
        this.editEducationCategory = this.editEducationCategory.bind(this);
        this.deleteEducationCategory = this.deleteEducationCategory.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.addEducationContent = this.addEducationContent.bind(this);
        this.editEducationContent = this.editEducationContent.bind(this);
        this.deleteEducationContent = this.deleteEducationContent.bind(this);
        this.onSelectCategory = this.onSelectCategory.bind(this);
    };

    handleTabChange(index) {
        if(index === 0){
            this.props.getEducationCategoryList({});
        } else {
            this.loadEducationContentData(null);
        }
        this.setState({index});
    };
    
    loadEducationContentData() {
        this.props.getEducationContentList({
            'search_text': this.state.search,
            'education_category_id': this.state.selectedEducationCategory,
        });
    }

    onSearch(text){
        this.setState({search:text},()=>{
            this.loadEducationContentData();
        });
    }

    onSelectCategory(education_category_id) {
        this.setState({ selectedEducationCategory: education_category_id }, () => {
            this.loadEducationContentData();
        });
    }

    addEducationContent() { 
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education/content/add");
    }

    editEducationContent(content) {        
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education/content/edit/"+content.id);
    }

    deleteEducationContent(content) {        
        let that = this;
        swal.fire({
            title: "Delete Content",
            text: "You are about to delete education content. Are you sure?",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am",
            cancelButtonText: "Cancel",
            useRejections:true
        }).then(function () {
            that.props.deleteEducationContent({
                content_id: content.id
            }).then((response) => {
                that.loadEducationContentData();
                swal.close();
            }).catch((error) => { 
                swal.close();
            });            
        }, function (dismiss) {
                        
        });        

    }


    componentWillMount() {
        this.props.getEducationCategoryList({});
    }

    componentWillUnmount() {        
        this.props.cleanUp();
    }


    addEducationCategory() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education/category/add");
    }

    editEducationCategory(category) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education/category/edit/"+category.id);
    }

    deleteEducationCategory(category) {
        let that = this;
        swal.fire({
            title: "Delete Category",
            text: "You are about to delete education category. Are you sure?",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am",
            cancelButtonText: "Cancel",
            useRejections:true
        }).then(function () {
            that.props.deleteEducationCategory({
                category_id: category.id
            }).then((response) => {
                that.props.getEducationCategoryList({});
                swal.close();
            }).catch((error) => { 
                swal.close();
            });            
        }, function (dismiss) {
                        
        });        

    }


    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        education_categories: state.education.educationReducer.education_categories,
        education_content: state.education.educationReducer.education_content_list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getEducationCategoryList,
            deleteEducationCategory,
            getEducationContentList,
            deleteEducationContent,
            cleanUp: EducationAction.cleanModule,
        }, 
        dispatch
    );
}

const EducationContentConnected = connect(mapStateToProps,mapDispatchToProps)(EducationContent);
export default EducationContentConnected;