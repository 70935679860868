import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PracticeDetails from '../../../admin/components/practice_details';
import PracticeDescription from '../../../admin/components/practice_description';
import WelcomeVideo from '../../../admin/components/practice_welcome_video';
import MarketingTips from '../../../buildmypractice/components/marketing_tips';
import EngagingVideos from '../../../buildmypractice/components/engaging_videos';
import CannedMessageEdit from '../../../message/components/canned_message_edit';
import CannedMessageEditNew from '../../../message_new/components/canned_message_edit';
import CannedMessages from '../../../message/components/canned_messages';
import CannedMessagesNew from '../../../message_new/components/canned_messages';
import ServicesOfferedEdit from '../../../admin/services_offered/components/services_offered_edit';
import ServicesOffered from '../../../admin/services_offered/components/services_offered';
import ConditionsTreatedEdit from '../../../admin/conditions_treated/components/conditions_treated_edit';
import ConditionsTreated from '../../../admin/conditions_treated/components/conditions_treated';
import NewsList from '../../../news/components/news_list';
import NewsEdit from '../../../news/components/news_edit';
import NewsView from '../../../news/components/news_view';
import WorkfitNotificationSchedule from '../../../dhf/workfit/components/workfit_notification_schedule.jsx';
import WorkfitNotificationScheduleAdd from '../../../dhf/workfit/components/workfit_notification_schedule_add.jsx';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 m-t-20 p-0' }, this.props.selectedRole.is_workfit_practice === false ? React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu',
        'key': '3068'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/news`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'News for my users')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/details`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Setup')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/description`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Description')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/email_template`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Email Templates')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/services_offered`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Services Offered')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/conditions_treated`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Conditions Treated')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/engaging_videos`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Engaging Videos')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/marketing_tips`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Marketing Tips'))) : null, this.props.selectedRole.is_workfit_practice === true ? React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu',
        'key': '4726'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/news`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'News')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/branding/notification_schedules`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Notifications'))) : null, React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/news/edit/:news_id`,
        'component': NewsEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/news/add`,
        'component': NewsEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/view/:news_id`,
        'component': NewsView
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/news`,
        'component': NewsList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/details`,
        'component': PracticeDetails
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/description`,
        'component': PracticeDescription
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/welcome_video`,
        'component': WelcomeVideo
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/marketing_tips`,
        'component': MarketingTips
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/engaging_videos`,
        'component': EngagingVideos
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/email_template/edit/:message_type`,
        'component': CannedMessageEditNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/email_template`,
        'component': CannedMessagesNew
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/services_offered/edit/:service_offered_id`,
        'component': ServicesOfferedEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/services_offered/add`,
        'component': ServicesOfferedEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/services_offered`,
        'component': ServicesOffered
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/conditions_treated/edit/:condition_treated_id`,
        'component': ConditionsTreatedEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/conditions_treated/add`,
        'component': ConditionsTreatedEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/conditions_treated`,
        'component': ConditionsTreated
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/notification_schedules/add`,
        'component': WorkfitNotificationScheduleAdd
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/notification_schedules`,
        'component': WorkfitNotificationSchedule
    })));
}