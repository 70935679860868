import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { PARTNER } from '../../../core/constants/constants';
import { Link } from 'react-router-dom';
import Checkbox from 'react-toolbox/lib/checkbox';
export default function () {
    return React.createElement('div', {}, this.props.display_signup_form ? React.createElement('div', {
        'className': 'row center-xs middle-xs  full-height c-white w-100',
        'key': '946'
    }, React.createElement('form', {
        'className': 'bg-none col-xs-11 col-sm-10 col-md-6 big-labels white-labels text-left',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('h1', { 'className': 'c-white text-center' }, 'SIGN UP'), React.createElement(Field, {
        'name': 'first_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'First Name'
    }), React.createElement(Field, {
        'name': 'last_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'Last Name'
    }), React.createElement(Field, {
        'name': 'email',
        'type': 'email',
        'component': TextInput,
        'hint': 'Email Address'
    }), React.createElement(Field, {
        'name': 'password',
        'type': 'password',
        'component': TextInput,
        'hint': 'Password'
    }), _.has(this.props.match.params, 'user_role_type') && this.props.match.params.user_role_type === 'p' ? React.createElement('div', { 'key': '1773' }, React.createElement(Field, {
        'name': 'practice_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'Practice Name'
    }), React.createElement(Field, {
        'name': 'city',
        'type': 'text',
        'component': TextInput,
        'hint': 'City'
    }), React.createElement(Field, {
        'name': 'zipcode',
        'type': 'text',
        'component': TextInput,
        'hint': 'Zipcode'
    })) : null, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20 p-l-0 c-white',
        'name': 'timezone',
        'component': Select,
        'label': 'Time Zone',
        'options': this.props.timezones,
        'simpleValue': true
    }), React.createElement('div', { 'className': 'f-300 f-16 c-white m-b-10 text-center' }, React.createElement(Checkbox, {
        'checked': this.state.acceptPrivacyPolicy,
        'className': 'c-white di-block m-b-0',
        'label': true,
        'onChange': this.acceptPrivacyPolicyChange
    }), '\n                            I accept ', PARTNER.name, ' \n                                ', React.createElement(Link, {
        'to': '/terms_of_use',
        'className': 'c-success'
    }, 'User Agreement'), ' and \n                                ', React.createElement(Link, {
        'to': '/site_media/static/files/privacy_policy.pdf',
        'target': '_blank',
        'className': 'c-success'
    }, 'Privacy policy')), React.createElement('div', { 'className': 'row w-100 center-xs m-b-25' }, React.createElement(Button, {
        'label': 'Sign Up',
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success big-button',
        'raised': true,
        'style': { width: '180px' }
    })))) : null, !this.props.display_signup_form ? React.createElement('div', {
        'className': 'row center-xs middle-xs m-t-30  full-height',
        'key': '3723'
    }, React.createElement('div', {
        'className': 'col-xs-11 col-sm-10 col-md-6 p-20',
        'style': { border: '2px solid white' }
    }, React.createElement('h2', { 'className': 'c-white' }, 'ACTIVATE YOUR ACCOUNT'), React.createElement('i', { 'className': 'material-icons f-50 c-white' }, 'inbox'), React.createElement('div', {
        'style': { background: 'rgba(255,255,255,0.8)' },
        'className': 'p-20  m-t-20'
    }, React.createElement('p', { 'className': 'text-left c-accent f-16 f-500' }, '\n                        For security reasons we just sent you an email to verify your email address. \n                        Please select the verification link in the email to get started. \n                        If it does not appear in a few minutes check your spam filters.\n                    '), React.createElement('p', { 'className': 'text-left m-t-10 c-accent f-18 f-500' }, '\n                        Thank you!\n                    ')))) : null);
}