import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Tab } from 'react-toolbox';
import { Tabs } from 'react-toolbox';
import { Calendar as BigCalendar } from 'react-big-calendar';
import { IconButton } from 'react-toolbox';
import FacilityBooking from '../../facility/client_facility_booking';
import { Dialog } from 'react-toolbox/lib/dialog';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs m-20' }, React.createElement(Card, { 'className': 'w-100' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('div', { 'className': 'col-xs-12 m-30' }, React.createElement(BigCalendar, {
        'className': 'full-height',
        'localizer': this.props.localizer,
        'selectable': true,
        'popup': true,
        'defaultView': 'month',
        'views': [
            'month',
            'week',
            'day',
            'agenda'
        ],
        'events': this.props.eventList,
        'onSelectEvent': event => this.viewEvent(event),
        'eventPropGetter': event => {
            return { style: { backgroundColor: '#3498db' } };
        }
    })))), React.createElement(Dialog, {
        'active': this.state.viewEventDialog,
        'onEscKeyDown': this.closeEventDialog,
        'onOverlayClick': this.closeEventDialog
    }, this.state.viewEventDialog ? React.createElement(FacilityBooking, {
        'facility_id': this.props.match.params.facility_id,
        'booking_slot_id': this.state.scheduleId,
        'closeBookingDialog': this.closeEventDialog,
        'key': '1503'
    }) : null));
}