import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Swal from 'sweetalert2';
import Template from "./templates/practitioner_client_home.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { getClientHome, createNewClientEngagement } from '../../redux/engagement/network/client_network';
import ClientAction from '../../redux/engagement/actions/client_creator';
import { resendClientInvitation } from '../../redux/engagement/network/client_list_network';

class ClientHome extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.goToEngagement = this.goToEngagement.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);
        this.editClientDetails = this.editClientDetails.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.createNewEngagement = this.createNewEngagement.bind(this);
        this.goToClient = this.goToClient.bind(this);
        this.state = {
            tabIndex:0
        };    
    };
    goToClient(){
        history.push(`/practice/${this.props.selectedRole.practice.id}/client`)
    }
    componentWillMount() {
        if (!this.props.match.params.client_id){
            history.goBack();
        }
        this.props.getClientHome({'id':this.props.match.params.client_id});
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    handleTabChange(tabIndex){
        this.setState({tabIndex});
    }

    resendInvitation(client_id) {
        this.props.resendClientInvitation({
            client_id: client_id,
        }).then(() => {
            }).catch((error) => { });        
    }

    editClientDetails(client_id) {
        
    }
    
    goToEngagement(engagement) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/client/engagement/"+engagement.id);
    }

    createNewEngagement() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to create a new game plan for this client?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            let params = {};
            params['client_id'] = that.props.match.params.client_id;
            that.props.createNewClientEngagement({ ...params }).then((response) => {
                let engagement_id = response['engagement_id'];
                if (engagement_id !== -1) {
                    history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + engagement_id);
                } else {
                    history.push("/practice/"+that.props.selectedRole.practice.id+"/client");
                }            
            }).catch((error) => {
            });        
        }, ()=> { });
        
    }

    render() {
        if (_.isEmpty(this.props.client)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }            
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        client: state.engagement.clientReducer.client,
        engagements: state.engagement.clientReducer.engagements,
        groups: state.engagement.clientReducer.groups,
        new_engagement_message: state.engagement.clientReducer.new_engagement_message,
        active_engagement: state.engagement.clientReducer.active_engagement,
        support_multilpe_gameplan: state.engagement.clientReducer.support_multiple_gameplan,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getClientHome,
            createNewClientEngagement,
            resendClientInvitation,
            cleanUp: ClientAction.clientInstanceCleanup,
        }, 
        dispatch
    );
}

const ClientHomeConnected = connect(mapStateToProps,mapDispatchToProps)(ClientHome);
export default ClientHomeConnected;
