import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import FontIcon from 'react-toolbox/lib/font_icon';
import { NavLink } from 'react-router-dom';
import { IconMenu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { PARTNER } from '../../../../core/constants/constants';
export default function () {
    return React.createElement('ul', { 'className': 'm-t-20' }, React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/hf/home',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n            Home\n        ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/hf/workouts',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-workouts',
        'gomotive': true
    }), '\n            Workouts\n        ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/hf/subscriptions',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'verified_user' }), '\n                Subscriptions\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/hf/movement_meter',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'settings' }), '\n            Movement Meter\n        ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/hf/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n            User Settings\n        ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n            Sign out\n        ')));
}