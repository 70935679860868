import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/partner_practice_add.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm,formValueSelector } from 'redux-form';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import {getPartnerDetails,searchPracticePartners,invitePartner} from '../../redux/partner/network/partner_network';
import partnerActions from '../../redux/partner/actions/partner_action';
import authReducer from '../../redux/auth/reducers/auth_reducer';

class PartnerAdd extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.state = {
            practice_list : [],
            practitioner:{}
        };
    }
    
    componentWillMount() {
        this.props.getPartnerDetails({});   
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    onEmailChange(data){
        if(!_.isEmpty(this.state.practitioner) && this.state.practitioner.email ===  this.props.email){
            return;
        }
        if (this.props.email) {
              this.props.searchPracticePartners({search:this.props.email}).then((response)=>{
                    if(response.data.practices){
                          this.props.change("practice_exists",true);  
                          this.setState({practice_list:response.data.practices,practitioner:response.data.user});
                    }else{
                        this.props.change("practice_exists",false);  
                        this.setState({practice_list:[],practitioner:{}});
                    }
              }).catch((error)=>{
                  this.props.change("practice_exists",false);  
                    this.setState({practice_list:[],practitioner:{}});
              });  
        }else{
            this.props.change("practice_exists",false);  
             this.setState({practice_list:[],practitioner:{}});
        }        
    }

    doSave(data){
        let params = _.pick(data, [
                'email',
                'subject',
                'body',
                'selected_practice',
                'practice_name',
                'practice_city',
                'practice_zipcode',
                'practice_exists',
                'available_plans'
                ]
        );
        this.props.invitePartner(params).then((response)=>{
            history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/business_partners");
        }).catch((error)=>{
            //Do nothing
        });
    }


    render() {
        if(_.isEmpty(this.props.partner)){
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('partnerAddForm');
function mapStateToProps(state) {
    let partner = state.partner.partnerReducer.partner;
    let invitation_email = state.partner.partnerReducer.invitation_email;
    let initialValues = {};
    if(!_.isEmpty(partner)){
        initialValues = {
            partner:partner.id,
            email:null,
            subject:invitation_email.subject,
            body:invitation_email.body,
            selected_practice:null,
            practice_name:null,
            available_plans:[],
            practice_city:null,
            practice_zipcode:null,
            practice_exists:false
        };
    }
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: initialValues,
        partner: partner,
        partner_plans: state.partner.partnerReducer.partner_plans,
        invitation_email: invitation_email,
        user: state.auth.authReducer.user,
        email: selector(state, 'email'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getPartnerDetails,
            searchPracticePartners,
            invitePartner,
            cleanUp:partnerActions.partnerInstanceFill
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'email',
        'subject',
        'body',
    ];
    if(values.practice_exists){
        requiredFields = [...requiredFields,'selected_practice'];
    }else{
        requiredFields = [...requiredFields,'practice_name','practice_city','practice_zipcode'];
    } 
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address!';
    }
    if (values.email && values.email === props.user.email) {
        errors.email = 'You cant add your own practice to your business network!';
    }
    return errors;
};


const PartnerAddForm = reduxForm(
    {
        form: 'partnerAddForm',
        enableReinitialize: true,
        validate
    }
)(PartnerAdd);


const PartnerAddConnected = connect(mapStateToProps,mapDispatchToProps)(PartnerAddForm);
export default PartnerAddConnected;