import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Panel } from 'react-toolbox';
import Input from 'react-toolbox/lib/input';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-toolbox/lib/date_picker';
import { Dialog } from 'react-toolbox/lib/dialog';
export default function () {
    function repeatQuickStart1(quickStart, quickStartIndex) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container pointer',
            'key': 'quickStart' + quickStartIndex,
            'onClick': () => this.gotoQuickStartView(quickStart)
        }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(quickStart.latest_version.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5 m-b-8',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (quickStart.latest_version.description ? '9' : '12') + ' f-16 truncate',
            'tooltip': quickStart.latest_version.name
        }, '\n                ', quickStart.latest_version.name, '\n            '), quickStart.latest_version.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': quickStart.latest_version.description,
            'icon': 'info',
            'key': '2278'
        }) : null));
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        this.props.acquiredQuickStarts.length === 0 ? React.createElement('div', {
            'className': 'row full-height middle-xs center-xs col-xs-12',
            'key': '1389'
        }, '\n        No QuickStarts acquired yet\n    ') : null,
        _.map(this.props.acquiredQuickStarts, repeatQuickStart1.bind(this)),
        React.createElement(Dialog, {
            'active': this.state.datePickerVisible,
            'title': 'Choose a start date',
            'onEscKeyDown': this.hideDatePicker,
            'onOverlayClick': this.hideDatePicker
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(DatePicker, {
            'onChange': this.onDateChanged,
            'value': this.state.selectedDate
        })), React.createElement('div', { 'className': 'col-xs-12 end-xs' }, React.createElement(Button, {
            'label': 'Cancel',
            'className': 'bg-accent',
            'primary': true,
            'raised': true,
            'onClick': this.hideDatePicker
        }), React.createElement(Button, {
            'label': 'Proceed',
            'className': 'bg-success m-l-10',
            'primary': true,
            'raised': true,
            'onClick': this.finishDateSelection
        })))
    ]);
}