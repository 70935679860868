import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/user_movement_meter_configuration.rt";
import { reduxForm, formValueSelector } from 'redux-form';
import store, { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getClientMovementMeterConfiguration,postClientMovementMeterConfiguration} from '../../redux/user/network/user_profile';
import actionCreator from '../../redux/user/actions/user_profile';
import Utils from '../../utils/utils';

class UserMovementMeterConfiguration extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.state = {
            "workout_duration_options":[
                {
                    label: "Minimum (4 hrs of activity)",
                    value: "MINIMUM"
                },
                {
                    label: "Moderate (6 hrs of activity)",
                    value: "MODERATE"
                },
                {
                    label: "Optimal (9 hrs of activity)",
                    value: "OPTIMAL"
                },
                {
                    label: "Custom",
                    value: "CUSTOM",
                    description: "Choose the activity duration"
                },
            ]
        };
    }
    componentDidMount() {
        this.props.getClientMovementMeterConfiguration();
    }
    componentWillUnmount() {
        this.props.cleanUp();
    }

    onChangeDuration(defFunc,val){
        if(val === "MINIMUM"){
            this.props.change("duration_total",240);
        }else if(val === "MODERATE"){
            this.props.change("duration_total",360);
        }else if(val === "OPTIMAL"){
            this.props.change("duration_total",540);
        }    
    }

    doSave(data) {
        let params = { ...data };
        let total_duration = data["duration_total"];
        if(total_duration < 240){
            Utils.showErrorAlert("Total weekly workout duration should be more than 240 minutes");
            return;
        } 
        this.props.postClientMovementMeterConfiguration(params).then(() => {
            Utils.showSuccessAlert("Your new movement goals have been updated and will become effective next week!");
        }).catch((error)=>{

        });
    }

    render() {
        if(_.isEmpty(this.props.user_movement_meter_configuration)){
            return (<div> </div>);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientMovementMeterConfiguration,
        postClientMovementMeterConfiguration,
        cleanUp:actionCreator.cleanModule
    }, dispatch);
}
const selector = formValueSelector('UserMovementMeterConfigurationForm'); 
function mapStateToProps(state) {
    let user_movement_meter_configuration = state.user.userProfile.user_movement_meter_configuration;
    let initialValues = {
        selected_workout: "CUSTOM",
        duration_total: 0
    };
    let duration_total  = 0;
    if(!_.isEmpty(user_movement_meter_configuration)){
        duration_total = user_movement_meter_configuration.duration_total;
        initialValues.duration_total = duration_total;
        if (duration_total === 240){
            initialValues.selected_workout = "MINIMUM";
        }else if(duration_total === 360){
            initialValues.selected_workout = "MODERATE";
        }else if(duration_total === 540){
            initialValues.selected_workout = "OPTIMAL";
        }else{
            initialValues.selected_workout = "CUSTOM";
        }
    }
    let current_duration_total = selector(state, 'duration_total') || 0;
    let current_selected_workout = selector(state, 'selected_workout') || "CUSTOM";

    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        user: state.auth.authReducer.user,
        user_movement_meter_configuration: user_movement_meter_configuration,
        initialValues: initialValues,
        current_duration_total: current_duration_total,
        current_selected_workout:current_selected_workout,
        duration_total: duration_total,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'duration_total',
        'selected_workout'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (values[field] === null || values[field] === "") {
            errors[field] = 'Required';
        }
    });
    if(values["selected_workout"] === "CUSTOM"){
        if(values["duration_total"] === null  ||  values["duration_total"] < 240){
            errors["duration_total"] = 'Total weekly workout duration should be more than 240 minutes';
        }
    }
    return errors;
};
const UserMovementMeterConfigurationForm = reduxForm(
    {
        form: 'UserMovementMeterConfigurationForm',
        enableReinitialize: true,
        validate
    }
)(UserMovementMeterConfiguration);

const UserMovementMeterConfigurationFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserMovementMeterConfigurationForm);

export default UserMovementMeterConfigurationFormConnected;
