import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import SelectComponent from 'react-select';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { DEFAULT_MODE } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { PROGRESSION_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { Panel } from 'react-toolbox';
import { SearchInput as Input } from '../../../utils/common_components/textInput_form';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import { NavLink } from 'react-router-dom';
export default function () {
    function repeatProgram1(program, programIndex) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'program' + programIndex
        }, !program.published ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '4814'
        }, '\n                DRAFT\n            ') : null, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(program.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement('div', { 'className': 'col-xs-' + (program.description ? '9' : '12') + ' text-left' }, React.createElement(TooltipWrapper, {
            'className': 'f-16 truncate',
            'tooltip': program.name
        }, '\n                    ', program.name, '\n                '), React.createElement('div', { 'className': 'f-10 truncate' }, program.practice.name)), program.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': program.description,
            'icon': 'info',
            'key': '5466'
        }) : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-2 m-0' }, program.deleted_date === null ? [
            this.props.mode === DEFAULT_MODE ? [
                program.is_owner ? React.createElement(IconButton, {
                    'icon': 'create',
                    'tooltip': 'Edit ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.edit(program.id);
                    },
                    'key': '57941'
                }) : null,
                !program.is_owner ? React.createElement(IconButton, {
                    'icon': 'info_outline',
                    'tooltip': 'View ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.edit(program.id);
                    },
                    'key': '57943'
                }) : null,
                program.is_owner ? React.createElement(IconButton, {
                    'icon': 'visibility_off',
                    'tooltip': 'Hide ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.delete(program.id, 'hide');
                    },
                    'key': '57945'
                }) : null,
                program.is_practice_member ? React.createElement(IconButton, {
                    'icon': 'content_copy',
                    'tooltip': 'Clone this ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                    'className': 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.clone(program.id);
                    },
                    'key': '57947'
                }) : null,
                program.is_practice_member ? React.createElement(IconButton, {
                    'icon': 'screen_share',
                    'tooltip': 'Save as ' + (this.props.programType === 'template' ? 'QuickStart' : 'Template'),
                    'className': 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.saveAs(program.id);
                    },
                    'key': '57949'
                }) : null,
                this.props.programType === 'template' ? React.createElement(IconButton, {
                    'icon': program.is_favorite ? 'star' : 'star_border',
                    'tooltip': program.is_favorite ? 'Unfavorite ' + this.props.programType : 'Favorite ' + this.props.programType,
                    'className': program.is_favorite ? 'm-r-5 c-success small-icon-button round-border' : 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.toggleFavorite(program.id);
                    },
                    'key': '579411'
                }) : null,
                this.props.selectedClientForAction !== null ? React.createElement(IconButton, {
                    'tooltip': 'Create workout from workout template',
                    'icon': 'add_circle',
                    'className': 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.createWorkoutFromWorkoutTemplate(program.id);
                    },
                    'key': '579413'
                }) : null
            ] : null,
            this.props.mode === TEMPLATE_SELECTION_MODE ? [React.createElement(IconButton, {
                    'icon': 'add_circle_filled',
                    'tooltip': 'Select this Template',
                    'className': 'm-r-5 c-black small-icon-button round-border',
                    'onClick': () => {
                        this.createWorkoutFromProgram(program.id);
                    },
                    'key': '83121'
                })] : null
        ] : null, program.deleted_date !== null ? [program.is_owner ? React.createElement(IconButton, {
                'icon': 'visibility_on',
                'tooltip': 'Unhide ' + (this.props.programType === 'template' ? 'Template' : 'QuickStart'),
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.delete(program.id, 'unhide');
                },
                'key': '87091'
            }) : null] : null)));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel' }, this.props.mode === DEFAULT_MODE ? React.createElement('div', {
        'className': 'row start-xs m-10',
        'key': '1699'
    }, this.props.typeLCase === 'template' ? React.createElement('div', {
        'className': 'col-xs-12 col-sm-10 col-md-8 p-relative search-box m-b-20 p-5 vertical-align',
        'key': '1780'
    }, React.createElement(Input, {
        'style': { height: '40px' },
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'label': 'Search text',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-success-red c-white m-r-10',
        'onClick': this.add,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    }), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    })) : null, this.props.typeLCase === 'quickstart' ? React.createElement('div', {
        'className': 'col-xs-12 end-xs',
        'key': '2539'
    }, React.createElement(SelectComponent, {
        'className': 'col-xs-4',
        'value': this.state.quickstart_type,
        'options': this.state.quickstartSelectionList,
        'simpleValue': true,
        'onChange': val => {
            this.onChangeQuickstartSelectionList(val);
        }
    }), React.createElement(Button, {
        'className': 'bg-success-red c-white m-r-10',
        'onClick': this.add,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    })) : null) : null, this.props.mode === TEMPLATE_SELECTION_MODE ? React.createElement('div', {
        'className': 'row end-xs m-10 search-box p-5 vertical-align',
        'key': '3136'
    }, React.createElement(Input, {
        'style': { height: '40px' },
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search text',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    }), React.createElement(IconButton, {
        'tooltip': 'Close template selection',
        'className': 'bg-danger m-r-15',
        'onClick': this.props.cancelTemplateSelection,
        'icon': 'close',
        'ripple': true
    })) : null, this.props.programList.length === 0 ? [
        this.props.mode === DEFAULT_MODE ? React.createElement('div', {
            'className': 'w-100 text-center alert-warning m-20',
            'key': '38791'
        }, '\n            There are no ', this.props.typeLCase, 's for the selected filters. Click on + button to create a new ', this.props.typeLCase, ' or modify your search filter criteria.\n        ') : null,
        this.props.mode === TEMPLATE_SELECTION_MODE ? React.createElement('div', {
            'className': 'w-100 text-center alert-warning m-20',
            'key': '38793'
        }, '\n            Follow this ', React.createElement(NavLink, { 'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/p/template/list` }, 'link'), '    to create a ', this.props.typeLCase, ' and then return to create this workout.\n        ') : null
    ] : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.programList, repeatProgram1.bind(this))
    ]), this.props.paginateInfo.page < this.props.paginateInfo.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '9163'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.refreshProgramList(this.props, true, false);
        }
    }))) : null), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow',
        'width': 50
    }, React.createElement('form', {
        'className': 'medium-labels p-b-30',
        'onSubmit': this.props.handleSubmit(this.applyFilter)
    }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n                  Filters\n            '), React.createElement(Button, {
        'onClick': () => {
            this.resetFilter();
        },
        'className': 'small-button',
        'label': 'Reset',
        'raised': true,
        'primary': true
    }), React.createElement(Button, {
        'onClick': () => {
            this.applyFilter();
        },
        'className': 'small-button bg-success m-l-10',
        'label': 'Apply',
        'raised': true
    }), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), React.createElement('div', { 'className': 'filter-section p-10' }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'my_deleted_templates',
        'label': 'View My Hidden Templates',
        'component': Switch
    })), React.createElement('hr', { 'className': 'dashed' }), !this.props.my_deleted_templates ? React.createElement('div', { 'key': '10747' }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'is_favorite',
        'label': 'Show Just My Favorite Templates',
        'component': Switch
    }), React.createElement('span', { 'className': 'badge bg-success m-l-10' }, this.props.filterData.favorites ? this.props.filterData.favorites : 0)), React.createElement(Field, {
        'className': 'm-t-10',
        'name': 'program_sources',
        'label': 'Program Sources',
        'component': Checkbox,
        'showCountLabel': true,
        'options': this.props.programSources
    }), React.createElement('div', { 'className': 'm-t-10' }, React.createElement(Field, {
        'name': 'category',
        'label': 'Program Category',
        'component': Select,
        'options': this.props.filterData.category,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category.id === 5 ? React.createElement(Field, {
        'name': 'sports',
        'label': 'Sports Name',
        'component': Select,
        'options': this.props.filterData.sports,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'multi': true,
        'optionRenderer': this.selectRender,
        'key': '12146'
    }) : null), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category.id === 5 ? React.createElement(Field, {
        'name': 'sports_exercise_type',
        'label': 'Sports Condition',
        'component': Select,
        'options': this.props.filterData.sports_exercise_type,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectRender,
        'key': '12748'
    }) : null)) : null))));
}