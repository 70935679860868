import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { PARTNER } from '../../../core/constants/constants';
import { Link } from 'react-router-dom';
import Checkbox from 'react-toolbox/lib/checkbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
export default function () {
    return React.createElement('div', { 'className': 'gi-login d-flex m-t-30 full-height row center-xs middle-xs  full-height c-white w-100' }, React.createElement(AppBar, {
        'className': 'main-app-bar-gi',
        'style': { width: '100%' }
    }, React.createElement('a', { 'href': 'https://grayinstitute.com/' }, React.createElement('div', { 'className': 'login-page-logo' }))), React.createElement('div', { 'className': 'w-100' }, PARTNER.app_type === 'tri' ? React.createElement('div', { 'key': '1071' }, React.createElement('img', {
        'src': '/site_media/static/images/logo_tri.png',
        'className': 'img-responsive',
        'style': {
            height: '61px',
            margin: '0 auto !important'
        }
    })) : null, React.createElement('form', {
        'className': 'bg-white white-labels p-b-38',
        'style': {
            width: '375px',
            margin: '0 auto'
        },
        'onSubmit': this.props.handleSubmit(this.doLogin)
    }, React.createElement('h1', { 'className': 'c-black p-t-24' }, 'Login'), React.createElement(Field, {
        'label': 'Email',
        'className': 'w-100 m-b-20',
        'name': 'email',
        'type': 'text',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement(Field, {
        'label': 'Password',
        'className': 'w-100',
        'name': 'password',
        'type': 'password',
        'component': TextInput
    }), React.createElement('div', {
        'className': 'forgot-pwd',
        'onClick': this.gotoReset
    }, React.createElement('p', { 'style': { cursor: 'pointer' } }, '\n\t\t\t\tForgot Password?\n\t\t\t')), React.createElement('div', { 'className': 'row w-100 p-b-7 center-xs' }, React.createElement(Button, {
        'label': 'Login',
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success big-button',
        'raised': true,
        'style': {
            width: '180px',
            margin: '0'
        }
    })), this.state.is_gi_subdomain ? React.createElement('div', {
        'className': 'row w-100 center-xs signup-container cursor-pointer',
        'key': '2293'
    }, React.createElement('p', {
        'className': 'c-black signup',
        'onClick': this.gotoSignup,
        'style': { margin: '0' }
    }, 'I don\u2019t have a Gray Institute account')) : null)));
}