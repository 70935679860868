let initialState = {
    engagements: [],
    groups: [],
    quickstarts: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLIENT_ALL_WORKOUTS_FILL":
            if(!action.groups){
                return state;
            }    
            return Object.assign({}, state, {
                groups: action.groups,
                engagements: action.engagements,
                quickstarts: action.quickstarts,
            });
        case "CLIENT_ALL_WORKOUTS_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
