import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import ProgressionEditableWrapper from '../progression_editable_wrapper';
import ProgressionEdit from '../progression_edit';
import MultiDatePicker from '../../../utils/common_components/multi_date_picker';
export default function () {
    function repeatProgression1(progression, progressionIndex) {
        return React.createElement('div', {
            'className': 'col-xs-12',
            'style': { zIndex: '1' },
            'key': 'progression' + progression.id
        }, React.createElement(ProgressionEditableWrapper, _.assign({}, {
            'progression': progression,
            'program': this.props.program,
            'disableEditing': false
        }, this.props)));
    }
    return React.createElement('div', {}, React.createElement('div', { 'style': { position: 'relative' } }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement('div', {
            'className': 'bg-accent',
            'style': {
                marginLeft: '32px',
                zIndex: '0',
                height: 'calc(100% - 1px)',
                width: '10px',
                position: 'absolute'
            }
        }),
        React.createElement('div', {
            'className': 'col-xs-12 bg-accent p-20 box-shadow',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'row f-20 between-xs' }, React.createElement('div', { 'className': 'col-xs-10 text-left' }, React.createElement('h6', { 'className': 'f-20 w-100' }, ' ', this.props.program.name, ' '), this.props.program.published ? React.createElement('div', {
            'className': 'badge badge-success p-l-8 p-r-8 f-12 f-300',
            'key': '1466'
        }, 'PUBLISHED') : null, !this.props.program.published ? React.createElement('div', {
            'className': 'badge badge-warning p-l-8 p-r-8 f-12 f-300',
            'key': '1599'
        }, 'DRAFT') : null), React.createElement('div', { 'className': 'col-xs-2 text-right' }, !this.props.disableEditing ? React.createElement(Button, {
            'className': 'bg-success-red c-white c-white m-5 small-button',
            'label': 'SAVE & PUBLISH',
            'onClick': () => {
                this.togglePublish(true);
            },
            'raised': true,
            'key': '1810'
        }) : null, !this.props.disableEditing ? React.createElement(Button, {
            'className': 'bg-primary c-white m-5 small-button',
            'label': 'SAVE AS DRAFT',
            'onClick': () => {
                this.togglePublish(false);
            },
            'raised': true,
            'key': '2036'
        }) : null)), this.props.program.schedule_type !== 2 ? React.createElement('div', {
            'className': 'row middle-xs m-t-10 f-16',
            'key': '2288'
        }, '\n                    Total Length:\n                    ', React.createElement(Chip, { 'className': 'bg-primary m-l-10 chip-thin' }, this.props.program.total_days, ' days')) : null),
        !this.props.disableEditing ? React.createElement(Button, {
            'onClick': this.add,
            'className': 'bg-success-red c-white m-l-10 m-t-20',
            'icon': 'add',
            'floating': true,
            'ripple': true,
            'key': '2938'
        }) : null,
        !this.props.disableEditing && this.props.program.progressions.length === 0 ? React.createElement('div', {
            'className': 'col-xs-12 f-18 start-xs m-t-20 p-0',
            'style': { zIndex: '1' },
            'key': '3127'
        }, React.createElement('span', { 'className': 'bg-danger p-5' }, '\n                    Click on \'+\' button to build a new progression\n                ')) : null,
        _.map(this.props.program.progressions, repeatProgression1.bind(this))
    ])));
}