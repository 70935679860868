// Action Creators
const fillPrimaryPractice = (primary_practice, practice_options) => ({
    type: 'PRACTICE_CLIENT_PRIMARY_PRACTICE_FILL',
    primary_practice,
    practice_options,
});
const cleanModule = () => ({
    type: 'PRACTICE_CLIENT_PRIMARY_PRACTICE_CLEANUP',
});

export default {
    fillPrimaryPractice,
    cleanModule,
};
