import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/quickstart_track";
import store, { history } from '../../redux/store';
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';

class QuickStartTrack extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    };

    render() {
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}
const QuickStartTrackConnected = connect(mapStateToProps, mapDispatchToProps)(QuickStartTrack);
export default QuickStartTrackConnected;
