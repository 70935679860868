import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/engagement_appointment_edit.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field , FieldArray} from 'redux-form';
import {engagementAppointmentPost } from '../../redux/engagement/network/engagement_network';
import moment from 'moment';

class EngagementAppointmentEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doClose = this.doClose.bind(this);
    };

    doClose() {
        this.props.handleClose();
    }

    doSave(data) {     
        let that = this;
        let start_time = moment(data.start_time);
        let end_time = moment(data.end_time);
        let params = {...data, engagement_id: this.props.engagement.id};
        if(params.payment_fee <= 1) {
            Utils.showErrorAlert(" Online payment fee must be greater than or equal to 1");
            return;
        }
        if(this.props.appointment) {
            params.appointment_id = this.props.appointment.id;
        }
        params.start_time = moment(data.appointment_date).hour(start_time.hour()).minute(start_time.minute()).seconds(0).toDate();
        params.end_time = moment(data.appointment_date).hour(end_time.hour()).minute(end_time.minute()).seconds(0).toDate();
        delete params.appointment_date;        
        this.props.engagementAppointmentPost(params).then((response) => {
            that.doClose();
        }).catch((error) => {
            //do nothing
        });        
    }

    render() {
        if (_.isEmpty(this.props.engagement)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

EngagementAppointmentEdit.propTypes = {
    engagement:PropTypes.object.isRequired,    
    appointment: PropTypes.object,
};



const selector = formValueSelector('clientEngagementAppointmentEditForm');
function mapStateToProps(state, ownprops) {
    let appointment = { appointment_date: null, start_time: null, end_time: null};
    if(_.has(ownprops,"appointment") && ownprops.appointment && !_.isEmpty(ownprops.appointment)){
        appointment = {...ownprops.appointment};
        appointment.appointment_date = moment(appointment.calendar.start_time).seconds(0).milliseconds(0).toDate();
        appointment.start_time = moment(appointment.calendar.start_time).seconds(0).milliseconds(0).toDate();
        appointment.end_time = moment(appointment.calendar.end_time).seconds(0).milliseconds(0).toDate();
    }    
    return {
        engagement: ownprops.engagement,
        initialValues: appointment,
        handleClose:ownprops.handleClose,
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            engagementAppointmentPost,            
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};

    let requiredFields = [
        'appointment_date',
        'start_time',
        'end_time',
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};


const EngagementAppointmentEditForm = reduxForm(
    {
        form: 'clientEngagementAppointmentEditForm',
        enableReinitialize: true,
        validate
    }
)(EngagementAppointmentEdit);
const EngagementAppointmentEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(EngagementAppointmentEditForm);
export default EngagementAppointmentEditFormConnected;
