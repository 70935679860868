let initialState = {
    services_offered_list: [],
    services_offered: {}
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "SERVICES_OFFERED_LIST_FILL":
            if (!action.services_offered_list){
                return state;
            }
            return Object.assign({}, state, {
                services_offered_list: action.services_offered_list
            });
        case "SERVICES_OFFERED_GET_FILL":
            if (!action.services_offered){
                return state;
            }
            return Object.assign({}, state, {
                services_offered: action.services_offered
            });
        case "SERVICES_OFFERED_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
