import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/partner_plan_edit.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm,formValueSelector } from 'redux-form';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import {getPartnerDetails} from '../../redux/partner/network/partner_network';
import { getPartnerPlanDetails,postPartnerPlan} from '../../redux/partner/network/partner_plan_network';
import partnerPlanActions from '../../redux/partner/actions/partner_plan_action';

class PartnerPlanEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doPublish = this.doPublish.bind(this);
        this.doDraftSave = this.doDraftSave.bind(this);
        this.state = {publish:false};
    }
    
    componentWillMount() {
        let params = {};
        if(this.props.match.params.plan_id) {
               params.id = this.props.match.params.plan_id;
        }
        this.props.getPartnerDetails({});
        this.props.getPartnerPlanDetails(params);      
    }

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    doPublish(){
        this.setState({publish:true},()=>{
            this.props.handleSubmit(this.doSave)();
       });
    }
    doDraftSave(){
        this.setState({publish:false},()=>{
            this.props.handleSubmit(this.doSave)();
       });
    }

    doSave(data){
        let params = _.pick(data, [
                'name',
                'fee_amount',
                'month_duration_count',
                'plan_payment_type',
                'client_count',
                'available_for_subscription'
                ]
        );
        params.available_for_subscription = !params.available_for_subscription;
        params.is_published = this.state.publish;
        params.libraries = _.map(data.libraries,(item)=>{
            if(item.id){
                return item.id;
            }else{
                  return item;
            }
        });
        params.fee_amount = parseFloat(params.fee_amount);
        if (data.id){
             params.id = data.id;
        }
        this.props.postPartnerPlan(params).then(()=>{
            history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/plans");
        }).catch((error)=>{
            //Do nothing
        });
    }


    render() {
        return Template.apply(this);
    }
}
const selector = formValueSelector('planEditForm');
function mapStateToProps(state) {
    let partner_plan = { ...state.partner.partnerPlanReducer.partner_plan };
    partner_plan.available_for_subscription = !partner_plan.available_for_subscription;
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: {...partner_plan},
        partner_plan: partner_plan,
        plan_payment_type: selector(state, 'plan_payment_type'),
        partner: state.partner.partnerReducer.partner,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getPartnerPlanDetails,
            postPartnerPlan,
            getPartnerDetails,
            cleanUp: partnerPlanActions.partnerPlanInstanceFill
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name','plan_payment_type','fee_amount','client_count'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });



    if(values["plan_payment_type"] === 3){
        if(!values["month_duration_count"]){
             errors["month_duration_count"] = 'Required';
        }else if(values["month_duration_count"] < 1){
             errors["month_duration_count"] = 'Plan Duration should be greater than 1 month';
        }
    }

    if(values["fee_amount"] < 1){
        errors["fee_amount"] = 'Amount should be greater than or equal to 1';
    }

    // if(values["fee_amount"] && values["client_count"] && values["plan_payment_type"]){
    //     if(values["fee_amount"] < 1){
    //         errors["fee_amount"] = 'Amount should be greater than or equal to 1';
    //     }else{
    //         let fee_amount = values["fee_amount"];
    //         let client_count = values["client_count"];
    //         if(values["plan_payment_type"] === 1){
    //             if(client_count === 10 && fee_amount < 9){
    //                 errors["fee_amount"] = 'For client count 10, fee amount cannot be less than 9';
    //             }else if(client_count === 25 && fee_amount < 24){
    //                 errors["fee_amount"] = 'For client count 25, fee amount cannot be less than 24';
    //             }else if(client_count === 250 && fee_amount < 39){
    //                 errors["fee_amount"] = 'For client count 250, fee amount cannot be less than 39';
    //             }
    //         }else if(values["plan_payment_type"] === 2){
    //             if(client_count === 10 && fee_amount < 90){
    //                 errors["fee_amount"] = 'For client count 10, fee amount cannot be less than 90';
    //             }else if(client_count === 25 && fee_amount < 240){
    //                 errors["fee_amount"] = 'For client count 25, fee amount cannot be less than 240';
    //             }else if(client_count === 250 && fee_amount < 390){
    //                 errors["fee_amount"] = 'For client count 250, fee amount cannot be less than 390';
    //             }
    //         }else if(values["plan_payment_type"] === 3 &&  values["month_duration_count"] && values["month_duration_count"] > 0){
    //             let month_duration_count  = values["month_duration_count"];
    //             if(client_count === 10 && fee_amount < (9*month_duration_count)){
    //                 errors["fee_amount"] = 'For client count 10, fee amount cannot be less than '+(9*month_duration_count);
    //             }else if(client_count === 25 && fee_amount < (24*month_duration_count)){
    //                 errors["fee_amount"] = 'For client count 25, fee amount cannot be less than '+(24*month_duration_count);
    //             }else if(client_count === 250 && fee_amount < (39*month_duration_count)){
    //                 errors["fee_amount"] = 'For client count 250, fee amount cannot be less than '+(39*month_duration_count);
    //             }    
    //         }    
    //     }
    // }

    if(!values["libraries"]  || values["libraries"].length === 0){
         errors["libraries"] = 'Required';
    }



    return errors;
};


const PartnerPlanEditForm = reduxForm(
    {
        form: 'planEditForm',
        enableReinitialize: true,
        validate
    }
)(PartnerPlanEdit);


const PartnerPlanEditConnected = connect(mapStateToProps,mapDispatchToProps)(PartnerPlanEditForm);
export default PartnerPlanEditConnected;