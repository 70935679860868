import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/analysis`,
        'render': this.analysisComponent
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/performance`,
        'render': this.performanceComponent
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/summary`,
        'render': this.summaryComponent
    })));
}