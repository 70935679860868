import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/group_goal_tracking_graph";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { getClientGoalTrackingHistory } from '../../redux/group/network/client_goal_tracking';
import Swal from 'sweetalert2';
import actions from '../../redux/group/actions/client_goal_tracking';
import moment from 'moment';

class GroupGoalTrackingGraph extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
    };

    componentWillMount() {
        this.props.getClientGoalTrackingHistory({
            group_goal_client_question_id: this.props.group_goal_client_question.id,
            group_id:this.props.group.id,
            client_id: this.props.client.id,
        });
    }

    goBack() {
        this.props.onBackPressed();
    }

    componentWillUnmount() {
        if(this.props.preventCleanUp){
            return;
        }
        this.props.cleanModule();
    }

    render() {
        if (_.isEmpty(this.props.track_details)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state, ownProps) {
    let group_goal_client_question =  ownProps.group_goal_client_question;
    let tracking_key = "goal_question_" + ownProps.group_goal_client_question.id;
    let track_details = _.cloneDeep(state.group.clientGoalTracking.trackingHistory[tracking_key]);
    track_details = _.map(track_details, (item) => {
        item.tracking_date = moment(item.tracking_date).format("MMMM Do YYYY");
        item.value = item.current_value;
        item.target = group_goal_client_question.target_value;
        return item;
    });
    let dots = (!track_details || typeof track_details === 'undefined' || track_details.length < 15) ? true : false;
    let returnProps =  {
        group: ownProps.group,
        preventCleanUp: ownProps.preventCleanUp,
        group_goal_client_question:group_goal_client_question,
        track_details: track_details,
        client: ownProps.client,
        dots:dots
    };
    return returnProps;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientGoalTrackingHistory,
        cleanModule: actions.cleanModule,
    }, dispatch);
}

const GroupGoalTrackingGraphConnected = connect(mapStateToProps, mapDispatchToProps)(GroupGoalTrackingGraph);
export default GroupGoalTrackingGraphConnected;