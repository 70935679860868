import * as React from 'react';
import * as _ from 'lodash';
import { Layout } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import Input from 'react-toolbox/lib/input';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import ExercisePreview from '../../../exercise/components/exercise_preview';
import moment from 'moment';
export default function () {
    function repeatContent1(content, index) {
        return React.createElement('div', {
            'className': 'width-260 box-shadow bg-white m-l-8 m-r-8 m-b-18 ribbon-container',
            'key': 'content_list' + index
        }, React.createElement('div', {}, React.createElement('div', {}, content.news_media || content.thumbnail_url ? React.createElement('div', {
            'className': 'thumbnail box-260-150 p-relative',
            'style': { backgroundImage: 'url(' + encodeURI(content.thumbnail_url || content.news_media[0].thumbnail_url) + ')' },
            'key': '4127'
        }, this.props.content_type === 'video' || this.props.content_type === 'mini_course' ? React.createElement('div', {
            'className': 'play-btn p-absolute',
            'key': '4379'
        }) : null, this.props.content_type === 'video' || this.props.content_type === 'podcast' || this.props.content_type === 'mini_course' ? React.createElement('div', { 'key': '4560' }, content.news_media ? React.createElement('div', { 'key': '4727' }) : null) : null) : null), React.createElement('div', { 'className': 'row bg-white center-xs middle-xs m-0 m-b-8 m-t-12' }, React.createElement(TooltipWrapper, {
            'tooltip': content.title,
            'className': 'col-xs-' + (content.description ? '9' : '12') + ' video-title f-16 text-l text-capital p-l-13 p-r-13'
        }, '\n                        ', content.title.length > 25 ? content.title.substring(0, 35) + '...' : content.title, '\n                    '), content ? React.createElement('div', { 'key': '5661' }, content.published === false ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '5707'
        }, '\n                            Draft\n                        ') : null) : null, content.published === true ? React.createElement('div', { 'key': '5886' }, React.createElement('div', { 'className': 'bg-published ribbon' }, '\n                            Published\n                        ')) : null), React.createElement('div', { 'className': 'p-l-13 p-r-13 m-t-8' }, React.createElement('span', { 'className': 'subtitle f-12' }, content.category, '  \u2022'), React.createElement('span', { 'className': 'subtitle f-12 m-r-3' }, ' \n                       ', moment(content.publish_date).format('MMM D'), ',\n                    '), React.createElement('span', { 'className': 'subtitle f-12' }, moment(`${ content.publish_date } ${ content.publish_time }`).format('hh:mm A'), ' UTC')), React.createElement('div', { 'className': 'subtitle f-12 p-l-5 p-l-13 p-r-13 m-t-8' }, 'Added by ', content.author), React.createElement('div', { 'className': 'view-btn' }, React.createElement('div', {
            'tooltip': 'Views',
            'className': 'w-100 p-l-5 text-left m-r-23 c-grey small-icon-button'
        }, React.createElement('span', { 'className': 'views-icon m-l-9' }), React.createElement('span', { 'className': 'p-l-5' }, content.views, ' views'))), React.createElement('div', { 'className': 'row center-xs' }, [
            React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-b-8',
                'key': '70181'
            }, React.createElement(IconButton, {
                'icon': 'edit',
                'tooltip': 'Edit ' + this.props.content_type + ' Details',
                'className': 'm-r-23 c-grey small-icon-button',
                'onClick': () => {
                    this.edit(content.id);
                }
            })),
            React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '70183'
            })
        ], content.deleted_date !== null ? [content.is_owner ? React.createElement(IconButton, {
                'icon': 'deactivate',
                'tooltip': 'Unhide ' + this.props.content_type,
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.delete(content.id, 'unhide');
                },
                'key': '80061'
            }) : null] : null)));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'row start-xs m-t-18 m-r-10' }, React.createElement('div', { 'className': 'col-xs-12 col-sm-10 col-md-8 p-relative no-box-shadow search-box m-b-20 p-5  vertical-align' }, React.createElement('div', {
        'className': 'w-25 m-r-10 search-category',
        'style': { height: '48px' }
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'category',
        'component': Select,
        'label': 'Category',
        'options': this.props.categories,
        'valueKey': 'db_value',
        'labelKey': 'label'
    }))), this.props.selectedClientForAction !== null ? React.createElement('div', {
        'className': 'm-r-20',
        'key': '3113'
    }, React.createElement(Button, {
        'className': 'bg-accent-dark m-r-20 m-t-5',
        'onClick': this.createWorkoutFromWorkoutCart,
        'icon': 'shopping_cart',
        'floating': true,
        'ripple': true,
        'mini': true
    }, React.createElement('small', { 'className': 'notification-badge' }, this.props.workoutCartExeciseList.length))) : null), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-r-10' },
        _.map(this.props.contentList, repeatContent1.bind(this)),
        this.props.paginateInfo.total_pages && this.props.paginateInfo.page < this.props.paginateInfo.total_pages - 1 ? React.createElement('div', {
            'className': 'row center-xs m-30 w-100',
            'key': '8485'
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
            'label': 'Load more content...',
            'className': 'height-fit bg-success-border text-capital c-black',
            'onClick': () => {
                this.loadMore(true);
            }
        }))) : null
    ]), React.createElement(ExercisePreview, {
        'exercise': this.state.previewingExercise,
        'onClosePreview': () => {
            this.setState({ previewingExercise: null });
        }
    })));
}