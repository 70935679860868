// Action Creators
const facilityListFill = (facility_list) => ({
    type: 'STUDIO_FACILITY_LIST',
    facility_list,
});

const facilityObjectFill = (facility) => ({
    type: 'STUDIO_FACILITY_OBJECT',
    facility,
});

const facilityBookingListFill = (facility, booking_slots) => ({
    type: 'STUDIO_FACILITY_BOOKING_LIST',
    facility,
    booking_slots
});

const facilityBookingObjectFill = (facility, booking_slot, client_list) => ({
    type: 'STUDIO_FACILITY_BOOKING_OBJECT',
    facility,
    booking_slot,
    client_list,
});

const cleanModule = () => ({
    type: 'STUDIO_FACILITY_CLEANUP',
});

export default {
    facilityListFill,
    facilityObjectFill,
    facilityBookingListFill,
    facilityBookingObjectFill,
    cleanModule,
};
