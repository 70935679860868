import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
export default function () {
    function repeatGoal_question1(goal_question, goal_questionIndex) {
        return React.createElement('div', {
            'className': 'between-xs center-xs m-l-10',
            'key': 'goal_question' + goal_questionIndex
        }, !goal_question.question_deleted_date ? React.createElement('div', {
            'className': 'row',
            'key': '1778'
        }, React.createElement('div', { 'className': 'col-xs-8' }, React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].question',
            'className': 'w-100',
            'type': 'text',
            'multiline': true,
            'component': TextInput,
            'label': goal_question.question,
            'disabled': true + '/'
        })), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].metric_unit',
            'component': Select,
            'label': 'Metric Unit',
            'valueKey': 'id',
            'labelKey': 'unit_name',
            'options': this.props.goalTrackingUnits,
            'value': goal_question.metric_unit,
            'disabled': true
        })), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].initial_value',
            'type': 'number',
            'component': TextInput,
            'label': 'Baseline Value'
        })), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].target_value',
            'type': 'number',
            'component': TextInput,
            'label': 'Target Value'
        }))) : null);
    }
    return React.createElement('form', {
        'onSubmit': this.props.handleSubmit(this.doSave),
        'className': 'small-labels'
    }, React.createElement('div', { 'className': 'row box-shadow' }, React.createElement('div', { 'className': 'col-xs-12 bg-beige p-t-5 p-l-5 p-r-5' }, React.createElement('h4', {}, this.props.goal.text)), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 bg-white p-b-5 p-l-5 p-r-5' },
        React.createElement('div', { 'className': 'row start-xs middle-xs m-b-15 m-t-15' }, React.createElement('div', { 'className': 'col f-18 material-label p-l-10' }, '\n                    Goal Metrics\n                ')),
        _.map(this.props.goal.client_goal_questions, repeatGoal_question1.bind(this))
    ])), React.createElement('div', { 'className': 'row-margin end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'm-l-10 bg-success',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    })));
}