import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progression_view_new";
import Utils from '../../utils/utils';
import { history } from "../../redux/store";

class ProgressionView extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.preview = this.preview.bind(this);
        this.do_track = this.do_track.bind(this);
        this.viewIntroVideo = this.viewIntroVideo.bind(this);
        this.closeIntroVideo = this.closeIntroVideo.bind(this);
        this.sliderSettings = {
            dots: true,
            centerMode: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 2,
            variableWidth: true,
        };
        this.state = {
            previewingExercise: null,
            show_intro_video: false,
        };
    }

    do_track(track_status){
        let params = {
            has_tracked:track_status,
            progression_id:this.props.progression.id,
            date:this.props.track_date,
        };
        this.props.trackProgressionCallback(params);
    }

    preview(exercise) {
        this.setState({
            previewingExercise: exercise,
        });
    }

    viewIntroVideo() {
        this.setState({
            show_intro_video: true,
        });
    }

    closeIntroVideo() {
        this.setState({
            show_intro_video: false,
        });
    }

    render() {
        return Template.apply(this);
    }

}

export default ProgressionView;
