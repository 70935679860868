import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { history } from '../../../redux/store';
import QuestionEdit from '../question_edit.jsx';
import Sortable from 'react-anything-sortable';
import SortableItem from '../../../utils/common_components/sortable_item';
export default function () {
    function repeatQuestion1(question, questionIndex) {
        return React.createElement(SortableItem, {
            'key': 'question' + questionIndex,
            'className': 'vertical w-100',
            'sortData': question
        }, React.createElement('div', {
            'onClick': () => {
                this.selectQuestion(question);
            },
            'className': 'm-t-10 m-l-10 d-flex'
        }, React.createElement('span', { 'className': 'bg-white p-5 m-r-1 box-shadow vertical-align' }, question.field_type === 99 ? React.createElement(FontIcon, {
            'value': 'note',
            'key': '3613'
        }) : null, question.field_type !== 99 ? React.createElement(FontIcon, {
            'value': 'help_outline',
            'key': '3708'
        }) : null), React.createElement('div', { 'className': ` f-12 p-10 box-shadow pointer flex-auto w-90 ${ !_.isEmpty(this.props.selected_question) && question.field_guid === this.props.selected_question.field_guid ? 'bg-primary box-shadow' : 'bg-white' }   ` }, '\n                                ', question.name, '\n                            '), this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement('span', {
            'className': 'bg-white p-5 m-l-1 box-shadow handle vertical-align',
            'style': { cursor: 'ns-resize' },
            'key': '4181'
        }, React.createElement(FontIcon, { 'value': 'reorder' })) : null));
    }
    return React.createElement('div', { 'className': 'row m-0' }, React.createElement('div', { 'className': 'col-xs-12 bg-accent p-5' }, React.createElement(Button, {
        'onClick': () => {
            history.goBack();
        },
        'className': 'small-button m-l-5 m-r-5 bg-beige',
        'label': 'Go Back',
        'icon': 'undo',
        'raised': true
    }), React.createElement('span', {}, this.props.intake.name), this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.addQuestion,
        'className': 'pull-right small-button m-r-5 bg-success',
        'label': 'Add Question',
        'icon': 'add',
        'primary': true,
        'raised': true,
        'key': '1277'
    }) : null, React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.viewPreview,
        'className': 'pull-right small-button bg-accent-light m-r-5 c-white',
        'label': 'Preview',
        'icon': 'pageview',
        'raised': true
    }), this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.publishIntakeForm,
        'className': 'pull-right small-button m-r-5' + ' ' + _.transform({
            'bg-danger': this.props.intake.published === true,
            'bg-primary': this.props.intake.published === false
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'label': this.props.intake.published === true ? 'Unpublish' : 'Publish',
        'tooltip': this.props.intake.published === true ? 'Unpublish form from usage' : 'Publish form for usage',
        'icon': 'public',
        'raised': true,
        'key': '1905'
    }) : null, this.props.intake.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'disabled': this.state.add_question_disabled,
        'onClick': this.updateFormName,
        'className': 'pull-right small-button bg-info m-r-5',
        'label': 'Update Name',
        'icon': 'create',
        'raised': true,
        'key': '2583'
    }) : null), React.createElement('div', { 'className': 'col-xs-5 p-5' }, React.createElement.apply(this, [
        Sortable,
        {
            'className': 'vertical-container',
            'sortHandle': 'handle',
            'onSort': this.handleSort,
            'direction': 'vertical',
            'containment': true,
            'dynamic': true
        },
        _.map(this.props.intake.questions, repeatQuestion1.bind(this))
    ])), React.createElement('div', { 'className': 'col-xs-7' }, React.createElement(QuestionEdit, {})));
}