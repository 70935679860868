import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import {history} from '../../redux/store';
import Template from "./templates/canned_messages.rt";
import { getCannedMessageList } from '../../redux/message/network/canned_message_network';
import CannedMessageAction from '../../redux/message/actions/canned_message_creator';

class CannedMessages extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
    };
    
    componentWillMount() {
        this.props.getCannedMessageList();
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    editCannedMessage(message_type) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/branding/email_template/edit/'+message_type);
    }


    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        canned_message_list: state.message.cannedMessageReducer.canned_message_list
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getCannedMessageList,
        cleanUp: CannedMessageAction.cannedMessageCleanup,
    }, dispatch),
    dispatch
});

const CannedMessagesConnected = connect(mapStateToProps,mapDispatchToProps,)(CannedMessages);
export default CannedMessagesConnected;
