import React from 'react'
import Dropzone from 'react-dropzone'

function DropzoneComponent({children,style,multiple,accept,onDrop,classname}) {
    return (
        <Dropzone onDrop={onDrop} style={style} multiple={multiple} accept={accept} class={classname}>
        {({getRootProps, getInputProps}) => (
            <section>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {children}
            </div>
            </section>
        )}
        </Dropzone>
    )
}

export default DropzoneComponent;