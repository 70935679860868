import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import Dialog from 'react-toolbox/lib/dialog';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select_form';
import RadioGroup from '../../../utils/common_components/radio_group_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Switch from '../../../utils/common_components/switch_form';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { history } from '../../../redux/store';
import { CardTitle } from 'react-toolbox/lib/card';
export default function () {
    function repeatField1(field, fieldIndex) {
        return [
            field.field_type === 99 ? React.createElement('div', {
                'className': 'col-xs-12',
                'key': '19531'
            }, React.createElement('div', { 'className': 'm-t-10 text-center f-20 f-500' }, field.name), field.note !== '' ? React.createElement('div', {
                'className': 'bg-body border-body w-100 p-10 m-t-10 m-b-10',
                'style': { borderLeft: '4px solid #0288C4' },
                'key': '2219'
            }, React.createElement('p', { 'className': 'c-accent-dark' }, field.note)) : null) : null,
            field.field_type === 1 ? React.createElement('div', {
                'className': 'bg-body border-body p-l-10 p-r-10 p-t-10 m-5',
                'key': '19533'
            }, React.createElement(Field, {
                'multiline': true,
                'placeholder': 'Please enter information',
                'name': field.field_guid,
                'type': 'text',
                'component': TextInput,
                'label': field.name.toUpperCase()
            })) : null,
            field.field_type === 2 ? React.createElement('div', {
                'className': 'bg-body border-body p-l-10 p-r-10 p-t-10 m-5',
                'key': '19535'
            }, React.createElement(Field, {
                'className': 'p-b-10',
                'placeholder': 'Please enter information',
                'name': field.field_guid,
                'type': 'number',
                'component': TextInput,
                'label': field.name.toUpperCase()
            })) : null,
            field.field_type === 3 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '19537'
            }, React.createElement(Field, {
                'className': 'blue-highlight',
                'name': field.field_guid,
                'component': Select,
                'label': field.name.toUpperCase(),
                'options': field.field_choices,
                'placeholder': 'Please enter information'
            }), field.show_additional_field ? React.createElement('div', {
                'className': 'row w-100',
                'key': '3731'
            }, React.createElement(Field, {
                'className': 'col-xs-12',
                'placeholder': 'Enter any additional Info',
                'multiline': true,
                'name': field.field_guid + '_additional_value',
                'type': 'text',
                'component': TextInput
            })) : null) : null,
            field.field_type === 14 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '19539'
            }, React.createElement(Field, {
                'className': 'vertical-align w-100 row',
                'name': field.field_guid,
                'component': RadioGroup,
                'labelString': field.name.toUpperCase(),
                'options': field.field_choices,
                'colXSNum': 4,
                'placeholder': 'Please enter information'
            }), field.show_additional_field ? React.createElement('div', {
                'className': 'row w-100',
                'key': '4589'
            }, React.createElement(Field, {
                'className': 'col-xs-12',
                'placeholder': 'Enter any additional Info',
                'multiline': true,
                'name': field.field_guid + '_additional_value',
                'type': 'text',
                'component': TextInput
            })) : null) : null,
            field.field_type === 4 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '195311'
            }, React.createElement(Field, {
                'name': field.field_guid,
                'label': field.name.toUpperCase(),
                'component': Checkbox,
                'options': field.field_choices
            }), field.show_additional_field ? React.createElement('div', {
                'className': 'row w-100',
                'key': '5352'
            }, React.createElement(Field, {
                'className': 'col-xs-12',
                'placeholder': 'Enter any additional Info',
                'multiline': true,
                'name': field.field_guid + '_additional_value',
                'type': 'text',
                'component': TextInput
            })) : null) : null,
            field.field_type === 5 ? React.createElement('div', {
                'className': 'bg-body border-body p-10 m-5',
                'key': '195313'
            }, React.createElement(Field, {
                'name': field.field_guid,
                'component': DatePicker,
                'label': field.name.toUpperCase()
            })) : null
        ];
    }
    return React.createElement('div', { 'className': 'm-t-30 p-t-30' }, React.createElement(Button, {
        'onClick': () => {
            history.goBack();
        },
        'className': 'bg-none d-flex back-btn c-grey text-capital',
        'label': 'Go Back',
        'icon': 'undo'
    }), React.createElement('form', {
        'className': 'medium-labels',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row m-0' }, React.createElement('div', { 'className': 'col-xs-12 bg-white d-flex flex-sb p-5' }, React.createElement(CardTitle, {
        'title': this.props.intake.name,
        'className': 'p-0 m-l-10 width-fit c-black m-t-8 m-b-18'
    }), React.createElement('div', { 'className': 'row p-b-2 p-l-5 p-r-5' }, React.createElement(Button, {
        'type': 'submit',
        'disabled': this.props.submitting,
        'className': 'no-box-shadow pull-right c-white text-capital bg-success-red m-r-5',
        'label': 'Save',
        'raised': true
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 bg-white full-height p-10' },
        _.map(this.props.intake.questions, repeatField1.bind(this))
    ]))));
}