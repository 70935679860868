import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/workfit_notification_template_edit.rt";
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { reduxForm } from 'redux-form';
import { getNotificationTemplate, saveNotificationTemplate } from '../../../redux/dhf/workfit/network/workfit_network';
import WorkfitAction from '../../../redux/dhf/workfit/actions/workfit_actions';
import Swal from 'sweetalert2';

class WorkfitNotificationTemplateEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);   
        this.saveWorkfitNotificationTemplate = this.saveWorkfitNotificationTemplate.bind(this);     
    };
    
    componentWillMount() {
        if(this.props.match.params.notification_template_id) {
            let params = {
                'notification_template_id': this.props.match.params.notification_template_id,
            };
            this.props.getNotificationTemplate(params);
        } else {
            this.props.getNotificationTemplate({});
        }
    }

    saveWorkfitNotificationTemplate(data) {
        let params = {...data};
        if(this.props.match.params.notification_template_id) {
            params['notification_template_id'] = this.props.match.params.notification_template_id;
        }
        this.props.saveNotificationTemplate(params).then((response) => {
            Utils.showSuccessAlert("Notification tempalte has been successfully saved");
            history.push("/practice/"+this.props.selectedRole.practice.id+"/workfit/notification_templates");
        }).catch(() => { });
    }
    
    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        notification_template: state.workfit.workfitReducer.notification_template,
        initialValues: state.workfit.workfitReducer.notification_template,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getNotificationTemplate,
            saveNotificationTemplate,            
            cleanUp: WorkfitAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'notification_text',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const WorkfitNotificationTemplateEditForm = reduxForm({
    form: 'WorkfitNotificationTemplateEditForm', 
    enableReinitialize: true, 
    validate
})(WorkfitNotificationTemplateEdit);


const WorkfitNotificationTemplateEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(WorkfitNotificationTemplateEditForm);
export default WorkfitNotificationTemplateEditFormConnected;