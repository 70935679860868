import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import Checkbox from '../../../utils/common_components/checkbox_form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import Select from '../../../utils/common_components/select';
import { FieldArray } from 'redux-form';
import Dropzone from '../../../utils/common_components/dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
import HelpTip from '../../../utils/common_components/help_tip';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    function repeatAssociated_content1(associated_content, index) {
        return React.createElement('div', {
            'key': 'associated_list' + index,
            'className': 'associated-content'
        }, associated_content ? React.createElement('div', {
            'className': 'associated-title',
            'key': '11214'
        }, React.createElement('div', {
            'className': 'image-container',
            'style': {
                backgroundImage: 'url(' + associated_content.thumbnail_url + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }
        }), React.createElement('div', { 'className': 'text' }, '\n                                    ', associated_content.title, '\n                                ')) : null);
    }
    return React.createElement('div', { 'className': 'row homefit-group-edit' }, React.createElement(Button, {
        'className': 'm-t-15 d-flex back-btn c-grey',
        'onClick': this.cancel
    }, '\n        Groups\n    '), React.createElement(Card, { 'className': 'w-100 bg-none no-box-shadow' }, React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement('form', {
        'onSubmit': this.props.handleSubmit(this.onSubmit),
        'className': 'bg-none d-flex bg-white no-box-shadow'
    }, React.createElement('div', {
        'className': 'media-info m-r-24',
        'style': { width: '76.15%' }
    }, React.createElement('div', { 'className': 'w-100 row p-0 p-b-0 m-b-16 add-info box-shadow-10' }, React.createElement(CardTitle, {
        'title': 'Group',
        'className': 'c-black m-b-10'
    }), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'd-flex w-100' }, React.createElement('div', { 'className': 'w-48' }, this.props.guid ? React.createElement('div', { 'key': '2498' }, this.props.is_logo_uploaded ? React.createElement('div', {
        'className': 'm-b-20 w-48',
        'key': '2564'
    }, React.createElement(Avatar, { 'className': 'avatar-image-100 box-100 bg-transparent' }, React.createElement('img', { 'src': this.props.logo_url }))) : null) : null, React.createElement('div', { 'className': 'd-flex flex-column' }, React.createElement('div', { 'className': 'col-md-6' }, Object.keys(this.state.holdImage).length ? React.createElement('div', {
        'className': 'thumbnail',
        'style': {
            backgroundImage: 'url(' + encodeURI(this.props.thumbnail_url) + ')',
            width: '300px',
            height: '180px'
        },
        'key': '3103'
    }) : null, !Object.keys(this.state.holdImage).length && this.props.initialValues.thumbnail_url ? React.createElement('div', {
        'className': 'thumbnail',
        'style': {
            backgroundImage: 'url(' + encodeURI(this.props.thumbnail_url) + ')',
            width: '300px',
            height: '180px'
        },
        'key': '3317'
    }) : null), React.createElement('div', { 'className': 'd-flex w-100 m-t-15' }, React.createElement(Dropzone, {
        'className': 'w-48 dummy-suppress-warning',
        'multiple': false,
        'accept': 'image/*',
        'onDrop': this.holdImage
    }, React.createElement(Button, {
        'label': 'Upload Cover image',
        'className': 'add-blue-btn',
        'primary': true,
        'raised': true
    })), Object.keys(this.state.holdImage).length ? React.createElement(Button, {
        'onClick': this.deleteholdImage,
        'icon': 'cancel',
        'className': 'width-fit m-l-10',
        'primary': true,
        'raised': true,
        'key': '4002'
    }) : null))), React.createElement('div', { 'className': 'w-48' }, React.createElement('div', { 'className': 'm-b-24' }, React.createElement(Field, {
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Group Name'
    })), React.createElement('div', { 'className': 'w-60' }, React.createElement(Field, {
        'name': 'group_treatment_cycle',
        'component': Select,
        'label': 'Type',
        'options': this.state.treatment_cycle,
        'clearable': false,
        'disabled': this.props.group.is_group_in_progress
    })))), React.createElement(CardTitle, {
        'title': 'Intro Video',
        'className': 'c-black form-subtitle m-t-20 f-18 w-100'
    }), React.createElement('div', { 'className': 'preview-container d-flex w-100' }, this.props.video_guid ? React.createElement('div', {
        'className': 'm-b-20 w-60',
        'key': '5182'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-player',
        'handleUploadProcessing': true,
        'videoList': [this.props.video_guid]
    })) : null, React.createElement('div', { 'className': 'row video-btn-container' }, React.createElement(VimeoUploader, {
        'className': 'col w-100 m-l-4 add-media-btn',
        'onUploadCompleted': this.onVideoUploaded,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'name': 'add_media',
        'buttonText': this.props.video_guid ? 'Change Video' : 'Add Media'
    }), this.props.video_guid ? React.createElement(Button, {
        'onClick': this.deleteVideo,
        'label': 'Delete Video',
        'className': 'm-l-6 bg-danger-light',
        'primary': true,
        'raised': true,
        'key': '6069'
    }) : null)), React.createElement('div', { 'className': 'm-t-24' }, React.createElement(Field, {
        'name': 'description',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Description',
        'multiline': true
    })), React.createElement('div', { 'className': 'w-100 m-t-24 d-flex m-b-25 align-center' }, !this.state.changeCoach ? React.createElement('div', {
        'className': 'coach-field border-around m-r-16 p-relative w-48',
        'key': '6754'
    }, React.createElement(Field, {
        'label': 'Author',
        'className': 'w-100 p-t-5',
        'name': 'trainer[practice_name]',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    })) : null, !this.state.changeCoach ? React.createElement('div', {
        'className': 'w-20',
        'key': '7316'
    }, React.createElement(Button, {
        'label': this.props.match.params.group_id ? 'Change Coach' : 'Add Coach',
        'disabled': this.props.submitting,
        'className': 'm-l-10 bg-success-red small-button text-capital c-white w-100',
        'onClick': this.changeCoach,
        'style': { width: 'fit-content !important' }
    })) : null, this.state.changeCoach ? React.createElement('div', {
        'className': 'border-around w-100 m-r-16',
        'key': '7768'
    }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'trainer',
        'component': Select,
        'label': 'Coaches',
        'options': this.props.authors,
        'valueKey': 'id',
        'labelKey': 'practice_name',
        'onChange': this.selectTrainer,
        'clearable': false
    })) : null))), React.createElement(Card, { 'className': 'monetization box-shadow-10 p-t-24 p-b-36 p-l-24 p-r-24 m-r-20 m-b-16' }, React.createElement(CardTitle, {
        'title': 'Monetization',
        'className': 'c-black form-subtitle f-18 w-100'
    }), React.createElement('div', { 'className': 'w-48 text-left d-flex align-center m-b-10' }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'is_paid',
        'component': Checkbox,
        'label': 'Is Program Paid'
    })), React.createElement('div', { 'className': 'd-flex w-50 m-b-25' }, React.createElement('div', { 'className': 'w-48 m-r-16' }, React.createElement(Field, {
        'name': 'unit_price',
        'type': 'text',
        'component': TextInput,
        'label': 'Unit Price'
    })))), React.createElement.apply(this, [
        Card,
        { 'className': 'related-content box-shadow-10 p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' },
        React.createElement(CardTitle, {
            'title': 'Associated Content (Optional)',
            'className': 'c-black form-subtitle m-b-28 f-18 w-100'
        }),
        React.createElement('div', { 'className': 'p-relative search-box no-box-shadow m-b-20 p-5  vertical-align' }, React.createElement('div', { 'className': 'w-50 m-r-15' }, React.createElement(Field, {
            'className': 'w-100 p-t-0',
            'name': 'associated_category',
            'component': Select,
            'label': 'Category',
            'valueKey': 'db_value',
            'labelKey': 'label',
            'options': this.props.categories,
            'onChange': this.listContent
        })), React.createElement('div', { 'className': 'associated-item  p-relative w-50' }, React.createElement('span', { 'className': 'placeholder-text' }, 'Search by title'), React.createElement(Field, {
            'component': Select,
            'multi': true,
            'className': 'flex-auto p-0 m-r-5',
            'icon': 'search',
            'options': this.props.homefitAssociatedContentOptions,
            'valueKey': 'id',
            'labelKey': 'title',
            'type': 'text',
            'name': 'associated_content'
        }))),
        _.map(this.props.associated_content, repeatAssociated_content1.bind(this))
    ])), React.createElement('div', { 'className': 'publish-info p-0' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Save',
        'onClick': () => this.save(false),
        'className': 'bg-success m-b-12 m-l-10 w-100',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'onClick': this.cancel,
        'className': 'm-l-5 bg-success-border w-100',
        'raised': true
    }))))))));
}