// Action Creators
const habitTemplateListFill = (habit_template_list,paginate_info) => ({
    type: 'HABIT_TEMPLATE_LIST_FILL',
    habit_template_list: habit_template_list,
    paginate_info: paginate_info,
});

const habitTemplateFill = (habit_template, supporting_data) => ({
    type: 'HABIT_TEMPLATE_FILL',
    habit_template: habit_template,
    supporting_data: supporting_data,
});

const habitTemplateCleanup = () => ({
    type: 'HABIT_TEMPLATE_CLEANUP',
});

export default {
    habitTemplateListFill,
    habitTemplateFill,
    habitTemplateCleanup,
};
