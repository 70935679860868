import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const postGroupGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_goal_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Saved Successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addGroupGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_goal_add";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Added Successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteGroupGoal = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_goal_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Deleted Successfully");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    postGroupGoal,
    addGroupGoal,
    deleteGroupGoal,
}