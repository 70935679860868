import  React  from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/move_videos.rt";
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
// import Utils from '../../../utils/utils';
import store, { history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import swal from 'sweetalert2';
import { getMoveVideoList, deleteMoveVideo,hideHomefitWorkout } from '../../redux/dhf/move/network/move_network_new';
import MoveAction from '../../redux/dhf/move/actions/move_actions';
import { getPracticePartnerList, getAuthorList } from '../../redux/partner/network/partner_network';
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import { CSVLink } from "react-csv";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";

const rows = []

class MoveVideos extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.viewMoveVideo = this.viewMoveVideo.bind(this);
        this.closeMoveVideo = this.closeMoveVideo.bind(this);
        this.editMoveVideo = this.editMoveVideo.bind(this);
        this.add = this.add.bind(this);
        this.deleteMoveVideo = this.deleteMoveVideo.bind(this);
        this.unpublishWorkout = this.unpublishWorkout.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.hideReport = this.hideReport.bind(this);
        this.selectTrainer = this.selectTrainer.bind(this);
        this.state = {
            search: null,
            show_move_video: false,
            move_video: {},
            selectedTrainer: null,
            selectedTrainerName: "",
            showReport: false,
            data : [
                { firstname: "jill", lastname: "smith", age: 22 },
                { firstname: "david", lastname: "warner", age: 23 },
                { firstname: "nick", lastname: "james", age: 26 }
              ],
            headers: [
                { label: 'Workout ID', key: 'id' },
                { label: 'Workout Name', key: 'workout_name' },
                { label: 'Author Name', key: 'author_name' },
                { label: 'Views', key: 'views' },
              ],
              rows: []
        };
    }
    selectTrainer(value){
        this.setState({
            selectedTrainer: value,
            selectedTrainerName: value.practice_name
        })
    }
    hideReport(){
        this.setState({
            showReport: false,
            rows: []
        })
    }
    generateReport() {

        var col = ["Workout ID","Workout Name","Author Name","Views"];
        // var rows = [];
        if(this.state.selectedTrainer !== null){
        this.props.getMoveVideoList({
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "2",
            practice_id: this.props.practice_id,
            trainer: this.state.selectedTrainer,
        }).then((resp)=>{
            resp.move_videos.forEach((video)=>{
                let temp = {
                    Workout_Id: video.id,
                    Workout_Name: video.name,
                    Author_Name: video.trainer.owner.name,
                    Views: video.client_count
                }
                this.state.rows.push(temp);
              })
        }) 
        .then(()=>{
            this.setState({
                showReport: true,
            })
        })
    } else {
        alert("Please select a Coach")
    }
    }

    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        this.props.cleanUp();
        this.fetchData(null, 0);
    }

    fetchData(search_text, page) {
        this.props.getMoveVideoList({
            app_version: "5.1.12",
            current_role_type: "practitioner",
            package_version: "1",
            practice_id: this.props.practice_id,
            'search_text': search_text,
            'page': page
        });
        this.props.getAuthorList({
            "app_version": "5.2",
            "practice_id": 276,
            "current_role_type": "practice_admin",
            package_version: "2"
        });
    }

    loadMore() {
        this.fetchData(this.state.search_text, this.props.paginate_info.page+1)
    }

    unpublishWorkout(workout){
        let params = {
            "move_video_id": workout.id,
            "visibility_type": workout.is_published ? 'hide': 'unhide',
            "package_version": "2",
            "app_version": "5.2",
        }
        this.props.hideHomefitWorkout(params)
        .then(()=> {
            this.props.getMoveVideoList({
                app_version: "5.1.12",
                current_role_type: "practitioner",
                package_version: "1",
                practice_id: this.props.practice_id,
            })
        })
    }

    onSearch(e){
        this.setState({search: e.target.value},()=>{
            this.fetchData(this.state.search, 0);
        });
    }

    viewMoveVideo(video) {
        let move_video = {};
        move_video['title'] = video.name;
        move_video['welcome_text'] = "";
        move_video["video_guid"] = video.video_guid;
        move_video['thumbnail'] = video.thumbnail;
        this.setState({
            show_move_video: true,
            move_video: move_video,
        });
    }

    closeMoveVideo() {
        this.setState({
            show_move_video: false,
            move_video: {}
        });
    }

    editMoveVideo(id) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/workout/edit/" + id);
    }

    add() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/workout/add");
    }

    deleteMoveVideo(id) {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You will be deleting HomeFit workout permanently! Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
                let params = { 
                    move_video_id: id,
                    app_version: "5.1.12",
                    current_role_type: "practitioner",
                    package_version: "1",
                 };
                that.props.deleteMoveVideo(params).then(() => {
                    that.props.getMoveVideoList({
                        app_version: "5.1.12",
                        current_role_type: "practitioner",
                        package_version: "1",
                        practice_id: that.props.practice_id,
                        'page': 0
                    });
                });
            }
            swal.close();              
        }, function (dismiss) {
                        
        });    

    }


    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}


function mapStateToProps(state) {
    return {
        rows,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        move_videos: state.move.moveReducer.move_videos,
        paginate_info: state.move.moveReducer.paginate_info,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        practice_partners:state.partner.partnerReducer.practice_partners,
        authors:state.partner.partnerReducer.authors,
    };
}
const selector = formValueSelector('MoveVideoFilterForm');

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getAuthorList,
        getPracticePartnerList,
        getMoveVideoList,
        deleteMoveVideo,
        hideHomefitWorkout,
        cleanUp: MoveAction.cleanModule,
    }, dispatch),
    dispatch
});
const MoveVideoFilterForm = reduxForm(
    {
        form: 'MoveVideoFilterForm',
        enableReinitialize:  true,
    }
)(MoveVideos);
const MoveVideosConnected = connect(mapStateToProps,mapDispatchToProps)(MoveVideoFilterForm);
export default MoveVideosConnected;
