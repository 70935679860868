import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ClientCalendarHome from '../client_calendar_home';
import ClientGroupClassList from '../client_group_class_list';
import ClientGroupClassBooking from '../client_group_class_booking';
import ClientFacilityList from '../facility/client_facility_list';
import ClientFacilityViewCalendar from '../facility/client_facility_view_calendar';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu'
    }, React.createElement(NavLink, {
        'to': '/client/scheduler/mine',
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'My Calendar')), React.createElement(NavLink, {
        'to': '/client/scheduler/group_class',
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Group Class')), React.createElement(NavLink, {
        'to': '/client/scheduler/facility',
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Studio Facility'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/facility/:facility_id`,
        'component': ClientFacilityViewCalendar
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/facility`,
        'component': ClientFacilityList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class/:group_class_id`,
        'component': ClientGroupClassBooking
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class`,
        'component': ClientGroupClassList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/mine`,
        'component': ClientCalendarHome
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/`,
        'component': ClientCalendarHome
    })));
}