let initialState = {
    conditions_treated_list: [],
    condition_treated: {}
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "CONDITIONS_TREATED_LIST_FILL":
            if (!action.conditions_treated_list){
                return state;
            }
            return Object.assign({}, state, {
                conditions_treated_list: action.conditions_treated_list
            });
        case "CONDITIONS_TREATED_GET_FILL":
            if (!action.condition_treated){
                return state;
            }
            return Object.assign({}, state, {
                condition_treated: action.condition_treated
            });
        case "CONDITIONS_TREATED_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
