import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/set_primary_practice";
import { formValueSelector, reduxForm } from 'redux-form';

import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPrimaryPractice, setPrimaryPractice } from '../../redux/user/network/client_primary_practice';
import Utils from '../../utils/utils';

class PrimaryPracticeUpdate extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.cancel = this.cancel.bind(this);
        this.updatePractice = this.updatePractice.bind(this);
        this.state = {
            primary_practice: null,
        };
    }

    updatePractice(practice) {
        this.setState({ primary_practice: practice });
    }
    
    componentWillMount() {
        this.props.getPrimaryPractice({});
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ primary_practice: nextProps.primary_practice.id });
    }

    cancel() {
        history.back();
    }

    doSave() {
        this.props.setPrimaryPractice({primary_practice: this.state.primary_practice}).then(() => {
            history.back();
        }).catch(() => { });
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPrimaryPractice,
        setPrimaryPractice
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        primary_practice: state.user.clientPrimaryPractice.primary_practice,
        practice_options: state.user.clientPrimaryPractice.practice_options,
        ...ownProps,
    };
}

const PrimaryPracticeUpdateConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrimaryPracticeUpdate);

export default PrimaryPracticeUpdateConnected;
