import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  MenuTemplate from "./templates/workfit_menu";
import store,{history} from '../../../redux/store';
import Utils from '../../../utils/utils';
import BaseComponent from '../../../utils/common_components/basecomponent';
import {Link} from 'react-router-dom';
import MenuAction from '../../../redux/core/actions/menu_state_creator';
import RoleAction from '../../../redux/auth/actions/role_creator';


class Menu extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.goto = this.goto.bind(this);
        this.switchRole = this.switchRole.bind(this);
        this.switchApps = this.switchApps.bind(this);
        this.doLogout = this.doLogout.bind(this);
    };

    switchApps() {
        history.push("/dhf");        
    }

    goto(url){
        history.push(url);
    } 

    switchRole() {
        this.props.chooseRole({});
        localStorage.setItem("selectedRole",0);
        history.push("/");        
    }

    doLogout() {
        this.props.doLogout();
        try {
            window.zE(function () {
                zE.hide();
            });
        } catch (err) {            
        }
    }


    render() {
        if(!this.props.isAuthenticated){
          return (<div>Loading....</div>);
        }
        return MenuTemplate.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        chooseRole:RoleAction.chooseRole,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        roles: state.auth.roleReducer.roles,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

const MenuConnected = connect(mapStateToProps, mapDispatchToProps)(Menu);
export default MenuConnected;

