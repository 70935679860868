import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';

import Template from "./templates/move_video_edit.rt";
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { reduxForm } from 'redux-form';
import { getMoveVideo, saveMoveVideo } from '../../../redux/dhf/move/network/move_network';
import MoveAction from '../../../redux/dhf/move/actions/move_actions';
import moment from 'moment';
// const dateState = new Date();

class MoveVideoEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveMoveVideo = this.saveMoveVideo.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.onIntroVideoUploadCompleted = this.onIntroVideoUploadCompleted.bind(this);
        this.onIntroVideoUploadFailed = this.onIntroVideoUploadFailed.bind(this);
        this.state = {
            video_guid: null,
            thumbnail_url: null,
            intro_video_guid: null,
            intro_thumbnail_url: null,
            publish: false,
            date: null
        };        
    };
    
    componentWillMount() {
        if(this.props.match.params.move_video_id) {
            let params = {
                'move_video_id': this.props.match.params.move_video_id,
            };
            this.props.getMoveVideo(params);
        } else {
            this.props.getMoveVideo({});
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.move_video.video_guid) {
            this.setState({
                video_guid: nextProps.move_video.video_guid,
                thumbnail_url: nextProps.move_video.thumbnail_url,
                date:moment(nextProps.move_video.visible_date, moment.defaultFormat).toDate()
            });
        }
        if(nextProps.move_video.intro_video_guid) {
            this.setState({
                intro_video_guid: nextProps.move_video.intro_video_guid,
                intro_thumbnail_url: nextProps.move_video.intro_thumbnail_url,
                date:moment(nextProps.move_video.visible_date, moment.defaultFormat).toDate()
            });
        }

        // this.setState({
        //     ...this.state,
        //     // date:moment().format(nextProps.move_video.visible_date)
        // });
        // console.log(this.state.date);
        // console.log(nextProps.move_video.visible_date);

    }    
    handleChange = (item, value) => {
        console.log("Handle");
        this.setState({...this.state,
             date: item});
        // console.log(this.state.date);
      };
    handleReset = () => {
        console.log("null");
        this.setState({...this.state,
            date: null});
        // console.log(this.state.date);
      };

    componentWillUnmount() {
        this.props.cleanUp();
    }

    onVideoUploadCompleted(result) {
        let that = this;
        setTimeout(function() {
            that.setState({
                video_guid: result.guid,
                thumbnail_url: result.thumbnail,
            }); 
        }, 500);

    }

    onVideoUploadFailed(error) {        
        this.setState({
            video_guid: null,
            thumbnail_url: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    onIntroVideoUploadCompleted(result) {
        let that = this;
        setTimeout(function() {
            that.setState({
                intro_video_guid: result.guid,
                intro_thumbnail_url: result.thumbnail,
            }); 
        }, 500);

    }

    onIntroVideoUploadFailed(error) {        
        this.setState({
            intro_video_guid: null,
            intro_thumbnail_url: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }


    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/move");
    }

    saveAsDraft() {
        this.setState({publish:false},()=>{
            this.props.handleSubmit(this.saveMoveVideo)();
       });
    }

    saveAndPublish() {
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.saveMoveVideo)();
        });
    }


    saveMoveVideo(data) {
        if(!this.state.video_guid) {
            Utils.showErrorAlert("You have not uploaded video for HomeFit Workout");
            return;
        }
        let params = Utils.flatternFormData(data);
        if(this.props.match.params.move_video_id) {
            params['move_video_id'] = this.props.match.params.move_video_id;
        }
        params['is_published'] = this.state.publish;
        params['video_guid'] = this.state.video_guid;
        params['thumbnail_url'] = this.state.thumbnail_url;
        params['intro_video_guid'] = this.state.intro_video_guid;
        params['intro_thumbnail_url'] = this.state.intro_thumbnail_url;
        params['visible_date'] = this.state.date;
        this.props.saveMoveVideo(params).then((response) => {
            Utils.showSuccessAlert("Your HomeFit workout has been successfully saved");
            history.push("/practice/"+this.props.selectedRole.practice.id+"/move/workouts");
        }).catch(() => { });
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        move_video: state.move.moveReducer.move_video,
        supporting_data: state.move.moveReducer.supporting_data,
        initialValues: state.move.moveReducer.move_video,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getMoveVideo,
            saveMoveVideo,
            cleanUp: MoveAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const MoveVideoEditForm = reduxForm({
    form: 'WrapperVideoEditForm', 
    enableReinitialize: true, 
    validate
})(MoveVideoEdit);


const MoveVideoEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(MoveVideoEditForm);
export default MoveVideoEditFormConnected;