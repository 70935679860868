// Action Creators
const fillProgressionDatesList = (all_dates, date_workouts, date_habits) => ({
    type: 'CLIENT_GROUP_PROGRESSION_DATES_FILL',
    all_dates,
    date_workouts,
    date_habits,
});
const fillProgressionDateInstance= (progression_preview,habits,group) => ({
    type: 'CLIENT_GROUP_PROGRESSION_PER_DATE_INSTANCE_FILL',
    progression_preview,
    habits,
    group
});

const cleanModule = () => ({
    type: 'CLIENT_GROUP_PROGRESSION_DATES_CLEANUP',
});

export default {
    fillProgressionDatesList,
    fillProgressionDateInstance,
    cleanModule,
};
