import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/container";
import BaseComponent from '../../utils/common_components/basecomponent';
import Analysis from './analysis';
import Performance from './performance';
import Summary from './summary';
import { history } from '../../redux/store';
import { getClientHome } from '../../redux/engagement/network/client_network';

class Container extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.analysisComponent = this.analysisComponent.bind(this);
        this.performanceComponent = this.performanceComponent.bind(this);
        this.summaryComponent = this.summaryComponent.bind(this);
        this.submitAnalysis = this.submitAnalysis.bind(this);
        this.submitPerformance = this.submitPerformance.bind(this);
        this.submitSummary = this.submitSummary.bind(this);
        this.constructProps = this.constructProps.bind(this);
        this.state = {
            analysisType: null,
            notes: null,
            rsc: null,
            performanceModality: null,
            analysisItemSelections: {},
        };
    };

    componentWillMount() {
        this.props.getClientHome({ id: this.props.match.params.client_id });
    }

    submitAnalysis(rsc, analysisType, notes, selections) {
        this.setState({
            rsc: rsc,
            analysisType: analysisType,
            notes: notes,
            analysisItemSelections: selections,
        }, () => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/maps3d/' + this.props.match.params.client_id + '/performance');
        });
    }

    submitPerformance(performanceModality) {
        this.setState({
            performanceModality: performanceModality
        }, () => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/maps3d/' + this.props.match.params.client_id + '/summary');
        });
    }

    submitSummary(analysisType) {
        this.setState({
            analysisType: analysisType
        }, () => {
        });
    }

    analysisComponent(routeProps) {
        return (
            <Analysis {...this.constructProps(routeProps) } submitAnalysis={this.submitAnalysis} />
        );
    }

    performanceComponent(routeProps) {
        return (
            <Performance {...this.constructProps(routeProps) } submitPerformance={this.submitPerformance} />
        );
    }

    summaryComponent(routeProps) {
        return (
            <Summary {...this.constructProps(routeProps) } submitSummary={this.submitSummary} />
        );
    }

    constructProps(routeProps) {
        let props = { ...this.props, ...this.state };
        let updatedMatch = { ...routeProps.match, ...props.match, ...{ params: { ...props.match.params, ...routeProps.match.params } } };
        props.match = updatedMatch;
        return props;
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientHome,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        client: state.engagement.clientReducer.client,
        ...ownProps,
    };
}
const ContainerConnected = connect(mapStateToProps, mapDispatchToProps)(Container);
export default ContainerConnected;
