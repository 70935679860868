let initialState = {
    engagement_list: [],
    engagement: {},
    goal_targets: [],
};

export default function clientEngagement(state = initialState, action) {
    switch (action.type) {
        case "CLIENT_ENGAGEMENT_LIST_FILL":
            if (!action.engagement_list){
                return state;
            }
            return Object.assign({}, state, {
                engagement_list: action.engagement_list,
            });
        case "CLIENT_ENGAGEMENT_INSTANCE_FILL":
            if (!action.engagement){
                return state;
            }
            return Object.assign({}, state, {
                engagement: action.engagement,
            });
        case "CLIENT_ENGAGEMENT_GOAL_TARGETS_FILL":
            if (!action.goal_targets){
                return state;
            }
            return Object.assign({}, state, {
                goal_targets: action.goal_targets,
            });
        case "CLIENT_ENGAGEMENT_INSTANCE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
