import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/engagement_define_plan.rt";
import { reduxForm } from 'redux-form';
import { updateEngagementTreatmentDays} from '../../redux/engagement/network/engagement_network';



class ClientEngagementDefinePlan extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doClose = this.doClose.bind(this);
        this.state = {
            goal_tracking_day_options: [
                { value: 6, label: 'Sunday' },
                { value: 7, label: 'Monday' },
                { value: 1, label: 'Tuesday' },
                { value: 2, label: 'Wednesday' },
                { value: 3, label: 'Thursday' },
                { value: 4, label: 'Friday' },
                { value: 5, label: 'Saturday' },
            ]
        };
    };

    doClose() {
        this.props.handleClose();
    }

    doSave(data) {     
        let that = this;
        this.props.updateEngagementTreatmentDays({
            id: this.props.engagement.id,
            goal_tracking_day: data.goal_tracking_day,
        }).then((response) => {
            that.doClose();
        }).catch((error) => {
            //do nothing
        });
    }


    render() {
        if (_.isEmpty(this.props.engagement)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

ClientEngagementDefinePlan.propTypes = {
    engagement:PropTypes.object.isRequired,    
};



function mapStateToProps(state, ownprops) {
    let initialValues = { 
        goal_tracking_day:ownprops.engagement.goal_tracking_day
    };
    return {
        engagement: ownprops.engagement,
        initialValues: initialValues,
        handleClose:ownprops.handleClose
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateEngagementTreatmentDays,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    let requiredFields = [
        'goal_tracking_day',
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ClientEngagementDefinePlanForm = reduxForm(
    {
        form: 'clientEngagementGoalEditForm',
        enableReinitialize: true,
        validate
    }
)(ClientEngagementDefinePlan);
const ClientEngagementDefinePlanConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagementDefinePlanForm);
export default ClientEngagementDefinePlanConnected;
