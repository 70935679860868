import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import Sortable from 'react-anything-sortable';
import SortableItem from '../../../utils/common_components/sortable_item';
import { WORKOUT_TARGET_GROUP } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { NavLink } from 'react-router-dom';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import GoalEdit from '../goal_edit';
import ProgramEdit from '../../../program_new/components/program_edit_new';
import ProgramList from '../../../program_new/components/program_list_new';
import HealthDocumentList from '../../../documents_new/components/document_list_new';
import Chip from 'react-toolbox/lib/chip';
import HabitList from '../group_habit_list';
import HabitEdit from '../group_habit_edit';
import GroupLatestGoalTracking from '../group_goal_tracking_latest';
import DefinePlan from '../group_define_plan.jsx';
import Payment from '../group_payments';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
import TextInput from '../../../utils/common_components/textInput_form';
import Select from '../../../utils/common_components/select';
import MailComposer from '../../../utils/common_components/mail_composer';
import { Provider } from 'react-redux';
export default function () {
    function repeatGoal_question1(goal_question, index) {
        return React.createElement('div', {
            'className': 'm-b-2 col-xs-12',
            'key': 'goal_question' + index
        }, React.createElement('div', { 'className': 'row no-box-shadow bg-white p-10' }, React.createElement('div', { 'className': 'col-xs-10 f-16' }, '\n                                                ', goal_question.question, ' \n                                            '), React.createElement('div', { 'className': 'col-xs-2 d-flex flex-column text-right' }, React.createElement('span', { 'className': 'f-14 c-grey' }, 'Target'), React.createElement('span', { 'className': 'f-18' }, goal_question.metric_unit.unit_name))));
    }
    function repeatGoal2(goal, goalIndex) {
        return React.createElement('tr', { 'key': goal.id }, React.createElement('td', {}, goal.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.assignGoal(goal);
            },
            'tooltip': 'Assign goal for this Group',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    function repeatWorkout3(workout, workoutIndex) {
        return React.createElement(SortableItem, {
            'className': 'w-100 d-flex  align-center border-dark no-box-shadow bg-white p-t-8 p-b-8 p-l-18 p-r-23 m-r-10 m-t-12 ribbon-container',
            'key': 'workout' + workout.id,
            'sortData': workout
        }, React.createElement('div', {}, React.createElement(IconButton, {
            'icon': 'reorder',
            'style': { cursor: 'move' },
            'tooltip': 'Re-Order Workout',
            'className': 'm-r-5 c-black small-icon-button handle'
        })), React.createElement('div', {
            'className': 'thumbnail box-77-48',
            'style': { backgroundImage: 'url(' + encodeURI(workout.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'w-70 row bg-white d-flex flex-column m-0 m-t-8 p-l-5 m-r-5',
            'style': { height: '68%' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (workout.description ? '9' : '12') + ' f-16 truncate',
            'tooltip': workout.name
        }, '\n                                                    ', workout.name, '\n                                                '), React.createElement('div', { 'className': 'width-fit badge-blue c-white p-t-2 p-b-2 p-l-4 p-r-4 f-10 f-300 m-b-2' }, '\n                                                    Gray Institute\n                                                '), workout.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-grey center-xs',
            'tooltip': workout.description,
            'icon': 'info',
            'key': '17362'
        }) : null, workout.practitioner ? React.createElement('div', {
            'className': 'col-xs-12 f-12 f-300 m-b-2',
            'key': '17531'
        }, '\n                                                    Practitioner: ', workout.practitioner.name, '\n                                                ') : null, workout.start_date && workout.workout_start_date ? React.createElement('div', {
            'className': 'col-xs-12 f-12 f-300 m-b-2',
            'key': '17806'
        }, '\n                                                    Start date: ', moment(workout.workout_start_date).format('MMMM Do YYYY'), '\n                                                ') : null, workout.start_date && workout.workout_start_date !== workout.start_date ? React.createElement('div', {
            'className': 'col-xs-12 f-12 f-300',
            'key': '18131'
        }, '\n                                                    Last revision date: ', moment(workout.start_date).format('MMMM Do YYYY'), '\n                                                ') : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-2 m-0' }, React.createElement(IconButton, {
            'icon': 'edit',
            'tooltip': 'Edit Workout',
            'className': 'm-r-5 c-grey small-icon-button',
            'onClick': () => {
                this.editWorkout(workout);
            }
        }), React.createElement(IconButton, {
            'icon': 'delete-forever',
            'tooltip': 'Delete Workout',
            'className': 'm-r-5 c-grey small-icon-button',
            'onClick': () => {
                this.deleteWorkout(workout);
            }
        }), workout.is_being_used && !workout.is_completed && workout.schedule_type === 2 ? React.createElement(IconButton, {
            'icon': 'close',
            'tooltip': 'Mark workout as completed',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.markWorkoutComplete(workout);
            },
            'key': '19243'
        }) : null)));
    }
    function repeatAttachment4(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument5(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment4.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document from this group',
            'className': 'delete-icon m-l-5 box-shadow-none c-red',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'mini': true
        }))));
    }
    function repeatAttachment6(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument7(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '200px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment6.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document from this group',
            'className': 'm-l-5 box-shadow-none c-red',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'mini': true
        }))));
    }
    function repeatHabit_template8(habit_template, habit_templateIndex) {
        return React.createElement('tr', { 'key': habit_template.id }, React.createElement('td', {}, habit_template.name), React.createElement('td', {}, habit_template.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.createGroupHabitFromTemplate(habit_template);
            },
            'tooltip': 'Add habit for this group',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    return React.createElement('div', { 'className': 'm-b-25 homefit-group-dashboard' }, React.createElement(NavLink, {
        'className': 'm-t-20 d-flex align-center back-btn c-grey',
        'to': `/practice/${ this.props.selectedRole.practice.id }/engagement/groups`,
        'onClick': this.cancel
    }, '\n            Groups\n        '), React.createElement('div', { 'className': 'd-flex' }, React.createElement('div', {
        'className': 'box-shadow-10 bg-white m-t-21 m-r-16',
        'style': { width: '76.6%' }
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'no-box-shadow panel m-b-0' }, React.createElement('div', { 'className': 'panel-body p-24' }, React.createElement('div', { 'className': 'd-flex w-100' }, React.createElement('div', { 'className': 'm-r-30' }, this.props.group.guid ? React.createElement('div', { 'key': '4071' }, !this.props.group.thumbnail_url ? React.createElement('div', {
        'style': {
            backgroundImage: 'url(' + encodeURI(this.props.group.logo_url) + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        },
        'className': 'm-b-20 group-thumbnail',
        'key': '4147'
    }, React.createElement(Avatar, { 'className': 'avatar-image-100 box-100 bg-transparent' }, React.createElement('img', { 'src': this.props.group.logo_url }))) : null, this.props.group.thumbnail_url ? React.createElement('div', {
        'style': { backgroundImage: 'url(' + encodeURI(this.props.group.thumbnail_url) + ')' },
        'className': 'm-b-20 group-thumbnail',
        'key': '4686'
    }) : null) : null), React.createElement('div', { 'className': 'w-48 p-l-6' }, React.createElement('div', { 'className': 'dashboard-title m-b-8' }, '\n                                    ', this.props.group.name, '\n                                    '), React.createElement('div', { 'className': 'page-subtitle w-60 m-b-13' }, '\n                                        ', this.props.group.__group_treatment_cycle, '\n                                    '), React.createElement('div', { 'className': 'text-left d-flex' }, React.createElement(IconButton, {
        'onClick': this.editGroup,
        'icon': 'edit',
        'tooltip': 'Edit Group (Program)',
        'className': 'box-shadow-none d-flex c-grey m-l-5 micro',
        'floating': true,
        'mini': true
    }), React.createElement(IconButton, {
        'icon': 'deactivate',
        'tooltip': 'Deactivate this group so all content is hidden from clients\' view',
        'className': this.props.group.active ? 'c-grey' : 'p-t-5 c-red',
        'onClick': () => {
            this.hideGroup(this.props.group.id);
        }
    }), React.createElement(IconButton, {
        'icon': 'email',
        'tooltip': 'Email all clients',
        'gomotive': true,
        'className': 'c-grey m-l-5',
        'onClick': () => {
            this.showMailComposer(this.props.group);
        }
    }))))))), React.createElement(Tabs, {
        'inverse': true,
        'fixed': true,
        'className': 'p-0 custom-tab accent col-xs-12',
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'label': 'Goal',
        'gomotive': true
    }, this.state.topPanelVisible ? [
        React.createElement('div', {
            'className': this.props.group.goal ? 'd-flex flex-end' : 'row middle-xs',
            'style': { width: '93%' },
            'key': '70551'
        }, !this.props.group.goal ? React.createElement('div', {
            'className': 'col-xs-10 alert-warning p-l-10',
            'key': '7258'
        }, '\n                                    Click on + icon to define a goal for this group.                        \n                                ') : null, React.createElement('div', { 'className': 'col-xs-2 flex-end' }, React.createElement(Button, {
            'tooltip': 'Add goal text & metrics',
            'onClick': this.onClickEditGoal,
            'className': 'bg-success width-fit',
            'label': 'Add New',
            'floating': true,
            'ripple': true,
            'mini': true
        })), !this.props.group.goal ? React.createElement('div', {
            'className': 'col-xs-12 f-14 f-300 c-primary m-t-5',
            'key': '7975'
        }, '\n                                    Note: Other features will be enabled once you define a goal for this game plan.\n                                ') : null),
        this.props.group.goal ? [
            ,
            React.createElement.apply(this, [
                'div',
                {
                    'className': 'row',
                    'key': '83003'
                },
                _.map(this.props.group.goal.goal_questions, repeatGoal_question1.bind(this))
            ])
        ] : null,
        ,
        React.createElement(Dialog, {
            'active': this.state.goalChooserVisible,
            'title': 'Please select a goal from your library or create a custom goal for this group',
            'onEscKeyDown': this.hideGoalChooser,
            'onOverlayClick': this.hideGoalChooser,
            'key': '70557'
        }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
            'tbody',
            {},
            _.map(this.props.goal_list, repeatGoal2.bind(this))
        ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
            'label': 'Add Custom Goal',
            'onClick': this.editGoal,
            'tooltip': 'Click this button if you want to add a custom goal for this group',
            'className': 'bg-success m-r-10',
            'raised': true
        }), React.createElement(Button, {
            'label': 'Close!',
            'tooltip': 'Close dialog box',
            'accent': true,
            'raised': true,
            'onClick': this.hideGoalChooser
        }))))
    ] : null, !this.state.topPanelVisible ? [this.state.showGoalEditForm ? React.createElement(GoalEdit, {
            'store': this.props.store,
            'group_id': this.props.match.params.group_id,
            'goal': this.props.group.goal,
            'finishEditing': this.finishEditingGoal,
            'deleteGoal': this.deleteGoal,
            'key': '127901'
        }) : null] : null), React.createElement(Tab, {
        'label': 'Program',
        'disabled': !this.props.group.goal
    }, this.state.topPanelVisible ? React.createElement('div', {
        'style': { marginTop: '-17px' },
        'key': '13490'
    }, React.createElement('ul', { 'className': 'tab-bar grey-tab m-t-24' }, React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 0 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(0);
        }
    }, '\n                                        Workouts\n                                    ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 1 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(1);
        }
    }, '\n                                        Habits\n                                    ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 2 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(2);
        }
    }, '\n                                        Nutrition\n                                    ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 3 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(3);
        }
    }, '\n                                        Guidance\n                                    '))), this.state.treatmentTabIndex === 0 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '15068'
    }, this.props.workouts.length === 0 ? React.createElement('div', {
        'className': 'col-xs-9 alert-warning m-t-10 m-b-10',
        'key': '15176'
    }, '\n                                    Click on + icon to create a workout for this group.\n                                ') : null, this.props.workouts.length !== 0 ? React.createElement('div', {
        'className': 'col-xs-9',
        'key': '15427'
    }) : null, !this.state.showWorkoutEditForm ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '15530'
    }, React.createElement.apply(this, [
        Sortable,
        {
            'className': 'vertical-container',
            'sortHandle': 'handle',
            'onSort': this.handleWorkoutSort,
            'containment': true,
            'dynamic': true
        },
        _.map(this.props.workouts, repeatWorkout3.bind(this))
    ])) : null, React.createElement('div', { 'className': 'w-100 center-xs m-t-10 pull-right' }, React.createElement(Button, {
        'onClick': this.addWorkout,
        'className': 'height-fit f-14 text-transform-none bg-success-red c-white m-b-10 m-r-12',
        'mini': true
    }, '\n                                        Add from template\n                                    '), React.createElement(Button, {
        'onClick': this.addWorkout,
        'className': 'height-fit f-14 text-transform-none bg-success-border m-b-10',
        'mini': true
    }, '\n                                        Create new\n                                    '))) : null, this.state.treatmentTabIndex === 1 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '20633'
    }, React.createElement(HabitList, {
        'group': this.props.group,
        'editHabit': this.editHabit
    })) : null, this.state.treatmentTabIndex === 2 ? React.createElement('div', {
        'className': 'row start-xs treatment-template-nutrition m-b-20',
        'key': '20874'
    }, React.createElement('div', { 'className': 'w-100 row m-t-10 bg-white no-box-shadow' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a nutrition plan to this group.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('span', { 'className': 'table-heading' }, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.nutritionDocuments, repeatDocument5.bind(this))
    ]), React.createElement('div', { 'className': 'w-100 d-flex    center-xs' }, React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('nutrition'),
        'tooltip': 'Add nutrition document',
        'className': 'center-xs height-fit text-transform-none bg-success-border m-b-10',
        'mini': true
    }, '\n                                            Add another\n                                    ')))) : null, this.state.treatmentTabIndex === 3 ? React.createElement('div', {
        'className': 'row start-xs treatment-template-guidance m-b-20',
        'key': '24670'
    }, React.createElement('div', { 'className': 'w-100 row m-t-10 bg-white no-box-shadow' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a guidance document to this group.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { maxWidth: '200px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('span', { 'className': 'table-heading' }, 'Attachments')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.guidanceDocuments, repeatDocument7.bind(this))
    ]), React.createElement('div', { 'className': 'w-100 d-flex center-xs' }, React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('guidance'),
        'tooltip': 'Add guidance document',
        'className': 'center-xs height-fit text-transform-none bg-success-border m-b-10',
        'mini': true
    }, '\n                                                Add another\n                                        ')))) : null) : null, !this.state.topPanelVisible ? React.createElement('div', { 'key': '28509' }, React.createElement('div', { 'className': 'col-xs-12' }, this.state.showWorkoutEditForm ? React.createElement(ProgramEdit, _.assign({}, {
        'finishEditing': this.finishEditingWorkout,
        'usedInside': true,
        'group_id': this.props.match.params.group_id,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'program_id': this.state.editingWorkoutId,
        'disableEditing': false,
        'isNonSyncGroup': this.props.group.group_treatment_cycle == 2,
        'editGroupWorkoutToggle': this.editGroupWorkoutToggle,
        'listWorkouts': this.props.listWorkouts,
        'key': '28635'
    }, this.state.workoutProgramTypeParams)) : null, this.state.showTemplateSelection ? React.createElement(ProgramList, _.assign({}, {
        'usedInside': true,
        'cancelTemplateSelection': this.unAllocateSpace,
        'group_id': this.props.match.params.group_id,
        'mode': TEMPLATE_SELECTION_MODE,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'afterCreateWorkoutFromProgram': this.afterCreateWorkoutFromTemplate,
        'key': '29220'
    }, this.state.templateProgramTypeParams)) : null, this.state.habitSpaceAllocated ? React.createElement(HabitEdit, {
        'finishEditing': this.finishEditingHabit,
        'group': this.props.group,
        'habitId': this.state.editingHabitId,
        'key': '29722'
    }) : null)) : null), React.createElement(Tab, {
        'label': 'Clients',
        'disabled': !this.props.group.goal
    }, React.createElement('div', {}, React.createElement(GroupLatestGoalTracking, { 'group': this.props.group })))), React.createElement(Dialog, {
        'active': this.state.openPaymentModal,
        'title': 'Payment Plan',
        'onEscKeyDown': this.hidePaymentModal,
        'onOverlayClick': this.hidePaymentModal
    }, this.state.openPaymentModal ? React.createElement(Payment, {
        'group': this.props.group,
        'handleClose': this.hidePaymentModal,
        'key': '30499'
    }) : null), React.createElement(Dialog, {
        'active': this.state.documentSelectionDialogVisible,
        'className': 'full-dialog',
        'title': 'Click \'+\' to assign',
        'onEscKeyDown': () => this.toggleDocumentSelectionDialog(),
        'onOverlayClick': () => this.toggleDocumentSelectionDialog()
    }, this.state.documentSelectionDialogVisible ? React.createElement(HealthDocumentList, {
        'selectionMode': true,
        'store': this.props.store,
        'match': { params: { document_type: this.state.healthDocumentType } },
        'onSelected': this.assignHealthDocument,
        'key': '31029'
    }) : null), React.createElement(Dialog, {
        'active': this.state.openGoalIntervalModal,
        'title': 'Client is prompted to check-in on this day',
        'onEscKeyDown': this.hideGoalIntervalModal,
        'onOverlayClick': this.hideGoalIntervalModal
    }, React.createElement(DefinePlan, {
        'group': this.props.group,
        'handleClose': this.hideGoalIntervalModal
    })), React.createElement(Dialog, {
        'active': this.state.mailComposerVisible,
        'className': 'dialog-title-center',
        'title': this.state.currentlyMailingGroup ? 'Message ' + this.state.currentlyMailingGroup.name : '',
        'onEscKeyDown': this.hideMailComposer,
        'onOverlayClick': this.hideMailComposer
    }, React.createElement(Provider, { 'store': this.props.store }, React.createElement(MailComposer, {
        'store': this.props.store,
        'hideEmail': true,
        'onSubmit': this.onMailComposerSubmit
    }))), React.createElement(Dialog, {
        'active': this.state.openHabitTemplateModal,
        'title': 'Please select a habit from your library',
        'onEscKeyDown': this.hideHabitTemplateModal,
        'onOverlayClick': this.hideHabitTemplateModal
    }, this.state.openHabitTemplateModal ? React.createElement('div', {
        'className': 'row',
        'key': '32714'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', {}, 'Description'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.habit_template_list, repeatHabit_template8.bind(this))
    ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Close',
        'tooltip': 'Close dialog box',
        'accent': true,
        'raised': true,
        'onClick': this.hideHabitTemplateModal
    }))) : null)), React.createElement('div', { 'className': 'publish-info p-0 m-t-21' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Publish',
        'onClick': () => this.save(),
        'className': 'bg-success m-l-10 w-100',
        'primary': true,
        'raised': true
    }))))));
}