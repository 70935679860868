import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Button from 'react-toolbox/lib/button';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Send Message to Partners',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.interacteWithPartners) }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'partner_interaction_type',
        'component': Select,
        'label': 'Partner Type',
        'options': this.state.partner_interaction_type,
        'simpleValue': true,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'subject',
        'type': 'text',
        'component': TextInput,
        'label': 'Subject'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'className': 'w-100',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Body'
    }))), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Send Message',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'm-l-5 bg-accent',
        'type': 'button',
        'onClick': this.cancel,
        'raised': true
    })))))));
}