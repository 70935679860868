import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { select as Select } from '../../../utils/common_components/select';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    function repeatGoal1(goal, goalIndex) {
        return React.createElement(Tr, { 'key': 'goal' + goalIndex }, React.createElement(Td, {
            'column': 'name',
            'value': goal.text,
            'style': {
                width: '350px',
                textAlign: 'left'
            }
        }, React.createElement('span', {}, goal.text)), React.createElement(Td, {
            'column': 'author',
            'value': goal.owner.name,
            'style': {
                width: '120px',
                textAlign: 'left'
            }
        }, React.createElement('span', {}, '\n                    ', goal.owner.name, '\n                ')), React.createElement(Td, {
            'column': 'is_publish',
            'style': {
                width: '120px',
                textAlign: 'left'
            }
        }, React.createElement('div', {}, goal.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '3654' }, goal.is_publish ? React.createElement('span', { 'key': '4146' }, 'Published') : null, !goal.is_publish ? React.createElement('span', { 'key': '4229' }, 'Not Published') : null) : null, goal.practice.id !== this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '4328' }, this.props.hidden_goals.indexOf(goal.id) === -1 ? React.createElement('span', { 'key': '4879' }, 'Published') : null, this.props.hidden_goals.indexOf(goal.id) > -1 ? React.createElement('span', { 'key': '4994' }, 'Hidden') : null) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container edit-btn-small justify-center' }, goal.is_owner ? [
            ,
            React.createElement(ButtonDefault, {
                'ripple': true,
                'raised': true,
                'primary': true,
                'tooltip': 'Edit goal template',
                'onClick': () => {
                    this.editGoal(goal);
                },
                'className': 'm-r-12 goal-template-action',
                'key': '54053'
            }, '\n                                Edit\n                        '),
            ,
            ,
            React.createElement(ButtonDefault, {
                'ripple': true,
                'raised': true,
                'primary': true,
                'tooltip': 'Delete template',
                'onClick': () => {
                    this.deleteGoal(goal.id);
                },
                'className': 'delete-red-icon goal-template-action-delete small-icon-button goal-template-action delete-button',
                'key': '54059'
            })
        ] : null, !goal.is_owner ? [
            ,
            React.createElement(ButtonDefault, {
                'raised': true,
                'ripple': true,
                'primary': true,
                'tooltip': 'Preview goal template',
                'onClick': () => {
                    this.editGoal(goal);
                },
                'className': 'm-r-12 goal-template-action',
                'key': '77303'
            }, '\n                                Preview\n                        '),
            this.props.hidden_goals.indexOf(goal.id) === -1 ? React.createElement(ButtonDefault, {
                'ripple': true,
                'raised': true,
                'primary': true,
                'tooltip': 'Hide goal template',
                'onClick': () => {
                    this.changeVisibilityStatus(goal, 'hide');
                },
                'className': 'unpublish-icon m-r-23 small-icon-button goal-template-action goal-template-action-hide secondary-button',
                'key': '77305'
            }) : null,
            this.props.hidden_goals.indexOf(goal.id) > -1 ? React.createElement(ButtonDefault, {
                'ripple': true,
                'raised': true,
                'primary': true,
                'tooltip': 'Show goal template',
                'onClick': () => {
                    this.changeVisibilityStatus(goal, 'show');
                },
                'className': 'unpublish-red m-r-23 small-icon-button goal-template-action goal-template-action-hide secondary-button',
                'key': '77307'
            }) : null,
            this.props.hidden_goals.indexOf(goal.id) === -1 ? React.createElement(IconButton, {
                'raised': true,
                'ripple': true,
                'icon': 'visibility_off',
                'tooltip': 'Hide goal template',
                'onClick': () => {
                    this.changeVisibilityStatus(goal, 'hide');
                },
                'className': 'm-r-23 small-icon-button goal-template-action goal-template-action-hide secondary-button',
                'key': '77309'
            }) : null,
            this.props.hidden_goals.indexOf(goal.id) > -1 ? React.createElement(IconButton, {
                'raised': true,
                'ripple': true,
                'icon': 'visibility_off',
                'tooltip': 'Show goal template',
                'onClick': () => {
                    this.changeVisibilityStatus(goal, 'show');
                },
                'className': 'goal-template-action goal-template-action-show small-icon-button',
                'key': '773011'
            }) : null
        ] : null)));
    }
    return React.createElement('div', { 'className': 'm-t-20 m-r-20' }, React.createElement('div', { 'className': 'page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Templates'), React.createElement('h2', { 'className': 'page-title with-subtitle' }, 'Goals')), React.createElement(Button, {
        'className': 'floating-add-button  red-add-button m-r-5',
        'style': { top: '100px' },
        'onClick': this.addGoal
    }, '\n            Add new\n        ')), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-24',
            'id': 'table',
            'noDataText': 'Create your own goal by clicking on + icon',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'name',
            'style': {
                width: '350px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Goal Title')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '120px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Author')), React.createElement(Th, {
            'column': 'is_publish',
            'style': {
                width: '120px',
                textAlign: 'left'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Status')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Action'))),
        _.map(this.props.goals, repeatGoal1.bind(this))
    ]));
}