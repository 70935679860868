import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/giContent.rt";

class Content extends BaseComponent { 
    render() {
        return Template.apply(this);
    }
}

export default Content;
