import * as React from 'react';
import * as _ from 'lodash';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
import Button from 'react-toolbox/lib/button';
import { Link } from 'react-router-dom';
export default function () {
    return React.createElement('div', {
        'className': 'row center-xs middle-xs full-height c-dark w-100 p-20',
        'style': {
            fontSize: '2rem',
            marginTop: '-130px'
        }
    }, React.createElement('div', { 'className': 'col-xs-12 header2' }, this.state.display_content === 'gm' ? React.createElement('h2', { 'key': '404' }, '\n            GoMotive Terms Of Use\n        ') : null, this.state.display_content === 'gi' ? React.createElement('h2', { 'key': '509' }, '\n            Gray Institute Terms Of Use\n        ') : null), React.createElement('div', {
        'className': 'panel col-xs-8 p-20',
        'style': { height: '75px' }
    }, React.createElement(Link, {
        'to': '/site_media/static/files/Terms_of_service_GI.pdf',
        'target': '_blank',
        'className': 'c-success'
    }, 'Trainer Terms of Use'), React.createElement('br', {}), React.createElement('br', {}), React.createElement(Link, {
        'to': '/site_media/static/files/Terms_of_use_HomeFit+.pdf',
        'target': '_blank',
        'className': 'c-success'
    }, 'Client Terms of Use')));
}