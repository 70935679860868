import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import moment from 'moment';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import RadioGroup1 from '../../../utils/common_components/radio_group_form.jsx';
import { RadioGroup } from 'react-toolbox/lib/radio';
import { RadioButton } from 'react-toolbox/lib/radio';
export default function () {
    return React.createElement('div', { 'className': 'm-20' }, React.createElement('div', { 'className': 'row f-20' }, '\n        Track Progress\n    '), React.createElement('div', { 'className': 'row f-16 m-t-5' }, React.createElement('span', { 'className': 'f-300 m-r-10' }, 'Progression:'), this.props.progression.name, '\n    '), React.createElement('div', { 'className': 'row f-14 m-t-5' }, React.createElement('span', { 'className': 'f-300 m-r-10' }, 'Workout:'), this.props.progression.program_or_workout_name, '\n    '), React.createElement('hr', { 'className': 'dashed' }), React.createElement('form', {
        'className': 'medium-labels',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-10' }, React.createElement(Card, { 'className': 'w-100 m-t-10' }, React.createElement(CardTitle, {
        'title': 'Track Workout ' + moment(this.props.progression.progress.progression_date.date).format('MMMM Do, YYYY'),
        'className': 'm-b-10'
    }), React.createElement(CardText, {}, React.createElement('div', { 'className': 'row start-xs m-r-0 m-15 vertical-align' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'has_tracked',
        'labelString': 'Did you complete your workout for ' + moment(this.props.progression.progress.progression_date.date).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[last] dddd',
            nextWeek: 'dddd',
            sameElse: 'L'
        }) + ' ?',
        'component': RadioGroup1,
        'options': this.state.has_tracked_options
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'feedback',
        'type': 'text',
        'component': TextInput,
        'label': 'Share feedback or ask your practitioner a question',
        'multiline': true,
        'rows': 4
    }))), React.createElement('div', { 'className': 'row center-xs m-t-20' }, React.createElement('div', { 'className': 'col-xs-8 end-xs' }, React.createElement(Button, {
        'label': 'Submit',
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Close',
        'onClick': this.doClose,
        'disabled': this.props.submitting,
        'type': 'button',
        'className': 'bg-accent m-l-10',
        'raised': true
    })))))))));
}