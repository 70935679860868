import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import actions from '../actions/calendar_edit';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getCalendarEvent = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +   "/" + API_VERSION + "/" + PACKAGE + "/calendar_get";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.fillCalendarEvent(
                response['event'],
                response['supporting_data']
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postCalendarEvent = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +   "/" + API_VERSION + "/" + PACKAGE + "/calendar_post";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Saved Successfully");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteCalendarEvent = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +   "/" + API_VERSION + "/" + PACKAGE + "/calendar_delete";
        return Axios.post(url, params).then((response) => {
            Utils.showSuccessAlert("Deleted Successfully");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientCalendarEvent = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +   "/" + API_VERSION + "/" + PACKAGE + "/client_calendar_get";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.fillClientCalendarEvent(
                response['event'],
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getCalendarEvent,
    postCalendarEvent,
    deleteCalendarEvent,
    getClientCalendarEvent,
};