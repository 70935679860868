import * as React from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-toolbox/lib/date_picker';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import HelpTip from '../../../utils/common_components/help_tip';
import { Link } from 'react-router-dom';
import { history } from '../../../redux/store';
import EngagementHabitView from '../../../engagement/components/client_engagement_habit_view';
import GroupHabitView from '../../../group/components/client_group_habit_view';
export default function () {
    function repeatHabit1(habit, habitIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': habitIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-habits' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(EngagementHabitView, {
            'habit': habit,
            'trackHabitProgressCallback': this.trackEngagementHabitCallback
        })));
    }
    function repeatHabit2(habit, habitIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': habitIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-habits' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement(GroupHabitView, {
            'habit': habit,
            'trackHabitProgressCallback': this.trackGroupHabitCallback
        })));
    }
    return React.createElement('div', { 'className': 'm-10' }, React.createElement('div', { 'className': 'row m-b-30' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('div', { 'className': 'row middle-xs' }, React.createElement('div', { 'className': 'col' }, React.createElement('h3', {}, '\n                        ', this.props.program.name, '\n                    '))))), this.props.program.action_type === 'none' ? React.createElement('div', {
        'className': 'alert-warning m-20',
        'key': '1214'
    }, '\n        You do not have any habits assigned for today\n    ') : null, this.props.program.action_type === 'display_payment' ? React.createElement('div', { 'key': '1380' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', {
        'className': 'col-xs-11',
        'style': { zIndex: '1' }
    }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Payment Subscription'), React.createElement('div', { 'className': 'panel-subtitle' }, 'Complete the payment to view treatment details ')), this.props.program.type === 'engagement' ? React.createElement('div', {
        'className': 'panel-body',
        'key': '1886'
    }, React.createElement(Button, {
        'onClick': () => {
            history.push('/client/engagement/' + this.props.program.id);
        },
        'className': 'bg-success',
        'icon': 'credit_card',
        'label': 'Subscribe',
        'tooltip': 'Click to complete payment for you to view treatment details',
        'raised': true,
        'primary': true
    })) : null, this.props.program.type === 'group' ? React.createElement('div', {
        'className': 'panel-body',
        'key': '2260'
    }, React.createElement(Button, {
        'onClick': () => {
            history.push('/client/engagement/' + this.props.program.id);
        },
        'className': 'bg-success',
        'icon': 'credit_card',
        'label': 'Subscribe',
        'tooltip': 'Click to complete payment for you to view treatment details',
        'raised': true,
        'primary': true
    })) : null)))) : null, this.props.program.action_type === 'view_treatment' ? React.createElement('div', { 'key': '2682' }, this.props.program.type === 'engagement' && this.props.program.habits.length > 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '2756' },
        _.map(this.props.program.habits, repeatHabit1.bind(this))
    ]) : null, this.props.program.type === 'group' && this.props.program.habits.length > 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '3373' },
        _.map(this.props.program.habits, repeatHabit2.bind(this))
    ]) : null, this.props.program.habits.length === 0 ? React.createElement('div', { 'key': '3975' }, '\n            No habits have been assigned for this program.\n        ') : null) : null);
}