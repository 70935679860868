import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Sidemenu from '../homefit_menu';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from '../../../../core/components/not_found';
import ClientSettings from '../../../../client_app/components/client_settings_container';
import HomeFitHome from '../homefit_home';
import HomeFitWorkouts from '../homefit_workouts';
import MovementMeterConfiguration from '../../../../user/components/user_movement_meter_configuration';
import HomeFitSubscriptions from '../homefit_subscription';
export default function () {
    return React.createElement('div', { 'className': 'd-flex' }, React.createElement(NavDrawer, {
        'className': 'main-drawer',
        'active': this.props.menuopen,
        'pinned': this.props.pinned,
        'onOverlayClick': this.toggleDrawerActive
    }, React.createElement(Sidemenu, {
        'isAuthenticated': true,
        'location': this.props.location,
        'doLogout': this.props.doLogout
    })), React.createElement('div', {
        'className': 'content-zone',
        'style': {
            flex: '1',
            padding: '0px 15px'
        }
    }, React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/home`,
        'component': HomeFitHome
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/workouts`,
        'component': HomeFitWorkouts
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/settings`,
        'component': ClientSettings
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/movement_meter`,
        'component': MovementMeterConfiguration
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/subscriptions`,
        'component': HomeFitSubscriptions
    }), React.createElement(Route, { 'component': NotFound }))));
}