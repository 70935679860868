// Action Creators
const documentListFill = (health_documents) => ({
    type: 'DOCUMENT_LIST_FILL',
    health_documents,
});

const documentListRemove = (remove_document_id) => ({
    type: 'DOCUMENT_LIST_REMOVE',
    remove_document_id,
});

const documentFill = (health_document) => ({
    type: 'DOCUMENT_INSTANCE_FILL',
    health_document,
});


const cleanModule = () => ({
    type: 'DOCUMENT_CLEANUP',
});

export default {
    documentListFill,
    documentListRemove,
    documentFill,
    cleanModule,
};
