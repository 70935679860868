import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from 'react-toolbox/lib/switch';
import FontIcon from 'react-toolbox/lib/font_icon';
import Slider from 'react-slick';
import SlickCss from 'slick-carousel/slick/slick.css';
import SlickThemeCss from 'slick-carousel/slick/slick-theme.css';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import { Dialog } from 'react-toolbox/lib/dialog';
export default function () {
    function repeatExercise1(exercise, exerciseIndex) {
        return React.createElement('div', {
            'className': 'p-relative text-center',
            'key': 'exercise' + exercise.id
        }, React.createElement('div', {
            'className': 'panel m-t-10 m-l-10 m-b-10 width-250',
            'onClick': () => {
                this.clickStartVideo(exerciseIndex);
            }
        }, React.createElement('div', { 'className': 'panel-heading p-5 c-black' }, React.createElement(TooltipWrapper, {
            'className': 'f-14 truncate',
            'tooltip': exercise.name
        }, '\n                            ', exercise.name, '\n                        ')), React.createElement('div', { 'className': 'panel-body pointer p-relative p-0' }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(exercise.exercise_thumbnail_url) + ')' }
        }))), this.state.currentIndex === exerciseIndex ? React.createElement('div', {
            'className': 'arrow-top-box di-block',
            'style': { marginBottom: '-5px' },
            'key': '3594'
        }) : null);
    }
    function repeatCue2(cue, cueIndex) {
        return React.createElement('div', { 'key': 'c-' + cue.id }, React.createElement('span', { 'className': 'f-300' }, cue.description));
    }
    function repeatEquipment3(equipment, equipmentIndex) {
        return React.createElement('div', { 'key': 'e-' + equipment.id }, React.createElement('span', { 'className': 'f-300' }, equipment.name));
    }
    return React.createElement('div', { 'className': 'row p-10 center-xs m-15' }, React.createElement('div', { 'className': 'text-left col-xs-11' }, React.createElement('h4', {}, this.props.progression.program_or_workout_name, ' (', this.props.progression.name, ')'), React.createElement('p', { 'className': 'f-italic f-14 c-darkgray pre-wrap' }, this.props.progression.description)), this.props.progression.overall_description ? React.createElement(IconButton, {
        'className': 'col-xs-1 c-black center-xs',
        'tooltip': this.props.progression.overall_description,
        'icon': 'info',
        'key': '1679'
    }) : null, React.createElement('hr', { 'className': 'dashed' }), React.createElement('div', { 'className': 'col-xs-12  bg-accent p-10 vertical-align' }, React.createElement('h5', { 'className': 'flex-auto text-left f-500' }, 'Duration : ', this.props.progression.duration, ' mins'), React.createElement('div', { 'className': 'pull-right bg-success-red c-white br-50em vertical-align p-5  box-shadow f-13' }, React.createElement('i', { 'className': 'material-icons f-28 m-r-10 m-l-10' }, 'reorder'), React.createElement('label', { 'className': 'm-r-20' }, ' Sequence'), React.createElement(Switch, {
        'className': 'm-0 same-color-switch',
        'checked': this.state.loopCurrent,
        'onChange': this.changeVideoLoop
    }), React.createElement('label', { 'className': 'm-l-20 m-r-10' }, 'Loop '), React.createElement('i', { 'className': 'material-icons f-28 m-r-10' }, 'loop'))), React.createElement('div', {
        'className': 'col-xs-12  c-black p-l-30 p-r-25 bg-accent box-shadow-inset',
        'style': { overflow: 'hidden' }
    }, this.props.progression.exercises.length !== 0 ? React.createElement.apply(this, [
        Slider,
        _.assign({}, {
            'slickGoTo': this.state.currentIndex,
            'key': '2659'
        }, this.sliderSettings),
        _.map(this.props.progression.exercises, repeatExercise1.bind(this))
    ]) : null), this.state.currentExercise ? React.createElement('div', {
        'className': 'col-xs-8 d-flex justify-center m-t-20',
        'style': { flexDirection: 'column' },
        'key': '3770'
    }, React.createElement('div', { 'className': 'panel p-0 bg-white row justify-content' }, React.createElement('div', { 'className': 'p-10 panel-body col-xs-12' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video',
        'onChangePlayingIndex': this.updateCurrentIndex,
        'onPlaybackEnd': this.onPlaybackEnd,
        'currentIndex': this.state.currentIndex,
        'videoList': this.props.video_list,
        'autoPlay': true,
        'playlistLoop': this.state.loopCurrent
    })), React.createElement('div', { 'className': 'panel-footer p-10 bg-white w-100' }, React.createElement('h4', {}, this.state.currentExercise.name), React.createElement('p', { 'className': 'pre-wrap' }, this.state.currentExercise.description), this.state.currentExercise.notes ? React.createElement('div', {
        'className': 'row m-t-10 bg-gray p-10 m-b-10 m-l-2 m-r-2',
        'key': '4545'
    }, React.createElement('h4', {}, 'Notes'), React.createElement('div', { 'className': 'col-xs-12 pre-wrap' }, '\n                        ', this.state.currentExercise.notes, '\n                    ')) : null, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-md-2 col-xs-4 text-center' }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-24',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.sets ? this.state.currentExercise.sets : '-', '\n                            ')), React.createElement('div', { 'className': 'clearfix' }), React.createElement('p', { 'className': 'font-18' }, 'Sets')), this.state.currentExercise.metric === 1 ? React.createElement('div', {
        'className': 'col-md-2 col-xs-4 text-center',
        'key': '5404'
    }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-24',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.reps ? this.state.currentExercise.reps : '-', '\n                            ')), React.createElement('div', { 'className': 'clearfix' }), React.createElement('p', { 'className': 'font-18' }, 'Reps')) : null, this.state.currentExercise.metric === 3 ? React.createElement('div', {
        'className': 'col-md-2 col-xs-4 text-center',
        'key': '5952'
    }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-16',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.duration ? this.state.currentExercise.duration : '-', '\n                                ', React.createElement('br', {}), ' ', this.state.currentExercise.duration_unit_display, '\n                            ')), React.createElement('div', { 'className': 'clearfix' }), React.createElement('p', { 'className': 'font-18' }, 'Duration')) : null, this.state.currentExercise.metric === 2 ? React.createElement('div', {
        'className': 'col-md-2col-xs-4 text-center',
        'key': '6600'
    }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-16',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.distance ? this.state.currentExercise.distance : '-', '\n                                ', React.createElement('br', {}), this.state.currentExercise.distance_unit_display)), React.createElement('div', { 'className': 'clearfix' }), React.createElement('p', { 'className': 'font-18' }, 'Distance')) : null, React.createElement('div', { 'className': 'col-md-2 col-xs-4 text-center' }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-16',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.weight ? this.state.currentExercise.weight : '-', '\n                                ', React.createElement('br', {}), this.state.currentExercise.weight_unit_display)), React.createElement('div', { 'className': 'clearfix' }), React.createElement('div', { 'className': 'text-center' }, React.createElement('p', { 'className': 'font-18' }, 'Weight'))), React.createElement('div', { 'className': 'col-md-2 col-xs-4 text-center' }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-16',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.hold ? this.state.currentExercise.hold : '-', '\n                                ', React.createElement('br', {}), this.state.currentExercise.hold_unit_display)), React.createElement('div', { 'className': 'clearfix' }), React.createElement('p', { 'className': 'font-18' }, 'Hold Position')), React.createElement('div', { 'className': 'col-md-2 col-xs-4 text-center' }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-16',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.pause ? this.state.currentExercise.pause : '-', '\n                                ', React.createElement('br', {}), this.state.currentExercise.pause_unit_display, '\n                            ')), React.createElement('div', { 'className': 'clearfix' }), React.createElement('p', { 'className': 'font-18' }, 'Rest Betw. Sets')), React.createElement('div', { 'className': 'col-md-2 col-xs-4 text-center' }, React.createElement('div', { 'className': 'activity-icon bg-info' }, React.createElement('p', {
        'className': 'f-24',
        'style': { lineHeight: '60px' }
    }, '\n                                ', this.state.currentExercise.heart_rate ? this.state.currentExercise.heart_rate : '-', '\n                            ')), React.createElement('div', { 'className': 'clearfix' }), React.createElement('div', { 'className': 'text-center' }, React.createElement('p', { 'className': 'font-18' }, 'Heart Rate')))), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-md-6 col-xs12 m-b-10' }, React.createElement('div', { 'className': 'bg-gray p-5' }, this.state.currentExercise.cues.length === 0 ? React.createElement('div', { 'key': '9797' }, '\n                                No cues have been defined for this exercise.\n                            ') : null, this.state.currentExercise.cues.length !== 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '9996' },
        React.createElement('h4', {}, 'Cues'),
        _.map(this.state.currentExercise.cues, repeatCue2.bind(this))
    ]) : null)), React.createElement('div', { 'className': 'col-md-6 col-xs12 m-b-10' }, React.createElement('div', { 'className': 'bg-gray p-5' }, this.state.currentExercise.equipments.length === 0 ? React.createElement('div', { 'key': '10557' }, '\n                                No equipment has been defined for this exercise.\n                            ') : null, this.state.currentExercise.equipments.length !== 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '10766' },
        React.createElement('h4', {}, 'Equipment'),
        _.map(this.state.currentExercise.equipments, repeatEquipment3.bind(this))
    ]) : null)))))) : null, React.createElement(Dialog, {
        'active': this.state.trackDialogVisible,
        'title': 'Track Workout',
        'onEscKeyDown': this.hideTrackDialog,
        'onOverlayClick': this.hideTrackDialog
    }, React.createElement('div', { 'className': 'col-xs-12 center-xs' }, React.createElement(Button, {
        'label': 'Did it',
        'style': { width: '180px' },
        'className': 'bg-success',
        'raised': true,
        'onClick': () => {
            this.trackWorkout(3);
        }
    })), React.createElement('div', { 'className': 'col-xs-12 center-xs m-t-10' }, React.createElement(Button, {
        'label': 'Did part of it',
        'style': { width: '180px' },
        'className': 'bg-primary',
        'raised': true,
        'onClick': () => {
            this.trackWorkout(2);
        }
    })), React.createElement('div', { 'className': 'col-xs-12 center-xs m-t-10' }, React.createElement(Button, {
        'label': 'Didn\'t do it',
        'style': { width: '180px' },
        'className': 'bg-danger',
        'raised': true,
        'onClick': () => {
            this.trackWorkout(1);
        }
    })), React.createElement('div', { 'className': 'col-xs-12 center-xs m-t-10' }, React.createElement(Button, {
        'label': 'Cancel',
        'style': { width: '180px' },
        'className': 'bg-accent',
        'raised': true,
        'onClick': this.hideTrackDialog
    }))));
}