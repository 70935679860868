import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Select from '../../../utils/common_components/select';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    return React.createElement('div', { 'className': 'row habit-template-edit' }, React.createElement(Button, {
        'className': 'm-t-20 m-b-10 d-flex back-btn c-grey',
        'onClick': this.doCancel
    }, '\n        Trainer / Templates / Habits\n    '), React.createElement(Card, { 'className': 'w-100 bg-none no-box-shadow' }, React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'className': 'bg-none d-flex bg-white no-box-shadow' }, React.createElement('div', {
        'className': 'add-info box-shadow-10',
        'style': { margin: 'unset' }
    }, React.createElement(CardTitle, {
        'title': (this.props.match.params.habit_template_id ? 'Edit' : 'Add') + ' New Habit',
        'className': 'c-black m-b-20 p-b-15 p-0 form-title f-24 w-100'
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Name',
        'name': 'name',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Habit text',
        'name': 'text',
        'onClick': this.inputClick,
        'component': TextInput,
        'rows': 8,
        'multiline': true
    }), React.createElement('div', { 'className': 'col-xs-12 m-b-16' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'w-50' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'schedule_type',
        'component': Select,
        'label': 'How frequently should your clients follow this habit?',
        'options': this.props.supporting_data.frequency_schedule,
        'simpleValue': true
    })), React.createElement('div', { 'className': 'col-xs-6' }, this.props.schedule_type === 4 ? React.createElement(Field, {
        'className': 'w-100',
        'name': 'schedule_week_day',
        'component': Select,
        'label': 'Which day of the week?',
        'options': this.props.supporting_data.week_day_list,
        'simpleValue': true,
        'key': '3122'
    }) : null, this.props.schedule_type === 5 ? React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'schedule_month_date',
        'type': 'number',
        'component': TextInput,
        'label': 'Which day of the month?',
        'key': '3608'
    }) : null))), React.createElement('div', { 'className': 'col-xs-12 p-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'w-50 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'duration_count',
        'type': 'number',
        'label': 'How long should your clients follow this habit?',
        'component': TextInput
    })), React.createElement('div', { 'className': 'w-40' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'duration_type',
        'component': Select,
        'options': this.props.supporting_data.duration_type,
        'simpleValue': true
    })))), React.createElement(Card, {
        'className': 'no-box-shadow add-video',
        'style': { marginTop: '30px' }
    }, React.createElement(CardTitle, {
        'title': 'Video (Optional)',
        'className': 'c-black form-subtitle'
    }), React.createElement(CardText, {}, React.createElement('div', { 'className': 'row center-xs middle-xs' }, React.createElement('div', { 'className': 'full-width d-flex flex-space-around' }, this.props.video_guid ? React.createElement('div', {
        'className': 'm-b-20 w-300',
        'key': '5501'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-player',
        'handleUploadProcessing': true,
        'videoList': [this.props.video_guid]
    })) : null, this.props.habit_template.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
        'className': 'row center-xs video-btn-container add-media-btn w-40',
        'key': '5897'
    }, React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'buttonText': this.props.video_guid ? 'Change Video' : 'Upload Video'
    }), this.props.video_guid ? React.createElement(Button, {
        'onClick': this.deleteVideo,
        'label': 'Delete Video',
        'className': 'm-l-10 bg-danger-light full-width',
        'primary': true,
        'raised': true,
        'key': '6539'
    }) : null) : null))))), this.props.habit_template.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
        'className': 'publish-info',
        'key': '7102'
    }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-r-10 middle-xs' }, this.props.habit_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'label': 'Save as Draft',
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'type': 'button',
        'raised': true,
        'onClick': this.saveAsDraft,
        'key': '7408'
    }) : null, this.props.habit_template.practice.id === this.props.selectedRole.practice.id ? React.createElement(Button, {
        'label': 'Publish',
        'className': 'm-l-10 bg-success w-100',
        'type': 'button',
        'raised': true,
        'onClick': this.saveAndPublish,
        'key': '7815'
    }) : null))) : null))));
}