import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_library_habit_templates.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
    getPartnerHabitTemplateList,
    addPartnerHabitTemplate, 
    removePartnerHabitTemplate,
    addAllHabitTemplatesPartnerLibrary,
    removeAllHabitTemplatesPartnerLibrary
} from '../../redux/partner/network/partner_network';
import { getHabitTemplateList } from '../../redux/habit/network/habit_network';
import partnerActions from '../../redux/partner/actions/partner_action';
import actions from '../../redux/habit/actions/habit_template_creator';


class PartnerLibraryHabitTemplates extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addHabitTemplate = this.addHabitTemplate.bind(this);
        this.removeHabitTemplate = this.removeHabitTemplate.bind(this);
        this.loadHabitTemplates = this.loadHabitTemplates.bind(this);
        this.loadPartnerHabitTemplateList = this.loadPartnerHabitTemplateList.bind(this);
        this.addAllHabitTemplates = this.addAllHabitTemplates.bind(this);
        this.removeAllHabitTemplates = this.removeAllHabitTemplates.bind(this);
    };

    componentWillMount() {
        if(!this.props.match.params.library_id){
            history.goBack();
        }
        this.props.cleanUp({});
        this.props.habitTemplateCleanup();
        this.loadHabitTemplates();
        this.loadPartnerHabitTemplateList();
    }

    loadHabitTemplates(){
        let params = {
            'show_published':  true, 
        };
        this.props.getHabitTemplateList(params);
    }

    loadPartnerHabitTemplateList() {
        let params = {  
            id:this.props.match.params.library_id,
        };
        this.props.getPartnerHabitTemplateList(params);
    }



    componentWillUnmount() {
        this.props.cleanUp({});
        this.props.habitTemplateCleanup();
    }

    addHabitTemplate(habit_template){
        let params = {
            id:this.props.match.params.library_id,
            habit_template_id:habit_template.id
        };
        this.props.addPartnerHabitTemplate(params);
    }
    removeHabitTemplate(habit_template){
        let params = {
            id:this.props.match.params.library_id,
            habit_template_id:habit_template.id
        };
        this.props.removePartnerHabitTemplate(params);
    }

    addAllHabitTemplates() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.addAllHabitTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.habitTemplateCleanup();
            this.loadHabitTemplates();
            this.loadPartnerHabitTemplateList();
        }).catch((error) => {            
        });  
    }

    removeAllHabitTemplates() {
        let params = {  
            library_id:this.props.match.params.library_id,
        };
        this.props.removeAllHabitTemplatesPartnerLibrary(params).then((response) => { 
            this.props.cleanUp({});
            this.props.habitTemplateCleanup();
            this.loadHabitTemplates();
            this.loadPartnerHabitTemplateList();
        }).catch((error) => {            
        });  
    }


    render() {
        if(_.isEmpty(this.props.partner_library)){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getHabitTemplateList,
        getPartnerHabitTemplateList,
        addPartnerHabitTemplate,
        removePartnerHabitTemplate,
        cleanUp:partnerActions.cleanUp,
        habitTemplateCleanup: actions.habitTemplateCleanup,
        addAllHabitTemplatesPartnerLibrary,
        removeAllHabitTemplatesPartnerLibrary
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    let partner_template_ids = _.keyBy(state.partner.partnerReducer.partner_library.habit_templates,"id");
    return {
        habit_templates: state.habit.habitTemplateReducer.habit_template_list,
        partner_library: state.partner.partnerReducer.partner_library,
        partner_template_ids:partner_template_ids
    };
}
const PartnerLibraryHabitTemplatesConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerLibraryHabitTemplates);
export default PartnerLibraryHabitTemplatesConnected;
