// Action Creators
const enrollClientsFill = (enroll_clients) => ({
    type: 'ENROLL_CLIENTS_FILL',
    enroll_clients    
});

const cleanup = () => ({
    type: 'ENROLL_CLIENTS_CLEANUP',
});
export default {
    enrollClientsFill,
    cleanup,
};