import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { FieldArray } from 'redux-form';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'col-xs-10 m-20 p-0' }, React.createElement(CardTitle, {
        'title': 'Goal Template',
        'className': 'm-b-10'
    }), React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement('form', {}, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', {}, React.createElement(Field, {
        'name': 'text',
        'type': 'text',
        'component': TextInput,
        'label': 'Goal text'
    }))), React.createElement('div', { 'className': 'col-xs-12 m-t-25' }, React.createElement(FieldArray, {
        'name': 'goal_tracking',
        'component': this.renderGoalTrackingQuestions
    })), !this.props.goal.practice || this.props.goal.practice && this.props.goal.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
        'className': 'col-xs-12 end-xs m-t-10',
        'key': '2063'
    }, React.createElement(Button, {
        'label': 'Publish',
        'onClick': this.onPublish,
        'className': 'm-r-10',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Save as draft',
        'className': 'bg-accent',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    })) : null)))));
}