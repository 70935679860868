import React from 'react';
import { IconButton } from 'react-toolbox/lib/button';
import Tooltip from 'react-toolbox/lib/tooltip';
import { MdOutlineVisibilityOff, MdOutlineModeEditOutline, CgPassword, MdOutlineEmail, RiDeleteBin5Line,AiOutlineUser, MdDeleteForever, MdPictureAsPdf, IoMdReorder, GrClose, AiOutlineInfoCircle, CgUserList, FaStethoscope, AiOutlineFlag, VscGraph, AiFillInfoCircle, AiFillPlayCircle, IoIosAddCircle,AiFillCheckCircle,AiFillStar,AiOutlineStar, MdContentCopy, MdScreenShare, GiProgression, GiPowerButton,BiCalendar,BsChevronRight,BsChevronLeft,BsChevronDown } from "react-icons/all"

const TooltipFunction = ({ icon, tooltip, onClick, className }) => {
    const TooltipButton = Tooltip(IconButton);
    const TooltipIconButton = ({ icon }) => {
        return <TooltipButton className={className} tooltip={tooltip} onClick={onClick} icon={icon} />
    }
    switch (icon) {
        case "deactivate":
            return <TooltipIconButton icon={<MdOutlineVisibilityOff />} />
        case "reactivate":
            return <TooltipIconButton icon={<MdOutlineVisibilityOff />} />
        case "edit":
            return <TooltipIconButton icon={<MdOutlineModeEditOutline />} />
        case "delete":
            return <TooltipIconButton icon={<RiDeleteBin5Line />} />
        case "delete-forever":
            return <TooltipIconButton icon={<MdDeleteForever />} />
        case "resetpassword":
            return <TooltipIconButton icon={<CgPassword />} />
        case "email":
            return <TooltipIconButton icon={<MdOutlineEmail />} />
        case "user":
            return <TooltipIconButton icon={<AiOutlineUser />} />
        case "pdf":
            return <TooltipIconButton icon={<MdPictureAsPdf />} />
        case "close":
            return <TooltipIconButton icon={<GrClose />} />
        case "reorder":
            return <TooltipIconButton icon={<IoMdReorder />} />
        case "info":
            return <TooltipIconButton icon={<AiOutlineInfoCircle />} />
        case "info-fill":
            return <TooltipIconButton icon={<AiFillInfoCircle />} />
        case "program":
            return <TooltipIconButton icon={<CgUserList />} />
        case "assessment":
            return <TooltipIconButton icon={<FaStethoscope />} />
        case "goal":
            return <TooltipIconButton icon={<AiOutlineFlag />} />
        case "results":
            return <TooltipIconButton icon={<VscGraph />} />
        case "intake":
            return <TooltipIconButton icon={<VscGraph />} />
        case "play":
            return <TooltipIconButton icon={<AiFillPlayCircle />} />
        case "star":
            return <TooltipIconButton icon={<AiOutlineStar />} />
        case "star-fill":
            return <TooltipIconButton icon={<AiFillStar />} />
        case "add":
            return <TooltipIconButton icon={<IoIosAddCircle />} />
        case "check":
            return <TooltipIconButton icon={<AiFillCheckCircle />} />
        case "clone":
            return <TooltipIconButton icon={<MdContentCopy />} />
        case "screen-share":
            return <TooltipIconButton icon={<MdScreenShare />} />
        case "activate":
            return <TooltipIconButton icon={<GiPowerButton />} />
        case "progress":
            return <TooltipIconButton icon={<GiProgression />} />
        case "calendar":
            return <TooltipIconButton icon={<BiCalendar />} />
        case "chevron-right":
            return <TooltipIconButton icon={<BsChevronRight />} />
        case "chevron-left":
            return <TooltipIconButton icon={<BsChevronLeft />} />
        case "chevron-down":
            return <TooltipIconButton icon={<BsChevronDown />} />
        default:
            return null;
    }
};
export default TooltipFunction;