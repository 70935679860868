import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import ClientGroupCreator from '../actions/client_group_creator';
import ClientGroupProgressionDatesCreator from '../actions/client_group_progression_dates_creator';

const getClientGroupList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_list";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientGroupCreator.clientGroupListFill(response.groups));
            dispatch(hideProgress());
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const getClientGroup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_get";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientGroupCreator.clientGroupGetFill(
                response.group,
            ));
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postClientGroupGoalTarget = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_goal_target_post";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientGroupCreator.clientGroupGetFill(
                response.group,
            ));
            dispatch(hideProgress());
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getGroupClientProgressionDatesList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_workout_progressions_list";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientGroupProgressionDatesCreator.fillProgressionDatesList(
                response.all_dates, response.date_workouts, response.date_habits
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};

const getGroupClientProgressionPerDate= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_workout_progressions_per_date";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientGroupProgressionDatesCreator.fillProgressionDateInstance(
                response.progressions, response.habits, response.group
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};
const groupTrackHabit= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_track_habit";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully tracked habit");
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};

const getClientGroupGoalTargetForm= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_goal_tracking_get";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientGroupCreator.clientGroupGoalTargetsFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};
const postClientGroupGoalTargetForm= (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_group_goal_tracking_post";
        return Axios.post(url, params).then((response) => {
            dispatch(ClientGroupCreator.clientGroupGetFill(
                response.group,
            ));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully tracked the goal");
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(hideProgress());
            throw error;
        });
    };
};



export {
    getClientGroupList,
    getClientGroup,
    postClientGroupGoalTarget,
    getGroupClientProgressionDatesList,
    getGroupClientProgressionPerDate,
    getClientGroupGoalTargetForm,
    groupTrackHabit,
    postClientGroupGoalTargetForm
};