import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_list.rt";
import { reduxForm } from 'redux-form';

import store, {history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getPracticeList} from '../../redux/practice/network/practice_list';
import actionCreator from '../../redux/practice/actions/practice_list';
import Utils from '../../utils/utils.js';


class PracticeList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.searchPractices = _.debounce(this.searchPractices.bind(this),1000);
        this.getBackgroundColor = this.getBackgroundColor.bind(this);
        this.connectToPractice = this.connectToPractice.bind(this);
        this.loadData = this.loadData.bind(this);
        this.state = {
            search_text:""
        };
    }

    componentWillMount() {
        this.loadData();
    }


    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanup();
    }

    getBackgroundColor(name){
        return Utils.getBackgroundColor(name);
    }
    
    connectToPractice(practice){
        history.push("/guest/client/connect/practice/"+practice.id);
    }

    searchPractices(text) {         
        let that = this;
        this.setState({search_text:text},()=>{
            this.loadData();
        });
    }

    loadData(increment=false) {
        let params = { ...this.props.paginate_info};
        params.search = this.state.search_text;
        params.guest_search = true;
        if(increment){
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        this.props.getPracticeList(params);
    }


}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPracticeList,
        cleanup:actionCreator.practiceListCleanup
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        practices: state.practice.practiceList.practices,
        paginate_info: state.practice.practiceList.paginate_info,
    };
}



const PracticeListConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PracticeList);

export default PracticeListConnected;
