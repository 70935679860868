import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
export default function () {
    return React.createElement('div', { 'className': 'row m-b-20' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('img', {
        'src': this.props.quickStart.image_url,
        'style': { width: '100%' }
    })), React.createElement('div', { 'className': 'col-xs-12 f-20 m-t-20' }, '\n        ', this.props.quickStart.name, '\n    '), React.createElement('div', { 'className': 'col-xs-12 c-accent-light f-300 m-t-2' }, '\n        ', this.props.quickStart.practice.name, '\n    '), React.createElement('hr', {
        'className': 'col-xs-12 bg-gray m-0 m-t-5 m-b-20',
        'style': { borderTop: '1px solid #ccc' }
    }), React.createElement('div', { 'className': 'col-xs-12 pre-wrap' }, '\n        ', this.props.quickStart.description, '\n    '), React.createElement('div', { 'className': 'col-xs-12 center-xs' }, React.createElement(Button, {
        'label': 'Acquire',
        'className': 'bg-success',
        'primary': true,
        'raised': true,
        'onClick': () => {
            this.acquire();
        }
    })));
}