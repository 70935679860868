import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import { IconButton } from 'react-toolbox/lib/button';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import ProgressionView from '../progression_view';
export default function () {
    return React.createElement('div', { 'className': 'row m-t-20' }, React.createElement('div', { 'className': 'f-16 bg-' + (this.props.progressionPreview.progression ? 'blue' : 'success') + ' p-10' }, '\n            ', this.props.progressionPreview.start_day !== this.props.progressionPreview.end_day ? 'Day ' + this.props.progressionPreview.start_day + ' - ' + 'Day ' + this.props.progressionPreview.end_day : 'Day ' + this.props.progressionPreview.start_day, ' ', this.props.progressionPreview.progression ? '' : '(Rest Day)', '\n    '), React.createElement('div', { 'className': 'col-xs-12 m-t-20' }, this.props.progressionPreview.progression ? React.createElement(ProgressionView, {
        'progression': this.props.progressionPreview.progression,
        'selectedRole': this.props.selectedRole,
        'key': '2182'
    }) : null, !this.props.progressionPreview.progression ? React.createElement('div', {
        'className': 'row bg-accent p-10 f-18',
        'key': '2367'
    }, '\n            No progressions assigned for this day\n        ') : null));
}