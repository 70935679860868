import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import actions from '../actions/user_profile';

const getProfile = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/coach_profile_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actions.fillProfile(
                response['profile'],
                response['supporting_data'],
            ));
            return response.profile
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postProfile = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_profile_update";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Saved Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postProfileProfessionalInfo = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/coach_profile_update";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Saved Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updateEmail = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_email_update";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Updated Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updatePassword = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_password_update";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Updated Successfully");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientSearchFilterPreference = (params) => {
    return (dispatch) => {
        const url = "/" + API_VERSION + "/" + PACKAGE + "/client_search_preference_get";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.fillClientSearchPreference(response['client_search_preference']));
            return response;
        }).catch((error) => {
            throw error;
        });
    };
};
const getClientMovementMeterConfiguration = (params) => {
    return (dispatch) => {
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_movement_meter_configuration_get";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.fillClientMovementMeterConfiguration(response['data']));
            return response;
        }).catch((error) => {
            throw error;
        });
    };
};
const postClientMovementMeterConfiguration = (params) => {
    return (dispatch) => {
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_movement_meter_configuration_post";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.fillClientMovementMeterConfiguration(response['data']));
            return response;
        }).catch((error) => {
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const ChangeEmail = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/update_email_request";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Updated Successfully");
            return response;
        }).catch((error) => {
            console.log("error data", error);
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            return error;
        });
    };
};


export {
    getProfile,
    postProfile,
    postProfileProfessionalInfo,
    updateEmail,
    updatePassword,
    getClientSearchFilterPreference,
    getClientMovementMeterConfiguration,
    postClientMovementMeterConfiguration,
    ChangeEmail
};