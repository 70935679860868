import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatCue1(cue, cueIndex) {
        return React.createElement(Tr, { 'key': 'cue' + cueIndex }, React.createElement(Td, {
            'column': 'name',
            'value': cue.name
        }, React.createElement('div', {}, React.createElement('div', {}, cue.name), React.createElement('div', { 'className': 'f-12 f-300 m-t-5' }, cue.description))), React.createElement(Td, {
            'column': 'practice',
            'value': cue.practice.name,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, '\n                        ', cue.practice.name, '\n                    ')), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container edit-btn-small' }, cue.is_owner ? React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'onClick': () => {
                this.editCue(cue.id);
            },
            'className': 'goal-template-action',
            'primary': true,
            'key': '3481'
        }, 'Edit') : null, !cue.is_owner ? React.createElement(ButtonDefault, {
            'onClick': () => {
                this.editCue(cue.id);
            },
            'className': 'goal-template-action m-r-12',
            'raised': true,
            'ripple': true,
            'primary': true,
            'key': '3857'
        }, 'Preview') : null)));
    }
    return React.createElement('div', { 'className': 'cue-list m-t-20' }, React.createElement('div', { 'className': 'm-t-15 page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Templates'), React.createElement('h2', { 'className': 'page-title with-subtitle text-capital' }, 'Cues')), React.createElement(Button, {
        'className': 'floating-add-button  red-add-button m-r-5',
        'style': { top: '100px' },
        'onClick': this.addCue
    }, '\n            Add new\n        ')), React.createElement('div', { 'className': 'm-t-20' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Cues have not been configured for your practice',
            'sortBy': true,
            'sortable': false,
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('span', { 'className': 'table-heading' }, 'Name and Text')), React.createElement(Th, {
            'column': 'practice',
            'style': {
                width: '190px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Practice')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.cue_list, repeatCue1.bind(this))
    ])));
}