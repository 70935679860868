import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/payment_container";
import store,{history} from '../../redux/store';

import { verifyToken } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../redux/core/actions/menu_state_creator';


class PaymentContainer extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.pushToIndex = this.pushToIndex.bind(this);
    };

    componentWillMount() {
        this.pushToIndex(this.props);
    }
    
    pushToIndex(props) {
        let pathArray = props.location.pathname.split("/");
        if (pathArray.pop() === "payments") {
            history.push("/practice/"+props.selectedRole.practice.id+"/payments/my_plans");
        } 
    }

    componentWillReceiveProps(nextProps) {
        this.pushToIndex(nextProps);
    }

    render() {
        if(!this.props.isAuthenticated || _.isEmpty(this.props.selectedRole)){
            return (<div className="row center-xs m-t-25">
                <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            verifyToken,
            updateMenuOpen: MenuAction.updateMenuOpen
        },
        dispatch
    );
}

function mapStateToProps(state,ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen:state.core.appMenu.menu_open,
        pinned:state.core.appMenu.pinned
    };
}

const PaymentContainerConnected = connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);
export default PaymentContainerConnected;
