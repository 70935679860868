import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import CheckboxGroup from '../../../utils/common_components/checkbox_group_form';
import Checkbox from '../../../utils/common_components/checkbox_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import Switch from '../../../utils/common_components/switch_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
export default function () {
    function repeatTiming1(timing, timingIndex) {
        return React.createElement('div', {
            'className': 'row vetical-align m-b-10',
            'key': timingIndex
        }, React.createElement('div', { 'className': 'border-around m-b-20 m-r-16 col-xs-offset-1 col-xs-4' }, React.createElement(Field, {
            'label': 'Day of the week',
            'name': 'group_class_frequency[' + timingIndex + '].day',
            'component': Select,
            'options': this.state.day_of_week_list,
            'simpleValue': true
        })), React.createElement('div', { 'className': 'border-around m-b-20 m-r-16 col-xs-3' }, React.createElement(Field, {
            'label': 'Start Time',
            'name': 'group_class_frequency[' + timingIndex + '].start_time',
            'component': TimePicker
        })), React.createElement('div', { 'className': 'border-around m-b-20 col-xs-3' }, React.createElement(Field, {
            'label': 'End Time',
            'name': 'group_class_frequency[' + timingIndex + '].end_time',
            'component': TimePicker
        })), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(IconButton, {
            'tooltip': 'Delete',
            'icon': 'delete_forever',
            'className': 'c-danger',
            'ripple': true,
            'onClick': () => this.deleteGroupClassFrequency(timingIndex)
        })));
    }
    return React.createElement('div', { 'className': 'm-10' }, React.createElement('form', {
        'className': 'bg-white no-box-shadow d-flex',
        'style': { background: 'unset !important' }
    }, React.createElement.apply(this, [
        'div',
        {
            'className': 'm-r-24',
            'style': { width: '76.6%' }
        },
        React.createElement('div', { 'className': 'border-around m-b-20' }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'name',
            'type': 'text',
            'component': TextInput,
            'label': 'Group Name'
        })),
        React.createElement('div', {}, React.createElement(Field, {
            'className': 'w-100',
            'name': 'description',
            'type': 'text',
            'component': TextInput,
            'multiline': true,
            'rows': 2,
            'label': 'Description'
        })),
        React.createElement('div', { 'className': 'border-around m-b-20' }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'max_client_count',
            'type': 'number',
            'component': TextInput,
            'label': 'Max number of clients allowed to register'
        })),
        React.createElement('div', { 'className': 'border-around m-b-20' }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'booking_type',
            'component': Select,
            'label': 'Booking type',
            'options': this.state.booking_type,
            'valueKey': 'id',
            'labelKey': 'name',
            'simpleValue': true
        })),
        React.createElement('div', { 'className': 'd-flex m-b-20' }, React.createElement('div', { 'className': 'border-around w-48 m-r-16' }, React.createElement(Field, {
            'label': 'Start Date',
            'name': 'start_date',
            'component': DatePicker,
            'minDate': moment().subtract(1, 'days').toDate()
        })), React.createElement('div', { 'className': 'border-around w-48' }, React.createElement(Field, {
            'label': 'End Date',
            'name': 'end_date',
            'component': DatePicker,
            'minDate': moment().subtract(1, 'days').toDate()
        }))),
        React.createElement('div', { 'className': 'border-around select-chips m-b-20' }, React.createElement(Field, {
            'label': 'Group class trainers',
            'name': 'practitioners',
            'component': Select,
            'options': this.props.practitioners,
            'valueKey': 'id',
            'labelKey': 'name',
            'multi': true
        })),
        React.createElement('label', { 'className': 'w-100 material-label m-b-10' }, 'Which day of the week are the group classes conducted?'),
        this.props.group_class_frequency && this.props.group_class_frequency.length === 0 ? React.createElement('div', {
            'className': 'alert-warning w-100 m-20 text-center',
            'key': '3941'
        }, 'Group class need class timings!') : null,
        _.map(this.props.group_class_frequency, repeatTiming1.bind(this)),
        React.createElement('div', { 'className': 'row' }, React.createElement(Button, {
            'className': 'center-xs height-fit text-transform-none bg-success-border m-b-10',
            'onClick': this.addGroupClassFrequency,
            'label': 'Add Another',
            'mini': true
        }))
    ]), React.createElement('div', { 'className': 'publish-info' }, React.createElement('div', {
        'className': 'bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24',
        '<div': true
    }, React.createElement(Button, {
        'label': 'Save as draft',
        'onClick': this.onDraft,
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Publish',
        'onClick': this.onPublish,
        'className': 'm-l-10 bg-success w-100',
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    })))));
}