// Action Creators
const groupListFill = (group_list,paginate_info,group_template_exists) => ({
    type: 'GROUP_LIST_FILL',
    group_list: group_list,
    paginate_info:paginate_info,
    group_template_exists:group_template_exists
});

const groupFilterListFill = (group_selection_list,consultant_list) => ({
    type: 'GROUP_FILTER_LIST_FILL',
    group_selection_list: group_selection_list,
    consultant_list:consultant_list
});

const groupGetFill = (group) => ({
    type: 'GROUP_GET_FILL',
    group: group
});

const groupAvailableClientsFill = (clients) => ({
    type: 'GROUP_AVAILABLE_CLIENTS_FILL',
    clients: clients,
});

const groupCleanup = () => ({
    type: 'GROUP_CLEANUP',
});

export default {
    groupListFill,
    groupFilterListFill,
    groupGetFill,
    groupAvailableClientsFill,
    groupCleanup,
};
