import React from 'react';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/practice_consultants";
import { reduxForm } from 'redux-form';
import store from '../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {   getConsultantList,    getConsultant,    postConsultant,    inviteConsultant,
    toggleHideConsultant,    resendConsultantInvitation,} from '../../../redux/practice/network/practice_consultant';
import actionCreator from '../../../redux/practice/actions/practice_consultant';
import Utils from '../../../utils/utils';
import { history } from "../../../redux/store";
import Swal from 'sweetalert2';
import PractitionerInvitation from '../../../utils/common_components/invite/user_invitation';
import {consultantSubscribeToPlan} from '../../../redux/payment/network/consultant_plan_subscribe_network';
import {showProgress, hideProgress} from '../../../redux/core/actions/progress_creator';

class PracticeConsultants extends BaseComponent {

    constructor(props, context) {        
        super(props, context);
        this.editDetails = this.editDetails.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);        
        this.updateTitleBio = this.updateTitleBio.bind(this);
        this.hide = this.hide.bind(this);
        this.onChangePractitionerCategory = this.onChangePractitionerCategory.bind(this);
        this.invitePractitioner = this.invitePractitioner.bind(this);
        this.invitePractitionerCancel = this.invitePractitionerCancel.bind(this);
        this.invitePractitionerScreen = this.invitePractitionerScreen.bind(this);
        this.subscribeToPlan = this.subscribeToPlan.bind(this);
        this.state = {
            'practitionerCategory': 'active',
            page_type : "practitioner_list"
        };
    }

    componentWillMount() {
        this.props.getConsultantList({'category': 'active'});
    }

    onChangePractitionerCategory(val){
        if(!val){
            return;
        }
        this.setState({
            practitionerCategory: val
        });
        this.props.getConsultantList({'category': val});
    }

    hide(practitioner) {
        let display_text = "";
        if (this.state.practitionerCategory === 'active' || this.state.practitionerCategory === 'invited') {
            if(practitioner.payment_live){
                display_text = "Would you like to make this dependent partner inactive from your practice? Your monthly payment plan will be unsubscribed automatically.  Are you sure?";
            }else{
                display_text = "Would you like to make this dependent partner inactive from your practice? Are you sure?";
            }
        } else {
            display_text = "Would you like to make this dependent partner active for your practice? Are you sure?";
        }
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {};
            params['id'] = practitioner.id;
            params['category'] = that.state.practitionerCategory;
            that.props.toggleHideConsultant(params).then(() => {
                that.props.getConsultantList({ 'category': that.state.practitionerCategory });
            }).catch((error) => { 

            });
        }, function (dismiss) {
        });
    }

    invitePractitionerScreen() {
        this.setState({
            page_type: "practitioner_invite"
        });        
    }
    
    invitePractitionerCancel() {
        this.setState({
            page_type: "practitioner_list"
        });        

    }    

    invitePractitioner(params) {
        let that = this;
        this.props.inviteConsultant({
            email: params.email,
            subject: params.subject,
            body: params.body
        }).then(()=>{
            this.setState({
                page_type: "practitioner_list",
                practitionerCategory:"invited"
            });    
            this.onChangePractitionerCategory("invited");    
        }).catch((error)=>{});
    }    

    updateTitleBio(practitioner){
        history.push('/practice/'+this.props.selectedRole.practice.id+'/partner/consultant/update_bio/'+practitioner.id);
    }

    editDetails(practitioner){
        history.push('/practice/'+this.props.selectedRole.practice.id+'/partner/consultant/edit/'+practitioner.user.id);
    }

    subscribeToPlan(token,consultant){
        this.props.showProgress();
        this.props.consultantSubscribeToPlan({token:token.id,consultant_id:consultant.id});
    }

    resendInvitation(practitioner){
        this.props.resendConsultantInvitation({
            id: practitioner.user.id,
        }).then((response) => {
        }).catch((error) => {             
        });        
    }

    render() {
        if (this.state.page_type === "practitioner_list") {
            return Template.apply(this);
        } else {
            return <PractitionerInvitation
                        title="Invite a new dependent partner"
                        subject={this.props.canned_message.subject}
                        body={this.props.canned_message.body}
                        handleCancel={this.invitePractitionerCancel}
                        handleInvite={this.invitePractitioner}/>;
        }
    }

    componentWillUnmount() {
        this.props.clean();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getConsultantList,
        getConsultant,
        postConsultant,
        inviteConsultant,
        toggleHideConsultant,
        resendConsultantInvitation,
        consultantSubscribeToPlan,
        showProgress,
        clean: actionCreator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        consultantList: state.practice.practiceConsultants.consultantList,
        selectionList: state.practice.practiceConsultants.selectionList,
        canned_message: state.practice.practiceConsultants.canned_message,
        ...ownProps,
    };
}

const PracticeConsultantsConnected = connect(mapStateToProps,mapDispatchToProps)(PracticeConsultants);
export default PracticeConsultantsConnected;
