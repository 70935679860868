// Action Creators
const goalListFill = (goal_list, hidden_goals) => ({
    type: 'GOAL_LIST_FILL',
    goal_list: goal_list,
    hidden_goals: hidden_goals,
});

const goalTrackingUnitListFill = (goal_tracking_unit) => ({
    type: 'GOAL_TRACKING_UNIT_LIST_FILL',
    goal_tracking_unit: goal_tracking_unit
});

const goalGetFill = (goal, goal_types, goal_tracking_unit) => ({
    type: 'GOAL_GET_FILL',
    goal: goal,
    goal_types: goal_types,
    goal_tracking_unit: goal_tracking_unit
});

const goalCleanup = () => ({
    type: 'GOAL_CLEANUP',
});

export default {
    goalListFill,
    goalGetFill,
    goalTrackingUnitListFill,
    goalCleanup,
};
