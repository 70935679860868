import React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "../templates/facility/facility_list.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { history } from '../../../redux/store';
import Swal from 'sweetalert2';
import { getFacilityList,facilityDelete } from '../../../redux/calendar/network/facility_network.js';
import  facilityActionCreator from '../../../redux/calendar/actions/facility_action';

class FacilityList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addFacility = this.addFacility.bind(this);
        this.editFacility = this.editFacility.bind(this);
        this.deleteFacility = this.deleteFacility.bind(this);
        this.bookFacility = this.bookFacility.bind(this);
        this.viewFacilitySlots = this.viewFacilitySlots.bind(this);
    }

    componentWillMount() {
        this.props.getFacilityList({});
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    addFacility(){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/facility/add");
    }

    editFacility(facility) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/facility/edit/"+facility.id);
    }
    deleteFacility(facility) {
        let that = this;
        Swal.fire({
            title: "Are you sure you want to delete this studio facility?",
            text: "Any associated client bookings will be deleted!",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            that.props.facilityDelete({
                'id': facility.id,
            }).then((response)=>{
                that.props.getFacilityList({});
            }).catch((error)=>{

            });
        }, ()=> { });
    }

    bookFacility(facility) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/facility/book/"+facility.id);
    }

    viewFacilitySlots(facility){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/scheduler/facility/view_calendar/"+facility.id);
    }

    render() {
        return Template.apply(this);
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        facilityList: state.calendar.facilityReducer.facility_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFacilityList,
        facilityDelete,
        cleanUp: facilityActionCreator.cleanModule
    }, dispatch
    );
}


const FacilityListConnected = connect(mapStateToProps, mapDispatchToProps)(FacilityList);
export default FacilityListConnected;