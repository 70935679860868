let initialState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
    onboardData: {},
    timezones: [],
    workfitPin: "",
    clientTitle:"GoMotive",
    is_lms_active: false,
    is_lms_admin: false
};
export default function auth(state = initialState, action) {
    switch (action.type) {        
        case "AUTH_LOGIN_SUCCESS":
            return Object.assign({}, state, {
                isAuthenticated: true,
                isTokenAvailable: true,
                isFetching: false,
            });
        case "AUTH_FAILURE":
            return Object.assign({}, initialState);
        case "AUTH_UPDATE_USER":
            return Object.assign({}, state, { user: action.user });
        case "AUTH_WORKFIT_GENERATE_PIN":
            return Object.assign({}, state, { workfitPin: action.pin });            
        case "AUTH_TIMEZONE_LIST":  
            return Object.assign({}, state, {
                timezones: action.timezones
            });    
        case "AUTH_UPDATE_CLIENT_TITLE":
            return Object.assign({}, state, {
                clientTitle: action.clientTitle
            });    
        case "GET_ONBOARD_DATA":
            return Object.assign({}, state, {
                onboardData: action.data
            });    
        case "GET_SEARCH_ONBOARD_DATA":
            return Object.assign({}, state, {
                onboardSearchData: action.data
            });    
        case "STORE_LMS_DATA":
            return Object.assign({}, state, {
                ...action.data,
            });    
        case 'AUTH_USER_LOGOUT':
            return Object.assign({},initialState)
        default:
            return state;
    }
}
