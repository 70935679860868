import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/practitioner_client_new_engagement.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { createNewClientEngagement } from '../../redux/engagement/network/client_network';
import ClientAction from '../../redux/engagement/actions/client_creator';
import { getCannedMessage } from '../../redux/message/network/canned_message_network';
import { reduxForm } from 'redux-form';
import { EXISTING_CLIENT_NEW_ENGAGEMENT } from '../../redux/message/constants/constants';

class PractitionerClientNewEngagement extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.createNewEngagement = this.createNewEngagement.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    };
    
    componentWillMount() {
        if (!this.props.match.params.client_id){
            history.goBack();
        }
        this.props.getCannedMessage({
            'message_type': EXISTING_CLIENT_NEW_ENGAGEMENT
        });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    createNewEngagement(data) {
        let params = { ...data };
        params['client_id'] = this.props.match.params.client_id;
        this.props.createNewClientEngagement({ ...params }).then((response) => {
            let engagement_id = response['engagement_id'];
            if (engagement_id !== -1) {
                history.push("/practice/"+this.props.selectedRole.practice.id+"/client/engagement/" + engagement_id);
            } else {
                history.push("/practice/"+this.props.selectedRole.practice.id+"/client");
            }            
        }).catch((error) => {
        });
    }

    handleCancel() {
        history.goBack();
    }

    render() {
        if(!_.isEmpty(this.props.canned_message)) {
            return Template.apply(this);
        } else {
            return (<div></div>);
        }
    }

}

function mapStateToProps(state) {
    let initialValues = {};
    initialValues['subject'] = state.message.cannedMessageReducer.canned_message.subject;
    initialValues['body'] = state.message.cannedMessageReducer.canned_message.body;
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        initialValues: initialValues,
        canned_message: state.message.cannedMessageReducer.canned_message,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getCannedMessage,
            createNewClientEngagement,
            cleanUp: ClientAction.clientInstanceCleanup,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'subject',
        'body',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const PractitionerClientNewEngagementForm = reduxForm(
    {
        form: 'PractitionerClientNewEngagementForm',
        enableReinitialize: true,
        validate
    }
)(PractitionerClientNewEngagement);

const PractitionerClientNewEngagementFormConnected = connect(mapStateToProps,mapDispatchToProps)(PractitionerClientNewEngagementForm);
export default PractitionerClientNewEngagementFormConnected;
