// Action Creators
const fillExercise = (news) => ({
    type: 'EXERCISE_FILL',
    news,

});

const categoryFill = (categories) => ({
    type: 'CATEGORY_FILL',
    categories,
})
const cleanModule = () => ({
    type: 'EXERCISE_CLEANUP',
});

export default {
    fillExercise,
    categoryFill,
    cleanModule,
};