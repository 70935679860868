import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import moment from 'moment';
import { getClientHealthDocuments } from '../../redux/client/network/client_network.js';
import Template from './templates/client_health_documents.rt';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import ClientAction from '../../redux/client/actions/client_actions';
import ProgressBar from 'react-toolbox/lib/progress_bar';

class ClientHealthDocuments extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);        
        this.loadPage = this.loadPage.bind(this); 
        this.state={                        
            type:'',
            id:0,
            tracking_date: moment(moment().minutes(0).seconds(0).milliseconds(0)).format("YYYY-MM-DD"),
        };        
    };
        
    componentWillMount() {
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);    
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && _.has(this.props.match.params, 'type') && _.has(this.props.match.params, 'id')) {
            this.setState({
                "type":this.props.match.params.type,
                "id":this.props.match.params.id
            }, () => {
                this.loadPage();
            });
        }
    }
    
    loadPage() {  
        let params = {};
        params["fetch_type"] = this.state.type
        params["id"] = this.state.id;
        this.props.getClientHealthDocuments(params);      
    }

    
    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        if(this.props.program !== null) {
            return Template.apply(this);
        } else {
            return (
                <div className="row center-xs m-t-25">
                    <ProgressBar type="circular" mode="indeterminate" multicolor />
                </div>
            );
        }
    }

}

function mapStateToProps(state) {  
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        program: state.client.clientReducer.v2_selected_program,        
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({        
        getClientHealthDocuments,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
        cleanUp: ClientAction.cleanModule,
        }, 
        dispatch
    );
}

const ClientHealthDocumentsConnected = connect(mapStateToProps,mapDispatchToProps)(ClientHealthDocuments);
export default ClientHealthDocumentsConnected;
