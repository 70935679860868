import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { NavLink } from 'react-router-dom';
import { Select as SelectNew } from 'react-select';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { tetheredSelectAsynFunction as AsyncSelect } from '../../../utils/common_components/select_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Input from 'react-toolbox/lib/input';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement(NavLink, {
        'to': '#',
        'className': 'm-t-20 d-flex back-btn c-grey',
        'onClick': this.cancel
    }, '\n        Back\n    '), React.createElement(Card, { 'className': 'no-box-shadow m-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', { 'className': 'add-media d-flex no-box-shadow' }, React.createElement('div', { 'className': 'media-info p-b-36' }, React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(Card, { 'className': 'no-box-shadow bg-white' }, React.createElement(CardTitle, {
        'title': 'Schedule Notification',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement(CardText, { 'className': 'w-100 p-t-20 p-0' }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'notification_template',
        'labelKey': 'notification_text',
        'valueKey': 'id',
        'component': Select,
        'label': 'Notification Template',
        'options': this.props.notification_templates,
        'clearable': false
    }))), React.createElement('div', { 'className': 'page-subtitle f-14 c-black m-t-16 m-b-21' }, 'Schedule Notification'), React.createElement('div', { 'className': 'd-flex' }, React.createElement('div', { 'className': 'w-50 date-picker p-relative border-around m-r-16' }, React.createElement(Field, {
        'name': 'publish_date',
        'type': 'date',
        'component': DatePicker,
        'label': 'Publish Date'
    })), React.createElement('div', { 'className': 'w-50 time-picker p-relative border-around' }, React.createElement(Field, {
        'name': 'publish_time',
        'component': TimePicker,
        'label': 'Publish Time'
    })))))), React.createElement('div', { 'className': 'publish-info bg-white box-shadow-10 p-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-30 m-b-10 m-r-10 middle-xs' }, React.createElement('div', { 'className': 'w-100 m-l-10' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'target',
        'component': Select,
        'label': 'Target',
        'valueKey': 'db_value',
        'labelKey': 'label'
    })), React.createElement(Button, {
        'label': 'Add to schedule',
        'type': 'submit',
        'disabled': this.props.submitting,
        'className': 'w-100 m-l-10 bg-success w-40',
        'raised': true,
        'onClick': this.scheduleNotificationTemplate,
        '<': true,
        'div': true
    })))))));
}