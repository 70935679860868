import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from '../../../core/components/not_found';
import GIProSpecialities from '../gi_pro_specialities';
import GIProExercises from '../gi_pro_exercises';
import GIProSummary from '../gi_pro_summary';
export default function () {
    return React.createElement('div', { 'className': 'd-flex' }, React.createElement('div', {
        'className': 'content-zone',
        'style': {
            flex: '1',
            padding: '0px 15px'
        }
    }, React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/specialities`,
        'component': GIProSpecialities
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/summary`,
        'component': GIProSummary
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/exercises/:library`,
        'component': GIProExercises
    }), React.createElement(Route, { 'component': NotFound }))));
}