import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { PARTNER } from '../../../core/constants/constants';
import { AppBar } from 'react-toolbox/lib/app_bar';
export default function () {
    return React.createElement('div', { 'className': 'pwd-reset-page row p-t-100 center-xs middle-xs  full-height c-white w-100' }, React.createElement(AppBar, {
        'className': 'main-app-bar-gi',
        'style': { width: '100%' }
    }, React.createElement('a', { 'href': 'https://grayinstitute.com/' }, React.createElement('div', { 'className': 'login-page-logo' }))), React.createElement('form', {
        'className': PARTNER.app_type == 'gi' ? ' big-labels white-labels' : 'tri-reset  big-labels white-labels',
        'onSubmit': this.props.handleSubmit(this.doSave),
        'style': { height: 'fit-content' }
    }, React.createElement('h1', { 'className': 'c-black p-t-24 f-24 pwd-title d-flex align-center' }, React.createElement('div', {
        'className': 'pwd-arrow',
        'onClick': this.goBack
    }), '\n                Forgot Password\n            '), React.createElement('p', { 'className': 'c-black f-16 p-b-24 text-center' }, 'Please enter your registered email address. ', React.createElement('br', {}), '\n                A password reset link will be sent on this address'), React.createElement(Field, {
        'label': 'Email',
        'name': 'email',
        'type': 'text',
        'style': { padding: '0 !important' },
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement('div', { 'className': 'row w-100 p-b-7 center-xs' }, React.createElement(Button, {
        'label': 'Request password Reset',
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success big-button pwd-btn',
        'raised': true,
        'style': { padding: '12px!important' }
    }))));
}