import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import PracticeWelcomeVideoCreator from '../actions/practice_welcome_video';

const practiceWelcomeVideoGetDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_welcome_video_get_details";
        return Axios.post(url, params).then((response) => {
            dispatch(PracticeWelcomeVideoCreator.practiceWelcomeVideoFill(response.welcome_video));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const practiceWelcomeVideoUpload = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_welcome_video_upload";
        return Axios.post(url, params).then((response) => {
            dispatch(PracticeWelcomeVideoCreator.practiceWelcomeVideoFill(response.welcome_video));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const practiceWelcomeVideoPublish = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_welcome_video_publish";
        return Axios.post(url, params).then((response) => {
            dispatch(PracticeWelcomeVideoCreator.practiceWelcomeVideoFill(response.welcome_video));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const practiceWelcomeVideoDelete = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practice_welcome_video_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(PracticeWelcomeVideoCreator.practiceWelcomeVideoFill(response.welcome_video));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    practiceWelcomeVideoGetDetails,
    practiceWelcomeVideoUpload,
    practiceWelcomeVideoPublish,
    practiceWelcomeVideoDelete,
};