import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/performance";
import { formValueSelector, reduxForm } from 'redux-form';
import { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

class Performance extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.selectPerformance = this.selectPerformance.bind(this);
        this.state = {
            performanceModalities: [
                {
                    "id": "analysis",
                    "name": "Analysis"
                },
                {
                    "id": "support",
                    "name": "Support"
                },
                {
                    "id": "elevated_lungeleg",
                    "name": "Elevated (Lunge Leg)"
                },
                {
                    "id": "fixedtrunk",
                    "name": "Fixed Trunk"
                },
                {
                    "id": "unilateralhandswing",
                    "name": "Unilateral Hand Swing"
                },
                {
                    "id": "plane_hands",
                    "name": "Plane (Hands)"
                },
                {
                    "id": "plane_foot",
                    "name": "Plane (Foot)"
                },
                {
                    "id": "hybrid_hands",
                    "name": "Hybrid (Hands)"
                },
                {
                    "id": "hybrid_foot",
                    "name": "Hybrid (Foot)"
                },
                {
                    "id": "pivot_in_chain",
                    "name": "Pivot (In-Chain)"
                },
                {
                    "id": "pivot_out_of_chain",
                    "name": "Pivot (Out-of-Chain)"
                },
                {
                    "id": "load",
                    "name": "Load"
                },
                {
                    "id": "elevated_stanceleg",
                    "name": "Elevated (Stance Leg)"
                },
                {
                    "id": "locomotor",
                    "name": "Locomotor"
                },
                {
                    "id": "spherical",
                    "name": "Spherical"
                }
            ]
        };
    }
    
    selectPerformance(value) {
        this.props.submitPerformance(value);
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

const selector = formValueSelector('PerformanceForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const PerformanceForm = reduxForm(
    {
        form: 'PerformanceForm',
        enableReinitialize: true,
        validate
    }
)(Performance);

const PerformanceConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PerformanceForm);

export default PerformanceConnected;
