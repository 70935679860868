import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../../utils/common_components/textInput_form';
import Switch from '../../../../utils/common_components/switch_form';
import Dropzone from 'react-dropzone';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import VimeoUploader from '../../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../../utils/common_components/vimeo_player';
export default function () {
    return React.createElement('div', { 'className': 'row m-25' }, React.createElement(Card, { 'className': 'col-xs-12 card-full-height' }, React.createElement(CardTitle, { 'title': 'Challenge Workout' }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('form', {
        'onSubmit': this.props.handleSubmit(this.saveChallengeWorkout),
        'method': 'post'
    }, React.createElement('div', { 'className': 'row center-xs' }, !this.state.video_guid ? React.createElement('div', {
        'className': 'col-xs-4 m-t-10 m-b-10',
        'key': '1716'
    }, React.createElement('div', { 'className': 'row' }, React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed
    }))) : null, this.state.video_guid ? React.createElement('div', {
        'className': 'col-xs-4 m-t-10 box-300-225',
        'key': '2289'
    }, React.createElement(VimeoPlayer, {
        'handleUploadProcessing': true,
        'identifier': 'wrapper_video_' + this.state.video_guid,
        'videoList': [this.state.video_guid]
    })) : null, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'workout_name',
        'type': 'text',
        'component': TextInput,
        'label': 'Workout Name',
        'value': '/'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'descriptionRichText',
        'name': 'workout_description',
        'type': 'text',
        'component': TextInput,
        'multiline': true,
        'label': 'Workout Description'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'count',
        'labelKey': 'label',
        'valueKey': 'value',
        'component': Select,
        'label': 'Count',
        'options': this.props.supportingData.selection_list,
        'clearable': false
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'workout_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Workout Duration',
        'value': '/'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'f-20 c-primary' }, 'Movement Meter'), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'mobility_movement_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Mobility (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'strength_movement_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Strength (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'metabolic_movement_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Metabolic (In minutes)'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'power_movement_duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Power (In minutes)'
    }))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'm-r-10',
        'disabled': this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }))))))));
}