import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import Chip from 'react-toolbox/lib/chip';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import PartnerPlanDetails from '../plan_selection_details';
import moment from 'moment';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer';
export default function () {
    function repeatPlan1(plan, planIndex) {
        return [React.createElement('div', {
                'className': 'col-md-3 col-sm-6',
                'key': '16311'
            }, React.createElement('div', {
                'className': 'pricing-widget text-center w-100',
                'onClick': () => {
                    this.doEdit(plan);
                }
            }, React.createElement('div', {
                'className': 'pricing-head f-20 d-flex flex-column p-0 c-white',
                'style': {
                    minHeight: '200px',
                    background: plan.color_code
                }
            }, React.createElement('div', {
                'className': 'w-100 f-400 p-15 m-b-10',
                'style': { background: 'rgba(0,0,0,0.25)' }
            }, plan.name), React.createElement('i', {
                'className': 'material-icons icon-shadow',
                'style': {
                    fontSize: '80px',
                    color: 'white'
                }
            }, 'verified_user'), React.createElement('div', { 'className': 'f-18 w-100 f-200 m-t-10' }, React.createElement('span', { 'className': 'f-14' }, ' ', plan.default_currency.toUpperCase(), ' ', React.createElement('span', { 'className': 'f-28' }, plan.fee_amount), ' / month '))), React.createElement('div', { 'className': 'pricing-body' }, React.createElement('ul', { 'className': 'pricing-list text-center' }, React.createElement('li', {}, plan.is_published ? React.createElement(Chip, {
                'className': 'bg-primary',
                'key': '2607'
            }, 'Published') : null, !plan.is_published ? React.createElement(Chip, { 'key': '2703' }, 'Draft') : null), React.createElement('li', {}, plan.has_been_assigned ? React.createElement(Chip, {
                'className': 'bg-success-red c-white border-r-30',
                'key': '2837'
            }, 'Assigned') : null, !plan.has_been_assigned ? React.createElement(Chip, { 'key': '2961' }, 'Not Assigned') : null), React.createElement('li', {}, React.createElement(Chip, {}, 'Subscription Count : ', plan.subscription_count)), React.createElement('li', { 'className': 'p-10 text-left f-15 f-300' }, React.createElement(RichTextViewer, {
                'className': 'col-xs-12 p-10  text-left',
                'value': plan.description
            }))))))];
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row  center-xs m-10' },
        React.createElement('div', { 'className': 'col-md-3 col-sm-6' }, React.createElement('div', {
            'className': 'pricing-widget text-center w-100',
            'style': { cursor: 'default' }
        }, React.createElement('div', {
            'className': 'pricing-head f-20 d-flex flex-column p-0 c-white',
            'style': {
                minHeight: '200px',
                background: '#8B572A'
            }
        }, React.createElement('div', {
            'className': 'w-100 f-400 p-15 m-b-10',
            'style': { background: 'rgba(0,0,0,0.25)' }
        }, 'Bronze Plan'), React.createElement('i', {
            'className': 'material-icons icon-shadow',
            'style': {
                fontSize: '80px',
                color: 'white'
            }
        }, 'verified_user'), React.createElement('div', { 'className': 'f-18 w-100 f-200 m-t-15' }, React.createElement('span', { 'className': 'f-20' }, ' Free plan '))), React.createElement('div', { 'className': 'pricing-body' }, React.createElement('ul', { 'className': 'pricing-list text-center' }, React.createElement('li', {}, React.createElement(Chip, { 'className': 'bg-primary' }, 'Published')))))),
        _.map(this.props.plans, repeatPlan1.bind(this))
    ]);
}