import BaseComponent from '../../utils/common_components/basecomponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Template from "./templates/subscription";
import store, { history } from '../../redux/store';

class Users extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);

        this.onSearch = _.debounce(this.onSearch.bind(this),1000);

    };
    onSearch(text){
        if(text == null){
            this.loadMore();
        }
        this.setState({search:text},()=>{
            // this.props.getAdminsList({
            //     "app_version": "5.2",
            //     "package_version": "2",
            //     "search": this.state.search,
            //     "page":0,
            //     "practice_id": 276,
            //     "current_role_type": "practice_admin"
            // })
        });

    }


    addUser() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/users/add');
    }

    editUser(user) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/user/edit/' + user.id);
    }
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({


    },
        dispatch
    );
}

const UsersConnected = connect(mapStateToProps, mapDispatchToProps)(Users);
export default UsersConnected;