import BaseComponent from '../../utils/common_components/basecomponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, arrayPush, Field } from 'redux-form';

import Template from "./templates/users_edit";
import store, { history } from '../../redux/store';

let userType = [
    {  label: "Coach", value: "Coach" },
    {  label: "Client", value: "Client" },
    { label: "Admin", value: "Admin" },
]
let radio_options= [
    {
        label : 'Yes',
        value:true
    },
    {
        label : 'No',
        value:false
    }                                 
]
let select_options= [
    {
        label : 'Physical Therapist',
        value: 'Physical Therapist'
    },
    {
        label : 'Physical Trainer',
        value: 'Physical Trainer'
    }                                 
]

class UserEdit extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);

    };

    addUser() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/users/add');
    }

    editUser(user) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/user/edit/' + user.id);
    }
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        userType,
        select_options,
        radio_options,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,

    };
}
const selector = formValueSelector('UserEditForm');
function mapDispatchToProps(dispatch) {
    return bindActionCreators({


    },
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const UserEditForm = reduxForm(
    {
        form: 'UserEditForm',
        enableReinitialize: true,
        validate
    }
)(UserEdit);

const UsersConnected = connect(mapStateToProps, mapDispatchToProps)(UserEditForm);
export default UsersConnected;