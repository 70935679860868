import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'col-xs-12',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': (this.props.calendarEventId ? 'Edit' : 'Add') + ' Appointment',
        'className': 'm-b-10'
    }), React.createElement(CardText, {}, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'calendar_type',
        'component': Select,
        'disabled': this.props.calendarEventId,
        'label': 'Event Type',
        'options': this.props.supportingData.calendar_type,
        'valueKey': 'name',
        'labelKey': 'display_name',
        'simpleValue': true
    })), this.props.selectedCalendarType && this.props.selectedCalendarType === 'appointment' ? React.createElement('div', { 'key': '1903' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'client',
        'component': Select,
        'disabled': this.props.calendarEventId,
        'label': this.props.selectedRole.practice.name + ' clients',
        'options': this.props.supportingData.clients,
        'valueKey': 'id',
        'labelKey': 'name',
        'simpleValue': true
    })) : null, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    })), React.createElement('div', { 'className': 'text-left' }, React.createElement(Field, {
        'label': 'All Day Event',
        'name': 'all_day',
        'component': Switch
    })), React.createElement('div', {}, React.createElement(Field, {
        'label': 'Date',
        'name': 'date',
        'component': DatePicker
    }), !this.props.all_day ? [
        React.createElement(Field, {
            'label': 'Start Time',
            'name': 'start_time',
            'component': TimePicker,
            'key': '29121'
        }),
        React.createElement(Field, {
            'label': 'End Time',
            'name': 'end_time',
            'component': TimePicker,
            'key': '29123'
        })
    ] : null), React.createElement('div', {}, !this.props.calendarEventId ? React.createElement(Button, {
        'label': 'Create',
        'className': 'bg-success m-r-10',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true,
        'key': '3236'
    }) : null, this.props.calendarEventId ? React.createElement(Button, {
        'label': 'Update',
        'className': 'bg-success m-r-10',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true,
        'key': '3595'
    }) : null, this.props.calendarEventId ? React.createElement(Button, {
        'label': 'Delete',
        'className': 'bg-danger m-r-10',
        'onClick': this.delete,
        'primary': true,
        'raised': true,
        'key': '3953'
    }) : null, React.createElement(Button, {
        'label': 'Cancel',
        'onClick': this.props.finishEditing,
        'className': 'bg-accent',
        'primary': true,
        'raised': true
    }))))));
}