import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import DatePicker from '../../../../utils/common_components/datepicker_form';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Notification Template',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.scheduleNotificationTemplate) }, React.createElement('div', { 'className': 'row m-25' }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'notification_template',
        'labelKey': 'notification_text',
        'valueKey': 'id',
        'component': Select,
        'label': 'Notification Template',
        'options': this.props.notification_templates,
        'clearable': false
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'scheduled_date',
        'type': 'date',
        'label': 'Schedule Date',
        'component': DatePicker,
        'minDate': moment().subtract(1, 'days').toDate()
    })), React.createElement('div', { 'className': 'col-xs-12 m-t-25 end-xs m-20' }, React.createElement(Button, {
        'label': 'Save & Publish',
        'className': 'bg-success m-r-10',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'submit',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    })))))));
}