import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { FieldArray } from 'redux-form';
export default function () {
    function repeatGoal_template1(goal_template, goal_templateIndex) {
        return React.createElement('tr', { 'key': goal_template.id }, React.createElement('td', {}, goal_template.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.addGoal(goal_template);
            },
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    return React.createElement('div', { 'className': 'row center-xs m-20' }, !this.props.inlineDisplay ? React.createElement('ol', {
        'className': 'breadcrumb  col-xs-12 text-left',
        'key': '1330'
    }, React.createElement('li', { 'className': 'p-relative' }, React.createElement('a', { 'onClick': this.doCancel }, '\n                    ', this.props.engagement.name, '\n                ')), React.createElement('li', { 'className': 'p-relative' }, React.createElement('a', { 'className': 'active' }, '\n                Goals\n            '))) : null, React.createElement('form', {
        'className': 'col-xs-12',
        'onSubmit': this.props.handleSubmit(this.saveGoal)
    }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(FieldArray, {
        'name': 'goals',
        'component': this.renderGoals
    })))), React.createElement(Dialog, {
        'active': this.state.openGoalTemplateModal,
        'title': 'Please select a goal from the library or create a custom goal',
        'onEscKeyDown': this.hideGoalTemplateModal,
        'onOverlayClick': this.hideGoalTemplateModal
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.goal_templates, repeatGoal_template1.bind(this))
    ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Add Custom Goal',
        'onClick': () => {
            this.addEmptyGoal();
        },
        'className': 'bg-success m-r-10',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Close',
        'accent': true,
        'raised': true,
        'onClick': this.hideGoalTemplateModal
    })))));
}