import * as React from 'react';
import * as _ from 'lodash';
import { Layout } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import Input from 'react-toolbox/lib/input';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { tetheredSelectAsynFunction as AsyncSelect } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import { TetheredSelectWrap as SelectWrap } from '../../../utils/common_components/tetheredSelect';
import ExercisePreview from '../../../exercise/components/exercise_preview';
export default function () {
    function repeatExercise1(exercise, index) {
        return React.createElement('div', {
            'className': 'width-' + (this.props.exerciseSelectionMode ? '150' : '250') + ' box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'exercise_list' + index
        }, exercise.state === 1 ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '3834'
        }, '\n                DRAFT\n            ') : null, exercise.deleted_date ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '3946'
        }, '\n                HIDDEN\n            ') : null, React.createElement('div', {
            'className': 'thumbnail box-' + (this.props.exerciseSelectionMode ? '150-90' : '250-150'),
            'style': { backgroundImage: 'url(' + encodeURI(exercise.exercise_thumbnail_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (exercise.description ? '9' : '12') + ' f-16 truncate',
            'tooltip': exercise.name
        }, '\n                    ', exercise.name, '\n                '), exercise.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': exercise.description,
            'icon': 'info',
            'key': '4532'
        }) : null), React.createElement('div', { 'className': 'row center-xs m-0' }, exercise.deleted_date === null ? [!this.props.exerciseSelectionMode ? React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '47511'
            }, React.createElement(IconButton, {
                'tooltip': 'View Exercise Details',
                'icon': 'play_circle_filled',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.preview(exercise);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Add exercise to workout cart',
                'icon': 'add_circle',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.addExerciseToWorkoutCart(exercise);
                }
            })) : null] : null));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'row start-xs m-10' }, React.createElement('div', { 'className': 'col-xs-12 col-sm-10 col-md-8 p-relative search-box m-b-20 p-5  vertical-align' }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search exercise by name,category,difficulty level,type....',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    })), React.createElement('div', { 'className': 'm-r-20' }, React.createElement(Button, {
        'className': 'bg-accent-dark m-r-20 m-t-5',
        'onClick': this.createWorkoutFromWorkoutCart,
        'icon': 'shopping_cart',
        'floating': true,
        'ripple': true,
        'mini': true
    }, React.createElement('small', { 'className': 'notification-badge' }, this.props.workoutCartExeciseList.length)))), this.props.exerciseList.length === 0 ? [React.createElement('div', {
            'className': 'row center-xs alert-warning m-20',
            'key': '28011'
        }, '\n            There are no exercise(s) for the selected filters. Click on + button to create a new exercise or modify your search filter criteria.\n        ')] : null, React.createElement('div', { 'className': 'row start-xs  m-10' }, React.createElement(SelectWrap, {
        'className': 'col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 m-t-5',
        'placeholder': 'Select Coach',
        'clearable': false,
        'value': this.state.selectedCoach,
        'options': this.state.practitioners,
        'simpleValue': true,
        'labelKey': 'name',
        'valueKey': 'id',
        'onChange': val => {
            this.onSelectCoach(val);
        }
    })), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-10' },
        _.map(this.props.exerciseList, repeatExercise1.bind(this)),
        this.props.paginateInfo.total_pages && this.props.paginateInfo.page < this.props.paginateInfo.total_pages - 1 ? React.createElement('div', {
            'className': 'row center-xs m-30 w-100',
            'key': '5724'
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
            'label': 'Load more exercises...',
            'className': 'bg-primary c-black',
            'onClick': () => {
                this.loadMore(true);
            }
        }))) : null
    ]), React.createElement(ExercisePreview, {
        'exercise': this.state.previewingExercise,
        'onClosePreview': () => {
            this.setState({ previewingExercise: null });
        }
    })), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow',
        'width': this.props.exerciseSelectionMode ? 100 : 50
    }, React.createElement('form', {
        'className': 'medium-labels p-b-30',
        'onSubmit': this.props.handleSubmit(this.applyFilter)
    }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n                  Filters\n            '), React.createElement(Button, {
        'onClick': () => {
            this.resetFilter();
        },
        'className': 'small-button',
        'label': 'Reset',
        'raised': true,
        'primary': true
    }), React.createElement(Button, {
        'onClick': this.applyFilter,
        'className': 'small-button bg-success m-l-10',
        'label': 'Apply',
        'raised': true
    }), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), React.createElement('div', { 'className': 'filter-section p-10' }, !this.props.my_deleted_exercises ? React.createElement('div', { 'key': '7299' }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'threed_map',
        'label': '3DMAPS',
        'component': Switch
    })), React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'gi_golf_library',
        'label': 'Golf',
        'component': Switch
    })), this.props.threed_map && !this.props.gi_golf_library ? React.createElement('div', { 'key': '8007' }, React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'performance',
        'label': 'Select Performance',
        'component': Select,
        'options': this.props.performanceData,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectBadgeLessRender
    })), !(this.props.performance && this.props.performance.id == 'ANALYSIS_MOBILITY') && !(this.props.performance && this.props.performance.id == 'ANALYSIS_STABILITY') ? React.createElement('div', {
        'className': 'm-t-5',
        'key': '8641'
    }, React.createElement(Field, {
        'name': 'most',
        'label': 'Select Mobility/Stability',
        'component': Select,
        'options': this.props.mostData,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectBadgeLessRender
    })) : null, React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'matrix',
        'label': 'Select Matrix',
        'component': Select,
        'options': this.props.matricData,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectBadgeLessRender
    }))) : null, this.props.gi_golf_library && !this.props.threed_map ? React.createElement('div', { 'key': '9978' }, React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'id': 'gi_golf_library_level1',
        'name': 'gi_golf_library_level1',
        'label': 'Select Level 1 Filter',
        'component': Select,
        'options': this.props.filterData.gi_golf,
        'valueKey': 'id',
        'labelKey': 'name',
        'onChange': val => {
            this.giGolfFilter1Select(val);
        }
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'id': 'gi_golf_library_level2',
        'name': 'gi_golf_library_level2',
        'label': 'Select Level 2 Filter',
        'component': Select,
        'options': this.state.gi_golf_library_level2_options,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectBadgeLessRender,
        'onChange': val => {
            this.giGolfFilter2Select(val);
        }
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'id': 'gi_golf_library_level3',
        'name': 'gi_golf_library_level3',
        'label': 'Select Level 3 Filter',
        'component': Select,
        'options': this.state.gi_golf_library_level3_options,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectBadgeLessRender
    }))) : null) : null, !this.props.threed_map && !this.props.gi_golf_library ? React.createElement('div', { 'key': '11955' }, React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Filters'), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category.id === 5 ? React.createElement(Field, {
        'name': 'sports',
        'label': 'Sports Name',
        'component': Select,
        'options': this.props.filterData.sports,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'multi': true,
        'optionRenderer': this.selectRender,
        'key': '12163'
    }) : null), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category.id === 5 ? React.createElement(Field, {
        'name': 'sports_exercise_type',
        'label': 'Sports Condition',
        'component': Select,
        'options': this.props.filterData.sports_exercise_type,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectRender,
        'key': '12765'
    }) : null), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'metric',
        'label': 'Exercise Metric',
        'component': Select,
        'options': this.props.filterData.metric,
        'multi': true,
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'body_part',
        'label': 'Body Part',
        'component': Select,
        'options': this.props.filterData.body_part,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true,
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'difficulty',
        'label': 'Difficulty Level',
        'component': Select,
        'options': this.props.filterData.difficulty,
        'multi': true,
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'cues',
        'label': 'Cues',
        'component': Select,
        'options': this.props.filterData.cues,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'equipments',
        'label': 'Equipment',
        'component': Select,
        'options': this.props.filterData.equipments,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'exercise_types',
        'label': 'Types',
        'component': Select,
        'options': this.props.filterData.exercise_types,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'action',
        'label': 'Action',
        'component': Select,
        'options': this.props.filterData.action,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'driver',
        'label': 'Driver',
        'component': Select,
        'options': this.props.filterData.driver,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'direction',
        'label': 'Direction',
        'component': Select,
        'options': this.props.filterData.direction,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    }))) : null))));
}