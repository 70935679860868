import React  from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/gi_pro_exercises";
import { reduxForm,formValueSelector, change } from 'redux-form';
import { API_VERSION, PACKAGE } from '../../redux/practitioner/constants/constants';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';
import Axios from '../../utils/network/axios';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {listExercise, deleteExercise, toggleExerciseFavorite,getExerciseFilters} from '../../redux/exercise/network/exercise';
import { listPractitioner } from '../../redux/practitioner/network/practitioner_network';
import ExerciseActionCreator from '../../redux/exercise/actions/exercise_list';
import Utils from '../../utils/utils';
import {history} from "../../redux/store";
import Swal from 'sweetalert2';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { EXERCISE_LIST_VIDEO } from '../../utils/help_video';
import ClientAction from '../../redux/engagement/actions/client_creator';
import { createWorkoutFromWorkoutCart } from '../../redux/program/network/program_list';
import MenuAction from '../../redux/core/actions/menu_state_creator';

let performanceData = [
    { "id": "ANALYSIS_MOBILITY", name: "Mobility" },
    { "id": "ANALYSIS_STABILITY", name: "Stability" },
    { "id": "PERFORMANCE_SUPPORT", name: "Performance - Support" },
    { "id": "PERFORMANCE_ELEVATED_LUNGELEG", name: "Performance - Elevated (Lunge Leg)" },
    { "id": "PERFORMANCE_FIXEDTRUNK", name: "Performance - Fixed Trunk" },
    { "id": "PERFORMANCE_UNILATERALHANDSWING", name: "Performance - Unilateral Hand Swing" },
    { "id": "PERFORMANCE_PLANE_HANDS", name: "Performance - Plane (Hands)" },
    { "id": "PERFORMANCE_PLANE_FOOT", name: "Performance - Plane (Foot)" },
    { "id": "PERFORMANCE_HYBRID_HANDS", name: "Performance - Hybrid (Hands)" },
    { "id": "PERFORMANCE_HYBRID_FOOT", name: "Performance - Hybrid (Foot)" },
    { "id": "PERFORMANCE_PIVOT_IN_CHAIN", name: "Performance - Pivot (In-Chain)" },
    { "id": "PERFORMANCE_PIVOT_OUT_OF_CHAIN", name: "Performance - Pivot (Out-of-Chain)" },
    { "id": "PERFORMANCE_LOAD", name: "Performance - Load" },
    { "id": "PERFORMANCE_ELEVATED_STANCELEG", name: "Performance - Elevated (Stance Leg)" },
    { "id": "PERFORMANCE_LOCOMOTOR", name: "Performance - Locomotor" },
    { "id": "PERFORMANCE_SPHERICAL", name: "Performance - Spherical" }
];

let mostData = [
    { "id": "MOBILITY", name: "Mobility" },
    { "id": "STABILITY", name: "Stability" }
];

let matricData = [
    { "id": "ANT", name: "ANT" },
    { "id": "PST", name: "PST" },
    { "id": "SSL", name: "SSL" },
    { "id": "OSL", name: "OSL" },
    { "id": "SSR", name: "SSR" },
    { "id": "OSR", name: "OSR" }
];


class GIProExercises extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.preview = this.preview.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.drawerToggle = this.drawerToggle.bind(this);
        this.selectRender = this.selectRender.bind(this);
        this.valueRender = this.valueRender.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.onSelectCoach = this.onSelectCoach.bind(this);
        this.listPractitioner = this.listPractitioner.bind(this);
        this.setFilterPreferences = _.debounce(this.setFilterPreferences.bind(this),1000);
        this.giGolfFilter1Select = this.giGolfFilter1Select.bind(this);
        this.giGolfFilter2Select = this.giGolfFilter2Select.bind(this);
        this.addExerciseToWorkoutCart = this.addExerciseToWorkoutCart.bind(this);
        this.createWorkoutFromWorkoutCart = this.createWorkoutFromWorkoutCart.bind(this);
        this.state = {
            search:"",
            selectedCoach: null,
            practitioners: [],
            filterPreferences:{first:true},
            previewingExercise: null,            
            showFilter:false,
            gi_golf_library_level1_value: null,
            gi_golf_library_level2_value: null,
            gi_golf_library_level2_options: null,
            gi_golf_library_level3_value: null,
            gi_golf_library_level3_options: null,
            exercise_library_to_be_loaded: null
        };
    }

    componentWillMount() {       
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);        
    }

    componentDidMount() {
        this.loadMore(false,true);
        if(this.props.match !== undefined && this.props.match.params.library === "3dmaps") {
            this.setState({
                exercise_library_to_be_loaded: this.props.match.params.library,
                filterPreferences: {first:true, 'threedmaps_filter': 'gi_3dmaps'}
            }, () => {
                this.fetchFilterData();    
            });
        } else if(this.props.match !== undefined && this.props.match.params.library === "gi_golf") {
            this.setState({
                exercise_library_to_be_loaded: this.props.match.params.library,
                filterPreferences: {first:true, 'threedmaps_filter': 'gi_golf'}
            }, () => {
                this.fetchFilterData();    
            });
        } else if(this.props.match !== undefined && this.props.match.params.library === "gi") {
            this.setState({
                exercise_library_to_be_loaded: this.props.match.params.library,
                filterPreferences: {first:false, 'partners': ['2'], 'my_exercises': false, 'my_practice_exercise': false}
            }, () => {
                this.fetchFilterData();    
            });
        } else {
            this.fetchFilterData();
        }       
        this.props.updateHelpVideo(EXERCISE_LIST_VIDEO);
    }

    componentWillReceiveProps(nextProps) {
        if(!_.isEmpty(this.props.current_filters) &&  !_.isEqual(this.props.current_filters,nextProps.current_filters)){
            this.setFilterPreferences(nextProps.current_filters);
        }
    }

    giGolfFilter1Select(value) {
        if(value !== null) {
            this.setState({
                gi_golf_library_level2_options: value.children,
            });            
        }        
    }

    giGolfFilter2Select(value) {
        if(value !== null) {
            this.setState({
                gi_golf_library_level3_options: value.children
            })
        };
    }

    selectBadgeLessRender(option){
        return (
            <span>{option.label ? option.label : option.name}</span>
        );
    }

    selectRender(option){
        return (
            <span>{option.label ? option.label : option.name}<span className="pull-right badge bg-success" >{option.count ? option.count : 0}</span></span>
        );
    }

    valueRender(option){
        return (
            <span className="vertical-align">{option.label ? option.label : option.name}<span className="badge bg-success m-l-10" >{option.count ? option.count : 0}</span></span>
        );
    }

    applyFilter(){
        this.setState({showFilter:false});
        this.loadMore();
    }
    resetFilter(){
        this.props.reset();
    }
    
    loadMore(increment=false,first=false) {
        let params = { ...this.props.paginateInfo,...this.state.filterPreferences};
        if(first){
            params.first = first; 
            if(this.props.match !== undefined && this.props.match.params.library === '3dmaps'){
                params.threedmaps_filter = 'gi_3dmaps';
            } else if(this.props.match !== undefined && this.props.match.params.library === 'gi_golf') {
                params.threedmaps_filter = 'gi_golf';
            } else if(this.props.match !== undefined && this.props.match.params.library === 'gi') {
                params.first = false;
                params.partners = ['2']
                params.my_exercises = false;
                params.my_practice_exercise = false;
            }
        }
        params.search = this.state.search;
        if(increment){
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        this.props.listExercise(params);
        this.listPractitioner({...params, select: true})
            .then(async (response) => {
                // this.practitioners = response['practitioners'];
                this.setState({practitioners: response['practitioners']}, console.log);
            })
            .catch((error) => {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 400
                ) {
                    Utils.showErrorAlert(error.response.data.reason);
                }
            });

        // this.testInterval = setInterval(() => console.log(this.state.practitioners), 1000);
    }

    setFilterPreferences(filterData) {
        let params = {};
        if(this.props.match !== undefined && this.props.match.params.library === '3dmaps'){
            params.threedmaps_filter = 'gi_3dmaps';
        } else if(this.props.match !== undefined && this.props.match.params.library === 'gi_golf') {
            params.threedmaps_filter = 'gi_golf';
        } else if(this.props.match !== undefined && this.props.match.params.library === 'gi') {
            params.partners = ['2']
            params.my_exercises = false;
            params.my_practice_exercise = false;
        }
        if(_.has(filterData,"is_favorite")){
            params.is_favorite = filterData.is_favorite;
        }        
        if(_.has(filterData,"is_evidence_based")){
            params.is_evidence_based = filterData.is_evidence_based;
        }                
        if(filterData.my_deleted_exercises) {
            params["my_deleted_exercises"] = true;
            this.setState({filterPreferences:params});
        } else if (filterData.threed_map) {
            let threedmaps_filter = "gi_3dmaps";
            if (filterData.performance) {
                threedmaps_filter = threedmaps_filter + "_" + filterData.performance.id;
            }
            if (filterData.most) {
                threedmaps_filter = threedmaps_filter + "_" + filterData.most.id;
            }            
            if (filterData.matrix) {
                threedmaps_filter = threedmaps_filter + "_" + filterData.matrix.id;
            }
            params.threedmaps_filter = threedmaps_filter;            
            this.setState({filterPreferences:params});
        } else if(filterData.gi_golf_library) {
            let gi_golf_filter = "gi_golf";
            if(filterData.gi_golf_library_level3 !== undefined && filterData.gi_golf_library_level3 !== null) {
                gi_golf_filter = filterData.gi_golf_library_level3.id;
            } else if(filterData.gi_golf_library_level2 !== undefined && filterData.gi_golf_library_level2 !== null) {
                gi_golf_filter = filterData.gi_golf_library_level2.id;
            } else if(filterData.gi_golf_library_level1 !== undefined && filterData.gi_golf_library_level1 !== null) {
                gi_golf_filter = filterData.gi_golf_library_level1.id;
            }
            params.threedmaps_filter = gi_golf_filter;            
            this.setState({filterPreferences: params});
        } else {
            params["my_deleted_exercises"] = false;
            if(filterData.action &&  filterData.action.length > 0){
                params.action =  Utils.makeObjectArrayFlat(filterData.action,"id");
            }
            if(filterData.body_part &&  filterData.body_part.length > 0){
                params.body_part =  Utils.makeObjectArrayFlat(filterData.body_part,"id");
            }
            if(filterData.cues &&  filterData.cues.length > 0){
                params.cues =  Utils.makeObjectArrayFlat(filterData.cues,"id");
            }
            if(filterData.difficulty &&  filterData.difficulty.length > 0){
                params.difficulty =  Utils.makeObjectArrayFlat(filterData.difficulty,"value");
            }
            if(filterData.direction &&  filterData.direction.length > 0){
                params.direction =  Utils.makeObjectArrayFlat(filterData.direction,"id");
            }
            if(filterData.driver &&  filterData.driver.length > 0){
                params.driver =  Utils.makeObjectArrayFlat(filterData.driver,"id");
            }
            if(filterData.equipments &&  filterData.equipments.length > 0){
                params.equipments =  Utils.makeObjectArrayFlat(filterData.equipments,"id");
            }
            if(filterData.exercise_types &&  filterData.exercise_types.length > 0){
                params.exercise_types =  Utils.makeObjectArrayFlat(filterData.exercise_types,"id");
            }
            if(filterData.metric &&  filterData.metric.length > 0){
                params.metric = filterData.metric;
                params.metric =  Utils.makeObjectArrayFlat(filterData.metric,"value");
            }
            if(filterData.category && filterData.category.id){
                params.category = filterData.category.id;
                if(filterData.category.id === 5 &&  filterData.sports && filterData.sports.length > 0){
                    params.sports =  Utils.makeObjectArrayFlat(filterData.sports,"id"); 
                }
                if(filterData.category.id === 5  && filterData.sports_exercise_type && filterData.sports_exercise_type.id){
                    params.sports_exercise_type =  filterData.sports_exercise_type.id;
                }
            }
            if(_.includes(filterData.exercise_sources, "my_exercises")){
                params.my_exercises = true;
            }            
            if(_.includes(filterData.exercise_sources, "my_practice_exercise")){
                params.my_practice_exercise = true;
            }
            let partners  = _.filter(filterData.exercise_sources,(item)=>{
                if(item === "my_exercises" || item === "my_practice_exercise"){
                    return false;
                }else{
                    return true;
                }
            });            
            if(partners.length > 0){
                params.partners = partners;
            }
            this.setState({filterPreferences:params},()=>{
                this.fetchFilterData();
            });
        }
    }

    fetchFilterData(){
        this.props.getExerciseFilters(this.state.filterPreferences);
    }

    drawerToggle = () => {
        this.setState({showFilter: !this.state.showFilter});
      };
 
    preview(exercise) {
        this.setState({
            previewingExercise: exercise,
        });
    }

    toggleFavorite(id) {
        this.props.toggleExerciseFavorite({
             id: id
        }).then((response) => {
            this.fetchFilterData();
        }).catch(() => { });
    }

    delete(id, toggle_visibility){
        let that = this;
        let display_text = "";
        if(toggle_visibility === "hide") {
            display_text = "Are you sure you want to hide this exercise";
        } else {
            display_text = "Are you sure you want to unhide this exercise";
        }
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {            
            that.props.deleteExercise({
                "exercise_id": id,
                "toggle_visibility": toggle_visibility,
            }).then(()=>{
            }).catch(()=>{});            
        }, function (dismiss) {
                        
        });
    }

    onSearch(text){
        this.setState({search:text},()=>{
                this.loadMore();
        });
    }
    
    onSelectCoach(coach_id) {
        this.setState({ selectedCoach: coach_id}, () => {});
    }

    add(){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/exercise/add");
    }

    edit(id){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/exercise/edit/"+id);
    }

    addExerciseToWorkoutCart(exercise) {
        this.props.addExerciseToWorkoutCartActionCreator(exercise);
        Utils.showInfoAlert("Exercise has been added to workout cart");
    }

    createWorkoutFromWorkoutCart() {
        let proceed = true;
        if (this.props.workoutCartExeciseList.length === 0) {
            Utils.showErrorAlert("You have not added any exercise to the workout cart");
            proceed = false;
        }

        if (!this.state.selectedCoach) {
            Utils.showErrorAlert("You have not selected any coach");
            proceed = false;
        }
        
        if (proceed) {
            let that = this;
            Swal.fire({
                title: "Are you sure?",
                text: "Would you like to view all the exercises added in to workout cart?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections:true
            }).then(function (res) {
                if (['cancel', 'close'].includes(res['dismiss'])) return false;
                sessionStorage.setItem('selectedCoach', that.state.selectedCoach);
                history.push('/gipro/summary', { state: that.state });
                return true;
            }, function () {
                   
            });                 
        }
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        // this.props.clean();
        // if (this.testInterval) clearInterval(this.testInterval)
        this.props.clearHelpVideo();
        // this.props.clearClientSelection();
    }

    listPractitioner(params) {
        const url = '/' + API_VERSION + '/' + PACKAGE + '/practitioner_list';
        return Axios.post(url, params);
    }

}

GIProExercises.propTypes = {
    exerciseSelectionMode: PropTypes.bool,
    onExerciseSelection: PropTypes.func,
};
GIProExercises.defaultProps = {
    exerciseSelectionMode: false,
    onExerciseSelection: () => { },
};
const selector = formValueSelector('ExerciseFilterForm');
function mapDispatchToProps(dispatch) {
    return (
        bindActionCreators({
            deleteExercise,
            listExercise,
            listPractitioner,
            toggleExerciseFavorite,
            getExerciseFilters,
            clean: ExerciseActionCreator.cleanModule,
            incrementPageCount: ExerciseActionCreator.incrementPageCount,
            updateHelpVideo: HelpVideoCreator.updateHelpVideo,
            clearHelpVideo: HelpVideoCreator.clearHelpVideo,
            clearClientSelection: ClientAction.clientSelectionClear,
            addExerciseToWorkoutCartActionCreator: ExerciseActionCreator.addExerciseToWorkoutCart,
            clearWorkoutCartActionCreator: ExerciseActionCreator.clearWorkoutCart,
            createWorkoutFromWorkoutCart,
            updateMenuOpen:MenuAction.updateMenuOpen,
            updateMenuPinned:MenuAction.updateMenuPinned,            
            change,
        }, dispatch)
    );
}

function mapStateToProps(state, ownProps) {    
    let exerciseSources = [];
    let filterData = state.exercise.exerciseList.filterData;
    let searchPreferences = state.exercise.exerciseList.searchPreferences;
    
    let initialValues = {
        exercise_sources:[],
        category:null,
        direction:[],
        practitioners: [],
        cues:[],
        sports_exercise_type:[],
        difficulty:[],
        body_part:[],
        metric:[],
        driver:[],
        equipments:[],
        action:[],
        is_favorite: _.has(searchPreferences, "is_favorite") ? searchPreferences.is_favorite : false,
        is_evidence_based:_.has(searchPreferences,"is_evidence_based")  ? searchPreferences.is_evidence_based : false,
        threed_map: ownProps.match !== undefined && ownProps.match.params.library === '3dmaps' ? true : false,
        gi_golf_library: ownProps.match !== undefined && ownProps.match.params.library === 'gi_golf' ? true : false,
    };
    if(_.has(searchPreferences,"partners")){
        _.forEach(searchPreferences.partners,(item)=>{
            initialValues.exercise_sources.push(item.toString());
        });
    }
    if(_.has(searchPreferences,"my_exercises") && searchPreferences.my_exercises){
        initialValues.exercise_sources.push("my_exercises");
    }    
    if(_.has(searchPreferences,"my_practice_exercise") && searchPreferences.my_practice_exercise){
        initialValues.exercise_sources.push("my_practice_exercise");
    }
    if(!_.isEmpty(filterData)){
        exerciseSources.push({label:"My Exercises",value:"my_exercises",count:filterData.my_exercises});
        exerciseSources.push({label:"My Practice Exercises",value:"my_practice_exercise",count:filterData.practice});
        _.forEach(filterData.partners,(item)=>{
            exerciseSources.push({label:item.name,value:item.id.toString(),count:item.count});
        });
    }

    let threed_map = selector(state, 'threed_map');
    let gi_golf_library = selector(state, 'gi_golf_library');    
    if(threed_map) {
        gi_golf_library = false;
    }else if(gi_golf_library) {
        threed_map = false; 
    }
    return {
        performanceData,
        mostData,
        matricData,
        practitioners: state.practitioners,
        exerciseList: state.exercise.exerciseList.exerciseList,
        selectedRole: state.auth.roleReducer.selectedRole,
        is3DMAPSEnabled: state.auth.roleReducer.selectedRole.is_3dmaps_enabled,
        isGIGolfEnabled: state.auth.roleReducer.selectedRole.is_gi_golf_enabled,
        paginateInfo: state.exercise.exerciseList.paginateInfo,
        selectedClientForAction: state.engagement.clientReducer.selected_client_for_action,
        filterData:filterData,
        exerciseSources:exerciseSources,
        initialValues:initialValues,
        my_deleted_exercises: selector(state, 'my_deleted_exercises'),
        threed_map: threed_map,
        gi_golf_library: gi_golf_library,      
        performance: selector(state, 'performance'),
        category: selector(state, 'category'),
        exercise_sources: selector(state, 'exercise_sources'),
        workoutCartExeciseList: state.exercise.exerciseList.workoutCartExeciseList,
        business_partner: state.practitioner.practitionerReducer.business_partner,
        current_filters: selector(
            state,
            'my_deleted_exercises',
            'threed_map',
            'performance',
            'most',
            'matrix',
            'gi_golf_library',
            'gi_golf_library_level1',
            'gi_golf_library_level2',
            'gi_golf_library_level3',
            'exercise_sources',
            'category', 
            'sports', 
            'sports_exercise_type',
            'metric',
            'body_part',
            'difficulty',
            'cues',
            'equipments',
            'exercise_types',
            'action',
            'driver',
            'direction',
            'is_favorite',
            'is_evidence_based',
    ),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const GIProExercisesFilterForm = reduxForm(
    {
        form: 'ExerciseFilterForm',
        enableReinitialize: true,
        validate
    }
)(GIProExercises);

const GIProExercisesConnected = connect(mapStateToProps,mapDispatchToProps)(GIProExercisesFilterForm);
export default GIProExercisesConnected;
