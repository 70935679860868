let initialState = {
    profile: {},
    supportingData: {},
    client_search_preference:{},
    user_movement_meter_configuration:{}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "USER_PROFILE_FILL":
            if(!action.profile){
                return state;
            }
            return Object.assign({}, state, {
                profile: action.profile,
                supportingData: action.supportingData
            });
        case "USER_PROFILE_CLIENT_SEARCH_PREFERENCE":
            if(!action.client_search_preference){
                return state;
            }
            return Object.assign({}, state, {
                client_search_preference: action.client_search_preference,
            });           
        case "USER_MOVEMENT_METER_CONFIGURATION":
            if(!action.user_movement_meter_configuration){
                return state;
            }
            return Object.assign({}, state, {
                user_movement_meter_configuration: action.user_movement_meter_configuration,
            });           
        case "USER_PROFILE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
