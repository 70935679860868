import * as React from 'react';
import * as _ from 'lodash';
import Input from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button';
import { Field } from 'redux-form';
import Tabs from '../notifications_tabs';
export default function () {
    return React.createElement('div', { 'className': 'user-container full-height w-100' }, React.createElement('div', { 'className': 'row vertical-align flex-sb start-xs m-t-24 m-10' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'f-14 page-subtitle' }, 'Marketing'), React.createElement('h2', { 'className': 'm-0 page-title' }, 'Notifications')), React.createElement('div', { 'className': 'w-70 d-flex flex-end' }, React.createElement(Button, {
        'className': 'width-fit p-l-46 p-r-46 bg-success',
        'onClick': this.addNotification,
        'floating': true
    }, 'Add New'))), React.createElement('div', { 'className': 'tabs-container card-bg m-t-20 m-b-135 p-l-17 p-r-17 p-t-7 p-b-24' }, React.createElement(Tabs, {})));
}