import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import { IconButton } from 'react-toolbox/lib/button';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import Chip from 'react-toolbox/lib/chip';
import ProgressionView from '../progression_view';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import MultiDatePicker from '../../../utils/common_components/multi_date_picker';
export default function () {
    function repeatDay1(day, index) {
        return React.createElement(Chip, {
            'className': 'bg-success-red c-white chip-thin',
            'key': 'days' + index
        }, '\n                    ', day, '\n                ');
    }
    return React.createElement('div', { 'className': 'row m-t-20' }, this.state.showDatePicker ? React.createElement(MultiDatePicker, {
        'active': this.state.showDatePicker,
        'enabledDays': this.props.progression.available_dates,
        'selectedDays': this.props.progression.selected_dates,
        'disableSelection': this.props.disableEditing,
        'onCancel': () => {
            this.finishEditingDays(false);
        },
        'onAccept': selectedDays => {
            this.setState({ updatedDays: selectedDays }, () => {
                this.finishEditingDays(true);
            });
        },
        'key': '1937'
    }) : null, this.props.program.schedule_type !== 2 ? React.createElement('div', {
        'className': 'col-xs-12 bg-accent p-5',
        'key': '2379'
    }, React.createElement('div', { 'className': 'row middle-xs' }, !this.state.isEditingDays && this.props.progression.days_display ? React.createElement.apply(this, [
        'div',
        {
            'className': 'col-xs-10 start-xs p-l-10',
            'key': '2512'
        },
        _.map(this.props.progression.days_display.slice(0, 10), repeatDay1.bind(this)),
        this.props.progression.days_display.length > 10 ? React.createElement(TooltipWrapper, {
            'className': 'di-block',
            'tooltip': 'Click calendar icon to view all workout dates',
            'key': '2848'
        }, React.createElement(Chip, { 'className': 'bg-success-red c-white chip-thin' }, '\n                        more...\n                    ')) : null
    ]) : null, this.state.isEditingDays ? React.createElement('div', {
        'className': 'col-xs-10 start-xs p-l-10',
        'key': '3190'
    }, React.createElement(Select, {
        'value': this.state.updatedDays,
        'options': this.props.progression.days_options,
        'multi': true,
        'onChange': values => {
            this.setState({ updatedDays: values });
        }
    })) : null, !this.props.disableEditing && this.props.programType !== 'workout' ? React.createElement('div', {
        'className': 'col-xs-2 end-xs',
        'key': '3495'
    }, this.state.isEditingDays ? React.createElement(IconButton, {
        'icon': 'check_circle',
        'className': 'c-success',
        'onClick': () => this.finishEditingDays(true),
        'key': '3614'
    }) : null, this.state.isEditingDays ? React.createElement(IconButton, {
        'icon': 'cancel',
        'className': 'c-danger',
        'onClick': () => this.finishEditingDays(false),
        'key': '3760'
    }) : null, !this.state.isEditingDays ? React.createElement(IconButton, {
        'icon': 'edit',
        'className': 'c-white',
        'onClick': () => this.startEditingDays(),
        'key': '3900'
    }) : null) : null, this.props.programType === 'workout' ? React.createElement('div', {
        'className': 'col-xs-2 end-xs',
        'key': '4047'
    }, React.createElement(IconButton, {
        'tooltip': 'Click to choose dates',
        'icon': 'event',
        'className': 'c-white',
        'onClick': () => this.startEditingDays()
    })) : null)) : null, React.createElement('div', { 'className': 'col-xs-12 m-t-20' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-1 start-xs' }, React.createElement('div', {
        'className': 'row center-xs bg-warning p-10',
        'style': { width: '75px' }
    }, React.createElement(FontIcon, { 'value': 'timer' }), React.createElement('span', { 'className': 'm-t-5 m-l-5' }, '\n                        ', this.props.progression.duration, '\n                    '), React.createElement('div', {}, '\n                        minutes\n                    ')), React.createElement('div', { 'className': 'row m-t-20' }, React.createElement(Button, {
        'className': 'bg-primary m-l-16',
        'icon': this.props.disableEditing ? 'pageview' : 'edit',
        'floating': true,
        'ripple': true,
        'mini': true,
        'onClick': this.edit
    })), !this.props.disableEditing ? React.createElement('div', {
        'className': 'row m-t-10',
        'key': '5029'
    }, React.createElement(Button, {
        'className': 'bg-danger m-l-16',
        'icon': 'delete_forever',
        'floating': true,
        'ripple': true,
        'mini': true,
        'onClick': this.delete
    })) : null), React.createElement('div', { 'className': 'col-xs-11' }, React.createElement(ProgressionView, {
        'progression': this.props.progression,
        'selectedRole': this.props.selectedRole
    })))));
}