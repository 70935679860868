import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { NavLink } from 'react-router-dom';
import { Select as SelectNew } from 'react-select';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { tetheredSelectAsynFunction as AsyncSelect } from '../../../utils/common_components/select_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import Input from 'react-toolbox/lib/input';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement(NavLink, {
        'to': '#',
        'className': 'm-t-20 d-flex back-btn c-grey',
        'onClick': this.cancel
    }, '\n        Back\n    '), React.createElement(Card, { 'className': 'no-box-shadow m-t-15 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', { 'className': 'add-media d-flex no-box-shadow' }, React.createElement('div', { 'className': 'media-info p-b-36' }, React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(Card, { 'className': 'no-box-shadow bg-white' }, React.createElement(CardTitle, {
        'title': 'Create New Bundle',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement(CardText, { 'className': 'd-flex w-100 p-t-20 p-0' }, React.createElement('div', { 'className': 'w-60 m-r-16' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'bundle_name',
        'type': 'text',
        'component': TextInput,
        'hint': 'Bundle Name'
    })), React.createElement('div', { 'className': 'w-20 m-r-16' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'sku',
        'type': 'text',
        'component': TextInput,
        'hint': 'SKU'
    })), React.createElement('div', { 'className': 'border-around w-20' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'sku',
        'type': 'bundle_price',
        'component': TextInput,
        'label': 'Bundle Price'
    }))), React.createElement('div', { 'className': 'page-subtitle f-14 c-black m-t-16 m-b-21' }, 'Bundled Workout Templates'), React.createElement('div', { 'className': 'p-relative search-box no-box-shadow m-b-20 p-5  vertical-align' }, React.createElement('div', { 'className': 'associated-item p-relative w-100' }, React.createElement(Field, {
        'component': Select,
        'multi': true,
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'onChange': this.onSearch,
        'valueKey': 'id',
        'labelKey': 'name',
        'type': 'text',
        'name': 'news_workout_templates',
        'label': 'Search by title'
    }))), React.createElement('div', { 'className': 'associated-content' }, React.createElement('div', { 'className': 'associated-title d-flex' }, React.createElement('div', {
        'className': 'image-container m-r-8',
        'style': {
            backgroundColor: '#C4C4C4',
            width: '77px',
            height: '48px'
        }
    }), React.createElement('div', {
        'className': 'w-70 row bg-white d-flex flex-column m-0 m-t-8 p-l-5 m-r-5',
        'style': { width: '68%' }
    }, React.createElement(TooltipWrapper, { 'className': 'f-16 truncate' }, '\n                                        Pelvicore Impact 1.0\n                                    '), React.createElement('div', { 'className': 'width-fit badge-blue c-white p-t-2 p-b-2 p-l-4 p-r-4 f-10 f-300 m-b-2' }, '\n                                        Gray Institute\n                                    ')), React.createElement('div', { 'className': 'w-10 d-flex flex-end align-center' }, React.createElement('span', { 'className': 'paid-icon d-flex align-center justify-center' }, '$')), React.createElement('div', { 'className': 'row center-xs align-center m-0' }, React.createElement('div', { 'className': 'cancel d-flex col-xs-12 align-center p-2 m-0' }))))))), React.createElement('div', { 'className': 'publish-info bg-white box-shadow-10 p-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-30 m-b-10 m-r-10 middle-xs' }, React.createElement('div', { 'className': 'w-100 m-l-10' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'category',
        'component': Select,
        'label': 'Category',
        'valueKey': 'db_value',
        'labelKey': 'label'
    })), React.createElement(Button, {
        'label': 'Unpublish Bundle',
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'raised': true,
        'onClick': this.saveAsDraft
    }), React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.submitting,
        'className': 'w-100 m-l-10 bg-success w-40',
        'raised': true,
        'onClick': this.saveAndPublish,
        '<': true,
        'div': true
    })))))));
}