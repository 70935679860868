import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
import Button from 'react-toolbox/lib/button';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'New Game Plan',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.createNewEngagement) }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'subject',
        'type': 'text',
        'component': TextInput,
        'label': 'Subject'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Body',
        'value': this.props.body
    })), React.createElement('div', { 'className': 'p-5 start-xs c-danger' }, '\n                        Note: Do not remove text between 2 flower braces (including the flower braces). \n                        Things may break if you go ahead and change the same. \n                        Do contact GoMotive support, if you would like to modify the same.\n                    ')), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Email client',
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'm-l-5 bg-accent',
        'type': 'button',
        'onClick': this.handleCancel,
        'primary': true,
        'raised': true
    })))))));
}