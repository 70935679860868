let initialState = {
    consultant: {},
    consultantList: [],
    selectionList: [],
    canned_message: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_CONSULTANT_LIST_FILL":
            if (!action.consultantList){
                return state;
            }
            return Object.assign({}, state, {
                consultantList: action.consultantList,
                selectionList: action.selection_list,
                canned_message: action.canned_message
            });
        case "PRACTICE_CONSULTANT_UPDATE":
            if (!action.consultant){
                return state;
            }
            let consultantList = _.map(state.consultantList, (item) => {
                if (item.id === action.consultant.id) {
                    return action.consultant;
                } else {
                    return item;
                }
            });
            return Object.assign({}, state, {
                consultantList: consultantList,
            });

        case "PRACTICE_CONSULTANT_FILL":
            if (!action.consultant){
                return state;
            }
            return Object.assign({}, state, {
                consultant: action.consultant,
            });
        case "PRACTICE_CONSULTANT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
