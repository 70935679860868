import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/quickstart_available_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../redux/client/actions/quickstart_actions';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";


class QuickStartAvailableList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.gotoQuickStartView = this.gotoQuickStartView.bind(this);
    }

    gotoQuickStartView(id) {
        history.push('/client/quickstart/available/' + id);
    }

    render() {
        return Template.apply(this);
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        availableQuickStarts: state.client.quickStartReducer.availableQuickStarts,
        ...ownProps,
    };
}

const QuickStartAvailableListConnected = connect(mapStateToProps, mapDispatchToProps)(QuickStartAvailableList);
export default QuickStartAvailableListConnected;
