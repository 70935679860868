import React from "react";
import { bindActionCreators } from "redux";
import BaseComponent from "../../utils/common_components/basecomponent";
import HomeTemplate from "./templates/practitioner_home";
import { connect } from "react-redux";
import Utils from "../../utils/utils";
import Swal from "sweetalert2";
import ProgressBar from "react-toolbox/lib/progress_bar";
import { history } from "../../redux/store";
import PractitionerAction from "../../redux/practitioner/actions/practitioner_actions";
import MenuAction from "../../redux/core/actions/menu_state_creator";
import { getPractitionerHomeAlerts } from "../../redux/practitioner/network/practitioner_network";
import { messagePracticeClients } from "../../redux/message/network/message_network";
import authActionCreator from "../../redux/auth/actions/auth_creator";
import HelpVideoCreator from "../../redux/core/actions/help_video_creator";
import { PRACTITIONER_HOME_VIDEO } from "../../utils/help_video";
import { PARTNER } from "../../core/constants/constants";
import { notifyUsers } from "../../redux/dhf/workfit/network/workfit_network";

class Home extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.doLogout = this.doLogout.bind(this);
    this.showConnect = this.showConnect.bind(this);
    this.showGolfProgram = this.showGolfProgram.bind(this);
    this.closeConnect = this.closeConnect.bind(this);
    this.gotoClientList = this.gotoClientList.bind(this);
    this.gotoGroupList = this.gotoGroupList.bind(this);
    this.showMailComposer = this.showMailComposer.bind(this);
    this.hideMailComposer = this.hideMailComposer.bind(this);
    this.onMailComposerSubmit = this.onMailComposerSubmit.bind(this);
    this.handleMultipleAssessments = this.handleMultipleAssessments.bind(this);
    this.show3dmapsSelection = this.show3dmapsSelection.bind(this);
    this.hide3dmapsSelection = this.hide3dmapsSelection.bind(this);
    this.goto3dmapsExercises = this.goto3dmapsExercises.bind(this);
    this.inviteClient = this.inviteClient.bind(this);
    this.showGIGolfSelection = this.showGIGolfSelection.bind(this);
    this.hideGIGolfSelection = this.hideGIGolfSelection.bind(this);
    this.showGIExerciseSelection = this.showGIExerciseSelection.bind(this);
    this.hideGIExerciseSelection = this.hideGIExerciseSelection.bind(this);
    this.showEducation = this.showEducation.bind(this);
    this.showNotifyComposer = this.showNotifyComposer.bind(this);
    this.showMovementMeterSelection = this.showMovementMeterSelection.bind(
      this
    );
    this.hideMovementMeterSelection = this.hideMovementMeterSelection.bind(
      this
    );
    this.state = {
      showConnect: false,
      mailComposerVisible: false,
      hasMultipleAssessments: false,
      show3dmapsSelectionDialog: false,
      showGIGolfSelectionDialog: false,
      showGIExerciseSelectionDialog: false,
      showMovementMeterSelectionDialog: false,
      selectedMovementMeter: null,
    };
  }
  doLogout() {
    this.props.doLogout();
    Utils.removeLocalStorageValue("token");
    localStorage.removeItem("selectedRole");
    history.push("/auth/login");
  }

  componentWillMount() {
    // this.props.updateMenuPinned(false);
    this.props.updateMenuPinned(true);
    // this.props.updateMenuOpen(false);
    this.props.updateMenuOpen(true);
    this.props.cleanUp();
    if (!_.includes(this.props.location.pathname, "/home")) {
      history.push(
        "/practice/" + this.props.selectedRole.practice.id + "/home"
      );
    }
    this.fetchData();
  }

  componentDidMount() {
    this.props.updateHelpVideo(PRACTITIONER_HOME_VIDEO);
    let assessmentCount = 0;
    if (this.props.selectedRole.is_3dmaps_enabled) {
      assessmentCount = assessmentCount + 1;
    }
    if (this.props.selectedRole.is_mat_assessment_enabled) {
      assessmentCount = assessmentCount + 1;
    }
    if (this.props.selectedRole.is_dhf_assessment_enabled) {
      assessmentCount = assessmentCount + 1;
    }
    if (assessmentCount > 1) {
      this.setState({ hasMultipleAssessments: true });
    }
  }

  componentWillUnmount() {
    this.props.clearHelpVideo();
  }

  showMailComposer(group) {
    this.setState({
      mailComposerVisible: true,
    });
  }

  hideMailComposer() {
    this.setState({
      mailComposerVisible: false,
      showConnect: false,
    });
  }

  showNotifyComposer() {
    let that = this;
    Swal.fire({
      title: "Enter Push Notification Text",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Send",
      showLoaderOnConfirm: true,
      preConfirm: (notification_text) => {
        let params = {};
        params["client_id"] = "-1";
        params["notification_text"] = notification_text;
        that.props.notifyUsers(params);
        Swal.close();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      Swal.close();
    });
  }

  onMailComposerSubmit(data) {
    this.props
      .messagePracticeClients({
        ...data,
      })
      .then(() => {
        this.hideMailComposer();
      })
      .catch(() => {});
  }

  fetchData() {
    this.props.getPractitionerHomeAlerts({'practice_id':this.props.selectedRole.practice.id});
  }

  showConnect() {
    this.setState({ showConnect: true });
  }
  showGolfProgram() {
    history.push(
      "/practice/" +
        this.props.selectedRole.practice.id +
        "/engagement/exercise/gi_golf"
    );
  }

  //   if (this.props.selectedRole.is_gi_practice) {
  //     if (this.props.selectedRole.is_gi_golf_enabled) {
  //   history.push(
  //     "/practice/" +
  //       this.props.selectedRole.practice.id +
  //       "/engagement/exercise/gi_golf"
  //   );
  //     } else {
  //       Utils.showErrorAlert(
  //         "If you have purchased course already you must complete and pass the online test to gain access into the library. If you have not purchased the course, please contact GrayInstitute at info@grayinstitute.com"
  //       );
  //     }
  //   }
  closeConnect() {
    this.setState({ showConnect: false });
  }

  show3dmapsSelection() {
    this.setState({ show3dmapsSelectionDialog: true });
  }

  //   if(this.props.selectedRole.is_3dmaps_enabled) {
  //       this.setState({show3dmapsSelectionDialog:true});
  //   } else {
  //       Utils.showErrorAlert("If you have purchased course already you must complete and pass the online test to gain access into the library. If you have not purchased the course, please contact GrayInstitute at info@grayinstitute.com");
  //   }

  hide3dmapsSelection() {
    this.setState({ show3dmapsSelectionDialog: false });
  }

  goto3dmapsExercises() {
    history.push(
      "/practice/" +
        this.props.selectedRole.practice.id +
        "/engagement/exercise/3dmaps"
    );
  }

  gotoClientList(selectiontype = "") {
    if (selectiontype === "maps3d") {
      if (this.props.selectedRole.is_3dmaps_enabled) {
        history.push(
          "/practice/" +
            this.props.selectedRole.practice.id +
            "/client/select/maps3d"
        );
      } else {
        Utils.showErrorAlert(
          "If you have purchased course already you must complete and pass the online test to gain access into the library. If you have not purchased the course, please contact GrayInstitute at info@grayinstitute.com"
        );
      }
    } else if (selectiontype === "mat") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/client/select/mat"
      );
    } else if (selectiontype === "move_assess") {
      if (this.props.selectedRole["is_dhf_assessment_enabled"] === true) {
        history.push(
          "/practice/" +
            this.props.selectedRole.practice.id +
            "/client/select/" +
            this.state.selectedMovementMeter
        );
      } else {
        Utils.showErrorAlert(
          "You have not subscribed for Move Meter program. Kindly contact DHF team by sending an email to info@dynamichealthfitness.com."
        );
      }
    } else if (selectiontype === "maps3d_exercises") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/client/select/maps3d_exercises"
      );
    } else if (selectiontype === "workout_templates") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/client/select/workout_templates"
      );
    } else if (selectiontype === "golf_exercises") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/client/select/golf_exercises"
      );
    } else if (selectiontype === "gi_exercises") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/client/select/gi_exercises"
      );
    } else if (selectiontype === "move_exercises_assign") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/client/select/" +
          this.state.selectedMovementMeter +
          "_exercises"
      );
    } else if (selectiontype === "move_exercises_browse") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/engagement/exercise/" +
          this.state.selectedMovementMeter +
          "_exercises"
      );
    } else if (selectiontype === "move_workout_templates_assign") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/client/select/" +
          this.state.selectedMovementMeter +
          "_workout_templates"
      );
    } else if (selectiontype === "move_workout_templates_browse") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/engagement/p/template/list"
      );
    } else if (selectiontype === "move_gameplan_templates_browse") {
      history.push(
        "/practice/" +
          this.props.selectedRole.practice.id +
          "/engagement/treatment_templates/gameplan"
      );
    } else {
      history.push(
        "/practice/" + this.props.selectedRole.practice.id + "/client"
      );
    }
  }

  handleMultipleAssessments() {
    let that = this;
    let buttonText = '<div class="d-flex flex-column">';
    buttonText =
      buttonText +
      '<button class="3dmaps swal2-styled  bg-primary">3DMAPS</button>';
    if (this.props.selectedRole.is_mat_assessment_enabled) {
      buttonText =
        buttonText +
        '<button class="mat swal2-styled  bg-primary">MAT Assessment</button>';
    }
    if (this.props.selectedRole.is_dhf_assessment_enabled) {
      buttonText =
        buttonText +
        '<button class="dhf swal2-styled  bg-primary">D.M.S</button>';
    }
    buttonText =
      buttonText +
      '<button class="general swal2-styled  bg-primary">General Assessment</button></div>';

    Swal.fire({
      type: "info",
      title: "Choose Assessment",
      showConfirmButton: false,
      showCloseButton: true,
      text: "Select the assessment you want to start",
      footer: buttonText,
      useRejections: true,
    });
    $(".swal2-container").on("click", ".3dmaps", () => {
      Swal.close();
      that.gotoClientList("maps3d");
    });
    $(".swal2-container").on("click", ".mat", () => {
      Swal.close();
      that.gotoClientList("mat");
    });
    $(".swal2-container").on("click", ".dhf", () => {
      Swal.close();
      that.gotoClientList("dhf");
    });
    $(".swal2-container").on("click", ".general", () => {
      Swal.close();
      that.gotoClientList("");
    });
  }

  gotoGroupList() {
    history.push("/practice/" + this.props.selectedRole.practice.id + "/group");
  }

  inviteClient() {
    history.push(
      "/practice/" + this.props.selectedRole.practice.id + "/client/invite/0"
    );
  }

  showGIGolfSelection() {
    this.setState({ showGIGolfSelectionDialog: true });
  }

  //   if (this.props.selectedRole.is_gi_golf_enabled) {
  //     this.setState({ showGIGolfSelectionDialog: true });
  //   } else {
  //     Utils.showErrorAlert(
  //       "If you have purchased course already you must complete and pass the online test to gain access into the library. If you have not purchased the course, please contact GrayInstitute at info@grayinstitute.com"
  //     );
  //   }

  hideGIGolfSelection() {
    this.setState({ showGIGolfSelectionDialog: false });
  }

  showGIExerciseSelection() {
    this.setState({ showGIExerciseSelectionDialog: true });
  }

  hideGIExerciseSelection() {
    this.setState({ showGIExerciseSelectionDialog: false });
  }

  showEducation() {
    history.push(
      "/practice/" + this.props.selectedRole.practice.id + "/education"
    );
  }

  showMovementMeterSelection(movementMeter) {
    this.setState({
      showMovementMeterSelectionDialog: true,
      selectedMovementMeter: movementMeter,
    });
  }

  hideMovementMeterSelection() {
    this.setState({ showMovementMeterSelectionDialog: false });
  }

  render() {
    if (_.isEmpty(this.props.user)) {
      return (
        <div className="row center-xs m-t-25">
          <ProgressBar type="circular" mode="indeterminate" multicolor />
        </div>
      );
    }
    return HomeTemplate.apply(this);
  }
}
function mapStateToProps(state) {
  return {
    user: state.auth.authReducer.user,
    selectedRole: state.auth.roleReducer.selectedRole,
    menuopen: state.core.appMenu.menu_open,
    unread_chat_list: state.practitioner.practitionerReducer.unread_chat_list,
    unread_message_count:
      state.practitioner.practitionerReducer.unread_message_count,
    business_partner: state.practitioner.practitionerReducer.business_partner,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPractitionerHomeAlerts,
      updateMenuOpen: MenuAction.updateMenuOpen,
      updateMenuPinned: MenuAction.updateMenuPinned,
      cleanUp: PractitionerAction.cleanModule,
      messagePracticeClients,
      notifyUsers,
      doLogout: authActionCreator.doLogout,
      updateHelpVideo: HelpVideoCreator.updateHelpVideo,
      clearHelpVideo: HelpVideoCreator.clearHelpVideo,
    },
    dispatch
  );
}

const HomeConnected = connect(mapStateToProps, mapDispatchToProps)(Home);
export default HomeConnected;
