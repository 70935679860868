import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import Button from '../../../../utils/common_components/tooltipButton';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
import { Table } from '../../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatCoach1(coach, coachIndex) {
        return React.createElement(Tr, { 'key': 'coach' + coachIndex }, React.createElement(Td, {
            'column': 'name',
            'value': coach.name
        }, React.createElement('div', {}, React.createElement('div', {}, coach.name))), React.createElement(Td, {
            'column': 'contact',
            'value': coach.contact,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, '\n                                ', coach.contact, '\n                            ')), React.createElement(Td, {
            'column': 'website',
            'value': coach.website,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, '\n                                ', coach.website, '\n                            ')), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'tooltip': 'Edit',
            'icon': 'create',
            'onClick': () => {
                this.editCoach(coach.id);
            },
            'className': 'micro',
            'floating': true,
            'primary': true,
            'mini': true
        }))));
    }
    return React.createElement('div', { 'className': 'row w-100 m-20' }, React.createElement('div', { 'className': 'col-xs-12 m-t-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'No coaches have been added yet.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, { 'column': 'contact' }, React.createElement('strong', {}, 'Contact')), React.createElement(Th, { 'column': 'website' }, React.createElement('strong', {}, 'Website')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.coach_list, repeatCoach1.bind(this))
    ])), React.createElement(Button, {
        'tooltip': 'Create new coach',
        'className': 'floating-add-button m-r-8',
        'style': { top: '90px' },
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true,
        'onClick': this.addCoach
    }));
}