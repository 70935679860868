let initialState = {
    workouts: [],
    habits: [],
    nutritionDocuments: [],
    guidanceDocuments: [],
    habit: {},
    supportingData: {},
};


export default function treatment_template_program(state = initialState, action) {
    switch (action.type) {
        case "TREATMENT_TEMPLATE_WORKOUT_LIST_FILL":
            return Object.assign({}, state, {
                workouts: action.workouts
            });
        case "TREATMENT_TEMPLATE_WORKOUT_DELETE":
            if (!action.workout_id){
                return state;
            }
            let workouts = [...state.workouts];
            workouts = _.filter(workouts,(item)=>{
                if(item.id === action.workout_id){
                    return false;
                }else{
                    return true;
                }
            });
            return Object.assign({}, state, {
                workouts: workouts
            });
        case "TREATMENT_TEMPLATE_HABIT_LIST_FILL":
            return Object.assign({}, state, {
                habits: action.habits
            });
        case "TREATMENT_TEMPLATE_DOCUMENTS_NUTRITION_FILL":
            return Object.assign({}, state, {
                nutritionDocuments: action.documents,
            });
        case "TREATMENT_TEMPLATE_DOCUMENTS_GUIDANCE_FILL":
            return Object.assign({}, state, {
                guidanceDocuments: action.documents,
            });           
        case "TREATMENT_TEMPLATE_HABIT_FILL":
            return Object.assign({}, state, {
                habit: action.habit,
                supportingData: action.supportingData,
            });
        case "TREATMENT_TEMPLATE_PROGAM_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};