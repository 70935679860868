export const API_VERSION = 6;
export const PACKAGE = 'message';

export const INVITE_PRACTITIONER = 1;
export const INVITE_CLIENT_NEW_ENGAGEMENT = 2;
export const INVITE_CLIENT_GROUP = 3;
export const EXISTING_CLIENT_NEW_ENGAGEMENT = 4;
export const PARTNER_NETWORK_INVITATION_EMAIL = 5;

export const ENGAGEMENT_CLIENT_GOAL_SELECT = 6;
export const ENGAGEMENT_PRACTITIONER_INTAKE_ASSIGN = 7;
export const ENGAGEMENT_CLIENT_INTAKE_UPDATE = 8;
export const ENGAGEMENT_CLIENT_GOAL_TRACKING = 9;
export const ENGAGEMENT_PRACTITIONER_WORKOUT_ASSIGN_NEW = 10;
export const ENGAGEMENT_PRACTITIONER_WORKOUT_UPDATE_EXISTING = 11;
export const ENGAGEMENT_PRACTITIONER_HABIT_ASSIGN_NEW = 12;
export const ENGAGEMENT_PRACTITIONER_HABIT_UPDATE_EXISTING = 13;

export const INVITE_CLIENT_WORKFIT_PRACTICE = 200;

export const ENGAGEMENT_APPOINTMENT_BOOKING = 50;
export const ENGAGEMENT_APPOINTMENT_CANCEL = 51;
export const ENGAGEMENT_APPOINTMENT_UPDATE = 52;
export const GENERIC_MESSAGE_PRACTICE_ALL_CLIENTS = 100;
export const GENERIC_MESSAGE_GROUP_CLIENTS = 101;
export const GENERIC_MESSAGE_USER = 102;

