import * as React from 'react';
import * as _ from 'lodash';
import { Field } from 'redux-form';
import RichTextEditor from '../rich_text_editor_form';
import { editorConfig } from '../rich_text_editor_form';
import TextInput from '../textInput_form';
import Button from 'react-toolbox/lib/button';
export default function () {
    return React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, !this.props.hideEmail ? React.createElement('div', {
        'className': 'row',
        'key': '495'
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'email',
        'type': 'text',
        'component': TextInput,
        'label': 'Email'
    })) : null, React.createElement(Field, {
        'className': 'w-100',
        'label': 'Subject',
        'name': 'subject',
        'type': 'text',
        'component': TextInput
    }), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Field, {
        'id': 'mail_composer_body',
        'className': 'w-100',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Body'
    }))), React.createElement('div', { 'className': 'row end-xs m-t-10' }, React.createElement(Button, {
        'label': this.props.submitLabel,
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'bg-success',
        'raised': true
    })));
}