import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_create";
import { reduxForm } from 'redux-form';
import store, { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {practicePost} from '../../redux/practice/network/practice_create';
import {verifyToken} from '../../redux/auth/network/auth_network';
import actionCreator from '../../redux/practice/actions/practice_create';

class PracticeCreate extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
    }

    componentWillMount() {

    }

    doSave(data) {
        let params = { ...data };
        this.props.practicePost({ ...params }).then(() => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/home');
            this.props.verifyToken({
                package_version: 2
            });
        });
    }

    doCancel() {
        history.goBack();
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        practicePost,
        verifyToken,
        cleanModule: actionCreator.cleanModule
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        user: state.auth.authReducer.user,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'city',
        'zipcode',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const PracticeCreateForm = reduxForm(
    {
        form: 'PracticeCreateForm',
        enableReinitialize: true,
        validate
    }
)(PracticeCreate);

const PracticeCreateConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PracticeCreateForm);

export default PracticeCreateConnected;
