import  React  from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/workfit_videos.rt";
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { bindActionCreators } from 'redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import swal from 'sweetalert2';
import { getWorkfitVideoList } from '../../../redux/dhf/workfit/network/workfit_network';
import WorkfitAction from '../../../redux/dhf/workfit/actions/workfit_actions';

class WorkfitVideos extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSearch = this.onSearch.bind(this);
        this.viewWorkfitVideo = this.viewWorkfitVideo.bind(this);
        this.closeWorkfitVideo = this.closeWorkfitVideo.bind(this);
        this.editWorkfitVideo = this.editWorkfitVideo.bind(this);
        this.addWorkfitVideo = this.addWorkfitVideo.bind(this);
        this.state = {
            search: null,
            show_workfit_video: false,
            workfit_video: {}
        };
    }

    componentWillMount() {
        this.props.cleanUp();
        this.loadData(null);
    }

    loadData(search_text) {
        this.props.getWorkfitVideoList({
            'search_text': search_text,
        });
    }

    onSearch(text){
        this.setState({search:text},()=>{
            this.loadData(text);
        });
    }

    viewWorkfitVideo(video) {
        let workfit_video = {};
        workfit_video['title'] = video.name;
        workfit_video['welcome_text'] = "";
        workfit_video["video_guid"] = video.video_guid;
        workfit_video['thumbnail'] = video.thumbnail;
        this.setState({
            show_workfit_video: true,
            workfit_video: workfit_video,
        });
    }

    closeWorkfitVideo() {
        this.setState({
            show_workfit_video: false,
            workfit_video: {}
        });
    }

    editWorkfitVideo(video) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/workfit/videos/edit/'+video.id);
    }

    addWorkfitVideo() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/workfit/videos/add');
    }


    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}


function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        workfit_videos: state.workfit.workfitReducer.workfit_videos,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getWorkfitVideoList,
        cleanUp: WorkfitAction.cleanModule,
    }, dispatch),
    dispatch
});

const WorkfitVideosConnected = connect(mapStateToProps,mapDispatchToProps)(WorkfitVideos);
export default WorkfitVideosConnected;
