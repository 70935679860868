let initialState = {
    exercise: {
        exercise: {}
    },
    supportingData: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "EXERCISE_FILL":
            return Object.assign({}, state, {
                exercise: {...action.exercise, shopify_equipment: action.shopify_equipment},
                supportingData: action.supportingData,
            });
        case "EXERCISE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};