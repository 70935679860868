import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    return React.createElement('div', {}, React.createElement('div', { 'className': 'row start-xs m-t-20 text-center' }, React.createElement('div', { 'className': 'col-md-12 f-20' }, '\n            GI SPECIALITIES\n        ')), this.state.access_specialities ? React.createElement('div', {
        'className': 'row start-xs m-t-20',
        'key': '151'
    }, React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.showGISelection();
        }
    }, React.createElement('div', {
        'className': 'box-shadow m-10 bg-accent',
        'style': {
            minHeight: '300px',
            borderRadius: '50%'
        }
    }, React.createElement('div', {
        'className': 'text-center',
        'style': { paddingTop: '140px' }
    }, React.createElement('div', {}, 'Gray Institute Exercises')))), React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.show3dmapsSelection();
        }
    }, React.createElement('img', {
        'src': '/site_media/static/images/gi_3dmaps.jpg',
        'className': 'img-responsive'
    })), React.createElement('div', {
        'className': 'col-md-3 col-sm-3 col-xs-3 pointer',
        'onClick': () => {
            this.showGIGolfSelection();
        }
    }, React.createElement('img', {
        'src': '/site_media/static/images/gi_golf.jpg',
        'className': 'img-responsive'
    }))) : null, !this.state.access_specialities ? React.createElement('div', {
        'className': 'col-xs-12 m-t-30 text-center',
        'key': '1036'
    }, '\n        You do not have permission to access this page.\n    ') : null);
}