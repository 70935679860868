import React from 'react';
import  ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_engagement_goal_edit.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field , FieldArray} from 'redux-form';
import { history } from '../../redux/store';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Button from '../../utils/common_components/tooltipButton';
import PropTypes from 'prop-types';
import { getGoalTrackingUnitList } from '../../redux/goal/network/goal_network';
import { assignGoalsToEngagement,getClientEngagementInstance} from '../../redux/engagement/network/client_engagement_network';
import { getGoalList } from '../../redux/goal/network/goal_network';
import goalReducer from '../../redux/goal/reducers/goal_reducer';
import roleReducer from '../../redux/auth/reducers/role_reducer';

class ClientEngagementGoalEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.hideGoalTemplateModal = this.hideGoalTemplateModal.bind(this);
        this.showGoalTemplateModal = this.showGoalTemplateModal.bind(this);        
        this.addGoal = this.addGoal.bind(this);
        this.addEmptyGoal = this.addEmptyGoal.bind(this);
        this.saveGoal = this.saveGoal.bind(this);
        this.renderGoalTrackingQuestions = this.renderGoalTrackingQuestions.bind(this);
        this.renderGoals = this.renderGoals.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.goToEngagement = this.goToEngagement.bind(this);
        this.goalsFieldsArray = null;
        this.state = {
            openGoalTemplateModal: false,
            displayGoalsOnLoading: false,
        };        
    };

    componentWillMount() {
        if(!this.props.inlineDisplay){
            if (!this.props.match.params.engagement_id){
                history.goBack();
            }
            this.props.getClientEngagementInstance({ 'id': this.props.match.params.engagement_id }).then((response)=>{
                this.props.getGoalList({
                    fetch_type:"client_selection",
                    practice_id:response.data.practice.id
                });        
            });
         }    
        this.props.getGoalTrackingUnitList({});
        
    }    
    
    componentWillReceiveProps(nextProps) {        
        if (!this.state.displayGoalsOnLoading && nextProps.goals.length === 0) {
            this.setState({ displayGoalsOnLoading: true });
            this.showGoalTemplateModal();
        }
    }

    componentWillUnmount() {
        if(!this.props.inlineDisplay){
             this.props.cleanUp();
        }
    }

    addGoal(data) {
        let goal = _.pick(data, ['text']);
        goal.goal = data.id;
        goal.goal_tracking = [];
        // goal.goal_tracking = _.map(data.goal_tracking, (item) => {
        //     let trackingItem = _.pick(item, ['track_question', 'track_unit']);
        //     trackingItem.track_unit = trackingItem.track_unit.id;
        //     trackingItem.initial_value = 0;
        //     trackingItem.target_value = 0;
        //     return trackingItem;
        // });
        this.goalsFieldsArray.push(goal);
        this.scrollToBottom();
        this.hideGoalTemplateModal();
    }

    addEmptyGoal() {
        let goal = {text:"",goal_tracking:[]};
        this.goalsFieldsArray.push(goal);
        this.hideGoalTemplateModal();
        this.scrollToBottom();
    }


    doCancel() {
        history.goBack();
    }

    scrollToBottom() {
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    }

    goToEngagement() {
        if (this.props.selectedRole.role.name === "client"){
            if(!this.props.inlineDisplay){
                history.push("/client/engagement/"+this.props.engagement.id);
            }else{
                this.props.refreshData();
            }
        } else {
            history.goBack();
        }
    }

    hideGoalTemplateModal() {
         this.setState({ openGoalTemplateModal: false });
    }

    showGoalTemplateModal() {
         this.setState({ openGoalTemplateModal: true });
    }
 
    saveGoal(data) {     
        let client_goals = _.cloneDeep(data.goals);
        client_goals = _.map(client_goals,(item)=>{
            item.goal_tracking = _.map(item.goal_tracking,(tracking_item)=>{
                tracking_item.track_unit =  (tracking_item.track_unit && tracking_item.track_unit.id) ? tracking_item.track_unit.id : tracking_item.track_unit;
                return tracking_item;
            });
            return item;
        });
        this.props.assignGoalsToEngagement({ 
            engagement: this.props.engagement.id, 
            client_goals: client_goals,
            practice_id:this.props.engagement.practice.id ,
            package_version: 4
        }).then((response) => {
            this.goToEngagement();
        }).catch((error) => {
            //do nothing;
        });
    }

    renderGoals({ fields, meta }) {
        this.goalsFieldsArray = fields;
        let goals = fields.getAll();
        let noGoalsText = null;
        let addGoalButton = null;
        if (goals.length === 0){
            noGoalsText =  (
                    <div className="col-xs-12 start-xs m-t-25 m-b-25">        
                        <div className="alert-warning">You have not yet configured any goals for this game plan. Click on ADD GOAL button above to add</div>                 
                    </div>  
            );       
            addGoalButton = (
                    <Button    
                        tooltip="Choose from existing goal templates"    
                        icon="add"
                        label="Add Goal"
                        className="bg-primary m-r-10"
                         raised
                         onClick={this.showGoalTemplateModal} />  
            );    
        }

        return (
            <div className="row">
                <div className="col-xs-12 start-xs">        
                    {addGoalButton}
                </div>    
                {noGoalsText}
                <div className="col-xs-12 m-t-15">                
                {
                    fields.map((item, index) => {
                            return (
                                <div key={index} className="panel m-b-10" >
                                    <div className={ `row panel-heading  panel-bottom-border p-l-10 p-r-10 p-t-15 p-b-0 m-0 ${ goals[index].goal_tracking.length > 0 ? 'bg-beige': ''  }   `}>    
                                        <div className="col-xs-11">
                                            <Field
                                                label="Goal text"    
                                                name={`${item}.text`}
                                                type="text"
                                                component={TextInput}
                                                disabled={this.props.inlineDisplay}
                                                multiline />
                                        </div>   
                                        <div className="col-xs-1">    
                                            {!this.props.inlineDisplay &&
                                                <IconButton    
                                                    tooltip="Delete Goal"    
                                                    icon="delete_forever"
                                                    className="c-danger"
                                                    ripple
                                                    onClick={() => fields.remove(index)} />
                                            
                                            } 
                                        </div>   
                                    </div>        
                                    <FieldArray name={`${item}.goal_tracking`} component={this.renderGoalTrackingQuestions}/>
                            </div>    
                        );
                    })
                }
                </div>
                <div className="col-xs-12 end-xs" >
                    {!this.props.inlineDisplay &&
                        <Button onClick={this.doCancel} label="Cancel" className="bg-accent m-r-5" 
                            type="button"  raised/>  
                    }                      
                    <Button label="Save" className="bg-success" disabled={this.props.pristine || this.props.submitting} 
                        type="submit"  raised/>                    
                </div>   
            </div>    
        );
    }
    renderGoalTrackingQuestions({ fields, meta }) {
        let goalsTracking = fields.getAll();
        let noGoalTrackingText = null;
        if (goalsTracking.length === 0){
            return null;
        }        
        return (
            <div className="panel-body m-t-0 ">
            <div className="row">
                <h6 >Goal Metrics </h6> 
                {noGoalTrackingText}
                <div className="col-xs-12">                
                {
                    fields.map((item, index) => {
                            return (
                                <div key={index} className="row m-t-10">
                                    <div  className="col-xs-1 center-xs">
                                        <div className="round-number di-block"> <span>{index+1}</span></div>        
                                    </div>    
                                    <div className="col-xs-6">
                                        <Field
                                            label="Quantifiable measure to reach this goal"    
                                            name={`${item}.track_question`}
                                            type="text"
                                            component={TextInput}
                                            disabled={true}
                                            multiline />
                                    </div>     
                                    <div className="col-xs-2">    
                                        <Field
                                            label="Metric Unit"    
                                            name={`${item}.track_unit`}
                                            component={tetheredSelectFunction}
                                            options={this.props.goal_tracking_unit}
                                            valueKey="id" 
                                            labelKey="unit_name" 
                                            clearable={false}    
                                            disabled={true}
                                            simpleValue={true} />
                                    </div>
                                <div className="col-xs-1">
                                        <Field
                                            label="Baseline Value"    
                                            name={`${item}.initial_value`}
                                            type="number"
                                            data-support-float={true}
                                            component={TextInput}
                                        />
                                    </div>                                     
                                <div className="col-xs-1">
                                        <Field
                                            label="Target Value"    
                                            name={`${item}.target_value`}
                                            type="number"
                                            data-support-float={true}
                                            component={TextInput}
                                        />
                                    </div>                                     
                                <div className="col-xs-1 m-t-20">    
                                    {!this.props.inlineDisplay &&
                                        <IconButton    
                                            tooltip="Delete tracking question"    
                                            icon="delete_forever"
                                            className="c-danger"
                                            ripple
                                            onClick={() => fields.remove(index)} />
                                    }        
                                </div>

                            </div>    
                        );
                    })
                }
                </div>               
            </div>    
            </div>    
        );
    }
    
    render() {
        if (this.props.goal_tracking_unit.length === 0 || _.isEmpty(this.props.engagement)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

ClientEngagementGoalEdit.propTypes = {
    engagement:PropTypes.object.isRequired,    
};

function mapStateToProps(state, ownprops) {
    let inlineDisplay = _.has(ownprops,"inlineDisplay") ? ownprops.inlineDisplay : false;
    let goals = [];
    let engagement = {};
    let refreshData = null;
    if (inlineDisplay) {
        engagement = ownprops.engagement;
        refreshData =  ownprops.refreshData;
    } else {
        engagement = state.engagement.clientEngagementReducer.engagement;
    }   
    if (engagement && !_.isEmpty(engagement)){
        goals = [...engagement.goals];
    }
    let initialValues = { goals: goals };
    return {
        engagement:engagement,
        goals:goals,
        initialValues: initialValues,
        goal_templates: state.goal.goalReducer.goal_list,
        goal_tracking_unit: state.goal.goalReducer.goal_tracking_unit,
        selectedRole: state.auth.roleReducer.selectedRole,
        inlineDisplay:inlineDisplay,
        refreshData:refreshData
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGoalTrackingUnitList,
        assignGoalsToEngagement,
        getClientEngagementInstance,
        getGoalList,
            cleanUp:GoalActionCreator.goalCleanup //We are cleaning up the tracking unit list
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    const goalArrayErrors = [];
    if(values.goals){
        values.goals.forEach((goal, index) => {
            const goalErrors = {};
            const goalTrackingArrayErrors = [];
            if(!goal.text || goal.text === ""){
                goalErrors.text = "Required";
                
            }
            goal.goal_tracking.forEach((goal_track, i) => {
                 const goalTrackErrors = {};
                if(!goal_track.track_question || goal_track.track_question === ""){
                    goalTrackErrors.track_question = "Required";
                }
                if(goal_track.initial_value === null ||       goal_track.initial_value === ""){
                    goalTrackErrors.initial_value = "Required";
                }
                if(!goal_track.target_value === null ||  goal_track.target_value === ""){
                    goalTrackErrors.target_value = "Required";
                }
                if(!goal_track.track_unit || goal_track.track_unit === "" || goal_track.track_unit === null ){
                    goalTrackErrors.track_unit = "Required";
                }
                 goalTrackingArrayErrors[i] = goalTrackErrors;
            }); 
            goalErrors.goal_tracking = goalTrackingArrayErrors;
            goalArrayErrors[index] = goalErrors;
        });  
    }
   
    errors.goals = goalArrayErrors;
    return errors;
};


const ClientEngagementGoalEditForm = reduxForm(
    {
        form: 'clientEngagementGoalEditForm',
        enableReinitialize: true,
        validate
    }
)(ClientEngagementGoalEdit);
const ClientEngagementGoalEditConnected = connect(mapStateToProps,mapDispatchToProps)(ClientEngagementGoalEditForm);
export default ClientEngagementGoalEditConnected;
