import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import CalendarHome from '../calendar_home';
import FacilityViewCalendar from '../facility/facility_view_calendar';
import FacilityBook from '../facility/facility_booking_list';
import FacilityEdit from '../facility/facility_edit';
import FacilityList from '../facility/facility_list';
import GroupClassEditContainer from '../group_class_edit_container';
import GroupClassList from '../group_class_list';
import GroupClassBookingList from '../group_class_booking';
import GroupClassViewCalendar from '../group_class_view_calendar';
import AllGroupClassCalendar from '../all_group_class_calendar';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler/mine`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'My Calendar')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler/group_class_calendar`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Group Class Calendar')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler/group_class`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Group Classes')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/scheduler/facility`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Studio Resources'))), React.createElement(Switch, {}, React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/mine`,
        'component': CalendarHome
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/facility/view_calendar/:facility_id`,
        'component': FacilityViewCalendar
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/facility/book/:facility_id`,
        'component': FacilityBook
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/facility/edit/:facility_id`,
        'component': FacilityEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/facility/add`,
        'component': FacilityEdit
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/facility`,
        'component': FacilityList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class/edit/:group_class_id`,
        'component': GroupClassEditContainer
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class/add`,
        'component': GroupClassEditContainer
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class/:group_class_id/booking`,
        'component': GroupClassBookingList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class/:group_class_id/view_calendar`,
        'component': GroupClassViewCalendar
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class`,
        'component': GroupClassList
    }), React.createElement(Route, {
        'exact': true,
        'path': `${ this.props.match.url }/group_class_calendar`,
        'component': AllGroupClassCalendar
    })));
}