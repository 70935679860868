
let initialState = {
    all_group_class_calendar: [],
    group_class_list: [],
    group_class: {},
    booked_client_list: [],
    booked_client: {},
    client_list: [],
    schedule_date_list: [],
    schedule_date: null,
    practitioners: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "GROUP_CLASS_SCHEDULER_LIST":
            if (!action.group_class_list){
                return state;
            }
            return Object.assign({}, state, {
                group_class_list: action.group_class_list,
            });
        case "GROUP_CLASS_SCHEDULER_OBJECT":
            if (!action.group_class || !action.practitioners){
                return state;
            }
            return Object.assign({}, state, {
                group_class: action.group_class,
                practitioners: action.practitioners
            });            
         case "GROUP_CLASS_SCHEDULER_BOOKING_LIST":
            if (!action.group_class || !action.booked_client_list){
                return state;
            }
            return Object.assign({}, state, {
                group_class: action.group_class,
                booked_client_list: action.booked_client_list
            });                                
         case "GROUP_CLASS_SCHEDULER_BOOKING_OBJECT":
            if (!action.group_class  || !action.schedule_date_list){
                return state;
            }
            return Object.assign({}, state, {
                group_class: action.group_class,
                booked_client: action.booked_client,
                client_list: action.client_list,
                schedule_date_list: action.schedule_date_list
            });                        
        case "GROUP_CLASS_SCHEDULER_BOOKING_USER_LIST_FOR_DATE":
            if (!action.booked_client_list){
                return state;
            }
            return Object.assign({}, state, {
                group_class: action.group_class,
                booked_client_list: action.booked_client_list,
                schedule_date: action.schedule_date,
            });            
        case "ALL_GROUP_CLASS_EVENT_LIST":
            if (!action.all_group_class_calendar){
                return state;
            }
            return Object.assign({}, state, {
                all_group_class_calendar: action.all_group_class_calendar,
            });            

        case "GROUP_CLASS_SCHEDULER_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
