// Action Creators
const fillProgressionDatesList = (all_dates, date_workouts, ongoing_workouts, date_habits) => ({
    type: 'CLIENT_ENGAGEMENT_PROGRESSION_DATES_FILL',
    all_dates,
    date_workouts,
    ongoing_workouts,
    date_habits,
});
const fillProgressionDateInstance= (progression_preview,habits,engagement) => ({
    type: 'CLIENT_ENGAGEMENT_PROGRESSION_PER_DATE_INSTANCE_FILL',
    progression_preview,
    habits,
    engagement
});

const cleanModule = () => ({
    type: 'CLIENT_ENGAGEMENT_PROGRESSION_DATES_CLEANUP',
});

export default {
    fillProgressionDatesList,
    fillProgressionDateInstance,
    cleanModule,
};
