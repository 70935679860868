import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppTemplate from "./templates/app";
import { Route, Routes } from 'react-router-dom';

import store, { history } from '../../redux/store';
import { verifyToken } from '../../redux/auth/network/auth_network';
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import Cookies from 'js-cookie';
import { PARTNER } from '../../core/constants/constants';
import Axios from '../../utils/network/axios';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import RoleAction from '../../redux/auth/actions/role_creator';
import authActionCreator from '../../redux/auth/actions/auth_creator';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import PractitionerContainer from '../../practitioner_app/components/practitioner_container';
import HomeFitContainer from '../../dhf/move/components/homefit_container';
import ClientContainer from '../../client_app/components/client_container';
import GuestContainer from '../../guest_app/components/guest_container';
import GIProContainer from '../../gipro/components/gi_pro_container';
import AuthContainer from '../../auth/components/auth_container';
import UserEmailUpdate from '../../user/components/user_email_update';
import Swal from 'sweetalert2';
import ViewProfile from '../../user/components/view_profile';
import ProfileEdit from '../../user/components/profile_edit';
import ProfileEditInfo from '../../user/components/profile_edit_info';

// import AuthRoutes from './auth_routes';

class App extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.selectRole = this.selectRole.bind(this);
        this.toggleDrawerActive = this.toggleDrawerActive.bind(this);
        this.route = this.route.bind(this);
        this.openProfileMenu = this.openProfileMenu.bind(this);
        this.doAppLogout = this.doAppLogout.bind(this);
        this.checkBackgroundImage = this.checkBackgroundImage.bind(this);
        this.needsLogoImage = this.needsLogoImage.bind(this);
        this.setHotRedirect = this.setHotRedirect.bind(this);
        this.openHelpVideo = this.openHelpVideo.bind(this);
        this.closeHelpVideo = this.closeHelpVideo.bind(this);
        this.returnPractionerApp = this.returnPractionerApp.bind(this);
        this.returnClientApp = this.returnClientApp.bind(this);
        this.returnHomeFitApp = this.returnHomeFitApp.bind(this);
        this.returnGuestApp = this.returnGuestApp.bind(this);
        this.returnGIProApp = this.returnGIProApp.bind(this);
        this.returnChangeEmailApp = this.returnChangeEmailApp.bind(this);
        this.returnViewProfileApp = this.returnViewProfileApp.bind(this);
        this.returnEditProfileApp = this.returnEditProfileApp.bind(this);
        this.returnEditProfileInfoApp = this.returnEditProfileInfoApp.bind(this);
        // this.authRoutes = this.authRoutes.bind(this);
        this.openProfileMenu = this.openProfileMenu.bind(this);
        this.closeProfileMenu = this.closeProfileMenu.bind(this);
        this.showMobileAppModal = this.showMobileAppModal.bind(this);
        this.pushRoute = this.pushRoute.bind(this);
        this.state = {
            openProfileMenu: false,
            giPro: false,
            isTokenAvailable: false,
            openHelpVideoDialog: false,
            currentLocation: null,
            background_img_url_paths: [
                '/auth/login',
                '/auth/signup',
                '/auth/onboard',
                '/auth/password_reset',
                '/auth/activate',
            ]
        };
    };

    openProfileMenu() {
        let newState = !this.state.openProfileMenu
        this.setState({
            openProfileMenu: newState
        })
    }

    closeProfileMenu() {
        this.setState({
            openProfileMenu: false
        })
    }

    // openProfileMenu() {
    //     $(".profile_icon_menu button").trigger("click");
    // }

    openHelpVideo() {
        if (this.props.help_video_guid !== "") {
            this.setState({
                openHelpVideoDialog: true,
            });
        } else {
            Utils.showInfoAlert("Help video - Coming Soon");
        }
    }

    closeHelpVideo() {
        this.setState({
            openHelpVideoDialog: false,
        });
    }

    checkBackgroundImage() {
        let backgroundClass = "";
        let needsBackgroundImage = false;
        _.forEach(this.state.background_img_url_paths, (item) => {
            if (_.includes(this.props.history.location.pathname, item)) {
                needsBackgroundImage = true;
                return false;
            }
        });
        if (needsBackgroundImage) {
            if (PARTNER.app_type == 'gi') {
                backgroundClass = "image-bg gi-bg";
            } else {
                backgroundClass = "image-bg";
            }
            if (this.props.history.location.pathname === "/client/workfit/home") {
                backgroundClass = "dhf-bg";
            }
        } else {
            backgroundClass = "";
        }
        return backgroundClass;
    }

    needsLogoImage() {
        let logoClass = "";
        let needsLogoImage = false;
        _.forEach(this.state.background_img_url_paths, (item) => {
            if (_.includes(this.props.history.location.pathname, item)) {
                needsLogoImage = true;
                return false;
            }
        });
        if (needsLogoImage) {
            logoClass = "image-giLogo";
            if (this.props.history.location.pathname === "/client/workfit/home") {
                logoClass = "dhf-bg";
            }
        } else {
            logoClass = "";
        }
        return logoClass;
    }

    doAppLogout() {
        this.props.doLogout();
        Utils.removeLocalStorageValue("token");
        this.setState({ isTokenAvailable: false });
        localStorage.removeItem("selectedRole");
        history.push("/auth/login");
        try {
            window.zE(function () {
                zE.hide();
            });
        } catch (err) {
        }
    }

    getTitle() {
        let role = this.props.selectedRole;
        if (!this.state.giPro) {
            if (_.isEmpty(role)) {
                return "GoMotive Guest";
            } else {
                if (role.role.name === "client") {
                    return this.props.selectedRole.client_title;
                } else {
                    if (_.isEmpty(role.practice)) {
                        return role.partner.name + " " + role.role.name;
                    } else {
                        return "";
                    }
                }
            }
        }
    }

    /**
     * This method decides the landing page for any requested URL
     */
    route(nextProps) {
        let path = nextProps.history.location.pathname;
        if (path === '/' || path === '/home' || path === '/home/' || path === '/auth/content') {
            if (nextProps.user.is_guest) {
                history.push('/guest');
            } else {
                if (!_.isEmpty(nextProps.selectedRole)) {
                    let role = nextProps.selectedRole.role.name;
                    // if(role === "practice_admin" || role === "practitioner"){
                    //     history.push('/practice/'+ nextProps.selectedRole.practice.id +'/engagement/gi_content');
                    // }
                    if (role === "client") {
                        if (PARTNER.app_type == 'tri') {
                            history.push('/client/home');
                        } else {
                            history.push('/auth/login');
                            this.showMobileAppModal()
                        }
                    } else if (role === "business_partner") {
                        history.push('/partner/home');
                    }
                }
                if (!_.isEmpty(nextProps.selectedRole)) {
                    let role = nextProps.user.gi_isadmin;
                    if (PARTNER.app_type == 'gi') {
                        this.props.updateMenuPinned(false);
                        this.props.updateMenuOpen(false);
                        if (role) {
                            // history.push("/onboard");
                            history.push('/practice/' + nextProps.selectedRole.practice.id + '/home');
                        } else if (!role) {
                            if (nextProps.user.is_onboarded) {
                                history.push('/practice/' + nextProps.selectedRole.practice.id + '/home');
                            } else {
                                if (nextProps.user.is_email_verified) {
                                    history.push('/practice/' + nextProps.selectedRole.practice.id + '/onboard');
                                }
                                else {
                                    Swal.fire({
                                        title: "Account created successfully!",
                                        html: 'Kindly verify your email account and <a style="color:blue; text-decoration: underline; cursor: pointer; display:inline-block; border: none; outline: none;" id="loginLink">click here to login</a>',
                                        type: "info",
                                        showConfirmButton: false,
                                        allowOutsideClick: false, // Prevents closing the modal when clicking outside,
                                        didRender: () => {
                                            // Attach the click event listener after the SweetAlert2 modal has been rendered
                                            const loginLink = document.getElementById('loginLink');
                                            if (loginLink) {
                                                loginLink.onclick = (event) => {
                                                    this.doAppLogout();
                                                    Swal.close(); // Close the SweetAlert2 modal after clicking the link
                                                };
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    } else if (PARTNER.app_type == 'tri' || PARTNER.app_type == 'dhf') {
                        if (role) {
                            history.push('/practice/' + nextProps.selectedRole.practice.id + '/engagement');
                        } else if (!role) {
                            history.push('/practice/' + nextProps.selectedRole.practice.id + '/client');
                        }
                    }
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        this.route(nextProps);
        let oldpath = this.props.history.location.pathname;
        let newpath = nextProps.history.location.pathname;
        if (oldpath == newpath) {
            if (_.includes(newpath, '/home')) {
                if (!this.props.user.gi_isadmin) {
                    this.props.updateMenuPinned(false);
                    this.props.updateMenuOpen(false);
                }
            } else {
                if (!this.props.user.gi_isadmin) {
                    this.props.updateMenuPinned(false);
                    this.props.updateMenuOpen(false);
                }
            }
        }
        let hot_redirect_array = this.props.history.location.pathname.split("/");
        if (hot_redirect_array[1] === "gipro") {
            this.setState({
                giPro: true
            });
        }
    }

    setHotRedirect() {
        let hot_redirect_array = this.props.history.location.pathname.split("/");
        if (hot_redirect_array.length > 1) {
            if (hot_redirect_array[1] === "client" || hot_redirect_array[1] === "practice" || hot_redirect_array[1] === "practitioner" || hot_redirect_array[1] === "hf" || hot_redirect_array[1] === "gipro") {
                sessionStorage.setItem("hot_redirect", this.props.history.location.pathname);
            }
        }
    }

    componentWillMount() {
        // this.authRoutes(this.props)
        let hot_redirect_array = this.props.history.location.pathname.split("/");
        const token = Cookies.get("token");
        if (typeof token === 'undefined' || token === "") {
            let allowedLocations = ["/content", "/auth/signup", "/auth/onboard", "/auth/activate", "/api-login", "/auth/password_reset"];
            let gotoLogin = true;
            _.forEach(allowedLocations, (location) => {
                if (_.includes(this.props.history.location.pathname, location)) {
                    gotoLogin = false;
                }
            });
            if (gotoLogin) {
                this.setHotRedirect();
                history.push('/auth/login');
                try {
                    window.zE(function () {
                        zE.hide();
                    });
                } catch (err) {
                }
            }
        } else {
            Axios.defaults.headers = {
                // 'USER_AGENT': navigator.userAgent,
                'Accept': "application/json",
                'Content-type': "application/json",
                'Authorization': token
            };
            this.setState({ isTokenAvailable: true });

            if (hot_redirect_array[1] === "gipro") {
                this.setState({
                    giPro: true
                });
            }
            if (!this.props.isAuthenticated) {
                this.setHotRedirect();
                this.props.verifyToken({
                    package_version: 2
                });
            }
        }
        this.route(this.props);
        let locArray = window.location.pathname.split('/')
        let location = locArray[locArray.length - 1]
        this.setState({
            currentLocation: location
        })
    }

    toggleDrawerActive() {
        let path = this.props.history.location.pathname;
        if (_.includes(path, '/home')) {
            this.props.updateMenuPinned(!this.props.pinned);
            this.props.updateMenuOpen(!this.props.menuopen);
        } else {
            this.props.updateMenuPinned(!this.props.pinned);
            this.props.updateMenuOpen(!this.props.menuopen);
        }
    }

    selectRole(user_role) {
        this.props.chooseRole(user_role);
        if (user_role.role.name === "client") {
            localStorage.setItem('selectedRole', "client");
            history.push("/client/home");
            // history.push("/");
        } else if (user_role.role.name === "practitioner" || user_role.role.name === "practice_admin") {
            localStorage.setItem('selectedRole', user_role.id);
            if (PARTNER.app_type == 'gi') {
                history.push("/practice/" + user_role.practice.id + "/engagement/gi_content");
            } else {
                history.push("/practice/" + user_role.practice.id + "/engagement");
            }
        } else {
            history.push("/home");
        }
    }

    showMobileAppModal() {
        let that = this;
        Swal.fire({
            customClass: {
                container: 'paywall-modal',
                title: 'paywall-title',
                html: 'paywall-text',
                confirmButton: 'paywall-confirm-button',
                cancelButton: 'paywall-cancel-button',
            },
            title: "Fitness on the go",
            html: '<p>To access your training with your coach, and additional health and fitness content, please use the HomeFit+ app</p>',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#fff",
            confirmButtonText: "Go to App Store",
            cancelButtonText: "Go to Play Store",
            useRejections: true
        }).then((result) => {
            if (result.value) {
                window.open('https://apps.apple.com/us/app/homefit/id1334676281', '_blank');
            } else if (result.dismiss == 'cancel') {
                window.open('https://play.google.com/store/apps/details?id=com.gomotive.dhf.move', '_blank');
            } else if (result.dismiss == 'close') {
                that.doAppLogout()
                history.push('/auth/login');
                window.location.reload()
            }
        }, function (dismiss) {
        });
    }
    // doLogout() {
    //     this.props.doLogout();
    //     try {
    //         window.zE(function () {
    //             zE.hide();
    //         });
    //         console.log('called app logout')
    //         Utils.removeLocalStorageValue('token');
    //         localStorage.removeItem('selectedRole');
    //         history.push('/auth/login');

    //     } catch (err) {            
    //     }  
    // }


    pushRoute(url) {
        if (url === "email") {
            this.closeProfileMenu();
            history.push("/change_email")
        }
        else if (url === "profile") {
            this.closeProfileMenu();
            history.push("/view_profile")
        }
    }

    returnPractionerApp(routeProps) {
        return (
            <PractitionerContainer doLogout={this.doAppLogout} {...routeProps} />
        );
    }
    returnHomeFitApp(routeProps) {
        return (
            <HomeFitContainer doLogout={this.doAppLogout} {...routeProps} />
        );
    }
    returnClientApp(routeProps) {
        return (
            <ClientContainer doLogout={this.doAppLogout} {...routeProps} />
        );
    }
    returnGuestApp(routeProps) {
        return (
            <GuestContainer doLogout={this.doAppLogout} {...routeProps} />
        );
    }

    returnGIProApp(routeProps) {
        return (
            <GIProContainer doLogout={this.doAppLogout} {...routeProps} />
        );
    }

    returnChangeEmailApp(routeProps) {
        return (
            <UserEmailUpdate doLogout={this.doAppLogout} {...routeProps} />
        );
    }

    returnViewProfileApp(routeProps) {
        return (
            <ViewProfile doLogout={this.doAppLogout} {...routeProps} />
        );
    }

    returnEditProfileApp() {
        return (
            <ProfileEdit user_id={this.props.match.params.practitioner_id} is_client={false} self_editing={false} />
        );
    }

    returnEditProfileInfoApp() {
        return (
            <ProfileEditInfo user_id={this.props.match.params.practitioner_id} is_client={false} self_editing={false} />
        );
    }


    profilePicRender = () => {
        return (
            <>
                {this.props.user.profile_pic ?
                    <img style={{ width: '40px', height: '40px', borderRadius: '50%', border: '2px solid white' }} src={this.props.user.profile_pic} />
                    :
                    <div class="profile-box">{this.props.user.first_name.charAt(0)} {this.props.user.last_name.charAt(0)}</div>
                }
            </>

        )
    }

    // authRoutes(props){
    //     console.log('auth function',props)
    //     return (
    // <AuthRoutes/>
    // <Routes>
    //         {/* <Route exact path={`/api-login/:token`} component={GIApiLogin} /> */}
    //         <Route path="/auth" element={<AuthContainer/>} />
    // </Routes>
    //     );
    // }

    render() {
        if (!this.props.isAuthenticated && this.state.isTokenAvailable) {
            return (<div className="shadow-layer">
                <div className="gm-loader">
                    <svg height="240" width="240" className="pointer-loader main-body-loader">
                        <line className="pointed" x1="120" y1="120" x2="120" y2="100" />
                        <circle className="center" cx="120" cy="120" r="5" />
                    </svg>
                    <svg height="240" width="240" className="timer-loader main-body-loader">
                        <circle className="circle" cx="120" cy="120" r="30" />
                        <line className="tic" x1="120" y1="78" x2="120" y2="90" />
                    </svg>
                </div>
            </div>);
        } else {
            return AppTemplate.apply(this);
        }

    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        verifyToken,
        chooseRole: RoleAction.chooseRole,
        doLogout: authActionCreator.doLogout,
        updateMenuOpen: MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        isUpdatingServer: state.core.progressReducer.isUpdatingServer,
        user: state.auth.authReducer.user,
        roles: state.auth.roleReducer.roles,
        selectedRole: state.auth.roleReducer.selectedRole,
        menuopen: state.core.appMenu.menu_open,
        pinned: state.core.appMenu.pinned,
        help_video_guid: state.core.helpVideoReducer.help_video_guid,
        client_home_page_title: state.client.clientReducer.v2_home_page_title,
        logo_url: "/site_media/static/images/logo_" + PARTNER.subdomain + ".png",
    };
}
const MainApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default MainApp;
