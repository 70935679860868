import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row' }, this.props.message ? React.createElement(Card, {
        'className': 'no-box-shadow',
        'key': '707'
    }, React.createElement(CardTitle, {}, 'Message from ', this.props.message.sender.name), React.createElement(CardText, {}, React.createElement('div', { 'className': 'm-t-5 m-b-5' }, React.createElement('span', { 'className': 'col-xs-4  text-right' }, '\n                    Subject:\n                '), React.createElement('span', { 'className': 'col-xs-8' }, '\n                    ', this.props.message.subject, '\n                ')), React.createElement('div', { 'className': 'm-t-5 m-b-5' }, React.createElement('span', { 'className': 'col-xs-4  text-right' }, '\n                    Date:   \n                '), React.createElement('span', { 'className': 'col-xs-8' }, '\n                    ', moment(this.props.message.sent_datetime).calendar(), '\n                ')), React.createElement('hr', { 'className': 'dashed' }), React.createElement('div', { 'className': 'm-t-5 m-b-5 col-xs-12' }, '\n                ', renderHTML(this.props.message.body), '\n            '))) : null);
}