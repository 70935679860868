import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import consultantActionCreator from '../../practice/actions/practice_consultant';


const consultantSubscribeToPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/consultant_subscribe";
        let config = {};
        config["headers"] = {
            'USER_AGENT': navigator.userAgent,
            'Accept': "application/json",
            'Content-type': "application/json",
        };
        if (_.has(params,"token")){
            config["headers"]["STRIPETOKEN"] = params.token;
        }
        let updated_params = Object.assign({},params);
        delete updated_params.token;
        return Axios.post(url, updated_params,config).then((response) => {
            dispatch(consultantActionCreator.updateConsultantList(response.consultant));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully subscribed!");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    consultantSubscribeToPlan
};