import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Switch, Route, useLocation, useParams,Redirect } from 'react-router-dom';
import LoginForm from './login';
import SignupForm from './signup';
import WelcomeOnboardForm from './welcome_aboard';
import Activate from './activate';
import PasswordReset from './password_reset';


const AuthContainer = (props) => {
    let params = useParams();
    let location = useLocation();
    return (
        <>
        <Switch>
            <Route exact path={`/auth/login`} component={LoginForm} />  
            <Route exact path={`${props.match.url}/onboard`} component={WelcomeOnboardForm} />             
            <Route exact path={`${props.match.url}/signup`} component={SignupForm} />
            <Route exact path={`${props.match.url}/signup/:practice_guid`} component={SignupForm} />
            <Route exact path={`${props.match.url}/signup/:practice_guid/:user_role_type`} component={SignupForm} />
            <Route exact path={`${props.match.url}/password_reset`} component={PasswordReset} />
            <Route exact path={`${props.match.url}/activate/:activation_user_type/:activation_guid`} component={Activate} />
            <Redirect to="/auth/login"/>
        </Switch>
        </>
    );
};    


export default AuthContainer;
