import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import Select from 'react-select';
export default function () {
    return React.createElement(Dialog, {
        'active': this.props.open,
        'title': this.props.title,
        'onEscKeyDown': this.props.handleClose,
        'onOverlayClick': this.props.handleClose
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Select.Async, {
        'name': 'choose-user',
        'className': 'col-xs-12',
        'labelKey': 'name',
        'valueKey': 'id',
        'value': this.state.selected_user,
        'loadOptions': this.getOptions,
        'onChange': this.onSelectUser,
        'placeholder': 'Type to search',
        'optionRenderer': this.optionRenderer,
        'filterOptions': this.filterOptions
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Close',
        'accent': true,
        'raised': true,
        'onClick': this.props.handleClose
    }), React.createElement(Button, {
        'label': this.props.button_name,
        'className': 'm-l-10 bg-success',
        'onClick': this.doSend,
        'raised': true
    }))));
}