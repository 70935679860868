// Action Creators
const practiceDescriptionFill = (practice) => ({
    type: 'PRACTICE_DESCRIPTION_FILL',
    practice,
});
const cleanModule = () => ({
    type: 'PRACTICE_DESCRIPTION_CLEANUP',
});
export default {
    practiceDescriptionFill,
    cleanModule,
};