import React, { Children, useState } from 'react';
import Select from "react-select";

function SelectComponent(props) {
  const [focus, setFocus] = useState(false)
  const { input, label, options, multi, valueKey, labelKey, returnValue, defaultValue } = props;
  const { onChange, name, value, onBlur, onFocus } = input
  let transformedValue, userOptions;
  function transformValue(value, options, multi) {
    if (multi && typeof value === 'string') return [];

    const filteredOptions = options.filter(option => {
      return multi
        ? value.indexOf(option.value) !== -1
        : typeof (value) === 'object'
          ? option.value === value[valueKey]
          : option.value === value;
    });
    return multi ? filteredOptions : filteredOptions[0];
  }
  // if(!returnValue){
  if (options) {
    userOptions = options.map((option) => {
      if (returnValue === "object") {
        return {
          value: option[valueKey ? valueKey : 'value'],
          label: option[labelKey ? labelKey : 'label'],
          children: option.children
        }
      } else {
        return {
          value: option[valueKey ? valueKey : 'value'],
          label: option[labelKey ? labelKey : 'label']
        }
      }
    })
    transformedValue = transformValue(value, userOptions, multi);
  }
  // }
  const singleChangeHandler = (val) => {
    console.log(val, 'val')
    if (returnValue === "object") {
      onChange(val !== "" ? val : null)
    } else {
      onChange(val !== "" ? val.value : null)
    }
  }
  const multiChangeHandler = (values) => {
    onChange(values.map((val) => val.value))
  }
  const handleBlur = () => {
    setTimeout(() => {
      const { input } = props;
      input.onBlur(input.value);
    }, 1);
  };
  return (
    <div style={{ textAlign: "start", position: "relative" }}>
      <label
        style={{ color: focus ? "#DB303C" : "#c0c0c0", fontSize: "14px", position: "absolute", left: "10px", top: "-6px", backgroundColor: "#FFFFFF", zIndex: 9, padding: "0 5px 0 5px" }}
      >{label}</label>
      <Select
       optionClassName="needsclick"
       name={name}
        value={transformedValue || multi ? transformedValue : defaultValue ? defaultValue : { value: "", label: "Select.." }}
        options={userOptions}
        isMulti={multi}
        onChange={multi ? multiChangeHandler : singleChangeHandler}
        // onBlur={(e) => {
        //   console.log('onBlur')
        //   e.preventDefault()
        //   setFocus(false)
        //   onBlur(value)
        // }}
        // onFocus={() => {
        //   console.log('onFocus')
        //   setFocus(true)
        //   onFocus()
        // }}
        onBlur={event => event.preventDefault()}
        // closeMenuOnScroll={(e) => {
        //   if (e.target === "document") {
        //     console.log(document,'scroll')
        //     return false;
        //   } else {
        //     return true;
        //   }
        // }}
        menuPortalTarget={document.body}
        styles={{
          option: (styles, state) => ({
            ...styles,
            fontSize: "16px",
            color: state.isSelected ? "#FFF" : styles.color,
            backgroundColor: state.isSelected ? "#F3969A" : styles.color,
            borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
            "&:hover": {
              color: "#FFF",
              backgroundColor: "#DB303C"
            },
          }),
          placeholder: () => ({
            fontSize: "16px",
          }),
          control: (styles, state) => ({
            ...styles,
            fontSize: "16px",
            borderColor: state.isFocused ? "#DB303C" : "#CED4DA",
            boxShadow: state.isFocused ? '0 0 0 0 #DB303C' : 0,
            "&:hover": {
              borderColor: state.isFocused ? "#DB303C" : "#DB303C"
            },
            marginBottom: "18px"
          }),
          menuPortal: base => ({ ...base, zIndex: 5000 }),
        }}
      />
    </div>
  )
}

export default SelectComponent;