import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Template from "./templates/workfit_notification_schedule.rt";
import store,{history} from '../../../redux/store';
import { verifyToken } from '../../../redux/auth/network/auth_network';
import Utils from '../../../utils/utils';
import BaseComponent from '../../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../../redux/core/actions/menu_state_creator';
import { getNotificationScheduleList, notificationScheduleDelete } from '../../../redux/dhf/workfit/network/workfit_network';

class WorkfitNotificationSchedule extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);    
        this.scheduleNotification = this.scheduleNotification.bind(this);
        this.deleteNotificationSchedule = this.deleteNotificationSchedule.bind(this);
    }
    
    componentWillMount() { 
        this.props.getNotificationScheduleList();     
    }

    scheduleNotification() {  
        history.push('/practice/'+this.props.selectedRole.practice.id+'/branding/notification_schedules/add');
    }

    deleteNotificationSchedule(notificationSchedule) {
        let params = {};
        params['notification_schedule_id'] = notificationSchedule.id;
        this.props.notificationScheduleDelete(params).then((response) => {
            Utils.showSuccessAlert("Scheduled notification has been deleted");
            this.props.getNotificationScheduleList(); 
        }).catch(() => { });
    }

    render() {
        if (!this.props.isAuthenticated || _.isEmpty(this.props.selectedRole)) {
            return (<div className="row center-xs m-t-25">
                <ProgressBar type='circular' mode='indeterminate' multicolor />
            </div>);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            verifyToken,
            updateMenuOpen: MenuAction.updateMenuOpen,
            getNotificationScheduleList,
            notificationScheduleDelete,
        },
        dispatch
    );
}
function mapStateToProps(state, ownProps) {    
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
        menuopen: state.core.appMenu.menu_open,
        pinned: state.core.appMenu.pinned,
        notificationSchedules: state.workfit.workfitReducer.notification_schedules,
    };
}


const WorkfitNotificationScheduleConnected = connect(mapStateToProps, mapDispatchToProps)(WorkfitNotificationSchedule);
export default WorkfitNotificationScheduleConnected;
