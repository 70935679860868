import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Tab } from 'react-toolbox';
import { Tabs } from 'react-toolbox';
import ProgramGeneralEdit from '../program_general_edit_new';
import ProgressionsBuild from '../progressions_build_new';
import ProgramPreview from '../../../program/components/program_preview';
import ProgressionEdit from '../progression_edit_new';
import ProgramPublishEdit from '../../../program/components/program_publish_edit';
import { IconButton } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
export default function () {
    return React.createElement('div', { 'className': 'row program-edit-form center-xs ' + (this.props.usedInside ? '' : 'm-r-20 m-b-20 m-t-20') }, React.createElement(Card, { 'className': 'w-100 template-form-container no-box-shadow p-relative' }, React.createElement('div', { 'className': this.props.from_gameplan || this.props.group_id ? 'w-100 text-left bg-white' : 'w-75 text-left bg-white' }, !this.props.from_gameplan || !this.props.group_id ? React.createElement(Button, {
        'className': 'm-t-8 d-flex back-btn c-grey',
        'onClick': this.finishEditingMoveBack,
        'key': '1724'
    }, '\n                Back\n            ') : null, !this.props.usedInside ? React.createElement(CardTitle, {
        'title': (this.props.match.params.program_id ? 'Edit' : 'Add') + ' Workout Template',
        'className': 'p-l-10 bg-white c-black',
        'key': '1976'
    }) : null), React.createElement(CardText, { 'className': this.props.from_gameplan || this.props.group_id ? 'p-0 w-100 box-shadow' : 'p-0 w-75 box-shadow' }, React.createElement(Tabs, {
        'className': 'bg-white ' + `custom-tab ${ this.state.tabIndex === 1 || this.state.tabIndex === 2 ? 'beige-section' : '' }  `,
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'className': 'w-50',
        'activeClassName': 'active-tab',
        'label': this.props.typeUCase + ' Setup'
    }, React.createElement(ProgramGeneralEdit, _.assign({}, {
        'isNonSyncGroup': this.props.isNonSyncGroup,
        'moveToBuild': this.moveToBuild,
        'gamePlanSelection': true,
        'showWorkoutEditForm': 'this.props.showWorkoutEditForm',
        'treatment_template_id': true,
        'program': this.props.program,
        'getProgram': this.getProgram,
        'urlProgramID': this.props.match ? this.props.match.params.program_id : this.props.group_id || this.props.program_id,
        'postProgram': this.postProgram,
        'editProgression': this.editProgression,
        'disableEditing': this.props.disableEditing,
        'disableProgression': this.disableProgression,
        'finishEditingGame': this.finishEditingMoveBack,
        'user_subscription_active': this.state.user_subscription_active,
        'isTemplatesPurchased': this.state.isTemplatesPurchased
    }, this.props))), React.createElement(Tab, {
        'className': 'w-50' + ' ' + 'tab-no-padding',
        'activeClassName': 'active-tab',
        'label': 'Exercises',
        'disabled': this.state.disableProgression
    }, React.createElement(ProgressionEdit, _.assign({}, {
        'program': this.props.program,
        'getProgression': this.getProgression,
        'moveToBuild': this.moveToBuild,
        'postProgression': this.postProgression,
        'getProgram': this.getProgram,
        'finishEditingMoveBack': this.finishEditingMoveBack,
        'program_id': this.props.program_id,
        'progression_id': this.state.editingProgressionId,
        'disableEditing': this.props.disableEditing,
        'user_subscription_active': this.state.user_subscription_active,
        'isTemplatesPurchased': this.state.isTemplatesPurchased
    }, this.props)))))));
}