import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DashboardTemplate from "./templates/dashboard";
import Swal from 'sweetalert2';
import { getPracticePaymentSubscriptionDetails } from '../../redux/payment/network/plan_subscribe_network' 
import { history } from "../../redux/store";
import { listInspireCourses } from '../../redux/dashboard/network/dashboard_courses';
import { ssoLoginLMS } from '../../redux/auth/network/auth_network';
import memberBanner from '../../../assets/dashboard/membership.jpg'

const courses_banners = [
    {
        category: 'Techniques (TECH)',
        title: 'Functional Techniques',
        subtext: 'Define Function & Learn Purposeful Movements',
        blockClass: 'functional-technique-block',
        logoClass: 'functional-techinques-logo',
        link: 'https://grayinstitute.inspire360.com/p/functional-techniques'
    },
    {
        category: 'Foundations (FOUND)',
        title: 'Foundation',
        subtext: 'Key Principles of Functional Movement',
        blockClass: 'foundation-block',
        logoClass: 'foundation-logo',
        link: 'https://grayinstitute.inspire360.com/p/foundations'
    },
    {
        category: '3D Movement Analysis and Performance System (3DMAPS)',
        title: '3DMAPS®',
        subtext: ' Functional Assessment Certification',
        blockClass: 'threedmaps-block',
        logoClass: 'threedmaps-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/3dmapsMovement?_ga=2.173700643.1299759657.1649760019-2074451519.1644918861'
    },
    {
        category: 'Certification in Applied Functional Science (CAFS)',
        title: 'CAFS',
        subtext: 'Corrective Exercise Certification',
        blockClass: 'cafs-block',
        logoClass: 'cafs-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/certification-in-applied-functional-science?_ga=2.218701209.1299759657.1649760019-2074451519.1644918861'
    },
    {
        sku: 'GIFT',
        category: 'GIFT',
        title: 'GIFT',
        subtext: ' Mentorship in Applied Functional Science®',
        blockClass: 'gift-block',
        logoClass: 'gift-logo',
        link: 'https://grayinstitute.com/courses/gift-gray-institute-for-functional-transformation'
    },
    {
        category: 'Active Aging Specialist (AAS)',
        title: 'Active Aging',
        subtext: 'Rehab and Training for Aging Population',
        blockClass: 'aging-block',
        logoClass: 'aging-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/active-aging?_ga=2.140074291.1299759657.1649760019-2074451519.1644918861'
    },
    {
        category: 'Chain Reaction',
        title: 'Chain Reaction®',
        subtext: 'Biomechanics Seminar with Dr. Gary Gray',
        blockClass: 'chain-block',
        logoClass: 'chain-logo',
        link: 'https://grayinstitute.com/courses/chain-reaction-biomechanics-seminar-with-dr-gary-gray'
    },
    {
        category: 'Female Chain Reaction Specialist (FCR)',
        title: 'Female Chain Reaction®',
        subtext: 'Female Anatomy Treatment and Training',
        blockClass: 'female-chain-block',
        logoClass: 'female-chain-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/female-chain-reaction-online?_ga=2.118767241.1299759657.1649760019-2074451519.1644918861'
    },
    {
        category: 'Foot & Ankle Specialist (FAS)',
        title: 'Foot / Ankle',
        subtext: 'Focusing on the Biomechanics of the Foot and Ankle',
        blockClass: 'foot-block',
        logoClass: 'foot-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/foot-ankle?_ga=2.184695465.1299759657.1649760019-2074451519.1644918861'
    },
    {
        category: 'Functional Golf Specialist (FGS)',
        title: 'Functional Golf System',
        subtext: 'Golf Specific Treatment and Training',
        blockClass: 'golf-block',
        logoClass: 'golf-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/functional-golf-specialist?_ga=2.109796997.1299759657.1649760019-2074451519.1644918861'
    },
    {
        category: 'Functional Soft Tissue Specialist (FSTS)',
        title: 'Functional Soft Tissue Transformation',
        subtext: 'Soft Tissue Analysis and Treatment',
        blockClass: 'soft-tissue-block',
        logoClass: 'soft-tissue-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/FSTT?_ga=2.76182805.1299759657.1649760019-2074451519.1644918861'
    },
    {
        category: 'Functional Yoga Specialist (FYS)',
        title: 'Functional Yoga System',
        subtext: 'The Most Comprehensive Functional Yoga Training Course',
        blockClass: 'yoga-block',
        logoClass: 'yoga-logo',
        link: 'https://grayinstitute.inspire360.com/grayinstitute/female-chain-reaction-online?_ga=2.118767241.1299759657.1649760019-2074451519.1644918861'
    }
]
class Dashboard extends Component {
    constructor(props, context) {
        super(props, context);
        this.openMobilePopup = this.openMobilePopup.bind(this)
        this.goToTemplates = this.goToTemplates.bind(this)
        this.redirectToLMS = this.redirectToLMS.bind(this)
        this.state = {
            userSubscriptionStatus: false,
            newCoursesArr: [],
            view: 'my_templates',
            isTemplatesPurchased:false
        }
    }
    redirectToLMS(){
        this.props.ssoLoginLMS({
            "userId": this.props.user.remote_user_id,
            "token": this.props.auth.ssoData.PHPSESSID,
            "userType" : 2
        }).then((resp)=>{
            window.open(`${resp.data.url}?${resp.data.access_token}`, "_self")
        })
    }
    goToTemplates() {
        this.props.getPracticePaymentSubscriptionDetails({
            "package_version": "3", 
            "app_version": "5.4", 
            "current_role_type": "practice_admin", 
            "practice_id": this.props.practice_id
        }).then((res)=>{ 
            this.setState({
                userSubscriptionStatus: res.data.user_subscription_active,
                isTemplatesPurchased: res.data.isTemplatesPurchased
            })
            if(this.props.user.gi_isadmin){
                history.push(`/practice/${this.props.selectedRole.practice.id}/engagement/p/template/list`)
            } else {
                if(res.data.user_subscription_active || res.data.isTemplatesPurchased){
                    history.push(`/practice/${this.props.selectedRole.practice.id}/engagement/p/template/list`)
                } else if(!res.data.user_subscription_active || !res.data.isTemplatesPurchased) {
                    history.push(`/practice/${this.props.selectedRole.practice.id}/buy_plans`)
                }
            }
        })
    }
    componentDidMount(){
        console.log('dashboard live')
        this.props.listInspireCourses({
            "package_version": "3",
            "app_version": "5.4",
            "page":0
        }).then(()=>{
            courses_banners.filter(course => this.props.inspire_courses.some(inspire_course => {
                if(course.category === inspire_course.category){
                    return course.inspire = inspire_course
                }
            }));
            this.setState({
                newCoursesArr: courses_banners
            })
        })
    }
    openMobilePopup() {
        let that = this;
        Swal.fire({
            customClass: {
                container: 'paywall-modal',
                title: 'paywall-title',
                html:'paywall-text',
                confirmButton: 'mobile-popup-btn',
                cancelButton: 'mobile-popup-btn',
              },
            // title: "Active Subscription Required",
            html: '<p>Kindly use a Desktop computer or a Tablet to continue using this functionality.</p>',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#fff",
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            useRejections:true
        }).then((result) => {
            // if (result.value) {
            //     window.open('https://apps.apple.com/us/app/dhf-coachfit/id1130877074', '_blank');
            //   } else if (result.dismiss=='cancel') {
            //     window.open('https://play.google.com/store/apps/details?id=com.gomotive.dhf', '_blank');
            //   }
            return
        }, function (dismiss) {
        });
    }

    renderImage = () => {
        return (
            <a target="_blank" style={{marginTop:'30px',display:'block'}} href="https://grayinstitute.inspire360.com/p/membership">
                <img src={memberBanner} style={{borderRadius:'8px',width:'87%'}} alt="banner" />
            </a>
        )
    }

    render() {
        return DashboardTemplate.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPracticePaymentSubscriptionDetails,
        listInspireCourses,
        ssoLoginLMS
    }, dispatch);
}
function mapStateToProps(state) {    
    return {
        courses_banners,
        auth: state.auth.authReducer,
        user: state.auth.authReducer.user,
        roles: state.auth.roleReducer.roles,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        inspire_courses: state.dashboard.courseList,
    };
}
const DashboardComponent = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default DashboardComponent;