let initialState = {
    client: {},
    engagements: [],
    groups: [],
    active_engagement: false,
    support_multiple_gameplan: false,
    selected_client_for_action: null,
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "CLIENT_INSTANCE_FILL":
            if (!action.client || !action.engagements || !action.groups ){
                return state;
            }
            return Object.assign({}, state, {
                client: action.client,
                engagements: action.engagements,
                groups: action.groups,
                active_engagement: action.active_engagement,
                support_multiple_gameplan: action.support_multiple_gameplan,
            });
        case "CLIENT_DETAILS_FILL":
            if (!action.client){
                return state;
            }
            return Object.assign({}, state, {
                client: action.client,
            });            
        case "CLIENT_INSTANCE_CLEANUP":
            return Object.assign({}, initialState);
        case "CLIENT_SELECTION_FILL":
            if (!action.client_obj){
                return state;
            }
            return Object.assign({}, state, {
                selected_client_for_action: action.client_obj,                
            });
        case "CLIENT_SELECTION_CLEAR":            
            return Object.assign({}, state, {
                selected_client_for_action: null,                
            });
        default:
            return state;
    }
};
