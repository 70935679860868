import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ButtonDefault from 'react-toolbox/lib/button';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import FontIcon from 'react-toolbox/lib/font_icon';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import image from './feat-image';
import { MdOutlinePeople } from 'react-icons/md';
import { MdOutlineArrowForward } from 'react-icons/md';
import { MdOutlinePlayCircleOutline } from 'react-icons/md';
export default function () {
    function repeatBanner1(banner, index) {
        return React.createElement('div', {
            'className': 'width-343 box-shadow bg-white m-l-8 m-r-8 m-b-18 ribbon-container',
            'key': 'banner' + index
        }, React.createElement('div', {}, React.createElement('div', {
            'className': 'thumbnail box-343-190 p-relative p-16 p-r-25',
            'style': {
                background: 'linear-gradient(293.96deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%),url(' + encodeURI(banner.thumbnail_url) + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }
        }, React.createElement('div', { 'className': 'promo-title' }, banner.heading), React.createElement('div', { 'className': 'promo-subtitle m-t-8' }, banner.sub_heading), React.createElement(Button, {
            'tooltip': 'Learn More',
            'label': banner.button_text,
            'className': 'm-t-18 f-14 bg-success-red c-white text-capital'
        }))), React.createElement('div', { 'className': 'row bg-white center-xs middle-xs m-0 m-b-8 m-t-12  p-l-5' }, React.createElement(TooltipWrapper, {
            'tooltip': true,
            'className': 'col-xs-12 video-title f-16 text-center text-capital p-l-13 p-r-13'
        }, '\n                                    ', banner.banner_location, '\n                                ')), [React.createElement('div', {
                'className': 'col-xs-12 m-t-10 m-b-15 flex-center',
                'key': '35011'
            }, React.createElement(IconButton, {
                'tooltip': 'Edit Banner Details',
                'icon': 'edit',
                'className': 'c-grey small-icon-button m-r-10',
                'onClick': () => {
                    this.edit('promo-banners', banner.id);
                }
            }), React.createElement(IconButton, {
                'icon': 'deactivate',
                'tooltip': banner.hidden ? 'Unhide Banner' : 'Hide Banner',
                'className': banner.hidden ? 'c-red small-icon-button m-l-10' : 'c-grey small-icon-button m-l-10',
                'onClick': () => {
                    this.toggleVisibility(banner);
                }
            }))]);
    }
    function repeatBanner2(banner, index) {
        return React.createElement('div', {
            'className': 'width-343 box-shadow bg-white m-l-8 m-r-8 m-b-18 ribbon-container',
            'key': 'banner' + index
        }, React.createElement('div', { 'className': 'd-flex box-343-170 border-r-10\n                                    ' + (banner.themes ? banner.themes.color_name === 'Gray' ? 'bg-grey' : banner.themes.color_name === 'Red' ? 'bg-primary' : 'bg-blue' : 'bg-blue') }, React.createElement('div', { 'className': 'p-16 p-r-25 w-75' }, React.createElement('div', { 'className': 'feature-title' }, banner.heading), React.createElement('div', { 'className': 'feature-subtitle m-t-8 w-200' }, banner.sub_heading), React.createElement(Button, {
            'tooltip': 'Learn More',
            'className': (banner.themes ? banner.themes.color_name === 'Gray' ? 'c-dark-grey' : banner.themes.color_name === 'Red' ? 'c-brand-red' : 'c-blue' : 'c-blue') + ' \n                                            m-t-18 bg-white text-capital border-r-10',
            'style': {
                position: 'absolute',
                left: '16px',
                top: '100px'
            }
        }, '\n                                        ', banner.button_text, ' ', React.createElement(MdOutlineArrowForward, { 'className': 'm-l-5' }))), React.createElement('img', {
            'src': banner.thumbnail,
            'alt': 'image',
            'className': 'p-absolute p-right-0 p-top-neg-30'
        })), React.createElement('div', { 'className': 'flex-center m-t-10' }, React.createElement(MdOutlinePeople, { 'className': 'c-grey m-r-5' }), React.createElement('div', { 'className': 'feature-clicks m-r-10' }, banner.clicks, ' total clicks'), React.createElement(MdOutlinePeople, { 'className': 'c-grey m-r-5' }), React.createElement('div', { 'className': 'feature-clicks' }, banner.unique_clicks_count, ' unique clicks')), [React.createElement('div', {
                'className': 'col-xs-12 m-t-10 m-b-15 flex-center',
                'key': '70011'
            }, React.createElement(IconButton, {
                'tooltip': 'Edit Banner Details',
                'icon': 'edit',
                'className': 'c-grey small-icon-button m-r-10',
                'onClick': () => {
                    this.edit('feature-banners', banner.id);
                }
            }), React.createElement(IconButton, {
                'icon': 'deactivate',
                'tooltip': banner.hidden ? 'Unhide Banner' : 'Hide Banner',
                'className': banner.hidden ? 'c-red small-icon-button m-l-10' : 'c-grey small-icon-button m-l-10',
                'onClick': () => {
                    this.toggleVisibility(banner);
                }
            }))]);
    }
    function repeatBanner3(banner, index) {
        return React.createElement('div', {
            'className': 'width-343 box-shadow bg-white m-l-8 m-r-8 m-b-18 ribbon-container',
            'key': 'banner' + index
        }, React.createElement('div', { 'className': 'thumbnail box-343-280 border-r-10 m-b-15' }, React.createElement('div', { 'className': 'p-16' }, React.createElement('div', { 'className': 'upcoming-title' }, banner.heading), React.createElement('div', {
            'className': 'box-311-170 bg-grey border-r-10 m-t-8 flex-center flex-align-center',
            'style': {
                backgroundImage: 'url(' + encodeURI(banner.thumbnail) + ')',
                backgroundSize: 'contain'
            }
        }, React.createElement(MdOutlinePlayCircleOutline, { 'className': 'large-icon-button c-white' })), React.createElement('div', { 'className': 'upcoming-subtitle m-t-8' }, banner.sub_heading))), React.createElement('div', { 'className': 'flex-center m-t-10' }, React.createElement(MdOutlinePeople, { 'className': 'c-grey m-r-5' }), React.createElement('div', { 'className': 'upcoming-clicks m-r-10' }, banner.clicks, ' total clicks'), React.createElement(MdOutlinePeople, { 'className': 'c-grey m-r-5' }), React.createElement('div', { 'className': 'upcoming-clicks' }, banner.unique_clicks_count, ' unique clicks')), [React.createElement('div', {
                'className': 'col-xs-12 m-b-15 flex-center',
                'key': '97681'
            }, React.createElement(IconButton, {
                'tooltip': 'Edit Banner Details',
                'icon': 'edit',
                'className': 'c-grey small-icon-button m-r-10',
                'onClick': () => {
                    this.edit('upcoming-banners', banner.id);
                }
            }), React.createElement(IconButton, {
                'icon': 'delete',
                'tooltip': 'Delete Banner',
                'className': 'c-red small-icon-button m-l-10',
                'onClick': () => {
                    this.deleteBanner(banner);
                }
            }))]);
    }
    return React.createElement('div', { 'className': 'full-height w-100' }, React.createElement('div', { 'className': 'page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Marketing'), React.createElement('h2', { 'className': 'page-title with-subtitle' }, 'Banners'))), React.createElement('div', { 'className': 'tabs-container' }, React.createElement(Tabs, {
        'index': this.state.index,
        'onChange': this.handleChange
    }, React.createElement(Tab, {
        'activeClassName': 'active-tab',
        'label': 'Promo Banners'
    }, React.createElement('div', { 'className': 'promo-banner' }, React.createElement.apply(this, [
        'div',
        { 'className': 'm-t-24 d-flex flex-wrap' },
        _.map(this.props.bannerList, repeatBanner1.bind(this))
    ]))), React.createElement(Tab, {
        'activeClassName': 'active-tab',
        'label': 'Feature Banners'
    }, React.createElement('div', { 'className': 'feature-banner' }, React.createElement(Button, {
        'className': 'width-fit bg-success m-r-10 p-absolute p-right-0 p-top-100',
        'onClick': () => this.add('feature-banners'),
        'floating': true,
        'ripple': true,
        'mini': true
    }, 'Add New'), this.state.index === 1 ? React.createElement.apply(this, [
        'div',
        {
            'className': 'm-t-24 d-flex flex-wrap',
            'key': '4752'
        },
        _.map(this.props.bannerList, repeatBanner2.bind(this))
    ]) : null)), React.createElement(Tab, {
        'activeClassName': 'active-tab',
        'label': 'Upcoming Events'
    }, React.createElement('div', { 'className': 'upcoming-banner' }, React.createElement(Button, {
        'className': 'width-fit bg-success m-r-10 p-absolute p-right-0 p-top-100',
        'onClick': () => this.add('upcoming-banners'),
        'floating': true,
        'ripple': true,
        'mini': true
    }, 'Add New'), React.createElement.apply(this, [
        'div',
        { 'className': 'm-t-24 d-flex flex-wrap' },
        _.map(this.props.bannerList, repeatBanner3.bind(this))
    ]))))));
}