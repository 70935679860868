import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/notification_content";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { getGoalList, changePartnerGoalVisibilityStatus, deleteGoal } from '../../redux/goal/network/goal_network';
import swal from 'sweetalert2';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { ENGAGEMENT_GOAL_VIDEO } from '../../utils/help_video';
import { getCoachList } from '../../redux/dhf/move/network/coach_network';
import {getPractitionerList, } from '../../redux/practice/network/practice_practitioner';
import { getClientList } from '../../redux/engagement/network/client_list_network';

class NotificationList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.props.updateHelpVideo(ENGAGEMENT_GOAL_VIDEO);
    }
    
    componentWillUnmount() {
        this.props.clearHelpVideo();

    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        coachList: state.move.coachReducer.coach_list,
        clientList: state.engagement.clientListReducer.client_list,
        goals: state.goal.goalReducer.goal_list,
        hidden_goals: state.goal.goalReducer.hidden_goals,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGoalList,
        getCoachList,
        getPractitionerList,
        deleteGoal,
        getClientList,
        changePartnerGoalVisibilityStatus,
        updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearHelpVideo: HelpVideoCreator.clearHelpVideo,
    },
        dispatch
    );
}

const NotificationListConnected = connect(mapStateToProps, mapDispatchToProps)(NotificationList);
export default NotificationListConnected;