import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/cue_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import { getCueList } from '../../redux/exercise/network/cue_network';

class CueList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addCue = this.addCue.bind(this);
        this.editCue = this.editCue.bind(this);
    }
    
    componentWillMount() {
        this.props.getCueList();
    }

    componentWillUnmount() {
    }

    addCue() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/cue/add');
    }

    editCue(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/cue/edit/'+id);
    }
    
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        cue_list: state.exercise.cueReducer.cue_list
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getCueList,
        }, 
        dispatch
    );
}


const CueListConnected = connect(mapStateToProps,mapDispatchToProps)(CueList);
export default CueListConnected;