
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/content_video_edit";
import { reduxForm, formValueSelector, arrayPush } from 'redux-form';
import { useHistory } from 'react-router-dom'

import store,{history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listContent, contentSearch,
    getContent, postExercise, deleteExercise,
    uploadMedia, getCategory, getAssociatedContent,
    associatedContentSearch
} from '../../redux/content/network/content';
import { getHomeFitContent, postHomeFitContent, getCategoryHomeFit, homefitContentSearch } from '../../redux/content/network/homefit_content';
import {listHomeFitContent} from '../../redux/content/network/homefit_content';
import actionCreator from '../../redux/content/actions/exercise';
import actionCreatorList from '../../redux/content/actions/exercise_list';
import {listProgram, getProgramFilters} from '../../redux/program/network/program_list';
import Utils from '../../utils/utils';
import React from 'react';
import axios from 'axios';
import Axios from '../../utils/network/axios';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';
import moment from 'moment';
import { param } from 'jquery';
import ContentEdit from './content_video_edit'
import { getMoveVideoList} from '../../redux/dhf/move/network/move_network_new';
let homefitCategories = [
    { db_value: "power", label: "Power" },
    { db_value: "metabolic", label: "Metabolic" },
    { db_value: "strength", label: "Strength" },
    { db_value: "mobility", label: "Mobility" },
];

class VideoEditContainer extends BaseComponent {

    constructor(props, context) {
        super(props, context);

    }


    render() {
        if(window.location.pathname.split('/')[4]==="gi_content"){
            return <ContentEdit content="gi_content" properties={this.props}/>; 
        } else if(window.location.pathname.split('/')[4]==="content"){
            return <ContentEdit content="homefit_content" properties={this.props}/>;
        }
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectedAssociatedContentList : (content) => {
            return dispatch(actionCreatorList.selectedContent(
                content
            ));
        },
        selectedAssociatedWorkoutList : (workout) => {
            return dispatch(actionCreatorList.selectedWorkout(
                workout
            ));
        },
        removeContent: actionCreatorList.removeItem,
        removeWorkout: actionCreatorList.removeItemWorkout,
        getMoveVideoList,
        listHomeFitContent,
        getHomeFitContent,
        postHomeFitContent,
        associatedContentSearch,
        homefitContentSearch,
        listProgram,
        getContent,
        uploadFiles,
        getCategory,
        getAssociatedContent,
        postExercise,
        listContent,
        contentSearch,
        getCategoryHomeFit,
        getProgramFilters,
        deleteExercise,
        uploadMedia,
        cleanModule: actionCreator.cleanModule,
        showProgress,
        hideProgress,
    }, dispatch);
}
const selector = formValueSelector('VideoEditContainerForm');

function mapStateToProps(state, ownProps) {
    // For Publish date & time
    let news = state.content.content.news
    let homeFitNews = state.content.content.homeFitNews
    let currentDate = moment().format("D MMM YYYY");
    let currentTime = moment().format();

    let initialValues = {
        publish_date: Utils.parseServerDate(currentDate),
        publish_time: Utils.parseServerDate(currentTime),
    }

    if(window.location.pathname.split('/')[4]==="gi_content"){
        if(Object.keys(news).length){
            initialValues  = {
               ...news,
               publish_date: Utils.parseServerDate(news.publish_date),
               publish_time:Utils.parseServerDate(moment(`${news.publish_date} ${news.publish_time}`).format()),
           };
       }
    } else if(window.location.pathname.split('/')[4]==="content"){
        if(Object.keys(homeFitNews).length){
            initialValues  = {
            ...homeFitNews,
            publish_date: Utils.parseServerDate(homeFitNews.publish_date),
            publish_time:Utils.parseServerDate(moment(`${homeFitNews.publish_date} ${homeFitNews.publish_time}`).format()),
        };
        }
    }
    // For Category response
    let categories = state.content.content.categories
    initialValues.category = categories.find(category => {
        return category.label === initialValues.category
      })
    initialValues.category = initialValues.category ? initialValues.category.db_value : "";

    let selectedAssociatedContents =  state.content.content.selectedAssociatedContent
    let contents = window.location.pathname.split('/')[4]==="gi_content" ? state.content.content.contentListAssociated : state.content.content.homeFitContentList;
    let displayAssociatedContents =[];
    if(selectedAssociatedContents && Object.keys(selectedAssociatedContents).length){
        Object.keys(selectedAssociatedContents).forEach((key) => {
            if(typeof(selectedAssociatedContents[key])  === "object"){
                displayAssociatedContents.push(selectedAssociatedContents[key])
            }else{
                let foundContent = contents.find(content => content.id == selectedAssociatedContents[key])
                if(foundContent){
                    displayAssociatedContents.push(foundContent)
                }            
            }
        })
    } 
    let selectedAssociatedWorkouts =  state.content.content.selectedAssociatedWorkout
    let workoutContents = window.location.pathname.split('/')[4]==="gi_content" ? state.program.programList.programListAssociated : state.move.moveReducer.move_videos
    let displayAssociatedWorkoutContents =[];
    if(selectedAssociatedWorkouts && Object.keys(selectedAssociatedWorkouts).length){
        Object.keys(selectedAssociatedWorkouts).forEach((key) => {
            if(typeof(selectedAssociatedWorkouts[key])  === "object"){
                displayAssociatedWorkoutContents.push(selectedAssociatedWorkouts[key])
            }else{
                let foundWorkout = workoutContents.find(workout => workout.id == selectedAssociatedWorkouts[key])
                if(foundWorkout){
                    displayAssociatedWorkoutContents.push(foundWorkout)
                }                
            }
        })
    } 
    return {
        selectedAssociatedContents: selectedAssociatedContents,
        selectedAssociatedWorkouts: selectedAssociatedWorkouts,
        selectedAssociatedContent: state.content.content.selectedAssociatedContent,
        selectedAssociatedWorkout: state.content.content.selectedAssociatedWorkout,
        homefitCategories,
        // profile: profile,
        workoutCategories: state.program.programList.filterData.category,
        associatedWorkouts: state.program.programList.programList,
        news_associated:  displayAssociatedContents,
        news_workout_templates: displayAssociatedWorkoutContents,
        
        is_image_uploaded: selector(state, 'is_image_uploaded') ? true : initialValues.news_media ? initialValues.news_media[0].thumbnail_url ? true: false : false, 
        is_audio_uploaded: selector(state, 'is_audio_uploaded') ? true : initialValues.news_media ? initialValues.news_media[0].url ? true: false : false, 
        image_url: selector(state, 'image_url') ? selector(state, 'image_url') : initialValues.news_media && initialValues.news_media[0].thumbnail_url,
        user_guid: selector(state, 'user_guid'),
        image_name: selector(state, 'image_name'),
        audio_name: selector(state, 'audio_name'),
        audio_url: selector(state, 'audio_url'),
        news: state.content.content.news,
        homeFitNews: state.content.content.homeFitNews,
        categories: categories,
        associatedContentOptions: state.content.content.contentList ,
        homefitAssociatedContentOptions: state.content.content.homeFitContentList ,
        homefitAssociatedWorkoutsOptions: state.move.moveReducer.move_videos,
        initialValues:  initialValues,
        supportingData: state.exercise.exercise.supportingData,
        position_pictures: selector(state, 'position_pictures'),
        video_thumbnail_url: selector(state, 'video_thumbnail_url'),
        video_guid: selector(state, "video_guid"),
        is_client_specific: selector(state, 'is_client_specific'),
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        user: state.auth.authReducer.user,
        associated_category: selector(state, 'associated_category'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'title', 'category','client', 'publish_date', 'add_media'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const VideoEditContainerForm = reduxForm(
    {
        form: 'VideoEditContainerForm',
        enableReinitialize:  true,
        validate
    }
)(VideoEditContainer);

const VideoEditContainerConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoEditContainerForm);

export default VideoEditContainerConnected;


