import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import {bindActionCreators} from 'redux';
import Template from "./templates/client_payment.rt";
import {connect} from 'react-redux';
import Utils from '../../utils/utils';

import {subscribeToClientPlan} from '../../redux/payment/network/client_payment_plan_network';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import Swal from 'sweetalert2';

class ClientPayment extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.subscribe = this.subscribe.bind(this);
    }

    componentWillMount() {
    }

    subscribe(){
        history.push("/client/my_plans");
    }

    render() {
        if (_.isEmpty(this.props.choose_plan)) {
            return (
                <div></div>
            );
        }
        return Template.apply(this);
    }
}

function mapStateToProps(state,ownprops) {
    return {
        current_plan: ownprops.current_plan,
        choose_plan: ownprops.choose_plan,
        subscribeSuccessCallback: ownprops.subscribeSuccessCallback,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        subscribeToClientPlan,
    }, dispatch);
}
const ClientPaymentConnected = connect(mapStateToProps, mapDispatchToProps)(ClientPayment);
export default ClientPaymentConnected;