import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import PanelGroup from '../../../vendor/react-panelgroup/panelgroup';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatHabit_template1(habit_template, habit_templateIndex) {
        return React.createElement('div', { 'key': 'habit_template' + habit_template.id }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, React.createElement('label', {}, habit_template.name), React.createElement('p', { 'className': 'material-label f-10' }, habit_template.text)), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
            'tooltip': 'Delete',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'className': 'bg-danger micro',
            'onClick': () => {
                this.removeHabitTemplate(habit_template);
            }
        }))))));
    }
    function repeatHabit_template2(habit_template, habit_templateIndex) {
        return React.createElement('div', { 'key': 'habit_template' + habit_template.id }, !(habit_template.id in this.props.partner_template_ids) ? React.createElement('div', {
            'className': 'panel',
            'key': '3321'
        }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, React.createElement('label', {}, habit_template.name), React.createElement('p', { 'className': 'material-label f-10' }, habit_template.text)), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
            'tooltip': 'Add',
            'icon': 'add',
            'floating': true,
            'mini': true,
            'className': 'bg-success micro',
            'onClick': () => {
                this.addHabitTemplate(habit_template);
            }
        }))))) : null);
    }
    return React.createElement('div', { 'className': 'row start-xs  m-t-20 m-l-20 custom-divider' }, React.createElement('h3', { 'className': 'text-center m-5' }, '\n        Shared content library \'', this.props.partner_library.name, '\'\n    '), React.createElement(PanelGroup, { 'borderColor': '#eee' }, React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 full-height' },
        React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Shared Habit Templates')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
            'tooltip': 'Remove all habit templates from this shared content library',
            'label': 'REMOVE ALL',
            'className': 'bg-danger small-button c-white',
            'onClick': () => {
                this.removeAllHabitTemplates();
            }
        }))),
        _.map(this.props.partner_library.habit_templates, repeatHabit_template1.bind(this))
    ]), React.createElement.apply(this, [
        'div',
        {
            'className': 'col-xs-12',
            'style': { minHeight: '400px' }
        },
        React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Habit Templates you can share')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
            'tooltip': 'Add all habit templates to this shared content library',
            'label': 'ADD ALL',
            'className': 'bg-success small-button c-white',
            'onClick': () => {
                this.addAllHabitTemplates();
            }
        }))),
        _.map(this.props.habit_templates, repeatHabit_template2.bind(this))
    ])));
}