import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progressions_build_new";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from "../../redux/store";
import Utils from '../../utils/utils';
import {PARTNER} from '../../core/constants/constants';

class ProgressionsBuild extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.togglePublish = this.togglePublish.bind(this);
    }

    componentWillMount() {
    }

    togglePublish(publish) {
        this.props.toggleProgramPublish({
            id: this.props.program.id,
            'is_publish': publish,
        }).then(() => {
            this.props.finishEditingMoveBack();
        }).catch(() => { });
    }

    add() {
        if(PARTNER.progression_count === 's' && this.props.program.progressions.length > 0) {
            Utils.showErrorAlert("You can create only 1 progression workout.");
        } else {
            this.props.editProgression(-1);
        }
    }

    render() {
        if (_.isEmpty(this.props.program)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

const ProgressionsBuildConnected = connect(mapStateToProps, mapDispatchToProps)(ProgressionsBuild);
export default ProgressionsBuildConnected;
