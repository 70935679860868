// Action Creators
const workfitSessionListFill = (sessions, paginate_info) => ({
    type: 'CLIENT_WORKFIT_SESSION_LIST_FILL',
    sessions:sessions,
    paginate_info: paginate_info,
});


const workfitCleanup = () => ({
    type: 'CLIENT_WORKFIT_CLEANUP',
});

export default {
    workfitSessionListFill,    
    workfitCleanup,
};
