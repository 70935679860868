
let initialState = {
    programList: [],
    paginateInfo: {
        page: 0,
    },
    filterData:{},
    searchPreferences:{}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "PROGRAM_LIST_FILL":
            let newProgramList = [];
            if((action.paginateInfo && action.paginateInfo.page ===0) || Object.entries(action.paginateInfo).length == 0 ){
                newProgramList = [...action.programList];
            }else{
                newProgramList = [...state.programList,...action.programList];
            }
            // if(action.paginateInfo && action.paginateInfo.page ===0 ){
            //     newProgramList = [...state.programList,...action.programList];
            // }else{
            //     newProgramList = [...state.programList,...action.programList];
            // }
            return Object.assign({}, state, {
                programList:newProgramList,
                paginateInfo: action.paginateInfo
            });
        case "PROGRAM_LIST_FILL_ASSOCIATED":
            let newProgramListAssociated = [];
            if(action.paginateInfo && action.paginateInfo.page ===0 ){
                newProgramListAssociated = [...action.programList];
            }else{
                newProgramListAssociated = [...state.programList,...action.programList];
            }
            return Object.assign({}, state, {
                programListAssociated:newProgramListAssociated,
                paginateInfo: action.paginateInfo
            });
        case "PROGRAM_UPDATE_FAVORITE_FILL":
            if(!action.program) {
                return state;
            }
            let toggleProgramList = _.map(state.programList, (item) => {
                if (item.id === action.program.id) {
                    return action.program;
                } else {
                    return item;
                }
            });
            return Object.assign({}, state, {
                programList:toggleProgramList,
            });
        case "PROGRAM_INSERT_CLONE":
            let srcIndex = _.findIndex(state.programList, (program) => { return program.id === action.srcId; });
            let programList = [...state.programList];
            programList.splice(srcIndex + 1, 0, action.clonedProgram);
            return Object.assign({}, state, {
                programList: programList,
            });
        case "PROGRAM_DELETE":
            return Object.assign({}, state, {
                programList: _.filter(state.programList, (program) => { return program.id !== action.id;}),
            });
        case "PROGRAM_FILTER_DATA_FILL":
            if(!action.filterData) {
                return state;
            }        
            if(action.first){
                return Object.assign({}, state, {
                    filterData: action.filterData,
                    searchPreferences:{...action.filterData.search_params}
                });
            }else{
                return Object.assign({}, state, {
                    filterData: action.filterData
                });
            }
        case "PROGRAM_LIST_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};