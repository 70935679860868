import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/view_profile";
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import ProfileEdit from '../../user/components/profile_edit';
import { bindActionCreators } from 'redux';
import { getProfile } from '../../redux/user/network/user_profile';
import Phone from "../../../assets/phone.png"
import Cake from "../../../assets/cake.png"
import BriefCase from "../../../assets/briefcase-outline.png"
import Occupation from "../../../assets/domain.png"
import location from "../../../assets/map-marker.png"
import Education from "../../../assets/education.png"
import Check from "../../../assets/check.png"
import Bio from "../../../assets/text-account.png"
import Website from "../../../assets/Website.png"
import Media from "../../../assets/social-media.png"
import facebook from "../../../assets/facebook.png"
import instagram from "../../../assets/instagram.png"
import twitter from "../../../assets/twitter.png"
import youtube from "../../../assets/youtube.png"
import linkedin from "../../../assets/linkedin.png"
import tiktok from "../../../assets/tiktok.png"
import moment from 'moment'
import Button from 'react-toolbox/lib/button';
import Card from "react-toolbox/lib/card"
import CardActions from "react-toolbox/lib/card"
import Edit from "../../../assets/editicon.png"
import RightArrow from "../../../assets/go-arrow.png"

class ViewProfile extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doCancel = this.doCancel.bind(this);
        this.returnEditProfileApp = this.returnEditProfileApp.bind(this);
        this.pushRoute = this.pushRoute.bind(this);
        this.state = {
            interestData: [],
            educationData: [],
            profileData: {}
        }
    }

    componentWillMount() {
        this.props.getProfile({
            is_client: this.props.isClient,
            id: this.props.user_id,
        }).then((res) => {
            this.setState({
                profileData: res
            })
        })
    }


    componentDidMount() {
        this.setState({
            interestData: this.props.profile?.areas_of_interest,
            educationData: this.props.profile?.certificates
        })
    }

    doCancel() {
        history.goBack();
    }

    pushRoute() {
        history.push('/edit_profile')
    }

    returnEditProfileApp() {
        return (
            <ProfileEdit user_id={this.props.match.params.practitioner_id} profile={this.props.profile} is_client={false} self_editing={false} />
        );
    }

    isObjEmpty = (obj) => {
        return Object.keys(obj).length !== 0;
    }

    pushRouteEnable = () => {
        history.push('/edit_profile_info')
    }

    ViewProfileInfo = () => {
        return (
            <>
                {this.isObjEmpty(this.state.profileData) ?
                    <CardActions style={{ display: 'flex', flexDirection: 'column' }} class="end-xs m-b-20">
                        <Card class="w-100  no-box-shadow">
                            <div class="bg-white box-shadow-10 p-24">
                                <div class="view-con">
                                    <span class="title">View Profile</span>
                                    <a style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }} onClick={() => this.pushRoute()}>
                                        <img src={Edit} alt="edit" />
                                        <span>Edit Profile</span>
                                    </a>
                                </div>
                                <div class="profile-avatar-con">
                                    {this.state.profileData?.profile_pic ? <img style={{ width: '60px', height: '60px', borderRadius: '50%' }} src={this.state.profileData?.profile_pic} alt="profile pic" /> :
                                        <div class="icon">{this.state.profileData?.first_name.charAt(0)} {this.state.profileData?.last_name.charAt(0)}</div>}
                                    <div class="title-con">
                                        <span class="main-title">{this.state.profileData?.first_name} {this.state.profileData?.last_name}</span>
                                        <span class="subtitle">{this.state.profileData?.email}</span>
                                    </div>
                                </div>
                                <div class="info-con">
                                    <span class="info-title">Personal Info</span>
                                    <div class="info-sub">
                                        <div class="sub-con">
                                            <img src={Phone} alt="phone" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Phone Number</span>
                                                <span class="con-title">{this.state.profileData?.mobile_number}</span>
                                            </div>
                                        </div>
                                        <div class="sub-con">
                                            <img src={Cake} alt="cake" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Date of Birth</span>
                                                <span class="con-title">{moment(this.state.profileData?.dob).format("D MMM, YYYY")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-con">
                                    <span class="info-title">Professional  Info</span>
                                    <div class="info-sub">
                                        <div class="sub-con">
                                            <img src={BriefCase} alt="occupation" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Occupation</span>
                                                <span class="con-title">{this.state.profileData?.user_type?.name}</span>
                                            </div>
                                        </div>
                                        <div class="sub-con">
                                            <img src={Occupation} alt="company" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Company</span>
                                                <span class="con-title">{this.state.profileData?.company_name}</span>
                                            </div>
                                        </div>
                                        <div class="sub-con">
                                            <img src={location} alt="location" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Location</span>
                                                <span class="con-title">{this.state.profileData?.country}, {this.state.profileData?.state}, {this.state.profileData?.city} {this.state.profileData?.zipcode}</span>
                                            </div>
                                        </div>
                                        <div class="sub-con">
                                            <img src={Education} alt="education" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Certifications and Specializations</span>
                                                {this.state.profileData?.certificates?.map((item, index) =>
                                                    <span key={index} style={{ display: 'flex', alignItems: 'center' }} class="con-title">
                                                        <span style={{ marginRight: '5px', fontSize: '21px' }}>·</span>{item.name}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{ marginBottom: '10px', display: 'block' }}>Areas interest</span>
                                            {this.state.profileData?.areas_of_interest?.map((item, index) =>
                                                <div key={index} class="sub-con" style={{ alignItems: 'center' }}>
                                                    <img src={Check} alt="check" />
                                                    <div class="title-con">
                                                        <span class="con-title">{item.name}</span>
                                                    </div>
                                                </div>)}
                                        </div>
                                        <div class="sub-con">
                                            <img src={Bio} alt="bio" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Bio</span>
                                                <span class="con-title">{this.state.profileData?.bio}</span>
                                            </div>
                                        </div>
                                        <div class="sub-con">
                                            <img src={Website} alt="website" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Website Link</span>
                                                <a href={this.state.profileData?.website}><span class="con-title" style={{ color: '#1F8DCB' }}>{this.state.profileData?.website}</span></a>
                                            </div>
                                        </div>
                                        <div class="sub-con">
                                            <img src={Media} alt="media" />
                                            <div class="title-con">
                                                <span class="con-subtitle">Social Media</span>
                                                <div class="social-links">
                                                    {this.state.profileData?.facebook && <div class="sub-con"><a href={this.state.profileData?.facebook}><img src={facebook} alt="facebook" /></a></div>}
                                                    {this.state.profileData?.instagram && <div class="sub-con"><a href={this.state.profileData?.instagram}><img src={instagram} alt="instagram" /></a></div>}
                                                    {this.state.profileData?.twitter && <div class="sub-con"><a href={this.state.profileData?.twitter}><img src={twitter} alt="twitter" /></a></div>}
                                                    {this.state.profileData?.youtube && <div class="sub-con"><a href={this.state.profileData?.youtube}><img src={youtube} alt="youtube" /></a></div>}
                                                    {this.state.profileData?.linkedin && <div class="sub-con"><a href={this.state.profileData?.linkedin}><img src={linkedin} alt="linkedin" /></a></div>}
                                                    {this.state.profileData?.tiktok && <div class="sub-con"><a href={this.state.profileData?.tiktok}><img src={tiktok} alt="tiktok" /></a></div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-sub info-con">
                                    <span class='discover' style={{ display: 'block' }}>Discover your full potential as a professional with Movement Professional Locator</span>
                                    <span class='discover-sub' style={{ display: 'block', marginBottom: '5px', color: '#666666' }}>Get found by clients searching for someone like you and get referrals from other GI movement professional.</span>
                                    <button class="locator-btn" onClick={() => this.pushRouteEnable()}>
                                        <span>ENABLE Movement Professional locator</span>
                                        <img src={RightArrow} alt="arrow" />
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </CardActions>
                    : null}
            </>
        )
    }

    render() {
        return Template.apply(this);
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProfile,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let profile = { ...state.user.userProfile.profile };
    return {
        user: state.auth.authReducer.user,
        profile: profile,
        ...ownProps,
    };
}


const ViewProfileConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewProfile);

export default ViewProfileConnected;
