import React  from 'react';
import  Template from "./templates/vimeo_uploader";
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import axios from 'axios';
import swal from 'sweetalert2';
import PropTypes from 'prop-types';
import {showProgress, hideProgress} from '../../redux/core/actions/progress_creator';
import store from '../../redux/store';
import {VIMEO_UPLOAD_TOKEN} from '../../core/constants/constants';

class VimeoUploader extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.upload = this.upload.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
        this.cancelProcessing = this.cancelProcessing.bind(this);
        this.getThumbnail = this.getThumbnail.bind(this);
        this.wistiaParams = {
        };
        this.state = {
            uploading: false,
            processing: false,
            uploadProgress: 0,
            processingProgress: 0,
            uploadResult: {}
        };
        this.axiosApi = axios.create({
            headers: {
                'Authorization': 'bearer '+VIMEO_UPLOAD_TOKEN,
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type':'application/json',
            }
        });  
        this.AxiosUpload =  axios.create({
            headers: {
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Tus-Resumable':'1.0.0',
                'Upload-Offset':0,
                'Content-Type':'application/offset+octet-stream'
            },
            onUploadProgress: (progressEvent)=>{
                let uploaded = progressEvent.loaded === progressEvent.total;
                if(uploaded){
                    this.setState({
                        uploadProgress: (progressEvent.loaded/progressEvent.total)*100,
                        uploading: false,
                        processing:true,
                        processingProgress:0
                    });
                }else{
                    this.setState({
                        uploadProgress: (progressEvent.loaded/progressEvent.total)*100,
                        uploading: true,
                    });
                }
            },
        });
        this.cancellationSource = null; // is set just before upload starts
    };

    getUploadLink(files){
        let AxiosUploadLink =  axios.create({
            headers: {
                'Authorization': 'bearer '+VIMEO_UPLOAD_TOKEN,
                'Accept': "application/vnd.vimeo.*+json;version=3.4",
                'Content-Type':'application/json'
            }
        });
        let params = {    
            "upload" : {
                "approach" : "tus",
                 "size" : files[0].size
            },
            "name":files[0].name
        };
        store.dispatch(showProgress());
        return AxiosUploadLink.post("https://api.vimeo.com/me/videos/?fields=upload,uri", params);
        // return fetch("https://api.vimeo.com/me/videos", {
        //     body: JSON.stringify(params), 
        //     cache: 'no-cache',
        //     headers: {
        //         'Authorization': 'bearer c8b7ae8d25a3946e9bd225932b94f6c0',
        //         Accept: "application/vnd.vimeo.*+json;version=3.4",
        //     },
        //     method: 'POST',
        //     mode: 'cors',
        //   });
    }

    cancelUpload() {
        swal.fire({
            title: "Confirm Cancel",
            text: "Are you sure to cancel ongoing upload?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            this.cancellationSource.cancel('Operation canceled by the user.');
        }, () => { });
    }
    cancelProcessing() {
        swal.fire({
            title: "Confirm Cancel",
            text: "Are you sure to cancel ongoing upload?",
            type: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            this.cancellationSource.cancel('Operation canceled by the user.');
            this.setState({
                uploadProgress: 0,
                uploading: false,
                processing:false,
                processingProgress:0
            },()=>{
                Utils.showWarningAlert('Video upload was cancelled successfully.');
            });
        }, () => { });
    }

    getThumbnail(video_guid,counter = 1){
            let that = this;
            that.setState({
                processingProgress: counter * 5,
            });
            let thumbailUrl = "https://api.vimeo.com/videos/"+video_guid+"/?fields=transcode,pictures,files,is_playable,duration";
            that.cancellationSource = axios.CancelToken.source();
            that.axiosApi.get(thumbailUrl,{cancelToken: that.cancellationSource.token}).then((tResponse) => {
                if(tResponse.data.transcode.status === "in_progress" || tResponse.data.is_playable === false ){
                    if(counter < 400){
                        counter = counter + 1;
                        if(that.state.processing){
                            setTimeout(()=>{
                                that.getThumbnail(video_guid,counter);
                              },20000);  
                        }
                    }else{
                        store.dispatch(hideProgress());
                        that.props.onUploadFailed( new Error('Upload Failed'));
                    }
                }else{
                    let video_files = _.filter(tResponse.data.files,(item)=>{
                        if(item.quality === "hls"){
                            return true;
                        }else{
                            return false;
                        }
                    });
                    let uploadResult = {
                        thumbnail: tResponse.data.pictures.sizes[3].link,
                        guid: video_guid,
                        duration: tResponse.data.duration
                    }; 
                    if(video_files.length > 0){
                        uploadResult["video_url"] = video_files[0].link;
                    }
                    that.props.onUploadCompleted(uploadResult);
                    that.setState({
                        processing: false,
                        processingProgress: 100,
                        uploadResult: uploadResult,
                    });
                    Utils.showSuccessAlert("Video Successfully Uploaded.");
                    store.dispatch(hideProgress());
                }

            }).catch((error)=>{
                store.dispatch(hideProgress());
                that.setState({
                    processing: false,
                    processingProgress: 0,
                });
                if (error.message === 'Operation canceled by the user.') {
                    Utils.showWarningAlert('Video upload was cancelled successfully.');
                    return;
                }
                if (error.response && error.response.status && error.response.status === 400) {
                    Utils.showErrorAlert(error.response.data.reason);
                }
                that.props.onUploadFailed(error);
            });    
    }

    upload(files) {
        let that = this;
        that.cancellationSource = null;
        this.getUploadLink(files).then((resp)=>{
            that.setState({ uploading: true, uploadProgress: 0});
            store.dispatch(hideProgress());
            let formData = new FormData();
            formData.append("file_data", files[0]);
            for(let p in that.wistiaParams){
                formData.append(p, that.wistiaParams[p]);
            }
            that.cancellationSource = axios.CancelToken.source();
            let video_guid = resp.data.uri.split("/")[2];
            return that.AxiosUpload.patch(resp.data.upload.upload_link, files[0], {cancelToken: that.cancellationSource.token}).then((response) => {
                if (response.status === 204 && parseInt(response.headers["upload-offset"]) === files[0].size) {
                    that.setState({
                        uploading: false,
                        uploadProgress: 0,
                        processing:true,
                        processingProgress:0,
                    },()=>{
                        that.getThumbnail(video_guid);  
                    });
                } else {
                    that.setState({
                        uploading: false,
                        uploadProgress: 0,
                        processing:false,
                        processingProgress:0
                    });
                    store.dispatch(hideProgress());
                    that.props.onUploadFailed(response);
                }
            }).catch((error) => {
                store.dispatch(hideProgress());
                that.setState({
                    uploading: false,
                    uploadProgress: 0,
                    processing:false,
                    processingProgress:0
                });
                if (error.message === 'Operation canceled by the user.') {
                    Utils.showWarningAlert('Video upload was cancelled successfully.');
                    return;
                }
                if (error.response && error.response.status && error.response.status === 400) {
                    Utils.showErrorAlert(error.response.data.reason);
                }
                that.props.onUploadFailed(error);
                throw error;
            });
        }).catch((error)=>{
            that.setState({
                uploading: false,
                uploadProgress: 0,
                processing:false,
                processingProgress:0
            });
            store.dispatch(hideProgress());
            that.props.onUploadFailed(error);
            throw error;
        });
    }

    render() {
        return Template.apply(this);
    }
}

VimeoUploader.propTypes = {
    onUploadCompleted: PropTypes.func.isRequired,
    onUploadCanceled: PropTypes.func,
    onUploadFailed: PropTypes.func,
    buttonText: PropTypes.string,
};
VimeoUploader.defaultProps = {
    buttonText: 'Upload Video',
    onUploadCanceled: ()=>{},
    onUploadFailed: ()=>{Utils.showErrorAlert('Video upload failed. Please try again.');},
};
export default VimeoUploader;
