import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_group_class_booking_delete.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import { reduxForm, formValueSelector } from 'redux-form';
import { clientGroupClassSchedulerBookingCancel } from '../../redux/calendar/network/group_class_network';
import moment from 'moment';
import Swal from 'sweetalert2';

class ClientGroupClassBookingDelete extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
    }

    doSave(data){
        let that = this;
        let params = {...data};
        this.props.clientGroupClassSchedulerBookingCancel({...params})
        .then((response) => {
            that.props.closeBookingDialog();
        })
        .catch((error) => { 

        });
    }

    componentWillUnmount() {        
    }

    render() {
        if (_.isEmpty(this.props.group_class)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }  
        return Template.apply(this);
    }
}

const validate = (values, props) => {
    const errors = {};
    if(values.group_class_schedule.length ===0){
        errors["group_class_schedule"] = 'Please select a booking slot';
    }

    return errors;
};

function mapStateToProps(state, ownProps) {
    let groupClass =  _.cloneDeep(state.calendar.groupClassReducer.group_class);
    let bookedClient = _.cloneDeep(state.calendar.groupClassReducer.booked_client);
    bookedClient.bookings = _.filter(bookedClient.bookings,(item)=>{
        if(item.can_client_delete_booking === true){
            return true;
        }else{
            return false;
        }
    });

    bookedClient.bookings =  _.map(bookedClient.bookings,(booking)=>{
        booking["id"] = booking.group_class_schedule.id;
        const start_time = moment(booking.group_class_schedule.start_time).format('h:mm a');
        const end_time = moment(booking.group_class_schedule.end_time).format('h:mm a');
        const date = moment(booking.group_class_schedule.date,"YYYY-MM-DD").format("MMM DD, YYYY");
        booking['name'] = date + " ( "+start_time+ " to "+end_time+" )";
        return booking;
    });

    let initialValues = {client:null,group_class_id:ownProps.groupClassId,group_class_schedule:[]};
    if(!_.isEmpty(bookedClient)){
        initialValues["client"] = bookedClient.id;
    }

    return {
        initialValues: initialValues,
        group_class: groupClass,
        bookedClient: bookedClient,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clientGroupClassSchedulerBookingCancel
    }, dispatch
    );
}

const ClientGroupClassBookingDeleteForm = reduxForm({
    form: 'ClientGroupClassBookingDeleteForm',
    enableReinitialize: true,
    validate
}
)(ClientGroupClassBookingDelete);

const ClientGroupClassBookingDeleteFormConnected = connect(mapStateToProps, mapDispatchToProps)(ClientGroupClassBookingDeleteForm);
export default ClientGroupClassBookingDeleteFormConnected;