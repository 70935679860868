import React from 'react';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/consultant_update_bio";
import { reduxForm } from 'redux-form';
import store,{history} from '../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getConsultant, postConsultant} from '../../../redux/practice/network/practice_consultant';
import actionCreator from '../../../redux/practice/actions/practice_consultant';

class ConsultantEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.yesNoOptions=[
            {value: true, label: 'Yes'},
            {value: false, label: 'No'},
        ];
    }

    componentWillMount() {
        if(this.props.match.params.practitioner_id){
            this.props.getConsultant({id: this.props.match.params.practitioner_id});
        }
    }

    doSave(data){
        let params = { ...data };
        this.props.postConsultant({...params}).then(()=>{
            history.goBack();
        });
    }

    render() {
        if(_.isEmpty(this.props.practitioner)){
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getConsultant,
        postConsultant,
        cleanModule: actionCreator.cleanModule
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        practitioner: state.practice.practiceConsultants.consultant,
        initialValues: state.practice.practiceConsultants.consultant,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'title',
        'bio',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ConsultantEditForm = reduxForm(
    {
        form: 'ConsultantEditForm',
        enableReinitialize: true,
        validate
    }
)(ConsultantEdit);

const ConsultantEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsultantEditForm);

export default ConsultantEditConnected;
