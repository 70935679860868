import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    function repeatGoal1(goal, goalIndex) {
        return React.createElement(Tr, { 'key': 'goal' + goalIndex }, React.createElement(Td, {
            'column': 'name',
            'value': goal.text
        }, React.createElement('span', {}, goal.text)), React.createElement(Td, {
            'column': 'author',
            'value': goal.practice.name,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, '\n                    ', goal.practice.name, '\n                ')), React.createElement(Td, {
            'column': 'is_publish',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, goal.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '3130' }, goal.is_publish ? React.createElement('span', { 'key': '3622' }, 'Published') : null, !goal.is_publish ? React.createElement('span', { 'key': '3705' }, 'Not Published') : null) : null, goal.practice.id !== this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '3804' }, this.props.hidden_goals.indexOf(goal.id) === -1 ? React.createElement('span', { 'key': '4355' }, 'Published') : null, this.props.hidden_goals.indexOf(goal.id) > -1 ? React.createElement('span', { 'key': '4470' }, 'Hidden') : null) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container' }, goal.is_owner ? [
            ,
            React.createElement(ButtonDefault, {
                'raised': true,
                'ripple': true,
                'primary': true,
                'tooltip': 'Edit goal template',
                'onClick': () => {
                    this.editGoal(goal);
                },
                'className': 'm-r-12 goal-template-action',
                'key': '48513'
            }, 'Edit'),
            React.createElement(ButtonDefault, {
                'raised': true,
                'ripple': true,
                'tooltip': 'Delete goal template',
                'onClick': () => {
                    this.deleteGoal(goal.id);
                },
                'className': 'goal-template-action delete-button',
                'key': '48515'
            }, 'Delete')
        ] : null, !goal.is_owner ? [
            ,
            React.createElement(ButtonDefault, {
                'raised': true,
                'ripple': true,
                'primary': true,
                'tooltip': 'Preview goal template',
                'onClick': () => {
                    this.editGoal(goal);
                },
                'className': 'm-r-12 goal-template-action',
                'key': '64623'
            }, 'Preview'),
            this.props.hidden_goals.indexOf(goal.id) === -1 ? React.createElement(ButtonDefault, {
                'raised': true,
                'ripple': true,
                'tooltip': 'Hide goal template',
                'onClick': () => {
                    this.changeVisibilityStatus(goal, 'hide');
                },
                'className': 'goal-template-action secondary-button',
                'key': '64625'
            }, 'Hide') : null,
            this.props.hidden_goals.indexOf(goal.id) > -1 ? React.createElement(ButtonDefault, {
                'raised': true,
                'ripple': true,
                'tooltip': 'Show goal template',
                'onClick': () => {
                    this.changeVisibilityStatus(goal, 'show');
                },
                'className': 'goal-template-action',
                'key': '64627'
            }, 'Show') : null
        ] : null)));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement('p', {
        'className': 'alert-warning',
        'style': { marginTop: '65px' }
    }, 'Published goals can be selected by your clients'), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable m-t-15',
            'id': 'table',
            'noDataText': 'Create your own goal by clicking on + icon',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Author')), React.createElement(Th, {
            'column': 'is_publish',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Published')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.goals, repeatGoal1.bind(this))
    ]), React.createElement(Button, {
        'className': 'floating-add-button m-r-5',
        'style': { top: '100px' },
        'icon': 'add',
        'floating': true,
        'mini': true,
        'ripple': true,
        'onClick': this.addGoal
    }));
}