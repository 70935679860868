import * as React from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-toolbox/lib/date_picker';
import Chip from 'react-toolbox/lib/chip';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import HelpTip from '../../../utils/common_components/help_tip';
import ClientEngagementTreatmentItem from '../client_engagement_treatment_item';
import { Dialog } from 'react-toolbox/lib/dialog';
import TimePicker from 'react-toolbox/lib/time_picker';
export default function () {
    function repeatProgressionDate1(progressionDate, progressionDateIndex) {
        return React.createElement('div', {
            'onClick': () => {
                this.showPreview(progressionDate);
            },
            'className': 'panel col-xs-12 p-0' + ' ' + _.transform({ 'pointer': this.props.selectedRole.role.name === 'client' }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'key': 'progression_date' + progressionDate
        }, React.createElement('div', { 'className': `panel-body  vertical-align p-l-5 p-r-5 p-t-10 p-b-10    ${ progressionDate === this.state.today_string ? 'bg-accent' : '' }     ` }, React.createElement('div', {
            'className': 'text-center',
            'style': { width: '100px' }
        }, React.createElement('div', {
            'className': ` p-5 vertical-align box-shadow-inset   ${ progressionDate === this.state.today_string ? 'bg-success' : 'bg-primary' }         `,
            'style': {
                height: '75px',
                width: '75px',
                borderRadius: '50%',
                margin: '0 auto'
            }
        }, '\n                        ', moment(progressionDate).format('MMMM Do YYYY'), '\n                    ')), React.createElement(ClientEngagementTreatmentItem, {
            'selectedRole': this.props.selectedRole,
            'pageType': 'engagement',
            'engagement': this.props.engagement,
            'treatment_date': progressionDate,
            'workouts': this.props.date_workouts[progressionDate] ? this.props.date_workouts[progressionDate] : [],
            'ongoing_workouts': this.props.ongoing_workouts[progressionDate] ? this.props.ongoing_workouts[progressionDate] : [],
            'habits': this.props.date_habits[progressionDate] ? this.props.date_habits[progressionDate] : []
        })));
    }
    return React.createElement('div', {}, React.createElement('div', { 'className': 'vertical-align' }, React.createElement('h4', {
        'className': 'm-b-0 flex-auto',
        'style': { width: 'auto' }
    }, 'Workouts & Habits tracking')), React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement('div', { 'className': 'col-xs-12  p-0' }, React.createElement('div', { 'className': 'vertical-align p-l-10 p-r-10 p-t-0 p-b-0' }, React.createElement('i', { 'className': 'material-icons m-r-10' }, 'date_range'), React.createElement('h5', {
            'className': 'm-b-0 di-block',
            'style': { width: 'auto' }
        }, '\n                    Show from \n                    ', React.createElement(HelpTip, {}, '\n                        Workouts and Habits will be displayed from this selected date. Change date to display older workouts\n                    '), '\n                     :  \n                '), React.createElement('div', { 'className': 'flex-auto m-l-10' }, React.createElement(DatePicker, {
            'value': this.state.selectedDate,
            'onChange': this.changeSelectedDate
        })))),
        _.isEmpty(this.props.all_dates) ? React.createElement('div', {
            'className': 'col-xs-12 m-20 text-center',
            'key': '2007'
        }, React.createElement('div', { 'className': 'col-xs-12 alert-warning' }, 'Workouts and Habits to be tracked have not been assigned from the selected date.')) : null,
        !_.isEmpty(this.props.all_dates) ? _.map(this.props.all_dates, repeatProgressionDate1.bind(this)) : null
    ]), React.createElement(Dialog, {
        'active': this.state.openWorkoutTimingModal,
        'title': 'Workout Timings',
        'onEscKeyDown': this.hideWorkoutTimingModal,
        'onOverlayClick': this.hideWorkoutTimingModal
    }, this.state.openWorkoutTimingModal ? React.createElement('div', {
        'className': 'row vertical-align',
        'key': '4091'
    }, React.createElement('label', { 'className': 'medium-label col-xs-12' }, '\n      Setup your preferred workout time\n      ', React.createElement(HelpTip, {}, '\n         Setting the workout timings helps in creating calendar entries and reminders\n     ')), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(TimePicker, {
        'hint': 'Click to enter time',
        'value': this.state.workout_timings,
        'onChange': this.changeWorkoutTimings,
        'format': 'ampm',
        'readOnly': true
    })), React.createElement('div', { 'className': 'col-xs-12 m-t-25 end-xs' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'bg-success',
        'raised': true,
        'onClick': this.saveWorkoutTimings
    }), React.createElement(Button, {
        'label': 'Close',
        'className': 'bg-accent m-l-10',
        'raised': true,
        'onClick': this.hideWorkoutTimingModal
    }))) : null));
}