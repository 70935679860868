import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    function repeatItem1(item, index) {
        return React.createElement('div', {
            'className': 'row p-0',
            'key': 'bigData' + index
        }, React.createElement('div', { 'className': 'col-xs-12 bg-accent-dark f-16 f-400 p-10 c-white' }, '\n                                ', item.name, '\n                            '), React.createElement('div', { 'className': 'col-xs-12 m-t-3' }, React.createElement('div', { 'className': 'row middle-xs' }, React.createElement('div', {
            'className': 'col center-xs m-l-5',
            'style': { width: '259px' }
        }, this.state.selectedAnalysisType.value === 'mostability' || this.state.selectedAnalysisType.value === 'mobility' ? React.createElement('img', {
            'onClick': () => {
                this.selectVideo(item.mobility, item.name + ' Mobility');
            },
            'src': item.mobility_thumbnail,
            'style': {
                cursor: 'pointer',
                width: '128px',
                height: '80px'
            },
            'key': '3265'
        }) : null, this.state.selectedAnalysisType.value === 'mostability' || this.state.selectedAnalysisType.value === 'stability' ? React.createElement('img', {
            'onClick': () => {
                this.selectVideo(item.stability, item.name + ' Stability');
            },
            'src': item.stability_thumbnail,
            'className': 'm-l-3',
            'style': {
                cursor: 'pointer',
                width: '128px',
                height: '80px'
            },
            'key': '3671'
        }) : null), React.createElement('div', { 'className': 'col-xs-5 m-l-20 m-r-20' }, React.createElement('div', { 'className': 'row between-xs' }, React.createElement('div', { 'className': 'col' }, React.createElement('div', { 'className': 'row center-xs' }, '\n                                                    R\n                                                '), React.createElement('div', {
            'onClick': () => {
                this.onItemClicked(item.name, 'r');
            },
            'className': 'row center-xs middle-xs m-t-5 f-40',
            'style': {
                userSelect: 'none',
                cursor: 'pointer',
                height: '48px',
                width: '64px',
                border: '1px solid',
                borderRadius: '2px'
            }
        }, '\n                                                    ', this.getCurrentSelection(item.name, 'r'), '\n                                                ')), React.createElement('div', { 'className': 'col' }, React.createElement('div', { 'className': 'row center-xs' }, '\n                                                    C\n                                                '), React.createElement('div', {
            'onClick': () => {
                this.onItemClicked(item.name, 'c');
            },
            'className': 'row center-xs middle-xs m-t-5 f-40',
            'style': {
                userSelect: 'none',
                cursor: 'pointer',
                height: '48px',
                width: '64px',
                border: '1px solid',
                borderRadius: '2px'
            }
        }, '\n                                                    ', this.getCurrentSelection(item.name, 'c'), '\n                                                ')), React.createElement('div', { 'className': 'col' }, React.createElement('div', { 'className': 'row center-xs' }, '\n                                                    L\n                                                '), React.createElement('div', {
            'onClick': () => {
                this.onItemClicked(item.name, 'l');
            },
            'className': 'row center-xs middle-xs m-t-5 f-40',
            'style': {
                userSelect: 'none',
                cursor: 'pointer',
                height: '48px',
                width: '64px',
                border: '1px solid',
                borderRadius: '2px'
            }
        }, '\n                                                    ', this.getCurrentSelection(item.name, 'l'), '\n                                                ')))))));
    }
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12 m-20' }, React.createElement(Card, {}, React.createElement(CardTitle, {
        'className': 'text-left',
        'title': '3DMAPS Analysis',
        'subtitle': this.props.client.name + ' (' + this.props.client.email + ')'
    }), React.createElement(CardText, { 'className': 'p-0' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6 p-0' }, React.createElement('div', { 'className': 'row center-xs p-l-5 p-r-5' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-player',
        'videoList': [this.state.selectedVideoGuid]
    }))), React.createElement('div', { 'className': 'row center-xs p-10 f-500 f-16 c-accent' }, '\n                            ', this.state.selectedVideoName, '\n                        '), React.createElement('div', { 'className': 'm-t-10 m-l-10 m-r-10' }, React.createElement(Select, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'metric',
        'label': 'Analysis Type',
        'options': this.state.analysisTypes,
        'value': this.state.selectedAnalysisType,
        'clearable': false,
        'onChange': val => {
            this.onAnalysisTypeSelected(val);
        }
    })), React.createElement('div', { 'className': 'row m-l-10 m-r-10' }, React.createElement(Input, {
        'placeholder': 'Notes',
        'className': 'w-100',
        'type': 'text',
        'multiline': true,
        'value': this.state.notes,
        'onChange': val => {
            this.updateNotes(val);
        }
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-6' },
        _.map(this.state.bigData, repeatItem1.bind(this))
    ]))), React.createElement(CardActions, { 'className': 'center-xs m-20' }, React.createElement(Button, {
        'label': 'Generate RSC and select Performance System',
        'className': 'bg-success',
        'onClick': () => {
            this.doSave();
        },
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': () => {
            this.doCancel();
        },
        'type': 'button',
        'raised': true
    })))));
}