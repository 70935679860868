import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import moment from 'moment';
import { getClientEngagementInstance } from '../../redux/engagement/network/client_engagement_network.js';
import { getClientGroup } from '../../redux/group/network/client_group_network.js';
import EngagementTemplate from './templates/client_goals_engagement.rt';
import GroupTemplate from './templates/client_goals_group.rt';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import ProgressBar from 'react-toolbox/lib/progress_bar';

class ClientGoals extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);        
        this.loadPage = this.loadPage.bind(this); 
        this.hideGoalModal = this.hideGoalModal.bind(this);
        this.showGoalModal = this.showGoalModal.bind(this);   
        this.showGoalTrackingModal = this.showGoalTrackingModal.bind(this);
        this.hideGoalTrackingModal = this.hideGoalTrackingModal.bind(this);     
        this.state={                        
            type:'',
            id:0,
            openGoalModal: false,
            openGoalTrackingModal:false,
            selectedTrackingData:null,
        };        
    };

    hideGoalModal() {
        this.setState({ openGoalModal: false,selectedTrackingData:null });
    }
    

    showGoalModal(tracking_date) {
        this.setState({ openGoalModal: true,selectedTrackingData:tracking_date });
    }

    hideGoalTrackingModal() {
        this.setState({ openGoalTrackingModal: false,selectedTrackingData:null });
    }
    
    showGoalTrackingModal(tracking_date) {
        this.setState({ openGoalTrackingModal: true,selectedTrackingData:tracking_date });
    }    

        
    componentWillMount() {
        this.props.updateMenuPinned(false);
        this.props.updateMenuOpen(false);    
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && _.has(this.props.match.params, 'type') && _.has(this.props.match.params, 'id')) {
            this.setState({
                "type":this.props.match.params.type,
                "id":this.props.match.params.id
            }, () => {
                this.loadPage();
            });
        }
    }
    
    loadPage() {  
        if(this.state.type === 'engagement') {
            let _params = {};
            _params["id"] = this.state.id;
            this.props.getClientEngagementInstance(_params);
        } else {
            let _params = {};
            _params["id"] = this.state.id;
            this.props.getClientGroup(_params);
        }
    }

    
    componentWillUnmount() {
    }

    render() {
        if(this.state.type === 'engagement') {
            if(!_.isEmpty(this.props.engagement)) {
                return EngagementTemplate.apply(this);
            }else {
                return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
                </div>);
            }
        } else {
            if(!_.isEmpty(this.props.group)) {
                return GroupTemplate.apply(this);
            }else {
                return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
                </div>);
            }
        }
    }

}

function mapStateToProps(state) {  
    let group = state.group.clientGroupReducer.group;
    let group_needs_goal_tracking_target_update = false;
    if(!_.isEmpty(group)){
        let goal = group.goal;
        if(goal && goal.client_goal_questions.length > 0 ){
            _.forEach(goal.client_goal_questions,(item)=>{
                if(item.initial_value === null || item.target_value === null){
                    group_needs_goal_tracking_target_update = true;
                    return false;
                }
            });
        }
    }
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        engagement: state.engagement.clientEngagementReducer.engagement,
        group: state.group.clientGroupReducer.group,
        group_needs_goal_tracking_target_update: group_needs_goal_tracking_target_update,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({        
        getClientEngagementInstance,
        getClientGroup,
        updateMenuOpen:MenuAction.updateMenuOpen,
        updateMenuPinned: MenuAction.updateMenuPinned,
        }, 
        dispatch
    );
}

const ClientGoalsConnected = connect(mapStateToProps,mapDispatchToProps)(ClientGoals);
export default ClientGoalsConnected;
