import * as React from 'react';
import * as _ from 'lodash';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import { Panel } from 'react-toolbox';
import Input from 'react-toolbox/lib/input';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import EducationVideo from '../../../client_app/components/welcome_video';
import moment from 'moment';
export default function () {
    function repeatVideo1(video, index) {
        return React.createElement('div', {
            'className': 'width-250 how-to-card box-shadow bg-white m-l-8 m-r-8 m-b-18',
            'key': 'video' + index
        }, React.createElement('div', {}, React.createElement('div', {
            'className': 'thumbnail box-250-150 p-relative',
            'style': { backgroundImage: 'url(' + encodeURI(video.thumbnail) + ')' }
        }, React.createElement('div', { 'className': 'play-btn p-absolute' }))), React.createElement('div', { 'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5' }, React.createElement(TooltipWrapper, {
            'className': 'text-left col-xs-12 f-16 truncate',
            'tooltip': video.name
        }, '\n                        ', video.name, '\n                    ')), React.createElement('div', { 'className': 'row center-xs m-0' }, video.deleted_date === null ? [React.createElement('div', {
                'className': 'col-xs-12 p-t-10 p-b-10 m-0',
                'key': '41881'
            }, React.createElement(IconButton, {
                'tooltip': 'View Content',
                'icon': 'play',
                'className': 'm-l-20 m-r-8 c-grey small-icon-button',
                'onClick': () => {
                    this.viewEducationContent(video);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Edit How-To Video',
                'icon': 'edit',
                'className': 'm-r-5 c-grey small-icon-button',
                'onClick': () => {
                    this.editVideo(video.id);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Delete Content',
                'icon': 'delete',
                'className': 'c-red m-r-5 small-icon-button',
                'onClick': () => {
                    this.deleteEducationContent(video);
                }
            }))] : null));
    }
    return React.createElement('div', {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'm-t-30 page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Marketing'), React.createElement('h2', { 'className': 'page-title with-subtitle' }, 'How-to Videos')), React.createElement('div', { 'className': 'w-70 d-flex flex-end' }, React.createElement(Button, {
        'className': 'width-fit p-l-46 p-r-46 bg-success',
        'onClick': this.addVideo,
        'floating': true
    }, 'Add New'))), this.props.education_content.length === 0 ? [React.createElement('div', {
            'className': 'row center-xs alert-warning m-20',
            'key': '28621'
        }, '\n                There are no education content videos\n            ')] : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-t-20 m-r-5' },
        _.map(this.props.education_content, repeatVideo1.bind(this))
    ]), this.props.paginate_info.total_pages && this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '5279'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more ...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.loadEducationContentData(true);
        }
    }))) : null, this.state.show_education_video ? React.createElement(EducationVideo, {
        'title': this.state.education_video.title,
        'onCloseHandle': this.closeEducationContent,
        'welcome_video': this.state.education_video,
        'key': '5728'
    }) : null));
}