import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_details.rt";
import { reduxForm } from 'redux-form';

import store, {history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {practiceDetailsGet} from '../../redux/practice/network/practice_details';
import {
    practiceDescriptionMediaGet
} from '../../redux/practice/network/practice_description';
import actionCreator from '../../redux/practice/actions/practice_details';
import descriptionAction from '../../redux/practice/actions/practice_description';
import Utils from '../../utils/utils';
import { connectGuestToPractice } from '../../redux/engagement/network/client_engagement_network';
import {verifyToken} from '../../redux/auth/network/auth_network';

class PracticeDetails extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.getBackgroundColor = this.getBackgroundColor.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.showGoalSelectionDialog = this.showGoalSelectionDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.hideGoalSelectionDialog = this.hideGoalSelectionDialog.bind(this);
        this.doConnect = this.doConnect.bind(this);
        this.state = {
            dialog_body :"",
            dialog_title :"",
            show_dialog :false,
            show_goal_selection :false,
            selected_practitioner_id:null
        };

    }

    componentWillMount() {
        if(!this.props.match.params.practice_id){
              history.goBack();  
        }
        this.props.practiceDetailsGet({id:this.props.match.params.practice_id});
        this.props.practiceDescriptionMediaGet({practice_id:this.props.match.params.practice_id});
    }
    
    showGoalSelectionDialog(practitioner_id) {
        this.setState({show_goal_selection:true,selected_practitioner_id:practitioner_id});
    }
    showDialog(title,body){
        this.setState({dialog_body:body,dialog_title:title,show_dialog:true});
    }

    hideGoalSelectionDialog(){
        this.setState({show_goal_selection:false,selected_practitioner_id:null});
    }
    hideDialog(){
        this.setState({dialog_body:",dialog_title:",show_dialog:false});
    }

    doConnect() {
        let params = {};
        params.practice_id = this.props.match.params.practice_id;        
        this.props.connectGuestToPractice(params).then((response) => {
            this.props.verifyToken({
                package_version: 2
            }).then(()=>{
                this.props.handleClose();
                localStorage.setItem("selectedRole","client");
                history.push("/");
            }).catch(() => {
                history.replace("/");
                window.location.reload();
                this.props.handleClose();
             });

        }).catch(() => {  });
    }


    render() {
        if(_.isEmpty(this.props.practice) || _.isEmpty(this.props.description)){
            return (<div className="row center-xs m-t-25">
            <ProgressBar  type='circular' mode='indeterminate' multicolor />    
        </div>);
        }    
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanup1();
        this.props.cleanup2();
    }

    getBackgroundColor(name){
        return Utils.getBackgroundColor(name);
    }
    
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        connectGuestToPractice,
        practiceDetailsGet,
        practiceDescriptionMediaGet,
        verifyToken,
        cleanup1:actionCreator.cleanModule,
        cleanup2:descriptionAction.cleanModule
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authReducer.user,
        practice: state.practice.practiceDetails.practice,
        description:state.practice.practiceDescription.practice
    };
}



const PracticeDetailsConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(PracticeDetails);

export default PracticeDetailsConnected;
