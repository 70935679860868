import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import Button from '../../../../utils/common_components/tooltipButton';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
import { Table } from '../../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatNotificationTemplate1(notificationTemplate, notificationTemplateIndex) {
        return React.createElement(Tr, { 'key': 'notificationTemplate' + notificationTemplateIndex }, React.createElement(Td, {
            'column': 'notification_text',
            'value': notificationTemplate.notification_text
        }, React.createElement('div', {}, '\n                        ', notificationTemplate.notification_text, '\n                    ')), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'tooltip': 'Edit',
            'icon': 'create',
            'onClick': () => {
                this.editNotificationTemplate(notificationTemplate);
            },
            'className': 'micro',
            'floating': true,
            'primary': true,
            'mini': true
        }))));
    }
    return React.createElement('div', { 'className': 'row w-100 m-20' }, React.createElement('div', { 'className': 'col-xs-12 m-t-30 p-r-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'No notification templates have been added yet.',
            'sortable': ['notification_text'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'notification_text' }, React.createElement('strong', {}, 'Text')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.notificationTemplates, repeatNotificationTemplate1.bind(this))
    ])), React.createElement(Button, {
        'tooltip': 'Create new template',
        'className': 'floating-add-button m-r-8',
        'style': { top: '90px' },
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true,
        'onClick': () => {
            this.addNotificationTemplate();
        }
    }));
}