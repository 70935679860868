let initialState = {
    displaySignupForm: true,
};
export default function auth(state = initialState, action) {
    switch (action.type) {
        case "AUTH_SIGNUP_COMPLETE":  
            return Object.assign({}, state, {
                displaySignupForm: false
            });
        default:
            return state;
    }
}
