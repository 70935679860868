// Action Creators
const matFormFill = (mat_form) => ({
    type: 'MAT_FILL',
    mat_form:mat_form
});
const matAssessmentFill = (mat_assessment) => ({
    type: 'MAT_ASSESSMENT_FILL',
    mat_assessment
});
const matCleanUp = () => ({
    type: 'MAT_CLEANUP',
});

export default {
    matFormFill,
    matAssessmentFill,
    matCleanUp
};
