let initialState = {
    challenge_list: [],    
    challenge: {},
    supporting_data: {},
    challenge_workout: {},
    challenge_workout_supporting_data: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "CHALLENGE_LIST_FILL":
            if (!action.challenge_list){
                return state;
            }
            return Object.assign({}, state, {
                challenge_list: action.challenge_list,
            });
        case "CHALLENGE_FILL":
            if (!action.challenge){
                return state;
            }
            return Object.assign({}, state, {
                challenge: action.challenge,
                supporting_data: action.supporting_data
            });
        case "CHALLENGE_WORKOUT_FILL":
            if (!action.challenge_workout){
                return state;
            }
            return Object.assign({}, state, {
                challenge_workout: action.challenge_workout,
                challenge_workout_supporting_data: action.supporting_data
            });
        case "CHALLENGE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};