import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_description";
import { reduxForm, arrayPush, arrayRemove, formValueSelector } from 'redux-form';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    practiceDescriptionMediaGet, practiceDescriptionPost, practiceMediaDelete, practiceMediaPost
} from '../../redux/practice/network/practice_description';
import actions from '../../redux/practice/actions/practice_description';
import React from 'react';
import Swal from 'sweetalert2';
import Utils from '../../utils/utils';
import { uploadFiles } from '../../redux/core/network/file_upload';

class PracticeDescription extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveDescription = this.saveDescription.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.previewMedia = this.previewMedia.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.postPracticeMedia = this.postPracticeMedia.bind(this);
        this.state = {
            showMediaPreview: false,
            imageIndex: 0,
        };
    }

    componentWillMount() {
        this.props.practiceDescriptionMediaGet({});
    }

    onVideoUploadCompleted(result) {
        this.postPracticeMedia({
            ...result, media_type: 2
        });
    }

    onVideoUploadFailed(error) {
        Utils.showErrorAlert("Uploaded Failed. Please try again.");
    }

    closePreview() {
        this.setState({
            showMediaPreview: false,
        });
    }

    previewMedia(media) {
        this.setState({
            showMediaPreview: true,
            imageIndex: this.props.images.indexOf(media),
        });
    }

    deleteMedia(index) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete this media?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            that.props.practiceMediaDelete({ id: this.props.media_items[index].id }).then(() => {
                store.dispatch(arrayRemove(this.props.form, 'media_items', index));
            }).catch(() => { });
        }, ()=> { });
    }

    uploadImage(files) {
        let formData = new FormData();
        let ref = this;
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp)=>{
            let result = resp.upload_results[0];
            ref.postPracticeMedia({ ...result, media_type: 1, thumbnail: result.thumbnail_guid});
        }).catch(()=>{ });
    }
    
    postPracticeMedia(media_item) {
        this.props.practiceMediaPost({
            ...media_item,
        }).then((response) => {
            store.dispatch(arrayPush(this.props.form, 'media_items', {
                ...response.media_item
            }));
        }).catch(() => { });
    }

    saveDescription(data) {
        this.props.practiceDescriptionPost({ description: data.description }).catch(() => { });
    }

    render() {
        if (_.isEmpty(this.props.practice)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
    }

}

const selector = formValueSelector('PracticeDescriptionForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        practiceDescriptionMediaGet,
        practiceDescriptionPost,
        practiceMediaDelete,
        practiceMediaPost,
        uploadFiles,
        clean: actions.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let practice = state.practice.practiceDescription.practice;
    return {
        practice: practice,
        initialValues: practice,
        images: _.filter(practice.media_items, (media) => {
            return media.media_type === 1;
        }),
        media_items: selector(state, 'media_items'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    return {};
};

const PracticeDescriptionForm = reduxForm({
    form: 'PracticeDescriptionForm', enableReinitialize: true, validate
})(PracticeDescription);

const PracticeDescriptionConnected = connect(mapStateToProps, mapDispatchToProps)(PracticeDescriptionForm);
export default PracticeDescriptionConnected;
