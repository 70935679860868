import * as React from 'react';
import * as _ from 'lodash';
import { Layout } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import { SearchInput as Input } from '../../../utils/common_components/textInput_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import MoveVideo from '../../../client_app/components/welcome_video';
import Chip from 'react-toolbox/lib/chip';
import { CSVLink } from 'react-csv';
export default function () {
    function repeatVideo1(video, index) {
        return React.createElement('div', {
            'className': 'width-260 box-shadow bg-white m-l-10 m-r-6 m-t-18 ribbon-container',
            'key': 'move_video' + index
        }, !video.is_published ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '5079'
        }, '\n                    Draft\n                ') : null, video.is_published ? React.createElement('div', {
            'className': 'bg-published ribbon',
            'key': '5204'
        }, '\n                    Published\n                ') : null, React.createElement('div', {
            'className': 'thumbnail box-260-150',
            'style': { backgroundImage: 'url(' + encodeURI(video.thumbnail_url) + ')' }
        }), React.createElement('div', { 'className': 'row bg-white m-0 m-t-8 p-l-5' }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-12 f-16 truncate',
            'tooltip': video.name
        }, '\n                        ', video.name, '\n                    ')), React.createElement('div', { 'className': 'view-btn w-100 p-b-5 d-flex' }, React.createElement('div', {
            'tooltip': 'Views',
            'className': 'w-100 c-grey small-icon-button text-center'
        }, React.createElement('span', { 'className': 'views-icon' }), React.createElement('span', { 'className': 'p-l-5' }, video.client_count, ' views'))), React.createElement('div', { 'className': 'row center-xs m-0 p-b-7' }, video.deleted_date === null ? [React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '60711'
            }, React.createElement(IconButton, {
                'icon': 'edit',
                'tooltip': 'Edit Workout',
                'className': 'm-r-23 c-grey small-icon-button',
                'onClick': () => {
                    this.editMoveVideo(video.id);
                }
            }), React.createElement(IconButton, {
                'icon': 'deactivate',
                'tooltip': video.is_published ? 'Unpublish Workout' : 'Publish Workout',
                'className': video.is_published ? 'm-r-23 c-grey small-icon-button' : 'm-r-23 c-red small-icon-button',
                'onClick': () => {
                    this.unpublishWorkout(video);
                }
            }), React.createElement(IconButton, {
                'icon': 'delete',
                'tooltip': 'Delete Workout',
                'className': 'm-r-23 c-red small-icon-button',
                'onClick': () => {
                    this.deleteMoveVideo(video.id);
                }
            }))] : null));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel homefit-workout' }, React.createElement('div', { 'className': 'program-search-box row vertical-align flex-sb start-xs m-10 m-t-20' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'f-14 page-subtitle' }, 'HomeFit'), React.createElement('h2', { 'className': 'm-0 page-title' }, 'Workouts')), React.createElement('div', { 'className': 'w-50 row m-10 d-flex flex-end' }, React.createElement(Input, {
        'label': 'Search workouts by name',
        'style': {
            width: '800px',
            marginBottom: '50px',
            height: '40px'
        },
        'icon': 'search',
        'onChange': this.onSearch,
        'onClick': this.inputClick,
        'type': 'text',
        'name': 'search'
    }), React.createElement('div', { 'className': 'w-100 d-flex flex-end align-center' }, React.createElement('div', { 'className': 'w-48 m-r-16' }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'trainer',
        'component': Select,
        'label': 'Coach',
        'options': this.props.authors,
        'valueKey': 'id',
        'labelKey': 'practice_name',
        'onChange': this.selectTrainer
    })), React.createElement(Button, {
        'className': 'bg-primary m-r-10 text-capital',
        'onClick': this.generateReport,
        'label': 'Generate Report'
    })), React.createElement(Button, {
        'className': 'add-btn p-fixed bg-success m-r-10',
        'style': { zIndex: '50' },
        'onClick': this.add,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    }))), this.props.typeLCase === 'quickstart' ? React.createElement('div', {
        'className': 'col-xs-12 end-xs',
        'key': '4037'
    }, React.createElement(SelectComponent, {
        'className': 'col-xs-4',
        'value': this.state.quickstart_type,
        'options': this.state.quickstartSelectionList,
        'simpleValue': true,
        'onChange': val => {
            this.onChangeQuickstartSelectionList(val);
        }
    }), React.createElement(Button, {
        'className': 'bg-success m-r-10',
        'onClick': this.add,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    })) : null, this.props.move_videos.length === 0 ? [React.createElement('div', {
            'className': 'row center-xs alert-warning m-20',
            'key': '46261'
        }, '\n                There are no DHF HomeFit Workouts\n            ')] : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-10' },
        _.map(this.props.move_videos, repeatVideo1.bind(this))
    ]), this.props.paginate_info.total_pages && this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '7250'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more workouts...',
        'className': 'height-fit bg-success-border text-capital c-black',
        'onClick': () => {
            this.loadMore();
        }
    }))) : null, this.state.show_move_video ? React.createElement(MoveVideo, {
        'title': this.state.move_video.title,
        'onCloseHandle': this.closeMoveVideo,
        'welcome_video': this.state.move_video,
        'key': '7718'
    }) : null, React.createElement(Dialog, {
        'active': this.state.showReport,
        'className': 'full-dialog bg-beige',
        'style': { minHeight: '100px !important' },
        'onEscKeyDown': this.hideReport,
        'onOverlayClick': this.hideReport
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-20px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.hideReport
    })), React.createElement('div', {}, React.createElement(CSVLink, { 'data': this.state.rows }, 'Download the Report')))));
}