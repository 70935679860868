import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/view_message.rt";
import { viewMessage } from '../../redux/message/network/message_network';

class ViewMessage extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
    };
    
    componentWillMount() {
        this.props.viewMessage({
            message_id: this.props.message.id
        });
    }

    componentWillUnmount() {
    }


    render() {
        return Template.apply(this);
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        viewMessage,
    }, dispatch),
    dispatch
});

const ViewMessageConnected = connect(mapStateToProps,mapDispatchToProps,)(ViewMessage);
export default ViewMessageConnected;
