import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/group_habit_edit";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector } from 'redux-form';
import { history } from '../../redux/store';
import { getHabit, postHabit } from '../../redux/group/network/group_habit';
import actions from '../../redux/group/actions/group_habit';
import Swal from 'sweetalert2';

class GroupHabitEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.save = this.save.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.state = {
            publish: false,
        };
    };

    componentWillMount() {
        this.props.getHabit({
            id: this.props.habitId,
            group_id: this.props.group.id,
            package_version: 2
        });
    }

    componentWillReceiveProps (nextProps) {
        if (this.props.schedule_type && nextProps.schedule_type  && this.props.schedule_type !== nextProps.schedule_type  ) {
            if (nextProps.schedule_type === 4 && nextProps.duration_type === 1) {
                nextProps.change("duration_type", 2);
            }else if (nextProps.schedule_type === 5 && (nextProps.duration_type === 1 || nextProps.duration_type === 2)) {
                nextProps.change("duration_type", 3);
            }
        }
    }
    
    saveAsDraft() {
        this.setState({publish:false},()=>{
            this.props.handleSubmit(this.save)();
       });
    }

    saveAndPublish() {
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.save)();
        });
    }

    save(data) {
        let params = Utils.flatternFormData(data);
        params.group_id = this.props.group.id;
        params.is_published = this.state.publish;
        params.start_date = Utils.formatServerDate(params.start_date);
        params.package_version = 2
        if(this.props.habitId) {
            params['id'] = this.props.habitId;
        }
        this.props.postHabit(params).then(() => {
            this.props.finishEditing();
        }).catch(() => {
        });
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

    deleteVideo() {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete the video?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.change('video_guid', null);
            that.props.change('thumbnail', null);         
        }, function (dismiss) {
                        
        });
    }

    onVideoUploadCompleted(result) {
        this.props.change('video_guid', result.guid);
        this.props.change('thumbnail', result.thumbnail);
    }

    onVideoUploadFailed(error) {
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    render() {
        if (_.isEmpty(this.props.habit)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('GroupHabitEditForm');
function mapStateToProps(state) {
    let habit = { ...state.group.groupHabit.habit };
    if (!_.isEmpty(habit)) {
        habit.start_date = Utils.parseServerDate(habit.start_date);
    }
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.authReducer.selectedRole,
        habit: habit,
        initialValues: habit,
        supporting_data: state.group.groupHabit.supportingData,
        video_guid: selector(state, 'video_guid'),
        schedule_type: selector(state, 'schedule_type'),
        duration_type: selector(state, 'duration_type'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getHabit,
        postHabit,
        cleanModule: actions.cleanModule,
    }, dispatch);
}

const validate = (values, props) => {
    let requiredFields = [
        'name','text','schedule_type','duration_type','duration_count','start_date'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
        if (field === "schedule_type") {
            if (values[field] === 4) {
                if (values["schedule_week_day"] === undefined || values["schedule_week_day"] === null) {
                    errors["schedule_week_day"] = 'Required';
                } 
                if (values["duration_type"] === 1) {
                    errors["duration_type"] = 'Duration type cannot be in days when selected schedule type is once a week';
                }
            } else if (values[field] === 5) {
                if (values["schedule_month_date"] === undefined || values["schedule_month_date"] === null) {
                    errors["schedule_month_date"] = 'Required';
                } else if (values["schedule_month_date"] > 31) {
                    errors["schedule_month_date"] = 'Day of month must be less than or equal to 31';
                }
                if (values["duration_type"] === 1 || values["duration_type"] === 2) {
                    errors["duration_type"] = 'Duration type cannot be in days or weeks when selected schedule type is once a month';
                }
            }
        }
    });
    return errors;
};


const GroupHabitEditForm = reduxForm(
    {
        form: 'GroupHabitEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupHabitEdit);
const GroupHabitEditConnected = connect(mapStateToProps, mapDispatchToProps)(GroupHabitEditForm);
export default GroupHabitEditConnected;
