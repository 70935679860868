import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Tab } from 'react-toolbox';
import { Tabs } from 'react-toolbox';
import { Calendar as BigCalendar } from 'react-big-calendar';
import { IconButton } from 'react-toolbox';
export default function () {
    return React.createElement('div', { 'className': 'm-t-15' }, React.createElement(Card, { 'className': 'w-100' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('div', { 'className': 'col-xs-12 m-30' }, React.createElement(BigCalendar, {
        'className': 'full-height',
        'localizer': this.props.localizer,
        'selectable': true,
        'popup': true,
        'defaultView': 'week',
        'views': [
            'month',
            'week',
            'day',
            'agenda'
        ],
        'events': this.props.eventList,
        'eventPropGetter': event => {
            return { style: { backgroundColor: '#f1c40f' } };
        }
    })))));
}