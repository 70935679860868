import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Chip from 'react-toolbox/lib/chip';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import Dialog from 'react-toolbox/lib/dialog';
export default function () {
    function repeatAttachment1(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument2(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, {
            'column': 'attachments',
            'style': { width: '250px' }
        }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment1.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'author',
            'value': document.practice.name,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, document.practice.name)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container' }, !this.props.selectionMode && document.practice.id === this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'primary': true,
            'onClick': () => {
                this.editHealthDocument(document.id);
            },
            'className': 'goal-template-action m-r-12',
            'key': '3969'
        }, 'Edit') : null, !this.props.selectionMode && document.practice.id === this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'className': 'goal-template-action delete-button',
            'disabled': !document.can_be_deleted,
            'onClick': () => {
                this.deleteHealthDocument(document.id);
            },
            'raised': true,
            'ripple': true,
            'key': '4379'
        }, 'Delete') : null, !this.props.selectionMode && document.practice.id !== this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'onClick': () => {
                this.editHealthDocument(document.id);
            },
            'className': 'goal-template-action m-r-12',
            'raised': true,
            'ripple': true,
            'primary': true,
            'key': '4802'
        }, 'Preview') : null, this.props.selectionMode ? React.createElement(ButtonDefault, {
            'className': 'goal-template-action',
            'onClick': () => {
                this.props.onSelected(document);
            },
            'raised': true,
            'ripple': true,
            'primary': true,
            'key': '5224'
        }, 'Do Select') : null)));
    }
    return React.createElement('div', { 'className': 'm-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': this.props.selectionMode ? 'You have not uploaded ' + this.props.match.params.document_type + ' documents to GoMotive.' : 'Click on the + icon to add a ' + this.props.match.params.document_type + ' document to your library',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '350px' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, {
            'column': 'attachments',
            'style': { width: '250px' }
        }, React.createElement('strong', {}, 'Attachments')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Author ')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.health_documents, repeatDocument2.bind(this))
    ]), !this.props.selectionMode ? React.createElement(Button, {
        'style': { top: '90px' },
        'className': 'floating-add-button',
        'onClick': this.addHealthDocument,
        'icon': 'add',
        'floating': true,
        'mini': true,
        'ripple': true,
        'key': '5635'
    }) : null);
}