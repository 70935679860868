import Axios from '../../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';
import Utils from '../../../../utils/utils';
import {showProgress, hideProgress} from '../../../core/actions/progress_creator';
import ChallengeAction from '../actions/challenge_action';
import {history} from '../../../../redux/store';

const getChallengeList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(ChallengeAction.challengeListFill(
                response['challenges'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const getChallenge = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(ChallengeAction.challengeFill(
                response['challenge'], response["supporting_data"]
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const saveChallenge = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_save";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());            
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const deleteChallenge = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const publishChallenge = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_publish";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const getChallengeWorkout = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_workout_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(ChallengeAction.challengeWorkoutFill(
                response['challenge_workout'],
                response['supporting_data'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};

const saveChallengeWorkout = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_workout_save";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());    
            return response;            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            return error;
        });
    };
};

const deleteChallengeWorkout = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/challenge_workout_delete";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
        });
    };
};


export {
    getChallengeList,  
    getChallenge, 
    saveChallenge,
    deleteChallenge,
    publishChallenge,
    getChallengeWorkout,
    saveChallengeWorkout,
    deleteChallengeWorkout,
};