import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/profile_edit";
import { formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { postProfile } from '../../redux/user/network/user_profile';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import { verifyToken } from '../../redux/auth/network/auth_network';
import { uploadFiles } from '../../redux/core/network/file_upload';


class ProfileEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.pushRoute = this.pushRoute.bind(this);
        this.fileInputRef = React.createRef();
        this.renderImage = this.renderImage.bind(this);
        this.state = {
            imageSrc: null,
        }

    }

    componentDidMount() {
        this.setState({
            imageSrc: this.props?.profile?.profile_pic ? this.props?.profile?.profile_pic : null
        })
    }


    doSave(data) {
        let params = Utils.flatternFormData(data);
        params.is_client = this.props.isClient;
        if (params.dob) {
            params.dob = Utils.formatServerDate(params.dob);
        }
        this.props.postProfile({ ...params, id: this.props.user?.id, profile_pic: this.state.imageSrc, package_version: 3 }).then(() => {
            this.props.verifyToken({
                package_version: 2
            });
        }).catch(() => { });
    }

    doCancel() {
        history.goBack();
    }

    pushRoute() {
        history.push('/edit_profile_info');
    }


    AddPhoto = () => {
        this.fileInputRef.current.click();
    };

    uploadAvatar = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);

        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.setState({
                imageSrc: result.thumbnail_url
            })
        }).catch((error) => {
        });
    }


    renderImage() {
        const { imageSrc } = this.state;

        if (imageSrc) {
            return (
                <img
                    name="profile_pic"
                    src={imageSrc}
                    alt="Selected Profile Pic"
                    style={{ maxWidth: '90px', borderRadius: '50%',height:'80px' }}
                />
            );
        }
        else {
            return (
                <div className='relative'>
                    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34 0.25C15.4375 0.25 0.25 15.4375 0.25 34C0.25 52.5625 15.4375 67.75 34 67.75C52.5625 67.75 67.75 52.5625 67.75 34C67.75 15.4375 52.5625 0.25 34 0.25ZM34 13.375C38.125 13.375 41.5 16.75 41.5 20.875C41.5 25 38.125 28.375 34 28.375C29.875 28.375 26.5 25 26.5 20.875C26.5 16.75 29.875 13.375 34 13.375ZM15.25 52.75C15.25 42.4375 23.6875 34 34 34C44.3125 34 52.75 42.4375 52.75 52.75H15.25Z" fill="#666666" />
                    </svg>
                </div>
            )
        }

        return null;
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

const selector = formValueSelector('ProfileEditForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postProfile,
        verifyToken,
        uploadFiles
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let profile = { ...state.user.userProfile.profile };
    if (profile?.dob) {
        profile.dob = Utils.parseServerDate(profile?.dob);
    }
    let selectedRole = state.auth.roleReducer.selectedRole;
    return {
        isClient: ownProps.is_client ? ownProps.is_client : !_.isEmpty(selectedRole) && selectedRole.role.name === 'client',
        profile: profile,
        supportingData: state.user.userProfile.supportingData,
        initialValues: profile,
        // is_avatar_uploaded: selector(state, 'is_avatar_uploaded'),
        // avatar_url: selector(state, 'avatar_url'),
        user_guid: selector(state, 'user_guid'),
        user: state.auth.authReducer.user,
        ...ownProps,
    };
}

ProfileEdit.propTypes = {
    self_editing: PropTypes.bool,
};
ProfileEdit.defaultProps = {
    self_editing: true,
};

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ProfileEditForm = reduxForm(
    {
        form: 'ProfileEditForm',
        enableReinitialize: true,
        validate
    }
)(ProfileEdit);

const ProfileEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileEditForm);

export default ProfileEditConnected;
