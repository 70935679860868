// Action Creators
const fillProgramPreview = (program) => ({
    type: 'PROGRAM_PREVIEW_FILL',
    program,
});
const cleanModule = () => ({
    type: 'PROGRAM_PREVIEW_CLEANUP',
});

export default {
    cleanModule,
    fillProgramPreview,
};