import * as React from 'react';
import * as _ from 'lodash';
import { Tab } from 'react-toolbox';
import { Tabs } from 'react-toolbox';
import { IconButton } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import { Panel } from 'react-toolbox';
import Input from 'react-toolbox/lib/input';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Chip } from 'react-toolbox/lib/chip';
export default function () {
    function repeatPlan1(category, categoryIndex, plan, planIndex) {
        return React.createElement('span', { 'key': 'plan' + planIndex }, React.createElement(Chip, {}, plan.name));
    }
    function repeatCategory2(category, categoryIndex) {
        return React.createElement(Tr, { 'key': 'category' + categoryIndex }, React.createElement(Td, {
            'column': 'name',
            'value': category.name
        }, React.createElement('div', {}, React.createElement('span', {}, category.name))), React.createElement(Td, { 'column': 'plans' }, React.createElement.apply(this, [
            'div',
            {},
            _.map(category.plans, repeatPlan1.bind(this, category, categoryIndex))
        ])), React.createElement(Td, {
            'column': 'count',
            'value': category.content_count,
            'style': {
                width: '200px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement('span', {}, category.content_count))), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, React.createElement(Button, {
            'icon': 'create',
            'className': 'micro',
            'onClick': () => {
                this.editEducationCategory(category);
            },
            'floating': true,
            'primary': true,
            'mini': true
        }), category.content_count === 0 ? React.createElement(Button, {
            'icon': 'delete',
            'className': 'micro bg-danger m-l-5',
            'onClick': () => {
                this.deleteEducationCategory(category);
            },
            'floating': true,
            'mini': true,
            'key': '4010'
        }) : null)));
    }
    function repeatVideo3(video, index) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10',
            'key': 'video' + index
        }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(video.thumbnail) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '50px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-12 f-16 truncate',
            'tooltip': video.name
        }, '\n                                    ', video.name, '\n                                '), React.createElement('div', { 'className': 'col-xs-12 f-12 f-300 m-t-10 m-b-10' }, '\n                                    ', video.education_category.name, '\n                                ')), React.createElement('div', { 'className': 'row center-xs m-0' }, video.deleted_date === null ? [React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '70771'
            }, React.createElement(IconButton, {
                'tooltip': 'Edit Content',
                'icon': 'create',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.editEducationContent(video);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Delete Content',
                'icon': 'delete',
                'className': 'm-r-5 c-danger small-icon-button round-border',
                'onClick': () => {
                    this.deleteEducationContent(video);
                }
            }))] : null));
    }
    return React.createElement('div', { 'className': 'row center-xs m-20' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Tabs, {
        'index': this.state.index,
        'onChange': this.handleTabChange,
        'fixed': true
    }, React.createElement(Tab, {
        'label': 'Categories',
        'className': 'w-100'
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement(Button, {
        'onClick': this.addEducationCategory,
        'tooltip': 'Add new category',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true
    })), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a new education content category.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, { 'column': 'plans' }, React.createElement('strong', {}, 'Associated Plans')), React.createElement(Th, {
            'column': 'count',
            'style': {
                width: '200px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Count')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.education_categories, repeatCategory2.bind(this))
    ])), React.createElement(Tab, { 'label': 'Content' }, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'row start-xs m-10' }, React.createElement(Select, {
        'className': 'col-xs-6 m-t-7',
        'clearable': false,
        'value': this.state.selectedEducationCategory,
        'options': this.props.education_categories,
        'simpleValue': true,
        'labelKey': 'name',
        'valueKey': 'id',
        'onChange': val => {
            this.onSelectCategory(val);
        }
    }), React.createElement('div', { 'className': 'col-xs-6 p-relative m-b-20 p-5  vertical-align' }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search for education content',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-success m-r-10',
        'onClick': this.addEducationContent,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    }))), this.props.education_content.length === 0 ? [React.createElement('div', {
            'className': 'row center-xs alert-warning m-20',
            'key': '57881'
        }, '\n                            There are no education content videos\n                        ')] : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-10' },
        _.map(this.props.education_content, repeatVideo3.bind(this))
    ]))))));
}