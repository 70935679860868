import React from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const TimePickerFunction = (props) => {
    let { meta: meta, input: input, ...mainprops } = props;
    let { value, onChange, onFocus, onBlur } = input;
    if (value === "" || value === null) {
        value = undefined;
    }
    const readonly = props.readonly ? props.readonly : false;
    const error = (meta.error && (meta.touched || meta.submitFailed)) ? meta.error : "";
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                {...mainprops}
                error={error}
                format="ampm"
                value={value}
                onChange={onChange}
                readonly={readonly}
                renderInput={(params) => <TextField style={{width:"100%"}} {...params} />}
            />
        </LocalizationProvider>

    );
};
export default TimePickerFunction;