import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/question_edit.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm,formValueSelector } from 'redux-form';

import IntakeAction from '../../redux/intake/actions/intake_creator';
import { getIntakeInstance,saveIntakeQuestion } from '../../redux/intake/network/intake_network';
import { Card, CardText } from 'react-toolbox/lib/card';
import swal from 'sweetalert2';




class QuestionEdit extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doDelete = this.doDelete.bind(this);
    }

    doSave(data){
        let tdata = { ...data };
        let questions = _.cloneDeep(this.props.intake.questions);
        questions = _.map(questions, (item) => {
            if (item.field_guid === data.field_guid) {
                let insertData = _.omit(tdata, ['is_new']);
                insertData.field_type = parseInt(insertData.field_type);
                if (insertData.field_type === 3 || insertData.field_type === 4  || insertData.field_type === 14 ){
                    insertData.values = [];
                }
                return insertData;
            } else {
                item.field_type = parseInt(item.field_type);
                return item;
            }
        });
        let params = { questions: questions, eform: this.props.intake.id, type: 'save' };
        this.props.saveIntakeQuestion(params);
    }    
    doDelete() {
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: "You will deleting this question!",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            if (that.props.question.is_new === true){//is_new can be undefined
                that.props.removeQuestion(that.props.question);
            } else {
                let questions = _.cloneDeep(that.props.intake.questions);
                questions = _.filter(questions, (item) => {
                        if (item.field_guid === that.props.question.field_guid) {
                            return false;
                        } else {
                            return true;
                        }
                });    

                let params = { questions: questions, eform: that.props.intake.id, type: 'delete' };
                that.props.saveIntakeQuestion(params);    
            }
            swal.close();
        }, function (dismiss) {
                        
        });
    }    

    render() {
        if (_.isEmpty(this.props.question)) {
            return (
                <Card className="w-100 full-height" >
                    <CardText className="p-t-5">    
                        <div className="row center-xs m-t-25">
                            <h4 className="bg-light-info m-20 p-10 w-100">
                                Please select a question from left section!    
                            </h4> 
                        </div>
                    </CardText>    
                </Card>    
            );
        }
        return Template.apply(this);
    }
}
const selector = formValueSelector('questionEditForm'); 
function mapStateToProps(state, ownprops) {
    let question = { ...state.intake.intakeReducer.question };
    if (!_.isEmpty(question)){
        question.field_type = question.field_type.toString();
    }
    
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        intake: state.intake.intakeReducer.intake ,
        question: question,
        initialValues: { ...question },
        field_type: selector(state, 'field_type')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getIntakeInstance,
        saveIntakeQuestion,
        cleanUp: IntakeAction.intakeCleanUp,
        removeQuestion: IntakeAction.intakeQuestionRemove,
    }, dispatch);
}

const validate = (values, props) => {
    let requiredFields = [
        'name'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if ((values["field_type"] === "3" || values["field_type"] === "4" || values["field_type"] === "14") && values["field_choices"].length === 0) {
        errors["field_choices"] = 'Required';
    }

    return errors;
};

const QuestionEditForm = reduxForm(
    {
        form: 'questionEditForm',
        enableReinitialize: true,
        validate
    }
)(QuestionEdit);
const QuestionEditConnected = connect(mapStateToProps,mapDispatchToProps)(QuestionEditForm);
export default QuestionEditConnected;
