let initialState = {
    goal_list: [],
    hidden_goals: [],
    goal: {},
    goal_types: [],
    goal_tracking_unit: []
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "GOAL_LIST_FILL":
            if (!action.goal_list && !action.hidden_goals){
                return state;
            }
            return Object.assign({}, state, {
                goal_list: action.goal_list,
                hidden_goals: action.hidden_goals
            });
        case "GOAL_GET_FILL":
            if (!action.goal){
                return state;
            }
            return Object.assign({}, state, {
                goal: action.goal,
                goal_types: action.goal_types,
                goal_tracking_unit: action.goal_tracking_unit
            });
        case "GOAL_TRACKING_UNIT_LIST_FILL":
            if (!action.goal_tracking_unit){
                return state;
            }
            return Object.assign({}, state, {
                goal_tracking_unit: action.goal_tracking_unit
            });            
        case "GOAL_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
