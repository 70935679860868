import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import actions from '../actions/client_goal_tracking';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getClientGroupGoalTrackingList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_group_goal_tracking_list";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(actions.fillTrackingList(
                response['client_group_goal_tracking_list'],
                response['paginate_info']
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getClientGoalTrackingHistory = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_goal_tracking_history_get";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(actions.fillTrackingHistory(
                params['group_goal_client_question_id'],
                response['client_group_goal_tracking_history']
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const removeClientFromGroup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_client_remove";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.groupClientRemoval(
                params['client'],
            ));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully removed from the group");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const addTreatmentToGroupClient = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/add_treatment_to_group_client";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.onClientTreatmentAdd(
                response.client_workouts,
                response.client_habits,
                response.treatment_started_date,
                params['client'],
            ));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Program has been configured for your client");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const addClientForGroup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_client_add";
        return Axios.post(url, params).then((response) => {
            dispatch(actions.groupClientsAdd(
                response['clients'],
            ));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully added to the group");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    getClientGroupGoalTrackingList,
    getClientGoalTrackingHistory,
    removeClientFromGroup,
    addTreatmentToGroupClient,
    addClientForGroup
};