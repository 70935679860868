import { combineReducers } from 'redux';
import programList from './program_list';
import program from './program';
import programEdit from './program_edit';
import progressionEdit from './progression_edit';
import programPreview from './program_preview';

export default combineReducers({
    programList,
    program,
    programEdit,
    progressionEdit,
    programPreview,
});