import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import IntakeForm from '../../../engagement/components/client_intake_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import moment from 'moment';
export default function () {
    function repeatIntake1(intake, intakeIndex) {
        return React.createElement('tr', { 'key': intake.id }, React.createElement('td', {}, intake.intake.name), React.createElement('td', {}, intake.engagement.practitioner.name, ' from ', intake.practice.name), React.createElement('td', {}, moment(intake.created_date).format('MMMM Do YYYY ')), React.createElement('td', { 'className': 'w-10 text-center bg-warning' }, React.createElement('span', {}, intake.submitted ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '1778'
        }) : null, !intake.submitted ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '2050'
        }) : null)), React.createElement('td', { 'className': 'w-10 text-center' }, React.createElement(Button, {
            'tooltip': 'Click to fill the intake form',
            'onClick': () => {
                this.showPreviewIntakeModal(intake);
            },
            'icon': 'create',
            'primary': true,
            'floating': true,
            'mini': true
        })));
    }
    return React.createElement('div', { 'className': 'm-30' }, this.props.intake_forms.length > 0 ? React.createElement('div', {
        'className': 'panel timeline-panel',
        'key': '426'
    }, React.createElement('div', { 'className': 'panel-heading bg-beige' }, React.createElement('h4', { 'className': 'panel-title' }, 'Please complete any forms shown in yellow')), React.createElement('div', { 'className': 'panel-body p-0' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', { 'className': 'text-center' }, 'Intake Form'), React.createElement('th', { 'className': 'text-center' }, 'Assigned by'), React.createElement('th', { 'className': 'text-center' }, 'Assigned Date'), React.createElement('th', { 'className': 'w-10 text-center' }, 'Status'), React.createElement('th', { 'className': 'w-10 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.intake_forms, repeatIntake1.bind(this))
    ])))) : null, this.props.intake_forms.length === 0 ? React.createElement('div', { 'key': '2733' }, React.createElement('div', { 'className': 'alert-warning' }, 'No intake form has been assigned to you.')) : null, !_.isEmpty(this.state.previewIntake) ? React.createElement(Dialog, {
        'active': this.state.openPreviewIntakeModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hidePreviewIntakeModal,
        'onOverlayClick': this.hidePreviewIntakeModal,
        'key': '2882'
    }, React.createElement(IntakeForm, {
        'intake': this.state.previewIntake,
        'handleClose': this.hidePreviewIntakeModal
    })) : null);
}