import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
export default function () {
    function repeatGoal_question1(goal_question, goal_questionIndex) {
        return React.createElement('div', {
            'className': 'row between-xs center-xs m-l-10',
            'key': 'goal_question' + goal_questionIndex
        }, React.createElement('div', { 'className': 'col-xs-9' }, React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].question',
            'className': 'w-100',
            'type': 'text',
            'multiline': true,
            'component': TextInput,
            'label': 'Quantifiable measure to reach this goal'
        })), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].metric_unit',
            'type': 'text',
            'component': Select,
            'label': 'Metric Unit',
            'valueKey': 'id',
            'labelKey': 'unit_name',
            'options': this.props.goalTrackingUnits
        })), React.createElement('div', { 'className': 'col m-r-15' }, !goal_question.is_tracked ? React.createElement(IconButton, {
            'icon': 'delete_forever',
            'tooltip': 'Delete Goal Question',
            'className': 'm-t-20 c-white bg-danger',
            'onClick': () => this.deleteGoalQuestion(goal_questionIndex),
            'key': '3078'
        }) : null, goal_question.is_tracked ? React.createElement(IconButton, {
            'icon': 'highlight_off',
            'tooltip': 'Click here for your client to stop tracking this metric.',
            'className': 'm-t-20 c-danger',
            'onClick': () => this.deleteGoalQuestion(goal_questionIndex),
            'key': '3423'
        }) : null));
    }
    return React.createElement('form', {
        'onSubmit': this.props.handleSubmit(this.doSave),
        'className': 'small-labels'
    }, React.createElement('div', { 'className': 'row box-shadow' }, React.createElement('div', { 'className': 'col-xs-12 bg-beige p-t-5 p-l-5 p-r-5' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, React.createElement(Field, {
        'className': 'w-95 m-l-10',
        'name': 'text',
        'type': 'text',
        'multiline': true,
        'component': TextInput,
        'label': 'Goal Text',
        'rows': 2
    })), React.createElement('div', { 'className': 'col-xs-1 text-center' }, !this.props.goal.is_tracked ? React.createElement(IconButton, {
        'icon': 'delete_forever',
        'tooltip': 'Delete Goal',
        'className': 'm-t-20 c-white bg-danger',
        'onClick': () => this.deleteGoal(),
        'key': '1580'
    }) : null))), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 bg-white p-b-5 p-l-5 p-r-5' },
        React.createElement('div', { 'className': 'row start-xs middle-xs m-b-15 m-t-15' }, React.createElement('div', { 'className': 'col f-18 material-label p-l-10' }, '\n                    Goal Metrics\n                ')),
        _.map(this.props.goal_questions, repeatGoal_question1.bind(this)),
        this.props.goal_questions && this.props.goal_questions.length === 0 ? React.createElement('div', {
            'className': 'alert-warning p-10',
            'key': '3829'
        }, '\n                Click on + icon to create new quantifiable measure to reach this goal.\n            ') : null,
        React.createElement('div', { 'className': 'row m-10' }, React.createElement(IconButton, {
            'tooltip': 'Add Goal Question',
            'icon': 'add',
            'className': 'bg-success',
            'onClick': this.addGoalQuestion
        }))
    ])), React.createElement('div', { 'className': 'row-margin end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Close',
        'accent': true,
        'raised': true,
        'onClick': this.props.finishEditing
    }), React.createElement(Button, {
        'label': 'Save',
        'className': 'm-l-10 bg-success',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    })));
}