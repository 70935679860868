import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Avatar } from 'react-toolbox/lib/avatar';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import Slider from 'react-slick';
import SlickCss from 'slick-carousel/slick/slick.css';
import SlickThemeCss from 'slick-carousel/slick/slick-theme.css';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ExercisePreview from '../../../exercise/components/exercise_preview';
import IntroVideo from '../../../client_app/components/welcome_video';
export default function () {
    function repeatExercise1(exercise, exerciseIndex) {
        return React.createElement('div', {
            'className': 'width-250  box-shadow  m-l-10 m-r-10',
            'key': 'exercise' + exercise.id
        }, React.createElement('div', {
            'className': 'vertical-align m-0  bg-accent-dark',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'flex-auto p-l-5 f-14 truncate',
            'tooltip': exercise.name
        }, '\n                                    ', exercise.name, '\n                                '), exercise.description ? React.createElement(TooltipWrapper, {
            'className': 'm-r-10 c-white text-center di-block',
            'tooltip': exercise.description,
            'key': '3224'
        }, React.createElement('i', { 'className': 'material-icons pointer' }, 'info')) : null), React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(exercise.exercise_thumbnail_url) + ')' }
        }), React.createElement('div', { 'className': 'vertical-align between-xs m-0 bg-accent-dark p-5' }, React.createElement('div', {
            'className': 'p-3 bg-success-red c-white text-center f-12',
            'style': { minWidth: '60px' }
        }, '\n                                    Sets: ', exercise.sets, '\n                                '), React.createElement(TooltipWrapper, {
            'className': 'vertical-align',
            'tooltip': 'View Exercise Details',
            'onClick': () => {
                this.preview(exercise);
            }
        }, React.createElement('i', {
            'className': 'material-icons pointer',
            'style': { fontSize: '30px' }
        }, 'play_circle_filled')), exercise.metric === 1 ? React.createElement('div', {
            'className': 'p-3 bg-success-red c-white text-center f-12',
            'style': { minWidth: '60px' },
            'key': '4404'
        }, '\n                                    Reps: ', exercise.reps, '\n                                ') : null, exercise.metric === 2 ? React.createElement('div', {
            'className': 'p-3 bg-success-red c-white text-center f-12',
            'style': { minWidth: '60px' },
            'key': '4693'
        }, '\n                                    Distance: ', exercise.distance, ' ', exercise.__distance_unit, '\n                                ') : null, exercise.metric === 3 ? React.createElement('div', {
            'className': 'p-3 bg-success-red c-white text-center f-12',
            'style': { minWidth: '60px' },
            'key': '5017'
        }, '\n                                    Distance: ', exercise.duration, ' ', exercise.__duration_unit, '\n                                ') : null));
    }
    return React.createElement('div', {}, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10' }, React.createElement('div', { 'className': 'panel-title' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, '\n                    ', `${ this.props.display_workout_name ? this.props.progression.workout_name + '  ( ' + this.props.progression.name + ')' : this.props.progression.name }`, '\n                '), this.props.progression.workout_description ? React.createElement(IconButton, {
        'className': 'col-xs-1 c-black center-xs',
        'tooltip': this.props.progression.workout_description,
        'icon': 'info',
        'key': '1517'
    }) : null)), this.props.display_schedule_type_info ? React.createElement('div', {
        'className': 'panel-subtitle m-t-5',
        'key': '1785'
    }, '\n            ', this.props.progression.schedule_type === 2 ? this.props.progression.per_week + ' times per week & ' + this.props.progression.per_day + ' times per day' : 'Scheduled', '\n        ') : null, React.createElement('div', { 'className': 'panel-subtitle pre-wrap' }, '\n            ', this.props.progression.description, '\n        '), this.props.progression.intro && this.props.progression.intro.video_guid !== null ? React.createElement('div', {
        'className': 'm-t-5 pointer',
        'onClick': () => {
            this.viewIntroVideo();
        },
        'key': '2175'
    }, '\n            View Intro Video\n        ') : null), React.createElement('div', { 'className': 'panel-body row m-0 p-10' }, React.createElement('div', {
        'className': 'col-xs-12  bg-body  c-black p-l-20 p-r-20 p-t-10 p-b-10 box-shadow-inset',
        'style': { overflow: 'hidden' }
    }, this.props.progression.exercises.length !== 0 ? React.createElement.apply(this, [
        Slider,
        _.assign({}, { 'key': '2600' }, this.sliderSettings),
        _.map(this.props.progression.exercises, repeatExercise1.bind(this))
    ]) : null)), this.props.showTracking && this.props.trackProgressionCallback ? React.createElement('div', {
        'className': 'panel-footer bg-white vertical-align  center-xs',
        'key': '5465'
    }, React.createElement(Button, {
        'onClick': () => {
            this.do_track(3);
        },
        'icon': 'done_all',
        'className': ` br-50em ${ this.props.progression.has_tracked === 3 ? 'bg-success-red c-white' : 'c-accent-dark button-border' } `,
        'tooltip': 'Did it',
        'label': 'Did It',
        'flat': true,
        'disabled': this.props.progression.schedule_type === 2 && !this.props.progression.is_tracking_allowed
    }), React.createElement(Button, {
        'onClick': () => {
            this.do_track(2);
        },
        'icon': 'done',
        'className': ` br-50em m-l-10 ${ this.props.progression.has_tracked === 2 ? 'bg-primary' : 'c-accent-dark button-border' } `,
        'tooltip': 'Did Part of it',
        'label': 'Did Part of it',
        'flat': true,
        'disabled': this.props.progression.schedule_type === 2 && !this.props.progression.is_tracking_allowed
    }), this.props.progression.schedule_type !== 2 ? React.createElement(Button, {
        'onClick': () => {
            this.do_track(1);
        },
        'icon': 'clear',
        'className': ` br-50em m-l-10 ${ this.props.progression.has_tracked === 1 ? 'bg-danger' : 'c-accent-dark button-border' } `,
        'tooltip': 'Didn\'t do it',
        'label': 'Didn\'t do it',
        'flat': true,
        'key': '6295'
    }) : null, this.props.progression.schedule_type === 2 && this.props.progression.track_count > 0 ? React.createElement('div', {
        'className': 'm-l-20',
        'key': '6587'
    }, '\n            (', this.props.progression.track_count, ' of ', this.props.progression.max_allowed_tracking, ' tracked this week)\n        ') : null) : null), React.createElement(ExercisePreview, {
        'exercise': this.state.previewingExercise,
        'onClosePreview': () => {
            this.setState({ previewingExercise: null });
        }
    }), this.state.show_intro_video ? React.createElement(IntroVideo, {
        'onCloseHandle': this.closeIntroVideo,
        'welcome_video': this.props.progression.intro,
        'key': '7010'
    }) : null);
}