// Action Creators
const partnerPlanListFill = (partner_plans, discount_plans) => ({
    type: 'BUSINESS_PARTNER_PLAN_LIST_FILL',
    partner_plans,
    discount_plans
});
const partnerPlanInstanceFill = (partner_plan) => ({
    type: 'BUSINESS_PARTNER_PLAN_INSTANCE_FILL',
    partner_plan,
});
const cleanUp = () => ({
    type: 'BUSINESS_PARTNER_PLAN_CLEANUP'
});


export default {
    partnerPlanListFill,
    partnerPlanInstanceFill,
    cleanUp
};