import React from 'react';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import BaseComponent from '../../utils/common_components/basecomponent';
import HomeTemplate from './templates/client_home.rt';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { PARTNER } from '../../core/constants/constants';
import moment from 'moment';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {
  getClientHomeDetails,
  getClientHomeAlerts,
  getClientConnectPractitionerList,
  getClientStart,
  getClientPrograms
} from '../../redux/client/network/client_network.js';
import ClientAction from '../../redux/client/actions/client_actions';
import { history } from '../../redux/store';
import MenuAction from '../../redux/core/actions/menu_state_creator';
import { messageUser } from '../../redux/message/network/message_network';
import authActionCreator from '../../redux/auth/actions/auth_creator';
import RadialProgressChart from '../../vendor/radial-progress-chart/index';
import { STUDIOFIT_WELCOME_VIDEO } from '../../utils/help_video';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';

class ClientHome extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.doLogout = this.doLogout.bind(this);
    this.callBackAfterSave = this.callBackAfterSave.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.showEngagement = this.showEngagement.bind(this);    
    this.goStart = this.goStart.bind(this);
    this.doConnect = this.doConnect.bind(this);
    this.closeConnect = this.closeConnect.bind(this);
    this.gotoTrackGoal = this.gotoTrackGoal.bind(this);
    this.gotoIntake = this.gotoIntake.bind(this);
    this.chatWithPractitioner = this.chatWithPractitioner.bind(this);
    this.showMailComposer = this.showMailComposer.bind(this);
    this.hideMailComposer = this.hideMailComposer.bind(this);
    this.onMailComposerSubmit = this.onMailComposerSubmit.bind(this);
    this.closeWelcomeVideo = this.closeWelcomeVideo.bind(this);
    this.doWorkouts = this.doWorkouts.bind(this);
    this.doHabits = this.doHabits.bind(this);
    this.doHealthDocuments = this.doHealthDocuments.bind(this);
    this.doGoals = this.doGoals.bind(this);
    this.closeProgramSelectionDialog = this.closeProgramSelectionDialog.bind(
      this
    );
    this.loadProgramSelectedContent = this.loadProgramSelectedContent.bind(
      this
    );
    this.state = {
      showConnect: false,
      mailComposerVisible: false,
      currentlyMailingPractitioner: null,
      show_movement_graph: false,
      weekly_movement_score_text: '',
      welcome_video: null,
      showProgramSelectionDialog: false,
      contentSelection: null
    };
  }

  chatWithPractitioner(practitioner) {
    if (practitioner.conversation_id) {
      history.push('/client/conversation/' + practitioner.conversation_id);
    } else {
      this.props
        .personalChannel({
          to_user: practitioner.id
        })
        .then((response) => {
          history.push('/client/conversation/' + response.conversation_id);
        })
        .catch(() => {});
    }
  }

  showMailComposer(practitioner) {
    this.setState({
      mailComposerVisible: true,
      currentlyMailingPractitioner: practitioner
    });
  }

  hideMailComposer() {
    this.setState({
      mailComposerVisible: false,
      currentlyMailingPractitioner: null
    });
  }

  onMailComposerSubmit(data) {
    this.props
      .messageUser({
        ...data,
        user_practice_id: this.state.currentlyMailingPractitioner.practice.id,
        user_id: this.state.currentlyMailingPractitioner.id
      })
      .then(() => {
        this.hideMailComposer();
      })
      .catch(() => {});
  }

  doLogout() {
    this.props.doLogout();
    Utils.removeLocalStorageValue('token');
    localStorage.removeItem('selectedRole');
    history.push('/auth/login');
  }

  callBackAfterSave() {
    this.getClientHomePageDetails();
  }

  componentWillMount() {
    this.props.updateMenuPinned(false);
    this.props.updateMenuOpen(false);
    this.props.cleanUp();
    if (PARTNER.app_type === 'dhf' || PARTNER.app_type === 'tri') {
        this.getClientHomePageDetails();
      }
  }

  componentDidMount() {
    if (PARTNER.app_type === 'dhf' || PARTNER.app_type === 'tri') {
      this.props.updateHelpVideo(STUDIOFIT_WELCOME_VIDEO);
    }
    this.getClientHomePageDetails();
  }

  componentWillUnmount() {
    this.props.clearHelpVideo();
  }

  getClientHomePageDetails() {
    this.props.getClientHomeDetails({
        "app_version": "5.2",
        "package_version": "2",
    }).then((response) => {
      let cwmp = response.current_week_movement_points;
      if (Object.keys(cwmp).length > 0) {
        let cwmp = response.current_week_movement_points;
        let mobility_percentage =
          cwmp.mobility_percentage > 3 ? cwmp.mobility_percentage : 3;
        let strength_percentage =
          cwmp.strength_percentage > 3 ? cwmp.strength_percentage : 3.2;
        let metabolic_percentage =
          cwmp.metabolic_percentage > 5 ? cwmp.metabolic_percentage : 5;
        let power_percentage =
          cwmp.power_percentage > 7 ? cwmp.power_percentage : 7;
        let weeklyMovementText = `Weekly Movement Score
                Mobility: ${cwmp.mobility_percentage} %
                Strength: ${cwmp.strength_percentage} %
                Metabolic: ${cwmp.metabolic_percentage} %
                Power: ${cwmp.power_percentage} %
                `;
        this.setState(
          {
            show_movement_graph: response.show_movement_graph,
            weekly_movement_score_text: weeklyMovementText
          },
          () => {
            new RadialProgressChart('.radial-chart', {
              diameter: 130,
              series: [
                { labelStart: '\uf06d', value: power_percentage },
                { labelStart: '\uf004', value: metabolic_percentage },
                { labelStart: '\uf0e7', value: strength_percentage },
                { labelStart: '\uf1ae', value: mobility_percentage }
              ]
            });
          }
        );
      } else {
        this.setState({ show_movement_graph: response.show_movement_graph });
      }
    });

    // this.props.getClientHomeDetails({todays_date:moment().format("YYYY-MM-DD")})
    // .then((response)=>{
    //     if(response.alert_count === 0){
    //         this.props.clientHomeAlertsFill(0,0,[],[]);
    //     }else{
    //         this.props.getClientHomeAlerts();
    //     }
    //     if(response.show_movement_graph){
    //         let cwmp =  response.current_week_movement_points;
    //         let mobility_percentage =   (cwmp.mobility_percentage > 3) ?  cwmp.mobility_percentage : 3;
    //         let strength_percentage =   (cwmp.strength_percentage > 3) ?  cwmp.strength_percentage : 3.2;
    //         let metabolic_percentage =   (cwmp.metabolic_percentage > 5) ?  cwmp.metabolic_percentage : 5;
    //         let power_percentage =   (cwmp.power_percentage > 7) ?  cwmp.power_percentage : 7;
    //         let weeklyMovementText = `Weekly Movement Score
    //         Mobility: ${cwmp.mobility_percentage} %
    //         Strength: ${cwmp.strength_percentage} %
    //         Metabolic: ${cwmp.metabolic_percentage} %
    //         Power: ${cwmp.power_percentage} %
    //         `;
    //         this.setState({
    //             show_movement_graph:response.show_movement_graph,
    //             weekly_movement_score_text:weeklyMovementText
    //         },()=>{
    //             new RadialProgressChart('.radial-chart', {diameter: 130,series: [
    //                 {labelStart: '\uf06d', value: power_percentage},
    //                 {labelStart: '\uf004', value: metabolic_percentage},
    //                 {labelStart: '\uf0e7', value: strength_percentage},
    //                 {labelStart: '\uf1ae', value: mobility_percentage},
    //             ]});
    //         });
    //     }else{
    //         this.setState({show_movement_graph:response.show_movement_graph});
    //     }
    //     if (response.has_viewed_studiofit_video === false) {
    //         let studiofit_welcome_video = {};
    //         studiofit_welcome_video["title"] = "StudioFit Welcome Video";
    //         studiofit_welcome_video["video_guid"] = response.studiofit_welcome_video;
    //         this.setState({
    //             welcome_video: studiofit_welcome_video
    //         });
    //     }
    // }).catch((error)=>{

    // });
  }

  closeWelcomeVideo() {
    this.setState({
      welcome_video: null
    });
  }


  doConnect() {
    this.props.getClientConnectPractitionerList({});
    this.setState({ showConnect: true });
  }

  closeConnect() {
    this.setState({ showConnect: false });
  }

  gotoTrackGoal(goal) {
    if (goal.type === 'engagement') {
      history.push('/client/engagement/' + goal.id);
    } else if (goal.type === 'group') {
      history.push('/client/group/' + goal.id);
    }
  }

  gotoIntake(intake) {
    history.push('/client/engagement/' + intake.engagement_id);
  }

  goStart() {
    history.push('/client/go/start');
    // if(this.props.start_count === 0){

    // }else{
    //     this.props.getClientStart({}).then((response)=>{
    //         if(response.start_list.length === 0){
    //             history.push("/client/go/start");
    //         }else{
    //             if(response.start_list[0].type === "engagement"){
    //                 history.push("/client/engagement/"+response.start_list[0].details.id);
    //             }else if(response.start_list[0].type === "group"){
    //                 history.push("/client/group/"+response.start_list[0].details.id);
    //             }else{
    //                 history.push("/client/go/start");
    //             }
    //         }
    //     }).catch((error)=>{

    //     });
    // }
  }

  showPreview(engagement_id, date) {
    if (this.props.selectedRole.role.name === 'client') {
      history.push(
        '/client/engagement/' + engagement_id + '/workout/preview/' + date
      );
    }
  }

  showEngagement(engagement) {
    history.push('/client/engagement/' + engagement.id);
  }

  showEngagementChatPage(engagement) {
    history.push('/client/conversation/' + engagement.conversation.id);
  }

  doWorkouts() {
    if (this.props.v2_program_count === 0) {
      Utils.showErrorAlert(
        'You do not have any workouts assinged by your practitioner. Kindly get in touch with your practitioner by clicking on the Connect feature.'
      );
    } else if (this.props.v2_program_count === 1) {
      history.push(
        '/client/workouts/' +
          this.props.v2_program_type +
          '/' +
          this.props.v2_program_id
      );
    } else if (this.props.v2_program_count > 1) {
      this.setState({
        contentSelection: 'workout'
      });
      this.props
        .getClientPrograms({})
        .then((response) => {
          this.setState({
            showProgramSelectionDialog: true
          });
        })
        .catch((error) => {});
    }
  }

  doHabits() {
    if (this.props.v2_program_count === 0) {
      Utils.showErrorAlert(
        'You do not have any habits assinged by your practitioner. Kindly get in touch with your practitioner by clicking on the Connect feature.'
      );
    } else if (this.props.v2_program_count === 1) {
      history.push(
        '/client/habits/' +
          this.props.v2_program_type +
          '/' +
          this.props.v2_program_id
      );
    } else if (this.props.v2_program_count > 1) {
      this.setState({
        contentSelection: 'habit'
      });
      this.props
        .getClientPrograms({})
        .then((response) => {
          this.setState({
            showProgramSelectionDialog: true
          });
        })
        .catch((error) => {});
    }
  }

  doHealthDocuments() {
    if (this.props.v2_program_count === 0) {
      Utils.showErrorAlert(
        'You do not have any nutrition & guidaance assinged by your practitioner. Kindly get in touch with your practitioner by clicking on the Connect feature.'
      );
    } else if (this.props.v2_program_count === 1) {
      history.push(
        '/client/documents/' +
          this.props.v2_program_type +
          '/' +
          this.props.v2_program_id
      );
    } else if (this.props.v2_program_count > 1) {
      this.setState({
        contentSelection: 'healthDocument'
      });
      this.props
        .getClientPrograms({})
        .then((response) => {
          this.setState({
            showProgramSelectionDialog: true
          });
        })
        .catch((error) => {});
    }
  }

  doGoals() {
    if (this.props.v2_program_count === 0) {
      Utils.showErrorAlert(
        'You do not have any goals assinged by your practitioner. Kindly get in touch with your practitioner by clicking on the Connect feature.'
      );
    } else if (this.props.v2_program_count === 1) {
      history.push(
        '/client/goals/' +
          this.props.v2_program_type +
          '/' +
          this.props.v2_program_id
      );
    } else if (this.props.v2_program_count > 1) {
      this.setState({
        contentSelection: 'goal'
      });
      this.props
        .getClientPrograms({})
        .then((response) => {
          this.setState({
            showProgramSelectionDialog: true
          });
        })
        .catch((error) => {});
    }
  }

  closeProgramSelectionDialog() {
    this.setState({
      showProgramSelectionDialog: false
    });
  }

  loadProgramSelectedContent(program) {
    if (this.state.contentSelection === 'workout') {
      history.push('/client/workouts/' + program.type + '/' + program.id);
    } else if (this.state.contentSelection === 'habit') {
      history.push('/client/habits/' + program.type + '/' + program.id);
    } else if (this.state.contentSelection === 'healthDocument') {
      history.push('/client/documents/' + program.type + '/' + program.id);
    } else if (this.state.contentSelection === 'goal') {
      history.push('/client/goals/' + program.type + '/' + program.id);
    }
  }

  render() {
    if (_.isEmpty(this.props.user)) {
      return (
        <div className="row center-xs m-t-25">
          <ProgressBar type="circular" mode="indeterminate" multicolor />
        </div>
      );
    }
    if (!this.props.newsList) {
      return (
        <div className="row center-xs m-t-25">
          <ProgressBar type="circular" mode="indeterminate" multicolor />
        </div>
      );
    } else {
      return HomeTemplate.apply(this);
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.auth.authReducer.user,
    selectedRole: state.auth.roleReducer.selectedRole,
    start_count: state.client.clientReducer.start_count,
    alert_count: state.client.clientReducer.alert_count,
    checkin_count: state.client.clientReducer.checkin_count,
    unread_chat_count: state.client.clientReducer.unread_chat_count,
    unread_messages_count: state.client.clientReducer.unread_messages_count,
    untracked_goals: state.client.clientReducer.untracked_goals,
    intakes_assigned: state.client.clientReducer.intakes_assigned,
    practitioners: state.client.clientReducer.practitioners,
    start_list: state.client.clientReducer.start_list,
    menuopen: state.core.appMenu.menu_open,
    v2_workout_image_url: state.client.clientReducer.v2_workout_image_url,
    newsList: state.client.clientReducer.v2_news_list,
    v2_program_count: state.client.clientReducer.v2_program_count,
    v2_program_type: state.client.clientReducer.v2_program_type,
    v2_program_id: state.client.clientReducer.v2_program_id,
    v2_program_list: state.client.clientReducer.v2_program_list,
    partner: PARTNER
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClientHomeDetails,
      getClientHomeAlerts,
      getClientConnectPractitionerList,
      getClientStart,
      getClientPrograms,
      cleanUp: ClientAction.cleanModule,
      clientHomeAlertsFill: ClientAction.clientHomeAlertsFill,
      updateMenuOpen: MenuAction.updateMenuOpen,
      updateMenuPinned: MenuAction.updateMenuPinned,
      doLogout: authActionCreator.doLogout,
      messageUser,
      updateHelpVideo: HelpVideoCreator.updateHelpVideo,
      clearHelpVideo: HelpVideoCreator.clearHelpVideo
    },
    dispatch
  );
}

const ClientHomeConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientHome);
export default ClientHomeConnected;
