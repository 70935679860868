import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actions from '../actions/health_document';

const listGuidanceDocuments = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_health_document_list";
        params.document_type = 2;
        return Axios.post(url, params).then((response) => {
            dispatch(actions.guidanceDocumentsFill(
                response['documents']
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const listNutritionDocuments = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_health_document_list";
        params.document_type = 1;
        return Axios.post(url, params).then((response) => {
            dispatch(actions.nutritionDocumentsFill(
                response['documents']
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postHealthDocument = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_health_document_post";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            if (params.document_type === 'nutrition') {
                Utils.showSuccessAlert("Nutrition document has been assigned to group. Select Action to publish");    
            } else {
                Utils.showSuccessAlert("Guidance document has been assigned to group. Select Action to publish");    
            }
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteHealthDocument = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/group_health_document_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Has been removed from group.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

export {
    listGuidanceDocuments,
    listNutritionDocuments,
    postHealthDocument,
    deleteHealthDocument,
};