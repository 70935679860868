import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/exercise_edit_new";
import { reduxForm, formValueSelector, arrayPush, Field } from 'redux-form';
import TextInput from '../../utils/common_components/textInput_form';

import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import store, { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    getExercise, postExercise, deleteExercise,
    uploadMedia, listExerciseFilter
} from '../../redux/exercise/network/exercise';
import actionCreator from '../../redux/exercise/actions/exercise';
import Utils from '../../utils/utils';
import React from 'react';
import axios from 'axios';
import Axios from '../../utils/network/axios';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';
import { uploadFiles } from '../../redux/core/network/file_upload';
// import Button from '../../utils/common_components/tooltipButton';
import Dropzone from '../../utils/common_components/dropzone';
import Button from 'react-toolbox/lib/button';

let CAFSOptions = [
    { name: 'a', label: "A", value: "A" },
    { name: 'a', label: "B", value: "B" },
    { name: 'a', label: "C", value: "C" },
    { name: 'a', label: "D", value: "D" },
]

let FSTTOptions = [
    { name: 'self-massage', label: "Self Massage", value: "Self Massage" },
    { name: 'isometric', label: "Isometric", value: "Isometric" },
    { name: 'isotonic', label: "Isotonic", value: "Isotonic" },
]

let threeDMatrixOptions = [
    { name: 'lunging', label: "Lunging", value: "Lunging" },
    { name: 'squatting', label: "Squatting", value: "Squatting" },
    { name: 'pushing', label: "Pushing", value: "Pushing" },
    { name: 'pulling', label: "Pulling", value: "Pulling" },
    { name: 'reaching', label: "Reaching", value: "Reaching" },
    { name: 'lifting', label: "Lifting", value: "Lifting" },
    { name: 'locomoting', label: "Locomoting", value: "Locomoting" },
    { name: 'verimoting', label: "Verimoting", value: "Verimoting" },
]
class ExerciseEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.onVideoUploaded = this.onVideoUploaded.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.transformData = this.transformData.bind(this);
        this.captureThumbnail = this.captureThumbnail.bind(this);
        this.capturePositionPicture = this.capturePositionPicture.bind(this);
        this.deletePositionPicture = this.deletePositionPicture.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndPublish = this.saveAndPublish.bind(this);
        this.uploadEquipmentImage = this.uploadEquipmentImage.bind(this);
        this.renderEquipments = this.renderEquipments.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.yesNoOptions = [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ];
        this.state = {
            exerciseState: 1,
            checked: true,
            openMenu: null,
            selectedParents: [],
            checkedChildren: {} // Object to track the checked state of each child
        };
        this.player = null;
    }

    setActive(el, active) {
        const formField = el.parentNode.parentNode
        if (active) {
            formField.classList.add('form-field--is-active')
        } else {
            formField.classList.remove('form-field--is-active')
            el.value === '' ?
                formField.classList.remove('form-field--is-filled') :
                formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el) {
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
                el.onblur = () => {
                    this.setActive(el, false)
                }
                el.onfocus = () => {
                    this.setActive(el, true)
                }
            }
        )
    }
    renderEquipments({ fields, meta }) {
        if (fields.length === 0 && this.props.initialValues.shopify_equipment) {
            fields.removeAll();
            this.props.initialValues.shopify_equipment.forEach(() => {
                fields.push({});
            });
        } else if (fields.length === 0) {
            fields.push({});
        }
        let backgroundStyle = (index) => {
            return {
                backgroundImage: `url(${this.props.equipment_url ? this.props.equipment_url[index] : this.props.equipment_thumbnail[index]})`,
                height: "30px",
                width: "30px",
                backgroundSize: 'cover'
            }
        };
        return (
            <div className=" row">
                <div className="col-xs-12">
                    {
                        fields.map((item, index) => {
                            return (
                                <div key={index} className="border p-12 m-b-10">
                                    <Field label="Equipment" className="w-100 m-b-20" onClick={this.inputClick} name={`shopify_equipment[${index}][name]`}
                                        component={TextInput}
                                        valueKey="id" labelKey="name" multi={true} />
                                    <Field label="Equipment Price" className="w-100 m-b-20" onClick={this.inputClick} name={`shopify_equipment[${index}][price]`} component={TextInput} />
                                    <Field label="Shopify URL" className="w-100 m-b-20" onClick={this.inputClick} name={`shopify_equipment[${index}][shopify_url]`} component={TextInput} />

                                    <div class="d-flex equipment-content align-center">
                                        {this.props.equipment_filename && this.props.equipment_filename[index] ?
                                            <div class="equipment-title w-80 d-flex flex-sb align-center" >
                                                <div class="d-flex align-center">
                                                    <div>
                                                        <div class="c-black small-title f-14 text-left p-b-8">Thumbnail</div>
                                                        <div class="d-flex align-center">
                                                            <div class="image-container m-r-20" style={{
                                                                backgroundImage: `url(${this.props.equipment_url ? this.props.equipment_url[index] : this.props.equipment_thumbnail[index]})`,
                                                                height: "30px",
                                                                width: "30px",
                                                                backgroundSize: 'cover'
                                                            }}></div>
                                                            <div class="text text-left">
                                                                {this.props.equipment_filename ? this.props.equipment_filename[index] : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cancel"></div>
                                            </div>
                                            : <div></div>
                                        }
                                        <Dropzone name={`shopify_equipment[${index}][thumbnail]`} multiple={false} accept="image/*" onDrop={(file) => { this.uploadEquipmentImage(file, index); }} class="dropzone">
                                            <Button class="add-media-btn" label={this.props.is_equipImage_uploaded[index] ? 'Change Media' : 'Add Media'} primary raised />
                                        </Dropzone>
                                    </div>
                                    <div>
                                        <Button
                                            tooltip="Add a quantifiable measure to reach this goal"
                                            label="Delete Equipment"
                                            className="height-fit secondary-button bg-success-border m-r-10"
                                            onClick={() => {
                                                this.props.initialValues.shopify_equipment.splice(index, 1)
                                                fields.remove(index)
                                            }} />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                {
                    <div className="col-xs-12  end-xs">
                        <Button
                            tooltip="Add a quantifiable measure to reach this goal"
                            label="Add Another"
                            className="height-fit secondary-button bg-success-border"
                            onClick={() => fields.push({})} />
                    </div>
                }
            </div>
        );
    }

    saveAndPublish() {
        let submit = this.props.handleSubmit(this.doSave);
        this.setState({
            exerciseState: 2
        }, () => {
            submit();
        });
    }

    saveAsDraft() {
        let submit = this.props.handleSubmit(this.doSave);
        this.setState({
            exerciseState: 1
        }, () => {
            submit();
        });
    }

    componentWillMount() {
        this.props.getExercise({
            id: this.props.match.params.exercise_id,
            package_version: 2,
        });
        this.props.listExerciseFilter({ first: true })
    }

    deletePositionPicture(url, del) {
        let pics = _.cloneDeep(this.props.position_pictures);
        var pic = _.find(pics, (item) => { return item.url === url; });

        if ("id" in pic) {
            pic.delete = true;
        } else {
            pics = _.remove(pics, function (item) {
                if (item.url !== pic.url) {
                    return true;
                }
            });
        }
        this.props.change('position_pictures', pics);
    }

    deleteVideo() {
        this.props.change('video_guid', null);
        this.props.change('exercise_thumbnail_url', null);
    }

    onVideoUploaded(uploadResult) {
        this.props.change('video_guid', uploadResult.guid);
        this.props.change('exercise_thumbnail_url', uploadResult.thumbnail);
    }


    captureThumbnail() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type': 'application/json',
            }
        });
        if (that.player) {
            this.props.showProgress();
            that.player.getCurrentTime().then(function (time) {
                let params = { time: time, active: true };
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.video_guid + "/pictures", params
                ).then((response) => {
                    let thumbnail_url = "";
                    if (response.data.sizes.length > 3) {
                        thumbnail_url = response.data.sizes[3].link;
                    } else {
                        thumbnail_url = response.data.sizes[response.data.sizes.length - 1].link;
                    }
                    that.props.change('exercise_thumbnail_url', thumbnail_url);
                    that.props.hideProgress();
                    Utils.showSuccessAlert("Your new thumbnail image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.hideProgress();
                });
            }).catch(function (error) {
                that.props.hideProgress();
            });
        }
    }

    capturePositionPicture() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type': 'application/json',
            }
        });
        if (that.player) {
            this.props.showProgress();
            that.player.getCurrentTime().then(function (time) {
                let params = { time: time };
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.video_guid + "/pictures", params
                ).then((response) => {
                    let thumbnail_url = "";
                    if (response.data.sizes.length > 3) {
                        thumbnail_url = response.data.sizes[3].link;
                    } else {
                        thumbnail_url = response.data.sizes[response.data.sizes.length - 1].link;
                    }
                    store.dispatch(arrayPush('ExerciseEditForm', 'position_pictures', {
                        url: thumbnail_url,
                        time: time,
                    }));
                    that.props.hideProgress();
                    Utils.showSuccessAlert("Your new position pic image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.hideProgress();
                });
            }).catch(function (error) {
                that.props.hideProgress();
            });
        }
    }


    onVideoUploadFailed() {
        Utils.showErrorAlert("Video upload failed! Please try again.");
    }

    uploadEquipmentImage(files, index) {
        let formData = new FormData();
        formData.append("files", files[0]);
        this.props.uploadFiles(formData).then((resp) => {
            let result = resp.upload_results[0];
            this.props.change('shopify_equipment[' + index + '][thumbnail]', result.url);
            this.props.change('shopify_equipment[' + index + '][media]', result);
            this.props.change('equipmentDisplay[' + index + ']', result.url);
            this.props.change('equipment_url[' + index + ']', result.url);
            this.props.change('equipment_filename[' + index + ']', result.file_name);
            this.props.change('is_equipImage_uploaded', true);
            this.props.change('dummy_to_trigger_save', true);
        }).catch((error) => {
        });
    }

    doSave(data) {
        let params = this.transformData(data);
        params.state = this.state.exerciseState
        params.shopify_equipment = data.shopify_equipment;
        let checkShopify
        if (params.shopify_equipment && params.shopify_equipment.length) {
            checkShopify = params.shopify_equipment.map((o) => {
                if (o.thumbnail == undefined || o.name == undefined || o.price == undefined || o.shopify_url == undefined) {
                    return o
                }
            })
        }
        let keysToCheck = ['thumbnail', 'name', 'price', 'shopify_url']
        if (checkShopify && checkShopify.length && !checkShopify.every(i => i === undefined)) {
            let shopifyIndices = []
            checkShopify.map((i, index) => {
                if (i !== undefined) {
                    keysToCheck.map(key => {
                        if (Object.keys(i).indexOf(key) == -1) {
                            Utils.showErrorAlert("Please add " + key + " in equipment no. " + (index + 1))
                        }
                    })
                    return shopifyIndices.push(index + 1)
                }
            })
        }
        else {
            if (params.exercise_filter_subcategory_options) {
                params.exercise_filter_subcategory_options = Object.keys(params.exercise_filter_subcategory_options).filter(function (key) {
                    if (params.exercise_filter_subcategory_options[key] === true) {
                        return key;
                    }
                });
            }
            if (params.exercise_filter_subcategory_options_phase) {
                params.exercise_filter_subcategory_options_phase = Object.keys(params.exercise_filter_subcategory_options_phase).filter(function (key) {
                    if (params.exercise_filter_subcategory_options_phase[key] === true) {
                        return key;
                    }
                });
            }
            let newData = this.state.selectedParents.map(String).flat();

            let findData = params.exercise_filter_subcategory_options ? [newData, params.exercise_filter_subcategory_options.flat()] : newData
            params.exercise_filter_subcategory_options = [...new Set(findData.flat())];

            this.props.postExercise({
                ...params,
                practice_id: this.props.practice_id,
                package_version: 2
            }).then((response) => {
                history.goBack();
            }).catch((error) => { });
        }
    }

    transformData(data) {
        let params = Utils.flatternFormData(data, ['position_pictures']);
        return params;
    }

    showMenuData = (id) => {
        this.setState((prevState) => ({
            openMenu: prevState.openMenu === id ? null : id,
        }));
    }


    subNestCate = (e, parentId, subId, optionsData) => {
        const isChecked = e; // Get the current value of the checkbox

        // Clone the selectedParents array to modify
        let storeData = [...this.state.selectedParents];

        // Create a copy of checkedChildren to manage the checked state of children
        const updatedCheckedChildren = {
            ...this.state.checkedChildren,
            [subId]: isChecked // Set the checked state for this child
        };

        // Handle the checkbox state
        if (isChecked) {
            // If the checkbox is checked and the parentId is not already in the array, add it
            if (!storeData.includes(parentId)) {
                storeData.push(parentId);
            }
        } else {
            // Find the specific parent object by parentId
            const parentObject = optionsData.find(parent => parent.id === parentId);

            if (parentObject) {
                // Check if all children for this parent are deselected
                const areAllChildrenDeselected = parentObject.exercise_filter_subcategories_options_phase.every(child => {
                    // Use updatedCheckedChildren to determine if each child is unchecked
                    return !updatedCheckedChildren[child.id]; // Return true if the child is unchecked
                });

                // If all children are deselected, remove the parentId from the selectedParents array
                if (areAllChildrenDeselected) {
                    storeData = storeData.filter(id => id !== parentId);
                }
            }
        }

        // Update the state with new selectedParents and checkedChildren
        this.setState({
            selectedParents: storeData,
            checkedChildren: updatedCheckedChildren
        });
    };


    render() {
        if (_.isEmpty(this.props.initialValues)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listExerciseFilter,
        getExercise,
        postExercise,
        uploadFiles,
        deleteExercise,
        uploadMedia,
        cleanModule: actionCreator.cleanModule,
        showProgress,
        hideProgress,
    }, dispatch);
}
const selector = formValueSelector('ExerciseEditForm');

function mapStateToProps(state, ownProps) {

    let exercise = state.exercise.exercise.exercise;
    let equipment_thumbnail = [];
    let equipment_filename = [];
    let exercise_filter_subcategory_options = [];
    let exercise_filter_subcategory_options_phase = [];
    let cues = [];
    let equipments = [];
    let is_equipImage_uploaded = [];
    if (exercise.shopify_equipment) {
        exercise.shopify_equipment.map((equip, index) => {
            equipment_filename[index] = "thumbnail";
            equipment_thumbnail[index] = equip.thumbnail;
            is_equipImage_uploaded[index] = true;
        });
        exercise.exercise_filter_subcategory_options?.map((equip) => {
            exercise_filter_subcategory_options[equip] = true;
        });
        exercise.exercise_filter_subcategory_options_phase?.map((equip) => {
            exercise_filter_subcategory_options_phase[equip] = true;
        });
    }
    if (exercise.cues) {
        cues = exercise.cues.map((cue) => cue.id)
    }
    if (exercise.equipments) {
        equipments = exercise.equipments.map((equipment) => equipment.id)
    }


    return {
        // CAFSOptions,
        // FSTTOptions,
        // threeDMatrixOptions,
        // cafs: selector(state, 'cafs'),
        // cafs_foot: selector(state, 'cafs_foot'),
        // cafs_knee: selector(state, 'cafs_knee'),
        // cafs_hip: selector(state, 'cafs_hip'),
        // cafs_lumbar: selector(state, 'cafs_lumbar'),  
        // ffst: selector(state, 'ffst'),
        // ffst_foot: selector(state, 'ffst_foot'),
        // ffst_knee: selector(state, 'ffst_knee'),
        // ffst_hip: selector(state, 'ffst_hip'),
        // ffst_lumbar: selector(state, 'ffst_lumbar'),
        // threeDMatrix: selector(state, 'threeDMatrix'),
        // threeDMatrix_prone: selector(state, 'threeDMatrix_prone'),
        // threeDMatrix_supine: selector(state, 'threeDMatrix_supine'),
        // threeDMatrix_side_lying: selector(state, 'threeDMatrix_side_lying'),
        // threeDMatrix_kneeling: selector(state, 'threeDMatrix_kneeling'),
        // threeDMatrix_stand: selector(state, 'threeDMatrix_stand'),
        // threeDMatrix_balance: selector(state, 'threeDMatrix_balance'),

        is_equipImage_uploaded: selector(state, 'is_equipImage_uploaded'),
        equipment_thumbnail: selector(state, 'equipment_thumbnail'),
        equipment_url: selector(state, 'equipment_url'),
        equipment_filename: selector(state, 'equipment_filename'),
        shopify_equipment: selector(state, 'shopify_equipment'),

        // user_guid: selector(state, 'user_guid'),
        exercise: exercise,
        initialValues: {
            ...exercise,
            cues: cues,
            equipments: equipments,
            exercise_filter_subcategory_options: exercise_filter_subcategory_options,
            exercise_filter_subcategory_options_phase: exercise_filter_subcategory_options_phase,
            equipment_thumbnail,
            equipment_filename,
            is_equipImage_uploaded,
            // cafs: false,
            // cafs_foot: false,
            // cafs_knee: false,
            // cafs_hip: false,
            // cafs_lumbar: false,
            // ffst:false,
            // ffst_foot: false,
            // ffst_knee: false,
            // ffst_hip: false,
            // ffst_lumbar: false,
            // threeDMatrix: false,
            // threeDMatrix_prone: false,
            // threeDMatrix_supine: false,
            // threeDMatrix_side_lying: false,
            // threeDMatrix_kneeling: false,
            // threeDMatrix_stand: false,
            // threeDMatrix_balance: false,

        },
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        exerciseFilters: state.exercise.exerciseList.exerciseFilters,
        supportingData: state.exercise.exercise.supportingData,
        position_pictures: selector(state, 'position_pictures'),
        metric: selector(state, 'metric'),
        exercise_thumbnail_url: selector(state, 'exercise_thumbnail_url'),
        video_guid: selector(state, 'video_guid'),
        is_evidence_based: selector(state, 'is_evidence_based'),
        scientific_evidence_guid: selector(state, 'scientific_evidence_guid'),
        scientific_evidence_url: selector(state, 'scientific_evidence_url'),
        is_client_specific: selector(state, 'is_client_specific'),
        category: selector(state, 'category'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'name', 'category', 'client',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ExerciseEditForm = reduxForm(
    {
        form: 'ExerciseEditForm',
        enableReinitialize: true,
        validate
    }
)(ExerciseEdit);

const ExerciseEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExerciseEditForm);

export default ExerciseEditConnected;
