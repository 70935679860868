import { MODES, DEFAULT_MODE, WORKOUT_TARGET_TYPES, WORKOUT_TARGET_CLIENT } from '../constants/constants';

let initialState = {
    typeUCase: null,
    typeLCase: null,
    typeParam: null,
    programType: null,
    engagement_id: null,
    group_id: null,
    mode: DEFAULT_MODE,
    workoutTargetType: WORKOUT_TARGET_CLIENT,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PROGRAM_TYPE_SET":
            return Object.assign({}, state, {
                typeUCase: action.displayUCase,
                typeLCase: action.displayLCase,
                typeParam: action.typeParam,
                programType: action.programType,
            });
        case "PROGRAM_MODE_SET":
            if (MODES.indexOf(action.mode) === -1) {
                console.error('Invalid "mode" supplied to module Program');
            }
            return Object.assign({}, state, {
                mode: action.mode,
            });
        case "PROGRAM_WORKOUT_TARGET_SET":
            if (WORKOUT_TARGET_TYPES.indexOf(action.workoutMode) === -1) {
                console.error('Invalid "workout target type" supplied to module Program');
                return state;
            }
            return Object.assign({}, state, {
                workoutTargetType: action.workoutTargetType,
                engagement_id: action.workoutTargetType === WORKOUT_TARGET_CLIENT ? action.workoutTargetId : null,
                group_id: action.workoutTargetType === WORKOUT_TARGET_CLIENT ? null : action.workoutTargetId,
            });
        case "PROGRAM_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};