import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progression_edit_new";
import { reduxForm, formValueSelector, arrayRemove, arrayPush } from 'redux-form';

import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { getProgression, postProgression } from '../../redux/program/network/progression_edit';
import {toggleProgramPublish, toggleWorkoutPublish } from '../../redux/program/network/program_preview';
import { getProgram } from '../../redux/program/network/program_edit';
import actionCreator from '../../redux/program/actions/progression_edit';
import Swal from 'sweetalert2';
import Utils from '../../utils/utils';
import TextInput from '../../utils/common_components/textInput_form';
import Select from '../../utils/common_components/select';
import FormLabel from '../../utils/common_components/label_form';
import { Field, FieldArray } from 'redux-form';
import Button from 'react-toolbox/lib/button';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Sortable from "react-anything-sortable";
import SortableItem from "../../utils/common_components/sortable_item";
import Input from 'react-toolbox/lib/input';
import { history } from "../../redux/store";
import moment from 'moment';
import {PARTNER} from '../../core/constants/constants';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Tooltip } from '@mui/material';
import { ReorderOutlined } from '@mui/icons-material';
// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
//   padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "none",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgrey" : "none",
  padding: grid,
  width: '100%'
});
class ProgressionEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.deleteExercise = this.deleteExercise.bind(this);
        this.addExercise = this.addExercise.bind(this);
        this.transformData = this.transformData.bind(this);
        this.cancel = this.cancel.bind(this);
        this.renderExercises = this.renderExercises.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.deleteExerciseTag = this.deleteExerciseTag.bind(this);
        this.addExerciseTag = this.addExerciseTag.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.exerciseMetricsApplyToAll = this.exerciseMetricsApplyToAll.bind(this);
        this.exerciseMetricesApplyFromGlobal = this.exerciseMetricesApplyFromGlobal.bind(this);
        this.copyToExercise = this.copyToExercise.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
        this.finishDatesSelection = this.finishDatesSelection.bind(this);
        this.togglePublish = this.togglePublish.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.addDelay = this.addDelay.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.state = {
            datePickerVisible: false,
            publish: "",
            items: getItems(10)

        };
    }

    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    togglePublish(publish) {
        if (this.props.programType === 'workout' || this.props.group_id ) {
           this.props.toggleWorkoutPublish({
            id: this.props.program.id,
            'is_publish': publish,
        }).then(()=>{
            this.props.finishEditingMoveBack()
        });
       } else {
           this.props.toggleProgramPublish({
               id: this.props.program.id,
               'is_publish': publish,
           }).then(() => {
                this.props.finishEditingMoveBack()
           }).catch(() => { });
       }
    }

    finishDatesSelection(selectedDates) {
        let days = Utils.toDayArray(this.props.program.start_date, selectedDates);
        this.props.change('days', days);
        this.props.change('selected_dates', selectedDates);
        let daysDisplay = _.map(selectedDates, (item) => { return Utils.parseDateAsString(item) });
        this.props.change('days_display', daysDisplay);
        this.showDatePicker(false);
    }

    showDatePicker(flag) {
        this.setState({
            datePickerVisible: flag,
        });
    }

    toggleExpand(index) {
        let exercises = this.props.exercises;
        this.props.change("exercises[" + index + "].expanded", !exercises[index].expanded);
    }

    collapseAll() {
        let exercises = this.props.exercises;
        for (let index in exercises) {
            this.props.change("exercises[" + index + "].expanded", false);
        }
    }

    componentWillMount() {
        this.props.getProgression({
            // program_id: this.props.program_id,
            id: String(this.props.progression_id) !== '-1' ? this.props.progression_id : null,
        });
    }

    handleSort(exercises) {
        let sortedArr = []
        exercises = _.map(exercises, (itemval, index) => {
            let item = _.cloneDeep(itemval);
            item.order = index + 1;
            let indexVal = 'exercises[' + index + "]";
            // exercises.splice(indexVal, item) 
            this.props.change(indexVal, item);
            return item;
        });
    }
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        const items = reorder(
          this.props.exercises,
          result.source.index,
          result.destination.index
        );
        // items = _.map(items, (itemval, index) => {
        //     let item = _.cloneDeep(itemval);
        //     item.order = index + 1;
        //     let indexVal = 'exercises[' + index + "]";
        //     // exercises.splice(indexVal, item) 
        //     // this.props.change(indexVal, item);
        //     return item;
        // });
        items.map((item,index) =>{
            item.order = index +1 
            let indexVal = 'exercises[' + index + "]";
            this.props.change(indexVal, item);
            return item
        })
        // this.props.change('exercises',items);
      }
    deleteExerciseTag(exerciseIndex, tagIndex) {
        store.dispatch(arrayRemove(this.props.form, "exercises[" + exerciseIndex + "].tags", tagIndex));
    }

    addExerciseTag(exerciseIndex, tag) {
        store.dispatch(arrayPush(this.props.form, "exercises[" + exerciseIndex + "].tags", tag));
    }

    renderExercises(props) {
        let allExercises = props.fields.getAll();
        return (
            <div className="col-xs-12 m-t-10">
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    >
                    {props.fields.map((exercise, index) => {
                        let exerciseObject = allExercises[index];
                        let icon = exerciseObject.expanded ? 'chevron-down' : 'chevron-right';
                        return (
                        <Draggable key={String(exerciseObject.id ? exerciseObject.id : exerciseObject.exercise_id)} draggableId={String(exerciseObject.id ? exerciseObject.id : exerciseObject.exercise_id)} index={index}>
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}                           
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                            <div>
                                <div className="col-xs-12  bg-accent f-18 start-xs p-5">
                                    <div className="align-center col-xs-12 row middle-xs" onClick={() => this.toggleExpand(index)} >
                                        <div className="col-xs-1">
                                            <IconButton icon={icon} tooltip="Toggle" className="c-white w-15px" />
                                        </div>
                                        <div className="col-xs-7 start-xs">
                                            <Field name={`${exercise}.name`} label="Name" readOnly="{true}" component={FormLabel} className="w-100" />
                                            <p>{exercise.name}</p>
                                        </div>
                                        {
                                            !this.props.disableEditing &&
                                            <div className="col-xs end-xs d-flex flex-align-center">
                                                <IconButton icon="delete-forever" tooltip="Remove Exercise" className="c-white" onClick={() => this.deleteExercise(index)} />
                                                <div {...provided.dragHandleProps} style={{height:'20px'}}>
                                                    <Tooltip title="Reorder Exercise"><ReorderOutlined fontSize='20px' /></Tooltip>
                                                </div>
                                            </div>
                                        }    
                                    </div>
                                </div>
                                <div className={"col-xs-12 p-10 " + (exerciseObject.expanded ? '' : 'hide')}>
                                    <div className="row">
                                        <div className={this.props.pristine ? "col-xs-12 border-around-search m-b-15 p-relative form-field--is-filled" : "form-field--is-filled col-xs-12 border-around-search m-b-15 p-relative"}>
                                            <span class="placeholder-text">Sets</span>
                                            <Field name={`${exercise}.sets`} component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                        </div>
                                    </div>
                                    {
                                        exerciseObject.metric === 1 &&
                                        <div className="row">
                                            <div className={this.props.pristine ? "col-xs-12 border-around-search m-b-15 p-relative form-field--is-filled" : "form-field--is-filled col-xs-12 border-around-search m-b-15 p-relative"}>
                                            <span class="placeholder-text">Reps</span>
                                                <Field name={`${exercise}.reps`} component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        exerciseObject.metric === 2 &&
                                        <div className="row">
                                            <div className="col-xs-6 border-around-search m-b-15 p-relative">
                                            <span class="placeholder-text">Distance</span>
                                                <Field name={`${exercise}.distance`} component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                            </div>
                                            <div className="col-xs-6">
                                                <Field name={`${exercise}.distance_unit`} label="Distance Unit" component={Select} options={this.props.supportingData.distance_unit} className="w-100" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        exerciseObject.metric === 3 &&
                                        <div className="row">
                                            <div className="col-xs-6 border-around-search m-b-15 p-relative">
                                            <span class="placeholder-text">Duration</span>
                                                <Field name={`${exercise}.duration`} component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                            </div>
                                            <div className="col-xs-6 border-around">
                                                <Field name={`${exercise}.duration_unit`} label="Duration Unit" component={Select} options={this.props.supportingData.duration_unit} className="w-100" />
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="w-48 m-b-15 p-relative m-r-6">
                                            <Field name={`${exercise}.hold`} label="Hold Position" component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                        </div>
                                        <div className="w-48">
                                            <Field name={`${exercise}.hold_unit`} label="Hold Unit" component={Select} options={this.props.supportingData.duration_unit} className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="w-48 m-b-15 p-relative m-r-6">
                                            <Field name={`${exercise}.pause`} label="Rest Between Sets" component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                        </div>
                                        <div className="w-48 ">
                                            <Field name={`${exercise}.pause_unit`} label="Rest Unit" component={Select} options={this.props.supportingData.duration_unit} className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="w-48 m-b-15 p-relative m-r-6">
                                            <Field name={`${exercise}.weight`} label="Weight" component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                        </div>
                                        <div className="w-48">
                                            <Field name={`${exercise}.weight_unit`} label="Weight Unit" component={Select} options={this.props.supportingData.weight_unit} className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={this.props.pristine ? "col-xs-12 border-around-search m-b-15 p-relative form-field--is-filled" : "form-field--is-filled col-xs-12 border-around-search m-b-15 p-relative"}>
                                        <span class="placeholder-text">Target Heart Rate</span>
                                            <Field name={`${exercise}.heart_rate`} component={TextInput} onClick={this.inputClick} type="number" className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 select-chips m-b-15">
                                            <Field name={`${exercise}.cues`} label="Cues" valueKey="id" labelKey="name"
                                                component={Select} options={this.props.supportingData.cues}
                                                multi={true} className="w-100 p-t-0 m-b-20" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 select-chips m-b-15">
                                            <Field name={`${exercise}.equipments`} label="Equipment" valueKey="id" labelKey="name"
                                                component={Select} options={this.props.supportingData.equipments}
                                                multi={true} className="w-100 p-t-0 m-b-20" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={this.props.pristine ? "col-xs-12 m-b-15 p-relative form-field--is-filled" : "form-field--is-filled col-xs-12 m-b-15 p-relative"}>
                                        <span class="placeholder-text">Notes</span>
                                            <Field name={`${exercise}.notes`}
                                                component={TextInput} onClick={this.inputClick}
                                                multiline
                                                rows="{2}"
                                                className="w-100 textarea" />
                                        </div>
                                    </div>
                                    { !this.props.disableEditing &&
                                        <div className="row m-b-15">
                                            <div className="col-xs-12 d-flex justify-center flex-column">
                                    {/*<Button label="Apply from Global" className="p-l-6 p-r-6 text-capital m-b-10" primary raised onClick={() => { this.exerciseMetricesApplyFromGlobal(index); }} />*/}
                                            <Button label="Apply to All" className="p-l-6 p-r-6 text-capital" primary raised onClick={() => { this.exerciseMetricsApplyToAll(index); }} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <p>TESTING: {exercise.name}</p> */}
                            </div>
                        )}
                        </Draggable>)
                    })}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            </div>
        );
    }

    cancel() {
        this.props.moveToBuild();
    }

    deleteExercise(index) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete this exercise from progression?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
                store.dispatch(arrayRemove(that.props.form, "exercises", index));
                Utils.showSuccessAlert("Exercise has been removed from this progression");
            }
        }, function (dismiss) {

        });
    }

    addExercise(exercise) {
        exercise = _.cloneDeep(exercise);
        exercise.exercise_id = exercise.id;
        delete exercise.id;
        exercise.order = this.props.exercises.length + 1;
        exercise.tags = [];
        store.dispatch(arrayPush(this.props.form, "exercises", exercise));
        Utils.showSuccessAlert("Exercise has been added to workout");
    }

    exerciseMetricsApplyToAll(index) {
        let exercises = this.props.exercises;
        let exercise = exercises[index];
        for (let currentIndex in exercises) {
            if (currentIndex !== index) {
                this.copyToExercise(currentIndex, exercise);
            }
        }
        Utils.showSuccessAlert("Metric Settings from selected exercise have been applied to all exercises.");
    }

    copyToExercise(index, source) {
        this.props.change('exercises[' + index + '].sets', source.sets);
        this.props.change('exercises[' + index + '].reps', source.reps);
        this.props.change('exercises[' + index + '].distance', source.distance);
        this.props.change('exercises[' + index + '].distance_unit', source.distance_unit);
        this.props.change('exercises[' + index + '].duration', source.duration);
        this.props.change('exercises[' + index + '].duration_unit', source.duration_unit);
        this.props.change('exercises[' + index + '].weight', source.weight);
        this.props.change('exercises[' + index + '].weight_unit', source.weight_unit);
        this.props.change('exercises[' + index + '].hold', source.hold);
        this.props.change('exercises[' + index + '].hold_unit', source.hold_unit);
        this.props.change('exercises[' + index + '].pause', source.pause);
        this.props.change('exercises[' + index + '].pause_unit', source.pause_unit);
        this.props.change('exercises[' + index + '].heart_rate', source.heart_rate);
    }

    exerciseMetricesApplyFromGlobal(index) {
        if (this.props.globalMetricSettings.sets == null) {
            Utils.showWarningAlert("Global setting not yet in place. Save your work and then select \"Global Metric Settings\" in your settings menu in upper right");
            return;
        }
        this.copyToExercise(index, this.props.globalMetricSettings);
        Utils.showSuccessAlert("Global Metric Settings have been applied to selected exercise.");
    }

    submitForm() {
        this.props.handleSubmit(this.doSave)();
    }

    onDraft() {
        this.setState({ publish: false }, () => {
            this.props.handleSubmit(this.doSave)();
        });
    }

    onPublish() {
        this.setState({ publish: true }, () => {
            this.props.handleSubmit(this.doSave)();
        });
    }

    addDelay = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    doSave(data) {
        let params = this.transformData(data);
        params.program_id = this.props.program_id;
        console.log('save vals', params.exercises, this.props.exercises)
        params.exercises = this.props.exercises
        this.props.postProgression({ ...params }).then(() => {
            this.props.getProgram({ id: this.props.program.id }).then(() => {
                if(this.state.publish===false){
                    this.togglePublish(this.state.publish);
                    // this.addDelay(1000).then(r => {
                    //     history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + '/list');
                    // })
                  }else if(this.state.publish===true){
                      if(this.props.from_gameplan  ||  this.props.group_id ){
                          this.props.listWorkouts({ 
                            app_version: "5.1.12",
                            current_role_type: "practitioner",
                            package_version: "2",
                            practice_id: this.props.practice.id,
                              treatment_template_id: this.props.treatment_template_id, 
                              group_id: this.props.group_id 
                            })
                          if(this.props.group_id){
                              this.togglePublish(this.state.publish);
                              this.props.editGroupWorkoutToggle()
                            }
                            if(this.props.from_gameplan){
                                this.togglePublish(this.state.publish);
                                this.props.editWorkoutToggle()
                              }
                            }else if(this.props.programType === 'workout'){
                                this.togglePublish(this.state.publish);
                          } else {
                          this.togglePublish(this.state.publish);
                           this.addDelay(1000).then(r => {
                            history.push(Utils.getProgramUrlPrefix(this.props.programType,this.props.selectedRole) + '/list');
                        })
                      }
                  }
            });
        }).catch(() => { });
    }

    transformData(data) {
        let progression = Utils.flatternFormData(data, ["exercises"]);
        progression.exercises = _.map(progression.exercises, (item) => {
            let flat_item = Utils.flatternFormData(item);
            if(_.has(item,"exercise")){
                flat_item.exercise_id = item.exercise.id;
            }
            return flat_item;
        });
        return progression;
    }

    render() {
        if (_.isEmpty(this.props.progression)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // getProgression,
        // postProgression,
        toggleProgramPublish,
        toggleWorkoutPublish,
        cleanModule: actionCreator.cleanModule,
    }, dispatch);
}
const selector = formValueSelector('ProgressionEditForm');

function mapStateToProps(state, ownProps) {    
    let progression = state.program.progressionEdit.progression;
    let initialValues;
    if (_.isEmpty(progression)) {
        initialValues = undefined;
    } else {
        progression = _.cloneDeep(progression);
        if (ownProps.programType === 'workout') {
            let startDate = ownProps.program.start_date;
            let availDays = ownProps.program.rest_days;
            progression.selected_dates = Utils.toDateArray(startDate, progression.days);
            progression.available_dates = progression.selected_dates.concat(Utils.toDateArray(startDate, availDays));
            // let today_date = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
            // progression.available_dates = _.filter(progression.available_dates,(available_date)=>{
            //     let date =   moment(available_date).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
            //     if(date >= today_date){
            //         return true;
            //     }else{
            //         return false;
            //     }
            // });
            progression.days_display = _.map(progression.selected_dates, (item) => { return Utils.parseDateAsString(item) });
        }
        let cuesUpdated = progression.exercises.map((exercise) => {
            exercise.cues = exercise.cues.map((cue) => {
                return cue.id
            })
            return exercise
        })
        let equipments = progression.exercises.map((exercise) => {
            exercise.equipments = exercise.equipments.map((equip) => {
                return equip.id
            })
            return exercise
        })
        initialValues = progression;
    }
    return {
        user: state.auth.authReducer.user,
        globalMetricSettings: state.user.practitionerMetricSettings.settings,
        progression: progression,
        initialValues: initialValues,
        supportingData: state.program.progressionEdit.supportingData,
        name: selector(state, 'name'),
        exercises: selector(state, 'exercises'),
        days_display: selector(state, 'days_display'),
        selected_dates: selector(state, 'selected_dates'),
        practice: state.auth.roleReducer.selectedRole.practice,
        program: state.program.programEdit.program,
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'name','duration'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ProgressionEditForm = reduxForm(
    {
        form: 'ProgressionEditForm',
        enableReinitialize: true,
        validate
    }
)(ProgressionEdit);

const ProgressionEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgressionEditForm);

export default ProgressionEditConnected;
