
let initialState = {
    facility_list: [],
    facility: {},
    booking_slots: [],
    booking_slot: {},
    client_list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "STUDIO_FACILITY_LIST":
            if (!action.facility_list){
                return state;
            }
            return Object.assign({}, state, {
                facility_list: action.facility_list,
            });
        case "STUDIO_FACILITY_OBJECT":
            if (!action.facility){
                return state;
            }
            return Object.assign({}, state, {
                facility: action.facility,
            });            
         case "STUDIO_FACILITY_BOOKING_LIST":
            if (!action.facility || !action.booking_slots){
                return state;
            }
            return Object.assign({}, state, {
                facility: action.facility,
                booking_slots: action.booking_slots
            });                                
         case "STUDIO_FACILITY_BOOKING_OBJECT":
            if (!action.facility ||  !action.booking_slot){
                return state;
            }
            return Object.assign({}, state, {
                facility: action.facility,
                booking_slot: action.booking_slot,
                client_list: action.client_list,
            });                        

        case "STUDIO_FACILITY_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
