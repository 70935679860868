import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Bookings for ' + this.props.facility.name,
        'subtitle': moment(this.props.booking_slot.date, 'YYYY-MM-DD').format('MMM DD, YYYY - dddd') + '(' + moment(this.props.booking_slot.start_time).format('h:mm a') + ' to ' + moment(this.props.booking_slot.end_time).format('h:mm a') + ' )',
        'className': 'm-b-10 text-left'
    }), React.createElement(CardText, {}, React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'Name: '), React.createElement('div', { 'className': 'col-xs-10 material-label' }, this.props.facility.name)), React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'Note: '), React.createElement('div', { 'className': 'col-xs-10 material-label' }, this.props.facility.description)), React.createElement('div', { 'className': 'row vertical-align m-b-10' }, React.createElement('div', { 'className': 'col-xs-2 text-right' }, 'Slot Availability: '), React.createElement('div', { 'className': 'col-xs-10 text-left' }, React.createElement('span', { 'className': 'badge badge-success' }, '\n                                        ', this.props.booking_slot.booking_count, ' \n                                '), ' /\n                                ', React.createElement('span', { 'className': 'badge badge-success' }, '\n                                        ', this.props.facility.max_client_count, ' \n                                ')), this.props.disable_booking ? React.createElement('div', {
        'className': 'alert-warning col-xs-12 m-10',
        'key': '2336'
    }, '\n                                This Studio Facility can no longer be booked! \n                            ') : null), React.createElement('div', {}, React.createElement(Button, {
        'label': ` ${ this.props.is_booked ? 'Cancel Booking' : 'Book' }  `,
        'className': ` ${ this.props.is_booked ? 'bg-danger' : 'bg-success' }  ` + ' m-r-10',
        'disabled': this.props.disable_booking,
        'onClick': this.doBook,
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Close',
        'onClick': this.props.closeBookingDialog,
        'className': 'bg-accent',
        'type': 'button',
        'primary': true,
        'raised': true
    })))));
}