import React from 'react';
import Button from 'react-toolbox/lib/button';
import Tooltip from 'react-toolbox/lib/tooltip';

const TooltipFunction = (props)=>{
    const TooltipButton = (props.tooltip || props.Tooltip) ? Tooltip(Button) : Button;
    return (
          <TooltipButton {...props} />  
    );
};
export default TooltipFunction;