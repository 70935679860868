import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import MailComposer from '../../../utils/common_components/mail_composer';
import TreatmentTemplateList from '../../../treatment_template/components/treatment_template_list';
import { Panel } from 'react-toolbox';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
export default function () {
    function repeatGroup1(group, groupIndex) {
        return React.createElement('div', {
            'className': 'box-shadow bg-white m-10 width-300 ribbon-container',
            'key': 'group' + group.id
        }, React.createElement('div', {
            'className': 'row pointer',
            'onClick': event => this.goToGroupDashboard(event, group.id)
        }, group.payment_plan ? React.createElement('div', {
            'className': 'ribbon f-10 c-white',
            'style': { backgroundColor: group.payment_plan_color },
            'key': '3079'
        }, '\n                    ', group.payment_plan.name, '\n                ') : null, !group.payment_plan ? React.createElement('div', {
            'className': 'ribbon f-10 c-white',
            'style': { backgroundColor: group.payment_plan_color },
            'key': '3321'
        }, '\n                    Bronze Plan\n                ') : null, React.createElement('div', { 'className': 'col-xs-6' }, group.is_logo_uploaded ? React.createElement('div', {
            'className': 'thumbnail profile-picture-normal',
            'style': { backgroundImage: 'url(' + encodeURI(group.logo_url) + ')' },
            'key': '3594'
        }) : null, !group.is_logo_uploaded ? React.createElement(ProfilePicture, {
            'user': group,
            'key': '3774'
        }) : null), React.createElement('div', {
            'className': 'col-xs-6 p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', { 'className': 'end-xs' }, React.createElement(TooltipWrapper, { 'tooltip': group.name }, React.createElement('h6', { 'className': 'two-lines text-right' }, '\n                                        ', group.name, '\n                                ')), React.createElement('div', { 'className': 'f-12 f-100 m-t-5 truncate' }, '\n                            ', group.__group_treatment_cycle, '\n                        ')), React.createElement('div', {
            'className': 'p-absolute',
            'style': {
                top: '75px',
                right: '10px'
            }
        }, this.state.groupStatus === 'active' ? [
            React.createElement(IconButton, {
                'tooltip': 'Deactivate this group so all content is hidden from clients\' view',
                'icon': 'gm-hide',
                'gomotive': true,
                'className': 'c-black small-icon-button round-border',
                'onClick': () => {
                    this.hideGroup(group.id);
                },
                'key': '45391'
            }),
            React.createElement(IconButton, {
                'tooltip': 'Email all clients',
                'icon': 'gm-message',
                'gomotive': true,
                'className': 'c-black small-icon-button round-border m-l-5',
                'onClick': () => {
                    this.showMailComposer(group);
                },
                'key': '45393'
            })
        ] : null, this.state.groupStatus === 'inactive' ? React.createElement(IconButton, {
            'tooltip': 'Activate this group for your practice',
            'icon': 'gm-show',
            'gomotive': true,
            'className': 'c-black small-icon-button round-border',
            'onClick': () => {
                this.hideGroup(group.id);
            },
            'key': '5351'
        }) : null))));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'm-15' }, !this.state.showGroupTemplates ? React.createElement('div', { 'key': '1535' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12 col-sm-10 col-md-8 p-relative search-box m-b-20 p-5 vertical-align' }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'placeholder': 'Search Groups',
        'onChange': this.updateSearchText,
        'onKeyPress': this.searchGroups,
        'type': 'text',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-success m-r-10',
        'tooltip': 'Add new group',
        'onClick': this.addGroup,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    }), React.createElement(Button, {
        'className': 'bg-primary m-r-10',
        'onClick': this.drawerToggle,
        'tooltip': 'Click to change filter',
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true
    }))), this.props.group_list.length === 0 ? React.createElement('div', {
        'className': 'row m-20 center-xs alert-warning',
        'key': '2600'
    }, '\n            Click on + button to create a new group for your practice.\n        ') : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.group_list, repeatGroup1.bind(this))
    ]), this.props.paginate_info.total_pages && this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '5819'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more groups...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.getData(true);
        }
    }))) : null, React.createElement(Dialog, {
        'active': this.state.mailComposerVisible,
        'className': 'dialog-title-center',
        'title': this.state.currentlyMailingGroup ? 'Message ' + this.state.currentlyMailingGroup.name : '',
        'onEscKeyDown': this.hideMailComposer,
        'onOverlayClick': this.hideMailComposer
    }, React.createElement(MailComposer, {
        'hideEmail': true,
        'onSubmit': this.onMailComposerSubmit
    }))) : null, this.state.showGroupTemplates ? React.createElement('div', { 'key': '6657' }, React.createElement(TreatmentTemplateList, {
        'match': { params: { treatment_type: 'group' } },
        'create_treatment': true,
        'hideTreatmentTemplateSelection': this.hideGroupTemplateSelection
    })) : null)), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow',
        'width': 50
    }, React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'p-b-30 col-xs-12' }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n                        Filters\n                    '), React.createElement(Button, {
        'onClick': this.applyFilter,
        'className': 'small-button bg-success m-l-10',
        'label': 'Apply',
        'raised': true
    }), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), React.createElement('div', {}, React.createElement('div', { 'className': 'filter-section p-10 text-left' }, React.createElement('label', { 'className': 'medium-label' }, 'Group Status'), React.createElement(Select, {
        'className': 'col-xs-11',
        'value': this.state.groupStatus,
        'options': this.groupStatusList,
        'simpleValue': true,
        'clearable': false,
        'onChange': val => {
            this.onChangeGroupCategory(val);
        }
    })), React.createElement('div', { 'className': 'filter-section p-10 text-left' }, React.createElement('label', { 'className': 'medium-label' }, 'Group Types'), React.createElement(Select, {
        'className': 'col-xs-11',
        'value': this.state.groupType,
        'options': this.groupTypes,
        'simpleValue': true,
        'clearable': false,
        'onChange': val => {
            this.onChangeGroupType(val);
        }
    })), this.props.selectedRole.practice.is_business_partner && !this.props.selectedRole.is_consultant ? React.createElement('div', {
        'className': 'filter-section p-10 text-left',
        'key': '8805'
    }, React.createElement('label', { 'className': 'medium-label' }, 'Dependent Practitioner\'s Groups'), React.createElement(Select, {
        'className': 'col-xs-11',
        'value': this.state.selectedConsultant,
        'options': this.props.consultant_list,
        'simpleValue': true,
        'clearable': true,
        'onChange': val => {
            this.onChangeConsultantSelection(val);
        }
    })) : null)))));
}