import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import WorkfitVideos from '../workfit_videos.jsx';
import WorkfitVideoEdit from '../workfit_video_edit.jsx';
import WorkfitNotificationTemplate from '../workfit_notification_template.jsx';
import WorkfitNotificationTemplateEdit from '../workfit_notification_template_edit.jsx';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 m-t-20 p-0' }, React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/workfit/videos`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Videos')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/workfit/notification_templates`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Notification Templates'))), React.createElement(Switch, {}, React.createElement(Route, {
        'path': `${ this.props.match.url }/videos/add`,
        'component': WorkfitVideoEdit
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/videos/edit/:workfit_video_id`,
        'component': WorkfitVideoEdit
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/videos`,
        'component': WorkfitVideos
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/notification_templates/add`,
        'component': WorkfitNotificationTemplateEdit
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/notification_templates/edit/:notification_template_id`,
        'component': WorkfitNotificationTemplateEdit
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/notification_templates`,
        'component': WorkfitNotificationTemplate
    })));
}