import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import { Link } from 'react-router-dom';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';

class NotFound extends BaseComponent {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        history.push('/');
    }

    render() {
        return (
            <div className="row m-25">
                <h2 className="col-xs-12">Page Not Found</h2>
                <Link   to="/" className="col-xs-12">Back to Home</Link>   
            </div>    
        );
    }
}

export default NotFound;
