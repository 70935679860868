import thunkMiddleware from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
// import {  routerMiddleware } from 'react-router-redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer, { createRootReducer } from './root_reducer';
import Utils from '../utils/utils';
import stateImmutable from "redux-immutable-state-invariant";
import {createBrowserHistory} from 'history';

const defaultState = {};
const history = createBrowserHistory();
let middleware = [thunkMiddleware,routerMiddleware(history)];

let store = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const storeNew = createStore(
      createRootReducer(history), // root reducer with router state
      defaultState,
      composeEnhancers(
        applyMiddleware(...middleware),
      ),
    )

if (module && module.hot && __DEV__ && process.env.BROWSER) {
    module.hot.accept('./root_reducer', () => {
      const nextReducer = require('./root_reducer').default;
      store.replaceReducer(nextReducer);
    });
}

export { history };
export default storeNew;
