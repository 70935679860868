import  React  from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import { reduxForm,formValueSelector } from 'redux-form';
import Template from "./templates/homefit_workouts.rt";
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { bindActionCreators } from 'redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Swal from 'sweetalert2';
import { getMoveVideoList, deleteMoveVideo, toggleFavoriteMoveVideo, trackHomeFitWorkout } from '../../../redux/dhf/move/network/move_network';
import MoveAction from '../../../redux/dhf/move/actions/move_actions';

class HomeFitWorkouts extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.viewMoveVideo = this.viewMoveVideo.bind(this);   
        this.closeMoveVideo = this.closeMoveVideo.bind(this);
        this.subscribeForHomeFit = this.subscribeForHomeFit.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.drawerToggle = this.drawerToggle.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.state = {
            search: "",
            show_move_video: false,
            move_video: {},
            showFilter: false,
            show_favorites: false,
            move_meter: null,
            equipments: null,
            workout_type: null,
            workout_duration: null,
            workout_level: null,
        };
        this.movement_meter_list = [
            {
                value: "Mobility",
                label: "Mobility",
            },
            {
                value: "Strength",
                label: "Strength",
            },
            {
                value: "Metabolic",
                label: "Metabolic",
            },
            {
                value: "Power",
                label: "Power",
            },
        ];
        this.equipment_list = [
            {
                value: "bw",
                label: "Body weight",
            },
            {
                value: "db",
                label: "Dumbbells",
            },
            {
                value: "kb",
                label: "Kettlebells",
            },
            {
                value: "pp",
                label: "Power Plate",
            },
            {
                value: "fm",
                label: "Foam Roller",
            },
            {
                value: "bd",
                label: "Bands",
            }
        ];
        this.workout_type_list = [
            {
                value: "Traditional",
                label: "Traditional",
            },
            {
                value: "Functional",
                label: "Functional",
            }
        ];
        this.workout_level_list = [
            {
                value: "Beginner",
                label: "Beginner",
            },
            {
                value: "Intermediate",
                label: "Intermediate",
            },
            {
                value: "Advanced",
                label: "Advanced",
            }
        ];
        this.workout_duration_list = [
            {
                value: 5,
                label: "5 minutes",
            },
            {
                value: 10,
                label: "10 minutes",
            },
            {
                value: 15,
                label: "15 minutes",
            },
            {
                value: 20,
                label: "20 minutes",
            },
            {
                value: 25,
                label: "25 minutes",
            },
            {
                value: 30,
                label: "30 minutes",
            },
        ];

    }

    componentWillMount() {
        this.props.cleanUp();
        this.fetchData(0);
    }

    fetchData(page) {
        this.props.getMoveVideoList({
            'search_text': this.state.search,
            'show_favorites': this.state.show_favorites,
            'move_meter': this.state.move_meter,
            'equipments': this.state.equipments,
            'function': this.state.workout_type,
            'time': this.state.workout_duration,
            'level': this.state.workout_level,
            'page': page
        });
    }

    loadMore() {
        this.fetchData(this.props.paginate_info.page+1)
    }

    onSearch(text){
        this.setState({search:text},()=>{
            this.fetchData(0);
        });
    }

    viewMoveVideo(video) {
        if (!video.is_free && !this.props.is_homefit_subscription_valid) {
            Swal.fire({
                title: "Are you sure?",
                text: "Would you like to subscribe to DHF HomeFit to view all workouts?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections: true
            }).then(() => {
                history.push('/hf/subscriptions');
            }, () => { });
        } else {
            this.setState({
                show_move_video: true,
                move_video: video,
            });
        }
    }

    closeMoveVideo() {
        let curr_move_video = this.state.move_video;
        this.setState({
            show_move_video: false,
            move_video: {}
        }, () => {
            Swal.fire({
                title: "Did you workout?",
                text: "Did you complete this workout? If yes, we will update your Weekly Movement Goal",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I did!",
                cancelButtonText: "No",
                useRejections: true
            }).then(() => {
                let activity_obj = {};
                activity_obj["key"] = "workout";
                let params = {};
                params["activity_obj"] = activity_obj;
                params["workout_id"] = curr_move_video["id"];
                this.props.trackHomeFitWorkout(params);
                Utils.showSuccessAlert("Congratulations!!! Your weekly movement goal has been updated as you have completed the workout.");
                }, () => { 
                });         
        });        
    }


    subscribeForHomeFit(video) {
        if (!video.is_free && !this.props.is_homefit_subscription_valid) {
            Swal.fire({
                title: "Are you sure?",
                text: "Would you like to subscribe to DHF HomeFit to view all workouts?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections:true
            }).then(() => {
                history.push('/hf/subscriptions');
            }, ()=> { });
        }
    }

    drawerToggle = () => {
        this.setState({showFilter: !this.state.showFilter});
    };

    applyFilter(data) {
        this.setState({
            showFilter: false,
            show_favorites: data.show_favorites,
            move_meter: data.move_meter,
            equipments: data.equipments,
            workout_type: data.workout_type,
            workout_duration: data.workout_duration,
            workout_level: data.workout_level,
        }, () => {
            this.fetchData(0);
        });
    }

    toggleFavorite(video) {
        this.props.toggleFavoriteMoveVideo({ workout_id: video.id });
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    render() {
        if (_.isEmpty(this.props.user)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}


function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        move_videos: state.move.moveReducer.move_videos,
        paginate_info: state.move.moveReducer.paginate_info,
        is_homefit_subscription_valid: state.move.moveReducer.is_homefit_subscription_valid,
    };
}

let mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        getMoveVideoList,
        deleteMoveVideo,
        toggleFavoriteMoveVideo,
        trackHomeFitWorkout,
        cleanUp: MoveAction.cleanModule,
    }, dispatch),
    dispatch
});

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const HomeFitWorkoutsForm = reduxForm(
    {
        form: 'HomeFitWorkoutsFilterForm',
        enableReinitialize: true,
        validate
    }
)(HomeFitWorkouts);

const HomeFitWorkoutsFormConnected = connect(mapStateToProps,mapDispatchToProps)(HomeFitWorkoutsForm);
export default HomeFitWorkoutsFormConnected;
