let initialState = {
    workouts: [],
};

export default function engagementWorkout(state = initialState, action) {
    switch (action.type) {
        case "ENGAGEMENT_WORKOUT_LIST_FILL":
            if (!action.workouts){
                return state;
            }            
            return Object.assign({}, state, {
                workouts: action.workouts,
            });
        case "ENGAGEMENT_WORKOUT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
