import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import PartnerPlanDetails from '../plan_selection_details';
import moment from 'moment';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
export default function () {
    function repeatPractice_plan1(practice_plan, practice_planIndex) {
        return [
            React.createElement('div', {
                'className': 'panel col-xs-12 text-left m-b-10 p-0',
                'key': '5311'
            }, React.createElement('div', {
                'className': 'panel-body f-15',
                'style': { borderLeft: '5px solid #0292D3' }
            }, practice_plan.partner.subdomain !== 'app' && practice_plan.partner.subdomain !== 'gi' ? React.createElement('div', { 'key': '755' }, '\n                    Business Partner : ', practice_plan.partner.name, '\n                ') : null, practice_plan.partner.subdomain === 'app' || practice_plan.partner.subdomain === 'gi' ? React.createElement('div', { 'key': '963' }, '\n                    ', practice_plan.partner.name, '\n                ') : null, practice_plan.selected_plan ? React.createElement('p', {
                'className': 'f-15 material-label',
                'key': '1153'
            }, 'Current plan : ', practice_plan.selected_plan.name) : null, practice_plan.partner.subdomain !== 'gi' && practice_plan.selected_plan && practice_plan.selected_plan.plan_type === 1 ? [React.createElement('p', {
                    'className': 'f-13 material-label',
                    'key': '13071'
                }, 'You are on ', practice_plan.partner.trial_period, ' days trial plan, valid through ', moment(practice_plan.trial_closure_date).format('MMMM Do YYYY'))] : null, practice_plan.selected_plan && practice_plan.selected_plan.plan_type === 2 ? [React.createElement('p', {
                    'className': 'f-13 material-label',
                    'key': '17171'
                }, 'You are on fan plan. You have been restricted to view access only. Upgrade to a paid plan to enjoy full features')] : null, practice_plan.selected_plan && practice_plan.selected_plan.plan_type === 3 ? [React.createElement('p', {
                    'className': 'f-13 material-label',
                    'key': '20641'
                }, 'You are on ', practice_plan.selected_plan.name, ' plan. \n                        You are currently paying ', '\xA0', '\n                        ', practice_plan.selected_plan.plan_payment_type === 1 ? React.createElement('strong', { 'key': '2348' }, '  $', practice_plan.selected_plan.fee_amount, ' / month ') : null, practice_plan.selected_plan.plan_payment_type === 2 ? React.createElement('strong', { 'key': '2504' }, '   $', practice_plan.selected_plan.fee_amount, ' / year ') : null, practice_plan.selected_plan.plan_payment_type === 3 ? React.createElement('strong', { 'key': '2660' }, '  $', practice_plan.selected_plan.fee_amount, ' / ', practice_plan.selected_plan.month_duration_count, ' months ') : null)] : null)),
            React.createElement('div', {
                'className': 'col-xs-12',
                'key': '5313'
            }, React.createElement(PartnerPlanDetails, { 'partner_plan': practice_plan }))
        ];
    }
    return React.createElement.apply(this, [
        'div',
        { 'className': 'row m-20' },
        _.map(this.props.payment_plans, repeatPractice_plan1.bind(this))
    ]);
}