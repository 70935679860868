import BaseComponent from '../../utils/common_components/basecomponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Template from "./templates/home";
import MobileTemplate from "./templates/mobile_home";
import store,{history} from '../../redux/store';
import Utils from '../../../js/utils/utils';
import {PARTNER} from '../../core/constants/constants';

class GuestHome extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.gotoPracticeCreate = this.gotoPracticeCreate.bind(this);
        this.gotoClient = this.gotoClient.bind(this);
    }
    componentWillMount() {
        if(this.props.user.is_guest){
            if (PARTNER.allow_partner_signup === false) {
                if (PARTNER.subdomain === "gi" || PARTNER.subdomain === "app") {
                    history.push("/guest/client/connect");
                } else {
                    history.push("/guest/client/connect/practice/" + PARTNER.practice);
                }
            } else if (PARTNER.allow_client_signup === false) {
                history.push('/guest/practice/create');
            }
        }else{
            history.push('/');
        }    
    }
    gotoPracticeCreate(){
        history.push('/guest/practice/create');
    }
    gotoClient(){
        if(PARTNER.subdomain === "app" || PARTNER.subdomain === "gi"){
            history.push('/guest/client/connect');
        }else{
            history.push("/guest/client/connect/practice/"+PARTNER.practice);
        }
    }

    goToAppStore() {
        window.location = "https://itunes.apple.com/in/app/gomotive-remote-trainer/id929479595?mt=8";
    }

    goToApp() {
        let token = Utils.getLocalStorageValue("token");
        window.location = "gmqa://doit?token=" + token;
    }

    render() {
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)){
            return MobileTemplate.apply(this);
        }
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}
function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
    };
}
const GuestHomeConnected = connect(mapStateToProps, mapDispatchToProps)(GuestHome);
export default GuestHomeConnected;