import  React from "react";
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/challenge_workout_edit.rt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../utils/utils';
import { formValueSelector, reduxForm } from 'redux-form';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../../redux/store';
import { getChallengeWorkout, saveChallengeWorkout } from '../../../redux/dhf/move/network/challenge_network';
import ChallengeAction from '../../../redux/dhf/move/actions/challenge_action';


class ChallengeWorkoutEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);    
        this.doCancel = this.doCancel.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.saveChallengeWorkout = this.saveChallengeWorkout.bind(this);
        this.state = {
            video_guid: null,
            thumbnail: null,
        };   
    }
    
    componentWillMount() {
        this.props.getChallengeWorkout({
            challenge_id: this.props.match.params.challenge_id,
            challenge_workout_id: this.props.match.params.challenge_workout_id,
        }).then((response) => {            
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.challengeWorkout.workout_video_guid) {
            this.setState({
                video_guid: nextProps.challengeWorkout.workout_video_guid,
                thumbnail: nextProps.challengeWorkout.workout_thumbnail_url
            });
        }
    }    

    componentWillUnmount() {
        this.props.cleanUp({});
    }

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/move/challenge/"+this.props.match.params.challenge_id+"/workouts");
    }


    onVideoUploadCompleted(result) {
        let that = this;
        setTimeout(function() {
            that.setState({
                video_guid: result.guid,
                thumbnail: result.thumbnail,
            }); 
        }, 500);

    }

    onVideoUploadFailed(error) {        
        this.setState({
            video_guid: null,
            thumbnail: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }


    saveChallengeWorkout(data) {
        if(this.state.video_guid === null) {
            Utils.showErrorAlert("Kindly update workout video");
            return;
        }
        let params = _.omit(data, ['can_be_deleted']);
        if(this.props.match.params.challenge_id !== null) {
            params.challenge_id = this.props.match.params.challenge_id;
        }
        if(this.props.match.params.challenge_workout_id !== null) {
            params.challenge_workout_id = this.props.match.params.challenge_workout_id;
        }    
        if(typeof params.count === "object") {
            params.count = params.count.value;
        }    
        params.workout_video_guid = this.state.video_guid;
        params.workout_thumbnail_url = this.state.thumbnail;
        this.props.saveChallengeWorkout(params).then((response) => {  
            if(response.status !== undefined)          {
                Utils.showSuccessAlert("Workout has been saved.");
                history.push("/practice/"+this.props.selectedRole.practice.id+"/move/challenge/"+this.props.match.params.challenge_id+"/workouts");
            }            
        }).catch((error) => {
        })
    }
        
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        challengeWorkout: state.move.challengeReducer.challenge_workout,
        initialValues: state.move.challengeReducer.challenge_workout,
        supportingData: state.move.challengeReducer.challenge_workout_supporting_data,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getChallengeWorkout,  
            saveChallengeWorkout,              
            cleanUp: ChallengeAction.cleanModule
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'workout_name',
        'workout_duration',
        'count',        
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const ChallengeWorkoutEditForm = reduxForm(
    {
        form: 'ChallengeWorkoutEditForm',
        enableReinitialize: true,
        validate
    }
)(ChallengeWorkoutEdit);


const ChallengeWorkoutEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(ChallengeWorkoutEditForm);
export default ChallengeWorkoutEditFormConnected;