import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../../utils/common_components/tooltipButton';
import Dialog from 'react-toolbox/lib/dialog';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import Input from '../../../../utils/common_components/textInput_form';
import Switch from '../../../../utils/common_components/switch_form';
import Checkbox from '../../../../utils/common_components/checkbox_form';
import RadioGroup from '../../../../utils/common_components/radio_group_form';
import HelpTip from '../../../../utils/common_components/help_tip';
import WelcomeVideo from '../../../../client_app/components/welcome_video';
export default function () {
    function repeatQuestion1(section, sectionIndex, question, questionIndex) {
        return [
            question.measure !== 'boolean' ? React.createElement('div', {
                'className': 'col-xs-11',
                'key': '37301'
            }, React.createElement('div', { 'className': 'row p-l-10 p-t-10' }, React.createElement('div', { 'className': 'col-xs-12 vertical-align' }, React.createElement('label', { 'className': 'di-block material-label p-l-0  m-b-0 f-16' }, question.name), _.has(question, 'video_id') ? React.createElement(Button, {
                'onClick': () => {
                    this.showQuestionVideo({
                        video_guid: question.video_id,
                        welcome_text: question.name
                    });
                },
                'tooltip': 'Watch Video',
                'icon': 'ondemand_video',
                'className': 'nano m-r-5 m-l-5 bg-primary',
                'floating': true,
                'primary': true,
                'mini': true,
                'key': '4173'
            }) : null, _.has(question, 'description') ? React.createElement(HelpTip, {
                'className': 'bg-primary',
                'key': '4680'
            }, '\n                                        ', question.description, '\n                                    ') : null), React.createElement(Field, {
                'className': 'col-xs-12 p-t-15',
                'disabled': this.props.sections && this.props.sections[sectionIndex] ? !(this.props.sections[sectionIndex].assessed === true) : true,
                'name': 'sections[' + sectionIndex + '].questions[' + questionIndex + '].value',
                'hint': `Enter the ${ question.measure }`,
                'component': Input,
                'type': 'number',
                'data-support-float': true
            }))) : null,
            question.measure === 'boolean' ? React.createElement('div', {
                'className': 'col-xs-11',
                'key': '37303'
            }, React.createElement('div', { 'className': 'row p-l-10 p-t-10' }, React.createElement('div', { 'className': 'col-xs-12 vertical-align' }, React.createElement('label', { 'className': 'di-block material-label p-l-0  m-b-0 f-16' }, question.name), _.has(question, 'video_id') ? React.createElement(Button, {
                'onClick': () => {
                    this.showQuestionVideo({
                        video_guid: question.video_id,
                        welcome_text: question.name
                    });
                },
                'tooltip': 'Watch Video',
                'icon': 'ondemand_video',
                'className': 'nano m-r-5 m-l-5 bg-primary',
                'floating': true,
                'primary': true,
                'mini': true,
                'key': '5936'
            }) : null, _.has(question, 'description') ? React.createElement(HelpTip, {
                'className': 'on-left',
                'key': '6467'
            }, '\n                                            ', question.description, '\n                                        ') : null), React.createElement('div', { 'className': 'col-xs-12 m-t-10 m-l-5' }, React.createElement(Field, {
                'className': 'vertical-align w-100 row',
                'disabled': this.props.sections && this.props.sections[sectionIndex] ? !(this.props.sections[sectionIndex].assessed === true) : true,
                'name': 'sections[' + sectionIndex + '].questions[' + questionIndex + '].value',
                'component': RadioGroup,
                'options': this.state.radio_options,
                'colXSNum': 2
            })))) : null,
            React.createElement('div', {
                'className': 'col-xs-1',
                'key': '37305'
            }, this.state.sections && this.state.sections[sectionIndex]['questions'][questionIndex].risk == 'LOWRISK' ? React.createElement('span', {
                'className': `badge badge-success  f-10 no-wrap`,
                'key': '7588'
            }, this.state.sections[sectionIndex]['questions'][questionIndex].riskName) : null, this.state.sections && this.state.sections[sectionIndex]['questions'][questionIndex].risk == 'MEDIUMRISK' ? React.createElement('span', {
                'className': `badge badge-warning  f-10 no-wrap`,
                'key': '7865'
            }, this.state.sections[sectionIndex]['questions'][questionIndex].riskName) : null, this.state.sections && this.state.sections[sectionIndex]['questions'][questionIndex].risk == 'HIGHRISK' ? React.createElement('span', {
                'className': `badge badge-danger  f-10 no-wrap`,
                'key': '8145'
            }, this.state.sections[sectionIndex]['questions'][questionIndex].riskName) : null)
        ];
    }
    function repeatSection2(section, sectionIndex) {
        return React.createElement('div', {
            'className': 'col-xs-12 m-t-15',
            'key': 'section' + sectionIndex
        }, React.createElement('div', { 'className': 'bg-body border-body p-5 w-100 vertical-align' }, React.createElement(Field, {
            'className': 'di-block m-b-0',
            'name': 'sections[' + sectionIndex + '].assessed',
            'onChange': (pF, val) => {
                this.onSectionCheckboxChange(val, sectionIndex, section);
            },
            'component': Checkbox
        }), React.createElement('h5', { 'className': 'di-block w-90  p-5' }, section.name)), React.createElement.apply(this, [
            'div',
            { 'className': 'row' },
            _.map(section.questions, repeatQuestion1.bind(this, section, sectionIndex)),
            _.has(section, 'game_plan_templates') ? React.createElement('div', {
                'className': 'col-xs-12 m-t-10 m-l-5',
                'key': '8477'
            }, React.createElement('label', { 'className': 'di-block' }, 'Choose Game Plan Template'), React.createElement(HelpTip, {}, '\n                                Workouts, Habits, Nutrition Documents & Guidance Documents from the selected game plan template will be auto added to Game Plan \n                        '), React.createElement(Field, {
                'className': 'w-100',
                'disabled': this.props.sections && this.props.sections[sectionIndex] ? !(this.props.sections[sectionIndex].assessed === true) : true,
                'name': 'sections[' + sectionIndex + '].selected_game_plan',
                'component': Select,
                'simpleValue': true,
                'valueKey': 'id',
                'labelKey': 'name',
                'clearable': false,
                'options': section.game_plan_templates
            })) : null
        ]));
    }
    return React.createElement('div', {}, React.createElement(Card, {
        'className': 'w-100',
        'style': { minHeight: '100vh' }
    }, React.createElement(CardText, {
        'className': 'p-t-5 card-full-height',
        'style': { marginBottom: '150px' }
    }, React.createElement('form', {
        'className': 'medium-labels',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement('div', { 'className': 'col-xs-12' }, !this.props.previewMode ? React.createElement(Button, {
            'disabled': this.props.submitting,
            'type': 'submit',
            'className': 'pull-right small-button  bg-success m-t-10',
            'label': 'Done',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'key': '1704'
        }) : null, _.has(this.props.assessment, 'game_plan_templates') ? React.createElement('div', {
            'className': 'di-block pull-right',
            'key': '2076'
        }, React.createElement(Field, {
            'className': 'di-block width-300',
            'disabled': this.props.sections ? _.filter(this.props.sections, { assessed: true }).length === 0 : true,
            'name': 'selected_game_plan',
            'component': Select,
            'simpleValue': true,
            'valueKey': 'id',
            'labelKey': 'name',
            'clearable': false,
            'options': this.props.assessment.game_plan_templates
        })) : null),
        React.createElement('h4', {}, this.props.assessment.name),
        React.createElement('p', {
            'className': 'f-12',
            'style': { lineHeight: '1.5rem' }
        }, this.props.assessment.description),
        _.map(this.props.assessment.sections, repeatSection2.bind(this))
    ])))), this.state.video_object !== null ? React.createElement(WelcomeVideo, {
        'onCloseHandle': this.closeQuestionVideo,
        'welcome_video': this.state.video_object,
        'key': '9675'
    }) : null);
}