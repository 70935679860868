import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import Button from '../../../../utils/common_components/tooltipButton';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { TetheredSelectWrap as Select } from '../../../../utils/common_components/tetheredSelect';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../../utils/common_components/tooltipWrapper';
import Chip from 'react-toolbox/lib/chip';
import VimeoPlayer from '../../../../utils/common_components/vimeo_player';
export default function () {
    function repeatWorkout1(workout, workoutIndex) {
        return React.createElement('div', {
            'className': 'col-xs-12',
            'style': { zIndex: '1' },
            'key': 'workout' + workout.id
        }, React.createElement('div', { 'className': 'row m-t-20' }, React.createElement('div', { 'className': 'col-xs-12 m-t-20' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-1 start-xs' }, React.createElement('div', {
            'className': 'row center-xs bg-warning p-10',
            'style': { width: '80px' }
        }, React.createElement(FontIcon, { 'value': 'timer' }), React.createElement('span', { 'className': 'm-t-5 m-l-5' }, '\n                                        ', workout.count_display, '\n                                    ')), React.createElement('div', { 'className': 'row m-t-20' }, React.createElement(Button, {
            'className': 'bg-primary m-l-16',
            'icon': this.props.disableEditing ? 'pageview' : 'edit',
            'floating': true,
            'ripple': true,
            'mini': true,
            'onClick': () => {
                this.editChallengeWorkout(workout);
            }
        })), !this.props.disableEditing ? React.createElement('div', {
            'className': 'row m-t-10',
            'key': '4437'
        }, React.createElement(Button, {
            'className': 'bg-danger m-l-16',
            'icon': 'delete_forever',
            'floating': true,
            'ripple': true,
            'mini': true,
            'onClick': () => {
                this.deleteChallengeWorkout(workout);
            }
        })) : null), React.createElement('div', { 'className': 'col-xs-11' }, React.createElement('div', {}, workout.workout_name), React.createElement('div', { 'className': 'f-12 m-t-10' }, workout.workout_description), React.createElement('div', {
            'className': 'm-t-10',
            'style': {
                width: '300px',
                height: '200px'
            }
        }, React.createElement(VimeoPlayer, {
            'handleUploadProcessing': true,
            'identifier': 'wrapper_video_' + workout.workout_video_guid,
            'videoList': [workout.workout_video_guid]
        })))))));
    }
    return React.createElement('div', { 'className': 'm-t-30' }, React.createElement('div', { 'style': { position: 'relative' } }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement('div', {
            'className': 'bg-accent',
            'style': {
                marginLeft: '32px',
                zIndex: '0',
                height: 'calc(100% - 1px)',
                width: '10px',
                position: 'absolute'
            }
        }),
        React.createElement('div', {
            'className': 'col-xs-12 bg-accent p-20 box-shadow',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'row f-20 between-xs' }, React.createElement('div', { 'className': 'col-xs-10 text-left' }, React.createElement('h6', { 'className': 'f-20 w-100' }, ' ', this.props.challenge.latest_version.name, ' '), this.props.challenge.latest_version.is_published ? React.createElement('div', {
            'className': 'badge badge-success p-l-8 p-r-8 f-12 f-300',
            'key': '1950'
        }, 'PUBLISHED') : null, !this.props.challenge.latest_version.is_published ? React.createElement('div', {
            'className': 'badge badge-warning p-l-8 p-r-8 f-12 f-300',
            'key': '2103'
        }, 'DRAFT') : null), React.createElement('div', { 'className': 'col-xs-2 text-right' }, !this.props.disableEditing ? React.createElement(Button, {
            'className': 'bg-success c-white m-5 small-button',
            'label': 'SAVE & PUBLISH',
            'onClick': () => {
                this.saveAndPublish();
            },
            'raised': true,
            'key': '2334'
        }) : null, React.createElement('br', {}), !this.props.disableEditing ? React.createElement(Button, {
            'className': 'bg-primary c-white m-5 small-button',
            'label': 'SAVE AS DRAFT',
            'onClick': () => {
                this.saveAsDraft();
            },
            'raised': true,
            'key': '2550'
        }) : null, React.createElement('br', {}), !this.props.disableEditing ? React.createElement(Button, {
            'className': 'bg-white c-black m-5 small-button',
            'label': 'CLOSE',
            'onClick': () => {
                this.doClose();
            },
            'raised': true,
            'key': '2762'
        }) : null)), React.createElement('div', { 'className': 'row middle-xs m-t-10 f-16' }, '\n                    Challenge Duration:\n                    ', React.createElement(Chip, { 'className': 'bg-primary m-l-10 chip-thin' }, this.props.challenge.latest_version.length_display))),
        _.map(this.props.challenge.latest_version.workouts, repeatWorkout1.bind(this)),
        !this.props.disableEditing ? React.createElement(Button, {
            'onClick': () => {
                this.addChallengeWorkout();
            },
            'className': 'bg-success m-l-10 m-t-20',
            'icon': 'add',
            'floating': true,
            'ripple': true,
            'key': '5618'
        }) : null,
        !this.props.disableEditing && this.props.challenge.latest_version.workouts.length === 0 ? React.createElement('div', {
            'className': 'col-xs-12 f-18 start-xs m-t-20 p-0',
            'style': { zIndex: '1' },
            'key': '5821'
        }, React.createElement('span', { 'className': 'bg-danger p-5' }, '\n                    Click on \'+\' button to add a new workout\n                ')) : null
    ])));
}