import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import partnerPlanActionCreator from '../actions/partner_plan_action';

const getPartnerPlanList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_plans_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerPlanActionCreator.partnerPlanListFill(
                response.data,
                response.discount_coupons
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getPartnerPlanDetails = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_plans_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerPlanActionCreator.partnerPlanInstanceFill(
                response.data,
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const postPartnerPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_plans_post";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(partnerPlanActionCreator.partnerPlanInstanceFill(
                response.data,
            ));
            Utils.showSuccessAlert("Successfully updated Business Partner Plan");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const deletePartnerPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_plans_delete";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully deleted the Plan");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updatePartnerPlanDiscount = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/partner_plan_update_discount";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Discount coupon has been successfully updated");
            dispatch(partnerPlanActionCreator.partnerPlanListFill(
                response.data,
                response.discount_coupons
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getPartnerPlanList,
    getPartnerPlanDetails,
    postPartnerPlan,
    deletePartnerPlan,
    updatePartnerPlanDiscount
};