import BaseComponent from '../../utils/common_components/basecomponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Template from "./templates/bundles";
import store, { history } from '../../redux/store';

class Bundles extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addBundle = this.addBundle.bind(this);
        this.editBundle = this.editBundle.bind(this);

    };

    addBundle() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/bundle/add');
    }

    editBundle(user) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/bundle/edit/' + user.id);
    }
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({


    },
        dispatch
    );
}

const BundlesConnected = connect(mapStateToProps, mapDispatchToProps)(Bundles);
export default BundlesConnected;