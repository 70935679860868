
let initialState = {
    move_videos: [],
    shopify_equipment: {},
    paginate_info: {
        page: 0,
    },
    favorite_list: [],
    is_homefit_subscription_valid: false,
    supporting_data: {},
    move_video: {},
    family_member_list:[],
    move_user:{}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "MOVE_VIDEOS_FILL":
            if (!action.move_videos){
                return state;
            }                
            let workout_list = [];
            if(action.paginate_info && action.paginate_info.page ===0 ){
                workout_list = _.map(action.move_videos, (itemObj) => {
                    let item = { ...itemObj };
                    if (_.includes(action.favorite_list,item.id)) {
                        item.is_favorite = true;
                    } else {
                        item.is_favorite = false;
                    }
                    return item;
                });
            }else{
                let newIds = _.map(action.move_videos, (item) => { return item.id; });
                let oldRetain = _.filter(state.move_videos, (item) => {
                    return newIds.indexOf(item.id) === -1;
                });
                let new_workout_list = _.map(action.move_videos, (itemObj) => {
                    let item = { ...itemObj };
                    if (_.includes(action.favorite_list,item.id)) {
                        item.is_favorite = true;
                    } else {
                        item.is_favorite = false;
                    }
                    return item;
                });
                workout_list = oldRetain.concat(new_workout_list);
            }
            return Object.assign({}, state, {
                move_videos: workout_list,
                paginate_info: action.paginate_info,
                favorite_list: action.favorite_list,
                is_homefit_subscription_valid: action.is_homefit_subscription_valid,
            });
        case "MOVE_VIDEO_FILL":
            if (!action.move_video){
                return state;
            }                
            return Object.assign({}, state, {
                move_video: action.move_video,
                supporting_data: action.supporting_data,
                shopify_equipment: action.shopify_equipment,
            });  
        case "MOVE_USER_FILL":
            if (!action.move_user){
                return state;
            }                
            return Object.assign({}, state, {
                move_user: action.move_user,
            });
        case "MOVE_FAMILY_MEMBERS_FILL":
            if (!action.family_member_list) {
                return state;
            }
            return Object.assign({}, state, {
                family_member_list: action.family_member_list,
            });
        case "MOVE_VIDEO_TOGGLE_FAVORITE":            
            let new_favorite_list = state.favorite_list.slice(0, state.favorite_list.length);
            if (action.action_type === "add") {
                var index = new_favorite_list.indexOf(action.workout_id);
                if (index === -1) {
                    new_favorite_list.push(action.workout_id);
                }
            } else {
                var index = new_favorite_list.indexOf(action.workout_id);
                if (index > -1) {
                    new_favorite_list.splice(index, 1);
                }
            }      
            let favorite_workout_list = [];
            favorite_workout_list = _.map(state.move_videos, (itemObj) => {
                let item = { ...itemObj };
                if (_.includes(new_favorite_list,item.id)) {
                    item.is_favorite = true;
                } else {
                    item.is_favorite = false;
                }
                return item;
            });
            return Object.assign({}, state, {
                move_videos: favorite_workout_list,
                favorite_list: new_favorite_list,
            });
        case "MOVE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
