// Action Creators
const recommendGomotiveFill = (recommend_gomotive) => ({
    type: 'RECOMMEND_GOMOTIVE_FILL',
    recommend_gomotive    
});

const cleanup = () => ({
    type: 'RECOMMEND_GOMOTIVE_CLEANUP',
});
export default {
    recommendGomotiveFill,
    cleanup,
};