import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/practice_locations";
import { reduxForm } from 'redux-form';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {practiceLocationList, practiceLocationGet, 
    practiceLocationPost, practiceLocationDelete} from '../../redux/practice/network/practice_location';
import actionCreator from '../../redux/practice/actions/practice_location';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import Swal from 'sweetalert2';

class PracticeLocations extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentWillMount() {
        let id = this.props.selectedRole.practice.id;
        this.props.practiceLocationList({id: id});
    }

    delete(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete this location?. Are you sure?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No", 
            useRejections:true
        }).then(function () {
            that.props.practiceLocationDelete({ id: id }).then((response) => {
                that.props.practiceLocationList({ id: that.props.selectedRole.practice.id });
            });            
        }, function (dismiss) {
                        
        });
    }

    add(){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/admin/location/add");
    }

    edit(id){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/admin/location/edit/"+id);
    }

    render() {
        if(_.isEmpty(this.props.practiceLocations)){
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        practiceLocationList,
        practiceLocationGet,
        practiceLocationPost,
        practiceLocationDelete,
        clean: actionCreator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        practiceLocation: state.practice.practiceLocation.practiceLocation,
        practiceLocations: state.practice.practiceLocation.practiceLocationList,
        selectedRole: state.auth.roleReducer.selectedRole,
        ...ownProps,
    };
}

const PracticeLocationsConnected = connect(mapStateToProps,mapDispatchToProps)(PracticeLocations);
export default PracticeLocationsConnected;
