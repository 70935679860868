import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { PARTNER } from '../../../core/constants/constants';
import { Link } from 'react-router-dom';
import Checkbox from 'react-toolbox/lib/checkbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
export default function () {
    return React.createElement('div', {}, React.createElement(AppBar, {
        'className': 'main-app-bar-gi',
        'style': { width: '100%' }
    }, React.createElement('a', { 'href': 'https://dev.grayinstitute.com/' }, React.createElement('div', { 'className': 'login-page-logo' }))), this.props.display_signup_form ? React.createElement('div', {
        'className': 'gi-signup d-flex m-t-30 row center-xs middle-xs  full-height c-white w-100',
        'key': '1170'
    }, React.createElement('div', {}, React.createElement('form', {
        'className': 'white-labels text-left',
        'style': { width: '420px' },
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('h1', { 'className': 'text-center p-t-24' }, 'Register'), React.createElement('div', { 'className': 'd-flex flex-sb m-b-20' }, React.createElement(Field, {
        'label': 'First Name',
        'style': {
            width: '48%',
            height: '40px'
        },
        'name': 'first_name',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'Last Name',
        'style': {
            width: '48%',
            height: '40px'
        },
        'name': 'last_name',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    })), React.createElement(Field, {
        'label': 'Email Address',
        'className': 'w-100 m-b-20',
        'name': 'email',
        'type': 'email',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'Password',
        'className': 'w-100 m-b-20',
        'name': 'password',
        'type': 'password',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement(Field, {
        'label': 'Confirm Password',
        'className': 'w-100 m-b-20',
        'name': 'confirm_password',
        'type': 'password',
        'component': TextInput,
        'onClick': this.inputClick
    }), React.createElement('div', { 'className': 'row w-100 center-xs' }, React.createElement(Button, {
        'label': 'Sign Up',
        'disabled': this.props.submitting,
        'type': 'submit',
        'className': 'bg-success big-button',
        'raised': true,
        'style': { width: '180px' }
    })), this.state.is_gi_subdomain ? React.createElement('div', {
        'className': 'row w-100 center-xs signup-container cursor-pointer',
        'key': '2798'
    }, React.createElement('p', {
        'className': 'c-black signup',
        'onClick': this.gotoLogin
    }, 'I already have a Gray Institute account')) : null))) : null, !this.props.display_signup_form ? React.createElement('div', {
        'className': 'row center-xs middle-xs m-t-30  full-height',
        'key': '3113'
    }, React.createElement('div', {
        'className': 'col-xs-11 col-sm-10 col-md-6 p-20',
        'style': { border: '2px solid white' }
    }, React.createElement('h2', { 'className': 'c-white' }, 'ACTIVATE YOUR ACCOUNT'), React.createElement('i', { 'className': 'material-icons f-50 c-white' }, 'inbox'), React.createElement('div', {
        'style': { background: 'rgba(255,255,255,0.8)' },
        'className': 'p-20  m-t-20'
    }, React.createElement('p', { 'className': 'text-left c-accent f-16 f-500' }, '\n                        For security reasons we just sent you an email to verify your email address. \n                        Please select the verification link in the email to get started. \n                        If it does not appear in a few minutes check your spam filters.\n                    '), React.createElement('p', { 'className': 'text-left m-t-10 c-accent f-18 f-500' }, '\n                        Thank you!\n                    ')))) : null);
}