import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progression_preview_wrapper";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ProgressionPreviewWrapper extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        ...ownProps,
    };
}

const ProgressionPreviewWrapperConnected = connect(mapStateToProps, mapDispatchToProps)(ProgressionPreviewWrapper);
export default ProgressionPreviewWrapperConnected;
