import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/how-to-videos.rt";
import store, { history} from '../../redux/store';
import {    
    getPracticeEducationContentListDHF
} from '../../redux/education/network/education_network';
import swal from 'sweetalert2';
import EducationAction from '../../redux/education/actions/education_actions';
import {
    getEducationCategoryList, deleteEducationCategory,
    getEducationContentList, deleteEducationContent
} from '../../redux/education/network/education_network';

class HowToVideos extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            search: null,
            selectedPartner: null,
            selectedEducationCategory: null,
            show_education_video: false,
            education_video: {}
        };
        this.viewEducationContent = this.viewEducationContent.bind(this);
        this.closeEducationContent = this.closeEducationContent.bind(this);
        this.onSelectPartner = this.onSelectPartner.bind(this);
        this.onSelectCategory = this.onSelectCategory.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.addVideo = this.addVideo.bind(this);
        this.editVideo = this.editVideo.bind(this);
        this.deleteEducationContent = this.deleteEducationContent.bind(this);
        
    };    

    deleteEducationContent(content) {        
        let that = this;
        swal.fire({
            title: "Delete Content",
            text: "You are about to delete education content. Are you sure?",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am",
            cancelButtonText: "Cancel",
            useRejections:true
        }).then(function () {
            that.props.deleteEducationContent({
                content_id: content.id,
                package_version: "2",
            practice_id: 276,
            }).then((response) => {
                that.props.getEducationContentList({
                    app_version: "5.1.12",
                    current_role_type: "practitioner",
                    education_category_id: null,
                    package_version: "2",
                    page: 0,
                    partner_id: 2,
                    practice_id: 276,
                    search_text: null
                })
                swal.close();
            }).catch((error) => { 
                swal.close();
            });            
        }, function (dismiss) {
                        
        });        

    }

    addVideo() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/how-to-videos/add')
    }
    editVideo(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/marketing/how-to-videos/edit/'+id)
    }

    componentWillMount() {
        // this.loadEducationContentData();
        this.props.getEducationContentList({
            app_version: "5.1.12",
            current_role_type: "practitioner",
            education_category_id: null,
            package_version: "2",
            page: 0,
            partner_id: 2,
            practice_id: 276,
            search_text: null
        })
    }

    // loadEducationContentData(increment=false) {
    //     let that = this;
    //     this.props.getPracticeEducationContentList({            
    //         'search_text': this.state.search,
    //         'education_category_id': this.state.selectedEducationCategory,
    //         'partner_id': this.state.selectedPartner,
    //         'page':increment ?  (this.props.paginate_info.page + 1) : 0
    //     }).then((response) => {
    //         if (that.state.selectedPartner === null) {
    //             if (response["partner_list"].length > 0) {
    //                 let first_partner = response['partner_list'][0];
    //                 that.setState({
    //                     selectedPartner: first_partner["id"]
    //                 });
    //             }
    //         }
    //     }).catch((error) => { 
    //     });      
    // }

    onSearch(text){
        this.setState({search:text},()=>{
            this.loadEducationContentData();
        });
    }

    onSelectCategory(education_category_id) {
        this.setState({ selectedEducationCategory: education_category_id, search: null }, () => {
            this.loadEducationContentData();
        });
    }

    onSelectPartner(partner_id) {
        this.setState({ selectedPartner: partner_id, selectedEducationCategory: null, search: null }, () => {
            this.loadEducationContentData();
        });
    }

    viewEducationContent(video) {
        let education_video = {};
        education_video['title'] = video.name;
        education_video['welcome_text'] = "";
        education_video["video_guid"] = video.video_guid;
        education_video['thumbnail'] = video.thumbnail;
        this.setState({
            show_education_video: true,
            education_video: education_video,
        });
    }

    closeEducationContent() {
        this.setState({
            show_education_video: false,
            education_video: {}
        });
    }


    componentWillUnmount() {     
        this.props.cleanUp();
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        partner_list: state.education.educationReducer.partner_list,
        education_categories: state.education.educationReducer.education_categories,
        education_content: state.education.educationReducer.education_content_list,
        paginate_info: state.education.educationReducer.paginate_info,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEducationContentList,
        deleteEducationContent,
        cleanUp: EducationAction.cleanModule,
        }, 
        dispatch
    );
}

const HowToVideosConnected = connect(mapStateToProps,mapDispatchToProps)(HowToVideos);
export default HowToVideosConnected;