import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import CheckboxGroup from '../../../../utils/common_components/checkbox_group_form';
import Checkbox from '../../../../utils/common_components/checkbox_form';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import Switch from '../../../../utils/common_components/switch_form';
import DatePicker from '../../../../utils/common_components/datepicker_form';
import TimePicker from '../../../../utils/common_components/time_picker_form';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
export default function () {
    function repeatTiming1(timing, timingIndex) {
        return React.createElement('div', {
            'className': 'row vetical-align m-b-10',
            'key': timingIndex
        }, React.createElement('div', { 'className': 'col-xs-offset-1 col-xs-4' }, React.createElement(Field, {
            'label': 'Day of the week',
            'name': 'facility_frequency[' + timingIndex + '].day',
            'component': Select,
            'options': this.state.day_of_week_list,
            'simpleValue': true
        })), React.createElement('div', { 'className': 'col-xs-3' }, React.createElement(Field, {
            'label': 'Start Time',
            'name': 'facility_frequency[' + timingIndex + '].start_time',
            'component': TimePicker
        })), React.createElement('div', { 'className': 'col-xs-3' }, React.createElement(Field, {
            'label': 'End Time',
            'name': 'facility_frequency[' + timingIndex + '].end_time',
            'component': TimePicker
        })), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(IconButton, {
            'tooltip': 'Delete',
            'icon': 'delete_forever',
            'className': 'c-danger',
            'ripple': true,
            'onClick': () => this.deleteFacilityFrequency(timingIndex)
        })));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement('form', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'col-xs-10 p-0' }, React.createElement(CardTitle, {
        'title': 'Add Studio Facility',
        'className': 'm-b-10'
    }), React.createElement.apply(this, [
        CardText,
        {},
        React.createElement('div', {}, React.createElement(Field, {
            'className': 'w-100',
            'name': 'name',
            'type': 'text',
            'component': TextInput,
            'label': 'Studio Facility Name'
        })),
        React.createElement('div', {}, React.createElement(Field, {
            'className': 'w-100',
            'name': 'description',
            'type': 'text',
            'component': TextInput,
            'multiline': true,
            'rows': 2,
            'label': 'Description'
        })),
        React.createElement('div', {}, React.createElement(Field, {
            'className': 'w-100',
            'name': 'max_client_count',
            'type': 'number',
            'component': TextInput,
            'label': 'Max number of clients allowed to book'
        })),
        React.createElement('div', {}, React.createElement(Field, {
            'className': 'w-100',
            'name': 'max_booking_days',
            'type': 'number',
            'component': TextInput,
            'label': 'Max number of days in advance clients can book facilities'
        })),
        React.createElement('label', { 'className': 'w-100 material-label m-b-10' }, 'Which day of the week are the facilities available to be booked?"'),
        this.props.facility_frequency && this.props.facility_frequency.length === 0 ? React.createElement('div', {
            'className': 'alert-warning w-100 m-20 text-center',
            'key': '2770'
        }, 'Facility need slot timings!') : null,
        _.map(this.props.facility_frequency, repeatTiming1.bind(this)),
        React.createElement('div', { 'className': 'row' }, React.createElement(Button, {
            'className': 'bg-success col-xs-offset-1',
            'onClick': this.addFacilityFrequency,
            'icon': 'add',
            'floating': true,
            'mini': true,
            'ripple': true
        })),
        React.createElement('div', { 'className': 'm-t-30 text-right' }, React.createElement(Button, {
            'label': 'Save and Publish',
            'onClick': this.onPublish,
            'className': 'bg-success m-r-10',
            'disabled': this.props.submitting,
            'type': 'button',
            'raised': true
        }), React.createElement(Button, {
            'label': 'Save as draft',
            'onClick': this.onDraft,
            'className': 'bg-primary m-r-10',
            'disabled': this.props.submitting,
            'type': 'button',
            'raised': true
        }), React.createElement(Button, {
            'label': 'Cancel',
            'onClick': this.closeScreen,
            'className': 'bg-accent',
            'raised': true
        }))
    ]))));
}