let initialState = {
    unread_message_count:0,
    unread_chat_list:[],
    alert_count:0,
    metric_settings: {},
    practitioner_welcome_video_url: "",
    business_partner: {},
};

export default (state = initialState, action) => {
    switch (action.type) {        
        case "PRACTITIONER_HOME_ALERTS_FILL":                  
            return Object.assign({}, state, {
                unread_message_count: action.unread_message_count,
                unread_chat_list: action.unread_chat_list,
                alert_count: action.alert_count,
                metric_settings: action.metric_settings,
                practitioner_welcome_video_url: action.practitioner_welcome_video_url,
                business_partner: action.business_partner,
            });
        case "PRACTITIONER_LIST_FILL":
            return Object.assign({}, state, {
                practitioners: action.practitioners
            });
        case "PRACTITIONER_HOME_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
