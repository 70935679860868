// Action Creators
const coachListFill = (coaches) => ({
    type: 'COACH_LIST_FILL',
    coaches,
});
const coachFill = (coach) => ({
    type: 'COACH_FILL',
    coach,
});
const cleanModule = () => ({
    type: 'COACH_LIST_CLEANUP',
});


export default {
    coachListFill,
    coachFill,
    cleanModule,
};