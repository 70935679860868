import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/news_view";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import { getNews } from '../../redux/news/network/news_edit';
import actionCerator from '../../redux/news/actions/news_edit';

class NewsView extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.previewImage = this.previewImage.bind(this);
        this.state = {
            showMediaPreview: false,
            displayImageUrl: null,
        };
    }

    
    componentWillMount() {
        if(this.props.match && this.props.match.params && this.props.match.params.news_id) {
            this.props.getNews({ id: this.props.match.params.news_id });
        }
    }

    previewImage(media) {
        this.setState({
            showMediaPreview: true,
            displayImageUrl: media.url,
        });
    }    

    render() {
        if (_.isEmpty(this.props.news)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        if(this.props.match && this.props.match.params && this.props.match.params.news_id) {
            this.props.cleanModule();
        }
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getNews,
        cleanModule: actionCerator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {    
    if(ownProps.match && ownProps.match.params && ownProps.match.params.news_id) {
        let news = state.news.newsEdit.news;
        if (!_.isEmpty(news)) {
            news = {
                ...news,
                start_date: Utils.parseServerDateAsString(news.start_date),
                end_date: Utils.parseServerDateAsString(news.end_date),
            };
        }
        return {
            ...ownProps,
            news: news,
        };
    } else {
        let news = {...ownProps.news};
        if (!_.isEmpty(news)) {
            news = {
                ...news,
                start_date: Utils.parseServerDateAsString(news.start_date),
                end_date: Utils.parseServerDateAsString(news.end_date),
            };
        }
        return {
            ...ownProps,
            news: news,
        };
    }
}

const NewsViewConnected = connect(mapStateToProps, mapDispatchToProps)(NewsView);
export default NewsViewConnected;
