import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import actionCreator from '../actions/practice_practitioner';

const getPractitionerList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practitioner_list";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.fillPractitionerList(
                response['practitioner_list'],
                response['selection_list'],
                response['canned_message']
            ));
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getPractitioner = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practitioner_get";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            dispatch(actionCreator.fillPractitioner(
                response['practitioner'],
            ));
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const postPractitioner = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practitioner_post";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Your practitioner title & bio have been updated successfully.");
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const invitePractitioner = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practitioner_invite";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            if(response.invited){
                Utils.showSuccessAlert("An invitation email has been successfully send to your practitioner.  He will become active after he logs into the system.");
            }else{
                Utils.showInfoAlert("Practitioner is already part of your practice!");               
            }
            
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const toggleHidePractitioner = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practitioner_toggle_hide";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            if (params['category'] === 'active' || params['category'] === 'invited') {
                Utils.showSuccessAlert("Practitioner has been set to inactive state. He will no more be able to access your practice details");
            } else {
                Utils.showSuccessAlert("Practitioner has been set to active state. He can login and start accessing your practice details");
            }
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const resendPractitionerInvitation = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/practitioner_resend_invitation";
        return Axios.post(url, params).then(async (response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Email invitation has been resent to the practitioner");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};



export {
    getPractitionerList,
    getPractitioner,
    postPractitioner,
    invitePractitioner,
    toggleHidePractitioner,
    resendPractitionerInvitation,
};