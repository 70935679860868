import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../utils/common_components/basecomponent';
import Template from "./templates/workfit_video_edit.rt";
import Utils from '../../../utils/utils';
import store, { history} from '../../../redux/store';
import { reduxForm } from 'redux-form';
import { getWorkfitVideo, saveWorkfitVideo, deleteWorkfitVideo } from '../../../redux/dhf/workfit/network/workfit_network';
import WorkfitAction from '../../../redux/dhf/workfit/actions/workfit_actions';
import Swal from 'sweetalert2';

class WorkfitVideoEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveWorkfitVideo = this.saveWorkfitVideo.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.deleteWorkFitVideo = this.deleteWorkFitVideo.bind(this);
        this.state = {
            video_guid: null,
            thumbnail: null,
        };
    };
    
    componentWillMount() {
        if(this.props.match.params.workfit_video_id) {
            let params = {
                'workfit_video_id': this.props.match.params.workfit_video_id,
            };
            this.props.getWorkfitVideo(params);
        } else {
            this.props.getWorkfitVideo({});
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.workfit_video.video_guid) {
            this.setState({
                video_guid: nextProps.workfit_video.video_guid,
                thumbnail: nextProps.workfit_video.thumbnail
            });
        }
    }    

    componentWillUnmount() {
        this.props.cleanUp();
    }

    onVideoUploadCompleted(result) {
        let that = this;
        setTimeout(function() {
            that.setState({
                video_guid: result.guid,
                thumbnail: result.thumbnail,
            }); 
        }, 500);

    }

    onVideoUploadFailed(error) {        
        this.setState({
            video_guid: null,
            thumbnail: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/workfit");
    }


    saveWorkfitVideo(data) {
        if(!this.state.video_guid) {
            Utils.showErrorAlert("You have not uploaded a WorkFIT video");
            return;
        }
        let params = {...data};
        if(this.props.match.params.workfit_video_id) {
            params['workfit_video_id'] = this.props.match.params.workfit_video_id;
        }
        params['video_guid'] = this.state.video_guid;
        params['thumbnail'] = this.state.thumbnail;
        this.props.saveWorkfitVideo(params).then((response) => {
            Utils.showSuccessAlert("Your WorkFit video has been successfully saved");
            history.push("/practice/"+this.props.selectedRole.practice.id+"/workfit");
        }).catch(() => { });
    }

    deleteWorkFitVideo(workfit_video) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete this WorkFIT video?.",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            let params = {};
            params["workfit_video_id"] = that.props.match.params.workfit_video_id;
            that.props.deleteWorkfitVideo(params).then(() => {
                that.setState({
                    video_guid: null,
                    thumbnail: null
                });
                Utils.showSuccessAlert("Existing WorkFit video has been deleted. Kindly upload a new version.");
            }).catch((exception) => { });            
        }, function (dismiss) {                        
        });
    }


    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        workfit_video: state.workfit.workfitReducer.workfit_video,
        initialValues: state.workfit.workfitReducer.workfit_video,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getWorkfitVideo,
            saveWorkfitVideo,
            deleteWorkfitVideo,
            cleanUp: WorkfitAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const WorkfitVideoEditForm = reduxForm({
    form: 'WrapperVideoEditForm', 
    enableReinitialize: true, 
    validate
})(WorkfitVideoEdit);


const WorkfitVideoEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(WorkfitVideoEditForm);
export default WorkfitVideoEditFormConnected;