// Action Creators
const moveVideosFill = (move_videos, paginate_info, favorite_list, is_homefit_subscription_valid) => ({
    type: 'MOVE_VIDEOS_FILL',
    move_videos,
    paginate_info,
    favorite_list,
    is_homefit_subscription_valid,
});

const moveVideoFill = (move_video,supporting_data,shopify_equipment) => ({
    type: 'MOVE_VIDEO_FILL',
    move_video,
    supporting_data,
    shopify_equipment
});

const moveUserFill = (move_user,) => ({
    type: 'MOVE_USER_FILL',
    move_user,
});

const moveFamilyMembersFill = (family_member_list) => ({
    type: 'MOVE_FAMILY_MEMBERS_FILL',
    family_member_list,
});

const moveVideoToggleFavorite = (workout_id,action_type) => ({
    type: 'MOVE_VIDEO_TOGGLE_FAVORITE',
    workout_id,
    action_type,
});


const cleanModule = () => ({
    type: 'MOVE_CLEANUP',
});

export default {
    moveVideosFill,
    moveVideoFill,
    moveUserFill,
    moveFamilyMembersFill,
    cleanModule,
    moveVideoToggleFavorite,
};
