import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer';
export default function () {
    return React.createElement(Dialog, {
        'title': this.props.title,
        'onEscKeyDown': this.props.onCloseHandle,
        'onOverlayClick': this.props.onCloseHandle,
        'active': this.props.welcome_video !== null
    }, React.createElement('div', { 'className': 'w-100 p-relative' }, React.createElement(Button, {
        'floating': true,
        'primary': true,
        'mini': true,
        'tooltip': 'Close window',
        'icon': 'clear',
        'style': {
            right: '-15px',
            top: '-20px'
        },
        'className': 'c-white bg-danger pull-right nano',
        'onClick': this.props.onCloseHandle
    })), this.props.welcome_video ? React.createElement('div', {
        'className': 'row',
        'key': '1082'
    }, React.createElement('div', { 'className': 'col-xs-12 center-xs' }, React.createElement('div', { 'className': 'row start-xs-xs f-18' }, React.createElement(RichTextViewer, {
        'className': 'col-xs-12 m-t-10 m-b-10 text-left',
        'value': this.props.welcome_video.welcome_text
    })), React.createElement('div', { 'className': 'row center-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video' + this.props.welcome_video.video_guid,
        'videoList': [this.props.welcome_video.video_guid]
    }))))) : null);
}