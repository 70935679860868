import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Switch from '../../../utils/common_components/switch_form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import { FieldArray } from 'redux-form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
import HelpTip from '../../../utils/common_components/help_tip';
import Dashboard from '../treatment_template_dashboard';
import Checkbox from '../../../utils/common_components/checkbox_form';
export default function () {
    return React.createElement('div', { 'className': 'row game-plan-template' }, React.createElement(Button, {
        'className': 'm-t-20 d-flex back-btn c-grey',
        'onClick': this.cancel
    }, '\n         Templates / Game Plans\n    '), React.createElement(Card, { 'className': 'w-100 bg-none no-box-shadow' }, React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement('form', {
        'name': 'treatmentTemplateEditForm',
        'className': 'bg-none d-flex bg-white no-box-shadow'
    }, React.createElement('div', {
        'style': {
            width: '76.15%',
            margin: '0'
        }
    }, React.createElement('div', {
        'className': 'w-100 row p-0 p-b-0 add-info box-shadow-10',
        'style': { width: '100% !important' }
    }, React.createElement(CardTitle, {
        'title': ` ${ this.props.match.params.treatment_type === 'group' ? 'Group' : 'Game Plan' }  Template`,
        'className': 'c-black m-b-10'
    }), React.createElement('div', { 'className': 'col-xs-12' }, this.props.guid ? [
        this.props.is_logo_uploaded ? React.createElement('div', {
            'className': 'm-b-20 row center-xs',
            'key': '23831'
        }, React.createElement(Avatar, { 'className': 'avatar-image-100 box-100 bg-transparent' }, React.createElement('img', { 'src': this.props.logo_url }))) : null,
        this.props.match.params.treatment_type === 'group' ? React.createElement('div', {
            'className': 'row center-xs',
            'key': '23833'
        }, React.createElement(Dropzone, {
            'className': 'dummy-suppress-warning',
            'multiple': false,
            'accept': 'image/*',
            'onDrop': this.uploadLogo
        }, React.createElement(Button, {
            'label': this.props.is_logo_uploaded ? 'Change Logo' : 'Upload Logo',
            'primary': true,
            'raised': true
        })), this.props.is_logo_uploaded ? React.createElement(Button, {
            'onClick': this.deleteLogo,
            'label': 'Delete Logo',
            'className': 'm-l-10 bg-danger',
            'primary': true,
            'raised': true,
            'key': '3256'
        }) : null) : null
    ] : null, !this.props.match.params.treatment_template_id ? React.createElement('div', {
        'className': 'c-grey m-b-30',
        'key': '3539'
    }, '\n                                A game plan template must be created before adding data to it.\n                            ') : null, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'name',
        'label': 'Template Name',
        'type': 'text',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement('div', { 'className': 'col-xs-12 m-b-30' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), this.props.match.params.treatment_type === 'group' ? React.createElement('div', {
        'className': 'col-xs-12 m-b-30',
        'key': '4627'
    }, React.createElement(Field, {
        'name': 'treatment_template_cycle',
        'component': Select,
        'label': 'Type',
        'options': this.state.treatment_cycle,
        'clearable': false,
        'disabled': this.props.treatment_template.is_treatment_template_in_progress
    })) : null, this.props.match.params.treatment_type === 'group' ? React.createElement('div', {
        'className': 'text-left m-t-10',
        'key': '5064'
    }, React.createElement('p', { 'className': 'f-13 f-300' }, '\n                                SCHEDULED groups let you set the start and end date for the Workouts, Habits, and \n                                other content you prescribe. All clients are prompted to do the workout on the \n                                same schedule. You can prescribe "Scheduled" and "Ongoing" type workouts.\n                                '), React.createElement('p', { 'className': 'f-13 f-300' }, '\n                                ONGOING groups let you add in content and clients at any time, and you manually activate a client when \n                                they are ready to proceed at their own pace. You can prescribe only "Ongoing" type workouts.\n                                ')) : null, this.props.match.params.treatment_template_id || this.state.treatment_template_id ? React.createElement('div', { 'key': '5997' }, React.createElement(Dashboard, {
        'params': this.props.match.params,
        'treatment_template_id': this.state.treatment_template_id,
        'ref': 'dashboard'
    })) : null)), this.props.user.gi_isadmin ? React.createElement(Card, {
        'className': 'monetization box-shadow-10 p-t-24  p-l-24 p-r-24 m-r-26 m-t-24 m-b-16',
        'style': { width: '97%' },
        'key': '6681'
    }, React.createElement(CardTitle, {
        'title': 'Monetization',
        'className': 'c-black form-subtitle f-18 w-100'
    }), React.createElement('div', { 'className': 'w-48 text-left d-flex align-center m-t-3 m-b-10' }, React.createElement(Field, {
        'className': 'w-100 p-t-0',
        'name': 'is_free',
        'component': Checkbox,
        'label': 'This template is paid',
        'disabled': this.props.treatment_template.owner !== this.props.selectedRole.practice.id
    }))) : null), React.createElement('div', { 'className': 'publish-info p-0' }, this.props.treatment_template.owner !== this.props.selectedRole.practice.id ? React.createElement(Card, {
        'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24',
        'key': '7642'
    }, React.createElement('p', { 'className': 'paywall-msg m-b-10 text-center' }, 'To access this template and all other premium templates, please purchase the required subscription from your App Store.'), React.createElement('div', {
        'className': 'width-fit',
        'style': { margin: '0 auto' }
    }, React.createElement('a', {
        'href': 'https://apps.apple.com/us/app/dhf-coachfit/id1130877074',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to App Store',
        'className': 'paywall-appstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    }))), React.createElement('div', {
        'className': 'width-fit',
        'style': { margin: '0 auto' }
    }, React.createElement('a', {
        'href': 'https://play.google.com/store/apps/details?id=com.gomotive.dhf',
        'target': 'blank'
    }, React.createElement(Button, {
        'label': 'Go to Play Store',
        'className': 'paywall-playstore bg-success-border m-b-12 d-flex align-center p-t-26 p-b-26 p-r-15 p-l-15 w-100'
    })))) : null, this.props.treatment_template.owner == this.props.selectedRole.practice.id ? React.createElement(Card, {
        'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24',
        'key': '8866'
    }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-r-10 middle-xs' }, this.props.match.params.treatment_type !== 'group' ? React.createElement('div', { 'key': '9199' }) : null), React.createElement(Button, {
        'label': this.props.match.params.treatment_template_id || this.state.treatment_template_id ? 'Save as Draft' : 'Create',
        'className': 'this.state.treatment_template_id ? \'bg-success-border m-b-12 w-100\' : \'m-l-10 bg-success w-100\'',
        'type': 'button',
        'raised': true,
        'style': { width: '100%' },
        'onClick': () => this.save(false)
    }), this.props.match.params.treatment_template_id || this.state.treatment_template_id ? React.createElement('div', {
        'className': 'w-100',
        'key': '10866'
    }, React.createElement(Button, {
        'disabled': this.state.showWorkoutEditForm,
        'onClick': () => {
            this.save(true);
        },
        'label': 'Publish',
        'tooltip': 'Publish template',
        'className': 'bg-success w-100'
    })) : null) : null)))));
}