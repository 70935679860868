import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import Dialog from 'react-toolbox/lib/dialog';
import IconButton from '../../../utils/common_components/tooltipIconButton';
export default function () {
    function repeatIntake1(intake, intakeIndex) {
        return React.createElement(Tr, { 'key': 'row' + intakeIndex }, React.createElement(Td, {
            'column': 'name',
            'value': intake.name,
            'style': { width: '320px' }
        }, React.createElement('span', {}, intake.name)), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '220px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, intake.practice.name)), React.createElement(Td, {
            'column': 'published',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, intake.published ? React.createElement('span', { 'key': '3552' }, 'Published') : null, !intake.published ? React.createElement('span', { 'key': '3624' }, 'Not Published') : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, intake.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'goal-template-action-container edit-btn-small justify-center',
            'key': '3852'
        }, React.createElement(Button, {
            'onClick': () => {
                this.editIntake(intake.id);
            },
            'raised': true,
            'ripple': true,
            'className': 'm-r-12 goal-template-action',
            'primary': true
        }, 'Edit'), React.createElement(Button, {
            'ripple': true,
            'raised': true,
            'primary': true,
            'tooltip': 'Delete',
            'onClick': () => {
                this.deleteIntake(intake.id);
            },
            'className': 'delete-red-icon goal-template-action-delete small-icon-button goal-template-action delete-button'
        })) : null, intake.practice.id !== this.props.selectedRole.practice.id ? React.createElement('div', {
            'className': 'goal-template-action-container edit-btn-small justify-center',
            'key': '5095'
        }, React.createElement(Button, {
            'onClick': () => {
                this.editIntake(intake.id);
            },
            'className': 'm-r-12 goal-template-action',
            'raised': true,
            'ripple': true,
            'primary': true
        }, 'Preview'), React.createElement(Button, {
            'onClick': () => {
                this.cloneIntake(intake.id);
            },
            'className': 'goal-template-action',
            'raised': true,
            'ripple': true,
            'primary': true
        }, 'Clone')) : null)));
    }
    return React.createElement('div', { 'className': 'intake-list' }, React.createElement('div', { 'className': 'm-t-15 page-title-container d-flex' }, React.createElement('div', {}, React.createElement('h3', { 'className': 'page-subtitle f-14' }, 'Templates'), React.createElement('h2', { 'className': 'page-title with-subtitle text-capital' }, 'Intake Forms')), React.createElement(Button, {
        'className': 'floating-add-button  red-add-button m-r-5',
        'style': { top: '100px' },
        'onClick': this.addIntake
    }, '\n            Add new\n        ')), React.createElement('div', { 'className': 'm-t-24' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + button to add an intake form for your practice.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, {
            'column': 'name',
            'style': { width: '320px' }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Name')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '220px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Author')), React.createElement(Th, {
            'column': 'published',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, 'Available for use?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', { 'className': 'table-heading' }, ' Action '))),
        _.map(this.props.intake_list, repeatIntake1.bind(this))
    ])));
}