// Action Creators
const fillNewsList = (newsList, paginate_info) => ({
    type: 'NEWS_LIST_FILL',
    newsList,
    paginate_info,
});
const removeNews = (news_id) => ({
    type: 'NEWS_LIST_REMOVE',
    news_id
});
 
const cleanModule = () => ({
    type: 'NEWS_LIST_CLEANUP',
});

export default {
    fillNewsList,
    removeNews,
    cleanModule,
};