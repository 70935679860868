
import React from 'react';
import {Tab, Tabs} from 'react-toolbox';
import CoursesContent from './courses_content';
import { connect } from 'react-redux';

class TabsOptions extends React.Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.setContentTab = this.setContentTab.bind(this)
    
    this.state = {
      index: 0,
      fixedIndex: 0,
      inverseIndex: 1,
      selectedIndex:0,
    };
  }

  componentDidMount() {
    this.setContentTab()  
  }

  handleChange(value) {
    const { history } = this.props.props;
    let search = '?type=certification';
    if (value === 0) {
      search = '?type=certification';
      this.setState({
        fixedIndex: 0,
      });
    }  else if (value === 1) {
        search = '?type=specialization';
        this.setState({
          fixedIndex: 1,
        });
      } else if (value === 2) {
        search = '?type=giftMentorship';
        this.setState({
          fixedIndex: 2,
        });
      }
      history.push({
        pathname: this.props.props.location.path,
        search: search
      });
  }

  setContentTab() {
    const {
      location: { search },
    } = this.props.props;

    if (search.includes('?type=certification')) {
      this.setState({
        fixedIndex: 0,
      });
    } else if (search.includes('?type=specialization')) {
      this.setState({
        fixedIndex: 1,
      });
    } else if (search.includes('?type=giftMentorship')) {
      this.setState({
        fixedIndex: 2,
      });
    }
  }
  render () {
    return (
        <Tabs class="tabs_title" index={this.state.fixedIndex} onChange={this.handleChange} fixed>
          <Tab activeClassName="active-tab" label='Certifications'>< CoursesContent location={this.props.props.location} course_type="certification"/></Tab>
          <Tab activeClassName="active-tab" label='Specializations'><CoursesContent location={this.props.props.location} course_type ="specialization"/></Tab>
          <Tab activeClassName="active-tab" label='Webinars'><CoursesContent location={this.props.props.location} course_type ="giftMentorship"/></Tab>
        </Tabs>                                                                                                                                                                 
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  }
}


const TabsOptionsConnected = connect(
    mapStateToProps,
)(TabsOptions);

export default TabsOptionsConnected;