import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import FontIcon from 'react-toolbox/lib/font_icon';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Panel } from 'react-toolbox';
import Input from 'react-toolbox/lib/input';
import { Layout } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-toolbox/lib/date_picker';
import { Dialog } from 'react-toolbox/lib/dialog';
export default function () {
    function repeatWorkout1(workout, workoutIndex) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container pointer',
            'key': 'workout' + workoutIndex
        }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(workout.image_url) + ')' },
            'onClick': () => this.gotoDetailedView(workout)
        }, React.createElement('i', { 'className': 'row col material-icons f-50 c-white box-250-150 center-xs middle-xs m-0 p-0' }, 'play_circle_outline')), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5 m-b-8',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (workout.description ? '9' : '12') + ' f-16 truncate',
            'tooltip': workout.name
        }, '\n                            ', workout.name, '\n                        '), workout.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': workout.description,
            'icon': 'info',
            'key': '3845'
        }) : null), React.createElement('div', { 'className': 'row center-xs m-0 m-t-5' }, React.createElement('div', { 'className': 'col-xs-12 p-2 m-0' }, React.createElement(IconButton, {
            'icon': 'picture_as_pdf',
            'tooltip': 'View PDF',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.doWorkoutPdf(workout);
            }
        }))));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement('div', { 'className': 'row m-b-30' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('h3', {}, '\n                ', this.props.program.name, '\n            '))), this.props.program.action_type === 'display_payment' ? React.createElement('div', { 'key': '1579' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', {
        'className': 'col-xs-11',
        'style': { zIndex: '1' }
    }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Payment Subscription'), React.createElement('div', { 'className': 'panel-subtitle' }, 'Complete the payment to view treatment details ')), React.createElement(Button, {
        'onClick': () => {
            history.push('/client/my_plans');
        },
        'className': 'bg-success',
        'icon': 'credit_card',
        'label': 'Subscribe',
        'tooltip': 'Click to complete payment for you to view treatment details',
        'raised': true,
        'primary': true
    }))))) : null, this.props.program.action_type === 'view_treatment' && this.props.program.workouts.length === 0 ? React.createElement('div', {
        'className': 'alert-warning m-20',
        'key': '2362'
    }, React.createElement('div', { 'className': 'row m-b-30' }, React.createElement('div', { 'className': 'col-xs-12' }, '\n                You do not have any workouts assigned. Kindly talk to your practitioner.\n            '))) : null, this.props.program.action_type === 'view_treatment' && this.props.program.workouts.length > 0 ? React.createElement('div', { 'key': '2727' }, React.createElement('div', { 'className': 'row m-b-30' }, React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12' },
        _.map(this.props.program.workouts, repeatWorkout1.bind(this))
    ]))) : null);
}