import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import WorkfitSummaryReport from '../../../dhf/workfit/components/workfit_summary_report';
import WorkfitDetailReport from '../../../dhf/workfit/components/workfit_detail_report';
export default function () {
    return React.createElement('div', { 'className': 'col-xs-12 p-0' }, this.props.selectedRole.is_workfit_practice === true ? React.createElement(Navigation, {
        'type': 'horizontal',
        'className': 'sub-menu',
        'key': '1310'
    }, React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/workfit_reports/summary_report`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Summary Report')), React.createElement(NavLink, {
        'to': `/practice/${ this.props.selectedRole.practice.id }/workfit_reports/detail_report`,
        'activeClassName': 'active'
    }, React.createElement('abbr', {}, 'Detail Report'))) : null, React.createElement(Switch, {}, React.createElement(Route, {
        'path': `${ this.props.match.url }/summary_report`,
        'component': WorkfitSummaryReport
    }), React.createElement(Route, {
        'path': `${ this.props.match.url }/detail_report`,
        'component': WorkfitDetailReport
    })));
}