import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import { history } from '../../redux/store';
import { inviteClient } from '../../redux/engagement/network/client_list_network';
import ClientAction from '../../redux/engagement/actions/client_creator';
import CannedMessageAction from '../../redux/message/actions/canned_message_creator';
import { getCannedMessage } from '../../redux/message/network/canned_message_network';
import ClientInvitation from '../../utils/common_components/invite/user_invitation';
import { getGroupList } from '../../redux/group/network/group_network';
import GroupAction from '../../redux/group/actions/group_creator';

import { INVITE_CLIENT_NEW_ENGAGEMENT, INVITE_CLIENT_GROUP, INVITE_CLIENT_WORKFIT_PRACTICE } from '../../redux/message/constants/constants';

class PractitionerClientInvite extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.inviteClient = this.inviteClient.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    };
    
    componentWillMount() {
        if (this.props.match.params.invite_type === '2') {
            this.props.getCannedMessage({
                'message_type': INVITE_CLIENT_WORKFIT_PRACTICE,
                package_version: 1,
            });
        }
        else if (this.props.match.params.invite_type === '0') {
            this.props.getCannedMessage({
                'message_type': INVITE_CLIENT_NEW_ENGAGEMENT,
                package_version: 1,
            });
        } else {
            this.props.getCannedMessage({
                'message_type': INVITE_CLIENT_GROUP,
                package_version: 1,
            });            
            this.props.getGroupList({
                'group_status': 'active'
            });
        }
    }

    componentWillUnmount() {
        this.props.cleanUp();
        this.props.messageCleanup();
        this.props.groupCleanup();
    }

    inviteClient(data) {
        let params = { ...data };
        params['invite_type'] = this.props.match.params.invite_type;
        this.props.inviteClient({...params}).then((response) => {
            history.goBack();
        }).catch((error) => {
        });

    }

    handleCancel() {
        // history.goBack();
        // history.push(`${this.props.match.url}/client`)
        history.push(`/practice/${this.props.selectedRole.practice.id}/home`)
    }

    render() {
        if(!_.isEmpty(this.props.canned_message)) {
            return (<ClientInvitation
                title="Invite a new client"
                subject={this.props.canned_message.subject}
                body={this.props.canned_message.body}
                handleCancel={this.handleCancel}
                handleInvite={this.inviteClient}
                groupList={this.props.group_list}/>);
        }else{
            return (<div></div>);
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        canned_message: state.message.cannedMessageReducer.canned_message,
        group_list: state.group.groupReducer.group_list,
        selectedRole: state.auth.roleReducer.selectedRole,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getCannedMessage,
            getGroupList,
            inviteClient,
            cleanUp: ClientAction.clientInstanceCleanup,
            messageCleanup: CannedMessageAction.cannedMessageCleanup,
            groupCleanup: GroupAction.groupCleanup,
        }, 
        dispatch
    );
}


const PractitionerClientInviteConnected = connect(mapStateToProps,mapDispatchToProps)(PractitionerClientInvite);
export default PractitionerClientInviteConnected;
