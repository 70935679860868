import * as React from 'react';
import * as _ from 'lodash';
import { Input } from 'react-toolbox/lib/input';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';
import { history } from '../../../redux/store';
import MatEdit from '../mat_edit';
import { Checkbox } from 'react-toolbox/lib/checkbox';
import Select from 'react-select';
export default function () {
    function repeatAssessment1(assessment, assessmentIndex) {
        return React.createElement('div', { 'key': 'assessment' + assessmentIndex }, React.createElement('div', {
            'onClick': () => {
                this.selectAssessment(assessment);
            },
            'className': 'm-b-10 d-flex'
        }, React.createElement('span', { 'className': 'bg-white p-10 m-r-1 box-shadow vertical-align' }, '\n                                    ', assessmentIndex + 1, '\n                                '), React.createElement('div', { 'className': ` f-12 p-10 box-shadow pointer flex-auto ${ this.state.selected_assessment != null && assessment.id === this.state.selected_assessment.id ? 'bg-primary box-shadow' : 'bg-white' }   ` }, '\n                                    ', assessment.name, '\n                                '), React.createElement('span', { 'className': 'bg-white p-5 m-l-1 box-shadow  vertical-align' }, React.createElement(FontIcon, {
            'value': 'check_circle',
            'className': `${ assessment.assessed ? 'c-success' : 'c-disabled' }`
        }))));
    }
    function repeatAssessmentType2(assessmentType, assessmentTypeIndex) {
        return React.createElement('div', {
            'className': 'row m-b-20',
            'key': assessmentTypeIndex
        }, React.createElement(Checkbox, {
            'className': 'm-b-15 m-t-0 col-xs-12',
            'label': assessmentType.label,
            'checked': assessmentType.value,
            'onChange': val => {
                this.onClickAssessmentCheckbox(assessmentType.label, val);
            }
        }), assessmentType.value ? React.createElement('div', {
            'className': 'col-xs-12',
            'key': '3725'
        }, React.createElement('label', {}, 'Select Client Fitness Type'), React.createElement(Select, {
            'options': this.state.client_treatment_levels,
            'value': this.state[assessmentType.label],
            'clearable': false,
            'simpleValue': true,
            'onChange': val => {
                this.changeFitnessLevel(assessmentType.label, val);
            }
        })) : null);
    }
    return React.createElement('div', {}, React.createElement('div', { 'className': 'row m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-10' }, React.createElement('h3', { 'className': 'di-block w-60' }, 'MAT Assessment'), this.state.current_page === 0 && !this.props.previewMode ? React.createElement(Button, {
        'onClick': () => {
            this.gotoPage(1);
        },
        'className': 'pull-right small-button m-r-5 bg-success',
        'label': 'Create Workout',
        'tooltip': 'Create Workout',
        'icon': 'public',
        'raised': true,
        'key': '724'
    }) : null)), this.state.current_page === 0 ? React.createElement('div', {
        'className': 'row m-0',
        'key': '1142'
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-4 p-l-5 p-r-5' },
        _.map(this.props.mat_form, repeatAssessment1.bind(this))
    ]), React.createElement('div', { 'className': 'col-xs-8' }, React.createElement(MatEdit, {
        'assessment': this.state.selected_assessment,
        'previewMode': this.props.previewMode
    }))) : null, this.state.current_page === 1 ? React.createElement('div', {
        'className': 'row m-0 middle-xs center-xs',
        'key': '2634'
    }, React.createElement('div', { 'className': 'panel col-xs-11 col-sm-9 col-md-7' }, React.createElement('div', { 'className': 'panel-heading panel-bottom-border dashed text-left' }, React.createElement('div', { 'className': 'panel-title' }, 'Select Workout Exercise Types '), React.createElement('div', { 'className': 'panel-subtitle' }, '\n                            Choose the type of workouts needed for client\n                        ')), React.createElement.apply(this, [
        'div',
        { 'className': 'panel-body text-left' },
        _.map(this.state.assessment_types, repeatAssessmentType2.bind(this))
    ]), React.createElement('div', {
        'className': 'panel-footer w-100 p-5 bg-white',
        'style': { height: '40px' }
    }, React.createElement(Button, {
        'onClick': this.createWorkout,
        'className': 'pull-right small-button m-r-5 bg-success',
        'label': 'Create Workout',
        'tooltip': 'Create Workout',
        'icon': 'public',
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.saveAssessment,
        'className': 'pull-right small-button m-r-5 bg-primary',
        'label': 'Save Assessment',
        'tooltip': 'Save Assessment',
        'icon': 'public',
        'raised': true
    }), React.createElement(Button, {
        'onClick': () => {
            this.gotoPage(0);
        },
        'className': 'pull-right small-button m-r-5 bg-accent',
        'label': 'Cancel',
        'tooltip': 'Go Back',
        'icon': 'undo',
        'raised': true
    })))) : null);
}