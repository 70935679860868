// Action Creators
const fillWorkoutList = (groups, engagements, quickstarts) => ({
    type: 'CLIENT_ALL_WORKOUTS_FILL',
    groups, engagements, quickstarts
});
const cleanModule = () => ({
    type: 'CLIENT_ALL_WORKOUTS_CLEANUP',
});

export default {
    fillWorkoutList,
    cleanModule,
};