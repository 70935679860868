import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_container.rt";
import store,{history} from '../../redux/store';
import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import MenuAction from '../../redux/core/actions/menu_state_creator';



class PartnerContainer extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.pushToIndex = this.pushToIndex.bind(this);
    };

    componentWillMount() {
        this.pushToIndex(this.props);
    }
    
    pushToIndex(props) {
        let pathArray = props.location.pathname.split("/");
        if (pathArray.pop() === "partner") {
            history.push("/practice/"+props.selectedRole.practice.id+"/partner/library");
        } 
    }

    componentWillReceiveProps(nextProps) {
        this.pushToIndex(nextProps);
    }

    render() {
        if(
            !this.props.isAuthenticated 
            || _.isEmpty(this.props.selectedRole) 
            || this.props.selectedRole.role.name !== 'practice_admin' 
            ||   this.props.selectedRole.practice.is_business_partner !== true  
        ){
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }       
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        isAuthenticated: state.auth.authReducer.isAuthenticated,
        selectedRole: state.auth.roleReducer.selectedRole,
        roles: state.auth.roleReducer.roles,
    };
}
const PartnerContainerConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerContainer);
export default PartnerContainerConnected;
