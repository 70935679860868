// Action Creators
const workfitVideosFill = (workfit_videos) => ({
    type: 'WORKFIT_VIDEOS_FILL',
    workfit_videos,
});

const workfitVideoFill = (workfit_video) => ({
    type: 'WORKFIT_VIDEO_FILL',
    workfit_video,
});

const practiceListFill = (practice_list) => ({
    type: 'WORKFIT_PRACTICE_LIST_FILL',
    practice_list,
});

const notificationTemplateListFill = (notification_templates) => ({
    type: 'WORKFIT_NOTIFICATION_TEMPLATE_LIST_FILL',
    notification_templates,
});

const notificationTemplateGetFill = (notification_template) => ({
    type: 'WORKFIT_NOTIFICATION_TEMPLATE_GET_FILL',
    notification_template,
});

const notificationScheduleListFill = (notification_schedules) => ({
    type: 'WORKFIT_NOTIFICATION_SCHEDULE_LIST_FILL',
    notification_schedules,
});



const cleanModule = () => ({
    type: 'WORKFIT_CLEANUP',
});

export default {
    workfitVideosFill,
    workfitVideoFill,
    practiceListFill,
    notificationTemplateListFill,
    notificationTemplateGetFill,
    notificationScheduleListFill,
    cleanModule,
};
