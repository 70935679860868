// Action Creators
const practiceWelcomeVideoFill = (welcome_video) => ({
    type: 'PRACTICE_WELCOME_VIDEO_FILL',
    welcome_video,
});

const cleanModule = () => ({
    type: 'PRACTICE_WELCOME_VIDEO_CLEANUP',
});
export default {
    practiceWelcomeVideoFill,
    cleanModule,
};