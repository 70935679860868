import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProfilePicture from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
export default function () {
    function repeatGroup1(group, groupIndex) {
        return React.createElement('div', {
            'className': 'box-shadow bg-white m-10 width-300',
            'key': 'group' + group.id
        }, React.createElement('div', {
            'className': 'row pointer',
            'onClick': () => this.goToGroupDashboard(group)
        }, React.createElement('div', { 'className': 'col-xs-6' }, group.is_logo_uploaded ? React.createElement('div', {
            'className': 'thumbnail profile-picture-normal',
            'style': { backgroundImage: 'url(' + group.logo_url + ')' },
            'key': '1332'
        }) : null, !group.is_logo_uploaded ? React.createElement(ProfilePicture, {
            'user': {
                name: group.name,
                avatar_url: group.logo_url
            },
            'key': '1501'
        }) : null), React.createElement('div', {
            'className': 'col-xs-6 p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', { 'className': 'end-xs' }, React.createElement(TooltipWrapper, { 'tooltip': group.name }, React.createElement('h6', { 'className': 'two-lines text-right' }, '\n                                        ', group.name, '\n                                ')), React.createElement('div', { 'className': 'f-12 p-t-5 c-primary truncate' }, '\n                            ', group.practice.name, '\n                        ')))));
    }
    return React.createElement('div', { 'className': 'm-15' }, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs m-20' },
        _.map(this.props.group_list, repeatGroup1.bind(this))
    ]));
}