import * as React from 'react';
import * as _ from 'lodash';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import EasyPie from '../../../utils/common_components/easypie.jsx';
export default function () {
    function repeatTreatment1(treatment, treatmentIndex) {
        return [React.createElement('div', {
                'className': 'row bg-beige border-body p-10 m-10',
                'key': '2551'
            }, React.createElement('div', { 'className': 'col-xs-12 c-dark' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement('div', { 'className': 'p-r-10' }, React.createElement(TooltipWrapper, { 'tooltip': 'Workout' }, React.createElement('i', { 'className': 'material-icons c-black' }, 'accessibility'))), React.createElement('div', { 'className': 'flex-auto c-accent-dark' }, React.createElement('h6', {}, '\n                                ', treatment.progression_name, '(', treatment.workout_name, ')\n                            '), React.createElement('div', { 'className': 'f-13 c-darkgray' }, '\n                                Duration: ', treatment.progression_duration, ' mins\n                            ')), React.createElement('div', { 'className': 'c-accent-dark' }, treatment.has_tracked === 0 ? React.createElement('span', {
                'className': 'bg-danger p-5',
                'key': '1247'
            }, '\n                                Not Tracked\n                            ') : null, treatment.has_tracked === 3 ? React.createElement('span', {
                'className': 'bg-success p-5',
                'key': '1422'
            }, '\n                                Completed\n                            ') : null, treatment.has_tracked === 2 ? React.createElement('span', {
                'className': 'bg-primary p-5',
                'key': '1596'
            }, '\n                                Partially Done\n                            ') : null, treatment.has_tracked === 1 ? React.createElement('span', {
                'className': 'bg-danger p-5',
                'key': '1775'
            }, '\n                                Not Done\n                            ') : null))))];
    }
    function repeatHabit2(habit, habitIndex) {
        return [React.createElement('div', {
                'className': 'row bg-beige border-body p-10 m-10',
                'key': '20801'
            }, React.createElement('div', { 'className': 'col-xs-12 c-dark' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement('div', { 'className': 'p-r-10' }, React.createElement(TooltipWrapper, { 'tooltip': 'Habit' }, React.createElement('i', { 'className': 'material-icons c-black' }, 'watch_later'))), React.createElement('div', { 'className': 'flex-auto c-accent-dark' }, React.createElement('h6', {}, '\n                                ', habit.client_engagement_habit.text, '\n                            ')), React.createElement('div', { 'className': 'c-accent-dark' }, habit.has_tracked === 0 ? React.createElement('span', {
                'className': 'bg-danger p-5',
                'key': '2870'
            }, '\n                                Not Tracked\n                            ') : null, habit.has_tracked === 1 ? React.createElement('span', {
                'className': 'bg-danger p-5',
                'key': '3041'
            }, '\n                                Not Done\n                            ') : null, habit.has_tracked === 2 ? React.createElement('span', {
                'className': 'bg-primary p-5',
                'key': '3209'
            }, '\n                                Partially Done\n                            ') : null, habit.has_tracked === 3 ? React.createElement('span', {
                'className': 'bg-success p-5',
                'key': '3384'
            }, '\n                                Completed\n                            ') : null))))];
    }
    return React.createElement('div', { 'className': 'w-100' }, React.createElement.apply(this, [
        'div',
        { 'className': 'flex-auto' },
        _.map(this.props.workouts, repeatTreatment1.bind(this)),
        _.map(this.props.habits, repeatHabit2.bind(this))
    ]));
}