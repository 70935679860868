import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'col-xs-6 m-20',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, {}, React.createElement(CardTitle, { 'title': 'Change Password' }), React.createElement(CardText, { 'className': 'm-t-15' }, React.createElement('div', { 'className': 'row p-0' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'current_password',
        'type': 'password',
        'component': TextInput,
        'label': 'Current Password'
    })), React.createElement('div', { 'className': 'row p-0' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'new_password',
        'type': 'password',
        'component': TextInput,
        'label': 'New Password'
    })), React.createElement('div', { 'className': 'row p-0' }, React.createElement(Field, {
        'className': 'w-100 input-text-dark',
        'name': 'new_password_confirm',
        'type': 'password',
        'component': TextInput,
        'label': 'Confirm New Password'
    }))), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Submit',
        'disabled': this.props.pristine || this.props.submitting,
        'className': 'bg-success',
        'type': 'submit',
        'primary': true,
        'raised': true
    })))));
}