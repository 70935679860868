import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from '../constants/constants';
import Utils from '../../../utils/utils';
import {
  showProgress,
  hideProgress
} from '../../core/actions/progress_creator';
import coursesListAction from '../actions/courses_list';

const listInspireCourses = (params) => {
    return (dispatch) => {
      dispatch(showProgress());
       const url = '/' + API_VERSION + '/user/user_courses_list';
      return Axios.post(url, params)
        .then(async (response) => {
          dispatch(hideProgress());
          dispatch(
            coursesListAction.fillCoursesList(
              response['courses_list'],
              response['paginate_info'],
            )
          );
          return response
        })
        .catch((error) => {
          dispatch(hideProgress());
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            //Utils.showErrorAlert(error.response.data.reason);
          }
        });
    };
  };
export { listInspireCourses }
