import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import HelpTip from '../../../utils/common_components/help_tip';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { Tabs } from 'react-toolbox';
import { Tab } from 'react-toolbox';
import Sortable from 'react-anything-sortable';
import SortableItem from '../../../utils/common_components/sortable_item';
import { WORKOUT_TARGET_GROUP } from '../../../redux/program/constants/constants';
import { TEMPLATE_SELECTION_MODE } from '../../../redux/program/constants/constants';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import GoalEdit from '../goal_edit';
import ProgramEdit from '../../../program/components/program_edit';
import ProgramList from '../../../program/components/program_list';
import HealthDocumentList from '../../../documents/components/document_list';
import Chip from 'react-toolbox/lib/chip';
import HabitList from '../group_habit_list';
import HabitEdit from '../group_habit_edit';
import GroupLatestGoalTracking from '../group_goal_tracking_latest';
import DefinePlan from '../group_define_plan.jsx';
import Payment from '../group_payments';
export default function () {
    function repeatGoal_question1(goal_question, index) {
        return React.createElement('div', {
            'className': 'm-b-2 col-xs-12',
            'key': 'goal_question' + index
        }, React.createElement('div', { 'className': 'row box-shadow bg-white  f-16 p-10' }, React.createElement('div', { 'className': 'col-xs-10' }, '\n                                    ', index + 1, ': ', goal_question.question, ' \n                                '), React.createElement('div', { 'className': 'col-xs-2' }, '\n                                    Metric Unit: ', goal_question.metric_unit.unit_name, '\n                                ')));
    }
    function repeatGoal2(goal, goalIndex) {
        return React.createElement('tr', { 'key': goal.id }, React.createElement('td', {}, goal.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.assignGoal(goal);
            },
            'tooltip': 'Assign goal for this Group',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    function repeatWorkout3(workout, workoutIndex) {
        return React.createElement(SortableItem, {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'workout' + workout.id,
            'sortData': workout
        }, !workout.published ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '13871'
        }, '\n                                    DRAFT\n                                ') : null, workout.published && workout.is_being_used && !workout.is_completed ? React.createElement('div', {
            'className': 'bg-success ribbon',
            'key': '14043'
        }, '\n                                    ACTIVE\n                                ') : null, workout.published && !workout.is_being_used ? React.createElement('div', {
            'className': 'bg-success ribbon',
            'key': '14265'
        }, '\n                                    FUTURE\n                                ') : null, workout.published && workout.is_completed ? React.createElement('div', {
            'className': 'bg-success ribbon f-12 m-t-18',
            'key': '14495'
        }, '\n                                    COMPLETED\n                                ') : null, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(workout.image_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white start-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '80px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (workout.description ? '9' : '12') + ' f-16 truncate',
            'tooltip': workout.name
        }, '\n                                        ', workout.name, '\n                                    '), workout.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': workout.description,
            'icon': 'info',
            'key': '15209'
        }) : null, workout.practitioner ? React.createElement('div', {
            'className': 'col-xs-12 f-12 f-300 m-b-2',
            'key': '15367'
        }, '\n                                        Practitioner: ', workout.practitioner.name, '\n                                    ') : null, workout.start_date && workout.workout_start_date ? React.createElement('div', {
            'className': 'col-xs-12 f-12 f-300 m-b-2',
            'key': '15606'
        }, '\n                                        Start date: ', moment(workout.workout_start_date).format('MMMM Do YYYY'), '\n                                    ') : null, workout.start_date && workout.workout_start_date !== workout.start_date ? React.createElement('div', {
            'className': 'col-xs-12 f-12 f-300',
            'key': '15895'
        }, '\n                                        Last revision date: ', moment(workout.start_date).format('MMMM Do YYYY'), '\n                                    ') : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-2 m-0' }, React.createElement(IconButton, {
            'icon': 'create',
            'tooltip': 'Edit Workout',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.editWorkout(workout);
            }
        }), React.createElement(IconButton, {
            'icon': 'delete_forever',
            'tooltip': 'Delete Workout',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.deleteWorkout(workout);
            }
        }), React.createElement(IconButton, {
            'icon': 'picture_as_pdf',
            'tooltip': 'View PDF',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.doWorkoutPdf(workout);
            }
        }), React.createElement(IconButton, {
            'icon': 'reorder',
            'style': { cursor: 'move' },
            'tooltip': 'Re-Order Workout',
            'className': 'm-r-5 c-black small-icon-button round-border handle'
        }), workout.is_being_used && !workout.is_completed && workout.schedule_type === 2 ? React.createElement(IconButton, {
            'icon': 'clear',
            'tooltip': 'Mark workout as completed',
            'className': 'm-r-5 c-black small-icon-button round-border',
            'onClick': () => {
                this.markWorkoutComplete(workout);
            },
            'key': '17362'
        }) : null)));
    }
    function repeatAttachment4(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument5(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment4.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, document.is_published ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '20871'
        }) : null, !document.is_published ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '21100'
        }) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, document.is_published ? React.createElement(Button, {
            'className': 'm-l-5 bg-warning micro',
            'tooltip': 'Unpublish the nutrition document',
            'onClick': () => {
                this.healthDocumentPublishStatusToggle(document);
            },
            'icon': 'cancel',
            'floating': true,
            'mini': true,
            'key': '21596'
        }) : null, !document.is_published ? React.createElement(Button, {
            'className': 'm-l-5 bg-success micro',
            'tooltip': 'Publish the nutrition document',
            'onClick': () => {
                this.healthDocumentPublishStatusToggle(document);
            },
            'icon': 'done',
            'floating': true,
            'mini': true,
            'key': '22005'
        }) : null, React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document from this group',
            'className': 'm-l-5 bg-danger micro',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'accent': true,
            'mini': true
        }))));
    }
    function repeatAttachment6(document, documentIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatDocument7(document, documentIndex) {
        return React.createElement(Tr, { 'key': 'row' + documentIndex }, React.createElement(Td, {
            'column': 'name',
            'value': document.name
        }, React.createElement('span', {}, document.name)), React.createElement(Td, {
            'column': 'description',
            'style': { maxWidth: '350px' },
            'className': 'truncate'
        }, React.createElement('span', { 'className': 'truncate' }, document.description)), React.createElement(Td, { 'column': 'attachments' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(document.attachments, repeatAttachment6.bind(this, document, documentIndex))
        ])), React.createElement(Td, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, document.is_published ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '25687'
        }) : null, !document.is_published ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '25916'
        }) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, document.is_published ? React.createElement(Button, {
            'className': 'm-l-5 bg-warning micro',
            'tooltip': 'Unpublish the guidance document',
            'onClick': () => {
                this.healthDocumentPublishStatusToggle(document);
            },
            'icon': 'cancel',
            'floating': true,
            'mini': true,
            'key': '26416'
        }) : null, !document.is_published ? React.createElement(Button, {
            'className': 'm-l-5 bg-success micro',
            'tooltip': 'Publish the guidance document',
            'onClick': () => {
                this.healthDocumentPublishStatusToggle(document);
            },
            'icon': 'done',
            'floating': true,
            'mini': true,
            'key': '26824'
        }) : null, React.createElement(Button, {
            'tooltip': 'Remove the association of this nutrition document from this group',
            'className': 'm-l-5 bg-danger micro',
            'onClick': () => {
                this.unAssignHealthDocument(document);
            },
            'icon': 'delete_forever',
            'floating': true,
            'accent': true,
            'mini': true
        }))));
    }
    function repeatHabit_template8(habit_template, habit_templateIndex) {
        return React.createElement('tr', { 'key': habit_template.id }, React.createElement('td', {}, habit_template.name), React.createElement('td', {}, habit_template.text), React.createElement('td', { 'className': 'w-20 text-center' }, React.createElement(Button, {
            'onClick': () => {
                this.createGroupHabitFromTemplate(habit_template);
            },
            'tooltip': 'Add habit for this group',
            'className': 'bg-success',
            'icon': 'add',
            'floating': true,
            'mini': true
        })));
    }
    return React.createElement('div', { 'className': 'row p-15 m-b-25' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'panel m-b-0' }, React.createElement('div', { 'className': 'panel-body p-t-10' }, React.createElement('div', { 'className': 'vertical-align' }, React.createElement(Avatar, {
        'className': 'pointer',
        'size': 65,
        'user': {
            name: this.props.group.name,
            avatar_url: this.props.group.logo_url
        },
        'profile_type': 'circle'
    }), React.createElement('div', { 'className': 'flex-auto m-l-5' }, React.createElement('h5', { 'className': 'm-b-2' }, '\n                                ', this.props.group.name, '\n                                ', React.createElement(FontIcon, {
        'onClick': this.editGroup,
        'value': 'create',
        'className': 'di-block c-primary f-15 m-l-5 pointer'
    })), React.createElement('div', { 'className': 'f-14' }, ' Type: ', this.props.group.__group_treatment_cycle)), React.createElement('div', { 'className': 'vertical-align' })), React.createElement('div', { 'className': 'd-flex w-100' }, React.createElement('div', { 'className': 'flex-auto' }, React.createElement('h6', {}, 'Group Pricing'), React.createElement('span', { 'className': 'vertical-align' }, !this.props.group.payment_plan ? React.createElement('span', {
        'className': 'material-label m-t-0 di-block',
        'key': '4276'
    }, '\n                                    This group is on Bronze Plan\n                                ') : null, this.props.group.payment_plan ? React.createElement(Chip, { 'key': '4498' }, React.createElement('i', { 'className': 'material-icons f-14' }, 'verified_user'), '\n                                    ', this.props.group.payment_plan.name, '\n                                ') : null, React.createElement(FontIcon, {
        'onClick': this.showPaymentModal,
        'value': 'create',
        'className': 'di-block c-primary f-15 m-l-5 pointer'
    }))))))), React.createElement(Tabs, {
        'inverse': true,
        'fixed': true,
        'className': 'custom-tab accent col-xs-12',
        'index': this.state.tabIndex,
        'onChange': this.handleTabChange
    }, React.createElement(Tab, {
        'label': 'Goal',
        'icon': 'gm-goal',
        'gomotive': true
    }, this.state.topPanelVisible ? [
        !this.props.group.goal ? React.createElement('div', {
            'className': 'row middle-xs',
            'key': '52211'
        }, React.createElement('div', { 'className': 'col-xs-10 alert-warning p-l-10' }, '\n                        Click on + icon to define a goal for this group.                        \n                    '), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement(Button, {
            'tooltip': 'Add goal text & metrics',
            'onClick': this.onClickEditGoal,
            'className': 'bg-success pull-right',
            'icon': 'add',
            'floating': true,
            'ripple': true,
            'mini': true
        })), React.createElement('div', { 'className': 'col-xs-12 f-14 f-300 c-primary m-t-5' }, '\n                        Note: Other features will be enabled once you define a goal for this game plan.\n                    ')) : null,
        this.props.group.goal ? [
            React.createElement('div', {
                'className': 'row middle-xs p-5 bg-primary',
                'key': '61521'
            }, React.createElement('h4', { 'className': 'col-xs-10' }, '\n                            ', this.props.group.goal.text, '\n                            ', this.props.group.goal.goal_questions.length > 0 ? React.createElement(IconButton, {
                'onClick': this.onClickEditGoal,
                'tooltip': 'Edit goal text & metrics',
                'className': 'c-white',
                'icon': 'create',
                'key': '6390'
            }) : null, this.props.group.goal.goal_questions.length === 0 ? React.createElement(Button, {
                'onClick': this.onClickEditGoal,
                'tooltip': 'Edit goal text & metrics',
                'label': 'Create Metrics',
                'className': 'bg-warning',
                'raised': true,
                'key': '6724'
            }) : null), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement('div', { 'className': 'row end-xs p-r-5' }, React.createElement('div', { 'className': 'col-xs-12 p-b-5' }, '\n                                    Weekly Check-In\n                                '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
                'tooltip': 'Click to change the goal tracking day',
                'onClick': this.showGoalIntervalModal,
                'className': 'small-button bg-success m-l-5',
                'icon': 'date_range',
                'label': 'Every ' + this.props.group.__goal_tracking_day,
                'raised': true
            }))))),
            React.createElement.apply(this, [
                'div',
                {
                    'className': 'row',
                    'key': '61523'
                },
                _.map(this.props.group.goal.goal_questions, repeatGoal_question1.bind(this))
            ])
        ] : null,
        ,
        React.createElement(Dialog, {
            'active': this.state.goalChooserVisible,
            'title': 'Please select a goal from your library or create a custom goal for this group',
            'onEscKeyDown': this.hideGoalChooser,
            'onOverlayClick': this.hideGoalChooser,
            'key': '52217'
        }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
            'tbody',
            {},
            _.map(this.props.goal_list, repeatGoal2.bind(this))
        ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
            'label': 'Add Custom Goal',
            'onClick': this.editGoal,
            'tooltip': 'Click this button if you want to add a custom goal for this group',
            'className': 'bg-success m-r-10',
            'raised': true
        }), React.createElement(Button, {
            'label': 'Close!',
            'tooltip': 'Close dialog box',
            'accent': true,
            'raised': true,
            'onClick': this.hideGoalChooser
        }))))
    ] : null, !this.state.topPanelVisible ? [this.state.showGoalEditForm ? React.createElement(GoalEdit, {
            'group_id': this.props.match.params.group_id,
            'goal': this.props.group.goal,
            'finishEditing': this.finishEditingGoal,
            'deleteGoal': this.deleteGoal,
            'key': '109131'
        }) : null] : null), React.createElement(Tab, {
        'label': 'Program',
        'icon': 'gm-treatment',
        'gomotive': true,
        'disabled': !this.props.group.goal
    }, this.state.topPanelVisible ? React.createElement('div', {
        'style': { marginTop: '-17px' },
        'key': '11457'
    }, React.createElement('ul', { 'className': 'tab-bar grey-tab  clear-row' }, React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 0 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(0);
        }
    }, '\n                            Workouts\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 1 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(1);
        }
    }, '\n                            Habits\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 2 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(2);
        }
    }, '\n                            Nutrition\n                        ')), React.createElement('li', {}, React.createElement('a', {
        'className': ` fancy-hover pointer ${ this.state.treatmentTabIndex === 3 ? 'active' : '' }   `,
        'onClick': () => {
            this.handleTreatmentTabChange(3);
        }
    }, '\n                            Guidance\n                        '))), this.state.treatmentTabIndex === 0 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '12762'
    }, this.props.workouts.length === 0 ? React.createElement('div', {
        'className': 'col-xs-9 alert-warning m-t-10 m-b-10',
        'key': '12858'
    }, '\n                        Click on + icon to create a workout for this group.\n                    ') : null, this.props.workouts.length !== 0 ? React.createElement('div', {
        'className': 'col-xs-9',
        'key': '13073'
    }) : null, React.createElement('div', { 'className': 'col-xs-3 m-t-10 pull-right' }, React.createElement(Button, {
        'onClick': this.addWorkout,
        'tooltip': 'Add new workout',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement.apply(this, [
        Sortable,
        {
            'className': 'vertical-container',
            'sortHandle': 'handle',
            'onSort': this.handleWorkoutSort,
            'containment': true,
            'dynamic': true
        },
        _.map(this.props.workouts, repeatWorkout3.bind(this))
    ]))) : null, this.state.treatmentTabIndex === 1 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '17940'
    }, React.createElement(HabitList, {
        'group': this.props.group,
        'editHabit': this.editHabit
    })) : null, this.state.treatmentTabIndex === 2 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '18145'
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('nutrition'),
        'tooltip': 'Add nutrition document',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true
    })), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a nutrition plan to this group.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('strong', {}, 'Attachments')), React.createElement(Th, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Is Published?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.nutritionDocuments, repeatDocument5.bind(this))
    ])) : null, this.state.treatmentTabIndex === 3 ? React.createElement('div', {
        'className': 'row start-xs  m-b-20',
        'key': '22929'
    }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement(Button, {
        'onClick': () => this.toggleDocumentSelectionDialog('guidance'),
        'tooltip': 'Add guidance document',
        'className': 'bg-success pull-right m-b-10',
        'icon': 'add',
        'floating': true,
        'mini': true
    })), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Click on + icon to assign a guidance document to this group.',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'description',
            'style': { width: '250px' }
        }, React.createElement('strong', {}, 'Note')), React.createElement(Th, { 'column': 'attachments' }, React.createElement('strong', {}, 'Attachments')), React.createElement(Th, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Is Published?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '150px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.guidanceDocuments, repeatDocument7.bind(this))
    ])) : null) : null, !this.state.topPanelVisible ? React.createElement('div', { 'key': '27811' }, React.createElement('div', { 'className': 'col-xs-12' }, this.state.showWorkoutEditForm ? React.createElement(ProgramEdit, _.assign({}, {
        'finishEditing': this.finishEditingWorkout,
        'usedInside': true,
        'group_id': this.props.match.params.group_id,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'program_id': this.state.editingWorkoutId,
        'disableEditing': false,
        'isNonSyncGroup': this.props.group.group_treatment_cycle == 2,
        'key': '27913'
    }, this.state.workoutProgramTypeParams)) : null, this.state.showTemplateSelection ? React.createElement(ProgramList, _.assign({}, {
        'usedInside': true,
        'cancelTemplateSelection': this.unAllocateSpace,
        'group_id': this.props.match.params.group_id,
        'mode': TEMPLATE_SELECTION_MODE,
        'workoutTargetType': WORKOUT_TARGET_GROUP,
        'afterCreateWorkoutFromProgram': this.afterCreateWorkoutFromTemplate,
        'key': '28390'
    }, this.state.templateProgramTypeParams)) : null, this.state.habitSpaceAllocated ? React.createElement(HabitEdit, {
        'finishEditing': this.finishEditingHabit,
        'group': this.props.group,
        'habitId': this.state.editingHabitId,
        'key': '28844'
    }) : null)) : null), React.createElement(Tab, {
        'label': 'Clients',
        'icon': 'gm-clients',
        'gomotive': true,
        'disabled': !this.props.group.goal
    }, React.createElement('div', {}, React.createElement(GroupLatestGoalTracking, { 'group': this.props.group })))), React.createElement(Dialog, {
        'active': this.state.openPaymentModal,
        'title': 'Payment Plan',
        'onEscKeyDown': this.hidePaymentModal,
        'onOverlayClick': this.hidePaymentModal
    }, this.state.openPaymentModal ? React.createElement(Payment, {
        'group': this.props.group,
        'handleClose': this.hidePaymentModal,
        'key': '29532'
    }) : null), React.createElement(Dialog, {
        'active': this.state.documentSelectionDialogVisible,
        'className': 'full-dialog',
        'title': 'Click \'+\' to assign',
        'onEscKeyDown': () => this.toggleDocumentSelectionDialog(),
        'onOverlayClick': () => this.toggleDocumentSelectionDialog()
    }, this.state.documentSelectionDialogVisible ? React.createElement(HealthDocumentList, {
        'selectionMode': true,
        'match': { params: { document_type: this.state.healthDocumentType } },
        'onSelected': this.assignHealthDocument,
        'key': '30022'
    }) : null), React.createElement(Dialog, {
        'active': this.state.openGoalIntervalModal,
        'title': 'Client is prompted to check-in on this day',
        'onEscKeyDown': this.hideGoalIntervalModal,
        'onOverlayClick': this.hideGoalIntervalModal
    }, React.createElement(DefinePlan, {
        'group': this.props.group,
        'handleClose': this.hideGoalIntervalModal
    })), React.createElement(Dialog, {
        'active': this.state.openHabitTemplateModal,
        'title': 'Please select a habit from your library',
        'onEscKeyDown': this.hideHabitTemplateModal,
        'onOverlayClick': this.hideHabitTemplateModal
    }, this.state.openHabitTemplateModal ? React.createElement('div', {
        'className': 'row',
        'key': '30939'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('table', { 'className': 'table reactable' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, 'Name'), React.createElement('th', {}, 'Description'), React.createElement('th', { 'className': 'w-20 text-center' }, 'Action'))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.habit_template_list, repeatHabit_template8.bind(this))
    ]))), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-15' }, React.createElement(Button, {
        'label': 'Close',
        'tooltip': 'Close dialog box',
        'accent': true,
        'raised': true,
        'onClick': this.hideHabitTemplateModal
    }))) : null));
}