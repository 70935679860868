import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import PanelGroup from '../../../vendor/react-panelgroup/panelgroup';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatTreatment_template1(treatment_template, treatment_templateIndex) {
        return React.createElement('div', { 'key': 'treatment_template' + treatment_template.id }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, React.createElement('label', {}, treatment_template.name), React.createElement('p', { 'className': 'material-label f-10' }, treatment_template.description)), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
            'tooltip': 'Delete',
            'icon': 'delete_forever',
            'floating': true,
            'mini': true,
            'className': 'bg-danger micro',
            'onClick': () => {
                this.removeTreatmentTemplate(treatment_template);
            }
        }))))));
    }
    function repeatTreatment_template2(treatment_template, treatment_templateIndex) {
        return React.createElement('div', { 'key': 'treatment_template' + treatment_template.id }, !(treatment_template.id in this.props.partner_template_ids) ? React.createElement('div', {
            'className': 'panel',
            'key': '3539'
        }, React.createElement('div', { 'className': 'panel-body' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-11' }, React.createElement('label', {}, treatment_template.name), React.createElement('p', { 'className': 'material-label f-10' }, treatment_template.description)), React.createElement('div', { 'className': 'col-xs-1' }, React.createElement(Button, {
            'tooltip': 'Add',
            'icon': 'add',
            'floating': true,
            'mini': true,
            'className': 'bg-success micro',
            'onClick': () => {
                this.addTreatmentTemplate(treatment_template);
            }
        }))))) : null);
    }
    return React.createElement('div', { 'className': 'row start-xs  m-t-20 m-l-20 custom-divider' }, React.createElement('h3', { 'className': 'text-center m-5' }, '\n        Shared content library \'', this.props.partner_library.name, '\'\n    '), React.createElement(PanelGroup, { 'borderColor': '#eee' }, React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 full-height' },
        React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, 'Shared ', this.props.match.params.treatment_type === 'group' ? 'Group Templates' : 'Game Plan Templates')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
            'tooltip': 'Remove all templates from this shared content library',
            'label': 'REMOVE ALL',
            'className': 'bg-danger small-button c-white',
            'onClick': () => {
                this.removeAllTreatmentTemplates();
            }
        }))),
        _.map(this.props.partner_library.treatment_templates, repeatTreatment_template1.bind(this))
    ]), React.createElement.apply(this, [
        'div',
        {
            'className': 'col-xs-12',
            'style': { minHeight: '400px' }
        },
        React.createElement('div', { 'className': 'row start-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('h5', { 'className': 'm-b-15' }, this.props.match.params.treatment_type === 'group' ? 'Group Templates' : 'Game Plan Templates', ' you can share')), React.createElement('div', { 'className': 'col-xs-6 end-xs' }, React.createElement(Button, {
            'tooltip': 'Add all templates to this shared content library',
            'label': 'ADD ALL',
            'className': 'bg-success small-button c-white',
            'onClick': () => {
                this.addAllTreatmentTemplates();
            }
        }))),
        _.map(this.props.treatment_template_list, repeatTreatment_template2.bind(this))
    ])));
}