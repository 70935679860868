import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/recommend_gomotive";
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import { getRecommendGomotiveDetails, sendRecommendGomotiveEmail} from '../../redux/buildmypractice/network/recommend_gomotive_network';

class RecommendGoMotive extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
    }
    
    componentWillMount() {
        this.props.getRecommendGomotiveDetails();
    }

    componentWillUnmount() {
    }

    doSave(data) {
        let params = _.omit(data, ['can_be_deleted']);
        this.props.sendRecommendGomotiveEmail(params).then((response) => {
        }).catch((error) => {
        });  
    }
    
    render() {
        if(_.isEmpty(this.props.recommend_gomotive)){
            return (<div></div>);
        }
        return Template.apply(this);

    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        recommend_gomotive: state.buildmypractice.recommendGomotiveReducer.recommend_gomotive,
        initialValues: state.buildmypractice.recommendGomotiveReducer.recommend_gomotive
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getRecommendGomotiveDetails,
            sendRecommendGomotiveEmail
        }, 
        dispatch
    );
}


const validate = (values, props) => {
    let requiredFields = [
        'email',
        'subject',
        'body',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const RecommendGoMotiveForm = reduxForm({
    form: 'RecommendGoMotiveForm', 
    enableReinitialize: true, 
    validate
})(RecommendGoMotive);

const RecommendGoMotiveFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(RecommendGoMotiveForm);
export default RecommendGoMotiveFormConnected;

