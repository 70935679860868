// Action Creators
const fillProfile = (profile, supportingData) => ({
    type: 'USER_PROFILE_FILL',
    profile,
    supportingData,
});
const fillClientSearchPreference = (client_search_preference) => ({
    type: 'USER_PROFILE_CLIENT_SEARCH_PREFERENCE',
    client_search_preference,
});
const fillClientMovementMeterConfiguration = (user_movement_meter_configuration) => ({
    type: 'USER_MOVEMENT_METER_CONFIGURATION',
    user_movement_meter_configuration,
});
const cleanModule = () => ({
    type: 'USER_PROFILE_CLEANUP',
});

export default {
    fillProfile,
    fillClientSearchPreference,
    fillClientMovementMeterConfiguration,
    cleanModule,
};
