import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/intake_configure.rt";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { history} from '../../redux/store';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import IntakeAction from '../../redux/intake/actions/intake_creator';
import {getIntakeInstance,saveIntakeQuestion,toggleFormPublish,updateIntakeFormName} from '../../redux/intake/network/intake_network';
import {verifyToken} from '../../redux/auth/network/auth_network';
import swal from 'sweetalert2';

const INTAKE_FORM_TYPE = 1;

class IntakeDetails extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.selectQuestion = this.selectQuestion.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.viewPreview = this.viewPreview.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.publishIntakeForm = this.publishIntakeForm.bind(this);
        this.updateFormName = this.updateFormName.bind(this);
        this.state = { question_guid: "",add_question_disabled:false };
    }
    

    componentWillMount() {
        let intake_id = this.props.match.params.intake_id;
        if (intake_id){
            this.props.getIntakeInstance({form_type:1,id:intake_id});
        } else {
            history.goBack();
        }
        
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }
    
    handleSort(questions_ordered) {
        questions_ordered = _.cloneDeep(questions_ordered);
        questions_ordered = _.map(questions_ordered, (item,index) => {
            item.field_order = index + 1;
            return item;
        });
        let params = { questions: questions_ordered, eform: this.props.intake.id, type: 'reorder' };
        this.props.saveIntakeQuestion(params);        
    }


    selectQuestion(question){
        this.props.selectQuestion(question);
        window.scrollTo(0, 0);
    }    

    viewPreview(){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/intake/preview/"+this.props.intake.id);
    }

    updateFormName() {
        let that = this;
        swal.fire({
            title: "Intake form name!",
            text: "",            
            input: "text",
            inputValue: that.props.intake.name,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Update",
            cancelButtonText: "Close",
            useRejections:true
        }).then(function (inputValue) {
            if (inputValue === false) return false;            
            if (inputValue === "") {
                swal.showInputError("Provide a name for intake form!");
                return false;
            } 
            swal.close();
            that.props.updateIntakeFormName({
                'intake_form_id': that.props.intake.id,
                'name': inputValue.value,
                'form_type': INTAKE_FORM_TYPE
            }).then(() => {
                Utils.showSuccessAlert("Intake form name has been updated");                    
            }).catch((exception) => { });        
        }, function (dismiss) {
                        
        });
    }


    publishIntakeForm() {
        let text = "";
        let previousState = null;
        if (this.props.intake.published === true) {
            text = "Would you like to unpublish the form?";
            previousState = true;
        } else {
            text = "Would you like to publish the form?";
            previousState = false;
        }
        let that = this;
        swal.fire({
            title: "Are you sure?",
            text: text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            that.props.toggleFormPublish({
                intake_form_id: that.props.intake.id,
                'form_type': INTAKE_FORM_TYPE
            }).then(() => {
                if (previousState) {
                    Utils.showSuccessAlert("Intake form has been unpublished from usage");
                } else {
                    Utils.showSuccessAlert("Intake form has been published for usage");
                }
            }).catch((exception) => { });            
        }, function (dismiss) {
                        
        });        
    }

    
    addQuestion() {
        let that = this;
        this.setState({ add_question_disabled: true }, () => {
            this.props.addQuestion();
            //We are waiting for 500 milliseconds so that field_guid in a question is unique
            setTimeout(() => {
                that.setState({ add_question_disabled: false });
                this.props.selectQuestion(this.props.intake.questions[this.props.intake.questions.length-1]);
            },500);
        });
    }    

    render() {
        if (_.isEmpty(this.props.intake)) {
            return (<div className="row center-xs m-t-25">
                    <ProgressBar  type='circular' mode='indeterminate' multicolor />    
            </div>);
        }
        return Template.apply(this);
    }
}
function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,        
        intake: state.intake.intakeReducer.intake,
        selected_question: state.intake.intakeReducer.question
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getIntakeInstance,
        saveIntakeQuestion,
        toggleFormPublish,
        updateIntakeFormName,
        cleanUp: IntakeAction.intakeCleanUp,
        selectQuestion: IntakeAction.intakeQuestionFill,
        addQuestion: IntakeAction.intakeQuestionAdd,
        reorderQuestion: IntakeAction.intakeQuestionReorder,
    }, dispatch);
}

const IntakeDetailsConnected = connect(mapStateToProps,mapDispatchToProps)(IntakeDetails);
export default IntakeDetailsConnected;
