// Action Creators
const clientHomeFill = (
    show_movement_graph,
    current_week_movement_points,
    home_page_title,
    has_viewed_welcome_video,
    client_welcome_video_url,
    workout_image_url,
    associated_practice_count,
    program_count,
    program_type,
    program_id,
    news_list,
    practitioners,
    unread_message_count,
    unread_chat_count,    
) => ({
    type: 'CLIENT_HOME_FILL',
    show_movement_graph,
    current_week_movement_points,
    home_page_title,
    has_viewed_welcome_video,
    client_welcome_video_url,
    workout_image_url,
    associated_practice_count,
    program_count,
    program_type,
    program_id,
    news_list,
    practitioners,
    unread_message_count,
    unread_chat_count,    
});
const clientHomeAlertsFill = (unread_chat_count,unread_messages_count,untracked_goals,intakes_assigned) => ({
    type: 'CLIENT_HOME_ALERTS_FILL',
    unread_chat_count,
    unread_messages_count,
    untracked_goals,
    intakes_assigned,
});
const clientCheckinListFill = (checkin_count,engagement_list,group_list,quickstart_list,available_dates) => ({
    type: 'CLIENT_CHECKIN_LIST_FILL',
    checkin_count,
    engagement_list,
    group_list,
    quickstart_list,
    available_dates
});
const clientStartListFill = (start_list) => ({
    type: 'CLIENT_START_LIST_FILL',
    start_list,
});
const clientConnectPractitionersFill = (practitioners) => ({
    type: 'CLIENT_CONNECT_PRACTITIONERS',
    practitioners,
});

const cleanModule = () => ({
    type: 'CLIENT_HOME_CLEANUP',
});


const clientProgramListFill = (programs) => ({
    type: 'CLIENT_PROGRAM_LIST_FILL',
    programs,
});

const clientProgramFill = (program) => ({
    type: 'CLIENT_PROGRAM_FILL',
    program,
});

const clientIntakeFormListFill = (intake_forms) => ({
    type: 'CLIENT_INTAKE_FORM_LIST_FILL',
    intake_forms,
});



export default {
    clientHomeAlertsFill,
    clientHomeFill,
    clientCheckinListFill,
    clientStartListFill,
    clientConnectPractitionersFill,
    cleanModule,
    clientProgramFill,
    clientProgramListFill,
    clientIntakeFormListFill,
};
