import * as React from 'react';
import * as _ from 'lodash';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import { ListDivider } from 'react-toolbox/lib/list';
import { ListSubHeader } from 'react-toolbox/lib/list';
import FontIcon from 'react-toolbox/lib/font_icon';
import { NavLink } from 'react-router-dom';
import { IconMenu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { PARTNER } from '../../../core/constants/constants';
export default function () {
    return React.createElement('ul', { 'className': 'm-t-20' }, PARTNER.app_type === 'gi' ? React.createElement('span', { 'key': '711' }, this.props.roles.length > 1 ? React.createElement('li', { 'key': '760' }, React.createElement('a', {
        'onClick': this.switchRole,
        'className': 'pointer'
    }, React.createElement(FontIcon, {
        'value': 'gm-switch-role',
        'gomotive': true
    }), '\n                Switch Role\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/home',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n                Home\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/my_plans',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'credit_card' }), '\n                My Plans\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/client/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n                User Settings\n            ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n                Sign out\n            '))) : null, PARTNER.app_type === 'dhf' ? React.createElement('span', { 'key': '1787' }, this.props.roles.length > 1 ? React.createElement('li', { 'key': '1837' }, React.createElement('a', {
        'onClick': this.switchRole,
        'className': 'pointer'
    }, React.createElement(FontIcon, {
        'value': 'gm-switch-role',
        'gomotive': true
    }), '\n                Switch Role\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/home',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n                Home\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/intakes',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-intake',
        'gomotive': true
    }), '\n                Intake Forms\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/scheduler',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-appointments',
        'gomotive': true
    }), '\n                Scheduler\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/my_plans',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'credit_card' }), '\n                My Plans\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/client/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n                User Settings\n            ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n                Sign out\n            '))) : null, PARTNER.app_type === 'tri' ? React.createElement('span', { 'key': '3306' }, this.props.roles.length > 1 ? React.createElement('li', { 'key': '3356' }, React.createElement('a', {
        'onClick': this.switchRole,
        'className': 'pointer'
    }, React.createElement(FontIcon, {
        'value': 'gm-switch-role',
        'gomotive': true
    }), '\n                Switch Role\n            ')) : null, React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/home',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-home',
        'gomotive': true
    }), '\n                Home\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/intakes',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-intake',
        'gomotive': true
    }), '\n                Intake Forms\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/scheduler',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, {
        'value': 'gm-appointments',
        'gomotive': true
    }), '\n                Scheduler\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': '/client/my_plans',
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'credit_card' }), '\n                My Plans\n            ')), React.createElement('li', {}, React.createElement(NavLink, {
        'to': `/client/settings`,
        'activeClassName': 'active'
    }, React.createElement(FontIcon, { 'value': 'person_outline' }), '\n                User Settings\n            ')), React.createElement('li', {}, React.createElement('a', {
        'onClick': this.doLogout,
        'className': 'pointer'
    }, React.createElement(FontIcon, { 'value': 'exit_to_app' }), '\n                Sign out\n            '))) : null);
}