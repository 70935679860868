import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_engagement_habit_view.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import moment from 'moment';

class ClientEnagagementHabitView extends BaseComponent {
    
        constructor(props, context) {
            super(props, context);
            this.trackHabit = this.trackHabit.bind(this);
        }
    
        trackHabit(track_status){
            let params = {
                track_status:track_status,
                habit_track_id:this.props.habit.id,
                engagement_id:this.props.habit.client_engagement_habit.engagement,
            };
            this.props.trackHabitProgressCallback(params);
        }

        render() {
            return Template.apply(this);
        }
    
    }
    export default ClientEnagagementHabitView;