import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../../utils/common_components/tooltipButton';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import StripeCheckout from 'react-stripe-checkout';
import { STRIPE_KEY } from '../../../../payment/constants/constants';
import MemberInvite from '../../../../utils/common_components/invite/user_invitation';
import Chip from 'react-toolbox/lib/chip';
export default function () {
    function repeatPlan1(plan, planIndex) {
        return [React.createElement('div', {
                'className': 'col-md-4 col-sm-6',
                'key': '21461'
            }, React.createElement('div', { 'className': 'pricing-widget text-center' }, React.createElement('div', {
                'className': `pricing-head f-20 d-flex flex-column justify-center  ${ this.props.move_user.homefit_user.plan && this.props.move_user.homefit_user.plan.id === plan.id ? 'bg-primary' : '' }    `,
                'style': { minHeight: '200px' }
            }, React.createElement('div', { 'className': 'w-100 f-400' }, plan.name), React.createElement('div', { 'className': `f-18 w-100  ${ this.props.move_user.homefit_user.plan && this.props.move_user.homefit_user.plan.id === plan.id ? '' : 'c-primary' }    ` }, React.createElement('span', {}, ' USD ', plan.amount, ' / month ')), !this.props.move_user.homefit_user.plan || this.props.move_user.homefit_user.plan && this.props.move_user.homefit_user.is_primary_buyer ? [this.props.move_user.homefit_user && (!this.props.move_user.homefit_user.subscription_device || this.props.move_user.homefit_user.subscription_device === 'web') ? React.createElement('span', { 'key': '29601' }, !this.props.move_user.homefit_user.plan || this.props.move_user.homefit_user.plan.id !== plan.id ? React.createElement(StripeCheckout, {
                    'name': plan.name,
                    'description': plan.description,
                    'image': '/site_media/static/images/touch-icon-iphone.png',
                    'panelLabel': 'Pay',
                    'amount': plan.amount * 100,
                    'currency': 'USD',
                    'stripeKey': STRIPE_KEY,
                    'email': this.props.user.email,
                    'zipCode': false,
                    'allowRememberMe': false,
                    'token': token => {
                        this.subscribe(token, plan);
                    },
                    'key': '3372'
                }, React.createElement(Button, {
                    'tooltip': 'Click to subscribe',
                    'className': 'bg-success small-button m-t-20',
                    'icon': 'card_membership',
                    'label': 'Subscribe',
                    'primary': true,
                    'raised': true
                })) : null, this.props.move_user.homefit_user.plan && this.props.move_user.homefit_user.plan.id === plan.id ? React.createElement('span', { 'key': '4968' }, React.createElement(Button, {
                    'tooltip': 'Click to unsubscribe',
                    'className': 'bg-accent small-button m-t-20',
                    'icon': 'money_off',
                    'onClick': () => {
                        this.unSubscribe(plan);
                    },
                    'label': 'Unsubscribe',
                    'primary': true,
                    'raised': true
                })) : null) : null] : null), React.createElement('div', { 'className': 'pricing-body' }, plan.membership_type === 1 ? React.createElement('ul', {
                'className': 'pricing-list text-center',
                'key': '5954'
            }, React.createElement('li', {}, 'Subscription Members ', React.createElement('strong', {}, plan.membership_count))) : null, plan.membership_type === 2 ? React.createElement('ul', {
                'className': 'pricing-list text-center',
                'key': '6197'
            }, React.createElement('li', {}, 'Subscription Members ', React.createElement('strong', {}, plan.membership_count))) : null)))];
    }
    function repeatMember2(member, memberIndex) {
        return React.createElement('tr', { 'key': memberIndex }, React.createElement('td', {}, '\n                                        ', member.name, '\n                                '), React.createElement('td', {
            'className': 'text-center',
            'style': { width: '150px' }
        }, member.user_state === 3 ? React.createElement(Chip, {
            'className': 'bg-success',
            'key': '8044'
        }, ' Active ') : null, member.user_state !== 3 ? React.createElement(Chip, {
            'className': 'bg-warning',
            'key': '8156'
        }, ' Invited ') : null), React.createElement('td', {
            'className': 'text-center',
            'style': { width: '100px' }
        }, !member.is_primary_user && this.props.move_user.homefit_user.is_primary_buyer ? React.createElement(IconButton, {
            'tooltip': 'Delete member subscription',
            'icon': 'delete_forever',
            'className': 'm-r-5 c-danger small-icon-button round-border',
            'onClick': () => this.deleteFamilyMember(member),
            'key': '8383'
        }) : null));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement('div', { 'className': 'row center-xs' }, this.props.move_user.is_homefit_subscription_valid ? React.createElement('div', {
        'className': 'panel col-xs-12 text-left m-b-10 p-0',
        'key': '688'
    }, React.createElement('div', {
        'className': 'panel-heading',
        'style': { borderLeft: '5px solid #0292D3' }
    }, React.createElement('div', { 'className': 'panel-title' }, '\n                       Subscribed to ', this.props.move_user.homefit_user.plan.name, '\n                '), React.createElement('div', { 'className': 'panel-subtitle' }, this.props.move_user.homefit_user.is_primary_buyer ? React.createElement('span', { 'key': '1093' }, '\n                            You are paying USD ', this.props.move_user.homefit_user.plan.amount, ' per month.\n                       ') : null, !this.props.move_user.homefit_user.is_primary_buyer ? React.createElement('span', { 'key': '1321' }, '\n                           Your Family is paying USD ', this.props.move_user.homefit_user.plan.amount, ' per month.\n                       ') : null), this.props.move_user.homefit_user.is_primary_buyer && this.props.move_user.homefit_user.subscription_device !== 'web' ? React.createElement('div', {
        'className': 'alert-warning m-t-10',
        'key': '1580'
    }, '\n                        Subscription was bought in ', this.props.move_user.homefit_user.__subscription_device, ' device.\n                        You can unsubscribe only from your ', this.props.move_user.homefit_user.__subscription_device, ' device\n                 ') : null)) : null, this.props.move_user && this.props.move_user.homefit_user ? [_.map(this.props.move_user.plans, repeatPlan1.bind(this))] : null), this.props.move_user.homefit_user && this.props.move_user.homefit_user.plan && this.props.move_user.homefit_user.plan.membership_type === 2 ? React.createElement('div', {
        'className': 'row start-xs m-t-20 p-relative',
        'key': '6562'
    }, React.createElement('h4', {}, 'Add Family Members'), this.props.move_user.homefit_user.is_primary_buyer ? React.createElement(Button, {
        'className': 'floating-add-button',
        'tooltip': 'Click to add family members',
        'style': { top: '40px' },
        'onClick': this.showInviteMemberModal,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'key': '6809'
    }) : null, React.createElement('table', { 'className': 'table reactable col-xs-10 m-t-10' }, React.createElement('thead', {}, React.createElement('tr', {}, React.createElement('th', {}, '\n                            Name\n                        '), React.createElement('th', {
        'className': 'text-center',
        'style': { width: '150px' }
    }, '\n                                Status\n                        '), React.createElement('th', {
        'className': 'text-center',
        'style': { width: '100px' }
    }, '\n                                Actions\n                        '))), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(this.props.family_member_list, repeatMember2.bind(this))
    ])), React.createElement(Dialog, {
        'active': this.state.showInviteMemberModal,
        'className': 'bg-transparent no-box-shadow',
        'onEscKeyDown': this.hideInviteMemberModal,
        'onOverlayClick': this.hideInviteMemberModal
    }, this.state.showInviteMemberModal ? React.createElement(MemberInvite, {
        'title': 'Invite Family Member',
        'subject': this.state.subject,
        'body': this.state.body,
        'handleCancel': this.hideInviteMemberModal,
        'handleInvite': this.inviteFamilyMember,
        'key': '9141'
    }) : null)) : null);
}