import * as React from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-toolbox/lib/date_picker';
import Chip from 'react-toolbox/lib/chip';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import moment from 'moment';
import HelpTip from '../../../utils/common_components/help_tip';
import { Link } from 'react-router-dom';
import { history } from '../../../redux/store';
export default function () {
    function repeatAttachment1(nutrition, nutritionIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('i', { 'className': 'material-icons vertical-middle f-18' }, 'insert_drive_file'), React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatNutrition2(nutrition, nutritionIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': nutritionIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-nutrition' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'panel w-100' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, nutrition.name), React.createElement('div', { 'className': 'panel-subtitle pre-wrap' }, nutrition.description)), React.createElement('div', { 'className': 'panel-body  vertical-align' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(nutrition.attachments, repeatAttachment1.bind(this, nutrition, nutritionIndex))
        ])))));
    }
    function repeatAttachment3(guidance, guidanceIndex, attachment, attachmentIndex) {
        return React.createElement(Chip, { 'key': attachmentIndex }, React.createElement('i', { 'className': 'material-icons vertical-middle f-18' }, 'insert_drive_file'), React.createElement('a', {
            'style': { textDecoration: 'underline' },
            'href': attachment.attachment_url,
            'target': '_blank'
        }, attachment.attachment_name));
    }
    function repeatGuidance4(guidance, guidanceIndex) {
        return React.createElement('div', {
            'className': 'row',
            'key': guidanceIndex
        }, React.createElement('div', { 'className': 'col-xs-1 text-center' }, React.createElement('i', { 'className': 'gomotive-icons m-r-10 f-35 gm-guidance' })), React.createElement('div', {
            'className': 'col-xs-11',
            'style': { zIndex: '1' }
        }, React.createElement('div', { 'className': 'panel w-100' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 panel-bottom-border dashed' }, React.createElement('div', { 'className': 'panel-title' }, guidance.name), React.createElement('div', { 'className': 'panel-subtitle pre-wrap' }, guidance.description)), React.createElement('div', { 'className': 'panel-body  vertical-align' }, React.createElement.apply(this, [
            'div',
            { 'className': 'm-t-5' },
            _.map(guidance.attachments, repeatAttachment3.bind(this, guidance, guidanceIndex))
        ])))));
    }
    return React.createElement('div', { 'className': 'm-10' }, React.createElement('div', { 'className': 'row m-b-30' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement('div', { 'className': 'row middle-xs' }, React.createElement('div', { 'className': 'col' }, React.createElement('h3', {}, '\n                        ', this.props.program.name, '\n                    '))))), this.props.program.action_type === 'display_payment' ? React.createElement('div', { 'key': '991' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', {
        'className': 'col-xs-11',
        'style': { zIndex: '1' }
    }, React.createElement('div', { 'className': 'panel' }, React.createElement('div', { 'className': 'panel-heading p-l-10 p-r-10 p-b-5 p-t-10 f-20' }, React.createElement('div', { 'className': 'panel-title' }, 'Payment Subscription'), React.createElement('div', { 'className': 'panel-subtitle' }, 'Complete the payment to view treatment details ')), this.props.program.type === 'engagement' ? React.createElement('div', {
        'className': 'panel-body',
        'key': '1497'
    }, React.createElement(Button, {
        'onClick': () => {
            history.push('/client/engagement/' + this.props.program.id);
        },
        'className': 'bg-success',
        'icon': 'credit_card',
        'label': 'Subscribe',
        'tooltip': 'Click to complete payment for you to view treatment details',
        'raised': true,
        'primary': true
    })) : null, this.props.program.type === 'group' ? React.createElement('div', {
        'className': 'panel-body',
        'key': '1871'
    }, React.createElement(Button, {
        'onClick': () => {
            history.push('/client/engagement/' + this.props.program.id);
        },
        'className': 'bg-success',
        'icon': 'credit_card',
        'label': 'Subscribe',
        'tooltip': 'Click to complete payment for you to view treatment details',
        'raised': true,
        'primary': true
    })) : null)))) : null, this.props.program.action_type === 'view_treatment' ? React.createElement('div', { 'key': '2293' }, this.props.program.nutrition.length > 0 || this.props.program.guidance.length > 0 ? React.createElement.apply(this, [
        'div',
        { 'key': '2367' },
        _.map(this.props.program.nutrition, repeatNutrition2.bind(this)),
        _.map(this.props.program.guidance, repeatGuidance4.bind(this))
    ]) : null, this.props.program.nutrition.length === 0 && this.props.program.guidance.length === 0 ? React.createElement('div', { 'key': '5220' }, '\n            No nutrition & guidance documents have been assigned for this program.\n        ') : null) : null);
}