import { contains } from "jquery";

let initialState = {
    contentList: [],
    selectedAssociatedContent: [],
    selectedAssociatedWorkout: [],
    homeFitContentList: [],
    associatedContentList: [],
    paginateInfo: {
        page: 0,
    },
    categories: [],
    filterData:{},
    searchPreferences:{},
    news: {},
    homeFitNews: {},
    filterContentList: [],
    filterHomeFitContentList: [],
};
export default (state = initialState, action) => {

    switch (action.type) {
        case "CATEGORY_FILL":
            return Object.assign({}, state, {
                categories: action.categories,
            });

        case "EXERCISE_FILL":
            
            let guid = "";
            let thumbnail_url = "";
            let video_url = "";
            if(action.news && Object.keys(action.news).length) {
                if(action.news.news_media && action.news.news_media.length) {     
                    if(action.news.news_media[0].video_url){      
                        const path = action.news.news_media[0].video_url.split('/');
                        guid = path[path.length -1].split('?')[0];
                        thumbnail_url = action.news.news_media[0].thumbnail_url;
                    }
                }
            }

            return Object.assign({}, state, {
                news: { 
                    ...action.news,
                    video_thumbnail_url : thumbnail_url,
                    video_guid : guid,
                } ,               
            });
        case "CONTENT_FILL":
        
            let homefitguid = "";
            let homefit_thumbnail_url = "";
            let homefit_audio_url="";
            if(action.homeFitNews && Object.keys(action.homeFitNews).length) {
                if(action.homeFitNews.news_media && action.homeFitNews.news_media.length) {     
                    if(action.homeFitNews.news_media[0].url){      
                        const path = action.homeFitNews.news_media[0].url.split('/');
                        homefitguid = path[path.length -1];
                        homefit_thumbnail_url = action.homeFitNews.news_media[0].thumbnail_url;
                        homefit_audio_url = action.homeFitNews.news_media[0].url;
                    }
                }
            }

            return Object.assign({}, state, {
                homeFitNews: { 
                    ...action.homeFitNews,
                    video_thumbnail_url : homefit_thumbnail_url,
                    video_guid : homefitguid,
                    audio_url : homefit_audio_url,
                } ,               
            });
        case "EXERCISE_CLEANUP":
            return Object.assign({}, initialState);

        case "EXERCISE_LIST_FILL":
            if(!action.contentList) {
                return state;
            }
            let content = [];
            if(action.paginateInfo && action.paginateInfo.page ===0 ){
                content = action.contentList;
            }else{
                let newIds = _.map(action.contentList, (item) => { return item.id; });
                let oldRetain = _.filter(state.contentList, (item) => { return newIds.indexOf(item.id) === -1; });
                content = oldRetain.concat(action.contentList);
            }
            return   Object.assign({}, state, {
                contentList: content,
                paginateInfo: action.paginateInfo
            });
            case "EXERCISE_LIST_FILL_CATEGORY":
            if(!action.contentList) {
                return state;
            }
            let contentCategory = [];
            if(action.paginateInfo && action.paginateInfo.page ===0 ){
                contentCategory = action.contentList;
            }else{
            contentCategory = action.contentList;
            }
            return   Object.assign({}, state, {
                contentList: contentCategory,
                paginateInfo: action.paginateInfo
            });

            case "CONTENT_LIST_FILL":
                // if(!action.homeFitContentList) {
                //     return state;
                // }
                // let homefitContent = [];
                // if(action.paginateInfo && action.paginateInfo.page === 0 ){
                //     homefitContent = action.homeFitContentList;
                // } else if(action.paginateInfo && action.paginateInfo.page > 0 )  {
                //     homefitContent = [...state.homeFitContentList,...action.homeFitContentList];
                // }
                // homefitContent = action.homeFitContentList;

                if(!action.homeFitContentList) {
                    return state;
                }
                let homefitContent = [];
                if(action.paginateInfo && action.paginateInfo.page ===0 ){
                    homefitContent = action.homeFitContentList;
                }else{
                    let newIds = _.map(action.homeFitContentList, (item) => { return item.id; });
                    let oldRetain = _.filter(state.homeFitContentList, (item) => { return newIds.indexOf(item.id) === -1; });
                    homefitContent = oldRetain.concat(action.homeFitContentList);
                }
                return   Object.assign({}, state, {
                    homeFitContentList: homefitContent,
                    paginateInfo: action.paginateInfo
                });

        case "EXERCISE_FILTER_DATA_FILL":
            if(!action.filterContentList) {
                return state;
            }        
            if(action.first){
                let newIds = _.map(action.contentList, (item) => { return item.id; });
            let oldRetain = _.filter(state.contentList, (item) => { return newIds.indexOf(item.id) === -1; });
            content = oldRetain.concat(action.contentList);
                return Object.assign({}, state, {
                    // contentList: action.filterContentList,
                    contentList: content,
                    // searchPreferences:{...action.filterData.search_params}
                });
            }else{
                return Object.assign({}, state, {
                    contentList: action.filterContentList
                });
            }
        case "CONTENT_FILTER_LIST_FILL":
            if(!action.filterContentListFull) {
                return state;
            }        
            if(action.first){
                let newIds = _.map(action.contentList, (item) => { return item.id; });
            let oldRetain = _.filter(state.contentList, (item) => { return newIds.indexOf(item.id) === -1; });
            content = oldRetain.concat(action.contentList);
                return Object.assign({}, state, {
                    // contentList: action.filterContentListFull,
                    contentList: content,
                    // searchPreferences:{...action.filterData.search_params}
                });
            }else{
                return Object.assign({}, state, {
                    contentListAssociated: action.filterContentListFull
                });
            }
        case "CONTENT_FILTER_DATA_FILL":
            if(!action.filterHomeFitContentList) {
                return state;
            }        
            if(action.first){
                return Object.assign({}, state, {
                    homeFitContentList: action.filterHomeFitContentList,
                    // searchPreferences:{...action.filterData.search_params}
                });
            }else{
                return Object.assign({}, state, {
                    homeFitContentList: action.filterHomeFitContentList
                });
            }

            case "ASSOCIATED_CONTENT_DATA_FILL":
                if(!action.associatedContentList) {
                    return state;
                }        
                if(action.first){
                    return Object.assign({}, state, {
                        associatedContentList: action.associatedContentList,
                        // searchPreferences:{...action.filterData.search_params}
                    });
                }else{
                    return Object.assign({}, state, {
                        associatedContentList: action.associatedContentList
                    });
                }
            case "ASSOCIATED_CONTENT_SELECTED":
                if(!action.associatedContent) {
                    return state; 
                }
                let result = []
                Object.keys(action.associatedContent).map(function (key) {
                    if(key != 'preventDefault'){
                        result[action.associatedContent[key].id || action.associatedContent[key] ] =  action.associatedContent[key];
                    }
                 });
                return Object.assign({}, state, {
                    selectedAssociatedContent: {
                        ...result,
                        ...state.selectedAssociatedContent
                    }
                });
            case "ASSOCIATED_WORKOUT_SELECTED":
                if(!action.associatedWorkout) {
                    return state; 
                }
                let resultWorkout = []
                Object.keys(action.associatedWorkout).map(function (key) {
                    if(key != 'preventDefault'){
                        resultWorkout[action.associatedWorkout[key].id || action.associatedWorkout[key] ] =  action.associatedWorkout[key];
                    }
                 });
                return Object.assign({}, state, {
                    selectedAssociatedWorkout: {
                        ...resultWorkout,
                        ...state.selectedAssociatedWorkout
                    }
                });

        case "EXERCISE_LIST_ADD":
            if(!action.exercise) {
                return state;
            }
            let addExerciseList = [action.exercise,...state.exerciseList];
            return Object.assign({}, state, {
                exerciseList: addExerciseList,
                paginateInfo: state.paginateInfo

            });
        case "EXERCISE_LIST_REMOVE":
            let newExerciseList = _.filter(state.exerciseList, function(exerciseObj) {
                if(exerciseObj.id !== action.exercise.id) {
                    return true;
                } else {
                    return false;
                }
            });
            return Object.assign({}, state, {
                exerciseList: newExerciseList,
                paginateInfo: state.paginateInfo
            }); 
        case "CONTENT_LIST_REMOVE":
            let newContentList = _.filter(state.homeFitContentList, function(homefitContentObj) {
                if(homefitContentObj.id !== action.homefitContent.id) {
                    return homefitContentObj.id;
                } else {
                    return false;
                }
            });
            return Object.assign({}, state, {
                homeFitContentList: newContentList,
                paginateInfo: state.paginateInfo
            });
        case "EXERCISE_LIST_UPDATE_AFTER_TOGGLE_FAVORITE":
            if(!action.exercise) {
                return state;
            }
            let toggleExerciseList = _.map(state.exerciseList, (item) => {
                if (item.id === action.exercise.id) {
                    return action.exercise;
                } else {
                    return item;
                }
            });
            return Object.assign({}, state, {
                exerciseList: toggleExerciseList,
                paginateInfo: state.paginateInfo
            });

        case "EXERCISE_LIST_CLEANUP":
            return Object.assign({}, initialState);
        case "ADD_EXERCISE_TO_WORKOUT_CART":
            if(!action.exercise) {
                return state;
            }
            let exerciseObj = {...action.exercise};
            exerciseObj["exercise_id"] = action.exercise["id"];
            exerciseObj["id"] = null;
            exerciseObj["sets"] = 1;
            exerciseObj["reps"] = 10;
            exerciseObj["distance"] = 1;
            exerciseObj["duration"] = 1;
            if(state.workoutCartExeciseList.length == 0) {
                exerciseObj["order"] = 0;
            } else {
                exerciseObj["order"] = state.workoutCartExeciseList.length;
            }
            let newWorkoutCartExerciseList = [...state.workoutCartExeciseList];
            newWorkoutCartExerciseList.push(exerciseObj)
            return Object.assign({}, state, {
                workoutCartExeciseList: newWorkoutCartExerciseList
            });
        case "CLEAR_WORKOUT_CART":            
            return Object.assign({}, state, {
                workoutCartExeciseList: []
            });
        case "REMOVE_ITEM":            
        if(!action.id) {
            return state;
        }
        let newList = Object.keys(state.selectedAssociatedContent).reduce((object, key) => {
            if (key !== action.id) {
              object[key] = state.selectedAssociatedContent[key]
            }
            return object
          }, {})
            return Object.assign({}, state, {
                selectedAssociatedContent: newList
            });
        case "REMOVE_ITEM_WORKOUT":            
        if(!action.id) {
            return state;
        }
        let newWorkoutList = Object.keys(state.selectedAssociatedWorkout).reduce((object, key) => {
            if (key !== action.id) {
              object[key] = state.selectedAssociatedWorkout[key]
            }
            return object
          }, {})
            return Object.assign({}, state, {
                selectedAssociatedWorkout: newWorkoutList
            });
        default:
            return state;
    }
};