import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Notification Template',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.saveWorkfitNotificationTemplate) }, React.createElement('div', { 'className': 'row m-25' }, React.createElement('div', { 'className': 'col-xs-12 m-t-10' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'notification_text',
        'multiline': true,
        'className': 'w-100',
        'name': 'notification_text',
        'type': 'text',
        'component': TextInput,
        'rows': 2,
        'label': 'Notification Text'
    })))), React.createElement('div', { 'className': 'col-xs-12 m-t-25 end-xs m-20' }, React.createElement(Button, {
        'label': 'Save & Publish',
        'className': 'bg-success m-r-10',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'submit',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    })))))));
}