import * as React from 'react';
import * as _ from 'lodash';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import Avatar from '../../../utils/common_components/profile_picture';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Utils } from '../../../utils/utils';
import GoalTrackingGraph from '../group_goal_tracking_graph';
import ClientAdd from '../group_client_add';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import moment from 'moment';
import { Checkbox } from 'react-toolbox/lib/checkbox';
import DatePicker from 'react-toolbox/lib/date_picker';
export default function () {
    function repeatClient1(client, clientIndex) {
        return React.createElement('div', {
            'className': 'box-shadow bg-white m-10 width-300 ribbon-container',
            'key': 'client' + client.id
        }, this.props.group.group_treatment_cycle === 2 && !client.treatment_started_date ? React.createElement('div', {
            'className': 'bg-danger ribbon f-8 m-t-18',
            'key': '1857'
        }, '\n            NOT ACTIVE\n        ') : null, this.props.group.group_treatment_cycle === 2 && client.treatment_started_date && client.treatment_started_date <= this.state.today ? React.createElement('div', {
            'className': 'bg-success ribbon f-8 m-t-18',
            'key': '2032'
        }, '\n            IN PROGRESS\n        ') : null, this.props.group.group_treatment_cycle === 2 && client.treatment_started_date && client.treatment_started_date > this.state.today ? React.createElement('div', {
            'className': 'bg-warning ribbon f-8 m-t-18 c-black',
            'key': '2264'
        }, '\n            STARTS IN FUTURE\n        ') : null, React.createElement('div', { 'className': 'row pointer' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Avatar, { 'user': client })), React.createElement('div', {
            'className': 'col-xs-6 p-10 p-relative',
            'style': { width: '150px' }
        }, React.createElement('div', {
            'className': 'end-xs',
            'style': {
                wordBreak: 'break-word',
                wordSpace: 'pre-line'
            }
        }, React.createElement('div', { 'className': 'f-16 word-wrap' }, '\n                        ', client.name, '\n                    '), React.createElement('div', { 'className': 'f-12 f-300 p-t-5 word-wrap' }, '\n                        ', client.email, '\n                    '), this.props.group.group_treatment_cycle === 2 && client.treatment_started_date ? React.createElement('div', {
            'className': 'f-12 c-primary',
            'key': '3078'
        }, '\n                            ', moment(client.treatment_started_date).format('MMMM Do YYYY'), '\n                     ') : null), React.createElement('div', {
            'className': 'p-absolute',
            'style': {
                top: '115px',
                right: '10px'
            }
        }, this.props.group.goal && this.props.group.group_treatment_cycle === 2 && !client.treatment_started_date ? React.createElement(IconButton, {
            'tooltip': 'Activate Client',
            'icon': 'gm-treatment',
            'gomotive': true,
            'onClick': () => {
                this.showTreatmentAddModal(client);
            },
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '3431'
        }) : null, this.props.group.goal && client.client_goal_questions.length > 0 ? React.createElement(IconButton, {
            'tooltip': 'View Progress',
            'icon': 'gm-results',
            'gomotive': true,
            'onClick': () => {
                this.showGraph(client);
            },
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '3906'
        }) : null, this.props.group.goal && client.client_goal_questions.length > 0 ? React.createElement(IconButton, {
            'tooltip': 'Remove client from Group',
            'icon': 'gm-delete',
            'gomotive': true,
            'onClick': () => {
                this.deleteClient(client.id);
            },
            'className': 'm-r-5 c-black small-icon-button round-border',
            'key': '4307'
        }) : null))));
    }
    function repeatGoal_question2(goal_question, goal_questionIndex) {
        return React.createElement('div', {
            'className': 'panel m-b-10 col-xs-12 p-0',
            'key': 'goal_question' + goal_questionIndex
        }, goal_question.question_deleted_date ? React.createElement('div', {
            'className': 'panel-heading bg-danger panel-bottom-border',
            'key': '6297'
        }, React.createElement('h5', { 'className': 'panel-title' }, goal_questionIndex + 1, '. ', goal_question.question, ' (Metric has been deleted)')) : null, React.createElement('div', { 'className': 'panel-heading bg-beige panel-bottom-border' }, React.createElement('h5', { 'className': 'panel-title' }, goal_questionIndex + 1, '. ', goal_question.question)), React.createElement('div', { 'className': 'panel-body p-0 row m-0' }, React.createElement('div', {
            'className': 'col-xs-12 col-md-8',
            'style': { borderRight: '2px dashed #6C7F8D' }
        }, React.createElement('div', {
            'style': {
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            },
            'className': 'text-center p-relative'
        }, goal_question.initial_value !== null || goal_question.target_value !== null ? React.createElement(GoalTrackingGraph, {
            'group': this.props.group,
            'group_goal_client_question': goal_question,
            'client': this.state.selectedClient,
            'preventCleanUp': true,
            'key': '7065'
        }) : null)), React.createElement('div', { 'className': 'col-xs-12 col-md-4 text-center' }, React.createElement('div', {
            'className': 'row middle-xs text-center',
            'style': { height: '250px' }
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Baseline Value'), React.createElement(Button, {
            'tooltip': 'Baseline Value',
            'className': 'bg-accent f-18 cursor-default',
            'label': goal_question.initial_value !== null ? goal_question.initial_value.toString() : '-',
            'floating': true
        }), ' ', goal_question.metric_unit.unit_name, '\n                                '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Current Value'), React.createElement(Button, {
            'tooltip': 'Current Value',
            'className': 'bg-primary f-18 cursor-default',
            'label': goal_question.current_value !== null ? goal_question.current_value.toString() : '-',
            'floating': true
        }), ' ', goal_question.metric_unit.unit_name, '\n                                '), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', { 'className': 'medium-label d-block' }, 'Target Value'), React.createElement(Button, {
            'tooltip': 'Target Value',
            'className': 'bg-success f-18 cursor-default',
            'label': goal_question.target_value !== null ? goal_question.target_value.toString() : '-',
            'floating': true
        }), ' ', goal_question.metric_unit.unit_name, '\n                                ')))));
    }
    function repeatWorkout3(workout, workoutIndex) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10',
            'key': 'workout' + workout.id
        }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(workout.latest_version.image_url) + ')' }
        }), React.createElement('div', { 'className': 'row bg-white start-xs  m-0  p-l-5' }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-' + (workout.latest_version.description ? '9' : '12') + ' f-16 truncate m-t-8',
            'tooltip': workout.latest_version.name
        }, '\n                            ', workout.latest_version.name, '\n                        '), workout.latest_version.description ? React.createElement(IconButton, {
            'className': 'col-xs-2 c-black center-xs',
            'tooltip': workout.latest_version.description,
            'icon': 'info',
            'key': '11600'
        }) : null), React.createElement('div', { 'className': 'row center-xs m-0' }, React.createElement('div', { 'className': 'col-xs-12 p-5 m-0' }, React.createElement(Checkbox, {
            'className': 'm-b-0 m-t-15',
            'disabled': _.find(this.state.selectedTreatment, {
                'id': workout.id,
                'original_selected': true
            }) ? true : false,
            'checked': _.find(this.state.selectedTreatment, { 'id': workout.id }) ? true : false,
            'onChange': val => {
                this.onClickTreatmentCheckbox(workout, val);
            }
        }), _.find(this.state.selectedTreatment, { 'id': workout.id }) && _.find(this.state.selectedTreatment, { 'id': workout.id })['start_date'] ? React.createElement('div', { 'key': '12379' }, (_.find(this.state.selectedTreatment, { 'id': workout.id }).start_date >= this.state.today ? true : false) ? React.createElement(DatePicker, {
            'minDate': this.state.today,
            'value': _.find(this.state.selectedTreatment, { 'id': workout.id }).start_date,
            'onChange': val => {
                this.changeTreatmentDate(val, workout);
            },
            'inputFormat': value => {
                return moment(value).format('MMMM Do YYYY');
            },
            'key': '12554'
        }) : null, (_.find(this.state.selectedTreatment, { 'id': workout.id })['start_date'] >= this.state.today ? false : true) ? React.createElement('p', {
            'className': 'w-100',
            'key': '13172'
        }, '\n                                            ', moment(_.find(this.state.selectedTreatment, { 'id': workout.id })['start_date']).format('MMMM Do YYYY'), '\n                                    ') : null) : null)));
    }
    function repeatHabit4(habit, habitIndex) {
        return React.createElement('div', {
            'className': 'panel col-xs-8 m-t-10',
            'key': 'habit' + habit.id
        }, React.createElement('div', { 'className': 'panel-body row p-0' }, React.createElement('div', { 'className': 'col-xs-2 bg-beige center-xs p-5' }, React.createElement(Checkbox, {
            'className': 'm-b-0 p-t-15',
            'disabled': _.find(this.state.selectedHabits, {
                'id': habit.id,
                'original_selected': true
            }) ? true : false,
            'checked': _.find(this.state.selectedHabits, { 'id': habit.id }) ? true : false,
            'onChange': val => {
                this.onClickHabitCheckbox(habit, val);
            }
        }), _.find(this.state.selectedHabits, { 'id': habit.id }) && _.find(this.state.selectedHabits, { 'id': habit.id })['start_date'] ? React.createElement('div', { 'key': '15101' }, (_.find(this.state.selectedHabits, { 'id': habit.id }).start_date >= this.state.today ? true : false) ? React.createElement(DatePicker, {
            'minDate': this.state.today,
            'value': _.find(this.state.selectedHabits, { 'id': habit.id }).start_date,
            'onChange': val => {
                this.changeHabitDate(val, habit);
            },
            'inputFormat': value => {
                return moment(value).format('MMMM Do YYYY');
            },
            'key': '15278'
        }) : null, (_.find(this.state.selectedHabits, { 'id': habit.id })['start_date'] >= this.state.today ? false : true) ? React.createElement('p', {
            'className': 'w-100',
            'key': '15965'
        }, '\n                                                        ', moment(_.find(this.state.selectedHabits, { 'id': habit.id })['start_date']).format('MMMM Do YYYY'), '\n                                                ') : null) : null), React.createElement('div', { 'className': 'col-xs-10 p-5' }, React.createElement('h6', {}, habit.name), React.createElement('p', {}, habit.text))));
    }
    return React.createElement('div', {}, React.createElement('div', { 'className': 'row start-xs m-0' }, React.createElement('div', { 'className': 'col-xs-11 col-sm-10 col-md-8 p-relative search-box m-b-20 p-5  vertical-align' }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search clients by name & email',
        'name': 'search'
    }), React.createElement(Button, {
        'onClick': this.showClientAddModal,
        'className': 'bg-success  m-l-5',
        'tooltip': 'Add Clients',
        'icon': 'add',
        'floating': true,
        'mini': true
    }))), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs' },
        _.map(this.props.trackings, repeatClient1.bind(this))
    ]), this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '4792'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more clients...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.loadMoreClients(true);
        }
    }))) : null, React.createElement(Dialog, {
        'active': this.state.openClientAddModal,
        'title': 'Add Clients to Group',
        'onEscKeyDown': this.hideClientAddModal,
        'onOverlayClick': this.hideClientAddModal
    }, this.state.openClientAddModal ? React.createElement(ClientAdd, {
        'group': this.props.group,
        'handleClose': this.hideClientAddModal,
        'key': '5391'
    }) : null), this.state.selectedClient !== null ? React.createElement(Dialog, {
        'active': this.state.showGraph,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideGraph,
        'onOverlayClick': this.hideGraph,
        'key': '5610'
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        React.createElement('div', { 'className': 'vertical-align w-100' }, React.createElement('h4', { 'className': 'flex-auto' }, 'Goal Tracking - ', this.state.selectedClient.name), React.createElement(IconButton, {
            'tooltip': 'Close',
            'icon': 'clear',
            'className': 'c-danger',
            'onClick': this.hideGraph
        })),
        _.map(this.state.selectedClient.client_goal_questions, repeatGoal_question2.bind(this))
    ])) : null, this.state.selectedClient !== null && this.state.openTreatmentAddModal ? React.createElement(Dialog, {
        'active': this.state.openTreatmentAddModal,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideTreatmentAddModal,
        'onOverlayClick': this.hideTreatmentAddModal,
        'key': '9293'
    }, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs' },
        React.createElement('div', { 'className': 'vertical-align w-100' }, React.createElement('h3', { 'className': 'flex-auto' }, this.state.selectedClient.name), React.createElement(IconButton, {
            'tooltip': 'Close',
            'icon': 'clear',
            'className': 'c-danger',
            'onClick': this.hideTreatmentAddModal
        })),
        React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('label', {
            'className': 'material-label',
            'style': { marginBottom: '-15px' }
        }, 'Select Start Date'), React.createElement(DatePicker, {
            'minDate': this.state.today,
            'value': this.state.selectedDate,
            'inputFormat': value => {
                return moment(value).format('MMMM Do YYYY');
            },
            'onChange': val => {
                this.changeSelectedDate(val);
            }
        })),
        React.createElement('h4', { 'className': 'm-t-20' }, 'Workouts'),
        this.state.selectedClient.workouts.length === 0 ? React.createElement('div', {
            'className': 'col-xs-12 alert-warning m-t-10',
            'key': '10419'
        }, '\n                No workouts are available\n            ') : null,
        this.state.selectedClient.workouts.length > 0 ? React.createElement('p', {
            'className': 'material-label w-100 m-b-10 m-t-0',
            'key': '10595'
        }, '\n                Deselect any workouts you do NOT want this client to use. \n                If you later add workouts to this group they will become available unless you manually \n                deselect those as well.\n            ') : null,
        _.map(this.state.selectedClient.workouts, repeatWorkout3.bind(this)),
        React.createElement.apply(this, [
            'div',
            { 'className': 'row w-100 m-t-10' },
            React.createElement('h4', { 'className': 'm-t-20' }, 'Habits'),
            this.state.selectedClient.habits.length === 0 ? React.createElement('div', {
                'className': 'col-xs-12 alert-warning m-t-10',
                'key': '13722'
            }, '\n                            No habits are available\n                    ') : null,
            this.state.selectedClient.habits.length > 0 ? React.createElement('p', {
                'className': 'material-label w-100 m-b-10 m-t-0',
                'key': '13922'
            }, '\n                        Deselect any habits you do NOT want this client to use. \n                        If you later add habits to this group they will become available unless you manually \n                        deselect those as well.            \n                    ') : null,
            _.map(this.state.selectedClient.habits, repeatHabit4.bind(this))
        ]),
        React.createElement('div', {
            'className': 'col-xs-12 center-xs',
            'style': { marginTop: '50px' }
        }, React.createElement(Button, {
            'label': 'Publish',
            'tooltip': 'Save program details for this client',
            'onClick': this.addClientTreatment,
            'disabled': this.state.selectedTreatment.length + this.state.selectedHabits.length === 0,
            'type': 'button',
            'className': 'bg-success',
            'raised': true
        }), React.createElement(Button, {
            'onClick': this.hideTreatmentAddModal,
            'label': 'Close',
            'className': 'bg-accent m-l-5',
            'tooltip': 'Close',
            'type': 'button',
            'raised': true
        }))
    ])) : null);
}