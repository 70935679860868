let initialState = {
    practices: [],
    paginate_info: {
        page: 0,
    },
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PRACTICE_LIST_FILL":
            if (!action.practices){
                return state;
            }    
            let practices = [];
            if(action.paginate_info && action.paginate_info.page === 0 ){
                practices = action.practices;
            }else{
                let newIds = _.map(action.practices, (item) => { return item.id; });
                let oldRetain = _.filter(state.practices, (item) => { return newIds.indexOf(item.id) === -1; });                
                practices = oldRetain.concat(action.practices);
            }

            return Object.assign({}, state, {
                practices: practices,
                paginate_info: action.paginate_info,
            });
        case "PRACTICE_LIST_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
