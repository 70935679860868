import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Switch from '../../../utils/common_components/switch_form';
import Select from '../../../utils/common_components/select';
export default function () {
    function repeatGoal_question1(goal_question, goal_questionIndex) {
        return React.createElement('div', {
            'className': 'row between-xs center-xs m-l-10 m-r-10',
            'key': 'goal_question' + goal_questionIndex
        }, React.createElement('div', { 'className': this.props.pristine ? 'border-around-search p-relative col-xs-9 form-field--is-filled' : 'border-around-search p-relative col-xs-9' }, React.createElement('span', {
            'className': 'placeholder-text',
            'style': { zIndex: '2' }
        }, 'Quantifiable measure to reach this goal'), React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].question',
            'className': 'w-100',
            'onClick': this.inputClick,
            'type': 'text',
            'multiline': true,
            'component': TextInput,
            'style': { height: '44px' }
        })), React.createElement('div', { 'className': 'col-xs-2' }, React.createElement(Field, {
            'name': 'goal_questions[' + goal_questionIndex + '].metric_unit',
            'type': 'text',
            'component': Select,
            'label': 'Metric Unit',
            'valueKey': 'id',
            'labelKey': 'unit_name',
            'options': this.props.goalTrackingUnits
        })), React.createElement('div', { 'className': 'col m-r-15 d-flex align-center' }, !goal_question.is_tracked ? React.createElement(IconButton, {
            'tooltip': 'Delete Goal Question',
            'className': 'delete-red-icon',
            'onClick': () => this.deleteGoalQuestion(goal_questionIndex),
            'key': '3634'
        }) : null, goal_question.is_tracked ? React.createElement(IconButton, {
            'icon': 'highlight_off',
            'tooltip': 'Click here for your client to stop tracking this metric.',
            'className': 'm-t-20 c-danger',
            'onClick': () => this.deleteGoalQuestion(goal_questionIndex),
            'key': '3917'
        }) : null));
    }
    return React.createElement('form', {
        'onSubmit': this.props.handleSubmit(this.doSave),
        'className': 'group-goal-edit bg-white no-box-shadow small-labels'
    }, React.createElement('div', { 'className': 'row no-box-shadow' }, React.createElement('div', { 'className': 'col-xs-12 p-t-5 p-l-5 p-r-5' }, React.createElement('div', { 'className': 'row d-flex align-center' }, React.createElement('div', { 'className': this.props.pristine ? 'border-around-search p-relative col-xs-9 form-field--is-filled' : 'border-around-search p-relative col-xs-9' }, React.createElement('span', {
        'className': 'placeholder-text',
        'style': { zIndex: '2' }
    }, 'Goal Text'), React.createElement(Field, {
        'className': 'w-100',
        'name': 'text',
        'type': 'text',
        'multiline': true,
        'component': TextInput,
        'onClick': this.inputClick,
        'style': { height: '44px' },
        'rows': 2
    })), React.createElement('div', { 'className': 'col-xs-1 text-center' }, !this.props.goal.is_tracked ? React.createElement(IconButton, {
        'icon': 'delete',
        'tooltip': 'Delete Goal',
        'className': 'c-red',
        'onClick': () => this.deleteGoal(),
        'key': '1893'
    }) : null))), React.createElement.apply(this, [
        'div',
        { 'className': 'col-xs-12 bg-white p-b-5 p-l-5 p-r-5' },
        React.createElement('div', { 'className': 'row start-xs middle-xs m-b-15 m-t-15' }, React.createElement('div', { 'className': 'col f-14 page-subtitle p-l-10' }, '\n                    Goal Metrics\n                ')),
        _.map(this.props.goal_questions, repeatGoal_question1.bind(this)),
        this.props.goal_questions && this.props.goal_questions.length === 0 ? React.createElement('div', {
            'className': 'alert-warning p-10',
            'key': '4323'
        }, '\n                Click on + icon to create new quantifiable measure to reach this goal.\n            ') : null,
        React.createElement('div', { 'className': 'row d-flex justify-center m-10' }, React.createElement(Button, {
            'onClick': this.addGoalQuestion,
            'className': 'center-xs height-fit text-transform-none bg-success-border m-b-10',
            'mini': true
        }, '\n                    Add another\n                '))
    ])), React.createElement('div', { 'className': 'row-margin d-flex center-xs m-t-10' }, React.createElement(Button, {
        'label': 'Save',
        'className': 'bg-success m-b-12 m-l-10 w-20',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Close',
        'className': 'm-l-5 bg-success-border w-20',
        'accent': true,
        'raised': true,
        'onClick': this.props.finishEditing
    })));
}