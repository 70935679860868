import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import { showProgress, hideProgress } from '../../core/actions/progress_creator';
import groupClassActionCreator from '../actions/group_class_action';

const baseURL = '//' + window.location.host.replace('gi','dhf') + '/api/'+ Utils.getDevice();
const getGroupClassSchedulerList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_list";
        return Axios.post(url, params).then((response) => {
            dispatch(groupClassActionCreator.groupClassSchedulerListFill(response.group_class_list));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const getClientGroupClassSchedulerList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_group_class_scheduler_list";
        return Axios.post(url, params).then((response) => {
            dispatch(groupClassActionCreator.groupClassSchedulerListFill(response.group_class_list));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const groupClassSchedulerDelete = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(groupClassActionCreator.groupClassSchedulerListFill(response.group_class_list));
            Utils.showSuccessAlert("Successfully deleted group class");
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const groupClassSchedulerPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_post";
        return Axios.post(url, params).then((response) => {
            dispatch(groupClassActionCreator.groupClassSchedulerObjectFill(response.group_class));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const groupClassSchedulerGet = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_get";
        return Axios.post(url, params).then((response) => {
            dispatch(groupClassActionCreator.groupClassSchedulerObjectFill(
                response.group_class,
                response.practitioners,
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const groupClassSchedulerBookingList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_booking_list";
        return Axios.post(url, params).then((response) => {      
            dispatch(groupClassActionCreator.groupClassSchedulerBookingListFill(
                response.group_class, 
                response.booked_client_list
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteGroupClassClient = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_client_delete";
        return Axios.post(url, params).then((response) => {      
            dispatch(groupClassActionCreator.groupClassSchedulerBookingListFill(
                response.group_class, 
                response.booked_client_list
            ));      
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully deleted the client");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const groupClassSchedulerBookingGet = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_booking_get";
        return Axios.post(url, params).then((response) => {      
            dispatch(groupClassActionCreator.groupClassSchedulerBookingObjectFill(
                response.group_class,
                response.booked_client, 
                response.client_list,
                response.schedule_date_list
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const clientGroupClassSchedulerBookingGet = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_group_class_scheduler_get";
        return Axios.post(url, params).then((response) => {      
            dispatch(groupClassActionCreator.groupClassSchedulerBookingObjectFill(
                response.group_class,
                response.booked_details, 
                [],
                []
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const clientGroupClassSchedulerBookingPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_group_class_scheduler_booking_post";
        return Axios.post(url, params).then((response) => {      
            dispatch(groupClassActionCreator.groupClassSchedulerBookingObjectFill(
                response.group_class,
                response.booked_details, 
                [],
                []
            ));      
            dispatch(hideProgress());
            Utils.showSuccessAlert("You have successfully booked!");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const clientGroupClassSchedulerBookingCancel = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/client_group_class_bookings_cancel";
        return Axios.post(url, params).then((response) => {      
            dispatch(groupClassActionCreator.groupClassSchedulerBookingObjectFill(
                response.group_class,
                response.booked_details, 
                [],
                []
            ));      
            dispatch(hideProgress());
            Utils.showSuccessAlert("You have successfully cancelled your bookings");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const groupClassSchedulerBookingPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_booking_post";
        return Axios.post(url, params).then((response) => {      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getUserListForGroupClassScheduledDate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_scheduler_date_user_list";
        return Axios.post(url, params).then((response) => {      
            dispatch(groupClassActionCreator.groupClassSchedulerBookingUserListForDateFill(
                response.group_class,
                response.booked_client_list,
                response.schedule_date,
            ));      
            
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const allGroupClassEventList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/all_group_class_event_list";
        return Axios.post(url, params).then((response) => {   
            dispatch(groupClassActionCreator.allGroupClassEventListFill(
                response.all_group_class_calendar,
            ));      
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const groupClassScheduleEventDelete = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = baseURL +  "/" + API_VERSION + "/" + PACKAGE + "/group_class_event_delete";
        return Axios.post(url, params).then((response) => {   
            dispatch(hideProgress());
            Utils.showSuccessAlert("Selected scheduled event of group class has been deleted");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    groupClassSchedulerGet,
    groupClassSchedulerPost,
    getGroupClassSchedulerList,    
    groupClassSchedulerBookingList,
    groupClassSchedulerBookingGet,
    groupClassSchedulerBookingPost,
    getUserListForGroupClassScheduledDate,
    allGroupClassEventList,
    groupClassScheduleEventDelete,   
    deleteGroupClassClient, 
    groupClassSchedulerDelete,
    getClientGroupClassSchedulerList,
    clientGroupClassSchedulerBookingGet,
    clientGroupClassSchedulerBookingPost,
    clientGroupClassSchedulerBookingCancel
};