import * as React from 'react';
import * as _ from 'lodash';
import FontIcon from 'react-toolbox/lib/font_icon';
import Button from 'react-toolbox/lib/button';
import { Chip } from 'react-toolbox/lib/chip';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import moment from 'moment';
import { Dialog } from 'react-toolbox/lib/dialog';
import GroupClassBookingEdit from '../group_class_booking_edit.jsx';
export default function () {
    function repeatBookedClient1(bookedClient, bookedClientIndex) {
        return React.createElement(Tr, { 'key': 'row' + bookedClientIndex }, React.createElement(Td, {
            'column': 'name',
            'value': bookedClient.client.name
        }, React.createElement('span', {}, bookedClient.client.name)), this.props.groupClass.booking_type === 2 ? React.createElement(Td, {
            'column': 'entire_series',
            'style': {
                width: '120px',
                textAlign: 'center'
            },
            'key': '1967'
        }, React.createElement('span', {}, bookedClient.entire_series ? React.createElement(FontIcon, {
            'className': 'c-success icon-scale',
            'value': 'check_circle',
            'key': '2142'
        }) : null, !bookedClient.entire_series ? React.createElement(FontIcon, {
            'className': 'c-danger icon-scale',
            'value': 'not_interested',
            'key': '2328'
        }) : null)) : null);
    }
    return React.createElement('div', { 'className': 'm-30' }, React.createElement('h4', { 'className': 'm-b-5' }, 'Bookings for ', this.props.groupClass.name, ' on ', this.props.scheduleDate), React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable no-box-shadow',
            'id': 'table',
            'noDataText': 'No clients have been booked',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Client Name')), this.props.groupClass.booking_type === 2 ? React.createElement(Th, {
            'column': 'entire_series',
            'style': {
                width: '120px',
                textAlign: 'center'
            },
            'key': '1435'
        }, React.createElement('strong', {}, 'Booked entire series?')) : null),
        _.map(this.props.bookedClientList, repeatBookedClient1.bind(this))
    ]), React.createElement('div', { 'className': 'm-t-10 text-center' }, React.createElement(Button, {
        'onClick': () => {
            this.deleteEvent();
        },
        'label': 'Delete Event',
        'className': 'bg-danger'
    })));
}