import React from 'react';
import  ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/client_group_goal_tracking.rt";
import Utils from '../../utils/utils';
import { reduxForm, formValueSelector, Field , FieldArray} from 'redux-form';
import { history } from '../../redux/store';
import GoalActionCreator from '../../redux/goal/actions/goal_creator';
import TextInput from '../../utils/common_components/textInput_form';
import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Button from '../../utils/common_components/tooltipButton';
import clientGroupCreator from '../../redux/group/actions/client_group_creator';
import {getClientGroupGoalTargetForm,postClientGroupGoalTargetForm} from '../../redux/group/network/client_group_network';

class ClientGroupGoalTracking extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveGoal = this.saveGoal.bind(this);
        this.renderGoalTrackingQuestions = this.renderGoalTrackingQuestions.bind(this);
        this.renderGoals = this.renderGoals.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.goalsFieldsArray = null;    
    };

    componentWillMount() {
        this.props.getClientGroupGoalTargetForm({
                                                group_id:this.props.group.id,
                                                tracking_date:this.props.track_date,
                                            });   
    }    

    componentWillUnmount() {
        this.props.cleanUp([]);
    }

    doCancel() {
        this.props.handleClose();
    }


    saveGoal(data) {  
        let goals = data.goals;
        let goal_targets = _.cloneDeep(this.props.goal_targets);
        goal_targets = _.map(goal_targets,(goal_target)=>{
            _.forEach(goals,(goal)=>{
                _.forEach(goal.goal_tracking,(goal_tracking)=>{
                    if(goal_tracking.id === goal_target.group_goal_client_question){
                        goal_target.current_value =   goal_tracking.current_value;
                    }
                });
            });
            goal_target = _.pick(goal_target,["id","current_value"]);
            return goal_target;
        });
        this.props.postClientGroupGoalTargetForm({
            group_id:this.props.group.id,
            tracking_details:goal_targets,
        }).then((response)=>{
             this.props.handleClose();
        }).catch((error)=>{
            //Do nothing
        });

    }

    renderGoals({ fields, meta }) {
        this.goalsFieldsArray = fields;
        let goals = fields.getAll();
        return (
            <div className="row"> 
                <div className="col-xs-12 m-t-15">                
                {
                    fields.map((item, index) => {
                            return (
                                <div key={index} className="panel m-b-10" >
                                    <div className="row panel-heading bg-beige panel-bottom-border p-l-10 p-r-10 p-t-15 p-b-0 m-0 ">    
                                         <h6 className="col-xs-12 text-left">{goals[index].text}</h6>
                                    </div>        
                                    <div className="panel-body m-t-0 ">
                                        <FieldArray name={`${item}.goal_tracking`} component={this.renderGoalTrackingQuestions}/>
                                    </div>        

                            </div>    
                        );
                    })
                }
                </div>
                <div className="col-xs-12 end-xs" >
                    <Button onClick={this.doCancel} label="Close" className="bg-accent m-r-5" 
                        type="button"  raised/>                    
                    <Button label="Save" className="bg-success" disabled={this.props.submitting} 
                        type="submit"  raised/>                    
                </div>   
            </div>    
        );
    }
    renderGoalTrackingQuestions({ fields, meta }) {
        let goalsTracking = fields.getAll();
        let noGoalTrackingText = null;      
        return (
            <div className="row">
                {noGoalTrackingText}
                <div className="col-xs-12">                
                {
                    fields.map((item, index) => {
                            return (
                                <div key={index} className="row vertical-align text-left justify-left">  
                                    <div className="col-xs-8">
                                    <div className="round-number di-block m-r-10"> <span>{index+1}</span></div>        
                                       <label>{goalsTracking[index].question} </label>
                                    </div>     
                                    <div className="col-xs-4">    
                                            <Field
                                                className="di-block"
                                                hint="Current Value"  
                                                name={`${item}.current_value`}
                                                type="number"
                                                component={TextInput}
                                            />  
                                            /
                                             <label> {goalsTracking[index].target_value} {goalsTracking[index].metric_unit.unit_name} </label>    

                                    </div>                                   
                            </div>    
                        );
                    })
                }
                </div>               
            </div>    
        );
    }
    
    render() {
        if (this.props.goal_targets.length === 0 || _.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

ClientGroupGoalTracking.propTypes = {
    group:PropTypes.object.isRequired,    
    track_date:PropTypes.string.isRequired,    
};



function mapStateToProps(state, ownprops) {
    let goals = [];
    let group = ownprops.group;
    if (group && !_.isEmpty(group)){
        goals = [{...group.goal}];
    }
    let goal_targets =  state.group.clientGroupReducer.goal_targets;
    let goal_track_dict = _.keyBy(goal_targets,"group_goal_client_question");
    goals = _.map(goals,(goal_val)=>{
        let goal = {...goal_val};
        goal.goal_tracking =  _.filter(goal.client_goal_questions,(track_item_val)=>{
            if(_.has(goal_track_dict,track_item_val.id)){
                return true;
            }else{
                return false;
            }
        });
        return goal;
    }) ;





let initialValues = { goals: goals };
    return {
        group:group,
        goals:goals,
        track_date:ownprops.track_date,
        initialValues: initialValues,
        goal_targets: goal_targets,
        selectedRole: state.auth.roleReducer.selectedRole,
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getClientGroupGoalTargetForm,
        postClientGroupGoalTargetForm,
        cleanUp:clientGroupCreator.clientGroupGoalTargetsFill 
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    const goalArrayErrors = [];
    if(values.goals){
        values.goals.forEach((goal, index) => {
            const goalErrors = {};
            const goalTrackingArrayErrors = [];
            goal.goal_tracking.forEach((goal_track, i) => {
                 const goalTrackErrors = {};
                if(goal_track.current_value === "" || goal_track.current_value === null){
                    goalTrackErrors.current_value = "Required";
                }

                 goalTrackingArrayErrors[i] = goalTrackErrors;
            }); 
            goalErrors.goal_tracking = goalTrackingArrayErrors;
            goalArrayErrors[index] = goalErrors;
        });  
    }
   
    errors.goals = goalArrayErrors;
    return errors;
};


const ClientGroupGoalTrackingForm = reduxForm(
    {
        form: 'clientEngagementGoalTrackingForm',
        enableReinitialize: true,
        validate
    }
)(ClientGroupGoalTracking);
const ClientGroupGoalTrackingConnected = connect(mapStateToProps,mapDispatchToProps)(ClientGroupGoalTrackingForm);
export default ClientGroupGoalTrackingConnected;
