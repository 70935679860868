import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import Button from 'react-toolbox/lib/button';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'm-t-15 text-left' }, React.createElement(Button, {
        'className': 'm-t-20 d-flex back-btn c-grey',
        'onClick': this.cancel
    }, '\n            Back\n        ')), React.createElement(Card, { 'className': 'email-edit no-box-shadow m-t-10 m-r-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', {
        'className': 'add-goal d-flex no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.updateEmailTemplate)
    }, React.createElement('div', { 'className': 'add-info box-shadow' }, React.createElement(CardTitle, {
        'title': 'Edit Email Template',
        'className': 'c-black form-title f-24 w-100 p-0'
    }), React.createElement('div', { 'className': this.props.pristine ? 'border-around-search col-xs-12 p-relative form-field--is-filled' : 'border-around-search p-relative col-xs-12' }, React.createElement('span', { 'className': 'placeholder-text' }, 'Subject'), React.createElement(Field, {
        'className': 'w-100',
        'name': 'subject',
        'type': 'text',
        'component': TextInput,
        'onClick': this.inputClick
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'className': 'w-100',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'hint': 'Body'
    })), React.createElement('div', { 'className': 'col-xs-12 p-5 start-xs c-danger' }, '\n                        Note: Do not remove text ', '{within brackets like this}', ' above, if any. \n                        But you can change other texts before sending out the invite.\n                    '), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-1 m-t-10' }, this.props.canned_message.is_custom === true ? React.createElement(Button, {
        'onClick': this.loadDefaultTemplate,
        'label': 'Load Default Template',
        'type': 'button',
        'primary': true,
        'raised': true,
        'key': '2349'
    }) : null))), React.createElement('div', { 'className': 'publish-info' }, React.createElement('div', {}, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Publish',
        'className': 'm-l-10 bg-success w-100',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    })))))))));
}