import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { List } from 'react-toolbox/lib/list';
import { ListItem } from 'react-toolbox/lib/list';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
export default function () {
    return React.createElement('div', { 'className': 'row' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Recommend GoMotive',
        'className': 'p-b-5 text-left'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-8' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'email',
        'type': 'text',
        'component': TextInput,
        'label': 'Email Address'
    })), React.createElement('div', { 'className': 'col-xs-8' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'subject',
        'type': 'text',
        'component': TextInput,
        'label': 'Subject'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'descriptionRichText',
        'className': 'w-100',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Body'
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Send',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    })))))));
}