import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
import moment from 'moment';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement('form', {
        'className': 'col-xs-12',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Cancel Bookings for ' + this.props.group_class.name,
        'className': 'm-b-10'
    }), React.createElement(CardText, {}, this.props.group_class.booking_type === 2 ? React.createElement('div', { 'key': '1485' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'group_class_schedule',
        'component': Select,
        'label': 'Cancel Booking Slots',
        'options': this.props.bookedClient.bookings,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true,
        ',': true
    })) : null, React.createElement('div', {}, React.createElement(Button, {
        'label': 'Cancel Booking',
        'className': 'bg-danger m-r-10',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'onClick': this.props.closeBookingDialog,
        'className': 'bg-accent',
        'primary': true,
        'raised': true
    }))))));
}