import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  Template from "./templates/partner_plan_list.rt";
import store,{history}  from '../../redux/store';

import Utils from '../../utils/utils';
import BaseComponent from '../../utils/common_components/basecomponent';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { getPartnerPlanList, deletePartnerPlan} from '../../redux/partner/network/partner_plan_network';
import {getPartnerDetails} from '../../redux/partner/network/partner_network';
import partnerPlanActions from '../../redux/partner/actions/partner_plan_action';
import Swal from 'sweetalert2';

class PartnerPlanList extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.addPlan = this.addPlan.bind(this);
        this.editPlan = this.editPlan.bind(this);
        this.deletePlan = this.deletePlan.bind(this);
        this.discountForPlan = this.discountForPlan.bind(this);
        this.closeDicountForPlan = this.closeDicountForPlan.bind(this)
        this.state = {
            openDiscountDialog: false,
            discountForPlan: null,
        };
    };

    
    componentWillMount() {
        this.props.getPartnerDetails({});
        this.props.getPartnerPlanList({});
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    addPlan(){
        if(!this.props.partner.is_payment_gateway_ready){
            Utils.showWarningAlert("Please configure your stripe account and move it to 'LIVE' mode before adding plans   ");
            return false;
        }
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/plans/add");
    }
    editPlan(plan){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/plans/"+plan.id);
    }
    deletePlan(plan){
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Would you like to delete this Plan?",
            type: "warning",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#f16767",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            that.props.deletePartnerPlan({id:plan.id}).then((response)=>{
                that.props.getPartnerPlanList({});
            }).catch((error)=>{
    
            });
        }, ()=> { });
    }

    discountForPlan(plan) {
        this.setState({
            openDiscountDialog: true,
            discountForPlan: plan,
        });
    }

    closeDicountForPlan() {
        this.setState({
            openDiscountDialog: false,
        });
    }
  

    render() {
        return Template.apply(this);
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartnerPlanList,
        deletePartnerPlan,
        getPartnerDetails,
        cleanUp:partnerPlanActions.cleanUp
    }, dispatch);
}
function mapStateToProps(state,ownProps) {
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        partner: state.partner.partnerReducer.partner,
        partner_plans: state.partner.partnerPlanReducer.partner_plans,
        discount_plans: state.partner.partnerPlanReducer.discount_plans,
    };
}
const PartnerPlanListConnected = connect(mapStateToProps, mapDispatchToProps)(PartnerPlanList);
export default PartnerPlanListConnected;
