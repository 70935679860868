// Action Creators
const challengeListFill = (challenge_list) => ({
    type: 'CHALLENGE_LIST_FILL',
    challenge_list,
});
const challengeFill = (challenge, supporting_data) => ({
    type: 'CHALLENGE_FILL',
    challenge,
    supporting_data,
});
const cleanModule = () => ({
    type: 'CHALLENGE_LIST_CLEANUP',
});
const challengeWorkoutFill = (challenge_workout, supporting_data) => ({
    type: 'CHALLENGE_WORKOUT_FILL',
    challenge_workout,
    supporting_data
});


export default {
    challengeListFill,
    challengeFill,
    cleanModule,
    challengeWorkoutFill,
};