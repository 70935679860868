let initialState = {
    canned_message_list: [],
    canned_message: {}
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "CANNED_MESSAGE_LIST_FILL":
            if (!action.canned_message_list){
                return state;
            }    
            return Object.assign({}, state, {
                canned_message_list: action.canned_message_list,
            });
        case "CANNED_MESSAGE_GET":
            if (!action.canned_message){
                return state;
            }    
            return Object.assign({}, state, {
                canned_message: action.canned_message,
            });
        case "CANNED_MESSAGE_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
