import * as React from 'react';
import * as _ from 'lodash';
import { Layout } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import Input from 'react-toolbox/lib/input';
import IconButton from '../../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../../utils/common_components/tooltipWrapper';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../../utils/common_components/select_form';
import Switch from '../../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Checkbox from '../../../../utils/common_components/checkbox_group_form';
import RichTextEditor from '../../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import MoveVideo from '../../../../client_app/components/welcome_video';
import Chip from 'react-toolbox/lib/chip';
export default function () {
    function repeatVideo1(video, index) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10 ribbon-container',
            'key': 'move_video' + index
        }, !video.is_published ? React.createElement('div', {
            'className': 'bg-warning ribbon f-11',
            'key': '2818'
        }, '\n                    DRAFT\n                ') : null, video.is_published ? React.createElement('div', {
            'className': 'bg-success ribbon f-11',
            'key': '2948'
        }, '\n                    PUBLISHED\n                ') : null, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(video.thumbnail_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-12 f-16 truncate',
            'tooltip': video.name
        }, '\n                        ', video.name, '\n                    ')), React.createElement('div', { 'className': 'row center-xs m-0' }, video.deleted_date === null ? [React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '35421'
            }, React.createElement(IconButton, {
                'tooltip': 'Edit Workout',
                'icon': 'create',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.editMoveVideo(video);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Play Workout Video',
                'icon': 'play_circle_filled',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.viewMoveVideo(video);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'Delete Workout',
                'icon': 'delete',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.deleteMoveVideo(video);
                }
            }), video.is_free ? React.createElement(Chip, {
                'className': 'bg-success',
                'key': '4681'
            }, '\n                                Free\n                            ') : null, !video.is_free ? React.createElement(Chip, {
                'className': 'bg-danger',
                'key': '4830'
            }, '\n                                Paid\n                            ') : null)] : null));
    }
    return React.createElement(Layout, {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'row start-xs m-10' }, React.createElement('div', { 'className': 'col-xs-12 col-sm-10 col-md-8 p-relative search-box m-b-20 p-5  vertical-align' }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'icon': 'search',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search workouts by name',
        'name': 'search'
    }), React.createElement(Button, {
        'className': 'bg-success m-r-10',
        'onClick': this.addMoveVideo,
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true
    }))), this.props.move_videos.length === 0 ? [React.createElement('div', {
            'className': 'row center-xs alert-warning m-20',
            'key': '23641'
        }, '\n                There are no DHF HomeFit Workouts\n            ')] : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-10' },
        _.map(this.props.move_videos, repeatVideo1.bind(this))
    ]), this.props.paginate_info.total_pages && this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '5088'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more workouts...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.loadMore();
        }
    }))) : null, this.state.show_move_video ? React.createElement(MoveVideo, {
        'title': this.state.move_video.title,
        'onCloseHandle': this.closeMoveVideo,
        'welcome_video': this.state.move_video,
        'key': '5525'
    }) : null));
}