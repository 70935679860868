import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/progression_edit";
import { reduxForm, formValueSelector, arrayRemove, arrayPush } from 'redux-form';

import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { getProgression, postProgression } from '../../redux/program/network/progression_edit';
import { getProgram } from '../../redux/program/network/program_edit';
import actionCreator from '../../redux/program/actions/progression_edit';
import Swal from 'sweetalert2';
import Utils from '../../utils/utils';
import TextInput from '../../utils/common_components/textInput_form';
import { tetheredSelectFunction } from '../../utils/common_components/select_form';
import FormLabel from '../../utils/common_components/label_form';
import { Field, FieldArray } from 'redux-form';
import Button from 'react-toolbox/lib/button';
import FontIcon from 'react-toolbox/lib/font_icon';
import Chip from 'react-toolbox/lib/chip';
import IconButton from '../../utils/common_components/tooltipIconButton';
import Sortable from "react-anything-sortable";
import SortableItem from "../../utils/common_components/sortable_item";
import Input from 'react-toolbox/lib/input';
import { history } from "../../redux/store";
import moment from 'moment';
import {PARTNER} from '../../core/constants/constants';

class ProgressionEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.deleteExercise = this.deleteExercise.bind(this);
        this.addExercise = this.addExercise.bind(this);
        this.transformData = this.transformData.bind(this);
        this.cancel = this.cancel.bind(this);
        this.renderExercises = this.renderExercises.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.deleteExerciseTag = this.deleteExerciseTag.bind(this);
        this.addExerciseTag = this.addExerciseTag.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.exerciseMetricsApplyToAll = this.exerciseMetricsApplyToAll.bind(this);
        this.exerciseMetricesApplyFromGlobal = this.exerciseMetricesApplyFromGlobal.bind(this);
        this.copyToExercise = this.copyToExercise.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
        this.finishDatesSelection = this.finishDatesSelection.bind(this);
        this.state = {
            datePickerVisible: false,
        };
    }

    finishDatesSelection(selectedDates) {
        let days = Utils.toDayArray(this.props.program.start_date, selectedDates);
        this.props.change('days', days);
        this.props.change('selected_dates', selectedDates);
        let daysDisplay = _.map(selectedDates, (item) => { return Utils.parseDateAsString(item) });
        this.props.change('days_display', daysDisplay);
        this.showDatePicker(false);
    }

    showDatePicker(flag) {
        this.setState({
            datePickerVisible: flag,
        });
    }

    toggleExpand(index) {
        let exercises = this.props.exercises;
        this.props.change("exercises[" + index + "].expanded", !exercises[index].expanded);
    }

    collapseAll() {
        let exercises = this.props.exercises;
        for (let index in exercises) {
            this.props.change("exercises[" + index + "].expanded", false);
        }
    }

    componentWillMount() {
        this.props.getProgression({
            // program_id: this.props.program_id,
            id: String(this.props.progression_id) !== '-1' ? this.props.progression_id : null,
        });
    }

    handleSort(exercises) {
        exercises = _.map(exercises, (itemval, index) => {
            let item = _.cloneDeep(itemval);
            item.order = index + 1;
            let indexVal = 'exercises[' + index + "]";
            this.props.change(indexVal, item);
            return item;
        });
    }

    deleteExerciseTag(exerciseIndex, tagIndex) {
        store.dispatch(arrayRemove(this.props.form, "exercises[" + exerciseIndex + "].tags", tagIndex));
    }

    addExerciseTag(exerciseIndex, tag) {
        store.dispatch(arrayPush(this.props.form, "exercises[" + exerciseIndex + "].tags", tag));
    }

    renderExercises(props) {
        let allExercises = props.fields.getAll();
        return (
            <div className="col-xs-12 m-t-10">
                <Sortable class="vertical-container" sortHandle="handle" onSort={this.handleSort} direction="vertical" containment={true} dynamic={true} >
                    {
                        props.fields.map((exercise, index) => {
                            let exerciseObject = allExercises[index];
                            let icon = exerciseObject.expanded ? 'chevron-down' : 'chevron-right';
                            return <SortableItem key={index} className="row box-shadow m-l-0 m-t-10 vertical w-100" sortData={exerciseObject} >
                                <div className="col-xs-12  bg-accent f-18 start-xs p-5">
                                    <div className="row middle-xs" onClick={() => this.toggleExpand(index)} >
                                        <div className="col-xs-1">
                                            <IconButton icon={icon} tooltip="Toggle" className="c-white" />
                                        </div>
                                        <div className="col-xs-7 start-xs">
                                            <Field name={`${exercise}.name`} label="Name" readOnly="{true}" component={FormLabel} className="w-100" />
                                        </div>
                                        {
                                            !this.props.disableEditing &&
                                            <div className="col-xs end-xs">
                                                <IconButton icon="delete_forever" tooltip="Remove Exercise" className="c-white" onClick={() => this.deleteExercise(index)} />
                                                <IconButton icon="reorder" style={{ cursor: 'ns-resize' }} tooltip="Reorder Exercise" className="c-white handle" />
                                            </div>
                                        }    
                                    </div>
                                </div>
                                <div className={"col-xs-12 p-10 " + (exerciseObject.expanded ? '' : 'hide')}>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <Field name={`${exercise}.sets`} label="Sets" component={TextInput} type="number" className="w-100" />
                                        </div>
                                    </div>
                                    {
                                        exerciseObject.metric === 1 &&
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <Field name={`${exercise}.reps`} label="Reps" component={TextInput} type="number" className="w-100" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        exerciseObject.metric === 2 &&
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <Field name={`${exercise}.distance`} label="Distance" component={TextInput} type="number" className="w-100" />
                                            </div>
                                            <div className="col-xs-6">
                                                <Field name={`${exercise}.distance_unit`} label="Distance Unit" component={tetheredSelectFunction} options={this.props.supportingData.distance_unit} className="w-100" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        exerciseObject.metric === 3 &&
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <Field name={`${exercise}.duration`} label="Duration" component={TextInput} type="number" className="w-100" />
                                            </div>
                                            <div className="col-xs-6">
                                                <Field name={`${exercise}.duration_unit`} label="Duration Unit" component={tetheredSelectFunction} options={this.props.supportingData.duration_unit} className="w-100" />
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <Field name={`${exercise}.hold`} label="Hold Position" component={TextInput} type="number" className="w-100" />
                                        </div>
                                        <div className="col-xs-6">
                                            <Field name={`${exercise}.hold_unit`} label="Hold Unit" component={tetheredSelectFunction} options={this.props.supportingData.duration_unit} className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <Field name={`${exercise}.pause`} label="Rest Between Sets" component={TextInput} type="number" className="w-100" />
                                        </div>
                                        <div className="col-xs-6">
                                            <Field name={`${exercise}.pause_unit`} label="Rest Unit" component={tetheredSelectFunction} options={this.props.supportingData.duration_unit} className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <Field name={`${exercise}.weight`} label="Weight" component={TextInput} type="number" className="w-100" />
                                        </div>
                                        <div className="col-xs-6">
                                            <Field name={`${exercise}.weight_unit`} label="Weight Unit" component={tetheredSelectFunction} options={this.props.supportingData.weight_unit} className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <Field name={`${exercise}.heart_rate`} label="Target Heart Rate" component={TextInput} type="number" className="w-100" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <Field name={`${exercise}.cues`} label="Cues" valueKey="id" labelKey="name"
                                                component={tetheredSelectFunction} options={this.props.supportingData.cues}
                                                multi={true} className="w-100 p-t-0 m-b-20" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <Field name={`${exercise}.equipments`} label="Equipment" valueKey="id" labelKey="name"
                                                component={tetheredSelectFunction} options={this.props.supportingData.equipments}
                                                multi={true} className="w-100 p-t-0 m-b-20" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <Field name={`${exercise}.notes`}
                                                label="Notes"
                                                component={TextInput}
                                                multiline
                                                rows="{2}"
                                                className="w-100" />
                                        </div>
                                    </div>
                                    { !this.props.disableEditing &&
                                        <div className="row m-b-10">
                                            <div className="col-xs-12 end-xs">
                                                <Button label="Apply from Global" primary raised onClick={() => { this.exerciseMetricesApplyFromGlobal(index); }} />
                                                <Button label="Apply to All" className="m-l-10" primary raised onClick={() => { this.exerciseMetricsApplyToAll(index); }} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SortableItem>
                        })
                    }
                </Sortable>
            </div>
        );
    }

    cancel() {
        this.props.moveToBuild();
    }

    deleteExercise(index) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete this exercise from progression?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function () {
            store.dispatch(arrayRemove(that.props.form, "exercises", index));
            Utils.showSuccessAlert("Exercise has been removed from this progression");
        }, function (dismiss) {

        });
    }

    addExercise(exercise) {
        exercise = _.cloneDeep(exercise);
        exercise.exercise_id = exercise.id;
        delete exercise.id;
        exercise.order = this.props.exercises.length + 1;
        exercise.tags = [];
        store.dispatch(arrayPush(this.props.form, "exercises", exercise));
        Utils.showSuccessAlert("Exercise has been added to workout");
    }

    exerciseMetricsApplyToAll(index) {
        let exercises = this.props.exercises;
        let exercise = exercises[index];
        for (let currentIndex in exercises) {
            if (currentIndex !== index) {
                this.copyToExercise(currentIndex, exercise);
            }
        }
        Utils.showSuccessAlert("Metric Settings from selected exercise have been applied to all exercises.");
    }

    copyToExercise(index, source) {
        this.props.change('exercises[' + index + '].sets', source.sets);
        this.props.change('exercises[' + index + '].reps', source.reps);
        this.props.change('exercises[' + index + '].distance', source.distance);
        this.props.change('exercises[' + index + '].distance_unit', source.distance_unit);
        this.props.change('exercises[' + index + '].duration', source.duration);
        this.props.change('exercises[' + index + '].duration_unit', source.duration_unit);
        this.props.change('exercises[' + index + '].weight', source.weight);
        this.props.change('exercises[' + index + '].weight_unit', source.weight_unit);
        this.props.change('exercises[' + index + '].hold', source.hold);
        this.props.change('exercises[' + index + '].hold_unit', source.hold_unit);
        this.props.change('exercises[' + index + '].pause', source.pause);
        this.props.change('exercises[' + index + '].pause_unit', source.pause_unit);
        this.props.change('exercises[' + index + '].heart_rate', source.heart_rate);
    }

    exerciseMetricesApplyFromGlobal(index) {
        if (this.props.globalMetricSettings.sets == null) {
            Utils.showWarningAlert("Global setting not yet in place. Save your work and then select \"Global Metric Settings\" in your settings menu in upper right");
            return;
        }
        this.copyToExercise(index, this.props.globalMetricSettings);
        Utils.showSuccessAlert("Global Metric Settings have been applied to selected exercise.");
    }

    submitForm() {
        this.props.handleSubmit(this.doSave)();
    }

    doSave(data) {
        let params = this.transformData(data);
        params.program_id = this.props.program_id;
        this.props.postProgression({ ...params }).then(() => {
            this.props.getProgram({ id: this.props.program.id }).then(() => {
                this.props.moveToBuild();
            });
        }).catch(() => { });
    }

    transformData(data) {
        let progression = Utils.flatternFormData(data, ["exercises"]);
        progression.exercises = _.map(progression.exercises, (item) => {
            let flat_item = Utils.flatternFormData(item);
            if(_.has(item,"exercise")){
                flat_item.exercise_id = item.exercise.id;
            }
            return flat_item;
        });
        return progression;
    }

    render() {
        if (_.isEmpty(this.props.progression)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.cleanModule();
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // getProgression,
        // postProgression,
        cleanModule: actionCreator.cleanModule,
    }, dispatch);
}
const selector = formValueSelector('ProgressionEditForm');

function mapStateToProps(state, ownProps) {    
    let progression = state.program.progressionEdit.progression;
    let initialValues;
    if (_.isEmpty(progression)) {
        initialValues = undefined;
    } else {
        progression = _.cloneDeep(progression);
        if (ownProps.programType === 'workout') {
            let startDate = ownProps.program.start_date;
            let availDays = ownProps.program.rest_days;
            progression.selected_dates = Utils.toDateArray(startDate, progression.days);
            progression.available_dates = progression.selected_dates.concat(Utils.toDateArray(startDate, availDays));
            // let today_date = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
            // progression.available_dates = _.filter(progression.available_dates,(available_date)=>{
            //     let date =   moment(available_date).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
            //     if(date >= today_date){
            //         return true;
            //     }else{
            //         return false;
            //     }
            // });
            progression.days_display = _.map(progression.selected_dates, (item) => { return Utils.parseDateAsString(item) });
        }
        initialValues = progression;
    }
    return {
        globalMetricSettings: state.user.practitionerMetricSettings.settings,
        progression: progression,
        initialValues: initialValues,
        supportingData: state.program.progressionEdit.supportingData,
        name: selector(state, 'name'),
        exercises: selector(state, 'exercises'),
        days_display: selector(state, 'days_display'),
        selected_dates: selector(state, 'selected_dates'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'name','duration'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ProgressionEditForm = reduxForm(
    {
        form: 'ProgressionEditForm',
        enableReinitialize: true,
        validate
    }
)(ProgressionEdit);

const ProgressionEditConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgressionEditForm);

export default ProgressionEditConnected;
