// Action Creators
const bannerFill = (banner_list, supporting_data) => ({
    type: 'BANNER',
    banner_list,
    supporting_data
});
const getBanner = (banner, supporting_data) => ({
    type: 'GET_BANNER',
    banner,
    supporting_data
})
const cleanModule = () => ({
    type: 'BANNER_CLEANUP',
});

export default {
    bannerFill,
    cleanModule,
    getBanner
};
