import React from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/news_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import { history } from "../../redux/store";
import { listNews, deleteNews } from '../../redux/news/network/news_list';
import Swal from 'sweetalert2';
import actionCreator from '../../redux/news/actions/news_list';

class NewsList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.showNewsPreviewDialog = this.showNewsPreviewDialog.bind(this);
        this.hideNewsPreviewDialog = this.hideNewsPreviewDialog.bind(this);
        this.state = {
            news: null
        };
    }

    componentWillMount() {
        this.loadMoreNews(false);
    }

    loadMoreNews(increment=false) {
        let params = {  
            ...this.props.paginate_info
        };
        if(increment) {
            params.page = params.page + 1;
        }
        this.props.listNews(params);
    }


    componentWillUnmount() {
        this.props.cleanUp();
    }

    delete(id) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete this news?",
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (response) {
            if (response.dismiss && ['cancel', 'close'].includes(response.dismiss)) {
                console.log(response.dismiss)
            } else {
                that.props.deleteNews({ id: id }).then(() => {
                    console.log('delete ho gaya');           
                }).catch((err) => {
                    console.log('kuch bhi nai hua', err);           
                });
            }
        }, function (dismiss) {});
    }

    add() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/branding/news/add");
    }

    edit(id) {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/branding/news/edit/" + id);
    }

    showNewsPreviewDialog(news_item) {
        this.setState({
            news: news_item
        });
    }

    hideNewsPreviewDialog() {
        this.setState({
            news: null
        });
    }

    render() {
        return Template.apply(this);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listNews,
        deleteNews,
        cleanUp: actionCreator.cleanModule,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let newsList = _.cloneDeep(state.news.newsList.newsList);
    _.forEach(newsList, (news) => {
        news.start_date = Utils.parseServerDateAsString(news.start_date);
        news.end_date = Utils.parseServerDateAsString(news.end_date);
    });
    return {
        selectedRole: state.auth.roleReducer.selectedRole,
        newsList: newsList,
        paginate_info: state.news.newsList.paginate_info,
        ...ownProps,
    };
}

const NewsListConnected = connect(mapStateToProps, mapDispatchToProps)(NewsList);
export default NewsListConnected;
