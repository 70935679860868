import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/subscription_content";
import Utils from '../../utils/utils';
import store, { history } from '../../redux/store';
import { getGoalList, changePartnerGoalVisibilityStatus,} from '../../redux/goal/network/goal_network';
import swal from 'sweetalert2';
import HelpVideoCreator from '../../redux/engagement/actions/client_list_creator';
import { ENGAGEMENT_GOAL_VIDEO } from '../../utils/help_video';
import { getCoachList } from '../../redux/dhf/move/network/coach_network';
import {getPractitionerList, } from '../../redux/practice/network/practice_practitioner';
import {getAdminsList } from '../../redux/users/network/users_network';
import { getHomefitSubscriptionLog, getGISubscriptionLog } from '../../redux/subscription/network/subscription_network';
import { getClientList } from '../../redux/engagement/network/client_list_network';
import {getPartnerDetails,getPracticePartnerList, activatePartner} from '../../redux/partner/network/partner_network';

class UserList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.viewReceipt = this.viewReceipt.bind(this);
        this.hideReceipt = this.hideReceipt.bind(this);
        this.state ={
            paginate_info: "",
            showReceipt: false,
            completeReceiptObject: {}
        }
    };
    setActive(el, active = true){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    viewReceipt(index, type){
        let receipt = type==="homefit" ? this.props.homefit_log[index] : this.props.gi_log[index]
        this.setState({
            showReceipt: true,
            completeReceiptObject: receipt
        })
        
    }
    hideReceipt(){
        this.setState({
            showReceipt: false
        })
    }
    onSearch(text){
        if(text == null){
            this.loadMore();
        }
        this.setState({search:text},()=>{
            if(this.props.type=='homefit'){
                this.props.getHomefitSubscriptionLog({
                    "app_version": "5.1.12",
                    "current_role_type": "practitioner",
                    "package_version": "2",
                    "practice_id": 276,
                    "page": 0
                })
            } else if(this.props.type=='gi'){
                this.props.getGISubscriptionLog({
                    "app_version": "5.2",
                    "current_role_type": "practice_admin",
                    "package_version": "2",
                    "practice_id": 276,
                    "page": 0
                })
            }
        });

    }

    loadMore(){
        if(this.props.type=='homefit'){
            this.props.getHomefitSubscriptionLog({
                "app_version": "5.1.12",
                "current_role_type": "practitioner",
                "package_version": "2",
                "practice_id": 276,
                "page": 0
            })
        } else if(this.props.type=='gi'){
            this.props.getGISubscriptionLog({
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "package_version": "2",
                "practice_id": 276,
                "page": 0
            })
        }
    }
        onPageChange(page){
            if(this.props.type=='homefit'){
                this.props.getHomefitSubscriptionLog({
                    "app_version": "5.1.12",
                    "current_role_type": "practitioner",
                    "package_version": "2",
                    "practice_id": 276,
                    "page": page,
                    sort_by:this.props.homefit_paginate_info.sort_by,
                    asc:this.props.homefit_paginate_info.asc,
                    filters: { ...this.props.homefit_paginate_info.filters },
                })
         }  else if(this.props.type=='gi'){
            this.props.getGISubscriptionLog({
                "app_version": "5.2",
                "current_role_type": "practice_admin",
                "package_version": "2",
                "practice_id": 276,
                "page": page,
                sort_by:this.props.gi_paginate_info.sort_by,
                asc:this.props.gi_paginate_info.asc,
                filters: { ...this.props.gi_paginate_info.filters },
            })
        }
    }    
    onSort(sortData){
        let asc = false;
        if(sortData.direction === 1){
            asc = true;
        }
        this.props.getPracticePartnerList({
            page: 0,
            sort_by:sortData.column,
            asc:asc,
            filters: { ...this.props.paginate_info.filters },
            search_text: this.state.search_text
        })
    }
    componentWillMount() {
        // this.props.getGoalList({
        //     fetch_type: "manage"
        // });
       this.loadMore()
        // this.props.getPractitionerList({'category': 'active'});
    }

    componentDidMount() {
        // this.props.updateHelpVideo(ENGAGEMENT_GOAL_VIDEO);
    }
    
    componentWillUnmount() {
        this.props.clearList();

    }

    addUser() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/users/add');
    }


    editUser(user) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/user/edit/' + user.id);
    }


    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    // let paginate_info = this.state.paginate_info
    // if(this.props.type=='coaches'){
    //     paginate_info = 
    // } else if(this.props.type=='clients') {
    //     paginate_info = state.engagement.clientListReducer.paginate_info
    // } 
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        homefit_log: state.subscriptionLog.subscriptionLogReducer.homefitLog,
        gi_log: state.subscriptionLog.subscriptionLogReducer.GiLog,
        homefit_paginate_info: state.subscriptionLog.subscriptionLogReducer.homefit_paginate_info,
        gi_paginate_info: state.subscriptionLog.subscriptionLogReducer.gi_paginate_info,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getHomefitSubscriptionLog,
        getGISubscriptionLog,
        getGoalList,
        getAdminsList,
        getCoachList,
        getPractitionerList,
        getClientList,
        getPracticePartnerList,
        changePartnerGoalVisibilityStatus,
        // updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearList: HelpVideoCreator.clientListCleanup,
    },
        dispatch
    );
}

const UserListConnected = connect(mapStateToProps, mapDispatchToProps)(UserList);
export default UserListConnected;