import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import Checkbox from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
export default function () {
    function repeatMedia1(media, index) {
        return React.createElement('div', {
            'className': 'row middle-xs',
            'key': 'media' + index
        }, React.createElement(Card, { 'className': 'width-250 bg-white p-0 m-l-5 m-r-5 m-b-20' }, React.createElement('div', {
            'className': 'thumbnail box-250-150 ' + (media.delete ? 'grayscale-filter' : ''),
            'style': { backgroundImage: 'url(' + media.url + ')' }
        }), React.createElement('div', { 'className': 'bg-white row end-xs p-5' }, !media.delete ? React.createElement(IconButton, {
            'tooltip': 'Delete Picture',
            'icon': 'delete_forever',
            'className': 'c-black',
            'onClick': () => {
                this.deletePositionPicture(media.url, true);
            },
            'key': '19481'
        }) : null, media.delete ? React.createElement(IconButton, {
            'tooltip': 'Undo Delete',
            'icon': 'undo',
            'className': 'c-black',
            'onClick': () => {
                this.deletePositionPicture(media.url, false);
            },
            'key': '19715'
        }) : null)), React.createElement('div', { 'className': 'col-xs' }, React.createElement(Field, {
            'className': 'w-100',
            'name': 'position_pictures[' + index + '].caption',
            'type': 'text',
            'component': TextInput,
            'label': 'Caption',
            'multiline': true
        })));
    }
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'col-xs-10 m-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Video',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', { 'className': 'row center-xs middle-xs' }, React.createElement('div', { 'className': 'col-xs-6' }, this.props.video_guid ? React.createElement('div', {
        'className': 'm-b-20',
        'key': '1839'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-player',
        'handleUploadProcessing': true,
        'videoList': [this.props.video_guid]
    })) : null, React.createElement('div', { 'className': 'row center-xs' }, React.createElement(VimeoUploader, {
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploaded,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'buttonText': this.props.video_guid ? 'Change Video' : 'Upload Video'
    }), this.props.video_guid ? React.createElement(Button, {
        'onClick': this.deleteVideo,
        'label': 'Delete Video',
        'className': 'm-l-10 bg-danger',
        'primary': true,
        'raised': true,
        'key': '2668'
    }) : null))))), React.createElement('hr', { 'className': 'dashed' }), React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.doSave) }, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Required*',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'metric',
        'component': Select,
        'label': 'Exercise Metric',
        'options': this.props.supportingData.metric,
        'clearable': false
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'category',
        'component': Select,
        'label': 'Category',
        'options': this.props.supportingData.category,
        'valueKey': 'id',
        'labelKey': 'name'
    })), this.props.category && this.props.category.name === 'Sports' ? [
        React.createElement('div', { 'key': '43341' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'sports',
            'component': Select,
            'label': 'Sports Name',
            'options': this.props.supportingData.sports,
            'multi': true,
            'valueKey': 'id',
            'labelKey': 'name'
        })),
        React.createElement('div', { 'key': '43343' }, React.createElement(Field, {
            'className': 'w-100 p-t-0 m-b-20',
            'name': 'sports_exercise_type',
            'component': Select,
            'label': 'Sports Condition',
            'options': this.props.supportingData.sports_exercise_type,
            'valueKey': 'id',
            'labelKey': 'name'
        }))
    ] : null, React.createElement('div', { 'className': 'row m-l-0' }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Client Specific Exercise?'), React.createElement(Field, {
        'name': 'is_client_specific',
        'component': Switch
    })), this.props.is_client_specific ? React.createElement('div', { 'key': '5401' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'client',
        'component': Select,
        'label': 'Select Client',
        'options': this.props.supportingData.client,
        'labelKey': 'email',
        'valueKey': 'id'
    })) : null)), React.createElement('hr', { 'className': 'dashed' }), React.createElement(Card, { 'className': 'm-t-10 no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Additional Filters',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'body_part',
        'component': Select,
        'label': 'Body Part',
        'options': this.props.supportingData.body_part,
        'valueKey': 'id',
        'labelKey': 'name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'difficulty',
        'component': Select,
        'label': 'Difficulty Level',
        'options': this.props.supportingData.difficulty
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'cues',
        'component': Select,
        'label': 'Cues',
        'options': this.props.supportingData.cues,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'equipments',
        'component': Select,
        'label': 'Equipment',
        'options': this.props.supportingData.equipments,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'exercise_types',
        'component': Select,
        'label': 'Types',
        'options': this.props.supportingData.exercise_types,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'action',
        'component': Select,
        'label': 'Action',
        'options': this.props.supportingData.action,
        'valueKey': 'id',
        'labelKey': 'name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'driver',
        'component': Select,
        'label': 'Driver',
        'options': this.props.supportingData.driver,
        'valueKey': 'id',
        'labelKey': 'name'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'direction',
        'component': Select,
        'label': 'Direction',
        'options': this.props.supportingData.direction,
        'valueKey': 'id',
        'labelKey': 'name'
    })))), React.createElement('hr', { 'className': 'dashed' }), React.createElement(Card, { 'className': 'm-t-10 no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Metrics',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', { 'className': 'row left-xs' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'sets',
        'type': 'number',
        'component': TextInput,
        'label': 'Sets'
    })), this.props.metric && (this.props.metric === 1 || this.props.metric.value === 1) ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '9002'
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'reps',
        'type': 'number',
        'component': TextInput,
        'label': 'Reps'
    })) : null, this.props.metric && (this.props.metric === 2 || this.props.metric.value === 2) ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '9318'
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'distance',
        'type': 'number',
        'component': TextInput,
        'label': 'Distance'
    })), React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'distance_unit',
        'component': Select,
        'label': 'Distance Unit',
        'options': this.props.supportingData.distance_unit
    })))) : null, this.props.metric && (this.props.metric === 3 || this.props.metric.value === 3) ? React.createElement('div', {
        'className': 'col-xs-12',
        'key': '10254'
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'duration',
        'type': 'number',
        'component': TextInput,
        'label': 'Duration'
    })), React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'duration_unit',
        'component': Select,
        'label': 'Duration Unit',
        'options': this.props.supportingData.duration_unit
    })))) : null, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'hold',
        'type': 'number',
        'component': TextInput,
        'label': 'Hold Position'
    })), React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'hold_unit',
        'component': Select,
        'label': 'Hold Unit',
        'options': this.props.supportingData.hold_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'pause',
        'type': 'number',
        'component': TextInput,
        'label': 'Rest Between Sets'
    })), React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'pause_unit',
        'component': Select,
        'label': 'Rest between Sets Unit',
        'options': this.props.supportingData.duration_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'weight',
        'type': 'number',
        'component': TextInput,
        'label': 'Weight'
    })), React.createElement('div', { 'className': 'col-xs-6' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'weight_unit',
        'component': Select,
        'label': 'Weight Unit',
        'options': this.props.supportingData.weight_unit
    })))), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'heart_rate',
        'type': 'number',
        'component': TextInput,
        'label': 'Target Heart Rate'
    }))))))), React.createElement('hr', { 'className': 'dashed' }), React.createElement(Card, { 'className': 'm-t-10 no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Scientific Evidence',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', { 'className': 'row' }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'Evidence-based Exercise?'), React.createElement(Field, {
        'name': 'is_evidence_based',
        'component': Switch
    })), this.props.is_evidence_based ? React.createElement('div', {
        'className': 'row',
        'key': '14592'
    }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'scientific_evidence_text',
        'type': 'text',
        'component': TextInput,
        'label': 'Scientific Evidence',
        'multiline': true,
        'rows': 4
    }), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement('div', { 'className': 'row end-xs' }, this.props.scientific_evidence_guid ? React.createElement('div', {
        'className': 'col m-r-10',
        'key': '15216'
    }, React.createElement('a', {
        'href': this.props.scientific_evidence_url,
        'target': '_blank',
        'className': 'm-r-5'
    }, React.createElement(Button, {
        'label': 'View Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    })), React.createElement(Button, {
        'onClick': this.deleteScientificEvidence,
        'label': 'Remove Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    })) : null, !this.props.scientific_evidence_guid ? React.createElement('div', {
        'className': 'col',
        'key': '15855'
    }, React.createElement(Dropzone, {
        'className': 'col end-xs',
        'multiple': false,
        'accept': 'application/pdf',
        'onDrop': this.uploadScientificEvidence
    }, React.createElement(Button, {
        'label': this.props.scientific_evidence_guid ? 'Change Evidence File' : 'Upload Evidence File',
        'primary': true,
        'mini': true,
        'raised': true
    }))) : null))) : null)), React.createElement('hr', { 'className': 'dashed' }), this.props.video_guid ? React.createElement(Card, {
        'className': 'm-t-10 no-box-shadow',
        'key': '16549'
    }, React.createElement(CardTitle, {
        'title': 'Create a Thumbnail and Position Pictures for Great Printouts',
        'className': 'bg-accent'
    }), React.createElement('div', { 'className': 'm-20' }, '\n                        Select Play below and select it again to pause on the desired frame.\n                        Then select "Modify Thumbnail" or "Generate Position Picture".\n                    '), React.createElement('div', { 'className': 'row m-t-10 center-xs' }, React.createElement('div', { 'className': 'col-xs-6 p-10' }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-imagecreator',
        'onPlayerInitialized': player => {
            this.player = player;
        },
        'videoList': [this.props.video_guid]
    })), React.createElement('div', { 'className': 'col-xs-6 vertical-align center-xs' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'className': 'm-b-20',
        'label': 'Modify Thumbnail',
        'onClick': this.captureThumbnail,
        'primary': true,
        'raised': true
    }), this.props.exercise_thumbnail_url ? React.createElement(Card, {
        'className': 'width-250 bg-white p-0 m-l-5 m-r-5 m-b-20',
        'key': '17949'
    }, React.createElement('div', {
        'className': 'thumbnail box-250-150',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.exercise_thumbnail_url) + ')' }
    })) : null)))), React.createElement('hr', { 'className': 'dashed' }), React.createElement('div', { 'className': 'row center-xs m-t-20' }, React.createElement(Button, {
        'label': 'Generate Position Picture',
        'raised': true,
        'primary': true,
        'onClick': this.capturePositionPicture
    })), React.createElement.apply(this, [
        CardText,
        { 'className': 'p-t-20' },
        this.props.position_pictures && this.props.position_pictures.length === 0 ? React.createElement('div', {
            'className': 'row center-xs',
            'key': '18811'
        }, '\n                            No position pictures present\n                        ') : null,
        _.map(this.props.position_pictures, repeatMedia1.bind(this))
    ])) : null, React.createElement('div', { 'className': 'row end-xs m-t-10 m-b-10 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Save as Draft',
        'disabled': this.props.submitting,
        'className': 'text-capital f-16 bg-success-border',
        'raised': true,
        'onClick': this.saveAsDraft
    }), React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.submitting,
        'className': 'm-l-10 bg-success',
        'raised': true,
        'onClick': this.saveAndPublish
    }))))));
}