import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/treatment_template_list_new";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { getTreatmentTemplateList, toggleVisibilityTreatmentTemplate } from '../../redux/treatment_template/network/treatment_template_network';
import Swal from 'sweetalert2';
import actions from '../../redux/treatment_template/actions/treatment_template_creator';
import moment from 'moment';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { GAME_PLAN_TEMPLATE, GROUP_TEMPLATE } from '../../utils/help_video';
import { getPracticePaymentSubscriptionDetails } from '../../redux/payment/network/plan_subscribe_network' 
import { getTreatmentTemplate } from '../../redux/treatment_template/network/treatment_template_network';

class TreatmentTemplateList extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.addTreatment = this.addTreatment.bind(this);
        this.editTreatment = this.editTreatment.bind(this);
        this.onChangeTreatmentCategory = this.onChangeTreatmentCategory.bind(this);
        this.goToTreatmentDashboard = this.goToTreatmentDashboard.bind(this);
        this.searchTreatments = this.searchTreatments.bind(this);
        this.hideTreatmentTemplate = this.hideTreatmentTemplate.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.getData = this.getData.bind(this);
        this.hideTreatmentCreationModal = this.hideTreatmentCreationModal.bind(this);
        this.showTreatmentCreationModal = this.showTreatmentCreationModal.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.goToPaywall = this.goToPaywall.bind(this);

        this.state = {
            treatmentCategory: "active",
            search_text: "",
            showTreatmentCreationModal:false,
            selected_treatment_template:null
        };
        this.treatmentCategories = [
            {
                value: "active",
                label: "Visible",
            },
            {
                value: "inactive",
                label: "Hidden",
            },
        ];
    };

    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        this.props.cleanModule();  
    }
    
    
    componentDidMount() {
        this.getData(this.props);  
        if (this.props.match.params.treatment_type === "gameplan") {
            this.props.updateHelpVideo(GAME_PLAN_TEMPLATE);
        } else {
            this.props.updateHelpVideo(GROUP_TEMPLATE);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.treatment_type !== nextProps.match.params.treatment_type) {
            if (nextProps.match.params.treatment_type === "gameplan") {
                this.props.updateHelpVideo(GAME_PLAN_TEMPLATE);
            } else {
                this.props.updateHelpVideo(GROUP_TEMPLATE);
            }  
            this.props.cleanModule();
            this.getData(nextProps);
        }
    }
    

    componentWillUnmount() {
        this.props.cleanModule();
        this.props.clearHelpVideo();
    }

    getData(props,increment=false){
        let params = {
            'treatment_state': this.state.treatmentCategory,
            'search': this.state.search_text,
            'show_published': props.create_treatment ? true : false,
            'show_partner_templates': true,
            package_version: 2,
            "practice_id": this.props.practice_id,

        };
        if(props.match.params.treatment_type === "group"){
            params.treatment_type = 1;
            // params.treatment_type = 2;
        }else if(props.match.params.treatment_type === "gameplan"){
            params.treatment_type = 1;
        }
        params = {...params,...props.paginate_info};
        if(increment){
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        this.props.getTreatmentTemplateList(params);
    }

    addTreatment() {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type+'/add');
    }

    editTreatment(id) {
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type+'/edit/'+id);
    }
    
    goToTreatmentDashboard(event, id) {
        if (this.state.treatmentCategory === 'inactive' || this.props.create_treatment ) {
            return;
        }
        if (event.target.type === "button") {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type+'/edit/'+id);
        }
        history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/treatment_templates/'+this.props.match.params.treatment_type+'/edit/'+id);
    }

    onChangeTreatmentCategory(val){
        if(!val){
            return;
        }
        let that = this;
        this.setState({
            treatmentCategory: val.value
        },()=>{
            that.getData(that.props);
        });

    }

    hideTreatmentTemplate(treatmentTemplateId) {
        let display_text = "";
        if (this.state.treatmentCategory === 'active') {
            display_text = "Would you like to deactivate this treatment template from your practice? Are you sure?";
        } else {
            display_text = "Would you like to activate this treatment template for your practice? Are you sure?";
        }
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {
            if(result.value){
                let params = {};
                params['treatment_template_id'] = treatmentTemplateId;
                params['state'] = that.state.treatmentCategory === "active" ? "deactivate" : "activate"   ;
                that.props.toggleVisibilityTreatmentTemplate(params);
            }
        }, function (dismiss) {
                        
        });
    }
    

    updateSearchText(e) {
        this.setState({
            search_text: e.target.value
        });
    }    

    searchTreatments(e) {             
        if (e.key === "Enter") {            
            this.getData(this.props);
        } 
    }
    goToPaywall() {
        let that = this;
        Swal.fire({
            customClass: {
                container: 'paywall-modal',
                title: 'paywall-title',
                html:'paywall-text',
                confirmButton: 'paywall-confirm-button',
                cancelButton: 'paywall-cancel-button',
              },
            title: "Active Subscription Required",
            html: '<p>Use of this premium template requires an active Trainer Plus Subscription, which can be purchased from the Gray Institute mobile app.</p></br>' + "<p>To access this template and all other premium templates, please purchase the required subscription from your App Store.</p>",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#fff",
            confirmButtonText: "Go to App Store",
            cancelButtonText: "Go to Play Store",
            useRejections:true
        }).then((result) => {
            if (result.value) {
                window.open('https://apps.apple.com/us/app/dhf-coachfit/id1130877074', '_blank');
              } else if (result.dismiss=='cancel') {
                window.open('https://play.google.com/store/apps/details?id=com.gomotive.dhf', '_blank');
              }
        }, function (dismiss) {
        });
    }

    showTreatmentCreationModal(treatment_template){
        if(this.props.user.gi_isadmin) {
            this.setState({
                showTreatmentCreationModal:true,selected_treatment_template:treatment_template}); 
        } else {
            this.props.getPracticePaymentSubscriptionDetails({
                "package_version": "3", 
                "app_version": "5.4", 
                "current_role_type": "practice_admin", 
                "practice_id": this.props.practice_id
            }).then((res)=>{ })
            this.props.getTreatmentTemplate({"id": treatment_template.id}).then((res) =>{
            if(res.treatment_template.is_free){
                if(this.props.user.id !== res.treatment_template.owner){
                    if(!this.props.isTemplatesPurchased){
                        this.goToPaywall()
                    }else {
                        this.setState({showTreatmentCreationModal:true,selected_treatment_template:treatment_template}); 
                    }
                }else {
                    this.setState({showTreatmentCreationModal:true,selected_treatment_template:treatment_template}); 
                }
            } else {
                this.setState({showTreatmentCreationModal:true,selected_treatment_template:treatment_template}); 
            }

        })
        }
    }
    hideTreatmentCreationModal(event,treatment_created=null){
        if(treatment_created === null){
            this.setState({showTreatmentCreationModal:false,selected_treatment_template:null});
        }else{
            if(this.props.engagement_id){
                this.props.hideTreatmentTemplateSelection(null,treatment_created);
            }else{
                history.push('/practice/'+this.props.selectedRole.practice.id+'/group/dashboard/'+treatment_created.id);
            }
        }
    }


    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state,ownprops) {
    let create_treatment = _.has(ownprops,"create_treatment") ? ownprops.create_treatment : false ;
    let engagement_id = _.has(ownprops,"engagement_id") ? ownprops.engagement_id : null ;

    return {
        store,
        treatment_template: state.treatment_template,
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        treatment_template_list: state.treatment_template.treatmentTemplateReducer.treatment_template_list,
        paginate_info: state.treatment_template.treatmentTemplateReducer.paginate_info,
        create_treatment:create_treatment,
        engagement_id:engagement_id,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
           getTreatmentTemplateList,
           getTreatmentTemplate,
           getPracticePaymentSubscriptionDetails,
           toggleVisibilityTreatmentTemplate,
            cleanModule: actions.treatmentTemplateCleanup,
            updateHelpVideo: HelpVideoCreator.updateHelpVideo,
            clearHelpVideo: HelpVideoCreator.clearHelpVideo,
        }, 
        dispatch
    );
}

const TreatmentTemplateListConnected = connect(mapStateToProps,mapDispatchToProps)(TreatmentTemplateList);
export default TreatmentTemplateListConnected;