import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from '../../../utils/common_components/tooltipButton';
import ButtonDefault from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
import RichTextViewer from '../../../utils/common_components/rich_text_viewer.jsx';
export default function () {
    function repeatHabit1(habit, habitIndex) {
        return React.createElement(Tr, { 'key': 'habit' + habitIndex }, React.createElement(Td, {
            'column': 'name',
            'value': habit.name
        }, React.createElement('span', {}, habit.name)), React.createElement(Td, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.__schedule_type)), React.createElement(Td, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('span', {}, habit.duration_count, ' ', habit.__duration_type)), React.createElement(Td, {
            'column': 'author',
            'value': habit.practice.name,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('span', {}, '\n                    ', habit.practice.name, '\n                ')), React.createElement(Td, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, habit.practice.id === this.props.selectedRole.practice.id ? React.createElement('div', { 'key': '3564' }, habit.is_publish ? React.createElement('span', { 'key': '4063' }, 'Published') : null, !habit.is_publish ? React.createElement('span', { 'key': '4147' }, 'Not Published') : null) : null)), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', { 'className': 'goal-template-action-container' }, React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'primary': true,
            'className': 'm-r-12 goal-template-action',
            'onClick': () => {
                this.editHabitTemplate(habit.id);
            }
        }, 'Edit'), habit.practice.id === this.props.selectedRole.practice.id ? React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'className': 'goal-template-action delete-button',
            'onClick': () => {
                this.deleteHabitTemplate(habit.id);
            },
            'key': '4781'
        }, 'Delete') : null)));
    }
    return React.createElement('div', { 'className': 'm-20' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'style': { marginTop: '65px' },
            'noDataText': 'Create your own habit template by clicking on + icon',
            'sortable': [
                'name',
                'author'
            ],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name')), React.createElement(Th, {
            'column': 'schedule',
            'className': 'text-center'
        }, React.createElement('strong', {}, 'Schedule')), React.createElement(Th, {
            'column': 'duration',
            'className': 'text-center'
        }, React.createElement('strong', {}, 'Duration')), React.createElement(Th, {
            'column': 'author',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Author')), React.createElement(Th, {
            'column': 'is_published',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Is Published?')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Action'))),
        _.map(this.props.habit_templates, repeatHabit1.bind(this))
    ]), React.createElement(Button, {
        'className': 'floating-add-button m-r-5',
        'tooltip': 'Add new habit template',
        'style': { top: '100px' },
        'icon': 'add',
        'floating': true,
        'mini': true,
        'ripple': true,
        'onClick': () => {
            this.addHabitTemplate();
        }
    }));
}