import { combineReducers } from 'redux';
import moveReducer from './move_reducers';
import coachReducer from './coach_reducer';
import challengeReducer from './challenge_reducer';
import webinarReducer from './webinar_reducer';

export default combineReducers(
    {
        moveReducer,
        coachReducer,
        challengeReducer,
        webinarReducer
    }
);