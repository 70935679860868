// Action Creators
const fillHabitInstance = (habit, supporting_data) => ({
    type: 'ENGAGEMENT_HABIT_INSTANCE_FILL',
    habit,
    supporting_data,
});

const cleanModule = () => ({
    type: 'ENGAGEMENT_HABIT_INSTANCE_CLEANUP',
});

export default {
    fillHabitInstance,
    cleanModule,
};
