import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/how-to-videos_edit.rt";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { reduxForm, formValueSelector } from 'redux-form';
import {
    getEducationContent,saveEducationContent, getEducationCategory
} from '../../redux/education/network/education_network';
import EducationAction from '../../redux/education/actions/education_actions';
import axios from 'axios';
import { showProgress, hideProgress } from '../../redux/core/actions/progress_creator';

class HowToVideosEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveEducationContent = this.saveEducationContent.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.captureThumbnail = this.captureThumbnail.bind(this);
        this.state = {
            video_guid: null,
            thumbnail: null,
        };
    };
    
    componentWillMount() {

        if(this.props.match.params.video_id) {
            let params = {
                app_version: "5.2",
                current_role_type: "practice_admin",
                'content_id': this.props.match.params.video_id,
                package_version: "2",
                practice_id: 276,
            };
            this.props.getEducationContent(params);
            this.props.getEducationCategory({
                app_version: "5.2",
                current_role_type: "practice_admin",
                package_version: "1",
                practice_id: 276,
            });
        } else {
            this.props.getEducationCategory({
                app_version: "5.2",
                current_role_type: "practice_admin",
                package_version: "1",
                practice_id: 276,
            });
            this.props.getEducationContent({
                app_version: "5.2",
                current_role_type: "practice_admin",
                package_version: "2",
                practice_id: 276,
            });

        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.education_content.video_guid) {
            this.setState({
                video_guid: nextProps.education_content.video_guid,
                thumbnail: nextProps.education_content.thumbnail
            });
        }
    }    

    componentWillUnmount() {
        this.props.cleanUp();
    }

    onVideoUploadCompleted(result) {
        let that = this;
        that.props.change('video_guid', result.guid);
        that.props.change('thumbnail', result.thumbnail);
        setTimeout(function() {
            that.setState({
                video_guid: result.guid,
                thumbnail: result.thumbnail,
            }); 
        }, 500);

    }
    captureThumbnail() {
        let that = this;
        let AxiosThumbnail = axios.create({
            headers: {
                'Authorization': 'bearer 1574bfeeb086b47410c4d724a09b3788',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
                'Content-Type':'application/json',
            }
        });
        if (that.player) {
            this.props.showProgress();
            that.player.getCurrentTime().then(function(time) {
                let params = {time:time,active:true};
                AxiosThumbnail.post("https://api.vimeo.com/videos/" + that.props.video_guid + "/pictures",params
                ).then((response) => {
                    let thumbnail_url =  "";
                    if(response.data.sizes.length > 3 ){
                        thumbnail_url = response.data.sizes[3].link;
                    }else{
                        thumbnail_url = response.data.sizes[response.data.sizes.length -1].link;
                    }
                    that.props.change('thumbnail', thumbnail_url);    
                    that.props.hideProgress();
                    Utils.showSuccessAlert("Your new thumbnail image for this exercise is getting generated. Please wait.");
                }).catch((error) => {
                    that.props.hideProgress();
                });
            }).catch(function(error) {
                that.props.hideProgress();
            });
        }
    }


    onVideoUploadFailed(error) {        
        this.setState({
            video_guid: null,
            thumbnail: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/marketing/how-to-videos");
    }


    saveEducationContent(data) {
        if(this.props.user.id === 1599){
            if(!this.state.video_guid) {
                Utils.showErrorAlert("You have not uploaded an education video");
                return;
            }
            let params = { ...data };
            if(this.props.match.params.video_id) {
                params['content_id'] = this.props.match.params.video_id;
                params["education_category"] = params["education_category"]["id"];
            }
            params['video_guid'] = this.state.video_guid;
            params['thumbnail'] = this.props.thumbnail;
            params['video_url'] = this.state.thumbnail;
            params['practice_id'] = 276;
            params.package_version= "2",
            this.props.saveEducationContent(params).then((response) => {
                Utils.showSuccessAlert("Your video has been uploaded successfully.");
                history.push("/practice/"+this.props.selectedRole.practice.id+"/marketing/how-to-videos");
            }).catch(() => { });
        }
    }

    render() {
        return Template.apply(this);
    }
}

const selector = formValueSelector('HowToVideosEditForm');

function mapStateToProps(state) {
    return {
        thumbnail: selector(state, 'thumbnail'),
        video_guid: selector(state, 'video_guid'),
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,       
        education_categories: state.education.educationReducer.education_categories,
        education_content: state.education.educationReducer.education_content,
        initialValues: state.education.educationReducer.education_content,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getEducationContent,
            saveEducationContent,
            getEducationCategory,
            showProgress,
            hideProgress,
            cleanUp: EducationAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name','education_category'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const HowToVideosEditForm = reduxForm({
    form: 'HowToVideosEditForm', 
    enableReinitialize: true, 
    validate
})(HowToVideosEdit);


const HowToVideosEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(HowToVideosEditForm);
export default HowToVideosEditFormConnected;