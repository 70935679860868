let initialState = {
    program: {},
    supportingData: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "PROGRAM_EDIT_FILL":
            return Object.assign({}, state, {
                program: action.program,
                supportingData: action.supportingData,
            });
        case "PROGRAM_EDIT_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};