import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import PropTypes from 'prop-types';
import Template from "./templates/group_define_plan.rt";
import { updateGroupTrackingDay, getGroup } from '../../redux/group/network/group_network';
import { reduxForm } from 'redux-form';


class GroupDefinePlan extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doClose = this.doClose.bind(this);
        this.state = {
            goal_tracking_day_options: [
                { value: 6, label: 'Sunday' },
                { value: 0, label: 'Monday' },
                { value: 1, label: 'Tuesday' },
                { value: 2, label: 'Wednesday' },
                { value: 3, label: 'Thursday' },
                { value: 4, label: 'Friday' },
                { value: 5, label: 'Saturday' },
            ]
        };
    };

    doClose() {
        this.props.handleClose();
    }

    doSave(data) {     
        this.props.updateGroupTrackingDay({
            id: this.props.group.id,
            goal_tracking_day: data.goal_tracking_day,
        }).then((response) => {
            this.props.getGroup({ id: this.props.group.id })
                .then(() => {
                    this.doClose();
                }).catch((error) => {                
                });            
        }).catch((error) => {
            //do nothing
        });
    }


    render() {
        if (_.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }
}

GroupDefinePlan.propTypes = {
    group:PropTypes.object.isRequired,    
};



function mapStateToProps(state, ownprops) {
    let initialValues = { 
        goal_tracking_day:ownprops.group.goal_tracking_day
    };
    return {
        group: ownprops.group,
        initialValues: initialValues,
        handleClose:ownprops.handleClose
    };    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateGroupTrackingDay,
        getGroup,
    }, dispatch
    );
}

const validate = (values, props) => {
    const errors = {};
    let requiredFields = [
        'goal_tracking_day',
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const GroupDefinePlanForm = reduxForm(
    {
        form: 'clientEngagementGoalEditForm',
        enableReinitialize: true,
        validate
    }
)(GroupDefinePlan);
const GroupDefinePlanFormConnected = connect(mapStateToProps,mapDispatchToProps)(GroupDefinePlanForm);
export default GroupDefinePlanFormConnected;
