export const API_VERSION = 6;
export const PACKAGE = 'program';

export const DEFAULT_MODE = 0;
export const TEMPLATE_SELECTION_MODE = 1;
export const PROGRESSION_SELECTION_MODE = 2;
export const MODES = [DEFAULT_MODE, TEMPLATE_SELECTION_MODE, PROGRESSION_SELECTION_MODE];

export const WORKOUT_TARGET_CLIENT = 3;
export const WORKOUT_TARGET_GROUP = 4;
export const WORKOUT_TARGET_TYPES = [WORKOUT_TARGET_CLIENT, WORKOUT_TARGET_GROUP];