import * as React from 'react';
import * as _ from 'lodash';
import Button from 'react-toolbox/lib/button';
import RadioGroup from '../../../utils/common_components/radio_group_form';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
export default function () {
    function repeatGoal_template1(goal_template, goalIndex) {
        return React.createElement('div', { 'key': 'goal' + goalIndex }, React.createElement('div', { 'className': 'm-t-20' }, React.createElement(Field, {
            'name': 'goal',
            'component': 'input',
            'type': 'radio',
            'value': goal_template.id.toString()
        }), ' \n                    ', goal_template.text, '\n                '));
    }
    return React.createElement('div', { 'className': 'row start-xs' }, React.createElement.apply(this, [
        'form',
        {
            'className': 'w-100',
            'onSubmit': this.props.handleSubmit(this.doSave)
        },
        React.createElement('h5', { 'className': 'm-t-5' }, '\n                Please select a goal to get started or create your own:\n            '),
        _.map(this.props.goal_templates, repeatGoal_template1.bind(this)),
        this.props.selectedGoal === '-1' ? React.createElement('div', {
            'className': 'm-t-20',
            'key': '1059'
        }, React.createElement(Field, {
            'name': 'custom_goal_text',
            'type': 'text',
            'component': TextInput,
            'label': 'What do you want to do?'
        })) : null,
        React.createElement('div', { 'className': 'text-right m-t-20 w-100' }, React.createElement(Button, {
            'label': 'Save',
            'disabled': this.props.pristine || this.props.submitting,
            'type': 'submit',
            'className': 'bg-success',
            'raised': true
        }), React.createElement(Button, {
            'label': 'Close',
            'disabled': this.props.submitting,
            'onClick': this.props.handleClose,
            'type': 'button',
            'className': 'bg-accent m-l-5',
            'raised': true
        }))
    ]));
}