import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import Dropzone from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
export default function () {
    return React.createElement('div', { 'className': 'row center-profile' }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.doCancel
    }, '\n        Back\n    '), React.createElement('form', {
        'className': 'max-con',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, { 'className': 'w-100 card-bg no-box-shadow' }, React.createElement('div', { 'className': 'bg-white box-shadow-10' }, React.createElement(CardTitle, {
        'className': 'p-b-10 text-left',
        'title': 'Change Email',
        'subtitle': 'Your current email address is ' + this.props.user.email
    }), React.createElement(CardText, { 'className': 'm-t-15' }, React.createElement('div', { 'className': 'row p-b-20' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { color: '#666666' },
        'name': 'old_email',
        'type': 'text',
        'component': TextInput,
        'label': 'Your Current Email ID'
    })), React.createElement('div', { 'className': 'row' }, React.createElement(Field, {
        'className': 'w-100',
        'style': { color: '#666666' },
        'name': 'new_email',
        'type': 'text',
        'component': TextInput,
        'label': 'New Email ID'
    }))), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Save Changes',
        'disabled': this.props.pristine || this.props.submitting,
        'className': 'bg-success w-100',
        'type': 'submit',
        'primary': true,
        'raised': true
    }))))));
}