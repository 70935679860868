import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';

import Template from "./templates/education_content_edit.rt";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { reduxForm } from 'redux-form';
import {
    getEducationContent,saveEducationContent
} from '../../redux/education/network/education_network';
import EducationAction from '../../redux/education/actions/education_actions';

class EducationContentEdit extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.saveEducationContent = this.saveEducationContent.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.onVideoUploadCompleted = this.onVideoUploadCompleted.bind(this);
        this.onVideoUploadFailed = this.onVideoUploadFailed.bind(this);
        this.state = {
            video_guid: null,
            thumbnail: null,
        };
    };
    
    componentWillMount() {
        if(this.props.match.params.content_id) {
            let params = {
                'content_id': this.props.match.params.content_id,
            };
            this.props.getEducationContent(params);
        } else {
            this.props.getEducationContent({});
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.education_content.video_guid) {
            this.setState({
                video_guid: nextProps.education_content.video_guid,
                thumbnail: nextProps.education_content.thumbnail
            });
        }
    }    

    componentWillUnmount() {
        this.props.cleanUp();
    }

    onVideoUploadCompleted(result) {
        let that = this;
        setTimeout(function() {
            that.setState({
                video_guid: result.guid,
                thumbnail: result.thumbnail,
            }); 
        }, 500);

    }

    onVideoUploadFailed(error) {        
        this.setState({
            video_guid: null,
            thumbnail: null,
        });
        Utils.showErrorAlert("There was a failure in uploading video for this news article. Please try again.");
    }

    doCancel() {
        history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education");
    }


    saveEducationContent(data) {
        if(!this.state.video_guid) {
            Utils.showErrorAlert("You have not uploaded an education video");
            return;
        }
        let params = { ...data };
        params["education_category"] = params["education_category"]["id"];
        if(this.props.match.params.content_id) {
            params['content_id'] = this.props.match.params.content_id;
        }
        params['video_guid'] = this.state.video_guid;
        params['thumbnail'] = this.state.thumbnail;
        this.props.saveEducationContent(params).then((response) => {
            Utils.showSuccessAlert("Your video has been uploaded successfully.");
            history.push("/practice/"+this.props.selectedRole.practice.id+"/partner/education");
        }).catch(() => { });
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,       
        education_categories: state.education.educationReducer.education_categories,
        education_content: state.education.educationReducer.education_content,
        initialValues: state.education.educationReducer.education_content,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getEducationContent,
            saveEducationContent,
            cleanUp: EducationAction.cleanModule,
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name','education_category'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;

};

const EducationContentEditForm = reduxForm({
    form: 'EducationContentEditForm', 
    enableReinitialize: true, 
    validate
})(EducationContentEdit);


const EducationContentEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(EducationContentEditForm);
export default EducationContentEditFormConnected;