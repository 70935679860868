import * as React from 'react';
import * as _ from 'lodash';
import { IconButton } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import { Panel } from 'react-toolbox';
import Input from 'react-toolbox/lib/input';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import EducationVideo from '../../../client_app/components/welcome_video';
export default function () {
    function repeatVideo1(video, index) {
        return React.createElement('div', {
            'className': 'width-250 box-shadow bg-white m-l-10 m-r-10 m-t-10',
            'key': 'video' + index
        }, React.createElement('div', {
            'className': 'thumbnail box-250-150',
            'style': { backgroundImage: 'url(' + encodeURI(video.thumbnail) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '50px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'col-xs-12 f-16 truncate',
            'tooltip': video.name
        }, '\n                        ', video.name, '\n                    '), React.createElement('div', { 'className': 'col-xs-12 f-12 f-300 m-t-10 m-b-10' }, '\n                        ', video.education_category.full_name, '\n                    ')), React.createElement('div', { 'className': 'row center-xs m-0' }, video.deleted_date === null ? [React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '33891'
            }, React.createElement(IconButton, {
                'tooltip': 'View Content',
                'icon': 'play_circle_filled',
                'className': 'm-r-5 c-black small-icon-button round-border',
                'onClick': () => {
                    this.viewEducationContent(video);
                }
            }))] : null));
    }
    return React.createElement('div', {}, React.createElement(Panel, { 'className': 'full-panel' }, React.createElement('div', { 'className': 'row start-xs m-10' }, React.createElement(Select, {
        'className': 'col-xs-4 m-t-5',
        'placeholder': 'Select Partner',
        'clearable': false,
        'value': this.state.selectedPartner,
        'options': this.props.partner_list,
        'simpleValue': true,
        'labelKey': 'name',
        'valueKey': 'id',
        'onChange': val => {
            this.onSelectPartner(val);
        }
    }), React.createElement(Select, {
        'className': 'col-xs-4 m-t-5',
        'placeholder': 'Select content category',
        'clearable': false,
        'value': this.state.selectedEducationCategory,
        'options': this.props.education_categories,
        'simpleValue': true,
        'labelKey': 'name',
        'valueKey': 'id',
        'onChange': val => {
            this.onSelectCategory(val);
        }
    }), React.createElement('div', { 'className': 'col-xs-4 p-relative m-b-20 p-5  vertical-align' }, React.createElement(Input, {
        'className': 'flex-auto p-0 m-r-5',
        'onChange': this.onSearch,
        'type': 'text',
        'placeholder': 'Search for content',
        'name': 'search'
    }))), this.props.education_content.length === 0 ? [React.createElement('div', {
            'className': 'row center-xs alert-warning m-20',
            'key': '23231'
        }, '\n                There are no education content videos\n            ')] : null, React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs  m-10' },
        _.map(this.props.education_content, repeatVideo1.bind(this))
    ]), this.props.paginate_info.total_pages && this.props.paginate_info.page < this.props.paginate_info.total_pages - 1 ? React.createElement('div', {
        'className': 'row center-xs m-30 w-100',
        'key': '3973'
    }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
        'label': 'Load more ...',
        'className': 'bg-primary c-black',
        'onClick': () => {
            this.loadEducationContentData(true);
        }
    }))) : null, this.state.show_education_video ? React.createElement(EducationVideo, {
        'title': this.state.education_video.title,
        'onCloseHandle': this.closeEducationContent,
        'welcome_video': this.state.education_video,
        'key': '4422'
    }) : null));
}