import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/marketing_tips";
import { connect } from 'react-redux';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';

class MarketingTips extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }
    
    componentWillMount() {
    }

    componentWillUnmount() {
    }
    
    render() {
        return Template.apply(this);
    }
}

export default MarketingTips;
