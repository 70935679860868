import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/analysis";
import { formValueSelector, reduxForm } from 'redux-form';
import { history } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../utils/utils';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { ANALYSIS_3DMAPS_VIDEO } from '../../utils/help_video';


class Analysis extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.onAnalysisTypeSelected = this.onAnalysisTypeSelected.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.getNextValue = this.getNextValue.bind(this);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.getCurrentSelection = this.getCurrentSelection.bind(this);
        this.selectVideo = this.selectVideo.bind(this);
        this.updateNotes = this.updateNotes.bind(this);
        this.state = {
            bigData: [
                {
                    "tag": 1,
                    "name": "ANT",
                    "mobility":"257731495",
                    "stability":"257731540",
                    "mobility_thumbnail":"https://i.vimeocdn.com/video/685721120_640x360.jpg?r=pad",
                    "stability_thumbnail" : "https://i.vimeocdn.com/video/685721282_640x360.jpg?r=pad"
                },
                {
                    "tag": 2,
                    "name": "PST",
                    "mobility":"257731497",
                    "stability":"257731543",
                    "mobility_thumbnail":"https://i.vimeocdn.com/video/685721143_640x360.jpg?r=pad",
                    "stability_thumbnail" : "https://i.vimeocdn.com/video/685721097_640x360.jpg?r=pad"
                },
                {
                    "tag": 3,
                    "name": "SSL",
                    "mobility":"257731498",
                    "stability":"257731548",
                    "mobility_thumbnail":"https://i.vimeocdn.com/video/685720990_640x360.jpg?r=pad",
                    "stability_thumbnail": "https://i.vimeocdn.com/video/685722433_640x360.jpg?r=pad"
                },
                {
                    "tag": 4,
                    "name": "OSL",
                    "mobility":"257731500",
                    "stability":"257731554",
                    "mobility_thumbnail":"https://i.vimeocdn.com/video/685721104_640x360.jpg?r=pad",
                    "stability_thumbnail" : "https://i.vimeocdn.com/video/685721985_640x360.jpg?r=pad"
                },
                {
                    "tag": 5,
                    "name": "SSR",
                    "mobility":"257731503",
                    "stability":"257731558",
                    "mobility_thumbnail":"https://i.vimeocdn.com/video/685721114_640x360.jpg?r=pad",
                    "stability_thumbnail" : "https://i.vimeocdn.com/video/685721722_640x360.jpg?r=pad"
                },
                {
                    "tag": 6,
                    "name": "OSR",
                    "mobility":"257731506",
                    "stability":"257731560",
                    "mobility_thumbnail":"https://i.vimeocdn.com/video/685721039_640x360.jpg?r=pad",
                    "stability_thumbnail": "https://i.vimeocdn.com/video/685721680_640x360.jpg?r=pad"
                }
            ],
            itemSelections: {

            },
            analysisTypes: [
                {
                    value: 'mobility',
                    label: 'Mobility Analysis',
                },
                {
                    value: 'stability',
                    label: 'Stability Analysis',
                },
                {
                    value: 'mostability',
                    label: 'Mostability Analysis',
                },
            ],
            selectedAnalysisType: null,
            selectedVideoName: 'ANT Mobility',
            selectedVideoGuid: '257731495',
            notes: "",
        };
    }

    componentWillMount() {
        this.setState({
            selectedAnalysisType: this.props.analysisType ? this.props.analysisType : this.state.analysisTypes[2],
            notes: this.props.notes,
            itemSelections: this.props.analysisItemSelections
        });        
    }

    componentDidMount() {
        this.props.updateHelpVideo(ANALYSIS_3DMAPS_VIDEO);
    }

    selectVideo(guid, name) {
        this.setState({ selectedVideoGuid: guid, selectedVideoName: name });
    }

    updateNotes(val) {
        this.setState({
            notes: val
        });
    }

    updateItem(name, side, value) {
        let currentSelections = {...this.state.itemSelections};
        currentSelections[name] = {
            [side]: value,
        };
        this.setState({
            itemSelections: currentSelections
        });
    }

    onItemClicked(name, side) {
        let next = this.getNextValue(this.getCurrentSelection(name, side), side === 'c');
        this.updateItem(name, side, next);
    }

    getCurrentSelection(name, side) {
        let selection = this.state.itemSelections[name];
        return selection ? selection[side] : '';
    }

    getNextValue(currentValue, isCenter) {
        if (currentValue === "" || currentValue === undefined) {
            return isCenter ? "+" : "-";
        }
        if (currentValue === "+") {
            return "-";
        }
        if (currentValue === "-") {
            return "-p";
        }
        // currentValue is -p
        return "";
    }

    onAnalysisTypeSelected(val) {
        if (val === null) {
            Utils.showErrorAlert("You need to select an Analysis type to assess your client using 3DMAPS and generate workout");
            return;
        }
        this.setState({ selectedAnalysisType: val });
    }

    doSave() {
        let selections = this.state.itemSelections;
        let sortable = [];
        let index = 0;
        this.state.bigData.forEach(item => {
            let name = item.name;
            let selection = selections[name];
            let l, r, c;
            if (selection) {
                l = selection.l ? selection.l : '';
                r = selection.r ? selection.r : '';
                c = selection.c ? selection.c : '';
            } else {
                return;
            }
            let order;
            if (c === "+") {
                order = 0;
            } else if (l === "-" || r === "-") {
                order = 1;
            } else if (c === "-") {
                order = 2;
            } else if (l === "-p" || r === "-p") {
                order = 3;
            } else if (c === "-p") {
                order = 4;
            } else {
                order = 5;
            }
            sortable.push({ order: order, name: name, index: ++index });
        });
        sortable.sort((o1, o2) => {
            let diff = o1.order - o2.order;
            return diff === 0 ? o1.index - o2.index : diff;
        });
        let rscString = "";
        _.forEach(sortable, item => { rscString += " " + item.name; });
        rscString = rscString.trim();
        this.props.submitAnalysis(
            rscString, this.state.selectedAnalysisType, this.state.notes,
            this.state.itemSelections,
        );
    }

    doCancel() {
        history.goBack();
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clearHelpVideo();
    }

}

const selector = formValueSelector('AnalysisForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateHelpVideo: HelpVideoCreator.updateHelpVideo,
        clearHelpVideo: HelpVideoCreator.clearHelpVideo,
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const AnalysisForm = reduxForm(
    {
        form: 'AnalysisForm',
        enableReinitialize: true,
        validate
    }
)(Analysis);

const AnalysisConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisForm);

export default AnalysisConnected;
