let initialState = {
    client_list: [],
    all_client_list: [],
    client: {},
    practitioner_list: [],
    consultant_list: [],
    client_selection_list: [],
    group_count: 0,
    support_multiple_gameplan: false,
    is_workfit_practice: false,
    paginate_info: {
        page: 0,
    },
};

export default function intake(state = initialState, action) {
    switch (action.type) {
        case "CLIENT_LIST_FILL":
            if (!action.client_list || !action.paginate_info ){
                return state;
            }
            // let newClientList = [];
            // if(action.paginate_info.page === 0){
            //     newClientList = [ ...action.client_list];
            // }else{
            //     newClientList = [...state.client_list, ...action.client_list];
            // }
            return Object.assign({}, state, {
                client_list: action.client_list,
                paginate_info: action.paginate_info,
                group_count: action.group_count,
                support_multiple_gameplan: action.support_multiple_gameplan,
                is_workfit_practice: action.is_workfit_practice
            });
        case "ALL_CLIENT_LIST_FILL":
            if (!action.client_list){
                return state;
            }
            let newClientList = [];
            if(action.paginate_info.page === 0){
                newClientList = [ ...action.client_list];
            }else{
                newClientList = [...action.client_list];
                // newClientList = [...state.client_list, ...action.client_list];
            }
            return Object.assign({}, state, {
                client_list: newClientList,
                paginate_info: action.paginate_info,
                group_count: action.group_count,
                support_multiple_gameplan: action.support_multiple_gameplan,
                is_workfit_practice: action.is_workfit_practice
            });
        case "CLIENT_FILTER_FILL":
            if (!action.client_selection_list){
                return state;
            }
            return Object.assign({}, state, {
                client_selection_list: action.client_selection_list,
                practitioner_list: action.practitioner_list,
                consultant_list: action.consultant_list,
            });
        case "CLIENT_LIST_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
