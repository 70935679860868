import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import DatePicker from '../../../utils/common_components/datepicker_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import NewsView from '../news_view.jsx';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
export default function () {
    function repeatMedia1(media, index) {
        return React.createElement(Card, {
            'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5',
            'key': 'media' + index
        }, media.media_type === 1 ? React.createElement('div', {
            'className': 'thumbnail box-300-225 pointer',
            'style': { backgroundImage: 'url(' + encodeURI(media.thumbnail_url) + ')' },
            'onClick': () => {
                this.previewImage(media);
            },
            'key': '8723'
        }) : null, media.media_type === 2 ? React.createElement('div', {
            'className': 'box-300-225 pointer',
            'key': '8976'
        }, React.createElement(VimeoPlayer, {
            'identifier': 'additional_gomotive_video' + media.guid,
            'videoList': [media.guid],
            'handleUploadProcessing': true
        })) : null, React.createElement('div', { 'className': 'bg-white row end-xs p-5' }, React.createElement(IconButton, {
            'tooltip': 'Set as cover media',
            'icon': 'perm_media',
            'className': 'c-black',
            'onClick': () => {
                this.setCoverMedia(index);
            }
        }), React.createElement(IconButton, {
            'tooltip': 'Delete the uploaded media file',
            'icon': 'delete_forever',
            'className': 'c-black',
            'onClick': () => {
                this.deleteMedia(media);
            }
        })));
    }
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'col-xs-10 m-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', {}, React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'News Content',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'name': 'title',
        'type': 'text',
        'label': 'Title',
        'component': TextInput
    })), React.createElement('div', { 'className': 'col-xs-12 m-b-10' }, React.createElement(Field, {
        'id': 'newsContentRichText',
        'name': 'content',
        'type': 'text',
        'label': 'Content',
        'component': RichTextEditor
    })), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement(Field, {
        'name': 'start_date',
        'type': 'date',
        'label': 'Start Date',
        'component': DatePicker,
        'minDate': moment().subtract(1, 'days').toDate()
    })), React.createElement('div', { 'className': 'col-xs-4' }, React.createElement(Field, {
        'name': 'end_date',
        'type': 'date',
        'label': 'End Date',
        'component': DatePicker,
        'minDate': moment().subtract(1, 'days').toDate()
    }))))), React.createElement(Card, { 'className': 'no-box-shadow' }, React.createElement(CardTitle, {
        'title': 'Targets',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-6' }, this.props.selectedRole.practice.is_business_partner ? React.createElement('div', {
        'className': 'row f-18 m-b-20',
        'key': '3989'
    }, '\n                            Users in my practice\n                        ') : null, React.createElement('div', { 'className': 'row' }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'All practitioners in my practice'), React.createElement(Field, {
        'name': 'target.all_practitioners',
        'component': Switch
    })), React.createElement('div', { 'className': 'row' }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'All clients in my practice'), React.createElement(Field, {
        'name': 'target.all_clients',
        'component': Switch
    })), !this.props.all_clients && this.props.supportingData.groups.length > 0 ? React.createElement('div', {
        'className': 'row',
        'key': '4712'
    }, React.createElement('div', { 'className': 'col-xs-10 p-l-0 p-r-0' }, React.createElement(Field, {
        'className': 'p-t-0 m-b-20',
        'name': 'target.groups',
        'component': Select,
        'label': 'Groups',
        'options': this.props.supportingData.groups,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true
    }))) : null), React.createElement('div', { 'className': 'col-xs-6' }, this.props.selectedRole.practice.is_business_partner ? [
        React.createElement('div', {
            'className': 'row f-18 m-b-20',
            'key': '52811'
        }, '\n                                Users in my business network\n                            '),
        React.createElement('div', {
            'className': 'row',
            'key': '52813'
        }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'All practitioners in my business network'), React.createElement(Field, {
            'name': 'target.all_network_practitioners',
            'component': Switch
        })),
        React.createElement('div', {
            'className': 'row',
            'key': '52815'
        }, React.createElement('label', { 'className': 'material-label m-r-20' }, 'All clients in my business network'), React.createElement(Field, {
            'name': 'target.all_network_clients',
            'component': Switch
        }))
    ] : null)))), this.props.news_id ? React.createElement(Card, {
        'className': 'no-box-shadow',
        'key': '6238'
    }, React.createElement(CardTitle, {
        'title': 'Media',
        'className': 'bg-accent'
    }), React.createElement(CardText, { 'className': 'p-t-20' }, React.createElement('div', { 'className': 'row end-xs' }, React.createElement(Dropzone, {
        'className': 'col m-l-4',
        'multiple': true,
        'accept': 'image/*',
        'onDrop': this.uploadImages
    }, React.createElement(Button, {
        'label': 'Upload Images',
        'primary': true,
        'raised': true
    })), React.createElement(VimeoUploader, {
        'w': true,
        'className': 'col m-l-4',
        'onUploadCompleted': this.onVideoUploadCompleted,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed
    })), React.createElement('div', {}, this.props.cover_media ? React.createElement('div', {
        'className': 'col-xs-12 m-t-10',
        'key': '6908'
    }, React.createElement('div', { 'className': 'row material-label m-l-0 m-b-10' }, '\n                            Cover Media\n                        '), React.createElement(Card, {
        'className': 'width-300 bg-white p-0 m-t-10 m-l-5 m-r-5',
        'key': 'cover_media'
    }, this.props.cover_media.media_type === 1 ? React.createElement('div', {
        'className': 'thumbnail box-300-225 pointer',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.cover_media.thumbnail_url) + ')' },
        'onClick': () => {
            this.previewImage(this.props.cover_media);
        },
        'key': '7238'
    }) : null, this.props.cover_media.media_type === 2 ? React.createElement('div', {
        'className': 'box-300-225 pointer',
        'key': '7534'
    }, React.createElement(VimeoPlayer, {
        'identifier': 'gomotive_video' + this.props.cover_media.guid,
        'videoList': [this.props.cover_media.guid],
        'handleUploadProcessing': true
    })) : null, React.createElement('div', { 'className': 'bg-white row end-xs p-5' }, React.createElement(IconButton, {
        'tooltip': 'Delete the uploaded media file',
        'icon': 'delete_forever',
        'className': 'c-black',
        'onClick': () => {
            this.deleteMedia(this.props.cover_media);
        }
    })))) : null, !_.isEmpty(this.props.media_items) ? React.createElement('div', {
        'className': 'col-xs-12 m-t-10 m-b-15',
        'key': '8267'
    }, React.createElement('div', { 'className': 'row material-label m-l-0 m-b-10' }, '\n                            Additional Media\n                        '), React.createElement.apply(this, [
        'div',
        { 'className': 'row' },
        _.map(this.props.media_items, repeatMedia1.bind(this))
    ])) : null))) : null, React.createElement('div', { 'className': 'col-xs-12 end-xs m-30' }, this.props.news_id ? React.createElement(Button, {
        'label': 'Publish',
        'onClick': this.onPublish,
        'className': 'm-r-10',
        'disabled': this.props.submitting,
        'type': 'button',
        'primary': true,
        'raised': true,
        'key': '9969'
    }) : null, this.props.news_id ? React.createElement(Button, {
        'label': 'Preview',
        'className': 'bg-accent m-r-10',
        'onClick': this.showNewsPreviewDialog,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true,
        'key': '10154'
    }) : null, React.createElement(Button, {
        'label': 'Save as draft',
        'className': 'bg-accent m-r-10',
        'onClick': this.onDraft,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }), React.createElement(Button, {
        'label': 'Cancel',
        'className': 'bg-accent',
        'onClick': this.doCancel,
        'disabled': this.props.submitting,
        'type': 'button',
        'raised': true
    }))))), this.state.showMediaPreview ? React.createElement(Lightbox, {
        'mainSrc': this.state.displayImageUrl,
        'onCloseRequest': () => this.setState({
            showMediaPreview: false,
            displayImageUrl: null
        }),
        'key': '10704'
    }) : null, React.createElement(Dialog, {
        'active': this.state.newsPreviewDialog,
        'className': 'full-dialog',
        'onEscKeyDown': this.hideNewsPreviewDialog,
        'onOverlayClick': this.hideNewsPreviewDialog
    }, React.createElement(NewsView, {
        'news': this.props.news,
        'handleClose': this.hideNewsPreviewDialog
    })));
}