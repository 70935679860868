// import React from 'react';
// // import DayPicker, { DateUtils } from 'react-day-picker';
// // import { DayPicker } from 'react-day-picker';
// import { DayPicker, DateUtils } from 'react-day-picker';
// import BaseComponent from '../../utils/common_components/basecomponent';
import Dialog from 'react-toolbox/lib/dialog';
// // import Button from 'react-toolbox/lib/button';
import { Button} from '@mui/material';

// // import 'react-day-picker/lib/style.css';
// import 'react-day-picker/dist/style.css';
// import Utils from '../utils';
// import PropTypes from 'prop-types';

// export default class MultiDatePicker extends BaseComponent {

//     constructor(props, context) {
//         super(props, context);
//         this.handleDayClick = this.handleDayClick.bind(this);
//         this.isDayEnabled = this.isDayEnabled.bind(this);
//         this.state = {
//             selectedDays: [],
//         };
//     };

//     componentWillMount() {
//         this.setState({
//             selectedDays: this.props.selectedDays,
//         });
//     }

//     componentWillReceiveProps(nextProps) {
//         if (!_.isEqual(nextProps.selectedDays, this.props.selectedDays)) {
//             this.setState({
//                 selectedDays: nextProps.selectedDays,
//             });
//         }
//     }

//     /**
//      * Note: enabledDays has more priority over disabledDays
//      */
//     isDayEnabled(day) {
//         if(_.has(this.props, "enable_prev_days") && this.props.enable_prev_days  ){
//             //Do nothing for now
//         }else{
//             if(DateUtils.isPastDay(day)){
//                 return false;
//             }
//         }
//         let enabled = this.props.enabledDays.length !== 0;
//         let days = enabled ? this.props.enabledDays : this.props.disabledDays;
//         if (days.length === 0) {
//             return true;
//         }
//         let index = _.findIndex(days, (d) => {
//             return (d.getFullYear() === day.getFullYear()) &&
//                 (d.getMonth() === day.getMonth()) &&
//                 (d.getDate() == day.getDate());
//         });
//         return enabled ? index !== -1 : index === -1;
//     }

//     handleDayClick(day, { selected }) {
//         if (this.props.disableSelection) {
//             return;
//         }
//         if (!this.isDayEnabled(day)) {
//             return;
//         }
//         let selectedDays = [...this.state.selectedDays];
//         if (selected) {
//             const selectedIndex = selectedDays.findIndex(selectedDay =>
//                 DateUtils.isSameDay(selectedDay, day)
//             );
//             selectedDays.splice(selectedIndex, 1);
//         } else {
//             selectedDays.push(day);
//         }
//         this.setState({ selectedDays });
//         this.props.onChange(selectedDays);
//     };
//     render() {
//         return (
//             <Dialog active={this.props.active} title="Choose Dates"
//                 className="dialog-title-center dialog-wrap-content">
//                 <div>
//                     <DayPicker
//                         className="big-date-picker"
//                         disabledDays={(day) => { return !this.isDayEnabled(day); }}
//                         selectedDays={this.state.selectedDays}
//                         onDayClick={this.handleDayClick}
//                     />
//                 </div>
//                 <div className="row center-xs m-t-10">
//                     <Button label={this.props.disableSelection ? 'Close' : 'Cancel'} accent raised onClick={this.props.onCancel} >{this.props.disableSelection ? 'Close' : 'Cancel'}</Button>
//                     {!this.props.disableSelection &&
//                         <Button label="Done" className="m-l-10 bg-success" raised onClick={() => this.props.onAccept(this.state.selectedDays)} >Done</Button>
//                     }
//                 </div>
//             </Dialog>
//         );
//     }
// }

// MultiDatePicker.propTypes = {
//     onChange: PropTypes.func,
//     selectedDays: PropTypes.array,
//     active: PropTypes.bool,
//     disableSelection: PropTypes.bool,
// };

// MultiDatePicker.defaultProps = {
//     selectedDays: [],
//     enabledDays: [],
//     disabledDays: [],
//     active: true,
//     disableSelection: false,
//     onChange: (selectedDays) => { },
//     onAccept: (selectedDays) => { },
//     onCancel: () => { },
// };
import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

export default function MultiDatePicker(props) {
  const [days, setDays] = React.useState(props.selectedDays ? props.selectedDays :[new Date()]);

  let daysAdd = [];
  // const onClick = (value, { disabled }) => {
  //   console.log("val", value, "days", days);
  //   daysAdd.push(value);
  //   setDays(days);
  //   if (disabled) {
  //     console.log("disable");
  //   }
  // };
  console.log("daysAdd", daysAdd, days);
  let newA = days.map((day) => new Date(day).toISOString());
  console.log(newA, "New");
  console.log('date props', props)
  let selectedDays = props.selectedDays.map((day) => new Date(day));
  console.log(selectedDays,'selectedDays')
  return (
    <Dialog active={props.active} title="Choose Dates"
        className="dialog-title-center dialog-wrap-content">
        <div>
            <DayPicker
                mode="multiple"
                onSelect={setDays}
                disabled={{ before: new Date() }}
                // disabled={!props.enabledDays}
                // onDayClick={onClick}
                selected={days ? days : selectedDays}
                />
        </div>
        <div className="row center-xs m-t-10">
            <Button label={props.disableSelection ? 'Close' : 'Cancel'} accent raised onClick={props.onCancel} >{props.disableSelection ? 'Close' : 'Cancel'}</Button>
            {!props.disableSelection &&
                <Button label="Done" className="m-l-10 bg-success" raised onClick={() => props.onAccept(newA)} >Done</Button>
            }
        </div>
    </Dialog>
  );
}
