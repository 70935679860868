import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';

import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import EngagementCreator from '../actions/engagement_creator';
import documentActionCreator from '../../documents/actions/health_document';
import EngagementHabitCreator from '../actions/engagement_habit';

const getEngagementInstance = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_get";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const createEngagementHabitFromTemplate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/create_engagement_habit_from_template";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const assignIntakeToEngagement = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_intake_assign";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully assigned intake.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const addGoalEngagement = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_goal_add";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            Utils.showSuccessAlert("Goal with tracking questions has been successfully assigned to your client");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updateGoalEngagement = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_goal_update";
        return Axios.post(url, params).then((response) => {
            dispatch(hideProgress());
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            Utils.showSuccessAlert("Goal with tracking questions has been successfully assigned to your client");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const defineEngagementHealthPlan = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_define_plan";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully defined health plan.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const engagementPaymentPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_payment_post";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully update payment.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const engagementAppointmentPost = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_appointment_post";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully update appointment.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const deleteIntakeToEngagement = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_intake_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully deleted intake form.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const deletePaymentForEngagement = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_payment_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully deleted payment option.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const deleteAppointmentForEngagement = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_appointment_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully deleted appointment.");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const updateEngagementTreatmentDays = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/update_engagement_treatment_days";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getEngagmentAssessmentSummary = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_assessment_get";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementAssessmentInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};
const updateAssessmentSummary = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_assessment_update";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteEngagementAssessment = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_assessment_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const updateEngagementName = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_update_name";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully updated engagement name");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const updateEngagementStatus = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_update_status";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            Utils.showSuccessAlert("Successfully updated engagement status");
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getHabit = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_habit_get";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementHabitCreator.fillHabitInstance(
                response.habit,
                response.supporting_data,
            ));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const editHabit = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_habit_edit";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const deleteHabit = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_habit_delete";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const getEngagementHealthDocumentList = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_document_list";
        return Axios.post(url, params).then((response) => {
            dispatch(documentActionCreator.documentListFill(
                response['data']
            ));   
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const publishStatusToggleHealthDocument = (params) => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/engagement_document_publish_status_toggle";
        return Axios.post(url, params).then((response) => {
            dispatch(EngagementCreator.engagementInstanceFill(response.data));
            dispatch(hideProgress());
            return response;
        }).catch((error) => {
            dispatch(hideProgress());
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


export {
    getEngagementInstance,
    assignIntakeToEngagement,
    deleteIntakeToEngagement,
    addGoalEngagement,
    updateGoalEngagement,
    defineEngagementHealthPlan,
    engagementPaymentPost,
    engagementAppointmentPost,
    deletePaymentForEngagement,
    deleteAppointmentForEngagement,
    updateEngagementTreatmentDays,
    getEngagmentAssessmentSummary,
    updateAssessmentSummary,
    updateEngagementName,
    updateEngagementStatus,
    deleteEngagementAssessment,
    editHabit,
    getEngagementHealthDocumentList,
    deleteHabit,
    getHabit,
    createEngagementHabitFromTemplate,
    publishStatusToggleHealthDocument
};