import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import { Dialog } from 'react-toolbox/lib/dialog';
import Input from 'react-toolbox/lib/input';
import { Field } from 'redux-form';
import TextInput from '../../../utils/common_components/textInput_form';
import Select from '../../../utils/common_components/select';
import { FieldArray } from 'redux-form';
import Switch from '../../../utils/common_components/switch_form';
import Dropzone from 'react-dropzone';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
export default function () {
    return React.createElement('div', { 'className': 'row m-l-30 m-t-10 m-r-30 m-b-30' }, React.createElement(Card, { 'className': 'w-100 m-20' }, React.createElement(CardTitle, {
        'title': 'Assign Intake Form',
        'className': 'p-b-5'
    }), React.createElement(CardText, { 'className': 'p-t-5 card-full-height' }, React.createElement('form', {
        'className': 'col-xs-12 m-t-30',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'intake_form',
        'component': Select,
        'label': 'Intake Form',
        'valueKey': 'id',
        'labelKey': 'name',
        'options': this.props.intakes,
        'simpleValue': true
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'subject',
        'type': 'text',
        'component': TextInput,
        'label': 'Subject'
    })), React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Field, {
        'id': 'templateBodyRichText',
        'className': 'w-100',
        'name': 'body',
        'type': 'text',
        'component': RichTextEditor,
        'label': 'Body'
    })), React.createElement('div', { 'className': 'col-xs-12 end-xs m-t-10' }, React.createElement(Button, {
        'label': 'Assign',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'primary': true,
        'raised': true
    }), React.createElement(Button, {
        'onClick': this.doCancel,
        'label': 'Cancel',
        'className': 'bg-accent m-l-5',
        'type': 'button',
        'raised': true
    })))))));
}