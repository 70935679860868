import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import DatePicker from '../../../utils/common_components/datepicker_form';
import DropzoneComponent from 'react-dropzone';
import { Avatar } from 'react-toolbox/lib/avatar';
export default function () {
    return React.createElement('div', { 'className': 'row center-profile' }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.doCancel
    }, '\n        Back\n    '), React.createElement('form', {
        'className': 'max-con',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, { 'className': 'w-100 card-bg no-box-shadow' }, React.createElement('div', { 'className': 'bg-white box-shadow-10 p-24' }, React.createElement('span', { 'className': 'title' }, 'Edit Profile'), React.createElement(CardText, { 'className': 'm-t-15' }, React.createElement('div', {
        'style': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px'
        }
    }, React.createElement('input', {
        'type': 'file',
        'accept': 'image/*',
        'style': { display: 'none' },
        'ref': this.fileInputRef,
        'onChange': this.uploadAvatar
    }), React.createElement('div', { 'style': { marginBottom: '20px' } }, '\n                            ', this.renderImage(), '\n                        '), React.createElement('a', {
        'style': {
            padding: '0 20px 0 20px',
            height: '30px',
            borderRadius: '6px',
            border: '1px solid #DB303C',
            cursor: 'pointer',
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: '600',
            color: '#DB303C',
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'avenir next, avenir, sans-serif !important'
        },
        'onClick': this.AddPhoto
    }, 'Edit Photo')), React.createElement('div', {
        'style': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        }
    }, React.createElement(Field, {
        'className': 'm-b-20',
        'name': 'first_name',
        'type': 'text',
        'component': TextInput,
        'label': 'First Name'
    }), React.createElement(Field, {
        'className': 'm-b-20',
        'name': 'last_name',
        'type': 'text',
        'component': TextInput,
        'label': 'Last Name'
    }), React.createElement(Field, {
        'className': 'm-b-20',
        'name': 'mobile_number',
        'type': 'number',
        'component': TextInput,
        'label': 'Phone Number'
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'dob',
        'type': 'date',
        'component': DatePicker,
        'label': 'Date of Birth'
    }))), React.createElement(Button, {
        'className': 'professional-btn',
        'onClick': this.pushRoute
    }, 'click to EDIT Professional info '), React.createElement(CardActions, { 'className': 'end-xs m-r-10 m-b-20' }, React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.submitting,
        'className': 'bg-success btn-width',
        'type': 'submit',
        'primary': true,
        'raised': true
    }))))));
}