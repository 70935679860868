import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/treatment_create";
import Utils from '../../utils/utils';
import store, { history} from '../../redux/store';
import { listWorkouts,listHabits,listGuidanceDocuments,listNutritionDocuments } from '../../redux/treatment_template/network/treatment_template_program';  
import { createGroupFromTreatmentTemplate,createEngagementFromTreatmentTemplate } from '../../redux/treatment_template/network/treatment_template_network';  
import Swal from 'sweetalert2';
import treatmentTemplateProgramActions from '../../redux/treatment_template/actions/treatment_template_program_creator';
import moment from 'moment';

class TreatmentCreateFromTemplate extends BaseComponent {
    
    constructor(props, context) {
        super(props, context);
        this.getData = this.getData.bind(this);
        this.onClickWorkoutCheckbox = this.onClickWorkoutCheckbox.bind(this);
        this.onClickHabitCheckbox = this.onClickHabitCheckbox.bind(this);
        this.onClickNutritionCheckbox = this.onClickNutritionCheckbox.bind(this);
        this.onClickGuidanceCheckbox = this.onClickGuidanceCheckbox.bind(this);
        this.changeSelectedDate = this.changeSelectedDate.bind(this);
        this.changeWorkoutDate = this.changeWorkoutDate.bind(this);
        this.changeHabitDate = this.changeHabitDate.bind(this);
        this.createTreatmentFromTreatmentTemplate = this.createTreatmentFromTreatmentTemplate.bind(this);
        this.updateGroupName = this.updateGroupName.bind(this);
        this.state = {
            selectedWorkouts:[],
            selectedHabits:[],
            selectedNutrition:[],
            selectedGuidance:[],
            today:moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
            selectedDate: moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
            updatedGroupName: "",
        };

    };
    
    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        this.props.treatmentTemplateProgramCleanup();
    }

    getData(){
        let that = this;
        let p1 = this.props.listWorkouts({ treatment_template_id: this.props.treatment_template.id });
        let p2 =  this.props.listHabits({id: this.props.treatment_template.id});
        let p3 =  this.props.listGuidanceDocuments({treatment_template_id: this.props.treatment_template.id,document_type:2});
        let p4 =  this.props.listNutritionDocuments({treatment_template_id: this.props.treatment_template.id,document_type:1});
        let workouts = [];
        let habits = [];
        let guidance = [];
        let nutrition = [];
        Promise.all([p1, p2,p3,p4]).then(values => { 
            workouts = [...values[0]['workouts']];
            habits = [...values[1]['habits']] ;
            guidance = [...values[2]['documents']] ;
            nutrition = [...values[3]['documents']] ;
            workouts = _.filter(workouts,(item)=>{
                if(item.published){
                    return true;
                }else{
                    return false;
                }
        });
            workouts =_.map(workouts,(itemObj) =>{
                let item = {...itemObj};
                item.start_date = that.state.selectedDate;
                item.selected = true;
                return item;
            });
            habits =_.map(habits,(itemObj) =>{
                let item = {...itemObj};
                item.start_date = that.state.selectedDate;
                item.selected = true;
                return item;
            });
            guidance =_.map(guidance,(itemObj) =>{
                let item = {...itemObj};
                item.selected = true;
                return item;
            });
            nutrition =_.map(nutrition,(itemObj) =>{
                let item = {...itemObj};
                item.selected = true;
                return item;
            });
          that.setState({
              selectedWorkouts:workouts,
              selectedHabits: habits,
              selectedGuidance: guidance,
              selectedNutrition: nutrition,
              updatedGroupName: that.props.treatment_template.name
          });
        });
    }

    changeSelectedDate(date){
        let selectedWorkouts =  _.map(this.state.selectedWorkouts,(item)=>{
            if(item.start_date >= this.state.today){
                item.start_date = date;
            }    
            return item;
        });
        let selectedHabits =  _.map(this.state.selectedHabits,(item)=>{
            if(item.start_date >= this.state.today){
                item.start_date = date;
            }     
            return item;
        });
        this.setState({selectedDate:date,selectedWorkouts:selectedWorkouts,selectedHabits:selectedHabits});
    }


    onClickWorkoutCheckbox(data,boolVal){
        let updatedSelection;
        if(boolVal){
            data.start_date = this.state.selectedDate;
            updatedSelection = this.state.selectedWorkouts.concat(data);
        } else {
            updatedSelection = _.filter(this.state.selectedWorkouts,(item)=>{
                return item.id !== data.id;
            });
        }
        this.setState({
            selectedWorkouts:updatedSelection,
        });
    }
    onClickHabitCheckbox(data,boolVal){
        let updatedSelection;
        if(boolVal){
            data.start_date = this.state.selectedDate;
            updatedSelection = this.state.selectedHabits.concat(data);
        } else {
            updatedSelection = _.filter(this.state.selectedHabits,(item)=>{
                return item.id !== data.id;
            });
        }
        this.setState({
            selectedHabits:updatedSelection,
        });
    }
    onClickNutritionCheckbox(data,boolVal){
        let updatedSelection;
        if(boolVal){
            updatedSelection = this.state.selectedNutrition.concat(data);
        } else {
            updatedSelection = _.filter(this.state.selectedNutrition,(item)=>{
                return item.id !== data.id;
            });
        }
        this.setState({
            selectedNutrition:updatedSelection,
        });
    }
    onClickGuidanceCheckbox(data,boolVal){
        let updatedSelection;
        if(boolVal){
            updatedSelection = this.state.selectedGuidance.concat(data);
        } else {
            updatedSelection = _.filter(this.state.selectedGuidance,(item)=>{
                return item.id !== data.id;
            });
        }
        this.setState({
            selectedGuidance:updatedSelection,
        });
    }

    changeSelectedDate(date){
        let selectedWorkouts =  _.map(this.state.selectedWorkouts,(item)=>{
            if(item.start_date >= this.state.today){
                item.start_date = date;
            }    
            return item;
        });
        let selectedHabits =  _.map(this.state.selectedHabits,(item)=>{
            if(item.start_date >= this.state.today){
                item.start_date = date;
            }     
            return item;
        });
        this.setState({selectedDate:date,selectedWorkouts:selectedWorkouts,selectedHabits:selectedHabits});
    }

    changeWorkoutDate(date,workout){
        let selectedWorkouts =  _.map(this.state.selectedWorkouts,(item)=>{
            if(item.id === workout.id){
                item.start_date = date;
            }    
            return item;
        });
        this.setState({selectedWorkouts:selectedWorkouts});
    }
    changeHabitDate(date,habit){
        let selectedHabits =  _.map(this.state.selectedHabits,(item)=>{
            if(item.id === habit.id){
                item.start_date = date;
            }    
            return item;
        });
        this.setState({selectedHabits:selectedHabits});
    }

    updateGroupName(value) {
        this.setState({
            updatedGroupName: value
        });
    }

    createTreatmentFromTreatmentTemplate(){
        let that = this;
        if(this.state.selectedWorkouts.length === 0 && 
            this.state.selectedHabits.length === 0 && 
            this.state.selectedGuidance.length === 0 &&
            this.state.selectedNutrition.length ===0
        ){
            Utils.showWarningAlert("Select at least one program item!");
            return;
        }
        Swal.fire({
            title: "",
            text: "Selected content remains draft until you publish each element.",
            type: "info",
            showCancelButton: false,
            showCloseButton: false,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "OK",
            cancelButtonText: "No",
            useRejections:true
        }).then(() => {
            let workouts = [...that.state.selectedWorkouts];
            let habits = [...that.state.selectedHabits];
            let guidance = _.map(that.state.selectedGuidance,"id");
            let nutrition = _.map(that.state.selectedNutrition,"id");
            workouts = _.map(workouts,(itemObj)=>{
                let item = _.pick(itemObj,['id','start_date']);
                item.start_date = moment(item.start_date).format("YYYY-MM-DD");
                return item;
            });
            habits = _.map(habits,(itemObj)=>{
                let item = _.pick(itemObj,['id','start_date']);
                item.start_date = moment(item.start_date).format("YYYY-MM-DD");
                return item;
            });
            let params = {
                workouts: workouts,
                habits: habits,
                guidance: guidance,
                nutrition: nutrition,
                treatment_template_id: that.props.treatment_template.id,
                updated_group_name: that.state.updatedGroupName,
            };
            if(_.has(that.props,"engagement_id") && that.props.engagement_id){
                params.engagement_id = that.props.engagement_id;
                that.props.createEngagementFromTreatmentTemplate(params).then((response)=>{
                    that.props.hideTreatmentCreationModal(null,response.engagement);
                }).catch(error=>{});
            }else{
                that.props.createGroupFromTreatmentTemplate(params).then((response)=>{
                    that.props.hideTreatmentCreationModal(null,response.group);
                }).catch(error=>{});
            }
        }, ()=> { });
    }

    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state,ownprops) {
    let template_workouts = _.filter(state.treatment_template.treatmentTemplateProgramReducer.workouts,(item)=>{
            if(item.published){
                return true;
            }else{
                return false;
            }
    });
    return {
        treatment_template: ownprops.treatment_template,
        template_workouts: template_workouts,
        template_habits: state.treatment_template.treatmentTemplateProgramReducer.habits,
        template_nutrition: state.treatment_template.treatmentTemplateProgramReducer.nutritionDocuments,
        template_guidance: state.treatment_template.treatmentTemplateProgramReducer.guidanceDocuments,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
           listWorkouts,
           listHabits,
           listGuidanceDocuments,
           listNutritionDocuments,
           createGroupFromTreatmentTemplate,
           createEngagementFromTreatmentTemplate,
           treatmentTemplateProgramCleanup: treatmentTemplateProgramActions.treatmentTemplateProgramCleanup,
        }, 
        dispatch
    );
}

const TreatmentCreateFromTemplateConnected = connect(mapStateToProps,mapDispatchToProps)(TreatmentCreateFromTemplate);
export default TreatmentCreateFromTemplateConnected;