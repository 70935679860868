
import moment from 'moment';

let initialState = {
    event_list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CALENDAR_EVENT_LIST_FILL":
            if (!action.event_list){
                return state;
            }
            return Object.assign({}, state, {
                event_list: action.event_list,
            });
        case "CALENDAR_EVENT_LIST_CLEANUP":
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
