import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import { Field } from 'redux-form';
import ButtonDefault from 'react-toolbox/lib/button';
import Button from '../../../utils/common_components/tooltipButton';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import TextInput from '../../../utils/common_components/textInput_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import { TetheredSelectWrap as Select } from '../../../utils/common_components/tetheredSelect';
import { Table } from '../../../vendor/reactable/reactable/table.jsx';
import { Thead } from '../../../vendor/reactable/reactable/thead.jsx';
import { Th } from '../../../vendor/reactable/reactable/th.jsx';
import { Tr } from '../../../vendor/reactable/reactable/tr.jsx';
import { Td } from '../../../vendor/reactable/reactable/td.jsx';
import FontIcon from 'react-toolbox/lib/font_icon';
export default function () {
    function repeatCue1(cue, cueIndex) {
        return React.createElement(Tr, { 'key': 'cue' + cueIndex }, React.createElement(Td, {
            'column': 'name',
            'value': cue.name
        }, React.createElement('div', {}, React.createElement('div', {}, cue.name), React.createElement('div', { 'className': 'f-12 f-300 m-t-5' }, cue.description))), React.createElement(Td, {
            'column': 'practice',
            'value': cue.practice.name,
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, '\n                        ', cue.practice.name, '\n                    ')), React.createElement(Td, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('div', {}, cue.is_owner ? React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'onClick': () => {
                this.editCue(cue.id);
            },
            'className': 'goal-template-action',
            'primary': true,
            'key': '2971'
        }, 'Edit') : null, !cue.is_owner ? React.createElement(ButtonDefault, {
            'raised': true,
            'ripple': true,
            'disabled': true,
            'className': 'goal-template-action',
            'primary': true,
            'key': '3170'
        }, 'Edit') : null)));
    }
    return React.createElement('div', { 'className': 'row w-100 m-20' }, React.createElement('div', { 'className': 'col-xs-12 m-t-30' }, React.createElement.apply(this, [
        Table,
        {
            'className': 'table reactable',
            'id': 'table',
            'noDataText': 'Cues have not been configured for your practice',
            'sortable': ['name'],
            'isServerPaginate': false
        },
        React.createElement(Thead, {}, React.createElement(Th, { 'column': 'name' }, React.createElement('strong', {}, 'Name and Text')), React.createElement(Th, {
            'column': 'practice',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, 'Practice')), React.createElement(Th, {
            'column': 'action',
            'style': {
                width: '120px',
                textAlign: 'center'
            }
        }, React.createElement('strong', {}, ' Action '))),
        _.map(this.props.cue_list, repeatCue1.bind(this))
    ])), React.createElement(Button, {
        'tooltip': 'Create new cue for your practice',
        'className': 'floating-add-button m-r-8',
        'style': { top: '90px' },
        'icon': 'add',
        'floating': true,
        'ripple': true,
        'mini': true,
        'onClick': this.addCue
    }));
}