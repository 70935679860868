import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/client_group_dashboard.rt";
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import swal from 'sweetalert2';
import { getClientGroup } from '../../redux/group/network/client_group_network';
import actions from '../../redux/group/actions/client_group_creator';
import {postClientWelcomeVideoWatched} from '../../redux/client/network/client_network';



class ClientGroupDashboard extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            tabIndex: 0,
            openGoalTrackingModal:false,
            selectedTrackingData:null,
            show_welcome_video:false,
        };
        this.handleTabChange  = this.handleTabChange.bind(this);
        this.subscribeSuccessCallback = this.subscribeSuccessCallback.bind(this);
        this.showGoalTrackingModal = this.showGoalTrackingModal.bind(this);
        this.hideGoalTrackingModal = this.hideGoalTrackingModal.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.closeWelcomeVideo = this.closeWelcomeVideo.bind(this);
    };

    componentWillMount() {
        if(this.props.match.params.group_id){
            this.fetchData();
        }else{
            history.goBack();
        }
       
    }

    fetchData(){
        let that  = this;
        this.props.getClientGroup({ id: this.props.match.params.group_id }).then((response)=>{
            if(response.group.goal_state === 1){
                let needs_goal_tracking_target_update = false;
                if(!_.isEmpty(response.group)){
                    let goal = response.group.goal;
                    if(goal && goal.client_goal_questions.length > 0 ){
                        _.forEach(goal.client_goal_questions,(item)=>{
                            if(item.initial_value === null || item.target_value === null){
                                needs_goal_tracking_target_update = true;
                            }
                        });
                    }
                }

                let state = {tabIndex:0,show_welcome_video:false};
                let change_state = false;
                if(response.group.show_treatment === false){
                    state.tabIndex = 1; //Show Needs Payment
                    change_state = true;
                }
                if(response.group.show_welcome_video !== null){
                    state.show_welcome_video= true; 
                    change_state = true;
                    that.props.postClientWelcomeVideoWatched({
                        'object_type':'group',
                        'id':that.props.match.params.group_id
                    });
                }else if(response.group.show_treatment && needs_goal_tracking_target_update === false && response.group.client_untracked_goal_dates && response.group.client_untracked_goal_dates.length > 0){
                    state.openGoalTrackingModal = true;
                    state.selectedTrackingData = response.group.client_untracked_goal_dates[0]; 
                    change_state = true;
                }
                if(change_state){
                    that.setState(state); 
                }
            }

        }).catch((error)=>{

        });
    }

    closeWelcomeVideo() {
        this.fetchData();  
    }


    handleTabChange(tabIndex) {
        this.setState({
            tabIndex: tabIndex,
        });
    }

    hideGoalTrackingModal() {
        this.setState({ openGoalTrackingModal: false,selectedTrackingData:null });
    }
    
    showGoalTrackingModal(tracking_date) {
        this.setState({ openGoalTrackingModal: true,selectedTrackingData:tracking_date });
    }    


    componentWillUnmount() {
        this.props.cleanGroup();
    }

    render() {
        if (_.isEmpty(this.props.group)) {
            return (<div></div>);
        }
        return Template.apply(this);
    }

    subscribeSuccessCallback(){
        this.props.getClientGroup({ id: this.props.match.params.group_id });
    }

}    

function mapStateToProps(state) {
    let group = state.group.clientGroupReducer.group;
    let needs_goal_tracking_target_update = false;
    if(!_.isEmpty(group)){
        let goal = group.goal;
        if(goal && goal.client_goal_questions.length > 0 ){
            _.forEach(goal.client_goal_questions,(item)=>{
                if(item.initial_value === null || item.target_value === null){
                    needs_goal_tracking_target_update = true;
                    return false;
                }
            });
        }
    }

    return {
        user: state.auth.authReducer.user,
        group: group,
        needs_goal_tracking_target_update:needs_goal_tracking_target_update
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        cleanGroup: actions.clientGroupCleanup,
        postClientWelcomeVideoWatched,
        getClientGroup,
    }, dispatch);
}

const ClientGroupDashboardConnected = connect(mapStateToProps, mapDispatchToProps)(ClientGroupDashboard);
export default ClientGroupDashboardConnected;
