import  React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/cue_edit";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Utils from '../../utils/utils';

import ProgressBar from 'react-toolbox/lib/progress_bar';
import {history} from '../../redux/store';
import { getCue, saveCue } from '../../redux/exercise/network/cue_network';
import CueAction from '../../redux/exercise/actions/cue_action';

class CueEdit extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.saveCue = this.saveCue.bind(this);  
        this.state = {publish:false};
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
    }
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentWillMount() {
        this.props.getCue({
            cue_id : this.props.match.params.cue_id,
        });        
    }

    componentWillUnmount() {
        this.props.cleanUp();
    }

    saveCue(data) {     
        let params = _.omit(data, ['can_be_deleted']);
        this.props.saveCue(params).then((response) => {
            history.push('/practice/'+this.props.selectedRole.practice.id+'/engagement/cue');
        }).catch((error) => {
            //do nothing
        });  
    }
    
    render() {
        return Template.apply(this);
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.authReducer.user,
        selectedRole: state.auth.roleReducer.selectedRole,
        cue: state.exercise.cueReducer.cue,
        initialValues: state.exercise.cueReducer.cue
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            getCue,
            saveCue,
            cleanUp: CueAction.cleanModule
        }, 
        dispatch
    );
}

const validate = (values, props) => {
    let requiredFields = [
        'name',
        'description'
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};


const CueEditForm = reduxForm(
    {
        form: 'cueEditForm',
        enableReinitialize: true,
        validate
    }
)(CueEdit);


const CueEditFormConnected = connect(mapStateToProps,mapDispatchToProps)(CueEditForm);
export default CueEditFormConnected;