// Action Creators
const clientPaymentPlanListFill = (client_payment_plans) => ({
    type: 'CLIENT_PAYMENT_PLAN_LIST_FILL',
    client_payment_plans,
});
const clientPaymentPlanInstanceFill = (client_payment_plan) => ({
    type: 'CLIENT_PAYMENT_PLAN_INSTANCE_FILL',
    client_payment_plan,
});
const clientPaymentPlanSubscriptionListFill = (client_payment_plan_subscriptions) => ({
    type: 'CLIENT_PAYMENT_PLAN_SUBSCRIPTION_LIST_FILL',
    client_payment_plan_subscriptions,
});

export default {
    clientPaymentPlanListFill,
    clientPaymentPlanInstanceFill,
    clientPaymentPlanSubscriptionListFill,
};