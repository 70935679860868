import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import { tetheredSelectFunction as Select } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from 'react-dropzone';
export default function () {
    return React.createElement('div', { 'className': 'row center-xs' }, React.createElement(Card, { 'className': 'col-xs-10 m-20 p-0' }, React.createElement(CardTitle, {
        'title': (this.props.match.params.location_id ? 'Edit' : 'Add') + ' Practice Location',
        'className': 'm-b-10'
    }), React.createElement(CardText, { 'className': 'card-full-height' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit(this.save) }, React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'name',
        'type': 'text',
        'component': TextInput,
        'label': 'Location Name *'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'address',
        'type': 'text',
        'component': TextInput,
        'label': 'Address',
        'multiline': true
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'city',
        'type': 'text',
        'component': TextInput,
        'label': 'City *'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'state',
        'type': 'text',
        'component': TextInput,
        'label': 'State'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'zipcode',
        'type': 'text',
        'component': TextInput,
        'label': 'Zip Code *'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'country',
        'type': 'text',
        'component': TextInput,
        'label': 'Country'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'phone',
        'type': 'text',
        'component': TextInput,
        'label': 'Phone'
    })), React.createElement('div', {}, React.createElement(Field, {
        'className': 'w-100',
        'name': 'fax',
        'type': 'text',
        'component': TextInput,
        'label': 'Fax'
    })), React.createElement('div', { 'className': 'text-left' }, React.createElement(Field, {
        'className': 'w-100',
        'name': 'is_main_center',
        'type': 'text',
        'component': Switch,
        'label': 'Main Center?'
    })), React.createElement('div', { 'className': 'row end-xs m-t-10 m-b-10 m-r-10' }, React.createElement(Button, {
        'label': 'Cancel',
        'accent': true,
        'raised': true,
        'onClick': this.cancel
    }), React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.pristine || this.props.submitting,
        'type': 'submit',
        'className': 'm-l-10 bg-success',
        'raised': true
    }))))));
}